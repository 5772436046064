import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, SearchIcon, DeleteIcon } from '@uy3/web-components';
import { UserGetDetailResponseModel, useUserPermissionData } from 'contexts/userContext';
import { GetListApiResponseSuccess, iconSx, } from 'contexts/apiRequestContext';
import { GridColDef } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';
import { TypographyCustom } from 'pages/Security/Users/UsersList/TypographyCustom';

type MemberListTabProps = {
    page: number
    setPage: (newPage: number) => void;
    rowsPerPage: number
    setRowsPerPage: (page: number) => void
    queryData: GetListApiResponseSuccess<UserGetDetailResponseModel>
    onRemoveUserGroup: (groupName: string) => void,
};

export function MemberListTab({ page, setPage, rowsPerPage, setRowsPerPage, queryData, onRemoveUserGroup }: MemberListTabProps) {
    const { hasPermission } = useUserPermissionData();
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Membro',
            editable: false,
            hideSortIcons: true,
            minWidth: 150,
            flex: 1
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF',
            editable: false,
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            renderCell: ({ value }) => formatDocumentNumber(value)
        },
        {
            field: 'phoneNumber',
            headerName: 'Telefone',
            editable: false,
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            renderCell: ({ value }) => formatPhoneNumber(value)
        },
        {
            field: 'enabled',
            headerName: 'Habilitado',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => {
                const isEnable = !!value;
                return (
                    <TypographyCustom
                        isTrue={isEnable}
                        title={isEnable ? "Habilitado" : "Desabilitado"}
                    />
                )
            },
        },
        {
            field: 'userStatus',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                const isConfirmed = cellValues.value === 'CONFIRMED';
                return (
                    <TypographyCustom
                        isTrue={isConfirmed}
                        title={isConfirmed ? "Confirmado" : "Alteração de senha"}
                    />
                )
            }
        },
        {
            field: 'userCreateDate',
            headerName: 'Data de Criação',
            editable: false,
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <div>
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            editable: false,
            headerAlign: "center",
            align: 'center',
            flex: 0,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                    >
                        {hasPermission('User', 'Read') &&
                            <Link to={`/seguranca/usuarios/${cellValues.id}`}
                                style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}>
                                <IconButton>
                                    <SearchIcon sx={iconSx} />
                                </IconButton>
                            </Link>}

                        {hasPermission('UserGroup', 'Delete') &&
                            <IconButton
                                onClick={() => onRemoveUserGroup(String(cellValues.id))}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}

                        {!hasPermission('User', 'Read') && !hasPermission('UserGroup', 'Delete') && <>-</>}
                    </Stack>
                );
            }
        }
    ];


    return (
        <>
            <DataTable
                page={queryData ? queryData.page : page}
                columns={columns}
                rows={queryData.data}
                rowCount={queryData.totalItems}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </ >

    );
};

export default MemberListTab;
