import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React from 'react';
import { walletFiltersSchema, defaultValuesWalletFilterschema } from './CostsFiltersSchema';
import { FieldValues } from 'react-hook-form';
import { CostsFilters } from './CostsFilters';

export const CostsFiltersContainer: React.FC = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        switch (optionFilter) {
            case 'hasBillingDate': 
                addFilter(optionFilter, values[optionFilter]);
                break
            case 'initialBillingDate':
                const finalBillingDate = new Date(values.finalBillingDate);
                addFilter(optionFilter, values[optionFilter], finalBillingDate);
                break;
            case 'initialCreationDate':
                const finalCreationDate = new Date(values.finalCreationDate);
                addFilter(optionFilter, values[optionFilter], finalCreationDate);
                break;
            default:
                addFilter(optionFilter, values[optionFilter]);
        }
    };

    return (
        <>
            <FormProvider
                defaultValues={defaultValuesWalletFilterschema}
                validationSchema={walletFiltersSchema()}
                onSubmit={handleOnSubmit}
            >
                <CostsFilters
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    );
};
