import { Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import { useApiRequest } from 'contexts/apiRequestContext';
import { useGetRejectionReason } from 'contexts/creditNote/creditContext';
import { blackListOptions } from 'contexts/creditNote/creditNoteOptions';
import { useFormContext } from 'contexts/formContext'
import { PersonReponseParams } from 'contexts/personContext';
import { useParams } from 'react-router';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';

type CancelBankAccountRequestProps = {
    personInformation?: PersonReponseParams;
    onClose: () => void;
    naturalOrLegalPerson: NaturalPersonReadModel | LegalPersonReadModel;
}

export const CancelBankAccountRequest = ({ personInformation, naturalOrLegalPerson, onClose }: CancelBankAccountRequestProps) => {
    const { bankAccountId } = useParams()
    const { watch } = useFormContext();
    const { submitting } = useApiRequest();
    const { listReasons } = useGetRejectionReason();

    return (
        <Stack spacing={2} mt={2}>

            <SelectFormField
                label='Motivo da Reprovação'
                name='reasonDisapproval'
                required
                multiple
                options={listReasons}
            />

            <SelectFormField
                label='Tipo de bloqueio'
                name='blockType'
                required
                defaultValue={null}
                options={[
                    { label: 'Ajustável', value: 'Adjustable' },
                    { label: 'Final', value: "Final" },
                ]}
            />

            {watch("blockType") === 'Final' && !!bankAccountId  &&
                <SelectFormField
                    label='Incluir na Blacklist'
                    name='complianceChecklist'
                    required
                    multiple
                    options={blackListOptions(naturalOrLegalPerson)}
                />}

            {watch("blockType") === 'Final' &&
                <DatePickerFormField
                    label='Validade do Bloqueio'
                    name='blockUntil'
                    required
                    fullWidth
                    disablePast={true}
                />
            }

            <TextFormField
                variant='outlined'
                id="message"
                fullWidth
                type="text"
                name="message"
                label="Mensagem"
                margin="dense"
                multiline={true}
                rows={4}
                required={watch('reasonDisapproval')?.includes('OUTROS_MOTIVOS')}
            />
            <Stack direction='row' gap={2} justifyContent='end'>
                <Button
                    variant='outlined'
                    onClick={onClose}
                >
                    Não, cancelar
                </Button>

                <Button
                    variant='contained'
                    type='submit'
                    disabled={submitting}
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
