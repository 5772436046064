import { object, string } from "yup";

type TypeSchemaOptions = {
    [key: string]: any;
};

const schemaOptions: TypeSchemaOptions = {
    "NaturalRegistrationNumber": string().typeError('CPF: Precisa ser preenchido.').required('CPF: Precisa ser preenchido.').cpfCnpjValidation("CPF inválido"),
    "LegalRegistrationNumber": string().typeError('CNPJ: Precisa ser preenchido.').required('CNPJ: Precisa ser preenchido.').cpfCnpjValidation("CNPJ inválido"), 
    "Email": string().typeError('Email: Precisa ser preenchido.').required('Email: Precisa ser preenchido.').email('Email inválido')
}

export const validationSchemaSelectKeyPix = (type?: string) => {
    const isNaturalPersonOrLegalPerson = type === "NaturalRegistrationNumber" || type === "LegalRegistrationNumber" || type === "LegalRegistrationNumber" || type === "Email";
    return object().shape(isNaturalPersonOrLegalPerson ? {
        pixKey: schemaOptions[type]
    } : {
        pixKey: string().typeError('Chave Pix: Precisa ser preenchido.').required('Chave Pix: Precisa ser preenchido.')
    })
}

export const defaultValuesSelectPixKey = {
    pixKey: null
};