import { Box, IconButton } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { DataTable, EditIcon } from "@uy3/web-components";
import { asText } from "services/permissions";

type PermissionTabProps = {
    setFormData: (permission: object | undefined) => void;
    permissionData: any;
}

export function PermissionTab({ setFormData, permissionData }: PermissionTabProps) {    
    const columns: GridColDef[] | any[] = [
        {
            field: "resourceDisplay",
            headerName: "Recursos",
            flex: 2,
            editable: false,
        },
        {
            field: "statusText",
            headerName: "Status",
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                let row = cellValues.row;
                return <>{asText(row)}</>
            },
        },
        {
            field: "actions",
            headerName: "",
            sortable: false,
            editable: false, 
            minWidth: 150,
            flex: 2,
            align: 'right', 
            headerAlign: 'right', 
            renderCell: (cellValues: any) => {
                return (
                    <IconButton onClick={() => setFormData(cellValues.row)}>
                        <EditIcon sx={{ width: 24, height: 24 }} />
                    </IconButton>
                )
            },
        },
    ];

    return (
        <Box p={0} mt={4}>
            <DataTable
                columns={columns}
                rows={permissionData ?? []}
                getRowId={(row) => row?.resource}
                page={5}
                hideFooter
                rowCount={permissionData?.length ?? 0}
                rowsPerPage={permissionData?.length ?? 0}
                setPage={() => { }}
                setRowsPerPage={() => { }}
                enableJumpAction={false}
            />
        </Box >
    );
};

export default PermissionTab;