/* eslint-disable sonarjs/no-duplicate-string */
import type { FunctionComponent } from 'react';
import type { KeysTableProps } from './KeysTable.interface';
import { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { columns, rows } from './KeysTable.mock';
import { MainBox } from './KeysTable.styled';
import { Typography } from '../../atoms';

const HeaderBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Typography variant="sm" color="neutral.dark" lineHeight="29px">
        Chaves cadastradas na UY3
      </Typography>
    </Stack>
  );
};

const NoRowsOverlayNew = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" mb={3}>
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        Nenhuma chave cadastrada
      </Typography>
    </Stack>
  );
};

const NoResultsOverlayNew = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        Nenhum resultado encontrado
      </Typography>
    </Stack>
  );
};

export const KeysTable: FunctionComponent<KeysTableProps> = () => {
  const [pagination, setPagination] = useState(true);

  useEffect(() => {
    if (rows.length <= 0) {
      setPagination(true);
    } else {
      setPagination(false);
    }
  }, []);
  return (
    <Stack sx={{ position: 'relative' }}>
      <MainBox height={pagination ? 400 : 500}>
        <DataGrid
          rows={rows}
          rowHeight={40}
          columns={columns}
          disableColumnMenu
          pageSize={5}
          rowsPerPageOptions={[5, 10]}
          disableSelectionOnClick
          hideFooterPagination={pagination}
          hideFooterSelectedRowCount={pagination}
          components={{
            Toolbar: HeaderBar,
            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
            NoRowsOverlay: NoRowsOverlayNew,
            NoResultsOverlay: NoResultsOverlayNew,
          }}
          componentsProps={{
            toolbar: {
              variant: 'outlined',
              className: 'searchBar',
              sx: {
                display: 'flex',
                justifyContent: 'flex-end',
                maxWidth: '548px',
                alignItems: 'right',
              },
            },
          }}
        />
      </MainBox>
    </Stack>
  );
};
