import { Grid } from '@mui/material'
import { Stack } from '@mui/system'
import { Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext'
import { isEmpty } from 'lodash';


type ViewInfoProps = {
    label: string; 
    value: string; 
    onClick?: () => void
}

export function ViewInfo({ label, value, onClick}: ViewInfoProps) {
    return (
        <Grid container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            sx={{
                cursor: onClick !== undefined ? 'pointer' : 'auto'
            }}
            onClick={onClick && onClick}
            spacing={1}
        >
            <Grid item>
                <Typography
                    variant='h5'
                    sx={{
                        fontWeight: '400',
                        size: '16px',
                        lineHeight: '19.2px',
                        color: '#8F92A1',
                        mb: 1
                    }} >
                    {label}
                </Typography>
            </Grid>
            <Grid item>
                <Typography
                    variant='h6'
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '8px',
                        background: 'rgba(143, 146, 161, 0.20)',
                        borderRadius: "8px",
                        padding: "2px 8px"
                    }}>
                    {isEmpty(value) ? "N/D" : value}
                </Typography>
            </ Grid>
        </Grid>
    );
};

type OperatorViewInfoProps = {
    resource?: string
}

const OperatorViewInfo = ({ resource }: OperatorViewInfoProps) => {
    const { getValues } = useFormContext();
    const [id, user, group, updatedBy, updatedAt, tenantDisplay] = getValues(["id", "ownerUser", "ownerGroup", "updatedBy", "updatedAt", "tenantDisplay"]);
    return (
        <>
            {id === undefined ?
                <></>
                :
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ borderRadius: "8px", my: 5 }} >
                    {tenantDisplay !== undefined && <ViewInfo label='Correspondente' value={tenantDisplay} />}
                    {user?.userIdDisplay !== undefined && <ViewInfo label={resource === 'fund' ? 'Criado por' : 'Operador'} value={user?.userIdDisplay ?? "N/D"} />}
                    {group?.groupDisplay !== undefined && <ViewInfo label='Grupo' value={group?.groupDisplay} />}
                    {updatedAt !== undefined &&
                        <ViewInfo label='Data da última atualização' value={new Date(updatedAt).toLocaleDateString('pt-BR')} />}
                    {updatedBy?.userIdDisplay !== undefined && <ViewInfo label='Última atualização feita por' value={updatedBy?.userIdDisplay} />}
                </Stack>}
        </>
    )
}

export default OperatorViewInfo