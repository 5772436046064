import { string, object } from 'yup';

export function BillingSettingFiltersSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        walletsCode: string().when('optionFilter', {
            is: 'walletsCode',
            then: string().required(`Código da carteira: precisa ser preenchido.`).typeError('Código da carteira inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        statusesValue: string().when('optionFilter', {
            is: 'statusesValue',
            then: string().required(`Status: Precisa ser preenchido. `).typeError('Status inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        payerName: string().when('optionFilter', {
            is: 'payerName',
            then: string().required(`Nome do devedor: Precisa ser preenchido. `).typeError(`Nome do devedor: Precisa ser preenchido. `),
            otherwise: string().nullable().notRequired(),
        }),
        payerRegistrationNumber: string().when('optionFilter', {
            is: 'payerRegistrationNumber',
            then: string()
                .required(`CPF/CNPJ do devedor: Precisa ser preenchido. `)
                .typeError(`CPF/CNPJ do devedor: Precisa ser preenchido. `)
                .cpfCnpjValidation('CPF/CNPJ inválido'),
            otherwise: string().nullable().notRequired(),
        }),
        initialCreatedDate: string().when("optionFilter", {
            is: "initialCreatedDate",
            then: string()
                .typeError('Data de início precisa ser preenchido.').required('Data de início: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalCreatedDate: string().when("optionFilter", {
            is: "finalCreatedDate",
            then: string().typeError('Data final precisa ser preenchido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        billingCount: string().when("optionFilter", {
            is: "billingCount",
            then: string().typeError('Quantidade de parcelas precisa ser preenchido.').required('Quantidade de parcelas: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesBillingSettingFilterschema = {
    optionFilter: 'walletsCode',
    walletsCode: null,
    statusesValue: null,
    payerName: null,
    payerRegistrationNumber: null,
    initialCreatedDate: null,
    finalCreatedDate: null,
    billingCount: null,
};
