import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const BookWithTicketIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M20 13H20.75V12.25H20V13ZM12 16V15.25C11.5858 15.25 11.25 15.5858 11.25 16H12ZM16 16H16.75C16.75 15.5858 16.4142 15.25 16 15.25V16ZM16 22L15.4697 22.5303C15.6842 22.7448 16.0068 22.809 16.287 22.6929C16.5673 22.5768 16.75 22.3033 16.75 22H16ZM12 22H11.25C11.25 22.3033 11.4327 22.5768 11.713 22.6929C11.9932 22.809 12.3158 22.7448 12.5303 22.5303L12 22ZM14 20L14.5303 19.4697C14.2374 19.1768 13.7626 19.1768 13.4697 19.4697L14 20ZM8 5.25C7.58579 5.25 7.25 5.58579 7.25 6C7.25 6.41421 7.58579 6.75 8 6.75V5.25ZM16 6.75C16.4142 6.75 16.75 6.41421 16.75 6C16.75 5.58579 16.4142 5.25 16 5.25V6.75ZM8 8.75C7.58579 8.75 7.25 9.08579 7.25 9.5C7.25 9.91421 7.58579 10.25 8 10.25V8.75ZM12 10.25C12.4142 10.25 12.75 9.91421 12.75 9.5C12.75 9.08579 12.4142 8.75 12 8.75V10.25ZM7 2.75H17V1.25H7V2.75ZM19.25 5V16H20.75V5H19.25ZM4.75 16V5H3.25V16H4.75ZM17 18.25H16.5714V19.75H17V18.25ZM12 18.25H7V19.75H12V18.25ZM3.25 16C3.25 18.0711 4.92893 19.75 7 19.75V18.25C5.75736 18.25 4.75 17.2426 4.75 16H3.25ZM19.25 16C19.25 17.2426 18.2426 18.25 17 18.25V19.75C19.0711 19.75 20.75 18.0711 20.75 16H19.25ZM17 2.75C18.2426 2.75 19.25 3.75736 19.25 5H20.75C20.75 2.92893 19.0711 1.25 17 1.25V2.75ZM7 1.25C4.92893 1.25 3.25 2.92893 3.25 5H4.75C4.75 3.75736 5.75736 2.75 7 2.75V1.25ZM7 13.75H20V12.25H7V13.75ZM19.25 13V16H20.75V13H19.25ZM7 12.25C4.92893 12.25 3.25 13.9289 3.25 16H4.75C4.75 14.7574 5.75736 13.75 7 13.75V12.25ZM12 16.75H16V15.25H12V16.75ZM15.25 16V22H16.75V16H15.25ZM12.75 22V16H11.25V22H12.75ZM16.5303 21.4697L14.5303 19.4697L13.4697 20.5303L15.4697 22.5303L16.5303 21.4697ZM13.4697 19.4697L11.4697 21.4697L12.5303 22.5303L14.5303 20.5303L13.4697 19.4697ZM8 6.75H16V5.25H8V6.75ZM8 10.25H12V8.75H8V10.25Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

BookWithTicketIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
