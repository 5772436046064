import { Grid, Stack } from '@mui/material'
import { Button, Typography } from '@uy3/web-components'
import { CheckboxFormField } from 'components/Forms/FormFields'

type TransferReceiptPdfFormProps = {
  hasOpenBankAccount: boolean;
  errorMessage: string | undefined;
  onClose: () => void;
}

export const TransferReceiptPdfForm = ({ hasOpenBankAccount, errorMessage, onClose }: TransferReceiptPdfFormProps) => {
  return (
    <Stack spacing={2}>
      <Grid container justifyContent='space-between'>
        {hasOpenBankAccount && <CheckboxFormField
          name='generateStatement'
          label='Extrato da conta'
        />}
        <CheckboxFormField
          name='generateRecepit'
          label='Comprovante de transferência'
        />
        {!!errorMessage &&
          <Typography sx={{ color: 'red', pt: -10 }}>{errorMessage}</Typography>
        }
      </Grid>

      <Stack spacing={1}>
        <Button variant='contained' type='submit'>
          Gerar comprovante
        </Button>
        <Button variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
      </Stack>
    </Stack>
  )
}