import { BankAccountProductBaseModel } from './generics';
import { IRelatedPersonCreateModel } from 'utils/interface';

export class BankAccountProductCreateModel extends BankAccountProductBaseModel {
    relatedPerson?: IRelatedPersonCreateModel[];
    requiredDoc?: BankAccountProductRequiredDocCreateModel[];
}

interface BankAccountProductRequiredDocCreateModel {
    personType: string;
    fileType: string;
    group: number;
    typeOfRelationship: string;
}
