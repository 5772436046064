import { number } from 'yup';
import { Amortization, DefaultSchemaAmortization, ValidationSchemaAmortization } from './Amortization';
import { ECalculateByValueType } from 'utils/enums';

const genericRequired = 'Precisa ser preenchido.';
abstract class StudentLoan extends Amortization {
    public fixedPaymentDuringGraduation?: number;
    public termsUntilGraduation?: number;
    public absAfterGraduation?: number;
}

export class DefaultSchemaAmmStudentLoan extends DefaultSchemaAmortization implements StudentLoan {
    public amortizationType = 'studentloan';
    public calculateByValueType: ECalculateByValueType | string = ECalculateByValueType[ECalculateByValueType.Liquid];
    public fixedPaymentDuringGraduation = 1500;
    public termsUntilGraduation = 6;
    public absAfterGraduation = 3;
}

export class ValidationSchemaAmmStudentLoan extends ValidationSchemaAmortization {
    public fixedPaymentDuringGraduation = number()
        .typeError('Valor precisa ser um número.')
        .required(`Pagamento durante a graduação: ${genericRequired}`);
    public termsUntilGraduation = number()
        .typeError('Valor precisa ser um número.')
        .required(`Parcelas até a graduação: ${genericRequired}`);
    public absAfterGraduation = number()
        .typeError('Valor precisa ser um número.')
        .required(`Carência após a graduação: ${genericRequired}`);
}
