/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';

interface CommissionFormManagerProps {    }

const CommissionFormManager: React.FC<CommissionFormManagerProps> = () => {        

    return (
        <>
            <Typography variant="h6" component="h2" fontWeight={600} color="common.black">
                Gerencie as informações da emissão
            </Typography>

            <CurrencyFormField
                name="minimumPrincipalAmount"
                label="Valor mínimo do principal"
                variant="outlined"
                fullWidth
            />
            <CurrencyFormField
                name="maximumPrincipalAmount"
                label="Valor máximo do principal"
                variant="outlined"
                fullWidth
            />
        </>
    );
};

export default CommissionFormManager;
