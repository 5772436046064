import { IOption } from 'components/CardSelect/CardSelect';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { formatCurrencyInCents, isNegativeValue } from 'helpers';
import { TooltipZetra } from '../SelectWarranty/TooltipZetra';

export function summaryZetra(data: IZetraMarginQueryReadModel | undefined): IOption[] {
    const vinculoFuncionals = data?.result?.servidores ?? [];
    const createdAt = data?.createdAt!;

    const options: IOption[] = [];

    vinculoFuncionals.forEach((item) => {
        const margens = item?.margens ?? [];
        
        margens.forEach((margem) => {
            const isNegative = isNegativeValue(margem?.valorDisponivel);
            const vlMargemDisp = formatCurrencyInCents(margem?.valorDisponivel);
            const margemDescription = isNegative ? 'Margem indisponível' : vlMargemDisp;
            
            options.push({
                label: `${item?.orgao} - ${margem?.descricao}`,
                description: `Matrícula: ${item.matricula} | Data da consulta: ${new Date(
                    createdAt
                ).toLocaleDateString()} | Valor da margem: ${margemDescription}`,
                value: `${margem?.codigo}-${item?.orgaoCodigo}-${margem?.valorDisponivel}`,
                disabled: isNegative,
                //@ts-ignore
                tooltipComponent: {
                    title: <TooltipZetra values={item} />,
                    placement: 'left',
                },
            });
        });
    });

    return options;
}
