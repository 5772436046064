import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ChartArrowUpRectangleIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14.5 8.25C14.0858 8.25 13.75 8.58579 13.75 9C13.75 9.41421 14.0858 9.75 14.5 9.75V8.25ZM17.2764 9.55279L16.6056 9.8882V9.8882L17.2764 9.55279ZM17.3292 11.3354C17.5144 11.7059 17.9649 11.8561 18.3354 11.6708C18.7059 11.4856 18.8561 11.0351 18.6708 10.6646L17.3292 11.3354ZM17.1272 9.91119L17.5384 9.28396L16.284 8.46158L15.8728 9.08881L17.1272 9.91119ZM13.7644 13.6729L13.1372 13.2617V13.2617L13.7644 13.6729ZM12.0726 13.6424L11.431 14.0308L12.0726 13.6424ZM10.1644 10.4901L9.52284 10.8784H9.52284L10.1644 10.4901ZM8.43044 10.5302L7.77155 10.1719L8.43044 10.5302ZM5.34111 14.6417C5.14324 15.0056 5.27783 15.461 5.64173 15.6589C6.00562 15.8568 6.46103 15.7222 6.65889 15.3583L5.34111 14.6417ZM6 2.75H18V1.25H6V2.75ZM21.25 6V18H22.75V6H21.25ZM18 21.25H6V22.75H18V21.25ZM2.75 18V6H1.25V18H2.75ZM6 21.25C4.20507 21.25 2.75 19.7949 2.75 18H1.25C1.25 20.6234 3.37665 22.75 6 22.75V21.25ZM21.25 18C21.25 19.7949 19.7949 21.25 18 21.25V22.75C20.6234 22.75 22.75 20.6234 22.75 18H21.25ZM18 2.75C19.7949 2.75 21.25 4.20507 21.25 6H22.75C22.75 3.37665 20.6234 1.25 18 1.25V2.75ZM6 1.25C3.37665 1.25 1.25 3.37665 1.25 6H2.75C2.75 4.20507 4.20507 2.75 6 2.75V1.25ZM14.5 9.75H16.382V8.25H14.5V9.75ZM16.6056 9.8882L17.3292 11.3354L18.6708 10.6646L17.9472 9.21738L16.6056 9.8882ZM16.382 9.75C16.4767 9.75 16.5632 9.8035 16.6056 9.8882L17.9472 9.21738C17.6508 8.6245 17.0448 8.25 16.382 8.25V9.75ZM15.8728 9.08881L13.1372 13.2617L14.3917 14.084L17.1272 9.91119L15.8728 9.08881ZM12.7142 13.2541L10.8061 10.1017L9.52284 10.8784L11.431 14.0308L12.7142 13.2541ZM7.77155 10.1719L5.34111 14.6417L6.65889 15.3583L9.08934 10.8885L7.77155 10.1719ZM10.8061 10.1017C10.1065 8.946 8.41688 8.98511 7.77155 10.1719L9.08934 10.8885C9.18153 10.7189 9.4229 10.7133 9.52284 10.8784L10.8061 10.1017ZM13.1372 13.2617C13.0363 13.4155 12.8095 13.4114 12.7142 13.2541L11.431 14.0308C12.0978 15.1323 13.6857 15.1609 14.3917 14.084L13.1372 13.2617Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

ChartArrowUpRectangleIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
