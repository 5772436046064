import { Typography } from "@uy3/web-components";
import { activeTheme } from "services/theme";

const theme = activeTheme();

export const TypographyCustom = ({ isTrue, title }: { isTrue: boolean, title: string }) => {
    const successColor = theme.palette.success.dark;
    const errorColor = theme.palette.error.dark;
    return <Typography
        variant="h6"
        fontWeight="400"
        fontFamily='"Lato", sans-serif'
        color={isTrue ? successColor : errorColor}
    >
        {title}
    </Typography>
};