import { FormProvider } from 'contexts/formContext';
import { AddSignatureFormWrapper } from './AddSignatureForm/AddSignatureFormWrapper';
import {
    validationSchemaAddSignersForm,
    defaultValuesAddSigners,
} from './AddSignatureForm/AddSignatureSchema';
import { useAddSignatureMutation } from 'contexts/signature/signatureContext';
import { Signatures, Signer } from 'services/signature/signature.types';
import { FieldValues } from 'react-hook-form';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
} from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { signatureDeleteAsync } from 'services/signature/signature';
import { useIdentity } from 'contexts/identityContext';

interface AddSignatureContainerProps {
    onClose: () => void;
    signatureWorkFlowId: string;
    persons: any[];
    signers: Signer[];
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export default function AddSignatureContainer({
    onClose,
    signatureWorkFlowId,
    persons,
    signers,
    setToast,
}: AddSignatureContainerProps) {
    const { token } = useIdentity();

    const onSuccess = (title: string) => {
        showSuccessToast(title, '', setToast);
    };

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast('Ops, houve um erro', errorMessage, setToast);
    };

    const { mutateAsync } = useAddSignatureMutation(signatureWorkFlowId, ()=> onSuccess('Sucesso ao adicionar novos signatários'), onError);

    const validationSchema = validationSchemaAddSignersForm();
    const defaultValues = defaultValuesAddSigners;

    const onSubmit = (values: FieldValues) => {
        const newsignatories: Signatures[] = values?.signatories?.map((item: Signatures) => {
            return {
                displayName: item.displayName,
                personId: item.personId,
                typeOfRelationship: item.typeOfRelationship,
                typeOfRelationshipDisplay: item.typeOfRelationshipDisplay,
                signatureType: item.signatureType,
                signatureTypeDisplay: item.signatureTypeDisplay,
            };
        });

        const signatories = removeAddedSignersFromPersons(newsignatories, signers);
        mutateAsync(signatories);
        onClose();
    };

    function removeAddedSignersFromPersons(persons: any[], signers: Signatures[]): any[] {
        const signerIds = new Set(signers?.map((signer) => signer?.personId));
        const filteredPersons = persons.filter(
            (person) => person.personId !== undefined && !signerIds.has(person.personId)
        );

        return filteredPersons;
    }

    const personsWithoutAddedSigners = removeAddedSignersFromPersons(persons, signers);

    const handleOnDelete = async (id: string) => {
        try {
            await signatureDeleteAsync(signatureWorkFlowId, id, token!);
            onSuccess('Signatário removido com sucesso');
        } catch (error) {
            onError(error as ApiResponseError);
        }
    };

    return (
        <FormProvider
            validationSchema={validationSchema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}
        >
            <AddSignatureFormWrapper
                onClose={onClose}
                persons={personsWithoutAddedSigners}
                signers={signers}
                onDelete={handleOnDelete}
            />
        </FormProvider>
    );
}
