import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const Location01Icon: FunctionComponent<SvgIconProps> = (props) => {
    const { htmlColor = '#373737' } = props;

    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M6 21.25C5.58579 21.25 5.25 21.5858 5.25 22C5.25 22.4142 5.58579 22.75 6 22.75V21.25ZM18 22.75C18.4142 22.75 18.75 22.4142 18.75 22C18.75 21.5858 18.4142 21.25 18 21.25V22.75ZM18.75 9.5C18.75 11.2065 17.6599 13.4136 16.1547 15.2468C15.4148 16.1481 14.6072 16.9179 13.8465 17.4554C13.0624 18.0094 12.4227 18.25 12 18.25V19.75C12.8898 19.75 13.8438 19.294 14.7121 18.6804C15.6038 18.0504 16.5071 17.1815 17.314 16.1987C18.9026 14.2638 20.25 11.7209 20.25 9.5H18.75ZM12 18.25C11.5925 18.25 10.9595 17.9993 10.171 17.4074C9.409 16.8353 8.59932 16.0178 7.85679 15.0668C6.34675 13.1327 5.25 10.825 5.25 9.11111H3.75C3.75 11.3246 5.09075 13.9614 6.67446 15.9899C7.4788 17.0201 8.38006 17.9385 9.27041 18.6069C10.1343 19.2555 11.095 19.75 12 19.75V18.25ZM5.25 9.11111C5.25 5.48059 8.47857 2.75 12 2.75V1.25C7.78944 1.25 3.75 4.51941 3.75 9.11111H5.25ZM12 2.75C15.4938 2.75 18.75 5.45503 18.75 9.5H20.25C20.25 4.54497 16.2382 1.25 12 1.25V2.75ZM14.25 9C14.25 10.2426 13.2426 11.25 12 11.25V12.75C14.0711 12.75 15.75 11.0711 15.75 9H14.25ZM12 11.25C10.7574 11.25 9.75 10.2426 9.75 9H8.25C8.25 11.0711 9.92893 12.75 12 12.75V11.25ZM9.75 9C9.75 7.75736 10.7574 6.75 12 6.75V5.25C9.92893 5.25 8.25 6.92893 8.25 9H9.75ZM12 6.75C13.2426 6.75 14.25 7.75736 14.25 9H15.75C15.75 6.92893 14.0711 5.25 12 5.25V6.75ZM6 22.75H18V21.25H6V22.75Z"
                    stroke={htmlColor}
                    fill={htmlColor}
                    strokeWidth="0.2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

Location01Icon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    fill: 'none',
};
