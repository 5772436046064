import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { activeConfig } from 'services/config';
import * as Qs from 'qs';

type BaseApiResource = "CreditApi" | "BillingApi" | "SearchApi" | "ChatbotApi";

const instances: Record<string, AxiosInstance> = {};

const createAxiosInstance = async (baseApiURL: BaseApiResource) => {
    const appConfig = await activeConfig();

    const getBaseApi: { [type: string]: string } = {
        "CreditApi": appConfig.CREDIT_API_URL,
        "BillingApi": appConfig.BILLING_API_URL,
        "SearchApi": appConfig.SEARCH_API_URL,
        "ChatbotApi": appConfig.CHATBOT_API_URL
    }

    const axiosParams: AxiosRequestConfig = {
        baseURL: getBaseApi[baseApiURL],
        responseType: 'json' as const,
        paramsSerializer: (params: any) => Qs.stringify(params, { arrayFormat: 'repeat' }),
    };

    return axios.create(axiosParams);
};

const getAxiosInstance = async (baseApiURL: BaseApiResource) => {
    if (!instances[baseApiURL]) {
        instances[baseApiURL] = await createAxiosInstance(baseApiURL);
    }

    return instances[baseApiURL];
};

const getAxiosApi = (baseApiURL: BaseApiResource) => { 
    return {
        get: <T>(url: string, config?: AxiosRequestConfig) => {
            return getAxiosInstance(baseApiURL).then((instance) => instance.get<T>(url, config));
        },
        post: <T>(url: string, body: unknown, config?: AxiosRequestConfig) => {
            return getAxiosInstance(baseApiURL).then((instance) => instance.post<T>(url, body, config));
        },
        patch: <T>(url: string, body: unknown, config?: AxiosRequestConfig) => {
            return getAxiosInstance(baseApiURL).then((instance) => instance.patch<T>(url, body, config));
        },
        put: <T>(url: string, body: unknown, config?: AxiosRequestConfig) => {
            return getAxiosInstance(baseApiURL).then((instance) => instance.put<T>(url, body, config));
        },
        delete: <T>(url: string, config?: AxiosRequestConfig) => {
            return getAxiosInstance(baseApiURL).then((instance) => instance.delete<T>(url, config));
        },
    }
}

export function createAxiosConfig<T>(token: string, params?: T) {
    return {
        headers: { Authorization: `Bearer ${token}` },
        params,
    };
}

export const billingAxiosApi = getAxiosApi("BillingApi"); 
export const creditAxiosApi = getAxiosApi("CreditApi"); 
export const searchAxiosApi = getAxiosApi("SearchApi"); 
export const chatbotAxiosApi = getAxiosApi("ChatbotApi"); 