import { IconButton, Stack } from '@mui/material';
import { CancelIcon, Typography } from '@uy3/web-components';
import { SelectedValues } from 'components/DataTableFilters/CurrentValuesSelected/CurrentValuesSelected';
import { SelectOption } from 'components/Forms/FormFields';
import React from 'react';
import { activeTheme } from 'services/theme';

type SelectedValuesProps = {
  list: SelectOption[] | string[];
  removeFilterSelected: (id: string) => void
}

const theme = activeTheme();
export const SelectedOptionsChip: React.FC<SelectedValuesProps> = ({ list, removeFilterSelected }) => {
  return (
    <Stack direction='row' alignItems='center' gap={1} flexWrap='wrap'>
      {list.map((item, index) => {
        const label = typeof item === 'string' ? item : item?.label;
        const value = typeof item === 'string' ? item : item?.value;
        return <SelectedValues>
          <Typography sx={{ fontSize: '14px' }}>
            {label}
          </Typography>
          <IconButton onClick={() => removeFilterSelected(value as string)}>
            <CancelIcon
              style={{ height: 15, width: 15 }}
              htmlColor={theme.palette.primary.main}
            />
          </IconButton>
        </SelectedValues>
      })}
    </Stack>
  );
}