import { SelectOption } from 'components/Forms/FormFields';
import { DefaultRecordDetails, UploadGet } from 'contexts/apiRequestContext';
import { newFieldsForForm } from 'helpers';
import { defaultValuesCreditNoteForm } from 'pages/CreditNote/CreditNoteForm/CreditNoteSchema';
import { useForm, UseFormSetValue } from 'react-hook-form';
import { Config } from 'services/config/config.interface';
import { PayrollAgreementReadModel } from 'services/payrollAgreement/payrollAgreement.type';
import { ICommissionModel } from 'utils';
import { ComplianceRulesActionType, ComplianceRulesTypeEnum } from '../enums';

export interface ProductFiltersProps {
    page: number;
    size: number;
    name?: string;
    amortizationType?: string;
    initialDate?: string;
    finalDate?: string;
    searchString?: string;
    tenant?: string;
    categoryName?: string;
    isConsiderPostingDeadline?: boolean;
}

export interface BankComission {
    amount: number;
    type: string;
    baseValue: string;
}

export interface CommissionRangeType {
    id: string;
    minimumPrincipalAmount: number;
    maximumPrincipalAmount: number;
    bankComission: BankComission;
}

export interface ProductPrincipalAmountProps {
    minimumPrincipalAmount?: string | number | undefined;
    maximumPrincipalAmount?: string | number | undefined;
}

export interface CreditProductReadModel extends DefaultRecordDetails {
    instrumentType: string;
    name: string;
    categoryName: string;
    templateDoc: UploadGet;
    code: number;
    amortizationType: string;
    amortizationTypeDisplay: string;
    warrantyType: string;
    warrantyTypeDisplay: string;
    numberDaysValidityCustomerRegistration: number;
    viewType: string;
    viewTypeDisplay: string;
    defaultFundId: string;
    defaultFundDisplay: string;
    selfieSignature: boolean;
    selfieDocumentSignature: boolean;
    facialBiometrics: boolean;
    signatureValidation: string;
    signatureValidationStep: boolean;
    defaultCreditAnalysisGroup: number;
    automaticCreditApproval: boolean;
    automaticCreditAnalysisExecution: boolean;
    minimumInterestRate: number;
    maximumInterestRate: number;
    minimumPrincipalAmount: number;
    maximumPrincipalAmount: number;
    cosifBankAccount: string;
    requireGuarantor: boolean;
    bankComission: ICommissionModel;
    commissionRange: CommissionRange[];
    relatedPerson: RelatedPerson[];
    requiredDoc: RequiredDoc[];
    allowOwnFund: boolean;
    maximumValueWithoutDigitalSignature: number;
    instrumentApproval: boolean;
    draftGeneration: boolean;
    useSignaturePortal: boolean;
    signatureType: string;
    automaticComplianceAnalysisExecution: boolean;
    defaultComplianceAnalysisGroup: number;
    automaticComplianceApproval: boolean;
    requireWitness: boolean;
    limitOpToPerson: boolean;
    allowFinanceTaxExemption: boolean;
    debitBankAccount: DebitBankAccount;
    signaturePortalProvider: string;
    useCollateral: boolean;
    defaultCollateralDescription: string;
    openBankAccount: boolean;
    isConsumerCreditNote: boolean;
    maximumAbsAmortizationPeriods: number;
    maximumAbsInterestPeriods: number;
    maximumAmortizationPeriods: number;
    minimumAmortizationPeriods: number;
    minimumEmissionCostInCents: number;
    maximumEmissionCostInCents: number;
    timeToSendToSignatures: number;
    timeLimitToSign: number;
    timeLimitToSubmitApproval: number;
    timeLimitToApprove: number;
    maximumAutomaticLiquidationValueInCents: number;
    financeEmissioncost: boolean;
    financeFinTax: boolean;
    cvmRegistrationNumber: number;
    cvmRegistrationNumberIndex: number;
    cvmRegistrationDate: string;
    maximumValueWithoutSignatureValidation: number;
    notifySigner: boolean;
    unicoTemplateName: string;
    unicoMinimumScoreForSignatureValidation: number;
    manualWarranty: boolean;
    liquidationApprovalTenantName: string;
    liquidationApprovalTenantNameDisplay: string;
    complianceApprovalTenantName: string;
    creditProductModalityId: string;
    creditProductModalityIdDisplay: string;
    creditProductSubModalityId: string;
    creditProductSubModalityIdDisplay: string;
    complianceApprovalTenantNameDisplay: string;
    signatureApprovalTenantName: string;
    signatureApprovalTenantNameDisplay: string;
    warrantyRegistrationOffice: string;
    warrantyRegistrationOfficeDisplay: string;
    paymentFixedCosts: number;
    insuranceCoverAmount: number;
    payrollAgreements: PayrollAgreementReadModel[];
    termStartDate: string | Date;
    termEndDate: string | Date;
    creditNotePostingDeadline: string;
    termStatus: string;
    termStatusDisplay: string;
    complianceRules: IComplianceRules[];
    useReleaseToPaymentTrigger: boolean;
    releaseToPaymentTriggerLimitInDays: number;
}

export interface CommissionRange {
    id: string;
    minimumPrincipalAmount: number;
    maximumPrincipalAmount: number;
    details: CommissionDetail[];
}

export interface CommissionDetail {
    name: string;
    amount: number;
    type: string;
    baseValue: string;
    isCustomerRegistrationFee: boolean;
}

export interface RelatedPerson {
    personId: string;
    personIdDisplay: string;
    discriminator: string;
    typeOfRelationship: string;
    typeOfRelationshipDisplay: string;
    productId?: string;
    participationPercentage?: number;
}
export interface RelatedPersonFull extends RelatedPerson {
    relatedToId: string;
    personDiscriminator: string;
    relatedToIdDisplay: string;
    isSigner: boolean;
}

export type RequiredDoc = {
    productId: string;
    personType: string;
    fileType: string;
    group: number;
    typeOfRelationship: string;
} & DefaultRecordDetails;

export interface DebitBankAccount {
    productId: string;
    fundId: string;
    debitAccountExternalId: string;
    cosifBankAccount: string;
}

export type OptionType = {
    code: number;
} & SelectOption;

export interface BaseEntity {
    id: string;
    code: number;
    description: string;
    updatedAt: string;
}
export interface CreditProductSubModality extends BaseEntity {}
export interface Modality extends BaseEntity {
    creditProductSubModalities: CreditProductSubModality[];
}
export interface ModalityListProps {
    page: number;
    size: number;
    descriptionModality?: string;
}

export interface SubModalityProps {
    page: number;
    size: number;
    descriptionSubModality?: string;
    codeModalite?: number;
}

export interface IComplianceRules {
    complianceRulesType: ComplianceRulesTypeEnum;
    complianceRulesActionType: ComplianceRulesActionType;
}

export const categoryNameOptions = [
    { label: 'FGTS', value: 'FGTS', code: 6 },
    { label: 'FGTS COM SEGURO', value: 'FGTS COM SEGURO', code: 6 },
    { label: 'CCB', value: 'CCB', code: 8 },
    { label: 'CCB CLEAN', value: 'CCB CLEAN', code: 9 },
    { label: 'CCB FINANCIAMENTO VE', value: 'CCB FINANCIAMENTO VE', code: 10 },
    { label: 'CCB PRICE', value: 'CCB PRICE', code: 11 },
    { label: 'CDC', value: 'CDC', code: 12 },
    { label: 'CDC FGTS', value: 'CDC FGTS', code: 13 },
    { label: 'CONSIGNADO PRIVADO', value: 'CONSIGNADO PRIVADO', code: 14 },
    { label: 'CONSIGNADO PÚBLICO', value: 'CONSIGNADO PÚBLICO', code: 15 },
    { label: 'COMPRA DE DÍVIDA', value: 'COMPRA DE DÍVIDA', code: 8 },
    { label: 'PROCESSO TRABALHISTA', value: 'PROCESSO TRABALHISTA', code: 16 },
    { label: 'VEÍCULO', value: 'VEÍCULO', code: 17 },
    { label: 'NOTA COMERCIAL', value: 'NOTA COMERCIAL', code: 1 },
    { label: 'FINANCIAMENTO ESTUDANTIL', value: 'FINANCIAMENTO ESTUDANTIL', code: 18 },
    { label: 'DESCONTO', value: 'DESCONTO', code: 19 },
];

export const termStatusOptionsList = [
    { label: 'Vigente', value: 'Current' },
    { label: 'Futura', value: 'Future' },
    { label: 'Expirada', value: 'Expired' },
    { label: 'Propostas Pendentes', value: 'ProposalPending' },
];

export const updateAmortizationForProduct = (
    product: CreditProductReadModel,
    setValue: UseFormSetValue<any>,
    appConfig: Config,
    getValues?: ReturnType<typeof useForm>['getValues']
): boolean => {
    if (product != null) {
        const formFieldsConfig = appConfig?.FORM_FIELDS;
        const defaultValues = defaultValuesCreditNoteForm(product);
        const productWithDefaultValues = { ...product, ...defaultValues };

        const firstPaymentDate = getValues && getValues('amortization.firstPaymentDate');
        const existingFirstPaymentDate = formFieldsConfig?.amortization?.firstPaymentDate;
        const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();
        const isSiapeOffice =
            warrantyRegistrationOffice === 'siape' &&
            product?.instrumentType === 'PublicPayrollLoan';

        if (firstPaymentDate && (!!existingFirstPaymentDate || isSiapeOffice)) {
            productWithDefaultValues.amortization.firstPaymentDate = firstPaymentDate;

            if (!!existingFirstPaymentDate) {
                existingFirstPaymentDate.DefaultValue = firstPaymentDate;
            }
        }

        const formValueFromConfigAndProduct = newFieldsForForm(
            productWithDefaultValues,
            formFieldsConfig
        );

        console.log('new values merged to form:', formValueFromConfigAndProduct);

        const amortizationType = formValueFromConfigAndProduct?.amortizationType?.toLowerCase();
        const instrumentType = formValueFromConfigAndProduct?.instrumentType?.toLowerCase();
        const isNotCommercialPapper = (amortizationType || instrumentType) !== 'commercialpapper';
        const maxAmmPeriods = formValueFromConfigAndProduct?.maximumAmortizationPeriods;
        const maximumInterestRate = formValueFromConfigAndProduct?.maximumInterestRate;
        const apr = formValueFromConfigAndProduct?.amortization?.apr;

        for (const key in formValueFromConfigAndProduct) {
            const values = formValueFromConfigAndProduct[key];
            if (values !== null) {
                setValue(key, values);
            }
        }

        if (isNotCommercialPapper) {
            const isSacOrPrice = amortizationType === 'sac' || amortizationType === 'price';
            setValue(
                isSacOrPrice ? 'amortization.numberOfPayments' : 'amortization.termInMonths',
                maxAmmPeriods
            );
            setValue('amortization.apr', maximumInterestRate);
        } else {
            setValue('amortization.apr', apr / 100);
        }

        return true;
    }
    return false;
};
