import { Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext';
import { ReactNode } from 'react';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

type CloneRegisterProps = {
    children: ReactNode
    onClose: () => void
}

export const CloneRegister = ({children, onClose}: CloneRegisterProps) => {
    const { submitting } = useFormContext();
    return (
        <Stack spacing={2}>

            {children}

            <Stack
                spacing={2}
                direction="row"
                justifyContent="right"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}>
                    Não, fechar
                </Button>
                <Button
                    variant="contained"
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    type='submit'
                    disabled={submitting}
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>)
}
