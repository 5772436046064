import type { FunctionComponent } from 'react';
import type { DrawerMFAProps } from './DrawerMFA.interface';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import { Drawer } from '../Drawer';
import { Button, CodeField, TextField, Typography } from '../../atoms';
import { TransferIcon } from '../../icons/TransferIcon';

export const DrawerMFA: FunctionComponent<DrawerMFAProps> = ({
  open,
  helperText,
  error,
  anchor,
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Drawer
      title="Confirme a operação"
      description="Digite sua senha"
      open={open}
      anchor={anchor}
      onClose={onClose}
    >
      <Stack direction="column" spacing={4}>
        <TextField
          label="Senha"
          variant="outlined"
          fullWidth
          type="password"
          helperText={helperText}
          error={error}
        />

        <Stack direction="column">
          <Typography variant="xs" color="neutral.dark" lineHeight="24px" mb={1} mt={2}>
            Confirmação de código MFA
          </Typography>
          <Typography variant="xs" color="neutral.dark" lineHeight="24px">
            Informe o código que está presente em seu aplicativo de MFA
          </Typography>
        </Stack>

        <Stack direction="row" spacing={4}>
          <CodeField />
        </Stack>
      </Stack>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent={isMobile ? 'center' : 'flex-end'}
        spacing={3}
        mb={6}
        mt={8}
      >
        <Button
          startIcon={<CancelOutlinedIcon />}
          variant="outlined"
          size="medium"
          fullWidth={isMobile}
        >
          Cancelar
        </Button>
        <Button startIcon={<TransferIcon />} variant="contained" size="medium" fullWidth={isMobile}>
          Confirmar transferência
        </Button>
      </Stack>
    </Drawer>
  );
};
