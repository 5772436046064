import { Stack, styled } from "@mui/material";
import { StepRenderNotificationsEnum } from "services/notifications/enums/notificationsEnum";
import { activeTheme } from "services/theme";

const theme = activeTheme();
type NotificationsBoxType = {
    renderStep: StepRenderNotificationsEnum | undefined
}

const getBackgroundColorByStep = ({ renderStep }: NotificationsBoxType) => {
    switch(renderStep)
    {
        case StepRenderNotificationsEnum.Read: 
           return "#F6F6F6"; 
        case StepRenderNotificationsEnum.Settings: 
           return theme.palette.common.white; 

    }
}

export const NotificationsBox = styled(Stack)<NotificationsBoxType>`
   position: absolute;
  background: ${({ renderStep }) => getBackgroundColorByStep({renderStep})}; 
  border: 1px solid #BABFD0;
  box-shadow: 0px 2px 8px 2px rgba(31, 31, 31, 0.10);
  top: 9.8rem;
  right: 20px;
  width: 400px;
  z-index: 300;
  border-radius: 8px;
  height: calc(100vh - 11rem);
  overflow-y: auto
`

export const ReadNotificationsHeaderBox = styled(Stack)({
    background: theme.palette.common.white,
    padding: '15px 20px', 
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    zIndex: 400
});