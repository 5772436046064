import React from 'react'
import { FormProvider } from 'contexts/formContext'
import { validationSchemaCloneProduct, defaultValuesCloneProduct } from './DefaultValues/CloneProductSchema';
import { FieldValues } from 'react-hook-form';
import { CloneProduct } from './Form/CloneProduct';
import { CloneWithTenant } from './Form/CloneWithTenant';
import { validationSchemaCloneWithTenant, defaultValuesCloneWithTenant } from './DefaultValues/CloneWithTenantSchema';
import { IBase } from 'contexts/apiRequestContext';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { isEmpty } from 'lodash';
import { CloneRegister } from './CloneRegister';

type ResourceType = "product" | "naturalAndLegalPerson" | "fund"

type GenericOption = {
    validationSchema?: any;
    defaultValues?: any;
    component: React.ReactNode
}


type CloneRegisterContainerProps = {
    onSubmit: (values: FieldValues) => void;
    resource: ResourceType;
    onClose: () => void;
    data: IBase
}

export function CloneRegisterContainer({ resource, data, onClose, onSubmit }: CloneRegisterContainerProps) {
    const optionsbyResource: { [type: string]: GenericOption } = {
        "product": {
            defaultValues: defaultValuesCloneProduct(data),
            validationSchema: validationSchemaCloneProduct(data),
            component: <CloneProduct />
        },
        "naturalAndLegalPerson": {
            defaultValues: defaultValuesCloneWithTenant({entity: data, hasDefaultValue: false}),
            validationSchema: validationSchemaCloneWithTenant({entity: data, uniqueTenant: true}),
            component: <CloneWithTenant />
        }, 
        "fund": {
            defaultValues: defaultValuesCloneWithTenant({entity: data, hasDefaultValue: true}),
            validationSchema: validationSchemaCloneWithTenant({entity: data, uniqueTenant: false}),
            component: <CloneWithTenant />
        }, 
    };

    const selectedOption = optionsbyResource[resource!];
    const hasValidationSchema =
        (!isEmpty(selectedOption?.defaultValues) && Object.keys(selectedOption?.defaultValues)?.length > 0)
        &&
        (!isEmpty(selectedOption?.validationSchema) && Object.keys(selectedOption?.validationSchema)?.length > 0);


    return (
        <React.Fragment>

            {hasValidationSchema ? (
                <FormProvider
                    defaultValues={selectedOption.defaultValues}
                    validationSchema={selectedOption.validationSchema}
                    onSubmit={onSubmit}
                >
                    <CloneRegister onClose={onClose}>
                        {selectedOption.component}
                    </CloneRegister>
                </FormProvider>
            ) : (
                <GenericActionHandler
                    handleSubmit={() => onSubmit(data)}
                    onClose={onClose}
                    isModal
                    titleCancel='Não, cancelar'
                    titleConfirm='Sim, confirmar'
                />
            )}
        </React.Fragment>
    )
}
