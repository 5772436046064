import { createContext, useContext, useState } from 'react';

type FilterValuesContextProps = {
    filterValues: {
        filters: {
            [key: string]: string | string[] | Date;
        };
        recordType?: string;
    };
    setFilterValues: (
        filters: { [key: string]: string | Date | string[] },
        recordType?: string
    ) => void;
};

interface FilterValuesProviderProps {
    children: JSX.Element | JSX.Element[];
}

const FilterValuesContext = createContext<FilterValuesContextProps>({
    filterValues: {
        filters: {},
        recordType: ''
    },
    setFilterValues: () => { },
});

export const FilterValuesProvider = ({ children }: FilterValuesProviderProps) => {
    const [filterValues, setFilterValues] = useState<{
        filters: { [key: string]: string | Date | string[] };
        recordType?: string;
    }>({
        filters: {},
        recordType: ''
    });

    return (
        <FilterValuesContext.Provider
            value={{
                filterValues,
                setFilterValues: (filters, recordType) => {
                    setFilterValues({ filters, recordType });
                }
            }}
        >
            {children}
        </FilterValuesContext.Provider>
    );
};

export function useFilterValues(): FilterValuesContextProps {
    const context = useContext(FilterValuesContext);
    const { filterValues, setFilterValues } = context;

    return {
        filterValues,
        setFilterValues,
    };
};
