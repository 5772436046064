import { Grid, Stack } from '@mui/material';
import { ChatLineIcon, CopyIcon, DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid/';
import { iconSx } from 'contexts/apiRequestContext';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { useNavigate } from 'react-router';
import { IInputSettingsReadModel } from 'services/chatbot/types';
import { RowActions } from 'components/RowActions/RowActions';
import { ChatbotFiltersContainer } from 'components/DataTableFilters/ChatbotFilters/ChatbotFiltersContainer';

interface ChatbotListType {
  queryData: any;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setPage: (newPage: number) => void;
  page: number;
  handleAction: (action: string, data: IInputSettingsReadModel) => void
  copyIdentificationCode: (text: string) => void
}

const ChatbotList = ({ queryData, rowsPerPage, setRowsPerPage, setPage, page, handleAction, copyIdentificationCode }: ChatbotListType) => {
  const navigate = useNavigate();
  if (queryData?.type === 'error') return <Error error={queryData} />;

  const columns: GridColDef[] = [
    {
      field: 'tenantName',
      headerName: 'Correspondente',
      hideSortIcons: true,
      minWidth: 120,
      flex: 1,
      editable: false,
    },
    {
      field: 'productName',
      headerName: 'Produto',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'companyName',
      headerName: 'Nome da empresa',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'serviceQueueName',
      headerName: 'Nome da fila',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => row?.serviceQueue?.display,
    },
    {
      field: 'botPhone',
      headerName: 'Telefone',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ value }) => formatPhoneNumber(value),
    },
    {
      field: 'person',
      headerName: 'Pessoa',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => row?.person?.name ?? "N/D",
    },
    {
      field: 'beneficiary',
      headerName: 'Beneficiário',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => row?.beneficiary?.name ?? "N/D",
    },
    {
      field: 'invoiceValue',
      headerName: 'Valor',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: ({ value }) => formatCurrencyInCents(value),
    },
    {
      field: 'actions',
      headerName: 'Ações',
      hideSortIcons: true,
      minWidth: 150,
      editable: false,
      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) => {
        const isUsed = Boolean(row?.used);
        return (
          <Stack
            component={Grid}
            direction={'row'}
            justifyContent="center"
            alignItems="center"
            style={{
              textAlign: 'left',
              marginLeft: '-4px',
            }}
          >
            <RowActions listButtons={
              [
                {
                  action: () => handleAction('delete', row),
                  label: "Excluir",
                  disabled: false,
                  hide: isUsed,
                  icon: <DeleteIcon sx={iconSx} />
                },
                {
                  action: () => handleAction('waQRCode', row),
                  label: "Entrar em contato",
                  disabled: false,
                  hide: isUsed,
                  icon: <ChatLineIcon sx={iconSx} />
                },
                {
                  action: () => navigate(`/chatbot/${row?.id}`),
                  label: "Editar",
                  disabled: false,
                  hide: false,
                  icon: <EditIcon sx={iconSx} />
                },
                {
                  action: () => copyIdentificationCode(row?.identificationCode),
                  label: "Copiar código de identificação",
                  disabled: false,
                  hide: false,
                  icon: <CopyIcon sx={iconSx} />
                },
              ]
            } />
          </Stack>
        );
      }
    },
  ];

  return (
    <DataTable
      filterComponent={{
        showFilters: true,
        componentFilter: (
          <Grid mb={-0.5}>
            <ChatbotFiltersContainer />
          </Grid>
        ),
      }}
      columns={columns}
      rowsPerPage={rowsPerPage}
      rows={queryData !== undefined ? queryData?.data ?? [] : []}
      pageCount={queryData?.totalPages ?? 0}
      page={queryData ? queryData.page ?? 0 : page}
      rowCount={queryData?.totalItems ?? 0}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
    />
  );
};

export default ChatbotList;
