import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PixelGridCircleIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M16.3114 2.97457C15.0059 2.34984 13.5438 2 12 2C10.4562 2 8.99407 2.34984 7.68861 2.97457M16.3114 2.97457C15.7071 3.57792 15.3333 4.41197 15.3333 5.33333C15.3333 7.17428 16.8257 8.66667 18.6667 8.66667C19.588 8.66667 20.4221 8.29285 21.0254 7.68861M16.3114 2.97457C16.9145 2.37238 17.7471 2 18.6667 2C20.5076 2 22 3.49238 22 5.33333C22 6.25292 21.6276 7.08554 21.0254 7.68861M21.0254 7.68861C21.6502 8.99407 22 10.4562 22 12C22 13.5438 21.6502 15.0059 21.0254 16.3114M7.68861 2.97457C7.08554 2.37238 6.25292 2 5.33333 2C3.49238 2 2 3.49238 2 5.33333C2 6.25292 2.37238 7.08554 2.97457 7.68861M7.68861 2.97457C8.29285 3.57792 8.66667 4.41197 8.66667 5.33333C8.66667 7.17428 7.17428 8.66667 5.33333 8.66667C4.41197 8.66667 3.57792 8.29285 2.97457 7.68861M2.97457 7.68861C2.34984 8.99407 2 10.4562 2 12C2 13.5438 2.34984 15.0059 2.97457 16.3114M2.97457 16.3114C2.37238 16.9145 2 17.7471 2 18.6667C2 20.5076 3.49238 22 5.33333 22C6.25292 22 7.08554 21.6276 7.68861 21.0254M2.97457 16.3114C3.57792 15.7071 4.41197 15.3333 5.33333 15.3333C7.17428 15.3333 8.66667 16.8257 8.66667 18.6667C8.66667 19.588 8.29285 20.4221 7.68861 21.0254M7.68861 21.0254C8.99407 21.6502 10.4562 22 12 22C13.5438 22 15.0059 21.6502 16.3114 21.0254M21.0254 16.3114C20.4221 15.7071 19.588 15.3333 18.6667 15.3333C16.8257 15.3333 15.3333 16.8257 15.3333 18.6667C15.3333 19.588 15.7071 20.4221 16.3114 21.0254M21.0254 16.3114C21.6276 16.9145 22 17.7471 22 18.6667C22 20.5076 20.5076 22 18.6667 22C17.7471 22 16.9145 21.6276 16.3114 21.0254"
          stroke={htmlColor}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

PixelGridCircleIcon.defaultProps = {
  viewBox: '0 0 29 25',
  width: '29',
  height: '25',
  fill: 'none',
};
