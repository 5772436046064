import { useBatchAssignmentData } from 'contexts/batchAssignment/batchAssignmentContext';
import { useState } from 'react';
import { RefreshProgress } from 'components/RefreshProgress';
import { batchAssignmentSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { useFundsList } from 'contexts/fundContext';
import { GridSortModel } from '@mui/x-data-grid';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import BatchAssignmentHeader from './BatchAssignmentHeader';
import BatchListWithFilters from './BatchAssignmentListWithFilters/BatchAssignmentListWithFilters';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';

export const BatchAssignmentCreditNoteListContainer = () => {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [orderBy, setOrderBy] = useState<GridSortModel>([]);
    const [selectedStatusOfTable, setSelectedStatusOfTable] = useState<string[]>([]);
    const [drawerActionSteps, setDrawerActionSteps] = useState<string | undefined>(undefined);
    const orderByField =
        orderBy?.map((item) => `${item?.field}_${item?.sort?.toUpperCase()}`) || [];

    const [toast, setToast] = useState<ToastType>(toastState);
    const isActionFGTS = drawerActionSteps === 'FGTSManagement';
    const {
        batchAssignmentData,
        batchAssignmentStatus,
        refetch,
        isFetching,
        batchAssignmentError,
        isLoading
    } = useBatchAssignmentData(
        { page, size: rowsPerPage, orderBy: orderByField[0] },
        undefined,
        !isActionFGTS
    );
    const { fundAutoCompleteProps, data } = useFundsList({ page: 0, size: 5 }, 'always');

    const handleSelectionModelChange = (selectionModel: string[] | any) =>
        setSelectedStatusOfTable(selectionModel);

    let fundAutocompleteData: any = {
        ...fundAutoCompleteProps,
        listOptions:
            data?.data?.map((i: any) => {
                return { label: i.name, value: i.id };
            }) || [],
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    return (
        <GenericErrorBoundary
            status={batchAssignmentStatus}
            error={batchAssignmentError}
            fallback="cessões"
        >
            <Toast toast={toast} setToast={setToast} />

            <BatchAssignmentHeader
                refetch={refetch}
                selectedStatusOfTable={selectedStatusOfTable}
                setSelectedStatusOfTable={setSelectedStatusOfTable}
                setDrawerActionSteps={setDrawerActionSteps}
                setToast={setToast}
                toast={toast}
                batchData={batchAssignmentData}
            />

            {!isLoading&& <RefreshProgress refreshing={isFetching} />}

            <ListSkeleton isLoading={isLoading}>
                <BatchListWithFilters
                    //@ts-ignore
                    batchAssignmentData={batchAssignmentData ?? []}
                    batchAssignmentSelectFilterOptions={batchAssignmentSelectFilterOptions}
                    fundAutocompleteData={fundAutocompleteData}
                    handleSelectionModelChange={handleSelectionModelChange}
                    onChangePage={onChangePage}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    selectedStatusOfTable={selectedStatusOfTable}
                    setOrderBy={setOrderBy}
                    setSelectedStatusOfTable={setSelectedStatusOfTable}
                />
            </ListSkeleton>
        </GenericErrorBoundary>
    );
};
