import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { CreateBatchAssignment } from './CreateBatchAssignment'
import { createBatchAssignmentSchema, createBatchAssignmentDefaultValues } from './CreateBatchAssignmentSchema'
import { FiltersBatchAssigmentParametersProps } from 'services/creditNote/BatchAssignment/BatchAssignment.types'
import { useBatchAssignmentByFiltersMutation } from 'contexts/batchAssignment/batchAssignmentContext'
import { ApiResponseError, handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext'
import { useNavigate } from 'react-router'

type CreateBatchAssignmentContainerProps = {
    onClose: () => void;
    parameters: FiltersBatchAssigmentParametersProps
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const CreateBatchAssignmentContainer = ({ onClose, parameters, setToast }: CreateBatchAssignmentContainerProps) => {
    const navigate = useNavigate();

    const onSuccess = (values: any) => {
        showSuccessToast("Sucesso ao gerar a cessão", "", setToast);
        navigate(`/ccb/cessoes/${values[0]}`);
    }

    const { mutateAsync, isLoading } = useBatchAssignmentByFiltersMutation((values) => onSuccess(values), (error: ApiResponseError) => handleOnError(error, setToast));
    return (
        <div>
            <FormProvider {...{
                defaultValues: createBatchAssignmentDefaultValues,
                validationSchema: createBatchAssignmentSchema(),
                onSubmit(values) {
                    const assignmentCalculation = values?.assignmentCalculation;
                    mutateAsync({
                        assignmentCalculation,
                        creditNoteIds: [], 
                        filters: parameters
                    })
                },
            }}>
                <CreateBatchAssignment
                    isLoading={isLoading}
                    onClose={onClose}
                />
            </FormProvider>
        </div>
    )
}
