import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ChartPieIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 2C13.6445 2 15.2636 2.40557 16.714 3.18079C18.1643 3.956 19.4011 5.07694 20.3147 6.4443C21.2283 7.81166 21.7907 9.38324 21.9519 11.0198C22.113 12.6564 21.8681 14.3075 21.2388 15.8268M12 2V7.03563M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711M21.2388 15.8268C20.6095 17.3462 19.6152 18.6868 18.3439 19.7301C17.0727 20.7734 15.5638 21.487 13.9509 21.8079C12.338 22.1287 10.6709 22.0468 9.09716 21.5694C7.52346 21.092 6.09178 20.2339 4.92894 19.0711M21.2388 15.8268C21.7413 14.6136 22 13.3132 22 12H16.9644C16.9644 12.6519 16.836 13.2975 16.5865 13.8998M21.2388 15.8268C20.7363 17.0401 19.9997 18.1425 19.0711 19.0711L15.5103 15.5103C15.9713 15.0494 16.337 14.5021 16.5865 13.8998M4.92894 19.0711L8.48967 15.5103M8.48967 15.5103C9.06695 16.0876 9.77768 16.5136 10.5589 16.7506C11.3402 16.9876 12.1678 17.0283 12.9685 16.869C13.7692 16.7097 14.5183 16.3554 15.1494 15.8375C15.7804 15.3196 16.2741 14.654 16.5865 13.8998M8.48967 15.5103C7.79539 14.8161 7.32258 13.9315 7.13102 12.9685C6.93947 12.0055 7.03778 11.0073 7.41353 10.1002C7.78927 9.1931 8.42556 8.41777 9.24195 7.87228C10.0583 7.32679 11.0181 7.03563 12 7.03563M16.5865 13.8998C16.8989 13.1455 17.0205 12.3259 16.9405 11.5134C16.8604 10.7009 16.5813 9.92075 16.1277 9.24194C15.6742 8.56314 15.0602 8.00666 14.3402 7.62182C13.6202 7.23697 12.8164 7.03563 12 7.03563"
          stroke={htmlColor}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

ChartPieIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
