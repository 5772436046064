import { Error } from "components/Errors/Error";
import { RefreshProgress } from "components/RefreshProgress";
import React from "react";
import { useCreditList } from "contexts/creditNote/creditContext";
import { useParams } from "react-router";
import OperationList from "./OperationList";
import { GenericListHeader } from "components/GenericListHeader/GenericListHeader";
import { Grid } from "@mui/material";

type OperationsListContainerProps = {
    queryData?: any;
};

export function OperationsListContainer({ queryData }: OperationsListContainerProps) {
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [page, setPage] = React.useState(0);
    const { personId } = useParams();

    const { creditStatus, creditData, error, creditFetch, refetch } = useCreditList({ page, size: rowsPerPage, personId: personId! });
    if (creditStatus === "error") return <Error error={error} />

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    return (
        <>
            <GenericListHeader
                title="Operações"
                titleButton="Atualizar"
                enableAction
                onClick={refetch}
            />
            <Grid mt={-2} mb={2}>
                <RefreshProgress refreshing={creditFetch} />
            </Grid>
            {creditData && <OperationList
                queryData={queryData ?? creditData}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
            />}
        </>
    );
}