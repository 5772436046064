import { Grid, Stack } from '@mui/material';
import { activeTheme } from 'services/theme';
import {
    BankSlipIcon,
    CardButton,
    DiscountIcon,
    EnergyIcon,
    Typography,
} from '@uy3/web-components';

type PaymentTypeProps = 'bankSlip' | 'taxSlip' | 'consumerAccounts' | undefined;

type PaymentProps = {
    paymentOption: PaymentTypeProps;
    setpaymentOption: React.Dispatch<React.SetStateAction<PaymentTypeProps>>;
    description: string;
    title: string;
};

const theme = activeTheme();
export default function PaymentHeader({
    paymentOption,
    setpaymentOption,
    title,
    description,
}: PaymentProps) {
    return (
        <Stack spacing={2}>
            <Grid>
                <Typography variant="xxl">{title}</Typography>
                <Typography variant="h5" mt={0.4} color="neutral.medium">
                    {description}
                </Typography>
                <Stack
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    mt={2}
                    spacing={3}
                >
                    {paymentOption !== 'taxSlip' && paymentOption !== 'consumerAccounts' && (
                        <CardButton
                            contentSx={{ width: 200 }}
                            name="btn-card-bankSlip"
                            title="Boletos de cobrança"
                            description="Compras em geral."
                            icon={<BankSlipIcon htmlColor={theme.palette.primary.main} />}
                            onClick={() => setpaymentOption('bankSlip')}
                        />
                    )}
                    {paymentOption !== 'bankSlip' && paymentOption !== 'consumerAccounts' && (
                        <CardButton
                            contentSx={{ width: 200 }}
                            name="btn-card-taxSlip"
                            title="Tributos"
                            description="Impostos e guias do governo."
                            icon={<DiscountIcon htmlColor={theme.palette.primary.main} />}
                            onClick={() => setpaymentOption('taxSlip')}
                        />
                    )}
                    {paymentOption !== 'bankSlip' && paymentOption !== 'taxSlip' && (
                        <CardButton
                            contentSx={{ width: 200 }}
                            name="btn-card-consumerAccounts"
                            title="Contas de consumo"
                            description="Água, gás, energia e telefone."
                            icon={<EnergyIcon htmlColor={theme.palette.primary.main} />}
                            onClick={() => setpaymentOption('consumerAccounts')}
                        />
                    )}
                </Stack>
            </Grid>
        </Stack>
    );
}
