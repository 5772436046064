import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions'
import React from 'react'
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip'

export const PermissionsActions = () => {
    return (
        <ActionsPermissions
            PermissionResource={<PermissionResource />}
        />
    )
}

const PermissionResource = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Permitir atualizações das permissões no ambiente do operador',
                    name: 'actions.AllowUpdatePermissionsSameTenant'
                }}
            />
        </React.Fragment>

    )
}