import moment from 'moment';
import { string, object } from 'yup';

export function validationSchemaBatchCreditNoteFilter() {
    const int64MaxValue = 9223372036854775807;

    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        personId: string().when("optionFilter", {
            is: "personId",
            then: string().typeError('Tomador inválido.').required('Tomador: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        creditNoteNo: string().when("optionFilter", {
            is: "creditNoteNo",
            then: string().typeError('Número da operação inválido.').required('Número da operação: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        status: string().when("optionFilter", {
            is: "status",
            then: string().typeError('Status inválido.').required('Status: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxAssignmentValue: string().when("optionFilter", {
            is: "maxAssignmentValue",
            then: string().test('is-less-than-max', 'Valor de cessão inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor de cessão precisa ser um número.').required('Valor de cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minAssignmentValue: string().when("optionFilter", {
            is: "minAssignmentValue",
            then: string().test('is-less-than-min', 'Valor de cessão inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor de cessão precisa ser um número.').required('Valor de cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxValue: string().when("optionFilter", {
            is: "maxValue",
            then: string().test('is-less-than-max', 'Valor máximo inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor máximo precisa ser um número.').required('Valor máximo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minValue: string().when("optionFilter", {
            is: "minValue",
            then: string().test('is-less-than-min', 'Valor mínimo inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor mínimo precisa ser um número.').required('Valor mínimo: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string()
                .typeError('Data de início inválido.').required('Data de início: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().typeError('Data final inválido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesBatchCreditNoteFilter = {
    maxValue: 0,
    minValue: 0,
    personId: null,
    optionFilter: 'personId',
    creditNoteNo: null,
    status: null,
    initialDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
};