export const cnpjMask = [
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	"/",
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
];

export const cpfMaskAndSpace = [
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
	/\d/,
];

export const cpfMask = [
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	".",
	/\d/,
	/\d/,
	/\d/,
	"-",
	/\d/,
	/\d/,
];
