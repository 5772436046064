import React from 'react'
import { Grid, IconButton, Stack } from '@mui/material'
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid'
import { BankSlipIcon, Button, CardData, CloseIcon, CompleteIcon, DataTable, InfoIcon, PaymentLinkIcon, PenIcon, ShowIcon, Typography } from '@uy3/web-components';
import { ApiResponseError, ToastType, iconSx, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { Button as MuiButton } from '@mui/material';
import { activeTheme } from 'services/theme';
import { useSignatureResendSignersAsync } from 'contexts/signature/signatureContext';
import './DataGridCustom.css'
import { onCopyText } from 'helpers/methods/OnCopyText';
import { SignatureWorkFlowFull } from 'services/signature/signature.types';

type ViewDetailsSignatureType = {
    rowData: GridValidRowModel;
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    signatureData: SignatureWorkFlowFull;
    signatureRefetch: () => void;
    onSuccess: (type: string) => void; 
    onError: (response: ApiResponseError) => void
    handleOnSubmit: () => void
}

const theme = activeTheme();

export const ViewDetailsSignature: React.FC<ViewDetailsSignatureType> = (props) => {
    const { onClose, rowData, setToast, signatureData, onError, onSuccess, handleOnSubmit} = props;

    const isPedingSignature = ['Aguardando Assinatura'].includes(signatureData?.statusDisplay);

    const { mutateResendSignatureNotification } = useSignatureResendSignersAsync(rowData?.signatureWorkFlowId, () => onSuccess("resend"), onError);

    const handleResendSignatureNotification = async () => await mutateResendSignatureNotification();
   
    async function onCopy(link: string) {

        onCopyText({
            showErrorToast: () => showErrorToast("Ops, houve um erro", "Não foi possível copiar o link de assinatura", setToast),
            showSuccessToast: () => showSuccessToast("Sucesso ao copiar o link de assinatura", "", setToast),
            value: link
        });
    }

    const signersData = signatureData?.signers?.map(signer => {
        return {
            id: signer.id,
            name: signer.displayName,
            status: signer.statusDisplay,
            url: signer?.url,
            signScore: signer?.signScore
        }
    });

    const hasSignersPending = signatureData?.signers.some(item => item.statusDisplay === 'Não Assinado')
    const signaturePortalDisplay = signatureData?.signaturePortalDisplay;

    return (
        <Stack mt={-2} spacing={3}>
            <Stack direction='row' mb={2} alignItems='center' justifyContent='space-between' pb={1} borderBottom='1px solid #EBEFF2'>
                <Stack direction='row' alignItems='center' >
                    <BankSlipIcon /> &nbsp;
                    <Typography fontSize='14px' alignItems='center'>
                        {rowData?.displayName ?? "Ver documento"}
                    </Typography>

                </Stack>
                <Grid>
                    <MuiButton
                        sx={{ ml: 0, mr: -2 }}
                        variant="text"
                        color="primary"
                        size="small"
                        startIcon={<ShowIcon sx={iconSx} />}
                        href={rowData?.tempGetUrl}
                        target="_blank"
                    />
                </Grid>
            </Stack>

            {rowData?.createdAt && rowData?.updatedAt &&
                <CardData
                    listItem={listData(rowData, signaturePortalDisplay)}
                />

            }

            {Object.values(signersData ?? {})?.length > 0 && (
                <>
                    <Typography fontSize='18px' fontWeight={600}>
                        Signatários
                    </Typography>

                    {isPedingSignature && (
                        <Grid display='flex' justifyContent='left' alignItems='center'>
                            <InfoIcon sx={iconSx} htmlColor={theme.palette.warning.dark} /> &nbsp;
                            <Typography variant='md' color={theme.palette.warning.dark}>Existem assinaturas pendentes</Typography>
                        </Grid>
                    )}

                    <div className='dataGridCustom'>
                        <DataTable
                            columns={columnsSigners(onCopy)}
                            rows={signersData ?? []}
                            rowsPerPage={3}
                            setPage={() => { }}
                            setRowsPerPage={() => { }}
                            hideFooter
                            hideFooterPagination
                            enableJumpAction={false}
                            hideFooterSelectedRowCount
                        />
                    </div>

                </>
            )}

            <Stack direction='row' alignItems='center' spacing={2} justifyContent='right'>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                {signatureData?.statusDisplay === 'Aguardando Início' && <Button
                    name="btn-submit-start"
                    variant="contained"
                    onClick={handleOnSubmit}
                    startIcon={
                        <PenIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                >
                    Iniciar assinatura
                </Button>}
                {hasSignersPending && <Button
                    name="btn-submit-resend"
                    variant="contained"
                    onClick={handleResendSignatureNotification}
                    startIcon={
                        <CompleteIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                >
                    Reenviar todos os pendentes
                </Button>}
            </Stack>
        </Stack>
    )
}

const columnsSigners = (onCopy: (link: string) => void): GridColDef[] => {
    return [
        {
            field: 'name',
            headerName: "Nome",
            align: 'left',
            headerAlign: 'left',
            hideSortIcons: true,
            flex: 1,
            editable: false,
        },
        {
            field: 'status',
            headerName: "Status",
            align: 'left',
            headerAlign: 'left',
            hideSortIcons: true,
            flex: 1.5,
            editable: false,
        },
        {
            field: 'signScore',
            headerName: "Score",
            align: 'left',
            headerAlign: 'left',
            hideSortIcons: true,
            editable: false,
            renderCell: ({ value }) => value ?? "-"
        }, 
        {
            field: 'url',
            headerName: "Link",
            align: 'center',
            headerAlign: 'center',
            hideSortIcons: true,
            editable: false,
            renderCell: ({ value }) => {

                return (
                    <>
                        {!!value ?
                            <IconButton onClick={() => onCopy(value)}>
                                <PaymentLinkIcon sx={iconSx} />
                            </IconButton> : "-"
                        }
                    </>
                )
            }
        }
    ]
}

const listData = (rowData: any, signaturePortalDisplay: string) => [
    {
        id: '1',
        title: 'Criado por',
        value: `${rowData?.createdBy?.userIdDisplay ?? 'N/D'}`
    },
    {
        id: '2',
        title: 'Data de criação',
        value: rowData?.createdAt ? `${new Date(rowData?.createdAt).toLocaleDateString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
        })}` : 'N/D'
    },
    {
        id: '2',
        title: 'Data da última atualização',
        value: rowData?.updatedAt ? `${new Date(rowData?.updatedAt).toLocaleDateString('pt-BR', {
            hour: '2-digit',
            minute: '2-digit',
        })}` : 'N/D'
    },
    {
        id: '3',
        title: 'Atualizado por',
        value: rowData?.updatedAt?.userIdDisplay ? `${rowData?.updatedAt?.userIdDisplay ?? 'N/D'}` : 'N/D'
    },
    {
        id: '4',
        title: 'Certificadora',
        value: signaturePortalDisplay ?? 'N/D'
    }
];