import { useMutation } from "@tanstack/react-query";
import { AxiosError, isAxiosError } from "axios";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { readRemessa } from "services/discount/readRemessa";

export function useDiscountReadMutation(onSuccess?: (data: any) => void, onError?: (error: any) => void) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (file: File) => {
            startRequest();
            const { data, status, statusText } = await readRemessa(file!,token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};