import { ESignatureType, ESignatureValidation } from 'utils/enums';
import { BankAccountProductBaseModel } from './generics';
import { mapDataToModelInstance, MappingConfig } from 'helpers/validations/validateAndPreparePayload';

export class BankAccountProductUpdateModel extends BankAccountProductBaseModel {
    relatedPerson?: BankAccountProductRelatedPersonUpdateModel[];
    requiredDoc?: BankAccountProductRequiredDocUpdateModel[];
}

class BankAccountProductRequiredDocUpdateModel {
    id?: string;
    productId?: string;
    personType?: string;
    fileType?: string;
    group?: number;
    typeOfRelationship?: string;
}

class BankAccountProductRelatedPersonUpdateModel {
    id?: string;
    bankAccountProductId?: string;
    personId?: string;
    typeOfRelationship?: string;
    signatureType?: ESignatureType;
    signatureValidation?: ESignatureValidation;
    userId?: string;
    level?: string;
    tenantName?: string;
    relatedToId?: string;
}

type UpdateModel = BankAccountProductRequiredDocUpdateModel | BankAccountProductRelatedPersonUpdateModel;

const modelMapping: MappingConfig<UpdateModel> = {
    relatedPerson: BankAccountProductRelatedPersonUpdateModel,
    requiredDoc: BankAccountProductRequiredDocUpdateModel,
};

type UpdateModelKey = "relatedPerson" | "requiredDoc";

export function mapBankAccountProductUpdateModelData<T>(dataModel: T, mappingKey: UpdateModelKey): T {    
    return mapDataToModelInstance(dataModel, mappingKey, modelMapping) as T;
}
