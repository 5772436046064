import { Alert, FeedbackErro } from '@uy3/web-components';
import Offline from 'components/Offline/Offline';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { Component, ErrorInfo, ReactNode } from 'react';

type ErrorBoundaryState = {
    hasError: boolean;
    error?: Error | null;
    errorInfo?: ErrorInfo | null;
};

type ErrorBoundaryProps = {
    fallback: string;
    typeMessage?: 'modal' | 'textMessage';
    error?: string;
    textButton?: string;
    type?: 'genericError' | 'notFound' | 'temporary' | undefined;
    children: ReactNode;
};

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
        };
    }

    static getDerivedStateFromError(): ErrorBoundaryState {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        this.setState({
            hasError: true,
            error,
            errorInfo,
        });
    }

    render(): ReactNode {
        const { children, fallback, type, textButton = "Voltar para início", typeMessage, error: concatError } = this.props;
        const { hasError, error } = this.state;        

        const mapError = error as any
        const { errorMessage } = mapErrorResponse(mapError);
        const apiError = `Status Code: ${mapError?.response?.status} - ${errorMessage}`
        const defaultMessage = `Houve um erro inesperado em ${fallback}, tente novamente mais tarde, se o erro persistir contate o suporte. ${concatError ?? ""}`;

        const ComponentError = (errorType: string | undefined) => {
            switch (errorType) {
                case 'modal':
                    return (
                        <FeedbackErro
                            open={true}
                            title={defaultMessage}
                            textButton={textButton}
                            type={type ?? 'genericError'}
                            description={error?.message}
                        />
                    );
                case 'textMessage':
                    return <Alert severity="error" text={defaultMessage} />;
                default:
                    return <Offline errorMessage={apiError} highlightedText={fallback} />;
            }
        };

        const showComponentError = ComponentError(typeMessage);
        if (hasError) return <>{showComponentError}</>;

        return children;
    }
}

export default ErrorBoundary;
