import { Stack } from '@mui/material'
import { SendRemessa } from './SendRemessa'
import { FormProvider } from 'contexts/formContext'
import { validationSchemaSendRemessa, defaultValuesSendRemessa } from './SendRemessaSchema'
import { ToastType, UploadGet, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext'
import { SelectOption } from 'components/Forms/FormFields'
import { useSendRemessaMutation } from 'contexts/fundContext'
import { FieldValues } from 'react-hook-form'
import { ResponseErrorSendMessageType } from 'services/remessasVortx/remessasVortx.type'

type SendRemessaContainerProps = {
    onClose: () => void;
    relatedDocs: UploadGet[];
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    refetch: () => void
}

export const SendRemessaContainer: React.FC<SendRemessaContainerProps> = ({ relatedDocs, onClose, setToast, refetch }) => {
    const relatedDocsFiltered = relatedDocs.filter(item => item?.fileName?.endsWith('.xml'));
    const optionsRelatedDocs: SelectOption[] = relatedDocsFiltered.map((item) => {
        return {
            label: item?.displayName,
            value: item?.fileName
        }
    });

    const onSuccess = (data: ResponseErrorSendMessageType) => {
        
        if (data?.id_unico === null || data.id_unico === undefined) {
            return onError(data); 
        }

        const title = "Remessa enviada com sucesso";
        const description = "";
        onClose();
        refetch();
        return showSuccessToast(title, description, setToast)
    }

    const onError = (response: ResponseErrorSendMessageType) => {
        const genericMessage = 'Houve um erro inesperado: Por favor tente novamente mais tarde.';
        const title = response?.Message ?? "Ops, ocorreu um erro";
        const description = response?.Errors ?? genericMessage;
        showErrorToast(title, description, setToast)
    }

    const { mutateAsync } = useSendRemessaMutation(onSuccess, onError);

    const handleSubmit = (values: FieldValues) => {
        mutateAsync(values?.path);
    }

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultValuesSendRemessa}
                validationSchema={validationSchemaSendRemessa()}
                onSubmit={handleSubmit}
            >
                <SendRemessa {...{
                    isFetching: false,
                    onClose,
                    optionsRelatedDocs
                }} />
            </FormProvider>
        </Stack>
    )
}
