import { Stack } from '@mui/material';
import DetailsBaseForm from './DetailsBaseForm';

export default function ExecutorForm() {
    return (
        <Stack spacing={3}>
            <DetailsBaseForm prefix="details.executor" />
        </Stack>
    );
}
