import React from 'react';
import { useTheme } from '@mui/material';
import { CardCheckboxProps } from './CardCheckbox.interfaces';
import { CustomGrid } from './CardCheckbox.styles';
import { Checkbox, Typography } from '../../atoms';

export const CardCheckbox: React.FC<CardCheckboxProps> = ({ title, checked, onClick }) => {
  const theme = useTheme();

  return (
    <CustomGrid
      id="custom-box"
      onClick={onClick}
      container
      borderColor="neutral.dark"
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        color: checked ? theme.palette.primary.main : theme.palette.common.black,
      }}
    >
      <Typography variant="md">{title}</Typography>
      <Checkbox checked={!!checked} inputProps={{ 'aria-label': 'controlled' }} />
    </CustomGrid>
  );
};
