import { Stack } from '@mui/material'
import { AddIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'

type CreditorsHeaderProps = {
    refetch: () => void;
    handleAddCreditor: () => void;
}

export const CreditorsHeader: React.FC<CreditorsHeaderProps> = ({
    refetch, 
    handleAddCreditor
}) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="xxl"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                lineHeight="38.4px"
            >
                Credores
            </Typography>

            <Actions 
                numberOfButtons={3}
                buttonsActionsList={[
                    {
                        enable: true, 
                        label: "Atualizar", 
                        action: refetch, 
                        disabled: false, 
                        icon: <UpdateIcon sx={iconSx}/>
                    }, 
                    {
                        enable: true, 
                        label: "Adicionar credor", 
                        action: handleAddCreditor, 
                        disabled: false, 
                        icon: <AddIcon sx={iconSx}/>
                    }
                ]}
            />
        </Stack>
    )
}
