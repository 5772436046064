import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { ICommissionReadModel, IPaymentPeriodicityReadModel } from '../../creditNote/types/genericTypes';
import { ECalculationType, EPaymentMonth, EPeriodicity, ECalculateByValueType } from 'utils/enums';

class BasicAmortizationCreateModel {
    amortizationType!: string;
    startDate!: string;
    apr!: number;
    includePaymentFixedCosts: boolean = false;
    requestedAmount!: number;
    financeTaxExempted: boolean = false;
    agentCommission!: ICommissionReadModel;
    fiduciaryGuarantee?: number;
    indexer?: string;
    indexerValue?: number;
    calculateByValueType?: ECalculateByValueType;

    constructor(init?: Partial<BasicAmortizationCreateModel>) {
        Object.assign(this, init);
    }
}

class BasicAmortizationOmittedProperties extends BasicAmortizationCreateModel {
    //OBS: Essas propriedades são omitidas na criação do modelo de dados
    fiduciaryGuarantee?: never;
    indexer?: never;
    indexerValue?: never;
    calculateByValueType?: ECalculateByValueType;
}

export class AmortizationCreateModel extends BasicAmortizationCreateModel {
    paymentDay!: number;
    absAmortizationInMonths!: number;
    absInterestInMonths!: number;
    periodicity!: EPeriodicity;
    daysInYear!: number;
    firstPaymentInterest!: boolean;

    constructor(init?: Partial<AmortizationCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class SACCreateModel extends BasicAmortizationCreateModel {
    firstPaymentDate!: string;
    numberOfPayments!: number;
    numberOfInterestPayments!: number;
    calculationType!: ECalculationType;
    paymentPeriodicity!: IPaymentPeriodicityReadModel;
    dueDateOnBusinessDays!: boolean;
    discountMode!: boolean;

    constructor(init?: Partial<SACCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class PriceCreateModel extends SACCreateModel { }

class CleanPriceCreateModel extends AmortizationCreateModel {
    termInMonths!: number;

    constructor(init?: Partial<CleanPriceCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

class ReceivablesPriceCreateModel extends CleanPriceCreateModel {
    constructor(init?: Partial<ReceivablesPriceCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class PriceCoefficientCreateModel extends AmortizationCreateModel {
    dueDateOnBusinessDays!: boolean;
    termInMonths?: number;

    constructor(init?: Partial<PriceCoefficientCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class StudentLoanCreateModel extends AmortizationCreateModel {
    termInMonths?: number;
    fixedPaymentDuringGraduation!: number;
    termsUntilGraduation!: number;
    absAfterGraduation!: number;

    constructor(init?: Partial<StudentLoanCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class FGTSAmortizationCreateModel extends BasicAmortizationOmittedProperties {
    termInMonths!: number;
    paymentMonth!: EPaymentMonth;

    constructor(init?: Partial<FGTSAmortizationCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class CommercialPaperCreateModel extends BasicAmortizationOmittedProperties {
    paymentDay!: number;
    daysInYear!: number;
    firstPaymentInterest!: boolean;
    termInMonths!: number;

    constructor(init?: Partial<CommercialPaperCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

export class DiscountCreateModel {
    amortizationType?: string;
    startDate!: string;
    apr!: number;
    futureValues!: IFutureValue[];

    constructor(init?: Partial<DiscountCreateModel>) {
        Object.assign(this, init);
    }
}

interface IFutureValue {
    dueDate: string;
    payment: number;
    interest: number;
}

export type AmortizationTypes =
    | CommercialPaperCreateModel
    | AmortizationCreateModel
    | SACCreateModel
    | PriceCreateModel
    | CleanPriceCreateModel
    | ReceivablesPriceCreateModel
    | PriceCoefficientCreateModel
    | StudentLoanCreateModel
    | FGTSAmortizationCreateModel
    | DiscountCreateModel;

type Constructor<T> = new () => T;

const amortizationTypeMapping: Record<string, Constructor<AmortizationTypes>> = {
    sac: SACCreateModel,
    price: PriceCreateModel,
    cleanprice: CleanPriceCreateModel,
    pricecoefficient: PriceCoefficientCreateModel,
    receivablesprice: ReceivablesPriceCreateModel,
    studentloan: StudentLoanCreateModel,
    fgts: FGTSAmortizationCreateModel,
    commercialpapper: CommercialPaperCreateModel,
    discount: DiscountCreateModel,
};

export function toAmortizationDataModel<T extends AmortizationTypes>(data: T): T {
    const amortizationType = data.amortizationType?.toLowerCase();
    const AmortizationClass = amortizationTypeMapping[amortizationType!];

    return toDataModel(data as Record<keyof AmortizationTypes, unknown>, AmortizationClass) as T;
}
