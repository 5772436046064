import { string, object } from 'yup';

export function validationSchemaSendRemessa() {
    return object().shape({
        path: string()
            .typeError('Caminho do arquivo precisa ser preenchido.')
            .required("Caminho do arquivo precisa ser preenchido")
    });

};

export const defaultValuesSendRemessa = {
    path: null,
};
