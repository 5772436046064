import { string, object } from 'yup';

export function validationSchemaApproveForm() {
    return object().shape({
        message: string().typeError('Mensagem inválida.').nullable().notRequired()
    });
};

export const defaultValuesApproveForm = {
    message: null
};