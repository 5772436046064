import { Grid, Stack } from '@mui/material'
import { AddIcon, Button, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type InvolvedBankAccountProductHeaderListProps = {
    enableActionAddInvolved: boolean;
    handlerAddInvolved: () => void
};

const theme = activeTheme();
export const InvolvedBankAccountProductHeaderList = ({ enableActionAddInvolved, handlerAddInvolved }: InvolvedBankAccountProductHeaderListProps) => {
    const colorBlack = theme.palette.common.black;

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mt={3} my={4}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Envolvidos
                </Typography>
            </Grid>
            <Grid>
                {enableActionAddInvolved && <Button
                    name='btn-add-involved-list'
                    variant="text"
                    onClick={handlerAddInvolved}
                    sx={{ color: colorBlack }}
                    startIcon={<AddIcon htmlColor={colorBlack} sx={iconSx} />}
                >
                    Adicionar envolvido
                </Button>}
            </Grid>
        </Stack>
    )
};
