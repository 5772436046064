/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, InputAdornment, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import {
    CheckboxFormField,
    SelectFormField,
    SelectOption,
    TextFormField,
} from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useEffect, useState } from 'react';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useBankList } from 'contexts/bankList';
import { cnpjMask, zipCodeMask } from 'utils/constants';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { useFormContext } from 'contexts/formContext';
import { isValidString } from 'helpers/formats/StringFormats';
import { EnumItem } from 'contexts/enumContext';

interface InformationFundTabProps {
    fundId: string;
    UFList: SelectOption[];
    legalPersonList: AutocompleteOptionList;
    tenantList: AutocompleteOptionList;
    setOpen: (open: boolean) => void;
    setIsTemplateDoc: (value: boolean) => void;
    billingSettingAutocomplete: AutocompleteOptionList;
    communicationSettingAutocomplete: AutocompleteOptionList;
    walletAutocomplete: AutocompleteOptionList;
    hasPermissionToCreateBillingAccount: boolean;
    assignmentTypeList: EnumItem[] | undefined
}

const InformationFundTab = ({
    fundId,
    UFList,
    legalPersonList,
    tenantList,
    setIsTemplateDoc,
    setOpen,
    billingSettingAutocomplete,
    communicationSettingAutocomplete,
    walletAutocomplete,
    hasPermissionToCreateBillingAccount, 
    assignmentTypeList
}: InformationFundTabProps) => {
    const { watch, setValue } = useFormContext();
    const [showFtpPassword, setShowFtpPassword] = useState(false);
    const { bankListAutocomplete } = useBankList({ compe: true });
    const isNewFund = fundId === 'novo';
    const hasWalletCode = isValidString(String(watch('walletCode'))); 
    const assignmentTypeValue = Number(watch('assignmentType'));

    const bankAccountType = [
        { label: 'Conta Corrente de Pessoa Física', value: 'NaturalCheckingAccount' },
        { label: 'Poupança de Pessoa Física', value: 'NaturalSavingsAccount' },
        { label: 'Conta Simples de Pessoa Física', value: 'NaturalSimpleAccount' },
        { label: 'Conta Corrente de Pessoa Jurídica', value: 'LegalCheckingAccount' },
        { label: 'Poupança de Pessoa Jurídica', value: 'LegalSavingsAccount' },
    ];

    useEffect(() => {
        if (isNewFund) setValue('allowIndividualAssignment', true);
    }, [isNewFund])

    const optionsSignaturePortalProvider = [
        { label: 'RBM', value: 0 },
        { label: 'ClickSign', value: 1 },
        { label: 'QuickSoft', value: 2 }
    ];

    const optionsBySignature: { [type: number]: SelectOption[] } = {
        0: [
            { label: 'Assinatura Eletrônica', value: 'EletronicSignature' },
            { label: 'Assinatura com Selfie', value: 'SelfieSignature' },
            { label: 'Certificado Digital', value: 'DigitalCertificate' }
        ],
        1: [
            { label: 'Assinatura Eletrônica', value: 'EletronicSignature' },
            { label: 'Assinatura Eletrônica + Documento', value: 'EletronicSignatureAndDocument' },
            { label: 'Assinatura com Selfie', value: 'SelfieSignature' },
            { label: 'Selfie + Documento Oficial', value: 'SelfieDocumentSignature' },
            { label: 'Biometria Facial', value: 'FacialBiometrics' },
            { label: 'Certificado Digital', value: 'DigitalCertificate' }
        ],
        2: [
            { label: 'Assinatura Eletrônica', value: 'EletronicSignature' },
            { label: 'Certificado Digital', value: 'DigitalCertificate' }
        ]
    }

    return (
        <Box mb={10}>
            <Box>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Dados do fundo
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <TextFormField
                                name="registrationNumber"
                                label="CNPJ"
                                fullWidth
                                placeholder="00.000.000/0000-00"
                                disabled={!isNewFund}
                                variant="outlined"
                                required
                                InputProps={{
                                    inputComponent: MaskedInput,
                                    inputProps: { mask: cnpjMask },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                name="name"
                                fullWidth
                                label="Nome"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                name="companyName"
                                label="Razão Social"
                                placeholder="Razão Social"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <AutocompleteField
                                name="administratorPersonId"
                                label="Administrador"
                                displayName="administratorPersonIdDisplay"
                                {...legalPersonList}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                name="email"
                                label="E-mail"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <SelectFormField
                                required
                                name="signaturePortalProvider"
                                label="Certificadora"
                                fullWidth
                                options={optionsSignaturePortalProvider}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                variant="outlined"
                                name="externalId"
                                type="number"
                                label="Código Carteira"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextFormField
                                variant="outlined"
                                name="codeWalletCerc"
                                label="Código CERC"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <CurrencyFormField
                                label="Valor máximo sem assinatura digital"
                                name="maximumValueWithoutDigitalSignature"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                required
                                fullWidth
                                label="Quórum mínimo de signatários"
                                name="signerMinimumQuorum"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Aprovação de crédito
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <AutocompleteField
                                name="creditApproverTenantName"
                                displayName="creditApproverTenantNameDisplay"
                                label="Aprovador de Crédito"
                                {...tenantList}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                name="instrumentApproverTenantName"
                                displayName='instrumentApproverTenantNameDisplay'
                                label="Aprovador de Instrumento"
                                {...tenantList}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Custo de cessão
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <SelectFormField
                                name="assignmentCalculation.mode"
                                label="Modelo de cálculo"
                                fullWidth
                                required
                                options={[
                                    { label: 'Padrão', value: 3 },
                                    { label: 'Valor Inicial', value: 0 },
                                    { label: 'Valor Presente', value: 2 },
                                    { label: 'Valor Presente Parcela', value: 1 },
                                ]}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectFormField
                                name="assignmentCalculation.type"
                                label="Tipo de taxa"
                                fullWidth
                                required
                                options={[
                                    { label: 'Valor Absoluto', value: 0 },
                                    { label: 'Percentual', value: 1 },
                                ]}
                            />
                        </Grid>
                        {watch('assignmentCalculation.type') === 0 ? (
                            <Grid item xs={3}>
                                <CurrencyFormField
                                    label="Custo de cessão"
                                    required
                                    fullWidth
                                    name="assignmentCalculation.amount"
                                    variant="outlined"
                                />
                            </Grid>
                        ) : (
                            <Grid item xs={3}>
                                <PercentageFormField
                                    name="assignmentCalculation.amount"
                                    label="Custo de cessão (%)"
                                    required
                                    fullWidth
                                    variant="outlined"
                                />
                            </Grid>
                        )}

                    </Grid>
                </Stack>
            </Box>
            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Relatório CNAB
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <SelectFormField
                                name="cnabType"
                                label="CNAB"
                                options={[
                                    { label: 'CNAB 400', value: '400' },
                                    { label: 'CNAB 400 - Itaú', value: '400Itau' },
                                    { label: 'CNAB 444', value: '444' },
                                    { label: 'CNAB 500', value: '500' },
                                    { label: 'CNAB 600', value: '600' }
                                ]}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                fullWidth
                                label="Espécie de Título"
                                name="titleSpecies"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                variant="outlined"
                                fullWidth
                                label="Código do cedente"
                                name="assignorCode"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Informações Bancárias
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <SelectFormField
                                name="bankAccount.type"
                                label="Tipo da Conta"
                                options={bankAccountType}
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                name="bankAccount.bankCode"
                                displayName="bankAccount.bankCodeDisplay"
                                label="Banco"
                                {...bankListAutocomplete}
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextFormField
                                fullWidth
                                label="Agência"
                                name="bankAccount.agency"
                                variant="outlined"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextFormField
                                fullWidth
                                label="Dígito da Agência"
                                name="bankAccount.agencyDigit"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0, max: 100 }}
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextFormField
                                fullWidth
                                label="Conta"
                                name="bankAccount.account"
                                variant="outlined"
                                type="number"
                            />
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextFormField
                                fullWidth
                                label="Dígito da Conta"
                                name="bankAccount.accountDigit"
                                variant="outlined"
                                type="number"
                                inputProps={{ min: 0, max: 100 }}
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Endereço
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container columns={10} spacing={3}>
                        <Grid item xs={3}>
                            <TextFormField
                                name="address.zipCode"
                                id="zipCode"
                                label="CEP"
                                variant="outlined"
                                placeholder="00000-000"
                                required
                                fullWidth
                                InputProps={{
                                    inputComponent: MaskedInput,
                                    inputProps: { mask: zipCodeMask },
                                    onChange: (event) => {
                                        let value = event?.currentTarget?.value.replace(/\D/g, '');
                                        if (value.length >= 8) {
                                            queryZipCodeAndFillForm(value, (fieldName, value) => {
                                                setValue(`address.${fieldName}`, value);
                                            });
                                        }
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                name="address.addressName"
                                fullWidth
                                label="Endereço"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                name="address.district"
                                fullWidth
                                label="Bairro"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                name="address.city"
                                fullWidth
                                label="Cidade"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextFormField
                                name="address.number"
                                fullWidth
                                type='number'
                                label="Número"
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <SelectFormField
                                name="address.uf"
                                label="UF"
                                options={UFList}
                                fullWidth
                                variant="outlined"
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextFormField
                                name="address.complement"
                                fullWidth
                                label="Complemento"
                                variant="outlined"
                            />
                        </Grid>
                    </Grid>
                </Stack>
            </Box>

            {hasPermissionToCreateBillingAccount &&
                <Box mt={3}>
                    <Typography
                        variant="h5"
                        color="neutral.dark"
                        sx={{
                            lineHeight: { xs: '24px', md: '24px' },
                            fontWeight: { xs: '700', md: '700' },
                            fontStyle: { xs: 'normal', md: 'normal' },
                        }}
                    >
                        Parametros da gestão de carteira
                    </Typography>
                    
                    <Stack direction="column" mt={2}>
                        <Grid container columns={10} spacing={3}>
                            <Grid item xs={3}>
                                <AutocompleteField
                                    label="Código do convênio"
                                    name="walletCode"
                                    displayName="walletCodeDisplay"
                                    {...walletAutocomplete!}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <AutocompleteField
                                    label="Régua de cobrança"
                                    name="billingSettingId"
                                    required={hasWalletCode}
                                    displayName="billingSettingDisplay"
                                    disabled={!hasWalletCode}
                                    {...billingSettingAutocomplete!}
                                />
                            </Grid>

                            <Grid item xs={3}>
                                <AutocompleteField
                                    label="Régua de comunicação"
                                    name="communicationSettingId"
                                    required={hasWalletCode}
                                    displayName="communicationSettingDisplay"
                                    disabled={!hasWalletCode}
                                    {...communicationSettingAutocomplete!}
                                />
                            </Grid>
                        </Grid>
                    </Stack>
                </Box>}

            <Box mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Modelo de Cessão
                </Typography>

                <Stack direction="column" mt={2}>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <TextFormField
                                fullWidth
                                label="Modelo de contrato de cessão individual"
                                name="templateDoc.fileName"
                                placeholder="Inclua o arquivo de cessão individual"
                                variant="outlined"
                                disabled
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {!isNewFund && (
                                                <InputAdornment position="end">
                                                    <TooltipComponent
                                                        title="Download do Arquivo"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <a
                                                            href={watch(
                                                                'templateDoc.tempGetUrl'
                                                            )}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <CloudDoneIcon />
                                                        </a>
                                                    </TooltipComponent>
                                                </InputAdornment>
                                            )}

                                            <InputAdornment position="end">
                                                <TooltipComponent
                                                    title="Atualizar Arquivo"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <span
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setIsTemplateDoc(true);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <CloudUploadIcon />
                                                    </span>
                                                </TooltipComponent>
                                            </InputAdornment>
                                        </>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextFormField
                                fullWidth
                                label="Modelo de contrato de cessão em lote"
                                name="templateBatchPath.fileName"
                                placeholder="Inclua o arquivo de cessão em lote"
                                disabled
                                variant="outlined"
                                InputProps={{
                                    endAdornment: (
                                        <>
                                            {!isNewFund && (
                                                <InputAdornment position="end">
                                                    <TooltipComponent
                                                        title="Download do Arquivo"
                                                        placement="top"
                                                        arrow
                                                    >
                                                        <a
                                                            href={watch(
                                                                'templateBatchPath.tempGetUrl'
                                                            )}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <CloudDoneIcon />
                                                        </a>
                                                    </TooltipComponent>
                                                </InputAdornment>
                                            )}

                                            <InputAdornment position="end">
                                                <TooltipComponent
                                                    title=" Atualizar Arquivo"
                                                    placement="top"
                                                    arrow
                                                >
                                                    <span
                                                        onClick={() => {
                                                            setOpen(true);
                                                            setIsTemplateDoc(false);
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        <CloudUploadIcon />
                                                    </span>
                                                </TooltipComponent>
                                            </InputAdornment>
                                        </>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid container item>
                            <CheckboxFormField
                                color="primary"
                                label="Coleta de assinatura"
                                name="collectAssignmentSignatures"
                                size="medium"
                            />
                        </Grid>

                        {watch('collectAssignmentSignatures') === true && (
                            <>
                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="signatureType"
                                        label="Forma de envio"
                                        fullWidth
                                        required={
                                            watch('collectAssignmentSignatures') === true ? true : false
                                        }
                                        options={[
                                            { label: 'Email', value: 0 },
                                            { label: 'Sms', value: 1 },
                                            { label: 'Whatsapp', value: 2 },
                                        ]}
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <CurrencyFormField
                                        required
                                        variant="outlined"
                                        fullWidth
                                        label="Valor máximo sem assinatura digital"
                                        name="maximumValueWithoutDigitalSignature"
                                    />
                                </Grid>

                                <Grid item xs={3}>
                                    <SelectFormField
                                        name="signaturePortalProvider"
                                        label="Certificadora"
                                        fullWidth
                                        required={
                                            watch('collectAssignmentSignatures') === true ? true : false
                                        }
                                        options={optionsSignaturePortalProvider}
                                    />
                                </Grid>
                                {watch('signaturePortalProvider') !== null && (
                                    <Grid item xs={3}>
                                        <SelectFormField
                                            name="signatureValidation"
                                            label="Tipo de Assinatura"
                                            fullWidth
                                            required={
                                                watch('collectAssignmentSignatures') === true
                                                    ? true
                                                    : false
                                            }
                                            options={optionsBySignature[watch('signaturePortalProvider')]}
                                        />
                                    </Grid>
                                )}
                            </>
                        )}
                        <Grid item xs={3}>
                            <SelectFormField
                                name="assignmentType"
                                label="Tipo de Cessão"
                                required
                                fullWidth
                                options={assignmentTypeList}
                            />
                        </Grid>
                        {assignmentTypeValue === 1 && (
                            <Grid item xs={3}>
                                <TextFormField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    label="Host-FTP"
                                    name="ftpHostName"
                                />
                            </Grid>
                        )}
                        {assignmentTypeValue === 1 && (
                            <Grid item xs={3}>
                                <TextFormField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    label="Usuário-FTP"
                                    name="ftpUser"
                                />
                            </Grid>
                        )}
                        {assignmentTypeValue === 1 && (
                            <Grid item xs={3}>
                                <TextFormField
                                    type={showFtpPassword ? 'text' : 'password'}
                                    fullWidth
                                    label="Senha-FTP"
                                    name="ftpPassword"
                                    variant="outlined"
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() =>
                                                        setShowFtpPassword(!showFtpPassword)
                                                    }
                                                >
                                                    {showFtpPassword ? (
                                                        <VisibilityIcon />
                                                    ) : (
                                                        <VisibilityOffIcon />
                                                    )}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        )}
                        {assignmentTypeValue === 1 && (
                            <Grid item xs={3}>
                                <TextFormField
                                    fullWidth
                                    label="Caminho do Arquivo Lastro"
                                    name="pathLastro"
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                        {assignmentTypeValue === 1 && (
                            <Grid item xs={3}>
                                <TextFormField
                                    fullWidth
                                    label="Caminho do Arquivo CNAB"
                                    name="pathCnab"
                                    variant="outlined"
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </Box>

            <Grid container item>
                <CheckboxFormField
                    color="primary"
                    checked={isNewFund ? true : watch('allowIndividualAssignment')}
                    label="Permitir Cessão Individual"
                    name="allowIndividualAssignment"
                    size="medium"
                />
            </Grid>
        </Box>
    );
};

export default InformationFundTab;
