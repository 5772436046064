import { FormProvider } from 'contexts/formContext';
import { NaturaPersonAndLegalPersonFilter } from './NaturaPersonAndLegalPersonFilter';
import { defaultValuesNaturaPersonAndLegalPersonFilterSchema, validationNaturalPersonAndLegalPersonFilterSchemaSchema } from './NaturaPersonAndLegalPersonFilterSchema';
import { useFilters } from 'contexts/filterContext';
import { FieldValues } from 'react-hook-form';

type NaturalPersonAndLegalPersonFiltersContainerProps = {
    isNaturalPerson?: boolean;
}

export const NaturalPersonAndLegalPersonFiltersContainer = ({ isNaturalPerson = false }: NaturalPersonAndLegalPersonFiltersContainerProps) => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const onSubmitSearch = (values: FieldValues) => {
        if (values.optionFilter === "initialDate") {
            addFilter(values.optionFilter, values[values.optionFilter], new Date(values["finalDate"]));
        } else {
            addFilter(values.optionFilter, values[values.optionFilter]);
        }
    };

    const schema = validationNaturalPersonAndLegalPersonFilterSchemaSchema();
    const defaultValues = defaultValuesNaturaPersonAndLegalPersonFilterSchema;

    return (
        <>
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmitSearch}
            >
                <NaturaPersonAndLegalPersonFilter
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                    isNaturalPerson={isNaturalPerson}
                />
            </FormProvider>
        </>
    )
}
