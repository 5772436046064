import { IUploadModel } from 'contexts/apiRequestContext';
import { IAddressModel, BankAccountCreateOrUpdateModel } from 'utils/interface';

export class NaturalPersonBaseModel {
    phone!: string;
    phoneExtension?: string;
    email!: string;
    name!: string;
    nationality?: string;
    pep?: boolean;
    address!: IAddressModel;
    birthDate!: string;
    civilStatus!: string;
    gender?: string;
    documentType?: string;
    documentNumber?: string;
    documentIssuer?: string;
    documentDate?: string;
    documentExpiration?: string;
    phone2?: string;
    phone2Extension?: string;
    mothersName?: string;
    fathersName?: string;
    placeOfBirthCountry?: string;
    placeOfBirthState?: string;
    typeOfResidence?: string;
    between7And15YearsOld?: number;
    upTo6YearsOld?: number;
    over15YearsOld?: number;
    workplace?: string;
    workplaceCompanyRegistrationNumber?: string;
    employeeNumber!: string;
    occupation!: string;
    natureOfOccupation?: string;
    commercialPhone?: string;
    commercialPhoneExtension?: string;
    netSalary?: number;
    otherIncome?: number;
    admissionDate?: string;
    commercialPhone2?: string;
    commercialPhone2Extension?: string;
    commercialAddress?: IAddressModel;
    economicActivityCodeId?: string;
    spouseIndividualRegistrationNumber?: string;
    spouseDocumentType?: string;
    spouseDocumentNumber?: string;
    spouseDocumentIssuer?: string;
    spouseDocumentDate?: string;
    spouseDocumentExpiration?: string;
    spouseName?: string;
    spouseWorkplace?: string;
    spouseOccupation?: string;
    spouseCommercialPhone?: string;
    spouseCommercialPhoneExtension?: string;
    spouseBirthDate?: string;
    spouseNetSalary?: number;
    spouseOtherIncome?: number;
    allowContactWhatsApp?: boolean;
    allowUpdatesWhatsApp?: boolean;
    uploads?: IUploadModel[];
    bankAccounts?: BankAccountCreateOrUpdateModel[];
    riskRating?: string;
    healthDeclaration?: string;
    department?: string;
    socialName?: string;

    constructor(init?: Partial<NaturalPersonBaseModel>) {
        Object.assign(this, init);
    }
}

export type NaturalPersonFiltersProps = {
    page: number;
    size: number;
    searchString?: string;
    pendingPreApprove?: boolean;
    registrationNumber?: string;
    personDisplay?: string;
    email?: string | boolean;
    initialDate?: string;
    finalDate?: string;
    orderByField?: string;
    orderBy?: string;
    orderByDirection?: string;
    tenant?: string | undefined;
};