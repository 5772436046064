import { Stack } from '@mui/system';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useApiRequest } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import { activeTheme } from 'services/theme';

type BankAccountOperatorFormProps = {
    listOperator: AutocompleteOptionList;
    onClose: () => void;
};

const theme = activeTheme();
export function BankAccountOperatorForm({ listOperator, onClose }: BankAccountOperatorFormProps) {
    const { submitting } = useApiRequest();
    const { tenantAutoCompleteProps } = useTenant();

    return (
        <Stack spacing={3}>
            <AutocompleteField
                name="tenantName"
                displayName="tenantNameDisplay"
                label="Correspondente"
                required
                {...tenantAutoCompleteProps}
            />
            <AutocompleteField
                name="userId"
                label="Operador"
                displayName="userIdDisplay"
                {...listOperator}
            />

            <SelectFormField
                name="level"
                label="Nível de permissão"
                options={[
                    { label: 'Visualizador', value: 'Viewer' },
                    { label: 'Aprovador em Conjunto', value: 'JointApprover' },
                    { label: 'Aprovador Master', value: 'MasterApprover' },
                    { label: 'Solicitante', value: 'Requester' },
                ]}
            />

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-submit"
                    disabled={submitting}
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    type="submit"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
}
