import {
    ApproveIcon,
    BankSlipIcon,
    BookUploadIcon,
    CancelIcon,
    CompleteIcon,
    DeleteIcon,
    DocExcelIcon,
    InvoiceReceivableIcon,
    MoneyProfit,
    OperatorIcon,
    PaymentLinkIcon,
    RegisterIcon,
    ReportIcon,
    SignatureIcon,
    UpdateIcon,
    WalletIcon
} from '@uy3/web-components';
import { useAppConfig } from 'contexts/appConfig';
import { Actions } from 'components/Actions/Actions';
import { UseMutateFunction } from '@tanstack/react-query';
import { UploadGet, iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { checkActionsPerms } from 'services/permissions';
import { useUserPermissionData } from 'contexts/userContext';
import { FundReadModel } from 'services/fund/types/fundReadModel';
import { useTenant } from 'contexts/tenantContext';

interface ButtonOfActionsBatchProps {
    refetch: () => void;
    setStepAction: (action: string) => void;
    onGenerateFileHemera: () => void;
    onDepositHemera: () => void;
    enableSendFileHemera: boolean; 
    onSubmitSaveDocuments: UseMutateFunction<any, unknown, UploadGet[], unknown>
    fund: FundReadModel | undefined
}

const ButtonOfActionsBatch: React.FC<ButtonOfActionsBatchProps> = ({ 
    refetch, 
    setStepAction, 
    onSubmitSaveDocuments, 
    onDepositHemera, 
    onGenerateFileHemera, 
    enableSendFileHemera, 
    fund
}) => {
    const { watch} = useFormContext();
    const { appConfig } = useAppConfig();
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const { isRootTenancy } = useTenant();
    const enable_use_vortx_fund = appConfig.USE_VORTX_FUND === 1; 
    const enable_use_hemera_fund = appConfig.USE_HEMERA_FUND === 1; 

    const hasActionPermission = (action: string): boolean => {
        if (checkActionsPerms(action, permissionsData, 'BatchAssignment')) {
            return true;
        }
        return false;
    };

    const uploads = watch('uploads') ?? [];

    return (
        <Actions
            numberOfButtons={4}
            buttonsActionsList={[
                {
                    enable: true,
                    action: refetch,
                    label: 'Atualizar',
                    icon: <UpdateIcon />,
                },
                {
                    label: 'Concluir',
                    action: () => setStepAction('finished'),
                    enable: true,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Recalcular',
                    action: () => setStepAction('recalculate'),
                    enable: isRootTenancy,
                    icon: <SignatureIcon />,
                },
                {
                    label: 'Salvar documentos',
                    action: () => onSubmitSaveDocuments(uploads),
                    enable: hasActionPermission('UploadDocs'),
                    icon: <InvoiceReceivableIcon />
                },
                {
                    label: 'Gerar cobrança',
                    action: () => setStepAction('startInstallment'),
                    enable: hasPermission('BillingAccount', 'Create'),
                    icon: <WalletIcon />
                },
                {
                    label: 'Gerar lastro zip',
                    action: () => setStepAction('generateZip'),
                    enable: true,
                    icon: <PaymentLinkIcon />,
                },
                {
                    label: 'Gerar remessa hemera',
                    action: onGenerateFileHemera,
                    enable: enable_use_hemera_fund,
                    icon: <BookUploadIcon />,
                },
                {
                    label: 'Enviar remessa hemera',
                    action: onDepositHemera,
                    enable: enable_use_hemera_fund,
                    icon: <MoneyProfit />,
                },
                {
                    label: 'Enviar arquivo hemera',
                    action: () => setStepAction('sendHemera'),
                    enable: enableSendFileHemera,
                    icon: <CompleteIcon />,
                },
                {
                    label: 'Gerar arquivo de liquidação',
                    action: () => setStepAction('liquidationFile'),
                    enable: true,
                    icon: <BankSlipIcon />,
                },
                {
                    label: 'Gerar remessa vortx',
                    action: () => setStepAction('remittenceVortx'),
                    enable: enable_use_vortx_fund,
                    icon: <ReportIcon />,
                },
                {
                    enable: enable_use_vortx_fund,
                    label: "Enviar remessa Vortx",
                    action: () => setStepAction('sendRemessaVortx'),
                    icon: <CompleteIcon sx={iconSx} />
                },
                {
                    label: 'Gerar arquivo csv (Angá)',
                    action: () => setStepAction('generateFileAnga'),
                    enable: !!fund && Number(fund.assignmentType) === 3,
                    icon: <DocExcelIcon />,
                },
                {
                    label: 'Gerar CNAB 444',
                    action: () => setStepAction('generateCnab444'),
                    enable: true,
                    icon: <InvoiceReceivableIcon />,
                },
                {
                    label: 'Cancelar',
                    action: () => setStepAction('cancel'),
                    enable: isRootTenancy,
                    icon: <CancelIcon />,
                },
                {
                    label: 'Excluir',
                    action: () => setStepAction('delete'),
                    enable: isRootTenancy,
                    icon: <DeleteIcon />,
                },
                {
                    label: 'Registrar na CERC',
                    action: () =>  setStepAction('cercRegister'),
                    enable: true,
                    icon: <RegisterIcon />,
                },
                {
                    label: 'Atribuir',
                    action: () =>  setStepAction('assign'),
                    enable: hasActionPermission('AssignRecord'),
                    icon: <OperatorIcon />,
                },
            ]}
        />
    );
};
export default ButtonOfActionsBatch;
