import { object, number } from 'yup';

const requiredMessage: string = "Precisa ser preenchido";

export function validationWalletFormSchema() {
    return object().shape({
        register: number().typeError(`Custo de registro: ${requiredMessage}`).required(`Custo de registro: ${requiredMessage}`),
        cancellation: number().typeError(`Custo de baixa: ${requiredMessage}`).required(`Custo de baixa: ${requiredMessage}`),
        discount: number().typeError(`Custo de desconto ${requiredMessage}`).required(`Custo de desconto ${requiredMessage}`),
        changeDueDate: number().typeError(`Custo de alteração de vencimento: ${requiredMessage}`).required(`Custo de alteração de vencimento: ${requiredMessage}`),
        settlement: number().typeError(`Custo de liquidação: ${requiredMessage}`).required(`Custo de liquidação: ${requiredMessage}`),
        debtCollection: number().typeError(`Custo de protesto: ${requiredMessage}`).required(`Custo de protesto: ${requiredMessage}`),
        debtCollectionCancellation: number().typeError(`Custo de sustar protesto: ${requiredMessage}`).required(`Custo de sustar protesto: ${requiredMessage}`),
        debtCollectionSettlement: number().typeError(`Custo de liquidação cartorio: ${requiredMessage}`).required(`Custo de liquidação cartorio: ${requiredMessage}`),
        debtCollectionSuccess: number().typeError(`Custo de baixa protesto: ${requiredMessage}`).required(`Custo de baixa protesto: ${requiredMessage}`),
        transferRegister: number().typeError(`Custo de transferência registro: ${requiredMessage}`).required(`Custo de transferência registro: ${requiredMessage}`),
        transferCancellation: number().typeError(`Custo de transferência baixa: ${requiredMessage}`).required(`Custo de transferência baixa: ${requiredMessage}`),
    });
};

export const defaultValuesWalletForm = {
    register: null,
    debtCollectionSuccess: null,
    cancellation: null,
    discount: null,
    changeDueDate: null,
    debtCollection: null,
    debtCollectionCancellation: null,
    debtCollectionSettlement: null,
    transferRegister: null,
    transferCancellation: null
};
