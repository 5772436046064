import { string, object } from 'yup';

export function validationConfirmMfaFormSchema() {
    return object().shape({
        code: string().typeError('Código MFA: precisa ser preenchido')
            .required('Código MFA: precisa ser preenchido.')    
            .min(6, "Deve possuir 6 dígitos")
            .max(6, "Deve possuir 6 dígitos"),
        password: string().typeError('Senha: Precisa ser preenchido.').required('Senha: Precisa ser preenchido.')
    });
}

export const defaultValuesConfirmMfaForm = {
    password: "",
    code: ""
};