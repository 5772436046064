import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

export const StyledLink = styled(Link)(({ theme, color }) => ({
// 
  fontSize: theme.typography.sm!.fontSize ?? '1rem',
  textDecoration: 'none',
  color,
  '&:hover': {
    textDecoration: 'underline',
  },
}));
