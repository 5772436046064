import { number, object, string } from "yup";

export const basicInformationSchemaValidation = (discriminator: string) => {
    const registrationNumberLabel = discriminator === 'LegalPerson' ? 'CNPJ' : 'CPF';

    const base = {
        name: string().required('Nome: precisa ser preenchido').typeError('Nome: precisa ser preenchido'),
        email: string().required('Email: precisa ser preenchido').typeError('Email: precisa ser preenchido').email('Email inválido'),
        phone: string().required('Telefone: precisa ser preenchido').typeError('Telefone: precisa ser preenchido').phoneNumberValidation('Telefone inválido'),
        registrationNumber: string()
            .required(`${registrationNumberLabel}: precisa ser preenchido`)
            .typeError(`${registrationNumberLabel}: precisa ser preenchido`)
            .cpfCnpjValidation(`${registrationNumberLabel} inválido`),
        address: object().shape({
            addressName: string().typeError('Endereço inválido.').required("Endereço: Precisa ser preenchido."),
            zipCode: string().typeError('CEP inválido.').required("CEP: Precisa ser preenchido."),
            city: string().typeError('Cidade inválida.').required("Cidade: Precisa ser preenchido."),
            uf: string().typeError('UF inválida.').required("UF: Precisa ser preenchido."),
            district: string().typeError('Bairro inválido.').required("Bairro: Precisa ser preenchido."),
            number: number().typeError('Valor precisa ser um número').required("Número: Precisa ser preenchido."),
            complement: string().nullable()
        })
    }

    if (discriminator === 'LegalPerson') {
        return object().shape({
            ...base,
            companyName: string()
                .required('Razão Social: precisa ser preenchido')
                .typeError('Razão Social: precisa ser preenchido'),
        });
    };

    if (discriminator === 'NaturalPerson') {
        return object().shape({
            ...base
        });
    };
};

export const basicInformationDefaultValues = {
    name: null,
    email: null,
    phone: null,
    registrationNumber: null,
    companyName: null,
}