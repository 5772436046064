import { Stack } from '@mui/material';
import { AddIcon, Alert, Button, LeftIcon, SaveIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import React, { useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { activeTheme } from 'services/theme';
import CategoryList from './CategoryList';

interface CreateOrEditCategoriesProps {
    onClose: () => void;
    isLoading: boolean
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
const CreateOrEditCategories: React.FC<CreateOrEditCategoriesProps> = ({ onClose, isLoading }) => {
    const [showAlert, setShowAlert] = useState(false);
    const { control, setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'categories',
    });

    const watchCategories = watch('categories') ?? [];
    const controlledFields = fields.map((field, index) => {
        return {
            ...field,
            ...watchCategories[index],
        };
    });

    const handleRemove = (index: number) => {
        if (watchCategories.length === 1) {
            setShowAlert(true);
        } else {
            remove(index);
        }
    };

    const handleOnClick = () => append('');

    const handleClose = () => {
        setValue('categories', []);
        onClose();
    };

    return (
        <Stack spacing={4}>
            {showAlert && (
                <Alert
                    severity="info"
                    text="Não é possível remover a última opção. Pelo menos uma opção deve ser mantida."
                    variant="filled"
                />
            )}

            <CategoryList fields={controlledFields} remove={handleRemove} />

            <Button
                sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 600,
                    fontSize: '15px',
                }}
                variant="text"
                onClick={handleOnClick}
                startIcon={<AddIcon htmlColor={colorMain} sx={iconSx} />}
            >
                Adicionar nova categoria
            </Button>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    startIcon={<LeftIcon htmlColor={colorMain} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={handleClose}
                >
                    Voltar
                </Button>
                <Button
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    type="submit"
                    name="btn-submit"
                    disabled={isLoading}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
export default CreateOrEditCategories;
