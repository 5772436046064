import React from 'react'
import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, DeleteIcon } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { BankAccountKeyPixType } from 'services/accounts/bankAccount';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import { iconSx } from 'contexts/apiRequestContext';


type MenagePixKeyListProps = {
    queryData: BankAccountKeyPixType[];
    setRowData: (details: any) => void;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
}

const ManagePixKeyList: React.FC<MenagePixKeyListProps> = ({
    page,
    queryData,
    rowsPerPage,
    setRowData,
    setPage,
    setRowsPerPage
}) => {
    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tipo da chave',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                const typeDisplayLowerCase = row?.typeDisplay?.toString()?.toLowerCase();
                return formatBankAccountManagePixKey(typeDisplayLowerCase, row?.pixKey)
            }
        },
        {
            field: 'createdAt',
            headerName: 'Data de criação',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
            renderCell: (cellvalues) => new Date(cellvalues.value).toLocaleDateString('pt-BR')
        },
        {
            field: 'ownerAt',
            headerName: 'Data de posse ',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
            renderCell: (cellvalues) => new Date(cellvalues.value).toLocaleDateString('pt-BR')
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            align: "center",
            headerAlign: 'center',
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <IconButton onClick={() => setRowData(cellValues.row)}>
                            <DeleteIcon
                                sx={iconSx}
                            />
                        </IconButton>
                    </Stack>
                );
            }
        }
    ];

    return (
        <Grid m={2}>
            <DataTable
                columns={columns}
                getRowId={() => Math.random().toString()}
                rowsPerPage={rowsPerPage}
                rows={queryData !== undefined ? queryData ?? [] : []}
                page={queryData ? queryData.length ?? 0 : page}
                rowCount={queryData?.length ?? 0}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                hideFooter
                enableJumpAction={false}
            />
        </Grid>
    )
}

export default ManagePixKeyList;