/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Stack } from '@mui/material';
import { DeleteIcon, Switch, Typography } from '@uy3/web-components';
import { Fragment, useEffect } from 'react';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';

type BillingsListCreateFormProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
};

export const BillingsListCreateForm = ({ fields, remove }: BillingsListCreateFormProps) => (
    <Fragment>
        <Stack direction="column" spacing={2}>
            {fields.map((field, index) => (
                <Stack key={field.id} direction="row" alignItems="center">
                    <BillingForm key={field.id} index={index} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </Fragment>
);

const BillingForm = ({ index }: { index: number }) => {
    const prefix = `billings.${index}`
    const { setValue, watch } = useFormContext();
    const isDiscountValue = watch(`${prefix}.isDiscount`) ?? false;
    const handleOnChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setValue(`${prefix}.isDiscount`, checked);
    };

    useEffect(() => {
        if(!!isDiscountValue)
            return setValue(`${prefix}.discountLimitDate`, watch(`${prefix}.dueDate`))
    }, [isDiscountValue]);

    return (
        <CustomAccordionContainer
            index={index}
            description={`Cobrança #${index + 1}`}
        >
            <Stack spacing={2}>
                <Grid xs={3}>
                    <TextFormField
                        name={`${prefix}.documentNumber`}
                        label="Número do documento"
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid xs={3}>
                    <CurrencyFormField
                        name={`${prefix}.amount`}
                        label="Valor"
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid xs={3}>
                    <DatePickerFormField
                        name={`${prefix}.dueDate`}
                        label="Data de vencimento"
                        fullWidth
                        required
                    />
                </Grid>
                <Stack direction='row' alignItems='center'>
                    <Switch aria-label="insert-discount" onChange={handleOnChange} checked={watch(`${prefix}.isDiscount`)} />
                    <Typography
                        variant="lg"
                        color="common.black"
                        ml={1}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        Inserir desconto
                    </Typography>
                </Stack>
                {watch(`${prefix}.isDiscount`) &&
                    <Stack spacing={2}>
                        <Grid xs={3}>
                            <CurrencyFormField
                                name={`${prefix}.discount`}
                                label="Desconto"
                                variant="outlined"
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={3}>
                            <DatePickerFormField
                                name={`${prefix}.discountLimitDate`}
                                label="Data limite desconto"
                                fullWidth
                            />
                        </Grid>
                    </Stack>}
            </Stack>
        </CustomAccordionContainer>
    );
};

