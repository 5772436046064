import { Stack } from '@mui/material'
import { Button, CloseIcon, RightIcon } from '@uy3/web-components'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { activeTheme } from 'services/theme'

const theme = activeTheme();
type TransferFormProps = {
    onClose: () => void;
    isLoading: boolean
}
export const TransferForm = ({ onClose, isLoading }: TransferFormProps) => {
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });

    return (
        <Stack spacing={2}>
            <AutocompleteField
                label="Selecione a carteira"
                name="toWalletCode"
                displayName="toWalletCodeDisplay"
                required
                {...walletCodeAutoCompleteProps!}
            />

            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type='submit'
                    disabled={isLoading}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Transferir
                </Button>
            </Stack>
        </Stack>
    )
}
