import { activeConfig } from 'services/config';
import { SAC } from './SAC';
import { DefaultSchemaBasicAmortization, currentDateWithSetHours } from './BasicAmortization';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { ECalculationType, EPeriodicity, ECalculateByValueType } from 'utils/enums';

const appConfig = activeConfig();
let termValue = appConfig.DEFAULT_TERM_VALUE?.toString();

export abstract class Price extends SAC { }

export class DefaultSchemaAmmPrice extends DefaultSchemaBasicAmortization implements Price {
    public amortizationType = 'price';
    public calculateByValueType: ECalculateByValueType | string = ECalculateByValueType[ECalculateByValueType.Gross];
    public firstPaymentDate = toIsoStringWithTimezone(new Date(currentDateWithSetHours));
    public numberOfPayments = parseInt(termValue);
    public numberOfInterestPayments = 0;
    public includePaymentFixedCosts = true;
    public calculationType: ECalculationType | string = ECalculationType[ECalculationType.V365DiasCorridos];
    public paymentPeriodicity = {
        every: 1,
        periodicity: EPeriodicity[EPeriodicity.Monthly]
    };
    public dueDateOnBusinessDays = false;
}
