import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { CustomBox } from 'components/Custom/CustomMUI';
import { useFormContext } from 'contexts/formContext';
import { BankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetails';
import React from 'react';

interface BankInformationFormProps {
    onClose: () => void;
    personDisplay: string;
    isLegalPerson?: boolean;
}

export default function BankInformationForm({
    onClose,
    personDisplay,
    isLegalPerson = false,
}: BankInformationFormProps) {
    const { watch } = useFormContext();
    const name = watch('name') ?? personDisplay;
    const registrationNumber = watch('registrationNumber');

    return (
        <React.Fragment>
            <CardInfo {...{ name, registrationNumber }} />
            <Stack spacing={3} direction="column" my={3}>
                <CustomBox container>
                    <Typography
                        variant="md"
                        fontWeight={600}
                        lineHeight="21.6px"
                        letterSpacing="-0.2px"
                    >
                        Sobre os dados bancários
                    </Typography>
                    <Typography variant="xs">
                        O envio dos dados bancários é obrigatório para seguir com sua solicitação.
                    </Typography>
                </CustomBox>
                <Typography variant="lg" color="common.black">
                    Informações bancárias
                </Typography>
            </Stack>
            <BankDetails isLegalPerson={isLegalPerson} onClose={onClose} />
        </React.Fragment>
    );
}
