import { Paper } from '@mui/material';
import { Button } from '@uy3/web-components';
import React from 'react';

interface UploadDrop {
    accept: string;
    handleFormValues: (file: File) => void
}

export const UploadDropForm: React.FC<UploadDrop> = ({
    accept,
    handleFormValues
}) => {

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            const file = files[0];
            handleFormValues(file);
        }
    };

    return (
        <>
            <Paper sx={baseStyle} onDragOver={handleDragOver} onDrop={handleDrop}>
                <Button variant="text" fullWidth component="label" size='large' name='btn-dropzone-input'>
                    Clique aqui para fazer o carregamento do arquivo
                    <input
                        name='dropzone-input'
                        hidden
                        type="file"
                        accept={accept}
                        onChange={(e) => {
                            const file = e.target.files?.item(0);
                            handleFormValues(file as File);
                        }}
                    />
                </Button>
            </Paper>
        </>
    );
};

export const baseStyle = {
    cursor: 'pointer',
    display: 'block',
    padding: '20px',
    border: '1px dashed #D04D27',
    '&:hover': { border: '1px solid #ccc' },
    boxShadow: '0px 8px 32px rgba(186, 191, 208, 0.32)',
    borderRadius: '8px',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
