import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, InfoIcon } from '@uy3/web-components';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { iconSx } from 'contexts/apiRequestContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import React from 'react'
import { IProductMarginQuery, IVinculoFuncional } from 'services/datasets/datasets.interface'

type SiapeMarginQueryListProps = {
  queryData: IVinculoFuncional[];
  handleShowDetail: (row: IVinculoFuncional) => void;
}

export const SiapeMarginQueryList: React.FC<SiapeMarginQueryListProps> = ({ handleShowDetail, queryData }) => {
  const columns: GridColDef[] = [
    {
      field: 'NmOrgao',
      headerName: 'Nome do órgão',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'CodOrgao',
      headerName: 'Código do órgão',
      hideSortIcons: true,
      minWidth: 120,
      flex: 2,
      editable: false,
    },
    {
      field: 'CnpjOrgao',
      headerName: 'CNPJ do órgão',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => cellValues?.value !== null ? formatDocumentNumber(cellValues?.value) : 'N/D'
    },
    {
      field: 'CdMatricula',
      headerName: 'Matrícula',
      hideSortIcons: true,
      editable: false,
      minWidth: 150,
      flex: 2,
    },
    {
      field: 'VlMargemDisp',
      headerName: 'Valor da margem',
      hideSortIcons: true,
      minWidth: 210,
      editable: false,
      flex: 1,
      renderCell: ({ row }) => {
        const values = row?.Produtos?.map((x: IProductMarginQuery) => x?.VlMargemDisp) as number[];
        const totalValue = values?.reduce((acc, current) => acc + current)
        return formatCurrencyInCents(totalValue);
      }
    },
    {
      field: 'CodSituacaoFuncional',
      headerName: 'Código situação funcional',
      hideSortIcons: true,
      minWidth: 210,
      editable: false,
      flex: 1,
    },
    {
      field: 'DescSituacaoFuncional',
      headerName: 'Descrição de classificação',
      hideSortIcons: true,
      minWidth: 210,
      editable: false,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Ações',
      hideSortIcons: true,
      minWidth: 50,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Stack direction='row' justifyContent="center" alignItems="center">
            <TooltipComponent title="Ver mais" placement='right'>
              <Grid>
                <IconButton onClick={() => handleShowDetail(row as IVinculoFuncional)}>
                  <InfoIcon sx={iconSx} />
                </IconButton>
              </Grid>
            </TooltipComponent>
          </Stack>
        );
      },
    },
  ];
  return (
    <Stack spacing={2}>
      <DataTable
        columns={columns}
        rows={queryData ?? []}
        page={0}
        rowCount={queryData?.length ?? 0}
        rowsPerPage={5}
        setPage={() => { }}
        setRowsPerPage={() => { }}
        getRowId={(x) => x?.CnpjOrgao + x?.CodOrgao + x?.CdUpag + x?.ContaDomicBancario}
      />
    </Stack>
  )
}
