import { Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields'
import { useApiRequest } from 'contexts/apiRequestContext';
import { useGetRejectionReason } from 'contexts/creditNote/creditContext';
import { useFormContext } from 'contexts/formContext'

type ApproveOrDisapproveFormProps = {
  approveOrDesapprove: string;
}

export const ApproveOrDisapproveForm = ({ approveOrDesapprove }: ApproveOrDisapproveFormProps) => {
  const { watch } = useFormContext();
  const { submitting } = useApiRequest();
  const { listReasons } = useGetRejectionReason();

  const isMessageRequired = watch('reasonDisapproval')?.includes('OUTROS_MOTIVOS');
  return (
    <Stack spacing={2} mt={2}>
      {approveOrDesapprove === "approve" ?
        <TextFormField
          variant='outlined'
          fullWidth
          type="text"
          name="message"
          label="Observações"
          margin="dense"
          multiline={true}
          rows={4}
          required={!!isMessageRequired}
        />
        :
        <>

          <SelectFormField
            label='Motivo da Reprovação'
            name='reasonDisapproval'
            required
            multiple
            options={listReasons}
          />
          <SelectFormField
            label='Tipo de bloqueio'
            name='blockType'
            required
            defaultValue={null}
            options={[
              { label: 'Ajustável', value: 'Adjustable' },
              { label: 'Final', value: "Final" },
            ]}
          />
          {watch("blockType") === 'Final' &&
            <DatePickerFormField
              label='Validade do Bloqueio'
              name='blockUntil'
              required
              fullWidth
              disablePast={true}
            />
          }
          <TextFormField
            variant='outlined'
            id="message"
            fullWidth
            type="text"
            name="message"
            label="Observações"
            margin="dense"
            multiline={true}
            rows={4}
            required={!!isMessageRequired}
          />
        </>
      }
      <Button
        variant='contained'
        type='submit'
        disabled={submitting}
      >
        Enviar
      </Button>
    </Stack>
  )
}
