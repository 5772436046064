import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import { formatCurrencyInCents, isNegativeValue } from 'helpers/formats/Currency';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { validationInformation } from 'helpers/formats/StringFormats';
import React, { ReactNode } from 'react';
import { IVinculoFuncional } from 'services/datasets/datasets.interface';

type TooltipWarrantyProductSelectedProps = {
    values: IVinculoFuncional;
};

interface IChildren {
    children: ReactNode;
}


export const TooltipWarrantyProductSelected: React.FC<TooltipWarrantyProductSelectedProps> = ({ values }) => {
    const productLength: number = values?.Produtos?.length ?? 0;

    const ListItem = ({ children }: IChildren) => {
        return <Typography style={{ margin: '5px 0 ', fontSize: '14px' }}>{children}</Typography>;
    };

    return (
        <Stack sx={{ margin: '5px 0' }} spacing={2}>
            <Typography variant="md" fontWeight="bold" textAlign="center">
                Informações
            </Typography>

            <ListItem>
                <SpanCustom>Nome: </SpanCustom>
                {validationInformation(values?.NmOrgao)}
            </ListItem>

            <ListItem>
                <SpanCustom>Código do órgão: </SpanCustom>
                {validationInformation(values?.CodOrgao)}
            </ListItem>

            <ListItem>
                <SpanCustom>CNPJ: </SpanCustom>
                {validationInformation(formatDocumentNumber(values?.CnpjOrgao) ?? 'N/D')}
            </ListItem>

            <ListItem>
                <SpanCustom>Siatuação funcional: </SpanCustom>
                {validationInformation(values?.CodSituacaoFuncional)}: {validationInformation(values?.DescSituacaoFuncional)}
            </ListItem>
            <ListItem>
                <SpanCustom>Código de Classificação: </SpanCustom>
                {validationInformation(values?.CodClassificacao)}
            </ListItem>
            <ListItem>
                <SpanCustom>Descrição de classificação: </SpanCustom>
                {validationInformation(values?.DescClassificacao)}
            </ListItem>

            {productLength > 1 && (
                <Typography variant="md" fontWeight="bold" textAlign="center">
                    Produtos (${productLength})
                </Typography>
            )}
            {productLength > 0 && (
                <>
                    {values?.Produtos?.map((item) => {
                        const isNegative = isNegativeValue(item?.VlMargemDisp);
                        const vlMargemDisp = formatCurrencyInCents(item?.VlMargemDisp);
                        const vlMargem = isNegative ? 'Margem indisponível' : vlMargemDisp;

                        const Render = () => {
                            return (
                                <React.Fragment>
                                    <ListItem>
                                        <SpanCustom>Valor da margem: </SpanCustom>
                                        {validationInformation(vlMargem)}
                                    </ListItem>
                                    <ListItem>
                                        <SpanCustom>Código do Orgão: </SpanCustom>
                                        {validationInformation(item.CdConvenio)}
                                    </ListItem>
                                </React.Fragment>
                            );
                        };
                        return (
                            <React.Fragment>
                                {productLength > 1 ? (
                                    <Stack
                                        sx={{
                                            border: `1.2px solid #262626 `,
                                            borderRadius: 2,
                                            padding: 1,
                                        }}
                                    >
                                        <Render />
                                    </Stack>
                                ) : (
                                    <Render />
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </Stack>
    );
};
