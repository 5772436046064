/* eslint-disable @typescript-eslint/no-redeclare */
import { Stack } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { Button, CardData } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { useBatchAssignmentData } from 'contexts/batchAssignment/batchAssignmentContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

type BatchAssignmentTotalValues = {
  batchAssignmentData: any;
  selectedStatusOfTable: string[] | GridRowId[];
  onClose: () => void;
};

function onlyFieldWithValue(batch: any) {
  return {
    accruedInterest: batch?.accruedInterest,
    initialValue: batch?.initialValue,
    assignmentPrice: batch?.assignmentPrice,
    assignmentCalculationResult: batch?.assignmentCalculationResult,
    presentValue: batch?.presentValue,
  };
}

export const BatchAssignmentTotalValues: React.FC<BatchAssignmentTotalValues> = ({
  selectedStatusOfTable,
  onClose
}) => {
  const size = selectedStatusOfTable?.length > 5 ? selectedStatusOfTable?.length : 5;
  const { batchAssignmentData, isFetching } = useBatchAssignmentData({ page: 0, size });
  const batchData = batchAssignmentData as any
  const listTotalSum = batchData?.data?.filter((batch: CreditNoteReadModel) => {
    return selectedStatusOfTable!?.includes(batch.id);
  })?.map(onlyFieldWithValue);

  const sumField = (list: any[], field: string) => {
    return list?.reduce((accumulator, currentValue) => {
      return accumulator + currentValue[field];
    }, 0);
  };

  const accruedInterestSum = sumField(listTotalSum, 'accruedInterest');
  const initialValueSum = sumField(listTotalSum, 'initialValue');
  const assignmentPriceSum = sumField(listTotalSum, 'assignmentPrice');
  const assignmentCalculationResultSum = sumField(listTotalSum, 'assignmentCalculationResult');
  const presentValue = sumField(listTotalSum, 'presentValue');

  if (isFetching) {
    return <RefreshProgress refreshing={isFetching}/>
  }

  return (
    <Stack mt={2}>
      <CardData
        listItem={[
          {
            id: '1',
            title: 'Total cessão',
            value: formatCurrencyInCents(assignmentPriceSum),
          },
          {
            id: '2',
            title: 'Total inicial',
            value: formatCurrencyInCents(initialValueSum),
          },
          {
            id: '3',
            title: 'Total apropriado de juros',
            value: formatCurrencyInCents(accruedInterestSum),
          },
          {
            id: '4',
            title: 'Total presente',
            value: formatCurrencyInCents(presentValue),
          },
          {
            id: '5',
            title: 'Total Ágio',
            value: formatCurrencyInCents(assignmentCalculationResultSum),
          },
        ]}
      />
      <Button variant='outlined' onClick={onClose}>Fechar</Button>
    </Stack>
  );
};
