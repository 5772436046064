import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const NotificationIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <path
        d="M1.56937 14.2978L0.814516 13.6419H0.814516L1.56937 14.2978ZM2.66845 11.8026L1.67509 11.6875L2.66845 11.8026ZM19.3316 11.8026L20.3249 11.6875L19.3316 11.8026ZM20.4306 14.2978L21.1855 13.6419L20.4306 14.2978ZM18.9005 8.0816L17.9071 8.19668V8.19668L18.9005 8.0816ZM3.09955 8.0816L4.0929 8.19668L3.09955 8.0816ZM15.6838 20.9135C15.8814 20.3978 15.6236 19.8195 15.1079 19.6218C14.5922 19.4241 14.0139 19.682 13.8162 20.1977L15.6838 20.9135ZM8.18376 20.1977C7.98609 19.682 7.4078 19.4241 6.8921 19.6218C6.3764 19.8195 6.11858 20.3978 6.31624 20.9135L8.18376 20.1977ZM18.7123 17.1111H3.28767V19.1111H18.7123V17.1111ZM17.9071 8.19668L18.3382 11.9177L20.3249 11.6875L19.8938 7.96651L17.9071 8.19668ZM3.6618 11.9177L4.0929 8.19668L2.10619 7.96651L1.67509 11.6875L3.6618 11.9177ZM2.32422 14.9537C3.06552 14.1006 3.53157 13.0418 3.6618 11.9177L1.67509 11.6875C1.59109 12.4126 1.29064 13.094 0.814516 13.6419L2.32422 14.9537ZM18.3382 11.9177C18.4684 13.0418 18.9345 14.1006 19.6758 14.9537L21.1855 13.6419C20.7094 13.094 20.4089 12.4126 20.3249 11.6875L18.3382 11.9177ZM3.28767 17.1111C2.19751 17.1111 1.58573 15.8036 2.32422 14.9537L0.814516 13.6419C-1.03051 15.7653 0.454517 19.1111 3.28767 19.1111V17.1111ZM18.7123 19.1111C21.5455 19.1111 23.0305 15.7653 21.1855 13.6419L19.6758 14.9537C20.4143 15.8036 19.8025 17.1111 18.7123 17.1111V19.1111ZM19.8938 7.96651C19.3683 3.43036 15.5496 0 11 0V2C14.5231 2 17.4972 4.6584 17.9071 8.19668L19.8938 7.96651ZM4.0929 8.19668C4.50283 4.6584 7.47691 2 11 2V0C6.45045 0 2.63173 3.43036 2.10619 7.96651L4.0929 8.19668ZM13.8162 20.1977C13.4282 21.2102 12.3444 22 11 22V24C13.1182 24 14.9801 22.7492 15.6838 20.9135L13.8162 20.1977ZM11 22C9.65562 22 8.57184 21.2102 8.18376 20.1977L6.31624 20.9135C7.01987 22.7492 8.8818 24 11 24V22Z"
        fill={htmlColor}
      />
    </SvgIcon>
  );
};

NotificationIcon.defaultProps = {
  viewBox: '0 0 22 24',
  width: '22',
  height: '24'
};
