import { IconButton } from '@mui/material';
import { InfoIcon } from '@uy3/web-components';
import { StyledTooltip } from 'components/Custom/CustomTooltip/Tooltip';
import { iconSx } from 'contexts/apiRequestContext';
import { InfoTooltipContent } from './InfoTooltipContent';
import { TooltipProps } from './Tooltip.interface';

const Tooltip: React.FC<TooltipProps> = ({ title, description }) => {
    return (
        <StyledTooltip
            title={<InfoTooltipContent {...{ title, description }} />}
            placement="right-start"
        >
            <IconButton aria-label="info-tooltip">
                <InfoIcon sx={iconSx} />
            </IconButton>
        </StyledTooltip>
    );
};
export default Tooltip;
