import { styled } from "@mui/material/styles";
import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        fontSize: '14px',
        fontWeight: 400,
        padding: '8px 16px',
        borderRadius: '8px',
        maxWidth: 'none',
        
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
}));

