
import { Box, Grid, Paper, Stack } from '@mui/material';
import { Button, Typography, EditIcon, DeleteIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { EnumItem } from 'contexts/enumContext';
import { activeTheme } from 'services/theme';

const styleCard = {
  display: 'block',
  border: '1px solid #E0E3EA',
  borderLeft: '2px solid orangeRed',
  margin: '0 10px 10px 0',
  borderRadius: '5px',
  width: '220px',
  height: '500px',
  padding: '1em'
};

type RequiredDocumentsTabProps = {
  openDrawer: (indexNumber?: number | undefined) => void;
  typeOfDocument: EnumItem[];
  onDelete: (index: number) => void;
  name: string;
};

const theme = activeTheme();
export const RequiredDocumentsList = ({ name, openDrawer, typeOfDocument, onDelete }: RequiredDocumentsTabProps) => {
  const { watch } = useFormContext();
  
  const queryData = watch(name) ?? [];

  const getFileTypeDisplay = (file: string) => {
    return typeOfDocument?.filter((p: any) => p?.value === file)[0]?.label
  };

  const getPersonType = (file: string) => {
    return [
      { label: 'Pessoa Física', value: 'Natural' },
      { label: 'Pessoa Jurídica', value: 'Legal' },
    ].filter((p: any) => p?.value === file)[0]?.label
  };

  return (<>
    <Paper sx={{ p: 6, mb: 2, boxShadow: 'none', border: '1px solid #E0E3EA', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
      {queryData.length >= 1 ? (
        queryData?.map((item: any, indexNumber: number) => (
          <Grid style={styleCard} position='relative'>
            <Typography fontSize={'14px'} fontWeight={600} color="neutral.medium" lineHeight={2}>
              Agrupamento
            </Typography>
            <Typography fontSize={'17px'} color="neutral.dark" fontWeight={600} lineHeight={2}>
              {item?.group}
            </Typography>
            <Typography fontSize={'14px'} fontWeight={600} color="neutral.medium" lineHeight={2}>
              Tipo de pessoa
            </Typography>
            <Typography fontSize={'17px'} color="neutral.dark" fontWeight={600} lineHeight={2}>
              {getPersonType(item?.personType)}
            </Typography>
            <Typography fontSize={'14px'} fontWeight={600} color="neutral.medium" lineHeight={2}>
              Tipo de documento
            </Typography>
            <Typography fontSize={'17px'} color="neutral.dark" fontWeight={600} lineHeight={2}>
              {getFileTypeDisplay(item?.fileType)}
            </Typography>

            <Stack
              spacing={1}
              sx={{
                mt: 3,
                position: 'absolute',
                bottom: 15,
                width: '90%',
              }}
            >
              <Button
                variant="outlined"
                size="small"
                onClick={() => openDrawer(indexNumber)}
              >
                <EditIcon htmlColor={theme.palette.primary.main} /> &nbsp; Editar
              </Button>
              <Button
                variant="outlined"
                size="small"
                onClick={() => onDelete(indexNumber)}
              >
                <DeleteIcon htmlColor={theme.palette.primary.main} /> &nbsp; Excluir
              </Button>
            </Stack>
          </Grid>))
      ) :
        <Box
          flex={1}
          textAlign='center'
          mt={6}
          mb={6}
        >
          <Typography variant='h4' fontWeight='bold' color='neutral.medium'>
            Nenhum documento adicionado.
          </Typography>
        </Box>
      }
    </Paper>
  </>
  )
};
