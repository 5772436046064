import { string, object } from 'yup';

export function fundtFiltersSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        name: string().when("optionFilter", {
            is: "name",
            then: string().required('Nome precisa ser preenchida.').typeError('Nome: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string().required('Data de inicio do cadstro precisa ser preenchida.').typeError('Data de inicio do cadstro: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().required('Data final do cadastro precisa ser preenchida.').typeError('Data final do cadastro: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        tenant: string().when("optionFilter", {
            is: "tenant",
            then: string().typeError('Correspondente precisa ser preenchido.').required('Correspondente: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        amortizationType: string().when("optionFilter", {
            is: "amortizationType",
            then: string().typeError('Tipo de amortização precisa ser preenchido.').required('Tipo de amortização: Precisa ser preenchido.'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesFundtFilterSchema = {
    optionFilter: 'searchString',
    name: null,
    amortizationType: null,
    tenant: null,
    initialDate: new Date(), 
    finalDate: new Date(), 
};