import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
export const MainBox = styled(Box)(() => ({
    width: 'fit-content',
    padding: '20px',
    background: theme.palette.common.white,
    backgroundColor: "#ffff",
    borderRadius: '8px',
    maxWidth: '660px',
    '& .rdrInputRanges, .rdrDateDisplayWrapper, .rdrDefinedRangesWrapper': {
        display: 'none'
    },
    '& .rdrDefinedRangesWrapper': {
        borderRight: 'none',
        width: '124px',
        marginRight: '24px'
    },
    '& .rdrStaticRangeLabel': {
        ...theme.typography.caption,
        color: theme.palette.primary.dark,
        lineHeight: '14px',
        padding: '8px 12px'
    },
    '& .rdrStaticRange': {
        borderBottom: 'none',
        marginBottom: '2px',
        '&:hover': {
            '& .rdrStaticRangeLabel': {
                color: theme.palette.primary.contrastText,
                background: 'transparent',
                borderRight: `2px solid ${theme.palette.primary.main}`,
            }
        }
    },
    '& .rdrStaticRangeSelected': {
        color: theme.palette.primary.main,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '4px 0px 0px 4px',
    },
    '& .rdrMonthName': {
        ...theme.typography.sm,
        color: theme.palette.primary.dark,
        textTransform: 'capitalize',
        paddingLeft: '10px'
    },
    '& .rdrWeekDay': {
        ...theme.typography.xs,
        color: theme.palette.common.black,
        textTransform: 'capitalize',
        '&:last-of-type': {
            color: theme.palette.primary.main,
        },
        '&:first-of-type': {
            color: theme.palette.primary.main,
        },
    },
    '& .rdrWeekDays': {
        '&:last-of-type, &:fist-of-type': {
            color: theme.palette.primary.main,
        }
    },
    '& .rdrDayToday .rdrDayNumber span': {
        color: `${theme.palette.info.main}`,
        '&:after': {
            display: 'none'
        }
    },
    '& .rdrStartEdge': {
        borderRadius: '8px 0 0 8px',
        background: theme.palette.primary.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrEndEdge': {
        borderRadius: '0 8px 8px 0',
        background: theme.palette.primary.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrInRange': {
        background: 'transparent',
        '& + .rdrDayNumber span': {
            color: `${theme.palette.primary.main} !important`
        }
    },
    '& .rdrStartEdge.rdrEndEdge': {
        borderRadius: '8px',
        background: theme.palette.info.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrDayPassive .rdrDayNumber span': {
        color: 'transparent !important'
    },
}))