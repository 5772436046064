import { createTheme } from '@mui/material/styles';
import { typography } from './typography';
import { palette } from './palette/dark';
import { components } from './components';

export const dark = createTheme({
  palette,
  typography,
  components
});
