import { ApiResponseError, handleOnError, showSuccessToast, toastState, ToastType, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { useUserPermissionData } from "contexts/userContext";
import { Fragment, useState } from "react";
import { useLocation, useParams } from "react-router";
import { CreatePermissionProps, fromApiDataModel, PermissionModel, postPermissionList } from "services/permissions";
import { defaultValuesPermissionForm, validationPermissionFormSchema } from "./PermissionForm/PermissionFormSchema";
import Toast from "components/Toast/Toast";
import PermissionHeader from "./PermissionHeader";
import { RefreshProgress } from "components/RefreshProgress";
import PermissionTab from "./PermissionTab";
import { Alert, Drawer, Modal } from "@uy3/web-components";
import { FormProvider } from "contexts/formContext";
import { PermissionForm } from "./PermissionForm/PermissionForm";
import { ClonePermissionContainer } from "./ClonePermission/ClonePermissionContainer";
import { GenericActionHandler } from "components/GenericActionHandler/GenericActionHandler";
import { Stack } from "@mui/material";

export const PermissionTabContainer = () => {
    const { pathname } = useLocation();
    const isPathGroup = pathname.includes('grupos');
    const { currentTenantId } = useTenant();
    const { endRequest, setSubmitError, startRequest, submitError, submitting } = useApiRequest();
    const { userId } = useParams();
    const { groupId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);

    const params: any = isPathGroup ? {
        principalId: groupId,
        principalType: "Group"
    } : {
        principalId: userId,
        principalType: "User"
    };

    const [formData, setFormData] = useState<any>(undefined);
    const { token } = useIdentity();

    const onClose = () => {
        setFormData(undefined);
        setAction(undefined)
    };

    const { data: permissionsData, refetch, isFetching } = useUserPermissionData(params!);

    const postPermissionAsync = async (permissionList: PermissionModel[]) => {

        const body: CreatePermissionProps = {
            permissions: permissionList ?? [],
            principalId: isPathGroup ? groupId : userId!,
            principalType: isPathGroup ? 'Group' : 'User',
            tenant: currentTenantId
        };

        await postPermissionList(body, token!).then(() => {
            endRequest(true)
            onClose();
            const title = 'Permissões atualizadas com sucesso!';
            const description = undefined;
            showSuccessToast(title, description, setToast);
            return refetch();
        }).catch((error: ApiResponseError) => {
            setSubmitError(error);
            handleOnError(error, setToast);
        })
    }

    const onAddedOrEditPermission = async (values: any) => {
        startRequest();
        let formValues = {
            createPermission: values?.createPermission,
            deletePermission: values?.deletePermission,
            readPermission: values?.readPermission,
            resource: values?.resource,
            resourceDisplay: values?.resourceDisplay,
            updatePermission: values?.updatePermission,
            actions: values?.actions
        };

        let index = permissionsData?.map((p) => p?.resource).indexOf(values?.resource);
        let updatedPermissions = [...permissionsData!]
        updatedPermissions[index!] = formValues;

        await postPermissionAsync(updatedPermissions);
    };

    const clearAllPermission = async () => {
        const list = permissionsData?.map(permission => {
            return {
                ...permission,
                actions: defaultValuesPermissionForm.actions, 
                createPermission: "None", 
                deletePermission: "None", 
                readPermission: "None", 
                updatePermission: "None"

            }
        })
        const permissions = fromApiDataModel(list as any[])
        await postPermissionAsync(permissions)
    }

    const schema = validationPermissionFormSchema();

    const isSubmitting = !submitError ? submitting : false;

    const recordType = isPathGroup ? 'Group' : 'User'

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <PermissionHeader refetch={refetch} setAction={setAction} />
            <RefreshProgress refreshing={isFetching} />
            <PermissionTab
                permissionData={permissionsData ?? []}
                setFormData={setFormData}
            />
            <Drawer
                anchor="right"
                title="Permissões do operador"
                description="Defina uma opção para cada tipo de permissão."
                open={formData !== undefined}
                onClose={onClose}
            >
                <>
                    <FormProvider
                        validationSchema={schema}
                        defaultValues={formData}
                        onSubmit={onAddedOrEditPermission}>
                        <PermissionForm
                            submitError={submitError}
                            submitting={isSubmitting}
                            onClose={onClose}
                        />
                    </FormProvider>
                </>
            </Drawer>

            <Drawer
                title='Copiar permissões'
                description={`Selecione abaixo o ${recordType === 'Group' ? 'grupo' : 'operador'} que deseja clonar as permissões.`}
                open={action === 'clone-permission'}
                onClose={onClose}
                anchor="right"
            >
                <ClonePermissionContainer {...{
                    recordType,
                    onClose,
                    setToast,
                    refetch,
                    postPermissionAsync
                }} />
            </Drawer>

            <Modal
                title='Limpar permissões'
                description={`Realmente deseja limpar permissões?`}
                open={action === 'clear-permission'}
                onClose={onClose}
            >
                <Stack>
                    <Stack sx={{m: "20px 0", display: 'block'}}>
                    <Alert severity="warning" text="Atenção: Ao executar esta ação, todas as permissões deste registro serão removidas."/>

                    </Stack>
                    <GenericActionHandler {...{
                        handleSubmit: clearAllPermission,
                        onClose,
                        isModal: true
                    }} />
                </Stack>
            </Modal>
        </>
    );
}