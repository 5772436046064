import { Grid, ListItemAvatar, Stack, SvgIconProps } from '@mui/material'
import { Avatar, Typography } from '@uy3/web-components'
import { CurrencyValueSkeleton } from 'components/Skeleton/CurrencyValueSkeleton';
import { validationInformation } from 'helpers/formats/StringFormats';
import { FunctionComponent } from 'react';

export type ItemProps = {
    value: string;
    title: string;
    Icon?: FunctionComponent<SvgIconProps>;
    isLoading?: boolean;
    disable?: boolean;
    wordBreak?: boolean
}

type DetailsFormProps = {
    list: ItemProps[];
    description?: string
    title?: string;
    isLoading?: boolean
}

export const DetailBillingItem = ({ title, value, Icon, isLoading = false, wordBreak = true }: ItemProps) => {
    const iconSxCustom = { width: 27, height: 27 }
    const textValue = validationInformation(value);
    return (
        <Stack direction='row' alignItems='center' justifyContent='start' sx={{ mb: 4, flexWrap: 'wrap' }}>
            <ListItemAvatar>
                <Avatar sx={{ background: '#E6E6E6' }}>
                    {Icon && <Icon sx={iconSxCustom} htmlColor='#707070' />}
                </Avatar>
            </ListItemAvatar>
            <Stack direction='column' sx={{ wordBreak: wordBreak ? 'break-word' : 'normal', maxWidth: '250px' }}>
                <Typography variant='sm' fontWeight='500'>
                    {title}
                </Typography>
                <Typography variant='sm' color='#707070'>
                    {isLoading ? <CurrencyValueSkeleton sx={{ mt: 0 }} width='250px' /> : textValue}
                </Typography>
            </Stack>
        </Stack>
    )
}

export const DetailsForm = ({ list, title, isLoading, description}: DetailsFormProps) => {
    return (
        <Stack spacing={2} mt={3}>
            {(!!title || !!description)&& <Grid>
                <Typography
                    variant="xxl"
                    color="neutral.dark"
                    sx={{ lineHeight: '24px', fontWeight: '700', fontStyle: 'normal' }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h6"
                    color="neutral.medium"
                    sx={{
                        lineHeight: '20px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '16px',
                        opacity: '87%', 
                        mt: 1
                    }}
                >
                    {description}
                </Typography>
            </Grid>}
            <Grid
                spacing={2}
                sx={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', // Define colunas de 400px e ajusta o espaço restante
                    gap: 2,
                    pt: 2
                }}
            > {
                    list.filter(item => item.disable !== true).map((props, indice) => {
                        return (
                            <DetailBillingItem key={indice} isLoading={isLoading!} {...props} />
                        )
                    })
                }
            </Grid>
        </Stack>
    )
}
