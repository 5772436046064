import React, { ReactNode } from 'react';
import { Stack } from '@mui/material';
import AccordionContent from 'components/AccordionContent/AccordionContent';
import { useTenant } from 'contexts/tenantContext';

type ActionsPermissionsProps = {
    PermissionResource?: ReactNode;
    PermissionRootTenancy?: ReactNode;
};

export const ActionsPermissions: React.FC<ActionsPermissionsProps> = ({
    PermissionResource,
    PermissionRootTenancy,
}) => {
    const { isRootTenancy } = useTenant();

    return (
        <React.Fragment>
            {PermissionResource !== undefined && (
                <AccordionContent
                    title="Ações do recurso"
                    expandedIndex={0}
                    content={<Stack margin={3}>{PermissionResource}</Stack>}
                />
            )}

            {isRootTenancy && PermissionRootTenancy !== undefined && (
                <AccordionContent
                    title="Ações exclusivas UY3"
                    expandedIndex={1}
                    content={<Stack margin={3}>{PermissionRootTenancy}</Stack>}
                />
            )}
        </React.Fragment>
    );
};
