/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack } from '@mui/material';
import { Button, PersonIcon, Typography } from '@uy3/web-components';
import { SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useFormContext } from 'contexts/formContext';
import { useEffect } from 'react';
import { useProductAccountData } from 'contexts/bankAccountProduct';
import { activeTheme } from 'services/theme';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { phoneNumberMask, zipCodeMask } from 'utils/constants';

interface IinformationBankAccRequestProps {
    bankAccountId: string;
    bankAccountProductList: AutocompleteOptionList;
    titularList: AutocompleteOptionList;
    UFList: SelectOption[];
    setIsAddPerson: React.Dispatch<React.SetStateAction<boolean>>;
    enableShortcut: boolean;
    legalNatureList: SelectOption[];
    cnaeList: AutocompleteOptionList;
}

const theme = activeTheme();
const InformationBankAccountRequestTab = ({
    bankAccountId,
    bankAccountProductList,
    titularList,
    setIsAddPerson,
    enableShortcut,
    UFList,
    legalNatureList,
    cnaeList,
}: IinformationBankAccRequestProps) => {
    const { watch, readOnly, setValue } = useFormContext();
    const [ownerDiscriminator, ownerId, bankAccountProductId] = watch([
        'ownerDiscriminator',
        'ownerId',
        'bankAccountProductId'
    ]);

    const { productAccData } = useProductAccountData(bankAccountProductId!);
    const isLegalPerson = ownerDiscriminator === 'LegalPerson';

    const handleOwnerLink = (): string => {
        if (isLegalPerson) {
            return `/pessoas-juridicas/todas/${ownerId}`;
        } else return `/pessoas-fisicas/todas/${ownerId}`;
    };

    const typeAccount = [
        { label: 'Conta Livre Movimentação', value: 'Payment' },
        { label: 'Conta Escrow', value: 'Escrow' },
        { label: 'Escrow +', value: 'EscrowPlus' },
        {
            label: 'Escrow Multicredores',
            value: 'EscrowMulticreditors',
        },
    ];

    const bankingAccountType = productAccData?.bankingAccountType;
    useEffect(() => {
        if (bankAccountProductList?.listOptions?.length > 0) {
            const selectedProduct = bankAccountProductList.listOptions.find(
                (product) => product.value === bankAccountProductId
            );

            if (selectedProduct?.value && bankingAccountType) {
                setValue('type', bankingAccountType);
            }
        }
    }, [bankingAccountType]);

    return (
        <>
            <Box py={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                    }}
                >
                    Dados da Solicitação de Conta Bancária
                </Typography>
                <Stack direction="column" mt={2}>
                    <Grid container spacing={3}>
                        {bankAccountId !== 'nova' && (
                            <Grid item xs={4}>
                                <TextFormField
                                    name="tenantDisplay"
                                    fullWidth
                                    variant="outlined"
                                    label="Correspondente"
                                    disabled={true}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <AutocompleteField
                                name="bankAccountProductId"
                                label="Produto"
                                displayName="bankAccountProductIdDisplay"
                                endIconType="link"
                                link={`/cadastro/produtos-conta/${watch('bankAccountProductId')}`}
                                showEndIcon={watch('bankAccountProductId')}
                                required
                                disabled={readOnly}
                                {...bankAccountProductList}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                name="partnerName"
                                fullWidth
                                label="Nome do parceiro"
                                variant="outlined"
                                required
                                disabled={readOnly}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AutocompleteField
                                name="ownerId"
                                label="Titular"
                                displayName="ownerDisplay"
                                required
                                disabled={readOnly}
                                endIconType="link"
                                link={handleOwnerLink()}
                                showEndIcon={watch('ownerId')}
                                {...titularList!}
                            />
                            {enableShortcut && (
                                <Grid
                                    container
                                    sx={{ justifyContent: 'flex-end', display: 'flex' }}
                                >
                                    <Button
                                        name="btn-add-person"
                                        startIcon={
                                            <PersonIcon
                                                htmlColor={theme.palette.primary.main}
                                                width={22}
                                                height={22}
                                            />
                                        }
                                        variant="text"
                                        onClick={() => {
                                            setIsAddPerson(true);
                                        }}
                                    >
                                        Adicionar titular
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                        {/* <Grid item xs={4}>
                            <TextFormField
                                fullWidth
                                label="Nome do cartão corporativo"
                                name="cardName"
                                variant="outlined"
                                disabled={readOnly}
                            />
                        </Grid> */}
                        <Grid item xs={4}>
                            <SelectFormField
                                name="type"
                                label="Tipo de conta"
                                fullWidth
                                disabled
                                options={typeAccount}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormField
                                fullWidth
                                type="number"
                                label="Quórum mínimo de aprovação"
                                name="quorumMinimumApproval"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        {bankAccountId !== 'nova' && (
                            <Grid item xs={4}>
                                <TextFormField
                                    name="stepDisplay"
                                    fullWidth
                                    label="Etapa da Preenchimento"
                                    variant="outlined"
                                    disabled={true}
                                />
                            </Grid>
                        )}
                        <Grid item xs={4}>
                            <SelectFormField
                                name="reason"
                                required
                                disabled={readOnly}
                                label="Motivo para abrir a conta"
                                fullWidth
                                options={[
                                    { label: 'Cartão de débito corporativo', value: 'Debit' },
                                    { label: 'Crédito', value: 'Credit' },
                                    { label: 'Emitir boletos de cobrança', value: 'Invoice' },
                                    {
                                        label: 'Receber e enviar transferências e pagar boletos',
                                        value: 'Transfer',
                                    },
                                    { label: 'Outros', value: 'Others' },
                                ]}
                            />
                        </Grid>
                        {watch('reason') === 'Others' && (
                            <Grid item xs={4}>
                                <TextFormField
                                    disabled={readOnly}
                                    fullWidth
                                    label="Outro motivo"
                                    name="otherReason"
                                    variant="outlined"
                                    multiline
                                    rows={2}
                                />
                            </Grid>
                        )}
                    </Grid>
                </Stack>
            </Box>

            {!!ownerId && (
                <>
                    <Box mt={3}>
                        <Typography
                            variant="h5"
                            color="neutral.dark"
                            sx={{
                                lineHeight: { xs: '24px', md: '24px' },
                                fontWeight: { xs: '700', md: '700' },
                                fontStyle: { xs: 'normal', md: 'normal' },
                                fontSize: '2rem',
                            }}
                        >
                            Dados do Titular
                        </Typography>
                    </Box>

                    <Box mt={3}>
                        <Typography
                            variant="h5"
                            color="neutral.dark"
                            sx={{
                                lineHeight: { xs: '24px', md: '24px' },
                                fontWeight: { xs: '700', md: '700' },
                                fontStyle: { xs: 'normal', md: 'normal' },
                            }}
                        >
                            Endereço
                        </Typography>
                        <Stack direction="column" mt={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.address.zipCode"
                                        id="zipCode"
                                        label="CEP"
                                        variant="outlined"
                                        placeholder="00000-000"
                                        fullWidth
                                        InputProps={{
                                            inputComponent: MaskedInput,
                                            inputProps: { mask: zipCodeMask },
                                            onChange: (event) => {
                                                let value = event?.currentTarget?.value.replace(
                                                    /\D/g,
                                                    ''
                                                );
                                                if (value.length >= 8) {
                                                    queryZipCodeAndFillForm(
                                                        value,
                                                        (fieldName, value) => {
                                                            setValue(
                                                                `owner.address.${fieldName}`,
                                                                value
                                                            );
                                                        }
                                                    );
                                                }
                                            },
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.address.addressName"
                                        fullWidth
                                        label="Endereço"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.address.number"
                                        fullWidth
                                        label="Número"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.address.complement"
                                        fullWidth
                                        label="Complemento"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.address.district"
                                        fullWidth
                                        label="Bairro"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <TextFormField
                                        name="owner.address.city"
                                        fullWidth
                                        label="Cidade"
                                        variant="outlined"
                                    />
                                </Grid>
                                <Grid item xs={2}>
                                    <SelectFormField
                                        name="owner.address.uf"
                                        label="UF"
                                        options={UFList}
                                        fullWidth
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Box mt={3}>
                        <Typography
                            variant="h5"
                            color="neutral.dark"
                            sx={{
                                lineHeight: { xs: '24px', md: '24px' },
                                fontWeight: { xs: '700', md: '700' },
                                fontStyle: { xs: 'normal', md: 'normal' },
                            }}
                        >
                            Informações Adicionais
                        </Typography>
                        <Stack direction="column" mt={2}>
                            <Grid container spacing={3}>
                                <Grid item xs={4}>
                                    <AutocompleteField
                                        name="owner.economicActivityCodeId"
                                        label="CNAE"
                                        displayName="owner.economicActivityCode.description"
                                        {...cnaeList}
                                    />
                                </Grid>
                                {isLegalPerson && (
                                    <>
                                        <Grid item xs={4}>
                                            <SelectFormField
                                                name="owner.legalNature"
                                                label="Natureza Jurídica"
                                                variant="outlined"
                                                options={legalNatureList}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={4}>
                                            <TextFormField
                                                label="Número de Funcionários"
                                                name="owner.numberOfEmployees"
                                                variant="outlined"
                                                fullWidth
                                                type="number"
                                                inputProps={{ min: 0 }}
                                            />
                                        </Grid>
                                    </>
                                )}
                                <Grid item xs={4}>
                                    <CurrencyFormField
                                        label="Faturamento médio mensal (Últimos 12 Meses)"
                                        name="owner.averageMonthlyRevenue"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextFormField
                                        name="owner.phone"
                                        variant="outlined"
                                        fullWidth
                                        label="Telefone Celular"
                                        placeholder="(00) 0000-0000"
                                        InputProps={{
                                            inputComponent: MaskedInput,
                                            inputProps: { mask: phoneNumberMask },
                                        }}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                </>
            )}
        </>
    );
};

export default InformationBankAccountRequestTab;
