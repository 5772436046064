import { Stack } from '@mui/material'
import { Button, CancelIcon, SaveIcon } from '@uy3/web-components';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { iconSx } from 'contexts/apiRequestContext';
import { usePersonsList } from 'contexts/personContext';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

type AddCreditorFormProps = {
    onClose: () => void
}

export const AddCreditorForm: React.FC<AddCreditorFormProps> = ({ onClose }) => {
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');

    return (
        <Stack spacing={2}>
            <AutocompleteField
                name="personId"
                label="Credor"
                displayName="personIdDisplay"
                required
                {...personAutoCompleteProps!}
            />

            <Stack direction='row' alignItems='center' justifyContent='right' gap={2}>
                <Button
                    startIcon={
                        <CancelIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Voltar
                </Button>
                <Button
                    name="btn-submit-upload"
                    type="submit"
                    variant="contained"
                    startIcon={
                        <SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    )
}
