import type { FunctionComponent } from 'react';
import type { ActiveProductsProps } from './ActiveProducts.interface';

import { Box, Stack } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Card, CardInfo, TextButton } from './ActiveProducts.styled';
import { CardIcon, SelectField, Typography } from '../../atoms';

export const ActiveProducts: FunctionComponent<ActiveProductsProps> = ({
  period,
  title,
  buttonText,
  amountActiveProducts,
  placeholderSelect,
  products,
  icon,
  color,
  onPeriodSelected,
}) => {
  return (
    <Card>
      <CardInfo>
        <Box className="cardIcon">
          <CardIcon icon={icon} size="medium" color={color} />
        </Box>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
          className="infoText"
        >
          <Typography variant="xs" color="neutral.medium" lineHeight="14px">
            {period}
          </Typography>
          <Typography variant="xs" color="neutral.darkest" lineHeight="19px" mb={0.5}>
            {title}
          </Typography>
          <TextButton
            endIcon={<ArrowForwardIosIcon />}
            variant="text"
            color="primary"
            size="medium"
            onClick={onPeriodSelected}
          >
            {buttonText}
          </TextButton>
        </Stack>
      </CardInfo>
      <Stack direction="column" alignItems="flex-start" justifyContent="flex-start" width="100%">
        <Typography variant="xs" color="neutral.darkest" lineHeight="24px" mb={2}>
          {amountActiveProducts}
        </Typography>
        <SelectField label={placeholderSelect} items={products} />
      </Stack>
    </Card>
  );
};
