import { Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { SiapeMarginQueryList } from './SiapeMarginQueryList';
import { useSiapeMarginMutation, useSiapeMarginQuery } from 'contexts/datasetContext';
import { isEmpty } from 'lodash';
import { HandleTitle } from 'components/Datasets/DatasetNaturalPerson/TypographyAndValues';
import { RefreshProgress } from 'components/RefreshProgress';
import { Button, Drawer } from '@uy3/web-components';
import {
  ApiResponseError,
  handleOnError,
  showSuccessToast,
  toastState,
  ToastType,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { IVinculoFuncional } from 'services/datasets/datasets.interface';
import { SiapeMarginDetails } from './SiapeMarginDetails';
import { useFormContext } from 'contexts/formContext';

export const SiapeMarginQuery = () => {
  const [rowData, setRowData] = useState<IVinculoFuncional | null>(null);
  const [toast, setToast] = useState<ToastType>(toastState);
  const { watch } = useFormContext();
  const [registrationNumber, personRegistrationNumber] = watch([
    'registrationNumber',
    'personRegistrationNumber',
  ]);

  const cpf = personRegistrationNumber ?? registrationNumber;

  const {
    data: siapeQueryData,
    isFetching,
    refetch,
  } = useSiapeMarginQuery({ registrationNumber: cpf });

  const onSuccess = () => {
    refetch();
    const title = 'Consulta de margem realizada com sucesso!';
    const description = undefined;
    showSuccessToast(title, description, setToast);
  };

  const { mutateAsync: siapeMarginMutation, isLoading: isLoadingSiapMarginMutation } =
    useSiapeMarginMutation(onSuccess, (err: ApiResponseError) => handleOnError(err, setToast));

  const hasData =
    siapeQueryData?.length !== 0 && !isEmpty(siapeQueryData) && siapeQueryData !== undefined;

  const siapeMarginData = hasData ? siapeQueryData?.[0] : null;
  const result = siapeMarginData?.result;
  const queryDataToList = result?.VinculoFuncionals ?? [];

  return (
    <Stack spacing={2} mt={-4}>
      <Toast toast={toast} setToast={setToast} />
      <RefreshProgress refreshing={isFetching || isLoadingSiapMarginMutation} />

      {hasData && (
        <Stack>
          {result !== null && (
            <Grid mb={3}>
              <HandleTitle title="Nome" value={result?.Nome ?? 'N/D'} key="nome" />
              <HandleTitle
                title="Data da consulta"
                value={result?.DtOperacao}
                key="dtOperacao"
              />
              {!isEmpty(result?.CdRetCode) && queryDataToList?.length === 0 && (
                <HandleTitle
                  title="Código de retorno"
                  value={result?.CdRetCode}
                  key="cdRetCode"
                />
              )}
              {!isEmpty(result?.DsRetCode) && queryDataToList?.length === 0 && (
                <HandleTitle
                  title="Mensagem de retorno"
                  value={result?.DsRetCode}
                  key="dsRetCode"
                />
              )}
            </Grid>
          )}

          {queryDataToList?.length > 0 && (
            <SiapeMarginQueryList
              queryData={queryDataToList}
              handleShowDetail={setRowData}
            />
          )}
        </Stack>
      )}

      <Grid item xs={6}>
        <Button
          onClick={() => siapeMarginMutation({ registrationNumber: cpf })}
          variant="contained"
          disabled={isLoadingSiapMarginMutation}
          size="medium"
        >
          Consultar margem
        </Button>
      </Grid>

      <Drawer
        anchor="right"
        open={rowData !== null}
        title={rowData?.NmOrgao ?? 'Detalhes'}
        description="Confira mais informações sobre a margem"
        onClose={() => setRowData(null)}
      >
        <SiapeMarginDetails data={rowData!} onClose={() => setRowData(null)} />
      </Drawer>
    </Stack>
  );
};
