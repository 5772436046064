import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import DatasetWrapper from '../DatasetWrapper';
import { ChargingGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface ICharging {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function Charging({ datasetId, disableRequestDataSet }: ICharging) {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'Charging');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta da Presença em cobrança${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'charging') as ChargingGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle
                        title={'Em cobrança'}
                        value={result?.isCurrentlyOnCollection ? 'Sim' : 'Não'}
                        key="isCurrentlyOnCollection"
                    />
                    <HandleTitle
                        title={'Total'}
                        value={result?.collectionOccurrences ?? 'N/D'}
                        key="collectionOccurrences"
                    />
                    <HandleTitle
                        title={'Origens diferentes'}
                        value={result?.collectionOrigins ?? 'N/D'}
                        key="collectionOrigins"
                    />
                    <HandleTitle
                        title={'Primeira cobrança'}
                        value={
                            result?.firstCollectionDate &&
                            new Date(result?.firstCollectionDate).toLocaleDateString()
                        }
                        key="firstCollectionDate"
                    />
                    <HandleTitle
                        title={'Última cobrança'}
                        value={
                            result?.lastCollectionDate &&
                            new Date(result?.lastCollectionDate).toLocaleDateString()
                        }
                        key="lastCollectionDate"
                    />
                    <HandleTitle
                        title={'Quantidade nos últimos 30 dias'}
                        value={result?.last30DaysCollectionOrigins ?? 'N/D'}
                        key="last30DaysCollectionOrigins"
                    />
                    <HandleTitle
                        title={'Quantidade nos últimos 90 dias'}
                        value={result?.last90DaysCollectionOrigins ?? 'N/D'}
                        key="last90DaysCollectionOrigins"
                    />
                    <HandleTitle
                        title={'Quantidade no último ano'}
                        value={result?.last365DaysCollectionOrigins ?? 'N/D'}
                        key="last365DaysCollectionOrigins"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('Charging')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
}
