/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material'
import { Button, LeftIcon, RightIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask, cpfMask, cnpjMask } from 'utils/constants';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';

type AddPixKeyFormProps = {
    onClose: () => void;
    formValues: any;
}
const theme = activeTheme();
export const AddPixKeyForm = ({ onClose, formValues }: AddPixKeyFormProps) => {
    const { setValue } = useFormContext();
    const type = formValues?.type;

    return (
        <Stack spacing={2}>
            {type === 'LegalRegistrationNumber' &&
                <>
                    <TextFormField
                        name="pixKey"
                        label="CNPJ"
                        variant="outlined"
                        required
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: cnpjMask }
                        }}
                        fullWidth
                    />
                </>
            }

            {type === 'NaturalRegistrationNumber' &&
                <TextFormField
                    name="pixKey"
                    label="CPF"
                    variant="outlined"
                    required
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: cpfMask },
                    }}
                    fullWidth
                />}

            {type === 'Phone' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        required
                        name="pixKey"
                        label={`Telefone`}
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: phoneNumberMask },
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            {type === 'Automatic' && (
                <Grid item xs={6}>
                    <TextFormField
                        variant="outlined"
                        required
                        name="pixKey"
                        label={`Chave aleatória`}
                        fullWidth
                    />
                </Grid>
            )}

            {type === 'Email' && (
                <Grid item xs={6}>
                    <TextFormField
                        name="pixKey"
                        label={`Chave Pix Email`}
                        variant="outlined"
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const value = event?.target?.value;
                            setValue('pixKey', value);
                        }}
                        fullWidth
                    />
                </Grid>
            )}

            <Stack direction='row' alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{
                                height: 23,
                                width: 23,
                            }}
                        />
                    }
                >
                    Avançar
                </Button>
            </Stack>
        </Stack>
    )
}