import { Grid, Stack } from '@mui/material';
import { Button, DocExcelIcon, DocPdfIcon } from '@uy3/web-components';
import React from 'react';

interface ButtonExportProps {}

const ButtonExport: React.FC<ButtonExportProps> = () => {
    return (
        <Stack direction="row" justifyContent="flex-end" spacing={3} m={3}>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={true}
                    startIcon={<DocPdfIcon htmlColor="#fff" />}
                >
                    Exportar PDF
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    type="submit"
                    disabled={true}
                    startIcon={<DocExcelIcon htmlColor="#fff" />}
                    color="primary"
                >
                    Exportar Excel
                </Button>
            </Grid>
        </Stack>
    );
};

export default ButtonExport;
