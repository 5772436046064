import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Card = styled(Stack)(({ theme }) => ({
  '&.isCard': {
    border: '0.5px solid #BABFD0',
    background: theme.palette.common.white,
    borderRadius: '8px',
    padding: '24px',
  },
  maxWidth: '100%',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    '& .stackMain': {
      flexWrap: 'wrap',
    },
    '& .value': {
      margin: '8px auto 8px auto', 
    },
    '&.fullWidth': {
      '& .cardIcon': {
        display: 'none'
      },
      '& .infoText': {
        marginLeft: 0
      }
    },
  }
}))

export const AboutCardValue = styled('div')`
  display: flex; 
  alignItems: center;  

  @media (max-width: 400px) {
    display: block; 
    text-align: center;
    margin: auto;
  }; 
`;
export const CardIconContainer = styled('div')`
  @media (max-width: 400px) {
    margin-bottom: 10px;
  }; 
`;

Card.defaultProps = {
  alignItems: 'center',
}