import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { AddSignatures, SignatureWorkFlowCreateType } from "./signature.types";

const url = "/Signature";

const generateConfigAxios = (token: string, params?: any) => {
    return {
        headers: { Authorization: `Bearer ${token}` },
        params
    };
}

export const signatureGetAsync = async (signatureWorkflowId: string, token: string) => {
    return await creditAxiosApi.get(`${url}/${signatureWorkflowId}`, generateConfigAxios(token));
};

export const signatureCreateAsync = async (body: SignatureWorkFlowCreateType, token: string) => {
    return await creditAxiosApi.post(url, body, generateConfigAxios(token));
};

export const signatureUpdateAsync = async (signatureWorkflowId: string, body: SignatureWorkFlowCreateType, token: string) => {
    return await creditAxiosApi.put(`${url}/${signatureWorkflowId}`, body, generateConfigAxios(token));
};

export const signatureStartAsync = async (signatureWorkflowId: string, filePath: string, token: string) => {
    return await creditAxiosApi.post(`${url}/${signatureWorkflowId}/Start`, {}, generateConfigAxios(token, { filePath }));
};

export const signatureResendSignatureNotificationAsync = async (signatureWorkflowId: string, token: string) => {
    return await creditAxiosApi.post(`${url}/${signatureWorkflowId}/ResendNotification`, {}, generateConfigAxios(token));
};

export const signatureAddSignersAsync = async (signatureWorkflowId: string, body: AddSignatures, token: string) => {
    return await creditAxiosApi.post(`${url}/${signatureWorkflowId}/AddSigners`, body, generateConfigAxios(token));
};

export const signatureDeleteAsync = async (signatureWorkflowId: string, signerId: string, token: string) => {
    return await creditAxiosApi.delete(`${url}/${signatureWorkflowId}/Signer/${signerId}`, generateConfigAxios(token));
};

export const signatureCancelAsync = async (signatureWorkflowId: string, token: string) => {
    return await creditAxiosApi.post(`${url}/${signatureWorkflowId}/Cancel`, {}, generateConfigAxios(token));
};
