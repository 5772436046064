import { Grid, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components'
import React from 'react'

type ApprovalsHeaderProps = {
  Actions: JSX.Element;
}

export const ApprovalsHeader: React.FC<ApprovalsHeaderProps> = ({ Actions }) => {
  return (
    <Stack spacing={2} justifyContent='space-between' direction='row' alignItems='center'>
      <Grid>
        <Typography
          variant="h4"
          color="neutral.dark"
          fontStyle="normal"
          fontWeight="700"
          fontSize="32px"
          lineHeight="38.4px"
        >
          Aprovações
        </Typography>
        <Typography variant="sm" display="block">
          Confira as transações pendentes de sua aprovação e selecione aprovar ou reprovar
        </Typography>
      </Grid>
      <Grid>
        {Actions}
      </Grid>
    </Stack>
  )
}