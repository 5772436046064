import { Grid, styled } from '@mui/material'
import { DraftIcon, Typography } from '@uy3/web-components'
import React from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

export const GridClaimRequest = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
  border: '2px solid #F1F1F1',
  margin: '20px 20px',
  padding: 15,
  borderRadius: 5
}));

export const SpanClaim = styled('span')(() => ({
  color: theme.palette.primary.main,
  cursor: 'pointer',
  transition: '0.2s',
  padding: '5px 0',
  textDecoration: 'underline', 
  ':hover': {
    textDecoration: 'none'
  }
}));

type ClaimsRequestsProps = {
  openDrawer: () => void;
}

export const ClaimsRequests: React.FC<ClaimsRequestsProps> = ({ openDrawer }) => {
  return (
    <GridClaimRequest>
      <DraftIcon sx={{ width: 30, height: 30 }} htmlColor={theme.palette.primary.main} />
      <Typography ml={1} fontSize={18}>
        Existem solicitações de migração de chave, para autorizar &nbsp;
        <SpanClaim onClick={openDrawer}>clique aqui</SpanClaim>
      </Typography>
    </GridClaimRequest>
  )
}