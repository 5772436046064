import styled from "@emotion/styled";
import { Grid } from '@mui/material';
import { Box } from "@mui/material";
import { IconView } from "components/AuthenticatedLayout/Menu/SideMenu";
import { CenteredBox } from "components/CenteredBox";
import { Outlet } from "react-router";
import { activeTheme } from "services/theme";

const theme = activeTheme();
const spacing = activeTheme().spacing(4);

export const MainBox = styled(Grid)();

export const Background = styled(MainBox)(() => ({
	minHeight: "100vh",
	backgroundColor: theme.palette.primary.dark,
}));

export const LogoBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	marginBottom: theme.spacing(2),
	'& svg': {
		width: theme.spacing(20),
		height: theme.spacing(16)
	}
}))

export const WhiteBox = styled(MainBox)(() => ({
	padding: spacing,
	borderRadius: theme.spacing(.5),
	backgroundColor: "white",
	boxShadow: theme.shadows[5],
}));

export const GuestLayout = () => {
	return <MainBox container spacing={0}>
		<Background xs={12}>
			<CenteredBox container spacing={4} rowSpacing={4} justifyContent="center" alignItems="center">
				<WhiteBox xs={4} mt={8}>
					<LogoBox>
						{IconView(
							//@ts-ignore
							theme?.components?.Icon, 260
						)}
					</LogoBox>
					<Outlet />
				</WhiteBox>
			</CenteredBox>
		</Background>
	</MainBox>
}
