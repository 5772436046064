import { SintegraGet } from 'services/datasets/datasets.interface';
import DatasetWrapper from '../DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';
import { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface ISintegra {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function Sintegra({ datasetId, disableRequestDataSet }: ISintegra) {
    const recordType = 'LegalPerson';
    const { personId } = useParams();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'Sintegra');
    const { setSubmitError } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de SINTEGRA ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'sintegra') as SintegraGet;
    const result = data?.result.items[0];

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <div>
                <HandleTitle title={'CNPJ'} value={result?.idNumber ?? 'N/D'} key="idNumber" />
                <HandleTitle title={'Estado'} value={result?.status ?? 'N/D'} key="status" />
                <HandleTitle
                    title={'Razão social'}
                    value={result?.officialName ?? 'N/D'}
                    key="officialName"
                />
                <HandleTitle
                    title={'Nome fantasia'}
                    value={result?.businessName ?? 'N/D'}
                    key="businessName"
                />
                <HandleTitle
                    title={'Data de Fundação'}
                    value={result?.foundingDate ?? 'N/D'}
                    key="foundingDate"
                />
                <HandleTitle
                    title={'Inscrição Estadual'}
                    value={result?.stateRegistration ?? 'N/D'}
                    key="stateRegistration"
                />
                <HandleTitle
                    title={'Núcleo do endereço'}
                    value={result?.addressCore ?? 'N/D'}
                    key="addressCore"
                />
                <HandleTitle
                    title={'Número de porta do endereço'}
                    value={result?.number ?? 'N/D'}
                    key="number"
                />
                <HandleTitle
                    title={'Complemento do endereço'}
                    value={result?.complement ?? 'N/D'}
                    key="complement"
                />
                <HandleTitle
                    title={'Bairro do endereço'}
                    value={result?.neighborhood ?? 'N/D'}
                    key="neighborhood"
                />
                <HandleTitle title={'Cidade'} value={result?.city ?? 'N/D'} key="city" />
                <HandleTitle
                    title={'Estado'}
                    value={result?.addressState ?? 'N/D'}
                    key="addressState"
                />
                <HandleTitle title={'CEP'} value={result?.zipCode ?? 'N/D'} key="zipCode" />
                <HandleTitle
                    title={'Natureza Legal'}
                    value={result?.legalnature ?? 'N/D'}
                    key="legalnature"
                />
                <HandleTitle title={'Status'} value={result?.status ?? 'N/D'} key="status" />
                <HandleTitle
                    title={'Situação fiscal'}
                    value={result?.fiscaloccurrence ?? 'N/D'}
                    key="fiscaloccurrence"
                />
                <HandleTitle
                    title={'Posto de fiscalização'}
                    value={result?.fiscaloffice ?? 'N/D'}
                    key="fiscaloffice"
                />
                <HandleTitle
                    title={'Regime de ICMS'}
                    value={result?.icmsregime ?? 'N/D'}
                    key="icmsregime"
                />
                <HandleTitle
                    title={'Atividade principal'}
                    value={result?.maineconomicactivity ?? 'N/D'}
                    key="maineconomicactivity"
                />
                <HandleTitle
                    title={'Data de registro para emissão de NFe'}
                    value={result?.nferegistrationdt ?? 'N/D'}
                    key="nferegistrationdt"
                />
                <HandleTitle
                    title="Casos de obrigatoriedade de NFe"
                    value={result?.nfemandatoryindicator ?? 'N/D'}
                    key="nfemandatoryindicator"
                />
                <HandleTitle
                    title="Início da obrigatoriedade de NFe"
                    value={result?.nfemandatorystartdt ?? 'N/D'}
                    key="nfemandatorystartdt"
                />
            </div>
            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('Sintegra')}
                isLoading={isLoading || status === 'loading'}
            />
        </>
    );
}
