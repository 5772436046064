import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { GenerateFileLiquidation } from './GenerateFileLiquidation';
import { GenerateFileLiquidationHeader } from './GenerateFileLiquidationHeader';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesGenerateFileLiquidationSchema, validationSchemaGenerateFileLiquidationSchema } from './Forms/GenerateFileLiquidationSchema';
import { FieldValues } from 'react-hook-form';
import { useIdentity } from 'contexts/identityContext';
import { remessaLiquidacaoForDate, postRemessaLiquidacaoForOp } from 'services/remessasVortx/remessasVortx';
import { IRemessaLiquidacaoForDate, IRemessaLiquidacaoForOp } from 'services/remessasVortx/remessasVortx.type';
import { ApiResponseError, ToastType, handleOnError, toastState, useApiRequest } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { RefreshProgress } from 'components/RefreshProgress';

export const GenerateFileLiquidationContainer = () => {
    const { token } = useIdentity();
    const { startRequest, endRequest, submitting: isLoading } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);

    const getLiquidacaiForDate = async (values: IRemessaLiquidacaoForDate) => {
        startRequest();
        try {
            var { data } = await remessaLiquidacaoForDate(values, token!);
            const responseDate = data as { xmlVortx: string }
            window.open((responseDate?.xmlVortx), '_blank');
            endRequest(true)
        } catch (error) {
            handleOnError(error as ApiResponseError, setToast)
            endRequest(false)
        }
    }

    const getLiquidacaiForop = async (values: IRemessaLiquidacaoForOp) => {
        startRequest();
        try {
            var { data } = await postRemessaLiquidacaoForOp(values, token!);
            const responseDate = data as { xmlVortx: string }
            window.open((responseDate?.xmlVortx), '_blank');
            endRequest(true)
        } catch (error) {
            handleOnError(error as ApiResponseError, setToast)
            endRequest(false)
        }
    }

    const handleSubmitForm = async (values: FieldValues) => {
        if (values?.typeLiquidation === "concilicaoCaixa") {
            await getLiquidacaiForDate({
                date: values?.date,
                fundId: values?.fundId
            });
        } else {
            await getLiquidacaiForop({
                date: values?.date,
                incluirVencidos: values?.incluirVencidos,
                baixarParcelas: values?.baixarParcelas,
                liquidateByvalue: values?.liquidateByvalue,
                opId: values?.opId
            })
        }
    }

    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isLoading} />
            <FormProvider
                defaultValues={defaultValuesGenerateFileLiquidationSchema}
                validationSchema={validationSchemaGenerateFileLiquidationSchema()}
                onSubmit={handleSubmitForm}
            >
                <Stack marginBottom={2} >
                    <GenerateFileLiquidationHeader isLoading={isLoading} />
                    <GenerateFileLiquidation />
                </Stack>
            </FormProvider>
        </Stack>
    )
}
