import { string, object } from 'yup';

export function validationSchemaSendDocsForm() {
    return object().shape({
        email: string().typeError('E-mail inválido.').required('E-mail: Precisa ser preenchido.').email("E-mail inválido"),
    });
};

export const defaultValuesSendDocsForm = {
    email: ""
};