import { Stack } from '@mui/material'
import { AddIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'

type CommunicationSettingListHeaderProps = {
    refetch: () => void;
    handleAction: (action: string) => void
}

export const CommunicationSettingListHeader: React.FC<CommunicationSettingListHeaderProps> = ({
    refetch, 
    handleAction
}) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Régua de comunicação
            </Typography>

            <Stack>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refetch,
                            disabled: false,
                            icon: <UpdateIcon sx={iconSx} />
                        }, 
                        {
                            enable: true,
                            label: "Nova régua de comunicação",
                            action: () => handleAction('add'),
                            disabled: false,
                            icon: <AddIcon sx={iconSx} />
                        }
                    ]}
                />
            </Stack>
        </Stack>
    )
}
