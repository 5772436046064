import { CardData } from '@uy3/web-components';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { FieldValues } from 'react-hook-form';

interface SummaryChargesCardDataProps {
    formValues: FieldValues;
}

const SummaryChargesCardData: React.FC<SummaryChargesCardDataProps> = ({ formValues }) => {
    const documentNumber = formValues?.documentNumber ?? 'N/D';
    const registrationNumber = formValues?.payer?.registrationNumber;
    const name = formValues?.payer?.name ?? 'N/D';
    const amount = formValues?.amount;

    return (
        <>
            <CustomTitle title="Dados da cobrança" variant="lg" />
            <CardData
                listItem={[
                    {
                        id: '1',
                        title: 'Valor',
                        value: formatCurrencyInCents(amount),
                    },
                    { id: '2', title: 'Número do documento', value: documentNumber },
                    { id: '3', title: 'Pagador', value: name },
                    {
                        id: '4',
                        title: 'CPF/CNPJ',
                        value: formatDocumentNumber(registrationNumber),
                    },
                ]}
            />
        </>
    );
};

export default SummaryChargesCardData;
