import { isEmpty } from 'lodash';
import { ICommunicationSettingFull } from 'services/walletManagement/communicationSetting/communicationSetting.types';
import { object, string, boolean, number } from 'yup';

export function validationSchemaCommunicationSetting() {
    return object().shape({
        walletCode: string()
            .typeError('Carteira precisa ser preenchido.')
            .required('Carteira precisa ser preenchido.'),
        name: string()
            .typeError('Nome precisa ser preenchido.')
            .required('Nome precisa ser preenchido.'),
        daysToSendBillingBeforeDueDate: number()
            .typeError('Quantidade de dias precisa ser preenchido.')
            .required('Quantidade de dias: Precisa ser preenchido.')
            .min(1, 'Quantidade de dias: precisa ser maior que 1')
            .max(365, 'Quantidade de dias: não pode ser maior que 365'),
        walletNumber: string().nullable(),
        notifyByEmail: boolean().nullable().notRequired(),
        notifyBillingUpdate: boolean().nullable().notRequired(),
        notifyBillingAfterSettlement: boolean().nullable().notRequired(),
        notifyBillingAfterOverDueDate: boolean().nullable().notRequired(),
        resendBillingAfterOverDueDate: boolean().nullable().notRequired(),
    });
}

export const defaultValuesCommunicationSetting = (row?: Partial<ICommunicationSettingFull>) => {
    if (!isEmpty(row?.id)) {
        return {
            ...row,
            walletCodeDisplay: row?.walletCode,
            submitToAuto: (row?.daysToSendBillingBeforeDueDate ?? 0) > 0 ? true : false
        }
    }
    return {
        walletCode: row?.walletCode ?? null,
        walletCodeDisplay: row?.walletCode ?? null,
        name: null,
        walletNumber: null,
        daysToSendBillingBeforeDueDate: 0,
        notifyByEmail: false,
        notifyBillingUpdate: false,
        notifyBillingAfterSettlement: false,
        notifyBillingAfterOverDueDate: false,
        resendBillingAfterOverDueDate: false
    }
};
