import { handleNumericNaN, toIsoStringWithTimezone } from 'helpers';
import { activeConfig } from 'services/config';
import { ICommissionModel } from 'utils';
import { string, object, date, number, boolean } from 'yup';

const appConfig = activeConfig();
const genericRequired = 'Precisa ser preenchido.';
export const currentDateWithSetHours = new Date().setHours(0, 0, 0, 0);

let requestedAmount = appConfig.DEFAULT_REQUESTED_AMOUNT?.toString();
let termValue = appConfig.DEFAULT_TERM_VALUE?.toString();
let agentCommissionValue = appConfig.DEFAULT_AGENT_COMMISSION_VALUE?.toString();
let agentCommissionType = appConfig.DEFAULT_AGENT_COMMISSION_TYPE;
let agentCommissionBaseValue = appConfig.DEFAULT_AGENT_COMMISSION_BASE_VALUE;
let apr = appConfig.DEFAULT_APR_VALUE;

export abstract class BasicAmortization {
    public amortizationType?: string | any;
    public requestedAmount?: number | any;
    public startDate?: String | any;
    public termInMonths?: number | any;
    public apr?: number | any;
    public financeTaxExempted: boolean | any = false;
    public agentCommission?: ICommissionModel | any;
}

export class DefaultSchemaBasicAmortization extends BasicAmortization {
    public requestedAmount = parseFloat(requestedAmount);
    public startDate = toIsoStringWithTimezone(new Date(currentDateWithSetHours));
    public termInMonths = parseInt(termValue);
    public apr = apr / 100;
    public financeTaxExempted = false;
    public agentCommission = {
        amount: parseFloat(agentCommissionValue),
        type: agentCommissionType,
        baseValue: agentCommissionBaseValue,
    };
    public includePaymentFixedCosts = false;
}

export class ValidationSchemaBasicAmortization {
    public amortizationType = string()
        .typeError('Selecione um tipo de amortização')
        .required('Selecione um tipo de amortização');
    public requestedAmount = number()
        .typeError('Valor precisa ser um número.')
        .required(`Valor solicitado: ${genericRequired}`)
        .min(1, 'Valor mínimo: 1')
        .transform(handleNumericNaN);

    public startDate = date()
        .typeError('Data inválida')
        .required(`Data de início: ${genericRequired}`);
    public termInMonths = number().when('amortizationType', {
        is: (value: string) => value !== 'sac' && value !== 'price',
        then: number()
            .typeError('Valor precisa ser um número.')
            .required(`Prazo: ${genericRequired}`)
            .min(1, 'Valor mínimo: 1')
            .transform(handleNumericNaN),
        otherwise: number().nullable().notRequired(),
    });

    public financeTaxExempted = boolean().typeError('Valor inválido.');
    public agentCommission = object().shape({
        amount: number()
            .typeError('Valor precisa ser um número.')
            .required(`Valor da comissão: ${genericRequired}`)
            .min(0, 'Valor mínimo: 0')
            .transform(handleNumericNaN),
        type: string()
            .oneOf(['Absolute', 'Percentage'], 'Selecione um tipo de comissão')
            .required('Tipo de comissão é obrigatório.'),
        baseValue: string()
            .oneOf(['InitialValue', 'RequestedValue'], 'Selecione uma base de cálculo')
            .nullable(),
    });
}
