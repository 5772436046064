import { Stack } from '@mui/material'
import { EditIcon, EmailIcon, MoneyIcomeIcon, OperatorIcon, Search02Icon, UpdateIcon, UploadIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import InformationCardHeader from 'components/InformationCardHeader/InformationCardHeader'
import { iconSx } from 'contexts/apiRequestContext'
import { useTenant } from 'contexts/tenantContext'
import { useUserPermissionData } from 'contexts/userContext'
import { formatCurrencyInCents } from 'helpers/formats/Currency'
import { useNavigate } from 'react-router'
import { checkActionsPerms } from 'services/permissions'
import { WalletsFull } from 'services/walletManagement/wallet/wallets.type'

export type WalletFormHeaderProps = {
    walletData: WalletsFull;
    refetch: () => void;
    handleAction: React.Dispatch<React.SetStateAction<string | undefined>>;
    handleExportWalletPosition: () => void;
}

export const WalletFormHeader = ({ walletData, refetch, handleExportWalletPosition, handleAction }: WalletFormHeaderProps) => {
    const navigate = useNavigate();
    const { hasPermission, data: permissionData} = useUserPermissionData();
    const { isRootTenancy } = useTenant();

    const hasActionPermission = (action: string): boolean =>  
        checkActionsPerms(action, permissionData, 'BillingAccount');
    
    const bankAccount = walletData?.bankAccount;
    const agencyAndAccount = `Ag: ${walletData?.bankAgency ?? "N/D"} - Conta: ${bankAccount ?? "N/D"}`
    const walletCode = walletData?.walletCode;

    const validateEnableAutoSubmitToDebtCollection = (): string => {
        if (walletData?.enableAutoSubmitToDebtCollection=== null) return "Sim"
        return  !!walletData?.enableAutoSubmitToDebtCollection ? "Sim" : "Não"
    }
    return (
        <Stack spacing={2} mb={4}>
            <InformationCardHeader
                {...{
                    title: `Carteira #${walletCode}`,
                    width: '100%', 
                    hideIsEmpty: true, 
                    gridTemplateColumns: 'auto auto auto auto', 
                    details: [
                        {label: "Agência e conta", value: agencyAndAccount}, 
                        {label: "Conta tarifa", value: walletData?.bankAccountCosts}, 
                        {label: "Correspondente", value: walletData?.tenantDisplay}, 
                        {label: "Grupo proprietário", value: walletData.ownerGroup?.groupDisplay}, 
                        {label: "Beneficiário", value: walletData?.beneficiaryName}, 
                        {label: "Tipo de cobrança", value: walletData?.billingTypeValueDisplay}, 
                        {label: "Tipo de juros", value: walletData?.interestCodeValueDisplay?? "N/D"}, 
                        {label: "Limite para pagamento", value: !!walletData?.daysOverdueLimit ? `${walletData?.daysOverdueLimit} dias` : ''}, 
                        {label: "Habilita protesto automático", value: validateEnableAutoSubmitToDebtCollection()}, 
                        {label: "Tarifas pendentes", value: formatCurrencyInCents(walletData.pendingCost), textBold: walletData?.pendingCost > 0}, 
                        {label: "Conta da tarifa", value: walletData.bankAccountDebtCosts}
                    ],
                    Actions: (
                        <Actions
                            buttonsActionsList={[
                                {
                                    enable: true,
                                    label: "Atualizar",
                                    action: refetch,
                                    disabled: false,
                                    icon: <UpdateIcon sx={iconSx} />
                                },
                                {
                                    enable: isRootTenancy && hasActionPermission('UpdateBillingCosts'),
                                    label: "Atualizar custos",
                                    action: () => handleAction('updateBankSlipCost'),
                                    disabled: false,
                                    icon: <MoneyIcomeIcon sx={iconSx} />
                                },
                                {
                                    enable: true,
                                    label: "Ver cobranças",
                                    action: () => navigate(`/gestao-carteira/cobrancas?walletsCode=${walletCode}`),
                                    disabled: false,
                                    icon: <Search02Icon sx={iconSx} />
                                },
                                {
                                    enable: isRootTenancy,
                                    label: "Atualizar carteira",
                                    action: () => handleAction('updateWalletCode'),
                                    disabled: false,
                                    icon: <EditIcon sx={iconSx} />
                                },
                                {
                                    enable: true,
                                    label: "Atualizar e-mails",
                                    action: () => handleAction('updateEmails'),
                                    disabled: false,
                                    icon: <EmailIcon sx={iconSx} />
                                },
                                {
                                    enable: true,
                                    label: "Exportar posição carteira",
                                    action: handleExportWalletPosition,
                                    disabled: false,
                                    icon: <UploadIcon sx={iconSx} />
                                },
                                {
                                    enable: hasPermission('BillingAccount', 'Update'),
                                    label: "Atribuir",
                                    action: () => handleAction('assign'),
                                    disabled: false,
                                    icon: <OperatorIcon sx={iconSx} />
                                }
                            ]}
                            numberOfButtons={4}
                        />
                    )
                }}
            />

        </Stack>
    )
}

