/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { CheckboxFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { isValidString } from 'helpers/formats/StringFormats'
import React, { Fragment, useEffect } from 'react'
import { activeTheme } from 'services/theme'

type InstallmentCreditNoteProps = {
    billingSettingAutocomplete: AutocompleteOptionList;
    communicationSettingAutocomplete: AutocompleteOptionList;
    walletAutocomplete: AutocompleteOptionList;
    onClose: () => void;
    isLoading: boolean
};

const theme = activeTheme();

export const InstallmentCreditNote: React.FC<InstallmentCreditNoteProps> = ({
    communicationSettingAutocomplete,
    walletAutocomplete,
    billingSettingAutocomplete,
    onClose,
    isLoading
}) => {
    const { watch, setValue } = useFormContext();
    const walletCode = watch('walletCode');
    const useFundSetting = Boolean(watch('useFundSetting'));
    const hasWalletCode = isValidString(String(walletCode));

    useEffect(() => {
        if (useFundSetting === true) {
            setValue('walletCode', null);
            setValue('walletCodeDisplay', null);
            setValue('billingSettingId', null);
            setValue('billingSettingDisplay', null);
            setValue('communicationSettingId', null);
            setValue('communicationSettingDisplay', null);
        };
    }, [useFundSetting])

    return (
        <Stack spacing={2}>
            <Grid item xs={3} pb={2}>
                <CheckboxFormField label='Utilizar os parâmetros da gestão de carteira configurados no fundo.' name='useFundSetting' />
            </Grid>
            {useFundSetting === false && (
                <Fragment>
                    <Grid item xs={3}>
                        <AutocompleteField
                            label="Código do convênio"
                            name="walletCode"
                            displayName="walletCodeDisplay"
                            {...walletAutocomplete!}
                        />
                    </Grid>

                    <Grid item xs={4}>
                        <AutocompleteField
                            label="Régua de cobrança"
                            name="billingSettingId"
                            displayName="billingSettingDisplay"
                            disabled={!hasWalletCode}
                            {...billingSettingAutocomplete!}
                        />
                    </Grid>

                    <Grid item xs={3}>
                        <AutocompleteField
                            label="Régua de comunicação"
                            name="communicationSettingId"
                            displayName="communicationSettingDisplay"
                            disabled={!hasWalletCode}
                            {...communicationSettingAutocomplete!}
                        />
                    </Grid>
                </Fragment>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} pt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm"
                    startIcon={
                        <CompleteIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                    disabled={isLoading}

                    variant="contained"
                    size="medium"
                    type='submit'
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
