import { isPixKeyDuplicate } from 'helpers/methods/GenericMethods';
import { BankAccountCreateOrUpdateModel } from 'utils';
import { object, string, number } from 'yup';
import StringSchema from 'yup/lib/string';
import { AnyObject } from 'yup/lib/types';

const requiredFieldByDataBank = (operationTypeValue: string, pixKeyTypeValue: string) => {
    return (
        operationTypeValue === 'Transfer' ||
        (operationTypeValue === 'Pix' && pixKeyTypeValue === 'AgencyAndAccount')
    );
};

export function bankDetailsListValidationSchema(bankAccounts?: BankAccountCreateOrUpdateModel[]) {
    return object().shape({
        operationTypeValue: string()
            .required('Tipo de operação: obrigatório.')
            .typeError('Tipo de operação: inválido.'),
        bankCode: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) =>
                requiredFieldByDataBank(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Banco: Precisa ser preenchido.')
                .typeError('Banco: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        type: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) =>
                requiredFieldByDataBank(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Tipo de conta: Precisa ser preenchido.')
                .typeError('Tipo de conta: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        agency: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) =>
                requiredFieldByDataBank(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Agência : Precisa ser preenchido.')
                .typeError('Valor precisa ser um número.')
                .max(4, 'Agência: Precisa ter 4 digitos.')
                .min(4, 'Agência: Precisa ter 4 digitos.'),
            otherwise: string().nullable(),
        }),
        account: number().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) =>
                requiredFieldByDataBank(operationTypeValue, pixKeyTypeValue),
            then: number()
                .typeError('Valor precisa ser um número.')
                .required('Conta : Precisa ser preenchido.'),
            otherwise: number().nullable(),
        }),

        accountDigit: string()
            .max(1, 'Dígito da conta: Precisa ter 1 digito.')
            .typeError('Dígito da conta inválido.')
            .nullable(),

        pixKeyTypeValue: string().when('operationTypeValue', {
            is: 'Pix',
            then: string()
                .typeError('Tipo de chave: Precisa ser preenchido.')
                .required('Tipo de chave: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),

        keyPix: string().when('pixKeyTypeValue', (pixKeyTypeValue) => {
            const schemaOptionsValidations: {
                [type: string]: StringSchema<string | undefined, AnyObject, string | undefined>;
            } = {
                Email: string()
                    .typeError('O Email informado é inválido.')
                    .required('Email: Precisa ser preenchido')
                    .email('Email inválido'),

                Phone: string()
                    .typeError('Número de telefone é inválido.')
                    .required('Telefone: Precisa ser preenchido.')
                    .phoneNumberValidation('Número de telefone deve conter 11 Caracteres'),

                NaturalRegistrationNumber: string()
                    .typeError('O CPF informado é inválido.')
                    .required('CPF: Precisa ser preenchido.')
                    .cpfCnpjValidation('CPF inválido.'),

                LegalRegistrationNumber: string()
                    .typeError('O CNPJ informado  é inválido.')
                    .required('CNPJ: Precisa ser preenchido')
                    .cpfCnpjValidation('CNPJ inválido'),

                Automatic: string()
                    .typeError('A chave aleatória é inválida.')
                    .required('Chave aleatória: Precisa ser preenchido'),
            };

            const schema = schemaOptionsValidations[pixKeyTypeValue!] ?? string().nullable();

            return schema.test(
                'is-duplicate',
                'Chave Pix já cadastrada.',
                (value) => !isPixKeyDuplicate(value, bankAccounts)
            );
        }),
    });
}

export const defaultValuesBankDetails = {
    agency: null,
    accountNumber: null,
    accountType: null,
    accountDigit: null,
    bankCode: null,
    operationTypeValue: null,
    pixKeyTypeValue: null,
    keyPix: '',
};
