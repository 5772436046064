import { Grid, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { GridColDef, GridRenderCellParams, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import {
    ApproveIcon,
    CancelIcon,
    DataTable, DeleteIcon, EditIcon, RejectIcon, SearchIcon
} from '@uy3/web-components';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';

interface OperatorTabProps {
    setRowsPerPage: (page: number) => void;
    setStepOperator: React.Dispatch<React.SetStateAction<string | undefined>>;
    setUserData: (rowData: any) => void;
    setPage: (newPage: number) => void;
    setOperationInfo: (rowData: any) => void;
    queryData: any;
    rowsPerPage: number;
    page: number;
    handleGetItemsSelected: (items: GridSelectionModel) => void
    rowsSelected: GridRowId[]
}

export const OperatorList = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    setOperationInfo,
    setStepOperator,
    setUserData,
    handleGetItemsSelected, 
    rowsSelected
}: OperatorTabProps) => {
    const { isRootTenancy } = useTenant();

    const handleOperatorAction = (row: GridRenderCellParams, actionType?: string) => {
        if (!!actionType) setStepOperator(actionType);
        setUserData(row);
    };

    const columnsOperatorList: GridColDef[] = [
        {
            field: 'user',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '8px',
                        }}
                    >
                        {`${row?.user?.userIdDisplay ?? "N/D"}`}
                        {isRootTenancy && ` (${row?.user?.tenantDisplay})`}
                    </div>
                );
            },
        },
        {
            field: 'levelDisplay',
            headerName: 'Nível',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                const isPedingApprove = row?.statusDisplay === "Aguardando aprovação";
                return (
                    <Stack
                        component={Grid}
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton onClick={() => setOperationInfo(row)}>
                            <SearchIcon sx={iconSx} />
                        </IconButton>
                        {isPedingApprove ?
                            <>
                                <RowActions listButtons={[
                                    {
                                        action: () => handleOperatorAction(row, 'editOperator'),
                                        disabled: false,
                                        icon: <EditIcon />,
                                        label: "Editar"
                                    },
                                    {
                                        action: () => handleOperatorAction(row.id, 'confirmMfaApprove'),
                                        disabled: rowsSelected?.length > 0,
                                        icon: <ApproveIcon />,
                                        label: "Aprovar"
                                    },
                                    {
                                        action: () => handleOperatorAction(row.id, 'confirmMfaReject'),
                                        disabled: rowsSelected?.length > 0,
                                        icon: <RejectIcon />,
                                        label: "Rejeitar"
                                    },
                                    {
                                        action: () => handleOperatorAction(row, 'confirmMfaDelete'),
                                        disabled: false,
                                        icon: <DeleteIcon />,
                                        label: "Excluir"
                                    },
                                    {
                                        action: () => handleOperatorAction(row.id, 'confirmMfaCancel'),
                                        disabled: false,
                                        icon: <CancelIcon />,
                                        label: "Cancelar"
                                    }
                                ]} />
                            </> :
                            <RowActions listButtons={[
                                {
                                    action: () => handleOperatorAction(row, 'editOperator'),
                                    disabled: false,
                                    icon: <EditIcon />,
                                    label: "Editar",
                                    hide: !isRootTenancy
                                },
                                {
                                    action: () => handleOperatorAction(row, 'confirmMfaDelete'),
                                    disabled: false,
                                    icon: <DeleteIcon />,
                                    label: "Excluir"
                                }
                            ]} />
                        }
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <BankAccountFiltersContainer typeFilter="operator" />,
            }}
            columns={columnsOperatorList}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            checkboxSelection={true}
            keepNonExistentRowsSelected={true}
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleGetItemsSelected}
        />
    );
};
