import { Grid } from '@mui/material';
import { CardData, Typography } from '@uy3/web-components';
import { Detail, Log, Overview } from 'services/datasets/datasets.interface';

type DatasetDetailsCreditProps = {
    dataDetails?: any,
    overview?: Overview
};

type DetailProps = {
    details: Detail,
    formatStatus: (bloqued: boolean, alert: boolean, error: boolean) => {}
};

export const Details = ({ details, formatStatus }: DetailProps) => {
    
    const logsList = details.logs.filter((log: Log) => {
        return log.alerta === true || log.erro === true|| log.bloqueio=== true; 
    }); 
    
    return (
        <>
            <>
                {logsList?.map((dt: Log, index: number) =>{ 
                    const result = formatStatus(dt.bloqueio, dt.alerta, dt.erro);
                    
                    return (
                    <Grid mt={2}>
                        <CardData
                            key={index}
                            listItem={[
                                {
                                    id: "1", title: 'Regra', value: dt.regra_descricao
                                },
                                {
                                    id: "2", title: 'Detalhes', value: dt.regra_condicao.length >= 120 ?
                                        `${dt.regra_condicao.substring(0, 120)}...` : dt.regra_condicao
                                },
                                { id: "3", title: 'Resultado', value:  <>{result}</>}
                            ]}
                        />
                    </Grid>
                )})}
            </>
        </>
    )
}

export const DatasetDetailsCredit = ({ dataDetails }: DatasetDetailsCreditProps) => {
    const overview: Overview = dataDetails?.overview;
    const details: Detail[] = dataDetails?.details;

    const formatStatus = (bloqued: boolean, alert: boolean, error: boolean) => {
        if (bloqued) return 'Bloqueio';
        if (alert) return 'Alerta';
        if (error) return 'Erro';
        return "N/D";
    };

    return (
        <>
            <Grid>
                <Grid item>
                    <Typography variant="h4" mb={2}>Resumo</Typography>
                </Grid>
                <Grid>
                    <Typography variant='h6' mt={0.5}><strong>Produto: </strong>{overview?.nome_grupo_analise}</Typography>
                    <Typography variant='h6' mt={0.5}><strong> Resultado: </strong> {formatStatus(overview?.bloqueio, overview?.alerta, overview?.erro) || "OK"} </Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CNPJ avaliados: </strong> {overview?.quantidade_cnpj}</Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CNPJ com alerta: </strong> {overview?.quantidade_cnpj_alerta}</Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CNPJ com bloqueio: </strong> {overview?.quantidade_cnpj_bloqueio}</Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CPF avaliados: </strong> {overview?.quantidade_cpf} </Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CPF avaliados com alerta: </strong> {overview?.quantidade_cpf_alerta} </Typography>
                    <Typography variant='h6' mt={0.5}><strong> Nº CPF avaliados com bloqueio : </strong> {overview?.quantidade_cpf_bloqueio} </Typography>
                </Grid>

                {details.map((rowItem: Detail) => (
                    <>
                        <Typography variant='h6'><strong>Nome:</strong> {rowItem.nome || "Não identificado"}</Typography>
                        {rowItem?.bloqueio || rowItem?.alerta || rowItem?.erro ?
                            <Typography variant='h6'><strong>Status:</strong> {formatStatus(rowItem?.bloqueio, rowItem?.alerta, rowItem?.erro)}</Typography>
                            :
                            <></>
                        }
                    </>
                ))}

                {details.map((rowItem: Detail) =>
                    <Details
                        details={rowItem}
                        formatStatus={formatStatus}
                    />
                )}

            </Grid>
        </>
    )
}
