/* eslint-disable array-callback-return */
import { FormProvider } from 'contexts/formContext'
import { validationApproveDisapproveFormSchemaSchema, defaultValuesDisapproveFormSchema, defaultValuesApproveFormSchema, validationApproveSchema } from './ApproveAndDisapproveFormSchema';
import { ApproveAndDisapproveForm } from './ApproveAndDisapproveForm';
import { useIdentity } from 'contexts/identityContext';
import { ApiResponseError, ToastType, useApiRequest } from 'contexts/apiRequestContext';
import { useNavigate } from 'react-router';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import {
    BankAccountRequestReadModel,
    DisapprovedBankAccountProps,
    approveBankAccountById,
    disapprovedBankAccountById,
} from 'services/accounts/bankAccountRequest';

type ApproveAndDisapproveFormContainerProps = {
    approveOrDesapprove: string;
    bankAccountRowSelected: BankAccountRequestReadModel[];
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    onClose: () => void;
    refetch: () => void;
    notIsNewBankAccount?: boolean;
    bankAccountId?: string;
    clearSelectedRows?: () => void;
    bankAccountRequestData: any;
}

const ApproveAndDisapproveFormContainer = ({
    approveOrDesapprove,
    bankAccountRowSelected,
    clearSelectedRows,
    onClose,
    refetch,
    notIsNewBankAccount,
    bankAccountId,
    setToast,
    bankAccountRequestData
}: ApproveAndDisapproveFormContainerProps) => {
    const { token } = useIdentity();
    const { setSubmitError } = useApiRequest();
    const { endRequest, startRequest } = useApiRequest();
    const navigate = useNavigate();

    const handleResponseError = (response: ApiResponseError) => {
        setSubmitError(response);
        const { errorMessage } = mapErrorResponse(response);
        setToast({
            title: 'Ops, ocorreu um erro!',
            description: errorMessage,
            open: true,
            severity: 'error'
        })
        endRequest(false);
    }

    const sendApproveAndDisapprove = (values: any) => {
        startRequest();
        if (approveOrDesapprove === 'approve') {

            notIsNewBankAccount ?
                approveBankAccountById(bankAccountId!, values?.message, token!).then(() => {
                    navigate('/contas-digitais/solicitacoes');
                    onClose();
                    endRequest(true);
                }).catch((response: ApiResponseError) => handleResponseError(response))
                :
                bankAccountRowSelected?.map((item) => {
                    approveBankAccountById(item?.id, values?.message, token!).then(() => {
                        refetch();
                        onClose();
                        endRequest(true)
                        clearSelectedRows!();
                    }).catch((response: ApiResponseError) => {
                        handleResponseError(response)
                    });
                })
        }
        if (approveOrDesapprove === 'disapprove') {
            let payload = {
                blockType: values?.blockType,
                blockUntil: values?.blockUntil,
                message: values?.message,
                reasonDisapproval: values?.reasonDisapproval, 
                attributeTypes: values?.complianceChecklist
            } as DisapprovedBankAccountProps;

            notIsNewBankAccount ?
                disapprovedBankAccountById(bankAccountId!, payload, token!).then(() => {
                    navigate('/contas-digitais/solicitacoes');
                    onClose();
                    endRequest(true)
                }).catch((response: ApiResponseError) => handleResponseError(response))
                :
                bankAccountRowSelected?.map((item) => {
                    startRequest()
                    disapprovedBankAccountById(item?.id, payload, token!).then(() => {
                        refetch();
                        onClose();
                        endRequest(true)
                        clearSelectedRows!();
                    }).catch((response: ApiResponseError) => {
                        handleResponseError(response)
                    });
                });
        }
    }

    const schemaDisapprove = validationApproveDisapproveFormSchemaSchema(bankAccountId!);
    const defaultValuesDisapprove = defaultValuesDisapproveFormSchema;
    const schemaApprove = validationApproveSchema();
    const defaultValuesApprove = defaultValuesApproveFormSchema;

    return (
        <FormProvider
            defaultValues={approveOrDesapprove === 'approve' ? defaultValuesApprove : defaultValuesDisapprove}
            validationSchema={approveOrDesapprove === 'approve' ? schemaApprove : schemaDisapprove}
            onSubmit={sendApproveAndDisapprove}
        >
            <ApproveAndDisapproveForm
                approveOrDesapprove={approveOrDesapprove}
                personInformation={{
                    discriminator: bankAccountRequestData?.ownerDiscriminator,
                    personId: bankAccountRequestData?.ownerId
                }!}
                onClose={onClose}
            />
        </FormProvider>
    )
}

export default ApproveAndDisapproveFormContainer