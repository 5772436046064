import { string, number, lazy, mixed, object, array, boolean } from 'yup';
import { genericMessageRequired, validateRequiredFields } from 'helpers';
import {
    ConsortiumCreateModel,
    DefaultWarranty,
    FactoringCreateModel,
    HomeEquityCreateModel,
    InvoiceFactoringCreateModel,
    JudiciaryProcessCreateModel,
    PublicPayrollCreateModel,
    VehicleCreateModel,
    WarrantyType,
} from 'services/creditNote';
import { publicPayrollSchema } from '../PublicPayrollForm';

export const validationSchemaWarranty = (isRequired: boolean) =>
    lazy((value) => {
        let baseShape = {
            warrantyType: mixed<WarrantyType>()
                .oneOf(
                    [
                        'Vehicle',
                        'HomeEquity',
                        'JudiciaryProcess',
                        'Factoring',
                        'PublicPayroll',
                        'InvoiceFactoring',
                        'Consortium',
                    ],
                    'Selecione um tipo de garantia'
                )
                .required('Tipo de garantia: ' + genericMessageRequired),
        };

        if (value.warrantyType === 'Factoring') {
            return object()
                .shape({
                    ...baseShape,
                    totalValue: number()
                        .typeError('Valor total inválido.')
                        .required('Valor total: ' + genericMessageRequired)
                        .min(0, 'Valor total não pode ser menor que 0.'),

                    register: validateRequiredFields(
                        string().typeError('Registro inválido.'),
                        'register',
                        'Registradora'
                    ),
                    paymentNetworks: validateRequiredFields(
                        array().of(string().typeError('Arranjos de pagamentos inválida.')),
                        'paymentNetworks',
                        'Arranjos de pagamentos'
                    ),

                    type: string()
                        .typeError('Tipo inválido.')
                        .oneOf(['Collateral', 'OwnershipChange'], 'Selecione um tipo de garantia')
                        .required('Tipo de garantia: ' + genericMessageRequired),
                    splitMethodType: string()
                        .typeError('Tipo de divisão inválido.')
                        .oneOf(['Percentage', 'Absolute'], 'Selecione um tipo de divisão')
                        .required('Tipo de divisão: ' + genericMessageRequired),
                    constituted: boolean()
                        .typeError('Recebível Performado inválido.')
                        .required('Recebível Performado: ' + genericMessageRequired),
                })
                .transform((value: any, _: any) => {
                    return new FactoringCreateModel(value);
                });
        }

        if (value.warrantyType === 'Vehicle') {
            return object()
                .shape({
                    ...baseShape,
                    renavam: string()
                        .typeError('Renavam inválido.')
                        .required('Renavam: ' + genericMessageRequired),
                    board: string()
                        .typeError('Placa inválida.')
                        .required('Placa: ' + genericMessageRequired),
                    typeOfVehicle: string()
                        .typeError('Tipo de veículo inválido.')
                        .required('Tipo: ' + genericMessageRequired),
                    chassis: string()
                        .typeError('Chassi inválido.')
                        .required('Chassi' + genericMessageRequired),
                    supplier: string()
                        .typeError('Fornecedor inválido.')
                        .required('Fornecedor: ' + genericMessageRequired),
                    taxNumberOfSupplier: string()
                        .typeError('CNPJ do fornecedor inválido.')
                        .required('CNPJ do fornecedor: ' + genericMessageRequired)
                        .cpfCnpjValidation('CNPJ inválido.'),
                    brand: string()
                        .typeError('Marca inválida.')
                        .required('Marca: ' + genericMessageRequired),
                    color: string()
                        .typeError('Cor inválida.')
                        .required('Cor: ' + genericMessageRequired),
                    model: string()
                        .typeError('Modelo inválido.')
                        .required('Modelo:' + genericMessageRequired),
                    yearOfManufacture: string()
                        .typeError('Ano de fabricação inválido.')
                        .required('Ano de fabricação: ' + genericMessageRequired)
                        .max(4, 'Ano de fabricação precisa conter 4 digitos.')
                        .min(4, 'Ano de fabricação não pode ser menor que 4 digitos.'),
                    yearOfModel: string()
                        .typeError('Ano do modelo inválido.')
                        .required('Ano do modelo: ' + genericMessageRequired)
                        .max(4, 'Ano do modelo precisa conter 4 digitos.')
                        .min(4, 'Ano do modelo não pode ser menor que 4 digitos.'),
                    typeOfFuel: string()
                        .typeError('Tipo de combustível inválido.')
                        .required('Tipo de combustível: ' + genericMessageRequired),
                    downPayment: number().nullable().notRequired(),
                    sellerCompanyName: string().nullable().notRequired(),
                    sellerRegistrationNumber: string()
                        .typeError('CNPJ do vendedor inválido.')
                        .cpfCnpjValidation('CNPJ inválido.')
                        .required('CNPJ do vendedor: ' + genericMessageRequired),
                    valueInCash: number().nullable().notRequired(),
                    subtotalAmount: number().nullable().notRequired(),
                    totalAmountFinancedTaxFree: number().nullable().notRequired(),
                })
                .transform((value: any, _: any) => {
                    return new VehicleCreateModel(value);
                });
        }

        if (value.warrantyType === 'HomeEquity') {
            return object()
                .shape({
                    ...baseShape,
                    registryOffice: string()
                        .typeError('Cartório de registro ' + genericMessageRequired)
                        .required('Cartório de registro: ' + genericMessageRequired),
                    registrationNumber: string()
                        .typeError('Matricula ' + genericMessageRequired)
                        .required('Matricula: ' + genericMessageRequired),
                    width: number()
                        .typeError('Largura em metros ' + genericMessageRequired)
                        .required('Largura em metros: ' + genericMessageRequired),
                    length: number()
                        .typeError('Comprimento em metros ' + genericMessageRequired)
                        .required('Comprimento em metros: ' + genericMessageRequired),
                    addressName: string()
                        .typeError('Endereço ' + genericMessageRequired)
                        .required('Endereço: ' + genericMessageRequired),
                    zipCodeAddress: string()
                        .typeError('CEP ' + genericMessageRequired)
                        .required('CEP: ' + genericMessageRequired),
                    city: string().nullable(),
                    uf: string()
                        .typeError('UF ' + genericMessageRequired)
                        .required('UF: ' + genericMessageRequired),
                    district: string()
                        .typeError('Bairro ' + genericMessageRequired)
                        .required('Bairro: ' + genericMessageRequired),
                    addressNumber: string()
                        .typeError('Número ' + genericMessageRequired)
                        .required('Número: ' + genericMessageRequired),
                    complementAddress: string()
                        .typeError('Complemento ' + genericMessageRequired)
                        .required('Complemento: ' + genericMessageRequired),
                    propertyFeatures: string().nullable(),
                })
                .transform((value: any, _: any) => {
                    return new HomeEquityCreateModel(value);
                });
        }

        if (value.warrantyType === 'JudiciaryProcess') {
            return object()
                .shape({
                    ...baseShape,
                    number: string()
                        .typeError('Nº do processo' + genericMessageRequired)
                        .required('Nº do processo: ' + genericMessageRequired),
                    court: string()
                        .typeError('Vara / Tribunal' + genericMessageRequired)
                        .required('Vara / Tribunal: ' + genericMessageRequired),
                    valueOfCause: number()
                        .typeError('Valor da causa' + genericMessageRequired)
                        .required('Valor da causa: ' + genericMessageRequired),
                    claimant: string()
                        .typeError('Requerente / Autor' + genericMessageRequired)
                        .required('Requerente / Autor: ' + genericMessageRequired),
                    claimed: string()
                        .typeError('Requerido / Réu' + genericMessageRequired)
                        .required('Requerido / Réu: ' + genericMessageRequired),
                    typeOfProcess: string()
                        .typeError('Tipo do processo' + genericMessageRequired)
                        .required('Tipo do processo: ' + genericMessageRequired),
                })
                .transform((value: any, _: any) => {
                    return new JudiciaryProcessCreateModel(value);
                });
        }

        if (value.warrantyType === 'PublicPayroll') {
            return object()
                .shape({
                    ...baseShape,
                    payrollAgreementId: string()
                        .typeError('Convênio é inválido.')
                        .required('Selecione o Convênio'),
                    payrollAgreementName: string().nullable(),
                    warrantyCode: string().nullable(),
                    employeeCode: string()
                        .typeError('Código da matricula é inválido.')
                        .required('Código da matricula: ' + genericMessageRequired),
                    employeerInstCode: string().nullable(),
                    agreementCode: string()
                        .typeError('Código externo do convênio é inválido.')
                        .required('Código externo do convênio: ' + genericMessageRequired),
                    paymentTransferDay: string()
                        .typeError('Dia do repasse é inválido.')
                        .required('Dia do repasse: ' + genericMessageRequired),
                    paymentAmountInCents: string()
                        .typeError('Valor da margem é inválido.')
                        .required('Valor da margem: ' + genericMessageRequired),
                    employmentStatusCode: string().nullable(),
                    employmentStatusDescription: isRequired
                        ? string()
                            .typeError('Situação vínculo funcional é inválido.')
                            .required('Selecione a situação vínculo funcional.')
                        : string().nullable(),
                    ...publicPayrollSchema.fields,
                })
                .transform((value) => {
                    return new PublicPayrollCreateModel(value);
                });
        }

        if (value.warrantyType === 'InvoiceFactoring') {
            return object()
                .shape({
                    ...baseShape,
                    payerName: string()
                        .typeError(`Nome do pagador: ${genericMessageRequired}`)
                        .required(`Nome do pagador: ${genericMessageRequired}`),
                    payerRegistrationNumber: string()
                        .typeError(`CPF/CNPJ ${genericMessageRequired}`)
                        .required(`CPF/CNPJ ${genericMessageRequired}`)
                        .cpfCnpjValidation('CPF/CNPJ: inválido'),
                    contractNumber: string()
                        .typeError(`Número do contrato ${genericMessageRequired}`)
                        .required(`Número do contrato ${genericMessageRequired}`),
                    invoiceValue: string()
                        .typeError(`Valor nominal ${genericMessageRequired}`)
                        .required(`Valor nominal ${genericMessageRequired}`),
                    invoiceNumber: string()
                        .typeError(`Número nominal ${genericMessageRequired}`)
                        .required(`Número nominal ${genericMessageRequired}`),
                    discountValue: string()
                        .typeError(`Valor desconto: ${genericMessageRequired}`)
                        .required(`Valor desconto: ${genericMessageRequired}`),
                    presentValue: string()
                        .typeError(`Valor presente ${genericMessageRequired}`)
                        .required(`Valor presente ${genericMessageRequired}`),
                    dueDate: string()
                        .typeError(`Data de vencimento ${genericMessageRequired}`)
                        .required(`Data de vencimento ${genericMessageRequired}`),
                    emissionDate: string()
                        .typeError(`Data de emissão ${genericMessageRequired}`)
                        .nullable(),
                    paymentAmountInCents: string()
                        .typeError(`Valor do pagamento ${genericMessageRequired}`)
                        .required(`Valor do pagamento ${genericMessageRequired}`),
                    paymentItemIndex: number().nullable(),
                    paymentItemTotal: number().nullable(),
                    yourNumber: string().nullable(),
                    storeCode: number().nullable(),
                    productCode: number().nullable(),
                    creditType: string().nullable(),
                    identification: string().nullable(),
                    index: number().nullable(),
                    payerDescription: string().nullable(),
                    payerAdress: string().nullable(),
                    payerZipCodeAdress: number().nullable(),
                    contractFee: number().nullable(),
                })
                .transform((value: any, _: any) => {
                    return new InvoiceFactoringCreateModel(value);
                });
        }

        if (value.warrantyType === 'Consortium') {
            return object()
                .shape({
                    ...baseShape,
                    administratorName: string().nullable(),
                    administratorRegistrationNumber: string()
                        .cpfCnpjValidation('CNPJ: inválido')
                        .nullable(),
                    group: string()
                        .typeError(`Grupo ${genericMessageRequired}`)
                        .required(`Grupo ${genericMessageRequired}`),
                    quota: string()
                        .typeError(`Cota ${genericMessageRequired}`)
                        .required(`Cota ${genericMessageRequired}`),
                })
                .transform((value: any, _: any) => {
                    return new ConsortiumCreateModel(value);
                });
        }

        return object().shape({ ...baseShape });
    });

export const defaultValuesWarranty = (warrantyType: WarrantyType | undefined) => {
    switch (warrantyType) {
        case 'PublicPayroll':
            return new PublicPayrollCreateModel();
        case 'InvoiceFactoring':
            return {
                payerName: null,
                payerRegistrationNumber: null,
                contractNumber: null,
                invoiceValue: null,
                invoiceNumber: null,
                discountValue: null,
                presentValue: null,
                dueDate: new Date(),
                emissionDate: new Date(),
                paymentAmountInCents: null,
            };
        default:
            return new DefaultWarranty();
    }
};
