import type { FunctionComponent } from 'react';
import { useState, useCallback } from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';
import pt from 'date-fns/locale/pt';
import { addDays, format } from 'date-fns';
import { Grid, Stack } from '@mui/material';
import { MainBox } from './Calendar.styled';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button, CalendarIcon, CancelIcon, DoneIcon, Typography } from '@uy3/web-components';
import VerticalSelect from 'components/VerticalSelect/VerticalSelect';
import { activeTheme } from 'services/theme';

export interface ITypeDateToIso {
  startDate: string;
  endDate: string;
};

export interface ITypeDateByOtptions {
  index: number,
  value: string
}

export interface IHandleCalendarSubmit {
  rangeValueDate?: ITypeDateToIso | undefined,
  valueSelectedListDate?: ITypeDateByOtptions
}

type TypePeriod = "otherOptions" | "onlyCalendar";

type CalendarProps = {
  onClose: () => void;
  typeOfUse?: TypePeriod;
  listOptions?: string[];
  handleSubmit: (props: IHandleCalendarSubmit) => void
  buttonLabelConfirm?: string;
  buttonLabelCancel?: string;
  minDate?: Date;
  maxDate?: Date;
}

const theme = activeTheme();

export const Calendar: FunctionComponent<CalendarProps> = ({
  onClose,
  typeOfUse = 'otherOptions',
  listOptions,
  handleSubmit,
  buttonLabelCancel = "Cancelar",
  buttonLabelConfirm = "Selecionar",
  minDate, 
  maxDate
}) => {
  const [valueSelectedList, setValueSelectedList] = useState<ITypeDateByOtptions | undefined>(undefined);
  const [rangeValueDate, setRangeValueDate] = useState<ITypeDateToIso | undefined>(undefined)
  const [lastIndex, setLastIndex] = useState<number>(0);
  const [dateRange, setDateRange] = useState<Range>({
    startDate: addDays(new Date(), -7),
    endDate: new Date(),
    key: 'selection'
  });

  // intial date selected
  const dataInicialString = dateRange.startDate || '';
  const dataInicialISO = format(new Date(dataInicialString), "yyyy-MM-dd'T'HH:mm:ssXX");
  const startFormatedDate = format(new Date(dataInicialISO), "dd 'de' MMMM 'de' yyyy", {
    locale: pt,
  });

  // final date selected
  const dataFinalString = dateRange.endDate || '';
  const dataFinalISO = format(new Date(dataFinalString), "yyyy-MM-dd'T'HH:mm:ssXX");
  const finalFormatedDate = format(new Date(dataFinalISO), "dd 'de' MMMM 'de' yyyy", {
    locale: pt,
  });

  // string with period selected
  const periodSelected = `${startFormatedDate} até ${finalFormatedDate}`;

  const onChange = ({ selection }: RangeKeyDict) => {
    const { endDate, startDate } = selection;
    setDateRange(selection);
    if (!!endDate && !!startDate) {
      const startDateToIso = startDate.toISOString();
      const endDateToIso = endDate.toISOString();
      setRangeValueDate({ endDate: endDateToIso, startDate: startDateToIso })
    }
  }

  const handleValueSelected = (index: number) => {
    const getValue = listOptions?.find((_, indice) => {
      setLastIndex(index + 1);
      return indice === index
    }) as string;
    setValueSelectedList({ index, value: getValue });
  }

  const handleOnSubmit = useCallback(() => {
    return {
      valueSelectedListDate: valueSelectedList,
      rangeValueDate
    };
  }, [valueSelectedList, rangeValueDate]);

  const styleIcon = { width: 20, height: 20 };
  const hasOptions = typeOfUse === 'otherOptions';

  return (
    <>
      <Grid sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CalendarIcon htmlColor={theme.palette.common.black} sx={styleIcon} />
        <Typography variant='h5'>{periodSelected}</Typography>
      </Grid>
      <Grid margin={hasOptions ? '' : 'auto'}>
        <Stack
          direction={hasOptions ? 'row' : 'column'}
          alignItems='center'
          justifyContent='space-between'
          mb={2}
          mt={2}
        >
          {hasOptions &&
            <VerticalSelect
              width={'full'}
              listOptions={listOptions}
              handleValueSelected={handleValueSelected}
            />
          }
          {lastIndex === listOptions?.length || typeOfUse === 'onlyCalendar' ?
            <MainBox
              sx={{
                '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
                  width: typeOfUse === 'onlyCalendar' ? '100%' : '480px'
                }
              }}
            >
              <DateRangePicker
                onChange={onChange}
                moveRangeOnFirstSelection={false}
                locale={pt}
                months={2}
                ranges={[dateRange]}
                minDate={minDate}
                maxDate={maxDate}
                direction="horizontal"
                weekStartsOn={0}
                rangeColors={[theme.palette.primary.main, 'blue', 'green']}
                preventSnapRefocus
              />
            </MainBox> : <></>}
        </Stack>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="center" >
          <Button
            variant="outlined"
            startIcon={<CancelIcon htmlColor={theme.palette.primary.main} sx={styleIcon} />}
            sx={{ width: '100%' }}
            color="primary"
            size="medium"
            onClick={onClose}
          >
            {buttonLabelCancel}
          </Button>
          <Button
            variant="contained"
            startIcon={<DoneIcon htmlColor={theme.palette.common.white} sx={styleIcon} />}
            sx={{ width: '100%' }}
            color="primary"
            size="medium"
            onClick={async () => {
              const { rangeValueDate, valueSelectedListDate } = await handleOnSubmit();
              handleSubmit && handleSubmit({
                rangeValueDate,
                valueSelectedListDate
              });
            }}
          >
            {buttonLabelConfirm}
          </Button>
        </Stack>
      </Grid>
    </>
  );
};
