import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const TransactionalLimitsIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
      >
        <path
          d="M23.2 16.1252C23.4073 15.6133 23.1603 15.0303 22.6483 14.8231C22.1364 14.6158 21.5534 14.8629 21.3462 15.3748L23.2 16.1252ZM2.79996 7.87478C2.59273 8.38672 2.83975 8.96971 3.35168 9.17694C3.86362 9.38416 4.44661 9.13715 4.65383 8.62522L2.79996 7.87478ZM24 12C24 5.92487 19.0751 1 13 1V3C17.9706 3 22 7.02944 22 12H24ZM2 12C2 18.0751 6.92487 23 13 23V21C8.02944 21 4 16.9706 4 12H2ZM13 23C17.618 23 21.569 20.1546 23.2 16.1252L21.3462 15.3748C20.0103 18.6749 16.7755 21 13 21V23ZM13 1C8.38203 1 4.43103 3.84536 2.79996 7.87478L4.65383 8.62522C5.9897 5.32506 9.22452 3 13 3V1Z"
          fill={htmlColor}
        />
        <path
          d="M15 10C15 8.89543 14.1046 8 13 8C11.8954 8 11 8.89543 11 10C11 11.1046 11.8954 12 13 12"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 12C14.1046 12 15 12.8954 15 14C15 15.1046 14.1046 16 13 16C11.8954 16 11 15.1046 11 14"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M13 6.5V8"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13 16V17.5"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 11.5L21.8944 11.9472C22.572 12.286 23.3828 12.2129 23.9889 11.7584L25 11"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 13L1.89443 12.5528C2.572 12.214 3.38282 12.2871 3.98885 12.7416L5 13.5"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

TransactionalLimitsIcon.defaultProps = {
  viewBox: '0 0 26 24',
  width: '26',
  height: '24',
  fill: 'none',
};