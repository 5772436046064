import { Stack } from '@mui/material';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
const palette = theme.palette;

type HighlightBoxProps = {
    background: string;
    textDisplay: string;
    width: string;
};

const HighlightBox: React.FC<HighlightBoxProps> = ({ background, textDisplay, width }) => {
    const color = palette.common.white;
    const backgroundColor = background ? background : palette.grey['400'];
    
    return (
        <Stack
            sx={{
                background: backgroundColor,
                color,
                padding: 1,
                display: 'block',
                borderRadius: 2,
                minWidth: width,
                textAlign: 'center',
            }}
        >
            {textDisplay}
        </Stack>
    );
};

export default HighlightBox;
