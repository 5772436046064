import { string, object, array } from 'yup';

export function validationSchemaAddNewPayrollAgreements() {
    return object().shape({
        payrollAgreementsIds: array()
            .of(string())
            .typeError('Selecione ao menos 1 orgão')
            .required('Selecione ao menos 1 orgão')
            .test('is-empty', 'Selecione ao menos 1 orgão', (value) => {
                if (value && value.length > 0) return true;
                return false;
            }),
    });
}

export const defaultValuesAddNewPayrollAgreements = {
    payrollAgreementsIds: [],
};
