import { object, string } from "yup";


export const validationEditOperatorSchema = () => {
  return object().shape({
    level: string().typeError('Nível precisa ser preenchido').required('Nível: precisa ser preenchido')
  });
};

export const defaultValuesEditOperator = {
  level: null
};