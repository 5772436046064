import { useParams } from 'react-router';
import DatasetWrapper from '../../DatasetWrapper';
import PartnersLawsuitsList from './PartnersLawsuitsList';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { Lawsuit, PartnersLawsuitsPost } from 'services/datasets/datasets.interface';
import { Modal } from '@uy3/web-components';
import { useState } from 'react';
import { ProcessDetails } from '../../InformationDetails';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IPartnersLawsuits {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function PartnersLawsuits({ datasetId, disableRequestDataSet }: IPartnersLawsuits) {
    const recordType = 'LegalPerson';
    const [modal, setModal] = useState(false);
    const [rowData, setRowData] = useState<Lawsuit>({} as Lawsuit);
    const { personId } = useParams();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'PartnersLawsuits');
    const { setSubmitError } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Ações Judiciais relacionadas aos sócios ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'partners_lawsuits'
    ) as PartnersLawsuitsPost;

    const result = data?.result;

    const closeModal = () => setModal(false);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Modal
                title="Informações detalhada"
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={<ProcessDetails rowData={rowData} />}
            />
            <PartnersLawsuitsList
                result={result}
                setRowData={setRowData}
                setModal={setModal}
                isLoading={isLoading || status === 'loading'}
            />
            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('PartnersLawsuits')}
            />
        </>
    );
}
