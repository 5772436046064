import { IInstallmentFull } from "services/walletManagement/installment/installment.types";
import { object, string } from "yup";
import { validationInstallmentCreateFormSchema } from "../InstallmentCreateForm/InstallmentCreateFormShema";
import { isEmpty } from "lodash";

export function validationEditInstallmentFormSchema(installmentData?: IInstallmentFull) {
    if (installmentData?.registered === false) return validationInstallmentCreateFormSchema()

    return object().shape({
        payer: object().shape({
            email: string().nullable().notRequired()
        })

    })
}

export const defaultValuesEditInstallmentForm = (installmentData?: IInstallmentFull) => {
    const isModeEdit = !isEmpty(installmentData?.id);
    const isRegistered = !!installmentData?.registered;

    if (isModeEdit && !isRegistered) {
        return {
            walletCode: installmentData?.walletCode,
            walletCodeDisplay: installmentData?.walletCode,
            personId: installmentData?.payer?.name,
            personIdDisplay: installmentData?.payer?.name,
            billingSettingId: installmentData?.billingSettingId,
            communicationSettingId: installmentData?.communicationSettingId,
            billingSettingDisplay: installmentData?.billingSettingDisplay,
            communicationSettingDisplay: installmentData?.communicationSettingDisplay,
            payer: {
                ...installmentData?.payer,
                registrationNumber: installmentData?.payer?.registrationNumber.toString()
            }
        }
    }
    if (isModeEdit && isRegistered) {
        return {
            payer: {
                email: installmentData?.payer?.email
            }
        }
    }
};