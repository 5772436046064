import type { FunctionComponent } from 'react';
import type { CardInfoProps } from './CardInfo.interface';
import { Typography, Grid } from '@mui/material';
import { Card } from './CardInfo.styled';
import { CardIcon } from '../../atoms/CardIcon';

export const CardInfo: FunctionComponent<CardInfoProps> = ({
  title,
  description,
  icon,
  value,
  color,
}) => {
  return (
    <Card>
      <Grid item className="cardIcon">
        <CardIcon icon={icon} size="medium" color={color} />
      </Grid>
      <Grid
        container
        columnGap={1}
        direction="column"
        mx="16px"
        display="flex"
        alignItems="flex-start"
        justifyContent="center"
        width="auto"
      >
        <Grid item>
          <Typography component="h5" variant="sm" color="neutral.dark" lineHeight="19px">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="xs" color="neutral.medium" component="p" lineHeight="14px">
            {description}
          </Typography>
        </Grid>
      </Grid>
      {value && (
          <Typography
            variant="lg"
            color="primary"
            lineHeight="38px"
            fontWeight={700}
            className="value"
          >
            {` R$ ${value}`}
          </Typography>
      )}
    </Card>
  );
};
