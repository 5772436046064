import React from 'react'
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip';
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions';

export const BatchAssignmentActions = () => {
    return (
        <ActionsPermissions
            PermissionRootTenancy={<PermissionResourceBatchRootTenancy />}
        />
    )
}

const PermissionResourceBatchRootTenancy = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Atribuir Registro', name: 'actions.AssignRecord' }}
                helpMessage="Permite designar ou associar um determinado registro a um ambiente específico."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Salvar documentos', name: 'actions.UploadDocs' }}
                helpMessage="Permite o usuário salvar os documentos da cessão."
            />
        </React.Fragment>
    )
}