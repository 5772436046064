import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react';

interface IDashboardSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const DashboardSkeleton: React.FC<IDashboardSkeleton> = ({
    children,
    isLoading
}) => {

    if (!isLoading) {
        return (children);
    }

    return (
        <Stack spacing={4} mr={3} mb={4}>
            <Stack direction='row' spacing={3} justifyContent='space-between' alignItems='center'>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={150}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={150}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            </Stack>

            <Stack direction='row' gap={10} alignItems='center'>
                {[1, 2, 3, 4, 5].map(element => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={150}
                            sx={{
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
            </Stack>

            <Stack>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={400}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            </Stack>
        </Stack>
    )
}
