import { useState } from 'react';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { Drawer } from '@uy3/web-components';
import { useBankAccontBeneficiaries } from 'contexts/bankAccount/bankAccountBeneficiaryContext';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { TableTransferContainer } from '../../TableTransfer/TableTransferContainer';
import { HeaderTransfer } from 'components/HeaderTransfer/HeaderTransfer';
import { CreatePixKeyContainer } from './CreatePixKey/CreatePixKeyContainer';
import { useBankAccountBalance } from 'contexts/bankAccount/bankAccountContext';
import Toast from 'components/Toast/Toast';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { PixQRCodeContainer } from './PixQRCode/PixQRCodeContainer';
import { PixCopyAndPasteContainer } from './PixCopyAndPaste/PixCopyAndPasteContainer';
import { ManagePixKeyFormContainer } from './ManagePixKey/ManagePixKeyForm/ManagePixKeyFormContainer';
import { useBankAccountPixKeyData } from 'contexts/bankAccount/bankAccountPixKey';

export function PixTransferFormContainer() {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [hasCreateKeyPix, setHasCreateKeyPix] = useState<boolean>(false);
    const [stepConfirmation, setStepConfirmation] = useState<"createTransfer" | "pixQrCode" | "pixCopyAndPaste" | "addPixKey" | undefined>(undefined);
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
    const { keyPixListAutocomplete, refetch: refetchListKeyPix, dataIsEmpty } = useBankAccountPixKeyData(bankAccountId!);

    const onClose = () => setStepConfirmation(undefined);

    const { status: beneficiaryStatus, error: beneficiaryError, isLoading } = useBankAccontBeneficiaries({ id: bankAccountId!, operationTypeValue: 'Pix' });
    const { data: dataBalance } = useBankAccountBalance(bankAccountId!);

    if (beneficiaryStatus === "error") <Error error={beneficiaryError} />;

    return (
        <ErrorBoundary fallback='transferência via Pix'>

            <Toast toast={toast} setToast={setToast} />

            <HeaderTransfer
                handlePix={() => setStepConfirmation('createTransfer')}
                handlePixQRcode={() => setStepConfirmation('pixQrCode')}
                handlePixCopyAndPaste={() => setStepConfirmation('pixCopyAndPaste')}
                type='Pix'
            />

            <Drawer
                title='Pagar Pix com chave'
                anchor='right'
                open={stepConfirmation === 'createTransfer'}
                onClose={onClose}
                children={
                    <CreatePixKeyContainer
                        onClose={onClose}
                        dataBalance={dataBalance}
                        setIsRefreshing={setIsRefreshing}
                        setToast={setToast}
                    />
                }
            />

            <Drawer
                title='Receber Pix QR Code'
                description='Receba transferências Pix gerando QR Codes a qualquer momento.'
                anchor='right'
                open={stepConfirmation === 'pixQrCode'}
                onClose={onClose}
                children={
                    <PixQRCodeContainer
                        onClose={onClose}
                        addPixKey={() => setHasCreateKeyPix(true)}
                        keyPixListAutocomplete={keyPixListAutocomplete}
                        refetchListKeyPix={refetchListKeyPix}
                        showActionToAddPixKey={dataIsEmpty}
                    />
                }
            />
            <Drawer
                title='Pagar Pix copia e cola'
                description='Transferências copia e cola 24hrs por dia, todos os dias.'
                anchor='right'
                open={stepConfirmation === 'pixCopyAndPaste'}
                onClose={onClose}
                children={
                    <PixCopyAndPasteContainer
                        setToast={setToast}
                        onClose={onClose}
                        setIsRefreshing={setIsRefreshing}
                    />
                }
            />

            {hasCreateKeyPix &&
                <ManagePixKeyFormContainer
                    onCloseGenericMethod={() => {
                        setHasCreateKeyPix(false);
                        setStepConfirmation('pixQrCode');
                    }}
                    refetch={refetchListKeyPix}
                    stepToStart='selectPixKey' />
            }

            <TableTransferContainer
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                operationType='Pix'
                isLoading={isLoading}
            />
        </ErrorBoundary>
    )
}