import { Stack } from '@mui/material';
import { Button} from '@uy3/web-components';
import { TextFormFieldPassword } from 'components/Forms/FormFields/TextFormFieldPassword/TextFormFieldPassword';
import { useFormContext } from 'contexts/formContext';

const ConfirmPasswordForm = () => {
    const { submitting } = useFormContext();

    return (
        <Stack direction="column" spacing={2} mb={2}>
            <TextFormFieldPassword
                name="password"
                label="Senha"
                variant="outlined"
                fullWidth
                required
            />
            <TextFormFieldPassword
                name="passwordConfirmation"
                label="Confirmar senha"
                variant="outlined"
                fullWidth
                required
            />
            <Button
                type="submit"
                variant="contained"
                size="medium"
                disabled={submitting}
                sx={{ width: '100%' }}
            >
                Confirmar
            </Button>
        </Stack>
    );
};

export default ConfirmPasswordForm;
