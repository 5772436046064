import { Stack } from '@mui/material';
import { AddIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types';

type InstallmentListHeaderProps = {
    refetch: () => void;
    setHandleAction: React.Dispatch<React.SetStateAction<{
        action: string | undefined;
        row?: IInstallmentFull;
    } | undefined>>
}

export const InstallmentListHeader = ({ refetch, setHandleAction }: InstallmentListHeaderProps) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Parcelamentos
            </Typography>

            <Stack>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refetch,
                            disabled: false,
                            icon: <UpdateIcon sx={iconSx} />
                        },
                        {
                            enable: true,
                            label: "Novo parcelamento",
                            action: () => setHandleAction({action: "add-installment"}),
                            disabled: false,
                            icon: <AddIcon sx={iconSx} />
                        },
                    ]}
                />
            </Stack>
        </Stack>)
}
