import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import React, { useState } from 'react';
import GroupList from './GroupsList';
import { useGroupListData } from 'contexts/groupContext';
import { deleteGroupById } from 'services/group';
import { useIdentity } from 'contexts/identityContext';
import { FilterProvider } from 'contexts/filterContext';
import { Button, Popup } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { ApiResponseError, ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { useTenant } from 'contexts/tenantContext';
import GroupListHeader from './GroupListHeader';

export function GroupListContainer() {
  const { token } = useIdentity();
  const { currentTenantId } = useTenant();
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
  const [page, setPage] = React.useState(0);
  const [paginationToken, setPaginationToken] = useState<any | undefined>({ 0: undefined });
  const [rowData, setRowdata] = useState<any>();
  const [toast, setToast] = useState<ToastType>(toastState);

  const {
    status,
    data: groupData,
    error,
    isFetching,
    refetch,
    isLoading
  } = useGroupListData({ page, size: rowsPerPage, paginationToken: paginationToken[page] });

  if (status === 'error') return <Error error={error} />;

  const onChangeRowsPerPage = (page: number) => {
    setPaginationToken({ 0: null });
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
    setRowsPerPage(page);
  };

  const onChangePage = (newPage: number) => {
    setPaginationToken({ ...paginationToken, [newPage]: groupData?.paginationToken });
    setPage(newPage);
  };

  const onClosePopup = () => setRowdata(undefined);
  const onDeleteGroup = async (groupNameId: string) => {
    try {
      await deleteGroupById(groupNameId, token!, currentTenantId);
      await refetch();
      onClosePopup();
      setToast({
        open: true,
        title: 'Grupo excluído com sucesso!',
        severity: 'success'
      });
    } catch (error) {
      const { errorMessage } = mapErrorResponse(error as ApiResponseError);
      setToast({
        open: true,
        title: 'Ops, ocorreu um erro!',
        description: errorMessage,
        severity: 'error'
      });
    }
  };


  return (
    <>
      {!isLoading && <Toast toast={toast} setToast={setToast} />}
      <GroupListHeader isFetching={isFetching} refetch={refetch} />
      <RefreshProgress refreshing={isFetching} />
      <FilterProvider availableFilters={{ searchString: { label: "Nome", type: "text" } }}>
        <GroupList
          queryData={groupData}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={onChangeRowsPerPage}
          setPage={onChangePage}
          page={page}
          setOpenPopup={setRowdata}
        />
      </FilterProvider>
      <Popup
        open={rowData !== undefined}
        title='Excluir'
        text={`Tem certeza que deseja excluir este grupo?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onDeleteGroup(rowData)}
              size="medium"
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </>
  );
};
