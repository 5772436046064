import { useState } from 'react';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Grid, Stack } from '@mui/material';
import { Actions } from 'components/Actions/Actions';
import {
    ApproveIcon,
    Button,
    CloseIcon,
    Drawer,
    Modal,
    RejectIcon,
    UpdateIcon,
} from '@uy3/web-components';
import { useApprovals } from 'contexts/approvalsContext';
import { FilterProvider } from 'contexts/filterContext';
import { optionsFilterBankAccountPending } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import { BankAccuntRequestList } from './BankAccuntRequestList';
import ApproveOrDisapproveFormContainer from './ApproveOrDisapproveForm/ApproveOrDisapproveFormContainer';
import { ApprovalsDetails } from '../Details/ApprovalsDetails';
import { ApprovalsHeader } from 'pages/Approvals/ApprovalsForm/ApprovalsHeader';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
export const ApprovalsBankAccountRequestListContainer = () => {
    const [actionStep, setActionStep] = useState<string | undefined>(undefined);
    const [rowAction, setRowAction] = useState<{ action: string; rowData: any } | undefined>(
        undefined
    );
    const bankAccountRequestId = rowAction?.rowData?.bankAccountRequestId;
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const onClose = () => {
        setActionStep(undefined);
        setRowAction(undefined);
    };

    const {
        pendingApprovalsData,
        isFetching,
        pendingApprovalsError,
        pendingApprovalsStatus,
        refetch,
        isLoading,
    } = useApprovals({ page, size: rowsPerPage, approvalResource: 'BankAccountRequest' });
    const bankAccountRequestSelected = pendingApprovalsData?.data?.filter((item: any) =>
        rowsSelected.includes(item?.id)
    );
    const handleSelectionModelChange = (selectionModel: GridSelectionModel) =>
        setRowsSelected(selectionModel);

    const onChangePage = (page: number) => setPage(page);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    if (pendingApprovalsStatus === 'error') return <Error error={pendingApprovalsError} />;

    return (
        <>
            <ApprovalsHeader
                Actions={
                    <Actions
                        numberOfButtons={3}
                        buttonsActionsList={[
                            {
                                enable: rowsSelected.length > 0,
                                label: 'Aprovar',
                                action: () => setActionStep('approve'),
                                icon: <ApproveIcon />,
                            },
                            {
                                enable: rowsSelected.length > 0,
                                label: 'Reprovar',
                                action: () => setActionStep('reject'),
                                icon: <RejectIcon />,
                            },
                            {
                                enable: true,
                                label: 'Atualizar',
                                action: refetch,
                                icon: <UpdateIcon />,
                            },
                        ]}
                    />
                }
            />
            {!isLoading && (
                <Grid mt={3}>
                    <RefreshProgress refreshing={isFetching} />
                </Grid>
            )}
            <Grid mt={2}>
                <FilterProvider
                    availableFilters={{
                        typeOfPendingApprovals: {
                            label: 'Aprovações',
                            type: 'select',
                            multiple: false,
                            options: optionsFilterBankAccountPending,
                        },
                    }}
                >
                    <ListSkeleton isLoading={isLoading}>
                        <BankAccuntRequestList
                            handleSelectionModelChange={handleSelectionModelChange}
                            rowsSelected={rowsSelected}
                            setRowAction={setRowAction}
                            page={page}
                            setPage={onChangePage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={onChangeRowsPerPage}
                            queryData={pendingApprovalsData}
                        />
                    </ListSkeleton>
                </FilterProvider>
            </Grid>

            <Modal
                open={actionStep === 'approve' || rowAction?.action === 'approve'}
                title="Aprovar solicitação de conta"
                description="Tem certeza que deseja aprovar essa solicitação?"
                onClose={onClose}
                children={
                    <ApproveOrDisapproveFormContainer
                        onClose={onClose}
                        refetch={refetch}
                        bankAccountrowSelected={bankAccountRequestSelected}
                        approveOrDesapprove="approve"
                        bankAccountId={bankAccountRequestId}
                        notIsNewBankAccount={rowsSelected.length > 0 ? false : true}
                    />
                }
            />

            <Modal
                open={actionStep === 'reject' || rowAction?.action === 'reject'}
                title="Rejeitar solicitação de conta"
                description="Tem certeza que deseja rejeitar essa solicitação?"
                onClose={onClose}
                children={
                    <ApproveOrDisapproveFormContainer
                        onClose={onClose}
                        refetch={refetch}
                        bankAccountrowSelected={bankAccountRequestSelected}
                        approveOrDesapprove="disapprove"
                        bankAccountId={bankAccountRequestId}
                        notIsNewBankAccount={rowsSelected.length > 0 ? false : true}
                    />
                }
            />

            <Drawer
                title="Detalhes"
                open={rowAction?.action === 'details'}
                onClose={onClose}
                anchor="right"
                children={
                    <>
                        <ApprovalsDetails detailsData={rowAction?.rowData} />
                        <Stack spacing={2} justifyContent="right" direction="row">
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                            >
                                Fechar
                            </Button>
                        </Stack>
                    </>
                }
            />
        </>
    );
};
