import { WarrantyType } from './generics';

type PayerDescription = 'NaturalPerson' | 'LegalPerson';

type CreditType =
    | 'Duplicata_Mercantil'
    | 'Duplicata'
    | 'Nota_Promissória'
    | 'Recibo'
    | 'Nota_de_Seguro'
    | 'Cheque'
    | 'Duplicata_de_Serviço'
    | 'Letras_de_Câmbio'
    | 'CPR_Rural'
    | 'CCB'
    | 'Parcela_de_Contrato'
    | 'Outros';

export class InvoiceFactoringCreateModel {
    warrantyType: WarrantyType = 'InvoiceFactoring';
    payerName?: string;
    payerRegistrationNumber!: number;
    contractNumber?: string;
    invoiceNumber?: string; // Número da duplicata
    invoiceValue!: number; // Valor da duplicata
    discountValue!: number;
    presentValue!: number;
    dueDate!: string;
    paymentAmountInCents!: number; // Valor nominal no vencimento
    paymentItemIndex!: number; // Número da parcela
    paymentItemTotal!: number; // Total de parcelas
    yourNumber?: string;
    storeCode!: number; // Código da loja
    productCode!: number;
    creditType!: CreditType;
    identification?: string; // Identificação
    emissionDate!: string;
    index!: number;
    payerDescription!: PayerDescription; // Tipo de inscrição do pagador
    payerAdress?: string;
    payerZipCodeAdress!: number;
    contractFee!: number; // Taxa do contrato

    constructor(obj?: Partial<InvoiceFactoringCreateModel>) {
        Object.assign(this, obj);
    }

    title() {
        return 'Consignado Público';
    }

    description() {
        return `XPTO`;
    }
}
