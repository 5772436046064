import { date, object, string } from "yup";

export const validationvDarfWithBarCodeSchema = () => {
    return object().shape({
        barCode: string()
            .typeError('Valor precisa ser uma string.')
            .required('Código de barras ou linha digitável precisa ser preenchido.')
            .test('length', 'O código de barras ou linha digitável inválida.', (value: any) => {
                let onlyNumbers = /\D/g;
                let barCodeOrLineDigitable = value?.replace(onlyNumbers, '');
                return barCodeOrLineDigitable.length === 44 || barCodeOrLineDigitable.length === 48;
            }), 
        registrationNumber: 
        string()
        .typeError('CPF/CNPJ: Precisa ser preenchido.')
        .required("CPF/CNPJ: Precisa ser preenchido.")
        .cpfCnpjValidation("CPF/CNPJ: Inválido"),
        dueDate: date().typeError('Data de vencimento precisa ser preenchida.').required('Data de vencimento precisa ser preenchida.')
    });
};

export const defaultValuesDarfWithBarCode = {
    barCode: "",
    registrationNumber: "",
    dueDate: null
};