import React, { useState } from 'react';
import DatasetWrapper from '../DatasetWrapper';
import JudicialProcessesList from './JudicialProcessesList';
import { Modal } from '@uy3/web-components';
import { JudicialProcessesDatasetGet, Process } from 'services/datasets/datasets.interface';
import { ProcessDetails } from '../InformationDetails';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IJudicialProcessesProps {
    recordType: string;
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function JudicialProcesses({ recordType, datasetId, disableRequestDataSet }: IJudicialProcessesProps) {
    const [modal, setModal] = useState(false);
    const [rowData, setRowData] = useState<Process>({} as Process);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'JudicialProcesses');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Processos judiciais e Administrativos ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };
    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'judicial_processes'
    ) as JudicialProcessesDatasetGet;

    const closeModal = () => setModal(false);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Modal
                title="Informações detalhada"
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={<ProcessDetails rowData={rowData} />}
            />
            {data && (
                <JudicialProcessesList data={data} setRowData={setRowData} setModal={setModal} isLoading={isLoading || status === 'loading'} />
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('JudicialProcesses')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};