import { WarrantyType } from "./generics";

type WarrantyOperationType = 'Collateral' | 'OwnershipChange';
type WarrantySplitMethodType = 'Percentage' | 'Absolute';

export class FactoringCreateModel {
    warrantyType: WarrantyType = 'Factoring';
    totalValue?: number;
    register?: string;
    type?: WarrantyOperationType; // Operação de contrato de garantia
    splitMethodType?: WarrantySplitMethodType;
    paymentNetworks?: any[];
    constituted?: boolean; // Tipo de recebível performado

    constructor(obj?: Partial<FactoringCreateModel>) {
        Object.assign(this, obj);
    }
}
