import { FC, Dispatch } from 'react'
import { GridValidRowModel } from '@mui/x-data-grid';
import { DataTable, DownloadIcon, SearchIcon } from '@uy3/web-components';
import { RowActions } from 'components/RowActions/RowActions';
import { GetListApiResponseSuccess, iconSx } from 'contexts/apiRequestContext';
import { LinesFileTypeGeneric } from 'services/walletManagement/bankSlip/bankSlip.types';
import { ShowOperationStatus } from './ShowOperationStatus';

type FileLinesListProps = {
    setRowsPerPage: (page: number) => void;
    downloadTransactionReceiptMutate: (id: string) => void;
    setPage: (newPage: number) => void;
    queryData: GetListApiResponseSuccess<LinesFileTypeGeneric>;
    rowsPerPage: number;
    page: number;
    recordType: "FileInstruction" | "FileConfirmation"
    action: (step: string, data?: LinesFileTypeGeneric) => void;
    setHandleAction: Dispatch<React.SetStateAction<{
        action: string;
        data: GridValidRowModel;
    } | undefined>>
}

export const FileLinesList: FC<FileLinesListProps> = (props) => {
    const {
        page,
        queryData,
        recordType,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        action, 
        downloadTransactionReceiptMutate
    } = props;

    const columns: any[] = [
        {
            field: 'confirmationTransactionTypeValueDisplay',
            headerName: 'Instrução',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return cellValues?.row?.detail?.confirmationTransactionTypeValueDisplay ?? "N/D"
            }
        },
        {
            field: 'documentNumber',
            headerName: 'Número do documento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => cellValues?.row?.detail?.documentNumber ?? "N/D"
        },
        {
            field: 'payerName',
            headerName: 'Pagador',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => cellValues?.row?.detail?.payerName ?? "N/D"
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => cellValues?.row?.detail !== null ?
                new Date(cellValues?.row?.detail?.createdAt).toLocaleDateString('pt-BR') : "N/D"
        },
        recordType === 'FileInstruction' ? {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            headerAlign: 'center', 
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                const status = cellValues?.row?.detail?.statusOperationValue;
                const statusDisplay = cellValues?.row?.detail?.statusOperationValueDisplay;
                return <ShowOperationStatus {...{
                    status,
                    statusDisplay
                }} />
            }
        } : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 0,
            editable: false,
            renderCell: (cellValues: any) => {
                return <RowActions
                    listButtons={[
                        {
                            action: () => action("show-details", cellValues?.row),
                            label: "Ver detalhes",
                            icon: <SearchIcon sx={iconSx} />,
                            disabled: false,
                            hide: false
                        }, 
                        {
                            action: () => downloadTransactionReceiptMutate(cellValues?.row?.id), 
                            label: "Download Recibo", 
                            disabled: false, 
                            hide: recordType === 'FileInstruction', 
                            icon: <DownloadIcon sx={iconSx}/>
                        }
                    ]}
                />
            },
        },
    ].filter(Boolean);

    return (
        <DataTable
            filterComponent={{
                showFilters: false,
                componentFilter: <></>,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    )
}
