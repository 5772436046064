import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Typography } from '@uy3/web-components';
import { CreditNoteListActions } from 'contexts/creditNote/creditNoteListActions';
import { FormProvider } from 'contexts/formContext';
import React from 'react';
import {
    defaultValuesCreditNoteForm,
    validationSchemaCreditNoteForm,
} from '../CreditNoteForm/CreditNoteSchema';

interface CreditNoteHeaderProps {
    creditData: any;
    refetch: () => void;
    setToast: any;
    toast: any;
    setSelectedStatusOfTable: any;
    selectedStatusOfTable: any;
}

const CreditNoteHeader: React.FC<CreditNoteHeaderProps> = ({
    creditData,
    refetch,
    setToast,
    toast,
    setSelectedStatusOfTable,
    selectedStatusOfTable,
}) => {
    const queryClient = useQueryClient();
    const schema = validationSchemaCreditNoteForm();
    const defaultValues = creditData ?? defaultValuesCreditNoteForm();

    const refetchNotesAndDatasets = () => {
        queryClient.invalidateQueries({
            queryKey: ['NaturalPerson', 'ConsumerRiskScore'],
        });
        queryClient.invalidateQueries({
            queryKey: ['CreditNote', 'complianceanalysis'],
        });
        queryClient.invalidateQueries({
            queryKey: ['CreditNote', 'creditanalysis'],
        });
        refetch();
    };
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Operações
            </Typography>
            <Grid mr={1}>
                {creditData !== undefined && (
                    <FormProvider
                        defaultValues={defaultValues}
                        validationSchema={schema}
                        onSubmit={(values: any) => console.log('creditData', values)}
                    >
                        <CreditNoteListActions
                            setSelectedStatusOfTable={setSelectedStatusOfTable}
                            creditData={creditData}
                            selectedStatusOfTable={selectedStatusOfTable}
                            refetchCreditList={refetchNotesAndDatasets}
                            setToast={setToast}
                            toast={toast}
                        />
                    </FormProvider>
                )}
            </Grid>
        </Stack>
    );
};

export default CreditNoteHeader;
