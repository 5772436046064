import axios from 'axios';
import jsonLocal from '../theme/json/config.json';
import { Config } from './config.interface';

const prefix = "UYZY_";

export async function loadConfig(domain: string) {
    const localStorage = window.localStorage;
    const localConfig = localStorage.localConfig; // local, undefined, 'domain especifico s3'

    let config: Object = {};
    if (localConfig === 'local') {
        config = jsonLocal as Object;
    } else if (localConfig === undefined) {
        const resp = await axios.get(`https://uyzy-config.s3.sa-east-1.amazonaws.com/${domain}.json`);
        config = resp.data;
    } else {
        const resp = await axios.get(`https://uyzy-config.s3.sa-east-1.amazonaws.com/${localConfig}.json`);
        config = resp.data;
    };

    saveConfig(config);    
    return activeConfig();
}

export function activeConfig(): Config {
    let config: any = {};
    for (var key in localStorage) {
        if (key.indexOf(prefix) > -1) {
            var item = localStorage.getItem(key);
            var configKey = key.replace(prefix, '');
            if (item) config[configKey] = JSON.parse(item);
        }
    }
    return config as Config;
}

export function clearConfig() {
    for (var key in localStorage) {
        if (key.indexOf(prefix) > -1) {
            localStorage.removeItem(key);
        }
    }
}

export function saveConfig(config: any) {
    clearConfig(); // Clear old config before saving news values
    for (var key in config) {
        localStorage.setItem(`${prefix}${key}`, JSON.stringify(config[key as keyof Config]));
    }
    localStorage.setItem(`${prefix}LOADED`, JSON.stringify(true));
}