import { Stack } from '@mui/material';
import { Button, CloseIcon, CompleteIcon, DoneIcon, LeftIcon, RightIcon, SaveIcon } from '@uy3/web-components';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';

interface IStepNewChargesButtonProps {
    currentStep: number;
    onNextStep?: () => void;
    onPrevStep?: () => void;
    onClose?: () => void;
    saveAndDoNotChargeNow?: () => void;
    continueCriatting?: () => void;
    isReverseList?: boolean
}

interface LabeledButtonProps extends React.ComponentProps<typeof Button> {
    label: string;
}

enum StepButton {
    close = 0,
    back = 1,
    nextStep = 2,
    notChargeSubmit = 3,
    chargesNow = 4,
    confirm = 5,
    continueCategories = 6,
    continueParameters = 7,
}

export enum FormStep {
    NewChargesStep = 0,
    SummaryChargesStep = 1,
    CreateChargesStep = 2,
    BillingParametersStep = 3,
    // BillingRuleStep = 4,
    BillingSummaryStep = 4,
}

const theme = activeTheme();
export const StepNewChargesButton: React.FC<IStepNewChargesButtonProps> = ({
    currentStep,
    onClose,
    onNextStep,
    onPrevStep,
    saveAndDoNotChargeNow,
    isReverseList,
    continueCriatting,
}) => {
    const { submitting: submittingApiRequest} = useApiRequest();
    const { submitting } = useFormContext();
    const htmlColorMain = theme.palette.primary.main;
    const htmlColorWhite = theme.palette.common.white;

    const buttons: LabeledButtonProps[] = [
        {
            label: 'Fechar',
            onClick: onClose,
            name: 'btn-onClose',
            variant: 'outlined',
            size: 'medium',
            startIcon: <CloseIcon htmlColor={htmlColorMain} sx={iconSx} />,
            color: 'primary',
        },
        {
            label: 'Voltar',
            onClick: onPrevStep,
            name: 'btn-onBack',
            variant: 'outlined',
            size: 'medium',
            startIcon: <LeftIcon htmlColor={htmlColorMain} sx={iconSx} />,
        },
        {
            label: 'Continuar',
            type: 'submit',
            name: 'btn-onNext',
            variant: 'contained',
            size: 'medium',
            startIcon: <RightIcon htmlColor={htmlColorWhite} sx={iconSx} />,
        },
        {
            label: 'Gerar cobrança agora',
            onClick: continueCriatting,
            name: 'btn-onNext-charges',
            variant: 'contained',
            size: 'medium',
            startIcon: <CompleteIcon htmlColor={htmlColorWhite} sx={iconSx} />,
            color: 'primary',
        },
        {
            label: 'Salvar e não cobrar agora',
            onClick: saveAndDoNotChargeNow,
            name: 'btn-not-charges',
            variant: 'outlined',
            size: 'medium',
            startIcon: <SaveIcon htmlColor={htmlColorMain} sx={iconSx} />,
            color: 'primary',
        },
        {
            label: 'Confirmar',
            type: 'submit',
            name: 'btn-confirm',
            variant: 'contained',
            disabled: submittingApiRequest,
            size: 'medium',
            startIcon: <DoneIcon htmlColor={htmlColorWhite} sx={iconSx} />,
            color: 'primary',
        },
        {
            label: 'Continuar',
            type: 'submit',
            name: 'btn-continueCategories',
            variant: 'contained',
            disabled: submitting,
            size: 'medium',
            startIcon: <SaveIcon htmlColor={htmlColorWhite} sx={iconSx} />,
            color: 'primary',
        },
        {
            label: 'Continuar', 
            // onClick: createParametersAsync,
            type: 'submit', 
            name: 'btn-continueParameters',
            variant: 'contained',
            disabled: submitting,
            size: 'medium',
            startIcon: <SaveIcon htmlColor={htmlColorWhite} sx={iconSx} />,
            color: 'primary',
        },
    ];

    const renderTheButtons = [
        [StepButton.close, StepButton.nextStep],
        [StepButton.notChargeSubmit, StepButton.chargesNow, StepButton.close],
        [StepButton.back, StepButton.nextStep],
        [StepButton.back, StepButton.confirm],
        [StepButton.back, StepButton.continueCategories],
        [StepButton.back, StepButton.continueParameters],
    ];

    const getConfigurations = () => {
        if (currentStep === FormStep.NewChargesStep) return renderTheButtons[0];
        if (currentStep === FormStep.SummaryChargesStep) return renderTheButtons[1];
        if (currentStep === FormStep.CreateChargesStep) return renderTheButtons[4];
        if (currentStep === FormStep.BillingParametersStep) return renderTheButtons[5];
        if (currentStep === FormStep.BillingSummaryStep) return renderTheButtons[3];

        return [];
    };

    const renderButtons = buttons
        .filter((_, index) => {
            const configurations = getConfigurations();
            return configurations.includes(index);
        })
        .map((button, index) => (
            <Button key={index} {...button}>
                {button.label}
            </Button>
        ))

    return (
        <Stack gap={2} direction="row" mt={3} justifyContent="flex-end" flexWrap='wrap'>
            {isReverseList ? renderButtons.reverse() : renderButtons}
        </Stack>
    );
};
