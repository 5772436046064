import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask } from 'utils/constants';
import { cnpjMask } from 'utils/constants';
import React from 'react';

interface InfoLegalPersonFormProps {
    cnaeList: AutocompleteOptionList;
    legalNatureList: any[];
}

export default function InformationLegalPersonForm({
    cnaeList,
    legalNatureList,
}: InfoLegalPersonFormProps) {
    return (
        <React.Fragment>
            <Typography variant="lg" color="common.black">
                Dados da Empresa
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <TextFormField
                    name="registrationNumber"
                    label="CNPJ"
                    fullWidth
                    placeholder="00.000.000/0000-00"
                    variant="outlined"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: cnpjMask },
                    }}
                    required
                />
                <TextFormField
                    name="companyName"
                    label="Razão Social"
                    placeholder="Razão Social"
                    variant="outlined"
                    fullWidth
                    required
                />
                <TextFormField
                    name="name"
                    label="Nome Fantasia"
                    placeholder="Nome Fantasia"
                    variant="outlined"
                    fullWidth
                    required
                />
                <TextFormField name="email" label="E-mail" variant="outlined" fullWidth required />
                <DatePickerFormField name="openDate" label="Data de Abertura" fullWidth required />
                <TextFormField
                    name="phone"
                    variant="outlined"
                    fullWidth
                    label="Telefone Celular"
                    placeholder="(00) 0000-0000"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: phoneNumberMask },
                    }}
                    required
                />
                <AutocompleteField
                    name="economicActivityCodeId"
                    label="CNAE"
                    displayName="economicActivityCode.description"
                    {...cnaeList}
                />
                <SelectFormField
                    name="legalNature"
                    label="Natureza Jurídica"
                    variant="outlined"
                    options={legalNatureList}
                    fullWidth
                    required
                />
            </Stack>
        </React.Fragment>
    );
}
