import { GetListApiResponseSuccess, GetViewApiResponse } from 'contexts/apiRequestContext';
import { createAxiosConfig, creditAxiosApi } from 'services/axiosApi/axiosApi';
import {
    LiquidationScheduleClosureModel,
    LiquidationScheduleCreditNoteFilters,
    LiquidationScheduleRequestModel,
    mapLiquidationScheduleData,
    LiquidationScheduleReadModel,
    ApprovalLiquidationScheduleCreateModel,
    mapApprovalScheduleData,
} from './types';
import { mapPaymentReviewItems } from './types/LiquidationScheduleRequestModel';
import { BankAccountCreateOrUpdateModel } from 'utils';
import { currentDateWithSetHours, toDataModel, toIsoStringWithTimezone } from 'helpers';

const url = '/CreditNote';
export async function editOrCreateLiquidationSchedule(
    data: LiquidationScheduleRequestModel,
    id: string,
    token: string = '',
    liquidationId?: string
) {
    const baseEndpoint = `${url}/${id}/LiquidationSchedule`;
    const endpoint = liquidationId ? `${baseEndpoint}/${liquidationId}` : baseEndpoint;

    const axiosConfig = createAxiosConfig(token);
    const payload = createPayload(data);

    const axiosFunction = liquidationId ? creditAxiosApi.put : creditAxiosApi.post;
    return await axiosFunction<GetViewApiResponse<LiquidationScheduleReadModel>>(
        endpoint,
        payload,
        axiosConfig
    );
}

function createPayload(data: LiquidationScheduleRequestModel) {
    return mapLiquidationScheduleData<LiquidationScheduleRequestModel>(data);
}

export async function getLiquidationScheduleCreditNote(
    filters: LiquidationScheduleCreditNoteFilters,
    creditNoteId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/LiquidationScheduleCreditNote`;
    const axiosConfig = createAxiosConfig(token, filters);

    return await creditAxiosApi.get<GetListApiResponseSuccess<LiquidationScheduleReadModel>>(
        endpoint,
        axiosConfig
    );
}

export async function approveLiquidationSchedule(
    data: ApprovalLiquidationScheduleCreateModel,
    creditNoteId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/approveLiquidationSchedule`;
    const payload = mapApprovalScheduleData(data);
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(endpoint, payload, axiosConfig);
}

export async function getPixkeyAsync(pixKey: string, token: string) {
    const endpoint = `/BankAccount/Pix/Key/${pixKey}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.get(endpoint, axiosConfig);
}

export async function getBankslipAsync(barCode: string, token: string) {
    const endpoint = `/BankAccount/Pay/Bankslip`;
    const axiosConfig = createAxiosConfig(token, { barCode });

    return await creditAxiosApi.get(endpoint, axiosConfig);
}

export async function getPixQrCodeAsync(qrCode: string, token: string) {
    const endpoint = `/BankAccount/Pix/QRCode`;
    const axiosConfig = createAxiosConfig(token, {
        qrCode,
        intendedPaymentDate: toIsoStringWithTimezone(new Date(currentDateWithSetHours)),
    });

    return await creditAxiosApi.get(endpoint, axiosConfig);
}

export async function postApproveRevisionAsync(
    payload: LiquidationScheduleReadModel[],
    creditNoteId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/paymentRevisionLiquidationSchedule`;
    const axiosConfig = createAxiosConfig(token);
    const paymentReview = mapPaymentReviewItems(payload);

    return await creditAxiosApi.post(endpoint, paymentReview, axiosConfig);
}

export async function deleteLiquidationScheduleAsync(
    creditNoteId: string,
    liquidationId: string,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/LiquidationSchedule/${liquidationId}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.delete(endpoint, axiosConfig);
}

export async function postClosureAsync(
    creditNoteId: string,
    data: LiquidationScheduleClosureModel,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/liquidationScheduleClosure`;
    const axiosConfig = createAxiosConfig(token);
    const payload = toDataModel(data as Record<keyof LiquidationScheduleClosureModel, unknown>, LiquidationScheduleClosureModel);
    return await creditAxiosApi.post(endpoint, payload, axiosConfig);
}

export async function postClosureRevisionAsync(
    creditNoteId: string,
    data: BankAccountCreateOrUpdateModel,
    token: string
) {
    const endpoint = `${url}/${creditNoteId}/liquidationScheduleClosureRevision`;
    const axiosConfig = createAxiosConfig(token);
    const payload = toDataModel(data as Record<keyof BankAccountCreateOrUpdateModel, unknown>, BankAccountCreateOrUpdateModel);
    return await creditAxiosApi.post(endpoint, payload, axiosConfig);
}
