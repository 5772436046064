import React, { ReactNode, useState } from 'react'
import { StepAddPayerEnum } from './Enums/StepAddPayerEnum';
import { Drawer } from '@uy3/web-components';
import { DescriminatorForm } from './StepsForm/DescriminatorForm/DescriminatorForm';
import { BaseStepForm } from './StepsForm/BaseStepForm';
import { FieldValues } from 'react-hook-form';
import { basicInformationSchemaValidation, basicInformationDefaultValues } from './StepsForm/BasicInformationForm/BasicInformationFormSchema';
import { BasicInformationForm } from './StepsForm/BasicInformationForm/BasicInformationForm';
import { ApiResponseError, handleOnError, showSuccessToast, showWarningToast, ToastType } from 'contexts/apiRequestContext';
import { useLegalPersonMutation } from 'contexts/legalPersonContext';
import { useNaturalPersonMutation } from 'contexts/naturalPersonContext';
import { LegalPersonFormSchema } from 'pages/LegalPerson/LegalPersonForm/LegalPersonSchema';
import { NaturalPersonSchema } from 'pages/NaturalPerson/NaturalPersonForm/NaturalPersonSchema';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { mapErrorResponse } from 'contexts/responseErrorContext';

type AddPayerFormContainerProps = {
    open: boolean;
    title: string;
    description?: string;
    onClose: () => void
    toggleDrawer: boolean;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    onSuccessGeneric?: (values: FieldValues) => void;

}

export type DiscriminatorType = "LegalPerson" | "NaturalPerson" | undefined;

export const AddPayerFormContainer: React.FC<AddPayerFormContainerProps> = ({ title, description, open, onClose, toggleDrawer = false, setToast, onSuccessGeneric }) => {
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const [stepForm, setStepForm] = useState<StepAddPayerEnum>(StepAddPayerEnum.getDescriminator);
    const [discriminator, setDiscriminator] = useState<DiscriminatorType>(undefined);
    const { setFormFieldsErrors } = useFormFieldsError();

    const onCloseFull = () => {
        onClose();
        setStepForm(StepAddPayerEnum.getDescriminator);
        setDiscriminator(undefined)
    }

    const onSuccess = (id: string) => {
        const message: { [type: string]: string } = {
            "LegalPerson": "Sucesso ao criar pessoa jurídica",
            "NaturalPerson": "Sucesso ao criar pessoa física"
        };
        showSuccessToast(message[discriminator!], description, setToast);
        onSuccessGeneric && onSuccessGeneric({ ...formValues!, id, discriminator });
        onCloseFull();
        setFormFieldsErrors([]);
    };

    const onError = async (error: ApiResponseError) => {
        const { fieldErrors, warningToastError } = mapErrorResponse(error);
        setFormFieldsErrors(fieldErrors ?? []);
        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        }
        handleOnError(error, setToast)
    };

    const { mutate: createLegalPerson, isLoading: isLoadingLegalPerson } = useLegalPersonMutation('novo', onSuccess, onError);
    const { mutate: createNaturalPerson, isLoading: isLoadingNaturalPerson } = useNaturalPersonMutation('nova', onSuccess, onError);

    const defaultProps = {
        onClose: onCloseFull,
        isLoading: isLoadingLegalPerson || isLoadingNaturalPerson,
        currentStep: stepForm
    };

    const onSubmit = (values: FieldValues) => {
        setFormValues(values)
        if (discriminator === 'LegalPerson') {
            return createLegalPerson(values as LegalPersonFormSchema)
        } else if (discriminator === 'NaturalPerson') {
            return createNaturalPerson(values as NaturalPersonSchema)
        }
    };

    const getFormByStps: { [step: number]: ReactNode } = {
        [StepAddPayerEnum.getDescriminator]: (
            <DescriminatorForm {...{
                onNextStep: (value) => {
                    setDiscriminator(value);
                    setTimeout(() => {
                        setStepForm(StepAddPayerEnum.basicInformation);
                    }, 1500);
                },
                onClose,
                discriminator
            }} />
        ),
        [StepAddPayerEnum.basicInformation]: (
            <BaseStepForm {...{
                ...defaultProps,
                defaultValues: basicInformationDefaultValues,
                validationSchema: basicInformationSchemaValidation(discriminator!),
                onSubmit: onSubmit,
                children: <BasicInformationForm {...{ discriminator: discriminator!, isLoading:isLoadingLegalPerson || isLoadingNaturalPerson}} />
            }} />
        )
    };

    const getTitleDrawer = () => {
        if (discriminator === 'LegalPerson') return 'Cadastrar | Pessoa Jurídica'
        if (discriminator === 'NaturalPerson') return 'Cadastrar | Pessoa Física'
        return title;
    }

    const optionsDescriptions: { [type: number]: string } = {
        [StepAddPayerEnum.getDescriminator]: "Escolha se deseja cadastrar uma pessoa física ou jurídica",
        [StepAddPayerEnum.basicInformation]: "Preencha o formulário abaixo para cadastrar pessoa.",
    };

    return (
        <div>
            <Drawer {...{
                title: StepAddPayerEnum.basicInformation ? getTitleDrawer() : title,
                description: optionsDescriptions[stepForm] ?? description,
                open,
                anchor: 'right',
                onClose: stepForm >= StepAddPayerEnum.basicInformation ? () => { } : onClose,
                children: getFormByStps[stepForm],
                toggleDrawer
            }} />
        </div>
    )
}
