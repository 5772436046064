import { object, string, array } from 'yup';

export function validationSchemaCreateChargesForm() {
    return object().shape({
        tags: array(string()).notRequired().nullable()
    });
}

export const defaultValuesCreateChargesForm = {
    tags: []
};
