import { Stack } from '@mui/material';
import { IInstallmentFull, IUpdateInstallment } from 'services/walletManagement/installment/installment.types'
import { InstallmentCreateForm } from '../InstallmentCreateForm/InstallmentCreateForm';
import { InstallmentEditForm } from './InstallmentEditForm';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesEditInstallmentForm, validationEditInstallmentFormSchema } from './InstallmentEditSchema';
import React from 'react';
import { ButtonsActionsInstallmentForm } from '../InstallmentCreateForm/InstallmentCreateFormContainer';
import { MutateOptions } from '@tanstack/react-query';

type InstallmentEditFormContainerProps = {
    installmentData: IInstallmentFull;
    isLoading: boolean
    onClose: () => void
    updateMutateAsync: (variables: IUpdateInstallment, options?: MutateOptions<IInstallmentFull, unknown, IUpdateInstallment, unknown> | undefined) => Promise<IInstallmentFull>
}

export const InstallmentEditFormContainer = ({ installmentData, isLoading, onClose, updateMutateAsync}: InstallmentEditFormContainerProps) => {

    const onSubmit = async (values: IUpdateInstallment) => {
        await updateMutateAsync({
            billingSettingId: values?.billingSettingId,
            communicationSettingId: values.communicationSettingId,
            walletCode: values.walletCode,
            payer: values.payer
        });
    }

    return (
        <Stack spacing={2} mb={2}>
            <FormProvider {...{
                validationSchema: validationEditInstallmentFormSchema(installmentData),
                defaultValues: defaultValuesEditInstallmentForm(installmentData),
                onSubmit,
                readOnly: isLoading,
                children: (
                    <Stack spacing={2} marginTop={-3}>
                        {installmentData?.registered === false ?
                            <InstallmentCreateForm />
                            :
                            <InstallmentEditForm />
                        }
                        <ButtonsActionsInstallmentForm {...{ onClose }} />
                    </Stack>
                )
            }}
            />
        </Stack>
    )
}
