import DatasetWrapper from 'components/Datasets/DatasetWrapper';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import { ConsumerRiskScoreGet } from 'services/datasets/datasets.interface';
import SpeedometerRiskScore from './Speedometer';
import { Stack } from '@mui/material';
import PentagonDetails from './PentagonDetails';
import Pentagon from './Pentagon';
import Toast from 'components/Toast/Toast';
import { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';

interface IConsumerRiskScore {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

function linksColor(list: any) {
    const colors: string[][] = [
        //1-2 1-2 1-3* 1-2*
        ['gray', 'gray', 'gray', 'gray'],
        //2-3 2-3 2-4* 2-3*
        ['gray', 'gray', 'gray', 'gray'],
        //3-4 3-4 3-5* 3-4*
        ['gray', 'gray', 'gray', 'gray'],
        //4-5 4-5 4-1* 4-5*
        ['gray', 'gray', 'gray', 'gray'],
        //5-1 5-1 5-2* 5-1*
        ['gray', 'gray', 'gray', 'gray'],
    ];

    if (!list) {
        return colors;
    }
    colors[0][3] = colorFromWeight(list.documentVsPhone);
    colors[0][2] = colorFromWeight(list.documentVsDevice);
    colors[1][3] = colorFromWeight(list.phoneVsDevice);
    colors[1][2] = colorFromWeight(list.phoneVsZipCode);
    colors[2][3] = colorFromWeight(list.zipCodeVsDevice);
    colors[2][2] = colorFromWeight(list.emailVsDevice);
    colors[3][3] = colorFromWeight(list.emailVsZipCode);
    colors[3][2] = colorFromWeight(list.documentVsZipCode);
    colors[4][3] = colorFromWeight(list.documentVsEmail);
    colors[4][2] = colorFromWeight(list.emailVsPhone);

    return colors;
}

function colorFromWeight(weight: number): string {
    const gray = 'gray';

    const colorsWeight: { [key: number]: string } = {
        1: '#dc3545',
        2: '#ff9f00',
        3: '#1b692c',
    };

    return colorsWeight[weight] ?? gray;
}

export function ConsumerRiskScore({ datasetId, disableRequestDataSet }: IConsumerRiskScore) {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'ConsumerRiskScore');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Score${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'consumer_risk_score'
    ) as ConsumerRiskScoreGet;
    const score = data?.score ?? [];

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {data && (
                <>
                    <Stack direction='row' mr={27} alignItems='center' justifyContent='center'>
                        <PentagonDetails
                            innerRadius={150}
                            outerRadius={160}
                            details={[
                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            marginBottom: 100,
                                            textTransform: 'uppercase',
                                        }}
                                    >
                                        CPF
                                    </div>
                                </div>,
                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            marginLeft: 150,
                                            textTransform: 'uppercase',
                                            marginBottom: 10,
                                        }}
                                    >
                                        Celular
                                    </div>
                                </div>,
                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            marginTop: 100,
                                            marginLeft: 60,
                                        }}
                                    >
                                        Device
                                    </div>
                                </div>,
                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            textTransform: 'uppercase',
                                            marginTop: 100,
                                            marginRight: 60,
                                        }}
                                    >
                                        CEP
                                    </div>
                                </div>,
                                <div>
                                    <div
                                        style={{
                                            fontWeight: 600,
                                            fontSize: 14,
                                            marginRight: 130,
                                            textTransform: 'uppercase',
                                            marginBottom: 10,
                                        }}
                                    >
                                        Email
                                    </div>
                                </div>,
                            ]}
                        >
                            <Pentagon colors={linksColor(data?.result?.scoresByAttributes)} />
                        </PentagonDetails>
                    </Stack>
                    <Stack alignContent='center' mt={5} alignItems='center' justifyContent='center'>
                        <SpeedometerRiskScore value={score} />
                    </Stack>
                </>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('ConsumerRiskScore')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};