/**
 * Checks if the params date is in the past relative to the current date.
 *
 * @param {string} date - The date will compare.
 * @returns {boolean} - Returns true if the date is in the past, otherwise false.
 */

export const isDateInThePast = (date: Date): boolean => {
    const targetDate = new Date(date.setHours(0, 0, 0, 0));
    const currentDate = new Date(new Date().setHours(0, 0, 0, 0));

    if (currentDate.getTime() > targetDate.getTime()) {
        return true;
    }

    return false;
}

