import { FormProvider } from 'contexts/formContext'
import { GenerateCnab } from './GenerateCnab'
import { validationGenerateCnabSchema, defaultValuesGenerateCnab } from './GenerateCnabSchema'
import React from 'react'
import { GenerateCNAB400FileInstructionType } from 'services/walletManagement/files/fileInstruction/fileInstruction.type'

type GenerateCnabContainerProps = {
    handleSubmit: (values: GenerateCNAB400FileInstructionType) => void;
    onClose: () => void; 
}

export const GenerateCnabContainer: React.FC<GenerateCnabContainerProps> = ({handleSubmit, onClose}) => {
    return (
        <FormProvider
            defaultValues={defaultValuesGenerateCnab}
            validationSchema={validationGenerateCnabSchema()}
            onSubmit={handleSubmit}
        >
            <GenerateCnab onClose={onClose}/>
        </FormProvider>
    )
}
