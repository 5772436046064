import React from 'react'
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip'
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions'

export const UserActions = () => {
    return (
        <ActionsPermissions
            PermissionResource={<PermissionResourceUser />}
        />
    )
}

const PermissionResourceUser = () => {
    return (
        <React.Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Desabilitar usuário', name: 'actions.DisableUser' }}
                helpMessage="Permite desabilitar um usuário desde que o mesmo esteja habilitado."
            />
            <CheckboxWithTooltip
                checkboxFormField={{ label: 'Habilitar usuário', name: 'actions.EnableUser' }}
                helpMessage="Permite habilitar um usuário desde que o mesmo esteja desabilitado."
            />
        </React.Fragment>
    )
}