import React from 'react';
import { ApproveIcon, DataTable, RejectIcon, ReportIcon, SearchIcon } from '@uy3/web-components';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Grid, Stack } from '@mui/material';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { ApprovalsFull } from 'services/approvals/approvals';
import { RowActions } from 'components/RowActions/RowActions';
import { CreditApprovContainerFilters } from 'components/DataTableFilters/CreditNoteApprovalsFilters/CreditNoteApprovalsContainer';
import { Link } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';

interface CreditNoteListApprovalsProps {
  rowsSelected: GridRowId[];
  handleSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
  page: number;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setRowAction: React.Dispatch<React.SetStateAction<{
    action: string;
    rowData: ApprovalsFull;
  } | undefined>>;
  queryData: any;
};

export const CreditNoteListApprovals: React.FC<CreditNoteListApprovalsProps> = ({
    handleSelectionModelChange,
    rowsSelected,
    page,
    queryData,
    rowsPerPage,
    setRowAction,
    setPage,
    setRowsPerPage,
}) => {
    const columns: GridColDef[] | any[] = [
        {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            flex: 1,
            maxWidth: 170,
            editable: false,
            hideSortIcons: true,
        },
        {
            field: 'creditNoteNo',
            headerName: 'Número da operação',
            hideSortIcons: true,
            flex: 1,
            maxWidth: 190,
            editable: false,
            renderCell: ({ row }) => `#${row?.creditNoteOp?.creditNoteNo}`,
        },
        {
            field: 'personDisplay',
            headerName: 'Tomador',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => row?.creditNoteOp?.personDisplay,
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => row?.creditNoteOp?.statusDisplay,
        },
        {
            field: 'invoiceValue',
            headerName: 'Valor',
            maxWidth: 200,
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => formatCurrencyInCents(row?.creditNoteOp?.invoiceValue),
        },
        {
            field: 'approvalTypeDisplay',
            headerName: 'Tipo de solicitação',
            hideSortIcons: true,
            flex: 1,
            editable: false,
        },
        {
            field: `creditNoteOp.createdAt`,
            headerName: 'Data de início',
            hideSortIcons: true,
            flex: 1,
            maxWidth: 190,
            editable: false,
            renderCell: ({ row }) => {
                let value = row?.creditNoteOp?.createdAt;
                return new Date(value).toLocaleDateString('pt-BR');
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            renderCell: ({ row }) => {
                return (
                    <Stack direction="row" alignItems='center'>
                        <Link to={`/ccb/operacoes/${row?.creditNoteOpId}`}>
                            <SearchIcon sx={iconSx} />
                        </Link>
                        <RowActions
                            listButtons={[
                                {
                                    action: () =>
                                        setRowAction({
                                            action: 'details',
                                            rowData: row,
                                        }),
                                    disabled: false,
                                    icon: <ReportIcon />,
                                    label: 'Detalhes',
                                },
                                {
                                    action: () =>
                                        setRowAction({
                                            action: 'approve',
                                            rowData: row,
                                        }),
                                    disabled: false,
                                    icon: <ApproveIcon />,
                                    label: 'Aprovar',
                                },
                                {
                                    action: () =>
                                        setRowAction({
                                            action: 'reject',
                                            rowData: row,
                                        }),
                                    disabled: false,
                                    icon: <RejectIcon />,
                                    label: 'Rejeitar',
                                },
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            filterComponent={{
                showFilters: true,
                componentFilter: (
                    <Grid mb={-0.5}>
                        <CreditApprovContainerFilters />
                    </Grid>
                ),
            }}
            page={queryData ? queryData.page ?? 0 : page}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            checkboxSelection={true}
            keepNonExistentRowsSelected
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleSelectionModelChange}
        />
    );
};
