import { object, string } from 'yup';

export const validationBankAccountOperatorFormSchema = () => {
    return object().shape({
        userId: string()
            .typeError('Selecione um operador.')
            .required('Operador: Precisa ser preenchido.'),
        level: string()
            .typeError('Selecione um nível de permissão.')
            .required('Nível de Permissão: Precisa ser preenchido.'),
        tenantName: string()
            .required('Selecione um correspondente.')
            .typeError('Correspondente inválido.'),
    });
};

export const defaultValuesBankAccountOperatorForm = {
    userId: null,
    level: null,
    tenantName: null,
};
