import ReleaseToPaymentForm from './ReleaseToPaymentForm';
import { FormProvider } from 'contexts/formContext';
import { FieldValues } from 'react-hook-form';
import { validationSchemaReleaseToPayment } from './ReleaseToPaymentSchema';
import { ApiResponseError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useReleaseToPaymentMutation } from 'contexts/creditNote/creditContext';

type ReleaseToPaymentProps = {
    handleOnClose: () => void;
    creditNoteId: string;
    handleErrorResponse: (error: ApiResponseError) => void;
    navigateWithFilters: (path: string) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export default function ReleaseToPayment({
    creditNoteId,
    handleOnClose,
    handleErrorResponse,
    navigateWithFilters,
    setToast,
}: ReleaseToPaymentProps) {
    const onSuccess = () => {
        showSuccessToast('Operação realizada com sucesso!', undefined, setToast);
        navigateWithFilters('/ccb/operacoes');
        handleOnClose();
    };

    const { mutateAsync } = useReleaseToPaymentMutation(
        creditNoteId,
        onSuccess,
        handleErrorResponse
    );

    const handleOnSubmit = (values: FieldValues) => mutateAsync(values);

    return (
        <FormProvider
            validationSchema={validationSchemaReleaseToPayment}
            defaultValues={{}}
            onSubmit={handleOnSubmit}
        >
            <ReleaseToPaymentForm onClose={handleOnClose} />
        </FormProvider>
    );
}
