/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, IconButton, Stack } from '@mui/material';
import { DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid/';
import RiskScoreContainer from 'components/RiskScore/RiskScore/RiskScoreContainer';
import { useAppConfig } from 'contexts/appConfig';
import { BankAccountRequestFiltersContainer } from 'components/DataTableFilters/BankAccountRequestFilters/BankAccountRequestFiltersContainer';
import DatasetsStatusColumnContainer from 'components/RiskScore/DatasetsStatusColumn/DastasetsStatusColumnContainer';
import React, { useEffect, useState } from 'react';
import ButtonRemoveSelectedRow from 'components/DataTableFilters/MethodsForFilters/ButtonRemoveSelectedRow';
import { checkActionsPerms } from 'services/permissions';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import { useTenant } from 'contexts/tenantContext';

interface IBankAccountList {
    queryData: any;
    rowsSelected: string[] | GridRowId[] | undefined;
    setRowsSelected: (rows: any) => void;
    setRowData: (row: any) => void;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
}

const BankAccountList = ({
    queryData,
    handleSelectionModelChange,
    rowsSelected,
    setRowsSelected,
    rowsPerPage,
    setRowData,
    setRowsPerPage,
    setPage,
    page,
}: IBankAccountList) => {
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const [selectionModel, setSelectionModel] = useState([]);
    const { isParentTenant, isRootTenancy } = useTenant();
    const hasPermissionCreditNoteToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, 'CreditNote')
    const hasPermissionPersonToReadDataset = () => {
        if (
            checkActionsPerms('ReadDataSet', permissionsData, 'NaturalPerson') ||
            checkActionsPerms('ReadDataSet', permissionsData, 'LegalPerson')
        ) return true;
        return false;
    }

    useEffect(() => {
        setRowsSelected(selectionModel);
    }, [selectionModel]);

    const handleClearSelection = () => {
        setSelectionModel([]);
        setRowsSelected([]);
    };

    const useComplianceAnalysis = appConfig.USE_COMPLIANCE_ANALYSIS_DATASET;

    const columns: GridColDef[] | any[] = [
        isParentTenant
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                width: 141,
                editable: false,
                align: 'left',
                renderCell: (cellValues: any) => cellValues.value,
            }
            : undefined,
        {
            field: 'partnerName',
            headerName: 'Nome do Parceiro',
            hideSortIcons: true,
            width: 100,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues.value ?? 'N/D',
        },
        {
            field: 'ownerDisplay',
            headerName: 'Solicitante',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues.value,
        },
        {
            field: 'ownerRegistrationNumber',
            headerName: 'CPF/CNPJ',
            hideSortIcons: true,
            minWidth: 180,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatDocumentNumber(cellValues.value) ?? 'N/D',
        },
        {
            field: 'typeDisplay',
            headerName: 'Tipo de Conta',
            hideSortIcons: true,
            minWidth: 130,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues.value,
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 170,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues.value,
        },
        {
            field: 'bankAccountNumber',
            headerName: 'Nº da conta',
            hideSortIcons: true,
            minWidth: 110,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => cellValues.value ?? 'N/D',
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 110,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        useComplianceAnalysis && hasPermissionCreditNoteToReadDataset
            ? {
                field: 'statusMotorAnalysis',
                headerName: 'Compliance',
                hideSortIcons: true,
                minWidth: 113,
                editable: false,
                align: 'left',
                renderCell: (cellValues: any) => {
                    return (
                        <DatasetsStatusColumnContainer
                            recordType={'BankAccountRequest'}
                            id={cellValues?.id}
                            datasetName="complianceanalysis"
                        />
                    );
                },
            }
            : undefined,
        isRootTenancy && hasPermissionPersonToReadDataset()
            ? {
                field: 'riskScore',
                headerName: 'Score de risco',
                hideSortIcons: true,
                minWidth: 120,
                editable: false,
                align: 'left',
                renderCell: (cellValues: any) => {
                    var isLegalperson = cellValues?.row?.ownerDiscriminator === 'LegalPerson';
                    return (
                        <RiskScoreContainer
                            discriminator={cellValues.row?.ownerDiscriminator}
                            id={isLegalperson ? undefined : cellValues.row?.ownerId}
                        />
                    );
                },
            }
            : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            align: 'center',
            headerAlign: 'center',
            minWidth: 80,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        <Link to={`/contas-digitais/solicitacoes/${cellValues.id}`}>
                            <IconButton name="btn-edit">
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {hasPermission('BankAccountRequest', 'Delete') &&
                            <IconButton onClick={() => setRowData(cellValues.row)} name="btn-delete">
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    return (
        <Box p={0} mt={2} mr={2} position="relative">
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <BankAccountRequestFiltersContainer />,
                }}
                columns={columns}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                paginationMode="server"
                pageCount={queryData?.totalPages ?? 0}
                checkboxSelection={true}
                keepNonExistentRowsSelected={true}
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={handleSelectionModelChange}
            />

            {rowsSelected!?.length > 0 && queryData !== undefined && (
                <ButtonRemoveSelectedRow
                    handleClearRow={handleClearSelection}
                    rowsSelected={rowsSelected}
                />
            )}
        </Box>
    );
};

export default BankAccountList;
