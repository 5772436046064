import { object, string } from "yup";

export function validationUploadFileInstructionSchema() {
  return object().shape({
    name: string().typeError('Nome: precisa ser preenchido').required('Nome: precisa ser preenchido')
  });
}

export const defaultValuesUploadFileInstruction = {
    name: null,
};