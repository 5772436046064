import type { FunctionComponent } from 'react';
import type { OfflineProps } from './Offline.interface';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Button, Typography } from '../../atoms';
import { LeftIcon, OfflineIcon } from '../../icons';

export const Offline: FunctionComponent<OfflineProps> = ({
    highlightedText = 'Internet Banking',
    errorMessage
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const BackToHomeButton = () => {
        return (
            <Button
                size="medium"
                variant="contained"
                color="primary"
                href="/"
                startIcon={<LeftIcon htmlColor="#ffff" sx={{ height: 25, width: 25 }} />}
            >
                Voltar para o início
            </Button>
        );
    };

    const isNotEmpty = (value: string | undefined | null) => {
        return value !== null && value !== undefined && value !== '';
    };

    return (
        <Stack
            sx={{
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
            >
                <Grid item mr={3}>
                    <OfflineIcon
                        sx={{
                            height: 270,
                            width: 270
                        }}
                    />
                </Grid>
                <Grid direction="column">
                    <Grid item mb={2}>
                        <Typography color="neutral.medium" variant={isMobile ? 'xxl' : 'xxxl'}>
                            Serviço temporariamente indisponível
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography color="neutral.medium" variant={isMobile ? 'xs' : 'sm'}>
                            O serviço de&nbsp;
                            <Typography color="primary" variant={isMobile ? 'xs' : 'sm'}>
                                {highlightedText}
                            </Typography>
                            &nbsp;encontra-se temporariamente indisponível no momento.
                            <br />
                            Por favor, tente novamente mais tarde.
                        </Typography>
                    </Grid>
                    {isNotEmpty(errorMessage) && (
                        <Grid item>
                            <Typography color="neutral.medium" variant={isMobile ? 'xs' : 'sm'}>
                                {errorMessage}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item mt={2.5}>
                        <BackToHomeButton />
                    </Grid>
                </Grid>
            </Grid>
        </Stack>
    );
};
