import { Theme } from '@emotion/react';
import { Skeleton, SxProps } from '@mui/material';
import React from 'react'; 

type CurrencyValueSkeletonProps = {
    width?: string; 
    sx?: SxProps<Theme> 
}

export const CurrencyValueSkeleton: React.FC<CurrencyValueSkeletonProps> = ({
    width = '200px', 
    sx
}) => {
    return (
        <Skeleton
            variant='rectangular'
            animation='pulse'
            width={width}
            height='20px'
            sx={!!sx ? {...sx, borderRadius: '5px'} : {
                mt: 2, 
                borderRadius: '5px'
            }}
        />
    )
}
