import React from 'react'
import { InformationsWallet } from './InformationsWallet'
import { useParams } from 'react-router';
import { defaultValuesWalletForm, validationWalletFormSchema } from '../../WalletFormSchema';
import { FormProvider } from 'contexts/formContext';
import { useQueryCacheContext } from 'contexts/queryCacheContext';

export const InformationsWalletContainer = () => {
  const { id } = useParams();
  const { getQueryCache } = useQueryCacheContext();
  const queryDataByKey = getQueryCache(['get-wallet-by-id', id]);
  
  const defaultValuesCustom = {
    ...queryDataByKey?.bankSlipCosts,
    ...queryDataByKey
  };

  return (
    <React.Fragment>
      {queryDataByKey !== undefined &&
        <FormProvider
          defaultValues={defaultValuesCustom ?? defaultValuesWalletForm}
          validationSchema={validationWalletFormSchema()}
          onSubmit={() => { }}
          readOnly={true}
        >
          <InformationsWallet />
        </FormProvider>}
    </React.Fragment>
  )
}

