import { PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import moment from 'moment';
import { object, date, number } from 'yup';

export const validationPaySliptDetailsSchema = (bankSlipData: PaymentBankSlip) => {
    return object().shape({
        paymentDate: date()
            .typeError('Data inválida.')
            .required('Data de pagamento: Precisa ser preenchido.'),
        paymentValue: number().when('field', {
            is: () => bankSlipData.maximumValue !== bankSlipData.minimumValue,
            then: number()
                .typeError('Valor do pagamento precisa ser preechido.')
                .required('Valor do pagamento: precisa ser preechido.')
                .min(bankSlipData.minimumValue, `Valor mínimo ${formatCurrencyInCents(bankSlipData.minimumValue)}`)
                .max(bankSlipData.maximumValue, `Valor máximo ${formatCurrencyInCents(bankSlipData.maximumValue)}`),
            otherwise: number().notRequired(),
        })
    });
};

export const defaultValuesPaySliptDetails = (paymentValue: number) => {
    return {
        paymentDate:
            parseInt(moment().format('HH')) < 22
                ? moment().format('yyyy-MM-DD')
                : moment().add(1, 'day').format('yyyy-MM-DD'),
        paymentValue: paymentValue ?? null
    };
};
