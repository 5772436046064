import { Grid } from "@mui/material";
import { TooltipProps } from "./Tooltip.interface";
import { Typography } from "@uy3/web-components";

export const InfoTooltipContent: React.FC<TooltipProps> = ({ title, description }) => (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Grid item>
            <Typography variant="xs" textAlign="center">
                {title}
            </Typography>
        </Grid>
        <Grid
            item
            sx={{
                textAlign: 'start',
                inlineSize: '200px',
                overflowWrap: 'break-word',
                lineHeight: '16.8px',
            }}
        >
            {description}
        </Grid>
    </Grid>
);
