import { Stack } from '@mui/material';
import { Button, CancelIcon, GroupedButtons, RightIcon, Typography } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { iconSx } from 'contexts/apiRequestContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import React, { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { activeTheme } from 'services/theme';

type ApprovalLimitFormProps = {
    onClose: () => void;
    rowData: FieldValues;
};

type HoursType = "businessHours" | "nonBusinessHours"
type FieldsComponentProps = {
    type: string;
    hours: HoursType;
    currentLimit: FieldValues
}

const theme = activeTheme();

const FieldsComponent = ({ type, hours, currentLimit}: FieldsComponentProps) => {
    
    const getCurrentalueByType = (field: string, label?: string) => { 
        const value = currentLimit?.[hours]?.[field]; 
        const currentLimitValue = value !== null ? formatCurrencyInCents(value) : "Limite padrão da instituição"; 
        return `${label} - Limite atual: ${currentLimitValue}`
    }; 

    return (
        <Stack spacing={2}>
            <CurrencyFormField
                label={getCurrentalueByType('valueInCentsOwnOwnership', 'Mesma titularidade')}
                placeholder="Manter limite padrão"
                name={`${type}.${hours}.valueInCentsOwnOwnership`}
                variant='outlined'
                fullWidth
            />
            <CurrencyFormField
                label={getCurrentalueByType('valueInCentsNaturalPerson', 'Transferência para PF')}
                placeholder="Manter limite padrão"
                name={`${type}.${hours}.valueInCentsNaturalPerson`}
                variant='outlined'
                fullWidth
            />
            <CurrencyFormField
                label={getCurrentalueByType('valueInCentsLegalPerson', 'Transferência para PJ')}
                placeholder="Manter limite padrão"
                name={`${type}.${hours}.valueInCentsLegalPerson`}
                variant='outlined'
                fullWidth
            />
        </Stack>
    )
}

const TypographyCustom = ({ value }: { value: string }) => {
    return (
        <Typography variant='md' fontSize='16.65px' fontWeight='bold'>{value}</Typography>
    )
}

export const ApprovalLimitForm = ({ onClose, rowData }: ApprovalLimitFormProps) => {
    const [hoursSelected, setHoursSelected] = useState<HoursType>("businessHours");

    const bankAccountEffectiveLimits = rowData?.bankAccountEffectiveLimits;

    const currentLimitsValue: {[type:string]: any} = {
        bankSlipLimit: bankAccountEffectiveLimits?.bankSlipLimit,
        pixLimit: bankAccountEffectiveLimits?.pixLimit,
        tedLimit: bankAccountEffectiveLimits?.tedLimit,
        transferLimit: bankAccountEffectiveLimits?.transferLimit
    }

    const handleSelectHours = (i: number) => {
        const value = i === 0 ? 'businessHours' : 'nonBusinessHours'
        setHoursSelected(value);
    }

    return (
        <Stack spacing={2}>
            <GroupedButtons
                size='large'
                width={240}
                groupedButtons={["Horário comercial", 'Fora do horário']}
                onClick={handleSelectHours}
            />
            <TypographyCustom value="Pix" />
            <FieldsComponent
                currentLimit={currentLimitsValue?.pixLimit}
                hours={hoursSelected}
                type='pixLimit'
            />
            <TypographyCustom value="TED" />
            <FieldsComponent
                currentLimit={currentLimitsValue?.tedLimit}
                hours={hoursSelected}
                type='tedLimit'
            />
            <TypographyCustom value="TRANSFERÊNCIA INTERNA" />
            <FieldsComponent
                currentLimit={currentLimitsValue?.transferLimit}
                hours={hoursSelected}
                type='transferLimit'
            />
            <TypographyCustom value="BOLETO" />
            <FieldsComponent
                currentLimit={currentLimitsValue?.bankSlipLimit}
                hours={hoursSelected}
                type='bankSlipLimit'
            />

            <Stack
                direction='row'
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CancelIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    name='btn-close-involved-form'
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name='btn-add-involved-form'
                    type="submit"
                    startIcon={<RightIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                >
                    Avançar
                </Button>
            </Stack>
        </Stack>
    )
}
