import { string, object } from 'yup';

export function validationSchemaDocForm() {
    return object().shape({
        displayName: string().typeError('Nome de exibição inválido.').required('Nome de exibição: Precisa ser preenchido.'),
        fileName: string().typeError('Upload inválido.').required('Upload: É necessário fazer o upload do arquivo.'),
        fileType: string().typeError('Tipo de documento inválido.').required('Tipo de documento: Precisa ser preenchido.')
    });
};

export const defaultValuesDocForm = {
    id: null,
    fileType: null,
    fileName: null,
    displayName: null
};

