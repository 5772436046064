import { FormProvider } from 'contexts/formContext'
import React, { Fragment, useState } from 'react'
import { InstallmentCreditNote } from './InstallmentCreditNote'
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { useBillingParametersList } from 'contexts/wallet/billingParameters/billingParametersContext';
import { useCommunicationSettingList } from 'contexts/wallet/communicationSetting/communicationSettingContext';
import { isValidString } from 'helpers/formats/StringFormats';
import { defaultValuesInstallmentCreditNote, validationSchemaInstallmentCreditNote } from './InstallmentCreditNoteSchema';
import { ApiResponseError, handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useCreateInstallmentMutate } from 'contexts/creditNote/creditContext';
import { FieldValues } from 'react-hook-form';

type InstallmentCreditNoteContainerProps = {
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    creditNoteId: string
}

export const InstallmentCreditNoteContainer = ({ onClose, setToast,creditNoteId }: InstallmentCreditNoteContainerProps) => {
    const [walletCode, setWalletCode] = useState<number | undefined>(undefined)
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });
    const { billingParameterAutoCompleteProps } = useBillingParametersList({ page: 0, size: 10, walletsCode: walletCode }, false, walletCode);
    const { communicationSettingAutocomplete } = useCommunicationSettingList({ walletsCode: [walletCode!] });

    const { mutateAsync: installmentMutateAsync, isLoading } = useCreateInstallmentMutate(creditNoteId!, 
        () => {
            showSuccessToast("Sucesso ao gerar cobrança", "", setToast);
            onClose(); 
        }, 
        (response: ApiResponseError) => handleOnError(response, setToast)
    )

    const onSubmit = async (values: FieldValues) => {
        if (values.useFundSetting === true) {
            await installmentMutateAsync(null);
        }
        await installmentMutateAsync({
            billingSettingId: values?.billingSettingId, 
            communicationSettingId: values?.communicationSettingId, 
            walletCode: values?.walletCode
        });
    };

    return (
        <Fragment>
            <FormProvider
                defaultValues={defaultValuesInstallmentCreditNote}
                validationSchema={validationSchemaInstallmentCreditNote()}
                onSubmit={onSubmit}
                onChangeField={[
                    {
                        fieldName: 'walletCode',
                        delegate: (value: string, setValue: (name: any, value: any) => void) => {
                            if (isValidString(String(value))) {
                                setWalletCode(Number(value))
                            }
                        }
                    }
                ]}
            >
                <InstallmentCreditNote {
                    ...{
                        communicationSettingAutocomplete,
                        billingSettingAutocomplete: billingParameterAutoCompleteProps,
                        walletAutocomplete: walletCodeAutoCompleteProps,
                        onClose, 
                        isLoading
                    }}
                />
            </FormProvider>
        </Fragment>
    )
}
