import { Grid, Stack } from '@mui/material'
import { Button, CancelIcon, SaveIcon } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import { activeTheme } from 'services/theme'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext'

type TransferBankSlipFormProps = {
    onClose: () => void
}

const theme = activeTheme();
export const TransferBankSlipForm = ({ onClose }: TransferBankSlipFormProps) => {
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });

    return (
        <Stack spacing={2}>
            <Grid>
                <AutocompleteField
                    label="Selecione a carteira"
                    name="walletCode"
                    displayName="walletCodeDisplay"
                    required
                    {...walletCodeAutoCompleteProps!}
                />
            </Grid>
            <Stack spacing={2} direction='row' justifyContent='right'>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                >
                    Cancelar
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    startIcon={<SaveIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
