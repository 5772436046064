import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react';

interface IDrawerSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const DrawerSkeleton: React.FC<IDrawerSkeleton> = ({
    children,
    isLoading
}) => {
    if (!isLoading) return (children);

    return (
        <Stack spacing={3} mt={3}>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={60}
                sx={{
                    borderRadius: '3px'
                }}
            />
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={25}
                sx={{
                    borderRadius: '3px'
                }}
            />
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={25}
                sx={{
                    borderRadius: '3px'
                }}
            />
            <Stack pt={5} spacing={2}>
                <Skeleton 
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={60}
                    sx={{
                        borderRadius: '3px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={60}
                    sx={{
                        borderRadius: '3px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={60}
                    sx={{
                        borderRadius: '3px'
                    }}
                />

            </Stack>

            <Stack pt={5} spacing={2}>
                <Skeleton 
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={50}
                    sx={{
                        borderRadius: '3px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={50}
                    sx={{
                        borderRadius: '3px'
                    }}
                />

            </Stack>
        </Stack>
    )
}
