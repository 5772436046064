import { useState } from 'react';
import { Button, DataTable, Modal, SearchIcon } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import DatasetWrapper from './DatasetWrapper';
import { PgfnItem, PgfnGet } from 'services/datasets/datasets.interface';
import { Grid, Stack } from '@mui/material';
import { HandleTitle } from './DatasetNaturalPerson/TypographyAndValues';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IPgfnListProps {
    recordType: string;
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

const details = (certificate: PgfnItem) => {
    const result = certificate;

    const emissionDate =
        result?.emissionDate && new Date(result?.emissionDate).toLocaleDateString('pt-BR');
    const validityDate =
        result?.validityDate && new Date(result?.validityDate).toLocaleDateString('pt-BR');
    return (
        <>
            <HandleTitle
                title={'Há pendências com a Procuradoria-Geral da Fazenda Nacional(PGFN)'}
                value={certificate?.pgfnClearance ? 'Não' : 'Sim'}
                key="pgfnClearance"
            />
            <HandleTitle
                title={'Tipo de consulta'}
                value={result?.queryType ?? 'N/D'}
                key="queryType"
            />
            <HandleTitle
                title={'Identificação do pagador de impostos'}
                value={result?.taxPayer ?? 'N/D'}
                key="taxPayer"
            />
            <HandleTitle
                title={'Data de emissão'}
                value={emissionDate ?? 'N/D'}
                key="emissionDate"
            />
            <HandleTitle
                title={'Data de validade'}
                value={validityDate ?? 'N/D'}
                key="validityDate"
            />
        </>
    );
};

export function PGFNList({ recordType, datasetId, disableRequestDataSet }: IPgfnListProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'Pgfn');
    const { setSubmitError } = useApiRequest();
    const [modal, setModal] = useState(false);
    const [rowData, setRowData] = useState<any>();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Procuradoria geral da Fazenda Nacional ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'origin',
            headerName: 'Origem',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'baseStatus',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'items',
            headerName: 'Detalhes',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => {
                                var rowData = details(cellValues.row as PgfnItem);
                                setRowData(rowData);
                                setModal(true);
                            }}
                            startIcon={<SearchIcon sx={{ height: 20, width: 20, ml: 1 }} />}
                        />
                    </Stack>
                );
            },
        },
    ];

    const data = dataset?.find((dataset) => dataset?.dataSet === 'pgfn') as PgfnGet;
    const result = data?.result?.items;
    const closeModal = () => setModal(false);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Modal
                title="Informações detalhada"
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={<>{rowData}</>}
            />
            <DataTable
                loading={isLoading || status === 'loading'}
                getRowId={() => Math.random().toString()}
                columns={columns}
                rows={result ?? []}
                page={page}
                rowCount={result?.length ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('Pgfn')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};