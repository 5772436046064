import { NotificationsReadModel } from 'services/notifications/types/notificationsReadModel';
import { Notification } from './Notification';
import { NotificationsListStack } from './notification.styled';
import { getRelativeDate } from 'helpers/formats/DateFormat';
import { Fragment } from 'react/jsx-runtime';
import AccordionContent from '../../../../components/AccordionContent/AccordionContent';
import { Stack } from '@mui/material';
import { Button, Typography } from '@uy3/web-components';

type ListNotificationsProps = {
    notificationsData: NotificationsReadModel[] | undefined;
    lastRead?: string;
    handleLastReadAndRedirect: (notification: NotificationsReadModel) => void
    enableRestoreFilters: boolean
    onNextPageNotification: () => void
    restoreFiltersPagination: () => void
};

export const ListNotifications = ({
    lastRead,
    notificationsData,
    handleLastReadAndRedirect,
    enableRestoreFilters,
    onNextPageNotification,
    restoreFiltersPagination
}: ListNotificationsProps) => {
    const notificationsList = (notificationsData as NotificationsReadModel[]) ?? [];

    const checkNotificationIsNew = (createdAt: string) => {
        if (lastRead === null || lastRead === undefined) {
            return true;
        }
        const createdAtDate = new Date(createdAt).getTime();
        const lastReadDate = new Date(lastRead).getTime();
        return createdAtDate > lastReadDate;
    }

    const mapperListNotifications = notificationsList.map((notification) => {
        return {
            ...notification,
            groupDisplay: getRelativeDate(notification.createdAt),
            isNewNotification: checkNotificationIsNew(notification.createdAt)
        };
    });

    const groupedNotifications = mapperListNotifications.reduce((acc: any, notification: any) => {
        const { groupDisplay } = notification as NotificationsReadModel;
        if (!acc[groupDisplay]) {
            acc[groupDisplay] = [];
        }
        acc[groupDisplay].push(notification);
        return acc;
    }, {});

    const message = enableRestoreFilters ? "Não existem mais notificações a serem exibidas." : "Sem nenhuma novidade por aqui. Avisaremos quando algo precisar da sua atenção."

    const goBackToStartScroll = () => {
        const container = document.querySelector('.startScroll');
        if (container) {
            container.scrollTop = 0;
        };
    };

    const handlePagination = () => {
        if (enableRestoreFilters) restoreFiltersPagination();
        else onNextPageNotification();
        goBackToStartScroll();
    }; 

    return (
        <NotificationsListStack className='startScroll'>
            {(notificationsData?.length ?? 0) > 0 ? (
                <Fragment>
                    {Object.entries(groupedNotifications)?.map((value, index) => {
                        const notificationsByPeriod = value[1] as NotificationsReadModel[];
                        const title = value[0] as string;
                        return (
                            <Fragment>
                                <AccordionContent {...{
                                    title: title,
                                    expandedIndex: index,
                                    content: (
                                        <Fragment>
                                            {notificationsByPeriod.map((item, index) => {
                                                return (
                                                    <Notification
                                                        key={index}
                                                        notification={item}
                                                        handleLastReadAndRedirect={() => handleLastReadAndRedirect(item)}
                                                    />
                                                );
                                            })}
                                        </Fragment>
                                    ),
                                }} />
                            </Fragment>
                        );
                    })}
                </Fragment>
            ) : (
                <Stack textAlign='center' justifyContent='center' mt={5} p={3}>
                    <Typography variant='h5' fontWeight='700'>
                        {message}
                    </Typography>
                </Stack>
            )}
            <Button
                onClick={handlePagination}
                size='medium'
                variant={enableRestoreFilters ? 'outlined' : 'contained'}
                sx={{ margin: '20px' }}
            >
                {enableRestoreFilters ? "Retaurar filtros" : "Ver mais"}
            </Button>
        </NotificationsListStack>
    );
};
