import DatasetWrapper from '../DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import moment from 'moment';
import { PersonalBasicDataGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

function removeTimeZone(dateString: string) {
    let birthDate = '';

    if (dateString.split('+').length > 1) {
        let date = dateString.split('+');

        birthDate = date[0];
    }
    return moment(birthDate).format('DD/MM/YYYY');
}

interface IPersonalBasicData {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function PersonalBasicData({ datasetId, disableRequestDataSet }: IPersonalBasicData) {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'PersonalBasicData');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta do CPF${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'personal_basic_data'
    ) as PersonalBasicDataGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle title={'Nome'} value={result?.name ?? 'N/D'} key="name" />
                    <HandleTitle title={'Idade'} value={result?.age ?? 'N/D'} key="age" />
                    <HandleTitle
                        title={'Documento'}
                        value={
                            result?.taxIdNumber ? formatDocumentNumber(result?.taxIdNumber) : 'N/D'
                        }
                        key="taxIdNumber"
                    />
                    <HandleTitle
                        title={'Data de Nascimento'}
                        value={result?.birthDate ? removeTimeZone(result.birthDate) : 'N/D'}
                        key="taxIdStatusDate"
                    />
                    <HandleTitle
                        title={'Nacionalidade'}
                        value={result?.nationality ?? 'N/D'}
                        key="nationality"
                    />
                    <HandleTitle
                        title={'Falecido'}
                        value={result?.dead ? 'Sim' : 'Não'}
                        key="dead"
                    />
                    <HandleTitle
                        title={'Gênero'}
                        value={result?.gender === 'M' ? 'Masculino' : 'Feminino'}
                        key="gender"
                    />
                    <HandleTitle
                        title={'Score do nome'}
                        value={
                            result?.nameUniquenessScore
                                ? `${result?.nameUniquenessScore * 100}%`
                                : 'N/D'
                        }
                        key="nameUniquenessScore"
                    />
                    <HandleTitle
                        title={'Origem'}
                        value={result?.taxIdOrigin ?? 'N/D'}
                        key="taxIdOrigin"
                    />
                    <HandleTitle
                        title={'Status'}
                        value={result?.taxIdStatus ?? 'N/D'}
                        key="taxIdStatus"
                    />
                    <HandleTitle
                        title={'Qtd. processos'}
                        value={result?.totalLawsuits ?? 'N/D'}
                        key="totalLawsuits"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('PersonalBasicData')}
                isLoading={isLoading || status === 'loading'}
            />
        </>
    );
}
