import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import DatasetWrapper from '../DatasetWrapper';
import { Button, Typography } from '@uy3/web-components';
import moment from 'moment';

type PDFReportProps = {
    id: string | undefined;
    disableRequestDataSet?: boolean;
};

export function PDFReport({ id, disableRequestDataSet }: PDFReportProps) {
    const recordType = 'LegalPerson';
    const { personId } = useParams();
    const dataId = id ?? personId;
    const { dataset, refetch } = useDatasetData(recordType, dataId!, 'pdfreport');
    const { setSubmitError } = useApiRequest();

    const onSuccess = () => refetch();
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutate, isLoading } = useDatasetMutation(recordType, dataId!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'pdf_report') as any;
    const result = data?.result;

    var currentData = moment();
    var updateData = moment(result?.updatedAt);
    var diffDays = currentData.diff(updateData, 'days');
    var disabled = diffDays > 2 ? false : true;

    const handleReportLink = () => {
        window.open(result, '_blank')
    }

    return (
        <>
            {result === 'Em Execução' ? (
                <Typography variant="h6" color="neutral.medium" fontWeight={500}>
                    Em Execução
                </Typography>
            ) : (
                <Button
                    variant='contained'
                    onClick={handleReportLink}
                    sx={{width: '300px', marginLeft: '15px'}}
                >
                    Ver relatório
                </Button>
            )}

            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('pdfreport')}
                isLoading={isLoading || result === 'Em Execução'}
                disabled={disabled || result === 'Em Execução'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
}
