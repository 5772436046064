import type { FunctionComponent } from 'react';
import type { TabBarProps, TabPanelProps } from './TabBar.interface';

import { useState } from 'react';
import Box from '@mui/material/Box';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Tabs } from './TabBar.styled';
import { TabItem } from '../../atoms/TabItem';

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
};

export const TabBar: FunctionComponent<TabBarProps> = ({ tabs, variant, orientation }) => {
  const { pathname } = useLocation();
  const [value, setValue] = useState(0);
  const target = tabs?.[0].href ?? '';

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  let activeTab =
    tabs?.length &&
    tabs.findIndex(
      (tab) => pathname.endsWith(tab.href ?? '') || `${pathname}/`.endsWith(tab.href ?? '')
    );

  const path = pathname.split('/').slice(0, -1).join('/');

  if (activeTab === -1) {
    activeTab = (tabs?.length && tabs.findIndex((tab) => path.endsWith(tab.href ?? ''))) ?? 0;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs
          value={activeTab ?? value}
          variant={variant}
          orientation={orientation}
          onChange={handleChange}
          scrollButtons
          allowScrollButtonsMobile
        >
          {tabs?.map((tab) => {
            const firtsTab = tab.href === target;
            return (
              <TabItem
                key={tab.label}
                label={tab.label}
                component={NavLink}
                to={firtsTab ? '' : tab.href}
              />
            );
          })}
        </Tabs>
      </Box>
      {tabs?.map((tab, index) => (
        <TabPanel key={tab.label} value={activeTab ?? value} index={index}>
          <Outlet />
        </TabPanel>
      ))}
    </Box>
  );
};
