import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

interface ICommissionRangeListHeaderProps {
    isFetching: boolean;
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    enableActionCommission: boolean
}

const theme = activeTheme();
const CommissionRangeHeader: React.FC<ICommissionRangeListHeaderProps> = ({ isFetching, setOpenDrawer, enableActionCommission}) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];

    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row" mb={4} mt={3}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="30px"
                lineHeight="38.4px"
            >
                Custo de emissão
            </Typography>
            {enableActionCommission && <Grid mr={2}>
                <Button
                    onClick={() => setOpenDrawer(true)}
                    sx={{ color }}
                    variant="text"
                    size="large"
                    startIcon={<AddIcon htmlColor={color} sx={iconSx} />}
                >
                    Adicionar emissão
                </Button>
            </Grid>}
        </Stack>
    );
};
export default CommissionRangeHeader;
