import { useQueryClient } from "@tanstack/react-query";
import { ToastType, useApiRequest } from "contexts/apiRequestContext";
import { FormProvider } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { useNavigate, useParams } from "react-router";
import { manualUpdateCreditNote, sendWebCredCreditNote } from "services/creditNote";
import { defaultValuesManualUpdateForm, validationSchemaManualUpdateForm } from "./ManualUpdateSchema";
import { FieldValues } from 'react-hook-form';
import ManualUpdateForm from "./ManualUpdateForm";
import { Dispatch } from "react";
import { mapErrorResponse } from "contexts/responseErrorContext";

type ManualUpdateProps = {
    setModalProps: (props: any) => void;
    setToast: Dispatch<React.SetStateAction<ToastType>>
};

export const ManualUpdate = ({ setModalProps, setToast }: ManualUpdateProps) => {
    const { startRequest, setSubmitError, endRequest } = useApiRequest();
    const navigate = useNavigate();
    const { token } = useIdentity();
    const { id } = useParams();
    const { currentTenantId } = useTenant();
    const queryClient = useQueryClient();

    const onSubmit = async (values: FieldValues) => {
        const { newStatus, timelineAction, newTimelineType, newTimelineDescription, workflowAction, sendWebCred, acceptanceDate } = values;

        let formValues = {
            newStatus,
            timelineAction,
            newTimelineType,
            newTimelineDescription,
            workflowAction,
            acceptanceDate
        };

        startRequest();
        manualUpdateCreditNote(formValues, id!, token!, currentTenantId).then(() => {
            if (sendWebCred) {
                sendWebCredCreditNote(id!, token!, currentTenantId).catch((error) => {
                    setSubmitError(error);
                });
            };
            queryClient.invalidateQueries({
                queryKey: ["credit-data"],
                refetchType: 'active',
            });
            setModalProps(undefined);
            navigate(`/ccb/operacoes/${id}`);
            setToast({
                open: true,
                title: "Atualização manual realizada com sucesso!",
                severity: "success"
            });
        }).catch((response) => {
            const { errorMessage } = mapErrorResponse(response)
            setModalProps(undefined);
            setToast({
                open: true,
                title: 'Ops, ocorreu um erro!',
            description: errorMessage,
                severity: "error"
            });
        }).finally(() => endRequest(true));
    };

    const schema = validationSchemaManualUpdateForm();
    const defaultValues = defaultValuesManualUpdateForm;

    return (<>
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onChangeField={
                [{
                    fieldName: 'timelineAction',
                    delegate: (value: string, setValue: (name: any, value: any) => void) => setValue('timelineAction', value)
                }]}
            onSubmit={onSubmit}>
            <ManualUpdateForm
                recordType="creditNote"
                onClose={() => setModalProps(undefined)}
            />
        </FormProvider>
    </>
    );
};
