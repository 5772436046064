import { FunctionComponent } from 'react';
import { TabBar as Tabs, TabBarProps } from '@uy3/web-components';
import { Stack } from '@mui/material';
import styled from '@emotion/styled';

const CustomStack = styled(Stack)`
border-radius: 8px;
border : 0.75px solid rgba(186, 191, 208, 0.5);
margin-bottom: 50px;
border-top: none;
padding-bottom: 48px;
`;

export const TabBar: FunctionComponent<TabBarProps> = ({ tabs, variant = 'fullWidth' }) => <CustomStack><Tabs variant={variant} tabs={tabs} /></CustomStack>

