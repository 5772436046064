import { FormProvider } from 'contexts/formContext';
import DrawerConfirmMfa from 'components/ConfirmMfaForm/ConfirmMfaForm';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';

interface DrawerMFAProps {
    onSubmit: (values: any) => void;
    onClose: () => void;
    toggleDrawer?: boolean
}

export default function DrawerMFA({ onSubmit, onClose, toggleDrawer = false }: DrawerMFAProps) {
    const mfaSchema = validationConfirmMfaFormSchema();
    const mfaDefaultValues = defaultValuesConfirmMfaForm;

    return (
        <FormProvider
            validationSchema={mfaSchema}
            defaultValues={mfaDefaultValues}
            onSubmit={onSubmit}
        >
            <DrawerConfirmMfa onClose={onClose} toggleDrawer={toggleDrawer}/>
        </FormProvider>
    );
}
