import { FormProvider } from 'contexts/formContext';
import { CreditNoteFilter } from './CreditNoteFilter';
import {
    defaultValuesCreditNoteFilter,
    validationCreditNoteFilterSchema,
} from './CreditNoteFilterSchema';
import { useFilters } from 'contexts/filterContext';
import { useState } from 'react';
import { Grid } from '@mui/material';
import { Toast } from '@uy3/web-components';

export const CreditNoteFilterContainer = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams?.getAll('status');
    const [existsStatusFilter, setExistsStatusFilter] = useState(false);

    const onSubmitSearch = (values: any) => {
        const { optionFilter } = values;
        switch (optionFilter) {
            case 'initialDate':
                const finalDate = new Date(values.finalDate);
                addFilter(optionFilter, values[optionFilter], finalDate);
                break;
            case 'initialPaymentDate':
                const finalPaymentDate = new Date(values.finalPaymentDate);
                addFilter(optionFilter, values[optionFilter], finalPaymentDate);
                break;
            case 'status':
                if (status && status.includes(values[optionFilter])) {
                    setExistsStatusFilter(true);
                    return;
                }
                addFilter(optionFilter, values[optionFilter]);
                setExistsStatusFilter(false);
                break;
            case 'relatedPersons':
                addFilter(optionFilter, values[optionFilter]);
                addFilter('typeOfRelationship', values?.typeOfRelationship);
                break
            default:
                addFilter(optionFilter, values[optionFilter]);
        }
    };

    const creditNoteFilterSchema = validationCreditNoteFilterSchema();
    const defaultValuesFilterSchema = defaultValuesCreditNoteFilter;

    return (
        <>
            <Grid container item>
                <Toast
                    severity="info"
                    title="O filtro já está aplicado na listagem abaixo."
                    onClose={() => setExistsStatusFilter(false)}
                    open={existsStatusFilter}
                />
            </Grid>
            <FormProvider
                validationSchema={creditNoteFilterSchema}
                defaultValues={defaultValuesFilterSchema}
                onSubmit={onSubmitSearch}
            >
                <CreditNoteFilter currentValues={filters} removeFilter={removeFilterByIndex} />
            </FormProvider>
        </>
    );
};
