import { BasicDatasetGet } from 'services/datasets/datasets.interface';
import DatasetWrapper from '../DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';
import { useFormContext } from 'contexts/formContext';
import { useState } from 'react';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

type BasicDataProps = {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
};

export function BasicData({ datasetId, disableRequestDataSet }: BasicDataProps) {
    const recordType = 'LegalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'LegalBasicData');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta do CNPJ ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'legal_basic_data'
    ) as BasicDatasetGet;
    const result = data?.result;


    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle title={'Idade'} value={result?.age ?? 'N/D'} key="age" />
                    <HandleTitle
                        title={'Fundação'}
                        value={
                            result?.foundedDate
                                ? new Date(result?.foundedDate).toLocaleDateString('pt-BR')
                                : 'N/D'
                        }
                        key="foundedDate"
                    />
                    <HandleTitle
                        title={'Razão social'}
                        value={result?.officialName ?? 'N/D'}
                        key="officialName"
                    />
                    <HandleTitle
                        title={'Match da razão social(%)'}
                        value={result?.officialNameInputNameMatchPercentage ?? 'N/D'}
                        key="officialNameInputNameMatchPercentage"
                    />
                    <HandleTitle
                        title={'Score da razão social'}
                        value={result?.officialNameUniquenessScore ?? 'N/D'}
                        key="officialNameUniquenessScore"
                    />
                    <HandleTitle
                        title={'Nome fantasia'}
                        value={result?.tradeName ?? 'N/D'}
                        key="tradeName"
                    />
                    <HandleTitle
                        title={'Match do nome fantasia(%)'}
                        value={result?.tradeNameInputNameMatchPercentage ?? 'N/D'}
                        key="tradeNameInputNameMatchPercentage"
                    />
                    <HandleTitle
                        title={'Score do nome fantasia'}
                        value={result?.tradeNameUniquenessScore ?? 'N/D'}
                        key="tradeNameUniquenessScore"
                    />
                    <HandleTitle
                        title={'Origem'}
                        value={result?.taxIdOrigin ?? 'N/D'}
                        key="taxIdOrigin"
                    />
                    <HandleTitle
                        title={'Status'}
                        value={result?.taxIdStatus ?? 'N/D'}
                        key="taxIdStatus"
                    />
                    <HandleTitle
                        title={'Data do status'}
                        value={
                            result?.taxIdStatusDate
                                ? new Date(result?.taxIdStatusDate).toLocaleDateString('pt-BR')
                                : 'N/D'
                        }
                        key="taxIdStatusDate"
                    />
                    <HandleTitle
                        title={'Regime'}
                        value={result?.taxRegime ?? 'N/D'}
                        key="taxRegime"
                    />

                    <HandleTitle
                        title={'Qtd. processos'}
                        value={result?.totalLawsuits ?? 'N/D'}
                        key="totalLawsuits"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('LegalBasicData')}
                isLoading={isLoading || status === 'loading'}
            />
        </>
    );
}
