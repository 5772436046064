import { boolean, object } from "yup";

export function validationCancelFormSchema() {
  return object().shape({
    keepBillingOpen: boolean(),
  });
}

export const defaultValuesCancelFormSchema = {
  keepBillingOpen: false
};