import { WarrantyType } from './generics';

export class ConsortiumCreateModel {
    warrantyType: WarrantyType = 'Consortium';
    administratorName?: string;
    administratorRegistrationNumber?: string;
    quota?: string;
    group?: string;

    constructor(obj?: Partial<ConsortiumCreateModel>) {
        Object.assign(this, obj);
    }

    title?() {
        return 'Consórcio';
    }
    description?() {
        return `XPTO`;
    }
}
