import { createContext, useContext } from "react";
import { CreditNoteReadModel } from "services/creditNote/types/creditNoteReadModel";

export type HandleOnSubmitType = { handle: (name: string, value: any) => void } | undefined

type HandleSubmitCreditNoteProps = {
    handleOnSubmit: (creditNoteaValues: CreditNoteReadModel, setValue: (name: string, value: any) => void) => void;
};

const HandleSubmitContext = createContext<HandleSubmitCreditNoteProps>({
    handleOnSubmit: (creditNoteaValues: CreditNoteReadModel, setValue: (name: string, value: any) => void) => {}
});

interface HandleSubmitCreditNoteProviderProps {
    children: JSX.Element;
}

export const HandleSubmitContextProvider = ({
    children
}: HandleSubmitCreditNoteProviderProps) => {
    
    const handleOnSubmit = (creditNoteaValues: any, setValue: (name: string, value: any) => void) => {
        const fields: string[] = ['unitPrice', 'quantity'];
        
        fields.forEach((item) => {
            const value = creditNoteaValues?.[item];
            setValue(item, value); 
        });
    }
    
    return (
        <HandleSubmitContext.Provider
            value={{
                handleOnSubmit:(creditNoteaValues, setValue) => handleOnSubmit(creditNoteaValues, setValue)
            }}
        >
            {children}
        </HandleSubmitContext.Provider>
    );
};

export function useHandleSubmitCreditNoteFormContext() {
    const context = useContext(HandleSubmitContext);
    const { handleOnSubmit } = context;
    return {
        handleOnSubmit
    };
}