import { AddIcon, CalendarIcon, CheckCircleIcon, DataTable, DiscountIcon, NaturalPersonIcon, PenIcon, ReceivablesIcon, SaveIcon, TimeQuarterIcon, TotalValueIcon, Typography } from '@uy3/web-components';
import { Box, Grid, Stack, styled } from '@mui/material';
import { Actions } from 'components/Actions/Actions';
import { activeTheme } from 'services/theme';
import { DetailsForm, ItemProps } from 'pages/WalletManagement/Billing/BillingForm/DetailsForm';
import OperationDetails from 'pages/CreditNote/CreditNoteForm/Tabs/Operation/OperationDetails';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import moment from 'moment';
import React, { useState } from 'react';
import { FilterProvider } from 'contexts/filterContext';
import { IDesconto } from 'services/creditNote/types/creditNoteReadModel';
import { localPaginateDataTable, formatDocumentNumber } from 'helpers';

const theme = activeTheme();

type Props = {
    clear: () => void
    descontoData: IDesconto | null
    handleSubmitFile: (file: any) => void
    handleAction: (action: string) => void
}

const DescontoForm:React.FC<Props> = ({
    clear, 
    descontoData, 
    handleSubmitFile, 
    handleAction
}) => {
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(1);
    
    const sum = (a: number, b: number) => a + b;
    const mediumTerm = () => {
        var dates = descontoData?.warranty?.map((i: any) => moment(i.dueDate).diff(moment(descontoData!.amortization?.startDate), 'days'));
        var pm = (dates!.reduce(sum) / dates!.length).toFixed(0);
        return pm;
    }
    
    const warranty = (descontoData?.warranty ?? []);
    const paymentScheduleItems = (descontoData?.paymentScheduleItems ?? []);

    const isLoaded = descontoData?.loaded === true;

    return (
        <Stack spacing={2}>
            <Stack justifyContent="space-between" alignItems="center" direction="row">
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="32px"
                    lineHeight="38.4px"
                >
                    Desconto
                </Typography>
                <Grid mr={1}>
                    <>
                        <Grid sx={{ mr: -1 }}>
                            <Actions numberOfButtons={4} buttonsActionsList={[
                                {
                                    enable: Object.keys(descontoData ?? {})?.length > 1,
                                    label: "Carregar outro",
                                    action: () => clear(),
                                    icon: <AddIcon htmlColor={theme.palette.common.black} />
                                },
                                {
                                    enable: isLoaded,
                                    label: "Abrir operação",
                                    action: () => handleAction('openCreditNote'), 
                                    icon: <SaveIcon htmlColor={theme.palette.common.black} />
                                }
                            ]} />
                        </Grid>
                    </>
                </Grid>
            </Stack>
            {!isLoaded && <>
                <Typography variant="sm">
                    Selecione o arquivo CNAB para carregar os dados da operação
                </Typography>
                <Box pb={3}>
                    <Typography
                        variant="h6"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="23px"
                        lineHeight="38.4px"
                    >
                        Arquivo
                    </Typography>
                    <FileStyled
                        type='file'
                        name='file'
                        onChange={(values) => {
                            if (values?.target.files?.length && values?.target.files?.length > 0)
                                handleSubmitFile(values?.target.files[0])
                        }}
                    />
                </Box>
            </>}
            {isLoaded && <>
                <DetailsForm title='Dados do desconto' list={[
                    {
                        title: "Cedente",
                        value: descontoData?.personDisplay,
                        Icon: NaturalPersonIcon,
                    },
                    {
                        title: "Quantidade de títulos",
                        value: warranty?.length.toString(),
                        Icon: PenIcon
                    },
                    {
                        title: "Valor da cessão",
                        value: formatCurrencyInCents(paymentScheduleItems[0]?.principalAmountInCents),
                        Icon: TotalValueIcon
                    },
                    {
                        title: "Valor futuro",
                        value: formatCurrencyInCents(paymentScheduleItems?.map((i: any) => i.payment).reduce(sum, 0)),
                        Icon: CheckCircleIcon
                    },
                    {
                        title: "Prazo médio",
                        value: mediumTerm() + " dias",
                        Icon: CalendarIcon
                    },
                    {
                        title: "Taxa de desconto",
                        value: formatPercentage(descontoData?.amortization?.monthlyInterest ?? 0),
                        Icon: DiscountIcon
                    },
                    {
                        title: "Deságio total",
                        value: formatCurrencyInCents(paymentScheduleItems?.map((i: any) => i.payment).reduce(sum, 0) - paymentScheduleItems[0]?.principalAmountInCents),
                        Icon: TimeQuarterIcon
                    },
                    {
                        title: "Tarifas",
                        value: formatCurrencyInCents(0),
                        Icon: ReceivablesIcon
                    },
                    {
                        title: "CET (a.m.)",
                        value: formatPercentage(descontoData?.amortization?.effectiveMonthlyCost ?? 0),
                        Icon: TimeQuarterIcon
                    },
                    {
                        title: "CET (a.a.)",
                        value: formatPercentage(descontoData?.amortization?.effectiveYearlyCost ?? 0),
                        Icon: TimeQuarterIcon
                    },
                ] as ItemProps[]} isLoading={false} />

                <React.Fragment>

                    <Grid container xs={12} alignItems="center" justifyContent="space-between" mb={2}>
                        <Grid item xs={12} md={6} lg={4} xl={3} justifyContent="flex-start">
                            <Typography
                                variant="xxl"
                                color="neutral.dark"
                                sx={{ lineHeight: '24px', fontWeight: '700', fontStyle: 'normal' }}
                            >
                                Títulos descontados
                            </Typography>
                            <Typography
                                variant="h6"
                                color="neutral.medium"
                                sx={{
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    fontSize: '12px',
                                    opacity: '87%',
                                }}
                            >
                                Todos os títulos incluídos no arquivo carregado
                            </Typography>
                        </Grid>
                    </Grid>
                    <FilterProvider
                        availableFilters={{
                            initialDate: {
                                label: 'Data',
                                type: 'dateRange',
                                multiple: false,
                                dateRangeName: 'finalDate',
                            },
                        }}
                    >
                        <DataTable
                            style={{
                                marginBottom: 20
                            }}
                            columns={[
                                {
                                    field: 'payerName',
                                    headerName: 'Sacado',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => value ?? "N/D"
                                },
                                {
                                    field: 'payerRegistrationNumber',
                                    headerName: 'CPF/CNPJ',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => formatDocumentNumber(value) ?? "N/D"
                                },
                                {
                                    field: 'dueDate',
                                    headerName: 'Vencimento',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => new Date(value).toLocaleDateString('pt-BR') ?? "N/D"
                                },
                                {
                                    field: 'presentValue',
                                    headerName: 'Valor Presente',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => value ? formatCurrencyInCents(value) : "N/D"
                                },
                                {
                                    field: 'invoiceValue',
                                    headerName: 'Valor Futuro',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => value ? formatCurrencyInCents(value) : "N/D"
                                },
                                {
                                    field: 'contractNumber',
                                    headerName: 'Contrato',
                                    hideSortIcons: true,
                                    minWidth: 80,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => value ?? "N/D"
                                },
                                {
                                    field: 'invoiceNumber',
                                    headerName: 'Nota Fiscal',
                                    hideSortIcons: true,
                                    minWidth: 150,
                                    flex: 2,
                                    editable: false,
                                    renderCell: ({ value }) => value ?? "N/D"
                                },
                            ]}
                            rows={localPaginateDataTable(warranty, page, rowsPerPage)}
                            getRowId={(row) => row?.warrantyType + row.invoiceNumber + row?.invoiceValue + row?.contractNumber}
                            rowCount={warranty?.length}
                            rowsPerPage={rowsPerPage}
                            setPage={setPage}
                            page={page}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </FilterProvider>
                    <Stack pt={3} pb={3}>
                        <Stack>
                            <OperationDetails
                                removedFromPrintList={[]}
                                currentPaymentScheduleItems={paymentScheduleItems}
                            />
                        </Stack>
                    </Stack>
                </React.Fragment>
            </>}
        </Stack >

    );
};

export default DescontoForm;


const FileStyled = styled('input')({
    display: 'block',
    padding: '10px',
    borderRadius: '8px',
    background: '#E5E7ED',
    textAlign: 'center'
})