import { GridColDef } from "@mui/x-data-grid";
import { DataTable } from "@uy3/web-components";
import { isEmpty } from "lodash";
import { IReserveTokensRegistered } from "services/accounts/naturalPerson/types/naturalPersonReadModel";

type TokensListProps = {
    queryData: IReserveTokensRegistered[];
};

const TokensList = ({
    queryData
}: TokensListProps) => {
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Token',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: (cellValues) => cellValues?.row?.result?.Token?.TokenValue ?? cellValues?.row?.result?.Token

        },
        {
            field: 'tokenTypeDisplay',
            headerName: 'Tipo',
            hideSortIcons: true,
            minWidth: 210,
            editable: false,
            flex: 1,
            renderCell: (cellValues) => cellValues?.row?.result?.Token?.TokenTypeDisplay ?? "N/D"

        },
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 100,
            editable: false,
            flex: 0.5
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            align: 'center',
            headerAlign: 'center',
            minWidth: 150,
            editable: false,
            flex: 1,
            renderCell: (cellValues) => {
                const createdAt = cellValues?.value; 
                return !isEmpty(createdAt) ? new Date(createdAt).toLocaleDateString('pt-BR') : "N/D"
            }
        }, 
        {
            field: 'expirationDate',
            headerName: 'Expira em',
            hideSortIcons: true,
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            flex: 1,
            renderCell: (cellValues) => {
                const expirationDate = cellValues?.row?.result?.ExpirationDate; 
                return !isEmpty(expirationDate) ? new Date(expirationDate).toLocaleDateString('pt-BR') : "N/D"
            }
        },
        {
            field: 'tokenUsedAt',
            headerName: 'Utilizado em',
            hideSortIcons: true,
            align: 'center',
            headerAlign: 'center',
            minWidth: 150,
            editable: false,
            flex: 1,
            renderCell: (cellValues: any) => {
                const tokenUsedAt = cellValues?.row?.result?.TokenUsedAt; 
                return !isEmpty(tokenUsedAt) ? new Date(tokenUsedAt).toLocaleDateString('pt-BR') : "N/D"
            }
        }
    ];

    return (
        <DataTable
            columns={columns}
            rows={queryData ?? []}
            page={0}
            rowCount={queryData?.length ?? 0}
            rowsPerPage={5}
            setPage={() => { }}
            setRowsPerPage={() => { }}
        />
    );

};

export default TokensList;