/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, PersonIcon, SaveIcon } from '@uy3/web-components'
import { SelectFormField } from 'components/Forms/FormFields'
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { activeTheme } from 'services/theme'
import { CreateAccountType, TypeAction } from './PaymentRevisionFormContainer';

const theme = activeTheme();

type PaymentRevisionProps = {
  useCDC: boolean;
  isFGTS: boolean;
  createdAccount: CreateAccountType | null;
  setOpenDrawer: Dispatch<SetStateAction<{ open: boolean; action: TypeAction } | undefined>>
  onClose: () => void
}

const PaymentRevisionForm = ({
  createdAccount,
  isFGTS,
  setOpenDrawer,
  useCDC,
  onClose
}: PaymentRevisionProps) => {
  const { submitting } = useApiRequest();
  const { setValue } = useFormContext();

  useEffect(() => {
    if (createdAccount !== null) {
      let path = createdAccount.type === 'bankAccount' ? 'bankAccountId' : 'changeBankAccountId'
      setValue(path, 'new')
    }
  }, [createdAccount])


  return (
    <Stack spacing={2} mt={2}>
      <Grid item xs={6}>
        <SelectFormField
          name="bankAccountId"
          label="Conta de liquidação"
          variant="outlined"
          options={createdAccount?.account?.bankAccount}
          fullWidth
        />
      </Grid>
      <Grid
        item
        justifyContent="flex-end"
        alignItems="flex-end"
        display="flex"
      >
        <Button
          onClick={() => setOpenDrawer({
            action: 'bankAccount',
            open: true
          })}
          startIcon={<PersonIcon
            htmlColor={theme.palette.primary.main}
            sx={iconSx}
          />}
          sx={{ fontSize: 16, mt: -1, mr: -1 }}>
          Adicionar dados bancários
        </Button>
      </Grid>

      {useCDC &&
        <>
          <Grid item xs={6}>
            <SelectFormField
              name="changeBankAccountId"
              label="Conta para Troco"
              variant="outlined"
              options={createdAccount?.account?.changeBankAccount}
              fullWidth
            />
          </Grid>
          <Grid
            item
            justifyContent="flex-end"
            alignItems="flex-end"
            display="flex"
          >
            <Button
              onClick={() => setOpenDrawer({
                action: 'changeBankAccountId',
                open: true
              })}
              startIcon={<PersonIcon
                htmlColor={theme.palette.primary.main}
                sx={iconSx}
              />}
              sx={{ fontSize: 16, mt: -1, mr: -1 }}>
              Adicionar dados bancários
            </Button>
          </Grid>
        </>
      }
      <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} mt={3}>
        <Button
          startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
          disabled={submitting}
        >
          Fechar
        </Button>
        <Button
          name="btn-submit-bank-details"
          type="submit"
          variant="contained"
          size="medium"
          disabled={submitting}
          startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
        >
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}

export default PaymentRevisionForm
