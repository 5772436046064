import styled from "@emotion/styled";

export const tableCellStyle = {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    height: '40px',
    fontFamily: 'Lato, sans-serif',
};

export const tableHeaderCellStyle = {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '19px',
    background: '#F8F9FA',
    height: '56px',
    fontFamily: 'Lato, sans-serif',
};

export const StyledTableInfo = styled('div')(() => ({
    '@media print': {
        width: '700px',
        margin: 'auto',
    },
    marginRight: '14px',
}));