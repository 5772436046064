enum ECreditNoteLinkStatus {
    Linked = "Vinculado",
    Unlinked = "Desvinculado",
}

enum ETransferCreditNoteStatus {
    New = 'Novo',
    Pendding = 'Pendente',
    Finished = 'Finalizado',
    Failed = 'Rejeitado',
}

enum ETransitoryResourceLiquidation {
    ManualLiquidation = "Liquidação manual",
    AutomaticLiquidation = "Liquidação automatica",
}

enum EAction {
    NoAction = "Sem ação",
    ManualLiquidation = "Liquidação manual",
    AutomaticLiquidation = "Liquidação automática",
    Failed = "Rejeitar",
}

export {
    ECreditNoteLinkStatus,
    ETransferCreditNoteStatus,
    ETransitoryResourceLiquidation,
    EAction,
};
