import { object, string } from "yup";

export const createBatchAssignmentSchema = () => {
    return object().shape({
        assignmentCalculation: object().shape({
            type: string().typeError('Tipo de cálculo: precisa ser preenchido').required('Tipo de cálculo: precisa ser preenchido'),
            amount: string().typeError('Valor do cálculo: precisa ser preenchido').required('Valor do cálculo: precisa ser preenchido')
        })
    });
};

export const createBatchAssignmentDefaultValues = {
    assignmentCalculation: {
        type: null, 
        amount: 0
    }
}