import { Typography, TypographyProps } from '@uy3/web-components';
import React from 'react';

const styleTypography = {
    lineHeight: '30px',
    display: 'flex',
    alignItems: 'center',
};

type TitleCustomProps = {
    title: string;
} & TypographyProps;

export const CustomTitle: React.FC<TitleCustomProps> = ({ title, ...rest }) => {
    return (
        <Typography
            variant="h4"
            display="block"
            color="neutral.dark"
            fontWeight={700}
            sx={styleTypography}
            {...rest}
        >
            {title}
        </Typography>
    );
};
