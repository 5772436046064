import { Box, Stack } from '@mui/material';
import { Typography, SaveIcon, Alert, OperatorIcon, DeleteIcon, UpdateIcon, ShareIcon } from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';
import { activeTheme } from 'services/theme';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType, showSuccessToast } from 'contexts/apiRequestContext';
import { Actions } from 'components/Actions/Actions';
import { useUserPermissionData } from 'contexts/userContext';
import { hasCustomPermission, isActionAllowed } from 'helpers';
import { Dispatch, SetStateAction } from 'react';
import { useTenant } from 'contexts/tenantContext';

type BankAccountProductType = {
  productId: string;
  mode: FormMode;
  refetch: () => void;
  setToast: Dispatch<SetStateAction<ToastType>>;
  setAction: (action: string) => void;
};

const theme = activeTheme();
const BankAccountProductForm = ({
  productId,
  mode,
  refetch,
  setToast, 
  setAction
}: BankAccountProductType) => {
  const { hasPermission } = useUserPermissionData();
  const { validationErrors, watch, isDirty, readOnly } = useFormContext();
  const newProduct = mode === 'create';

  const refreshWithToken = () => {
    refetch();
    const title = 'Produto atualizado com sucesso!';
    showSuccessToast(title, "", setToast);
  }

  const resource = 'BankAccountProduct';
  const typePermission = newProduct ? 'Create' : 'Update';
  const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
  const { isRootTenancy } = useTenant();
  const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, isRootTenancy)

  return (
    <>
      {validationErrors && (
        <Box mt={2} mb={2}>
          <Alert
            severity="error"
            text={
              'Não foi possível salvar as alterações. Corrija os erros do formulário e tente novamente.'
            }
            variant="filled"
          />
        </Box>
      )}
      <Box>
        <NotSavedChangesAlert isDirty={isDirty} />
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
          <Typography
            variant="h4"
            color="neutral.dark"
            fontStyle="normal"
            fontWeight="700"
            fontSize="32px"
            lineHeight="38.4px"
          >
            {newProduct ? 'Novo cadastro' : `${watch('name')}`}
          </Typography>
          <Actions
            numberOfButtons={4}
            buttonsActionsList={[
              {
                enable: enableAction,
                label: "Salvar",
                type: 'submit',
                icon: <SaveIcon htmlColor={theme.palette.common.black} />
              },
              {
                enable: !newProduct,
                label: "Atualizar",
                action: refreshWithToken,
                icon: <UpdateIcon htmlColor={theme.palette.common.black} />
              }, 
              {
                enable: !newProduct && enableAction,
                label: "Clonar produto",
                action: () => setAction('cloneProduct'),
                icon: <ShareIcon htmlColor={theme.palette.common.black} />
              },
              {
                enable: !newProduct && hasPermission('BankAccountProduct', 'Update') && isRootTenancy,
                label: "Atribuir",
                action: () => setAction('assign'),
                icon: <OperatorIcon htmlColor={theme.palette.common.black} />
              },
              {
                enable: !newProduct && hasPermission('BankAccountProduct', 'Delete') && isRootTenancy,
                label: "Excluir",
                action: () => setAction('delete'),
                icon: <DeleteIcon htmlColor={theme.palette.common.black} />
              },
            ]}
          />
        </Stack>
        <OperatorViewInfo />
        <TabBar
          tabs={[
            {
              label: 'Informações',
              href: productId
            },
            {
              label: 'Envolvidos',
              href: "envolvidos"
            },
            {
              label: 'Documentos obrigatórios',
              href: "documentos"
            },
          ]}
        />
      </Box>
    </>
  );
};

export default BankAccountProductForm;
