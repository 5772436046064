import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const MailDelayIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="none"
      >
        <path
          d="M4.80273 11.2893L7.00273 12.9393C8.0694 13.7393 9.53607 13.7393 10.6027 12.9393L12.8027 11.2893M10.4876 6.28931H3.80273C2.14588 6.28931 0.802734 7.63245 0.802734 9.28931V17.2893C0.802734 18.9462 2.14588 20.2893 3.80273 20.2893H13.8027C15.4596 20.2893 16.8027 18.9462 16.8027 17.2893V9.28931C16.8027 9.04946 16.8027 8.85516 16.8027 8.85516"
          stroke={htmlColor}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2092 4.00854C15.2092 3.59433 14.8734 3.25854 14.4592 3.25854C14.045 3.25854 13.7092 3.59433 13.7092 4.00854H15.2092ZM14.4592 5.50854H13.7092C13.7092 5.92276 14.045 6.25854 14.4592 6.25854V5.50854ZM15.9592 6.25854C16.3734 6.25854 16.7092 5.92276 16.7092 5.50854C16.7092 5.09433 16.3734 4.75854 15.9592 4.75854V6.25854ZM17.7092 5.50854C17.7092 7.30347 16.2542 8.75854 14.4592 8.75854V10.2585C17.0826 10.2585 19.2092 8.1319 19.2092 5.50854H17.7092ZM14.4592 8.75854C12.6643 8.75854 11.2092 7.30347 11.2092 5.50854H9.70923C9.70923 8.1319 11.8359 10.2585 14.4592 10.2585V8.75854ZM11.2092 5.50854C11.2092 3.71362 12.6643 2.25854 14.4592 2.25854V0.758545C11.8359 0.758545 9.70923 2.88519 9.70923 5.50854H11.2092ZM14.4592 2.25854C16.2542 2.25854 17.7092 3.71362 17.7092 5.50854H19.2092C19.2092 2.88519 17.0826 0.758545 14.4592 0.758545V2.25854ZM13.7092 4.00854V5.50854H15.2092V4.00854H13.7092ZM14.4592 6.25854H15.9592V4.75854H14.4592V6.25854Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

MailDelayIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
