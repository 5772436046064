import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { useIdentity } from 'contexts/identityContext';
import { useApiRequest } from './apiRequestContext';
import { useState } from 'react';
import { creditAxiosApi } from "services/axiosApi/axiosApi";
const apiPath = '/EconomicActivityCode';
export function useEconomicActivityCodeListData() {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [search, setSearch] = useState<string | undefined>(undefined);

    let query: string | undefined = '';
    if (search && search.length >= 3) query = `/${search}`;

    const queryContext = useQuery({
        enabled: !!token && !!search,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['economic-activity-codes', query],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await creditAxiosApi.get(`${apiPath}${query}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const dataList = data as Array<{ id: string; description: string; code: string }>;
            return dataList?.map((e) => {
                return { value: e.id, label: `(${e.code}) - ${e.description}`, code: e.code };
            });
        },
    });

    const autoCompleteProps = {
        listOptions: queryContext.data ?? [],
        loading: queryContext.isFetching,
        onSearch: (searchString: string | undefined) => {
            setSearch(searchString);
        },
    };

    return { ...queryContext, autoCompleteProps };
}
