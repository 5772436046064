export const rowDataMock = [
    {
        id: 1,
        limits: 'PIX',
        comercialHours: 'R$ 1.000.000,00',
        outOfHours: 'R$ 100.000,00',
    },
    {
        id: 2,
        limits: 'TED',
        comercialHours: 'R$ 1.000.000,00',
        outOfHours: 'R$ 100.000,00',
    },
    {
        id: 3,
        limits: 'Transferência',
        comercialHours: 'R$ 1.000.000,00',
        outOfHours: 'R$ 100.000,00',
    },
    {
        id: 4,
        limits: 'Boletos',
        comercialHours: 'R$ 1.000.000,00',
        outOfHours: 'R$ 100.000,00',
    },
];
