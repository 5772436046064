import { InputAdornment } from '@mui/material';
import { PercentageIcon, TextField, TextFieldProps, Typography } from '@uy3/web-components';
import React, { useState } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { resolve } from 'services/arrayGetters';
import { NumericFormat, InputAttributes, NumericFormatProps } from 'react-number-format';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { isFieldDisable, isFieldRequired, formatDivide, formatMultiply  } from 'helpers';

interface IPercentageFormFieldProps extends TextFieldProps {
    name: string;
    format?: 'decimal' | 'integer';
};

interface ICustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
};

const theme = activeTheme();
const NumberFormatCustom = React.forwardRef<NumericFormatProps<InputAttributes>, ICustomProps>(
    function NumberFormatCustom(props, ref) {
        const { onChange, name, ...rest } = props;
        const { appConfig } = useAppConfig();
        const decimalScale = appConfig?.PERCENTAGE_DECIMAL_SCALE  ?? 6;

        return (
            <NumericFormat
                {...rest}
                getInputRef={ref}
                onValueChange={(values: any) => {
                    onChange({
                        target: {
                            name: name,
                            value: values.value,
                        },
                    });
                }}
                decimalSeparator=","
                valueIsNumericString
                decimalScale={decimalScale}
                fixedDecimalScale
                isAllowed={(values) => {
                    const { formattedValue } = values;
                    const digitsBeforeComma = formattedValue?.split(",")[0]?.replace(/\D/g, '')?.length;
                    const digitsAfterComma = formattedValue?.split(",")[1]?.length || 0;

                    return digitsBeforeComma <= 3 && digitsAfterComma <= decimalScale;
                }}
            />
        );
    }
);

const PercentageFormField: React.FC<IPercentageFormFieldProps> = ({
    format = 'decimal',
    name,
    required,
    label,
    ...props
}) => {
    const [keyboard, setKeyboard] = useState<string | undefined>(undefined);
    var { validationErrors, watch, setValue, readOnly } = useFormContext();
    const { appConfig } = useAppConfig();
    const { formFieldsErrors } = useFormFieldsError();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key);
    });

    let value = undefined;
    if (watch) {
        value = watch(name);
        if (value && format === 'decimal') {
            value = formatMultiply(value);
        };
    };

    var error = validationErrors && resolve(name, validationErrors)?.message;

    const handleChange = (value: any) => {
        let newValue: string | undefined;
        setKeyboard(value);

        if (value !== '') {
            const parsedValue = Number(value);
            if (format === 'decimal' && !isNaN(parsedValue)) {
                newValue = formatDivide(parsedValue)?.toString();
            } else if (format === 'integer') {
                newValue = value;
            }
        } else {
            newValue = undefined;
        }

        setValue(name, newValue, { shouldDirty: true });
    };

    const isRequired = !!required || isFieldRequired(name);
    const labelWithRequired = isRequired ? `${label} *` : label;
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);

    return (
        <>
            <TextField
                {...props}
                name={name}
                error={!!error || hasResponseError}
                disabled={readOnly || isFieldDisabledConfig}
                variant="outlined"
                value={value ?? keyboard}
                label={labelWithRequired}
                onChange={(event) => handleChange(event.target.value)}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                    startAdornment: (
                        <InputAdornment position="start">
                            <PercentageIcon
                                sx={{ height: '1.7rem' }}
                                htmlColor={readOnly ? '#9E9E9E' : theme.palette.common.black}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            {!!error && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    <>{responseError?.helpMessage}</>
                </Typography>
            )}
        </>
    );
};

export default PercentageFormField;
