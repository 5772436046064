import { number } from 'yup';
import { CleanPrice, DefaultSchemaAmmCleanPrice, ValidationSchemaAmmCleanPrice } from './CleanPrice';
import { ECalculateByValueType } from 'utils/enums';

const genericRequired = 'Precisa ser preenchido.';

export class ReceivablesPrice extends CleanPrice {
    public fiduciaryGuarantee?: number;
}

export class DefaultSchemaAmmReceivablesPrice extends DefaultSchemaAmmCleanPrice {
    public amortizationType = 'receivablesPrice';
    public calculateByValueType: ECalculateByValueType | string = ECalculateByValueType[ECalculateByValueType.Liquid];
    public fiduciaryGuarantee = 0;
}

export class ValidationSchemaAmmReceivablesPrice extends ValidationSchemaAmmCleanPrice {
    public fiduciaryGuarantee = number()
        .typeError('O percentual de garantia é obrigatótia.')
        .required(`Percentual de garantia: ${genericRequired}`);
}
