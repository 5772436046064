import { date } from 'yup';
import { DefaultSchemaAmortization } from './Amortization';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';

const genericRequired = 'Precisa ser preenchido.';
export const currentDateWithSetHours = new Date().setHours(0, 0, 0, 0);

abstract class Discount {

}

export class DefaultSchemaDiscount extends DefaultSchemaAmortization implements Discount {
    public amortizationType = 'discount';
    public startDate = toIsoStringWithTimezone(new Date(currentDateWithSetHours));
}

export class ValidationSchemaDiscount {
    public startDate = date()
        .typeError('Data inválida')
        .required(`Data de início: ${genericRequired}`);
}
