import { LoadingBar } from "@uy3/web-components";
import { useUserPermissionData } from "contexts/userContext";
import { Dashboard } from "pages/Dashboard";
import { Navigate } from "react-router";

export const Home = () => {
    const { hasPermission, data } = useUserPermissionData();

    if (data === undefined){
        return <LoadingBar/>
    }
    if (hasPermission("CreditNote", "Read")) {
        return <Dashboard />
    } else if (hasPermission("BankAccount", "Read")) {
        return <Navigate to='/contas-digitais/ativas' />
    } else {    
        return <Navigate to='/nao-encontrado' />
    }
}