/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { FormProvider, useFormContext } from '../formContext';
import * as bankAccountAPI from 'services/accounts/bankAccountRequest';
import { Popup, Button, Modal, Drawer } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { useIdentity } from '../identityContext';
import { useNavigate } from 'react-router';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from '../apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { FieldValues } from 'react-hook-form';
import { BankAccountsActionsContext } from './bankAccountsActionsContext';
import {
    BankAccountActionsProps,
    BankAccountActionsProviderProps,
    GenericChildrenActionProps,
} from './bankAccountActions.interface';
import { DrawerState, IUploadReadModel, ModalState, PopupState } from 'utils';
import {
    ApprovalsSignatureForm,
    ApproveInstrument,
    CancelBankAccountRequestContainer,
    DraftPreviewContainer,
    RejectInstrument,
} from 'pages/BankAccount/BankAccountRequest/BankAccountRequestForm';
import { useTenant } from 'contexts/tenantContext';
import { sendWebCredCreditNote } from 'services/creditNote';
import {
    defaultValuesManualUpdateForm,
    validationSchemaManualUpdateForm,
} from 'pages/CreditNote/CreditNoteForm/Modals/ManualUpdate/ManualUpdateSchema';
import { DeleteCheckListContainer, ManualUpdateForm } from 'pages/CreditNote/CreditNoteForm/Modals';
import { ApproveAndDisapproveFormContainer } from 'pages/BankAccount/BankAccountRequest/BankAccountRequestList/ApproveAndDisapproveForm';

export const BankAccountActionsProvider = ({
    children,
    bankAccountId,
    bankAccountRequestData,
    refetch,
}: BankAccountActionsProviderProps) => {
    const { watch, setValue } = useFormContext();
    const { startRequest, endRequest, submitting } = useApiRequest();
    const { currentTenantId } = useTenant();
    const navigate = useNavigate();
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [popupProps, setPopupProps] = useState<PopupState>(undefined);
    const [modalProps, setModalProps] = useState<ModalState>(undefined);
    const [drawerProps, setDrawerProps] = useState<DrawerState>(undefined);
    const [isGenerateDraftPreview, setIsGenerateDraftPreview] = useState(false);

    const watchUploads: IUploadReadModel[] = watch('uploads') ?? [];
    const uploadData = bankAccountRequestData?.uploads;

    // Ao gerar um contrato, o estado dos uploads não é automaticamente atualizado. 
    // Portanto, é necessário sincronizar o estado com os dados de uploads obtidos da base de dados
    // sem perder os documentos adicionados pelo usuário antes de gerar o contrato.
    useEffect(() => {
        if (uploadData?.length > 0 && isGenerateDraftPreview) {
            // Filtra os uploads adicionados pelo usuário que ainda não estão no uploadData
            const newUploads = watchUploads.filter(
                (userUpload) => !uploadData.some(
                    (dbUpload) => dbUpload?.id === userUpload?.id
                )
            );

            // Atualiza o estado com os uploads vindos da base de dados e mantém os novos adicionados pelo usuário
            setValue('uploads', [...uploadData, ...newUploads]);
        }
    }, [uploadData]);

    const handleErrorResponse = (response: ApiResponseError) => {
        const { errorMessage, warningToastError } = mapErrorResponse(response);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
            endRequest(false);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
            endRequest(false);
        }
    };

    const handleOnClose = () => {
        setModalProps(undefined);
        setDrawerProps(undefined);
        setPopupProps(undefined);
    };

    const GenericChildrenAction = ({ onClick }: GenericChildrenActionProps) => {
        return (
            <Grid container direction="row" pt={3} justifyContent="flex-end" alignItems="center">
                <Button
                    name="btn-cancel"
                    variant="outlined"
                    color="primary"
                    onClick={handleOnClose}
                    size="medium"
                    sx={{ mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    name="btn-confirm"
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    onClick={onClick}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        );
    };

    async function onUploadDocs(uploads: []) {
        startRequest();
        await bankAccountAPI
            .updateDocumentsBankAccountRequest(bankAccountId!, { uploads }, token!)
            .then(() => {
                handleOnClose();
                endRequest(true);
                showSuccessToast('Documentos atualizados com sucesso!', undefined, setToast);
                refetch();
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    async function openBankAccountRequest() {
        startRequest();
        await bankAccountAPI
            .postOpenBankAccountRequest(bankAccountId!, token!)
            .then(() => {
                endRequest(true);
                showSuccessToast('Operação registrada com sucesso!', undefined, setToast);
                refetch();
                handleOnClose();
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    async function onSendApprovalRevision() {
        startRequest();
        await bankAccountAPI
            .approvalRevisionById(bankAccountId!, token!)
            .then(() => {
                endRequest(true);
                navigate('/contas-digitais/solicitacoes');
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    async function onSubmitManualUpdate(values: FieldValues) {
        const {
            newStatus,
            timelineAction,
            newTimelineType,
            newTimelineDescription,
            workflowAction,
            sendWebCred,
        } = values;

        let formValues = {
            newStatus,
            timelineAction,
            newTimelineType,
            newTimelineDescription,
            workflowAction,
        };
        startRequest();

        if (sendWebCred) {
            await sendWebCredCreditNote(bankAccountId!, token!, currentTenantId).catch(
                (response: ApiResponseError) => handleErrorResponse(response)
            );
        }

        await bankAccountAPI
            .manualUpdateBankAccountRequest(bankAccountId!, formValues, token!, currentTenantId)
            .then(() => {
                endRequest(true);
                handleOnClose();
                refetch();
                showSuccessToast('Status da conta alterado com sucesso!', undefined, setToast);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    async function deletedBankAccountRequest() {
        startRequest();
        await bankAccountAPI
            .deleteBankAccountRequestById(bankAccountId!, token!)
            .then(() => {
                navigate(`/contas-digitais/solicitacoes`);
                endRequest(true);
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    async function onSubmitApproval() {
        startRequest();
        bankAccountAPI
            .postBankAccountRequestSubmitApproval(bankAccountId!, token!)
            .then(() => {
                navigate(`/contas-digitais/solicitacoes`);
                endRequest(true);
                showSuccessToast(
                    'Solicitação enviada para aprovação com sucesso!',
                    undefined,
                    setToast
                );
            })
            .catch((response: ApiResponseError) => handleErrorResponse(response));
    }

    const ApproveOrRejectForm = (recordType: 'approve' | 'disapprove') => (
        <ApproveAndDisapproveFormContainer
            setToast={setToast}
            onClose={handleOnClose}
            refetch={refetch}
            bankAccountRowSelected={[]}
            approveOrDesapprove={recordType}
            bankAccountId={bankAccountId}
            notIsNewBankAccount
            bankAccountRequestData={bankAccountRequestData}
        />
    );

    const ApprovalsSignatureForms = (recordType: 'approveSignature' | 'disapproveSignature') => (
        <ApprovalsSignatureForm onClose={handleOnClose} resource={recordType} setToast={setToast} />
    );

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={submitting} />

            <Modal
                title={modalProps?.title}
                description={modalProps?.description}
                open={!!modalProps}
                onClose={handleOnClose}
                children={modalProps?.children}
                sizeModal="large"
            />

            <Drawer
                anchor="right"
                title={drawerProps?.title}
                description={drawerProps?.description}
                open={!!drawerProps}
                onClose={handleOnClose}
                children={drawerProps?.children}
            />

            <Popup
                open={!!popupProps}
                title={popupProps?.title}
                text={popupProps?.message}
                onClose={handleOnClose}
                children={popupProps?.children}
            />

            <BankAccountsActionsContext.Provider
                value={{
                    isNew: bankAccountId === 'nova',
                    onSubmitApproval,
                    onUploadDocs,
                    onSendApprovalRevision,
                    openBankAccountRequest,
                    refetch,
                    onDelete: () =>
                        setPopupProps({
                            title: 'Excluir solicitação',
                            message: 'Tem certeza que deseja excluir esta solicitação?',
                            children: <GenericChildrenAction onClick={deletedBankAccountRequest} />,
                        }),
                    deleteCheckList: () =>
                        setModalProps({
                            title: 'Remover restrições',
                            description: 'Escolha quais restrições deseja remover desse registro',
                            children: (
                                <DeleteCheckListContainer
                                    onClose={handleOnClose}
                                    personId={bankAccountRequestData?.ownerId!}
                                />
                            ),
                        }),
                    onApprove: () =>
                        setModalProps({
                            title: 'Aprovar solicitação',
                            description: 'Tem certeza que deseja aprovar esta solicitação?',
                            children: ApproveOrRejectForm('approve'),
                        }),
                    onDisapprove: () =>
                        setModalProps({
                            title: 'Rejeitar solicitação',
                            description: 'Tem certeza que deseja rejeitar esta solicitação?',
                            children: ApproveOrRejectForm('disapprove'),
                        }),
                    onManualUpdate: () => {
                        setModalProps({
                            title: 'Definir status',
                            description: 'Tem certeza que deseja alterar o status da conta?',
                            children: (
                                <FormProvider
                                    validationSchema={validationSchemaManualUpdateForm()}
                                    defaultValues={defaultValuesManualUpdateForm}
                                    onChangeField={[
                                        {
                                            fieldName: 'timelineAction',
                                            delegate: (
                                                value: string,
                                                setValue: (name: any, value: any) => void
                                            ) => setValue('timelineAction', value),
                                        },
                                    ]}
                                    onSubmit={onSubmitManualUpdate}
                                >
                                    <ManualUpdateForm
                                        recordType="bankAccount"
                                        onClose={handleOnClose}
                                    />
                                </FormProvider>
                            ),
                        });
                    },
                    onApproveInstrument: () =>
                        setPopupProps({
                            title: 'Aprovar instrumento',
                            message: 'Tem certeza que deseja aprovar esta solicitação?',
                            children: (
                                <ApproveInstrument
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    bankaccountId={bankAccountId!}
                                />
                            ),
                        }),
                    onRejectInstrument: () =>
                        setPopupProps({
                            title: 'Rejeitar instrumento',
                            message: 'Tem certeza que deseja rejeitar esta solicitação?',
                            children: (
                                <RejectInstrument
                                    onErrorCatch={handleErrorResponse}
                                    setPopupProps={setPopupProps}
                                    bankaccountId={bankAccountId!}
                                />
                            ),
                        }),
                    onApproveSignature: () =>
                        setModalProps({
                            title: 'Aprovar assinatura',
                            description: 'Tem certeza que deseja aprovar assinatura?',
                            children: ApprovalsSignatureForms('approveSignature'),
                        }),
                    onDisapproveSignature: () =>
                        setModalProps({
                            title: 'Reprovar assinatura',
                            description: 'Tem certeza que deseja reprovar assinatura?',
                            children: ApprovalsSignatureForms('disapproveSignature'),
                        }),
                    onDraftpreview: () =>
                        setPopupProps({
                            title: 'Gerar contrato',
                            message: 'Tem certeza que deseja gerar o contrato?',
                            children: (
                                <DraftPreviewContainer
                                    {...{
                                        onClose: handleOnClose,
                                        onError: handleErrorResponse,
                                        onSuccess: () => {
                                            showSuccessToast(
                                                'Sucesso ao gerar contrato',
                                                'Ótimo! Agora você pode visualizar o contrato, na aba de Documentos.',
                                                setToast
                                            );
                                            handleOnClose();
                                            refetch();
                                            setIsGenerateDraftPreview(true);
                                        },
                                    }}
                                />
                            ),
                        }),
                    cancelBankAccountRequest: () => {
                        setPopupProps({
                            title: 'Cancelar solicitação',
                            message: '',
                            children: (
                                <CancelBankAccountRequestContainer
                                    bankAccountRequestData={bankAccountRequestData}
                                    onClose={() => setPopupProps(undefined)}
                                    setToast={setToast}
                                />
                            ),
                        });
                    },
                }}
            >
                {children}
            </BankAccountsActionsContext.Provider>
        </>
    );
};

export const useBankAccountActions = (): BankAccountActionsProps =>
    useContext(BankAccountsActionsContext);
