import { GroupedButtons, Switch, Typography } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import SendBillingStatement from './DrawerDetails/SendBillingStatement';
import CardLimits from './DrawerDetails/CardLimits';
import Tooltip from 'components/Tooltip';
import RequestLimitForm from './RequestLimitForm';
import { useState } from 'react';

interface RequestLimitFormProps {
    steps: { step: number };
    limitOption: string;
    hoursLimitOption: string;
    setHoursLimit: React.Dispatch<React.SetStateAction<number>>;
    currentLimit: any;
    isEscrow: boolean;
}

interface StepComponents {
    [step: number]: JSX.Element;
}

const RequestLimitFormContainer: React.FC<RequestLimitFormProps> = ({
    steps,
    hoursLimitOption,
    limitOption,
    setHoursLimit,
    currentLimit,
    isEscrow
}) => {
    const [advancedSettingsLimit, setAdvancedSettingsLimit] = useState(false);
    const stepComponents: StepComponents = {
        1: <RequestLimitForm {...{ hoursLimitOption, limitOption, advancedSettingsLimit, currentLimit, isEscrow }} />,
        2: <CardLimits />,
        3: <SendBillingStatement />,
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAdvancedSettingsLimit(checked);
        if (hoursLimitOption === "nonBusinessHours" && !checked) {
            setHoursLimit(0);
        }
    };

    return (
        <>
            <Stack spacing={2}>
                <Grid container item>
                    <Switch onChange={handleChange} />
                    <Typography
                        variant="md"
                        color="common.black"
                        ml={2}
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        Configurações avançadas de limite
                    </Typography>
                </Grid>

                <Stack
                    mx={0.2}
                    my={1}
                    direction="row"
                    alignItems="center"
                    justifyContent={'space-between'}
                >
                    <Typography variant="xl" fontWeight={700}>
                        {advancedSettingsLimit ? "Configurações avançadas" : "Configuração simplificada"}
                    </Typography>
                    <Tooltip
                        title={advancedSettingsLimit ? "Configuração avançada:" : "Configuração simplificada:"}
                        description={advancedSettingsLimit ? "Permite definir os valores máximos diários para cada tipo de operação (TED, Pix, Boleto, Transferência interna), divididos entre horário comercial e não-comercial."
                            : "Permite definir os valores máximos diários para cada tipo de operação (TED, Pix, Boleto, Transferência interna), tipo de beneficiário (mesma titularidade, pessoa física ou pessoa jurídica) e divididos entre horário comercial e não-comercial."}
                    />
                </Stack>
                <Typography variant="md" fontWeight={700}>
                    Informe quanto deseja aumentar do seu limite
                </Typography>
                {steps.step < 3 && advancedSettingsLimit && (
                    <GroupedButtons
                        groupedButtons={['Horário comercial', 'Fora do horário']}
                        onClick={(value) => setHoursLimit(value)}
                        size="large"
                        width={230}
                    />
                )}
                {stepComponents[steps.step]}
            </Stack>
        </>
    );
};

export default RequestLimitFormContainer;
