import { FunctionComponent } from 'react';

export interface FilterComponentProps {
    componentFilter?: React.ReactNode;
    showFilters?: boolean;
  } 
  
  const FilterComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const { showFilters, componentFilter } = props;
    return (
      <>
        {showFilters && componentFilter}
      </>
    );
  };
  export default FilterComponent;