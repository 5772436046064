import { string, object } from 'yup';

export function validationSchemaPaymentRevision(useCDC: boolean) {
    switch (useCDC) {
        case true:
            return object().shape({
                bankAccountId: string().nullable(),
                changeBankAccountId: string().nullable()
            });
        case false:
            return object().shape({
                bankAccountId: string().nullable()
            });
    }
};

export const defaultValuesPaymentRevision = {
    bankAccountId: null,
    changeBankAccountId: null
};