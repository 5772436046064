/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { useFormContext } from 'contexts/formContext';
import ReporterForm from './ReporterForm';
import ExecutorForm from './ExecutorForm';
import ToAccountForm from './ToAccountForm';
import { FraudRecord } from '../FraudRecordContainer';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';

interface DetailsFormProps {
    fraudRecordDetails?: FraudRecord;
}

interface FormSection {
    title: string;
    index: number;
    component: React.ReactNode;
    tooltip: string;
}

const sections: FormSection[] = [
    {
        title: 'Reclamante',
        index: 0,
        component: <ReporterForm />,
        tooltip: `Informações referente ao Reclamante. Campos a serem preenchidos com os documentos de origem da Atividade Relacionada, para a execução de uma ocorrência ou tentativa de fraude, normalmente dados de supostas vítimas.`,
    },
    {
        title: 'Executor ou Suspeito da Fraude',
        index: 1,
        component: <ExecutorForm />,
        tooltip: `Informações referente ao Executor ou suspeito da fraude. Campos exclusivamente a serem preenchidos com o documento do Fraudador ou Suspeito de Executar o indício de fraude. (Pessoa Física ou Jurídica). Não são dados de vítimas ou supostas vítimas.`,
    },
    {
        title: 'Conta Destino',
        index: 2,
        component: <ToAccountForm />,
        tooltip: `Informações referente a Conta Destino. Campos a serem preenchidos quando a atividade relacionada se refere à serviços de transferências de recursos. O documento registrado como conta destinatária deve ter um caráter fraudulento.`,
    },
];

const DetailsForm: React.FC<DetailsFormProps> = ({ fraudRecordDetails }) => {
    const { setValue } = useFormContext();

    useEffect(() => {
        if (fraudRecordDetails) {
            setValue('details.reporter.taxPayer', fraudRecordDetails.taxPayer);
            setValue(
                'details.reporter.documentNumber',
                formatDocumentNumber(fraudRecordDetails.registrationNumber)
            );
            setValue('details.reporter.fullName', fraudRecordDetails.name);
            setValue('details.reporter.tradeName', fraudRecordDetails?.tradeName);
        }
    }, []);

    return (
        <Stack spacing={3}>
            {sections.map((section, index) => (
                <Grid key={index} container alignItems={'center'}>
                    <Grid item xs={11}>
                        <CustomAccordionContainer
                            description={section.title}
                            index={section.index}
                            tooltipText={section.tooltip}
                        >
                            {section.component}
                        </CustomAccordionContainer>
                    </Grid>
                </Grid>
            ))}
        </Stack>
    );
};

export default DetailsForm;
