import { IUploadModel } from 'contexts/apiRequestContext';
import { EAssignmentCalculationMode, ELiquidationMode } from '../enum';
import { ESignatureType, ESignatureValidation } from 'utils/enums';
import { ICommissionModel } from 'utils/interface';
import { AmortizationTypes } from 'services/Amortization';
import { LiquidationScheduleRequestModel } from 'services/LiquidationSchedule';
import { WarrantyTypes } from './WarrantyModel';

export interface IBasicRelatedPersonCreditNote {
    personId: string;
    typeOfRelationship: string;
    relatedToId?: string;
    signatureType: ESignatureType;
    signatureValidation: ESignatureValidation;
    percentage: number;
}

export interface ICommissionReadModel extends ICommissionModel { }

export interface IPaymentPeriodicityReadModel {
    every: number;
    periodicity: string;
}

export type CreditNoteOpStatus =
    | 'Draft'
    | 'ComplianceApproval'
    | 'Signatures'
    | 'Liquidation'
    | 'Finished'
    | 'Disapproved'
    | 'Error'
    | 'Revision'
    | 'CreditApproval'
    | 'InstrumentApproval'
    | 'Canceled'
    | 'WaitLiquidation'
    | 'SignaturesValidation'
    | 'PaymentRevision'
    | 'ManualLiquidation'
    | 'Warranty'
    | 'ManualWarranty'
    | 'MarginReserveApproval';

export type LiquidationType = 'Invoice' | 'EletronicTransfer';

type AssignmentCalculationType = 'Absolute' | 'Percentage';

export interface IAssignmentCalculationModel {
    amount: number;
    type: AssignmentCalculationType;
    mode: EAssignmentCalculationMode;
}

export interface IPaymentScheduleItemCreateModel {
    dueDate: string;
    principalAmountInCents: number;
    amortization: number;
    interest: number;
    financeTax: number;
    addedFixedCosts: number;
    payment: number;
}

export class CreditNoteOpBaseModel {
    productId!: string;
    fundId?: string;
    warranty: WarrantyTypes[] = [];
    emissionDate?: string;
    amortization: AmortizationTypes = {} as AmortizationTypes;
    personId!: string;
    liquidationType: LiquidationType = {} as LiquidationType;
    invoiceBarCode?: string;
    beneficiaryId?: string;
    collateral?: string;
    observations?: string;
    status?: CreditNoteOpStatus;
    bankAccountId!: string;
    tokenName?: string;
    isRegistered: boolean = false;
    bulkAssignment: boolean = false;
    invoiceValue?: number;
    invoiceNumber?: string;
    changeBankAccountId?: string;
    issueNumber?: number;
    emissionNumber?: number;
    quantity?: number;
    unitPrice?: number;
    assignmentCalculation: IAssignmentCalculationModel = {} as IAssignmentCalculationModel;
    relatedPersonCreditNote: IBasicRelatedPersonCreditNote[] = [];
    uploads: IUploadModel[] = [];
    paymentScheduleItems: IPaymentScheduleItemCreateModel[] = [];
    unicoSignScore?: number;
    registrationNumber?: string;
    transitoryBankAccountId?: string;
    transitoryBankAccountOpenAccount?: boolean;
    liquidationMode?: ELiquidationMode;
    liquidationSchedule: LiquidationScheduleRequestModel[] | null = null;

    constructor(init?: Partial<CreditNoteOpBaseModel>) {
        Object.assign(this, init);
    }
}

export type CheckAverbationResponseType = {
    status: string;
    identificador: string;
    protocolo: number;
};

export interface CosifReadModel {
    code: string;
    description: string;
    pix: boolean;
    transfer: boolean;
    bankSlip: boolean;
    tenantName: string;
    externalAccountId: string;
}

export function canEnableClosure(
    status: string | undefined,
    transitoryBankAccountId: string | undefined,
    showClosureButton: boolean,
    isLiquidationSchedule: boolean,
    hasPermission: boolean
): boolean {
    const isOperationClosed = status === 'Finished';
    const hasBankAccount = Boolean(transitoryBankAccountId);

    return (
        isOperationClosed &&
        hasBankAccount &&
        showClosureButton &&
        isLiquidationSchedule &&
        hasPermission
    );
}

export function shouldShowLiquidationClosureButton(status: string | undefined): boolean {
    return status === 'Pending' || status === 'Cancelled' || !status;
}