import { IconButton, styled } from '@mui/material'
import { NotificationIcon } from '@uy3/web-components';
import { GetListApiElasticSearch } from 'contexts/apiRequestContext';
import { IMetadataNotifications, NotificationsReadModel } from 'services/notifications/types/notificationsReadModel';
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type NotificationCustomIconProps = {
    showNotifications: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    notificationsData: GetListApiElasticSearch<NotificationsReadModel, IMetadataNotifications> | undefined, 
}

const DetailNewsNotifications = styled('div')(() => ({
    width: 22, 
    height: 22, 
    background: theme.palette.error.light, 
    color: theme.palette.common.white, 
    fontWeight: 700,
    borderRadius: '100%', 
    zIndex: 200, 
    position: 'absolute', 
    top: -3, 
    right: -3, 
    justifyContent: 'center', 
    alignContent: 'center', 
    textAlign: 'center'
}));

export const NotificationCustomIcon = ({ showNotifications, notificationsData }: NotificationCustomIconProps) => {
    const info = (notificationsData?.metadata?.newNotifications??0); 
    const hasNewNotifications: boolean = info > 0;
    return (
        <div style={{position: 'relative'}}>
            <IconButton onClick={(e) => showNotifications(e)}>
                <NotificationIcon
                    htmlColor={theme.palette.grey['600']}
                    sx={{ height: (theme) => theme.spacing(3), width: (theme) => theme.spacing(3) }}
                />
            </IconButton>
            {hasNewNotifications && <DetailNewsNotifications>{info >= 100 ? `+99`:`${info}`}</DetailNewsNotifications>}
        </div>
    )
}
