import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { QueryAmortizationCreateModel, toAmortizationDataModel } from './types';
import { FieldValues } from 'react-hook-form';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';

var url = `/Amortization`;
export async function createSimulationCreditNote(data: FieldValues, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const payload = toDataModel<QueryAmortizationCreateModel>(
        data as Record<keyof QueryAmortizationCreateModel, unknown>,
        QueryAmortizationCreateModel
    );
    const ammortization = payload?.amortization;

    if (ammortization) {
        payload.amortization = toAmortizationDataModel(ammortization);
    }

    return await creditAxiosApi.post(url, payload, config);
}
