import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';

function WalletFiltersForm() {
    const { watch } = useFormContext();

    const ActionSubmitSearch = () => {
        return (
            <InputAdornment position="end">
                <IconButton type="submit">
                    <SearchIcon
                        sx={{ width: 25, height: 25 }}
                        htmlColor="#666666"
                    />
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Código convênio', value: 'walletsCode' },
                    { label: 'Nome ', value: 'name' },
                ]}
            />
            {watch('optionFilter') === 'name' && (
                <TextFormField
                    name="name"
                    variant="outlined"
                    label="Filtrar por nome"
                    fullWidth
                    InputProps={{
                        endAdornment:<ActionSubmitSearch/>,
                    }}
                />
            )}
            {watch('optionFilter') === 'walletsCode' && (
                <TextFormField
                    name="walletsCode"
                    variant="outlined"
                    label="Filtrar por código convênio"
                    fullWidth
                    InputProps={{
                        endAdornment: <ActionSubmitSearch/>,
                    }}
                />
            )}
        </>
    );
}

type WalletFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const WalletFilters = ({ currentValues, removeFilterSelected }: WalletFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<WalletFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
