import { Stack } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { DataTable, DeleteIcon, EditIcon, ReportIcon } from '@uy3/web-components';
import { RowActions } from 'components/RowActions/RowActions';
import { useFormContext } from 'contexts/formContext';
import { localPaginateDataTable } from 'helpers';
import { IWarrantyReadModel, mapDescriptionWarranty, mapTitleWarranty } from 'services/creditNote';

type WarrantyTabProps = {
    name: string;
    rowsPerPage: number;
    deleteAsset: (index: number) => void;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    handleOnEdit: (rowIndex: number) => void;
    onDetails: (row: GridRowModel<IWarrantyReadModel>) => void;
};

export const WarrantyListTab = ({
    page,
    name,
    rowsPerPage,
    setPage,
    deleteAsset,
    setRowsPerPage,
    handleOnEdit,
    onDetails
}: WarrantyTabProps) => {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];

    const columns: GridColDef[] = [
        {
            field: 'warrantyType',
            headerName: 'Tipo de garantia',
            hideSortIcons: true,
            maxWidth: 200,
            flex: 1,
            editable: false,
            renderCell: (cellValue) => mapTitleWarranty(cellValue.value),
        },
        {
            field: 'description',
            headerName: 'Descrição',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: (cellValue) => {
                return mapDescriptionWarranty(cellValue.row);
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: ({ row }: any) => {
                const rowIndex = queryData.findIndex((obj: any) =>
                    Object.keys(row).every(key => obj[key] === row[key])
                );


                return (
                    <Stack direction="row" alignItems='center'>
                        <RowActions
                            listButtons={[
                                {
                                    action: () => onDetails(row),
                                    disabled: false,
                                    icon: <ReportIcon />,
                                    label: 'Detalhes',
                                },
                                {
                                    action: () => handleOnEdit(rowIndex),
                                    disabled: false,
                                    icon: <EditIcon />,
                                    label: 'Editar',
                                },
                                {
                                    action: () => deleteAsset(rowIndex),
                                    disabled: false,
                                    icon: <DeleteIcon />,
                                    label: 'Excluir',
                                },
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            columns={columns}
            rows={localPaginateDataTable(queryData, page, rowsPerPage)}
            page={page}
            rowCount={queryData?.length}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            getRowId={() => Math.random().toString()}
            enableJumpAction={false}
        />
    );
};
