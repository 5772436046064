import { creditAxiosApi } from "services/axiosApi/axiosApi";import { FundReadModel, FundFiltersProps } from './types/fundReadModel';
import { FundFormSchema } from 'pages/Fund/FundForm/FundSchema';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import { FundCreateModel } from './types/fundCreateModel';
import { FundUpdateModel } from './types/fundUpdateModel';

var url = `/Fund`;
export const getFundsList = async (filters: FundFiltersProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };

    return await creditAxiosApi.get<GetListApiResponseSuccess<FundReadModel>>(url, config);
};

type FundCreateOrUpdateModel = FundCreateModel | FundUpdateModel;
export async function editOrCreateFund(
    data: FundFormSchema,
    fundId: string,
    token = '',
    tenant?: string
) {
    const isCreating = fundId === 'novo';
    const urlFull = isCreating ? url : `${url}/${fundId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const FundCreateOrUpdate =  isCreating ? FundCreateModel : FundUpdateModel;

    const payload = toDataModel<FundCreateOrUpdateModel>(data, FundCreateOrUpdate);

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<FundReadModel>(urlFull, payload, axiosConfig);
}

export async function createFundAsync(data: FundReadModel, token: string, tenant?: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };
    return await creditAxiosApi.post('/Fund', data, config);
}

export async function getFundById(fundId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<FundReadModel>(url + `/${fundId}`, config);
}

export async function deleteFundAsync(fundId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.delete(url + `/${fundId}`, config);
}
