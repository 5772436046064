import { string, object } from 'yup';

export function ConfirmEmailFormValidationSchema() {
	return object().shape({
		code: string().typeError('Código precisa ser preenchido.')
			.required('Código precisa ser preenchido.'),		
	});
}

export const ConfirmEmailFormDefaultValues = {
	code: "",	
};