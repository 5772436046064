import { Grid } from '@mui/material';
import { DatePickerFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFormContext } from 'contexts/formContext';
import { useFundsList } from 'contexts/fundContext';
import { useTenant } from 'contexts/tenantContext';
import React from 'react';

export const RemessaLiquidacaoForDateForm: React.FC = () => {
    const { currentTenantId } = useTenant()
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10, tenant: currentTenantId! }, 'always');

    const { watch } = useFormContext();

    return (
        <Grid container spacing={2} columns={6}>
            <Grid item xs={3}>
                <AutocompleteField
                    name="fundId"
                    label="Fundo"
                    displayName="fundIdDisplay"
                    link={`/cadastro/fundos/${watch('fundId')}`}
                    showEndIcon={watch('fundId')}
                    endIconType="link"
                    {...fundAutoCompleteProps!}
                />
            </Grid>
            <Grid item xs={3}>
                <DatePickerFormField
                    label='Data de liquidação'
                    fullWidth
                    name='date'
                />
            </Grid>
        </Grid>
    );
}