import { creditAxiosApi } from "services/axiosApi/axiosApi";

var url = `/UserGroup`;

export async function getGroupById(groupName: string, token = "", tenant?: string) {

    const urlWithTenant = tenant ? `${url}/${groupName}?tenant=${tenant}` : `${url}/${groupName}`

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get(urlWithTenant, config);
};

export interface CreateOrEditGroupProps {
    description: string;
    groupName: string;
    precedence: number; 
};

export async function editOrCreateGroup(data: CreateOrEditGroupProps, tenant?: string | undefined, groupId?: string, token = "") {

    const type = groupId === 'novo' ? 'create' : 'edit';

    var url = type === 'create' ? `/UserGroup` : `/UserGroup/${groupId}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params: {tenant}
    };

    const request = {
        create: {
            function: () => creditAxiosApi.post(url, data, config),
            successMessage: 'Grupo cadastrado com sucesso',
            errorMessage: 'Ocorreu um erro no cadastrar o grupo'
        },
        edit: {
            function: () => creditAxiosApi.put(url, data, config),
            successMessage: 'Grupo editado com sucesso',
            errorMessage: 'Ocorreu um erro ao atualizar o grupo'
        }
    };

    return await request[type].function();

}

export type UserInGroupProps = {
    size: number;
    page: number;
    nextToken?: string | undefined;
    tenant?: string | undefined;
}

export const getUserInGroupList = (filters: UserInGroupProps, groupName: string, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters 
    };

    return creditAxiosApi.get(`${url}/GetUsersByGroup/${groupName}`, config);
};


export const deleteGroupById = async (groupName: string, token?: string, tenant?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {tenant}
    };

    return await creditAxiosApi.delete(url + `/${groupName}`, config);
};