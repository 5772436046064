export const getColor = (status: string): string => {
    const colorMap: { [key: string]: string } = {
        Rascunho: '#D04D27',
        Revisão: '#C77700',
        'Revisão de Pagamento': '#C77700',
        'Revisão de Abertura de Conta': '#C77700',
        Erro: '#C77700',
        Encerrado: '#4CAF50',
        Ativa: '#4CAF50',
        Concluída: '#4CAF50'
    };
    return colorMap[status] || '#D04D27';
};

export const getBackgroundColor = (status: string): string => {
    const colorRGBA: { [key: string]: string } = {
        Rascunho: 'rgba(208, 77, 39, 0.08)',
        Revisão: 'rgba(255, 152, 0, 0.05)',
        'Revisão de Pagamento': 'rgba(255, 152, 0, 0.05)',
        'Revisão de Abertura de Conta': 'rgba(255, 152, 0, 0.05)',
        Erro: 'rgba(255, 152, 0, 0.05)',
        Encerrado: 'rgba(123, 198, 126, 0.05)',
        Ativa: 'rgba(123, 198, 126, 0.05)',
        Concluída: 'rgba(123, 198, 126, 0.05)'
    };
    return colorRGBA[status] || 'rgba(208, 77, 39, 0.08)';
};
