import { creditAxiosApi } from "services/axiosApi/axiosApi";
const url = '/Cnab'; 

export type GenerateCnabsType = {
    fundId: string, 
    payload: string[], 
    type: string, 
    token: string, 
    requiredFundId?: boolean;
}

export async function postCNABNCB3(data: string[], token: string) {
    const id = data[0];
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/NCB3/${id}`, {}, config);
}

export async function postCNABItau(data: string[], token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/cnab400itau`, data, config);
}

export async function CNABStarPeculioAsync(data: string[], token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/StarPeculio`, data, config);
}

export async function CNABStarAssistenciaFinanceiraAsync(data: string[], token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(`${url}/StarAssistenciaFinanceira`, data, config);
}

export async function postGenerateCnabs({fundId, payload, token, type, requiredFundId = true}: GenerateCnabsType) {
    const url: { [type: string]: string } = {
        "Cnab400": "/Cnab400", 
        "Cnab400B": "Cnab/Cnab400B",   
        "Cnab444": `/Cnab/cnab444`,
        "Cnab444REAG": `/Cnab444REAG`,
        "Cnab500": `/Cnab500`,
        "Cnab550": `/Cnab550`, 
        "Cnab600": `/Cnab//cnab600`, 
        "CnabAXA": "Cnab/cnabAxaAdesao",
        "CnabAXACancel": "Cnab/cnabAxaCancel",
        "LiquidationFileWebCred": '/LiquidationFileWebCred', 
        "Vortx": "/vortx",
    }

    if (!!fundId && requiredFundId) { 
        url[type] += `?fundId=${fundId}`;
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url[type], payload, config);
}
