import { object, string } from "yup";

export const validationSchemaClaimRequest = () => {
  return object().shape({
    claimId: string().typeError('Selecione uma chave para poder continuar').required('Selecione uma chave para poder continuar')
  });
}

export const defaultValuesClaimRequest = {
  claimId: null
};