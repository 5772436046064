import { GridColDef } from '@mui/x-data-grid'
import { DataTable } from '@uy3/web-components'
import { maskCurrencyInCents } from 'helpers/formats/Currency'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { IFaltante } from 'services/remessasVortx/remessasVortx.type'

type MissingListProps = {
    queryData: IFaltante[]
}

export const MissingList = ({ queryData }: MissingListProps) => {
    const columns: GridColDef[] = [
        {
            field: 'cpf',
            headerName: 'CPF',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            maxWidth: 230,
            renderCell: ({ value }) => formatDocumentNumber(value)

        },
        {
            field: 'valorInt',
            headerName: 'Valor',
            hideSortIcons: true,
            editable: false,
            flex: 1,
            maxWidth: 230,
            renderCell: ({ value }) => `${maskCurrencyInCents(value)}`
        }
    ];

    return (
        <DataTable
            headerBarWrapper={{
                header: 'Faltantes'
            }}
            columns={columns}
            rows={queryData ?? []}
            page={0}
            rowCount={queryData?.length ?? 0}
            rowsPerPage={5}
            setPage={() => { }}
            setRowsPerPage={() => { }}
            getRowId={(x) => Math.random().toString()}
        />
    )
}
