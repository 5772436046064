import { Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields";
import { ApiResponseError, ToastType, useApiRequest } from "contexts/apiRequestContext";
import { FormProvider } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { useParams } from "react-router";
import { sendAssignmenteEmailCreditNoteById } from "services/creditNote";
import { FieldValues } from 'react-hook-form';
import { defaultValuesSendDocsForm, validationSchemaSendDocsForm } from "./SendDocsSchema";
import { mapErrorResponse } from "contexts/responseErrorContext";

type SendDocsCreditNoteProps = {
    setPopupProps: (props: any) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
};

export const SendDocumentsCreditNote = ({ setPopupProps, setToast }: SendDocsCreditNoteProps) => {
    const { startRequest, endRequest, submitting } = useApiRequest();
    const { token } = useIdentity();
    const { id } = useParams();
    const queryClient = useQueryClient();


    const onSubmit = async (values: FieldValues) => {
        const { email } = values;

        startRequest();
        sendAssignmenteEmailCreditNoteById(id!, token!, email).then(() => {
            queryClient.invalidateQueries({
                queryKey: ["credit-note-list"],
                refetchType: 'active',
            });
            setToast({
                open: true,
                title: 'E-mail enviado com sucesso!',
                severity: 'success',
            });
            endRequest(true);
            setPopupProps(undefined);
        }).catch((response: ApiResponseError) => {
            const { errorMessage } = mapErrorResponse(response);
            setToast({
                open: true,
                title: 'Ops, ocorreu um erro!',
            description: errorMessage,
                severity: 'error',
            });
            endRequest(false);
        });
    };

    const schema = validationSchemaSendDocsForm();
    const defaultValues = defaultValuesSendDocsForm;

    return (
        <>
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={onSubmit}>
                <>
                    <Grid container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        mt={1}
                    >
                        <Grid item xs={12}>
                            <TextFormField
                                name="email"
                                label="E-mail"
                                variant='outlined'
                                fullWidth
                                required
                            />
                        </Grid>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={submitting}
                            type="submit"
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Enviar
                        </Button>
                    </Grid>
                </>
            </FormProvider>
        </>
    );
};