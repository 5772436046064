import { Stack } from '@mui/material';
import { SelectWarranty } from './SelectWarranty';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultvaluesSelectWarrantyForm,
    validationSchemaSelectWarrantyForm,
} from './SelectWarrantySchema';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import React from 'react';
import { isEmpty } from 'lodash';
import { showWarningToast, ToastType } from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import { useIdentity } from 'contexts/identityContext';
import {
    fetchAndMapWarrantyData,
    mapSiapeWarrantyPublicPayroll,
    mapZetraWarrantyPublicPayroll,
} from 'services/payrollAgreement';
import { IWarrantyReadModel } from 'services/creditNote';
import { IServidore, IZetraMarginQueryReadModel } from 'services/zetra';

type SelectWarrantyContainerProps = {
    marginQueryData: IZetraMarginQueryReadModel | ISiapeMarginQueryReadModel | undefined;
    onBackStep: () => void;
    onCloseAll: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    handleMarginQuery: () => void;
    isZetra: boolean;
    registrationNumber: string
};

export const SelectWarrantyContainer: React.FC<SelectWarrantyContainerProps> = ({
    marginQueryData,
    onBackStep,
    setToast,
    onCloseAll,
    handleMarginQuery,
    isZetra,
    registrationNumber
}) => {
    const { setValue, watch } = useFormContext();
    const { token } = useIdentity();
    const watchedWarranty = (watch('warranty') ?? []) as IWarrantyReadModel[];

    const handleSubmitForm = async (values: FieldValues) => {
        const marginSiape = values?.marginSiape;
        const margensOptionsZetra: string[] = values?.margensOptionsZetra ?? [];

        if ((isEmpty(marginSiape) && !isZetra) || (isZetra && margensOptionsZetra.length === 0)) {
            return showWarningToast('Selecione ao menos 1 modelo de margem', '', setToast);
        }

        if (!isZetra) {
            //#region Siape
            const queryData = marginQueryData as ISiapeMarginQueryReadModel;
            const selectedItems =
                queryData?.result?.VinculoFuncionals.filter(
                    (item) => `${item?.CnpjOrgao}-${item?.CodOrgao}` === marginSiape
                ) ?? [];

            if (!selectedItems.length) {
                return showWarningToast('Nenhum item selecionado encontrado', '', setToast);
            }

            const internalId = selectedItems[0]?.CodOrgao;

            await fetchAndMapWarrantyData(
                internalId,
                selectedItems,
                mapSiapeWarrantyPublicPayroll,
                token!,
                watchedWarranty,
                setValue,
                setToast,
                onCloseAll
            );
        } else {
            //#region Zetra
            const queryData = marginQueryData as IZetraMarginQueryReadModel;
            let selectedItems = [] as IServidore[];

            if (margensOptionsZetra?.length > 0) {
                selectedItems =
                    queryData?.result?.servidores.filter(({ orgaoCodigo, margens }) =>
                        margensOptionsZetra.some((code) => {
                            const codeWithValDisponivel = margens.map((margem) => {
                                return `${margem?.codigo}-${orgaoCodigo}-${margem?.valorDisponivel}`;
                            });

                            return codeWithValDisponivel.includes(code);
                        })
                    ) ?? [];
            }

            const internalId = selectedItems[0]?.orgaoCodigo;

            await fetchAndMapWarrantyData(
                internalId,
                selectedItems,
                (serv, data) => mapZetraWarrantyPublicPayroll(data, serv, values, registrationNumber),
                token!,
                watchedWarranty,
                setValue,
                setToast,
                onCloseAll
            );
        }
    };

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultvaluesSelectWarrantyForm}
                validationSchema={validationSchemaSelectWarrantyForm()}
                onSubmit={handleSubmitForm}
            >
                <SelectWarranty
                    {...{
                        isZetra,
                        marginQueryData,
                        onClose: onBackStep,
                        handleMarginQuery,
                    }}
                />
            </FormProvider>
        </Stack>
    );
};
