import { FormProvider } from 'contexts/formContext';
import { BatchAssignmentForm } from './BatchAssignmentForm';
import { validationBatchAssignmentFormSchema } from './Tabs/InformationBatchAssignment/InformationBatchAssignmentSchema';
import { useNavigate, useParams } from 'react-router';
import {
    useBatchAssignmentMutation,
    useBatchAssignmentByIdData,
    useCancelBatchAssignmenMutation,
    useDeleteBatchAssignmenMutation,
    useRecalculateBatchAssignamntMutation,
    useLiquidationFileBatchAssignemntMutation,
    useRemittenceVortxBatchAssignemntMutation,
    useGenareteZipMutation,
    useSaveDocumentsMutation,
    useHemeraDepositBatchAssignment,
    useHemeraGenerateFileBatchAssignment,
    useHemeraSendFileBatchAssignment,
    useCercRegisterMutation,
    useInstallmentBatchAssigment,
    useGenerateCSVAngaFileMutate,
} from 'contexts/batchAssignment/batchAssignmentContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { useEffect, useState } from 'react';
import { Modal } from '@uy3/web-components';
import ApproveRevisionContainer from './Modals/ApproveRevision/ApproveRevisionContainer';
import GenerateCnabFormContainer from './Modals/GenerateCnabForm/GenerateCnabFormContainer';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import RecalculateFormContainer from './Modals/Recalculate/RecalculateFormContainer';
import { PayloadBatchAssignmentProps } from 'services/creditNote/BatchAssignment/BatchAssignment.types';
import ConfirmPopup from '../ConfirmPopup';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { FieldValues } from 'react-hook-form';
import Toast from 'components/Toast/Toast';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import ErrorLogs from 'components/Logs';
import { IOperationsLogs } from 'components/Logs/Logs.interface';
import { SendRemessaContainer } from 'pages/Fund/FundForm/Drawer/SendRemessa/SendRemessaContainer';
import { useFundData } from 'contexts/fundContext';
import AssignDrawer from 'components/Assign/AssignDrawer';

export const BatchAssignmentFormContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [formKey, setFormKey] = useState(0);
    const { submitting } = useApiRequest();
    const [actions, setActions] = useState<string | undefined>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [openModalLogs, setOpenModalLogs] = useState(false);
    const [operationsLogs, setOperationsLogs] = useState<IOperationsLogs>();
    const { batchAssignmentData, refetch, isFetching, isLoading: isLoadingBatchAssignment } = useBatchAssignmentByIdData(id!);
    const { fundData } = useFundData(batchAssignmentData?.fundId);

    useEffect(() => {
        if (!isFetching && batchAssignmentData) {
            setFormKey((prevKey) => prevKey + 1);
        }
    }, [isFetching, batchAssignmentData]);

    const onClose = () => setActions(undefined);
    const getCreditNoteIds = batchAssignmentData?.creditNotes.map((creditNote: any) => creditNote.id);

    const onSuccess = (action: string, data?: any) => {
        onClose();
        refetch();

        const successMessage: { [type: string]: string } = {
            cancel: 'Cessão cancelada com sucesso!',
            delete: 'Cessão excluída com sucesso!',
            recalculate: 'Cessão recalculada com sucesso!',
            liquidationFile: 'Arquivo de liquidação gerado com sucesso!',
            remittenceVortx: 'Remessa Vortx gerada com sucesso!',
            generateZip: 'Lastro ZIP gerado com sucesso!',
            saveDocuments: 'Documentos salvos com sucesso!',
            depositHemera: 'Depositado com sucesso',
            generateFileHemera: 'Arquivo hemera gerado com sucesso!',
            sendFileHemera: 'Arquivo hemera enviado com sucesso!',
            cercRegiste: 'Registro relizado com sucesso!',
            startInstallment: "Sucesso ao gerar cobrança", 
            generateFileAnga: "Sucesso ao gerar o arquivo CSV"
        };

        if (action === 'delete') 
            return navigate(-1);

        if (action === 'cercRegister') {
            setOperationsLogs(data);
            return setOpenModalLogs(true);
        }; 

        if (action === 'generateFileAnga') {
            window.open(data?.tempUrl);
        }

        const title = successMessage[action];
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage, warningToastError } = mapErrorResponse(error);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        }
        else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const { mutate, isLoading } = useBatchAssignmentMutation(onSuccess, onError);
    const { mutateCancel, isLoadingCancel } = useCancelBatchAssignmenMutation(() => onSuccess('cancel'), onError);
    const { mutateDelete, isLoadingDelete } = useDeleteBatchAssignmenMutation(() => onSuccess('delete'), onError);
    const { recalculateMutate, isLoadingRecalculate } = useRecalculateBatchAssignamntMutation(id!, () => onSuccess('recalculate'), onError);
    const { mutateLiquidationFile } = useLiquidationFileBatchAssignemntMutation(() => onSuccess('liquidationFile'), onError);
    const { mutateRemittenceVortx } = useRemittenceVortxBatchAssignemntMutation(() => onSuccess('remittenceVortx'), onError);
    const { mutateGenerateZip } = useGenareteZipMutation(() => onSuccess('generateZip'), onError);
    const { mutateSaveDocuments } = useSaveDocumentsMutation(id!, () => onSuccess('saveDocuments'), onError);
    const { mutateHemeraDeposit } = useHemeraDepositBatchAssignment(id!, () => onSuccess('depositHemera'), onError);
    const { mutateGenerateFile } = useHemeraGenerateFileBatchAssignment(id!, () => onSuccess('generateFileHemera'), onError);
    const { mutateSendFile } = useHemeraSendFileBatchAssignment(id!, () => onSuccess('sendFileHemera'), onError);
    const { mutateAsync: cercRegisterMutate } = useCercRegisterMutation((data) => onSuccess('cercRegister', data), onError);
    const { installmentBatchAssignmentMutate } = useInstallmentBatchAssigment((data) => onSuccess('startInstallment', data), onError);
    const { mutateAsync: generateFileCSVAnga } = useGenerateCSVAngaFileMutate(id!, (data) => onSuccess('generateFileAnga', data), onError);

    const onSubmit = (formValues: PayloadBatchAssignmentProps) => {
        const payload = {
            ...formValues,
            creditNoteIds: getCreditNoteIds,
        };
        mutate(payload);
        refetch();
    };

    const validationSchema = validationBatchAssignmentFormSchema();
    const handleOnModalCloseLogs = () => setOpenModalLogs(false);

    return (
        <GenericFormSkeleton isLoading={isLoadingBatchAssignment}>
            <>
                <ErrorLogs
                    {...{
                        onModalCloseLogs: handleOnModalCloseLogs,
                        openModalLogs,
                        operationsLogs
                    }}
                />
                <Toast toast={toast} setToast={setToast} />
                <RefreshProgress refreshing={isLoading || isFetching || submitting} />
                <FormProvider
                    key={formKey}
                    validationSchema={validationSchema}
                    defaultValues={batchAssignmentData}
                    onSubmit={onSubmit}
                    readOnly={true}
                >
                    <>
                        <Modal
                            open={actions === 'finished'}
                            onClose={onClose}
                            title="Aprovar cessão"
                            description="Conclua a etapa de revisão para concluir a cessão."
                            children={
                                <ApproveRevisionContainer
                                    refetch={refetch}
                                    onCloseModal={onClose}
                                />
                            }
                        />
                        <Modal
                            open={actions === 'recalculate'}
                            onClose={onClose}
                            title="Recalcular cessão"
                            description="Selecione os parâmetros para recalcular a cessão."
                            children={
                                <RecalculateFormContainer
                                    onCalculate={(values: FieldValues) =>
                                        recalculateMutate(values)
                                    }
                                    onCloseModal={onClose}
                                    isLoadingRecalculate={isLoadingRecalculate}
                                />
                            }
                        />
                        <BatchAssignmentForm
                            onClose={onClose}
                            mutateHemeraDeposit={mutateHemeraDeposit}
                            mutateGenerateFile={mutateGenerateFile}
                            mutateSendFile={mutateSendFile}
                            drawerActionSteps={actions}
                            refetch={refetch}
                            onSubmitSaveDocuments={mutateSaveDocuments}
                            setStepAction={setActions}
                            timeline={batchAssignmentData?.timeline ?? []}
                            statusDisplay={batchAssignmentData?.statusDisplay}
                            fund={fundData}
                        />
                    </>
                </FormProvider>
                <Modal
                    open={actions === 'generateCnab444'}
                    onClose={onClose}
                    title="Gerar CNAB 444"
                    children={<GenerateCnabFormContainer onCloseModal={onClose} />}
                />
                <ConfirmPopup
                    open={actions === 'cancel'}
                    title="Cancelar cessão"
                    text="Tem certeza que deseja cancelar essa cessão?"
                    onClose={onClose}
                    isLoading={isLoadingCancel}
                    onConfirm={() => mutateCancel(id!)}
                />

                <Modal
                    open={actions === 'liquidationFile'}
                    title="Gerar Arquivo de Liquidação"
                    description="Tem certeza que deseja gerar arquivo de liquidação?"
                    onClose={onClose}
                    children={
                        <GenericActionHandler
                            handleSubmit={() => mutateLiquidationFile(id!)}
                            onClose={onClose}
                            isModal={true}
                        />
                    }
                />
                <Modal
                    open={actions === 'generateFileAnga'}
                    title="Gerar Arquivo CSV (Angá)"
                    description="Tem certeza que deseja gerar arquivo?"
                    onClose={onClose}
                    children={
                        <GenericActionHandler
                            handleSubmit={() => generateFileCSVAnga()}
                            onClose={onClose}
                            isModal={true}
                        />
                    }
                />
                <Modal
                    open={actions === 'remittenceVortx'}
                    title="Gerar Remessa Vortx"
                    description="Tem certeza que deseja gerar remessa Vortx?"
                    onClose={onClose}
                    children={
                        <GenericActionHandler
                            handleSubmit={() => mutateRemittenceVortx(id!)}
                            onClose={onClose}
                            isModal={true}
                        />
                    }
                />
                <Modal
                    open={actions === 'generateZip'}
                    title="Gerar Lastro ZIP"
                    description="Tem certeza que deseja gerar lastro zip?"
                    onClose={onClose}
                    children={
                        <GenericActionHandler
                            handleSubmit={() => mutateGenerateZip(id!)}
                            onClose={onClose}
                            isModal={true}
                        />
                    }
                />

                <Modal
                    open={actions === 'startInstallment'}
                    title="Gerar cobrança"
                    description="Tem certeza que deseja gerar cobrança?"
                    onClose={onClose}
                    children={<GenericActionHandler
                        handleSubmit={() => installmentBatchAssignmentMutate(id!)}
                        onClose={onClose}
                        isModal={true}
                    />
                    }
                />

                <ConfirmPopup
                    open={actions === 'delete'}
                    title="Excluir cessão"
                    text="Tem certeza que deseja excluir essa cessão?"
                    onClose={onClose}
                    isLoading={isLoadingDelete}
                    onConfirm={() => mutateDelete(id!)}
                />

                <ConfirmPopup
                    open={actions === 'cercRegister'}
                    title="Registrar na CERC"
                    text="Tem certeza que deseja realizar esse registro?"
                    onClose={onClose}
                    isLoading={isLoadingDelete}
                    onConfirm={() => cercRegisterMutate(id!)}
                />

                <AssignDrawer
                    recordId={id!}
                    recordType="BatchAssignment"
                    navigate="/ccb/cessoes"
                    openDrawer={actions === 'assign'}
                    onClose={onClose}
                    refetchRecord={refetch}
                />

                <Modal
                    open={actions === 'sendRemessaVortx'}
                    align='left'
                    title='Enviar remessa Vortx'
                    onClose={onClose}
                >
                    <SendRemessaContainer {...{
                        onClose: onClose,
                        relatedDocs: batchAssignmentData?.uploads ?? [],
                        setToast,
                        refetch
                    }} />
                </Modal>
            </>
        </GenericFormSkeleton>
    );
};
