import { IRefetchOnMountModeGetQuery, useApiRequest } from '../../apiRequestContext';
import { useIdentity } from '../../identityContext';
import { useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { GenericQueryParamsDash } from 'services/walletManagement/dashboard/types/queryParams.types';
import { getGenericDashboardAsync } from 'services/walletManagement/dashboard/genericDashboardAsync';
import { useTenant } from 'contexts/tenantContext';
import { IBillingWalletRegisteredType } from 'services/walletManagement/dashboard/types/billingWalletRegistered.type';

export function useBillingWalletRegisteredDash(
    filters?: GenericQueryParamsDash,
    refetchOnMount?: IRefetchOnMountModeGetQuery
) {
    const { currentTenantId } = useTenant()
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const filtersComplete = { ...filters, tenant: currentTenantId } as GenericQueryParamsDash;

    const queryContext = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        retry: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['billing-wallet-registered', filtersComplete],
        queryFn: async (): Promise<IBillingWalletRegisteredType> => {
            startRequest();
            const recordType = "BillingWalletRegistered";
            const { data, status, statusText } = await getGenericDashboardAsync(
                filtersComplete,
                recordType,
                token
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as IBillingWalletRegisteredType;
            return dataSuccess;
        },
    });

    return queryContext;
}
