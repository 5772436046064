import { CardData } from '@uy3/web-components';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import moment from 'moment';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

interface BillingDetailsFormProps {
    valorTitulo: number;
}

const BillingDetailsForm: React.FC<BillingDetailsFormProps> = ({ valorTitulo }) => {
    const issuanceDate = moment().format('DD/MM/YYYY');

    return (
        <>
            <CustomTitle title="Dados da cobrança" variant="lg" />

            <CardData
                listItem={[
                    {
                        id: '1',
                        title: 'Data emissão',
                        value: issuanceDate,
                    },
                    { id: '2', title: 'Valor', value: formatCurrencyInCents(valorTitulo) },
                    { id: '3', title: 'Tipo de cobrança', value: 'Boleto bancário' },
                ]}
            />
        </>
    );
};

export default BillingDetailsForm;
