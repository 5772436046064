import { string, object } from 'yup';

export function fileConfirmationSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        walletsCode: string().when("optionFilter", {
            is: "walletsCode",
            then: string()
                .typeError('Código da carteira precisa ser preenchido.').required('Código da carteira: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string()
                .typeError('Data de início precisa ser preenchido.').required('Data de início: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().typeError('Data final precisa ser preenchido.').required('Data final: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesfileConfirmationchema = {
    optionFilter: 'initialDate',
    walletsCode: null,
    initialDate: null,
    finalDate: null,
};
