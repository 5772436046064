import type { ThemeOptions } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';
import { font } from './tokens';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    xg?: TypographyStyleOptions;
    xxxl?: TypographyStyleOptions;
    xxl?: TypographyStyleOptions;
    xl?: TypographyStyleOptions;
    lg?: TypographyStyleOptions;
    md?: TypographyStyleOptions;
    sm?: TypographyStyleOptions;
    xs?: TypographyStyleOptions;
  }

  interface TypographyVariantsOptions {
    xg?: TypographyStyleOptions;
    xxxl?: TypographyStyleOptions;
    xxl?: TypographyStyleOptions;
    xl?: TypographyStyleOptions;
    lg?: TypographyStyleOptions;
    md?: TypographyStyleOptions;
    sm?: TypographyStyleOptions;
    xs?: TypographyStyleOptions;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    xg?: true;
    xxxl?: true;
    xxl?: true;
    xl?: true;
    lg?: true;
    md?: true;
    sm?: true;
    xs?: true;
  }
}

export const typography: ThemeOptions['typography'] = {
  fontSize: 10,
  htmlFontSize: 10,
  fontFamily: '"Lato", sans-serif',

  xg: {
    fontSize: font.size.xg,
    lineHeight: font.lineHeight.xg
  },
  xxxl: {
    fontSize: font.size.xxxl,
    lineHeight: font.lineHeight.xxxl
  },
  xxl: {
    fontSize: font.size.xxl,
    lineHeight: font.lineHeight.xxl
  },
  xl: {
    fontSize: font.size.xl,
    lineHeight: font.lineHeight.xl
  },
  lg: {
    fontSize: font.size.lg,
    lineHeight: font.lineHeight.lg
  },
  md: {
    fontSize: font.size.md,
    lineHeight: font.lineHeight.md
  },
  sm: {
    fontSize: font.size.sm,
    lineHeight: font.lineHeight.sm
  },
  xs: {
    fontSize: font.size.xs,
    lineHeight: font.lineHeight.xs
  }
};
