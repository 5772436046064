import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';

type UsersAndGroupFiltersProps = {
    currentValues: any,
    removeFilterSelected: (index: number) => void;
    typeFilter: "users" | "groups"
}

function UsersAndGroupFiltersForm({ typeFilter }: { typeFilter: "users" | "groups" }) {
    const { watch } = useFormContext();
    return <>
        {typeFilter === 'users' ?
            <>
                <SelectFormField
                    label='Filtrar por'
                    name='optionFilter'
                    options={[
                        { label: 'Nome', value: 'name' },
                        { label: 'Email', value: 'email' },
                    ]}
                />
                {watch('optionFilter') === 'name' && <TextFormField
                    name='name'
                    label='Nome'
                    variant='outlined'
                    placeholder='Filtrar por nome'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />}
                {watch('optionFilter') === 'email' && <TextFormField
                    name='email'
                    variant='outlined'
                    label="Email"
                    placeholder='Filtrar por email'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />}
            </>
            :
            <>
                <SelectFormField
                    label='Filtrar por'
                    name='optionFilter'
                    options={[
                        { label: 'Nome', value: 'searchString' },
                    ]}
                />
                <TextFormField
                    name='searchString'
                    label='Nome'
                    variant='outlined'
                    placeholder='Filtrar por nome'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </>
        }
    </>
}

export const UsersAndGroupFilters = ({ currentValues, removeFilterSelected, typeFilter }: UsersAndGroupFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<UsersAndGroupFiltersForm typeFilter={typeFilter} />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    )
}
