import { DataMapping, toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { ConsortiumCreateModel } from './consortiumModel';
import { FactoringCreateModel } from './factoringModel';
import { HomeEquityCreateModel } from './homeEquityModel';
import { InvoiceFactoringCreateModel } from './invoiceFactoringModel';
import { JudiciaryProcessCreateModel } from './judiciaryProcessModel';
import { PublicPayrollCreateModel } from './publicPayrollModel';
import { VehicleCreateModel } from './vehicleModel';
import { currentDateWithSetHours, toIsoStringWithTimezone } from 'helpers';

export type WarrantyType =
    | 'Warranty' // Garantia
    | 'Vehicle' // Veículo
    | 'HomeEquity' // Imóvel
    | 'JudiciaryProcess' // Processo Judicial
    | 'Factoring' // Antecipação de recebíveis
    | 'PublicPayroll' // Consignado Público
    | 'InvoiceFactoring' // Desconto de duplicatas
    | 'Consortium'; // Consórcio

export type WarrantyTypes =
    | ConsortiumCreateModel
    | FactoringCreateModel
    | HomeEquityCreateModel
    | InvoiceFactoringCreateModel
    | JudiciaryProcessCreateModel
    | PublicPayrollCreateModel
    | VehicleCreateModel;

const warrantyTypeMapping: Record<string, DataMapping<WarrantyTypes>> = {
    consortium: ConsortiumCreateModel,
    factoring: FactoringCreateModel,
    homeequity: HomeEquityCreateModel,
    invoicefactoring: InvoiceFactoringCreateModel,
    judiciaryprocess: JudiciaryProcessCreateModel,
    publicpayroll: PublicPayrollCreateModel,
    vehicle: VehicleCreateModel,
};

export function warrantyToDataModel<T extends WarrantyTypes>(data: T): T {
    const warrantyType = data?.warrantyType?.toLowerCase();
    const WarrantClass = warrantyTypeMapping[warrantyType!];

    return toDataModel(data as Record<keyof WarrantyTypes, unknown>, WarrantClass) as T;
}

export class DefaultWarranty {
    warrantyType: string;
    dueDate: string;
    emissionDate: string;
    previousAdeNumber: string | null;
    warrantyDeadline: string;
    warrantyOption: string;

    constructor() {
        this.warrantyType = 'Warranty';
        this.dueDate = new Date().toISOString();
        this.emissionDate = new Date().toISOString();
        this.previousAdeNumber = null;
        this.warrantyOption = 'FreeMargin';
        this.warrantyDeadline = toIsoStringWithTimezone(new Date(currentDateWithSetHours));
    }
}
