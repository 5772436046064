import { useQuery } from "@tanstack/react-query";
import { useApiRequest, GetViewApiResponse } from "./apiRequestContext";
import { useIdentity } from "./identityContext";
import { isAxiosError } from "axios";
import { ApprovalsProps, getApprovals } from "services/approvals/approvals";
import { useFilterValues } from "./filterValuesContext";

export function useApprovalsBankAccount(params: ApprovalsProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues } = useFilterValues();
    const filtersComplete = {
        ...params,
        approvalResource: filterValues.filters?.typeOfPendingApprovals ?? "BankAccountUser",
    } as ApprovalsProps; 
    const { data, error, status, refetch, isFetching, ...rest } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['pending-approvals-data', filtersComplete, filterValues],
        queryFn: async (): Promise<any> => {
            startRequest();
            const resp = await getApprovals(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponse<any>
        }
    });

    return {
        pendingApprovalsData: data,
        pendingApprovalsError: error,
        pendingApprovalsStatus: status,
        refetch,
        isFetching, 
        ...rest
    };

};
export function useApprovals(filters: ApprovalsProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues } = useFilterValues();
    const listDataFilters = { ...filterValues.filters };
    const filtersComplete = { ...filters, ...listDataFilters };

    const { data, error, status, refetch, isFetching, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['pending-approvals-data', filtersComplete],
        queryFn: async (): Promise<any> => {
            startRequest();
            const resp = await getApprovals(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponse<any>
        }
    });
    
    return {
        pendingApprovalsData: data,
        pendingApprovalsError: error,
        pendingApprovalsStatus: status,
        refetch,
        isFetching, 
        isLoading: !Object.values(filterValues.filters).length && isLoading
    };

};