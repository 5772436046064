import moment from "moment";
import { object, date } from "yup";

export function validationCodeCopyAndPasteDetailsSchema() {
    return object().shape({
        transferDate: date().typeError('Data inválida.').required('Data: Precisa ser preenchido.'),
    });
}
export const defaultValuesCodeCopyAndPasteDetails = {
    transferDate: parseInt(moment().format('HH')) < 17 ? moment().format('yyyy-MM-DD') : moment().add(1, 'day').format('yyyy-MM-DD'),
};