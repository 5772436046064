import {
    SelectProps as MuiSelectProps
} from '@mui/material';
import { SelectField } from '@uy3/web-components';

type SelectFieldComponentPros = {
    items: string[]; 
    label: string; 
    onChange: (values: any, child: any) => void
} & MuiSelectProps

export const SelectFieldComponent = (props: SelectFieldComponentPros) => {
  return (
    <SelectField {...props} />
  )
}
