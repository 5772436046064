import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React from 'react';
import { communicationSettingSchema, defaultValuesCommunicationSettingFilter } from './CommunicationSettingFilterSchema';
import { FieldValues } from 'react-hook-form';
import { CommunicationSettingFilters } from './CommunicationSettingFilters';

export const CommunicationSettingFiltersContainer: React.FC = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        addFilter(optionFilter, values[optionFilter]);
    };

    return (
        <FormProvider
            defaultValues={defaultValuesCommunicationSettingFilter}
            validationSchema={communicationSettingSchema()}
            onSubmit={handleOnSubmit}
        >
            <CommunicationSettingFilters
                currentValues={filters}
                removeFilterSelected={removeFilterByIndex}
            />
        </FormProvider>
    );
};
