import { Grid, Stack } from '@mui/material'
import { Button, CardData, CloseIcon, Typography } from '@uy3/web-components'
import { useTenant } from 'contexts/tenantContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { formatWitTenant } from 'helpers/formats/StringFormats';
import moment from 'moment';
import { activeTheme } from 'services/theme';

type BankAccountOperatorDetailsProps = {
  detailsData: any;
  onClose: () => void;
}

const theme = activeTheme();

export const BankAccountOperatorDetails = ({ detailsData, onClose }: BankAccountOperatorDetailsProps) => {
  const { isRootTenancy } = useTenant();

  const listData = [
    {
      id: '1',
      title: 'Nível',
      value: `${detailsData?.levelDisplay ?? 'N/D'}`
    },
    {
      id: '2',
      title: 'Documento',
      value: `${formatDocumentNumber(detailsData?.registrationNumber) ?? 'N/D'}`
    },
    {
      id: '2',
      title: 'Criado por',
      value: `${formatWitTenant(detailsData?.createdBy, isRootTenancy) ?? 'N/D'}`
    },
    {
      id: '3',
      title: 'Data da criação',
      value: `${new Date(detailsData?.createdAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  ]

  if (!!detailsData?.updatedBy) listData.push(
    {
      id: '4',
      title: 'Atualizado por',
      value: `${formatWitTenant(detailsData?.updatedBy, isRootTenancy) ?? 'N/D'}`
    },
    {
      id: '5',
      title: 'Data da atualização',
      value: `${new Date(detailsData?.updatedAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  );

  const approvals = detailsData?.approvals ?? [];

  return (
    <Stack>
      <Typography variant='lg' mb={2}>Informações</Typography>
      <CardData
        listItem={listData}
      />

      {approvals.length > 0 && <>
        <Typography variant='lg' fontWeight="700" mb={2}>Fluxo de aprovação</Typography>

        {approvals.map((item: any) => {
          return (
            <Grid>
              <CardData
                listItem={[
                  {
                    id: '1',
                    title: 'Resposta',
                    value: `${item?.actionDisplay === 'Aprovar' ? 'Aprovado': 'Reprovado'}`,
                  },
                  {
                    id: '2',
                    title: 'Operador',
                    value: ` ${formatWitTenant(item?.user, isRootTenancy)}`,
                  },
                  {
                    id: '3',
                    title: 'Data da resposta',
                    value: `${moment(item?.createdAt).format('DD/MM/YYYY')}`,
                  },
                ]}
              />
            </Grid>
          );
        })}
      </>}
      <Stack direction='row' alignItems='center' justifyContent='right'>
        <Button variant='outlined' onClick={onClose} startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}>Fechar</Button>
      </Stack>
    </Stack>
  )
}
