/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { getLiquidationScheduleInfo } from '../ApproveLiquidationScheduleSchema';
import { useFieldArray } from 'react-hook-form';
import { useEffect } from 'react';
import { TliquidationScheduleOpt } from 'services/LiquidationSchedule';
import { FooterText } from './actionsText';
import { EAction } from 'services/LiquidationSchedule/types/enums';
import { TGetCosif } from 'contexts/creditProductContext';

type LiquidationScheduleFormProps = {
    liquidationScheduleList: TliquidationScheduleOpt[];
    cosifList: TGetCosif[];
    index: number;
    showCosifAccount: boolean;
    hasCosifAccountOfTransitory: boolean;
    hasTransitoryAccount: boolean;
};

function LiquidationScheduleForm({
    liquidationScheduleList,
    cosifList,
    index = 0,
    showCosifAccount,
    hasCosifAccountOfTransitory,
    hasTransitoryAccount
}: LiquidationScheduleFormProps) {
    const { watch, setValue } = useFormContext();
    const action: keyof typeof EAction = watch(`liquidationSchedule.${index}.action`);
    const isSelectedDisapprove = action === 'Failed';
    const liquidationScheduleId = watch(`liquidationSchedule.${index}.liquidationScheduleId`);
    const { description, label: tooltipText } = getLiquidationScheduleInfo(liquidationScheduleList, liquidationScheduleId);
    const footerText = <FooterText action={action} />;
    const prefixCosif = `liquidationSchedule.${index}.cosifAccountCode`;
    const cosifCodeWatch = watch(prefixCosif);
    const hasExternalId: boolean = !!cosifList.find((i) => i.value === cosifCodeWatch)?.externalAccountId;
    const cosifAccountOptions = cosifList as SelectOption[];
    const hasCosifAccountCode = !!cosifList.find((i) => i.value === watch('cosifAccountCode'))?.externalAccountId
    const showAutomaticLiquidationOption = hasExternalId || hasCosifAccountOfTransitory || hasTransitoryAccount || hasCosifAccountCode;

    useEffect(() => {
        if (showCosifAccount) {
            setValue(prefixCosif, null);
        }
    }, [showCosifAccount]);

    const actionOptions = [
        {
            label: 'Manter pendente',
            value: 'NoAction',
        },
        {
            label: 'Marcar como liquidado',
            value: 'ManualLiquidation',
        },
        {
            label: 'Reprovar',
            value: 'Failed',
        },
        showAutomaticLiquidationOption
            ? {
                  label: 'Liquidar automaticamente',
                  value: 'AutomaticLiquidation',
              }
            : undefined,
    ].filter((i) => i !== undefined) as SelectOption[];

    return (
        <CustomAccordionContainer {...{ index, description, tooltipText, footerText }}>
            <Stack direction="column" spacing={2}>
                {showCosifAccount && (
                    <SelectFormField
                        required
                        name={`liquidationSchedule.${index}.cosifAccountCode`}
                        label="Conta de débito"
                        options={cosifAccountOptions}
                    />
                )}
                <SelectFormField
                    required
                    name={`liquidationSchedule.${index}.action`}
                    label="Ação"
                    options={actionOptions}
                />
                {isSelectedDisapprove && (
                    <TextFormField
                        required
                        fullWidth
                        label="Observações"
                        name={`liquidationSchedule.${index}.failureMessage`}
                        variant="outlined"
                        margin="dense"
                        multiline
                        rows={3}
                    />
                )}
            </Stack>
        </CustomAccordionContainer>
    );
}

type ApproveFormProps = {
    liquidationScheduleList: TliquidationScheduleOpt[];
    cosifList: TGetCosif[];
    showCosifAccount: boolean;
    hasCosifAccountOfTransitory: boolean;
    hasTransitoryAccount: boolean;
};

function ApproveLiquidationScheduleForm({
    liquidationScheduleList,
    cosifList,
    showCosifAccount,
    hasCosifAccountOfTransitory,
    hasTransitoryAccount
}: ApproveFormProps) {
    const { control } = useFormContext();
    const { fields, append } = useFieldArray({
        control,
        name: 'liquidationSchedule',
    });

    const appendField = () => {
        const appendFields = liquidationScheduleList.map((item) => ({
            liquidationScheduleId: item.value,
            action: 'NoAction',
        }));

        append(appendFields);
    };

    useEffect(() => {
        if (fields.length === 0 && liquidationScheduleList.length > 0) {
            appendField();
        }
    }, [fields, liquidationScheduleList]);

    return (
        <>
            {fields.map((field, index) => (
                <LiquidationScheduleForm
                    key={field.id}
                    {...{
                        index,
                        hasCosifAccountOfTransitory,
                        liquidationScheduleList,
                        cosifList,
                        showCosifAccount,
                        hasTransitoryAccount
                    }}
                />
            ))}
        </>
    );
}

export default ApproveLiquidationScheduleForm;
