import DynamicAccordion from 'components/Datasets/Accordion/Accordion';
import {
    RelatedPeoplePhonesList,
    AddressesExtended,
    PesquisaProtesto,
    PGFNList,
    PhonesList,
    JudicialProcesses,
    KycAndCompliance,
    Charging,
    EmailReputation,
    FinanceData,
    OnlineNegotiation,
    PersonalBasicData,
    PfAntecedenteList,
    ProfessionalData,
    PublicJobsList,
    SocialBenefitList,
    WebMentions,
    ConsumerRiskScore,
    FraudRecords,
} from 'components/Datasets';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import InfoMessage from 'components/InfoMessage/InfoMessage';

type DatasetNaturalPersonContainerProps = {
    personId?: string | undefined;
};

const DatasetNaturalPersonContainer: React.FC<DatasetNaturalPersonContainerProps> = ({
    personId,
}) => {
    const { data: permissionsData } = useUserPermissionData();
    const resource = 'NaturalPerson';

    const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, resource);
    const disableRequestDataSet = !checkActionsPerms('RequestDataSet', permissionsData, resource);

    const accordion = [
        {
            title: 'Consultar Fraude',
            content: hasPermissionToReadDataset ? (
                <FraudRecords
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Consulta de CPF',
            content: hasPermissionToReadDataset ? (
                <PersonalBasicData
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Consulta de Email',
            content: hasPermissionToReadDataset ? (
                <EmailReputation
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Score de Risco',
            content: hasPermissionToReadDataset ? (
                <ConsumerRiskScore
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Endereços',
            content: hasPermissionToReadDataset ? (
                <AddressesExtended
                    recordType={resource}
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Atividade Empresarial e Profissional',
            content: hasPermissionToReadDataset ? (
                <ProfessionalData
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Menções na web',
            content: hasPermissionToReadDataset ? (
                <WebMentions datasetId={personId} disableRequestDataSet={disableRequestDataSet} />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Informações Financeiras',
            content: hasPermissionToReadDataset ? (
                <FinanceData datasetId={personId} disableRequestDataSet={disableRequestDataSet} />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Presença online',
            content: hasPermissionToReadDataset ? (
                <OnlineNegotiation
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Presença em cobrança',
            content: hasPermissionToReadDataset ? (
                <Charging datasetId={personId} disableRequestDataSet={disableRequestDataSet} />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Programas de benefícios e Assistência Social',
            content: hasPermissionToReadDataset ? (
                <SocialBenefitList
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Serviços públicos',
            content: hasPermissionToReadDataset ? (
                <PublicJobsList
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Certidão Antecedentes Criminais - Polícia Federal',
            content: hasPermissionToReadDataset ? (
                <PfAntecedenteList
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Protestos',
            content: hasPermissionToReadDataset ? (
                <PesquisaProtesto
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'KYC e Compliance',
            content: hasPermissionToReadDataset ? (
                <KycAndCompliance
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Processos Judiciais e Administrativos',
            content: hasPermissionToReadDataset ? (
                <JudicialProcesses
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Procuradoria Geral da Fazenda Nacional',
            content: hasPermissionToReadDataset ? (
                <PGFNList
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Telefones',
            content: hasPermissionToReadDataset ? (
                <PhonesList
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Telefones de relacionados',
            content: hasPermissionToReadDataset ? (
                <RelatedPeoplePhonesList
                    recordType={resource}
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
    ];

    return <DynamicAccordion accordion={accordion} />;
};

export { DatasetNaturalPersonContainer };
