/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { useFormContext } from 'contexts/formContext';
import { useFieldArray } from 'react-hook-form';
import { CommissionFormCostsAccordionMetadataKey } from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { useEffect } from 'react';
import { Alert } from '@uy3/web-components';
import { BillingsListCreateForm } from './BillingsListCreateForm';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { isValidString } from 'helpers/formats/StringFormats';
import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import moment from 'moment';
import { IBillingToInstallment } from 'services/walletManagement/installment/installment.types';
import { activeTheme } from 'services/theme';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { ToastType } from 'contexts/apiRequestContext';

type BillingInstallmentCreateFormType = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const theme = activeTheme();
export const BillingInstallmentCreateForm = ({ setToast }: BillingInstallmentCreateFormType) => {
    const { control, setValue, watch, validationErrors } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name: 'billings' });
    const numberInstallment = Number(watch('numberInstallment'));
    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);
    const hasErrorValidations: boolean = (validationErrors?.['billings'] as unknown as any[])?.length >= 1;

    const [
        documentNumber,
        period,
        dueDate,
        amount
    ] = watch(['documentNumber', 'period', 'dueDate', "amount"]);

    const billlingsValues = (watch('billings') as IBillingToInstallment[]) ?? [];

    const appendDueDate = (date: string, index: number) => {
        switch (String(watch('period')).toLowerCase()) {
            case "month":
                return moment(date).add(index, 'month').format('YYYY-MM-DDTHH:mm:ssZ');
            case "fifteendays":
                return moment(date).add(15 * index, 'days').format('YYYY-MM-DDTHH:mm:ssZ');
            default:
                return date;
        }
    };

    useEffect(() => {
        const currentValue = Number(watch('numberInstallment'));
        const defaultValuesInArray = Array.from({ length: currentValue }, () => (
            {
                dueDate: null,
                documentNumber,
                amount
            }
        )) as unknown as Partial<IBillingToInstallment>[];

        const formFields = defaultValuesInArray.map((x, index) => {
            const dueDateFormated = index === 0 ? dueDate : appendDueDate(toIsoStringWithTimezone(new Date(dueDate)), index)
            return {
                ...x,
                dueDate: dueDateFormated,
                documentNumber: `${x.documentNumber}-${index + 1}`,
                amount: parseInt((Number(x?.amount) / numberInstallment)?.toString())
            }
        });

        remove();
        append([...formFields]);
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length + currentValue);
        setValue('isCustomInstallment', invalidValueBasedPrincipal);
    }, [numberInstallment, documentNumber, period, dueDate, amount]);

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
        setValue('numberInstallment', billlingsValues?.length - 1)
    };

    const optionsPeriod = [
        { label: `Mensal`, value: "month" },
        { label: `Quinzenal`, value: "fifTeenDays" }
    ];

    const billingValueBase: number = parseInt(String(Number(amount) / numberInstallment));
    const isInstallmentsCustom: boolean = billlingsValues?.some(item => item.amount !== billingValueBase);

    const sumInstallmentsCustom = billlingsValues
        .map(x => x.amount)
        .reduce((acc, current) => acc + current, 0);

    const invalidValueBasedPrincipal = () => {
        if (isInstallmentsCustom) {
            return sumInstallmentsCustom !== amount;
        }
        return false;
    }

    return (
        <Stack spacing={3} mt={1} mb={3}>
            <CustomTitle title="Valor e parcela da cobrança" variant="lg" />

            <Grid>
                <CurrencyFormField
                    variant='outlined'
                    label="R$"
                    name='amount'
                    required
                    fullWidth
                />
            </Grid>

            <Grid xs={3}>
                <TextFormField
                    label="Em quantas vezes deseja parcelar a cobrança?"
                    name="numberInstallment"
                    variant="outlined"
                />
            </Grid>

            <CustomTitle title="Configurações de parcelas" variant="lg" />

            <Grid xs={3}>
                <TextFormField
                    label="Número do documento"
                    name="documentNumber"
                    variant="outlined"
                />
            </Grid>

            <Grid xs={3}>
                <SelectFormField
                    label="Período de pagamento"
                    name="period"
                    variant="outlined"
                    required
                    fullWidth
                    options={optionsPeriod}
                />
            </Grid>
            <Grid xs={3}>
                <DatePickerFormField
                    label="Data de vencimento da 1º parcela"
                    name="dueDate"
                    required
                    disablePast
                    fullWidth
                />
            </Grid>

            {
                isValidString(String(numberInstallment)) &&
                isValidString(String(documentNumber)) &&
                isValidString(String(period)) &&
                isValidString(String(amount)) &&
                isValidString(dueDate) &&
                fields.length > 0 &&
                <Stack spacing={2}>
                    <CustomTitle title="Parcelas" variant="lg" />
                    <BillingsListCreateForm fields={fields} remove={removeComm} />
                </Stack>}

            {hasErrorValidations &&
                <Alert severity='warning' text='Certifique-se de que os campos do formulário estão preenchidos.' />}


            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Grid>
                    <CustomTitle variant="lg" title='Valor total da cobrança' />
                </Grid>
                <Stack direction='column' justifyContent='end'>
                    <CustomTitle
                        variant="xl"
                        sx={{ textAlign: "right" }}
                        color={theme.palette.primary.main}
                        title={formatCurrencyInCents(amount)}
                    />

                    <CustomTitle
                        variant="sm"
                        sx={{ fontSize: "13px", textAlign: "right" }}
                        color={theme.palette.primary.main}
                        title={isInstallmentsCustom ? "Parcelas personalizadas" : `em ${numberInstallment}x de ${formatCurrencyInCents(billingValueBase)}`}
                    />
                </Stack>
            </Stack>

            {invalidValueBasedPrincipal() && (
                <Alert
                    severity='info'
                    title='Atenção'
                    text={`A soma total das parcelas modificadas (${formatCurrencyInCents(sumInstallmentsCustom)}) é diferente do valor do parcelamento informado (${formatCurrencyInCents(amount)}).`}
                />
            )}
        </Stack >
    );
};
