import { Stack } from '@mui/material';
import AddAccountEntryForm from './AddAccountEntryForm';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';

type AddAccountEntryContainerProps = {
    onClose: () => void;
};

const theme = activeTheme();
export function AddAccountEntryContainer({ onClose }: AddAccountEntryContainerProps) {
    return (
        <Stack spacing={5}>
            <AddAccountEntryForm />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    size="medium"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
}
