import { string, object, boolean } from 'yup';
import { } from 'helpers/validations/formFieldValidator';

export function validationSchemaBeneficiaryForm() {
    return object().shape({
        name: string().typeError('Nome: Precisa ser preenchido.').required('Nome: Precisa ser preenchido.'),
        registrationNumber: string()
            .typeError('CPF: Precisa ser preenchido.')
            .required("CPF: Precisa ser preenchido.")
            .cpfCnpjValidation("CPF ou CNPJ inválido."),
        bank: string().typeError("Banco inválido.").required('Banco: Precisa ser preenchido.'),
        agency:
            string()
                .typeError('Agência inválida.')
                .required('Agência: Precisa ser preenchido.')
                .max(4, "Agência: Precisa ter 4 digitos.")
                .min(4, "Agência: Precisa ter 4 digitos."),
        accountNumber: string().typeError('Conta: Precisa ser preenchido.').required('Conta: Precisa ser preenchido.'),
        accountType: string().typeError('Tipo de conta: Precisa ser preenchido.').required('Tipo de conta: Precisa ser preenchido.'),
        operationTypeValue: string().typeError('Tipo de operação: Precisa ser preenchido.').required('Tipo de operação: Precisa ser preenchido.'),
        saveBeneficiary: boolean()
    });
}

export const defaultValuesBeneficiaryForm = {
    saveBeneficiary: false,
    name: "",
    registrationNumber: "",
    bank: "",
    agency: "",
    accountNumber: "",
    accountType: "",
    operationTypeValue: "Transfer",
};