import React from 'react';
import { FilterProvider } from 'contexts/filterContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import ParcelsBatchAssignmentList from './ParcelsBatchAssignmentList';

interface CreditNoteListWithFiltersProps {
    rowsPerPage: number;
    page: number;
    onChangeRowsPerPage: (page: number) => void;
    onChangePage: (page: number) => void;
    isLoading: boolean;
    paymentScheduleData: any;
}

const ParcelsBatchListWithFilters: React.FC<CreditNoteListWithFiltersProps> = ({
    rowsPerPage,
    page,
    onChangeRowsPerPage,
    onChangePage,
    paymentScheduleData,
    isLoading,
}) => {
    return (
        <FilterProvider
            availableFilters={{
                searchString: { label: 'Todos', type: 'text', multiple: false },
                initialDate: {
                    label: 'Data de vencimento',
                    type: 'dateRange',
                    multiple: false,
                    dateRangeName: 'initialDate',
                },
                maxPaymentValue: { label: 'Valor da Parcela', type: 'text', multiple: false },
                minPaymentValue: { label: 'Valor da Parcela (Mínimo)', type: 'text', multiple: false },
                maxAssignmentValue: { label: 'Valor da Cessão', type: 'text', multiple: false },
                minAssignmentValue: { label: 'Valor da Cessão (Mínimo)', type: 'text', multiple: false },
                // principalAmountInCents: { label: 'Saldo devedor', type: 'text', multiple: false },
                creditNoteNo: {
                    label: 'Número da operação',
                    type: 'text',
                    multiple: false,
                },
            }}
        >
            <ListSkeleton isLoading={isLoading}>
                <ParcelsBatchAssignmentList
                    queryData={{
                        data: paymentScheduleData?.data,
                        totalItems: paymentScheduleData?.totalItems,
                        page: page,
                        paginationToken: '',
                        totalPages: paymentScheduleData?.totalPages / rowsPerPage,
                        type: 'success',
                    }}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    setPage={onChangePage}
                    setRowsPerPage={onChangeRowsPerPage}
                />
            </ListSkeleton>
        </FilterProvider>
    );
};

export default ParcelsBatchListWithFilters;
