import { Drawer } from '@uy3/web-components';
import { useEnumContext } from 'contexts/enumContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useState } from 'react';
import { RequiredDocumentsList } from './RequiredDocumentsList';
import { RequiredDocumentsForm } from './RequiredDocumentsForm';
import {
    validationSchemaRequiredDocumentsForm,
    defaultValuesRequiredDocumentsForm,
    RequiredDocumentsFormSchema,
} from './RequiredDocumentsSchema';
import { ToastType, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { RequiredDocumentHeader } from './RequiredDocumentHeader';

export const RequiredDocumentsTabContainer = () => {
    const [selectedRequiredDoc, setSelectedRequiredDoc] = useState<number | undefined>(undefined);
    const { setValue, watch } = useFormContext();
    const [toast, setToast] = useState<ToastType>(toastState);
    const requiredDoc = watch('requiredDoc') ?? [];

    const { data: typeOfDocument } = useEnumContext({ enumName: 'FileType', size: 100 });

    const validationDocument = validationSchemaRequiredDocumentsForm();
    const defaultValuesDocument = defaultValuesRequiredDocumentsForm;

    const closeDrawer = () => setSelectedRequiredDoc(undefined);

    const setNewRequiredDoc = (values: RequiredDocumentsFormSchema) => {
        let newRequiredDoc = [...requiredDoc];
        newRequiredDoc[selectedRequiredDoc!] = values;
        setValue('requiredDoc', newRequiredDoc);
        closeDrawer();
        const title = 'Documentos atualizado com sucesso.';
        const desc = undefined;
        showSuccessToast(title, desc, setToast);
    };

    const onDelete = (index: number) => {
        let newDocuments = [...requiredDoc];
        newDocuments.splice(index, 1);
        setValue('requiredDoc', newDocuments);
    };

    const openDrawer = (indexNumber: number | undefined) => setSelectedRequiredDoc(indexNumber ?? requiredDoc?.length)

    const defaultFormValue = requiredDoc[selectedRequiredDoc!] ?? defaultValuesDocument;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <RequiredDocumentHeader
                handleCreate={openDrawer}
            />
            <Drawer
                anchor="right"
                title="Adicionar documento"
                open={selectedRequiredDoc !== undefined}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={validationDocument}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewRequiredDoc}
                >
                    <RequiredDocumentsForm
                        typeOfDocument={typeOfDocument ?? []}
                        onClose={closeDrawer}
                    />
                </FormProvider>
            </Drawer>

            <RequiredDocumentsList
                typeOfDocument={typeOfDocument ?? []}
                openDrawer={openDrawer}
                name={'requiredDoc'}
                onDelete={onDelete}
            />
        </>
    );
};
