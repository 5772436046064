import { Grid, Stack } from '@mui/material';
import { CheckboxFormField, DatePickerFormField, SelectFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useCreditNoteAutocomplete } from 'contexts/creditNote/creditContext';
import { useFormContext } from 'contexts/formContext';
import React from 'react';

type GenerateRemessaLiquidacaoOpFormProps = {
    showCheckbox?: boolean
}

export const GenerateRemessaLiquidacaoOpForm: React.FC<GenerateRemessaLiquidacaoOpFormProps> = ({ showCheckbox = true }) => {
    const { creditNoteAtucomplete } = useCreditNoteAutocomplete();
    const { watch } = useFormContext();
    return (
        <Stack gap={2} mt={2}>
            <Grid container spacing={2} columns={6}>
                <Grid item xs={3}>
                    <AutocompleteField
                        name="opId"
                        label="Operação"
                        displayName="opIdDisplay"
                        link={`/ccb/operacoes/${watch('opId')}`}
                        showEndIcon={watch('opId')}
                        endIconType="link"
                        {...creditNoteAtucomplete!}
                    />
                </Grid>
                <Grid item xs={3}>
                    <DatePickerFormField
                        label='Data de liquidação'
                        fullWidth
                        name='date'
                    />
                </Grid>
                <Grid item xs={3}>
                    <SelectFormField
                        name='liquidateByvalue'
                        label='Liquidar por'
                        options={[
                            { label: "Valor presente", value: "AssignmentValue" },
                            { label: "Valor futuro", value: "FutureValue" }
                        ]}
                    />
                </Grid>
            </Grid>
            {showCheckbox &&
                <Grid >
                    <CheckboxFormField label='Incluir vencidos' name='incluirVencidos' />
                    <CheckboxFormField label='Baixar parcelas' name='baixarParcelas' />
                </Grid>}
        </Stack>
    );
}