import { Stack } from '@mui/material';
import React, { useState } from 'react';
import DrawerManageCategoryContainer from './AddCategoriesDrawer/DrawerManageCategoryContainer';
import CreateChargesFormWrapper from './CreateChargesForm/CreateChargesFormWrapper';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';

interface CreateChargesFormProps { }

const CreateChargesContainer: React.FC<CreateChargesFormProps> = () => {
    const [toast, setToast] = useState<ToastType>(toastState);

    const [addCategories, setAddCategories] = useState(false);

    const onClose = () => setAddCategories(false);

    return (
        <Stack spacing={4}>
            <Toast toast={toast} setToast={setToast} />
            <CreateChargesFormWrapper {...{ setAddCategories }} />
            <DrawerManageCategoryContainer {...{ addCategories, onClose, setToast }} />
        </Stack>
    );
};

export default CreateChargesContainer;
