/* eslint-disable react-hooks/exhaustive-deps */
import {
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    tableCellClasses
} from '@mui/material';
import { useFormContext } from 'contexts/formContext';
import moment from 'moment';
import { PaymentScheduleItemReadModel  } from 'services/creditNote/types/creditNoteReadModel';
import { formatCurrencyInCents, formatMultiply } from 'helpers/formats/Currency';
import HeaderOperation from './Header';
import { useState } from 'react';
import Input from 'components/Forms/InputManualCalculation';
import { StyledTableInfo, tableCellStyle, tableHeaderCellStyle } from './tableCell.styled';
import CurrencyInput from 'components/Forms/FormFields/CurrencyInput';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { useApiRequest } from 'contexts/apiRequestContext';
import './index.css';
import ButtonManualCalculation from './ButtonsManualCalculation';
import { useLocation } from 'react-router';
import GetLabelForField from 'components/GetLabelForField';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { Button, Popup } from '@uy3/web-components';

interface IOperationDetailsProps {
    removedFromPrintList?: string[];
    currentPaymentScheduleItems: PaymentScheduleItemReadModel [];
    isNewCreditOp?: boolean;
};

type IndexerProps = {
    hasPlus: boolean;
    isIndexerValue?: boolean;
};

enum ExibitionMode {
    ShowAPIValues = 0,
    ShowLastSimulation = 1,
    ShowEditedValues = 2
}

const OperationDetails: React.FC<IOperationDetailsProps> = ({
    removedFromPrintList,
    currentPaymentScheduleItems,
    isNewCreditOp
}) => {
    const { product } = useCreditNoteFormContext();
    const { getValues, setValue, readOnly, watch } = useFormContext();
    const [isEditingPaymentSchedule, setIsEditingPaymentSchedule] = useState(false);
    const [paymentScheduleItemsUnderEdit, setPaymentScheduleItemsUnderEdit] = useState<PaymentScheduleItemReadModel [] | undefined>(undefined);
    const [rowIndex, setRowIndex] = useState(-1);
    const [columnIndex, setColumnIndex] = useState(-1);
    const [newValue, setNewValue] = useState(0);
    const { submitError } = useApiRequest();
    const [msgError, setMsgError] = useState<string | undefined>(undefined);
    const location = useLocation();
    const isMenuSimular = location.pathname.includes('simular');
    const lastSimulation = watch("createSimulation");
    const hasValuePaymentFixedCosts = (!!product && product?.paymentFixedCosts > 0);
    const showAddedFixedCostsColumn: boolean = getValues('amortization.includePaymentFixedCosts') === true && hasValuePaymentFixedCosts;
    const [openPopup, setOpenPopup] = useState(false);
    const [fieldIndex, setFieldIndex] = useState<number>(0);
    const [customizedPaymentPlan, setCustomizedPaymentPlan] = useState(isEditingPaymentSchedule);

    const headers = {
        installment: 'Parcela',
        dueDateTable: 'Vencimento',
        principalAmountInCents: 'Saldo Devedor',
        amortization: 'Amortização',
        interest: 'Juros',
        ...(customizedPaymentPlan
            ? {
                  IOF: 'IOF'
              }
            : {}),
        payment: 'Pagamento',
        ...(showAddedFixedCostsColumn && !isEditingPaymentSchedule && !customizedPaymentPlan
            ? {
                  addedFixedCosts: GetLabelForField('addedFixedCosts', 'Tarifas'),
              }
            : {})
    };

    const keysToShow = Object.keys(headers) as (keyof typeof headers)[];

    const getValuesIndexer = ({ hasPlus, isIndexerValue }: IndexerProps) => {
        const isIndexer = getValues('amortization.indexerValue');
        const withoutIndexer = 'Sem indexador pós-fixado';
        if (isIndexer) {
            const indx = getValues('amortization.indexer') ?? withoutIndexer;
            return `${hasPlus ? '+ ' : ''}${isIndexerValue ? isIndexer * 100 : isIndexer}% ${indx}`;
        } else if (hasPlus) {
            return '';
        }
        return withoutIndexer;
    };

    const formatDate = (date: string): string => {
        return moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
    };

    const updatePaymentScheduleItemDueDate = (index: number, newDueDate: string) => {
        const updatedItems = [...paymentScheduleItemsUnderEdit!];
        // change due date of the current payment
        updatedItems[index].dueDate = newDueDate;
        setPaymentScheduleItemsUnderEdit(updatedItems);
    };

    const validateDueDate = (index: number, newDueDate: string): boolean => {
        if (index > 0 && newDueDate <= paymentScheduleItemsUnderEdit![index - 1].dueDate) {
            setMsgError('A data da parcela deve ser maior do que a data da parcela anterior');
            return false;
        }
        return true;
    };

    const handleDueDateChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newDueDate = formatDate(e.target.value);
        updatePaymentScheduleItemDueDate(index, newDueDate);
    };

    const handleDueDateBlur = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newDueDate = formatDate(e.target.value);
        const isValidDate = moment(newDueDate).isValid();
        setFieldIndex(index);

        if ((index === 0 || index === 1) && isValidDate) {
            setOpenPopup(true);
        }

        if (validateDueDate(index, newDueDate)) {
            updatePaymentScheduleItemDueDate(index, newDueDate);
        }
    };

    const handleChange = <K extends keyof PaymentScheduleItemReadModel >(fieldKey: K, value: number, index: number) => {
        const newValue = value as PaymentScheduleItemReadModel [K];
        let editedPaymentScheduleItems = [...paymentScheduleItemsUnderEdit!];
        editedPaymentScheduleItems[index] = { ...editedPaymentScheduleItems[index] };
        editedPaymentScheduleItems[index][fieldKey] = newValue;
        setPaymentScheduleItemsUnderEdit(editedPaymentScheduleItems);
    };

    const handleClickedCell = (index: number, colIndex: number) => {
        setRowIndex(index);
        setColumnIndex(colIndex);
    };

    const checkClassName = (key: string): string => !removedFromPrintList?.includes(key) ? "print-visible-table" : "print-hidden";

    const exibitionMode =
        paymentScheduleItemsUnderEdit && paymentScheduleItemsUnderEdit.length > 0
            ? ExibitionMode.ShowEditedValues
            : lastSimulation?.paymentScheduleItems &&
              lastSimulation?.paymentScheduleItems?.length > 0
            ? ExibitionMode.ShowLastSimulation
            : ExibitionMode.ShowAPIValues;

    const paymentScheduleItemsToShow: PaymentScheduleItemReadModel [] =
        exibitionMode === ExibitionMode.ShowAPIValues
            ? currentPaymentScheduleItems
            : exibitionMode === ExibitionMode.ShowLastSimulation
            ? lastSimulation?.paymentScheduleItems
            : paymentScheduleItemsUnderEdit;

    const valueTotal = paymentScheduleItemsToShow?.length && paymentScheduleItemsToShow?.map((x) => x.payment).reduce((a, b) => a + b);

    if (exibitionMode === ExibitionMode.ShowAPIValues) {
        setValue('paymentScheduleItems', null);
    } else if (exibitionMode === ExibitionMode.ShowLastSimulation) {
        setValue('paymentScheduleItems', null);
    } else {
        setValue('customPaymentPlan', true);
        setValue('paymentScheduleItems', paymentScheduleItemsUnderEdit);
    }

    const columnWhenIsNotEditting = ['principalAmountInCents', 'amortization', 'interest', "payment"];
    const columnCustomPayment = ['principalAmountInCents', 'amortization', 'interest', "financeTax", "payment"]

    if (showAddedFixedCostsColumn && !isEditingPaymentSchedule) {
        columnWhenIsNotEditting.push('addedFixedCosts');
    }

    const handleOnClose = () => {
        setOpenPopup(false);
    };

    const applyDueDateToAll = () => {
        const editedPaymentScheduleItems = [...paymentScheduleItemsUnderEdit!];

        for (let i = fieldIndex + 1; i < editedPaymentScheduleItems.length; i++) {
            const previousDueDate = editedPaymentScheduleItems[i - 1].dueDate;
            const newDueDateForSubsequent = moment(previousDueDate).add(1, 'day').format('YYYY-MM-DDTHH:mm:ssZ');

            // change due date of the subsequent payments
            if (moment(newDueDateForSubsequent).isSameOrBefore(previousDueDate, 'month')) {
                editedPaymentScheduleItems[i].dueDate = moment(previousDueDate).add(1, 'month').format('YYYY-MM-DDTHH:mm:ssZ');
            } else {
                editedPaymentScheduleItems[i].dueDate = newDueDateForSubsequent;
            }
        };

        setOpenPopup(false);
        setPaymentScheduleItemsUnderEdit(editedPaymentScheduleItems);
    };

    return (
        <>
            <Popup
                open={openPopup}
                onClose={handleOnClose}
                title="Alterar data de vencimento"
                text="Deseja aplicar esta data de pagamento para as demais parcelas?"
            >
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOnClose}
                        size="medium"
                        sx={{ mt: 1, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={applyDueDateToAll}
                        size="medium"
                        sx={{ mt: 1 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </Popup>
            <ErrorBoundary fallback="dados da simulação." error={submitError?.message}>
                <StyledTableInfo>
                    {!isNewCreditOp && !isMenuSimular && (
                        <HeaderOperation {...{ rowIndex, setRowIndex, paymentScheduleItemsUnderEdit, msgError, setMsgError }} />
                    )}
                    {!readOnly && !isMenuSimular && (
                        <ButtonManualCalculation
                            {...{
                                rowIndex,
                                setRowIndex,
                                currentPaymentScheduleItems: paymentScheduleItemsUnderEdit,
                                setCurrentPaymentScheduleItems: setPaymentScheduleItemsUnderEdit,
                                msgError,
                                setMsgError,
                                isEditing: isEditingPaymentSchedule,
                                setIsEditing: setIsEditingPaymentSchedule,
                                setCustomizedPaymentPlan
                            }}
                        />
                    )}
                    {paymentScheduleItemsToShow &&
                        <Grid container alignItems="center" justifyContent="center" my={5}>
                            <TableContainer component={Paper}>
                                <Table
                                    size="small"
                                    aria-label="a dense table"
                                    sx={{ [`& .${tableCellClasses.root}`]: { borderBottom: 'none' } }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            {keysToShow.map((key) => (
                                                <TableCell
                                                    className={checkClassName(key)}
                                                    key={key}
                                                    align="center"
                                                    sx={tableHeaderCellStyle}
                                                >
                                                    {headers[key]}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {paymentScheduleItemsToShow?.map((row, index) => (
                                            <TableRow key={index} sx={{ backgroundColor: rowIndex === index ? 'rgba(186, 191, 208, 0.16)' : '#FFFFFF' }} >
                                                <TableCell align="center" sx={tableCellStyle} className={checkClassName('installment')}>
                                                    {index}
                                                </TableCell>

                                                <TableCell
                                                    className={checkClassName('dueDateTable')}
                                                    align="center"
                                                    sx={tableCellStyle}
                                                    onClick={() => handleClickedCell(index, 0)}
                                                >
                                                    {rowIndex === index && columnIndex === 0 && isEditingPaymentSchedule ? (
                                                        <Input
                                                            name="dueDate"
                                                            type="date"
                                                            defaultValue={moment(row.dueDate).format('YYYY-MM-DD')}
                                                            onChange={(e) => handleDueDateChange(e, index)}
                                                            onBlur={(e) => handleDueDateBlur(e, index)}
                                                        />
                                                    ) : moment(row.dueDate).format('DD/MM/YYYY')}
                                                </TableCell>

                                                {(customizedPaymentPlan ?
                                                    columnCustomPayment :
                                                    columnWhenIsNotEditting
                                                ).map((key, colIndex) => {
                                                    const isPaymentKey = key === 'payment';
                                                    const currentRowKey = row[key as keyof PaymentScheduleItemReadModel ];
                                                    const numericCurrentRowKey = Number(currentRowKey);
                                                    const isFirstItem = index === 0;
                                                    const isPrincipalAmountInCents = key === 'principalAmountInCents' && isFirstItem;
                                                    const isAmortization = key === 'amortization' && isFirstItem;
                                                    const isInterest = key === 'interest' && isFirstItem;
                                                    const isPayment = key === 'payment' && isFirstItem;
                                                    return (
                                                        <TableCell
                                                            key={key}
                                                            className={checkClassName(key)}
                                                            align="center"
                                                            sx={tableCellStyle}
                                                            onClick={() => handleClickedCell(index, colIndex + 1)}
                                                        >
                                                            {rowIndex === index && columnIndex === colIndex + 1 && isEditingPaymentSchedule ? (
                                                                <CurrencyInput
                                                                    name={key}
                                                                    onBlur={() => {
                                                                        const valueFormatted = formatMultiply(newValue, 'currency') || 0;
                                                                        handleChange(key as keyof PaymentScheduleItemReadModel , valueFormatted, index);
                                                                        if (isPrincipalAmountInCents) setValue('amortization.requestedAmount', valueFormatted)
                                                                    }}
                                                                    onChange={(e) => {
                                                                        const value = Number(e.target.value);
                                                                        setNewValue(value);
                                                                    }}
                                                                    value={numericCurrentRowKey / 100}
                                                                    disabled={isAmortization || isInterest || isPayment}
                                                                />
                                                            ) : isPaymentKey ? currentRowKey !== 0
                                                                ? `${formatCurrencyInCents(row.payment)} ${getValuesIndexer({ hasPlus: true, isIndexerValue: true })}`
                                                                : formatCurrencyInCents(currentRowKey)
                                                                : isPaymentKey && row.payment !== 0
                                                                    ? `${formatCurrencyInCents(row.payment)} ${getValuesIndexer({ hasPlus: true, isIndexerValue: true })}`
                                                                    : formatCurrencyInCents(numericCurrentRowKey)
                                                            }
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        ))}

                                        <TableRow>
                                            <TableCell
                                                className={checkClassName('valueTotal')}
                                                colSpan={isEditingPaymentSchedule ? 7 : showAddedFixedCostsColumn ? 10 : 6}
                                                align="right"
                                                sx={{
                                                    ...tableHeaderCellStyle,
                                                    pr: showAddedFixedCostsColumn ? 4 : 10,
                                                }}
                                            >
                                                {`Total: ${formatCurrencyInCents(valueTotal)}`}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>}
                </StyledTableInfo>
            </ErrorBoundary>
        </>
    );
};
export default OperationDetails;
