import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react'

interface IReadNotificationsSkeletonProps {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const ReadNotificationsSkeleton: React.FC<IReadNotificationsSkeletonProps> = ({
    children,
    isLoading
}) => {
    if (!isLoading) return children;

    return (
        <Stack spacing={2} padding='15px'>
            <Stack spacing={2} pt={3}>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={60}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={30}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            </Stack>

            <Stack spacing={2} pt={4}>
                {[1, 2, 3, 4].map(item => {
                    return (
                        <Stack>
                            <Skeleton
                                variant='rectangular'
                                animation='pulse'
                                width='100%'
                                height={115}
                                sx={{
                                    borderRadius: '8px'
                                }}
                            />
                        </Stack>
                    )
                })}
            </Stack>
        </Stack>
    )
}
