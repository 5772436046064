import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React from 'react';
import { FieldValues } from 'react-hook-form';
import { chatbotFiltersSchema, defaultValuesChatbotSchema } from './ChatbotFiltersSchema';
import { ChatbotFilters } from './ChatbotFilters';

export const ChatbotFiltersContainer: React.FC = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        addFilter(optionFilter, values[optionFilter]);
    };

    return (
        <FormProvider
            defaultValues={defaultValuesChatbotSchema}
            validationSchema={chatbotFiltersSchema()}
            onSubmit={handleOnSubmit}
        >
            <ChatbotFilters
                currentValues={filters}
                removeFilterSelected={removeFilterByIndex}
            />
        </FormProvider>
    );
};
