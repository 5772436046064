import { object, boolean, string } from 'yup';

const requiredMessage: string = "Precisa ser preenchido"; 

export function validationUpdateWalletFormSchema() {
    return object().shape({
        billingTypeValue: string().typeError(`Tipo de cobrança: ${requiredMessage}`).required(`Tipo de cobrança: ${requiredMessage}`),        
        enableAutoSubmitToDebtCollection: boolean().nullable()     
    });
};

export const defaultValuesUpdateWalletForm = {
    billingTypeValue: null,
    enableAutoSubmitToDebtCollection: null
};
