import { billingAxiosApi } from "services/axiosApi/axiosApi";

const api = `/ZipCode`

export const getZipCodeByBillingApi = async (zipCode: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.post(`${api}/${zipCode}`, {}, config);
}
