import { object, string, StringSchema } from 'yup';

type PixKeyType = 'NaturalRegistrationNumber' | 'LegalRegistrationNumber' | 'Email' | 'Phone' | 'Automatic';
type PixKeyTypeValidation = Record<PixKeyType, {
    errorMessage: string;
    requiredMessage: string;
    validation: (schema: StringSchema) => StringSchema;
}>;

export const createBeneficiarySchema = () => {

    let PixKeyType: PixKeyType;
    const pixKeyTypeValidationMap: PixKeyTypeValidation = {
        NaturalRegistrationNumber: {
            errorMessage: 'CPF inválido.',
            requiredMessage: 'CPF: Precisa ser preenchido.',
            validation: (schema) => schema.cpfCnpjValidation('CPF inválido.'),
        },
        LegalRegistrationNumber: {
            errorMessage: 'CNPJ inválido.',
            requiredMessage: 'CNPJ: Precisa ser preenchido.',
            validation: (schema) => schema.cpfCnpjValidation('CNPJ inválido.'),
        },
        Email: {
            errorMessage: 'E-mail inválido.',
            requiredMessage: 'Email: Precisa ser preenchido.',
            validation: (schema) => schema.email('E-mail inválido.'),
        },
        Phone: {
            errorMessage: 'Telefone inválido.',
            requiredMessage: 'Telefone: Precisa ser preenchido.',
            validation: (schema) => schema.phoneNumberValidation('Número de telefone deve conter 11 caracteres ').typeError('Telefone inválido.'),
        },
        Automatic: {
            errorMessage: 'Chave Pix: Precisa ser preenchido.',
            requiredMessage: 'Chave Pix: Precisa ser preenchido.',
            validation: (schema) => schema.typeError('Chave Pix: Precisa ser preenchido.'),
        },
    };

    const isOperationType = (operationTypeValue: string, pixKeyTypeValue: string) =>
        operationTypeValue === 'Transfer' || (operationTypeValue === 'Pix' && pixKeyTypeValue === 'AgencyAndAccount');

    return object().shape({
        name: string()
            .required('Nome do favorecido: Precisa ser preenchido.')
            .typeError('Nome do favorecido: Precisa ser preenchido.'),
        operationTypeValue: string()
            .typeError('Selecione o tipo de operação.')
            .required('Tipo de operação: obrigatório.'),
        bank: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) => isOperationType(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Banco: Precisa ser preenchido.')
                .typeError('Banco: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        registrationNumber: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) => isOperationType(operationTypeValue, pixKeyTypeValue),
            then: string()
                .typeError('CPF/CNPJ inválido.')
                .required('CPF/CNPJ: Precisa ser preenchido.')
                .cpfCnpjValidation('CPF ou CNPJ inválido.'),
            otherwise: string().nullable(),
        }),
        accountType: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) => isOperationType(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Tipo de conta inválida.')
                .typeError('Tipo de conta: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        agency: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) => isOperationType(operationTypeValue, pixKeyTypeValue),
            then: string()
                .required('Agência : Precisa ser preenchido.')
                .typeError('Valor precisa ser um número.')
                .max(4, 'Agência (sem digito): Precisa ter 4 digitos.')
                .min(4, 'Agência (sem digito): Precisa ter 4 digitos.'),
            otherwise: string().nullable(),
        }),
        accountNumber: string().when(['operationTypeValue', 'pixKeyTypeValue'], {
            is: (operationTypeValue: string, pixKeyTypeValue: string) => isOperationType(operationTypeValue, pixKeyTypeValue),
            then: string()
                .typeError('Valor precisa ser um número.')
                .required('Conta: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        pixKeyTypeValue: string().when('operationTypeValue', {
            is: 'Pix',
            then: string()
                .typeError('Tipo de chave inválida.')
                .required('Tipo de chave: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        }),
        keyPix: string().when('pixKeyTypeValue', {
            is: (pixKeyTypeValue: PixKeyType) => {
                PixKeyType = pixKeyTypeValue;
                return pixKeyTypeValidationMap[pixKeyTypeValue];
            },
            then: () => {
                const { validation, errorMessage, requiredMessage } = pixKeyTypeValidationMap[PixKeyType] || pixKeyTypeValidationMap.Automatic;
                return validation(string()).typeError(errorMessage).required(requiredMessage)
            },
            otherwise: string().nullable(),
        })
    });
};

export const defaultValuesBeneficiary = {
    name: null,
    agency: null,
    accountNumber: null,
    accountType: null,
    registrationNumber: null,
    bank: null,
    operationTypeValue: null,
    pixKeyTypeValue: null,
    keyPix: null
};