import { Stack } from '@mui/material';
import { Button } from '@uy3/web-components';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import React from 'react';

type ImportSignatoriesProps = {
  personsToImportList: SelectOption[];
  onClose: () => void
  onCancel?: () => void;
}

export const ImportSignatories = ({ personsToImportList, onCancel, onClose }: ImportSignatoriesProps) => {
  return (
    <Stack spacing={4} mt={2}>
      <SelectFormField
        label='Signatários'
        name='personsToImport'
        required
        multiple
        options={personsToImportList}
      />
      <Stack direction='row' alignItems='center' spacing={2} mt={1}>
        <Button variant='outlined' sx={{ width: '50%' }} onClick={() => {
          onCancel && onCancel();
          onClose()
        }}>
          Cancelar
        </Button>
        <Button variant='contained' sx={{ width: '50%' }} type='submit'>
          Importar
        </Button>
      </Stack>
    </Stack>
  )
}

