import { Grid } from '@mui/material';
import { SelectFormField, SelectFormFieldProps } from 'components/Forms/FormFields';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { ReactNode } from 'react';


type SelectFormFieldWithTooltipProps = {
    selectFormField: SelectFormFieldProps;
    helpMessage: ReactNode;
}

export const SelectFormFieldWithTooltip = ({ selectFormField, helpMessage }: SelectFormFieldWithTooltipProps) => {
    return (
        <TooltipComponent
            placement='left'
            title={helpMessage}
        >
            <Grid>
                <SelectFormField {...selectFormField} />
            </Grid>
        </TooltipComponent>
    )
}