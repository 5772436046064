import { Stack } from '@mui/material'
import { Typography } from '@uy3/web-components'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { validationInformation } from 'helpers/formats/StringFormats'
import React, { ReactNode } from 'react'
import { FieldValues } from 'react-hook-form'
import { activeTheme } from 'services/theme'

type InfoBankAccountRequestProps = {
    formValues: FieldValues
}


const theme = activeTheme();

export const SpanCustom = ({ children }: { children: ReactNode }) => {
    const sx = { color: theme.palette.primary.main };
    return <span style={sx}>{children}</span>
}
 

interface IChildren {
    children: ReactNode
}

export const InfoBankAccountRequest: React.FC<InfoBankAccountRequestProps> = ({ formValues }) => {
    const ListItem = ({ children }: IChildren) => {
        return <Typography style={{ margin: '5px 0 ', fontSize: '14px' }}>{children}</Typography>
    }
    return (
        <Stack sx={{margin: '-5px 0'}}>
            <Stack style={{ display: 'block', fontSize: '13px' }}>
                <ListItem>
                    <SpanCustom>Titular: </SpanCustom>
                    {validationInformation(formValues?.ownerDisplay)}
                </ListItem>
                <ListItem>
                    <SpanCustom>CPF/CNPJ: </SpanCustom>
                    {validationInformation(formValues?.ownerRegistrationNumber) !== "N/D" ? formatDocumentNumber(formValues?.ownerRegistrationNumber) : "N/D"}
                </ListItem>
                <ListItem>
                    <SpanCustom>Tipo de conta: </SpanCustom>
                    {validationInformation(formValues?.typeDisplay)}
                </ListItem>
                <ListItem>
                    <SpanCustom>Correspondente: </SpanCustom>
                    {validationInformation(formValues?.tenantDisplay)}
                </ListItem>
            </Stack>
        </Stack>
    )
}
