import { Grid, Stack } from '@mui/material'
import { Button, CancelIcon, CompleteIcon } from '@uy3/web-components'
import { CheckboxFormField } from 'components/Forms/FormFields'
import { iconSx } from 'contexts/apiRequestContext'
import { activeTheme } from 'services/theme'

type CancelFormProps = {
    isLoading: boolean;
    onClose: () => void;
}

const theme = activeTheme();

export const CancelForm = ({ isLoading, onClose }: CancelFormProps) => {
    return (
        <Stack spacing={2}>
            <Grid>
                <CheckboxFormField name='keepBillingOpen' label='Manter carteira aberta' />
            </Grid>

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main}/>}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant='contained'
                    disabled={isLoading}
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white}/>}
                    type='submit'
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
