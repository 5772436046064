import { Grid, Box, Stack } from '@mui/material';
import { Typography, SaveIcon, Alert, PasswordIcon, UpdateIcon, ClearCircleIcon, CheckCircleIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType } from 'contexts/apiRequestContext';
import { Actions } from 'components/Actions/Actions';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';

type UserFormProps = {
    userId: string | undefined;
    setStepAction: (step: string) => void;
    isFetching: boolean;
    refetch: () => void;
    setToast: (toast: ToastType) => void;
    isDisabled: boolean;
    hasPermissionCreateOrUpdate: boolean
};

const theme = activeTheme();
export const UserForm = ({
    userId,
    setStepAction,
    refetch,
    setToast,
    isDisabled, 
    hasPermissionCreateOrUpdate
}: UserFormProps) => {
    const { validationErrors, isDirty, watch } = useFormContext();
    const { data: permissionsData } = useUserPermissionData();
    const hasActionPermission = (action: string) => checkActionsPerms(action, permissionsData, 'User')

    const isNew = userId === 'novo';
    const colorBlack = theme.palette.common.black;
    const tabs = [{ label: 'Informações', href: userId }];

    if (!isNew) {
        tabs.push(
            { label: 'Grupos', href: 'grupos' },
            { label: 'Permissões', href: 'permissoes' }
        );
    }

    const refetchWithToast = () => {
        refetch();
        setToast({
            title: 'Os dados do operador foram atualizados!',
            severity: 'success',
            open: true
        });
    }

    return (
        <>
            {validationErrors && (
                <Box mt={2} mb={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar o operador: Corrija os erros do formulário e envie novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box>

                <NotSavedChangesAlert isDirty={isDirty} />
                <Stack direction='row' justifyContent='space-between' mb={3} alignItems='center'>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        {isNew ? 'Novo Operador' : `Operador ${watch('name')}`}
                    </Typography>
                    <Grid>
                        <Actions
                            numberOfButtons={3}
                            buttonsActionsList={[
                                {
                                    enable: hasPermissionCreateOrUpdate,
                                    type: 'submit',
                                    label: "Salvar", icon: <SaveIcon htmlColor={colorBlack} />
                                },
                                {
                                    action: refetchWithToast,
                                    enable: !isNew,
                                    label: "Atualizar",
                                    icon: <UpdateIcon htmlColor={colorBlack} />
                                },
                                {
                                    action: () => setStepAction('restPassword'),
                                    enable: !isNew && hasPermissionCreateOrUpdate,
                                    label: "Resetar senha",
                                    icon: <PasswordIcon htmlColor={colorBlack} />
                                },
                                {
                                    action: () => setStepAction('disableUser'),
                                    enable: !isNew && !isDisabled && hasActionPermission('DisableUser'),
                                    label: "Desabilitar usuário",
                                    icon: <ClearCircleIcon htmlColor={colorBlack} />
                                },
                                {
                                    action: () => setStepAction('enableUser'),
                                    enable: !isNew && isDisabled && hasActionPermission('EnableUser'),
                                    label: "Habilitar usuário",
                                    icon: <CheckCircleIcon htmlColor={colorBlack} />
                                }
                            ]}
                        />
                    </Grid>
                </Stack>
                <Grid sx={{ mr: 3 }}>
                    <TabBar tabs={tabs} variant={!isNew ? 'fullWidth' : 'scrollable'} />
                </Grid>
            </Box>
        </>
    );
};
