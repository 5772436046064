import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Card = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 8px 32px rgba(186, 191, 208, 0.32)',
  borderRadius: '8px',
  padding: '24px',
  maxWidth: '100%'
}))

Card.defaultProps = {
  alignItems: 'center',
  flexDirection: 'column',
}

export const BoxItems = styled(Stack)(({ theme }) => ({
  margin: '40px 0 24px 0',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    margin: '30px 0 14px 0',
  }
}))

BoxItems.defaultProps = {
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export const ListItem = styled(Stack)(({ theme }) => ({
  marginBottom: '16px',
  width: '100%',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}))

ListItem.defaultProps = {
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}
