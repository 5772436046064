import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const MaximizeIcon: FunctionComponent<SvgIconProps> = (props) => {
    const { htmlColor = '#373737' } = props;

    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M21 8.99999L21 3M21 3L15 3M21 3L10 14"
                    stroke={htmlColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12 5H7C4.79086 5 3 6.79086 3 9V17C3 19.2091 4.79086 21 7 21H15C17.2091 21 19 19.2091 19 17V12"
                    stroke={htmlColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

MaximizeIcon.defaultProps = {
    viewBox: '0 0 24 25',
    width: '24',
    height: '25',
    fill: 'none',
};
