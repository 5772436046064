import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import {
    signatureAddSignersAsync,
    signatureCancelAsync,
    signatureCreateAsync,
    signatureGetAsync,
    signatureResendSignatureNotificationAsync,
    signatureStartAsync,
    signatureUpdateAsync,
} from 'services/signature/signature';
import {
    AddSignatures,
    SignatureWorkFlowCreateType,
    SignatureWorkFlowFull,
} from 'services/signature/signature.types';

export function useGetSignatureAsync(signatureWorkflowId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && !!signatureWorkflowId,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['get-signature-workflow-signrs', signatureWorkflowId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await signatureGetAsync(
                signatureWorkflowId,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
    });

    return {
        signatureStatus: status,
        signatureData: data as SignatureWorkFlowFull,
        signatureErr: error as ApiResponseError,
        signatureFetch: isFetching,
        isLoading,
        refetch,
    };
}

export function useSignatureCreateAsync(
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: SignatureWorkFlowCreateType) => {
            startRequest();
            const { data, status, statusText } = await signatureCreateAsync(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateCreateSignatureAsync: mutateAsync, isLoading, error };
}

export function useSignatureUpdateAsync(
    signatureWorkFlowId: string,
    onSuccess?: () => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: SignatureWorkFlowCreateType) => {
            startRequest();
            const { data, status, statusText } = await signatureUpdateAsync(
                signatureWorkFlowId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess();
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateUpdateSignatureAsync: mutateAsync, isLoading, error };
}

export function useSignatureStartAsync(
    signatureWorkFlowId: string,
    onSuccess?: () => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (filePath: string) => {
            startRequest();
            const { data, status, statusText } = await signatureStartAsync(
                signatureWorkFlowId,
                filePath,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess();
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateStartSignatureAsync: mutateAsync, isLoading, error };
}

export function useSignatureResendSignersAsync(
    signatureWorkFlowId: string,
    onSuccess?: () => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async () => {
            startRequest();
            const { data, status, statusText } = await signatureResendSignatureNotificationAsync(
                signatureWorkFlowId,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess();
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateResendSignatureNotification: mutateAsync, isLoading, error };
}

export function useAddSignatureMutation(
    signatureWorkFlowId: string,
    onSuccess: (data: AddSignatures) => void,
    onError: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: AddSignatures) => {
            startRequest();
            const { data, status, statusText } = await signatureAddSignersAsync(
                signatureWorkFlowId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as AddSignatures;
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
}



export function useSignatureCancelAsync(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (signatureWorkflowId: string) => {
            startRequest();
            const { data, status, statusText } = await signatureCancelAsync(signatureWorkflowId, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error };
}