import { FormProvider } from 'contexts/formContext';
import { EditOperatorForm } from '../EditOperatorForm/EditOperatorForm';
import {
    defaultValuesEditOperator,
    validationEditOperatorSchema,
} from '../EditOperatorForm/EditOperatorFormSchema';
import { FieldValues } from 'react-hook-form';
import { ApiResponseError, ToastType, showSuccessToast, useApiRequest } from 'contexts/apiRequestContext';
import { postBankAccountRequestOperatorProps, postBankAccountOperator } from 'services/accounts/bankAccount';
import { useIdentity } from 'contexts/identityContext';

interface EditOperatorDrawerFormProps {
    onClose: () => void;
    isFetching: boolean;
    handleResponseError: (response: ApiResponseError) => void;
    operatorListRefetch: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    accountNumber: any;
    userData: any;
}

export default function EditOperatorDrawerForm({
    onClose,
    isFetching,
    handleResponseError,
    operatorListRefetch,
    setToast,
    accountNumber,
    userData
}: EditOperatorDrawerFormProps) {
    const { startRequest, endRequest } = useApiRequest();
    const { token } = useIdentity();

    const onEditOperator = (formValues: FieldValues) => {
        startRequest();
        const data = {
            level: formValues?.level,
            tenant: userData?.user?.tenant,
            userId: userData?.user?.userId,
        } as postBankAccountRequestOperatorProps;

        postBankAccountOperator(accountNumber, data, token!)
            .then(() => {
                operatorListRefetch();
                endRequest(true);
                onClose();
                const title = 'Operador da conta editado com sucesso!.';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    return (
        <FormProvider
            validationSchema={validationEditOperatorSchema()}
            defaultValues={defaultValuesEditOperator}
            onSubmit={onEditOperator}
        >
            <EditOperatorForm onClose={onClose} isFetching={isFetching} currentLevel={userData?.levelDisplay} />
        </FormProvider>
    );
}
