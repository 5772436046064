import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { DataTable } from '@uy3/web-components'
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import moment from 'moment';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type CreditNotesProps = {
    queryData: GetListApiResponseSuccess<CreditNoteReadModel>
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    page: number;
    rowsSelected: string[] | GridRowId[] | undefined;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;

}

export const CreditNotes = ({ queryData, page, rowsPerPage, setPage, setRowsPerPage, rowsSelected, handleSelectionModelChange}: CreditNotesProps) => {
    const { isParentTenant } = useTenant();

    const columnsCreditList = [
        isParentTenant
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                width: 141,
                align: 'left',
                editable: false,
                renderCell: (cellValues: any) => cellValues.value,
            }
            : undefined,
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            minWidth: 200,
            flex: 2,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => `#${cellValues.value}`,
        },
        {
            field: 'productCategoryDisplay',
            headerName: 'Categoria do produto',
            hideSortIcons: true,
            minWidth: 200,
            flex: 2,
            editable: false,
            align: 'left',
        },
        {
            field: 'amortization.principalAmountInCents',
            headerName: 'Valor principal',
            hideSortIcons: true,
            minWidth: 200,
            flex: 2,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                formatCurrencyInCents(cellValues?.row?.amortization?.principalAmountInCents),
        },
        {
            field: 'amortization.liquidValue',
            headerName: 'Valor líquido',
            hideSortIcons: true,
            width: 130,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues?.row?.amortization?.liquidValue),
        },
        {
            field: 'amortization.startDate',
            headerName: 'Data de início',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) =>
                moment(cellValues.row?.amortization?.startDate).format('DD/MM/YY')
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 200,
            flex: 2,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => {
                const statusDisplay = cellValues.row?.statusDisplay;
                return statusDisplay === 'Liquidação' ? "Pago" : statusDisplay;
            }
        },
        {
            field: 'amortization.apr',
            headerName: 'Taxa',
            hideSortIcons: true,
            width: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => formatPercentage(cellValues?.row?.amortization?.apr),
        },
        {
            field: 'amortization.termInMonths',
            headerName: 'Prazo',
            hideSortIcons: true,
            width: 80,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => Math.max(cellValues?.row?.amortization?.termInMonths, cellValues?.row?.amortization?.numberOfPayments)
        },
        {
            field: 'createdAt',
            headerName: 'Data de criação',
            hideSortIcons: true,
            width: 125,
            editable: false,
            align: 'left',
            renderCell: (cellValues: any) => moment(cellValues.value).format('DD/MM/YY HH:mm')
        },
    ].filter(Boolean);

    return (
        <DataTable
            getRowId={(row) => row.id}
            columns={columnsCreditList as GridColDef[]}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            enableJumpAction={false}
            checkboxSelection
            keepNonExistentRowsSelected
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleSelectionModelChange}
        />
    )
}
