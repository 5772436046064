import { PermissionFilter } from "services/permissions"

export class RouteHandle {
	displayName?: string = ""
	icon?: JSX.Element = <></>
	showNav?: boolean = true
	showBreadcrumb?: boolean = true
	permission?: PermissionFilter | PermissionFilter[] | undefined = undefined
	isRootTenancy?: boolean = false
	childrenCanBeHidden?: boolean
}
export const defaultHandler = new RouteHandle();
export const createHandle = (obj: RouteHandle): RouteHandle => {
	return {
		...defaultHandler,
		...obj
	}
}

export const hiddenHandle = createHandle({
	showNav: false,
	showBreadcrumb: false,
	permission: undefined,
	childrenCanBeHidden: false
});