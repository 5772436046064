import { Stack } from '@mui/material'
import { Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'

type FileConfirmationHeaderProps = {
    refresh: () => void;
}

export default function FileConfirmationHeader({ refresh }: FileConfirmationHeaderProps) {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Arquivos retorno
            </Typography>

            <Stack>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refresh,
                            disabled: false,
                            icon: <UpdateIcon sx={iconSx} />
                        }
                    ]}
                />
            </Stack>
        </Stack>
    )
}
