import DatasetWrapper from '../DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { EmailReputationGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
interface IEmailReputation {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function EmailReputation({ datasetId, disableRequestDataSet }: IEmailReputation) {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'EmailReputation');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta da Presença em cobrança${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };
    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'email_reputation'
    ) as EmailReputationGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle title={'Score'} value={result?.eaScore ?? 'N/D'} key="eaScore" />
                    <HandleTitle
                        title={'Nível'}
                        value={result?.fraudRisk ?? 'N/D'}
                        key="fraudRisk"
                    />
                    <HandleTitle
                        title={'Descritivo'}
                        value={result?.eaAdvice ?? 'N/D'}
                        key="eaAdvice"
                    />
                    <HandleTitle title={'Razão'} value={result?.eaReason ?? 'N/D'} key="eaReason" />
                    <HandleTitle
                        title={'Faixa'}
                        value={result?.eaRiskBand ?? 'N/D'}
                        key="eaRiskBand"
                    />
                    <HandleTitle
                        title={'Existe'}
                        value={result?.emailExists ?? 'N/D'}
                        key="emailExists"
                    />
                    <HandleTitle
                        title={'Indústria'}
                        value={result?.sourceIndustry ?? 'N/D'}
                        key="sourceIndustry"
                    />
                    <HandleTitle
                        title={'Tipo de fraude'}
                        value={result?.fraudType ?? 'N/D'}
                        key="fraudType"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('EmailReputation')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};