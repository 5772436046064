import { Stack } from '@mui/material'
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField'
import React from 'react'
import { ClonePermissionContainerProps } from './ClonePermissionContainer'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'
import { activeTheme } from 'services/theme'
import { iconSx, useApiRequest } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { useTenant } from 'contexts/tenantContext'

type ClonePermissionProps = {
    listUsersAutocomplete: AutocompleteOptionList;
    listGroupsAutocomplete: AutocompleteOptionList;
    tenantAutoComplete: AutocompleteOptionList;
} & Pick<ClonePermissionContainerProps, 'recordType' | 'onClose'>;

const theme = activeTheme();

export const ClonePermission: React.FC<ClonePermissionProps> = ({ recordType, listGroupsAutocomplete, listUsersAutocomplete, onClose, tenantAutoComplete }) => {
    const { isParentTenant } = useTenant();
    const { submitting: submittingForm } = useFormContext()
    const isUserForm = recordType === 'User';
    const { submitting } = useApiRequest();

    const autocompleteList = isUserForm ? listUsersAutocomplete : listGroupsAutocomplete;

    return (
        <Stack spacing={4}>
            {isParentTenant && (
                <AutocompleteField
                    label="Correspondente"
                    name="tenant"
                    displayName="tenantDisplay"
                    {...tenantAutoComplete}
                />
            )}

            <AutocompleteField
                name='resourceId'
                displayName="resourceIdDisplay"
                required
                label={isUserForm ? "Operador" : "Grupo"}
                {...autocompleteList}
            />

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting || submittingForm}
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    )
}
