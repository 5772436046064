import styled from '@emotion/styled'
import { ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Stack } from '@mui/material'
import { CardData, GroupedButtons } from '@uy3/web-components'
import { formatCurrencyInCents } from 'helpers/formats/Currency'
import { useState } from 'react'
import { FieldValues } from 'react-hook-form'

type ReadCurrentLimitProps = {
    rowData: FieldValues
}

const AccordionStyle = styled(Accordion)(({
    fontSize: '14px', 
    border: '1px solid #BABFD0', 
    borderRadius: '8px', 
    boxShadow: 'none', 
    cursor:  'pointer'
}))

export const ReadCurrentLimit = ({ rowData }: ReadCurrentLimitProps) => {
    const [hoursSelected, setHoursSelected] = useState<"businessHours" | "nonBusinessHours">("businessHours");
    const bankAccountEffectiveLimits = rowData?.bankAccountEffectiveLimits;

    const values = {
        pixLimit: bankAccountEffectiveLimits?.pixLimit[hoursSelected],
        tedLimit: bankAccountEffectiveLimits?.tedLimit[hoursSelected],
        transferLimit: bankAccountEffectiveLimits?.transferLimit[hoursSelected],
        bankSlipLimit: bankAccountEffectiveLimits?.bankSlipLimit[hoursSelected]
    }

    const trasformDataFromArray = Object.entries(values);

    const handleSelectHours = (i: number) => {
        const value = i === 0 ? 'businessHours' : 'nonBusinessHours'
        setHoursSelected(value);
    }

    const mappedTitleAccordion: { [type: string]: string } = {
        "tedLimit": 'TED',
        "transferLimit": 'TRANSFERÊNCIA INTERNA',
        "pixLimit": 'Pix',
        "bankSlipLimit": 'BOLETO'
    }

    const showCurrentValues = (value:number) => {
        return value !== null ? formatCurrencyInCents(value) : "Limite padrão da instituição"; 
    } 

    return (
        <Stack spacing={2}>
            <GroupedButtons
                size='large'
                width={240}
                groupedButtons={["Horário comercial", 'Fora do horário']}
                onClick={handleSelectHours}
            />

            {trasformDataFromArray.map((acc, i) => {
                const record = acc[0];
                const values = acc[1];
                return (
                    <AccordionStyle key={i} defaultExpanded={i === 0}>
                        <AccordionSummary
                            expandIcon={<ExpandMore sx={{ fontSize: 20 }} />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{ fontSize: '16px' }}
                        >
                            {mappedTitleAccordion[record]}
                        </AccordionSummary>
                        <AccordionDetails>
                            <CardData
                                listItem={[
                                    { id: "1", title: "Mesma titularidade", value: showCurrentValues(values?.valueInCentsOwnOwnership)}, 
                                    { id: "2", title: "Transferência para PF", value: showCurrentValues(values?.valueInCentsNaturalPerson)}, 
                                    { id: "3", title: "Transferência para PJ", value: showCurrentValues(values?.valueInCentsLegalPerson)}
                                ]}
                            />
                        </AccordionDetails>
                    </AccordionStyle>
                )
            })}
        </Stack>
    )
}
