import { Stack } from '@mui/material';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';

export default function AddAccountEntryForm() {
    return (
        <Stack spacing={3}>
            <CurrencyFormField
                label="Valor solicitado"
                fullWidth
                name="amount"
                variant="outlined"
                required
            />
            <TextFormField
                name="documentNumber"
                fullWidth
                label="Documento"
                variant="outlined"
                required
            />
            <TextFormField
                name="complement"
                fullWidth
                label="Complemento"
                variant="outlined"
                required
            />
            <SelectFormField
                name="entryType"
                variant="outlined"
                label="Tipo de lançamento"
                fullWidth
                required
                options={[
                    { label: 'Pagamento de Boleto', value: 'BilletPayment' },
                    { label: 'Débito de Tarifa de TED', value: 'TransferFeeDebit' },
                ]}
            />
        </Stack>
    );
}
