import { FormProvider } from 'contexts/formContext';
import React from 'react'
import { defaultValuesTransferSchema, transferValidationSchema } from './TransferSchema';
import { IInstallmentFull, ITransferInstallment } from 'services/walletManagement/installment/installment.types';
import { TransferForm } from './TransferForm';

type TransferFormContainerProps = {
    rowData: IInstallmentFull;
    onSubmit: (values: ITransferInstallment) => void;
    onClose: () => void
    isLoading: boolean
}
export const TransferFormContainer = ({ rowData, onSubmit, onClose, isLoading }: TransferFormContainerProps) => {
    return (
        <FormProvider {...{
            defaultValues: defaultValuesTransferSchema,
            validationSchema: transferValidationSchema(rowData!),
            onSubmit
        }}>
            <TransferForm {...{onClose, isLoading }} />
        </FormProvider>
    )
}
