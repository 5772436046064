import React from 'react'
import InformationBatchAssignment from './InformationBatchAssignment'
import { useTenant } from 'contexts/tenantContext';
import { useFundsList } from 'contexts/fundContext';

export const InformationBatchAssignmentContainer = () => {
    const { currentTenantId } = useTenant();
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10, tenant: currentTenantId! }, 'always');

    return (
        <>
            <InformationBatchAssignment
                fundAutocomplete={fundAutoCompleteProps}
            />
        </>
    )
}