import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { TenantFiltersProps, TenantListReadModel } from "./types";

let url = `/Tenant`;
export async function getTenantList(filters: TenantFiltersProps, token: string) {
    const { searchString, ...rest } = filters;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            searchString: searchString ? searchString : undefined,
            ...rest,
        },
    };

    return await creditAxiosApi.get<TenantListReadModel>(url, config);
}
