import { FC } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Typography } from '@uy3/web-components';
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import { css } from "@emotion/react";
import ErrorBoundary from "components/Errors/ErrorBoundary";
import { useApiRequest } from "contexts/apiRequestContext";

const ContentWrapper = styled(AccordionDetails)(
    ({ theme }) => css`
    display: flex;
    flex-direction: column;
    padding: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(2)};
  `
);

interface IAccordion {
    accordion: {
        title: string;
        content: JSX.Element;
    }[];
    id?: string;
};

const DynamicAccordion: FC<IAccordion> = ({ accordion, id }) => {
    const { submitError } = useApiRequest();

    return (
        <ErrorBoundary fallback="dataset" typeMessage="textMessage">
            {accordion.map((accordion, index) => (
                <Accordion key={index} id={id}>
                    <AccordionSummary expandIcon={<ExpandMore />}>
                        <Typography variant="h5">{accordion.title}</Typography>
                    </AccordionSummary>
                    <ContentWrapper>
                        <ErrorBoundary fallback={accordion?.title} typeMessage="textMessage" error={submitError?.message}>
                            <>{accordion.content}</>
                        </ErrorBoundary>
                    </ContentWrapper>
                </Accordion>
            ))}
        </ErrorBoundary>
    );
};

export default DynamicAccordion;
