import { Stack } from '@mui/material';
import { Button, CloseIcon, Drawer, RightIcon } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import React, { useState } from 'react';
import { defaultValuesDarfForm, validationDarfFormSchema } from './DarfFormSchema';
import { DarfForm } from './DarfForm';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import {
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { FieldValues } from 'react-hook-form';
import { StepsPayment, postPayDarf } from 'services/accounts/bankAccount';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';

interface DarfContainerProps {
    setStepPayment: React.Dispatch<React.SetStateAction<StepsPayment>>;
    stepPayment: string | undefined;
    title: string;
    recordType: string;
    refetch: () => void;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

const theme = activeTheme();
const DarfContainer: React.FC<DarfContainerProps> = ({
    setStepPayment,
    stepPayment,
    title,
    recordType,
    refetch,
    onCloseDrawer,
    setToast
}) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [formFieldValues, setFormFieldValues] = useState<FieldValues>();
    const { setSubmitError, startRequest, endRequest } = useApiRequest();

    const validationDarf = validationDarfFormSchema();
    const defaultValuesDarf = defaultValuesDarfForm;
    const validationConfirmMfa = validationConfirmMfaFormSchema();
    const defaultValuesConfirmMfa = defaultValuesConfirmMfaForm;

    const handleSubmitGetValue = (values: FieldValues) => {
        setFormFieldValues(values);
        setStepPayment('confirmationMfa');
    };

    const onClose = () => {
        setStepPayment(undefined);
        setSubmitError(undefined);
    };

    const handleStep = () => {
        setStepPayment('requestDarf');
        setSubmitError(undefined);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const onSubmit = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: async (response: AxiosResponse<string, any>) => {
                const calculationPeriod = recordType === 'DARFPreto' ?
                    moment(formFieldValues?.calculationPeriod).format('YYYY/MM/DD') :
                    moment(formFieldValues?.calculationPeriod).format('YYYY-MM-DD')
                const payload = {
                    name: formFieldValues?.name,
                    receitaFederalCodeId: formFieldValues?.receitaFederalCodeId,
                    principalValue: formFieldValues?.principalValue,
                    paymentValue: formFieldValues?.paymentValue,
                    interestValue: formFieldValues?.interestValue,
                    receitaFederal: formFieldValues?.receitaFederal,
                    totalValue: formFieldValues?.totalValue,
                    percentual: formFieldValues?.percentual,
                    interestAndChargesValue: formFieldValues?.interestAndChargesValue,
                    paymentDate: moment(formFieldValues?.paymentDate).format('YYYY/MM/DD') + 'T00:00:00',
                    calculationPeriod: calculationPeriod,
                    taxPayerIdentificationType: 0,
                    registrationNumber: formFieldValues?.registrationNumber,
                    sessionId: response.data,
                    code: values?.code,
                };

                postPayDarf(bankAccountId!, payload, token!)
                    .then(() => {
                        endRequest(true);
                        onClose();
                        const title = 'Pagamento realizado com sucesso!';
                        const description = 'Ótimo! Agora é só aguardar a confirmação do pagamento.';
                        showSuccessToast(title, description, setToast);
                        onCloseDrawer();
                        refetch();
                    })
                    .catch((response) => {
                        const { description, open, title } = errorHandlingBankAccount(response);
                        endRequest(false);
                        setToast({ title, description, open, severity: 'error' });
                    });
            },
        });
    };

    function darfForm() {
        return (
            <FormProvider
                validationSchema={validationDarf}
                defaultValues={defaultValuesDarf}
                onSubmit={handleSubmitGetValue}
            >
                <DarfForm onClose={onClose} />
            </FormProvider>
        );
    }

    function mfaForm() {
        return (
            <FormProvider
                validationSchema={validationConfirmMfa}
                defaultValues={defaultValuesConfirmMfa}
                onSubmit={onSubmit}
            >
                <ConfirmMfaForm onClose={handleStep} toggleDrawer={true}/>
            </FormProvider>
        );
    }

    const formStep: { [key: string]: React.ReactNode } = {
        requestDarf: darfForm(),
        confirmationMfa: mfaForm(),
    };

    const form = formStep[stepPayment || 'requestDarf'];
    const openDrawer = stepPayment === 'requestDarf' || stepPayment === 'confirmationMfa';

    return (
        <>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onCloseDrawer}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    onClick={handleStep}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>

            <Drawer anchor="right" title={title} open={openDrawer} onClose={onClose} toggleDrawer>
                {form}
            </Drawer>
        </>
    );
};
export default DarfContainer;
