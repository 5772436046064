import { Stack } from '@mui/material';
import React from 'react';
import CategorySelectionForm from './CategorySelectionForm';
import ProcessBillingForm from './ProcessBillingForm';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { useFormContext } from 'contexts/formContext';

interface CreateChargesFormWrapperProps {
    setAddCategories: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateChargesFormWrapper: React.FC<CreateChargesFormWrapperProps> = ({
    setAddCategories,
}) => {
    const { getValues } = useFormContext();
    const formValues = getValues();
    const amount = formValues?.amount;
    return (
        <Stack spacing={2}>
            <CategorySelectionForm setAddCategories={setAddCategories} />
            <ProcessBillingForm registrationCost={formValues?.walletSelected?.custoRegistro}/>
            <HorizontalInfo type="currency" value={amount} />
        </Stack>
    );
};

export default CreateChargesFormWrapper;
