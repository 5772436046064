import { boolean, object, string } from 'yup';

export function validationClonePermissionSchema(resourceDisplay: string) {
    return object().shape({
        resourceId: string()
            .typeError(`${resourceDisplay} precisa ser preenchido`)
            .required(`${resourceDisplay} precisa ser preenchido`),
        hasTenant: boolean().nullable(), 
        tenant: string().nullable().notRequired()
    });
};

export const defaultValuesClonePermission = {
    resourceId: null,
    hasTenant: false, 
    tenant: null
};