import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';

function CommuncationFilterForm() {
    const { watch } = useFormContext();
    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    // { label: 'Todos', value: 'searchString' },
                    { label: 'Código convênio', value: 'walletsCode' },
                    { label: 'Nome', value: 'name' },
                    { label: 'Número da carteira', value: 'walletNumber' }
                ]}
            />
            {watch('optionFilter') === 'walletsCode' && (
                <TextFormField
                    name="walletsCode"
                    variant="outlined"
                    label="Filtrar por código convênio"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'name' && (
                <TextFormField
                    name="name"
                    variant="outlined"
                    label="Filtrar por nome"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            {watch('optionFilter') === 'walletNumber' && (
                <TextFormField
                    name="walletNumber"
                    variant="outlined"
                    label="Filtrar por número da carteira"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            {watch('optionFilter') === 'daysToSendBillingBeforeDueDate' && (
                <TextFormField
                    name="daysToSendBillingBeforeDueDate"
                    variant="outlined"
                    label="Filtrar por dias para envio"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}



        </>
    );
}

type CommunicationSettingFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const CommunicationSettingFilters = ({ currentValues, removeFilterSelected }: CommunicationSettingFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<CommuncationFilterForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
