import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import {
    formatDocumentNumber,
    validationInformation,
    formatCurrencyInCents,
    isNegativeValue,
} from 'helpers';
import React, { ReactNode } from 'react';
import { IServidore } from 'services/zetra';

type TooltipZetraProps = {
    values: IServidore;
};

interface IChildren {
    children: ReactNode;
}

export const TooltipZetra: React.FC<TooltipZetraProps> = ({ values }) => {
    const productLength: number = values?.margens?.length ?? 0;

    const ListItem = ({ children }: IChildren) => {
        return <Typography style={{ margin: '5px 0 ', fontSize: '14px' }}>{children}</Typography>;
    };

    return (
        <Stack sx={{ margin: '5px 0' }} spacing={2}>
            <Typography variant="md" fontWeight="bold" textAlign="center">
                Informações
            </Typography>

            <ListItem>
                <SpanCustom>Nome: </SpanCustom>
                {validationInformation(values?.orgao)}
            </ListItem>

            <ListItem>
                <SpanCustom>Código do órgão: </SpanCustom>
                {validationInformation(values?.orgaoCodigo)}
            </ListItem>

            <ListItem>
                <SpanCustom>CPF: </SpanCustom>
                {validationInformation(formatDocumentNumber(values?.cpf) ?? 'N/D')}
            </ListItem>

            <ListItem>
                <SpanCustom>Estabelecimento: </SpanCustom>
                {validationInformation(values?.estabelecimento)}
            </ListItem>

            <ListItem>
                <SpanCustom>Categoria: </SpanCustom>
                {validationInformation(values?.categoria)}
            </ListItem>

            <ListItem>
                <SpanCustom>Matricula: </SpanCustom>
                {validationInformation(values?.matricula)}
            </ListItem>

            {productLength > 1 && (
                <Typography variant="md" fontWeight="bold" textAlign="center">
                    Produtos (${productLength})
                </Typography>
            )}
            {productLength > 0 && (
                <>
                    {values?.margens?.map((m) => {
                        const isNegative = isNegativeValue(m?.valorDisponivel);
                        const vlMargemDisp = formatCurrencyInCents(m?.valorDisponivel);
                        const vlMargem = isNegative ? 'Margem indisponível' : vlMargemDisp;

                        const Render = () => {
                            return (
                                <React.Fragment>
                                    <ListItem>
                                        <SpanCustom>Valor da margem: </SpanCustom>
                                        {validationInformation(vlMargem)}
                                    </ListItem>
                                </React.Fragment>
                            );
                        };
                        return (
                            <React.Fragment>
                                {productLength > 1 ? (
                                    <Stack
                                        sx={{
                                            border: `1.2px solid #262626 `,
                                            borderRadius: 2,
                                            padding: 1,
                                        }}
                                    >
                                        <Render />
                                    </Stack>
                                ) : (
                                    <Render />
                                )}
                            </React.Fragment>
                        );
                    })}
                </>
            )}
        </Stack>
    );
};
