import React, { createContext, useContext, useState } from 'react';

type ProductIdProps = {
  productId: string | undefined;
  setProductId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ProductIdContext = createContext<ProductIdProps>({
  productId: undefined,
  setProductId: () => { },
});

interface ProductIdProviderProps {
  children: JSX.Element;
}

export const ProductIdProvider: React.FC<ProductIdProviderProps> = ({ children }) => {
  const [productId, setProductId] = useState<string | undefined>(undefined);

  return (
    <ProductIdContext.Provider
      value={{
        productId,
        setProductId,
      }}
    >
      {children}
    </ProductIdContext.Provider>
  );
};

export const useProductId = () => useContext(ProductIdContext);
