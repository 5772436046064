import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ChatClockIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12.75 10.5C12.75 10.0858 12.4142 9.75 12 9.75C11.5858 9.75 11.25 10.0858 11.25 10.5H12.75ZM12 12H11.25C11.25 12.4142 11.5858 12.75 12 12.75V12ZM13.5 12.75C13.9142 12.75 14.25 12.4142 14.25 12C14.25 11.5858 13.9142 11.25 13.5 11.25V12.75ZM11 3.75H13V2.25H11V3.75ZM2.75 17V12H1.25V17H2.75ZM13 20.25H6V21.75H13V20.25ZM1.25 17C1.25 19.6234 3.37665 21.75 6 21.75V20.25C4.20507 20.25 2.75 18.7949 2.75 17H1.25ZM21.25 12C21.25 16.5563 17.5563 20.25 13 20.25V21.75C18.3848 21.75 22.75 17.3848 22.75 12H21.25ZM13 3.75C17.5563 3.75 21.25 7.44365 21.25 12H22.75C22.75 6.61522 18.3848 2.25 13 2.25V3.75ZM11 2.25C5.61522 2.25 1.25 6.61522 1.25 12H2.75C2.75 7.44365 6.44365 3.75 11 3.75V2.25ZM15.25 12C15.25 13.7949 13.7949 15.25 12 15.25V16.75C14.6234 16.75 16.75 14.6234 16.75 12H15.25ZM12 15.25C10.2051 15.25 8.75 13.7949 8.75 12H7.25C7.25 14.6234 9.37665 16.75 12 16.75V15.25ZM8.75 12C8.75 10.2051 10.2051 8.75 12 8.75V7.25C9.37665 7.25 7.25 9.37665 7.25 12H8.75ZM12 8.75C13.7949 8.75 15.25 10.2051 15.25 12H16.75C16.75 9.37665 14.6234 7.25 12 7.25V8.75ZM11.25 10.5V12H12.75V10.5H11.25ZM12 12.75H13.5V11.25H12V12.75Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

ChatClockIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
