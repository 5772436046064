import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, Typography } from '@uy3/web-components';
import { useState } from 'react';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';

type KycSanctionsHistoryProps = {
  result: any;
};

function KycSanctionsHistoryList({ result }: KycSanctionsHistoryProps) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  const columnsPepHistory: GridColDef[] = [
    {
      field: 'originalName',
      headerName: 'Nome original',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'sanctionName',
      headerName: 'Nome de sanção',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'source',
      headerName: 'Fonte',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Tipo',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'matchRate',
      headerName: 'Similaridade',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Início',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
          </div>
        );
      },
    },
    {
      field: 'endDate',
      headerName: 'Fim',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
          </div>
        );
      },
    }
  ];

  const firstSanctionDate =
    result?.firstSanctionDate && new Date(result?.firstSanctionDate).toLocaleDateString('pt-BR');
  const lastSanctionDate =
    result?.lastSanctionDate && new Date(result?.lastSanctionDate).toLocaleDateString('pt-BR');

  return (
    <>
      <Grid2 mt={2}>
        <Typography variant="h5" color="neutral.dark" fontWeight={600}>
          Sanções
        </Typography>
        <HandleTitle
          title={'Está sobre sanção'}
          value={result?.isCurrentlySanctioned ? 'Sim' : 'Não'}
          key="isCurrentlySanctioned"
        />
        <HandleTitle
          title={'Primeiro registro'}
          value={firstSanctionDate ?? 'N/D'}
          key="firstSanctionDate"
        />
        <HandleTitle
          title={'Último registro'}
          value={lastSanctionDate ?? 'N/D'}
          key="lastSanctionDate"
        />
      </Grid2>

      <DataTable
        getRowId={() => Math.random().toString()}
        columns={columnsPepHistory}
        rows={result?.sanctionsHistory ?? []}
        page={page}
        rowCount={result?.sanctionsHistory?.length ?? 5}
        rowsPerPage={rowsPerPage}
        setPage={onChangePage}
        setRowsPerPage={onChangeRowsPerPage}
        paginationMode="client"
      />
    </>
  );
}

export default KycSanctionsHistoryList;
