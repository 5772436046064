import { string, object } from 'yup';

export function usersAndGroupSchema() {
    return object().shape({
        optionFilter: string().typeError('Filtrar precisa ser preenchido.').required('Filtrar: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Nome precisa ser preenchida.').typeError('Nome: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        email: string().when("optionFilter", {
            is: "email",
            then: string().required('Email precisa ser preenchida.').typeError('Email: precisa ser preenchida.'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesUsersAndGroupFilterschema = (defaultValue: string) => {
    return {
        name: null,
        searchString: null,
        optionFilter: defaultValue
    }
};