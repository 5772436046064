import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Button, Popup, Typography, UpdateIcon } from '@uy3/web-components';
import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import { useCreditList } from 'contexts/creditNote/creditContext';
import { useIdentity } from 'contexts/identityContext';
import { useState } from 'react';
import { useParams } from 'react-router';
import { restoreCreditFromDeleted } from 'services/creditNote';
import { activeTheme } from 'services/theme';
import { DeletedCreditList } from './DeletedCreditList';
import { FilterProvider } from 'contexts/filterContext';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { ToastType, iconSx, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';

const theme = activeTheme();
export const DeletedCreditListContainer = () => {
    const { userId } = useParams();
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [rowData, setRowData] = useState<any | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );
    const onClosePopup = () => {
        setRowData(undefined);
    };
    const [page, setPage] = useState(0);

    const { creditStatus, creditData, error, creditFetch, refetch } = useCreditList({
        personId: userId,
        page,
        size: rowsPerPage,
        isDeleted: true,
    });
    if (creditStatus === 'error') return <Error error={error} />;

    const handleRestore = (data: any) => {
        restoreCreditFromDeleted(data, token!)
            .then(() => {
                onClosePopup();
                const title = 'Operação restaurada com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((error) => {
                const { errorMessage } = mapErrorResponse(error);
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);
            })
            .finally(() => refetch());
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Stack
                container
                spacing={{ xs: 2, md: 3 }}
                component={Grid}
                direction={isMobile ? 'column' : 'row'}
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="32px"
                    lineHeight="38.4px"
                >
                    Operações excluídas
                </Typography>
                <Grid direction="row">
                    <Button
                        sx={{ color: 'black', fontSize: '17px', marginRight: '10px' }}
                        variant="text"
                        disabled={creditFetch}
                        size="large"
                        type="submit"
                        onClick={() => refetch()}
                        startIcon={
                            <UpdateIcon
                                htmlColor={!creditFetch ? 'black' : 'grey'}
                                sx={iconSx}
                            />
                        }
                        color="primary"
                    >
                        Atualizar
                    </Button>
                </Grid>
            </Stack>

            <RefreshProgress refreshing={creditFetch} />

            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text', multiple: false },
                    status: {
                        label: 'Status',
                        type: 'select',
                        multiple: true,
                        options: creditNoteSelectFilterOptions,
                    },
                    personDisplay: { label: 'Tomador', type: 'text', multiple: false },
                    initialDate: {
                        label: 'Data de início',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'finalDate',
                    },
                    initialPaymentDate: {
                        label: 'Data de pagamento',
                        type: 'date',
                        multiple: false,
                    },
                    maxValue: { label: 'Valor máximo', type: 'text', multiple: false },
                    minValue: { label: 'Valor mínimo', type: 'text', multiple: false },
                    tenant: { label: 'Correspondente', type: 'text', multiple: false },
                    creditNoteNo: { label: 'Número da operação', type: 'text', multiple: false },
                }}
            >
                <DeletedCreditList
                    queryData={creditData}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    setPage={onChangePage}
                    page={page}
                    setOpenPopup={setRowData}
                />
            </FilterProvider>

            <Popup
                open={rowData !== undefined}
                title={
                    rowData
                        ? `Restaurar a operação #${rowData?.row?.creditNoteNo}?`
                        : 'Restaurar Operação'
                }
                text={`Tem certeza que deseja restaurar essa operação?`}
                onClose={onClosePopup}
                children={
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClosePopup}
                            size="medium"
                            sx={{ mt: 3, mr: 2 }}
                        >
                            Não, cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRestore(rowData.id)}
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Sim, restaurar
                        </Button>
                    </Grid>
                }
            />
        </>
    );
};
