/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { Alert, Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { CheckboxFormField, DatePickerFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import React, { useEffect } from 'react';
import { activeTheme } from 'services/theme';
import { isEmpty } from 'lodash';

type FGTSManagementProps = {
    isLoading: boolean;
    onCloseModal: () => void;
    fundList: AutocompleteOptionList;
    batchAssignmentAutoComplete: AutocompleteOptionList;
};

const theme = activeTheme();

export const FGTSManagement: React.FC<FGTSManagementProps> = ({
    isLoading,
    onCloseModal,
    fundList,
    batchAssignmentAutoComplete,
}) => {
    const { watch, setValue } = useFormContext();
    const batchId = watch('id');
    const isBatchIdInvalid = isEmpty(batchId);

    useEffect(() => {
        if (isBatchIdInvalid) {
            setValue('reassign', true);
        }
    }, [batchId]);

    return (
        <Stack spacing={2} mt={2}>
            <Alert severity="info" text="Recurso exclusivo para cessões de operações FGTS" />
            <AutocompleteField
                name="id"
                label="Cessão"
                displayName="idDisplay"
                link={`/ccb/cessoes/${batchId}`}
                showEndIcon={batchId}
                endIconType="link"
                {...batchAssignmentAutoComplete!}
            />

            <DatePickerFormField label="Data da cessão" name="assignmentDate" fullWidth required />
            <AutocompleteField
                name="fundId"
                label="Fundo Cessionário"
                displayName="fundIdDisplay"
                link={`/cadastro/fundos/${watch('fundId')}`}
                showEndIcon={watch('fundId')}
                endIconType="link"
                required
                {...fundList!}
            />

            <PercentageFormField variant="outlined" name="minRate" label="Taxa mínima" required />

            <PercentageFormField
                variant="outlined"
                name="assignmentPremiumPercent"
                label="Ágio (%)"
                required
            />

            <CurrencyFormField
                variant="outlined"
                name="assignmentPremiumValue"
                label="Ágio (R$)"
                required
            />

            <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
                <Grid>
                    <DatePickerFormField
                        shrink
                        label="Data min. pagamento"
                        name="minAcceptanceDate"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid>
                    <DatePickerFormField
                        shrink
                        label="Data max. pagamento"
                        name="maxAcceptanceDate"
                        fullWidth
                        required
                    />
                </Grid>
            </Stack>

            <CheckboxFormField
                name="reassign"
                disabled={isBatchIdInvalid}
                label="Atualizar operações/parcelas"
                color="primary"
            />

            <Stack direction="row" spacing={2} justifyContent="right">
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    onClick={onCloseModal}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    type="submit"
                    disabled={isLoading}
                >
                    Enviar
                </Button>
            </Stack>
        </Stack>
    );
};
