import { Stack } from '@mui/material'
import { Button, CardData, CloseIcon, PaymentLinkIcon, Typography } from '@uy3/web-components'
import { iconSx, showErrorToast, showSuccessToast, ToastType } from 'contexts/apiRequestContext'
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber'
import { onCopyText } from 'helpers/methods/OnCopyText'
import { StyledQRCode } from 'pages/Login/MfaSetupForm/MfaSetupForm'
import { IInputSettingsReadModel } from 'services/chatbot/types'
import { activeTheme } from 'services/theme'

type GenerateLinkProps = {
    inputSetting: IInputSettingsReadModel
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

const theme = activeTheme();

export const GenerateLink = ({ inputSetting, onClose, setToast }: GenerateLinkProps) => {
    const text = `${inputSetting?.incomingMessage + " " + inputSetting?.identificationCode}`; 
    // encodeURIComponent
    const url = new URL(`https://wa.me/55${inputSetting?.botPhone}`);
    url.searchParams.set('text', text);

    const handleAction = () => {
        onCopyText({
            showErrorToast: () =>
                showErrorToast(
                    'Ops, houve um erro',
                    'Não foi possível copiar o link',
                    setToast
                ),
            showSuccessToast: () =>
                showSuccessToast(
                    'Sucesso ao copiar o link',
                    'Link foi copiado para a sua área de transferência',
                    setToast
                ),
            value: url.href,
        });
    }

    return (
        <Stack spacing={3}>
            <Typography fontSize='20px' >Escaneie o QR Code abaixo com seu celular ou clique no botão abaixo para copiar o link da conversa via WhatsApp.</Typography>
            <Stack direction='row' alignContent='center'>
                <StyledQRCode
                    id="qr-gen"
                    value={url.href}
                    size={290}
                    level={"H"}
                    includeMargin={true}
                    className='uk-align-center'
                />
            </Stack>

            <CardData
                listItem={[
                    { id: '1', title: 'Número de telefone', value: formatPhoneNumber(inputSetting?.botPhone) },
                    { id: '2', title: 'Código de identificação', value: inputSetting?.identificationCode },
                ]}
            />

            <Stack direction='row' alignItems='center' spacing={2} justifyContent='start'>
                <Button variant='outlined' onClick={onClose} startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />} sx={{ width: '100%' }}>
                    Fechar
                </Button>
                <Button variant='contained' onClick={handleAction} startIcon={<PaymentLinkIcon sx={iconSx} htmlColor={theme.palette.common.white} />} sx={{ width: '100%' }}>
                    Copiar link
                </Button>
            </Stack>
        </Stack>
    )
}
