import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const TicketIcon: FunctionComponent<SvgIconProps> = (props) => {
    const { htmlColor = '#373737' } = props;
    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    d="M10 8V6M10 18V16M10 13V11M6 21C3.79086 21 2 19.2091 2 17V16C2 15.4477 2.46 15.0163 2.98189 14.8356C4.15653 14.4289 5 13.313 5 12C5 10.687 4.15653 9.57105 2.98189 9.16437C2.46 8.98368 2 8.55228 2 8V7C2 4.79086 3.79086 3 6 3H18C20.2091 3 22 4.79086 22 7V8C22 8.55228 21.54 8.98368 21.0181 9.16437C19.8435 9.57105 19 10.687 19 12C19 13.313 19.8435 14.4289 21.0181 14.8356C21.54 15.0163 22 15.4477 22 16V17C22 19.2091 20.2091 21 18 21H6Z"
                    stroke={htmlColor}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </svg>
        </SvgIcon>
    );
};

TicketIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    fill: 'none',
};
