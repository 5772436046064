import { CreditProductReadModel } from 'services/creditProduct';
import { string, object } from 'yup';

export function validationSchemaInvolvedForm(fileName: string, product?: CreditProductReadModel) {
    const isShowPercentegeFieldByProduct = (): boolean => {
        if (!!product && (product?.paymentFixedCosts > 0 || product?.insuranceCoverAmount > 0)) {
            return true;
        }
        return false
    }
    return object().shape({
        personId: string().typeError('O Envolvido é inválido.').required('Envolvido: Precisa ser preenchido.'),
        relatedToId: string().typeError('Relacionada a: é inválido.').nullable().notRequired(),
        typeOfRelationship: string().typeError('Tipo de relação inválido.').required('Tipo de relação: Precisa ser preenchido.'),
        signatureType: string().typeError('Forma de Envio inválido.').nullable().notRequired(),
        signatureValidation: string().typeError('Forma de Validação inválido.').nullable().notRequired(),      
        percentage: string().when("typeOfRelationship", {
            is: (value: any) => {
                return value === "Beneficiary" && fileName === 'relatedPersonCreditNote' && isShowPercentegeFieldByProduct()
            },
            then: string().typeError('Percentual de distribuição precisa ser preenchido.').required('Percentual de distribuição: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),   
    });
};

export const defaultValuesInvolvedForm = {
    personId: null,
    relatedToId: null,
    typeOfRelationship: null,
    signatureType: null,
    signatureValidation: null , 
    percentage: null  
};

