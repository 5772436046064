import { Grid, Stack } from '@mui/material'
import { Button, KeyIcon, LeftIcon, RightIcon, Typography, UY3Icon } from '@uy3/web-components'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type ClaimPixKeyProps = {
  onClose: () => void;
  formValues: any;
  isLoading: boolean; 
  bankAccountData: any; 
  onClaimSubmit: () => void;
}

const cardStyle = {
  border: '1.5px solid #BABFD0',
  borderRadius: 3,
  padding: 3,
  mt: -2
}

export const ClaimPixKey = ({ onClose, formValues, bankAccountData, isLoading, onClaimSubmit }: ClaimPixKeyProps) => {
  return (
    <Stack spacing={4}>
      <Typography display='block' fontSize={18} fontWeight='bold'>
        Verificamos que a chave Pix está cadastrada em outra instituição
      </Typography>

      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={cardStyle}>
        <Grid direction='row'>
          <Typography fontSize={16}>{<KeyIcon sx={{height: 20, width: 20}}/>} Chave Pix de outra instituição</Typography>
        </Grid>
        <Grid direction='row'>
          <Typography fontSize={15}>{formValues?.pixKey}</Typography>
        </Grid>
      </Stack>

      <Typography display='block' fontSize={20} fontWeight='bold'>
        Migrando para a UY3
      </Typography>
      <Stack direction='row' alignItems='center' justifyContent='space-between' sx={{...cardStyle, padding: '10px 20px'}}>
        <Grid direction='row' position='relative'>
          <Typography fontSize={16}>{<UY3Icon sx={{height: 50, width: 50}}/>} 
          <span style={{marginTop: -2, display: 'block', position: 'absolute', top: 15, left: 60, width: 200}}>UY3 S.A</span></Typography>
        </Grid>
        <Grid direction='row' alignItems='center'> 
          <Typography fontSize={15}>
            Agência: {bankAccountData?.bankAccount?.agency} | 
            Conta: {bankAccountData?.bankAccount?.account}</Typography>
        </Grid>
      </Stack>

      <Grid sx={{...cardStyle}}>
        <Typography display='block' fontSize={17} fontWeight='bold'>
          Após a confirmação será necessário
        </Typography>

        <Typography display='block' fontSize={15} m='10px 0 '>
          Aceitar no aplicativo da outra instituição em até 7 dias corridos. Caso contrário a portabilidade será cancelada.

        </Typography>
        <Typography display='block' fontSize={15}>
          Ao confirmar na outra instituição, você receberá uma notificação assim que a chave for cadastrada na UY3.
        </Typography>
      </Grid>

      <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
        <Button
          startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Não desejo migrar
        </Button>
        <Button
          onClick={onClaimSubmit}
          variant="contained"
          size="medium"
          disabled={isLoading}
          startIcon={
            <RightIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: 23,
                width: 23,
              }}
            />
          }
        >
          Confirmar
        </Button>
      </Stack>
    </Stack>
  )
}