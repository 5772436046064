import { Grid, Stack } from '@mui/material';
import { Alert, AvailableCashIcon, Button, CardData, Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import moment from 'moment';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';

type DarfWithBarCodeInformationProps = {
  bankSlipData: any;
  onPayment: () => void;
}

const theme = activeTheme();

export const DarfWithBarCodeInformation = ({ bankSlipData, onPayment }: DarfWithBarCodeInformationProps) => {
  const { submitting } = useFormContext();
  return (
    <>
      <Grid spacing={2}>
        <Typography variant='h5' mb={2}>Informações referente ao DARF: </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid width='100%'>
            <CardData
              listItem={[
                { id: "2", title: 'Convênio de integração', value: `${bankSlipData?.convenioIntegracao ?? "N/D"}` },
                { id: "2", title: 'Valor principal', value: `${formatCurrencyInCents(bankSlipData?.principalValue)}` },
                { id: "3", title: 'Data de vecimento', value: `${moment(bankSlipData?.dueDate).format('DD/MM/YYYY')}` }
              ]}
            />
          </Grid>
        </Stack>
        {bankSlipData?.principalValue === 0 ?
          <Alert
            severity="warning"
            text="O valor principal retornado na consulta do boleto precisa ser maior que 0 (zero)"
          />
          :
          <Stack direction="row-reverse" justifyContent="flex-start" alignItems="center">
            <Button
              variant="contained"
              disabled={submitting}
              onClick={onPayment}
              startIcon={<AvailableCashIcon
                htmlColor={theme.palette.common.white}
                sx={iconSx}
              />}
              type='submit'
            >
              Pagar
            </Button>
          </Stack>}
      </Grid>
    </>
  )
}
