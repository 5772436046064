import { FormProvider } from 'contexts/formContext';
import { FundFilters } from './FundFilters';
import { defaultValuesFundtFilterSchema, fundtFiltersSchema } from './FundFiltersSchema';
import { useFilters } from 'contexts/filterContext';

export const FundFiltersContainer = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const onSubmitSearch = (values: any) => {
        if (values.optionFilter === "initialDate") {
            addFilter(values.optionFilter, values[values.optionFilter], new Date(values["finalDate"]));
        } else {
            addFilter(values.optionFilter, values[values.optionFilter]);
        }
    };

    const schema = fundtFiltersSchema();
    const defaultValues = defaultValuesFundtFilterSchema;

    return (
        <FormProvider
            defaultValues={defaultValues}
            validationSchema={schema}
            onSubmit={onSubmitSearch}
        >
            <FundFilters
                currentValues={filters}
                removeFilterSelected={removeFilterByIndex}
            />
        </FormProvider>
    )
}
