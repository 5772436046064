/* eslint-disable jsx-a11y/alt-text */
import type { FunctionComponent } from 'react';
import type { FeedbackErroProps } from './FeedbackErro.interface';
import { useCallback } from 'react';
import { Stack, SvgIconProps, useTheme } from '@mui/material';
import { Description, StyledDialog, StyledDialogContent, Title } from './FeedbackErro.styled';
import { Button } from '../../atoms';
import { DoneIcon, GenericErrorIcon, LeftIcon, NotFoundIcon, OfflineIcon } from '../../icons';

type OptionsType = {
  [type: string]: React.ReactNode
}

export const FeedbackErro: FunctionComponent<FeedbackErroProps> = ({
  title,
  description,
  textButton,
  open,
  onClose,
  type
}) => {
  const theme = useTheme();
  const refreshPage = useCallback(() => {
    window.location.href = '/';
  }, []);

  const sxIcon = {width: 200, height: 200}

  const options: OptionsType = {
    "notFound": <NotFoundIcon sx={sxIcon}/>,
    "temporary": <OfflineIcon sx={sxIcon}/>,
    "genericError": <GenericErrorIcon sx={sxIcon}/>
  }

  const Icon = options[type as string];

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ sx: { width: 800, maxWidth: '100%' } }}
    >
      <Stack direction='row' alignItems='center' justifyContent='center' ml={15} mt={2}>
        {Icon}
        <StyledDialogContent>
          <Title variant="xxl" fontSize="28px" color="neutral.medium" fontWeight={600} lineHeight="43px">
            {title}
          </Title>
          <Description variant="md" color="neutral.medium" lineHeight="19px">
            {description}
          </Description>
          <Button
            startIcon={<LeftIcon
              htmlColor={theme.palette.common.white}
            />}
            variant="contained"
            color="primary"
            size="medium"
            sx={{ maxWidth: '186px' }}
            onClick={refreshPage}
          >
            {textButton}
          </Button>
        </StyledDialogContent>
      </Stack>
    </StyledDialog>
  );
};
