import { object, boolean } from "yup";

export function validationRegisterFormSchema() {
    return object().shape({
        enableRegisterOverDue: boolean().nullable().notRequired()
    });
}

export const defaultValuesRegisterForm = {
    enableRegisterOverDue: false,
};