import { IRelatedPersonCreateModel } from "utils/interface";
import { LegalPersonBaseModel } from "./generics";

export class LegalPersonCreateModel extends LegalPersonBaseModel {
    registrationNumber!: string;
    relatedPerson?: IRelatedPersonCreateModel[];

    constructor(init?: Partial<LegalPersonCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}