import { createContext, useContext, ReactNode, useState } from "react";

type ShowNotificationsContextType = {
    enableNotifications: boolean;
    setEnableNotifications: (x: boolean) => void
};

const ShowNotificationsContext = createContext<ShowNotificationsContextType>({
    enableNotifications: false,
    setEnableNotifications: () => { }
});

interface IChildren {
    children: ReactNode
}

export const ShowNotificationsProvider = ({ children }: IChildren) => {
    const [enableNotifications, setEnableNotifications] = useState<boolean>(false);

    return (
        <ShowNotificationsContext.Provider {...{
            value: {
                enableNotifications,
                setEnableNotifications
            }
        }}>
            {children}
        </ShowNotificationsContext.Provider>
    )
};

export const useShowNotification = () => useContext(ShowNotificationsContext);