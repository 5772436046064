import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const BookIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M8 6.25C7.58579 6.25 7.25 6.58579 7.25 7C7.25 7.41421 7.58579 7.75 8 7.75V6.25ZM16 7.75C16.4142 7.75 16.75 7.41421 16.75 7C16.75 6.58579 16.4142 6.25 16 6.25V7.75ZM8 10.25C7.58579 10.25 7.25 10.5858 7.25 11C7.25 11.4142 7.58579 11.75 8 11.75V10.25ZM12 11.75C12.4142 11.75 12.75 11.4142 12.75 11C12.75 10.5858 12.4142 10.25 12 10.25V11.75ZM7 16.75H20V15.25H7V16.75ZM17 21.25H7V22.75H17V21.25ZM7 21.25C5.75736 21.25 4.75 20.2426 4.75 19H3.25C3.25 21.0711 4.92893 22.75 7 22.75V21.25ZM17 22.75C19.0711 22.75 20.75 21.0711 20.75 19H19.25C19.25 20.2426 18.2426 21.25 17 21.25V22.75ZM7 15.25C4.92893 15.25 3.25 16.9289 3.25 19H4.75C4.75 17.7574 5.75736 16.75 7 16.75V15.25ZM7 2.75H17V1.25H7V2.75ZM19.25 5V19H20.75V5H19.25ZM4.75 19V5H3.25V19H4.75ZM17 2.75C18.2426 2.75 19.25 3.75736 19.25 5H20.75C20.75 2.92893 19.0711 1.25 17 1.25V2.75ZM7 1.25C4.92893 1.25 3.25 2.92893 3.25 5H4.75C4.75 3.75736 5.75736 2.75 7 2.75V1.25ZM8 7.75H16V6.25H8V7.75ZM8 11.75H12V10.25H8V11.75Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

BookIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
