import { object, string } from "yup";

export function validationSchemaApprovalsSignature(resource: 'approveSignature' | 'disapproveSignature') {
    let baseSchema: object = {}; 

    const optionsByResource: {[type:string]: object} = {
        "approveSignature": {
            message: string().nullable().notRequired(),
        }, 
        "disapproveSignature": {
            message: string().required('Mensagem: Precisa ser preenchida').typeError('Mensagem: Precisa ser preenchida'),
        }
    }

    baseSchema = optionsByResource[resource]!
    
    return object().shape({...baseSchema});
};

export const defaultValuesApprovalsSignature = {
    message: null
};