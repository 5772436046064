import { FormProvider } from 'contexts/formContext';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import {
    ConfirmPasswordFormDefaultValues,
    ConfirmPasswordFormSchema,
    ConfirmPasswordFormValidationSchema,
} from './ConfirmPasswordSchema';
import { Alert, Typography } from '@uy3/web-components';
import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { useIdentity } from 'contexts/identityContext';
import { useNavigate } from 'react-router';
import { useEmail } from 'contexts/emailContext';

export const ConfirmPasswordContainer = () => {
    const {
        confirmPassword,
        authFlow,
        navigateToFlow,
        submitError,
        isAuthenticated,
        clearSubmitError,
    } = useIdentity();
    const navigate = useNavigate();
    const { email } = useEmail();

    const schema = ConfirmPasswordFormValidationSchema();
    const defaultValues = ConfirmPasswordFormDefaultValues;
    const authenticated = isAuthenticated();

    const submit = (values: ConfirmPasswordFormSchema) => confirmPassword(email, values.password);

    useEffect(() => {
        if (!authFlow && !authenticated) {
            navigate('/login');
            clearSubmitError();
        }

        navigateToFlow(navigate);
    }, [authFlow, authenticated, clearSubmitError, navigate, navigateToFlow]);

    return (
        <React.Fragment>
            {submitError && <Alert severity="error" text={submitError} variant="filled" />}
            <Grid container direction="column" spacing={2} my={2} ml={1}>
                <Typography variant="xl">Cadastrar nova senha</Typography>
                <Typography variant="xs">
                    Para acessar o sistema, você deverá cadastrar uma nova senha
                </Typography>
            </Grid>
            <FormProvider validationSchema={schema} defaultValues={defaultValues} onSubmit={submit}>
                <ConfirmPasswordForm />
            </FormProvider>
        </React.Fragment>
    );
};
