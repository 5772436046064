
export class BankAccountPixRefundCreateModel {
    idPixEntry!: string
    reason!: string
    observation?: string
    valueInCents!: number

    constructor(init?: Partial<BankAccountPixRefundCreateModel>) {
        Object.assign(this, init);
    }
}