import { Stack } from '@mui/system';
import { Button, Typography } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { useFormContext } from 'contexts/formContext';
import { TextFormField } from 'components/Forms/FormFields/TextFormField/TextFormField';
import { TextFormFieldPassword } from 'components/Forms/FormFields/TextFormFieldPassword/TextFormFieldPassword';

export function LoginForm() {
    const { submitting } = useFormContext();

    return (
        <>
            <Typography variant="h5" color="neutral.dark" lineHeight="34px" mb={2} fontWeight={400}>
                Iniciar sessão
            </Typography>
            <Stack direction="column" spacing={2} mb={2}>
                <TextFormField
                    name="email"
                    label="E-mail"
                    variant="outlined"
                    fullWidth
                    required
                />
                <TextFormFieldPassword
                    name="password"
                    label="Senha"
                    variant="outlined"
                    fullWidth
                    required
                />
                <Button
                    name='login-button'
                    type="submit"
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={submitting}
                    fullWidth={true}
                    sx={{ width: '100%' }}
                >
                    Acessar
                </Button>
            </Stack>
            <Link to="/login/recuperar-senha">Esqueceu sua senha?</Link>
        </>
    );
}
