import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, InputAdornment, IconButton } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { optionsBankAccNewStatus } from 'contexts/bankAccount/bankAccountOptions';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';
import { phoneNumberMask } from 'utils/constants';

type BankAccountRequestFiltersProps = {
    currentValues: any,
    removeFilter: (index: number) => void;
}

const BankAccountFormFilters = () => {
    const { watch } = useFormContext();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, "").length > 11;

    return <>
        <Grid>
            <SelectFormField
                name='optionFilter'
                label='Filtrar '
                variant='outlined'
                fullWidth
                options={[
                    { label: "Todos", value: 'searchString' },
                    { label: "CPF/CNPJ", value: 'registrationNumber' },
                    { label: "Email", value: 'email' },
                    { label: "Nome do parceiro", value: 'partnerName' },
                    { label: "Nome do solicitante", value: 'ownerDisplay' },
                    { label: "Número da conta", value: 'accountNumber' },
                    { label: "Número de telefone", value: 'phone' },
                    { label: "Status", value: 'status' },
                    { label: "Tipo de conta", value: 'typeAccount' },
                    { label: "Data inicial", value: 'initialDate' },
                ]}
            />
        </Grid>
        <Grid>
            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    name='searchString'
                    variant='outlined'
                    label='Procurar'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {
                watch('optionFilter') === 'registrationNumber' && (
                    <TextFormField
                        label='Filtrar por CPF/CNPJ'
                        name='registrationNumber'
                        variant='outlined'
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: !isLegalPerson ? cpfMaskAndSpace : cnpjMask },
                            endAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: 0 }}>
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        fullWidth
                    />
                )
            }
            {watch('optionFilter') === 'partnerName' && (
                <TextFormField
                    name='partnerName'
                    variant='outlined'
                    label='Filtrar por nome do parceiro'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'accountNumber' && (
                <TextFormField
                    name='accountNumber'
                    variant='outlined'
                    label='Filtrar por número da conta'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'ownerDisplay' && (
                <TextFormField
                    name='ownerDisplay'
                    variant='outlined'
                    label='Filtrar por nome do solicitante'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'status' && (
                <SelectFormField
                    name='status'
                    label='Status'
                    showEndAdornment={true}
                    options={optionsBankAccNewStatus}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'typeAccount' && (
                <SelectFormField
                    name='typeAccount'
                    label='Tipo de conta'
                    options={[
                        { label: "Conta de Livre Movimentação", value: "Payment" },
                        { label: "Conta Escrow", value: "Escrow" },
                        { label: "Escrow +", value: "EscrowPlus" }, 
                        { label: "Escrow multicredores", value: "EscrowMulticreditors" },
                    ]}
                    showEndAdornment={true}
                    onClick={sendSubmitEvent}
                />
            )}
            {
                watch('optionFilter') === 'phone' && (
                    <TextFormField
                        label='Filtrar por número de telefone'
                        name='phone'
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            inputComponent: MaskedInput,
                            inputProps: { mask: phoneNumberMask },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )
            }
            {
                watch('optionFilter') === 'email' && (
                    <TextFormField
                        label='Filtrar por email'
                        name='email'
                        fullWidth
                        variant='outlined'
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                )
            }
            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data de início"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </Grid>
    </>
}

export const BankAccountRequestFilters = ({ currentValues, removeFilter }: BankAccountRequestFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<BankAccountFormFilters />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilter}
                />
            }
        />
    )
}
