import React, { createContext, useContext, useState } from 'react';

type ShowValuesContextProps = {

	showValues: boolean,
	setShowValues: (show : boolean) => void
};

interface ShowValuesProviderProps {
	children: JSX.Element | JSX.Element[];
}

const ShowValuesContext = createContext<ShowValuesContextProps>({
	showValues: false,
	setShowValues: () => { }
});

export const ShowValuesProvider = ({ children }: ShowValuesProviderProps) => {

	const [showValues, setShowValues] = useState(true);

	return <ShowValuesContext.Provider
		value={{
			showValues,
			setShowValues
		}}>
		{children}
	</ShowValuesContext.Provider>
};

export function useShowValues(): ShowValuesContextProps {
	const context = useContext(ShowValuesContext);
	const {
		showValues,
		setShowValues
	} = context;

	return {
		showValues,
		setShowValues,	
	};
}
