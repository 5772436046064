import { Grid, Stack } from '@mui/material'
import { Button, CardData, CloseIcon, CopyIcon, Typography } from '@uy3/web-components'
import { ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext'
import { onCopyText } from 'helpers/methods/OnCopyText'
import QRCode from 'qrcode.react'
import React from 'react'
import { FieldValues } from 'react-hook-form'
import { activeTheme } from 'services/theme'
import styled from "@emotion/styled";
import { formatCurrencyInCents } from 'helpers/formats/Currency'
import { formatBankAccountPixKeyOnyMask } from 'helpers/formats/BankAccount'
import { isEmpty } from 'lodash'
import { hideCredencesDocumentNumber } from 'helpers/formats/DocumentNumber'
import { onlyNumbers } from 'helpers/validations/formFieldValidator'

type ShowPixQRCodeProps = {
    code: string;
    onClose: () => void
    typeQrCode: string;
    formValues: FieldValues;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export const StyledQRCode = styled(QRCode)(() => ({
    margin: "20px auto 40px auto"
}));


const theme = activeTheme();

export const ShowPixQRCode = ({ code, setToast, formValues, onClose, typeQrCode }: ShowPixQRCodeProps) => {
    const keyPixFormated = formatBankAccountPixKeyOnyMask(formValues?.typeDisplay, formValues?.pixKey, true);
    const valueInCents = formatCurrencyInCents(Number(formValues?.valueInCents));
    const payerRegistrationNumber = formValues?.payerRegistrationNumber; 

    async function onCopy() {
        onCopyText({
            showErrorToast: () => showErrorToast("Ops, houve um erro", "Não foi possível copiar o QRCode", setToast),
            showSuccessToast: () => showSuccessToast(
                "Sucesso ao copiar o QRCode",
                "QR Code foi copiado para a sua área de transferência",
                setToast
            ),
            value: code
        });
    }

    const listItems = [
        { id: "1", title: "Chave PIX", value: keyPixFormated },
        { id: "2", title: "Tipo de chave", value: formValues?.typeDisplay }
    ]

    if (typeQrCode !== 'QRCodeStatic' && (!isEmpty(payerRegistrationNumber) && !isEmpty(formValues?.payerName))) {
        listItems.push(
            { id: "4", title: "CPF/CNPJ do pagador", value: hideCredencesDocumentNumber(onlyNumbers(payerRegistrationNumber)) },
            { id: "5", title: "Nome do pagador", value: formValues?.payerName },
        )
    }

    return (
        <Stack>
            <StyledQRCode
                id="qr-gen"
                value={code}
                size={290}
                level={"H"}
                includeMargin={true}
                className='uk-align-center'
            />

            <CardData
                listItem={listItems}
            />

            {!isEmpty(valueInCents.toString()) &&
                <Grid
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography variant="h5" fontWeight={700}>
                        Valor a receber
                    </Typography>
                    <Typography
                        variant="h4"
                        fontWeight={700}
                        color={theme.palette.primary.main}
                    >
                        {valueInCents}
                    </Typography>
                </Grid>}

            <Stack direction='column' alignItems='center' spacing={2} mt={3} >
                <Button
                    variant='contained'
                    fullWidth
                    size='large'
                    onClick={onCopy}
                    startIcon={<CopyIcon htmlColor={theme.palette.common.white} />}
                >
                    Copiar QR Code
                </Button>
                <Button
                    variant='outlined'
                    fullWidth
                    size='large'
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                >
                    Fechar
                </Button>
            </Stack>
        </Stack>
    )
}
