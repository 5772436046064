/* eslint-disable sonarjs/no-duplicated-branches */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable no-else-return */
import type { FunctionComponent } from 'react';
import type { TimelineProps } from './Timeline.interface';
import { Stack, Step, StepIconProps, Stepper, StepLabel, Typography, styled, Grid, LinearProgress } from '@mui/material';
import { useState } from 'react';
import { QontoConnector, StepIconRoot } from './Timeline.styled';
import {
  ComplianceIcon,
  DoneIcon,
  DraftIcon,
  LiquidateIcon,
  SignatureIcon,
} from '../../icons';

const TypographyStyled = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  lineHeight: '19px',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
  },
}));

const TypographyDescriptionStyled = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  lineHeight: '19px',
  [theme.breakpoints.down('md')]: {},
  [theme.breakpoints.down('sm')]: {
    marginBottom: '20px',
  },
}));

const StepIcon = (props: StepIconProps) => {
  const { active = false, completed, className, title } = props;
  const [color, setColor] = useState<string | undefined>(undefined);
  const iconsSX = { height: '24px', width: '22px', zIndex: 10, opacity: 5 };
  const icons: { [index: string]: React.ReactElement } = {
    1: <DraftIcon htmlColor={color} sx={iconsSX} />,
    2: <ComplianceIcon htmlColor={color} sx={iconsSX} />,
    3: <SignatureIcon htmlColor={color} sx={iconsSX} />,
    4: <LiquidateIcon htmlColor={color} sx={iconsSX} />,
    5: <DoneIcon htmlColor={color} sx={iconsSX} />,
  };

  const isFinished = title === 'Encerrado' && active;

  return (
    <StepIconRoot
      ownerState={{ completed, active, isFinished }}
      className={className}
      setColor={setColor}
    >
      {icons[String(props.icon)]}
    </StepIconRoot>
  );
};

type StepObject = {
  description: string;
  labelIsFinished?: string
};

export const Timeline: FunctionComponent<TimelineProps> = ({
  status,
  timeline,
  timelineType = "creditNote",
  statusDisplay
}) => {
  let steps: string[] = ['Rascunho', 'Aprovações', 'Assinatura', 'Liquidação', 'Encerrado'];

  const description = statusDisplay === 'Garantia Manual' ? 'Em averbação' : statusDisplay === 'Revisão de Pagamento' ? 'Em revisão' : 'Em liquidação';

  let stepObjects: { [key: string]: StepObject } = {
    'Rascunho': { description: statusDisplay === 'Revisão' ? 'Em revisão' : 'Registro criado' },
    'Aprovações': { description: statusDisplay === 'Reserva de Margem' ? 'Reserva de margem' : "Crédito" },
    'Assinatura': { description: 'Assinar documento' },
    'Liquidação': { description},
    'Encerrado': { description: 'Concluída', labelIsFinished: "Encerrado" }
  };

  if (timelineType === 'bankAccountRequest') {
    steps = ['Rascunho', 'Aprovações', 'Assinatura', 'Revisão', 'Encerrado'];
    stepObjects = {
      'Rascunho': { description: 'Registro criado' },
      'Aprovações': { description: "Aprovação" },
      'Assinatura': { description: 'Assinar documento' },
      'Revisão': { description: 'Em Revisão' },
      'Encerrado': { description: 'Concluída', labelIsFinished: "Ativa" }
    }
  };

  if (timelineType === "batchAssignment") {
    steps = ['Rascunho', 'Processamento', 'Assinatura', 'Liquidação', 'Encerrado'];
    stepObjects = {
      'Rascunho': { description: 'Registro criado' },
      'Processamento': { description: 'Em Processamento' },
      'Assinatura': { description: 'Assinar documento' },
      'Liquidação': { description: status.includes('Revisão') ? 'Em revisão' : 'Em liquidação' },
      'Encerrado': { description: 'Concluída', labelIsFinished: "Encerrado" }
    }
  };

  let activeStep = 0;
  const stepRevision = statusDisplay === 'Revisão de Pagamento' ? 3 : 0;

  const statusMap: { [key: string]: number } = {
    "Aprovação": 1,
    "Processamento": 1,
    "Assinaturas": 2,
    "Assinatura": 2,
    "Validação de Assinaturas": 2,
    "Liquidação": 3,
    "Garantia": 3,
    "Revisão": stepRevision,
    "Abertura": 3,
    "Encerrado": 4,
    "Ativa": 4
  };

  if (typeof status === 'string') {
    for (const [key, value] of Object.entries(statusMap)) {
      if (status.includes(key)) {
        activeStep = value;
        break;
      }
    }
  }

  const formatDate = (date: Date | string) => {
    if (date === null) return "Aguardando início"
    return new Date(date).toLocaleDateString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const progressBar: { [step: number]: number } = {
    0: 23,
    1: 44,
    2: 68,
    3: 92,
    4: 100
  };

  const findStartedAt = (label: string) => {
    if (label === "Aprovações" && status !== 'Encerrado') {
      return timeline?.find((item) => item?.name === status);
    } else if (label === 'Processamento') {
      return timeline?.find((item) => item?.name === "Cessão em lote");
    } else if (status === 'Revisão') {
      return timeline?.find((item) => item?.name.includes(status));
    }
    return timeline?.find((item) => item?.name.includes(label));
  }

  return (
    <Stack sx={{ width: '100%' }}>
      <Stepper activeStep={activeStep} connector={<QontoConnector />} orientation="horizontal">
        {steps.map((label, index) => {
          let isActive = index === activeStep;
          const details = stepObjects[steps[index]];
          const currentDataTimeline = findStartedAt(label);

          const equalStatus = (): boolean => {
            if (status.includes('Aprovação')) {
              return status === currentDataTimeline?.name;
            } else if (isActive && status === "Revisão") {
              return status === currentDataTimeline?.name;
            }
            return status === label;
          };

          const showStatus = isActive && equalStatus() === false;

          if (status === 'Encerrado') {
            isActive = true;
          }

          const labelShowStatus = showStatus ? status : details.labelIsFinished ?? label;
          const isFinished = status === 'Encerrado' && isActive;

          const currentDate = formatDate(currentDataTimeline?.startedAt!) === 'Invalid Date' ?
            "" : formatDate(currentDataTimeline?.startedAt!);

          return (
            <Step
              key={details.description || label}
              sx={{
                display: 'flex',
                flexDirection: 'row-reverse'
              }}
            >
              <Grid>
                <TypographyStyled
                  color='neutral.dark'
                  sx={{
                    fontSize: '16px',
                    fontWeight: 'normal'
                  }}
                >
                  {/* {isFinished ? labelShowStatus : isActive ? statusDisplay : label} */}
                  {isFinished ? labelShowStatus : label}
                </TypographyStyled>
                <TypographyDescriptionStyled
                  color='neutral.dark'
                  sx={{
                    fontSize: '12px',
                    lineHeight: 1.7
                  }}
                >
                  {isActive && status === 'Encerrado' ?
                    details.description : isActive && status !== 'Encerrado' && !showStatus ?
                      status : details.description
                  }
                  <br />
                  {equalStatus() && `${currentDate}`}
                </TypographyDescriptionStyled>
              </Grid>
              <StepLabel
                sx={{
                  marginTop: isActive ? -7 : 'auto'
                }}
                StepIconComponent={StepIcon}
                StepIconProps={{ active: isActive, title: status.toString()! }}
              />
            </Step>
          );
        })}
      </Stepper>

      <Grid sx={{
        margin: status === 'Encerrado' ? '25px 20px' : '15px 20px'
      }}>
        <LinearProgress
          color={status === 'Encerrado' ? 'success' : 'primary'}
          sx={{ height: '5px' }}
          variant="determinate"
          value={progressBar[activeStep]}
        />
      </Grid>

    </Stack>
  );
};
