import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

interface AccountStatementHeaderListProps {
    isFetching: boolean;
    refetch: () => void;
    periodSelected: string;
}

const theme = activeTheme();
const AccountStatementHeader: React.FC<AccountStatementHeaderListProps> = ({
    isFetching,
    refetch,
    periodSelected,
}) => {
    const { bankAccountId } = useParams();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const queryClient = useQueryClient();

    async function accountStatementRefetch() {
        refetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    const labelFilterSelected = `Extrato dos ${
        periodSelected === undefined ? 'últimos 7 dias' : periodSelected?.replace('Ú', 'ú')
    }`;

    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography variant="xxl" color="neutral.dark" lineHeight="38.4px">
                {labelFilterSelected}
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color }}
                    variant="text"
                    size="medium"
                    onClick={accountStatementRefetch}
                    disabled={isFetching}
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                    color="primary"
                >
                    Atualizar
                </Button>
            </Grid>
        </Stack>
    );
};
export default AccountStatementHeader;
