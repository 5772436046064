import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { Button } from '../../atoms';

export const Card = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: '0px 8px 32px rgba(186, 191, 208, 0.32)',
  borderRadius: '8px',
  padding: '24px',
  maxWidth: '100%',
}))

Card.defaultProps = {
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'space-between'
}

export const TextButton = styled(Button)(() => ({
  padding: '2px 2px 2px 0'
}))

export const CardInfo = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginBottom: '80px',
  '& .infoText': {
    marginLeft: '18px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '& .infoText': {
      marginLeft: 0,
      marginTop: '16px',
    }
  }
}))

CardInfo.defaultProps = {
  flexDirection: 'row',
}
