import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext'
import React, { ReactNode } from 'react'
import { activeTheme } from 'services/theme';
import { StepAddPayerEnum } from '../Enums/StepAddPayerEnum';
import { FieldValues } from 'react-hook-form';

type BaseStepFormProps = {
    children: ReactNode;
    defaultValues: object;
    validationSchema: any;
    onSubmit: (values: FieldValues) => void; 
    onClose: () => void;
    isLoading: boolean 
    currentStep: StepAddPayerEnum
}

const theme = activeTheme();

export const BaseStepForm: React.FC<BaseStepFormProps> = ({ children, defaultValues, onSubmit, validationSchema, onClose, currentStep, isLoading }) => {
    const color = theme.palette; 
    const isLastStep = currentStep === StepAddPayerEnum.basicInformation; 
    
    return (
        <FormProvider {...{
            defaultValues,
            validationSchema,
            onSubmit,
            children: (
                <Stack spacing={2}>
                    {children}
                    {isLastStep && <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                        <Button
                            variant="outlined"
                            startIcon={<CloseIcon htmlColor={color.primary.main} />}
                            onClick={onClose}
                        >
                            Fechar
                        </Button>
                        <Button
                            variant="contained"
                            disabled={isLoading}
                            startIcon={<SaveIcon htmlColor={color.common.white} />} 
                            type="submit"
                        >
                            Salvar
                        </Button>
                    </Stack>}
                </Stack>
            )
        }} />
    )
}
