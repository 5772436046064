import { Box, Grid, Stack } from '@mui/material';
import {
    AddCircleIcon,
    Alert,
    ChartWaveRectangleIcon,
    CheckCircleIcon,
    DeleteIcon,
    OperatorIcon,
    SaveIcon,
    ShareIcon,
    ShieldWarningIcon,
    TabsProps,
    Toast,
    Typography,
    UpdateIcon,
    UserRectangleIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { ComplianceChecklistMessage } from 'components/ComplianceChecklistMessage/ComplianceChecklistMessage';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';
import { TabBar } from 'components/TabBar/TabBar';
import { ToastType, iconSx } from 'contexts/apiRequestContext';
import { useAppConfig } from 'contexts/appConfig';
import { FormMode, useFormContext } from 'contexts/formContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { hasCustomPermission, isActionAllowed } from 'helpers';
import { useEffect, useState } from 'react';
import { AttributeTypeChecklist } from 'services/creditNote/complianceChecklist';
import { checkActionsPerms } from 'services/permissions';

type NaturalPersonFieldsFormProps = {
    personId: string;
    mode: FormMode;
    complianceChecklist: AttributeTypeChecklist[]
    setOpenDrawer: React.Dispatch<React.SetStateAction<{
        open: boolean;
        type: string;
    } | undefined>>
    refetch: () => void;
    setToast: (toast: ToastType) => void;
};

export default function NaturalPersonForm({
    mode,
    personId,
    setOpenDrawer,
    refetch,
    setToast,
    complianceChecklist
}: NaturalPersonFieldsFormProps) {
    const { validationErrors, watch, isDirty, readOnly } = useFormContext();
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const isNew = mode === 'create';
    const [open, setOpen] = useState(false);

    const resource = 'NaturalPerson';
    const typePermission = isNew ? 'Create' : 'Update';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const { isRootTenancy } = useTenant();
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, isRootTenancy);

    const enableLoadValuesByRegistrationNumber = isNew &&
    appConfig?.AUTO_QUERY_BASIC_DATA !== 1 && watch('loadValuesByRegistrationNumber') !== true;

    const hasPermissionDataset = (action: 'ReadDataSet' | 'RequestDataSet'): boolean =>
        checkActionsPerms(action, permissionsData, 'NaturalPerson');

    const tabs = [
        { label: 'Informações', href: personId },
        { label: 'Dados bancários', href: 'dados-bancarios' },
        { label: 'Documentos', href: 'documentos' },
        ...(!isNew && hasPermissionDataset('ReadDataSet') ? [{ label: 'Compliance', href: 'compliance' }] : []),
        ...(!isNew && appConfig.CREDIT_TAB === 1 ? [{ label: 'Crédito', href: 'credito' }] : []),
        ...(!isNew ? [{ label: 'Operações', href: 'operacoes' }] : []),
    ] as TabsProps[];

    const showDeletecomplianceChecklist = () => {
        if (complianceChecklist?.length > 0 && !isNew && isRootTenancy) return true;
        return false;
    }

    const refresWithToast = () => {
        refetch();
        setToast({
            title: 'Os dados da pessoa física foram atualizados!',
            severity: 'success',
            open: true,
        });
    }

    useEffect(() => {
        const personCredit = watch('personCreditStatus');
        const personCreditStatus = watch('personCreditStatus.status');

        if (!!personCredit && personCreditStatus !== "Approved") setOpen(true);
    }, [watch]);

    const close = () => setOpen(false);
    return (
        <>
            {validationErrors && (
                <Box my={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar: Corrija os erros do formulário e envie novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        {isNew ? 'Novo cadastro' : `${watch('name') ?? ''}`}
                    </Typography>
                    <Grid direction="row" alignItems="center">
                        <Actions
                            numberOfButtons={4}
                            buttonsActionsList={[
                                {
                                    enable: permissionCustom,
                                    label: "Salvar",
                                    type: 'submit',
                                    icon: <SaveIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: !isNew,
                                    label: "Atualizar",
                                    action: refresWithToast,
                                    icon: <UpdateIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: permissionCustom && enableLoadValuesByRegistrationNumber,
                                    label: "Carregar informações",
                                    action: () => setOpenDrawer({open: true, type: 'getPersonByRegistrationNumber'}),
                                    icon: <UserRectangleIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: isRootTenancy && hasPermissionDataset('RequestDataSet') && !isNew,
                                    label: "Regularizar CPF",
                                    action: () => setOpenDrawer({open: true, type: 'regulateRegistratioNumber'}),
                                    icon: <CheckCircleIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: enableAction && !isNew,
                                    label: "Clonar registro",
                                    action: () => setOpenDrawer({ open: true, type: 'clone' }),
                                    icon: <ShareIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: !isNew,
                                    label: "Atribuir",
                                    action: () => setOpenDrawer({ open: true, type: 'assign' }),
                                    icon: <OperatorIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: !isNew && checkActionsPerms('RequestDataSet', permissionsData, 'NaturalPerson'),
                                    label: "Adicionar token consignado",
                                    action: () => setOpenDrawer({ open: true, type: 'reserveToken' }),
                                    icon: <ChartWaveRectangleIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: showDeletecomplianceChecklist(),
                                    label: "Remover restrições",
                                    action: () => setOpenDrawer({ open: true, type: 'deletecomplianceChecklist' }),
                                    icon: <DeleteIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    label: 'Registrar fraude',
                                    action: () => setOpenDrawer({ open: true, type: 'fraudRecord' }),
                                    enable: enableAction && !isNew,
                                    icon: <ShieldWarningIcon sx={iconSx} />,
                                },
                                {
                                    enable: !isNew && isRootTenancy,
                                    label: "Incluir na Blacklist",
                                    action: () => setOpenDrawer({open: true, type: 'addBlackList'}),
                                    icon: <AddCircleIcon htmlColor="black" sx={iconSx} />
                                }
                            ]}
                        />
                    </Grid>
                </Stack>
                <Toast
                    onClose={close}
                    open={open}
                    title="Atenção! O tomador possui restrições de crédito."
                    severity="info"
                />
                <Grid container item xs={4} mb={3}>
                    <NotSavedChangesAlert isDirty={isDirty} />
                </Grid>
                <OperatorViewInfo />
                {showDeletecomplianceChecklist() &&
                    <Grid spacing={2} mt={2} mr={3} mb={2}>
                        <ComplianceChecklistMessage
                            complianceChecklistData={complianceChecklist}
                            name={watch('name')}
                        />
                    </Grid>}
                <Grid sx={{ mr: 3 }}>
                    <TabBar tabs={tabs} />
                </Grid>
            </>
        </>
    );
};