import React, { useState } from 'react'
import { ChatbotListHeader } from './ChatbotListHeader'
import { useDeleteInputSettingsMutation, useGetInputSettingsList } from 'contexts/chatbot/inputSettingsContext'
import ChatbotList from './ChatbotList';
import { Stack } from '@mui/material';
import { RefreshProgress } from 'components/RefreshProgress';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { IInputSettingsReadModel } from 'services/chatbot/types';
import { Drawer, Modal } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { ApiResponseError, handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { GenerateLink } from './GenerateLink/GenerateLink';
import { onCopyText } from 'helpers/methods/OnCopyText';
import { FilterProvider } from 'contexts/filterContext';
import { useFilterValues } from 'contexts/filterValuesContext';

export const ChatbotListContainer = () => {
  const {filterValues} = useFilterValues();
  const [toast, setToast] = useState<ToastType>(toastState);

  const [action, setAction] = useState<{ method: string, data: IInputSettingsReadModel } | undefined>(undefined)
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const onSuccess = (action: string) => {
    const options: { [type: string]: string } = {
      "delete": "Sucesso ao excluir o registro"
    }
    showSuccessToast(options[action], "", setToast);
    onClose();
    refetch();
  }

  const copyIdentificationCode = (text: string) => {
    onCopyText({
      value: text,
      showErrorToast: () => setToast({ 
        open: true, 
        severity: 'error', 
        title: 'Erro ao copiar o código.' 
      }),
      showSuccessToast: () => setToast({ 
        open: true, 
        severity: 'success', 
        title: 'Sucesso ao copiar o código de identificação' 
      })
    }); 
  }; 

  const onError = (error: ApiResponseError) => handleOnError(error, setToast)

  const { data, refetch, isRefetching, isLoading } = useGetInputSettingsList({
    page, 
    size: rowsPerPage
  });

  const { mutateAsync: deleteMutateAsync } = useDeleteInputSettingsMutation(() => onSuccess('delete'), onError);

  const onClose = () => setAction(undefined)

  const onChangePage = (page: number) => setPage(page);

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  const onDelete = async (id: string) => {
    await deleteMutateAsync(id);
  }

  const isLoadingFromSkeleton: boolean = (
    Object.values(filterValues).length === 0 &&
    !!isLoading && 
    !isRefetching
  ); 

  return (
    <div>
      <Toast toast={toast} setToast={setToast} />
      <ChatbotListHeader refetch={refetch} />
      <RefreshProgress refreshing={isRefetching} />
      <ListSkeleton isLoading={isLoadingFromSkeleton}>
        <FilterProvider
          availableFilters={{
            invoiceValue: { label: 'Valor', type: 'text' },
            botPhone: { label: 'Número de telefone', type: 'text' },
            productName: { label: 'Nome do produto', type: 'text' },
            personName: { label: 'Nome da pessoa', type: 'text' },
            baneficiaryName: { label: 'Nome do beneficiário', type: 'text' },
            serviceQueueName: { label: 'Nome da fila', type: 'text' }, 
            identificationCode: { label: 'Código de identificação', type: 'text' },
            productCategoryName: { label: 'Categoria do produto', type: 'text' }
          }}
        >
          <Stack mt={3}>
            <ChatbotList
              queryData={data}
              copyIdentificationCode={copyIdentificationCode}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={onChangeRowsPerPage}
              setPage={onChangePage}
              page={page}
              handleAction={(action, data) => {
                setAction({ method: action, data })
              }}
            />
          </Stack>
        </FilterProvider>
      </ListSkeleton>

      <Modal {...{
        open: action?.method === 'delete',
        align: 'left',
        title: 'Excluir',
        description: 'Tem certeza que deseja excluir esse registro?',
        children: (
          <Stack mt={2}>
            <GenericActionHandler
              handleSubmit={() => onDelete(action?.data?.id!)}
              onClose={onClose}
              isModal
            />
          </Stack>
        )
      }} />

      <Drawer {...{
        anchor: 'right',
        open: action?.method === 'waQRCode',
        title: "Entre em contato via WhatsApp",
        onClose: onClose,
        children: <GenerateLink onClose={onClose} inputSetting={action?.data as IInputSettingsReadModel} setToast={setToast} />
      }} />
    </div>
  )
}
