import { Stack } from '@mui/material';
import { AddIcon, Typography } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type CreditNotesHeaderProps = {
    generateBatchAssignment: () => void
    enableAction: boolean
}

const theme = activeTheme();

export const CreditNotesHeader = ({ generateBatchAssignment, enableAction}: CreditNotesHeaderProps) => {

    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack>
                <Typography fontSize='22px' fontWeight='bold'>Lista de operações</Typography>
                <Typography fontSize='15px' mb={3} fontWeight='400'>Confira as operações baseada nos filtros definidos anteriormente.</Typography>
            </Stack>
            <Actions
                numberOfButtons={3}
                buttonsActionsList={[
                    {
                        enable: true,
                        label: "Gerar cessão",
                        action: generateBatchAssignment,
                        disabled: !enableAction,
                        icon: <AddIcon htmlColor={!enableAction ? theme.palette.grey['400'] : theme.palette.common.black} sx={iconSx} />
                    }, 
                ]}
            />
        </Stack>
    )
}
