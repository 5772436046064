/* eslint-disable sonarjs/no-duplicate-string */

import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    field: 'dataDaOcorrencia',
    headerName: 'Data da Ocorrência',
    hideSortIcons: true,
    width: 250,
    editable: false,
    renderCell: (cellValues) => {
      return (
        <div
          style={{
            textAlign: 'left',
            marginLeft: '-8px',
          }}
        >
          {cellValues.value}
        </div>
      );
    },
  },
  {
    field: 'lancamento',
    headerName: 'Lançamento',
    hideSortIcons: true,
    width: 180,
    editable: false,
    renderCell: (cellValues) => {
      return (
        <div
          style={{
            textAlign: 'left',
            marginLeft: '-8px',
          }}
        >
          {cellValues.value}
        </div>
      );
    },
  },
  {
    field: 'descricao',
    headerName: 'Descrição',
    hideSortIcons: true,
    width: 230,
    editable: false,
    renderCell: (cellValues) => {
      return (
        <div
          style={{
            textAlign: 'left',
            marginLeft: '-8px',
          }}
        >
          {cellValues.value}
        </div>
      );
    },
  },
  {
    field: 'valor',
    headerName: 'Valor',
    width: 330,
    editable: false,
    hideSortIcons: true,
    renderCell: (cellValues) => {
      const colorValue = cellValues.value.slice(0, 1) === '-' ? '#F44336' : '#4CAF50';
      return (
        <div
          style={{
            color: colorValue,
            textAlign: 'left',
            marginLeft: '-8px',
          }}
        >
          {cellValues.value}
        </div>
      );
    },
  },
  {
    field: 'dia',
    headerName: '',
    hideSortIcons: true,
    width: 250,
    editable: false,
  },
];

export const rows = [
  {
    id: 1,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '-R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 2,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '-R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 3,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '-R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 4,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '-R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 5,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 6,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 7,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 8,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 9,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 10,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 11,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 12,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
  {
    id: 13,
    dataDaOcorrencia: '01/01/2021',
    lancamento: 'Lorem impsum',
    descricao: 'Aprovado',
    valor: '+R$ 1.000,00',
    dia: 'Saldo do dia: R$ 4.451,02',
  },
];

export const selectProps = {
  label: 'Filtrar por',
  items: ['Últimos 15 dias', 'Últimos 25 dias', 'Últimos 30 dias'],
};
