import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import { phoneNumberMask } from 'utils/constants';

export default function ContactInformation() {
    const { watch } = useFormContext();
    const name = watch('name');
    const registrationNumber = watch('registrationNumber');

    return (
        <React.Fragment>
            <CardInfo {...{ name, registrationNumber }} />
            <Typography variant="lg" color="common.black">
                Informações para contato
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <TextFormField
                    name="phone"
                    variant="outlined"
                    fullWidth
                    label="Telefone celular"
                    placeholder="(00) 0000-0000"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: phoneNumberMask },
                    }}
                />
                <TextFormField
                    name="phone2"
                    variant="outlined"
                    fullWidth
                    label="Telefone opcional"
                    placeholder="(00) 0000-0000"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: phoneNumberMask },
                    }}
                />
                <TextFormField name="phone2Extension" fullWidth label="Ramal" variant="outlined" />
            </Stack>
        </React.Fragment>
    );
}
