import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { currentDateWithSetHours } from 'pages/CreditNote/schema/BasicAmortization';
import { string, object, date, number } from 'yup';
import { evidenceType, fraudModalityList, relatedActivityList } from '../Options';
import { detailsSchema } from './DetailsSchema';
import { locationSchema } from './LocationSchema';

const genericMessage = 'Precisa ser preenchido.';

const createStringValidation = (
    list: Array<{ value: string }>,
    invalidMessage: string,
    requiredMessage: string
) => {
    return string()
        .oneOf(
            list.map((e) => e.value),
            invalidMessage
        )
        .typeError(invalidMessage)
        .required(` ${requiredMessage}: ${genericMessage}`);
};

export enum EFraudRecordFormStep {
    FraudRecordForm = 0,
    LocationForm = 1,
    DetailsForm = 2,
}

export function validationSchemaFraudRecordForm(step: EFraudRecordFormStep) {
    switch (step) {
        case EFraudRecordFormStep.FraudRecordForm:
            return object().shape({
                eventDateTime: date()
                    .typeError('data inválida.')
                    .max(new Date(), 'Data do evento não pode ser maior que a data atual.')
                    .required(`Data que ocorreu o indício de fraude: ${genericMessage}`),
                evidenceType: createStringValidation(
                    evidenceType,
                    'Tipo de evidência é inválido.',
                    'Tipo de evidência'
                ),
                fraudModality: createStringValidation(
                    fraudModalityList,
                    'Tipo de fraude é inválido.',
                    'Tipo de fraude'
                ),
                relatedActivity: createStringValidation(
                    relatedActivityList,
                    'Atividade relacionada é inválida.',
                    'Atividade relacionada'
                ),
                description: string().typeError('Motivo da fraude é inválido.').nullable(),
                operationType: string()
                    .oneOf(['Inclusion', 'Edition', 'Exclusion'])
                    .default('Inclusion')
                    .typeError('Tipo de operação é inválido.'),
                transactionValue: number().typeError('Valor da transação é inválido.').nullable(),
            });
        case EFraudRecordFormStep.LocationForm:
            return object().shape({
                location: locationSchema,
            });

        case EFraudRecordFormStep.DetailsForm:
            return object().shape({ details: detailsSchema });
        default:
            return object().shape({});
    }
}

export function defaultValuesFraudRecordForm(step: EFraudRecordFormStep) {
    const defaults: Record<EFraudRecordFormStep, any> = {
        [EFraudRecordFormStep.FraudRecordForm]: {
            eventDateTime: toIsoStringWithTimezone(new Date(currentDateWithSetHours)),
            evidenceType: null,
            fraudModality: null,
            relatedActivity: null,
            description: null,
            operationType: 'Inclusion',
            transactionValue: 0,
        },
        [EFraudRecordFormStep.LocationForm]: {
            location: {
                channel: null,
                local: null,
                ipAddress: null,
                device: null,
            },
        },
        [EFraudRecordFormStep.DetailsForm]: { details: {} },
    };

    return defaults[step] ?? {};
}
