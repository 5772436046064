import { Stack } from '@mui/material';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { useEnumContext } from 'contexts/enumContext';
import { isEmpty } from 'lodash';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';

interface AddSignatureFormProps {
    index: number;
    persons: any[];
}
export const metadataKey = 'meta.currentAccordion';

const AddSignatureForm: React.FC<AddSignatureFormProps> = ({ index, persons }) => {
    const { data: typeOfRelationship } = useEnumContext({
        enumName: 'TypeOfRelationship',
        size: 50,
    });
    const { watch } = useFormContext();

    const personIdDisplay = watch(`signatories.${index}.displayName`);

    const optionsFiltered = persons.filter((item) => {
        const personId = item?.personId;
        return !isEmpty(personId) && personId !== undefined && personId != null;
    });

    //ToDo: Refatorar esse trecho de código pois em outros lugares ele se repete - (listInvolvedOptions)
    const signersList: SelectOption[] = optionsFiltered?.map((item) => {
        const personIdDisplay = item?.personIdDisplay ?? '';
        const typeOfRelationshipDisplay = item?.typeOfRelationshipDisplay ?? '';
        const fundSignerTypeDisplay = item?.fundSignerTypeDisplay ?? '';
        const relationshipOrSignerType = typeOfRelationshipDisplay || fundSignerTypeDisplay;

        const label = `${personIdDisplay}${relationshipOrSignerType ? ` - (${relationshipOrSignerType})` : ''}`;

        return {
            label: label,
            value: item?.personId,
        };
    });

    const disabled = watch(`signatories.${index}.disabled`);

    return (
        <CustomAccordionContainer
            index={index}
            description={personIdDisplay ?? `Signatário #${index}`}
            disabled={disabled}
        >
            <Stack direction="column" spacing={2}>
                <AutocompleteField
                    name={`signatories.${index}.personId`}
                    label="Quem deve receber o documento para assinatura?"
                    displayName={`signatories.${index}.displayName`}
                    required
                    loading={false}
                    listOptions={signersList}
                />

                <SelectFormField
                    name={`signatories.${index}.typeOfRelationship`}
                    label="Tipo de relação"
                    variant="outlined"
                    options={sortToSelectOptionList(typeOfRelationship ?? [])}
                    fullWidth
                    required
                />
                <SelectFormField
                    label="Forma de envio para assinatura"
                    name={`signatories.${index}.signatureType`}
                    fullWidth
                    required
                    options={[
                        { label: 'Email', value: 'email' },
                        { label: 'Sms', value: 'sms' },
                        { label: 'Whatsapp', value: 'whatsapp' },
                    ]}
                />
            </Stack>
        </CustomAccordionContainer>
    );
};

export default AddSignatureForm;
