import { Grid, Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { SelectFormField, SelectOption } from 'components/Forms/FormFields'

type DeleteCheckListFormProps = {
  onClose: () => void;
  options: SelectOption[]
}

export const DeleteCheckListForm = ({onClose, options}: DeleteCheckListFormProps) => {
  return (
    <Stack spacing={2}>
      <Grid pt={2}>
        <SelectFormField
          label='Selecione'
          name='complianceChecklist'
          required
          multiple
          options={options}
        />
      </Grid>
      <Stack direction='row' alignItems='center' justifyContent='right' spacing={2}>
        <Button variant='outlined' onClick={onClose}>
          Cancelar
        </Button>
        <Button variant='contained' type='submit'>
          Remover
        </Button>
      </Stack>
    </Stack>
  )
}