export const barCodeAndLineDigitableMask = [
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'.',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'.',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'.',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	' ',
	/\d/,
	' ',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	/\d/
]