import React, { Fragment } from 'react'
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip'
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions'

export const BillingAccountActions = () => {
    return (
        <React.Fragment>
            <ActionsPermissions
                PermissionRootTenancy={(
                    <Fragment>
                        <CheckboxWithTooltip
                            checkboxFormField={{ label: 'Aprovar Liquidação', name: 'actions.LiquidationApproval' }}
                            helpMessage="Permite o usuário liquidar e liquidar STR no menu de cobrança nas informações de boleto."
                        />
                        <CheckboxWithTooltip
                            checkboxFormField={{ label: 'Atualizar custos', name: 'actions.UpdateBillingCosts' }}
                            helpMessage="Permite o usuário atualizar os custos da carteira."
                        />
                    </Fragment>
                )}
            />
        </React.Fragment>
    )
}
