import moment from "moment";
import { InferType, date, object, string } from "yup";

export function validationCreateTransferSchema() {
  return object().shape({
    bankAccountBeneficiaryId: string().typeError("Selecione um favorecido existente ou adicione manualmente").required("Selecione um favorecido existente ou adicione manualmente"),
    transferDate: date().typeError("Data inválida.").required("Data da transferência: Precisa ser preenchido."),
    amount: string().typeError("Valor da transferência precisa ser preenchido").required("Valor da transferência: precisa ser preenchido"),
    paymentPurpose: string().typeError("Selecione o tipo de pagamento").required("Esse campo é obrigatório."),
    sessionId: string().nullable(),
    code: string().nullable()
  });
}

const inferedSchema = validationCreateTransferSchema()
export type CreatePixType = InferType<typeof inferedSchema>;

export const defaultValuesCreateTransfer = {
  bankAccountBeneficiaryId: null, 
  paymentPurpose: null,
  bankAccountBeneficiaryIdDisplay: null, 
  operationTypeValue: "Pix",
  transferDateSelected: parseInt(moment().format('HH')) < 17 ? "today" : "otherDay",
  amount: null,
  transferDate: parseInt(moment().format('HH')) < 17 ? moment().format('yyyy-MM-DD') : moment().add(1, 'day').format('yyyy-MM-DD'),
  sessionId: "",
  code: ""
};