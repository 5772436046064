import { object, string } from "yup";

export function validationInstallmentCreateFormSchema() {
    return object().shape({
        walletCode: string()
            .typeError('Selecione uma carteira.')
            .required('Selecione uma carteira.'),
        personId: string().typeError('Selecione um pagador.').required('Selecione um pagador.'),
        payer: object().shape({
            name: string().nullable().notRequired(),
            personTypeValue: string().nullable().notRequired(),
            email: string().nullable().notRequired(),
            registrationNumber: string().nullable().notRequired(),
            zipCode: string().nullable().notRequired(),
            addressName: string().nullable().notRequired(),
            addressNumber: string().nullable().notRequired()
        }),
        billingSettingId: string().nullable().notRequired(),
        communicationSettingId: string().nullable().notRequired() 
    });
}

export const defaultValuesInstallmentCreateForm = {
    walletCode: null,
    personId: null,
    payer: null,
    billingSettingId: null,
    communicationSettingId: null,
};