import { Typography } from '@uy3/web-components';
import { ListNamesType, RenderAvatarsName } from 'components/RenderAvatarsName/RenderAvatarsName';
import Spinner from 'components/RiskScore/DatasetsStatusColumn/Spinner';
import { useGetSignatureAsync } from 'contexts/signature/signatureContext';
import { Signer } from 'services/signature/signature.types';

type SignersLengthType = {
    signatureWorkFlowId: string;
};

export default function SignersLength({ signatureWorkFlowId }: SignersLengthType) {
    const { signatureData, isLoading, signatureFetch } = useGetSignatureAsync(signatureWorkFlowId);
    const hasData = signatureData !== undefined || Object?.values(signatureData ?? {})?.length > 0;

    const list = signatureData?.signers ?? [];
    const options: ListNamesType[] = list?.map((item: Signer) => {
        return {
            id: item.id,
            name: item.displayName,
            tenant: item.tenantDisplay,
        };
    });

    if ((isLoading && !!signatureWorkFlowId) || signatureFetch) return <Spinner />;

    return (
        <>
            {!!signatureWorkFlowId && hasData ? (
                <RenderAvatarsName list={options} title="Signatários" />
            ) : (
                <Typography ml={2}>-</Typography>
            )}
        </>
    );
}
