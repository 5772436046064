/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { Box, Grid, Stack, useMediaQuery } from '@mui/material';
import { activeTheme } from 'services/theme';
import { RefreshOutlined } from '@mui/icons-material';
import { Button, Modal, Typography } from '@uy3/web-components';
import { useAppConfig } from 'contexts/appConfig';
import { RefreshProgress } from 'components/RefreshProgress';
import InfoMessage from 'components/InfoMessage/InfoMessage';

const theme = activeTheme();

interface IDataSetMasterProps {
    dataSet: any;
    handleUpdateData: (dataset: string) => void;
    isLoading?: boolean;
    children?: React.ReactNode;
    disabled?: boolean;
    disableRequestDataSet?: boolean;
}

function DatasetWrapper({
    dataSet,
    handleUpdateData,
    isLoading = false,
    children,
    disabled,
    disableRequestDataSet,
}: IDataSetMasterProps) {
    const [modal, setModal] = useState<boolean>(false);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { appConfig } = useAppConfig();

    const closeModal = () => setModal(false);
    const openModal = () => setModal(true);

    const useScan3 = appConfig.USE_SCAN3;
    const isSeletorTenant = appConfig.SELETOR_TENANTS;

    return (
        <>
            <RefreshProgress refreshing={isLoading} />
            {!isEmpty(dataSet?.result) ? (
                <>
                    <Modal
                        title="Valores originais"
                        open={modal}
                        onClose={closeModal}
                        children={
                            <pre style={contentStyle}>
                                {JSON.stringify(dataSet?.result, null, 2)}
                            </pre>
                        }
                        sizeModal="large"
                    />
                    {children && children}
                    <Box p={2}>
                        <Grid mb={1}>
                            <Typography variant="h5" color="neutral.medium" fontWeight={500}>
                                Gerado em: &nbsp;
                                {new Date(
                                    dataSet?.updatedAt || dataSet?.createdAt
                                ).toLocaleDateString('pt-BR')}
                            </Typography>
                        </Grid>
                        <Stack
                            container
                            spacing={{ xs: 2 }}
                            component={Grid}
                            direction={isMobile ? 'column' : 'row'}
                            justifyContent="left"
                        >
                            {
                                <Grid xs="auto" sm="auto" md="auto">
                                    <Button
                                        name="btn-update-dataset"
                                        variant="contained"
                                        size="medium"
                                        disabled={disabled || isLoading || disableRequestDataSet}
                                        //@ts-ignore
                                        onClick={handleUpdateData}
                                        startIcon={<RefreshOutlined />}
                                    >
                                        Atualizar
                                    </Button>
                                </Grid>
                            }
                            {!!isSeletorTenant && !useScan3 && (
                                <Grid xs="auto" sm="auto" md="auto">
                                    <Button
                                        name="btn-original-dataset"
                                        variant="contained"
                                        size="medium"
                                        onClick={openModal}
                                        disabled={disabled || isLoading}
                                    >
                                        Ver dados originais
                                    </Button>
                                </Grid>
                            )}
                            <Grid />
                        </Stack>
                    </Box>
                </>
            ) : (
                <Grid item xs={6}>
                    {disableRequestDataSet && (
                        <div style={{ margin: '-10px 0px 20px 0px' }}>
                            <InfoMessage message="Você não tem permissão para solicitar esse recurso" />
                        </div>
                    )}
                    <Button
                        name="btn-get-dataset"
                        variant="contained"
                        //@ts-ignore
                        onClick={handleUpdateData}
                        size="medium"
                        disabled={isLoading || disableRequestDataSet}
                    >
                        Obter dados
                    </Button>
                </Grid>
            )}
        </>
    );
}

export default DatasetWrapper;

export const contentStyle: React.CSSProperties = {
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    maxWidth: '100%',
};
