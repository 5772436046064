import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ApproveIcon, PersonIcon, RejectIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

interface OperatorHeaderProps {
    isFetching: boolean;
    operatorListRefetch: () => void;
    setStepOperator: (step: string) => void;
    hasItemsSelectedIsPending: boolean
}

const theme = activeTheme();
const OperatorHeader: React.FC<OperatorHeaderProps> = ({
    isFetching,
    operatorListRefetch,
    setStepOperator,
    hasItemsSelectedIsPending
}) => {
    const { bankAccountId } = useParams();
    const { hasPermission } = useUserPermissionData();
    const queryClient = useQueryClient();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const hasPermissionFromReadPerson = hasPermission('Person', 'Read'); 

    async function operatorRefetch() {
        operatorListRefetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction="row" justifyContent="space-between" mb={3} mt={2} alignItems="center">
            <Typography variant="xxl" color="neutral.dark" lineHeight="38.4px">
                Operadores
            </Typography>

            <Actions
                numberOfButtons={4}
                buttonsActionsList={[
                    {
                        enable: hasItemsSelectedIsPending,
                        label: "Aprovar",
                        action: () => setStepOperator('confirmMfaApprove'),
                        icon: <ApproveIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: hasItemsSelectedIsPending,
                        label: "Reprovar",
                        action: () => setStepOperator('confirmMfaReject'),
                        icon: <RejectIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: true,
                        label: "Atualizar",
                        action: operatorRefetch,
                        icon: <UpdateIcon sx={iconSx} htmlColor={color}/>,
                        disabled: isFetching
                    },
                    {
                        enable: hasPermissionFromReadPerson,
                        label: "Adicionar operador",
                        action: () => setStepOperator('bankAccountOperatorForm'),
                        icon: <PersonIcon sx={iconSx} htmlColor={color}/>,
                        disabled: isFetching
                    },
                ]}
            />
        </Stack>
    );
};
export default OperatorHeader;
