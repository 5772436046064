import { handleNumericNaN } from 'helpers';
import { array, boolean, number, object, string } from 'yup';

const Billing = object().shape({
    documentNumber: string().required(`Número do documento: Precisa ser preenchido`).typeError(`Número do documento: Precisa ser preenchido`),
    dueDate: string().required(`Data de vencimento: Precisa ser preenchido`).typeError(`Data de vencimento: Precisa ser preenchido`),
    amount: number()
        .required(`Valor da cobrança: Precisa ser preenchido`)
        .typeError('Valor precisa ser um número.')
        .moreThan(-1, 'Valor inválido.')
        .min(0.01, 'Valor principal: Precisa ter um valor maior que 0 (zero).'),
    isDiscount: boolean().nullable(),
    discount: string().when("isDiscount", {
        is: true,
        then: string().typeError("Disconto precisa ser preenchido").required("Disconto precisa ser preenchido"),
        otherwise: string().nullable()
    }),
    discountLimitDate: string().when("isDiscount", {
        is: true,
        then: string().nullable().notRequired(),
        otherwise: string().nullable()
    })
});


export function validationSchemaBillingBillingInstallmentCreateForm() {
    return object().shape({
        numberInstallment: number()
            .typeError('Quantidade de parcelas: precisa ser preenchido')
            .required('Quantidade de parcelas: precisa ser preenchido')
            .min(1, "É necessário no mínimo 1 parcela"),
        regiserInstallmentDue: boolean().nullable(),
        billings: array().of(Billing).nullable(),
        period: string().typeError("Período de pagamento: precisa ser preenchido").required("Período de pagamento: precisa ser preenchido"),
        documentNumber: string().typeError("Número do documento: precisa ser preenchido").required("Número do documento: precisa ser preenchido"),
        dueDate: string().typeError("Data de venccimento da 1º parcela: precisa ser preenchido").required("Data de venccimento da 1º parcela: precisa ser preenchido"),
        amount: number()
            .typeError('Valor da cobrança: precisa ser preenchido.')
            .required('Valor da cobrança: precisa ser preenchido.')
            .transform(handleNumericNaN)
            .min(0.01, 'Valor da cobrança: Precisa ter um valor maior que 0.'),
    })
}

export const defaultValuesBillingInstallmentCreateForm = {
    billings: [],
    numberInstallment: null,
    regiserInstallmentDue: false,
    dueDate: null,
    period: null,
    documentNumber: null,
    amount: 0
};