import { CardData } from '@uy3/web-components';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { isEmpty } from 'lodash';
import moment from 'moment';

type BankSlipDetail = {
    id: string;
    title: string | JSX.Element;
    value: string;
};

type BankSlipDetailsProps = {
    bankSlipData: any;
    showPaymentValueField: boolean;
};

export const BankSlipDetails = ({ bankSlipData, showPaymentValueField }: BankSlipDetailsProps) => {
    const totalValue = bankSlipData?.totalValue;
    const detailsBankSlip: BankSlipDetail[] = [
        {
            id: '1',
            title: <strong>Data de vencimento</strong>,
            value: moment(bankSlipData?.dueDate).format('DD/MM/yyyy') ?? 'N/D',
        },
        {
            id: '2',
            title: <strong>Valor nominal</strong>,
            value: formatCurrencyInCents(bankSlipData?.nominalValue) ?? 'N/D',
        },
        {
            id: '3',
            title: <strong>Encargos/juros</strong>,
            value: formatCurrencyInCents(bankSlipData?.fee) ?? 'N/D',
        },
        {
            id: '4',
            title: <strong>Descontos</strong>,
            value: formatCurrencyInCents(bankSlipData?.discount) ?? 'N/D',
        },
        {
            id: '5',
            title: <strong>Multa</strong>,
            value: formatCurrencyInCents(bankSlipData?.trafficTicket) ?? 'N/D',
        },
        {
            id: '6',
            title: <strong>Valor total</strong>,
            value: isEmpty(totalValue) ? formatCurrencyInCents(totalValue) : 'N/D',
        },
    ];

    if (showPaymentValueField) {
        detailsBankSlip.push(
            {
                id: '7',
                title: 'Valor mínimo de pagamento',
                value: formatCurrencyInCents(bankSlipData?.minimumValue) ?? 'N/D',
            },
            {
                id: '8',
                title: 'Valor máximo de pagamento',
                value: formatCurrencyInCents(bankSlipData?.maximumValue) ?? 'N/D',
            }
        );
    }

    return <CardData listItem={detailsBankSlip} />;
};
