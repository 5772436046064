import { Grid, Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { TextFormField } from 'components/Forms/FormFields'
import React from 'react'

const ResetPasswordForm = () => {
  return (
    <Stack spacing={2}>
        <Grid sx={{m: "5px 0"}}>
            <TextFormField
                name='password'
                variant='outlined'
                label="Senha temporária"
                fullWidth
            />
        </Grid>
        <Button type='submit' variant='contained'>
            Enviar
        </Button>
    </Stack>
  )
}

export default ResetPasswordForm