import { FormProvider } from 'contexts/formContext'
import React, { useState } from 'react'
import { validationSchemaCalculateLiquidation, defaultValuesCalculateLiquidation } from './CalculateLiquidationSchema'
import { Stack } from '@mui/material'
import { CalculationLiquidationHeader } from './CalculationLiquidationHeader'
import { GenerateRemessaLiquidacaoOpForm } from '../GenerateFileLiquidation/Forms/RemessaLiquidacaoForOpForm/RemessaLiquidacaoForOpForm'
import { useIdentity } from 'contexts/identityContext'
import { ApiResponseError, ToastType, handleOnError, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext'
import { IRemessaLiquidacaoForOp, IResponseForOp } from 'services/remessasVortx/remessasVortx.type'
import { getRemessaLiquidacaoForOp } from 'services/remessasVortx/remessasVortx'
import { FieldValues } from 'react-hook-form'
import Toast from 'components/Toast/Toast'
import { ResponseForOp } from './ResponseForOp'
import { RefreshProgress } from 'components/RefreshProgress'

export const CalculateLiquidationContainer = () => {
    const { token } = useIdentity();
    const { endRequest, startRequest, submitting: isLoading } = useApiRequest();
    const [queryResponse, setQueryResponse] = useState<IResponseForOp | null>(null);

    const [toast, setToast] = useState<ToastType>(toastState);

    const getLiquidacaiForop = async (values: IRemessaLiquidacaoForOp) => {
        startRequest();
        try {
            var { data } = await getRemessaLiquidacaoForOp(values, token!);
            const responseDate = data as IResponseForOp
            setQueryResponse(responseDate);
            showSuccessToast("Sucesso ao calcular a liquidação", "", setToast)
            endRequest(true)
        } catch (error) {
            endRequest(false)
            handleOnError(error as ApiResponseError, setToast)
        }
    }

    const handleSubmitForm = async (values: FieldValues) => {
        await getLiquidacaiForop({
            date: values?.date,
            liquidateByvalue: values?.liquidateByvalue,
            opId: values?.opId
        })
    }
    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />

            <RefreshProgress refreshing={isLoading} />

            <FormProvider
                defaultValues={defaultValuesCalculateLiquidation}
                validationSchema={validationSchemaCalculateLiquidation()}
                onSubmit={handleSubmitForm}
            >
                <React.Fragment>
                    <CalculationLiquidationHeader isLoading={isLoading} />
                    <GenerateRemessaLiquidacaoOpForm showCheckbox={false} />
                </React.Fragment>
            </FormProvider>


            {queryResponse !== null && (
                <Stack spacing={2} marginTop={4}>
                    <ResponseForOp response={queryResponse} />

                </Stack>
            )}

        </Stack>
    )
}
