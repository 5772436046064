import { CreditProductReadModel } from 'services/creditProduct/types/creditProductReadModel';

export function isZetraProduct(product: CreditProductReadModel | undefined): boolean {
    const instrumentType = product?.instrumentType?.toLowerCase() ?? '';
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice?.toLowerCase();
    const manualWarranty = product?.manualWarranty;

    const isPublicPayrollLoan = instrumentType === 'publicpayrollloan';
    const isArmy = warrantyRegistrationOffice === 'army'; // Exercito
    return isPublicPayrollLoan && isArmy && !manualWarranty;
}