/* eslint-disable array-callback-return */
import { FormProvider } from 'contexts/formContext';
import {
  approveBankAccountById,
  disapprovedBankAccountById,
} from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { useIdentity } from 'contexts/identityContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useState } from 'react';
import {
  defaultValuesApproveFormSchema,
  defaultValuesDisapproveFormSchema,
  validationApproveDisapproveFormSchemaSchema,
  validationApproveSchema,
} from 'pages/BankAccount/BankAccountRequest/BankAccountRequestList/ApproveAndDisapproveForm/ApproveAndDisapproveFormSchema';
import { ApprovalsFull } from 'services/approvals/approvals';
import { ApproveOrDisapproveForm } from './ApproveOrDisapproveForm';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { DisapprovedBankAccountType } from 'services/accounts/bankAccountRequest';

type ApproveOrDisapproveFormContainerProps = {
  approveOrDesapprove: string;
  bankAccountrowSelected: [];
  onClose: () => void;
  refetch: () => void;
  notIsNewBankAccount?: boolean;
  bankAccountId?: string;
};

const ApproveOrDisapproveFormContainer = ({
  approveOrDesapprove,
  bankAccountrowSelected,
  onClose,
  refetch,
  notIsNewBankAccount,
  bankAccountId,
}: ApproveOrDisapproveFormContainerProps) => {
  const [toast, setToast] = useState<ToastType>(toastState);
  const { token } = useIdentity();
  const { endRequest, startRequest } = useApiRequest();

  const approveBankAccountRequest = (message: string, id?: string) => {
    startRequest();
    return approveBankAccountById(bankAccountId! ?? id!, message, token!)
      .then(() => {
        endRequest(true);
        const title = 'Sucesso ao aprovar a solicitação';
        const description = undefined;
        showSuccessToast(title, description, setToast);

        setInterval(() => onClose(), 2000);
        refetch();
      })
      .catch((response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);

        endRequest(false);
      });
  };

  const disapproveBankAccountRequest = (payload: DisapprovedBankAccountType, id?: string) => {
    startRequest();
    disapprovedBankAccountById(bankAccountId! ?? id!, payload as any, token!)
      .then(() => {
        endRequest(true);
        const title = 'Sucesso ao reprovar a solicitação de conta!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
        
        refetch();
        setInterval(() => onClose(), 2000);
      })
      .catch((error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        endRequest(false);
      });
  };

  const sendApproveAndDisapprove = (values: DisapprovedBankAccountType) => {
    if (approveOrDesapprove === 'approve') {
      if (notIsNewBankAccount) return approveBankAccountRequest(values.message);
      return bankAccountrowSelected?.map(({ bankAccountRequestId }: ApprovalsFull) =>
        approveBankAccountRequest(values.message, bankAccountRequestId)
      );
    }
    if (approveOrDesapprove === 'disapprove') {
      let payload = {
        blockType: values?.blockType,
        blockUntil: values?.blockUntil,
        message: values?.message,
        reasonDisapproval: values?.reasonDisapproval
      };

      if (notIsNewBankAccount) return disapproveBankAccountRequest(payload);

      return bankAccountrowSelected?.map(({ bankAccountRequestId }: ApprovalsFull) =>
        disapproveBankAccountRequest(payload, bankAccountRequestId)
      );
    }
  };

  const schemaDisapprove = validationApproveDisapproveFormSchemaSchema(bankAccountId);
  const defaultValuesDisapprove = defaultValuesDisapproveFormSchema;
  const schemaApprove = validationApproveSchema();
  const defaultValuesApprove = defaultValuesApproveFormSchema;

  return (
    <>
      <Toast setToast={setToast} toast={toast} />
      <FormProvider
        defaultValues={
          approveOrDesapprove === 'approve'
            ? defaultValuesApprove
            : defaultValuesDisapprove
        }
        validationSchema={
          approveOrDesapprove === 'approve' ? schemaApprove : schemaDisapprove
        }
        onSubmit={sendApproveAndDisapprove}
      >
        <ApproveOrDisapproveForm approveOrDesapprove={approveOrDesapprove} />
      </FormProvider>
    </>
  );
};

export default ApproveOrDisapproveFormContainer;
