import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const MailCancelIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M21.4697 6.53033C21.7626 6.82322 22.2374 6.82322 22.5303 6.53033C22.8232 6.23743 22.8232 5.76256 22.5303 5.46967L21.4697 6.53033ZM19.5303 2.46967C19.2374 2.17678 18.7626 2.17678 18.4697 2.46967C18.1768 2.76256 18.1768 3.23744 18.4697 3.53033L19.5303 2.46967ZM22.5303 3.53034C22.8232 3.23744 22.8232 2.76257 22.5303 2.46968C22.2374 2.17678 21.7626 2.17678 21.4697 2.46968L22.5303 3.53034ZM18.4697 5.46967C18.1768 5.76257 18.1768 6.23744 18.4697 6.53033C18.7626 6.82323 19.2374 6.82323 19.5303 6.53033L18.4697 5.46967ZM6.45 11.4C6.11863 11.1515 5.64853 11.2186 5.4 11.55C5.15147 11.8814 5.21863 12.3515 5.55 12.6L6.45 11.4ZM8.2 13.65L8.65 13.05L8.2 13.65ZM11.8 13.65L12.25 14.25L11.8 13.65ZM14.45 12.6C14.7814 12.3515 14.8485 11.8814 14.6 11.55C14.3515 11.2186 13.8814 11.1515 13.55 11.4L14.45 12.6ZM22.5303 5.46967L21.0303 3.96967L19.9697 5.03033L21.4697 6.53033L22.5303 5.46967ZM21.0303 3.96967L19.5303 2.46967L18.4697 3.53033L19.9697 5.03033L21.0303 3.96967ZM21.4697 2.46968L19.9697 3.96967L21.0303 5.03033L22.5303 3.53034L21.4697 2.46968ZM19.9697 3.96967L18.4697 5.46967L19.5303 6.53033L21.0303 5.03033L19.9697 3.96967ZM5 7.75H15V6.25H5V7.75ZM17.25 10V18H18.75V10H17.25ZM15 20.25H5V21.75H15V20.25ZM2.75 18V10H1.25V18H2.75ZM5 20.25C3.75736 20.25 2.75 19.2426 2.75 18H1.25C1.25 20.0711 2.92893 21.75 5 21.75V20.25ZM17.25 18C17.25 19.2426 16.2426 20.25 15 20.25V21.75C17.0711 21.75 18.75 20.0711 18.75 18H17.25ZM15 7.75C16.2426 7.75 17.25 8.75736 17.25 10H18.75C18.75 7.92893 17.0711 6.25 15 6.25V7.75ZM5 6.25C2.92893 6.25 1.25 7.92893 1.25 10H2.75C2.75 8.75736 3.75736 7.75 5 7.75V6.25ZM5.55 12.6L7.75 14.25L8.65 13.05L6.45 11.4L5.55 12.6ZM12.25 14.25L14.45 12.6L13.55 11.4L11.35 13.05L12.25 14.25ZM7.75 14.25C9.08333 15.25 10.9167 15.25 12.25 14.25L11.35 13.05C10.55 13.65 9.45 13.65 8.65 13.05L7.75 14.25Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

MailCancelIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
