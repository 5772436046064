import { useFormContext } from "contexts/formContext";
import { Grid, Stack } from "@mui/material";
import CurrencyFormField from "components/Forms/FormFields/CurrencyFormField/CurrencyFormField";
import { Button } from "@uy3/web-components";
import { DatePickerFormField, SelectFormField, TextFormField } from "components/Forms/FormFields";

type ApproveCreditRequestFormProps = {
    closeModal: () => void;
};

function ApproveCreditRequestForm({ closeModal }: ApproveCreditRequestFormProps) {
    const { watch } = useFormContext();

    return (
        <>
            <Stack component={Grid} container spacing={2} mt={1}>
                <Grid item xs={12}>
                    <SelectFormField
                        name="action"
                        label="Resultado"
                        options={[{ label: 'Reprovado', value: "NOK" }, { label: 'OK', value: "OK" }]}
                    />
                </Grid>
                {watch("action") === "OK" && <Grid item xs={12}>
                    <CurrencyFormField
                        label="Limite de Crédito"
                        fullWidth
                        name="creditLimit"
                        variant="outlined"
                        required
                    />
                </Grid>}

                <Grid item xs={12}>
                    <DatePickerFormField
                        fullWidth
                        label="Prazo de validade"
                        name="validUntil"
                        required
                    />
                </Grid>

                {watch("action") === "NOK" &&
                    <Grid item xs={12} >
                        <TextFormField
                            required
                            fullWidth
                            label="Observações"
                            name="message"
                            variant="outlined"
                            margin="dense"
                            multiline
                            rows={3}
                        />
                    </Grid>}

                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={closeModal}
                        size="medium"
                        sx={{ mt: 1, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        sx={{ mt: 1 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </Stack>
        </>
    );

};

export default ApproveCreditRequestForm;