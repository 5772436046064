import { string, object, number, date } from 'yup';

export function validationSchemaCreditLimitForm() {
    return object().shape({
        productId: string().typeError('produto inválido.').nullable(),
        requestedLimit: number().typeError('Valor precisa ser um número.').min(0).nullable(),
    });
};

export const defaultValuesCreditLimitForm = {
    productId: null,
    requestedLimit: 0
};

export function validationSchemaApproveForm() {
    return object().shape({        
        action: string().required('Resultado: Precisa ser preenchido.').typeError('Resultado inválido.'),
        creditLimit: number().when('action', {
            is: (action: string) => action === 'OK',
            then: number().required('Limite de crédito: Precisa ser preenchido.').typeError('Limite de crédito inválido.'),
            otherwise: number().nullable()
        }),
        validUntil: date().typeError('Data inválida'),
        message: string().when('action', {
            is: (action: string) => action === 'NOK',
            then: string().required('Observações: Precisa ser preenchido.').typeError('Observações: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
};

export const defaultValuesApproveForm = {
    creditStatusRequestId: "",
    action: null,
    creditLimit: 0,
    validUntil: new Date().toDateString(),
    message: null
};
