import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const OfflineIcon: FunctionComponent<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <path
                d="M171.957 0H44.2978C38.4225 0 33.6595 4.76497 33.6595 10.6429V138.357C33.6595 144.235 38.4225 149 44.2978 149H171.957C177.833 149 182.596 144.235 182.596 138.357V10.6429C182.596 4.76497 177.833 0 171.957 0Z"
                fill="#E9D4C4"
            />
            <g filter="url(#filter0_d_929_394)">
                <path
                    d="M154.936 10.6428H11.3191C8.38146 10.6428 6 13.0253 6 15.9643V42.5714C6 45.5103 8.38146 47.8928 11.3191 47.8928H154.936C157.874 47.8928 160.255 45.5103 160.255 42.5714V15.9643C160.255 13.0253 157.874 10.6428 154.936 10.6428Z"
                    fill="white"
                />
            </g>
            <path
                d="M79.4043 19.1571H51.7447C49.9821 19.1571 48.5532 20.5866 48.5532 22.35C48.5532 24.1133 49.9821 25.5428 51.7447 25.5428H79.4043C81.1669 25.5428 82.5958 24.1133 82.5958 22.35C82.5958 20.5866 81.1669 19.1571 79.4043 19.1571Z"
                fill="#ECA973"
            />
            <path
                d="M98.5532 32.9928H51.7447C49.9821 32.9928 48.5532 34.4223 48.5532 36.1857C48.5532 37.949 49.9821 39.3785 51.7447 39.3785H98.5532C100.316 39.3785 101.745 37.949 101.745 36.1857C101.745 34.4223 100.316 32.9928 98.5532 32.9928Z"
                fill="#F18B3A"
            />
            <path
                d="M34.7234 13.8357H14.5107C11.573 13.8357 9.19153 16.2182 9.19153 19.1571V39.3786C9.19153 42.3175 11.573 44.7 14.5107 44.7H34.7234C37.6611 44.7 40.0426 42.3175 40.0426 39.3786V19.1571C40.0426 16.2182 37.6611 13.8357 34.7234 13.8357Z"
                fill="#D04D27"
            />
            <path
                d="M25 29H24.25C24.25 29.3228 24.4566 29.6094 24.7628 29.7115L25 29ZM25.75 24C25.75 23.5858 25.4142 23.25 25 23.25C24.5858 23.25 24.25 23.5858 24.25 24H25.75ZM27.7628 30.7115C28.1558 30.8425 28.5805 30.6301 28.7115 30.2372C28.8425 29.8442 28.6301 29.4195 28.2372 29.2885L27.7628 30.7115ZM25.75 29V24H24.25V29H25.75ZM24.7628 29.7115L27.7628 30.7115L28.2372 29.2885L25.2372 28.2885L24.7628 29.7115ZM34.25 29C34.25 34.1086 30.1086 38.25 25 38.25V39.75C30.9371 39.75 35.75 34.9371 35.75 29H34.25ZM25 38.25C19.8914 38.25 15.75 34.1086 15.75 29H14.25C14.25 34.9371 19.0629 39.75 25 39.75V38.25ZM15.75 29C15.75 23.8914 19.8914 19.75 25 19.75V18.25C19.0629 18.25 14.25 23.0629 14.25 29H15.75ZM25 19.75C30.1086 19.75 34.25 23.8914 34.25 29H35.75C35.75 23.0629 30.9371 18.25 25 18.25V19.75Z"
                fill="white"
            />
            <g filter="url(#filter1_d_929_394)">
                <path
                    d="M57.0638 56.4071H200.681C202.092 56.4071 203.444 56.9678 204.442 57.9657C205.44 58.9637 206 60.3172 206 61.7285V88.3357C206 89.747 205.44 91.1005 204.442 92.0985C203.444 93.0965 202.092 93.6571 200.681 93.6571H57.0638C55.6531 93.6571 54.3001 93.0965 53.3026 92.0985C52.305 91.1005 51.7446 89.747 51.7446 88.3357V61.7285C51.7446 60.3172 52.305 58.9637 53.3026 57.9657C54.3001 56.9678 55.6531 56.4071 57.0638 56.4071V56.4071Z"
                    fill="white"
                />
            </g>
            <path
                d="M125.149 64.9214H97.4893C95.7267 64.9214 94.2979 66.3509 94.2979 68.1142C94.2979 69.8776 95.7267 71.3071 97.4893 71.3071H125.149C126.912 71.3071 128.34 69.8776 128.34 68.1142C128.34 66.3509 126.912 64.9214 125.149 64.9214Z"
                fill="#ECA973"
            />
            <path
                d="M144.298 78.7571H97.4893C95.7267 78.7571 94.2979 80.1866 94.2979 81.9499C94.2979 83.7133 95.7267 85.1428 97.4893 85.1428H144.298C146.06 85.1428 147.489 83.7133 147.489 81.9499C147.489 80.1866 146.06 78.7571 144.298 78.7571Z"
                fill="#F18B3A"
            />
            <path
                d="M80.4681 59.6H60.2553C57.3176 59.6 54.9362 61.9825 54.9362 64.9214V85.1428C54.9362 88.0818 57.3176 90.4643 60.2553 90.4643H80.4681C83.4058 90.4643 85.7872 88.0818 85.7872 85.1428V64.9214C85.7872 61.9825 83.4058 59.6 80.4681 59.6Z"
                fill="#D04D27"
            />
            <path
                d="M70 75H69.25C69.25 75.3228 69.4566 75.6094 69.7628 75.7115L70 75ZM70.75 70C70.75 69.5858 70.4142 69.25 70 69.25C69.5858 69.25 69.25 69.5858 69.25 70H70.75ZM72.7628 76.7115C73.1558 76.8425 73.5805 76.6301 73.7115 76.2372C73.8425 75.8442 73.6301 75.4195 73.2372 75.2885L72.7628 76.7115ZM70.75 75V70H69.25V75H70.75ZM69.7628 75.7115L72.7628 76.7115L73.2372 75.2885L70.2372 74.2885L69.7628 75.7115ZM79.25 75C79.25 80.1086 75.1086 84.25 70 84.25V85.75C75.9371 85.75 80.75 80.9371 80.75 75H79.25ZM70 84.25C64.8914 84.25 60.75 80.1086 60.75 75H59.25C59.25 80.9371 64.0629 85.75 70 85.75V84.25ZM60.75 75C60.75 69.8914 64.8914 65.75 70 65.75V64.25C64.0629 64.25 59.25 69.0629 59.25 75H60.75ZM70 65.75C75.1086 65.75 79.25 69.8914 79.25 75H80.75C80.75 69.0629 75.9371 64.25 70 64.25V65.75Z"
                fill="white"
            />
            <g filter="url(#filter2_d_929_394)">
                <path
                    d="M11.3191 102.171H154.936C156.347 102.171 157.7 102.732 158.697 103.73C159.695 104.728 160.255 106.081 160.255 107.493V134.1C160.255 135.511 159.695 136.865 158.697 137.863C157.7 138.861 156.347 139.421 154.936 139.421H11.3191C9.90842 139.421 8.55548 138.861 7.55794 137.863C6.56041 136.865 6 135.511 6 134.1V107.493C6 106.081 6.56041 104.728 7.55794 103.73C8.55548 102.732 9.90842 102.171 11.3191 102.171V102.171Z"
                    fill="white"
                />
            </g>
            <path
                d="M79.4043 110.686H51.7447C49.9821 110.686 48.5532 112.115 48.5532 113.879C48.5532 115.642 49.9821 117.071 51.7447 117.071H79.4043C81.1669 117.071 82.5958 115.642 82.5958 113.879C82.5958 112.115 81.1669 110.686 79.4043 110.686Z"
                fill="#ECA973"
            />
            <path
                d="M98.5532 124.521H51.7447C49.9821 124.521 48.5532 125.951 48.5532 127.714C48.5532 129.478 49.9821 130.907 51.7447 130.907H98.5532C100.316 130.907 101.745 129.478 101.745 127.714C101.745 125.951 100.316 124.521 98.5532 124.521Z"
                fill="#F18B3A"
            />
            <path
                d="M34.7234 105.364H14.5107C11.573 105.364 9.19153 107.747 9.19153 110.686V130.907C9.19153 133.846 11.573 136.229 14.5107 136.229H34.7234C37.6611 136.229 40.0426 133.846 40.0426 130.907V110.686C40.0426 107.747 37.6611 105.364 34.7234 105.364Z"
                fill="#D04D27"
            />
            <path
                d="M25 121H24.25C24.25 121.323 24.4566 121.609 24.7628 121.712L25 121ZM25.75 116C25.75 115.586 25.4142 115.25 25 115.25C24.5858 115.25 24.25 115.586 24.25 116H25.75ZM27.7628 122.712C28.1558 122.842 28.5805 122.63 28.7115 122.237C28.8425 121.844 28.6301 121.419 28.2372 121.288L27.7628 122.712ZM25.75 121V116H24.25V121H25.75ZM24.7628 121.712L27.7628 122.712L28.2372 121.288L25.2372 120.288L24.7628 121.712ZM34.25 121C34.25 126.109 30.1086 130.25 25 130.25V131.75C30.9371 131.75 35.75 126.937 35.75 121H34.25ZM25 130.25C19.8914 130.25 15.75 126.109 15.75 121H14.25C14.25 126.937 19.0629 131.75 25 131.75V130.25ZM15.75 121C15.75 115.891 19.8914 111.75 25 111.75V110.25C19.0629 110.25 14.25 115.063 14.25 121H15.75ZM25 111.75C30.1086 111.75 34.25 115.891 34.25 121H35.75C35.75 115.063 30.9371 110.25 25 110.25V111.75Z"
                fill="white"
            />
            <defs>
                <filter
                    id="filter0_d_929_394"
                    x="0"
                    y="7.64282"
                    width="166.255"
                    height="49.25"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_929_394" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_929_394"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_929_394"
                    x="45.7446"
                    y="53.4071"
                    width="166.255"
                    height="49.25"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_929_394" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_929_394"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_d_929_394"
                    x="0"
                    y="99.1714"
                    width="166.255"
                    height="49.25"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="3" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_929_394" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_929_394"
                        result="shape"
                    />
                </filter>
            </defs>
        </SvgIcon>
    );
};

OfflineIcon.defaultProps = {
    viewBox: '0 0 212 149',
    fill: 'none',
    width: '212',
    height: '149',
};
