import { IQueryBasicData } from 'contexts/personContext';
import { string, object } from 'yup';

export function validationSchemaSelectOption(queryData?: IQueryBasicData) {
    return object().shape({
        addressSelected: string().when('field', {
            is: () => (queryData?.addresses ?? [])?.length > 1,
            then: string()
                .typeError('Endereço precisa ser preechido.')
                .required('Endereço: precisa ser preechido.'),
            otherwise: string().nullable(),
        }),
        emailSelected: string().when('field', {
            is: () => (queryData?.emails ?? [])?.length > 1,
            then: string()
                .typeError('Email precisa ser preechido.')
                .required('Email: precisa ser preechido.'),
            otherwise: string().nullable(),
        }),
        phoneNumberSelected: string().when('field', {
            is: () => (queryData?.phones ?? [])?.length > 1,
            then: string()
                .typeError('Número de telefone precisa ser preechido.')
                .required('Número de telefone: precisa ser preechido.'),
            otherwise: string().nullable(),
        }),
    });
}

export const defaultValuesSelectOption = {
    addressSelected: null,
    emailSelected: null,
    phoneNumberSelected: null,
};