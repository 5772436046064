import React, { useState } from 'react'
import { FormProvider, useFormContext } from 'contexts/formContext'
import { Stack } from '@mui/material'
import { validationSchemaPaymentRevision } from './PaymentRevisionSchema'
import { useCreditProductData } from 'contexts/creditProductContext'
import { Drawer } from '@uy3/web-components'
import { useRelatedBankAccountData } from 'contexts/relatedBankAccountContext'
import { BankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetails'
import PaymentRevisionForm from './PaymentRevisionForm'
import { bankDetailsListValidationSchema, defaultValuesBankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetailsSchema';
import { ApiResponseError, BankAccountFull, ToastType } from 'contexts/apiRequestContext'
import { useDonePaymentRevisionMutation } from 'contexts/bankAccount/bankAccountContext'
import { useParams } from 'react-router'
import { formatBankAccountForOption } from 'helpers/formats/BankAccount'
import { DonePaymentRevision } from 'services/creditNote/types/creditNoteReadModel'
import { mapErrorResponse } from 'contexts/responseErrorContext'

type PaymentRevisionFormContainerProps = {
  personId: string;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>;
  onClose: () => void;
}

export type TypeOption = { label: string, value: string }

export type TypeAction = "bankAccount" | "changeBankAccountId";

export type CreateAccountType = {
  account: {
    bankAccount: any;
    changeBankAccount: any | null;
  } | null;
  type: TypeAction | undefined;
  formValues: {
    bankAccount?: BankAccountFull;
    changeBankAccount?: BankAccountFull;
  } | null;
}

export const PaymentRevisionFormContainer = ({ onClose, setToast }: PaymentRevisionFormContainerProps) => {
  const { id } = useParams();
  const { watch, setValue } = useFormContext();
  const [openDrawerProps, setDrawerProps] = useState<{ open: boolean, action: TypeAction } | undefined>(undefined);
  const { creditProductData } = useCreditProductData(watch('productId'));

  const [beneficiaryId, personId, beneficiaryDiscriminator, personDiscriminator] = watch(['beneficiaryId', 'personId', 'beneficiaryDiscriminator', 'personDiscriminator']);
  const { data: beneficiaryBankList } = useRelatedBankAccountData(beneficiaryId, beneficiaryDiscriminator);
  const { data: personBankList } = useRelatedBankAccountData(personId, personDiscriminator);

  const handleSuccess = () => {
    setToast({
      title: "Revisão concluída com sucesso!",
      open: true,
      severity: 'success'
    })
    onClose();
  }

  const handleResonseError = (response: ApiResponseError) => {
    const {errorMessage} = mapErrorResponse(response)
    setToast({
      title: "Houve um erro ao concluir revisão!",
      open: true,
      description: errorMessage,
      severity: 'error'
    })
  }

  const { mutateDoneRevision } = useDonePaymentRevisionMutation(id!, handleSuccess, handleResonseError);

  const useCDC = creditProductData!?.isConsumerCreditNote;
  const ammType = creditProductData!?.amortizationType?.toLowerCase();
  const isFGTS = ammType === 'fgts';

  const [createdAccount, setCreatedAccount] = useState<CreateAccountType | null>({
    account: {
      bankAccount: useCDC ? beneficiaryBankList?.relatedBankAccountData : personBankList?.relatedBankAccountData,
      changeBankAccount: personBankList?.relatedBankAccountData
    },
    formValues: null,
    type: openDrawerProps?.action!
  });

  let isLegalPerson = personDiscriminator === "LegalPerson" ? true : false;
  const onCloseDrawer = () => setDrawerProps(undefined);

  const onSubmit = (fieldValues: any) => {
    let payload: DonePaymentRevision;
    const bankAccountListOptions = useCDC ? beneficiaryBankList?.data : personBankList?.data

    let bankAccountByBankAccount = bankAccountListOptions?.bankAccounts?.find((option: BankAccountFull) => option.id === fieldValues?.bankAccountId)
    let bankAccountByChangeBankAccoun = personBankList?.data?.bankAccounts?.find((option: BankAccountFull) => option.id === fieldValues?.changeBankAccountId) ?? null

    if (fieldValues?.changeBankAccountId !== 'new' || fieldValues?.bankAccountId !== 'new') {
      payload = {
        bankAccount: bankAccountByBankAccount! ?? createdAccount?.formValues?.bankAccount! ?? null,
        changeBankAccount: bankAccountByChangeBankAccoun! ?? createdAccount?.formValues?.changeBankAccount! ?? null
      }
    } else {
      payload = {
        bankAccount: createdAccount?.formValues?.bankAccount! ?? null,
        changeBankAccount: createdAccount?.formValues?.changeBankAccount! ?? null
      }
    }


    mutateDoneRevision(payload!);
  }

  const createAccount = (values: any) => {
    const { account, formValues } = createdAccount!;
    if (openDrawerProps?.action === 'bankAccount') {
      setCreatedAccount({
        account: {
          bankAccount: [...account?.bankAccount, formatBankAccountForOption(values, 'new')],
          changeBankAccount: account?.changeBankAccount
        },
        formValues: {
          bankAccount: values,
          changeBankAccount: formValues?.changeBankAccount
        },
        type: "bankAccount"
      })
    } else if (openDrawerProps?.action === 'changeBankAccountId') {
      setCreatedAccount({
        account: {
          bankAccount: account?.bankAccount,
          changeBankAccount: [...account?.changeBankAccount, formatBankAccountForOption(values, 'new')]
        },
        formValues: {
          bankAccount: formValues?.bankAccount,
          changeBankAccount: values
        },
        type: "changeBankAccountId"
      })
    }

    setToast({
      title: "Conta salva com sucesso!",
      open: true,
      severity: "success"
    })
    setValue('path', 'new');
    onCloseDrawer();
  }

  const defaultValues = {
    bankAccountId: watch('bankAccountId'),
    changeBankAccountId: watch('changeBankAccountId'),
  }

  return (
    <Stack spacing={4}>
      <FormProvider
        defaultValues={defaultValues}
        validationSchema={validationSchemaPaymentRevision(useCDC!)}
        onSubmit={onSubmit}
      >
        <>
          <PaymentRevisionForm
            isFGTS={isFGTS}
            onClose={onClose}
            createdAccount={createdAccount}
            setOpenDrawer={setDrawerProps}
            useCDC={useCDC}
          />
          <Drawer
            anchor='right'
            open={openDrawerProps?.open === true}
            onClose={onCloseDrawer}
            title='Adicionar conta bancária'
            children={
              <FormProvider
                defaultValues={defaultValuesBankDetails}
                validationSchema={bankDetailsListValidationSchema()}
                onSubmit={createAccount}
              >
                <BankDetails
                  isLegalPerson={isLegalPerson}
                  onClose={onCloseDrawer}
                />
              </FormProvider>
            }
          />
        </>
      </FormProvider>
    </Stack>
  )
}
