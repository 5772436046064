import {
    SaveIcon,
    ApprovalIcon,
    DeleteIcon,
    UpdateIcon,
    RejectIcon,
    ApproveIcon,
    SettingsIcon,
    DoneIcon,
    PersonIcon,
    InvoiceReceivableIcon,
    ReportIcon,
    CancelIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { useFormContext } from 'contexts/formContext';
import { useAppConfig } from 'contexts/appConfig';
import React from 'react';
import { checkActionsPerms } from 'services/permissions';
import { useParams } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import { useTenant } from 'contexts/tenantContext';
import { hasCustomPermission } from 'helpers';
import { AttributeTypeChecklist } from 'services/creditNote/complianceChecklist';
import { useBankAccountActions } from 'contexts/bankAccount/bankAccountActions';

interface IButtonsOfActionsProps {
    isNew: boolean;
    onAssignBankAccountRequest: () => void;
    complianceChecklist: AttributeTypeChecklist[];
}

const ButtonsOfActions: React.FC<IButtonsOfActionsProps> = ({
    isNew,
    onAssignBankAccountRequest,
    complianceChecklist,
}) => {
    const {
        cancelBankAccountRequest,
        onDraftpreview,
        onRejectInstrument,
        onApproveInstrument,
        onUploadDocs,
        openBankAccountRequest,
        onSendApprovalRevision,
        onManualUpdate,
        onDelete,
        onSubmitApproval,
        deleteCheckList,
        onApprove,
        onDisapprove,
        onApproveSignature,
        onDisapproveSignature,
        refetch,
    } = useBankAccountActions();

    const { bankAccountId } = useParams();
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const { getValues, watch } = useFormContext();
    const { isRootTenancy } = useTenant();

    const status = getValues('status');
    const isSendCreditApproval = !!appConfig.SEND_CREDIT_APPROVAL;
    const isApproveAndReject = !!appConfig.APPROVE_REJECT_CREDIT;
    const uploads = watch('uploads') ?? [];
    const isNewRequest = bankAccountId === 'nova';

    const isApprovalStts = status === 'Approval';

    const resource = 'BankAccountRequest';
    const typePermission = isNewRequest ? 'Create' : 'Update';
    const hasPermToCreateOrEdit = hasCustomPermission(resource, typePermission, hasPermission);

    const hasActionPermission = (action: string, resource?: string): boolean => {
        if (checkActionsPerms(action, permissionsData, resource ?? 'BankAccountRequest')) {
            return true;
        }
        return false;
    };

    const showDeletecomplianceChecklist = () => {
        if (complianceChecklist?.length > 0 && isApprovalStts && isApproveAndReject) return true;
        return false;
    };

    const showApproveInstrumentAndOnReject = (status: string): boolean => {
        if (
            status &&
            ['InstrumentApproval'].includes(status) &&
            hasActionPermission('InstrumentApproval')
        )
            return true;
        return false;
    };

    const hiddenSubmit = [
        'Timeout',
        'Approval',
        'Processing',
        'Closed',
        'InstrumentApproval',
        'Active',
    ];

    const showBtnSubmit = (status: string): boolean => {
        if (hasPermToCreateOrEdit && !hiddenSubmit.includes(status!)) return true;
        return false;
    };

    const showApproveOrRejectAction = () => {
        if (
            status === 'SignaturesValidation' &&
            isRootTenancy &&
            hasActionPermission('SignatureValidation')
        )
            return true;
        return false;
    };

    const showDraftpreviewAction = (): boolean => {
        if (['Draft', 'Revision'].includes(status!)) return true;
        return false;
    };

    const enableUpdateDocs = () => {
        const hasPermissionUploadDocs = hasActionPermission('UploadDocs');

        if (isRootTenancy && hasPermissionUploadDocs) {
            return true;
        } else if (
            !isRootTenancy &&
            hasPermissionUploadDocs &&
            ['InstrumentApproval', 'Signatures', 'Draft', 'Revision'].includes(status!)
        ) {
            return true;
        }
        return false;
    };

    return (
        <Actions
            numberOfButtons={4}
            buttonsActionsList={[
                {
                    label: 'Aprovar instrumento',
                    enable: showApproveInstrumentAndOnReject(status),
                    action: onApproveInstrument,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar instrumento',
                    enable: showApproveInstrumentAndOnReject(status),
                    action: onRejectInstrument,
                    icon: <RejectIcon />,
                },
                {
                    label: 'Aprovar',
                    enable: isApprovalStts && isApproveAndReject,
                    action: onApprove,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar',
                    enable: isApprovalStts && isApproveAndReject,
                    action: onDisapprove,
                    icon: <RejectIcon />,
                },
                {
                    label: 'Aprovar assinatura',
                    enable: showApproveOrRejectAction(),
                    action: onApproveSignature,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Reprovar assinatura',
                    enable: showApproveOrRejectAction(),
                    action: onDisapproveSignature,
                    icon: <ApproveIcon />,
                },
                {
                    label: 'Enviar para aprovação',
                    enable: [
                        'Draft',
                        'Timeout',
                        'Cancel',
                        'CorbanPreAnalysis',
                        'Revision',
                    ].includes(status),
                    action: onSubmitApproval,
                    icon: <ApprovalIcon />,
                },
                {
                    label: 'Gerar contrato',
                    enable: showDraftpreviewAction(),
                    action: onDraftpreview,
                    icon: <ReportIcon />,
                },
                {
                    label: 'Salvar',
                    enable: showBtnSubmit(status),
                    icon: <SaveIcon />,
                    type: 'submit',
                },
                {
                    label: 'Atualizar',
                    enable: !isNew,
                    action: refetch,
                    icon: <UpdateIcon />,
                },
                {
                    label: 'Definir status',
                    enable: !isNew && isRootTenancy,
                    action: onManualUpdate,
                    icon: <SettingsIcon />,
                },
                {
                    label: 'Cancelar',
                    enable: hasPermToCreateOrEdit && ['Approval', 'Signatures'].includes(status!),
                    action: cancelBankAccountRequest,
                    icon: <CancelIcon />,
                },
                {
                    label: 'Remover restrições',
                    action: deleteCheckList,
                    enable: showDeletecomplianceChecklist() && !isNew,
                    icon: <DeleteIcon />,
                },
                {
                    label: 'Atribuir',
                    enable: !isNew && hasPermToCreateOrEdit,
                    action: onAssignBankAccountRequest,
                    icon: <PersonIcon />,
                },
                {
                    label: 'Abrir conta',
                    enable:
                        !isNew &&
                        status !== 'Active' &&
                        hasActionPermission('OpenBankAccount') &&
                        isRootTenancy,
                    action: openBankAccountRequest,
                    icon: <ApprovalIcon />,
                },
                {
                    label: 'Salvar documentos',
                    enable: enableUpdateDocs(),
                    action: () => onUploadDocs(uploads),
                    icon: <InvoiceReceivableIcon />,
                },
                {
                    label: 'Concluir revisão',
                    enable:
                        !isNew &&
                        status === 'OpeningAccountRevision' &&
                        hasActionPermission('OpenBankAccount') &&
                        isRootTenancy,
                    action: onSendApprovalRevision,
                    icon: <DoneIcon />,
                },
                {
                    label: 'Excluir',
                    enable:
                        ['Draft', 'Revision', 'CorbanPreAnalysis'].includes(status) &&
                        isSendCreditApproval &&
                        hasPermission('BankAccountRequest', 'Delete'),
                    action: onDelete,
                    icon: <DeleteIcon />,
                },
            ]}
        />
    );
};
export default ButtonsOfActions;
