import { creditAxiosApi, createAxiosConfig } from 'services/axiosApi/axiosApi';
import { postBankAccountRequestOperatorProps } from '../bankAccount';
import {
    BankAccountAssign,
    BankAccountRequestCreateModel,
    BankAccountRequestProps,
    BankAccountRequestReadModel,
    BankAccountRequestUpdateModel,
    DisapprovedBankAccountProps,
    IDeleteParamsBankAccountRequestParams,
    IDraftpreviewBankAccountRequest,
    RelatedPersonBankAccountModel,
    UpdateDocumentsProps,
    approveInstrumentByIdProps,
} from './types';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { GetViewApiResponse, GetViewApiResponseSuccess } from 'contexts/apiRequestContext';
import { mapRelatedPersonToDataModel } from 'utils';

var url = '/BankAccountRequest';

export async function getBankAccountRequestList(filters: BankAccountRequestProps, token: string) {
    const axiosConfig = createAxiosConfig(token, filters);
    return await creditAxiosApi.get(url, axiosConfig);
}

export async function getBankAccountRequestById(bankAccountId: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.get<GetViewApiResponseSuccess<BankAccountRequestReadModel>>(
        url + `/${bankAccountId}`,
        axiosConfig
    );
}

export async function editOrCreateBankAccRequest(
    data: BankAccountRequestReadModel,
    bankAccountId: string,
    token = '',
    tenant?: string
) {
    const isCreating = bankAccountId === 'nova';
    const urlFull = isCreating ? url : `${url}/${bankAccountId}`;

    const CreateOrUpdate = isCreating
        ? BankAccountRequestCreateModel
        : BankAccountRequestUpdateModel;

    const axiosConfig = createAxiosConfig(token, tenant);

    const payload = toDataModel(
        data as Record<keyof BankAccountRequestReadModel, unknown>,
        CreateOrUpdate
    );

    if (payload?.relatedPerson) {
        payload.relatedPerson = mapRelatedPersonToDataModel(
            payload.relatedPerson,
            RelatedPersonBankAccountModel
        );
    }

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;
    return await axiosFunction<GetViewApiResponse<BankAccountRequestReadModel>>(
        urlFull,
        payload,
        axiosConfig
    );
}

export async function approveBankAccountById(id: string, message: string, token: string) {
    var url = `/BankAccountRequest/${id}/approve`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url, { message }, axiosConfig);
}

export async function disapprovedBankAccountById(
    id: string,
    payload: DisapprovedBankAccountProps,
    token: string
) {
    const url = `/BankAccountRequest/${id}/disapprove`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.post(url, payload, axiosConfig);
}

export async function cancelBankAccountRequestById(
    id: string,
    payload: DisapprovedBankAccountProps,
    token: string
) {
    const url = `/BankAccountRequest/${id}/cancel`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url, payload, axiosConfig);
}

export async function postBankAccountRequestSubmitApproval(id: string, token: string) {
    const url = `/BankAccountRequest/${id}/submitApproval`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url, {}, axiosConfig);
}

export async function deleteBankAccountRequestById(id: string, token: string) {
    const url = `/BankAccountRequest/${id}`;
    const axiosConfig = createAxiosConfig(token);

    return await creditAxiosApi.delete(url, axiosConfig);
}

export async function updateBankAccountRequestById(id: string, token: string) {
    const url = `/BankAccountRequest/${id}`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.put(url, {}, axiosConfig);
}

export async function manualUpdateBankAccountRequest(
    id: string,
    data: any,
    token: string,
    tenant: string | undefined
) {
    const axiosConfig = createAxiosConfig(token, tenant);
    return await creditAxiosApi.post(url + `/${id}/manualUpdate`, data, axiosConfig);
}

export async function approvalRevisionById(id: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/approvalRevision`, {}, axiosConfig);
}

export async function postOpenBankAccountRequest(bankAccountId: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${bankAccountId}/openBankAccount`, {}, axiosConfig);
}

export async function postAssignBankAccountRequest(
    bankAccountId: string,
    payload: BankAccountAssign,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${bankAccountId}/assign`, payload, axiosConfig);
}

export async function postBankAccountRequestOperator(
    bankAccountId: string,
    payload: postBankAccountRequestOperatorProps,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(
        url + `/${bankAccountId}/BankAccountRequestOperator`,
        payload,
        axiosConfig
    );
}

export async function updateDocumentsBankAccountRequest(
    bankAccountId: string,
    payload: UpdateDocumentsProps,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.put(
        `${url}/${bankAccountId}/upload`,
        payload,
        axiosConfig
    );
}

export async function approveInstrumentById(
    id: string,
    payload: approveInstrumentByIdProps,
    token: string
) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/approveInstrument`, payload, axiosConfig);
}

export async function disapproveInstrumentById(id: string, payload: any, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + `/${id}/disapproveInstrument`, payload, axiosConfig);
}

export async function approveOrRejectSignatureById(
    bankAccountRequestId: string,
    body: object,
    isRepprove: boolean,
    token: string
) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            reprove: isRepprove,
        },
    };
    return await creditAxiosApi.post(
        url + `/${bankAccountRequestId}/approveSignature`,
        body,
        config
    );
}

export async function draftpreviewBankAccountRequest(
    bankAccountRequestId: string,
    params: IDraftpreviewBankAccountRequest,
    token: string
) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.post(
        url + `/${bankAccountRequestId}/draftpreview`,
        {},
        axiosConfig
    );
}

export async function deleteBankAccountRequestOperator(
    operatorId: string,
    params: IDeleteParamsBankAccountRequestParams,
    token: string
) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.delete(
        url + `/BankAccountRequestOperator/${operatorId}`,
        axiosConfig
    );
}
