import { CardData } from '@uy3/web-components';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { isEmpty } from 'lodash';

type BeneficiaryPayerDetailsProps = {
    title: string;
    document: string;
    name: string;
};

export const BeneficiaryPayerDetails = ({
    title,
    document,
    name,
}: BeneficiaryPayerDetailsProps) => (
    <CardData
        listItem={[
            { id: '1', title: <strong>{title}</strong> },
            {
                id: '2',
                title: <strong>CPF/CNPJ</strong>,
                value: isEmpty(document) ? 'N/D' : formatDocumentNumber(document),
            },
            {
                id: '3',
                title: <strong>Nome/Razão Social</strong>,
                value: isEmpty(name) ? 'N/D' : name,
            },
        ]}
    />
);
