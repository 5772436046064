/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { AvailableCashIcon, CardValue, Drawer, Typography } from '@uy3/web-components';
import { useBankAccontBeneficiaries } from 'contexts/bankAccount/bankAccountBeneficiaryContext';
import { FormProvider } from 'contexts/formContext';
import { useParams } from 'react-router';
import { CreateTransfer } from './CreateTransfer';
import { defaultValuesCreateTransfer, validationCreateTransferSchema } from './CreateTransferSchema';
import { activeTheme } from 'services/theme';
import { FieldValues } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { CreateBeneficiaryType, IMFAState } from 'contexts/bankAccount/bankAccountType';
import { defaultValuesConfirmMfaForm, validationConfirmMfaFormSchema } from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { ApiResponseError, ToastType, useApiRequest } from 'contexts/apiRequestContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { AxiosResponse } from 'axios';
import { createTransfer } from 'services/accounts/bankAccount/bankAccount';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { BeneficiaryTransferForm } from './BeneficiaryTransferForm/BeneficiaryTransferForm';
import { defaultValuesBeneficiaryForm, validationSchemaBeneficiaryForm } from './BeneficiaryTransferForm/BeneficiaryTransferSchema';
import { useEnumContext } from 'contexts/enumContext';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';
import { BankAccounTransferType } from 'services/accounts/bankAccount';
import { isEmpty } from 'lodash';

const theme = activeTheme();

type CreateTransferContainerProps = {
  setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
  onClose: () => void;
  dataBalance: any;
}

export const CreateTransferContainer = ({ setIsRefreshing, setToast, dataBalance, onClose }: CreateTransferContainerProps) => {
  const { bankAccountId } = useParams();
  const { token } = useIdentity();
  const [isCreateByForm, setIsCreateByForm] = useState<boolean>(false);
  const [stepAction, setStepAction] = useState<string | undefined>(undefined);
  const [beneficiaryId, setBeneficiaryId] = useState<string | undefined>(undefined);
  const [formValues, setFormValues] = useState<any | null>(null);
  const { submitError, startRequest, endRequest } = useApiRequest();

  const { bankAccountAutocomplete, data: beneficiariesData } = useBankAccontBeneficiaries({
    id: bankAccountId!,
    operationTypeValue: 'Transfer',
    onlyApproveds: true
  });
  const { autoCompleteProps: paymentPurposeList } = useEnumContext({ enumName: 'PaymentPurpose' });
  const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

  useMemo(() => {
    if (beneficiaryId !== undefined && beneficiariesData.data !== undefined) {
      const find = beneficiariesData.data?.find((item: { id: string }) => item.id === beneficiaryId);
      setFormValues(find as CreateBeneficiaryType);
      setIsCreateByForm(false);
    }
  }, [beneficiaryId]);

  const onCreateBeneficiary = (values: FieldValues) => {
    setFormValues(values as CreateBeneficiaryType);
    setBeneficiaryId(undefined);
    setIsCreateByForm(true)
    setStepAction(undefined);
  };

  const selectExistingPerson = () => {
    setFormValues(null);
    setIsCreateByForm(false);
  }

  const handleTransferSubmit = async (values: IMFAState) => {
    startRequest();
    mutateGenerateSessionId({
      userPassword: values.password,
      then: async function ({ data: sessionId }: AxiosResponse<string>) {

        if (formValues) {
          let payload: BankAccounTransferType;
          let bankAccountBeneficiaryId = formValues.dataTransfer.bankAccountBeneficiaryId;
          const typeSelected = formValues?.dataTransfer?.transferDateSelected;
          const transferDate = typeSelected === "today" ? new Date().toISOString() : formValues.dataTransfer?.transferDate; 
          const onlyNumbers = /\D/g;

          let payloadBankAccountBeneficiaryCreate = {
            bankAccountBeneficiaryCreate: {
              name: formValues?.name,
              registrationNumber: String(formValues?.registrationNumber).replace(onlyNumbers, ""),
              bank: formValues?.bank,
              agency: formValues?.agency,
              accountNumber: formValues?.accountNumber,
              accountType: formValues?.accountType,
              operationTypeValue: "Transfer",
            },
            amount: formValues.dataTransfer?.amount,
            transferDate,
            paymentPurpose: formValues.dataTransfer?.paymentPurpose,
            code: values.code,
            sessionId,
            saveBeneficiary: formValues.saveBeneficiary
          };

          if (bankAccountBeneficiaryId === 'create' && isEmpty(beneficiaryId)) {
            payload = payloadBankAccountBeneficiaryCreate;
          } else {
            payload = {
              bankAccountBeneficiaryId,
              amount: formValues.dataTransfer?.amount,
              transferDate,
              paymentPurpose: formValues.dataTransfer?.paymentPurpose,
              code: values.code,
              sessionId,
              saveBeneficiary: false
            }
          }

          await createTransfer(bankAccountId!, payload, token!)
            .then(() => {
              setStepAction(undefined);
              endRequest(true);
              setIsRefreshing(true);
              onClose();
              setToast({
                open: true,
                title: 'Transferência realizada com sucesso!',
                severity: 'success'
              });
            }).catch((response: ApiResponseError) => {
              const { description, open, title } = errorHandlingBankAccount(response);
              endRequest(false);
              setToast({ title, description, open, severity: 'error' });
            });
        }
      }
    })

  };

  return (
    <Stack spacing={2}>
      <CardValue
        sx={{ width: '100%' }}
        color="primary"
        title="Saldo disponível"
        description="Baseado em transações e aprovações."
        icon={<AvailableCashIcon htmlColor={theme.palette.primary.main} />}
        value={formatCurrencyInCents(dataBalance?.available)?.toString()}
      />

      <Typography variant='h5' fontWeight={700}>Selecione um favorecido</Typography>

      <FormProvider
        validationSchema={validationCreateTransferSchema()}
        defaultValues={defaultValuesCreateTransfer}
        onSubmit={(values) => {
          setStepAction('mfacreatePix');
          setFormValues({
            ...formValues,
            dataTransfer: values as any
          });
        }}
      >
        <CreateTransfer
          bankAccountAutocomplete={bankAccountAutocomplete}
          formValues={formValues}
          isCreatingBeneficiary={isCreateByForm}
          onEdit={() => setStepAction('editBeneficiary')}
          paymentPurposeList={paymentPurposeList}
          onClose={onClose}
          setBeneficiaryId={setBeneficiaryId}
          selectExistingPerson={selectExistingPerson}
          onCreateBeneficiary={() => setStepAction('createBeneficiary')}
        />
      </FormProvider>

      <Drawer
        anchor='right'
        title={stepAction === 'editBeneficiary' ? 'Editar favorecido' : 'Adicionar favorecido'}
        toggleDrawer
        open={stepAction === 'createBeneficiary' || stepAction === 'editBeneficiary'}
        children={
          <FormProvider
            validationSchema={validationSchemaBeneficiaryForm()}
            defaultValues={stepAction === 'editBeneficiary' ? formValues : defaultValuesBeneficiaryForm}
            onSubmit={onCreateBeneficiary}
          >
            <BeneficiaryTransferForm
              onClose={() => setStepAction(undefined)}
              submitError={submitError}
            />
          </FormProvider>}
      />

      <Drawer
        anchor='right'
        title='Confirmar MFA'
        open={stepAction === 'mfacreatePix'}
        onClose={() => setStepAction(undefined)}
        toggleDrawer
      >
        <FormProvider
          validationSchema={validationConfirmMfaFormSchema()}
          defaultValues={defaultValuesConfirmMfaForm}
          onSubmit={handleTransferSubmit}
        >
          <ConfirmMfaForm
            onClose={() => setStepAction(undefined)}
            toggleDrawer={true}
          />
        </FormProvider>
      </Drawer>
    </Stack>
  )
}