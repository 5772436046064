import { GroupListProps, getGroupList } from 'services/user/userGroups';
import { useIdentity } from './identityContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    GetViewApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from './apiRequestContext';
import { AxiosError, isAxiosError } from 'axios';
import {
    CreateOrEditGroupProps,
    UserInGroupProps,
    editOrCreateGroup,
    getGroupById,
    getUserInGroupList,
} from 'services/group';
import { UserGetDetailResponseModel, useUserPermissionData } from './userContext';
import { useTenant } from './tenantContext';
import { useState } from 'react';
import { useFilterValues } from './filterValuesContext';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router';
export default interface Group {
    creationDate: string;
    description: any;
    groupName: string;
    lastModifiedDate: string;
    precedence: number;
    userPoolId: string;
};

export function useGroupData(groupName: string) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('UserGroup', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && groupName !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['group-data', groupName, currentTenantId],
        queryFn: async (): Promise<Group> => {
            startRequest();
            const { data, status, statusText } = await getGroupById(groupName, token!, currentTenantId);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as Group;
        },
        onError: (error: ApiResponseError) => {
            if (pathname.includes('/seguranca/grupos/') && pathname.length > 20) {
                return navigate('/seguranca/grupos');
            }
        }
    });

    return { status, data, error, isFetching, refetch, isLoading };
}

export function useUserInGroupListData(filters: UserInGroupProps, groupNameId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const filtersFull = { ...filters, tenant: currentTenantId };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('UserGroup', 'Read');

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && groupNameId !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['userInGroup-list', filters, groupNameId, currentTenantId],
        queryFn: async (): Promise<GetListApiResponse<UserGetDetailResponseModel>> => {
            startRequest();
            const { data, status, statusText } = await getUserInGroupList(filtersFull, groupNameId, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data as GetListApiResponseSuccess<UserGetDetailResponseModel>;
        }
    });

    return { status, data: data as GetListApiResponseSuccess<UserGetDetailResponseModel>, error, isFetching, refetch };
};

export function useGroupListData(filters: GroupListProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const [groupList, setGroupList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'UserGroup' : filterValues.recordType;
    const listDataFilters = recordType === 'UserGroup' && { ...filterValues.filters };
    let filtersComplete = { ...listDataFilters, ...filters, tenant: filters.tenant ?? currentTenantId };
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('UserGroup', 'Read');
    if (currentTenantId !== undefined) {
        filtersComplete = {...filtersComplete, paginationToken: undefined}
    }
    const queryContextGroup = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['group-list', filtersComplete, filters.tenant, currentTenantId],
        queryFn: async (): Promise<GetListApiResponseSuccess<Group>> => {
            startRequest();
            const { data, status, statusText } = await getGroupList(filtersComplete, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const options = data as GetListApiResponseSuccess<Group>;
            setGroupList(
                options.data.map((g) => {
                    return { label: g.groupName, value: g.userPoolId };
                })
            );

            return data as GetListApiResponseSuccess<Group>;
        }
    });

    const groupAutoCompleteProps = {
        listOptions: groupList,
        loading: !hasReadPermission
            ? false
            : queryContextGroup.isLoading || queryContextGroup.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ ...filterValues.filters, searchString }, 'UserGroup')
    };

    return { ...queryContextGroup, groupAutoCompleteProps };
};

export function useGroupMutation(groupId: string, onSuccess?: (data: CreateOrEditGroupProps) => void, onError?: (error: any) => void) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: CreateOrEditGroupProps): Promise<CreateOrEditGroupProps> => {
            startRequest();
            const { data, status, statusText } = await editOrCreateGroup(
                values,
                currentTenantId,
                groupId,
                token
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<CreateOrEditGroupProps>;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: []
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};
