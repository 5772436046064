import { Drawer } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import React, { useState } from 'react';
import { AssignForm } from './AssignForm';
import { defaultValuesAssignForm, validationSchemaAssignForm } from './AssignSchema';
import { assign } from 'services/assign';
import { useIdentity } from 'contexts/identityContext';
import { useOperatorList } from 'contexts/OperatorContext';
import { useGroupListData } from 'contexts/groupContext';
import { useNavigate } from 'react-router';
import {
    ApiResponseError,
    ToastType,
    handleOnError,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import Toast from 'components/Toast/Toast';
import { SelectOption } from 'components/Forms/FormFields';

interface IAssignDrawerProps {
    openDrawer: boolean;
    onClose: () => void;
    recordType: string;
    recordId: string;
    navigate: string;
    refetchRecord?: () => void;
}

const filtersProps = {
    page: 0,
    size: 10,
};

const AssignDrawer: React.FC<IAssignDrawerProps> = ({
    openDrawer,
    onClose,
    recordType,
    recordId,
    navigate,
    refetchRecord,
}) => {
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [selectedTenant, setSelectedTenant] = useState<SelectOption>();
    const { startRequest, endRequest } = useApiRequest();
    const { operatorsAutoCompleteProps } = useOperatorList(
        filtersProps,
        'always',
        selectedTenant?.value as string
    );
    const { groupAutoCompleteProps } = useGroupListData(
        { ...filtersProps, tenant: selectedTenant?.value as string },
        'always'
    );
    const nav = useNavigate();

    const schemaAssignProduct = validationSchemaAssignForm();
    const assignDefaultValues = defaultValuesAssignForm;

    const onAssignProduct = async (values: FieldValues) => {
        startRequest();
        const { userId, groupName } = values;

        let data = {
            groupName,
            recordId,
            recordType,
            tenant: selectedTenant?.value as string,
            userId,
        };

        await assign(data, token!)
            .then(() => {
                const title = 'Atibuição relizada com sucesso!';
                const desc = 'Ótimo! Agora você pode visualizar o registro atribuído.';
                showSuccessToast(title, desc, setToast);
                setTimeout(() => nav(`${navigate}/${recordId}`), 1000);
                endRequest(true);
                refetchRecord && refetchRecord();
                onClose();
            })
            .catch((response: ApiResponseError) => handleOnError(response, setToast))
            .finally(() => endRequest(false));
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Drawer
                anchor="right"
                title="Atribuir"
                description="Selecione um correspondente, operador ou grupo"
                open={openDrawer}
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={schemaAssignProduct}
                    defaultValues={assignDefaultValues}
                    onSubmit={onAssignProduct}
                >
                    <AssignForm
                        setSelectedTenant={setSelectedTenant}
                        groupList={groupAutoCompleteProps}
                        listOperator={operatorsAutoCompleteProps}
                        onClose={onClose}
                    />
                </FormProvider>
            </Drawer>
        </>
    );
};

export default AssignDrawer;
