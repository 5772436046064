import { Stack } from '@mui/material';
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { activeTheme } from 'services/theme';
import { barCodeAndLineDigitableMask } from 'utils/constants';

type BarCodeFormProps = {
    isFetching: boolean;
    onClose: () => void;
    hideBtnClose: boolean;
};

const theme = activeTheme();
const BarCodeForm = ({ isFetching, onClose, hideBtnClose }: BarCodeFormProps) => {
    return (
        <Stack spacing={2}>
            <TextFormField
                label="Informe o código de barras ou linha digitável"
                name="barCode"
                variant="outlined"
                fullWidth
                placeholder="0000.0000 0000.00000 0000.00000 0 000000000000000"
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: {
                        mask: barCodeAndLineDigitableMask,
                    },
                }}
            />
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                {hideBtnClose && <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>}
                <Button
                    variant="contained"
                    disabled={!!isFetching}
                    type="submit"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Consultar
                </Button>
            </Stack>
        </Stack>
    );
};

export default BarCodeForm;
