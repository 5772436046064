import { DefaultSchemaAmortization, ValidationSchemaAmortization } from './Amortization';
import { CleanPrice } from './CleanPrice';

abstract class CommercialPapper extends CleanPrice 
{
    
}

export class DefaultSchemaAmmComercialPapper extends DefaultSchemaAmortization implements CommercialPapper
{
    public instrumentType = 'commercialPapper';    
}

export class ValidationSchemaAmmCommercialPapper extends ValidationSchemaAmortization 
{    
}
