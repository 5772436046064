import { Drawer } from '@uy3/web-components';
import { AxiosResponse } from 'axios';
import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useBankAccountBalance, useBankSlpFGTS } from 'contexts/bankAccount/bankAccountContext';
import { IMFAState, PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import moment from 'moment';
import ConfirmInformationDrawer from 'pages/BankAccount/BankAccountActive/BankAccountView/InformationTransferDataDrawer/ConfirmInformationDrawer';
import { useState } from 'react';
import { useParams } from 'react-router';
import { postPayFgtsWithBarCode } from 'services/accounts/bankAccount/bankAccount';
import { InfoConfirmPay } from '../InfoConfirmPay/InfoConfirmPay';
import {
    defaultValuesBarCode,
    validationBarCodeSchema,
} from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/barCodeSchema';
import {
    defaultValuesPaymentInformationFgtsForm,
    validationPaymentInformationFgtsSchema,
} from './BankSlipFgtsInformationSchema';
import BarCodeForm from '../BarCodeForm/BarCodeForm';
import PaymentDetails from '../PaymentDetails/PaymentDetails';
import { RefreshProgress } from 'components/RefreshProgress';
import { StepsPayment } from 'services/accounts/bankAccount';
import { FieldValues } from 'react-hook-form';
import DrawerMFA from '../../DrawerMFA/DrawerMFA';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';


type BankSlipFgtsContainerProps = {
    refetch: () => void
    onCloseDrawer: () => void
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const BankSlipFgtsContainer = ({ refetch, onCloseDrawer, setToast }: BankSlipFgtsContainerProps) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [stepPayment, setStepPayment] = useState<StepsPayment>();
    const [formFieldValues, setFormFieldValues] = useState<FieldValues>();
    const [barCode, setBarCode] = useState<any>('');
    const { setSubmitError, startRequest, endRequest } = useApiRequest();
    const [hideBtnClose, setHideBtnClose] = useState(true);

    const { bankSlipFGTSData, isFetching } = useBankSlpFGTS(bankAccountId!, barCode);
    const { data: dataBalance } = useBankAccountBalance(bankAccountId!);

    const onClose = () => {
        setStepPayment(undefined);
        setSubmitError(undefined);
    };
    const onSubmitFGTSWithBarCode = (value: PaymentBankSlip) => {
        setHideBtnClose(false);

        let onlyNumbers = /\D/g;
        let payload = { barCode: value?.barCode?.replace(onlyNumbers, '') };
        return bankSlipFGTSData ? undefined : setBarCode(payload);
    };

    const onSubmitInformation = (value: any) => {
        setFormFieldValues(value);
        setStepPayment('confirmationPayment');
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const onSubmitConfirmationMfa = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password, then: async (response: AxiosResponse<string, any>) => {
                const payload = {
                    barCodeOrDigitableLine: barCode.barCode,
                    paymentValue: bankSlipFGTSData?.totalValue,
                    paymentDate: moment(formFieldValues?.paymentDate).format('YYYY-MM-DD') + 'T00:00:00',
                    sessionId: response.data,
                    code: values?.code
                }
                postPayFgtsWithBarCode(bankAccountId!, payload, token!).then(() => {
                    endRequest(true);
                    onClose();
                    onCloseDrawer();
                    refetch();
                    const title = "Pagamento realizado com sucesso!";
                    const description = 'Ótimo! Agora é só aguardar a confirmação do pagamento.';
                    showSuccessToast(title, description, setToast);

                }).catch((response: ApiResponseError) => {
                    const { description, open, title } = errorHandlingBankAccount(response);
                    endRequest(false);
                    setToast({ title, description, open, severity: 'error' });
                });
            }
        })
    };

    const paymentValue = formFieldValues?.paymentValue;
    const formDrawer: { [key: string]: React.ReactNode } = {
        confirmationMfa: <DrawerMFA onClose={() => setStepPayment('confirmationPayment')} onSubmit={onSubmitConfirmationMfa} />,
        confirmationPayment: (
            <ConfirmInformationDrawer
                open={true}
                onClose={onClose}
                setStep={setStepPayment}
                dataBalance={dataBalance}
                valueOfOperation={paymentValue ?? bankSlipFGTSData?.value}
                CardInfoComponent={<InfoConfirmPay bankSlipData={bankSlipFGTSData} />}
            />
        )
    }

    const form = formDrawer[stepPayment || 'confirmationPayment'];
    const title = stepPayment === 'confirmationPayment' ? 'Resumo da operação' : 'Confirme a operação';
    const openDrawer = stepPayment === 'confirmationPayment' || stepPayment === 'confirmationMfa';

    return (
        <>
            <FormProvider
                validationSchema={validationBarCodeSchema('lengthTributes')}
                defaultValues={defaultValuesBarCode}
                onSubmit={onSubmitFGTSWithBarCode}
            >
                <BarCodeForm
                    onClose={onCloseDrawer}
                    isFetching={isFetching}
                    hideBtnClose={hideBtnClose}
                />
            </FormProvider>

            {bankSlipFGTSData !== undefined && (
                <FormProvider
                    validationSchema={validationPaymentInformationFgtsSchema()}
                    defaultValues={defaultValuesPaymentInformationFgtsForm}
                    onSubmit={onSubmitInformation}
                >
                    <PaymentDetails
                        bankSlipData={bankSlipFGTSData}
                        onCloseDrawer={onCloseDrawer}
                        withPaymentAmount={false}
                    />
                </FormProvider>
            )}

            <Drawer anchor="right" title={title} open={openDrawer} onClose={onClose} toggleDrawer>
                {form}
            </Drawer>
            <RefreshProgress refreshing={isFetching} />
        </>
    );
};
