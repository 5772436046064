/* eslint-disable sonarjs/cognitive-complexity */
import type { FunctionComponent } from 'react';
import type { MenuItems, MenuProps } from './Menu.interface';
import { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import Box from '@mui/material/Box';
import { AccordionSummary, Skeleton, Stack } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import {
  Drawer,
  LogoBox,
  StyledAccordion,
  StyledAccordionDetails,
  StyledButton,
  StyledDivider,
  StyledLink,
  StyledListItem,
  StyledListItemButton,
  StyledListItemIcon,
  StyledListItemText,
  StyledStack,
  StyledList,
} from './Menu.styled';
import { ShowIcon } from '../../icons/ShowIcon';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { useOnClickOutside } from '../../../../util/ref/useOnClickOutside';


type RenderComponentMenuProps = {
  itemsList: MenuItems[];
  open: boolean;
  activeIndex: number;
  setActiveIndex: (active: number) => void;
  setOpen: (open: boolean) => void
}

const RenderComponentMenu = ({ itemsList, activeIndex, open, setActiveIndex, setOpen }: RenderComponentMenuProps) => {
  return (
    <StyledList>
      {itemsList?.map((item, index) => {
        if (item.submenu) {
          return (
            <StyledAccordion expanded={activeIndex === index}>
              <StyledListItem
                onClick={() => (open ? null : setOpen(true))}
                key={item.title}
                disablePadding
                className={clsx(item.active && 'active')}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    '& .MuiAccordionSummary-expandIconWrapper': {
                      display: open ? 'block' : 'none',
                    },
                  }}
                >
                  <StyledListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      marginBottom: '2px',
                    }}
                    onClick={() => {
                      if (activeIndex === index) {
                        setActiveIndex(-1);
                      } else {
                        setActiveIndex(index);
                      }
                      if (!open) setOpen(true);
                    }}
                  >
                    <StyledListItemIcon
                      sx={{
                        mr: open ? 2 : 0,
                      }}
                    >
                      {item.icon}
                    </StyledListItemIcon>

                    <StyledListItemText
                      primary={item.title}
                      sx={{ display: open ? 'block' : 'none' }}
                      className="title"
                    />
                  </StyledListItemButton>
                </AccordionSummary>
              </StyledListItem>

              <StyledAccordionDetails sx={{ display: open ? 'block' : 'none' }}>
                <StyledStack>
                  {item.submenu.map((link) => {

                    if (!!link?.subSubmenu && link?.subSubmenu?.length > 0) {
                      return (
                        <li className={clsx(link.active && 'active')} style={{ listStyle: 'none' }}>
                          <StyledLink
                            to={link.href}
                            style={{ textDecoration: 'none', fontStyle: 'italic', color: '#666666', display: 'block', marginLeft: -16, marginBottom: 1 }}
                          >
                            {link.title}
                          </StyledLink>
                          <Stack sx={{ display: 'block', marginLeft: '10px', padding: "10px 0 3px 0" }}>
                            {!!link?.subSubmenu && link?.subSubmenu?.length > 0 &&
                              link?.subSubmenu.map(item => {
                                return (
                                  <li>
                                    <StyledLink
                                      to={item.href}
                                      style={{ textDecoration: 'none' }}
                                      className={clsx(item.active && 'active')}
                                    >
                                      {item?.title}
                                    </StyledLink>
                                  </li>
                                )
                              })
                            }
                          </Stack>
                        </li>
                      )
                    }

                    return (
                      <li className={clsx(link.active && 'active')}>
                        <StyledLink
                          to={link.href}
                          style={{ textDecoration: 'none' }}
                          className={clsx(link.active && 'active')}
                        >
                          {link.title}
                        </StyledLink>
                      </li>
                    )
                  })}
                </StyledStack>
              </StyledAccordionDetails>
            </StyledAccordion>
          )
        }

        return (
          <>
            <Link to={item.href} style={{ textDecoration: 'none' }}>
              <StyledListItem
                key={item.title}
                disablePadding
                className={clsx(item.active && 'active')}
              >
                <StyledListItemButton
                  onClick={() => (open ? null : setOpen(true))}
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    marginBottom: '2px',
                  }}
                >
                  <StyledListItemIcon
                    sx={{
                      mr: open ? 2 : 0,
                    }}
                  >
                    {item.icon}
                  </StyledListItemIcon>

                  <StyledListItemText
                    primary={item.title}
                    sx={{ display: open ? 'block' : 'none' }}
                    className="title"
                  />
                </StyledListItemButton>
              </StyledListItem>
            </Link>
          </>
        )
      })}
    </StyledList>
  )
}

export const Menu: FunctionComponent<MenuProps> = ({
  items,
  logoIcon,
  drawerWidthMain,
  onClickLogout,
  closeDelay = 200,
  activateAutoOutsideMenu = false,
  isLoading = false
}) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);

  useOnClickOutside(wrapperRef, () => {
    if (activateAutoOutsideMenu === false || !activateAutoOutsideMenu) return;
    setOpen(false);
  }, closeDelay);

  const [activeIndex, setActiveIndex] = useState(-1);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    drawerWidthMain?.(open ? 290 : 98);
  }, [drawerWidthMain, open]);

  return (
    <Box ref={wrapperRef} sx={{ display: 'flex', position: 'relative' }}>
      <Drawer variant="permanent" open={open}>
        <LogoBox>{logoIcon}</LogoBox>
        {!isLoading ? (
          <RenderComponentMenu
            {...{
              activeIndex: activeIndex!,
              itemsList: items!,
              open: open!,
              setActiveIndex,
              setOpen,
            }}
          />
        ) : (
          <>
            {
              [1, 2, 3, 4, 5, 6, 7].map(item => {
                return (
                  <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='85%'
                    height={50}
                    sx={{
                      borderRadius: '5px',
                      margin: "10px 5px"
                    }}
                  />
                )
              })

            }
          </>
        )}
        <StyledDivider />
        <StyledListItem disablePadding sx={{ my: '40px' }}>
          <StyledListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
            }}
            onClick={() => onClickLogout && onClickLogout()}
          >
            <StyledListItemIcon
              sx={{
                mr: open ? 2 : 0,
              }}
            >
              <LogoutIcon htmlColor="#666666" />
            </StyledListItemIcon>
            <StyledListItemText
              primary="Sair"
              sx={{ display: open ? 'block' : 'none' }}
              className="title"
            />
          </StyledListItemButton>
        </StyledListItem>
        {!open ? (
          <StyledListItem disablePadding>
            <StyledListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={() => {
                if (!isLoading) {
                  handleDrawerOpen();
                }
              }}
            >
              <StyledListItemIcon
                sx={{
                  mr: open ? 2 : 0,
                }}
                aria-label="open drawer"
              >
                <ShowIcon htmlColor="#D04D27" />
              </StyledListItemIcon>
            </StyledListItemButton>
          </StyledListItem>
        ) : (
          <StyledListItem disablePadding>
            <StyledButton onClick={handleDrawerClose} fullWidth>
              Ocultar menu
            </StyledButton>
          </StyledListItem>
        )}
      </Drawer>
    </Box>
  );
};
