import React from 'react';
import { FeedbackErro, Typography } from '@uy3/web-components';
import { useRouteError } from 'react-router';

type ErrorElementProps = {
  fallback: string;
};

type MessageType = { message: string; stack: string };

const TitleTypography = (fallback: string) => {
  return (
    <Typography variant="xxl">
      Ops! Ocorreu um erro inesperado em <strong>{fallback}</strong>, tente novamente mais tarde, se o erro persistir contate o suporte.
    </Typography>
  );
};

export const ErrorElement: React.FC<ErrorElementProps> = ({ fallback }) => {
  const { message, stack } = useRouteError() as MessageType;
  return (
    <>
      <FeedbackErro
        open={true}
        title={TitleTypography(fallback)}
        description={message ?? stack}
        textButton="Voltar para o início"
        type="genericError"
      />
    </>
  );
};
