import { StepNewChargesButton } from '../../StepsButtons/StepNewChargesButton';
import CreateChargesContainer from './CreateChargesContainer';
import { ToastType } from 'contexts/apiRequestContext';

interface NewChargesStepProps {
    currentStep: number;
    onPrevStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    handleNextStep: () => void
}

export const CreateChargesStep: React.FC<NewChargesStepProps> = ({ currentStep, onPrevStep }) => {
    return (
        <>
            <CreateChargesContainer />
            <StepNewChargesButton currentStep={currentStep} onPrevStep={onPrevStep}/>
        </>
    );
};
