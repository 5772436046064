import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';

type FundFiltersProps = {
    currentValues: any,
    removeFilterSelected: (index: number) => void;
}

function FundFiltersForm() {
    const { watch } = useFormContext();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, "").length > 11;

    return (
        <>
            <SelectFormField
                name='optionFilter'
                label='Filtrar por'
                variant='outlined'
                fullWidth
                options={[
                    { label: "Todos", value: 'searchString' },
                    { label: "Nome", value: 'name' },
                    { label: "CPF/CNPJ", value: 'registrationNumber' },
                    { label: "Email", value: 'email' },
                    { label: "Data de criação", value: 'initialDate' },
                ]}
            />
            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    name='searchString'
                    variant='outlined'
                    label='Filtrar por todos'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'name' && (
                <TextFormField
                    name='name'
                    variant='outlined'
                    label='Filtrar por nome'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'registrationNumber' && (
                <TextFormField
                    name='registrationNumber'
                    variant='outlined'
                    label='Filtrar por CPF/CNPJ'
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    fullWidth
                />
            )}
            {watch('optionFilter') === 'email' && (
                <TextFormField
                    name='email'
                    variant='outlined'
                    label='Filtrar por email'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    )
}

export const FundFilters = ({ currentValues, removeFilterSelected }: FundFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<FundFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    )
}
