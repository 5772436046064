import type { FunctionComponent } from 'react';
import type { ModalProps } from './Modal.interface';

import CloseIcon from '@mui/icons-material/Close';
import clsx from 'clsx';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
  BoxIcon,
} from './Modal.styled';
import { Button, Typography } from '../../atoms';

export const Modal: FunctionComponent<ModalProps> = ({
  title,
  description,
  icon,
  direction,
  outlinedButton,
  containedButton,
  fullWidth,
  children,
  align,
  open,
  size,
  sizeModal,
  onClose,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ sx: { width: sizeModal === 'large' ? 550 : 460, maxWidth: '100%' } }}
    >
      <StyledDialogTitle>
        <StyledIconButton aria-label="Fechar Modal" data-testid="close-button" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Stack>
          <Stack direction={direction}>
            {icon && (
              <BoxIcon className={clsx(size)} sx={{ display: isMobile ? 'none' : 'flex' }}>
                {icon}
              </BoxIcon>
            )}
            <Stack direction="column" sx={{ width: '100%' }}>
              {title && (
                <Typography
                  variant="xl"
                  color="neutral.darkest"
                  lineHeight="24px"
                  mb={1}
                  textAlign={align}
                >
                  {title}
                </Typography>
              )}

              {description && (
                <Typography
                  variant="xs"
                  color="neutral.medium"
                  lineHeight="19px"
                  textAlign={align}
                >
                  {description}
                </Typography>
              )}
            </Stack>
          </Stack>

          {children}

          <Stack
            direction={direction === 'column' ? 'column-reverse' : 'row'}
            alignItems="center"
            justifyContent="center"
            mt={3}
            sx={{ flexDirection: isMobile ? 'column-reverse' : 'row' }}
          >
            {outlinedButton && (
              <Button
                variant="outlined"
                size="medium"
                sx={{ width: '100%', marginTop: isMobile ? '16px' : 0 }}
                fullWidth={fullWidth}
              >
                {outlinedButton}
              </Button>
            )}
            {containedButton && (
              <Button
                variant="contained"
                size="medium"
                fullWidth={fullWidth}
                sx={{ width: '100%', marginLeft: isMobile ? 0 : '16px' }}
              >
                {containedButton}
              </Button>
            )}
          </Stack>
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};
