import ErrorBoundary from '.';

interface ErrorThrowerProps {
    status: string;
    error: Error | unknown;
    children: React.ReactNode;
}

const ErrorThrower: React.FC<ErrorThrowerProps> = ({ status, error, children }) => {
    if (status === 'error') {
        throw error;
    }
    return <>{children}</>;
};

interface GenericErrorBoundaryProps {
    status: string;
    error: Error | unknown;
    fallback: string;
    children: React.ReactNode;
}

const GenericErrorBoundary: React.FC<GenericErrorBoundaryProps> = ({
    status,
    error,
    fallback,
    children
}) => {
    return (
        <ErrorBoundary fallback={`${fallback}`}>
            <ErrorThrower status={status} error={error}>
                {children}
            </ErrorThrower>
        </ErrorBoundary>
    );
};

export default GenericErrorBoundary;
