import styled from '@emotion/styled';
import { LinearProgress, Stack, linearProgressClasses } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SelectOption } from 'components/Forms/FormFields';
import moment from 'moment';
import React from 'react';
import { activeTheme } from 'services/theme';

type CardDetailsProps = {
    options: SelectOption[];
    updatedAt: string | Date
}

const theme = activeTheme();

const ContainerCard = styled(Stack)(({
    border: '0.5px solid #BABFD0',
    borderRadius: '5px',
    // padding: '20px',
    position: 'relative',
    height: 300,
}))

const GridItem = styled(Stack)(({
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    bottom: 0,
    textAlign: 'center',
}))

const GridLinearProgress = styled(Stack)(({
    maxWidth: '100%',
    position: 'relative',
    margin: "80px 50px"
}))

const TypographyHour = styled(Typography)(({
    position: 'absolute',
    fontWeight: 'bold',
    bottom: -25,
}))

//@ts-ignore
const BorderLinearProgress = styled(LinearProgress)(() => ({
    height: 10,
    borderRadius: 5,
    position: 'relative',
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#BABFD0',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.main,
    }
}));


export const CardDetails: React.FC<CardDetailsProps> = ({ options, updatedAt }) => {
    let currentHourInt = new Date().getHours();
    let customCurrentHour = currentHourInt * 4.5;

    let styleMarkerByHour = {
        display: 'block',
        position: 'absolute',
        background: theme.palette.primary.main,
        padding: 10,
        borderRadius: '8px',
        color: 'white',
        top: currentHourInt === 22 ? -23 : -12,
        fontSize: '12px',
        left: `${customCurrentHour - 2}%`
    } as React.CSSProperties;

    return (
        <ContainerCard>
            <Typography
                variant='h4'
                sx={{
                    fontWeight: 'bold',
                    margin: "30px 30px 20px 30px"
                }}
            >
                Total de operações hoje
            </Typography>
            <Typography
                variant='sm'
                color='#666'
                sx={{
                    margin: "-20px  30px"
                }}
            >
                Todas as operações determinadas no período
            </Typography>

            <GridLinearProgress>
                <BorderLinearProgress variant="determinate" value={currentHourInt === 23 ? 100 : customCurrentHour} />
                {currentHourInt !== 23 &&
                    <Typography style={styleMarkerByHour}>
                        {moment(updatedAt).format('LT')}
                    </Typography>}

                <TypographyHour variant='md' left={-20}>00:00</TypographyHour>
                <TypographyHour variant='md' right={-20}>23:59</TypographyHour>
            </GridLinearProgress>

            <Stack position='absolute' width='100%' bottom={0}>
                <GridItem>
                    {options.map(item => {
                        return (
                            <Stack sx={{
                                border: '0.5px solid #BABFD0',
                                borderBottom: 'none',
                                borderRight: 'none',
                                padding: '10px',
                            }}>
                                <Typography variant='sm' color='#666'>{item.label}</Typography>
                                <Typography variant='h4' fontWeight='700'>{item.value}</Typography>
                            </Stack>
                        )
                    })}
                </GridItem>
            </Stack>
        </ContainerCard>
    );
}