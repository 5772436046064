import { string, object, number } from 'yup';

const genericRequiredMessage = 'Precisa ser preenchido.';
export const validationSchemaAddAccountEntryForm = object().shape({
    amount: number()
        .typeError('Valor precisa ser um número.')
        .min(0)
        .required(`Valor: ${genericRequiredMessage}`)
        .positive(`Valor: Precisa ser positivo.`),
    documentNumber: string()
        .required(`Documento : ${genericRequiredMessage}`)
        .typeError('Documento inválido.')
        .max(20, 'Documento: Máximo de 20 caracteres.'),
    complement: string()
        .required(`Complemento  : ${genericRequiredMessage}`)
        .typeError('Complemento  inválido.')
        .max(25, 'Complemento: Máximo de 25 caracteres.'),
    entryType: string()
        .oneOf(['BilletPayment', 'TransferFeeDebit'])
        .required('Selecione o tipo de lançamento.')
        .typeError('Tipo de lançamento inválido.'),
});

export const defaultValuesAddAccountEntryForm = {
    amount: 0,
    documentNumber: "",
    complement: "",
    entryType: 'BilletPayment',
};
