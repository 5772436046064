import { Grid } from '@mui/material';
import { Button, Popup } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import React from 'react';
import { deleteCreditNoteById } from 'services/creditNote';

interface IpopupDeleteProps {
    onClose: () => void;
    rowData: any;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetch: () => void;
}

const PopupDelete: React.FC<IpopupDeleteProps> = ({ onClose, rowData, setToast, refetch }) => {
    const { token } = useIdentity();

    const onDelete = async (id: string) => {
        await deleteCreditNoteById(id, token!)
            .then(() => {
                const title = 'Operação excluída com sucesso!';
                const description = `A operação #${rowData?.row?.creditNoteNo} foi excluída com sucesso.`;
                showSuccessToast(title, description, setToast);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);
            })
            .finally(() => onClose());

        await refetch();
    };

    return (
        <Popup
            open={rowData !== undefined}
            title={rowData ? `Excluir #${rowData?.row?.creditNoteNo}?` : 'Excluir'}
            text={`Tem certeza que deseja excluir esta operação?`}
            onClose={onClose}
            children={
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onDelete(rowData.id)}
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            }
        />
    );
};

export default PopupDelete;
