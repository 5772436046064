/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Grid, Stack } from '@mui/material';
import { CheckboxFormField, SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { Alert, Button } from '@uy3/web-components';
import { useApiRequest } from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useFormContext } from 'contexts/formContext';

type ApproveLiquidationFormFormProps = {
    onClose: () => void;
    cosifDataOptions: any;
    optionsPersonBankList?: SelectOption[];
    formType: 'batchAssignment' | 'creditNote';
    creditAccountId: string;
};

export const ApproveLiquidationFormForm = ({
    onClose,
    cosifDataOptions,
    optionsPersonBankList,
    formType,
    creditAccountId
}: ApproveLiquidationFormFormProps) => {
    const { submitError, submitting } = useApiRequest();
    const { setValue } = useFormContext();

    useEffect(() => {
        if (creditAccountId && !submitting) setValue('creditAccountId', creditAccountId);
    }, []);

    return (
        <Stack>
            <Grid mt={3}>
                <SelectFormField
                    required
                    name="cosifAccountCode"
                    label="Conta de débito"
                    options={cosifDataOptions}
                />
            </Grid>
            {formType === 'creditNote' && (
                <Grid mt={3}>
                    <SelectFormField
                        name="creditAccountId"
                        label="Conta de crédito"
                        options={optionsPersonBankList}
                    />
                </Grid>
            )}
            <Grid mb={1}>
                <CheckboxFormField
                    name="isAutomaticBankTransfer"
                    label="Lançar transação automaticamente"
                />
            </Grid>
            <Alert
                severity="warning"
                text="Ao selecionar essa opção, será criado um lançamento no core bancário e uma nova
                    transferência será realizada para o beneficiário da operação."
            />

            {submitError && <ApiErrorAlert error={submitError} />}

            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Button
                    name="btn-CancelApproveLiq"
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    name="btn-ConfirmApproveLiq"
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    );
};
