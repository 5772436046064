/* eslint-disable no-mixed-operators */
import { useState } from 'react';
import { DataTable, EditIcon, SearchIcon } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { Grid, IconButton, Stack } from '@mui/material';
import { HandleTitle } from 'components/Datasets/DatasetNaturalPerson/TypographyAndValues';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { CreditStatusReadModel } from 'services/creditStatusRequest';

interface ICreditLimitListProps {
    result: CreditStatusReadModel[];
    setRowData: (rowData: any) => void;
    setModal: (modal: boolean) => void;
    setOpenDrawer: (open: boolean) => void;
};

function CreditLimitList({ result, setRowData, setModal, setOpenDrawer }: ICreditLimitListProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'createdAt',
            headerName: 'Solicitado em',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues && new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'requestedLimit',
            headerName: 'Limite solicitado',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? formatCurrencyInCents(cellValues.value) : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'approvedLimit',
            headerName: 'Limite aprovado',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? formatCurrencyInCents(cellValues.value) : 'N/D'}
                    </div>
                );
            }
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            align: "left",
            headerAlign: 'center',
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent='center'
                        alignItems='center'
                    >
                        {cellValues.row?.statusDisplay === 'Pendente' &&
                            <IconButton onClick={() => { setRowData(cellValues?.row); setModal(true) }}>
                                <EditIcon
                                    sx={{ height: 20, width: 20, ml: 1 }}
                                />
                            </IconButton>
                        }
                        <IconButton onClick={() => { setOpenDrawer(true); setRowData(cellValues?.row) }}>
                            <SearchIcon
                                sx={{ height: 20, width: 20, ml: 1 }}
                            />
                        </IconButton>
                    </Stack>
                );
            }
        },
    ];

    return (
        <>
            <HandleTitle title={'Status'} value={result?.[0]?.statusDisplay ?? 'N/D'} key='statusDisplay' />
            <HandleTitle title={'Limite aprovado'} value={formatCurrencyInCents(result?.[0]?.approvedLimit) ?? 'N/D'} key='approvedLimit' />
            <DataTable
                getRowId={(row) => Math.random().toString()}
                columns={columns}
                rows={result ?? []}
                page={page}
                rowCount={result?.length ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
        </>
    );
}

export default CreditLimitList;