import { IOption } from 'components/CardSelect/CardSelect';
import { formatCurrencyInCents, isNegativeValue } from 'helpers';
import { IProductMarginQuery, ISiapeMarginQueryReadModel } from 'services/datasets';
import { TooltipWarrantyProductSelected } from '../SelectWarranty/TooltipWarrantyProductSelected';

export function summarySiape(data: ISiapeMarginQueryReadModel | undefined): IOption[] {
    const vinculoFuncionals = data?.result?.VinculoFuncionals ?? [];
    const createdAt = data?.createdAt!;

    const options = vinculoFuncionals?.map((item) => {
        const vlMargemDisp = showInStrongMarginValue(item?.Produtos);
        const isDisabled = item?.Produtos?.some((p) => isNegativeValue(p?.VlMargemDisp));

        return {
            label: `${item?.NmOrgao}`,
            description: `Matrícula: ${item.CdMatricula} | Data da consulta: ${new Date(
                createdAt
            ).toLocaleDateString()} | Valor da margem: ${vlMargemDisp}`,
            value: `${item?.CnpjOrgao}-${item?.CodOrgao}`,
            disabled: isDisabled,
            tooltipComponent: {
                title: <TooltipWarrantyProductSelected values={item} />,
                placement: 'left',
            },
        };
    }) as IOption[];

    return options;
}

const showInStrongMarginValue = (products: IProductMarginQuery[]) => {
    const margemDisp = products?.map((m) => {
        const isNegative = isNegativeValue(m?.VlMargemDisp);
        const vlMargemDisp = formatCurrencyInCents(m?.VlMargemDisp);
        const vlMargem = isNegative ? 'Margem indisponível' : vlMargemDisp;

        return vlMargem;
    });
    return margemDisp.join();
};
