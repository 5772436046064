import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const DocPdfIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <path
        d="M13.1111 1V5.4C13.1111 7.83005 15.101 9.8 17.5556 9.8L22 9.8M2 5.4L2 18.6C2 21.0301 3.98985 23 6.44445 23H17.5556C20.0102 23 22 21.0301 22 18.6V11.6225C22 10.4556 21.5317 9.33643 20.6983 8.51127L14.4129 2.28873C13.5794 1.46357 12.4489 1 11.2702 1L6.44444 1C3.98985 1 2 2.96995 2 5.4Z"
        stroke={htmlColor}
        strokeWidth="2"
        strokeLinejoin="round"
        fill='none'
      />
      <path
        d="M6.43652 18V13.7051H7.82812C8.35547 13.7051 8.69922 13.7266 8.85938 13.7695C9.10547 13.834 9.31152 13.9746 9.47754 14.1914C9.64355 14.4062 9.72656 14.6846 9.72656 15.0264C9.72656 15.29 9.67871 15.5117 9.58301 15.6914C9.4873 15.8711 9.36523 16.0127 9.2168 16.1162C9.07031 16.2178 8.9209 16.2852 8.76855 16.3184C8.56152 16.3594 8.26172 16.3799 7.86914 16.3799H7.30371V18H6.43652ZM7.30371 14.4316V15.6504H7.77832C8.12012 15.6504 8.34863 15.6279 8.46387 15.583C8.5791 15.5381 8.66895 15.4678 8.7334 15.3721C8.7998 15.2764 8.83301 15.165 8.83301 15.0381C8.83301 14.8818 8.78711 14.7529 8.69531 14.6514C8.60352 14.5498 8.4873 14.4863 8.34668 14.4609C8.24316 14.4414 8.03516 14.4316 7.72266 14.4316H7.30371Z"
        stroke={htmlColor}
        fill='none'
      />
      <path
        d="M10.4355 13.7051H12.0205C12.3779 13.7051 12.6504 13.7324 12.8379 13.7871C13.0898 13.8613 13.3057 13.9932 13.4854 14.1826C13.665 14.3721 13.8018 14.6045 13.8955 14.8799C13.9893 15.1533 14.0361 15.4912 14.0361 15.8936C14.0361 16.2471 13.9922 16.5518 13.9043 16.8076C13.7969 17.1201 13.6436 17.373 13.4443 17.5664C13.2939 17.7129 13.0908 17.8271 12.835 17.9092C12.6436 17.9697 12.3877 18 12.0674 18H10.4355V13.7051ZM11.3027 14.4316V17.2764H11.9502C12.1924 17.2764 12.3672 17.2627 12.4746 17.2354C12.6152 17.2002 12.7314 17.1406 12.8232 17.0566C12.917 16.9727 12.9932 16.835 13.0518 16.6436C13.1104 16.4502 13.1396 16.1875 13.1396 15.8555C13.1396 15.5234 13.1104 15.2686 13.0518 15.0908C12.9932 14.9131 12.9111 14.7744 12.8057 14.6748C12.7002 14.5752 12.5664 14.5078 12.4043 14.4727C12.2832 14.4453 12.0459 14.4316 11.6924 14.4316H11.3027Z"
        stroke={htmlColor}
        fill='none'
      />
      <path
        d="M14.7803 18V13.7051H17.7246V14.4316H15.6475V15.4482H17.4404V16.1748H15.6475V18H14.7803Z"
        stroke={htmlColor}
        fill='none'
      />
    </SvgIcon>
  );
};

DocPdfIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none'
};
