import { Grid, Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { CheckboxFormField, TextFormField } from 'components/Forms/FormFields'
import { FormProvider, useFormContext } from 'contexts/formContext';
import { validationSchemaDisapproveMarginReverse, defaultvaluesDisapproveMarginReverse } from './DisapproveMarginReverse';
import { FieldValues } from 'react-hook-form';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type DisapproveMarginReverseFormProps = {
    onClose: () => void;
    handleSubmit: (values: FieldValues) => void;
}

export const DisapproveMarginReverseForm = ({ onClose, handleSubmit }: DisapproveMarginReverseFormProps) => {
    const { getValues } = useFormContext();
    const creditNoteValues = getValues() as CreditNoteReadModel;
    
    const includePaymentFixedCostsValue = creditNoteValues.amortization.includePaymentFixedCosts;
    const valueMarginReserveValue = creditNoteValues.amortization.requestedAmount;

    return (
        <>
            <FormProvider
                defaultValues={defaultvaluesDisapproveMarginReverse(includePaymentFixedCostsValue, valueMarginReserveValue)}
                validationSchema={validationSchemaDisapproveMarginReverse()}
                onSubmit={handleSubmit}
            >
                <Stack spacing={2}>
                    <Grid>
                        <TextFormField
                            label='Motivo de rejeição'
                            name='message'
                            required
                            fullWidth
                            variant='outlined'
                        />
                    </Grid>
                    <Grid>
                        <CurrencyFormField
                            label='Valor da margem'
                            name='valueMarginReserve'
                            fullWidth
                            variant='outlined'
                        />
                    </Grid>
                    <Grid>
                        <CheckboxFormField name='includePaymentFixedCosts' label='Incluir cliente como Participante de Regime de Previdência' />
                    </Grid>
                    <Stack direction='row' alignItems='center' justifyContent='right' spacing={2}>
                        <Button variant='outlined' onClick={onClose}>
                            Não, cancelar
                        </Button>
                        <Button variant='contained' type='submit'>
                            Sim, confirmar
                        </Button>
                    </Stack>
                </Stack>
            </FormProvider>
        </>
    )
}