import { Button, Popup } from '@uy3/web-components';
import React from 'react';
import { Grid } from '@mui/material';
import { RefreshProgress } from 'components/RefreshProgress';
import { useApiRequest } from 'contexts/apiRequestContext';

interface IActionButtonProps {
    onClick: () => void;
    label: string;
    variant: 'outlined' | 'contained';
    color: 'primary' | 'secondary';
    disabled?: boolean;
    isLoading?: boolean;
}

const ActionButton: React.FC<IActionButtonProps> = ({
    onClick,
    label,
    variant,
    color,
    disabled,
    isLoading,
}) => (
    <Button
        variant={variant}
        color={color}
        onClick={onClick}
        size="medium"
        disabled={disabled}
        sx={{ mt: 3, ...(variant === 'contained' && { ml: 2 }) }}
    >
        {isLoading ? 'Aguarde...' : label}
    </Button>
);

interface IConfirmPopupProps {
    open: boolean;
    title: string;
    text: string;
    onClose: () => void;
    isLoading: boolean;
    onConfirm: () => void;
}

const ConfirmPopup: React.FC<IConfirmPopupProps> = ({
    open,
    title,
    text,
    onClose,
    isLoading,
    onConfirm,
}) => {
    const { submitting } = useApiRequest();

    return (
        <Popup open={open} title={title} text={text} onClose={onClose}>
            <RefreshProgress refreshing={submitting} />
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <ActionButton
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    label="Não, cancelar"
                    disabled={isLoading}
                />
                <ActionButton
                    variant="contained"
                    color="primary"
                    onClick={onConfirm}
                    label="Sim, confirmar"
                    disabled={isLoading}
                    isLoading={isLoading}
                />
            </Grid>
        </Popup>
    );
}
export default ConfirmPopup;
