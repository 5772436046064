import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useFormContext } from 'contexts/formContext';
import React from 'react';

export default function FinancialInformation() {
    const { watch } = useFormContext();
    const name = watch('name');
    const registrationNumber = watch('registrationNumber');

    return (
        <React.Fragment>
             <CardInfo {...{ name, registrationNumber }} />
            <Typography variant="lg" color="common.black">
                Informações financeiras
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <CurrencyFormField
                    label="Faturamento médio mensal (Últimos 12 Meses)"
                    name="averageMonthlyRevenue"
                    variant="outlined"
                    fullWidth
                />
                <CurrencyFormField
                    label="Margem média de Lucro Bruto"
                    name="averageGrossProfitMargin"
                    variant="outlined"
                    fullWidth
                />
                <CurrencyFormField
                    label="Capital social inicial"
                    name="initialShareCapital"
                    variant="outlined"
                    fullWidth
                />
                <CurrencyFormField
                    label="Patrimônio Líquido"
                    name="netWorth"
                    variant="outlined"
                    fullWidth
                />
                <CurrencyFormField
                    label="Faturamento médio mensal (Arranjo)"
                    name="purchases"
                    variant="outlined"
                    fullWidth
                />
                <PercentageFormField
                    label="Exportação (em %)"
                    name="exportPercentage"
                    variant="outlined"
                    fullWidth
                />
                <CurrencyFormField
                    label="Custo com Fornecedores"
                    name="costWithSuppliers"
                    variant="outlined"
                    fullWidth
                />
                <TextFormField
                    label="Número de Funcionários"
                    name="numberOfEmployees"
                    variant="outlined"
                    fullWidth
                    type="number"
                    inputProps={{ min: 0 }}
                />
                <CurrencyFormField
                    label="Valor gasto com imposto"
                    name="spendingAmountWithTax"
                    variant="outlined"
                    fullWidth
                />
            </Stack>
        </React.Fragment>
    );
}
