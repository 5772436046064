import React from 'react'
import { ApprovalLimitForm } from './ApprovalLimitForm'
import { Stack } from '@mui/material'
import InfoMessage from 'components/InfoMessage/InfoMessage'
import { Typography } from '@uy3/web-components'
import { ReadCurrentLimit } from './ReadCurrentLimit/ReadCurrentLimit'
import { FieldValues } from 'react-hook-form'
import { FormProvider } from 'contexts/formContext'
import { defaultValuesRequestLimitForm, validationSchemaRequestLimitForm } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/MyLimits/DrawerForm/RequestLimitSchema'

type ApprovalLimitFormContainerProps = {
    rowData: FieldValues; 
    onClose: () => void;
    handleSubmit: (values: FieldValues) => void;
}

export const ApprovalLimitFormContainer = ({ rowData, onClose, handleSubmit }: ApprovalLimitFormContainerProps) => {
    const bankAccountTransactionLimits = rowData?.bankAccountTransactionLimits; 

    const defaultValuesBankAccountTransactionLimits = {
        bankSlipLimit: bankAccountTransactionLimits?.bankSlipLimit, 
        pixLimit: bankAccountTransactionLimits?.pixLimit, 
        tedLimit: bankAccountTransactionLimits?.tedLimit, 
        transferLimit: bankAccountTransactionLimits?.transferLimit
    }

    return (
        <Stack spacing={3} mt={-2}>
            <InfoMessage
                title='Horários'
                message='Horário comercial: 06 às 20h / Fora do horário: 20h às 06h.'
            />

            <Typography variant='md' fontSize='20.65px' fontWeight='bold'>Limite atual</Typography>

            <ReadCurrentLimit rowData={rowData} />

            <Typography variant='md' fontSize='20.65px' fontWeight='bold'>Novos limites solicitados</Typography>

            <FormProvider
                validationSchema={validationSchemaRequestLimitForm()}
                defaultValues={defaultValuesBankAccountTransactionLimits ?? defaultValuesRequestLimitForm}
                onSubmit={handleSubmit}
            >
                <ApprovalLimitForm onClose={onClose} rowData={rowData}/>
            </FormProvider>
        </Stack>
    )
}
