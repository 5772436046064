import { useQuery } from '@tanstack/react-query';
import { getLegalPersonById } from 'services/accounts/legalPerson';
import { getNaturalPersonById } from 'services/accounts/naturalPerson';
import { useIdentity } from './identityContext';
import { useApiRequest, GetViewApiResponseSuccess, ApiResponseError } from './apiRequestContext';
import { isAxiosError } from 'axios';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { formatBankAccountForOption } from 'helpers/formats/BankAccount';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { replacePhoneNumber } from 'helpers/formats/PhoneNumber';
import { isEmpty } from 'lodash';

type Discriminator = 'NaturalPerson' | 'LegalPerson';

export type RelatedBankAccountType = {
    label: string;
    value: string;
    operationTypeValue?: string;
    bankCode?: string;
    name?: string;
    account?: string;
    agency?: string;
    bankCodeDisplay?: string;
};

type RelatedBankAccountData = {
    relatedBankAccountData: RelatedBankAccountType[];
    data: any;
};

export const useRelatedBankAccountData = (id: string, discriminator: Discriminator | undefined) => {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery<RelatedBankAccountData>({
        enabled: Boolean(token) && Boolean(id) && !isEmpty(id) && Boolean(discriminator),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["related-bank-account", id, discriminator],
        queryFn: async (): Promise<RelatedBankAccountData> => {
            startRequest();
            let data, status;
            try {
                if (discriminator === "NaturalPerson") {
                    ({ data, status } = await getNaturalPersonById(id, token!));
                } else if (discriminator === "LegalPerson") {
                    ({ data, status } = await getLegalPersonById(id, token));
                }
                endRequest(true);
                if (status && status >= 400 && status <= 599) {
                    throw data;
                };
                const personData = data as GetViewApiResponseSuccess<NaturalPersonReadModel | LegalPersonReadModel | undefined>
                return {
                    relatedBankAccountData: personData?.bankAccounts?.map((bank) => {
                        if (bank.pixKeyTypeValue === 'Phone' && !!bank.keyPix) {
                            bank.keyPix = replacePhoneNumber(bank.keyPix);
                        }
                        return formatBankAccountForOption(bank);
                    }),
                    data: personData
                };
            } catch (error) {
                if (isAxiosError(error)) {
                    setSubmitError(error.response?.data as ApiResponseError);
                    endRequest(false);
                }
                throw error;
            }
        }
    });

    return { status, data, error, isFetching, refetch };
};
