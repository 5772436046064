import { FormProvider } from 'contexts/formContext';
import { BatchAssignmentFilters } from './BatchAssignmentFilters';
import { batchAssignmentFiltersSchema, defaultValuesBatchAssignmentFiltersSchema } from './BatchAssignmentFiltersSchema';
import { useFilters } from 'contexts/filterContext';

export const BatchAssignmentFiltersContainer = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const onSubmitSearch = (values: any) => {
        let { optionFilter } = values;

        if (optionFilter === 'initialAssignmentDate') {
            const finalAssignmentDate = new Date(values.finalAssignmentDate);
            addFilter(optionFilter, values[optionFilter], finalAssignmentDate);
        } else {
            addFilter(optionFilter, values[optionFilter]);
        }
    };

    const schema = batchAssignmentFiltersSchema();
    const defaultValues = defaultValuesBatchAssignmentFiltersSchema;

    return (
        <>
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmitSearch}
            >
                <BatchAssignmentFilters
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    )
}
