import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react';

interface IBankAccountActiveSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const BankAccountActiveSkeleton: React.FC<IBankAccountActiveSkeleton> = ({
    children,
    isLoading
}) => {

    if (!isLoading) {
        return (children);
    }

    return (
        <Stack spacing={4} mr={3} mb={4}>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={150}
                sx={{
                    borderRadius: '5px'
                }}
            />
            <Stack display='grid' gridTemplateColumns='auto auto' gap={6}>
                <Stack sx={{ display: 'grid', gridTemplateColumns: '20% auto', gap: 2 }}>
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height={120}
                        sx={{
                            borderRadius: '5px'
                        }}
                    />
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height={120}
                        sx={{
                            borderRadius: '5px'
                        }}
                    />
                </Stack>
                <Stack sx={{ display: 'grid', gridTemplateColumns: '20% auto', gap: 2 }}>
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height={120}
                        sx={{
                            borderRadius: '5px'
                        }}
                    />
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height={120}
                        sx={{
                            borderRadius: '5px'
                        }}
                    />
                </Stack>
            </Stack>
            <Stack pt={4} spacing={2}>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={60}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={600}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            </Stack>
        </Stack>
    )
}
