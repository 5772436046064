import { DefaultRecordDetails } from 'contexts/apiRequestContext';
import {
    ECommissionBaseValue,
    ECommissionType,
    EOperationType,
    ERelatedBankAccountType,
    ESignatureType,
    ESignatureValidation,
    ETypeOfRelationship,
} from 'utils/enums';

export interface IAddressModel {
    addressName: string;
    zipCode: string;
    city: string;
    state: string;
    uf: string;
    ufDisplay: string;
    district: string;
    number: string;
    complement: string;
}

export interface GroupInfo {
    groupId: string;
    groupDisplay: string;
    tenant: string;
    tenantDisplay: string;
}

export interface ICreatedBy {
    userId: string;
    userIdDisplay: string;
    tenant: string;
    tenantDisplay: string;
}

export interface IEconomicActivityCode {
    code: string;
    description: string;
}

export interface IPersonCreditStatus {
    registrationNumber: string;
    validUntil: string;
    status: string;
    reason: string;
    detailedReason: string;
    approvedLimit: number;
}

export interface ICommissionModel {
    amount: number;
    type: ECommissionType;
    baseValue: ECommissionBaseValue;
}

export interface IUploadReadModel extends DefaultRecordDetails {
    fileName: string;
    fileType: string;
    displayName: string;
    tempGetUrl: string;
    tempUrl: string;
    tempPutUrl: string;
    signatureWorkFlowId?: string;
}

export interface IBankAccountReadModel extends DefaultRecordDetails {
    bankCode: string;
    bankCodeCompe: number;
    bankCodeDisplay: string;
    bankIspb: number;
    account: string;
    accountDigit: string;
    agency: string;
    agencyDigit: string;
    type: string;
    typeDisplay: string;
    jointAccount: boolean;
    operationTypeValue: string;
    pixKeyTypeValue: string;
    keyPix: string | null;
    accountNumber?: number;
    pixKeyTypeValueDisplay?: string;
    operationTypeValueDisplay?: string;
    bankDisplay?: string;
}

export interface IRelatedPersonCreateModel {
    personId: string;
    typeOfRelationship: ETypeOfRelationship;
    signatureType: ESignatureType;
    signatureValidation: ESignatureValidation;
    userId?: string
    level?: string;
    tenantName?: string;
    relatedToId?: string;
}

export interface IRelatedPersonUpdateModel {
    personId: string;
    discriminator: string;
    personIdDisplay: string;
    typeOfRelationship: string;
    typeOfRelationshipDisplay: string;
    signatureType: string;
    signatureValidation: string;
}

export class BankAccountCreateOrUpdateModel {
    id?: string;
    bankCode?: number;
    bankIspb?: number;
    account?: string;
    accountDigit?: string;
    agency?: string;
    agencyDigit?: string;
    type?: ERelatedBankAccountType;
    jointAccount?: boolean;
    operationTypeValue?: EOperationType;
    pixKeyTypeValue?: string;
    keyPix?: string | null;

    constructor(obj?: BankAccountCreateOrUpdateModel) {
        Object.assign(this, obj);
    }

}

export type PopupState = {
    title: string;
    message: string;
    children: JSX.Element;
} | undefined;

export type ModalState = {
    title: string;
    description: string;
    children: JSX.Element;
} | undefined;

export type DrawerState = {
    title: string;
    description: string;
    children: JSX.Element;
} | undefined;