import { useQueryClient } from '@tanstack/react-query';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { useNavigate } from 'react-router';
import { SignatureCreditNoteProps, signatureCreditNoteById } from 'services/creditNote';
import { formatMessageReasonsDisapprove } from 'helpers/formats/ArrayFormats';
import { defaultValuesRejectForm, validationSchemaRejectForm } from '../ApproveAndReject/RejectSchema';
import RejectForm from '../ApproveAndReject/RejectForm';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';

type RejectSignaturesValidationProps = {
  setPopupProps: (props: any) => void;
  statusCreditNote: string;
  onClose?: () => void;
  listOfIds?: CreditNoteReadModel[]
  onErrorCatch: (response: ApiResponseError) => void
};

type SignatureApproveType = {
  message: string;
  reasonDisapprovalDisplay: string[]
};

export const RejectSignaturesValidation = ({ setPopupProps, statusCreditNote, onErrorCatch, listOfIds, onClose }: RejectSignaturesValidationProps) => {
  const { startRequest } = useApiRequest();
  const { watch } = useFormContext();
  const navigate = useNavigate();
  const { token } = useIdentity();
  const queryClient = useQueryClient();

  const handleSignature = async (values: SignatureApproveType, type: "signature" | "approveSignature") => {
    const { message, reasonDisapprovalDisplay } = values;

    const payloadMessage = {
      token: token!,
      reject: true,
      message: formatMessageReasonsDisapprove(reasonDisapprovalDisplay, 'Outros motivos', message)!,
      type
    } as SignatureCreditNoteProps;

    startRequest();
    listOfIds?.map(({ id }) => {
      return signatureCreditNoteById({ ...payloadMessage, id })
        .then(() => {
          queryClient.invalidateQueries({
            queryKey: ['credit-note-list'],
            refetchType: 'active',
          });
          navigate('/ccb/operacoes');
          onClose && onClose()
        }).catch((response) => onErrorCatch(response));
    })
  };

  const schema = validationSchemaRejectForm(statusCreditNote);
  const defaultValues = defaultValuesRejectForm;

  return (
    <FormProvider
      validationSchema={schema}
      defaultValues={defaultValues}
      onChangeField={[
        {
          fieldName: 'insertBlock',
          delegate: (value: string | null, setValue: (name: any, value: any) => void) =>
            setValue('insertBlock', value),
        }
      ]}
      onSubmit={(values) => handleSignature(values, 'approveSignature')}
    >
      <RejectForm
        onClose={() => setPopupProps(undefined)}
        statusCreditNote={statusCreditNote}
        personInformation={{
          discriminator: watch('personDiscriminator'),
          personId: watch('personId')
        }}
      />
    </FormProvider>
  );
};
