import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ApproveIcon, PersonIcon, RejectIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

interface FavoredHeaderProps {
    isFetching: boolean;
    beneficiarieRefetch: () => void;
    setStepFavored: (step: string) => void;
    enableBatchActionApprove: boolean
}

const theme = activeTheme();
const FavoredHeader: React.FC<FavoredHeaderProps> = ({
    isFetching,
    beneficiarieRefetch,
    setStepFavored,
    enableBatchActionApprove
}) => {
    const { bankAccountId } = useParams();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const queryClient = useQueryClient();

    async function favoredRefetch() {
        beneficiarieRefetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction="row" justifyContent="space-between" mb={3} alignItems="center">
            <Typography variant="xxl" color="neutral.dark" lineHeight="38.4px">
                Favorecidos
            </Typography>
            <Actions
                numberOfButtons={4}
                buttonsActionsList={[
                    {
                        enable: enableBatchActionApprove,
                        label: "Aprovar",
                        action: () => setStepFavored('confirmMfaApprove'),
                        icon: <ApproveIcon sx={iconSx} htmlColor={color} />,
                        disabled: false
                    },
                    {
                        enable: enableBatchActionApprove,
                        label: "Reprovar",
                        action: () => setStepFavored('confirmMfaReject'),
                        icon: <RejectIcon sx={iconSx} htmlColor={color} />,
                        disabled: false
                    },
                    {
                        enable: true,
                        label: "Adicionar favorecido",
                        action: () => setStepFavored('bankAccountfavoredForm'),
                        icon: <PersonIcon sx={iconSx} htmlColor={color} />,
                        disabled: false
                    },
                    {
                        enable: true,
                        label: "Atualizar",
                        action: favoredRefetch,
                        icon: <UpdateIcon sx={iconSx} htmlColor={color} />,
                        disabled: isFetching
                    },
                ]}
            />
        </Stack>
    );
};

export default FavoredHeader;
