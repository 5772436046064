import { Grid } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { useParams } from "react-router";
import { approveCreditNoteById } from "services/creditNote";
import { FormProvider } from "contexts/formContext";
import { FieldValues } from 'react-hook-form';
import { defaultValuesApproveForm, validationSchemaApproveForm } from "./ApproveSchema";
import { useCreditData } from "contexts/creditNote/creditContext";

type ApproveCreditNoteProps = {
    setPopupProps: (props: any) => void;
    onErrorCatch: (response: ApiResponseError) => void; 
    navigateWithFilters: (path: string) => void
};

export const ApproveCreditNote = ({ setPopupProps, onErrorCatch, navigateWithFilters}: ApproveCreditNoteProps) => {
    const { startRequest, endRequest } = useApiRequest();
    const { token } = useIdentity();
    const { id } = useParams();
    const queryClient = useQueryClient();
    const { creditData } = useCreditData(id!);

    const onSubmit = async (values: FieldValues) => {
        const { message } = values;
        startRequest();
        const status = creditData.status;
        await approveCreditNoteById(id!, token!, status, { message }).then(() => {
            queryClient.invalidateQueries({
                queryKey: ["credit-note-list"],
                refetchType: 'active',
            });
            endRequest(true)
            navigateWithFilters('/ccb/operacoes');
            setPopupProps(undefined);
        }).catch((error: ApiResponseError) => onErrorCatch(error))
    };

    const schema = validationSchemaApproveForm();
    const defaultValues = defaultValuesApproveForm;

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={onSubmit}>
            <>
                <Grid container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >
                    <Grid item xs={12} mt={1}>
                        <TextFormField
                            name="message"
                            label="Mensagem"
                            multiline
                            rows={3}
                            variant='outlined'
                            fullWidth
                            disabled={false}
                        />
                    </Grid>
                </Grid>

                <Grid container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Button
                        name="btn-cancel"
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopupProps(undefined)}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        name="btn-confirm"
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};