import { useState } from 'react';
import { useBankAccontBeneficiaries } from 'contexts/bankAccount/bankAccountBeneficiaryContext';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { Drawer } from '@uy3/web-components';
import { useBankAccountBalance } from 'contexts/bankAccount/bankAccountContext';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { TableTransferContainer } from '../../TableTransfer/TableTransferContainer';
import { HeaderTransfer } from 'components/HeaderTransfer/HeaderTransfer';
import { CreateTransferContainer } from './CreateTransfer/CreateTransferContainer';
import Toast from 'components/Toast/Toast';

export const TransferTabContainer = () => {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [stepConfirmation, setStepConfirmation] = useState<'createTransfer' | undefined>();
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

    const {
        status: favoredStatus,
        error: favoredError
    } = useBankAccontBeneficiaries({
        id: bankAccountId!,
        operationTypeValue: 'Transfer'
    });

    const { data: dataBalance } = useBankAccountBalance(bankAccountId!);
    if (favoredStatus === 'error') <Error error={favoredError} />;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />

            <HeaderTransfer
                handlePix={() => setStepConfirmation('createTransfer')}
                type='Transfer'
            />

            <Drawer
                title='Transferir'
                anchor='right'
                open={stepConfirmation === 'createTransfer'}
                onClose={() => setStepConfirmation(undefined)}
                children={
                    <CreateTransferContainer
                        onClose={() => setStepConfirmation(undefined)}
                        dataBalance={dataBalance}
                        setIsRefreshing={setIsRefreshing}
                        setToast={setToast}
                    />
                }
            />

            <TableTransferContainer
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                operationType="Transfer"
            />
        </>
    );
};
