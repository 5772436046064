import { Typography } from '@uy3/web-components';
import { DetailsContainer } from './ProductDetails.styled';
import { Dispatch, SetStateAction, lazy } from 'react';
import { DashboardV3MetricsFilters, IMetrics } from 'contexts/dashboardContext';
import { FeedbackMessage } from 'components/FeedbackMessage/FeedbackMessage';
import { Stack } from '@mui/material';

const ProductTable = lazy(() => import('../ProductTable'));
interface ProductDetailsProps {
    data: IMetrics | undefined;
    setFilters: Dispatch<SetStateAction<DashboardV3MetricsFilters | undefined>>;
    filters: DashboardV3MetricsFilters | undefined;
}

const ProductDetails: React.FC<ProductDetailsProps> = ({ data }) => {
    return (
        <DetailsContainer>
            <Typography fontSize='24px' display='block' lineHeight={2} fontWeight={800} color="neutral.darkest">
                Detalhes do produto
            </Typography>
            <Typography variant="xl" color="neutral.darkest">
                Categorias agrupadas pelo nome resumido
            </Typography>
            {data?.creditNotesByProductCategory?.length !== 0 ?
                <ProductTable fieldName={'principalAmountInCents'} data={data} />
                :
                <Stack mt={2}>
                    <FeedbackMessage
                        title='Não encontramos resultados para a última hora. Mas não se preocupe, é fácil buscar mais informações'
                        listOfSuggestions={[
                            "Ajuste seus filtros - Experimente diferentes combinações para ampliar sua busca.",
                            "Veja um período maior - Selecione um intervalo de tempo mais amplo para ver mais operações."
                        ]}
                    />
                </Stack>
            }

        </DetailsContainer>
    );
}

export default ProductDetails;
