import React, { useState } from 'react'
import { SignaturesCreditNote } from './SignaturesCreditNote'
import { useParams } from 'react-router'
import { useSignaturesCreditNote } from 'contexts/creditNote/signaturesCreditNoteContext';
import { Actions } from 'components/Actions/Actions';
import { Typography, UpdateIcon } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { RefreshProgress } from 'components/RefreshProgress';
import { ToastType, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { SignaturesType } from 'services/creditNote/types/creditNoteReadModel';

export type ActionLinkProps = {
  open: boolean,
  link: string,
  copiedLink?: string | undefined
} | undefined;

export const SignaturesCreditNoteContainer = () => {
  const { id } = useParams();
  const { signaturesData, isFetching, refetch } = useSignaturesCreditNote(id!);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [toast, setToast] = useState<ToastType>(toastState);
  const queryData = signaturesData as SignaturesType[];
  
  const queryDataFilterd = () => {
    const personIdsUnique = new Set<string>();
    if (!!queryData) {
      const resultSorted = queryData.sort((a, b) => {
        const dataA = new Date(a.createdAt);
        const dataB = new Date(b.createdAt);
        return dataB.getTime() - dataA.getTime();
      });

      const result: typeof resultSorted = [];

      for (const item of resultSorted) {
        if (!personIdsUnique.has(item.personId)) {
          personIdsUnique.add(item.personId);
          result.push(item);
        }
      }
      return result ?? [];
    }
    return [];
  }

  

  const onCopy = async (link: string) => {
    await navigator.clipboard.writeText(link);
    const title = "Link de assinatura copiado com sucesso!";
    const description = undefined;
    showSuccessToast(title, description, setToast);

  };

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  if (signaturesData?.type === 'error') return <></>;

  return (
    <ErrorBoundary fallback='signatários'>
      <Toast toast={toast} setToast={setToast} />
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        m='20px 0 30px 0'
      >
        <Grid>
          <Typography variant='h4' color="neutral.medium" fontWeight={600}>Documentos aguardando assinatura</Typography>
          <Typography variant='sm' display='block' mt={1}>
            Existem documentos aguardando assinatura, para copiar basta clicar no botão de ações
          </Typography>
        </Grid>
        <Actions
          buttonsActionsList={[
            {
              enable: true,
              label: 'Atualizar',
              icon: <UpdateIcon />,
              action: refetch,
            },
          ]}
          numberOfButtons={1}
        />
      </Stack>

      {isFetching ? <RefreshProgress refreshing={isFetching} /> : <></>}

      <SignaturesCreditNote
        onCopy={onCopy}
        queryData={queryDataFilterd() ?? []}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={onChangeRowsPerPage}
        setPage={onChangePage}
        page={page}

      />
    </ErrorBoundary>
  )
}