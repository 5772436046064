import { Stack } from "@mui/system";
import { Button, Typography } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields/TextFormField/TextFormField";
import { useFormContext } from "contexts/formContext";
import QRCode from "qrcode.react";
import styled from "@emotion/styled";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

export const StyledBox = styled(Grid2)(() => ({
	display: 'flex',
	alignItems: 'center',
}));

export const StyledQRCode = styled(QRCode)(() => ({
	margin: "0 auto"
}));

const googleAuthLink = "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&gl=US";
const comoUsarLink = "https://canaltech.com.br/apps/google-authenticator-como-usar/";

type MfaSetupFormProps = {
	code: string | undefined;
}

export function MfaSetupForm({ code } : MfaSetupFormProps) {

	const { submitting } = useFormContext();	
	return <>
		<Typography
			variant="h5"
			color="neutral.dark"
			lineHeight="34px"
			mb={2}
			fontWeight={400}
		>
			Configuração de aplicativo autenticador (MFA)
		</Typography>
		<Stack direction="column" spacing={2} mb={2}>
			<StyledBox container>				
				<StyledBox xs={12}>
					{code && <StyledQRCode
						id="qr-gen"
						value={code}
						size={290}
						level={"H"}
						includeMargin={true}
						className='uk-align-center'
					/>}
				</StyledBox>				
				<Grid2 xs={12}>
					<Typography
						variant="caption">
						Para acessar o sistema, você deverá configurar uma autenticação MFA.<br />						
						Para isso, instale o aplicativo <a target="_blank" rel="noreferrer" href={googleAuthLink}>Google Authenticator</a> em seu dispositivo móvel.
						Em seguida, no aplicativo, escolha a opção "Ler QR Code".
						Para mais informações, <a target="_blank" rel="noreferrer" href={comoUsarLink}>clique aqui</a>.
						Após carregar o QR Code, digite o código abaixo:
					</Typography>
				</Grid2>

			</StyledBox>

			<TextFormField id="code" name="code" label="Código de verificação" variant="outlined" fullWidth required />
			<Button
				type="submit"
				variant="contained"
				size="medium"
				color="info"
				disabled={submitting}
				fullWidth={true}
				sx={{ width: '100%' }}
			>
				Acessar
			</Button>
		</Stack>
		<Typography
			variant="caption"
		>
			Caso tenha perdido seu dispositivo, entre em contato com o administrador para definir um novo código de autenticação.
		</Typography>
	</>
}