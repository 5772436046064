import { useState } from 'react';
import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import DatasetWrapper from '../DatasetWrapper';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { PublicJobsGet } from 'services/datasets/datasets.interface';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
interface IPublicJobsList {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function PublicJobsList({ datasetId, disableRequestDataSet }: IPublicJobsList) {
    const recordType = 'NaturalPerson';
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'PublicJobs');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de serviços públicos${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'sector',
            headerName: 'Setor',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'companyIdNumber',
            headerName: 'CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && formatDocumentNumber(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'companyName',
            headerName: 'Companhia',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'area',
            headerName: 'Área',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'level',
            headerName: 'Cargo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value === 'ACTIVE' ? 'Ativo' : 'Inativo'}
                    </div>
                );
            },
        },
        {
            field: 'income',
            headerName: 'Renda',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && formatCurrencyInCents(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'startDate',
            headerName: 'Início',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
        {
            field: 'endDate',
            headerName: 'Fim',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
    ];

    const data = dataset?.find((dataset) => dataset?.dataSet === 'public_jobs') as PublicJobsGet;
    const result = data?.result?.jobs;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <DataTable
                    loading={isLoading || status === 'loading'}
                    getRowId={() => Math.random().toString()}
                    columns={columns}
                    rows={result ?? []}
                    page={page}
                    rowCount={result?.length ?? 5}
                    rowsPerPage={rowsPerPage}
                    setPage={onChangePage}
                    setRowsPerPage={onChangeRowsPerPage}
                    paginationMode="client"
                />
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('PublicJobs')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};