import { string, object } from 'yup';

export function validationSchemaRecoverSimulationForm() {
    return object().shape({
        simulationId: string().typeError('Id inválido.').required('ID: Precisa ser preenchido.')
    });
};

export const defaultValuesRecoverSimulationForm = {
    simulationId: ""
};