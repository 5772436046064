import { Stack } from '@mui/material'
import { Button, LeftIcon, SaveIcon } from '@uy3/web-components'
import { TextFormField } from 'components/Forms/FormFields'
import { MaskedInput } from 'components/Forms/MaskedInput'
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants'
import { iconSx, useApiRequest } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { activeTheme } from 'services/theme'

type GuarantorFormProps = {
    onClose: () => void;
}
const theme = activeTheme();

export const GuarantorForm = ({ onClose }: GuarantorFormProps) => {
    const { watch } = useFormContext();
    const { submitting } = useApiRequest();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, '').length > 11;
    return (
        <Stack spacing={2}>
            <TextFormField
                name='name'
                variant='outlined'
                label="Nome"
            />
            <TextFormField
                name='registrationNumber'
                variant='outlined'
                label="CPF/CNPJ"
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                }}
            />
            <Stack spacing={2} direction='row' alignItems='center' justifyContent='end'>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={
                        <LeftIcon sx={iconSx} htmlColor={theme.palette.primary.main} />
                    }
                >
                    Voltar
                </Button>
                <Button
                    startIcon={
                        <SaveIcon sx={iconSx} htmlColor={theme.palette.common.white} />
                    }
                    disabled={submitting}
                    variant='contained'
                    type='submit'
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
