import { Stack } from '@mui/material';
import DetailsBaseForm from './DetailsBaseForm';
import { SelectFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { isEmpty } from 'lodash';

export default function ReporterForm() {
    const { watch } = useFormContext();
    const watchFullName = watch('details.reporter.fullName');
    const disabled = !isEmpty(watchFullName);

    return (
        <Stack spacing={3}>
            <DetailsBaseForm prefix="details.reporter" disabled={disabled} />
            <SelectFormField
                name="details.reporter.involvement"
                variant="outlined"
                label="Envolvimento"
                fullWidth
                required
                options={[
                    { label: 'Sim', value: 'Sim' },
                    { label: 'Não', value: 'Nao' },
                ]}
            />
        </Stack>
    );
}
