export class BankAccountCreateModel {
    bankCode?: number;
    bankIspb?: number;
    account?: string;
    accountDigit?: string;
    agency?: string;
    agencyDigit?: string;
    type?: string;
    jointAccount?: boolean;
    operationTypeValue?: string;
    pixKeyTypeValue?: string;
    keyPix?: string;

    constructor(init?: Partial<BankAccountCreateModel>) {
        Object.assign(this, init);
    }
}

export class BankAccountUpdateModel extends BankAccountCreateModel {
    id!: string;

    constructor(init?: Partial<BankAccountUpdateModel>) {
        super(init);
        Object.assign(this, init);
    }
}