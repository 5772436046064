import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { IBillingSettingFiltersProps, IBillingSettingFull } from './billingSetting.types';
import { isEmpty } from 'lodash';

var url = `/BillingSetting`;
export const getBillingSettingListAsync = async (filters: IBillingSettingFiltersProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };

    return await billingAxiosApi.get(url, config);
};

export async function createOrEditBillingSettingAsync(data: IBillingSettingFull, chargesId?: string, token = '') {
    const isCreating = !!chargesId && !isEmpty(chargesId);
    const recordType = isCreating ? `${url}/${chargesId}` : url;
    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    const axiosFunction = isCreating ?  billingAxiosApi.put : billingAxiosApi.post;
    
    return await axiosFunction(recordType, data, axiosConfig);
}

export async function getBillingSettingById(chargesId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    return await billingAxiosApi.get<IBillingSettingFull>(`${url}/${chargesId}`, config);
}

export const deleteBillingSettingAsync = async (billingId: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.delete(`${url}/${billingId}`, config);
};
