import { MenuItem, ActionsIcon, Typography, ButtonProps } from '@uy3/web-components';
import { Paper, Box, Stack } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ButtonAction } from './ButtonAction';
import { handleMouseDown } from 'helpers/methods/HandleMouseDown';
import ButtonMenu from './ButtonMenu';

export type ActionTypeButtons = {
    label: string;
    action?: () => void;
    enable: number | boolean | undefined;
    link?: {
        isLink: boolean;
        href: string;
    };
    icon?: ReactNode;
    type?: ButtonProps['type'];
    disabled?: boolean;
};

export type ActionsType = {
    groupButtons?: ActionTypeButtons[];
} & ActionTypeButtons;

type ActionsProps = {
    buttonsActionsList: ActionsType[];
    numberOfButtons: number;
};

const styles = {
    icon: {
        marginRight: '10px',
        marginTop: 2,
    },
    menu: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    paper: {
        width: 300,
        position: 'absolute',
        right: 0,
        top: 55,
        zIndex: 100,
    },
};

export const Actions = ({
    buttonsActionsList: listActions,
    numberOfButtons: numberOfActions,
}: ActionsProps) => {
    const [openOptions, setOpenOptions] = useState<boolean>(false);
    const componentRef = useRef(null);
    const onClose = () => setOpenOptions(false);

    useEffect(() => handleMouseDown(onClose, componentRef), []);

    return (
        <Stack direction="row">
            <>
                {listActions
                    .filter((item: ActionsType) => item.enable === true)
                    .splice(0, numberOfActions)
                    .map((item: ActionsType) => {
                        const { label, icon, enable, action, type, disabled = false, groupButtons } = item;

                        if (!!groupButtons &&
                            groupButtons?.length > 0) {
                            return (
                                <ButtonMenu {...{
                                    action: item
                                }} />

                            )
                        }
                        return (
                            <ButtonAction
                                key={label}
                                label={label}
                                startIcon={icon}
                                visible={Boolean(enable)}
                                onClick={action}
                                type={type}
                                disabled={disabled}
                            />
                        )
                    })}
            </>
            <div ref={componentRef}>
                <Box position="relative" mr={2.5}>
                    {listActions.filter((i) => i.enable === true).length > numberOfActions && (
                        <ButtonAction
                            label="Outras ações"
                            visible={true}
                            onClick={() => (!openOptions ? setOpenOptions(true) : onClose())}
                            startIcon={<ActionsIcon height={20} width={20} />}
                        />
                    )}
                    {openOptions && (
                        <Paper sx={styles.paper}>
                            {listActions
                                .filter((item: ActionsType) => item.enable === true)
                                .splice(numberOfActions)
                                .map((item: ActionsType) => {
                                    const { label, icon, enable, action, disabled = false, groupButtons} = item;
                                    if (!!groupButtons &&
                                        groupButtons?.length > 0) {
                                        return (
                                            <ButtonMenu {...{
                                                action: item
                                            }} />

                                        )
                                    }
                                    return (
                                        <>
                                            {!item.link?.isLink ? (
                                                <MenuItem
                                                    key={label}
                                                    sx={styles.menu}
                                                    size="medium"
                                                    disabled={!enable || disabled}
                                                    onClick={action}
                                                >
                                                    <div style={styles.icon}>{icon}</div>
                                                    <Typography variant="sm">{label}</Typography>
                                                </MenuItem>
                                            ) : (
                                                <Link
                                                    key={label}
                                                    to={item?.link.href}
                                                    target="_Blank"
                                                    style={{ textDecoration: 'none' }}
                                                >
                                                    <MenuItem
                                                        sx={styles.menu}
                                                        size="medium"
                                                        disabled={!enable || disabled}
                                                        onClick={action}
                                                    >
                                                        <div style={styles.icon}>{icon}</div>
                                                        <Typography variant="sm">
                                                            {label}
                                                        </Typography>
                                                    </MenuItem>
                                                </Link>
                                            )}
                                        </>
                                    )
                                })}
                        </Paper>
                    )}
                </Box>
            </div>
        </Stack>
    );
};