import { Amortization, DefaultSchemaAmortization, ValidationSchemaAmortization } from './Amortization';

export abstract class PriceCoefficient extends Amortization {}

export class DefaultSchemaAmmPriceCoefficient extends DefaultSchemaAmortization implements PriceCoefficient
{
    public amortizationType = 'priceCoefficient';
}


export class ValidationSchemaAmmPriceCoefficient extends ValidationSchemaAmortization {}
