import { DataTable, Typography } from '@uy3/web-components';
import DatasetWrapper from '../DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { FinanceDataGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IFinanceData {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function FinanceData({ datasetId, disableRequestDataSet }: IFinanceData) {
    const recordType = 'NaturalPerson';
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'FinanceData');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta das Informações financeiras${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };
    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'year',
            headerName: 'Ano',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'bank',
            headerName: 'Banco',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'agency',
            headerName: 'Agência',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'batch',
            headerName: 'Lote',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'isVipBranch',
            headerName: 'Premium',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? 'Sim' : 'Não'}
                    </div>
                );
            },
        },
        {
            field: 'captureDate',
            headerName: 'Captura',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
    ];

    const data = dataset?.find((dataset) => dataset?.dataSet === 'finance_data') as FinanceDataGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <>
                    <HandleTitle
                        title={'Patrimonio Total Estimado'}
                        value={result?.totalAssets ?? 'N/D'}
                        key="totalAssets"
                    />
                    <Typography variant="h5" color="neutral.dark" mb={1} mt={1} fontWeight={600}>
                        Renda estimada mensal
                    </Typography>
                    <HandleTitle
                        title={'Atividade Empresaria'}
                        value={result?.companyOwnership ?? 'N/D'}
                        key="companyOwnership"
                    />
                    <HandleTitle
                        title={'Ministério do Trabalho'}
                        value={result?.mte ?? 'N/D'}
                        key="mte"
                    />
                    <HandleTitle title={'IBGE'} value={result?.ibge ?? 'N/D'} key="ibge" />
                    <HandleTitle
                        title={'Externo'}
                        value={result?.externalProvider ?? 'N/D'}
                        key="externalProvider"
                    />

                    <DataTable
                        loading={isLoading || status === 'loading'}
                        getRowId={() => Math.random().toString()}
                        columns={columns}
                        rows={result?.taxes ?? []}
                        page={page}
                        rowCount={result?.taxes?.length ?? 5}
                        rowsPerPage={rowsPerPage}
                        setPage={onChangePage}
                        setRowsPerPage={onChangeRowsPerPage}
                        paginationMode="client"
                    />
                </>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('FinanceData')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};
