import { useState, useEffect } from 'react';
import { activeTheme } from 'services/theme';
import { Typography, UpdateIcon } from '@uy3/web-components';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { Error } from 'components/Errors/Error';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { ConsumerRiskScoreGet } from 'services/datasets/datasets.interface';
import Spinner from '../DatasetsStatusColumn/Spinner';
import { TooltipIcon } from 'pages/Dashboard/ProductTable/ProductTable.styled';
import { Box } from '@mui/material';
import { CenteredBox } from 'components/CenteredBox';

type RiskScoreContainerProps = {
  discriminator: string;
  id: string | undefined;
};

const theme = activeTheme();
const RiskScoreContainer = ({ discriminator, id }: RiskScoreContainerProps) => {
  var recordType = 'NaturalPerson';
  const [score, setScore] = useState<any>(null);

  const { dataset, status, error, refetch } = useDatasetData(recordType, id!, "ConsumerRiskScore");

  const { setSubmitError } = useApiRequest();

  const onSuccess = (data: any) => {
    setScore(data);
    refetch();
  };

  const onError = (error: ApiResponseError) => { setSubmitError(error); setScore([]); }

  const { mutate } = useDatasetMutation(recordType, id!, onSuccess, onError);
  const handleUpdateData = async (values: string) => {
    setScore(null);
    mutate(values);
  };

  const data = dataset?.find(dataset => dataset?.dataSet === "consumer_risk_score") as ConsumerRiskScoreGet;

  useEffect(() => {
    if (!id) return;
    setScore(data ?? []);
  }, [data, refetch, id]);

  const naturalPerson = discriminator === 'NaturalPerson';
  const legalPerson = discriminator === 'LegalPerson';

  const datasetMustBeRequired = naturalPerson && score?.length === 0;

  if (status === 'error') return <Error error={error} />

  if (naturalPerson && score == null) return <Spinner />

  return (
    <CenteredBox id="center-box" width={100}>
      { }
      {(!datasetMustBeRequired || legalPerson) && getScore(data?.score)}
      {datasetMustBeRequired &&
        <UpdateIcon
          sx={{ height: 18, width: 18, cursor: 'pointer', color: theme.palette.primary.main, display: 'inline-block' }}
          onClick={() => handleUpdateData("ConsumerRiskScore")}
        />
      }
    </CenteredBox>
  );

};

export const getScore = (score: number | undefined) => {
  var text = "Indisp.";
  var color = theme.palette.grey[500];
  if (score) {
    text = "M.Alto";
    color = theme.palette.error.dark;
    if (score < 10) {
      text = "M.Baixo";
      color = theme.palette.success.light;
    } else if (score < 46) {
      text = "Baixo";
      color = theme.palette.success.dark;
    } else if (score < 80) {
      text = "Médio";
      color = theme.palette.warning.main;
    } else if (score < 90) {
      text = "Alto";
      color = theme.palette.error.main;
    }
  }
  return (
    <Box sx={{
      textAlign: 'center'
    }}>
      <TooltipIcon color={color} />
      <Typography
        variant="sm"
        style={{
          padding: 2,
          borderRadius: 18,
          minHeight: 18,
          minWidth: 18,
          textAlign: 'center'
        }}>
        {text}
      </Typography>
    </Box>
  );
};

export default RiskScoreContainer;