import { string, object, boolean } from 'yup';

export function validationSchemaInstallmentCreditNote() {
    return object().shape({
        useFundSetting: boolean().nullable(),
        walletCode: string().typeError('Código do convênio: precisa ser preenchido.').required('Código do convênio: precisa ser preenchido.'),
        billingSettingId: string().nullable().notRequired(),
        communicationSettingId: string().nullable().notRequired()
    })
};

export const defaultValuesInstallmentCreditNote = {
    walletCode: null,
    billingSettingId: null,
    communicationSettingId: null,
    useFundSetting: false
};