import { Stack } from '@mui/material';
import { TextFormField } from 'components/Forms/FormFields';
import { Alert } from '@uy3/web-components';
export const InstallmentEditForm = () => {
    return (
        <Stack spacing={2} marginTop={-2} mb={2}>
            <TextFormField
                name='payer.email'
                variant='outlined'
                label="Email do pagador"
                fullWidth
            />

            <Alert severity='info' text='Informamos que a cobrança já foi registrada em nosso sistema. Devido a isso, a edição das informações está restrita a alguns campos.' />
        </Stack>
    )
}
