import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { CheckboxFormField, SelectFormField } from 'components/Forms/FormFields'
import { activeTheme } from 'services/theme'

const color = activeTheme().palette; 

type UpdateWalletFormProps = {
    onClose: () => void; 
    isLoading: boolean
}

export const UpdateWalletForm = ({ onClose, isLoading }: UpdateWalletFormProps) => {
    return (
        <Stack spacing={2}>
            <Grid xs={2}>
                <SelectFormField
                    {...{
                        label: "Tipo de cobrança",
                        name: "billingTypeValue",
                        fullWidth: true,
                        required: true,
                        options: [
                            { label: "Mensal", value: "Monthly" },
                            { label: "Diária", value: "Daily" }
                        ]
                    }}
                />
            </Grid>
            <Grid xs={2}>
                <CheckboxFormField label='Habilitar envio para protesto automático' name='enableAutoSubmitToDebtCollection' />
            </Grid>

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon htmlColor={color.primary.main} />}
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    disabled={isLoading}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
