import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { SelectOption } from 'components/Forms/FormFields/SelectFormField/SelectFormField';
import UploadDropzone from './UploadDrop';
import UploadForm from './UploadForm';

export interface UploadFieldProps {
    options: SelectOption[];
    onClose?: () => void;
    accept: string;
    currentValues?: any;
    isRegistrationPerson?: boolean;
    isSendSignature?: boolean;
    startSignature?: () => void;
}

export const UploadFormWrapper: FunctionComponent<UploadFieldProps> = ({
    isRegistrationPerson,
    ...props
}) => {
    const { options, onClose, accept, currentValues, isSendSignature = false, startSignature } = props;

    return (
        <Stack spacing={2} mt={-2}>
            <Stack spacing={2} top={0} direction="column">
                <UploadDropzone {...{ accept, currentValues }} />
                <UploadForm
                    options={options}
                    onClose={onClose}
                    shouldDisplayButtons={!isRegistrationPerson}
                    isSendSignature={isSendSignature}
                    startSignature={startSignature}
                />
            </Stack>
        </Stack>
    );
};
