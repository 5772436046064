import type { ThemeOptions } from '@mui/material/styles';
import { TypographyStyleOptions } from '@mui/material/styles/createTypography';

export const font = {
    size: {
        xg: '2.5rem',
        xxxl: '2.25rem',
        xxl: '1.5rem',
        xl: '1.25rem',
        lg: '1.125rem',
        md: '1.0625rem',
        sm: '1rem',
        xs: '0.875rem',
    },
    weight: {
        regular: 400,
        semibold: 600,
        bold: 700,
        extrabold: 800,
    },
    lineHeight: {
        xg: '4.5rem',
        xxxl: '3.5rem',
        xxl: 1,
        xl: 1,
        lg: 1,
        md: '2.5rem',
        sm: '2.5rem',
        xs: '2rem',
    },
};

declare module '@mui/material/styles' {
    interface TypographyVariants {
        xg?: TypographyStyleOptions;
        xxxl?: TypographyStyleOptions;
        xxl?: TypographyStyleOptions;
        xl?: TypographyStyleOptions;
        lg?: TypographyStyleOptions;
        md?: TypographyStyleOptions;
        sm?: TypographyStyleOptions;
        xs?: TypographyStyleOptions;
    }

    interface TypographyVariantsOptions {
        xg?: TypographyStyleOptions;
        xxxl?: TypographyStyleOptions;
        xxl?: TypographyStyleOptions;
        xl?: TypographyStyleOptions;
        lg?: TypographyStyleOptions;
        md?: TypographyStyleOptions;
        sm?: TypographyStyleOptions;
        xs?: TypographyStyleOptions;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        xg?: true;
        xxxl?: true;
        xxl?: true;
        xl?: true;
        lg?: true;
        md?: true;
        sm?: true;
        xs?: true;
    }
}

export const typography: ThemeOptions['typography'] = {
    fontSize: 10,
    htmlFontSize: 10,
    fontFamily: '"Lato", sans-serif',

    xg: {
        fontSize: font.size.xg,
        lineHeight: font.lineHeight.xg,
    },
    xxxl: {
        fontSize: font.size.xxxl,
        lineHeight: font.lineHeight.xxxl,
    },
    xxl: {
        fontSize: font.size.xxl,
        lineHeight: font.lineHeight.xxl,
    },
    xl: {
        fontSize: font.size.xl,
        lineHeight: font.lineHeight.xl,
    },
    lg: {
        fontSize: font.size.lg,
        lineHeight: font.lineHeight.lg,
    },
    md: {
        fontSize: font.size.md,
        lineHeight: font.lineHeight.md,
    },
    sm: {
        fontSize: font.size.sm,
        lineHeight: font.lineHeight.sm,
    },
    xs: {
        fontSize: font.size.xs,
        lineHeight: font.lineHeight.xs,
    },
};
