import { Stack } from '@mui/material'
import { Button, CalendarCheckIcon, DoneIcon, SaveIcon } from '@uy3/web-components'
import { activeTheme } from 'services/theme'
import { ChangeStartDateContainerProps } from './ChangeStartDateContainer';
import { useApiRequest } from 'contexts/apiRequestContext';

const theme = activeTheme();


export const ChangeStartDate = ({ handleKeepCurrentState, handleSubmit, textCurrentStateButton, textHandleSubmitButton, recordType }: ChangeStartDateContainerProps) => {
    const { submitting } = useApiRequest();
    const primaryColor = submitting ? 'grey' : theme.palette.primary.main; 
    const whiteColor =  submitting ? 'grey' : theme.palette.common.white; 

    return (
        <Stack spacing={2} mt={3}>
            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    onClick={handleKeepCurrentState}
                    variant='outlined'
                    disabled={submitting}
                    startIcon={<DoneIcon htmlColor={primaryColor} />}
                >
                    {textCurrentStateButton}
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant='contained'
                    disabled={submitting}
                    startIcon={recordType === 'simulate' ? <CalendarCheckIcon htmlColor={whiteColor} /> : <SaveIcon htmlColor={whiteColor}/>}
                >
                    {textHandleSubmitButton}
                </Button>
            </Stack>
        </Stack>
    )
}
