import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DataTable } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';
import { SignaturesType } from 'services/creditNote/types/creditNoteReadModel';

type SignaturesCreditNoteProps = {
  queryData: SignaturesType[];
  onCopy: (link: string) => Promise<void>;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setPage: (newPage: number) => void;
  page: number;
};

const theme = activeTheme();
export const SignaturesCreditNote = ({
  queryData,
  onCopy,
  page,
  rowsPerPage,
  setPage,
  setRowsPerPage
}: SignaturesCreditNoteProps) => {

  const columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Signatário',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div style={{ textAlign: 'left', marginLeft: '-8px' }}>
            {cellValues.value ?? "N/D"}
          </div>
        );
      },
    },
    {
      field: 'personCellPhone',
      headerName: 'Telefone',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => formatPhoneNumber(cellValues.value) ?? "N/D",
    },
    {
      field: 'personEmail',
      headerName: 'Email',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => cellValues.value ?? "N/D",
    },
    {
      field: 'createdAt',
      headerName: 'Data de criação',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{ textAlign: 'left', marginLeft: '-8px', }}
          >
            {cellValues.value &&
              new Date(cellValues.value).toLocaleDateString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
              })}
          </div>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Ações',
      hideSortIcons: true,
      minWidth: 150,
      editable: false,
      renderCell: ({ row }) => {
        return (
          <Stack
            component={Grid}
            direction={'row'}
            justifyContent="center"
            alignItems="center"
            style={{ textAlign: 'left', marginLeft: '-4px' }}
          >
            <IconButton onClick={() => onCopy(row?.url)}>
              <ContentCopyIcon
                sx={{ height: 20, width: 20, color: theme.palette.common.black }}
              />
            </IconButton>
          </Stack>
        );
      },
    },
  ];

  return (
    <DataTable
      columns={columns}
      rows={queryData ?? []}
      page={page}
      rowCount={queryData?.length}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      getRowId={() => Math.random().toString()}
    />
  );
};
