import { useState } from "react";
import { useBankAccountListData } from "contexts/bankAccount/bankAccountContext";
import BankAccountList from "./BankAccountList";
import { useNavigate } from "react-router";
import { FilterProvider } from "contexts/filterContext";
import { accountTypeOptions } from "components/DataTableFilters/BankAccountFilters/BankAccountFilters";
import Offline from "components/Offline/Offline";
import { mapErrorResponse } from "contexts/responseErrorContext";
import { ApiResponseError } from "contexts/apiRequestContext";
import BankAccountHeader from "./BankAccountHeader";
import { ListSkeleton } from "components/Skeleton/ListSkeleton";
import { RefreshProgress } from "components/RefreshProgress";

export function BankAccountListContainer() {
    const navigate = useNavigate();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { data, error, status, isFetching, refetch, isLoading } = useBankAccountListData({ page, size: rowsPerPage }, false);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    if (data?.type === "success"
        && data.data?.length === 1
    ) {
        let bankAccountId = data.data[0].id;
        navigate(`/contas-digitais/ativas/${bankAccountId}`);
    }

    if (status === "error") {
        const { errorMessage } = mapErrorResponse(error as ApiResponseError);
        return <Offline errorMessage={errorMessage} />;
    }

    return (
        <>
            <BankAccountHeader isFetching={isFetching} refetch={refetch} />
            <RefreshProgress refreshing={isFetching && !isLoading} />
            <FilterProvider availableFilters={{
                searchString: { label: "Todos", type: "text" },
                f_personName: { label: "Nome", type: "text" },
                f_personRegistrationNumber: { label: "CPF/CNPJ", type: "text" },
                f_account: { label: "Número da conta", type: "text" },
                f_agency: { label: "Agência", type: "text" },
                f_modality: { label: "Modalidade", type: "select", multiple: false, options: accountTypeOptions },
            }}>
                <ListSkeleton isLoading={isLoading}>
                    <BankAccountList
                        queryData={data}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                    />
                </ListSkeleton>
            </FilterProvider>
        </>
    )
}