import { creditAxiosApi, createAxiosConfig } from 'services/axiosApi/axiosApi';
import { IFiltersPayrollAgreements, PayrollAgreementReadModel } from './payrollAgreement.type';
import { GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import { onlyNumbers } from 'helpers';

const url = '/PayrollAgreement';
export async function getPayrollAgreementList(params: IFiltersPayrollAgreements, token: string) {
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<GetListApiResponseSuccess<PayrollAgreementReadModel>>(`/PayrollAgreement`, axiosConfig);
}

export async function getByInternalId(internalId: string, token: string) {
    const axiosConfig = createAxiosConfig(token);
    const query = onlyNumbers(internalId);

    return await creditAxiosApi.get<PayrollAgreementReadModel>(`${url}/GetByInternalId/${query}`, axiosConfig);
}
