import { Stack, Typography } from '@mui/material';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import ReactCodeInput from 'react-code-input';
import { resolve } from 'services/arrayGetters';
import { activeTheme } from 'services/theme';
import './MfaFormField.css'

interface IFieldCode {
    handleChange: (event: string) => void;
    props: any;
}

interface IMfaFormField {
    name: string;
    toggleDrawer: boolean;
}

const theme = activeTheme();

const FieldCode = ({ handleChange, props }: IFieldCode) => {
    return (
        //@ts-ignore
        <ReactCodeInput
            className='text-field-mfa'
            onChange={handleChange}
            type='number'
            fields={6}
            {...props}
        />
    )
}

export const MfaFormField: React.FC<IMfaFormField> = ({ name, toggleDrawer }) => {
    const { setValue, validationErrors } = useFormContext();
    var error = validationErrors && resolve(name, validationErrors)?.message;

    const onChangeField = (value: string) => {
        if (value.length === 0 || !value) return;
        setValue(name, value);
    }

    const dinamicProps = {
        inputStyle: {
            fontSize: '24px',
            fontWeight: '700',
            textAlign: 'center',
            margin: toggleDrawer ? "5px 10px 10px 0px" : "5px 30px 10px 0px",
            outline: 'none',
            width: '60px',
            height: '60px',
            border: `1px solid ${error ? 'red' : 'black'}`,
            borderRadius: '8px'
        }
    }

    return (
        <Stack justifyContent='center'>
            <FieldCode
                handleChange={onChangeField}
                props={dinamicProps}
            />
            {!!error && (
                <Typography variant="body1" color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
        </Stack>
    )
}