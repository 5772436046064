import { Grid, Stack } from "@mui/material";
import { CopyIcon, Remove02Icon, Typography, UpdateIcon } from "@uy3/web-components";
import { Actions } from "components/Actions/Actions";
import { iconSx } from "contexts/apiRequestContext";
import { useUserPermissionData } from "contexts/userContext";

type PermissionHeaderProps = {
    refetch: () => void;
    setAction: (action: string) => void; 
}

export function PermissionHeader({ refetch, setAction }: PermissionHeaderProps) {
    const { hasPermission } = useUserPermissionData();
    const hasPermissionToCreateAndUpdate = hasPermission('Permission', 'Create') && hasPermission('Permission', 'Update');

    return (
        <Stack direction="row" justifyContent="space-between" alignItems='center' mr={3} mt={3}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="25px"
                >
                    Permissões
                </Typography>
            </Grid>
            <Actions {...{
                numberOfButtons: 4,
                buttonsActionsList: [
                    {
                        enable: hasPermissionToCreateAndUpdate, 
                        label: "Copiar permissões", 
                        action: () => setAction('clone-permission'), 
                        disabled: false, 
                        icon: <CopyIcon sx={iconSx}/>
                    }, 
                    {
                        enable: hasPermission('Permission', 'Delete'), 
                        label: "Limpar permissões", 
                        action: () => setAction('clear-permission'), 
                        disabled: false, 
                        icon: <Remove02Icon sx={iconSx}/>
                    }, 
                    {
                        enable: true,
                        label: "Atualizar",
                        action: () => refetch(),
                        disabled: false,
                        icon: <UpdateIcon sx={iconSx}
                        />
                    }
                ]
            }} />
        </Stack>
    );
};

export default PermissionHeader;