import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import {
    BankSlipRequestModel,
    PixkeyRequestModel,
    PixQrCodeRequestModel,
    TransferRequestModel,
} from './genericsType';
import { LiquidationScheduleReadModel } from './LiquidationScheduleReadModel';

export class ReviewLiquidationScheduleCreateModel {
    liquidationScheduleItems?: Array<{
        liquidationScheduleId: string;
        liquidationSchedule: LiquidationScheduleRequestModel;
    }>;
}

export class LiquidationScheduleRequestModel {
    amountInCents!: number;
    pixQrCode?: PixQrCodeRequestModel;
    bankSlip?: BankSlipRequestModel;
    pixKey?: PixkeyRequestModel;
    transfer?: TransferRequestModel;
}

/**
 * Mapeia os dados do Roteiro de Liquidação para o modelo apropriado com base na estrutura definida pelo schema do endpoint.
 * @template T - Tipo genérico do modelo de dados. O tipo `T` deve corresponder ao modelo esperado que será instanciado.
 * @param {T} data - Objeto de dados a ser mapeado para o modelo `LiquidationScheduleRequestModel`.
 * @return {T} - Retorna uma instância do modelo `LiquidationScheduleRequestModel` com os dados mapeados e formatados corretamente.
 */
export function mapLiquidationScheduleData<T>(data: T): T {
    const formattedData = toDataModel(data as unknown as Record<string, unknown>, LiquidationScheduleRequestModel);
    return formattedData as T;
}

/**
 * Mapeia os itens do Roteiro de Liquidação na etapa de Revisão de Pagamento.
 * @param {Array<LiquidationScheduleReadModel>} LiquidationScheduleReadModel - Lista de itens do roteiro de liquidação.
 * @returns {ReviewLiquidationScheduleCreateModel} - Objeto contendo uma lista de itens mapeados para revisão de pagamento.
 */
export function mapPaymentReviewItems(data: LiquidationScheduleReadModel[]): ReviewLiquidationScheduleCreateModel {
    return {
        liquidationScheduleItems: data.map((item) => {
            const liquidationScheduleFormatted = mapLiquidationScheduleData<LiquidationScheduleRequestModel>(item);

            return {
                liquidationScheduleId: item.id,
                liquidationSchedule: liquidationScheduleFormatted,
            };
        }),
    };
}