import { FormProvider } from 'contexts/formContext';
import { BankAccountOperatorForm } from '../BankAccountOperatorForm/BankAccountOperatorForm';
import { useOperatorList } from 'contexts/OperatorContext';
import {
    defaultValuesBankAccountOperatorForm,
    validationBankAccountOperatorFormSchema,
} from '../BankAccountOperatorForm/BankAccountOperatorFormSchema';
import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { createOperator } from 'services/accounts/bankAccount/bankAccount';
import { useTenant } from 'contexts/tenantContext';
import { ParamsCreateOperator } from 'services/accounts/bankAccount';
import { useIdentity } from 'contexts/identityContext';
import { useState } from 'react';

interface AddOperatorProps {
    onClose: () => void;
    operatorListRefetch: () => void;
    bankAccountId: string | undefined;
    handleResponseError: (response: ApiResponseError) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export default function AddOperator({
    onClose,
    operatorListRefetch,
    bankAccountId,
    handleResponseError,
    setToast,
}: AddOperatorProps) {
    const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
    const { operatorsAutoCompleteProps } = useOperatorList(
        { page: 0, size: 10 },
        'always',
        selectedTenant
    );
    const { currentTenantId } = useTenant();
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    const validationOperatorForm = validationBankAccountOperatorFormSchema();
    const defaultValuesOperatorForm = defaultValuesBankAccountOperatorForm;

    const onCreateOperator = async (formValues: ParamsCreateOperator) => {
        startRequest();
        await createOperator(bankAccountId!, formValues, currentTenantId, token!)
            .then(() => {
                operatorListRefetch();
                endRequest(true);
                onClose();
                const title = 'Operador criado com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    return (
        <FormProvider
            validationSchema={validationOperatorForm}
            defaultValues={defaultValuesOperatorForm}
            onSubmit={onCreateOperator}
            onChangeField={[
                {
                    fieldName: 'tenantName',
                    delegate: (value: string) => setSelectedTenant(value),
                },
            ]}
        >
            <BankAccountOperatorForm listOperator={operatorsAutoCompleteProps} onClose={onClose} />
        </FormProvider>
    );
}
