import { Drawer, Typography } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    UploadGet,
    showErrorToast,
    showSuccessToast,
} from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useEffect, useState } from 'react';
import { defaultValuesDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { GridValidRowModel } from '@mui/x-data-grid';
import { useGetSignatureAsync, useSignatureStartAsync } from 'contexts/signature/signatureContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { Stack } from '@mui/material';
import Tooltip from 'components/Tooltip';
import AddSignatureContainer from 'components/Tabs/Uploads/DrawerWrapper/Signature/AddSignature/SignatureContainer';
import { ViewDetailsSignature } from './ViewDetailsSignature/ViewDetailsSignature';
import { SignaturesContainer } from './Signature/SignatureForm/SignaturesContainer';
import CancelSignaturePopup from './Signature/CancelSignaturePopup';
import UploadOrRelatedDocsForm from '../UploadWithRelated/UploadOrRelatedDocsForm';
import { StartSignature } from './Signature/StartSignature/StartSignature';
import { EnumItem } from 'contexts/enumContext';
import { IRelatedPersonCreateOrUpdateModel } from 'services/bankAccountProduct/types/generics';

interface DrawerWrapperProps {
    selectedAction: string | undefined;
    selectedUploadIndex: number | undefined;
    openDrawer: boolean;
    setSelectedUploadIndex: (values: number | undefined) => void;
    setOpenDrawer: (values: boolean) => void;
    typeOfDocument: EnumItem[];
    rowData: GridValidRowModel | undefined;
    setSelectedAction: (values: string | undefined) => void;
    setRowData: (values: GridValidRowModel | undefined) => void;
    setStatusSignature: (values: string) => void;
    targetId: string;
    data: any;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    targetType: "BankAccountRequest" | "CreditNoteOp" | "BatchAssignment";
}

export default function DrawerWrapper({
    selectedAction,
    selectedUploadIndex,
    openDrawer,
    setSelectedUploadIndex,
    setOpenDrawer,
    typeOfDocument,
    rowData,
    setSelectedAction,
    setRowData,
    setStatusSignature,
    targetId,
    data,
    setToast,
    targetType
}: DrawerWrapperProps) {
    const [signatureWorkFlowId, setSignatureWorkFlowId] = useState<string | undefined>();
    const { watch } = useFormContext();

    const [relatedPerson, relatedDocs, uploads, personId] = watch([
        'relatedPerson',
        'relatedDocs',
        'uploads',
        'personId',
    ]);
    const watchUploads = uploads ?? relatedDocs ?? [];
    const signatureId = rowData?.signatureWorkFlowId ?? signatureWorkFlowId;
    const { signatureData, refetch: signatureRefetch } = useGetSignatureAsync(signatureId);
    const statusSignature = signatureData?.statusDisplay;

    useEffect(() => {
        if (statusSignature) {
            setStatusSignature(statusSignature);
        }
        if (selectedAction === 'moreOptions' && !statusSignature) {
            setStatusSignature('');
        }
    }, [setStatusSignature, statusSignature, selectedAction]);

    const closeDrawer = () => {
        setSelectedUploadIndex(undefined);
        setOpenDrawer(false);
        setSelectedAction(undefined);
        setRowData(undefined);
    };

    const defaultFormValue =
        selectedUploadIndex !== undefined
            ? watchUploads[selectedUploadIndex!]
            : defaultValuesDocForm;

    const isEditDocument =
        selectedUploadIndex === 0 && selectedAction === 'edit' && !statusSignature
            ? selectedAction
            : '';
            
    const FormNewUpload = () => (
        <UploadOrRelatedDocsForm
            closeDrawer={closeDrawer}
            selectedUploadIndex={selectedUploadIndex}
            setToast={setToast}
            selectedAction={isEditDocument}
            rowData={rowData}
            typeOfDocument={typeOfDocument}
            setSelectedAction={setSelectedAction}
        />
    );

    const onSuccess = () => {
        const title = 'Sucesso ao iniciar o processo de assinatura';
        const description = 'Ótimo! O processo de assinatura foi iniciado com sucesso.';
        showSuccessToast(title, description, setToast);
        closeDrawer();
        signatureRefetch();
    };

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const title = 'Ops, ocorreu um erro';
        showErrorToast(title, errorMessage, setToast);
    };

    const { mutateStartSignatureAsync } = useSignatureStartAsync(signatureId, onSuccess, onError);

    const handleStartSignature = async (filePath: string) =>
        await mutateStartSignatureAsync(filePath);

    const handleOnSubmit = () => {
        const uploadsList = uploads ?? [];
        const upload = uploadsList?.find(
            (item: UploadGet) => item?.id === signatureData?.uploadId
        ) as UploadGet;

        if (!upload?.fileName?.toLowerCase().endsWith('.pdf')) {
            const title = 'Ops, ocorreu um erro';
            const errorMessage =
                'Não foi possível iniciar assinatura, pois o documento que não é do tipo PDF';
            return showErrorToast(title, errorMessage, setToast);
        }

        return handleStartSignature('CCB\\');
    };

    const getListSigners = () => {
        let listSigners = [];
        const involveds: IRelatedPersonCreateOrUpdateModel[] = (data?.relatedPersonCreditNote || data?.relatedPersonFund || relatedPerson) ?? [];
        const involvedsFiltered = involveds?.filter(person => person?.discriminator?.toLowerCase() !== 'legalperson');
        
        listSigners = involvedsFiltered?.map((item) => {
            if (item.personId === personId) {
                return {
                    personIdDisplay: data?.personDisplay,
                    personId: data?.personId,
                    typeOfRelationshipDisplay: "Emitente",
                    typeOfRelationship: targetType === 'BatchAssignment' ? item?.fundSignerType : item?.typeOfRelationship, 
                    signatureValidation: item?.signatureValidation
                };
            }
            return { ...item };
        });

        const existsPersonIdSome = listSigners.some((item) => item.personId === personId);
        if (!existsPersonIdSome) {
            //@ts-ignore
            listSigners.push({
                personIdDisplay: data?.personDisplay,
                personId: data?.personId,
                typeOfRelationshipDisplay: 'Emitente',
                typeOfRelationship: 'Issuer'
            });
        }

        return listSigners;
    };

    let titleStep, descriptionStep, isOpenDrawer, formStep;
    switch (selectedAction) {
        case 'onlySignature':
            titleStep = 'Iniciar assinatura';
            descriptionStep = 'Agora você pode iniciar assinatura do documento';
            isOpenDrawer = true;
            formStep = <StartSignature onClose={closeDrawer} onStartSignature={handleOnSubmit} />;
            break;
        case 'viewDetails':
            titleStep = rowData?.displayName ?? 'Detalhes do documento';
            isOpenDrawer = true;
            formStep = (
                <ViewDetailsSignature
                    onClose={closeDrawer}
                    setToast={setToast}
                    signatureData={signatureData}
                    signatureRefetch={signatureRefetch}
                    rowData={rowData!}
                    onError={onError}
                    onSuccess={onSuccess}
                    handleOnSubmit={handleOnSubmit}
                />
            );
            break;
        case 'requestSignature':
            titleStep = 'Solicitar assinatura';
            descriptionStep = 'Confira as informações antes de solicitar a assinatura';
            isOpenDrawer = true;
            formStep = (
                <SignaturesContainer
                    setToast={setToast}
                    onCloseStep={() => setSelectedAction('edit')}
                    uploadSelected={rowData === null ? defaultFormValue : rowData}
                    persons={getListSigners()}
                    skipStepToStartSignature={() => setSelectedAction('onlySignature')}
                    saveSignatureWorkflowId={(id: string) => setSignatureWorkFlowId(id)}
                    targetType={targetType}
                    targetId={targetId}
                />
            );
            break;
        case 'signature':
            titleStep = 'Adicionar signatários';
            descriptionStep = 'Confira as informações antes de adicionar os signatários';
            isOpenDrawer = true;
            formStep = (
                <AddSignatureContainer
                    onClose={closeDrawer}
                    signatureWorkFlowId={signatureId}
                    persons={getListSigners()}
                    signers={signatureData?.signers ?? []}
                    setToast={setToast}
                />
            );
            break;
        default:
            titleStep = selectedUploadIndex === 0 ? 'Editar documento' : 'Enviar documento';
            descriptionStep = 'Confira as informações antes de enviar os documentos';
            isOpenDrawer = selectedUploadIndex !== undefined || openDrawer;
            formStep = FormNewUpload();
    }

    const isCancelSignature = selectedAction === 'cancelSignature';

    return (
        <>
            {isCancelSignature && (
                <CancelSignaturePopup
                    openPopup={isCancelSignature}
                    onClose={closeDrawer}
                    signatureWorkFlowId={signatureId}
                    setToast={setToast}
                />
            )}
            <Drawer anchor="right" title={titleStep} open={isOpenDrawer} onClose={closeDrawer}>
                <Stack mb={2} direction="row" alignItems="center">
                    <Typography variant="h6" color="textPrimary">
                        {descriptionStep}
                    </Typography>
                    {openDrawer && (
                        <Tooltip
                            title={'Documentos para assinaturas:'}
                            description={`
                        Minuta,
                        Ficha de Cadastro,
                        Cessão,
                        Aditamento,
                        Cessão Fiduciária,
                        Procuração,
                        Termo de posse,
                        Averbação da Garantia,
                        Questionário PLDFT,
                        Relatório de Cessão e
                        Recibo.`}
                        />
                    )}
                </Stack>
                {formStep}
            </Drawer>
        </>
    );
}
