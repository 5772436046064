import { CardData } from '@uy3/web-components'
import { nameAndRegistrationNumber } from 'helpers/formats/StringFormats';
import { BillingFull } from 'services/walletManagement/billing/billing.types';

type CardDataChargesType = {
    data: BillingFull
}

export function CardDataCharges({ data }: CardDataChargesType) {
    const payer = data?.payer;
    return (
        <CardData
            listItem={[
                {
                    id: '1',
                    title: "Status ",
                    value: data?.registered ? 'Registrado' : 'Rascunho'
                },
                {
                    id: '2',
                    title: "Código convênio",
                    value: data?.walletCode
                },

                {
                    id: '3',
                    title: "Devedor",
                    value: `${nameAndRegistrationNumber(payer?.name, payer?.registrationNumber?.toString()) ?? ""}`
                },
                {
                    id: '4',
                    title: "Email",
                    value: payer?.email ?? "N/D"
                },
                {
                    id: '6',
                    title: "Endereço",
                    value: `${payer?.addressName} | ${payer?.zipCode}`
                }
            ]}
        />
    )
}

export const ShowStatusSpan = ({ value }: { value: string }) => {
    const bgColor: { [type: string]: string } = {
        "A vencer": "#4CAF50",
        "Rascunho": "#FF9800",
        "Vencida": "#F88078"
    }
    return (
        <span style={{
            display: 'block',
            padding: 7,
            borderRadius: 7,
            color: 'white',
            background: bgColor[value]
        }}>
            {value}
        </span>
    )
}