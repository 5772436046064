import { InferType } from 'yup';
import { object, string, array } from 'yup';

const Category = object().shape({
    category: string()
        .required('Descrição da categoria: Precisa ser preenchido.')
        .min(1, 'Crie ao menos 1 categoria para prosseguir.')
        .typeError('Categoria inválida.'),
});

export function validationSchemaCategoriesForm() {
    return object().shape({
        categories: array().of(Category).nullable(),
    });
}

let inferedSchema = validationSchemaCategoriesForm();
export type CategoriesFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesCategoriesForm = {
    categories: [{ category: null }],
};
