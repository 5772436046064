import { isAxiosError } from "axios";
import { ApiResponseError } from "contexts/apiRequestContext";

export const handleErrorUseQuery = (error: any, setSubmitError: Function, endRequest: Function, onError: Function) => {
    let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
    let apiError: ApiResponseError = {
        type: 'error',
        message,
        code: 'UNKNOWN',
        errors: [],
    };

    if (isAxiosError(error)) {
        apiError = { type: 'error', code: error.code!, errors: [] };
        const { response } = error;
        if (response) {
            const { data } = response;
            if (data) {
                apiError = data as ApiResponseError;
            }
        }
    }

    endRequest(false);
    setSubmitError(apiError);
    onError(apiError);
};