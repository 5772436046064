import { StepNewChargesButton } from '../../StepsButtons/StepNewChargesButton';
import BillingParametersContainer from './BillingParametersContainer';
import { ToastType} from 'contexts/apiRequestContext';

interface BillingParametersStepProps {
    currentStep: number;
    onPrevStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export const BillingParametersStep: React.FC<BillingParametersStepProps> = ({
    currentStep,
    onPrevStep,
    setToast,
}) => {
    return (
        <>
            <BillingParametersContainer setToast={setToast} />
            <StepNewChargesButton {...{ currentStep, onPrevStep }} />
        </>
    );
};
