/* eslint-disable sonarjs/no-duplicate-string */
import type { FunctionComponent } from 'react';
import type { TransferTableProps } from './TransferTable.interface';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { columns as defaultColumns } from './TransferTable.mock';
import { MainBox } from './TransferTable.styled';
import { Typography } from '../../atoms';

const HeaderBar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Typography variant="sm" color="neutral.dark" lineHeight="29px">
        Últimas Transferências
      </Typography>
    </Stack>
  );
};

const NoRowsOverlayNew = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" mb={3}>
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        Nenhuma transação efetuada
      </Typography>
    </Stack>
  );
};

const NoResultsOverlayNew = () => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        Nenhum resultado encontrado
      </Typography>
    </Stack>
  );
};

export const TransferTable: FunctionComponent<TransferTableProps> = ({ rows = [], setPage, setRowsPerPage, page, rowsPerPage, actionCellRender, columns, rowCount }: TransferTableProps) => {  
  let definedColumns : GridColDef<any, any, any>[];
  if (!columns){
    definedColumns = defaultColumns;
  } else {
    definedColumns = columns;
  }
  if (actionCellRender) {
    definedColumns[definedColumns.length - 1].renderCell = actionCellRender
  }
  return (
    <Stack sx={{ position: 'relative' }}>
      <MainBox height={rows.length ? 400 : 500}>
        <DataGrid
          rows={rows}
          rowHeight={40}
          columns={definedColumns}
          disableColumnMenu
          pageSize={rowsPerPage}
          onPageSizeChange={setRowsPerPage}
          onPageChange={setPage}
          rowsPerPageOptions={[5, 10]}
          page={page}
          rowCount={rowCount}
          disableSelectionOnClick
          hideFooterPagination={rows.length === 0}
          hideFooterSelectedRowCount={rows.length === 0}
          components={{
            Toolbar: HeaderBar,
            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
            NoRowsOverlay: NoRowsOverlayNew,
            NoResultsOverlay: NoResultsOverlayNew,
          }}
          componentsProps={{
            toolbar: {
              variant: 'outlined',
              className: 'searchBar',
              sx: {
                display: 'flex',
                justifyContent: 'flex-end',
                maxWidth: '548px',
                alignItems: 'right',
              },
            },
          }}
        />
      </MainBox>
    </Stack>
  );
};
