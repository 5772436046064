import { CustomInput } from 'components/Forms/InputManualCalculation/inputManualCalculation.styled';
import React from 'react';
import { NumberFormatCustom } from '../CurrencyFormField/CurrencyFormField';

type CurrencyInputProps = {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    value: string | number | undefined;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    disabled?: boolean;
};

const CurrencyInput: React.FC<CurrencyInputProps> = ({ onChange, name, ...props }) => {
    return <NumberFormatCustom {...props} onChange={onChange} name={name} customInput={CustomInput} />;
};

export default CurrencyInput;
