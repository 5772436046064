import { Stack, styled } from "@mui/material";
import { activeTheme } from "services/theme";

type ContentNotificationStackProps = {
    isNew: boolean;
};

const theme = activeTheme();

export const HoursNotificationStask = styled(Stack)({
    background: '#F6F6F6',
    borderTop: `1px solid #BABFD0`,
    padding: '0 12px'
});
export const ContentNotificationStack = styled(Stack)<ContentNotificationStackProps>(({ isNew }) => ({
    background: isNew ? 'rgba(208, 77, 39, 0.10)' : theme.palette.common.white,
    padding: '15px',
    borderBottom: `1px solid #BABFD0`,
}));

export const DetailContentNotificationStask = styled(Stack)<ContentNotificationStackProps>(({ isNew }) => ({
    borderLeft: `2px solid ${isNew ? theme.palette.primary.main : theme.palette.common.black}`,
    padding: '10px',
    display: 'grid', 
    alignContent: 'center',
    gridTemplateColumns: 'auto auto', 
    alignItems: 'center', 
    gap: '20px'
}));

export const NotificationsListStack = styled(Stack)`
    overflow: scroll;
    overflow-x: hidden;
    
    ::-webkit-scrollbar {
        width: 5px;
    }
    
    ::-webkit-scrollbar-track {
        background-color: #ebebeb;
        -webkit-border-radius: 10px;
        border-radius: 10px;
    }
    
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: #6d6d6d; 
    }
`;
