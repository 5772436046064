import { Grid } from '@mui/material';
import { DataTable } from '@uy3/web-components';
import { CostsFiltersContainer } from 'components/DataTableFilters/CostsFilters/CostsFiltersContainer';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import React from 'react'

type CostsListProps = {
  queryData: any;
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setPage: (newPage: number) => void;
  page: number;
}

export const CostsList: React.FC<CostsListProps> = ({
  page,
  queryData,
  rowsPerPage,
  setPage,
  setRowsPerPage
}) => {
  const columns = [
    {
      field: 'confirmationTransactionTypeValueDisplay',
      headerName: 'Instrução',
      hideSortIcons: true,
      minWidth: 150,
      flex: 3,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value ?? "N/D"
    },
    {
      field: 'walletCode',
      headerName: 'Código do Convênio',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value ?? "N/D"

    },
    {
      field: 'costs',
      headerName: 'Custo',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value !== null ?  formatCurrencyInCents(cellValues?.value) : "N/D"
    },
    {
      field: 'documentNumber',
      headerName: 'Número do documento',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value ?? "N/D"
    },
    {
      field: 'billingFeeDate',
      headerName: 'Cobrado em',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value !== null ?
        new Date(cellValues?.value).toLocaleDateString('pt-BR') : "N/D"
    },
    {
      field: 'createdAt',
      headerName: 'Lançado em',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues: any) => cellValues?.value !== null ?
        new Date(cellValues?.value).toLocaleDateString('pt-BR') : "N/D"
    },
  ]

  return (
    <DataTable
      filterComponent={{
        showFilters: true,
        componentFilter: (
          <Grid mb={-0.5}>
            <CostsFiltersContainer />
          </Grid>
        ),
      }}
      columns={columns}
      rows={queryData !== undefined ? queryData?.data ?? [] : []}
      page={queryData ? queryData.page ?? 0 : page}
      rowCount={queryData?.totalItems ?? 0}
      pageCount={queryData?.totalPages ?? 0}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
    />
  )
}
