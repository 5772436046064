import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { IBankSlipCosts, IIUpdateNotificationRecipient, IWalletUpdateModel, WalletFilterListProps } from "./wallets.type";

const url = `/Wallet`

export const getWalletById = async (walletId: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.get(`${url}/${walletId}`, config);
}

export const getWalletsList = async (filters: WalletFilterListProps, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }, 
        params: filters
    };
    return await billingAxiosApi.get(`${url}`, config);
}

export const deleteWalletById = async (id: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.delete(`${url}/${id}`, config);
}

export const updateBankSlipCostAsync = async (id: string, payload: IBankSlipCosts,  token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.put(`${url}/${id}/UpdateBankSlipCost`, payload, config);
}

export const updateWalltByIdAsync = async (walletId: string, payload: IWalletUpdateModel,  token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.patch(`${url}/${walletId}`, payload, config);
}

export const updateNotificationRecipientAsync = async (walletId: string, payload: IIUpdateNotificationRecipient,  token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.put(`${url}/${walletId}/UpdateNotificationRecipient`, payload, config);
}

export async function getExportWalletPosition(walletCode: number , token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` }, 
        params: {walletCode}
    };

    // this service is here, but call method is bank slip   
    return await billingAxiosApi.get("/BankSlip/ExportWalletPosition", config);
}