import { DatePickerFormField, SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { useState } from 'react';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';

type BankAccountFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
    enableSelectFilter?: boolean;
    recordType?: string;
    typeFilter?:
    | 'transferAndPix'
    | 'payment'
    | 'operator'
    | 'favored'
    | 'bankAccountList'
    | 'pendingOperators'
    | 'pendingApprovals'
};

export const typeAccountOptios = [
    { label: 'Número da conta', value: 'accountNumber' },
    { label: 'Tipo de conta', value: 'typeAccount' },
    { label: 'Nível', value: 'f_level'}
];

export const pendingApprovalsLevelOptions = [
    { label: 'Aprovadores', value: 'Approvers' },
    { label: 'Todos os Operadores Pendentes', value: 'AllOperators' },
]

export const statusBankAccountOptions = [
    { label: 'Aprovado', value: 'Approved' },
    { label: 'Pendente', value: 'Pending' },
    { label: 'Rejeitado', value: 'Rejected' },
    { label: 'Tempo limite esgotado', value: 'Timeout' },
    { label: 'Cancelado pelo usuário', value: 'Canceled' },
    { label: 'Erro na autorização', value: 'Error' },
    { label: 'Em processamento', value: 'Processing' },
    { label: 'Efetivada', value: 'Finished' },
];

export const accountTypeOptions = [
    { label: 'Conta Simples de Pessoa Física', value: 'NaturalSimpleAccount' },
    { label: 'Conta Corrente de Pessoa Física', value: 'NaturalCheckingAccount' },
    { label: 'Conta Corrente de Pessoa Jurídica', value: 'LegalCheckingAccount' },
    { label: 'Entidades Públicas', value: 'PublicEntities' },
    { label: 'Depósitos Instituições Financeiras', value: 'FinancialInstitutionsDeposits' },
    { label: 'Poupança de Pessoa Física', value: 'NaturalSavingsAccount' },
    { label: 'Poupança de Pessoa Jurídica', value: 'LegalSavingsAccount' },
    { label: 'Depósitos Lotéricos', value: 'LotteryDeposits' },
    { label: 'Conta Escrow', value: 'EscrowAccount' },
];

export const typeAccountOperator = [
    { label: 'Conta escrow', value: 'Escrow' },
    { label: 'Conta de pagamento', value: 'Payment' },
    { label: "Escrow +", value: "EscrowPlus" }, 
    { label: "Escrow multicredores", value: "EscrowMulticreditors" },
];

export const optionsFilterBankAccountPending = [
    { label: 'Padrão (operadores)', value: 'BankAccountUser' },
    { label: 'Transferência', value: 'BankAccountTransfer' },
    { label: 'Pagamentos', value: 'BankAccountPayment' },
    { label: 'Favorecidos', value: 'BankAccountBeneficiary' }, 
    { label: 'Limites', value: 'BankAccountTransactionLimits' }, 
    
]

type BankAccountFiltersFormProps = {
    typeFilter?: string;
};

function BankAccountFiltersForm({ typeFilter }: BankAccountFiltersFormProps) {
    const { watch, setValue } = useFormContext();
    const [isLegalPerson, setIsLegalPerson] = useState<boolean>(false);

    const handleGetRegistrationNumber = (name: string, value: string) => {
        let registrationNumber = value.replace(/\D/g, '');
        if (registrationNumber.length > 11) {
            setIsLegalPerson(true);
        } else {
            setIsLegalPerson(false);
        }
        setValue(name, registrationNumber);
    };

    const filterOptions: any = {
        transferAndPix: [
            { label: 'Todos', value: 'searchString' },
            { label: 'Data da ocorrência', value: 'f_transferDate' },
            { label: 'Favorecido', value: 'f_beneficiaryName' },
            { label: 'CPF/CNPJ', value: 'f_beneficiaryRegistrationNumber' },
            { label: 'Status', value: 'f_status' },
        ],
        payment: [
            { label: 'Todos', value: 'searchString' },
            { label: 'Status', value: 'f_status' },
            { label: 'Data do pagamento', value: 'f_paymentDate' },
            { label: 'Valor do pagamento', value: 'f_amount' },
        ],
        operator: [
            { label: 'Todos', value: 'searchString' },
            { label: 'Nome', value: 'f_userUserId' },
            { label: 'Nível', value: 'f_level' },
            { label: 'Status', value: 'f_status' },
        ],
        favored: [
            { label: 'Todos', value: 'searchString' },
            { label: 'Nome', value: 'f_name' },
            { label: 'CPF/CNPJ', value: 'f_registrationNumber' },
            { label: 'Status', value: 'f_status' },
        ],
        bankAccountList: [
            { label: 'Todos', value: 'searchString' },
            { label: 'Nome', value: 'f_personName' },
            { label: 'CPF/CNPJ', value: 'f_personRegistrationNumber' },
            { label: 'Conta', value: 'f_account' },
            { label: 'Agência', value: 'f_agency' },
            { label: 'Modalidade', value: 'f_modality' },
        ],
        pendingOperators: typeAccountOptios,
        pendingApprovals: [{ label: 'Tipo de Aprovação', value: 'typeOfPendingApprovals' }]
    };

    const filterLevelOptions: {[type: string]: SelectOption[]} = {
        "operator": [
            { label: 'Visualizador', value: 'Viewer' },
            { label: 'Aprovador em Conjunto', value: 'JointApprover' },
            { label: 'Aprovador Master', value: 'MasterApprover' },
        ], 
        "pendingOperators": pendingApprovalsLevelOptions
    }

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={filterOptions[typeFilter!]}
            />

            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    label="Filtrar por todos"
                    variant="outlined"
                    name="searchString"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_name' && (
                <TextFormField
                    label="Filtrar por nome"
                    variant="outlined"
                    name="f_name"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_account' && (
                <TextFormField
                    label="Filtrar por número da conta"
                    variant="outlined"
                    name="f_account"
                    fullWidth
                    required
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_agency' && (
                <TextFormField
                    label="Filtrar por agência"
                    variant="outlined"
                    name="f_agency"
                    fullWidth
                    required
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_personName' && (
                <TextFormField
                    label="Filtrar por nome"
                    variant="outlined"
                    name="f_personName"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_userUserId' && (
                <TextFormField
                    label="Filtrar por nome"
                    variant="outlined"
                    name="f_userUserId"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_level' && (
                <SelectFormField
                    label="Filtrar por nível"
                    variant="outlined"
                    name="f_level"
                    options={filterLevelOptions[typeFilter!]}
                    fullWidth
                    required
                    showEndAdornment={true}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'f_modality' && (
                <SelectFormField
                    label="Filtrar por modalidade"
                    variant="outlined"
                    name="f_modality"
                    options={accountTypeOptions}
                    fullWidth
                    required
                    showEndAdornment={true}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'f_transferDate' && (
                <DatePickerFormField
                    label="Filtrar por data da ocorrência"
                    name="f_transferDate"
                    fullWidth
                    required
                    showEndAdornment={true}
                />
            )}
            {watch('optionFilter') === 'f_paymentDate' && (
                <DatePickerFormField
                    label="Filtrar por data do pagamento"
                    name="f_paymentDate"
                    fullWidth
                    required
                    showEndAdornment={true}
                />
            )}
            {watch('optionFilter') === 'f_beneficiaryName' && (
                <TextFormField
                    name="f_beneficiaryName"
                    variant="outlined"
                    label="Filtrar por favorecido"
                    fullWidth
                    required
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'f_beneficiaryRegistrationNumber' && (
                <TextFormField
                    label="Filtrar por CPF/CNPJ"
                    name="f_beneficiaryRegistrationNumber"
                    fullWidth
                    onChange={(e) =>
                        handleGetRegistrationNumber(
                            'f_beneficiaryRegistrationNumber',
                            e.target.value
                        )
                    }
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'f_personRegistrationNumber' && (
                <TextFormField
                    label="Filtrar por CPF/CNPJ"
                    name="f_personRegistrationNumber"
                    fullWidth
                    onChange={(e) =>
                        handleGetRegistrationNumber('f_personRegistrationNumber', e.target.value)
                    }
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'f_registrationNumber' && (
                <TextFormField
                    label="Filtrar por CPF/CNPJ"
                    name="f_registrationNumber"
                    onChange={(e) =>
                        handleGetRegistrationNumber('f_registrationNumber', e.target.value)
                    }
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'accountNumber' && (
                <TextFormField
                    label="Filtrar por número da conta"
                    name="accountNumber"
                    fullWidth
                    type="number"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'typeAccount' && (
                <SelectFormField
                    name="typeAccount"
                    label="Tipo de conta"
                    options={typeAccountOperator}
                    showEndAdornment={true}
                />
            )}
            {watch('optionFilter') === 'f_amount' && (
                <CurrencyFormField
                    label="Filtrar por valor do pagamento"
                    name="f_amount"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'f_status' && (
                <SelectFormField
                    name="f_status"
                    label="Filtrar por status"
                    required
                    showEndAdornment={true}
                    options={statusBankAccountOptions}
                    onClick={sendSubmitEvent}
                />
            )}

            {watch('optionFilter') === 'typeOfPendingApprovals' && (
                <SelectFormField
                    name="typeOfPendingApprovals"
                    label="Tipo de Aprovação"
                    required
                    showEndAdornment={true}
                    options={optionsFilterBankAccountPending}
                    onClick={sendSubmitEvent}
                />
            )}
        </>
    );
}

export const BankAccountFilters = ({
    currentValues,
    removeFilterSelected,
    typeFilter,
    enableSelectFilter = false, 
    recordType
}: BankAccountFiltersProps) => {
    return (
        <Grid mb={2}>
            <FilterStyleBase
                formFilters={<BankAccountFiltersForm typeFilter={typeFilter} />}
                currentValues={
                    <CurrentValuesSelected
                        recordType={`bank-account-${recordType}`}
                        currentValues={currentValues}
                        enableSelectFilter={enableSelectFilter}
                        removeFilterSelected={removeFilterSelected}
                    />
                }
            />
        </Grid>
    );
};
