import { string, object, InferType } from 'yup';

export function validationSchemaInvolvedBankAccountProductForm() {
    return object().shape({
        personId: string().typeError('Selecione uma Pessoa.').required("Pessoa: Precisa ser preenchido."),
        relatedToId: string().typeError('Relacionada a: é inválido.').nullable().notRequired(),
        typeOfRelationship: string().typeError('Selecione um Tipo de relação.').required("Tipo de relação: Precisa ser preenchido."),
        tenantName: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string().required('Correspondente é obrigatório.').typeError('Correspondente inválido.'),
            otherwise: string().nullable().optional()
        }),
        userId: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string()
                .required('Operador é obrigatório.')
                .typeError('Operador inválido.')
                .test(
                    'is-valid-user',
                    'O CPF do operador deve ser o mesmo da pessoa selecionada',
                    function () {
                        const { personRegistrationNumber, userIdRegistrationNumber } = this.parent;
                        return personRegistrationNumber === userIdRegistrationNumber;
                    }
                ),
            otherwise: string().nullable().optional(),
        }),
        level: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string().required('Nível de permissão é obrigatório.').typeError('Nível de permissão inválido.'),
            otherwise: string().nullable().optional()
        }),
    });
};

let inferedSchema = validationSchemaInvolvedBankAccountProductForm();
export type BankAccountProductFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesInvolvedBankAccountProduct = {
    personId: null,
    relatedToId: null,
    typeOfRelationship: null,
    userId: null,
    level: null,
    tenantName: null
};