import { string, object } from 'yup';

export function validationSchemaResetPasswordForm() {
    return object().shape({
        password: string()
            .typeError('Senha temporária precisa ser preenchida.')
            .required("Senha temporária precisa ser preenchida")
    });

};

export const defaultValuesPasswordForm = {
    password: null,
};
