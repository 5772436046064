import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { FilterProvider } from 'contexts/filterContext';
import React, { FunctionComponent } from 'react';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { OperationBatchAssigmentList } from './OperationBatchAssigmentList';

interface CreditApprovListWithFiltersProps {
    batchAssignmentData: any;
    page: number;
    rowsPerPage: number;
    setPage: (page: number) => void;
    rowsSelected: string[] | GridRowId[] | undefined;
    handleSelectionModelChange: (selectionModel: GridSelectionModel) => void;
    onChangeRowsPerPage: (page: number) => void;
    personAutocompleteOptions: any;
    isLoading: boolean;
}

const OperationBatchListWithFilters: FunctionComponent<CreditApprovListWithFiltersProps> = ({
    personAutocompleteOptions,
    handleSelectionModelChange,
    page,
    rowsPerPage,
    onChangeRowsPerPage,
    batchAssignmentData,
    rowsSelected,
    setPage,
    isLoading,
}) => {
    return (
        <FilterProvider
            availableFilters={{
                personId: {
                    label: 'Tomador',
                    type: 'autocomplete',
                    multiple: false,
                    options: personAutocompleteOptions?.listOptions,
                },
                status: {
                    label: 'Status',
                    type: 'select',
                    multiple: true,
                    options: creditNoteSelectFilterOptions,
                },
                creditNoteNo: {
                    label: 'Número da operação',
                    type: 'text',
                    multiple: false,
                },
                initialDate: {
                    label: 'Data de início',
                    type: 'dateRange',
                    multiple: false,
                    dateRangeName: 'initialDate',
                },
                maxAssignmentValue: {
                    label: 'Valor de Cessão (Máximo)',
                    type: 'text',
                    multiple: false,
                },
                minAssignmentValue: {
                    label: 'Valor de Cessão (Mínimo)',
                    type: 'text',
                    multiple: false,
                },
                maxValue: { label: 'Valor máximo', type: 'text', multiple: false },
                minValue: { label: 'Valor mínimo', type: 'text', multiple: false },
            }}
        >
            <ListSkeleton isLoading={isLoading}>
                <OperationBatchAssigmentList
                    handleSelectionModelChange={handleSelectionModelChange}
                    rowsSelected={rowsSelected}
                    onChangeRowsPerPage={onChangeRowsPerPage}
                    page={page}
                    queryData={batchAssignmentData}
                    rowsPerPage={rowsPerPage}
                    setPage={setPage}
                    personAutocompleteOptions={personAutocompleteOptions}
                />
            </ListSkeleton>
        </FilterProvider>
    );
};

export default OperationBatchListWithFilters;
