import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { activeTheme } from 'services/theme';

interface DrawerDarfWithBarCodeFormProps {
    onClose: () => void;
};

const theme = activeTheme();
export const DrawerDarfWithBarCodeForm: React.FC<DrawerDarfWithBarCodeFormProps> = ({ onClose }) => {
    return (
        <Stack spacing={2}>
            <Grid item>
                <TextFormField
                    fullWidth
                    name="name"
                    label="Nome do arrecadador"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name="receitaFederalCodeId"
                    label="Código da receita federal"
                    variant="outlined"
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type="number"
                    name="referenceNumber"
                    label="Número do documento"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type="number"
                    name="interestValue"
                    label="Valor do Juros"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type="number"
                    name="interestAndChargesValue"
                    label="Valor do Juros e Encargos"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <DatePickerFormField
                    name="calculationPeriod"
                    label="Data de apuração"
                    fullWidth
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name="trafficTicketValue"
                    label="Valor da Multa"
                    variant="outlined"
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name="paymentValue"
                    label="Valor do pagamento"
                    variant="outlined"
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name="totalValue"
                    label="Valor total"
                    variant="outlined"
                    required
                />
            </Grid>
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};
