import { Grid, Stack } from '@mui/material'
import { Button, Typography, UploadIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';

type UploadWithRelatedHeaderType = {
  addNewDocuemnt: () => void;
  hasPermissionCreateOrUpdate: boolean
  disabled: boolean;
};

const theme = activeTheme();

export const UploadWithRelatedHeader: React.FC<UploadWithRelatedHeaderType> = ({ addNewDocuemnt, disabled, hasPermissionCreateOrUpdate }) => {
  const { id } = useParams();
  const isDisableAndNotNewCreditNote = disabled && id!== 'nova'
  const color = !isDisableAndNotNewCreditNote ? theme.palette.common.black : theme.palette?.grey['400'];
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2}>
      <Grid>
        <Typography
          variant="h4"
          color="neutral.dark"
          fontStyle="normal"
          fontWeight="700"
          fontSize="28px"
          lineHeight="38.4px"
        >
          Documentos
        </Typography>
        <Typography variant="md" fontStyle="normal" fontSize="16px">
          Todos os documentos relacionados a operação
        </Typography>
      </Grid>

      {hasPermissionCreateOrUpdate && <Button
        onClick={addNewDocuemnt}
        sx={{ color }}
        disabled={isDisableAndNotNewCreditNote}
        startIcon={<UploadIcon sx={iconSx} htmlColor={color} />}
      >
        Novo documento adicional
      </Button>}
    </Stack>
  )
};