import { Stack } from '@mui/material'
import { Button, CloseIcon, RightIcon, Typography } from '@uy3/web-components'
import {  TextFormField } from 'components/Forms/FormFields'
import { MaskedInput } from 'components/Forms/MaskedInput'
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants'
import { useApiRequest } from 'contexts/apiRequestContext'

import { activeTheme } from 'services/theme'

type QueryBasicDataProps = {
    isLegalPerson: boolean
    onClose: () => void
}

const theme = activeTheme();


export const QueryBasicData = ({ isLegalPerson, onClose }: QueryBasicDataProps) => {
    const { submitting } = useApiRequest();

    return (
        <Stack spacing={2}>
            <Typography variant='md' fontWeight='bold'>Informe o seu {isLegalPerson ? 'CNPJ' : 'CPF'} no campo abaixo</Typography>

            <TextFormField
                name="registrationNumber"
                variant="outlined"
                label={isLegalPerson ? 'CNPJ' : 'CPF'}
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                }}
                fullWidth
            />

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
