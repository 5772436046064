import Timeline from "components/Timeline";
import { useCreditData } from "contexts/creditNote/creditContext";
import { useParams } from "react-router";

export function TimelineTabContainer() {
    const { id } = useParams();
    const { creditData } = useCreditData(id!);

    const timeline = creditData?.timeline ?? [];
    const status = creditData?.statusDisplay ?? '';

    return (
        <>
            <Timeline timeline={timeline} title="operação" key={1} status={status} />
        </>
    )
}
