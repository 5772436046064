/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, EditIcon } from '@uy3/web-components';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';
import { useEffect } from 'react';
import { IBillingSettingFull } from 'services/walletManagement/billingSetting/billingSetting.types';
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';

interface AddInterestModelFormProps {
    communicationSettingAutocomplete: AutocompleteOptionList;
    refetchParametrs: () => void;
    billingSettingData: IBillingSettingFull[];
    setAction: (step: string) => void; 
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
const AddInterestModelForm: React.FC<AddInterestModelFormProps> = ({ billingSettingData, refetchParametrs, communicationSettingAutocomplete, setAction}) => {
    const { watch, setValue, getValues } = useFormContext();
    const billingSettingId = watch('billingSettingId') ?? getValues()?.billingSettingId;

    const saveParametersByIdSelected = () => {
        const getByParametrsChargesId = billingSettingData?.find(item => item.id === billingSettingId);
        setValue('interestModelSelected', getByParametrsChargesId);
    }

    useEffect(() => {
        saveParametersByIdSelected()
        refetchParametrs();
    }, [billingSettingId])


    const optionsSelect = billingSettingData.map((item) => {
        return {
            label: item?.name,
            value: item?.id
        }
    });

    return (
        <>
            <CustomTitle title="Qual será a régua de cobrança do pagamento?" variant="lg" />
            <Grid>
                <SelectFormField
                    fullWidth
                    label="Régua de cobrança"
                    name='billingSettingId'
                    showButtonClearValue
                    options={optionsSelect}
                />
                <Stack justifyContent="flex-end" direction="row">
                    <Button
                        onClick={() => {
                            setAction('add-billing-setting')
                            saveParametersByIdSelected();
                        }}
                        startIcon={<EditIcon htmlColor={colorMain} sx={iconSx} />}
                    >
                        Adicionar régua de cobrança
                    </Button>
                </Stack>
            </Grid>

            <CustomTitle title="Régua de comunicação" variant="lg" />



            <Grid>

                <AutocompleteField
                    label="Régua de comunicação"
                    name="communicationSettingId"
                    displayName="communicationSettingDisplay"
                    {...communicationSettingAutocomplete!}
                />

                <Stack direction='row' justifyContent='end' alignItems='center'>
                    <Button
                        size="large"
                        variant="text"
                        color="primary"
                        onClick={() => setAction('add-communication-setting')}
                        style={{ cursor: 'pointer' }}
                        startIcon={
                            <AddIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                        }
                    >
                        Adicionar régua de comunicação
                    </Button>
                </Stack>
            </Grid>

            <CustomTitle title="Instruções" variant="lg" />

            <SelectFormField
                label="Instrução de protesto"
                name="firstInstructionDebtCollection"
                showButtonClearValue
                options={firstInstructionDebtCollectionOptions}
            />

            {watch('firstInstructionDebtCollection') === "06" &&
                <TextFormField
                    label="Nº de dias após vencimento para envio automático"
                    variant="outlined"
                    required
                    name="secondInstructionDebtCollection"
                    fullWidth
                    type='number'
                />
            }
        </>
    );
};

export default AddInterestModelForm;
