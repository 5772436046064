/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import { Button, CardData, CloseIcon, Drawer, Modal } from '@uy3/web-components';
import { useState } from 'react';
import CreditLimitList from './CreditLimitList';
import { useCreditStatusRequestList } from 'contexts/creditStatusRequest';
import { RefreshProgress } from 'components/RefreshProgress';
import { Grid, Stack } from '@mui/material';
import ApproveCreditRequestFormContainer from '../Modal/ApproveCreditRequestForm/ApproveCreditRequestFormContainer';
import CreditRequestFormContainer from '../Modal/CreditRequestForm/CreditRequestContainer';
import { activeTheme } from 'services/theme';
import InfoMessage from 'components/InfoMessage/InfoMessage';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import Toast from 'components/Toast/Toast';
import { toastState, ToastType } from 'contexts/apiRequestContext';

type CreditLimitProps = {
    id?: string | undefined;
    disableRequestDataSet?: boolean;
};

const theme = activeTheme();
function CreditLimit({ id, disableRequestDataSet }: CreditLimitProps) {
    const [modal, setModal] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [rowData, setRowData] = useState<any>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { personId } = useParams();
    const dataId = id ?? personId;
    const { data, status, refetch, error, isFetching } = useCreditStatusRequestList({
        page: 0,
        size: 15,
        personId: dataId!,
    });

    const closeModal = () => setModal(false);
    const onDrawer = () => setOpenDrawer((open) => !open);

    return (
        <GenericErrorBoundary error={error} status={status} fallback="limite de crédito">
            <RefreshProgress refreshing={isFetching} />
            <Toast toast={toast} setToast={setToast} />

            <Modal
                title={!rowData ? 'Solicitar crédito' : 'Operação'}
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={
                    <>
                        {!rowData ? (
                            <CreditRequestFormContainer
                                {...{ setToast, closeModal, refetch, id }}
                            />
                        ) : (
                            <ApproveCreditRequestFormContainer
                                rowData={rowData}
                                closeModal={closeModal}
                                refetch={refetch}
                            />
                        )}
                    </>
                }
            />

            <Drawer anchor="right" title="Detalhes" open={openDrawer} onClose={onDrawer}>
                <>
                    <CardData
                        listItem={[
                            {
                                id: '1',
                                title: 'Criado por',
                                value: `${rowData?.createdBy?.userIdDisplay}`,
                            },
                        ]}
                    />
                    <CardData
                        listItem={[
                            {
                                id: '2',
                                title: 'Atualizado por',
                                value: `${rowData?.updatedBy?.userIdDisplay}`,
                            },
                        ]}
                    />
                    <Stack direction="row" justifyContent="flex-end" spacing={3}>
                        <Button
                            startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                            variant="outlined"
                            size="medium"
                            onClick={onDrawer}
                        >
                            Fechar
                        </Button>
                    </Stack>
                </>
            </Drawer>

            <CreditLimitList
                result={data}
                setRowData={setRowData}
                setModal={setModal}
                setOpenDrawer={onDrawer}
            />

            {disableRequestDataSet && (
                <div style={{ marginTop: 15 }}>
                    <InfoMessage message="Você não tem permissão para solicitar esse recurso" />
                </div>
            )}

            <Grid container spacing={2} mt={2}>
                <Grid item xs={1.5}>
                    <Button
                        fullWidth
                        variant="contained"
                        disabled={disableRequestDataSet}
                        onClick={() => {
                            setModal(true);
                            setRowData(undefined);
                        }}
                    >
                        Solicitar
                    </Button>
                </Grid>

                <Grid item xs={1.5}>
                    <Button fullWidth variant="contained" onClick={() => refetch()}>
                        Atualizar
                    </Button>
                </Grid>
            </Grid>
        </GenericErrorBoundary>
    );
}

export default CreditLimit;
