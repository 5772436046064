import { UserGroupListTab } from './GroupTab';
import { Box, Grid } from '@mui/material';
import { Alert, Button, Drawer, Popup } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import { useGroupInsertUserMutation, useUserGroupListData } from 'contexts/userContext';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { removeUserGroupById } from 'services/user/userGroups';
import { useIdentity } from 'contexts/identityContext';
import { FormProvider } from 'contexts/formContext';
import AddGroupForm from './GroupDrawer/GroupForm';
import {
    defaultValuesGroupForm,
    GroupFormSchema,
    validationSchemaGroupForm,
} from './GroupDrawer/GroupSchema';
import { useGroupListData } from 'contexts/groupContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { GroupHeader } from './GroupHeader';

export const GroupTabContainer = () => {
    const { currentTenantId } = useTenant();
    const [open, setOpen] = useState(false);
    const { userId } = useParams();
    const { token } = useIdentity();
    const [rowsPerPage, setRowsPerPage] = useState<number>(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
    const [page, setPage] = useState<number>(0);
    const [paginationToken, setPaginationToken] = useState<any | undefined>({ 0: undefined });
    const [rowData, setRowData] = useState<any | undefined>(undefined);
    const { setSubmitError } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);

    const {
        status: groupStatus,
        data: groupData,
        error: groupError,
        groupAutoCompleteProps
    } = useGroupListData({ page: 0, size: 10 }, 'always');

    const {
        status: opStatus,
        data: opData,
        error: opError,
        refetch: refetchGroups,
        isFetching
    } = useUserGroupListData({ page, size: rowsPerPage, paginationToken: paginationToken[page] }, userId!);

    const groupSchema = validationSchemaGroupForm();
    const groupDefaultValues = defaultValuesGroupForm;

    const onClosePopup = () => setRowData(undefined);
    const onRemoveGroup = async (groupName: string) => {
        await removeUserGroupById(groupName, userId!, token!, currentTenantId);
        await refetchGroups();
        onClosePopup();
    };

    const onSuccess = () => {
        refetchGroups();
        setOpen(false);
        setSubmitError(undefined);
        const title = 'Grupo adicionado com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        setOpen(false);
    };

    const { mutate } = useGroupInsertUserMutation(userId!, onSuccess, onError);
    const onSubmitGroup = async (values: GroupFormSchema) => await mutate(values.groupNameDisplay);

    if (groupStatus === 'error' || opStatus === 'error' || opData?.type === 'error')
        return <Error error={opError ?? groupError} />;

    const onChangeRowsPerPage = (page: number) => {
        setPaginationToken({ 0: null });
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPaginationToken({ ...paginationToken, [page + 1]: groupData!.paginationToken });
        setPage(page);
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {opError && userId !== 'novo' && (
                <Box mb={1}>
                    <Alert
                        severity="error"
                        text="Erro ao carregar dados do usuário."
                        variant="filled"
                    />
                </Box>
            )}
            <GroupHeader refetchGroups={refetchGroups} setOpen={setOpen}/>
            <RefreshProgress refreshing={isFetching} />
            {opData !== undefined && (
                <UserGroupListTab
                    groupData={opData!}
                    page={page}
                    setPage={onChangePage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    onRemoveGroup={onRemoveGroup}
                    setOpenPopup={setRowData}
                />
            )}

            <Drawer
                anchor="right"
                title="Adicionar novo grupo"
                open={open}
                onClose={() => setOpen(false)}
            >
                <FormProvider
                    validationSchema={groupSchema}
                    defaultValues={groupDefaultValues}
                    onSubmit={onSubmitGroup}
                >
                    <AddGroupForm onClose={() => setOpen(false)} groupAutoCompleteProps={groupAutoCompleteProps} />
                </FormProvider>
            </Drawer>

            <Popup
                open={rowData !== undefined}
                title={'Excluir'}
                text={`Tem certeza que deseja excluir esse registro?`}
                onClose={onClosePopup}
                children={
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClosePopup}
                            size="medium"
                            sx={{ mt: 3, mr: 2 }}
                        >
                            Não, cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onRemoveGroup(rowData.id)}
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Sim, confirmar
                        </Button>
                    </Grid>
                }
            />
        </>
    );
};
