import DynamicAccordion from "components/Datasets/Accordion/Accordion";
import { useFormContext } from "contexts/formContext";
import { DatasetLegalPersonContainer } from "pages/LegalPerson/LegalPersonForm/Tabs/DatasetContainer/DatasetContainer";
import { DatasetNaturalPersonContainer } from "pages/NaturalPerson/NaturalPersonForm/Tabs/DatasetContainer/DatasetContainer";
import { useParams } from "react-router";
import { uniqBy } from "lodash";
import { Typography } from "@uy3/web-components";
import { DatasetDetailsCredit } from "components/RiskScore/DatasetDetailsCredit/DatasetDetailsCredit";
import { useDatasetData, useDatasetMutation } from "contexts/datasetContext";
import { ComplianceOrCreditAnalysisGet } from "services/datasets/datasets.interface";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import { Error } from "components/Errors/Error";
import DatasetWrapper from "../DatasetWrapper";
import { useUserPermissionData } from "contexts/userContext";
import { checkActionsPerms } from "services/permissions";
import InfoMessage from "components/InfoMessage/InfoMessage";

type Params = {
    bankAccountId?: string;
    id?: string;
};
export const KycTabContainer = () => {
    const { bankAccountId, id } = useParams<Params>();
    const { data: permissionsData } = useUserPermissionData();
    const { watch } = useFormContext();
    const { setSubmitError } = useApiRequest();

    const recordType = bankAccountId ? 'BankAccountRequest' : "CreditNote";
    const datasetId = bankAccountId ?? id;
    const { dataset, status, error, refetch } = useDatasetData(recordType, datasetId!, "complianceanalysis");

    const data = dataset?.find(dataset => dataset?.dataSet === "compliance_analysis") as ComplianceOrCreditAnalysisGet;
    const result = data?.result;

    const onSuccess = () => refetch();
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutate, isLoading } = useDatasetMutation(recordType, datasetId!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    if (status === 'error') return <Error error={error} />

    const owner = {
        ownerDiscriminator: watch("ownerDiscriminator"),
        ownerId: watch("ownerId"),
        ownerDisplay: watch("ownerDisplay")
    };

    const person = {
        personDiscriminator: watch("personDiscriminator"),
        personId: watch("personId"),
        personIdDisplay: watch("personDisplay")
    };

    const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, 'CreditNote');
    const disableRequestDataSet = !checkActionsPerms('RequestDataSet', permissionsData, 'CreditNote');

    const involveds = watch("relatedPerson") ?? watch("relatedPersonCreditNote") ?? [];
    const filterInvolvedPersons = involveds?.filter(
        (value: { typeOfRelationship: string }) => value.typeOfRelationship !== "Witness"
    );

    const naturalPersons = uniqBy(
        [
            ...(filterInvolvedPersons || []).filter((i: { personDiscriminator: string }) => i.personDiscriminator === "NaturalPerson"),
            owner?.ownerDiscriminator === "NaturalPerson" ? {
                personId: owner?.ownerId,
                personIdDisplay: owner?.ownerDisplay
            } :
                person?.personDiscriminator === "NaturalPerson" && {
                    personId: person?.personId,
                    personIdDisplay: person?.personIdDisplay
                }
        ].filter(x => x !== (undefined || false)), i => i?.personId
    );

    const legalPersons = uniqBy(
        [
            ...(filterInvolvedPersons || []).filter((i: { personDiscriminator: string }) => i.personDiscriminator === "LegalPerson"),
            owner?.ownerDiscriminator === "LegalPerson" ? {
                personId: owner?.ownerId,
                personIdDisplay: owner?.ownerDisplay
            } :
                person?.personDiscriminator === "LegalPerson" && {
                    personId: person?.personId,
                    personIdDisplay: person?.personIdDisplay
                }
        ].filter(x => x !== (undefined || false)), i => i?.personId
    );

    const accordionAnalysis = [
        {
            title: "Análise de Compliance",
            content: <>
                {hasPermissionToReadDataset ?
                    <DatasetWrapper
                        dataSet={data}
                        handleUpdateData={() => handleUpdateData("complianceanalysis")}
                        isLoading={isLoading}
                        disableRequestDataSet={disableRequestDataSet}
                    >
                        <DatasetDetailsCredit
                            dataDetails={result ?? []}
                        />
                    </DatasetWrapper>
                    :
                    <InfoMessage />
                }
            </>
        }
    ];

    const accordionLegalPersons = legalPersons?.map(person => ({
        title: person?.personIdDisplay,
        content: <DatasetLegalPersonContainer personId={person?.personId} />
    })).filter(x => x.title !== undefined);


    const accordionNaturalPerson = naturalPersons?.map(person => ({
        title: person?.personIdDisplay,
        content: <DatasetNaturalPersonContainer personId={person?.personId} />
    })).filter(x => x.title !== undefined);

    return (
        <>
            <DynamicAccordion accordion={accordionAnalysis} />

            {legalPersons?.length > 0 && <>
                <Typography variant="h5" color="neutral.medium" fontWeight="bold" mt={4} mb={2}>Pessoa Jurídica</Typography>
                <DynamicAccordion accordion={accordionLegalPersons} id="accordion-pj" />
            </>}

            {naturalPersons?.length > 0 && <>
                <Typography variant="h5" color="neutral.medium" fontWeight="bold" mt={4} mb={2}>Pessoa Física</Typography>
                <DynamicAccordion accordion={accordionNaturalPerson}  id="accordion-pf" />
            </>}

        </>
    );

};
