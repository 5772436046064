/* eslint-disable react-hooks/exhaustive-deps */
import { IconButton, Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon, DeleteIcon, PersonIcon, Alert } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { useFieldArray } from 'react-hook-form';
import AddSignatureForm, { metadataKey } from './AddSignatureForm';
import { Signer } from 'services/signature/signature.types';
import { useEffect } from 'react';

type AddSignatoriesFormProps = {
    onClose: () => void;
    persons: any[];
    signers: Signer[];
    onDelete: (id: string) => void;
};

type AddSignatoriesProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
    persons: any[];
};

const AddSignatories = ({ fields, remove, persons }: AddSignatoriesProps) => (
    <>
        <Stack direction="column" spacing={2}>
            {fields.map((item, index) => (
                <Stack key={item.id} direction="row" alignItems="center">
                    <AddSignatureForm key={item.id} index={index} persons={persons} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

const theme = activeTheme();
export const AddSignatureFormWrapper = ({ onClose, persons, signers, onDelete }: AddSignatoriesFormProps) => {
    const { control, setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: 'signatories',
    });

    const currentAccordion = watch(metadataKey);

    const removeSignatories = (index: number) => {
        const signerToRemove = fields[index] as Signer;
        
        if (signerToRemove) {
            const existingSigner = signers?.find(signer => signer?.personId === signerToRemove?.personId);
            if (existingSigner) {
                onDelete(existingSigner.id);
            }
        }
    
        if (index < currentAccordion) {
            setValue(metadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(metadataKey, null);
        }
        remove(index);
    };

    //initialize the form with signers list data
    const signersData = signers?.map((signer) => {
        return {
            displayName: `${signer?.displayName} - (Adicionado Anteriormente)`,
            personId: signer?.personId,
            typeOfRelationship: signer?.typeOfRelationship,
            signatureType: signer?.signatureType,
            disabled: true,
        };
    });

    useEffect(() => {
        return () => {
            if (signersData.length > 0) {
                append(signersData);
                setValue(metadataKey, null);
            }
        };
    }, []);

    const addNewComm = () => {
        append({});
        setValue(metadataKey, fields.length);
    };

    const colorMain = theme.palette.primary.main;
    const isEmptyField = fields.length === 0
    return (
        <Stack spacing={4}>
            {isEmptyField && (
                <Alert
                    severity="info"
                    text="Você ainda não adicionou nenhum signatário."
                    variant="filled"
                />
            )}
            {persons.length === 0 && fields.length > 0 && (
                <Alert
                    severity="info"
                    text="Não há signatários disponíveis para adicionar."
                    variant="filled"
                />
            )}
            <AddSignatories fields={fields} remove={removeSignatories} persons={persons} />
            {persons.length !== 0 && (
                <Button
                    sx={{
                        justifyContent: 'flex-start',
                        fontWeight: 600,
                        fontSize: '15px',
                    }}
                    variant="text"
                    onClick={addNewComm}
                    startIcon={<PersonIcon htmlColor={colorMain} sx={iconSx} />}
                >
                    Adicionar signatário
                </Button>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={colorMain} sx={iconSx} />}
                >
                    Fechar
                </Button>
                {persons.length !== 0 && (
                    <Button
                        startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                        variant="contained"
                        size="medium"
                        type="submit"
                        disabled={isEmptyField}
                    >
                        Salvar
                    </Button>
                )}
            </Stack>
        </Stack>
    );
};
