/* eslint-disable no-useless-escape */
import { string, object, ref } from 'yup';

export function ChangePasswordFormValidationSchema() {
    return object().shape({
        oldPassword: string()
            .typeError('Senha inválida.')
            .required('Senha: Precisa ser preenchido.'),
        password: string()
            .typeError('Senha inválida.')
            .required('Senha: Precisa ser preenchido.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número'
            )
            .min(8, 'A senha é muito curta - deve ter no mínimo 8 caracteres.'),
        passwordConfirmation: string()
            .typeError('Confirmar senha inválida.')
            .required('Confirmar senha: Precisa ser preenchido.')
            .oneOf([ref('password'), null], 'A senha e a confirmação de senha não conferem'),
    });
}

export const ChangePasswordFormDefaultValues = {
    oldPassword: null,
    password: null,
    passwordConfirmation: null
};
