import { object } from "yup";

export const DescontoFormSchema = () => {
    return object().shape({
       
    });
};

export const DescontoFormDefaultValues = {
    
};