import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

export const DetailsContainer = styled(Grid)(() => ({
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    backgroundColor: '#FFFFFF',
    marginTop: '32px',
    border: '0.5px solid #BABFD0',    
    padding: '24px',
}));
