import { FormProvider } from 'contexts/formContext';
import {
    defaultValuesCreditLimitForm,
    validationSchemaCreditLimitForm,
} from '../CreditLimitSchema';
import { useCreditStatusRequestMutation } from 'contexts/creditStatusRequest';
import { useCreditProdutList } from 'contexts/creditProductContext';
import { ApiResponseError, handleOnError, ToastType, useApiRequest } from 'contexts/apiRequestContext';
import { useParams } from 'react-router';
import CreditRequestForm from './CreditRequestForm';
import { CreditStatusRequestCreateModel } from 'services/creditStatusRequest';
import { Dispatch, SetStateAction } from 'react';

type CreditRequestContainerProps = {
    closeModal: () => void;
    refetch: () => void;
    id: string | undefined;
    setToast: Dispatch<SetStateAction<ToastType>>;
};

function CreditRequestFormContainer({ refetch, closeModal, id, setToast }: CreditRequestContainerProps) {
    const { setSubmitError } = useApiRequest();
    const { personId } = useParams();
    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, 'always');

    const onSuccess = () => {
        refetch();
        closeModal();
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        handleOnError(error, setToast);
    }

    const { mutateAsync } = useCreditStatusRequestMutation(onSuccess, onError);
    const handleSubmit = async (values: CreditStatusRequestCreateModel) => await mutateAsync({ ...values, personId: id ?? personId! });

    return (
        <FormProvider
            validationSchema={validationSchemaCreditLimitForm()}
            defaultValues={defaultValuesCreditLimitForm}
            onSubmit={handleSubmit}
        >
            <CreditRequestForm
                productList={creditProductAutoCompleteProps}
                closeModal={closeModal}
            />
        </FormProvider>
    );
}

export default CreditRequestFormContainer;
