import Toast from 'components/Toast/Toast';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import DatasetWrapper, { contentStyle } from '../DatasetWrapper';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { DataTable, Popup } from '@uy3/web-components';
import { getFraudColumns } from './ColumnsList';
import { FraudDataset } from 'services/fraudRecord';

interface IFraudRecords {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
    recordType: string;
}

export function FraudRecords({ datasetId, disableRequestDataSet, recordType }: IFraudRecords) {
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();
    const [page, setPage] = useState(0);
    const [openModal, setOpenModal] = useState({
        open: false,
        values: {} as FraudDataset,
    });
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );

    const id = datasetId ? datasetId : personId;
    const { dataset, isFetching, refetch } = useDatasetData(
        recordType,
        id!,
        'ClearSaleR6Fraud?getOnlyMostRecent=false'
    );
    const { setSubmitError } = useApiRequest();

    const name = watch('name');

    const successMessage = `Ótimo! Conseguimos realizar a consulta para a análise de fraude${
        name ? ` de ${name}` : ''
    } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };
    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset.dataSet === 'clearsale_r6_fraud');
    const result = data?.result as FraudDataset;
    const respApiResult = result?.responseAPI;
    const rowData = dataset?.map((item) => item?.result);

    const registrationNumber = formatDocumentNumber(result?.registrationNumber) ?? 'N/D';
    const newDate = new Date(respApiResult?.createdAt).toLocaleString();
    const isInvalidDate = isNaN(new Date(respApiResult?.createdAt).getTime());
    const createdAt = isInvalidDate ? 'N/D' : newDate;

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const handleOnClick = (values: FraudDataset) => {
        setOpenModal({
            open: true,
            values,
        });
    };

    const fraudColumns = getFraudColumns({ handleOnClick });

    const tranformData = (data: FraudDataset[]) => {
        if (!data) return [];
        const transformedData = data.flatMap((row) => {
            const { responseAPI, requestType } = row ?? {};
            const results = responseAPI?.results;

            const newRowData =
                results?.map((result) => {
                    return {
                        ...result,
                        requestType,
                    };
                }) ?? [];

            return newRowData.filter((item) => item);
        });

        return transformedData;
    };

    const fraudData = tranformData(rowData);

    return (
        <div>
            <Popup
                open={openModal.open}
                title="Detalhes da Fraude"
                onClose={() =>
                    setOpenModal({
                        open: false,
                        values: {} as FraudDataset,
                    })
                }
                children={
                    <pre style={contentStyle}>{JSON.stringify(openModal.values, null, 2)}</pre>
                }
            />
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle
                        title={'Registro de Consulta'}
                        value={registrationNumber}
                        key="registrationNumber"
                    />
                    <HandleTitle title={'Consultado em'} value={createdAt} key="createdAt" />
                    <DataTable
                        loading={isLoading || isFetching}
                        getRowId={() => Math.random().toString()}
                        columns={fraudColumns}
                        rows={fraudData ?? []}
                        page={page}
                        rowCount={fraudData?.length ?? 5}
                        rowsPerPage={rowsPerPage}
                        setPage={onChangePage}
                        setRowsPerPage={onChangeRowsPerPage}
                        paginationMode="client"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('ClearSaleR6Fraud')}
                disableRequestDataSet={disableRequestDataSet}
                isLoading={isLoading || isFetching}
            />
        </div>
    );
}
