import { Stack, styled, Switch } from '@mui/material'
import { Typography } from '@uy3/web-components';
import { CheckboxFormFieldProps } from 'components/Forms/FormFields'
import { SpanCustom } from 'components/HelpPermission/HelpPermission';
import { TooltipComponent } from 'components/Tooltip/Tooltop'
import { useFormContext } from 'contexts/formContext';
import { ReactNode } from 'react';
import { activeTheme } from 'services/theme';

type CheckboxWithTooltipProps = {
    checkboxFormField: CheckboxFormFieldProps;
    helpMessage?: ReactNode;
}

const theme = activeTheme();

export const CheckboxWithTooltip = ({ checkboxFormField, helpMessage }: CheckboxWithTooltipProps) => {
    const { watch, setValue } = useFormContext();
    const name = checkboxFormField?.name;
    const value = Boolean(watch(name));

    return (
        <TooltipComponent
            placement='left'
            title={helpMessage ? (
                <Typography fontSize='14px'>
                    <SpanCustom>{`${checkboxFormField.label}`}</SpanCustom> - &nbsp;
                    {helpMessage}
                </Typography>
            ) : ""}
        >
            <ActionStack>
                <Typography fontSize='16px'>{checkboxFormField.label}</Typography>
                <SwitchPermission
                    id="simple-switch-label"
                    onChange={(_: any, checked: boolean) => setValue(name, checked)}
                    checked={value}
                    name={name}
                />
            </ActionStack>
        </TooltipComponent>
    )
}

const ActionStack = styled(Stack)(() => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #ddd',
    padding: '10px 0',
    '&:only-child': {
        borderBottom: 0,
    },
}))

const SwitchPermission = styled(Switch)(() => ({
    width: 42, // Largura do switch
    height: 26, // Altura do switch
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 1,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 24, // Largura do botão
        height: 24, // Altura do botão
    },
    '& .MuiSwitch-track': {
        borderRadius: 13,
        backgroundColor: '#bdbdbd',
        opacity: 1,
    },
}));