import { useQuery } from '@tanstack/react-query';
import { useIdentity } from './identityContext';
import { IRefetchOnMountModeGetQuery, useApiRequest } from './apiRequestContext';
import { isAxiosError } from 'axios';
import { useTenant } from './tenantContext';
import { useState } from 'react';
import { getUserList, UserListProps } from 'services/user';
import { useUserPermissionData } from './userContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

export type OperatorsListType = { label: string; value: string, registrationNumber: string };
export function useOperatorList(filters: UserListProps, refetchOnMount?: IRefetchOnMountModeGetQuery, tenant?: string | undefined) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { hasPermission } = useUserPermissionData();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [operatorSearch, setOperatorSearch] = useState<string | undefined>(undefined);
    const filtersComplete = { ...filters, name: operatorSearch, tenant: tenant ?? currentTenantId };
    const [operatorList, setOperatorList] = useState<OperatorsListType[]>([]);
    const hasReadPermission = hasPermission('User', 'Read');

    const queryContextOperator = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['operator-list', filtersComplete, currentTenantId, filters.tenant],
        queryFn: async () => {
            startRequest();
            const resp = await getUserList(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const options = data ?? [];

            const operatorList = options.data.map((n) => {
                return {
                    label: `${n?.name} ${
                        n.registrationNumber !== 'N/D'
                            ? `- (${formatDocumentNumber(n.registrationNumber)})`
                            : ''
                    } ${n?.enabled ? '' : '(Usuário desativado)'} `,
                    value: n?.id,
                    registrationNumber: n?.registrationNumber,
                };
            });
            setOperatorList(operatorList);
            return data;
        },
    });

    const operatorsAutoCompleteProps = {
        listOptions: operatorList,
        loading: queryContextOperator.isLoading || queryContextOperator.isFetching,
        onSearch: (searchString: string | undefined) => setOperatorSearch(searchString)
    };

    return { ...queryContextOperator, operatorsAutoCompleteProps };
};
