import { Stack } from "@mui/material";
import { Button, OperatorIcon, Typography, UpdateIcon } from "@uy3/web-components";
import { iconSx } from "contexts/apiRequestContext";
import { useUserPermissionData } from "contexts/userContext";
import { activeTheme } from "services/theme";

type GroupHeaderType = {
    refetchGroups: () => void;
    setOpen: (isOpen: boolean) => void;
}

const theme = activeTheme();

export const GroupHeader = ({ refetchGroups, setOpen }: GroupHeaderType) => {
    const { hasPermission } = useUserPermissionData();
    return (
        <Stack direction="row" justifyContent="space-between" alignItems='center' mr={3} mt={3}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="25px"
                lineHeight="38.4px"
            >
                Grupos do usuário
            </Typography>
            <Stack direction='row' alignItems='center' gap={1}>
                <Button
                    variant="text"
                    disabled={false}
                    onClick={() => refetchGroups()}
                    size="medium"
                    sx={{ color: '#000' }}
                    startIcon={
                        <UpdateIcon
                            htmlColor={theme.palette.common.black}
                            sx={iconSx}
                        />
                    }
                >
                    Atualizar
                </Button>
                {hasPermission('UserGroup', 'Update')  && <Button
                    variant="text"
                    disabled={false}
                    onClick={() => setOpen(true)}
                    size="medium"
                    sx={{ color: '#000' }}
                    startIcon={
                        <OperatorIcon
                            htmlColor={theme.palette.common.black}
                            sx={iconSx}
                        />
                    }
                >
                    Novo grupo
                </Button>}
            </Stack>
        </Stack>
    );
};
