import type { FunctionComponent } from 'react';
import type { PopupProps } from './Popup.interface';

import CloseIcon from '@mui/icons-material/Close';
import { Stack } from '@mui/material';
import {
  StyledDialog,
  StyledDialogContent,
  StyledDialogTitle,
  StyledIconButton,
} from './Popup.styled';
import { Typography } from '../../atoms';

export const Popup: FunctionComponent<PopupProps> = ({
  open,
  onClose,
  children,
  title,
  text,
  caption,
}) => {
  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{ sx: { width: 750, maxWidth: '100%' } }}
    >
      <StyledDialogTitle>
        <StyledIconButton aria-label="Fechar popup" data-testid="close-button" onClick={onClose}>
          <CloseIcon />
        </StyledIconButton>
      </StyledDialogTitle>

      <StyledDialogContent>
        <Stack>
          {title && (
            <Typography variant="xl" color="neutral.darkest" lineHeight="24px" mb={2}>
              {title}
            </Typography>
          )}

          {text && (
            <Typography variant="xs" color="neutral.medium" lineHeight="19px">
              {text}
            </Typography>
          )}

          {caption && (
            <Typography variant="xs" color="neutral.medium" lineHeight="19px" mb={1.5}>
              {caption}
            </Typography>
          )}

          {children}
        </Stack>
      </StyledDialogContent>
    </StyledDialog>
  );
};
