import React, { ReactNode, useState } from 'react'
import { ChargesList } from './BillingList'
import { FilterProvider } from 'contexts/filterContext';
import { Drawer, Modal } from '@uy3/web-components';
import { ViewDetailsCharges } from '../BillingForm/Drawers/ViewCharges/ViewDetailsCharges';
import ChargesListHeader from './ChargesListHeader';
import { ErrorBox } from 'components/Errors/ErrorBox/ErrorBox';
import { ApiResponseError, ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { BillingFull, firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';
import Toast from 'components/Toast/Toast';
import NewChargesContainer from 'pages/WalletManagement/Dashboard/DashboardContainer/Drawer/NewCharge/NewChargesContainer';
import { useDownloadBankSlipByBarCodeMutation, useTransferBankSlipMutation, useUpdateOtherInformationBankSlipMutation } from 'contexts/wallet/bankSlip/bankSlip';
import { useLocation } from 'react-router';
import { Grid } from '@mui/material';
import { UploadFileInstructionContainer } from 'pages/WalletManagement/Wallets/WalletForm/Tabs/FileInstruction/Drawers/UploadFileInstruction/UploadFileInstructionContainer';
import { RefreshProgress } from 'components/RefreshProgress';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { useFilterValues } from 'contexts/filterValuesContext';
import { EditBillingContainer } from '../BillingForm/Drawers/EditBilling/EditBillingContainer';
import { GridCallbackDetails, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Error } from 'components/Errors/Error';
import { isEmpty } from 'lodash';
import { billingStatusOptions } from 'components/DataTableFilters/BillingFilters/BillingFilters';
import { useBillingListData, useDownloadBatchBillingMutation, useExportBillingsAsCnabMutation, useExportBillingsAsCsvMutation } from 'contexts/wallet/Billing/billingContext';
import { CancelFormContainer } from '../../BankSlip/BankSlipForm/Drawers/CancelForm/CancelFormContainer';
import { FormProvider } from 'contexts/formContext';
import { TransferBankSlipForm } from '../../BankSlip/BankSlipForm/Drawers/TransferBankSlipForm/TransferBankSlipForm';
import { defaultValuesTransferBankSlipFormSchemaSchema, validationTransferBankSlipFormSchemaSchema } from '../../BankSlip/BankSlipForm/Drawers/TransferBankSlipForm/TransferBankSlipFormSchema';
import { FieldValues } from 'react-hook-form';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { UpdateInstructionDebtCollection } from '../BillingForm/Drawers/UpdateInstructionDebtCollection/UpdateInstructionDebtCollection';
import { defaultValuesFirstInstructionDebtCollection, validationSchemaFirstInstructionDebtCollection } from '../BillingForm/Drawers/UpdateInstructionDebtCollection/UpdateInstructionDebtCollectionSchema';

type DrawerStepType = {
    title: string,
    children: ReactNode
}

export default function BillingListContainer() {
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const [secundaryAction, setSecundaryAction] = useState<string | undefined>(undefined)
    const [toast, setToast] = useState<ToastType>(toastState);
    const [rowsSelected, setRowsSelected] = useState<string[] | GridRowId[]>();
    const { filterValues } = useFilterValues();
    const hasFilters = Object.values(filterValues.filters).length > 0
    const { pathname } = useLocation();
    const isMenuCharges = pathname.endsWith('/cobrancas');
    const defaultRowsPerPage = parseInt(localStorage.getItem('rowsPerPage')!) || 5;
    const [rowsPerPage, setRowsPerPage] = useState(defaultRowsPerPage);
    const [page, setPage] = useState(0);
    const { handleErrorException } = useApiRequest();

    const [actionStep, setActionStep] = useState<{ step: string, data?: BillingFull } | undefined>(undefined);

    const {
        data: billingListData,
        refetch: billingRefetch,
        isFetching: billingIsFetching,
        isLoading: chargesIsLoading,
        error: billingError,
        status
    } = useBillingListData({ page, size: rowsPerPage });

    const handleSelectionModelChange = (selectionModel: GridSelectionModel, _: GridCallbackDetails<any>) => {
        setRowsSelected(selectionModel);
    };

    const handleSuccess = (action: string) => {
        const successMessage: { [action: string]: string } = {
            "download": "Download concluído com sucesso",
            "downloadBatch": "Download das cobranças realizada com sucesso",
            "exportBilling": "Sucesso ao exportar cobranças",
            "bankSlipCancel": "Baixa solicitada com sucesso",
            "transferWallet": "Sucesso ao transferir carteira",
            "updateFirstInstruction": "Sucesso ao atualizar instrução de protesto"
        };

        const messge = successMessage[action];
        setToast({ open: true, title: messge, description: "", severity: "success" });
        billingRefetch();
        setActionStep(undefined);
        setSecundaryAction(undefined);
    }

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast);
    const barCode = actionStep?.data?.bankSlip?.barCode;

    const { mutateAsync: exportBillingAsCSVAsync, isLoading: isLoadingExportAsCsvBilling } = useExportBillingsAsCsvMutation(() => handleSuccess('exportBilling'), onError);
    const { mutateAsync: exportBillingAsCnabAsync, isLoading: isLoadingExportAsCnabBilling } = useExportBillingsAsCnabMutation(() => handleSuccess('exportBilling'), onError);
    const { mutateAsync: downloadBatchBankSlip, isLoading: isLoadingDownloadBatch } = useDownloadBatchBillingMutation(() => handleSuccess('downloadBatch'), onError);
    const { downloadMutateAsync, isLoading: isLoadingDownload } = useDownloadBankSlipByBarCodeMutation(barCode!, () => handleSuccess("download"), onError)
    const { updateOtherInformationAsync: updateInstructionDebtCollectionAsync } = useUpdateOtherInformationBankSlipMutation(() => handleSuccess("updateFirstInstruction"), onError)
    const { transferSlipMutateAsync } = useTransferBankSlipMutation(() => handleSuccess("transferWallet"), onError)

    if (billingListData?.type === 'error') return <Error error={billingError} />

    const onClose = () => setActionStep(undefined);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const currentValuesRowSelected = actionStep?.data!;
    const rowDataByItemsSelected = billingListData?.data?.filter(item => rowsSelected?.includes(item?.id));
    const barCodesSelectedByRows = rowDataByItemsSelected?.map(x => x?.bankSlip?.barCode) ?? []

    const handleUpdateInstrictionDebtCollection = async (values: FieldValues) => {
        return barCodesSelectedByRows.forEach(async (barCode) => {
            return await updateInstructionDebtCollectionAsync({
                barCode,
                payload: {
                    firstInstructionDebtCollection: values?.firstInstructionDebtCollection,
                    secondInstructionDebtCollection: values?.secondInstructionDebtCollection
                }
            });
        });
    };

    const drawerOptions: { [type: string]: DrawerStepType } = {
        "viewDetails": {
            title: "Detalhes da cobrança",
            children: (
                <ViewDetailsCharges
                    onClose={onClose}
                    data={currentValuesRowSelected}
                    setToast={setToast}
                    isLoadingDownload={isLoadingDownload}
                    downloadPDFBankSlip={async (_, walletCode) => {
                        await downloadMutateAsync(walletCode?.toString())
                    }}
                />
            )
        },
        "registerBankSlip": {
            title: "Registrar boleto",
            children: (
                <NewChargesContainer
                    onCloseDrawer={onClose}
                    setToast={setToast}
                    rowData={currentValuesRowSelected as BillingFull}
                    onCloseContinueBy={onClose}
                    continueBy={2}
                    onSuccessWhenRegister={billingRefetch}
                />
            )
        },
        "editBilling": {
            title: "Editar cobrança",
            children: <EditBillingContainer
                formValues={actionStep?.data!}
                onClose={onClose}
                refetch={billingRefetch}
                setToast={setToast}
                allowEditTags
            />
        },
        "newCharges": {
            title: "Nova cobrança",
            children: (
                <NewChargesContainer
                    onCloseDrawer={onClose}
                    setToast={setToast}
                    onSuccessWhenRegister={billingRefetch}
                />
            )
        },
        "generateCnab": {
            title: "Importar arquivo CNAB",
            children: (
                <UploadFileInstructionContainer
                    onClose={onClose}
                    setToast={setToast}
                />
            )
        },
        "bankSlipCancel": {
            title: "Solicitar baixa do boleto",
            children: <CancelFormContainer
                onClose={onClose}
                barCode={barCodesSelectedByRows ?? []}
                handleError={onError}
                handleSuccess={() => {
                    handleSuccess('bankSlipCancel');
                    onClose();
                    setRowsSelected([]);
                }}
            />,
        },
        "ransferWallet": {
            title: "Transferir carteiras",
            children: (
                <FormProvider
                    defaultValues={defaultValuesTransferBankSlipFormSchemaSchema}
                    validationSchema={validationTransferBankSlipFormSchemaSchema()}
                    onSubmit={(values) => {
                        setFormValues(values);
                        setSecundaryAction('confirmTransfer')
                    }}>
                    <TransferBankSlipForm onClose={onClose} />
                </FormProvider>
            ),
        },
        "updateInstructionDebtCollection": {
            title: 'Atualizar instrução de protesto',
            children: (
                <FormProvider
                    defaultValues={defaultValuesFirstInstructionDebtCollection}
                    validationSchema={validationSchemaFirstInstructionDebtCollection()}
                    onSubmit={handleUpdateInstrictionDebtCollection}
                >
                    <UpdateInstructionDebtCollection onClose={onClose} />
                </FormProvider>
            )
        }
    }

    const transferWalletCodeInLote = () => {
        return (barCodesSelectedByRows ?? [])?.forEach(async (barCode) => {
            return await transferSlipMutateAsync({
                barCode: barCode,
                toWalletCode: formValues?.walletCode
            })
        })
    }

    const currentStep = actionStep?.step;
    const drawerSelectedByStep = drawerOptions[currentStep!];

    const customOnCloseByCurrentStep = () => {
        if (currentStep !== 'newCharges' && currentStep !== 'registerBankSlip')
            return onClose();
    };

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={2}>
                <ChargesListHeader
                    isFetching={billingIsFetching}
                    refetch={billingRefetch}
                    isMenuCharges={isMenuCharges}
                    handleAction={(action) => setActionStep({ step: action })}
                    rowDataByItemsSelected={rowDataByItemsSelected ?? []}
                    handleDownloadBatch={() => downloadBatchBankSlip(rowsSelected as string[])}
                    exportBillingAsCSVAsync={exportBillingAsCSVAsync}
                    exportBillingAsCnabAsync={exportBillingAsCnabAsync}
                    enableExportBilling={!isEmpty(billingListData?.data ?? [])}
                />
            </Grid>
            <RefreshProgress refreshing={(!chargesIsLoading && billingIsFetching) || isLoadingDownloadBatch || isLoadingExportAsCsvBilling || isLoadingExportAsCnabBilling} />
            <ErrorBox
                resource='Lista de cobranças'
                error={billingError as ApiResponseError}
                status={status}
                refreshByResource={billingRefetch}
            >
                <FilterProvider
                    availableFilters={{
                        searchString: { label: 'Todos', type: 'text' },
                        walletsCode: { label: 'Código convênio', type: 'text', multiple: true },
                        documentNumber: { label: 'Nº do documento', type: 'text' },
                        amount: { label: 'Valor de cobrança', type: 'text' },
                        barCode: { label: 'Código de barras', type: 'text' },
                        payerName: { label: 'Nome do pagador', type: 'text' },
                        payerRegistrationNumber: { label: 'CPF/CNPJ do pagador', type: 'text' },
                        statusesValue: {
                            label: 'Status',
                            type: 'select',
                            multiple: true,
                            options: billingStatusOptions
                        },
                        initialDueDate: {
                            label: 'Data de vencimento',
                            type: 'dateRange',
                            multiple: false,
                            dateRangeName: 'finalDueDate',
                        },
                        initialSettlementDate: {
                            label: 'Data do crádito',
                            type: 'dateRange',
                            multiple: false,
                            dateRangeName: 'finalSettlementDate',
                        },
                        initialCreatedDate: {
                            label: 'Data de criação',
                            type: 'dateRange',
                            multiple: false,
                            dateRangeName: 'finalCreatedDate',
                        },
                        firstInstructionDebtCollection: {
                            label: "Instrução de protesto",
                            type: 'select',
                            options: firstInstructionDebtCollectionOptions,
                            multiple: false
                        },
                        installmentId: {
                            label: 'Parcelamento',
                            type: 'text',
                            multiple: false
                        }
                    }}
                >
                    <ListSkeleton {...{ isLoading: chargesIsLoading && !hasFilters }}>
                        <ChargesList {...{
                            page,
                            queryData: billingListData,
                            rowsPerPage,
                            setRowsPerPage: onChangeRowsPerPage,
                            setPage: onChangePage,
                            setActionStep,
                            handleSelectionModelChange,
                            rowsSelected: rowsSelected ?? []
                        }} />

                    </ListSkeleton>

                </FilterProvider>
            </ErrorBox>

            <Drawer
                anchor='right'
                title={drawerSelectedByStep?.title}
                open={!!currentStep!}
                onClose={customOnCloseByCurrentStep}
                children={drawerSelectedByStep?.children}
            />

            <Modal
                title={`Transferir para ${formValues?.walletCodeDisplay ?? ""}`}
                description={`Você realmente deseja transferir a carteira?`}
                open={secundaryAction === 'confirmTransfer'}
                align='left'
            >
                <GenericActionHandler
                    onClose={() => setSecundaryAction('transfer')}
                    handleSubmit={transferWalletCodeInLote}
                    titleCancel='Não, cancelar'
                    titleConfirm='Sim, transferir'
                    isModal
                />
            </Modal>
        </React.Fragment>
    )
}
