import { FormProvider } from "contexts/formContext";
import { ConfirmEmailFormDefaultValues, ConfirmEmailFormValidationSchema } from "./ConfirmEmailFormSchema";
import { ConfirmEmailForm } from "./ConfirmEmailForm";
import { useEffect } from "react";
import { Alert } from "@uy3/web-components";
import { useIdentity } from "contexts/identityContext";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

export function ConfirmEmailFormContainer() {

	const { confirmEmailCode, sendEmailConfirmationCode, authFlow, navigateToFlow, submitError } = useIdentity();
	const navigate = useNavigate();

	const schema = ConfirmEmailFormValidationSchema();
	const defaultValues = ConfirmEmailFormDefaultValues;

	const submit = async (values: any) => {
		await confirmEmailCode(values.code);
	};
	const error = (values: any) => console.log("ConfirmEmailFormContainer - onError", values);

	useEffect(() => {
		console.log("ConfirmEmailFormContainer - authFlow atualizado: " + authFlow?.type);
		navigateToFlow(navigate);
	}, [authFlow, navigate, navigateToFlow]);

	return <>
		{submitError && <Box mb={1}>
			<Alert
				severity="error"
				text={submitError}
				variant="filled"
			/>
		</Box>}
		<FormProvider
			validationSchema={schema}
			defaultValues={defaultValues}
			onSubmit={submit}
			onError={error}>
			<ConfirmEmailForm
				sendCode={sendEmailConfirmationCode} />
		</FormProvider>
	</>
}