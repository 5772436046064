import { currentDateWithSetHours, genericMessageRequired, toIsoStringWithTimezone } from 'helpers';
import { string, object, boolean, date } from 'yup';

export function validationSchemaClosure() {
    return object().shape({
        cosifAccountCode: string()
            .required(`Código COSIF: ${genericMessageRequired}`)
            .typeError(`Selecione uma Conta de débito.`),
        closeTransitoryBankAccount: boolean().nullable().optional(),
        paymentDate: date()
            .typeError('Data de pagamento é inválida.')
            .required(`Data de pagamento: ${genericMessageRequired}`),
    });
}

export const defaultValuesClosure = {
    cosifAccountCode: null,
    closeTransitoryBankAccount: false,
    paymentDate: toIsoStringWithTimezone(new Date(currentDateWithSetHours)),
};
