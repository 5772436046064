import { string, object, boolean } from "yup"

export const validationSchemaDisapproveMarginReverse = () => {
  return object().shape({
    message: string().typeError("Mensagem: Precisa ser preenchido").required("Mensagem: Precisa ser preenchido"),
    valueMarginReserve: string().typeError("Valor de margem: Precisa ser preenchido").required("Valor de margem: Precisa ser preenchido"),
    includePaymentFixedCosts: boolean()
  })
}

export const defaultvaluesDisapproveMarginReverse = (
  includePaymentFixedCostsValue?: boolean,
  valueMarginReserveValue?: number
) => {
  return {
    message: null,
    valueMarginReserve: valueMarginReserveValue ?? null,
    includePaymentFixedCosts: includePaymentFixedCostsValue ?? false
  }
}