import { string, object } from 'yup';

export function validationSchemaAccountOperators() {
    return object().shape({
        tenant: string().typeError('Correspondente: Precisa ser preenchido').required('Correspondente: Precisa ser preenchido'), 
        userId: string().typeError('Operador precisa ser preenchido.').required('Operador: precisa ser preenchido.'),
        level: string().typeError('Nível de permissão precisa ser preenchido.').required('Nível de permissão precisa ser preenchido.'),
    });
};

export const defaultValuesAccountOperators = {
    userId: null,
    level: null,
    tenant: null
};
