/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useFormContext } from 'contexts/formContext';
import { ApproveLiquidationFormForm } from './ApproveLiquidationForm';
import {
    defaultValuesApproveLiquidation,
    validationApproveLiquidationSchema,
} from './ApproveLiquidationSchema';
import { useEffect, useState } from 'react';
import { CreditNoteInformationFormContextProvider } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { useCosifData, useCreditProductData } from 'contexts/creditProductContext';
import { useRelatedBankAccountData } from 'contexts/relatedBankAccountContext';
import { FieldValues } from 'react-hook-form';
import { ApproveCreditNoteByIdProps } from 'services/creditNote/types/creditNoteReadModel';

type ApproveLiquidationContainerProps = {
    onClose: () => void;
    handleApproveLiquidation: (payload: ApproveCreditNoteByIdProps) => void;
    creditData: any;
    formType: 'batchAssignment' | 'creditNote';
};

export const ApproveLiquidationContainer = ({
    onClose,
    handleApproveLiquidation,
    creditData,
    formType = 'creditNote'
}: ApproveLiquidationContainerProps) => {
    const { watch } = useFormContext();
    const [productId, setProductId] = useState<string | undefined>(undefined);
    const { creditProductData } = useCreditProductData(productId);
    const { cosifData } = useCosifData();
    const [beneficiaryId, beneficiaryDiscriminator] = watch([
        'beneficiaryId',
        'beneficiaryDiscriminator'
    ]);

    const { data: optionsPersonBankList } = useRelatedBankAccountData(
        beneficiaryId,
        beneficiaryDiscriminator
    );

    useEffect(() => {
        if (creditData && creditData.productId) {
            setProductId(creditData.productId);
        }
    }, [creditData]);

    const onSubmit = (values: FieldValues) => {
        handleApproveLiquidation({
            CosifAccountCode: values?.cosifAccountCode,
            IsAutomaticBankTransfer: values?.isAutomaticBankTransfer,
            creditAccountId: values?.creditAccountId
        });
    };

    const cosifDataOptions = cosifData?.map((item: any) => {
        return { label: item?.description, value: item?.code };
    });

    const schema = validationApproveLiquidationSchema();
    const defaultValues = defaultValuesApproveLiquidation;

    const bankAccountId = creditData?.bankAccountId;

    return (
        <FormProvider defaultValues={defaultValues} validationSchema={schema} onSubmit={onSubmit}>
            <CreditNoteInformationFormContextProvider product={creditProductData}>
                <ApproveLiquidationFormForm
                    creditAccountId={bankAccountId}
                    formType={formType}
                    cosifDataOptions={cosifDataOptions}
                    onClose={onClose}
                    optionsPersonBankList={optionsPersonBankList?.relatedBankAccountData}
                />
            </CreditNoteInformationFormContextProvider>
        </FormProvider>
    );
};
