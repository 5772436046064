import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { SelectOption } from 'components/Forms/FormFields';
import { createContext, useContext, useState } from 'react';
import { getTenantList } from 'services/tenant/tenant';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useAppConfig } from './appConfig';

type TenantSelectedProps = {
    currentTenantId: string | undefined;
    selectedTenant: SelectOption | undefined;
    setSelectedTenant: Function;
    tenantAutoCompleteProps: AutocompleteOptionList;
    queryContext: UseQueryResult<unknown, unknown>;
    isLoading: boolean;
    isParentTenant: boolean;
    isRootTenancy: boolean;
    showTenantFilter: boolean;
};

const TenantContext = createContext<TenantSelectedProps>({
    currentTenantId: undefined,
    selectedTenant: { label: '', value: '' },
    setSelectedTenant: () => { },
    tenantAutoCompleteProps: { listOptions: [], loading: false },
    queryContext: {} as UseQueryResult<unknown, unknown>,
    isLoading: false,
    isParentTenant: false,
    isRootTenancy: false,
    showTenantFilter: false
});

interface TenantProviderProps {
    children: JSX.Element;
}

const TENANT_TYPE_ROOT = 0;
const TENANT_FILTER = 1;
const isRootTenancyType = (tenantType: number): boolean => tenantType === TENANT_TYPE_ROOT;
const tenantFilter = (tenantFilter: number): boolean => tenantFilter === TENANT_FILTER;

export const TenantProvider = ({ children }: TenantProviderProps) => {
    const [selectedTenant, setSelectedTenant] = useState<SelectOption>();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [tenantList, setTenantList] = useState<SelectOption[]>([]);
    const filtersComplete = { page: 0, size: 10 };
    const { appConfig } = useAppConfig();
    const isRootTenancy = isRootTenancyType(appConfig.TENANT_TYPE);
    const showTenantFilter = tenantFilter(appConfig.USE_TENANT_FILTER);

    const queryContext: UseQueryResult<SelectOption[]> = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['tenant-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getTenantList(filtersComplete, token!);
                endRequest(true);

                const dataSuccess = data ?? [];
                let tenantDataList = dataSuccess.map((item) => ({
                    label: item?.name,
                    value: item?.id,
                }));

                setTenantList(tenantDataList);
                return tenantDataList;
            } catch (err) {
                endRequest(true);
                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    const { isLoading, isFetching, data } = queryContext;
    const tenantAutoCompleteProps = {
        listOptions: tenantList,
        loading: isLoading || isFetching,
    };

    return (
        <TenantContext.Provider
            value={{
                currentTenantId: selectedTenant?.value?.toString() ?? undefined,
                selectedTenant,
                setSelectedTenant,
                tenantAutoCompleteProps: tenantAutoCompleteProps,
                queryContext,
                isLoading: isLoading,
                isParentTenant: data && data?.length > 1 ? true : false,
                isRootTenancy,
                showTenantFilter
            }}
        >
            {children}
        </TenantContext.Provider>
    );
};

export const useTenant = () => useContext(TenantContext);
