export const MuiCssBaseline = {
  styleOverrides: () => ({
    '@font-face': {
      fontFamily: '"Lato", sans-serif',
    },
    ':root': {
      fontSize: '10px',
      height: '100%'      
    },
  }),
};
