import { string, object, boolean, date } from 'yup';

export function validationSchemaManualUpdateForm() {
    return object().shape({
        newStatus: string().typeError('Novo Status é inválido.').required('Selecione um novo status.'),
        timelineAction: string().typeError('Linha do tempo inválida.'),
        newTimelineType: string().when('timelineAction', {
            is: (value: string) => value === 'EndAndCreateNew',
            then: string().typeError('Etapa da linha do tempo inválida.').required('Selecione uma etapa da linha do tempo.'),
            otherwise: string().nullable().notRequired()
        }),
        newTimelineDescription: string().when('timelineAction', {
            is: (value: string) => value === 'EndAndCreateNew',
            then: string().typeError('Descrição da linha do tempo inválida.').required('Descrição da linha do tempo: Precisa ser preenchido.'),
            otherwise: string().nullable().notRequired()
        }),
        workflowAction: string().typeError('Esteira inválida.'),
        acceptanceDate: date().typeError('Data inválida.').nullable(),
        sendWebCred: boolean()
    })
};

export const defaultValuesManualUpdateForm = {
    newStatus: "",
    timelineAction: "None",
    newTimelineType: undefined,
    newTimelineDescription: undefined,
    workflowAction: "None",
    sendWebCred: false, 
    acceptanceDate: new Date()
};