import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid/';
import { FundFiltersContainer } from 'components/DataTableFilters/FundFilters/FundFiltersContainer';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';

interface FundListType {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setOpenPopup: (rowData: any) => void;
    hasPermission: (resource: string | string[], type: any) => boolean
}

const FundList = ({ queryData, rowsPerPage, setRowsPerPage, setPage, page, setOpenPopup, hasPermission }: FundListType) => {
    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columns: GridColDef[] = [
        {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'registrationNumber',
            headerName: 'CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => formatDocumentNumber(cellValues.value),
        },
        {
            field: 'email',
            headerName: 'Email',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'hasCollectAssignmentSignatures',
            headerName: 'Fluxo cessão',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return cellValues?.row?.collectAssignmentSignatures === true ? 'Sim' : 'Não'
            },
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) =>
                cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const id = cellValues?.id;
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={`/cadastro/fundos/${id}`}>
                            <IconButton name='btn-edit'>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {hasPermission('Fund', 'Delete') &&
                            <IconButton name='btn-delete' onClick={() => setOpenPopup(cellValues)}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            }
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <FundFiltersContainer />,
            }}
            columns={columns}
            rowsPerPage={rowsPerPage}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default FundList;
