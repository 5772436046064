import { Grid } from '@mui/material';
import { Button } from '@uy3/web-components';
import {
    CheckboxFormField,
    DatePickerFormField,
    SelectFormField,
    TextFormField,
} from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import {
    manualUpdateOptions,
    optionsNewStatus,
    optionsNewTimeline,
} from 'contexts/creditNote/creditNoteOptions';
import {
    optionsBankAccNewTimeLine,
    optionsBankAccNewStatus,
} from 'contexts/bankAccount/bankAccountOptions';

type ManualUodateFormProps = {
    onClose: () => void;
    recordType: 'bankAccount' | 'creditNote';
};

const ManualUpdateForm = ({ onClose, recordType }: ManualUodateFormProps) => {
    const { watch } = useFormContext();
    const opt = recordType === 'bankAccount' ? optionsBankAccNewTimeLine : optionsNewTimeline;
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <Grid item xs={12} mt={4}>
                    <SelectFormField
                        name="newStatus"
                        label="Novo Status"
                        options={
                            recordType === 'bankAccount'
                                ? optionsBankAccNewStatus
                                : optionsNewStatus
                        }
                        required
                    />
                </Grid>
                <Grid item xs={12} mt={3}>
                    <SelectFormField
                        name="timelineAction"
                        label="Atualizar linha do tempo"
                        options={manualUpdateOptions}
                    />
                </Grid>
                {watch('timelineAction') === 'EndAndCreateNew' && (
                    <>
                        <Grid item xs={12} mt={3}>
                            <SelectFormField
                                name="newTimelineType"
                                label="Selecione a etapa da linha do tempo"
                                defaultValue="Finished"
                                options={opt}
                                required={
                                    watch('timelineAction') === 'EndAndCreateNew' ? true : false
                                }
                            />
                        </Grid>
                        <Grid item xs={12} mt={2}>
                            <TextFormField
                                name="newTimelineDescription"
                                fullWidth
                                label="Descrição da linha do tempo"
                                margin="dense"
                                multiline
                                rows={3}
                                required={
                                    watch('timelineAction') === 'EndAndCreateNew' ? true : false
                                }
                                variant="outlined"
                            />
                        </Grid>
                    </>
                )}
                <Grid item xs={12} mt={3}>
                    <SelectFormField
                        name="workflowAction"
                        label="Encerrar esteira"
                        options={manualUpdateOptions}
                    />
                </Grid>
                {watch('newStatus') === 'Finished' && (
                    <Grid item xs={12} mt={3}>
                        <DatePickerFormField
                            name="acceptanceDate"
                            label="Data de liquidação"
                            fullWidth
                        />
                    </Grid>
                )}
                {recordType === 'creditNote' && (
                    <Grid item xs={12} mt={3}>
                        <CheckboxFormField
                            color="primary"
                            label="Integrar operação com WebCred"
                            name="sendWebCred"
                            disabled={false}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </>
    );
};

export default ManualUpdateForm;
