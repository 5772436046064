import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, isAxiosError } from 'axios';
import {
    ApiResponseError,
    BankAccountOperationType,
    GetListApiResponseSuccess,
    GetViewApiResponseSuccess,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { createBeneficiary } from 'services/accounts/bankAccount/bankAccount';
import { CreateBeneficiaryType } from './bankAccountType';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { useState } from 'react';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';

const apiPath = (id: string) => `/BankAccount/${id}/Beneficiary`;

type BeneficiaryType = {
    id: string,
    operationTypeValue: BankAccountOperationType,
    onlyApproveds?: boolean
}

export function useBankAccontBeneficiaries({
    id,
    operationTypeValue,
    onlyApproveds = false
}: BeneficiaryType ) {
    const [search, setSearch] = useState<string | undefined>(undefined);
    const [bankAccountList, setBankAccountList] = useState<{ label: string; value: string }[]>([]);
    const { token } = useIdentity();

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        queryKey: ['beneficiaries-bank-account-list', id, operationTypeValue, search],
        queryFn: async () => {
            let url = `${apiPath(id)}?f_operationTypeValue=${operationTypeValue}`;
            if (onlyApproveds) {
                url = `${url}&f_status=Approved`
            }
            url = search ? `${url}&searchString=${search}` : url
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
            const { data } = await creditAxiosApi.get(url, config);

            const options = data as GetListApiResponseSuccess<any>;
            const bankAccountOptions = options.data.map((beneficiary: any) => {
                if (operationTypeValue === 'Pix') {
                    if (beneficiary?.pixKeyTypeValue === 6)
                        return {
                            label: `${beneficiary.name} - ${beneficiary.pixKeyTypeValueDisplay} | Ag: ${beneficiary.agency} - Conta: ${beneficiary.accountNumber}`,
                            value: beneficiary.id,
                        };
                    else
                        return {
                            label: `${beneficiary.name} - ${beneficiary.pixKeyTypeValueDisplay === 'Telefone'
                                    ? beneficiary.keyPix.includes('+55')
                                        ? formatPhoneNumber(beneficiary.keyPix.replace('+55', ''))
                                        : formatPhoneNumber(beneficiary.keyPix)
                                    : beneficiary.keyPix
                                }`,
                            value: beneficiary.id,
                        };
                } else {
                    return { label: `${beneficiary?.name} - ${beneficiary?.bankDisplay} - ${beneficiary?.agency}/${beneficiary?.accountNumber}`, value: beneficiary?.id }
                }
            });
            setBankAccountList(bankAccountOptions);

            return data as any;
        },
    });
    const bankAccountAutocomplete = {
        listOptions: bankAccountList,
        loading: isLoading || isFetching,
        onSearch: (searchString: string | undefined) => {
            setSearch(searchString);
        },
    };
    return { bankAccountAutocomplete, status, data, error, isFetching, refetch, isLoading };
}

export function useCreateBeneficiaryMutation(
    fieldValues: CreateBeneficiaryType,
    bankAccountId: string,
    onSuccess?: (data: CreateBeneficiaryType) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();

    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: any /*added type*/): Promise<CreateBeneficiaryType> => {
            startRequest();
            const { data, status, statusText } = await createBeneficiary(
                bankAccountId!,
                values,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<CreateBeneficiaryType>;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateBeneficiary: mutate, isLoading };
}
