import { createContext } from 'react';
import { BankAccountActionsProps } from './bankAccountActions.interface';

export const BankAccountsActionsContext = createContext<BankAccountActionsProps>({
    isNew: true,
    onSubmitApproval: () => {},
    onDelete: () => {},
    deleteCheckList: () => {},
    onApprove: () => {},
    refetch: () => {},
    onDisapprove: () => {},
    onUploadDocs: () => {},
    onSendApprovalRevision: () => {},
    openBankAccountRequest: () => {},
    onApproveInstrument: () => {},
    onRejectInstrument: () => {},
    onApproveSignature: () => {},
    onDisapproveSignature: () => {},
    onDraftpreview: () => {},
    cancelBankAccountRequest: () => {},
    onManualUpdate: () => {},
});
