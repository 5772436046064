import { AttributeType } from "services/creditNote/complianceChecklist";
import { IBankAccountReadModel } from "utils/interface";

export const optionsNewTimeline = [
    {
        label: 'Aprovação de Crédito',
        value: 'CreditApprove'
    },
    {
        label: 'Aprovação de Compliance',
        value: 'ComplianceApprove'
    },
    {
        label: 'Aprovação de Instrumento',
        value: 'InstrumentApprove'
    },
    {
        label: 'Validação de Assinaturas',
        value: 'SignaturesValidation'
    },
    {
        label: 'Coleta de assinaturas',
        value: 'Signatures'
    },
    {
        label: 'Encerrado',
        value: 'Finished'
    },
    {
        label: 'Expirada',
        value: 'Expired'
    },
    {
        label: 'Cancelada',
        value: 'Cancel'
    },
    {
        // Using "pago" temporarily
        label: "Pago",
        value: 'Liquidation'
    },
    {
        label: 'Revisão de Pagamento',
        value: 'PaymentRevision'
    },
    {
        label: 'Rascunho',
        value: 'Draft'
    },
    {
        label: 'Reprovado',
        value: 'Disapproved',
    },
    {
        label: 'Revisão',
        value: 'Revision'
    },
    {
        label: 'Garantia',
        value: 'Warranty'
    },
    {
        label: 'Garantia Manual',
        value: 'ManualWarranty'
    },
    {
        label: 'Reserva de Margem',
        value: 'MarginReserveApproval'
    }
];

export const optionsNewStatus = [
    {
        label: 'Aprovação de Crédito',
        value: 'CreditApproval'
    },
    {
        label: 'Aprovação de Compliance',
        value: 'ComplianceApproval'
    },
    {
        label: 'Aprovação de Instrumento',
        value: 'InstrumentApproval'
    },
    {
        label: 'Aguardando Liquidação',
        value: 'WaitLiquidation'
    },
    {
        label: 'Coleta de assinaturas',
        value: 'Signatures'
    },
    {
        label: 'Cancelada',
        value: 'Canceled'
    },
    {
        label: 'Encerrado',
        value: 'Finished'
    },
    {
        label: 'Garantia',
        value: 'Warranty'
    },
    {
        label: 'Garantia Manual',
        value: 'ManualWarranty'
    },
    {
        // Using "pago" temporarily
        label: "Pago",
        value: 'Liquidation'
    },
    {
        label: 'Liquidação manual',
        value: 'ManualLiquidation'
    },
    {
        label: 'Revisão de Pagamento',
        value: 'PaymentRevision'
    },
    {
        label: 'Rascunho',
        value: 'Draft'
    },
    {
        label: 'Reprovado',
        value: 'Disapproved',
    },
    {
        label: 'Revisão',
        value: 'Revision'
    },
    {
        label: 'Validação de Assinaturas',
        value: 'SignaturesValidation'
    },
    {
        label: 'Reserva de Margem',
        value: 'MarginReserveApproval'
    }
]

export const manualUpdateOptions = [
    { label: 'Não', value: 'None' },
    { label: 'Encerrar etapa atual', value: 'EndCurrent' },
    { label: 'Encerrar etapa atual e iniciar nova', value: 'EndAndCreateNew' }
];

export const creditNoteSelectFilterOptions = [
    { label: 'Aprovação de Crédito', value: 'CreditApproval' },
    { label: 'Aprovação de Compliance', value: 'ComplianceApproval' },
    { label: 'Aprovação de Instrumento', value: 'InstrumentApproval' },
    { label: 'Aguardando Liquidação', value: 'WaitLiquidation' },
    { label: 'Cancelada', value: 'Canceled' },
    { label: 'Coleta de Assinaturas', value: 'Signatures' },
    { label: 'Encerrado', value: 'Finished' },
    { label: 'Erro', value: 'Error' },
    { label: 'Garantia', value: 'Warranty' },
    { label: 'Garantia Manual', value: 'ManualWarranty' },
    // Using "pago" temporarily
    { label: "Pago", value: 'Liquidation' },
    { label: 'Liquidação manual', value: 'ManualLiquidation' },
    { label: 'Rascunho', value: 'Draft' },
    { label: 'Reprovado', value: 'Disapproved' },
    { label: 'Revisão', value: 'Revision' },
    { label: 'Revisão de Pagamento', value: 'PaymentRevision' },
    { label: 'Validação de Assinaturas', value: 'SignaturesValidation' },
    { label: 'Reserva de Margem', value: 'MarginReserveApproval' }
];

export const batchAssignmentSelectFilterOptions = [
    { label: 'Concluída', value: 'Finished' },
    { label: 'Cancelada', value: 'Cancelled' },
    { label: 'Liquidação', value: 'Liquidation' },
    { label: 'Expirada', value: 'Timeout' },
    { label: 'Coleta de assinaturas', value: 'Signatures' },
    { label: 'Em processamento', value: 'Processing' },
];

export const blackListOptions = <T extends { [key: string]: any }>(data: T | undefined) => {
    if(!data) return [];
    
    const { registrationNumber, email, phone, documentNumber, address, bankAccounts } = data ?? {};
    const bankAccountsList = (bankAccounts as IBankAccountReadModel[]) ?? [];

    const isValueValid = (value: string | undefined): boolean => {
        return value !== '' && value !== undefined && value !== null;
    };

    let checkList: { label: string; value: string }[] = [];

    const label = registrationNumber?.length === 11 ? 'CPF' : 'CNPJ';

    const hasTransfer = bankAccountsList?.some((i) => i?.operationTypeValue === 'Transfer');
    if (hasTransfer) {
        checkList.push({ label: 'ISPB', value: 'ispb' });
    }

    if (isValueValid(registrationNumber ?? "")) {
        checkList.push({ label, value: "registrationnumber" })
    }

    if (isValueValid(documentNumber) && label === 'CPF') {
        checkList.push({ label: 'RG', value: 'documentnumber' });
    }

    if (isValueValid(email)) {
        checkList.push({ label: 'Email', value: 'email' });
    }

    if (isValueValid(phone)) {
        checkList.push({ label: 'Telefone', value: 'phone' });
    }

    if (address && isValueValid(address?.zipCode)) {
        checkList.push({ label: 'CEP', value: 'zipcode' });
    }

    return checkList;
};

export const getAttributeValue = <T extends { [key: string]: any }>(
    attributeType: AttributeType,
    data: T,
    ispb: number
): string | number => {

    const {
        registrationNumber,
        email,
        phone,
        documentNumber,
        address: { zipCode },
    } = data ?? {};

    const optionsMapping: { [type: string]: string | number } = {
        zipcode: zipCode,
        email: email,
        phone: phone,
        documentnumber: documentNumber,
        registrationnumber: registrationNumber ?? '',
        ispb: ispb,
    };
    
    return optionsMapping[attributeType];
};

export const reasonDisapprovalSigaturiesList = [
    { label: "Documento inválido | Apresentar outro documento", value: "DOCUMENTO_INVÁLIDO_APRESENTAR_DOCUMENTO" },
    { label: "CPF irregular | divergente", value: "CPF_IRREGULAR_DIVERGENTE" },
    { label: "Refazer selfie", value: "REFAZER_SELFE" },
    { label: "Outros motivos", value: "OUTROS_MOTIVOS" }
]