import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const AppsIcon: FunctionComponent<SvgIconProps> = (props) => {
    const { htmlColor = '#373737' } = props;

    return (
        <SvgIcon {...props}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M2 4C2 2.89543 2.89543 2 4 2H8C9.10457 2 10 2.89543 10 4V8C10 9.10457 9.10457 10 8 10H4C2.89543 10 2 9.10457 2 8V4Z" stroke={htmlColor} strokeWidth="2" />
                <path d="M22 6C22 8.20914 20.2091 10 18 10C15.7909 10 14 8.20914 14 6C14 3.79086 15.7909 2 18 2C20.2091 2 22 3.79086 22 6Z" stroke={htmlColor} strokeWidth="2" />
                <path d="M10 18C10 20.2091 8.20914 22 6 22C3.79086 22 2 20.2091 2 18C2 15.7909 3.79086 14 6 14C8.20914 14 10 15.7909 10 18Z" stroke={htmlColor} strokeWidth="2" />
                <path d="M14 16C14 14.8954 14.8954 14 16 14H20C21.1046 14 22 14.8954 22 16V20C22 21.1046 21.1046 22 20 22H16C14.8954 22 14 21.1046 14 20V16Z" stroke={htmlColor} strokeWidth="2" />
            </svg>
        </SvgIcon>
    );
};

AppsIcon.defaultProps = {
    viewBox: '0 0 24 24',
    width: '24',
    height: '24',
    fill: 'none',
};
