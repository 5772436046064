import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';

type FeedbackMessageProps = {
  listOfSuggestions: string[];
  title?: string
}

export function FeedbackMessage({
  title,
  listOfSuggestions
}: FeedbackMessageProps) {
  return (
    <Stack
      sx={{
        borderRadius: '8px',
        backgroundColor: '#babfd029',
        padding: "16px"
      }}>
      <Typography fontSize='20px' fontWeight='600' lineHeight='120%' letterSpacing='-0.2px'>
        {title}
      </Typography>
      <Typography variant='sm'>Vamos tentar algo diferente</Typography>
      <ol type='1' style={{padding: 0, margin: '10px 20px'}}>
        {listOfSuggestions.map((message) => {
          return <li style={{fontSize: '15px', lineHeight: 2}}>{message}</li>
        })}
      </ol>
    </Stack>
  )
}