import { TliquidationScheduleOpt } from 'services/LiquidationSchedule';
import { array, object, string } from 'yup';

const genericRequired = 'Precisa ser preenchido.';

const cosifAccountValidation = (isRequired: boolean) =>
    isRequired
        ? string()
            .required(`Código COSIF: ${genericRequired}`)
            .typeError(`Selecione uma Conta de débito.`)
        : string().nullable();

export const validationApproveLiquidationScheduleSchema = (
    hasTransitoryAccount: boolean,
    hasUnlinkedTransfer: boolean,
    hasExternalId: boolean, 
    hasPrincipalExternalId: boolean
) =>
    object().shape({
        cosifAccountCode: cosifAccountValidation(!hasUnlinkedTransfer),
        transitoryBankAccountAction:
            hasTransitoryAccount && !hasUnlinkedTransfer
                ? string()
                    .oneOf(['ManualLiquidation', 'AutomaticLiquidation'], 'Ação inválida')
                    .required(`Tipo de liquidação: ${genericRequired}`)
                    .typeError(`Selecione um Tipo de liquidação`)
                : string().nullable(),
        liquidationSchedule: !hasTransitoryAccount && !hasPrincipalExternalId ?
            array()
                .of(
                    object().shape({
                        liquidationScheduleId: string()
                            .uuid(`Roteiro de liquidação é inválido.`)
                            .typeError(`O campo 'Roteiro de liquidação' é inválido.`)
                            .required(`Roteiro de liquidação: ${genericRequired}`),
                        cosifAccountCode: cosifAccountValidation(!hasExternalId && !hasTransitoryAccount),
                        action: string()
                            .oneOf(
                                ['ManualLiquidation', 'Failed', 'NoAction', 'AutomaticLiquidation'],
                                'Ação inválida'
                            )
                            .required(`Ação: ${genericRequired}`)
                            .typeError(`O campo 'Ação' é inválido.`),
                        failureMessage: string().when('action', {
                            is: 'Disapprove',
                            then: string()
                                .required(`Observações: ${genericRequired}`)
                                .typeError(`O campo 'Observações' é inválido.`),
                            otherwise: string().nullable(),
                        }),
                    })
                )
                .required(`Roteiro de liquidação: ${genericRequired}`)
                .typeError(`Roteiro de liquidação é inválido.`)
            : array().nullable(),
    });

export const defaultValuesApproveLiquidationScheduleSchema = {
    cosifAccountCode: null,
    transitoryBankAccountAction: null,
    liquidationSchedule: [],
};

export const getLiquidationScheduleInfo = (scheduleList: TliquidationScheduleOpt[], id: string) => {
    const schedule = scheduleList.find((f) => f.value === id);
    return {
        description: schedule?.description ?? '',
        label: schedule?.label ?? '',
    };
};
