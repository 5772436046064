import { Stack } from "@mui/system";
import { Button, Typography } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields/TextFormField/TextFormField";
import { useFormContext } from "contexts/formContext";

export function MfaForm() {

	const { submitting } = useFormContext();

	return <>
		<Typography
			variant="h5"
			color="neutral.dark"
			lineHeight="34px"
			mb={2}
			fontWeight={400}
		>
			Confirmação de código MFA
		</Typography>
		<Stack direction="column" spacing={2} mb={2}>
			<TextFormField id="code" name="code" label="Código de verificação" variant="outlined" fullWidth required />			
			<Button
				type="submit"
                variant="contained"
				size="medium"
				disabled={submitting}
                fullWidth={true}
                sx={{ width: '100%'}}
              >
                Acessar
              </Button>
		</Stack>
		<Typography
			variant="subtitle1"
		>
			Caso tenha perdido seu dispositivo, entre em contato com o administrador para definir um novo código de autenticação.
		</Typography>
	</>
}