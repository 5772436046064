import { Grid, Stack } from '@mui/material';
import { Button, Typography, UpdateIcon } from '@uy3/web-components';
import ShowValuesButton from 'components/ShowValuesButton/ShowValuesButton';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

interface BankAccountHeaderProps {
    isFetching: boolean;
    refetch: () => void;
}

const theme = activeTheme();
const BankAccountHeader: React.FC<BankAccountHeaderProps> = ({ isFetching, refetch }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row" mb={3}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Contas digitais
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ alignSelf: 'flex-end', color: 'black' }}
                    variant="text"
                    disabled={isFetching}
                    onClick={refetch}
                    size="large"
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                    color="primary"
                >
                    Atualizar
                </Button>
                <ShowValuesButton />
            </Grid>
        </Stack>
    );
};

export default BankAccountHeader;
