import React from 'react'
import { WalletFormHeader, WalletFormHeaderProps } from './WalletFormHeader';
import { TabsProps } from '@uy3/web-components';
import { useParams } from 'react-router';
import { TabBar } from 'components/TabBar/TabBar';

type WalletFormType = {} & WalletFormHeaderProps

export const WalletForm = ({ ...props }: WalletFormType) => {
    const { id } = useParams(); 

    const tabs = [
        { label: 'Informações', href: id },
        { label: 'Arquivos remessa', href: 'arquivos-remessa' },
        { label: 'Arquivos retorno', href: 'arquivos-retorno' }, 
        { label: 'Tarifas do convênio', href: 'tarifas-convenio' } 
    ] as TabsProps[];

    return (
        <React.Fragment>
            <WalletFormHeader {...props} />
            <TabBar tabs={tabs} />
        </React.Fragment>
    )
}
