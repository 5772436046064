import { array, object } from "yup"

export const validationSchemaDeleteCheckList = () => {
  return object().shape({
    complianceChecklist: array().typeError('Motivo da aprovação precisa ser preenchida').min(1, "Selecione ao menos 1 reprovação.").required('Motivo da aprovação: precisa ser preenchida'),
  })
}

export const defaultvaluesDeleteCheckList = {
  complianceChecklist: []
}