import { Skeleton, Stack } from '@mui/material';
import React from 'react'

export const InstallmentFormSkeleton = () => {
    return (
        <Stack spacing={6} width='99%'>
            <Stack sx={{
                display: 'grid',
                pt: 10,
                gridTemplateColumns: '200px 800px',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: 2
            }}>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={30}
                    sx={{
                        margin: '20px 0',
                        borderRadius: '5px'
                    }}
                />
                <Stack direction='row' justifyContent='end' alignItems='center' spacing={2}>
                {[1, 2, 3, 4, 5].map(item => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={30}
                            sx={{
                                margin: '20px 0',
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
                </Stack>
            </Stack>
            <Stack sx={{
                display: 'grid',
                gridTemplateColumns: 'auto auto auto auto',
                flexWrap: 'wrap',
                gap: 2
            }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={30}
                            sx={{
                                margin: '20px 0',
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
            </Stack>

            <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={400}
                    sx={{
                        margin: '20px 0',
                        borderRadius: '5px'
                    }}
                />
        </Stack>
    )
}
