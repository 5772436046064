import styled from "@emotion/styled";
import { Grid, Stack } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import { Button, LeftIcon, NotFound, Typography } from "@uy3/web-components";
import {ErrorOutline} from '@mui/icons-material';
import { AxiosResponse } from "axios";
import { mapErrorResponse } from "contexts/responseErrorContext";
import { useNavigate } from "react-router-dom";
import { activeTheme } from "services/theme";

export const MainBox = styled(Grid2)();

export const ButtonBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'right',
	alignItems: 'right',
}))

export type ErrorProps = {
	error: {
		response?: AxiosResponse
	}
};

const theme = activeTheme();

export function Error(errors: any) {
	const navigate = useNavigate();
	const errorStatus = errors?.error?.response?.status;
	let genericMessage = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
	
	
	const { errorMessage } = mapErrorResponse(errors?.error);
	
	if (errorStatus === 404) return <NotFound />;
	
	return (
		<MainBox m={12}>
			<Stack direction='row' alignItems='start' p={2} borderRadius='8px' bgcolor='#FDEDED'>
				<ErrorOutline sx={{height: 30, width: 30, marginTop: 2, marginRight: 1}}/>
				<Grid>
					<Typography
						variant="body2"
						sx={{
							fontSize: { md: '40px' },
							fontWeight: { md: '600' },
							fontStyle: { xs: 'normal', md: 'normal' },
						}}
					>
						Houve um erro durante a sua solicitação
					</Typography>
					<Typography
						variant="body2"
						sx={{
							fontSize: { md: '30px' },
						}}
					>
						{errorMessage ?? genericMessage}
					</Typography>
				</Grid>
			</Stack>
			<ButtonBox mt={3}>
				<Button
					color="primary"
					size="medium"
					startIcon={<LeftIcon sx={{height: 20, width: 20}} htmlColor={theme.palette.primary.contrastText}/>}
					variant="contained"
					onClick={() => navigate(-1)}>Voltar</Button>
			</ButtonBox>
		</MainBox>
	);
}