import { useQuery } from '@tanstack/react-query';
import { useIdentity } from 'contexts/identityContext';
import qs from 'qs';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { useTenant } from './tenantContext';

const apiPath = '/Dashboard/MetricsV3';

export type DashboardV3MetricsFilters = {
    initialDate: Date | string | undefined;
    finalDate: Date | string | undefined;
    productList: string[] | undefined;
    statusList?: string[] | undefined;
};

export function useDashboardData(filters: DashboardV3MetricsFilters | undefined) {
    const { currentTenantId } = useTenant();
    const filtersComplete = { ...filters, tenant: currentTenantId };
    const { token } = useIdentity();
    return useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['dashboard', filtersComplete],
        queryFn: async (): Promise<IMetrics> => {
            const { data } = await creditAxiosApi.get(apiPath, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                params: filtersComplete,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                },
            });

            const result = data as IMetrics;
            return {
                ...result,
                updatedAt: new Date() as Date,
            } as IMetrics;
        },
    });
}

export interface IMetrics {
    creditNotesByStatus: ICreditNotesByStatus[];
    creditNotesByProductCategory: ICreditNotesByProductCategory[];
    creditNotesByMonth: ICreditNotesByMonth[];
    creditNotesSummary: ICreditNotesSummary;
    updatedAt: string | Date;
}

interface IBase {
    numberOfCreditNotes: number;
    principalAmountInCents: number;
}

export interface ICreditNotesByStatus extends IBase {
    status: number;
    statusDisplay: string;
}

export interface ICreditNotesByProductCategory extends IBase {
    categoryName: string;
    liquidAmountInCents: number;
}

export interface ICreditNotesByMonth {
    numberOfCreditNotes: number;
    liquidAmountInCents: number;
    date: string;
}
export interface ICreditNotesSummary {
    creditNotesCancelled: number;
    creditNotesFinished: number;
    creditNotesFinishedGrossValueSum: number;
    creditNotesInProgress: number;
    creditNotesProductCategories: number;
}
