import { string, object } from 'yup';

export function ParcelsBatchAssignmentSchema() {
    const int64MaxValue = 9223372036854775807;

    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string().required('Data de inicio do cadstro precisa ser preenchida.').typeError('Data de inicio do cadstro: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().required('Data final do cadastro precisa ser preenchida.').typeError('Data final do cadastro: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        creditNoteNo: string().when("optionFilter", {
            is: "creditNoteNo",
            then: string().typeError('Número da operação inválido.').required('Número da operação: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxPaymentValue: string().when("optionFilter", {
            is: "maxPaymentValue",
            then: string().test('is-less-than-max', 'Valor da Parcela inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor da Parcela precisa ser um número.').required('Valor da Parcela: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minPaymentValue: string().when("optionFilter", {
            is: "minPaymentValue",
            then: string().test('is-less-than-min', 'Valor da Parcela inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor da Parcela precisa ser um número.').required('Valor da Parcela: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        maxAssignmentValue: string().when("optionFilter", {
            is: "maxAssignmentValue",
            then: string().test('is-less-than-max', 'Valor da Cessão inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor da Cessão precisa ser um número.').required('Valor da Cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        minAssignmentValue: string().when("optionFilter", {
            is: "minAssignmentValue",
            then: string().test('is-less-than-min', 'Valor da Cessão inválido.', value => {
                if (!value) return true;
                const numericValue = parseInt(value);
                return (
                    !isNaN(numericValue) &&
                    Number.isInteger(numericValue) &&
                    numericValue >= 0 &&
                    numericValue <= int64MaxValue
                );
            }).typeError('Valor da Cessão precisa ser um número.').required('Valor da Cessão: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        
    });
}

export const defaultValuesBatchFiltersSchema = {
    optionFilter: 'creditNoteNo',
    name: null,
    amortizationType: null,
    tenant: null,
    initialDate: new Date(), 
    finalDate: new Date(), 
};