export class AssingCreateModel {
    userId!: string;
    groupName!: string;
    tenant!: string;

    constructor(init?: Partial<AssingCreateModel>) {
        Object.assign(this, init);
    }
}

export type AssignProps = {
    recordId: string | undefined,
    recordType: string,
    userId: string,
    groupName: string,
    tenant: string | undefined
}