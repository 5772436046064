import { boolean, object, string } from 'yup';


export function validationSchemaConcilicaoLiquidacao() {
    return object().shape({
        dataRepasse: string()
            .required('Data repasse: precisa ser preenchida')
            .typeError('Data repasse: precisa ser preenchida'),
        dataGarantia: string().nullable(),
        persist: boolean().nullable(),
        queryCaixa: boolean().nullable()
    });
};

export const defaultValuesConcilicaoLiquidacao = {
    dataRepasse: null,
    dataGarantia: null,
    persist: false,
    queryCaixa: false
};