import React from 'react';
import { ComponentFormType } from './GenericManagementWallet';
import { Stack } from '@mui/material';
import { Button, CloseIcon, DeleteIcon, DoneIcon, Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';

const theme = activeTheme();

type GenericFormManagementWalletProps = {
    FormComponent: React.ReactNode;
    title: string;
} & ComponentFormType;

export const GenericFormManagementWallet = ({
    onDelete,
    isEditForm = false,
    currentValue,
    FormComponent,
    title,
    onClose,
}: GenericFormManagementWalletProps) => {
    return (
        <Stack spacing={3}>
            {!isEditForm && (
                <Stack>
                    <Typography variant="lg" fontWeight={700}>
                        {title}
                    </Typography>
                </Stack>
            )}
            {isEditForm && (
                <Stack>
                    <Typography variant="h4" fontWeight={700}>
                        {title}
                    </Typography>
                    <Typography fontWeight="bold" variant="h5" lineHeight={2}>
                        {currentValue}
                    </Typography>
                </Stack>
            )}
            {FormComponent}
            <Stack
                direction={'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
                mt={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                {isEditForm && (
                    <Button
                        variant="outlined"
                        onClick={onDelete}
                        startIcon={
                            <DeleteIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                        }
                    >
                        Excluir
                    </Button>
                )}
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<DoneIcon sx={iconSx} htmlColor="#fff" />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
