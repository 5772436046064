import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultvaluesApproveMarginReverse,
    validationSchemaApproveMarginReverse,
} from './ApproveMarginReverseSchema';
import { FieldValues } from 'react-hook-form';
import { useGetAvailableReserveTokenByPersonId } from 'contexts/naturalPersonContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { ApproveMarginReverseForm } from './ApproveMarginReverseForm';
import { IGetTokensQuery } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';

type ApproveMarginReverseFormProps = {
    onClose: () => void;
    handleSubmit: (values: FieldValues) => void;
    handleAddToken: () => void;
    actionGeneric: string;
    personId: string;
    isNaturalPerson: boolean
};

export const ApproveMarginReverseContainer = ({
    onClose,
    handleSubmit,
    handleAddToken,
    actionGeneric,
    personId,
    isNaturalPerson
}: ApproveMarginReverseFormProps) => {
    const { getValues } = useFormContext();
    const { data } = useGetAvailableReserveTokenByPersonId(personId!, isNaturalPerson, actionGeneric);

    const creditNoteValues = getValues() as CreditNoteReadModel;
    const includePaymentFixedCostsValue = creditNoteValues.amortization.includePaymentFixedCosts;
    const valueMarginReserveValue = creditNoteValues.amortization.requestedAmount;

    const validationSchema = validationSchemaApproveMarginReverse();
    const defaultValues = defaultvaluesApproveMarginReverse(
        includePaymentFixedCostsValue,
        valueMarginReserveValue
    );

    const onSubmit = (values: FieldValues) => {
        delete values.selectedTokens;
        delete values.selectedTokensDisplay;
        const onlyTokensSelected = values?.filteredByTokensSelected?.map((x: IGetTokensQuery) => x.Token.TokenValue)
        const tokensBeforeAdded = values?.tokens?.filter((x: any) => onlyTokensSelected.includes(x?.token?.tokenValue)); 
        handleSubmit({ ...values, tokens: tokensBeforeAdded });
    }

    return (
        <FormProvider
            defaultValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            <ApproveMarginReverseForm
                onClose={onClose}
                handleAddToken={handleAddToken}
                tokenData={data}
            />
        </FormProvider>
    );
};
