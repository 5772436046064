import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Card = styled(Stack)(({ theme }) => ({
  background: theme.palette.common.white,
  border: '0.5px solid rgba(0, 0, 0, 0.08)',
  borderRadius: '8px',
  padding: '24px',
  width: 'fit-content',
  flexDirection: 'row',
  alignItems: 'center',
}));
