import { Box, Stack } from '@mui/material';
import { Alert, Typography } from '@uy3/web-components';
import { CustomGrid } from 'components/Custom/CustomMUI';
import { Radio } from 'components/Forms/Radio/Radio';
import { useUserPermissionData } from 'contexts/userContext';
import { hasCustomPermission } from 'helpers';
import React from 'react';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

type PersonType = 'NaturalPerson' | 'LegalPerson' | undefined;
interface RegistrationTypeProps {
    personType: PersonType;
    setPersonType: (value: PersonType) => void;
    setNextStep: (value: boolean) => void;
}

const RegistrationType: React.FC<RegistrationTypeProps> = ({
    personType,
    setPersonType,
    setNextStep,
}) => {
    const BorderColorNP =
        personType === 'NaturalPerson' ? theme.palette.primary.main : theme.palette.grey[300];
    const BorderColorLP =
        personType === 'LegalPerson' ? theme.palette.primary.main : theme.palette.grey[300];

    const { hasPermission } = useUserPermissionData();
    const hasLegalPersonPerm = hasCustomPermission('LegalPerson', 'Create', hasPermission);
    const hasNaturalPersonPerm = hasCustomPermission('NaturalPerson', 'Create', hasPermission);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value as PersonType;
        setPersonType(value);
        setNextStep(true);
    };

    return (
        <React.Fragment>
            {!hasNaturalPersonPerm && !hasLegalPersonPerm && (
                <Box mb={2}>
                    <Alert
                        severity="warning"
                        text="Você não tem permissão para criar uma pessoa física ou jurídica."
                    />
                </Box>
            )}

            <Typography variant="lg" color={theme.palette.common.black} mt={2}>
                Tipo de cadastro
            </Typography>
            <Stack direction="column" spacing={2} my={3}>
                <CustomGrid
                    container
                    borderColor={BorderColorNP}
                    sx={!hasNaturalPersonPerm ? disabledSx : {}}
                >
                    <Typography
                        variant="md"
                        color={
                            personType === 'NaturalPerson'
                                ? theme.palette.primary.main
                                : theme.palette.common.black
                        }
                    >
                        Pessoa física
                    </Typography>
                    <Radio
                        size="medium"
                        value={personType ?? ''}
                        options={[{ value: 'NaturalPerson', disabled: !hasNaturalPersonPerm }]}
                        onChange={handleOnChange}
                    />
                </CustomGrid>
                <CustomGrid
                    container
                    borderColor={BorderColorLP}
                    aria-disabled={hasLegalPersonPerm}
                    sx={!hasLegalPersonPerm ? disabledSx : {}}
                >
                    <Typography
                        variant="md"
                        color={
                            personType === 'LegalPerson'
                                ? theme.palette.primary.main
                                : theme.palette.common.black
                        }
                    >
                        Pessoa jurídica
                    </Typography>
                    <Radio
                        value={personType ?? ''}
                        size="medium"
                        options={[{ value: 'LegalPerson', disabled: !hasLegalPersonPerm }]}
                        onChange={handleOnChange}
                    />
                </CustomGrid>
            </Stack>
        </React.Fragment>
    );
};
export default RegistrationType;

const disabledSx = {
    opacity: 0.5,
    pointerEvents: 'none',
    backgroundColor: '#f2f2f2',
};
