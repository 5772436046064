import { SelectOption } from "components/Forms/FormFields";

interface ICodeByCategoryName<T> {
  options: T[];
  name: string;
}

export const codeByCategoryName = <T extends SelectOption>({ options, name }: ICodeByCategoryName<T>): T | undefined => {
  const find = options.find((item: T) => item.value === name);
  return find;
}