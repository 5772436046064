import { Grid } from '@mui/material';
import { Button, Popup } from '@uy3/web-components';
import {
    ApiResponseError,
    showErrorToast,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useSignatureCancelAsync } from 'contexts/signature/signatureContext';

interface CancelSignatureProps {
    openPopup: boolean;
    onClose: () => void;
    signatureWorkFlowId: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export default function CancelSignaturePopup({
    openPopup,
    onClose,
    signatureWorkFlowId,
    setToast
}: CancelSignatureProps) {
    const onSucess = () => {
        const title = 'Assinatura cancelada com sucesso!';
        const desc = 'Ótimo! Agora você pode visualizar o registro cancelado.';
        showSuccessToast(title, desc, setToast);
        onClose();
    };
    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const desc = errorMessage;
        showErrorToast(title, desc, setToast);
    };

    const { mutateAsync } = useSignatureCancelAsync(onSucess, onError);
    const handleCancelSignature = async () => {
        await mutateAsync(signatureWorkFlowId);
    };

    return (
        <>
            <Popup
                open={openPopup}
                title="Cancelar assinatura"
                text="Tem certeza que deseja cancelar esta assinatura?"
                onClose={onClose}
                children={
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onClose}
                            size="medium"
                            sx={{ mt: 3, mr: 2 }}
                        >
                            Não, cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelSignature}
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Sim, confirmar
                        </Button>
                    </Grid>
                }
            />
        </>
    );
}
