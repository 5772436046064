import styled from '@emotion/styled';
import { Grid, Stack } from '@mui/material';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

interface CustomGridProps {
    borderColor?: string;
    showAnimation?: boolean;
    minHeight?: string
    showBorderColorHover?: boolean
}

export const CustomGrid = styled(Grid)<CustomGridProps>`
    border-radius: 8px;
    padding: 16px;
    justify-content: space-between;
    align-items: center;
    border: 1px solid;
    border-color: ${(props) => props.borderColor ?? theme.palette.grey[300]};
    display: flex;
    flex-grow: 1;
    min-height: ${(props) => props?.minHeight ?? "auto"};
    word-break: break-all;

    &:hover {
        border-color: ${({showBorderColorHover = true}) => showBorderColorHover ? theme.palette.primary.main : "none"};
        ${({ showAnimation }) => showAnimation && `
            transform: translateX(2px) scale(1.02);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        `}
    }
`;

interface CustomBoxProps {
    width?: string;
}

export const CustomBox = styled(Grid) <CustomBoxProps>`
    border-radius: 8px;
    background: rgba(186, 191, 208, 0.16);
    display: flex;
    width: ${(props) => props.width ?? '565px'};
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
`;

export const CustomStack = styled(Stack)`
    border-radius: 8px;
    border: 1px solid var(--neutral-light, #babfd0);
    padding: 24px;
    margin-right: 24px;
`;

export const CustomInfoContainer = styled.div`
    border-radius: 8px;
    border: 1px solid #babfd0;
    flex-shrink: 0;
    margin-bottom: 24px;
`;
