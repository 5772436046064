import { LinearProgress } from "@mui/material"
import { Box } from "@mui/system"
import { activeTheme } from "services/theme";

type RefreshProgressProps = {
	refreshing: boolean
}

const currentTheme = activeTheme();

export const RefreshProgress = ({ refreshing }: RefreshProgressProps) => {
	return refreshing ?
		<Box mt={1} mb={1} sx={{ width: '100%' }} >
			<LinearProgress
				color='primary'
				sx={{
					backgroundColor: currentTheme.palette.primary.main,
					'& .MuiLinearProgress-bar1Indeterminate ': {
						backgroundColor: currentTheme.palette.primary.light,
					},
					'& .MuiLinearProgress-bar2Indeterminate ': {
						backgroundColor: currentTheme.palette.background.default,
					}
				}} />
		</Box> :
		<Box mt={1} mb={1}
			sx={{
				height: (theme) => theme.spacing(0.5),
				width: '100%'
			}}
		/>
}
