import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import React, { useState } from 'react';
import { BillingFull, IBillingCrate } from 'services/walletManagement/billing/billing.types';
import GenerateFormStepComponents from './GenerateFormStepComponents';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultValuesNewChargesForm,
    validationSchemaNewChargesForm,
} from './FormStep/NewCharges/NewChargesSchema';
import { FormStep } from './StepsButtons/StepNewChargesButton';
import {
    defaultValuesCreateChargesForm,
    validationSchemaCreateChargesForm,
} from './FormStep/CreateCharges/CreateChargesSchema';
import {
    defaultValuesBillingParamsForm,
    validationSchemaBillingParamsForm,
} from './FormStep/BillingParameters/BillingParametersSchema';
import {
    defaultValuesAddGuarantorForm,
    validationSchemaAddGuarantorForm,
} from './FormStep/BillingSummary/AddGuarantorSchema';
import { Popup, ToastProps } from '@uy3/web-components';
import { isEmpty } from 'lodash';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { useBillingRegisterMutation, useCreateBillingAsyncMutation, usePatchBillingAsyncMutation } from 'contexts/wallet/Billing/billingContext';

interface NewChargesContainerProps {
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    setDrawerTitleForStep?: React.Dispatch<React.SetStateAction<string | undefined>>;
    onCloseContinueBy?: () => void;
    continueBy?: number
    rowData?: BillingFull;
    onSuccessWhenRegister?: () => void;
}

const NewChargesContainer: React.FC<NewChargesContainerProps> = ({
    onCloseDrawer,
    setToast,
    setDrawerTitleForStep,
    onCloseContinueBy,
    continueBy = 0,
    rowData,
    onSuccessWhenRegister
}) => {
    const [formValues, setFormValues] = useState<FieldValues | null>(null)
    const { handleErrorException } = useApiRequest();
    const [confirmLastStep, setConfirmLastStep] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState(continueBy);
    const [idempotence, setIdempotence] = useState<string | undefined>(undefined);
    const [chargesId, setChargesId] = useState<string | undefined>(undefined);
    const { validationErrors } = useFormContext();

    const onSuccess = (type: string) => {
        const successMessage: { [type: string]: { title: string, description?: string, severity?: ToastProps['severity'] } } = {
            "createCharges": {
                title: "Sucesso",
                description: 'Excelente! Agora você pode editar ou seguir com o cadastro.',
                severity: 'info'
            },
            "addTags": {
                title: "Sucesso",
                description: "Agora você pode definir os parâmetros da cobrança",
                severity: 'info'
            },
            "billingSetting": {
                title: "Sucesso",
                description: "Parêmetros da cobrança definidos, agora você pode seguir em frente",
                severity: 'info'
            },
            "register": {
                title: "Cobrança registrada com sucesso!",
                description: "Aguarde um momento enquanto atualizamos o status. Assim que estiver alterado, o boleto estará disponível para você.",
                severity: 'success'
            },
        }
        const toastPreference = successMessage[type];
        setToast({
            open: true,
            severity: toastPreference.severity,
            title: toastPreference.title,
            description: toastPreference?.description
        });
        if (["addTags", "billingSetting"].includes(type)) {
            handleNextStep();
        }

    };

    const handleReponseError = (error: ApiResponseError) => handleErrorException(error, setToast)


    const { mutateAsync: mutateCreateChargesAsync } = useCreateBillingAsyncMutation(() => onSuccess('createCharges'), handleReponseError, idempotence);
    const { mutateAsync: mutateRegisterAsync } = useBillingRegisterMutation(() => onSuccess('register'), handleReponseError);
    const { mutateAsync: addTagsMutateAsync } = usePatchBillingAsyncMutation(chargesId! ?? rowData?.id, () => onSuccess('addTags'), handleReponseError);
    const { mutateAsync: billingSettingMutateAsync } = usePatchBillingAsyncMutation(chargesId! ?? rowData?.id, () => onSuccess('billingSetting'), handleReponseError);

    const handleClose = () => {
        setCurrentStep(0);
        onCloseDrawer();
    };

    const registerAsync = async () => {
        setConfirmLastStep(false);
        await mutateRegisterAsync(formValues?.id)
            .then(() => {
                handleClose();
                onSuccessWhenRegister && onSuccessWhenRegister()
            }).catch(handleReponseError);
    }

    const handleFormSave = async (values: FieldValues) => {
        setFormValues(values);
        if (currentStep !== FormStep.BillingSummaryStep) {
            return handleStepProgress(values);
        } else {
            const guarantor = values?.guarantor;
            if (isEmpty(guarantor?.name) && isEmpty(guarantor?.registrationNumber)) {
                return setConfirmLastStep(true)
            };
            return registerAsync();
        }
    };

    const optionAddGuarantor = () => {
        setConfirmLastStep(false); 
        setToast({
            open: true, 
            severity: 'info', 
            title: 'Excelente!', 
            description: 'Para adicionar um sacador avalista, clique no primeiro botão deste formulário e preencha o formulário.'
        })
    }

    const handleStepProgress = async (values: FieldValues) => {
        if (!validationErrors) handleNextStep();
    };

    const handleNextStep = () => {
        setCurrentStep((prev) => prev + 1);
    };

    const handlePrevStep = () => {
        if (continueBy !== 0 && currentStep === 2) {
            return onCloseContinueBy && onCloseContinueBy();
        }
        setCurrentStep((prev) => prev - 1);
    };

    const createTagsAsync = async (values: FieldValues) => {
        return await addTagsMutateAsync({
            tags: values.tagsSelected
        });
    }

    const createBillingSattingAsync = async (values: FieldValues) => {
        return await billingSettingMutateAsync({
            billingSettingId: values?.billingSettingId,
            dueDate: values?.dueDate,
            discount: values?.discount,
            discountLimitDate: values?.discountLimitDate, 
            communicationSettingId: values?.communicationSettingId,
            firstInstructionDebtCollection: values?.firstInstructionDebtCollection, 
            secondInstructionDebtCollection: values?.firstInstructionDebtCollection === "06" ? values?.secondInstructionDebtCollection : null, 
        });
     }

    const handleSubmitByPatch = async (values: FieldValues) => {
        switch (currentStep) {
            case 0:
                setIdempotence(String(Math.random()).replace('.', ''));
                return handleFormSave(values);
            case 1:
                return handleFormSave(values);
            case 2:
                return createTagsAsync(values);
            case 3:
                return createBillingSattingAsync(values);
            case 4:
                return handleFormSave(values);
        }
    }

    const onSaveAndDoNotChargeNow = async (values: FieldValues) => {
        const chargesData = values as IBillingCrate;
        await mutateCreateChargesAsync(chargesData)
            .then(() => {
                const title = "Cobrança criada com sucesso!";
                const description = 'Excelente! Agora você pode editar ou seguir com o cadastro.';
                showSuccessToast(title, description ?? "", setToast);
                handleClose();
            }).catch((error) => {
                handleReponseError(error)
                return handlePrevStep();
            });
    };

    const continueCriatting = (values: FieldValues): FieldValues => {
        return mutateCreateChargesAsync(values as IBillingCrate)
            .then(async (data) => {
                await handleFormSave({
                    ...data,
                    cobrancaId: data?.id
                });
                setChargesId(data?.id);
                return data;
            }).catch((error) => {
                handleReponseError(error)
                return handlePrevStep();
            });
    };

    let validationSchema;
    let defaultValues;

    switch (currentStep) {
        case FormStep.CreateChargesStep:
            validationSchema = validationSchemaCreateChargesForm();
            defaultValues = defaultValuesCreateChargesForm;
            setDrawerTitleForStep && setDrawerTitleForStep('Criar cobrança');
            break;

        case FormStep.BillingParametersStep:
            validationSchema = validationSchemaBillingParamsForm();
            defaultValues = defaultValuesBillingParamsForm;
            setDrawerTitleForStep && setDrawerTitleForStep('Parâmetros da cobrança');
            break;

        case FormStep.BillingSummaryStep:
            validationSchema = validationSchemaAddGuarantorForm();
            defaultValues = defaultValuesAddGuarantorForm;
            setDrawerTitleForStep && setDrawerTitleForStep('Resumo da cobrança');
            break;

        default:
            validationSchema = validationSchemaNewChargesForm();
            defaultValues = defaultValuesNewChargesForm;
            if (currentStep === FormStep.SummaryChargesStep) setDrawerTitleForStep && setDrawerTitleForStep('Resumo da cobrança');
            break;
        // regua de cobrança    
        // case FormStep.BillingRuleStep:
        //     validationSchema = validationSchemaBillingRuleForm();
        //     defaultValues = defaultValuesBillingRuleForm;
        //     setDrawerTitleForStep && setDrawerTitleForStep('Régua de cobrança');
        //     break;
    }

    return (
        <FormProvider
            defaultValues={defaultValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmitByPatch}
        >
            <React.Fragment>

                <GenerateFormStepComponents
                    {...{
                        currentStep,
                        handleClose,
                        handleNextStep,
                        handlePrevStep,
                        onSaveAndDoNotChargeNow,
                        continueCriatting,
                        setToast: setToast,
                        rowData: rowData!
                    }}
                />

                <Popup
                    open={confirmLastStep}
                    title='Aviso!'
                    onClose={() => setConfirmLastStep(false)}
                    text='Realmente deseja seguir com o registro sem um sacador avalista?'
                    children={(
                        <GenericActionHandler
                            handleSubmit={registerAsync}
                            onClose={optionAddGuarantor}
                            titleCancel='Adicionar sacador avalista'
                            titleConfirm='Sim, registrar sem sacador avalista'
                            isModal
                        />
                    )}
                />
            </React.Fragment>
        </FormProvider>
    );
};

export default NewChargesContainer;
