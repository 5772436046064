import { FC, Fragment } from 'react'
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DownloadIcon, InfoIcon, InvoiceReceivableIcon } from '@uy3/web-components';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import { FileInstructionFiltersContainer } from 'components/DataTableFilters/FileInstructionFilters/FileInstructionFiltersContainer';
import { useNavigate } from 'react-router';
import { isEmpty } from 'lodash';
import { TooltipComponent } from 'components/Tooltip/Tooltop';
import { IconButton, Stack } from '@mui/material';

type FileInstructionListProps = {
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    queryData: any;
    rowsPerPage: number;
    page: number;
    handleDownloadFile: (fileInstructionId: string) => void;
}

export const FileInstructionList: FC<FileInstructionListProps> = (props) => {
    const navigate = useNavigate();
    const {
        page,
        queryData,
        rowsPerPage,
        setPage,
        setRowsPerPage,
        handleDownloadFile
    } = props;

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nome do arquivo',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'walletCode',
            headerName: 'Código do Convênio',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'sequential',
            headerName: 'Sequencial',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'date',
            headerName: 'Data do upload',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => new Date(value).toLocaleDateString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
            })
        },
        {
            field: 'error',
            headerName: 'Mensagem de erro',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'center',
            headerAlign: 'center',  
            renderCell: ({ value }) => {
                return (
                    <Stack direction='row' alignItems='center' justifyContent='center'>
                        {!isEmpty(value) ?
                            <TooltipComponent placement='right' title={value}>
                                <IconButton>
                                    <InfoIcon sx={iconSx} />
                                </IconButton>
                            </TooltipComponent>
                            :
                            <>-</>}
                    </Stack>
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 0,
            editable: false,
            renderCell: ({ row }) => {
                return <RowActions
                    listButtons={[
                        {
                            action: () => navigate(`/gestao-carteira/carteiras/arquivos/${row?.id}/remessa`),
                            label: "Ver transações",
                            icon: <InvoiceReceivableIcon sx={iconSx} />,
                            disabled: false,
                            hide: false
                        },
                        {
                            action: () => handleDownloadFile(row?.id),
                            label: "Download",
                            icon: <DownloadIcon sx={iconSx} />,
                            disabled: false,
                            hide: false
                        },
                    ]}
                />
            },
        },
    ];



    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <FileInstructionFiltersContainer />,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    )
}
