import { Grid, Stack } from '@mui/material';
import { Button, Typography, UploadIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { hasCustomPermission } from 'helpers';
import { useLocation } from 'react-router';
import { activeTheme } from 'services/theme';

type RequiredDocumentHeaderProps = {
    handleCreate: (indexNumber?: number | undefined) => void;
};

const theme = activeTheme();
const colorBlack = theme.palette.common.black;
export const RequiredDocumentHeader = ({ handleCreate }: RequiredDocumentHeaderProps) => {
    const { pathname } = useLocation();
    const { hasPermission } = useUserPermissionData();
    const { isRootTenancy } = useTenant();

    const isReadOnly = (): boolean => {
        const typePermission = pathname.includes('novo') ? 'Create' : 'Update';
        const hasCreditProductMenu = pathname.includes('credito');
        const resource = hasCreditProductMenu ? 'CreditProduct' : 'BankAccountProduct';
        const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);

        if (!permissionCustom || !isRootTenancy) return true;
        return false;
    };

    const showButton = !isReadOnly();

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={4} mt={3}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Documentos obrigatórios
                </Typography>
            </Grid>
            <Grid>
                {showButton && (
                    <Button
                        name="btn-add-document-required"
                        sx={{ color: colorBlack }}
                        variant="text"
                        onClick={() => handleCreate()}
                        startIcon={<UploadIcon htmlColor={colorBlack} sx={iconSx} />}
                    >
                        Adicionar documento
                    </Button>
                )}
            </Grid>
        </Stack>
    );
};
