import { Accordion, AccordionSummary, styled } from '@mui/material';

export const CustomizedAccordion = styled(Accordion)(({ theme }) => ({
    width: "100%",
    borderRadius: 8,
    border: '1px solid #babfd0',
    '&.MuiPaper-root.MuiAccordion-root': {
        '&:before': {
            display: 'none', // Isso remove o elemento gerado pelo ::before
        },
    },

    '&.Mui-disabled': {
        backgroundColor: "transparent",
    },
}));

export const CustomAccordionSummary = styled(AccordionSummary)<{useSwithExpandIcon: boolean}>(({useSwithExpandIcon}) => ({
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: useSwithExpandIcon ? 'none' : 'default',
    },
    '& .MuiAccordionSummary-expandIconWrapper:not(.Mui-expanded)': {
        transform: useSwithExpandIcon ? 'none' : 'default'
    }
}))