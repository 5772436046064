import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SaveIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <path
        d="M18.1776 2.28873L18.8847 1.58162V1.58162L18.1776 2.28873ZM21.7113 5.82238L21.0042 6.52949L21.7113 5.82238ZM5.5 23L5.5 24H7.5L7.5 23H5.5ZM16.5 23V24H18.5V23H16.5ZM15.3 7.5C15.8523 7.5 16.3 7.05228 16.3 6.5C16.3 5.94772 15.8523 5.5 15.3 5.5L15.3 7.5ZM8.7 5.5C8.14772 5.5 7.7 5.94771 7.7 6.5C7.7 7.05228 8.14772 7.5 8.7 7.5V5.5ZM22 8.93365L22 18.6H24L24 8.93365H22ZM18.6 22L5.4 22L5.4 24L18.6 24V22ZM2 18.6L2 5.4H0L0 18.6H2ZM5.4 2L15.0664 2V0L5.4 0V2ZM17.4705 2.99584L21.0042 6.52949L22.4184 5.11527L18.8847 1.58162L17.4705 2.99584ZM15.0664 2C15.9681 2 16.8329 2.35821 17.4705 2.99584L18.8847 1.58162C17.872 0.568927 16.4985 0 15.0664 0V2ZM5.4 22C3.52223 22 2 20.4778 2 18.6H0C0 21.5823 2.41766 24 5.4 24L5.4 22ZM22 18.6C22 20.4778 20.4778 22 18.6 22V24C21.5823 24 24 21.5823 24 18.6H22ZM24 8.93365C24 7.50148 23.4311 6.12797 22.4184 5.11527L21.0042 6.52949C21.6418 7.16711 22 8.03192 22 8.93365H24ZM2 5.4C2 3.52223 3.52223 2 5.4 2V0C2.41766 0 0 2.41766 0 5.4H2ZM7.5 23L7.5 16.4H5.5L5.5 23H7.5ZM8.7 15.2H15.3V13.2H8.7L8.7 15.2ZM16.5 16.4V23H18.5V16.4H16.5ZM15.3 15.2C15.9627 15.2 16.5 15.7373 16.5 16.4H18.5C18.5 14.6327 17.0673 13.2 15.3 13.2V15.2ZM7.5 16.4C7.5 15.7373 8.03726 15.2 8.7 15.2L8.7 13.2C6.93269 13.2 5.5 14.6327 5.5 16.4H7.5ZM15.3 5.5L8.7 5.5V7.5L15.3 7.5L15.3 5.5Z"
        fill={htmlColor}
        strokeWidth="1"
      />
    </SvgIcon>
  );
};

SaveIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24'
};
