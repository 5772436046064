import { activeConfig } from "services/config";
import _ from 'lodash';
import { isEmpty } from "lodash";

interface ProductForForm {
    [key: string]: any;
}

export interface FormFieldsConfig {
    [fieldName: string]: any;
}

export const isFieldHidden = (fieldName: string, formFieldsConfig: FormFieldsConfig): boolean => {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    return fieldValue?.Hidden || false;
};

export function getDefaultValueByFieldName(fieldName: string, formFieldsConfig: FormFieldsConfig): string | undefined {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    if (!isEmpty(fieldValue?.DefaultValue)) {
        return fieldValue.DefaultValue;
    }
    return undefined;
}

function setDefaultValue(schema: any, formFieldsConfig: any) {
    if (!schema || !formFieldsConfig) return;
    var schemProps = Object.getOwnPropertyNames(formFieldsConfig);
    schemProps.forEach((key: string) => {
        if (schema[key] instanceof Object) {
            console.log("settings default values for ", key, formFieldsConfig[key], schema[key])
            setDefaultValue(schema[key], formFieldsConfig[key]);
        }
        if (!!formFieldsConfig[key] && Object.getOwnPropertyNames(formFieldsConfig[key]).includes("DefaultValue")) {
            schema[key] = formFieldsConfig[key].DefaultValue;
            console.log("setted default value for field", key, formFieldsConfig[key].DefaultValue);
        }
    });
}

export function setDefaultValues(formSchema: any) {
    const appConfig = activeConfig();
    setDefaultValue(formSchema, appConfig?.FORM_FIELDS);
    return formSchema;
}

// ToDo: melhorar qualidade do código, deixar os metodos mais coesos e menos acoplados
export function getLabelField(fieldName: string, formFieldsConfig: FormFieldsConfig): string | undefined {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    if (fieldValue?.Label) {
        return fieldValue.Label;
    }
    return undefined;
}

export function isFieldDisable(fieldName: string, formFieldsConfig: FormFieldsConfig): boolean {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    return fieldValue?.Disabled || false;
}

export function getFieldOptions(fieldName: string, formFieldsConfig: FormFieldsConfig): any[] | undefined {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    if (fieldValue && fieldValue.Options?.length > 0) {
        return fieldValue.Options;
    }

    return undefined;
}

export function getFieldOptionsByChildren(fieldName: string, children: string, formFieldsConfig: FormFieldsConfig): any[] | undefined {
    const fieldValue = _.get(formFieldsConfig, fieldName)?.[children];
    if (fieldValue && fieldValue.Options?.length > 0) {
        return fieldValue.Options;
    }
    return undefined;
}

// Formata um objeto para remover as propriedades Options, Hidden, Disabled, Required e Label
function formatObject(obj: any): any {
    const propertiesToRemove = ['Options', 'Hidden', 'Disabled', 'Required', "Label"];

    const newObj: ProductForForm = _.transform(obj, (result: any, value: any, key: string) => {
        if (_.isObject(value) && key !== 'Options') {
            // Formata recursivamente objetos aninhados, excluindo as propriedades definidas em propertiesToRemove
            result[key] = formatObject(value);
        } else if (!propertiesToRemove.includes(key)) {
            result[key] = value;
        }
    }, {});

    // verifica se alguma key contem um objeto vazio, caso sim, seta o valor como null
    _.forOwn(newObj, (value, key) => {
        if (_.isEmpty(value)) {
            newObj[key] = null;
        }
    });

    return newObj;

}

export function newFieldsForForm(product: ProductForForm, formFieldsConfig: FormFieldsConfig) {
    const clonedProduct: ProductForForm = { ...product };
    const formattedFormFieldsConfig = formatObject(formFieldsConfig);
    const fieldDefaultValues = setDefaultValues(formattedFormFieldsConfig);

    if (_.has(clonedProduct, 'maximumAmortizationPeriods')) {
        //verificar se o valor de maximumAmortizationPeriods é null ou undefined
        if (_.isNil(clonedProduct.maximumAmortizationPeriods)) {
            const ammType = clonedProduct?.amortizationType?.toLowerCase();
            const isSacOrPrice = ammType === 'sac' || ammType === 'price';
            //define o valor de maximumAmortizationPeriods com base no valor de amortization.numberOfPayments
            clonedProduct.maximumAmortizationPeriods = isSacOrPrice ? clonedProduct?.amortization?.numberOfPayments : clonedProduct?.amortization?.termInMonths;
        }
    }

    // Adicionar amortizationType ao objeto amortization dentro de fieldDefaultValues
    if (_.has(fieldDefaultValues, 'amortization')) {

        /*
             ToDo: Futuras atualizações podem requerer campos adicionais de data, É essencial implementar uma abordagem mais genérica
                   para definir valores padrão, evitando múltiplos (ifs) no código.
             Nota: Campos de data em FORM_FIELDS são 'NULL' por padrão, representando uma data inválida, então é necessário definir manualmente
        */
        if (_.has(fieldDefaultValues, 'amortization.startDate')) {
            fieldDefaultValues.amortization.startDate = clonedProduct?.amortization?.startDate ?? new Date();
        }

        //mergea amortization do clonedProduct com amortization de fieldDefaultValues
        fieldDefaultValues.amortization = _.merge({}, clonedProduct.amortization, fieldDefaultValues.amortization);
    }

    // Iterar sobre as chaves de fieldDefaultValues
    _.forOwn(fieldDefaultValues, (value, key) => {
        // Verificar se a chave existe em product
        if (_.has(product, key)) {
            // Se existir em product, sobrescrever o valor em productForForm com o valor de fieldDefaultValues
            _.set(clonedProduct, key, _.get(formFieldsConfig, key));
        } else {
            // Se não existir em product, definir o valor de fieldDefaultValues em clonedProduct
            _.set(clonedProduct, key, value);
        }
    });

    const mergedProductWithDefaults = _.merge({}, clonedProduct, fieldDefaultValues);

    return mergedProductWithDefaults;
}


const appConfig = activeConfig();
const REQUIRED_FIELDS: FormFieldsConfig = appConfig.FORM_FIELDS;

export function isFieldRequired(fieldName: string): boolean {
    if (fieldName.includes('.')) {
        const [prefix, field] = fieldName.split('.');
        return REQUIRED_FIELDS?.[prefix]?.[field]?.Required ?? false;
    }

    return REQUIRED_FIELDS?.[fieldName]?.Required ?? false;
}
