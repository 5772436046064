/* eslint-disable react-hooks/exhaustive-deps */
import { Switch, Typography } from '@uy3/web-components';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { Grid } from '@mui/material';
import { useFormContext } from 'contexts/formContext';
import InterestModelCardData from './InterestModelCardData';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { DatePickerFormField } from 'components/Forms/FormFields';
import { useEffect } from 'react';

interface InterestModeDetailsProps { }

const InterestModelDetails: React.FC<InterestModeDetailsProps> = () => {
    const { setValue, watch } = useFormContext();
    const abatment: boolean = watch('abatment');

    const handleOnChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        if (!checked) setValue('discount', null);
        setValue('abatment', checked);
    };

    useEffect(() => {
        if (!!abatment)
            return setValue('discountLimitDate', watch('dueDate'))
    }, [abatment])

    const modelName = watch('billingSettingIdIdDisplay');

    return (
        <>
            <CustomTitle title={`Régua de cobrança ${modelName ? `-${modelName}` : ''}`} variant="lg" />

            {watch('billingSettingId') && <InterestModelCardData />}

            <Grid container item xs={5}>
                <Switch aria-label="insert-discount" onChange={handleOnChange} checked={abatment} />
                <Typography
                    variant="lg"
                    color="common.black"
                    ml={1}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                >
                    Inserir desconto
                </Typography>
            </Grid>

            {abatment &&
                <>
                    <Grid>
                        <CurrencyFormField
                            variant="outlined"
                            name='discount'
                            fullWidth
                            label="Valor do desconto"
                            required
                        />
                    </Grid>
                    <Grid>
                        <DatePickerFormField
                            label="Data limite desconto"
                            name='discountLimitDate'
                            fullWidth
                            required
                        />
                    </Grid>
                </>
            }
        </>
    );
};

export default InterestModelDetails;
