import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
export const MainBox = styled(Box)(() => ({
    width: '100%',
    background: theme.palette.common.white,
    borderRadius: '8px',
    '& .rdrInputRanges, .rdrDateDisplayWrapper, .rdrDefinedRangesWrapper': {
        display: 'none'
    },
   
    '& .rdrStaticRangeSelected': {
        color: theme.palette.primary.main,
        borderRight: `2px solid ${theme.palette.primary.main}`,
        borderRadius: '4px 0px 0px 4px',
    },
    '& .rdrMonthName': {
        ...theme.typography.sm,
        color: theme.palette.primary.main,
        textTransform: 'capitalize',
        paddingLeft: '10px'
    },
    '& .rdrWeekDay': {
        ...theme.typography.xs,
        color: theme.palette.primary.dark,
        textTransform: 'capitalize',
        '&:last-of-type': {
            color: theme.palette.primary.main,
        },
        '&:first-of-type': {
            color: theme.palette.primary.main,
        },
    },
    '& .rdrWeekDays': {
        '&:last-of-type, &:fist-of-type': {
            color: theme.palette.primary.main,
        }
    },
    '& .rdrDay': {
        ...theme.typography.xs,
        color: `${theme.palette.primary.main} !important`,
    },
    '& .rdrDayToday .rdrDayNumber span': {
        color: `${theme.palette.info.main}`,
        '&:after': {
            display: 'none'
        }
    },
    '& .rdrStartEdge': {
        borderRadius: '8px 0 0 8px',
        background: theme.palette.primary.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrEndEdge': {
        borderRadius: '0 8px 8px 0',
        background: theme.palette.primary.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrInRange': {
        background: 'transparent',
        '& + .rdrDayNumber span': {
            color: `${theme.palette.primary.main} !important`
        }
    },
    '& .rdrStartEdge.rdrEndEdge': {
        borderRadius: '8px',
        background: theme.palette.info.main,
        '& + .rdrDayNumber span': {
            color: `${theme.palette.common.white} !important`
        }
    },
    '& .rdrDayPassive .rdrDayNumber span': {
        color: 'transparent !important'
    },
    '& .rdrDayHovered': {
        borderRadius: '8px',
        '& .rdrDayStartPreview': {
            borderRadius: '8px 0 0 8px'
        },
        '& .rdrDayEndPreview': {
            borderRadius: '0 8px 8px 0'
        },
        '& .rdrDayStartPreview.rdrDayEndPreview': {
            borderRadius: '8px'
        },
        '& .rdrDayInPreview': {
            borderRadius: '8px !important'
        },
    },
    '& .rdrDayStartPreview': {
        borderRadius: '8px 0 0 8px'
    },
    '& .rdrDayEndPreview': {
        borderRadius: '0 8px 8px 0'
    },
    '& .rdrDayStartPreview.rdrDayEndPreview': {
        borderRadius: '8px'
    },
    '& .rdrDayEndOfWeek.rdrDayWeekend .rdrDayInPreview': {
        borderRadius: '0 8px 8px 0'
    },
    '& .rdrDayStartOfWeek.rdrDayWeekend .rdrDayInPreview': {
        borderRadius: '8px 0 0 8px'
    },
    '& .rdrMonthPicker select option': {
        textTransform: 'capitalize',
    },
    '& .rdrMonthPicker select': {
        textTransform: 'capitalize',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
        color: `${theme.palette.primary.main}`
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span': {
        color: `${theme.palette.common.white}`
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span': {
        color: `${theme.palette.common.white}`
    },
    '& .rdrCalendarWrapper.rdrDateRangeWrapper': {
        height: '415px'
    },
}))