import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import { validationSchemaGroupForm, defaultValuesGroupForm } from './GroupFormSchema';
import { Box } from '@mui/material';
import { Alert } from '@uy3/web-components';
import { ApiResponseError, ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { RefreshProgress } from 'components/RefreshProgress';
import GroupForm from './GroupForm';
import { useGroupData, useGroupMutation } from 'contexts/groupContext';
import { CreateOrEditGroupProps } from 'services/group';
import React, { useState } from 'react';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { useUserPermissionData } from 'contexts/userContext';

const GroupFormContainer = () => {
    const { groupId } = useParams();
    const formMode: FormMode = groupId !== 'novo' ? 'update' : 'create';
    const { data, error, refetch, isFetching, isLoading } = useGroupData(groupId!);
    const { submitError, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasPermissionCreateOrUpdate = hasPermission('UserGroup', formMode === 'create' ? 'Create' : 'Update')
    const navigate = useNavigate();
    const [toast, setToast] = useState<ToastType>(toastState);

    const onSuccess = (data: CreateOrEditGroupProps) => {
        const id = formMode === 'create' ? data.groupName : groupId;
        navigate(`/seguranca/grupos/${id}`);
        if (groupId !== 'novo') refetch();
        setSubmitError(undefined);
        setToast({
            title: 'Grupo salvo com sucesso!',
            severity: 'success',
            open: true,
        });
    };

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        setSubmitError(response);
        setToast({
            title: 'Ops, ocorreu um erro!',
            description: errorMessage,
            severity: 'error',
            open: true,
        });
    };

    const { mutate } = useGroupMutation(groupId!, onSuccess, onError);
    const onSubmit = (values: CreateOrEditGroupProps) => mutate(values);
    const schema = validationSchemaGroupForm();
    const defaultValues = data ?? defaultValuesGroupForm;

    return (
        <GenericFormSkeleton isLoading={isLoading && groupId !== 'novo'}>
            <React.Fragment>
                <Toast toast={toast} setToast={setToast} />
                <RefreshProgress refreshing={isFetching} />
                <>
                    {error && groupId !== 'novo' && (
                        <Box mb={1}>
                            <Alert
                                severity="error"
                                text="Erro ao carregar os dados do grupo"
                                variant="filled"
                            />
                        </Box>
                    )}
                </>
                <ApiErrorAlert error={submitError} />
                <FormProvider
                    validationSchema={schema}
                    defaultValues={defaultValues}
                    onSubmit={onSubmit}
                    readOnly={!hasPermissionCreateOrUpdate}
                >
                    <GroupForm
                        hasPermissionCreateOrUpdate={hasPermissionCreateOrUpdate}
                        groupId={groupId}
                        isFetching={isFetching}
                        refetch={refetch}
                        setToast={setToast}
                    />
                </FormProvider>
            </React.Fragment>

        </GenericFormSkeleton>
    );
};

export default GroupFormContainer;
