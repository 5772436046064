import { Typography } from '@uy3/web-components';
import { isEmpty } from 'lodash'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type RenderColumnStatusBillingProps = {
    statusDisplay: any; 
    statusValue: string; 
    onClick: () => void;
    resource: "BillingList" | "InstallmentList"
}

export const RenderColumnStatusBilling = ({ statusDisplay, statusValue, onClick, resource }: RenderColumnStatusBillingProps) => {
    const billingStatusOptions: { [type: string]: string } = {
        "NotRegistered": theme.palette.grey[700],
        "Registering": "#309A8A",
        "Registered": theme.palette.info.main,
        "Paid": theme.palette.success.main,
        "Cancelled": '#2962FF',
        "InDebtCollection": theme.palette.primary.main,
        "ToDebtCollection": theme.palette.warning.main,
        "ConfirmedDebtCollection": theme.palette.error.main, 
        "ReturnedFromDebtCollection": "#F9110E", 
        "OverDue": "#880FFA", 
        "CancelDebtCollectionRegisterBilling": theme.palette.primary.main, 
        "CancelDebtCollectionCancelBilling": '#6b2a16'
    };

    const installmentStatusOptions: { [type: string]: string } = {
        "Created": theme.palette.grey[700],
        "Registered": theme.palette.info.main,
        "Paid": theme.palette.success.main,
        "Cancelled": '#2962FF'
    };

    const getOptionsColors = () => {
        switch(resource) {
            case "BillingList":
               return billingStatusOptions
            case "InstallmentList": 
               return installmentStatusOptions
        }
    }

    if (isEmpty(statusValue)) {
        return <> - </>
    }

    return (
        <Typography
            sx={{
                padding: '7px',
                borderRadius: '3px',
                color: 'white',
                background: getOptionsColors()[statusValue],
                marginLeft: -1,
                fontSize: '13px',
                width: '100%',
                textAlign: 'center',
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            {statusDisplay}
        </Typography>
    )
}
