import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { billingAxiosApi } from 'services/axiosApi/axiosApi';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { AssignProps, AssingCreateModel } from './types';

export async function assign(props: AssignProps, token: string) {
    const { recordId, recordType, ...rest } = props;

    var url = `/${recordType}/${recordId}/assign`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const payload = toDataModel(rest, AssingCreateModel);

    if (['Wallet'].includes(recordType)) {
        return billingAxiosApi.post(url, payload, config);
    }

    return creditAxiosApi.post(url, payload, config);
}
