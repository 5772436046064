import { Grid, Stack } from '@mui/material';
import { Button, CompleteIcon, DocExcelIcon, DocPdfIcon } from '@uy3/web-components';
import { useBankAccountCnab240Mutation, useBankAccountStatementMutation, useBankAccountStatementSpreadsheetMutation } from 'contexts/bankAccount/bankAccountContext';
import { useIdentity } from 'contexts/identityContext';
import React from 'react';
import { useParams } from 'react-router';
import { activeTheme } from 'services/theme';
import { DateFilterType } from './AccountStatementTabContainer';

interface ButtonExportProps {
    isEmptyData: boolean;
    isFetching: boolean;
    dateFilter: DateFilterType;
}

const theme = activeTheme();
const ButtonExportAccountStatement: React.FC<ButtonExportProps> = ({ dateFilter, isEmptyData, isFetching }) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();

    let filterStatement = {
        bankAccountId,
        initialDate: dateFilter.initialDate,
        finalDate: dateFilter.finalDate,
        token,
    };

    const { mutateStatementExportPdf, isLoading: isLoadingStatement } = useBankAccountStatementMutation(filterStatement);
    const { mutateStatementSpreadsheet, isLoading: isLoadingSpreadsheet } = useBankAccountStatementSpreadsheetMutation(filterStatement);
    const { mutateCnab240, isLoading: lodingCnab240 } = useBankAccountCnab240Mutation(filterStatement);

    const postStatementExportPdf = async () => mutateStatementExportPdf();
    const postStatementSpreadsheet = async () => mutateStatementSpreadsheet();
    const postCnab240 = async () => mutateCnab240();

    const isLoading = isLoadingStatement || isLoadingSpreadsheet || lodingCnab240 || isEmptyData;
    const disabledButton = isLoading || isEmptyData || isFetching;

    return (
        <Stack direction="row" justifyContent="flex-end" spacing={3} m={3}>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={isLoadingStatement}
                    onClick={postStatementExportPdf}
                    startIcon={<DocPdfIcon htmlColor={theme.palette.common.white} />}
                >
                    Exportar PDF
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    disabled={isLoadingSpreadsheet}
                    onClick={postStatementSpreadsheet}
                    startIcon={<DocExcelIcon htmlColor={theme.palette.common.white} />}
                    color="primary"
                >
                    Exportar Excel
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="contained"
                    size="medium"
                    color="primary"
                    disabled={disabledButton}
                    startIcon={<CompleteIcon htmlColor={theme.palette.primary.contrastText} />}
                    onClick={postCnab240}
                >
                    Exportar CNAB240
                </Button>
            </Grid>
        </Stack>
    );
};

export default ButtonExportAccountStatement;
