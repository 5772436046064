import { DefaultRecordDetails, IUploadModel } from 'contexts/apiRequestContext';
import { ESignaturePortalProvider, ESignatureType, EViewType } from 'utils/enums';
import { EBankAccountRequestType } from '../enums';

export interface IRelatedPersonCreateOrUpdateModel {
    personId: string;
    discriminator: string;
    personIdDisplay: string;
    typeOfRelationship: string;
    typeOfRelationshipDisplay: string;
    signatureType: string;
    signatureValidation: string;
    fundSignerType: string;
}

export interface IRequiredDoc extends DefaultRecordDetails {
    productId: string;
    personType: string;
    fileType: string;
    group: number;
    typeOfRelationship: string;
}

export class BankAccountProductBaseModel {
    name!: string;
    bankingAccountType?: EBankAccountRequestType;
    templateDoc?: IUploadModel;
    rateExternalId?: string;
    packageValue?: number;
    signatureType?: ESignatureType;
    useSignaturePortal?: boolean;
    signaturePortalProvider?: ESignaturePortalProvider;
    sendEmailNotification?: boolean;
    viewType!: EViewType;
    selfieSignature?: boolean;
    automaticComplianceApproval?: boolean;
    automaticComplianceAnalysisExecution?: boolean;
    defaultComplianceAnalysisGroup?: number;
    unicoTemplateName?: string;
    unicoMinimumScoreForSignatureValidation?: number;
    signatureValidationStep: boolean = false;
    instrumentApproval: boolean = false;
}