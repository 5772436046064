import { Theme } from "@mui/material";
import { UY3Icon, light } from "@uy3/web-components";
import { activeConfig } from "services/config";

export function activeTheme(): Theme {
	let config = activeConfig();
	let { spacing, shadows, components, typography, breakpoints, direction, shape, transitions, zIndex, mixins, unstable_sx, unstable_sxConfig, unstable_strictMode } = light;
	let theme = {
		spacing,
		mixins,
		unstable_strictMode,
		unstable_sx,
		unstable_sxConfig,
		shape,
		shadows,
		typography,
		breakpoints,
		transitions,
		zIndex,
		direction,
		components: {
			Icon: config.MAIN_LOGO === undefined ? <UY3Icon/> : <img src={config.MAIN_LOGO} width="100%" alt="logo" />,
			...components
		},
		palette: { ...light.palette, ...config.PALETTE },
	};

	return theme as unknown as Theme;
}