import { Grid, Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { TaxWithBarCodeFormContainer } from '../TaxWithBarCodeForm/TaxWithBarCodeFormContainer';
import { BankSlipFgtsContainer } from '../BankSlipFgts/BankSlipFGTSContainer';
import { DarfWithBarCodeContainerForm } from '../DarfWithBarCode/DarfWithBarCodeContainer';
import { DarfBlackContainer } from '../DarfBlack/DarfBlackContainer';
import { GareIcmsContainer } from '../GareIcms/GareIcmsContainer';
import { GareIpvaContainer } from '../GareIpva/GareIpvaContainer';
import { ToastType } from 'contexts/apiRequestContext';
import { SelectFieldComponent } from 'components/Forms/SelectField/SelectField';

interface TaxSlipFormProps {
    onClose: () => void;
    setPaymentOptionSelected: React.Dispatch<React.SetStateAction<string>>;
    paymentOptionSelected: string;
    bankAccountId: string | undefined;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

const TaxSlipForm: React.FC<TaxSlipFormProps> = ({
    onClose,
    setPaymentOptionSelected,
    paymentOptionSelected,
    bankAccountId,
    setToast
}) => {
    const queryClient = useQueryClient();
    async function paymentRefetch() {
        await queryClient.invalidateQueries(['bank-account-payment-list', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-payment-list', bankAccountId]);
    }

    const paymentOptionMap: { [key: string]: React.ReactNode } = {
        'Tributos com código de barras': <TaxWithBarCodeFormContainer setToast={setToast} refetch={paymentRefetch} onCloseDrawer={onClose} />,
        'Boleto FGTS': <BankSlipFgtsContainer refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />,
        'DARF com código de barras': <DarfWithBarCodeContainerForm refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />,
        'DARF sem código de barras': <DarfBlackContainer refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />,
        ICMS: <GareIcmsContainer refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />,
        IPVA: <GareIpvaContainer refetch={paymentRefetch} onCloseDrawer={onClose} setToast={setToast} />,
    };

    return (
        <Stack spacing={2}>
            <Grid>
                <SelectFieldComponent
                    label="Selecione o tipo de pagamento | Tributos"
                    onChange={(_, child: any) => {
                        setPaymentOptionSelected(child?.props?.children);
                    }}
                    items={[
                        'Tributos com código de barras',
                        'Boleto FGTS',
                        'DARF com código de barras',
                        'DARF sem código de barras',
                        'ICMS',
                        'IPVA',
                    ]}
                />
            </Grid>
            {paymentOptionMap[paymentOptionSelected]}
        </Stack>
    );
};

export default TaxSlipForm;
