import moment from "moment";
import { string, object } from "yup";

export function validationCreditNoteRejectSchema() {
  return object().shape({
    message: string().typeError("Motivo é obrigatório.").required("Motivo: precisa ser preenchido"),
    insertBlock: string().nullable(),
    blockUntil: string().typeError("Data de bloqueio preisa ser preenchida.").required("Data de Bloqueio: precisa ser preenchida"),
  });
}
export const defaultValuesCreditNoteReject = {
  message: null,
  insertBlock: null,
  blockUntil: moment(new Date()).format("YYYY-MM-DD") + "T00:00:00",
};