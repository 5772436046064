import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { ComplianceCheckListType, DeleteComplianceChecklistType } from "./types";


const url = "/complianceChecklist";

export const getCheckListVerifyPersonBy = async (personId: string, token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  return await creditAxiosApi.get(`${url}/verifyPerson/${personId}`, config);
}

export const deleteComplianceChecklistByIds = async ({ ids, token }: DeleteComplianceChecklistType) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: { ids }
  };
  return await creditAxiosApi.delete(`${url}`, config);
}; 


export const createComplianceCheckList = async (payloa: ComplianceCheckListType[], token: string) => {
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await creditAxiosApi.post<ComplianceCheckListType>(`${url}`, payloa, config);
}