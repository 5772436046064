import { Drawer, Modal } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import React from 'react';
import { FormProvider } from 'contexts/formContext';
import { useBillingParametersMutation, useDeleteBillingSettingMutation } from 'contexts/wallet/billingParameters/billingParametersContext';
import { ActionRowDataType } from '../BillingSettingListContainer';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { IBillingSettingFull } from 'services/walletManagement/billingSetting/billingSetting.types';
import { defaultValuesAddInterestModelForm, interestModelSchemaValidation } from './BillingSettingForm/AddBillingSettingFormSchema';
import AddBillingSettingForm from './BillingSettingForm/AddBillingSettingForm';


type DrawerBillingSettingFormContainerProps = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    onClose: () => void;
    actionRowData: ActionRowDataType,
    refetch: () => void;
};

const DrawerBillingSettingFormContainer: React.FC<DrawerBillingSettingFormContainerProps> = ({
    setToast,
    onClose,
    actionRowData,
    refetch
}) => {
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });
    const isEdit = actionRowData?.action === 'edit'
    const rowData = actionRowData?.row;
    const { handleErrorException } = useApiRequest();

    const onSuccess = () => {
        const option: {[type:string]: string} = {
            "delete": "Sucesso ao excluir a régua de cobrança", 
            "add": "Régua de cobrança adicionado com sucesso", 
            "edit": "Régua de cobrança editado com sucesso"
        }
        const title =option[actionRowData?.action!];
        showSuccessToast(title, "", setToast);
        onClose();
        refetch();
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)

    const { mutateAsync } = useBillingParametersMutation(onSuccess, onError);
    const { mutateAsync: deleteMutateAsync } = useDeleteBillingSettingMutation(onSuccess, onError)

    const onParametersSubmit = (values: IBillingSettingFull) => {
        const payload = {
            ...values,
            walletCode: isEdit ? rowData?.walletCode : values?.walletCode
        }
        mutateAsync(payload)
    };

    const handleDeleteModelInterestById = () => {
        deleteMutateAsync(rowData?.id);
    }

    const isModeEdit = actionRowData?.action === 'edit';
    const defaultValues = isModeEdit ? rowData : defaultValuesAddInterestModelForm({})

    return (
        <>
            <Drawer
                open={["edit", "add"].includes(actionRowData?.action!)}
                title={isModeEdit ? `(${rowData?.walletCode}) - ${rowData?.name}` : 'Régua de cobrança'}
                onClose={onClose}
                description='Possibilidade de inclusão de juros, multas e descontos.'
                anchor="right"
                children={
                    <FormProvider
                        defaultValues={defaultValues}
                        validationSchema={interestModelSchemaValidation()}
                        onSubmit={onParametersSubmit}
                    >
                        <AddBillingSettingForm
                            onCloseDrawer={onClose}
                            walletCodeAutoCompleteProps={walletCodeAutoCompleteProps}
                            isEdit={isModeEdit}
                        />
                    </FormProvider>
                }
            />

            <Modal
                open={actionRowData?.action === 'delete'}
                title='Excluir régua de cobrança'
                description='Tem certeza que deseja excluir esse modelo?'
                size='small'
                onClose={onClose}
                children={
                    <GenericActionHandler
                        handleSubmit={handleDeleteModelInterestById}
                        onClose={onClose}
                        isModal
                    />
                }
            />
        </>
    );
};
export default DrawerBillingSettingFormContainer;
