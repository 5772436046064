import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesRegisterForm, validationRegisterFormSchema } from './RegisterFormSchema'
import { FieldValues } from 'react-hook-form'
import { RegisterForm } from './RegisterForm'
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types'

type RegisterFormContainerProps = {
    onSubmit: (values: FieldValues) => void;
    onClose: () => void
    installmentData: IInstallmentFull;
    isLoading: boolean
}
export const RegisterFormContainer = ({ onClose, onSubmit, installmentData, isLoading }: RegisterFormContainerProps) => {
    return (
        <FormProvider {...{
            defaultValues: defaultValuesRegisterForm,
            validationSchema: validationRegisterFormSchema(),
            onSubmit,
            children: <RegisterForm
                {...{
                    data: installmentData!,
                    isLoading,
                    onClose
                }}
            />
        }} />)
}
