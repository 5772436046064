import styled from '@emotion/styled';
import { Box } from '@mui/material';

export const DateFiltersBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 8px',
  gap: '4px',
  borderRadius: '4px',
  backgroundColor: '#F7F7F7',
  height: '48px',
  width: 'fit-content'
}));
