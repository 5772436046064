import { Modal, Typography } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { ErrorLogsProps, IOperationsLogs } from './Logs.interface';
import { Stack } from '@mui/material';
import { CustomGrid } from 'components/Custom/CustomMUI';

const ErrorLogs: FunctionComponent<ErrorLogsProps> = ({
    openModalLogs,
    onModalCloseLogs,
    operationsLogs,
}) => {
    const operationsData = operationsLogs?.operations ?? [];

    return (
        <Modal
            open={openModalLogs}
            onClose={onModalCloseLogs}
            title="Log de registro na CERC"
            sizeModal="large"
        >
            <CustomGrid borderColor="#C0C0C0">
                <OperationsSummary operations={operationsData} />
            </CustomGrid>
            <pre>{JSON.stringify(operationsData, null, 2)}</pre>
        </Modal>
    );
};

export default ErrorLogs;

const OperationsSummary: FunctionComponent<IOperationsLogs> = ({ operations }) => {
    const totalOperations = operations.length.toString();
    const successfulOperations = operations.filter((op) => op.sucess).length.toString();
    const errorOperations = operations.filter((op) => op.sucess === false).length.toString();

    return (
        <>
            <Content title="Total de operações:" value={totalOperations} />
            <Content title="Registrados com sucesso:" value={successfulOperations} />
            <Content title="Não registrados:" value={errorOperations} />
        </>
    );
};

type ContentProps = {
    title: string;
    value: string;
};

const Content: FunctionComponent<ContentProps> = ({ title, value }) => {
    return (
        <Stack direction="row" spacing={1} mb={0.5}>
            <Typography variant="xs" fontWeight={700}>
                {title}
            </Typography>
            <Typography variant="xs">{value}</Typography>
        </Stack>
    );
};
