import { NaturalPersonBaseModel } from "./generics";

export class NaturalPersonCreateModel extends NaturalPersonBaseModel {
    registrationNumber!: string;

    constructor(init?: Partial<NaturalPersonCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}
