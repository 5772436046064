import { InferType, boolean, object } from "yup";

export function validationInformationKeyPixSchema() {
  return object().shape({
    saveBeneficiary: boolean().nullable(),
  });
}
const inferedSchema = validationInformationKeyPixSchema()
export type CreatePixType = InferType<typeof inferedSchema>;

export const defaultValuesInformationKeyPix = {
  saveBeneficiary: false
};