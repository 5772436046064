import { DatePickerFormField, SelectFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { confirmationTransactionOptions } from 'services/walletManagement/costs/costs.type';
import { sendSubmitEvent } from 'contexts/apiRequestContext';

function CostsFiltersForm() {
    const { watch } = useFormContext();
    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Data de cobrança', value: 'initialBillingDate' },
                    { label: 'Data de lançamento', value: 'initialCreationDate' },
                    { label: 'Tipo de instrução', value: 'confirmationTransactionType' },
                    { label: 'Ver tarifas cobradas', value: 'hasBillingDate' },
                ]}
            />
            {watch('optionFilter') === 'initialBillingDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data inicial de cobrança"
                        name="initialBillingDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Data final de cobrança"
                        name="finalBillingDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
            {watch('optionFilter') === 'confirmationTransactionType' && (
                <Grid>
                    <SelectFormField
                        label='Tipo de instrução'
                        name='confirmationTransactionType'
                        options={confirmationTransactionOptions}
                        showEndAdornment={true}
                        onClick={sendSubmitEvent} />
                </Grid>
            )}
            {watch('optionFilter') === 'hasBillingDate' && (
                <Grid>
                    <SelectFormField
                        label='Ver tarifas cobradas'
                        name='hasBillingDate'
                        options={[
                            {label: "Sim", value: true}, 
                            {label: "Não", value: false}, 
                        ]}
                        showEndAdornment={true}
                        onClick={sendSubmitEvent} />
                </Grid>
            )}

            {watch('optionFilter') === 'initialCreationDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data inicial de lançamento"
                        name="initialCreationDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Data final de lançamento"
                        name="finalCreationDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

type CostsFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const CostsFilters = ({ currentValues, removeFilterSelected }: CostsFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<CostsFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
