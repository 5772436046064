import { CardData } from "@uy3/web-components";
import { rowDataMock } from "../../rowDataMock";

const CardLimits = () => {
    const mock: any = rowDataMock.map((item: any) => ({
        id: item.id,
        title: item.limits,
        value: item.comercialHours,
    }));

    const cardData: any = [
        {
            title: 'Categoria do limite',
            value: 'Novo limite solicitado',
            uppercase: 'initial',
        },
        ...mock,
    ];

    return <CardData key={mock.id} listItem={cardData} />;
};

export default CardLimits;