import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import { zipCodeMask } from 'utils/constants';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import { UF } from 'services/zipCode/zipcode.types';

export default function AddressForm() {
    const { setValue, watch } = useFormContext();
    const name = watch('name');
    const registrationNumber = watch('registrationNumber');
    
    return (
        <React.Fragment>
            <CardInfo {...{ name, registrationNumber }} />
            <Typography variant="lg" color="common.black">
                Informações de endereço
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <TextFormField
                    name="address.zipCode"
                    id="zipCode"
                    label="CEP"
                    variant="outlined"
                    placeholder="00000-000"
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: zipCodeMask },
                        onChange: (event) => {
                            let keyBoard = event?.currentTarget?.value.replace(/\D/g, '');
                            queryZipCodeAndFillForm(keyBoard, (fieldName, value) => {
                                setValue(`address.${fieldName}`, value);
                            });
                        },
                    }}
                />
                <TextFormField
                    name="address.addressName"
                    fullWidth
                    label="Endereço"
                    variant="outlined"
                    value={watch('address.addressName') ?? ''}
                    InputLabelProps={{
                        shrink: watch('address.addressName') ? true : false,
                    }}
                />
                <TextFormField
                    name="address.city"
                    fullWidth
                    label="Cidade"
                    variant="outlined"
                    value={watch('address.city') ? watch('address.city') : ''}
                    InputLabelProps={{
                        shrink: watch('address.city') ? true : false,
                    }}
                />
                <TextFormField
                    name="address.complement"
                    fullWidth
                    label="Complemento"
                    variant="outlined"
                />
                <SelectFormField
                    name="address.uf"
                    label="UF"
                    options={UF}
                    fullWidth
                    variant="outlined"
                />
                <TextFormField
                    name="address.district"
                    fullWidth
                    label="Bairro"
                    variant="outlined"
                    value={watch('address.district') ? watch('address.district') : ''}
                    InputLabelProps={{
                        shrink: watch('address.district') ? true : false,
                    }}
                />
                <TextFormField name="address.number" fullWidth label="Número" variant="outlined" />
                <SelectFormField
                    label="Tipo de residêcia"
                    name="typeOfResidence"
                    options={[
                        { label: 'Própria', value: 'Owner' },
                        { label: 'Alugada', value: 'Rent' },
                    ]}
                />
            </Stack>
        </React.Fragment>
    );
}
