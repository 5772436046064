import { Drawer, Typography } from '@uy3/web-components';
import { IUploadModel } from 'contexts/apiRequestContext';
import { useEnumContext } from 'contexts/enumContext';
import { FormProvider, useFormContext } from 'contexts/formContext'
import React, { useState } from 'react'
import InformationBankAccountProductTab from './InformationBankAccountProductTab'
import { useParams } from 'react-router';
import Alert from '@mui/material/Alert';
import { UploadFormWrapper } from 'components/Tabs/Uploads/UploadForm/UploadFormWrapper';
import { defaultValuesDocForm, validationSchemaDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';

export const InformationBankAccountProductTabContaienr = () => {
  const { autoCompleteProps: typeOfDocument } = useEnumContext({ enumName: "FileType" });
  const [open, setOpen] = useState(false);
  const { watch, setValue } = useFormContext();
  const { productId } = useParams();
  const templateDoc = watch('templateDoc');

  const setNewUpload = (values: IUploadModel) => {
    setValue("templateDoc", values);
    setOpen(false);
  };

  const defaultFormValue = templateDoc ?? defaultValuesDocForm;

  return (
    <>
      <Drawer anchor='right' title="Arquivo de Cessão" open={open} onClose={() => setOpen(false)}>
        <FormProvider
          validationSchema={validationSchemaDocForm()}
          defaultValues={defaultFormValue}
          onSubmit={setNewUpload}>
          <UploadFormWrapper
            options={typeOfDocument.listOptions}
            accept='.docx'
            onClose={() => setOpen(false)}
            currentValues={templateDoc}
          />
        </FormProvider>
        <Alert
          sx={{
            mt: 4,
            borderRadius: 2,
            alignItems: 'center'
          }}
          severity="warning">
          <Typography
            variant="md"
            sx={{
              whiteSpace: 'normal',
              wordBreak: 'break-all'
            }}>
            {templateDoc?.fileName ? <p>Atenção, o documento<strong>&nbsp;{templateDoc.fileName}&nbsp;</strong>
              será atualizado!</p> : <>Carregando novo documento.</>}
          </Typography>
        </Alert>
      </Drawer>

      <InformationBankAccountProductTab
        handleOpen={() => setOpen(true)}
        id={productId!}
      />
    </>
  )
}
