import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import { Button, CardData, CloseIcon, DownloadIcon, Item, ShowIcon, Typography } from '@uy3/web-components';
import { UploadGet } from 'contexts/apiRequestContext';
import { formatWitTenant } from 'helpers/formats/StringFormats';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import React from 'react'
import { activeTheme } from 'services/theme';
import { useTenant } from 'contexts/tenantContext';

type BankAccountDetailsProps = {
  rowData: any;
  onClose: () => void;
  showLimit: () => void;
}

const buttonSx = { height: 20, width: 20, ml: 0.5, mr: 0.5 };

const theme = activeTheme();

export const BankAccountDetails: React.FC<BankAccountDetailsProps> = ({ rowData, onClose, showLimit }) => {
  const { isRootTenancy } = useTenant();
  const isBankAccountLimits = rowData?.bankAccountTransactionLimits !== null;
  const bankAccount = rowData?.bankAccountTransactionLimits?.bankAccount;

  const uploads: UploadGet[] = rowData?.bankAccountTransactionLimits?.uploads ?? [];

  const uploadToShow = (): Item[] => uploads.map(file => {
    return {
      id: file.id,
      title: file.displayName,
      value: (
        <IconButton href={file.tempGetUrl} target="_blank">
          <DownloadIcon sx={buttonSx} />
        </IconButton>
      )
    }
  });

  const listData = [
    {
      id: '1',
      title: 'Tipo da solicitação',
      value: `${rowData?.approvalTypeDisplay ?? 'N/D'}`
    },
    {
      id: '2',
      title: 'Criado por',
      value: `${formatWitTenant(rowData?.createdBy, isRootTenancy) ?? 'N/D'}`
    },
    {
      id: '3',
      title: 'Data da criação',
      value: `${new Date(rowData?.createdAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  ]

  if (!!rowData?.updatedBy) listData.push(
    {
      id: '4',
      title: 'Atualizado por',
      value: `${formatWitTenant(rowData?.updatedBy, isRootTenancy) ?? 'N/D'}`
    },
    {
      id: '5',
      title: 'Data da atualização',
      value: `${new Date(rowData?.updatedAt).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })}`
    }
  )

  return (
    <Stack spacing={2}>
      <Typography
        variant="h5"
        color="neutral.dark"
        fontStyle="normal"
        fontWeight="700"
        fontSize="19px"
      >
        Informações do solicitante
      </Typography>
      <CardData
        listItem={listData}
      />

      {isBankAccountLimits &&
        <>
          <Typography
            variant="h5"
            color="neutral.dark"
            fontStyle="normal"
            fontWeight="700"
            fontSize="19px"
          >
            Informações da conta
          </Typography>
          <CardData
            listItem={[
              {
                id: '1',
                title: 'Nome',
                value: `${bankAccount?.person?.name ?? 'N/D'}`
              },
              {
                id: '2',
                title: 'CNPJ/CPF',
                value: formatDocumentNumber(bankAccount?.person?.registrationNumber)
              },
              {
                id: '3',
                title: 'Tipo de conta',
                value: bankAccount?.typeDisplay
              },
              {
                id: '4',
                title: 'Modalidade',
                value: bankAccount?.modalityDisplay
              },
            ]}
          />

          {uploadToShow().length > 0 &&
            <>
              <Typography
                variant="h5"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="19px"
              >
                Arquivos enviados
              </Typography>
              <CardData listItem={uploadToShow()} />
            </>}


        </>}

      {rowData?.bankAccount && <>
        <Typography
          variant="h5"
          color="neutral.dark"
          fontStyle="normal"
          fontWeight="700"
          fontSize="19px"
        >
          Informações da conta
        </Typography>
        <CardData
          listItem={[
            {
              id: '1',
              title: 'Nome',
              value: `${rowData?.bankAccount?.person?.name ?? 'N/D'}`
            },
            {
              id: '2',
              title: 'Número da conta',
              value: `${rowData?.bankAccount?.account ?? 'N/D'}`
            },
            {
              id: '3',
              title: 'CPF/CNPJ',
              value: `${formatDocumentNumber(rowData?.bankAccount?.person?.registrationNumber) ?? 'N/D'}`
            }
          ]}
        />
      </>}

      <Stack spacing={2} justifyContent='right' direction='row'>
        <Button
          variant='outlined'
          onClick={onClose}
          startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}>
          Fechar
        </Button>
        {isBankAccountLimits && <Button
          variant='contained'
          onClick={showLimit}
          startIcon={<ShowIcon htmlColor={theme.palette.common.white} />}>
          Ver limites solicitados
        </Button>}
      </Stack>
    </Stack>
  )
};
