import { string, object, InferType } from 'yup';

export function validationSchemaRequiredDocumentsForm() {
    return object().shape({
        group: string().typeError('Agrupamento inválido.').required("Agrupamento: Precisa ser preenchido."),
        personType: string().typeError('Tipo de Pessoa inválida.').required("Tipo de Pessoa: Precisa ser preenchido."),
        fileType: string().typeError('Tipo de Documento inválido.').required("Tipo do Documento: Precisa ser preenchido.")
    });
};

let inferedSchema = validationSchemaRequiredDocumentsForm();
export type RequiredDocumentsFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesRequiredDocumentsForm = {
    group: null,
    personType: null,
    fileType: ""
};