import { ApproveIcon, Button, Drawer, Offline, RejectIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { AxiosResponse } from 'axios';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { defaultValuesConfirmMfaForm, validationConfirmMfaFormSchema } from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import { RefreshProgress } from 'components/RefreshProgress';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useBankAccountOperatorsPeding } from 'contexts/bankAccount/bankAccountContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { useState } from 'react';
import BankAccountOperator from './BankAccountOperator';
import { Grid, Stack } from '@mui/material';
import { useBankAccountOperatorsMutation } from 'contexts/bankAccount/bankAccountOperators';
import { FilterProvider } from 'contexts/filterContext';
import { pendingApprovalsLevelOptions, typeAccountOperator } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { OperatorsPedingApprove } from 'services/accounts/bankAccount';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { BankAccountOperatorDetails } from './BankAccountOperatorDetails';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { isEmpty } from 'lodash';

export const BankAccountOperatorContainer = () => {
    const [rowsSelected, setRowsSelected] = useState<string[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [rejectOrApprove, setRejectOrApprove] = useState<"approve" | "reject">();
    const [rowData, setRowData] = useState<{ type: string, data: any } | null>(null);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [page, setPage] = useState(0);
    const { userSubId } = useIdentity();
    const operatorData = rowData?.data?.row;
    const { data: operatorsPedingData, status: operatorsPedingStatus, isFetching, isLoading, refetch } = useBankAccountOperatorsPeding({ page, size: rowsPerPage });
    const { setSubmitError } = useApiRequest();

    const hasRowsSelected = rowsSelected?.length > 0

    const onClose = () => {
        setSubmitError(undefined);
        setRowData(null);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    let successMessage = `Operador ${rejectOrApprove === "approve" ? "aprovado" : "reprovado"} com sucesso!`;
    const onSuccess = () => {
        refetch();
        onClose();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const onChangePage = (page: number) => setPage(page);

    const { mutateGenerateSessionId, isLoading: isLoadingSession } = useGenerateSessionIdMutation();
    const { mutate: mutateOperators, isLoading: isLoafingOperators } = useBankAccountOperatorsMutation(onSuccess, onError);

    const operatorIds = hasRowsSelected ? rowsSelected : [operatorData?.id!]

    const onSubmitApprovePendingOperator = async (values: IMFAState) => {
        mutateGenerateSessionId({
            userPassword: values.password, then: (response: AxiosResponse<string, any>) => {
                setSubmitError(undefined);
                let approveOperatorpayload: OperatorsPedingApprove = {
                    action: "Approve",
                    sessionId: response.data,
                    code: values.code,
                    operatorIds
                };
                mutateOperators(approveOperatorpayload);
            }
        })
    };

    const onSubmitRejectPendingOperator = async (values: IMFAState) => {
        mutateGenerateSessionId({
            userPassword: values.password, then: (response: AxiosResponse<string, any>) => {
                setSubmitError(undefined);
                let rejectOperatorPayload: OperatorsPedingApprove = {
                    action: "Reject",
                    sessionId: response.data,
                    code: values.code,
                    operatorIds
                }
                mutateOperators(rejectOperatorPayload);
            }
        })
    };

    const approveOrRejectList = (mode: "approve" | "reject") => {
        setRejectOrApprove(mode);
        setRowData({ data: null, type: mode })
    }

    const isIncludeSubIdInApprovals = (approvals: any[]) => {
        if (approvals?.length > 0) {
            const findUser = approvals?.find((x: any) => x?.user?.userId === userSubId);
            if (findUser !== null && !isEmpty(findUser)) return true;
        }
        return false
    }


    if (operatorsPedingStatus === "error" || operatorsPedingData?.type === 'error')
        return <Offline highlightedText='operadores da conta' />

    const someElementHasApprovedBySubUserId = operatorsPedingData?.data
        ?.filter((x: any) => rowsSelected?.includes(x?.id))
        ?.some((x: any) => isIncludeSubIdInApprovals(x?.approvals));

    const onRowModesModelChange = (rows: string[]) => {
        setRowsSelected(rows);
        const lastElementSelected = rows[rows?.length -1]; 
        const data =  operatorsPedingData?.data?.find((x: any) => x?.id === lastElementSelected); 
        if (isIncludeSubIdInApprovals(data?.approvals)) {
            setToast({
                open: true, 
                severity: 'info', 
                title: 'Ops!', 
                description: `O ${rows?.length > 1 ? 'último' : ''} registro selecionado já foi avaliado.`
            })
        }
    }

    const mfaSchema = validationConfirmMfaFormSchema();
    const mfaDefaultValues = defaultValuesConfirmMfaForm;

    return (
        <ErrorBoundary fallback='operadores da conta'>
            <Toast toast={toast} setToast={setToast} />
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="32px"
                    lineHeight="38.4px"
                >
                    Operadores
                </Typography>
                <Grid xs="auto" sm="auto" md="auto" mr={3}>
                    {rowsSelected.length > 0 && !someElementHasApprovedBySubUserId && <Button
                        sx={{ color: 'black' }}
                        variant="text"
                        onClick={() => approveOrRejectList('approve')}
                        startIcon={<ApproveIcon htmlColor='black' />}
                        color="primary"
                    >
                        Aprovar
                    </Button>}
                    {rowsSelected.length > 0 && !someElementHasApprovedBySubUserId && <Button
                        sx={{ color: 'black' }}
                        variant="text"
                        onClick={() => approveOrRejectList('reject')}
                        startIcon={<RejectIcon htmlColor='black' />}
                        color="primary"
                    >
                        Reprovar
                    </Button>}
                    <Button
                        sx={{ color: 'black' }}
                        variant="text"
                        size="large"
                        disabled={isFetching}
                        onClick={() => refetch()}
                        startIcon={<UpdateIcon htmlColor={isFetching ? 'grey' : 'black'} />}
                        color="primary">
                        Atualizar
                    </Button>
                </Grid>
            </Stack>
            <RefreshProgress refreshing={isLoadingSession || isLoafingOperators || (isFetching && !isLoading)} />
            <FilterProvider availableFilters={{
                accountNumber: {
                    label: "Número da conta",
                    type: "text"
                },
                typeAccount: {
                    label: "Tipo de conta",
                    multiple: false,
                    type: "select",
                    options: typeAccountOperator
                },
                f_level: {
                    label: "Nível",
                    multiple: false,
                    type: "select",
                    options: pendingApprovalsLevelOptions
                }
            }}>
                <ListSkeleton
                    isLoading={isLoading}
                >
                    <BankAccountOperator
                        setRowData={setRowData}
                        setRejectOrApprove={setRejectOrApprove}
                        page={page}
                        queryData={operatorsPedingData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        rowsSelected={rowsSelected ?? []}
                        isIncludeSubIdInApprovals={isIncludeSubIdInApprovals}
                        onRowModesModelChange={onRowModesModelChange}
                    />
                </ListSkeleton>
            </FilterProvider>
            <Drawer
                anchor='right'
                title={rejectOrApprove === 'approve' ? 'Aprovar operador' : 'Rejeitar operador'}
                open={rowData !== null}
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={mfaSchema}
                    defaultValues={mfaDefaultValues}
                    onSubmit={rejectOrApprove === 'approve' ?
                        onSubmitApprovePendingOperator : onSubmitRejectPendingOperator
                    }
                >
                    <ConfirmMfaForm
                        onClose={onClose}
                    />
                </FormProvider>
            </Drawer>
            <Drawer
                anchor='right'
                title='Detalhes'
                open={rowData?.type === 'details'}
                onClose={onClose}
            >
                <BankAccountOperatorDetails onClose={onClose} detailsData={operatorData} />
            </Drawer>
        </ErrorBoundary>
    )
}
