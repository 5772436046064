import React from 'react'
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip'
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions'

export const CreditProductActions = () => {
    return (
        <React.Fragment>
            <ActionsPermissions
                PermissionRootTenancy={<PermissionResourceCreditProductRootTenancy />}
            />
        </React.Fragment>
    )
}


const PermissionResourceCreditProductRootTenancy = () => {
    return (
        <CheckboxWithTooltip
            checkboxFormField={{
                label: 'Incluir beneficiário no produto de crédito',
                name: 'actions.IncludeBeneficiaryProduct'
            }}
            helpMessage="Permite o usuário incluir um beneficiário como envolvido no produto de crédito."
        />
    )
}