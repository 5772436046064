/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from 'react';
import { activeConfig, loadConfig } from 'services/config';
import { RefreshProgress } from 'components/RefreshProgress';
import { Config } from 'services/config/config.interface';
import { isEqual } from 'lodash';
import { useIdentity } from './identityContext';
interface AppConfigProviderProps {
    children: JSX.Element;
};

interface AppConfigContextProps {
    appConfig: Config;
    setAppConfig: (config: Config) => void;
};

const AppConfigContext = createContext<AppConfigContextProps>({
    appConfig: {} as Config,
    setAppConfig: () => { }
});

const storesPreviousUrl = () => {
    const url = window.document.location.pathname.toLowerCase();
    const regex = /\/login|redirect/;

    if (!regex.test(url)) {
        localStorage.setItem('urlFromRedirect', url);
    }
}

export const AppConfigProvider = ({ children }: AppConfigProviderProps) => {
    var currentConfig = activeConfig();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [appConfig, setAppConfig] = useState<Config>(currentConfig);

    const { isAuthenticated } = useIdentity();

    useEffect(() => {
        const domain = window.document.location.hostname;

        const checkAndLoadConfig = async () => {
            const currentConfig = activeConfig();
            const newConfig = await loadConfig(domain);
            if (!isEqual(currentConfig, newConfig)) {
                window.location.reload();
            } else {
                setAppConfig(newConfig);
                setTimeout(() => setLoaded(true), 500);
            }
        };

        if (!isAuthenticated()) {
            storesPreviousUrl();
        }

        checkAndLoadConfig();
    }, []);

    return (
        <AppConfigContext.Provider
            value={{
                appConfig,
                setAppConfig,
            }}>
            {loaded ? children : <RefreshProgress refreshing={true} />}
        </AppConfigContext.Provider>
    );
};

export function useAppConfig(): AppConfigContextProps {
    const context = useContext(AppConfigContext);
    const { appConfig, setAppConfig } = context;

    return {
        appConfig,
        setAppConfig,
    };
}