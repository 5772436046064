import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { iconSx } from 'contexts/apiRequestContext';
import { blackListOptions } from 'contexts/creditNote/creditNoteOptions';
import { useFormContext } from 'contexts/formContext';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { activeTheme } from 'services/theme';

type BlackListProps = {
    data: NaturalPersonReadModel | LegalPersonReadModel;
    onClose: () => void;
    isLoading: boolean;
};

const theme = activeTheme();

export const BlackList = ({ data, onClose, isLoading }: BlackListProps) => {
    const { watch } = useFormContext();

    const ispbSelected = watch('complianceChecklist').includes('ispb');
    const bankAccounts: SelectOption[] =
        data?.bankAccounts
            .filter((i) => i?.operationTypeValue === 'Transfer')
            .map((i) => ({ label: i?.bankCodeDisplay, value: i?.bankIspb })) ?? [];

    return (
        <Stack spacing={2} mt={2}>
            <SelectFormField
                label="Incluir na Blacklist"
                name="complianceChecklist"
                required
                multiple
                options={blackListOptions(data)}
            />

            {ispbSelected && (
                <SelectFormField label="ISPB" name="ispb" required options={bankAccounts} />
            )}

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    name="btn-close-mfa"
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm-mfa"
                    type="submit"
                    variant="contained"
                    disabled={isLoading}
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    sx={{ justifyContent: 'center' }}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
