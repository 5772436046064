/* eslint-disable react-hooks/exhaustive-deps */
import { Grid } from '@mui/material';
import {
    AddIcon,
    Button,
    EditIcon,
    ChangePlanIcon,
    Alert,
    DeleteIcon,
    SaveIcon,
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useAppConfig } from 'contexts/appConfig';
import moment from 'moment';
import { RemovePrintMode } from 'pages/CreditNote/Simulation/SimulationForm';
import { Dispatch, SetStateAction, useState, FC, useEffect } from 'react';
import { PaymentScheduleItemReadModel  } from 'services/creditNote/types/creditNoteReadModel';
import { activeTheme } from 'services/theme';
import ImportSpreadsheetDrawer from './ImportSpreadsheet';
import { useFormContext } from 'contexts/formContext';
import SyncIcon from '@mui/icons-material/Sync';
import { useCreditData } from 'contexts/creditNote/creditContext';
import { useParams } from 'react-router';

interface IButtonManualCalculationProps {
    currentPaymentScheduleItems: PaymentScheduleItemReadModel [] | undefined;
    setCurrentPaymentScheduleItems: Dispatch<SetStateAction<PaymentScheduleItemReadModel [] | undefined>>;
    rowIndex: number;
    setRowIndex: Dispatch<SetStateAction<number>>;
    msgError: string | undefined;
    setMsgError: Dispatch<SetStateAction<string | undefined>>;
    isEditing: boolean;
    setIsEditing: Dispatch<SetStateAction<boolean>>;
    setCustomizedPaymentPlan: Dispatch<SetStateAction<boolean>>;
}

const theme = activeTheme();
const ButtonManualCalculation: FC<IButtonManualCalculationProps> = ({
    currentPaymentScheduleItems,
    setCurrentPaymentScheduleItems,
    isEditing,
    setIsEditing,
    rowIndex,
    setRowIndex,
    msgError,
    setMsgError,
    setCustomizedPaymentPlan
}) => {
    const { setValue, getValues, watch } = useFormContext();
    const { appConfig } = useAppConfig();
    const [showAlert, setShowAlert] = useState(false);
    const { submitting } = useApiRequest();
    const [openDrawer, setOpenDrawer] = useState(false);
    const lastSimulation = watch("createSimulation");
    const { id } = useParams<{ id: string | undefined }>();
    const { creditData } = useCreditData(id ?? 'nova');

    useEffect(() => {
        console.log("Buttons - Manual calc - Simulation changed!");
        setCurrentPaymentScheduleItems(undefined);
        setShowAlert(false);
        setIsEditing(false);
        setCustomizedPaymentPlan(false);
        setRowIndex(-1);
    }, [lastSimulation])

    const useManualCalculation = appConfig.USE_MANUAL_CALCULATION === 1;
    const color = !submitting ? theme.palette.common.black : theme.palette?.grey['400'];
    const handlerEditPayment = () => {
        setRowIndex(-1);
        setMsgError(undefined);
        setShowAlert(false);
        setValue('amortization.calculateByValueType', 'Gross');
        setIsEditing(true);    
        setCustomizedPaymentPlan(true);   
        let paymentScheduleItemsUnderEditCopy = [] as PaymentScheduleItemReadModel [];
        if (currentPaymentScheduleItems === undefined || currentPaymentScheduleItems.length === 0) {
            const addPaymentToSchedule = (paymentScheduleItemsUnderEditCopy: PaymentScheduleItemReadModel [], newPayment: Omit<PaymentScheduleItemReadModel , 'id' | 'tokenName' | 'tokenAmount'>) => {
                paymentScheduleItemsUnderEditCopy?.push(newPayment as PaymentScheduleItemReadModel );
            };
            const termInMonths = getValues('amortization.termInMonths');
            const initialDate = moment();

            // Qual são as parcelas base para iniciar a edição?
            //  Se foi realizada simulação, o ultimo plano simulado
            //  Se não, o ultima operação carregada
            //  Se não, inicializa parcelas zeradas = o prazo solicitado da operação
            if (lastSimulation) {
                paymentScheduleItemsUnderEditCopy = [...lastSimulation.paymentScheduleItems];
            } else if (creditData) {
                paymentScheduleItemsUnderEditCopy = [...creditData.paymentScheduleItems];
            } else {
                for (let i = 0; i <= termInMonths; i++) {
                    const newPayment = {
                        dueDate: initialDate.clone().add(i, 'months').format('YYYY-MM-DDTHH:mm:ssZ'),
                        principalAmountInCents: 0,
                        amortization: 0,
                        interest: 0,
                        payment: 0,
                        financeTax: 0, 
                        accruedInterest: 0, 
                        addedFixedCosts: 0
                    };
                    addPaymentToSchedule(paymentScheduleItemsUnderEditCopy, newPayment);
                };
            }
            setCurrentPaymentScheduleItems(paymentScheduleItemsUnderEditCopy);
        }
    };

    const handleAddParcel = () => {
        let lastDueDate = null;
        if (currentPaymentScheduleItems!.length > 0) {
            const lastParcel = currentPaymentScheduleItems![currentPaymentScheduleItems!.length - 1];
            lastDueDate = lastParcel.dueDate;
        }

        const newDueDateSubsequent = moment(lastDueDate)
            .add(1, 'month')
            .format('YYYY-MM-DDTHH:mm:ssZ');
        currentPaymentScheduleItems!.push({
            id: undefined,
            dueDate: newDueDateSubsequent,
            principalAmountInCents: 0,
            amortization: 0,
            interest: 0,
            payment: 0,
            financeTax: 0,
            accruedInterest: 0, 
            addedFixedCosts: 0
        });
        setRowIndex(currentPaymentScheduleItems!.length - 1);
        setShowAlert(false);
        setValue('amortization.termInMonths', currentPaymentScheduleItems!.length - 1);
    };

    const handleRemove = () => {
        currentPaymentScheduleItems!.splice(rowIndex, 1);
        setRowIndex(currentPaymentScheduleItems!.length - 1);
        setShowAlert(false);
        setValue('amortization.termInMonths', currentPaymentScheduleItems!.length - 1);
    };

    const changeHandlerCompleted = () => {
        setRowIndex(-1);
        setShowAlert(true);
        setMsgError(undefined);
        setIsEditing(false);
        setValue('paymentScheduleItems', currentPaymentScheduleItems);
        if (currentPaymentScheduleItems && currentPaymentScheduleItems.length > 0) {
            setValue('amortization.startDate', currentPaymentScheduleItems[0].dueDate);
        }
    };

    const handleUndoChanges = () => {
        setMsgError(undefined);
        setShowAlert(false);
        setIsEditing(false);
        setCustomizedPaymentPlan(false);
        setCurrentPaymentScheduleItems([]);
        setValue('paymentScheduleItems', null);
    };

    const handleImportSpreadsheet = () => setOpenDrawer(true);
    const onClose = () => setOpenDrawer(false);    
    return (
        <>
            <ImportSpreadsheetDrawer openDrawer={openDrawer} onClose={onClose} />
            {useManualCalculation && (
                <RemovePrintMode>
                    <Grid item justifyContent="flex-end" display="flex" my={1}>
                        {currentPaymentScheduleItems !== undefined && currentPaymentScheduleItems.length > 0 && (
                            <Button
                                variant="text"
                                size="small"
                                sx={{ color }}
                                startIcon={
                                    <SyncIcon htmlColor={color} sx={{ height: 23, width: 23 }} />
                                }
                                onClick={handleUndoChanges}
                            >
                                Desfazer alterações
                            </Button>
                        )}
                        {!isEditing && (
                            <Button
                                disabled={submitting}
                                variant="text"
                                size="small"
                                sx={{ color }}
                                onClick={handlerEditPayment}
                                startIcon={<EditIcon htmlColor={color} sx={iconSx} />}
                            >
                                Incluir/alterar parcelas
                            </Button>
                        )}
                        {isEditing && (
                            <>
                                <Button
                                    variant="text"
                                    size="small"
                                    sx={{ color }}
                                    startIcon={<SaveIcon htmlColor={color} sx={iconSx} />}
                                    onClick={changeHandlerCompleted}
                                >
                                    Concluir alterações
                                </Button>

                                <Actions
                                    numberOfButtons={0}
                                    buttonsActionsList={[
                                        {
                                            disabled: true,
                                            enable: true,
                                            label: 'Importar planilha',
                                            action: handleImportSpreadsheet,
                                            icon: <ChangePlanIcon htmlColor={color} sx={iconSx} />,
                                        },
                                        {
                                            enable: true,
                                            label: 'Adicionar parcela',
                                            action: handleAddParcel,
                                            icon: <AddIcon htmlColor={color} sx={iconSx} />,
                                        },
                                        {
                                            enable: true,
                                            label: 'Remover parcela',
                                            action: handleRemove,
                                            icon: <DeleteIcon htmlColor={color} sx={iconSx} />,
                                        },
                                    ]}
                                />
                            </>
                        )}
                    </Grid>
                </RemovePrintMode>
            )}
            <Grid item xs={12}>
                {(showAlert || msgError) && (
                    <Alert
                        severity="info"
                        variant="filled"
                        text={
                            msgError
                                ? msgError
                                : 'Atenção! Após concluir as alterações das parcelas, não esqueça de salvar o formulário.'
                        }
                    />
                )}
            </Grid>
        </>
    );
};

export default ButtonManualCalculation;
