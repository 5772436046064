import { string, object } from 'yup';

export function validationNaturalPersonAndLegalPersonFilterSchemaSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        personDisplay: string().when("optionFilter", {
            is: "personDisplay",
            then: string().required('Nome precisa ser preenchido.').typeError('Nome: precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        registrationNumber: string().when("optionFilter", {
            is: "registrationNumber",
            then: string().typeError('CPF/CNPJ precisa ser preenchido.').required('CPF/CNPJ: Precisa ser preenchido.').cpfCnpjValidation("CPF ou CNPJ inválido."),
            otherwise: string().nullable()
        }),
        email: string().when("optionFilter", {
            is: "email",
            then: string().typeError('Tomador precisa ser preenchido.').required('Tomador: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        companyName: string().when("optionFilter", {
            is: "companyName",
            then: string().typeError('Razão social precisa ser preenchido.').required('Razão social: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        tenant: string().when("optionFilter", {
            is: "tenant",
            then: string().typeError('Correspondente precisa ser preenchido.').required('Correspondente: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        name: string().when("optionFilter", {
            is: "name",
            then: string().typeError('Nome fantasia precisa ser preenchido.').required('Nome fantasia: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string()
                .typeError('Data inicial do cadastro precisa ser preenchido.').required('Data inicial do cadastro: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().typeError('Data final do cadastro precisa ser preenchido.').required('Data final do cadastro: Precisa ser preenchido.'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesNaturaPersonAndLegalPersonFilterSchema = {
    optionFilter: 'searchString',
    filterByOwnerGroup: null,
    filterByOwner: null,
    companyName: null,
    responsible: null,
    tenant: null,
    searchString: null,
    personDisplay: null,
    registrationNumber: null,
    email: null,
    creditNoteNo: null,
    initialDate: new Date(),
    finalDate: new Date(),
};