import { Paper } from '@mui/material';
import { Button } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { UploadNew } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useUploadMutation } from 'contexts/uploadContext';
import React from 'react';

interface UploadDropProps {
    accept: string;
    currentValues?: any;
    isMultiple?: boolean;
    setFiles?: React.Dispatch<React.SetStateAction<any[]>>;
    isDrawerAddPerson?: boolean;
}

const UploadDropzone: React.FC<UploadDropProps> = ({
    accept,
    currentValues,
    isMultiple = false,
    setFiles,
    isDrawerAddPerson
}) => {
    const { setValue } = useFormContext();

    const { mutate, isLoading } = useUploadMutation(
        currentValues,
        (data: UploadNew, formValue: File) => {
            const fileName = data.filename;
            const fileDisplayName = formValue.name;
            const tempGetUrl = data.getUrl;

            setValue('fileName', fileName);
            setValue('displayName', fileDisplayName);
            setValue('tempGetUrl', tempGetUrl);
            setFiles && setFiles((prev) => [...prev, {
                id: null,
                fileTypeDisplay: null,
                displayName: fileDisplayName,
                fileName,
                tempUrl: tempGetUrl,
            }]);
        }
    );

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => event.preventDefault();

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        const files = event.dataTransfer?.files;
        if (files && files.length > 0) {
            if (isMultiple) {
                const filesArray = Array.from(files);
                filesArray.forEach((file) => mutate(file));
                return;
            }

            const file = files[0];
            mutate(file);
        }
    };

    return (
        <>
            <RefreshProgress refreshing={isLoading} />
            <Paper sx={baseStyle} onDragOver={handleDragOver} onDrop={handleDrop}>
                <Button variant="text" fullWidth component="label" size={isDrawerAddPerson ? 'medium' : 'large'} name='btn-dropzone-input'>
                    Clique aqui para fazer o carregamento dos arquivos
                    <input
                        name='dropzone-input'
                        hidden
                        multiple={isMultiple}
                        type="file"
                        accept={accept}
                        onChange={(e) => {
                            const file = e.target.files?.item(0);
                            if (file) mutate(file);
                        }}
                    />
                </Button>
            </Paper>
        </>
    );
};

export default UploadDropzone;

export const baseStyle = {
    cursor: 'pointer',
    display: 'block',
    padding: '20px',
    border: '1px dashed #D04D27',
    '&:hover': { border: '1px solid #ccc' },
    boxShadow: '0px 8px 32px rgba(186, 191, 208, 0.32)',
    borderRadius: '8px',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
};
