import { WarrantyType } from './generics';

export class HomeEquityCreateModel {
    warrantyType: WarrantyType = 'HomeEquity';
    registryOffice?: string; // Cartório de Registro do Imóvel
    registrationNumber?: string; // Número de Matrícula do Imóvel
    width!: number; // Largura do Imóvel (double)
    length!: number; // Comprimento do Imóvel (double)
    addressName?: string;
    zipCodeAddress?: string;
    city?: string;
    uf?: string;
    district?: string;
    addressNumber?: string;
    complementAddress?: string;
    propertyFeatures?: string; // Características do Imóvel

    constructor(obj?: Partial<HomeEquityCreateModel>) {
        Object.assign(this, obj);
    }

    description?() {
        return `Rua ${this.addressName}, ${this.addressNumber} ${this.complementAddress} - ${this.district} - CEP ${this.zipCodeAddress} / ${this.uf}. Registrado em ${this.registryOffice} sob matrícula ${this.registrationNumber}. Dimensões: ${this.width}x${this.length} ${this.propertyFeatures}`;
    }

    title?() {
        return `Imóvel`;
    }
}
