import DynamicAccordion from "components/Datasets/Accordion/Accordion";
import { useFormContext } from "contexts/formContext";
import { useParams } from "react-router";
import { uniqBy } from "lodash";
import { Typography } from "@uy3/web-components";
import { DatasetDetailsCredit } from "components/RiskScore/DatasetDetailsCredit/DatasetDetailsCredit";
import { useDatasetData, useDatasetMutation } from "contexts/datasetContext";
import { ComplianceOrCreditAnalysisGet } from "services/datasets/datasets.interface";
import { ApiResponseError, useApiRequest } from "contexts/apiRequestContext";
import DatasetWrapper from "components/Datasets/DatasetWrapper";
import { useAppConfig } from "contexts/appConfig";
import { CreditTabContainer } from "components/Tabs/CreditTab/CreditTabContainer";
import InfoMessage from "components/InfoMessage/InfoMessage";
import { useUserPermissionData } from "contexts/userContext";
import { checkActionsPerms } from "services/permissions";
import GenericErrorBoundary from "components/Errors/ErrorBoundary/GenericErrorBoundary";

export const CreditNoteTabContainer = () => {
    const { id } = useParams();
    const { watch } = useFormContext();
    const { setSubmitError } = useApiRequest();
    const { appConfig } = useAppConfig();
    const useCreditAnalysis = !!appConfig.USE_CREDIT_ANALYSIS_DATASET;
    const recordType = "CreditNote";
    const { dataset, status, error, refetch } = useDatasetData(recordType, id!, "creditanalysis");
    const { data: permissionsData } = useUserPermissionData();
    const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, 'CreditNote');

    const data = dataset?.find(dataset => dataset?.dataSet === "credit_analysis") as ComplianceOrCreditAnalysisGet;
    const result = data?.result;

    const onSuccess = () => refetch();
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const person = {
        personDiscriminator: watch("discriminator") ?? watch("personDiscriminator"),
        personId: watch("personId"),
        personIdDisplay: watch("personIdDisplay") ?? watch("personDisplay")
    };

    const involveds = watch("relatedPersonCreditNote") ?? [];
    const filterInvolvedPersons = involveds?.filter(
        (value: { typeOfRelationship: string }) => value.typeOfRelationship !== "Witness"
    );

    const naturalPersons = uniqBy(
        [
            ...(filterInvolvedPersons || []).filter(
                (i: any) => (i.personDiscriminator || i.discriminator) === "NaturalPerson"
            ),
            person?.personDiscriminator === "NaturalPerson" && {
                personId: person?.personId,
                personIdDisplay: person?.personIdDisplay
            }
        ].filter(x => x !== undefined || x !== false),
        i => i?.personId
    );

    const legalPersons = uniqBy(
        [
            ...(filterInvolvedPersons || []).filter((i: any) => (i.personDiscriminator || i.discriminator) === "LegalPerson"),
            person?.personDiscriminator === "LegalPerson" && {
                personId: person?.personId,
                personIdDisplay: person?.personIdDisplay
            }
        ].filter(x => x !== undefined || x !== false),
        i => i?.personId
    );

    const accordionAnalysis = [
        {
            title: "Motor de Crédito",
            content: <>
                {hasPermissionToReadDataset ?
                    <DatasetWrapper dataSet={data} handleUpdateData={() => handleUpdateData("creditanalysis")} isLoading={isLoading} >
                        <DatasetDetailsCredit dataDetails={result ?? []} />
                    </DatasetWrapper> : <InfoMessage />}
            </>
        }
    ];

    const accordionLegalPersons = legalPersons?.map(person => ({
        title: person?.personIdDisplay,
        content: <CreditTabContainer personId={person?.personId} discriminator='LegalPerson' />
    })).filter(x => x.title !== undefined);


    const accordionNaturalPerson = naturalPersons?.map(person => ({
        title: person?.personIdDisplay,
        content: <CreditTabContainer personId={person?.personId} discriminator='NaturalPerson' />
    })).filter(x => x.title !== undefined);

    return (
        <GenericErrorBoundary status={status} error={error} fallback="operação">
            {useCreditAnalysis && <DynamicAccordion accordion={accordionAnalysis} />}

            {legalPersons?.length > 0 && <>
                <Typography variant="h5" color="neutral.medium" fontWeight="bold" mt={4} mb={2}>Pessoa Jurídica</Typography>
                <DynamicAccordion accordion={accordionLegalPersons} />
            </>}

            {naturalPersons?.length > 0 && <>
                <Typography variant="h5" color="neutral.medium" fontWeight="bold" mt={4} mb={2}>Pessoa Física</Typography>
                <DynamicAccordion accordion={accordionNaturalPerson} />
            </>}

        </GenericErrorBoundary>
    );

};