import { string, object } from 'yup';

export function validationSchemaQueryData(resource: string) {
    const documentNumber = resource === "LegalPerson" ? "CNPJ" : "CPF";
    return object().shape({
        registrationNumber: string()
            .typeError(`${documentNumber}: Precisa ser preenchido`)
            .required(`${documentNumber}: Precisa ser preenchido`)
            .cpfCnpjValidation('CPF inválido.')
    });
}

export const defaultValuesQueryData = {
    registrationNumber: null
};