import { Stack } from '@mui/material';
import { Button, CardData, LeftIcon, RightIcon, Switch, Typography } from '@uy3/web-components';
import { IPixKeyData } from 'contexts/bankAccount/bankAccountType';
import { useFormContext } from 'contexts/formContext';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import { formatDocumentNumber, hideCredencesDocumentNumber } from 'helpers/formats/DocumentNumber';
import { FC } from 'react'
import { activeTheme } from 'services/theme';

interface IInformationPixKey {
  onClose: () => void;
  pixKeyData: IPixKeyData;
}

const theme = activeTheme();

export const InformationPixKey: FC<IInformationPixKey> = ({ onClose, pixKeyData }) => {
  const { setValue, watch } = useFormContext();
  const { keyValue, keyTypeDisplay, personTypeDisplay, registrationNumber, bankName, accountTypeDisplay, name } = pixKeyData;
  const typeDisplayLowerCase = String(keyTypeDisplay)?.toString()?.toLowerCase() ?? "";

  return (
    <Stack spacing={2}>
      <CardData
        listItem={[
          {
            id: '1',
            title: 'Nome',
            value: name
          },
          {
            id: '2',
            title: 'Instituição',
            value: bankName
          },
          {
            id: '4',
            title: 'Tipo da chave',
            value: formatBankAccountManagePixKey(typeDisplayLowerCase, keyValue, true)
          },
          {
            id: '5',
            title: 'Tipo da conta',
            value: accountTypeDisplay
          },
          {
            id: '6',
            title: 'Tipo de pessoa',
            value: personTypeDisplay
          },
          {
            id: '7',
            title: 'CPF/CNPJ',
            value: personTypeDisplay === 'Juridica' ? formatDocumentNumber(registrationNumber) : hideCredencesDocumentNumber(registrationNumber)
          },
        ]}
      />

      <Stack direction="row" alignItems="center" pt={2} pb={2}>
        <Switch
          id="simple-switch-label"
          onChange={(event: any, checked: boolean) => {
            setValue('saveBeneficiary', checked);
          }}
          checked={watch('saveBeneficiary')}
          name='saveBeneficiary'
        />
        <Typography variant="sm" color="neutral.medium" lineHeight="19px" ml={2}>
          Salvar novas informações do contato para transações futuras
        </Typography>
      </Stack>

      <Stack direction='row' spacing={2} justifyContent='right' alignItems='center'>
        <Button
          startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Voltar
        </Button>

        <Button
          variant="contained"
          type='submit'
          size="medium"
          startIcon={
            <RightIcon htmlColor={theme.palette.common.white} sx={{ height: 23, width: 23, }} />
          }>
          Avançar
        </Button>
      </Stack>
    </Stack>
  )
};