import { Stack, styled } from '@mui/material'
import { useFormContext } from 'contexts/formContext'
import React from 'react'
import { SettingListForm } from './SettingListForm'
import { useFieldArray } from 'react-hook-form'
import { Typography } from '@uy3/web-components'

export const TitleSetting = styled(Typography)({
    padding: '20px 0 10px 0',
    borderBottom: '1px solid #BABFD0',

});

export const SettingNotificationForm = () => {
    const { control } = useFormContext();
    const { fields } = useFieldArray({ control, name: 'notificationTypes' });
    return (
        <Stack spacing={2} mb={4} mr={2}>
            <SettingListForm fields={fields} />
        </Stack>
    )
}
