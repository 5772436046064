import { Stack } from '@mui/material';
import { Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';

type FileLinesHeaderProps = {
  refetch: () => void; 
  fileName: string
}

export const FileLinesHeader: React.FC<FileLinesHeaderProps> = ({
   refetch, 
   fileName
}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
    <Typography
        variant="h4"
        color="neutral.dark"
        fontStyle="normal"
        fontWeight="700"
        fontSize="32px"
        lineHeight="38.4px"
    >
        Transações do arquivo {fileName}
    </Typography>

    <Stack>
        <Actions
            numberOfButtons={3}
            buttonsActionsList={[
                {
                    enable: true,
                    label: "Atualizar",
                    action: refetch,
                    disabled: false,
                    icon: <UpdateIcon sx={iconSx} />
                }
            ]}
        />
    </Stack>
</Stack>
  )
}
