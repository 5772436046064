import { ToastType } from 'contexts/apiRequestContext';
import { TLiquidationScheduleType } from 'pages/CreditNote/schema';
import { EOperationType, ERelatedBankAccountType } from 'utils/enums';

export class PixQrCodeRequestModel {
    qrCode: string;

    constructor(qrCode: string) {
        this.qrCode = qrCode;
    }
}

export class BankSlipRequestModel {
    barcodeOrDigitableLine: string;

    constructor(barcodeOrDigitableLine: string) {
        this.barcodeOrDigitableLine = barcodeOrDigitableLine;
    }
}

export class PixkeyRequestModel {
    key: string;

    constructor(key: string) {
        this.key = key;
    }
}

export class TransferToRequestModel {
    name: string;
    registrationNumber: string;
    bankCode: number;
    bankCodeDisplay?: string;
    bankIspb: number;
    agency: string;
    accountNumber: string;
    accountType: ERelatedBankAccountType;

    constructor(
        name: string,
        registrationNumber: string,
        bankCode: number,
        bankIspb: number,
        agency: string,
        accountNumber: string,
        accountType: ERelatedBankAccountType
    ) {
        this.name = name;
        this.registrationNumber = registrationNumber;
        this.bankCode = bankCode;
        this.bankIspb = bankIspb;
        this.agency = agency;
        this.accountNumber = accountNumber;
        this.accountType = accountType;
    }
}

export class TransferRequestModel {
    to: TransferToRequestModel;
    type: EOperationType;

    constructor(to: TransferToRequestModel, type: EOperationType) {
        this.to = to;
        this.type = type;
    }
}

export type LiquidationScheduleFilters = {
    page: number;
    size: number;
    linkStatus?: string;
    status?: string;
};

export type TQueryKey = {
    pixKey?: string | undefined;
    barCode?: string | undefined;
    pixQrCode?: string | undefined;
    formValue: TLiquidationScheduleType;
};

export type TLiquidationValidation = {
    queryKey: TQueryKey;
    setQueryKey: React.Dispatch<React.SetStateAction<TQueryKey | undefined>>;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    liquidationSchedule: TLiquidationScheduleType[];
    selectedLiquidationIndex: number | undefined;
    onDrawerClose: Function;
    liquidationId: string;
    creditNoteId: string;
};

export type TErrorTypeKey = 'keyPixIsError' | 'bankslipIsError' | 'pixQrCodeIsError';
export type TErrorTypeValue = 'pixKey' | 'bankSlip' | 'pixQrCode';
export type TComparisonFunction = (item: TLiquidationScheduleType) => boolean;

export class LiquidationScheduleClosureModel {
    cosifAccountCode!: string
    closeTransitoryBankAccount!: boolean
    paymentDate!: string
}

