import { Grid } from '@mui/material';
import { Button, Typography } from '@uy3/web-components';
import { ComponentProps } from 'react';
import { activeTheme } from 'services/theme';

interface ButtonCustomProps extends ComponentProps<typeof Button> {
    title: string;
    Icon: React.ReactElement;
}

const theme = activeTheme();
const ButtonCustom = ({ title, Icon, onClick, ...rest }: ButtonCustomProps) => {
    return (
        <Grid item justifyContent="flex-end" alignItems="flex-end" display="flex" mt={2}>
            <Button onClick={onClick} {...rest}>
                {Icon}
                <Typography
                    marginLeft={1}
                    variant="sm"
                    sx={{
                        color: theme.palette.primary.main,
                    }}
                >
                    {title}
                </Typography>
            </Button>
        </Grid>
    );
};

export default ButtonCustom;
