import { Grid } from '@mui/material';
import {
  ApprovalIcon,
  ApproveIcon,
  InvoiceReceivableIcon,
  RejectIcon,
  ShareIcon,
  DeleteIcon,
  DoneIcon,
  UpdateIcon,
  AddIcon,
  CloseIcon,
  ReloadRectangleIcon
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { ApiResponseError, ToastType, showErrorToast } from 'contexts/apiRequestContext';
import { useAppConfig } from 'contexts/appConfig';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserGroupListData, useUserPermissionData } from 'contexts/userContext';
import { checkOnlyStatusSome } from 'helpers/formats/ArrayFormats';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { checkActionsPerms } from 'services/permissions';

type CreditNoteListButtonsProps = {
  selectedRow: any;
  onCreateCNAB: () => void;
  sendApproval: () => void;
  generateAssignment: () => void;
  complianceApprove: () => void;
  approveSignaturesValidation: () => void;
  rejectSignaturesValidation: () => void;
  rejactCompliance: () => void;
  creditApproval: () => void;
  onCancel: () => void;
  creditReject: () => void;
  onDelete: () => void;
  instrumentApproval: () => void;
  instrumentReject: () => void;
  liquidationApprove: () => void;
  liquidationReject: () => void;
  clone: () => void;
  refetch: () => void;
  reprocessByStatus: () => void;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
};

const CreditNoteListButtons = ({
  selectedRow,
  onCreateCNAB,
  generateAssignment,
  sendApproval,
  approveSignaturesValidation,
  rejectSignaturesValidation,
  onCancel,
  onDelete,
  complianceApprove,
  rejactCompliance,
  creditApproval,
  creditReject,
  instrumentApproval,
  instrumentReject,
  liquidationApprove,
  liquidationReject,
  clone,
  refetch,
  setToast, 
  reprocessByStatus
}: CreditNoteListButtonsProps) => {
  const { appConfig } = useAppConfig();
  const isApproveAndRejectCredit = !!appConfig.APPROVE_REJECT_CREDIT;
  const arrayStatus = selectedRow?.map((item: any) => item?.status);
  const { data: permissionsData, hasPermission } = useUserPermissionData();
  const { isRootTenancy } = useTenant();
  const navigate = useNavigate();

  const { user } = useIdentity();
  const userId = user?.id;
  const {
    data: userGroup,
    error,
    status,
  } = useUserGroupListData({ page: 0, size: 20 }, userId!);

  useEffect(() => {
    if (status === 'error') {
      const { errorMessage } = mapErrorResponse(error as ApiResponseError);
      showErrorToast("Ops, houve um erro!", errorMessage, setToast)
    }
  }, [error, setToast, status])

  const onGenerateAssigment = selectedRow.length > 0 && selectedRow.every((row: any) => row?.fundId);
  const showOnlyOnCompliance = arrayStatus?.length && arrayStatus?.every((row: any) => row === 'ComplianceApproval');
  const showOnlyOnCreditApproval = arrayStatus?.length && arrayStatus?.every((row: any) => row === 'CreditApproval');
  const showOnlyOnSignaturesValidation = arrayStatus?.length && arrayStatus?.every((row: any) => row === 'SignaturesValidation');
  const showOnlyOnInstrument = arrayStatus?.length && arrayStatus?.every((row: any) => row === 'InstrumentApproval');
  const showOnlyOnLiquidation = arrayStatus?.length && arrayStatus?.every((row: any) => row === 'Liquidation');

  const hasSendApproval =
    isApproveAndRejectCredit &&
    arrayStatus?.some((status: string) =>
      ['Disapproved', 'Error', 'Draft', 'Revision'].includes(status)
    );
  const onDeleteCreditNote =
    arrayStatus?.length &&
    arrayStatus?.every((status: string) =>
      ['Revision', 'Draft', 'Canceled', 'Disapproved'].includes(status)
    );

  const hasActionPermission = (action: string): boolean => {
    if (checkActionsPerms(action, permissionsData, 'CreditNote')) {
      return true;
    }
    return false;
  };

  const showCancelCreditNote = (): boolean => {
    const defaultStatus = [
      'Draft',
      'Revision',
      'ComplianceApproval',
      'InstrumentApproval',
      'CreditApproval',
      'Signatures',
      'SignaturesValidation',
    ];
    if (
      isRootTenancy &&
      hasPermission('CreditNote', 'Read') &&
      hasActionPermission('Cancel') &&
      hasActionPermission('AdminCancel')
    ) {
      return arrayStatus?.every(
        (status: string) =>
          [
            ...defaultStatus,
            'ManualLiquidation',
            'WaitLiquidation',
            'Liquidation',
            'PaymentRevision',
            'Finished',
            'Error',
            'Warranty',
          ].includes(status) && checkOnlyStatusSome(arrayStatus, status)
      );
    } else if (hasPermission('CreditNote', 'Read') && hasActionPermission('Cancel')) {
      return arrayStatus?.every(
        (status: string) =>
          defaultStatus.includes(status) && checkOnlyStatusSome(arrayStatus, status)
      );
    }
    return false;
  };

  //@ts-ignore
  const isComplianceAdmin = userGroup?.data?.some(({ groupName }: { groupName: string }) => groupName === 'Compliance_Admin');

  const actionsCreditNoteList = [
    {
      enable: true,
      label: 'Atualizar',
      action: refetch,
      icon: <UpdateIcon />,
    },
    {
      enable: hasPermission('CreditNote', 'Create'),
      label: 'Nova operação',
      icon: <AddIcon />,
      action: () => navigate('/ccb/operacoes/nova')
    },
    {
      enable: showOnlyOnCompliance,
      label: 'Aprovar compliance',
      action: complianceApprove,
      icon: <ApproveIcon />,
    },
    {
      enable: showOnlyOnCompliance,
      label: 'Rejeitar compliance',
      action: rejactCompliance,
      icon: <RejectIcon />,
    },
    {
      enable: showOnlyOnCreditApproval,
      label: 'Aprovar crédito',
      action: creditApproval,
      icon: <ApproveIcon />,
    },
    {
      enable: showOnlyOnCreditApproval,
      label: 'Rejeitar crédito',
      action: creditReject,
      icon: <RejectIcon />,
    },
    {
      enable: showOnlyOnSignaturesValidation && isComplianceAdmin,
      label: 'Aprovar assinatura',
      action: approveSignaturesValidation,
      icon: <ApproveIcon />,
    },
    {
      enable: showOnlyOnSignaturesValidation && isComplianceAdmin,
      label: 'Rejeitar assinarura',
      action: rejectSignaturesValidation,
      icon: <RejectIcon />,
    },
    {
      enable: showOnlyOnInstrument,
      label: 'Aprovar instrumento',
      action: instrumentApproval,
      icon: <ApproveIcon />,
    },
    {
      enable: showOnlyOnInstrument,
      label: 'Rejeitar instrumento',
      action: instrumentReject,
      icon: <RejectIcon />,
    },
    {
      enable: showOnlyOnLiquidation,
      label: 'Aprovar liquidação',
      action: liquidationApprove,
      icon: <ApproveIcon />,
    },
    {
      enable: showOnlyOnLiquidation,
      label: 'Rejeitar liquidação',
      action: liquidationReject,
      icon: <RejectIcon />,
    },
    {
      enable: selectedRow.length > 0,
      label: 'Gerar relatório',
      action: onCreateCNAB,
      icon: <InvoiceReceivableIcon />,
    },
    {
      enable: hasSendApproval,
      label: 'Enviar para aprovação',
      action: sendApproval,
      icon: <ApprovalIcon />,
    },
    {
      label: 'Reiniciar Esteira',
      action: reprocessByStatus,
      enable: isRootTenancy && selectedRow.length > 0 && hasActionPermission('RestartWorkflow'), 
      icon: <ReloadRectangleIcon />
  },
    {
      enable: selectedRow?.length === 1,
      label: 'Clonar',
      action: clone,
      icon: <ShareIcon />,
    },
    {
      enable: onGenerateAssigment,
      label: ' Iniciar cessão em lote',
      action: generateAssignment,
      icon: <DoneIcon />,
    },
    {
      enable: onDeleteCreditNote,
      label: 'Excluir',
      action: onDelete,
      icon: <DeleteIcon />,
    },
    {
      enable: arrayStatus.length > 0 && showCancelCreditNote(),
      label: 'Cancelar',
      action: onCancel,
      icon: <CloseIcon />
    },
  ];

  return (
    <>
      <Grid sx={{ mr: -1 }}>
        <Actions numberOfButtons={4} buttonsActionsList={actionsCreditNoteList} />
      </Grid>
    </>
  );
};

export default CreditNoteListButtons;
