import { styled } from '@mui/system';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';

export const CustomTimeline = styled(Timeline)``;

export const CustomTimelineItem = styled(TimelineItem)``;

export const CustomTimelineSeparator = styled(TimelineSeparator)``;

interface TimelineDotProps {
    backgroundColor: string;
    borderColor?: string;
}

export const CustomTimelineDot = styled(TimelineDot) <TimelineDotProps>`
    background-color: ${(props) => props.backgroundColor};
    border: 2px solid ${(props) => props.borderColor || '#ccc'};
`;

export const CustomTimelineContent = styled(TimelineContent) <TimelineDotProps>`
    margin-left: 20px;
    display: flex;
    border-radius: 16px;
    padding: 16px;
    background: ${(props) => props.backgroundColor};
    font-family: 'Lato', sans-serif;
    text-align: left;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;
    min-height: 0;
    min-width: 0;
    max-width: 100%;
    width: 100%;
    height: 100%;
`;

export const TimelineDescription = styled('p')`
    margin: 0;
    color: ${(props) => props.color || '#555'};
    font-family: 'Lato', sans-serif;
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1 1 auto;
`;

export const CustomTimelineConnector = styled(TimelineConnector) <{ display: string }>`
    background-color: ${(props) => props.color};
    display: ${(props) => props.display};
`;