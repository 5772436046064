/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable-next-line react-hooks/exhaustive-deps */
import OperationsTab from './OperationTab';
import OperationDetails from './OperationDetails';
import AmortizationQuery from './AmortizationQuery';
import { useParams } from 'react-router-dom';
import { useCreditData } from 'contexts/creditNote/creditContext';
import { useFormContext } from 'contexts/formContext';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';

const OperationTabContainer = () => {
    const { id } = useParams();
    const { creditData, creditError, creditStatus } = useCreditData(id!);
    const { watch } = useFormContext();
    const simulation = watch('createSimulation');    

    const simulationResponse = simulation ? simulation : creditData?.amortization;

    return (
        <GenericErrorBoundary status={creditStatus} error={creditError} fallback="operação">
            <OperationsTab  />
            <AmortizationQuery simulationResponse={simulationResponse} />
            <OperationDetails
                currentPaymentScheduleItems={creditData?.paymentScheduleItems}
                isNewCreditOp={id === 'nova'}
            />
        </GenericErrorBoundary>
    );
};

export default OperationTabContainer;
