import { Stack } from '@mui/material';
import { CancelIcon, CompleteIcon, Button } from '@uy3/web-components';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

type SendFileHemeraFormProps = {
    formValues: any; 
    onClose: () => void
}
const theme = activeTheme();

export default function SendFileHemeraForm({formValues, onClose}: SendFileHemeraFormProps) {
    const uploads = formValues?.uploads ?? [] 
    const uploadsFiltered = uploads?.filter((item: any) => item?.fileType === 'AssignmetReport') ?? []; 

    const optionsSelect: SelectOption[] = uploadsFiltered?.map((item: any) => {
        return {
            label: item?.displayName ?? item?.fileName, 
            value: item?.id
        }
    });

    return (
        <Stack direction='column' spacing={4} mt={4}>
            <SelectFormField name='list' label='Selecione um documento' multiple options={optionsSelect}/>

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2 }>
                <Button variant='outlined' onClick={onClose} startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main}/>}>
                    Não, cancelar
                </Button>
                <Button type='submit' variant='contained' startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white}/>}>
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
