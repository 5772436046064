/* eslint-disable no-useless-escape */
import { string, object } from 'yup';

export function loginFormValidationSchema() {
    return object().shape({
        email: string()
            .typeError('Campo obrigatório')
            .required('Campo obrigatório.')
            .email('email inválido'),
        password: string()
            .typeError('Senha inválida.')
            .required('Senha: Precisa ser preenchido.')
    });
}

export const loginFormDefaultValues = {
    email: '',
    password: '',
};
