import { Stack } from '@mui/material';
import { Modal, Typography } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import React, { useState } from 'react';
import { useNavigate } from 'react-router';

export const LogoutAction = () => {
    const [confirmLogout, setConfirmLogout] = useState<boolean>(false);
    const navigate = useNavigate();
    const onClose = () => setConfirmLogout(false);

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" alignContent="center">
            <Typography
                onClick={() => setConfirmLogout(true)}
                sx={{
                    fontSize: '12px',
                    fontWeight: 600,
                    cursor: 'pointer',
                }}
            >
                Deseja sair da conta ?
            </Typography>

            <Modal
                {...{
                    open: confirmLogout,
                    onClose: () => setConfirmLogout(false),
                    align: 'center',
                    title: 'Sair da conta',
                    description: 'Tem certeza que realmente deseja sair da conta?',
                    children: (
                        <GenericActionHandler
                            onClose={onClose}
                            isModal
                            handleSubmit={() => {
                                onClose();
                                navigate('/login/logout');
                            }}
                        />
                    ),
                }}
            />
        </Stack>
    );
};
