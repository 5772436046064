import { Stack } from '@mui/material';
import { Button, CardData, CheckCircleIcon, CloseIcon } from '@uy3/web-components';
import { ApiResponseError, handleOnError, iconSx, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useCheckAverbationCreditNoteMutation } from 'contexts/creditNote/creditContext';
import React, { useState } from 'react'
import { useParams } from 'react-router';
import { CheckAverbationResponseType } from 'services/creditNote/types/genericTypes';
import { activeTheme } from 'services/theme';

type CheckAverbationProps = {
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
};

const theme = activeTheme();

export const CheckAverbation: React.FC<CheckAverbationProps> = ({ onClose, setToast }) => {
    const { id } = useParams()
    const [responseData, setResponseData] = useState<CheckAverbationResponseType | null>(null);

    const onSuccess = (response: CheckAverbationResponseType) => {
        if (response !== null)
            setResponseData(response);

        const title = "Verificação realizada com sucesso";
        const description = "Confira as informações da consulta na tela";
        showSuccessToast(title, description, setToast);
    };

    const onError = (response: ApiResponseError) => handleOnError(response, setToast);

    const { mutateAsync } = useCheckAverbationCreditNoteMutation(id!, onSuccess, onError);

    return (
        <Stack mt={2}>
            {Object.values(responseData ?? {})?.length > 0 && (
                <CardData {...{
                    listItem: [
                        { id: '1', title: 'Status', value: responseData?.status },
                        { id: '2', title: 'Protocolo', value: responseData?.protocolo },
                        { id: '3', title: 'Identificador', value: responseData?.identificador ?? "XPTO" }
                    ]
                }} />
            )}
            <Stack direction="row" justifyContent="end" alignItems="center" spacing={2}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-continuar"
                    onClick={async () => await mutateAsync()}
                    startIcon={<CheckCircleIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                >
                    Verificar
                </Button>
            </Stack>
        </Stack>
    )
}
