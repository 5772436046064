import { string, object } from 'yup';

export function validationSchemaInvolvedFundForm() {
    return object().shape({
        personId: string().typeError('O Envolvido é inválido.').required('Envolvido: Precisa ser preenchido.'),
        fundSignerType: string().typeError('Tipo de relação inválido.').required('Tipo de relação: Precisa ser preenchido.'),
        signatureType: string().typeError('Forma de Envio inválido.').nullable().notRequired(),
        signatureValidation: string().typeError('Forma de Validação inválido.').nullable().notRequired()
    });
};

export const defaultValuesInvolvedFundForm = {
    personId: null,
    fundSignerType: null,
    signatureType: null,
    signatureValidation: null
};

