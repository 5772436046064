/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react'
import { ActionButtonProfile, ActionsMyProfile } from './MyProfileMenu.styled'
import { Typography } from '@uy3/web-components'
import { handleMouseDown } from 'helpers/methods/HandleMouseDown'
import { useNavigate } from 'react-router'

type MyProfileMenuProps = {
    setOpenMenuProfile: React.Dispatch<React.SetStateAction<boolean>>;
    openMenuProfile: boolean;
}

export const MyProfileMenu: React.FC<MyProfileMenuProps> = ({ openMenuProfile, setOpenMenuProfile }) => {
    const navigate = useNavigate();
    const componentRef = useRef(null);
    const onClose = () => setOpenMenuProfile(false);

    useEffect(() => {
        handleMouseDown(onClose, componentRef)
    }, []);

    const handleMyProfile = () => {
        navigate('/meu-perfil');
        onClose();
    }

    const handleLogout = () => {
        navigate('login/logout');
    }

    if (Boolean(openMenuProfile) === false) return <></>;

    return (
        <ActionsMyProfile ref={componentRef}>
            <ButtonCustom label='Meu perfil' onClick={handleMyProfile} />
            <ButtonCustom label='Sair' onClick={handleLogout} />
        </ActionsMyProfile>
    )
}

type ButtonCustomProps = {
    label: string;
    onClick: () => void
}

const ButtonCustom: React.FC<ButtonCustomProps> = ({ label, onClick }) => {
    return (
        <ActionButtonProfile
            size="medium"
            onClick={onClick}
        >
            <Typography variant="sm">{label}</Typography>
        </ActionButtonProfile>
    )
}