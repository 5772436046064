import { Stack } from '@mui/material';
import { Button, CloseIcon, DocPdfIcon, SearchIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

type ActionsChargesType = {
    onClose: () => void;
    editBillingAction: () => void;
    downloadPDFBankSlip: () => void;
    disableActionDownload?: boolean
    bankSlipId: string
}

const theme = activeTheme();

export const ActionsCharges: React.FC<ActionsChargesType> = ({
    onClose,
    editBillingAction,
    downloadPDFBankSlip,
    disableActionDownload,
    bankSlipId
}) => {
    return (
        <Stack spacing={2} direction='row' alignItems='center' justifyContent='right'>
            <Button
                variant='outlined'
                onClick={onClose}
                startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
            >
                Fechar
            </Button>
            {!!bankSlipId && disableActionDownload && <Button
                variant='outlined'
                onClick={downloadPDFBankSlip}
                startIcon={<DocPdfIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
            >
                Download Boleto
            </Button>}
            <Button
                variant='contained'
                onClick={editBillingAction}
                startIcon={<SearchIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
            >
                Visualizar
            </Button>
        </Stack>
    )
}
