import { date, number, object, string } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

export function validationSchemaNewChargesForm() {
    return object().shape({
        walletCode: string()
            .typeError('Selecione uma carteira.')
            .required('Selecione uma carteira.'),
        personId: string().typeError('Selecione um pagador.').required('Selecione um pagador.'),
        documentNumber: string()
            .typeError('Nº de Documento inválido.')
            .required(`Nº de Documento: ${genericMessage}`)
            .max(10, 'Número do documento não pode ser maior que 10 caracteres'),
        description: string().nullable().notRequired(),
        payer: object().shape({
            name: string().nullable().notRequired(),
            personTypeValue: string().nullable().notRequired(),
            email: string().required("E-mail precisa ser preenchido").typeError("E-mail precisa ser preenchido").email('E-mail inválido'),
            registrationNumber: string().nullable().notRequired(),
            zipCode: string()
                .required("CEP: precisa ser preenchido")
                .typeError("CEP: precisa ser preenchido")
                .test('length-zipcode', 'CEP: deve conter 8 dígitos', (value: any) => {
                    const zipCode = String(value)?.replace(/\D/g, '');
                    return zipCode?.length === 8
                }),
            addressName: string().required("Endereço: precisa ser preenchido").typeError("Endereço: precisa ser preenchido"),
            addressNumber: string().required("Número: precisa ser preenchido").typeError("Número: precisa ser preenchido")
        }),
        dueDate: date()
            .typeError('Data de vencimento precisa ser preenchida')
            .required('Data de vencimento: Precisa ser preenchido.'),
        amount: number()
            .required(`Valor da cobrança: ${genericMessage}`)
            .typeError('Valor precisa ser um número.')
            .moreThan(-1, 'Valor inválido.')
            .min(0.01, 'Valor principal: Precisa ter um valor maior que 0 (zero).'),


    });
}

export const defaultValuesNewChargesForm = {
    walletCode: null,
    personId: null,
    documentNumber: null,
    description: null,
    payer: null,
    dueDate: null,
    amount: 0
};
