import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import moment from "moment";
import { number, object, string } from "yup";

export const validationvGareIpvaFormSchema = () => {
    return object().shape({
        paymentValue: number()
            .typeError('Valor do pagamento: Precisa ser preenchido.')
            .required("Valor do pagamento: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
        paymentDate: string().typeError('Data de apuração Precisa ser preenchido.').required("Data de apuração: Precisa ser preenchido."),
        name: string().typeError('Nome Precisa ser preenchido.').required("Nome: Precisa ser preenchido."),
        currentBoard: string().typeError('Placa atual ser preenchido.').required("Placa atual: Precisa ser preenchido."),
        typeSpecie: string().typeError('Tipo espécie do veículo ser preenchido.').required("Tipo espécie do veículo: Precisa ser preenchido."),
        quote: string().typeError('Número da cota Precisa ser preenchido.').required("Número da cota: Precisa ser preenchido."),
        ipvaRange: string().typeError('Código da faixa de IPVA Precisa ser preenchido.').required("Código da faixa de IPVA: Precisa ser preenchido."),
        exercise: string().typeError('Exercício Precisa ser preenchido.').required("Exercício: Precisa ser preenchido."),
        cityCode: string().typeError('Código do município Precisa ser preenchido.').required("Código do município: Precisa ser preenchido."),
        valueOfRevenue: number().typeError('Valor da receita Precisa ser preenchido.').required("Valor da receita: Precisa ser preenchido."),
        dueDate: string().typeError('Data de vencimento Precisa ser preenchido.').required("Data de vencimento: Precisa ser preenchido."),
        registrationNumber: string().typeError('CPF/CNPJ Precisa ser preenchido.').required("CPF/CNPJ: Precisa ser preenchido."),
        principalValue: number()
            .typeError('Valor principal: Precisa ser preenchido.')
            .required("Valor principal: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor principal: Precisa ter um valor maior que 0.'),
        totalValue: number()
            .typeError('Valor total: Precisa ser preenchido.')
            .required("Valor total: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor total: Precisa ter um valor maior que 0.'),
        receitaFederalCodeId: string().typeError('Código da receita federal inválido.').required("Código da receita federal: Precisa ser preenchido."),
        taxPayer: number().typeError('Número de contribuinte Precisa ser preenchido.').required("Número de contribuinte: Precisa ser preenchido."),
    });
};

export const defaultValuesGareIpvaForm = {
    dueDate: moment(new Date()).format('YYYY-MM-DD'), 
    paymentDate: moment(new Date()).format('YYYY-MM-DD'), 
    name: "",
    cityCode: "", 
    exercise: "",
    currentBoard: "", 
    registrationNumber: "", 
    typeSpecie: "",
    ipvaRange: "", 
    quote: null, 
    paymentValue: null, 
    taxPayer: null, 
    valueOfRevenue: null, 
    principalValue: null, 
    totalValue: null, 
    receitaFederalCodeId: null, 
};