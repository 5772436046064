import { IconButton, Stack } from '@mui/material';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { DeleteIcon } from '@uy3/web-components';
import InterestModelForm from './InterestModelForm';

type CategoryListProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
};

const InterestModelList = ({ fields, remove }: CategoryListProps) => (
    <>
        <CustomTitle
            title="Adicione um novo parâmetro ou modifique um existente."
            variant="sm"
        />

        <Stack direction="column" spacing={2}>
            {fields.map((field, index) => (
                <Stack key={field.id} direction="row" alignItems="center">
                    <InterestModelForm key={field.id} index={index} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

export default InterestModelList;
