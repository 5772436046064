import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack/Stack';
import { Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { BankAccountFull, iconSx } from 'contexts/apiRequestContext';
import { Paper } from '@mui/material';
import { formatAccountFormat } from 'helpers/formats/StringFormats';
import { replacePhoneNumber } from 'helpers/formats/PhoneNumber';
import { formatPixKeyValue } from 'helpers/formats/BankAccount';
import { Card } from 'components/Card';
import { PaidOutlined } from '@mui/icons-material';

export type TransferenciaTabProps = {
    openDrawer: (indexNumber?: number | undefined) => void;
    onDeleteBankAccount: (index: number) => void;
    name: string;
    enableActions: boolean
};

const typographySX = { fontSize: '19px', margin: '3px 0' };
export function BankDetailsList({ openDrawer, name, onDeleteBankAccount, enableActions }: TransferenciaTabProps) {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];
    const theme = activeTheme();
    return (
        <>
            <Paper sx={{ p: 6, mb: 2, boxShadow: 'none', border: '1px solid #E0E3EA' }}>
                {queryData?.length === 0 ? (
                    <Stack display="flex" justifyContent="center" alignItems="center">
                        <Typography
                            sx={typographySX}
                            fontWeight={600}
                            color="textSecondary"
                            align="center"
                        >
                            Nenhuma conta bancária cadastrada
                        </Typography>
                    </Stack>
                ) : (
                    <Grid container spacing={3} columns={{ xs: 1, sm: 3, md: 5 }}>
                        {queryData?.map((bankAccount: BankAccountFull, index: number) => {
                            if (bankAccount.pixKeyTypeValue === 'Phone' && !!bankAccount.keyPix) {
                                bankAccount.keyPix = replacePhoneNumber(bankAccount.keyPix);
                            }

                            const bankAccountFormated = () => {
                                const format = formatPixKeyValue(bankAccount);
                                const index = format.indexOf('-');
                                if (bankAccount?.pixKeyTypeValue === 'AgencyAndAccount') {
                                    return format;
                                }
                                return format.slice(index).replace('- ', '');
                            };

                            const isTransfer =
                                bankAccount?.operationTypeValue === '0' ||
                                bankAccount?.operationTypeValue === 'Transfer';
                            return (
                                <Grid item xs={1}>
                                    <Card.Root>
                                        <Card.Content
                                            title="Tipo de operação"
                                            value={
                                                <Stack direction="row" alignItems="center">
                                                    <PaidOutlined
                                                        sx={{
                                                            ...iconSx,
                                                            color: theme.palette.primary.main,
                                                        }}
                                                    />{' '}
                                                    {isTransfer ? 'Transferência' : 'Pix'}
                                                </Stack>
                                            }
                                        />
                                        <Card.Content
                                            title={`Tipo de ${isTransfer ? 'Conta' : 'Chave'}`}
                                            value={
                                                isTransfer
                                                    ? bankAccount?.typeDisplay
                                                    : formatAccountFormat(
                                                        bankAccount?.pixKeyTypeValue
                                                    )
                                            }
                                        />
                                        {(isTransfer ||
                                            bankAccount?.pixKeyTypeValue ===
                                            'AgencyAndAccount') && (
                                                <>
                                                    <Card.Content
                                                        title={'Banco'}
                                                        value={bankAccount?.bankCodeDisplay}
                                                    />
                                                    <Card.Content
                                                        title={'Agência'}
                                                        value={bankAccount?.agency}
                                                    />
                                                    <Card.Content
                                                        title={'Conta'}
                                                        value={`${bankAccount?.account} - ${bankAccount?.accountDigit === ''
                                                                ? 'N/D'
                                                                : bankAccount.accountDigit ?? 'N/D'
                                                            }`}
                                                    />
                                                </>
                                            )}

                                        {!isTransfer &&
                                            bankAccount?.pixKeyTypeValue !== 'AgencyAndAccount' && (
                                                <>
                                                    <Card.Content
                                                        title={'Chave Pix'}
                                                        value={bankAccountFormated()}
                                                    />
                                                </>
                                            )}

                                        {enableActions &&
                                            <Card.Actions
                                                onEditAction={() => openDrawer(index)}
                                                onDeleteAction={() => onDeleteBankAccount(index)}
                                            />}
                                    </Card.Root>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
            </Paper>
            {/* <Grid container justifyContent="right" spacing={3}>
                <Grid md={6} justifyContent="right" display="flex">
                    <Box sx={{ mt: 3 }}>
                        <Button
                            sx={{ alignSelf: 'flex-end' }}
                            variant="contained"
                            disabled={false}
                            onClick={() => openDrawer()}
                            size="medium"
                            startIcon={
                                <AddIcon
                                    htmlColor={theme.palette.primary.contrastText}
                                    sx={iconSx}
                                />
                            }
                            color="primary"
                        >
                            Adicionar conta
                        </Button>
                    </Box>
                </Grid>
            </Grid> */}
        </>
    );
}
