/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material'
import { AddIcon, Button, CloseIcon, SaveIcon } from '@uy3/web-components'
import { SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { useEffect } from 'react'
import { IGetTokensQuery } from 'services/accounts/naturalPerson/types/naturalPersonReadModel'
import { activeTheme } from 'services/theme'

type CompleteManualWarrantyProps = {
    onClose: () => void;
    tokensList: IGetTokensQuery[]
    handleAddToken: () => void
}

const theme = activeTheme();
export const CompleteManualWarranty = ({ onClose, tokensList, handleAddToken }: CompleteManualWarrantyProps) => {
    const colorMain = theme.palette.primary.main;
    const { setValue, watch } = useFormContext();
    const watchSelectedTokens = watch('selectedTokens') ?? [];

    useEffect(() => {
        if (tokensList) {
            tokensList.forEach((token, index) => {
                setValue(`tokens.${index}.expirationDate`, token.ExpirationDate);
                setValue(`tokens.${index}.token.tokenType`, token.Token.TokenType);
                setValue(`tokens.${index}.token.tokenValue`, token.Token.TokenValue);
                setValue(
                    `tokens.${index}.WarrantyRegistrationOffice`,
                    token.WarrantyRegistrationOffice
                );
            });
        }
    }, []);

    useEffect(() => {
        if (watchSelectedTokens.length > 0) {
            const tokensToSave = tokensList?.filter((item) => watchSelectedTokens?.includes(item.Token.TokenValue));
            setValue('tokens', tokensToSave);
        }
    }, [watchSelectedTokens])

    const optionTokens: SelectOption[] = !!tokensList?.length
        ? tokensList?.map((item) => item.option)
        : [];

    return (
        <Stack spacing={2}>
            <Grid item xs={12}>
                <SelectFormField
                    label="Token"
                    multiple
                    name="selectedTokens"
                    required
                    fullWidth
                    options={optionTokens}
                />
                <Grid container sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        variant="text"
                        onClick={handleAddToken}
                        startIcon={<AddIcon htmlColor={colorMain} />}
                        sx={{ color: colorMain }}
                    >
                        Adicionar token
                    </Button>
                </Grid>
            </Grid>

            
            {watchSelectedTokens && watchSelectedTokens.map((token: any, tokenIndex: number) => (
                <TextFormField
                    key={tokenIndex}
                    name={`tokens.${tokenIndex}.warrantyCode`}
                    variant="outlined"
                    label={`Código ADE - Token ${token}`}
                    fullWidth
                />
            ))}

            <Stack direction='row' alignItems='center' justifyContent='right' spacing={2} mt={2}>
                <Button variant='outlined' onClick={onClose} startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}>
                    Não, cancelar
                </Button>
                <Button variant='contained' type='submit' startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}>
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}