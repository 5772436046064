/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import ApproveLiquidationScheduleForm from './ApproveLiquidationScheduleForm';
import { CustomStack } from 'components/Custom/CustomMUI';
import { Typography } from '@uy3/web-components';
import { TliquidationScheduleOpt } from 'services/LiquidationSchedule';
import { TGetCosif } from 'contexts/creditProductContext';
import { useFormContext } from 'contexts/formContext';
import { useEffect } from 'react';

type ApproveLiquidationFormProps = {
    cosifList: TGetCosif[];
    liquidationScheduleList: TliquidationScheduleOpt[];
    hasTransitoryAccount: boolean;
    hasUnlinkedTransfer: boolean;
    hasCosifAccountOfTransitory: boolean;
    setHasPrincipalExternalId: (value: boolean) => void
};

export default function ApproveLiquidationScheduleWrapperForm({
    cosifList,
    liquidationScheduleList,
    hasTransitoryAccount,
    hasUnlinkedTransfer,
    hasCosifAccountOfTransitory,
    setHasPrincipalExternalId
}: ApproveLiquidationFormProps) {
    const { watch } = useFormContext(); // Contexto do Form de Aprovar Roteiro de liquidação

    const cosifCodeWatch = watch('cosifAccountCode');
    const hasExternalId: boolean = !!cosifList.find((i) => i.value === cosifCodeWatch)?.externalAccountId;
    const cosifData = cosifList as SelectOption[];

    useEffect(() => {
        if (!!cosifCodeWatch) {
            const externalAccountId = cosifData?.find(
                (i) => i.value === cosifCodeWatch
                //@ts-ignore
            )?.externalAccountId;
            setHasPrincipalExternalId(!!externalAccountId); 
        }
    }, [cosifCodeWatch])

    const transitoryBankAccountOptions = [
        { label: 'Liquidado manualmente', value: 'ManualLiquidation' },
        hasExternalId
            ? { label: 'Liquidar automaticamente', value: 'AutomaticLiquidation' }
            : undefined,
    ].filter((i) => i !== undefined) as SelectOption[];

    const showCosifAccount = !hasExternalId && !hasCosifAccountOfTransitory && !hasTransitoryAccount;
    
    return (
        <Stack my={4} spacing={3}>
            {!hasUnlinkedTransfer && (
                <CustomStack gap={2}>
                    <Typography variant="sm" color="common.black" fontWeight={700}>
                        Conta transitória
                    </Typography>
                    <SelectFormField
                        required
                        name="cosifAccountCode"
                        label="Conta de débito"
                        options={cosifData}
                    />
                    {hasTransitoryAccount && (
                        <SelectFormField
                            required
                            name="transitoryBankAccountAction"
                            label="Ação"
                            options={transitoryBankAccountOptions}
                        />
                    )}
                </CustomStack>
            )}
            <ApproveLiquidationScheduleForm
                {...{
                    liquidationScheduleList,
                    cosifList,
                    showCosifAccount,
                    hasCosifAccountOfTransitory,
                    hasTransitoryAccount
                }}
            />
        </Stack>
    );
}
