import { Stack } from '@mui/material'
import { Button } from '@uy3/web-components'
import { useApiRequest } from 'contexts/apiRequestContext';
import React from 'react';

type GenericActionHandlerProps = {
  onClose: () => void;
  handleSubmit: () => void;
  isModal?: boolean;
  titleConfirm?: string;
  titleCancel?: string;
  isLoading?: boolean;
}

export const GenericActionHandler: React.FC<GenericActionHandlerProps> = ({
  onClose,
  handleSubmit,
  isModal = false,
  titleConfirm = "Sim, confirmar",
  titleCancel = "Não, cancelar",
  isLoading = false
}) => {
  const { submitting } = useApiRequest();
  const sxButton = {
    width: isModal ? '100%' : 'auto'
  };
  const sxStack = {
    mb: isModal ? -2 : 'auto',
    mt: isModal ? 2 : 'auto'
  }
  const enableLoading = (isLoading || submitting);
  return (
    <React.Fragment>
      <Stack
        direction="row"
        spacing={2}
        sx={sxStack}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="outlined"
          sx={sxButton}
          onClick={onClose}>
          {titleCancel}
        </Button>
        <Button
          variant="contained"
          sx={sxButton}
          disabled={enableLoading}
          onClick={handleSubmit}
        >
          {titleConfirm}
        </Button>
      </Stack>
    </React.Fragment>

  )
}