import { ComponentProps, FunctionComponent, useState } from "react";
import { TextFormField, TextFormFieldProps } from "../TextFormField/TextFormField";
import { IconButton, InputAdornment } from "@mui/material";
import { HideIcon, ShowIcon } from "@uy3/web-components";

type TextFormFieldPasswordProps = ComponentProps<FunctionComponent<TextFormFieldProps>>;

export const TextFormFieldPassword = (props: TextFormFieldPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    return (
        <TextFormField
            {...props}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <ShowIcon /> : <HideIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}