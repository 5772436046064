/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { Button, EditIcon, Typography } from '@uy3/web-components';
import { SelectOption } from 'components/Forms/FormFields';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useEffect, useState } from 'react';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { isEmpty } from 'lodash';
import { useGetTagsAsync } from 'contexts/wallet/Billing/billingContext';

interface CategorySelectionProps {
    setAddCategories: React.Dispatch<React.SetStateAction<boolean>>;
}

const theme = activeTheme();
const CategorySelectionForm: React.FC<CategorySelectionProps> = ({ setAddCategories }) => {
    const { validationErrors, setValue, getValues } = useFormContext();
    const formValues = getValues();
    const { tagsAutocomplete, data: tagsList } = useGetTagsAsync(formValues?.walletCode);
    const [tegsSelected, setTegsSelected] = useState<SelectOption[]>([]);

    useEffect(() => {
        setValue('tagsList', tagsList)
    }, [tagsList]);

    let error = validationErrors && resolve('tags', validationErrors)?.message;
    const colorMain = theme.palette.primary.main;

    const handleChange = (event?: any, option?: any) => {
        if (!isEmpty(option)) {
            let valuesToImport = option?.map((option: SelectOption) => option?.value);
            setValue("tagsSelected", valuesToImport);
            setTegsSelected(option)
        } else {
            setTegsSelected([]);
        }
    }

    return (
        <Stack pt={3} spacing={2}>
            <CustomTitle title="Selecione uma categoria" variant="lg" />
            <AutocompleteField
                name="tags"
                label="Categoria"
                multiple
                value={tegsSelected ?? []}
                onChange={handleChange}
                {...tagsAutocomplete}
            />
            {!!error && (
                <Typography variant="xs" mt={0.5} color={theme.palette.error.dark}>
                    {error}
                </Typography>
            )}

            <Stack justifyContent="flex-end" direction="row">
                <Button
                    onClick={() => setAddCategories(true)}
                    startIcon={<EditIcon htmlColor={colorMain} sx={iconSx} />}
                >
                    Adicionar categorias
                </Button>
            </Stack>
        </Stack>
    );
};

export default CategorySelectionForm;
