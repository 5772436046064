import { Alert, ShieldWarningIcon } from '@uy3/web-components';
import React from 'react';

export default function FraudRecordAlert() {
    return (
        <Alert
            severity="error"
            text={
                'Informamos que esse cliente foi suspenso devido à detecção de atividades fraudulentas associadas a ele. Este bloqueio é definitivo.'
            }
            variant="filled"
            icon={<ShieldWarningIcon fontSize="large" htmlColor="#FFFFFF" />}
        />
    );
}
