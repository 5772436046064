import { ETypeOfRelationship } from 'utils/enums';
import {
    EBankAccountRequestReason,
    EBankAccountRequestStep,
    EBankAccountRequestType,
    ELegalNature,
    EOperatorPermissionLevel,
} from '../enum';
import { IAddressModel } from 'utils/interface';
import { IUploadModel } from 'contexts/apiRequestContext';

export interface BankAccountOperatorCreateModel {
    userId: string;
    tenantName: string;
    level: EOperatorPermissionLevel;
}

export class RelatedPersonBankAccountModel {
    id?: string;
    personId?: string;
    typeOfRelationship?: ETypeOfRelationship;
    participationPercentage?: number;
    isSigner: boolean = false;
    relatedToId?: string;
    userId?: string;
    level?: EOperatorPermissionLevel;
    tenantName?: string;

    constructor(init?: Partial<RelatedPersonBankAccountModel>) {
        Object.assign(this, init);
    }
}

export interface IBankAccountPersonRequestUpdateModel {
    name: string;
    address: IAddressModel;
    phone: string;
    economicActivityCodeId?: string;
    numberOfEmployees?: number;
    legalNature: ELegalNature;
    averageMonthlyRevenue?: number;
}

export class BankAccountRequestBaseModel {
    step!: EBankAccountRequestStep;
    ownerId!: string;
    bankAccountProductId?: string;
    partnerName!: string;
    reason!: EBankAccountRequestReason;
    otherReason?: string;
    quorumMinimumApproval?: number;
    type!: EBankAccountRequestType;
    operators?: BankAccountOperatorCreateModel[];
    owner!: IBankAccountPersonRequestUpdateModel;
    relatedPerson!: RelatedPersonBankAccountModel[];
    uploads?: IUploadModel[];
    cardReceiptAddress?: IAddressModel;
    cardName?: string;

    constructor(init?: Partial<BankAccountRequestBaseModel>) {
        Object.assign(this, init);
    }
}
