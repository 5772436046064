import { ReactNode, createContext, useContext, useState } from 'react';

export type HelperErrorType = {
  key: string; 
  helpMessage: string;
}

type FormFieldsErrorProps = {
  formFieldsErrors: HelperErrorType[];
  setFormFieldsErrors: (value: HelperErrorType[]) => void;
};

const FormFieldsErrorContext = createContext<FormFieldsErrorProps>({
  formFieldsErrors: [],
  setFormFieldsErrors: (value: HelperErrorType[]) => { }
});

interface IChildren {
  children: ReactNode;
}

export function FormFieldsErrorsProvider({ children }: IChildren) {
  const [formFieldsErrors, setFormFieldsErrors] = useState<HelperErrorType[]>([]);
  return (
    <FormFieldsErrorContext.Provider
      value={{
        formFieldsErrors,
        setFormFieldsErrors
      }}
    >
      {children}
    </FormFieldsErrorContext.Provider>
  )
};

export const useFormFieldsError = () => useContext(FormFieldsErrorContext); 