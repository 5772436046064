import { Stack } from "@mui/material";
import { Button, OperatorIcon, Typography, UpdateIcon } from "@uy3/web-components";
import { iconSx } from "contexts/apiRequestContext";
import { activeTheme } from "services/theme";
import { ActionType } from "./MemberTabContainer";
import { useUserPermissionData } from "contexts/userContext";

type MemberHeaderProps = {
    refetch: () => void;
    setActionType: (type: ActionType) => void;
};
const theme = activeTheme();

export function MemberHeader({ refetch, setActionType }: MemberHeaderProps) {
    const { hasPermission } = useUserPermissionData();
    return (
        <Stack direction="row" justifyContent="space-between" alignItems='center' mr={3} mt={3}>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="25px"
                lineHeight="38.4px"
            >
                Membros
            </Typography>
            <Stack direction='row' alignItems='center' gap={1}>
                <Button
                    variant="text"
                    disabled={false}
                    onClick={() => refetch()}
                    size="medium"
                    sx={{ color: '#000' }}
                    startIcon={
                        <UpdateIcon
                            htmlColor={theme.palette.common.black}
                            sx={iconSx}
                        />
                    }
                >
                    Atualizar
                </Button>
                {hasPermission('UserGroup', 'Update') &&
                    <Button
                        variant="text"
                        disabled={false}
                        onClick={() => setActionType({ action: 'addNewMember', value: undefined })}
                        size="medium"
                        sx={{ color: '#000' }}
                        startIcon={
                            <OperatorIcon
                                htmlColor={theme.palette.common.black}
                                sx={iconSx}
                            />
                        }
                    >
                        Novo membro
                    </Button>}
            </Stack>
        </Stack>
    );
};

export default MemberHeader;
