/* eslint-disable react-hooks/exhaustive-deps */
import { UF } from 'services/zipCode/zipcode.types';
import InformationBankAccountRequestTab from './InformationTab';
import { usePersonsList } from 'contexts/personContext';
import { useParams } from 'react-router';
import { useBankAccountProductsList, useProductAccountData } from 'contexts/bankAccountProduct';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useEffect, useState } from 'react';
import { ToastType, showErrorToast, toastState } from 'contexts/apiRequestContext';
import { Modal } from '@uy3/web-components';
import { useNaturalPersonData } from 'contexts/naturalPersonContext';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { useLegalPersonData } from 'contexts/legalPersonContext';
import {
    defaultValuesImportSignatoriesSchema,
    validationApproveImportSignatoriesSchemaSchema,
} from 'pages/CreditNote/CreditNoteForm/Tabs/Modals/ImportSignatoriesSchema';
import { ImportSignatories } from 'pages/CreditNote/CreditNoteForm/Tabs/Modals/ImportSignatories';
import { FieldValues } from 'react-hook-form';
import Toast from 'components/Toast/Toast';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { useBankAccountRequestData } from 'contexts/bankAccount/bankAccountRequest';
import { arraysAreEqual, assignToRelatedPersons } from 'helpers';
import DrawerAddPerson from 'components/DrawerAddPerson/DrawerAddPerson';
import { RelatedPerson, RelatedPersonFull } from 'services/creditProduct';
import { useEnumContext } from 'contexts/enumContext';
import { useEconomicActivityCodeListData } from 'contexts/economicActivityCode';

export const InformationBankAccountRequestTabContainer = () => {
    const { bankAccountId } = useParams();
    const isNewBankAccount = bankAccountId === 'nova';
    const { watch, setValue } = useFormContext();
    const [openModal, setOpenModal] = useState<boolean>(false);
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const { bankAccountProductAutoCompleteProps } = useBankAccountProductsList(
        { page: 0, size: 10 },
        'always'
    );
    const [toast, setToast] = useState<ToastType>(toastState);
    const { bankAccountRequestData } = useBankAccountRequestData(bankAccountId!);
    const [isAddPerson, setIsAddPerson] = useState<boolean>(false);
    const { autoCompleteProps: legalNatureList } = useEnumContext({ enumName: 'LegalNature' });
    const { autoCompleteProps: economicActvCodeCompleteProps } = useEconomicActivityCodeListData();

    const taker = {
        ownerDiscriminator: watch('ownerDiscriminator'),
        ownerId: watch('ownerId')
    };

    const bankAccountProductId = watch('bankAccountProductId');

    const { naturalPersonData } = useNaturalPersonData(
        taker.ownerDiscriminator === 'NaturalPerson' ? taker.ownerId : ''
    );
    const { legalPersonData } = useLegalPersonData(
        taker.ownerDiscriminator === 'LegalPerson' ? taker.ownerId : ''
    );
    const { productAccData } = useProductAccountData(bankAccountProductId ?? 'novo');

    const showErrorMessage = (error: string) => {
        setOpenModal(false);
        const title = 'Ops, ocorreu um erro!';
        const description = `Corrija o erro e tente novamente. ${error}`;
        showErrorToast(title, description, setToast);
    };

    const handleLegalPersonData = (data: LegalPersonReadModel) => {
        if (!data) return;
        const {
            name,
            address,
            phone,
            economicActivityCodeId,
            numberOfEmployees,
            legalNature,
            averageMonthlyRevenue,
            economicActivityCode
        } = data;

        setValue('owner.name', name);
        setValue('owner.address', address);
        if (!address) return showErrorMessage('Endereço não cadastrado');

        setValue('owner.phone', phone);
        if (!phone) return showErrorMessage('Telefone não cadastrado');

        setValue('owner.economicActivityCodeId', economicActivityCodeId);
        setValue('owner.economicActivityCode.description', economicActivityCode?.description);
        if (!economicActivityCodeId) return showErrorMessage('CNAE não cadastrado');

        setValue('owner.numberOfEmployees', numberOfEmployees ?? 0);
        if (!numberOfEmployees) return showErrorMessage('Número de funcionários não cadastrado');

        setValue('owner.legalNature', legalNature);
        if (!legalNature) return showErrorMessage('Natureza jurídica não cadastrada');

        setValue('owner.averageMonthlyRevenue', averageMonthlyRevenue);
        if (!averageMonthlyRevenue) return showErrorMessage('Faturamento mensal não cadastrado');
    };

    const handleNaturalPersonData = (data: NaturalPersonReadModel) => {
        if (!data) return;
        const { name, address, phone, totalIncome, economicActivityCodeId, economicActivityCode } =
            data;

        setValue('owner.name', name);
        setValue('owner.address', address);
        if (!address) return showErrorMessage('Endereço não cadastrado');

        setValue('owner.phone', phone);
        if (!phone) return showErrorMessage('Telefone não cadastrado');

        setValue('owner.averageMonthlyRevenue', totalIncome);
        setValue('owner.economicActivityCodeId', economicActivityCodeId);
        setValue('owner.economicActivityCode.description', economicActivityCode?.description);
    };

    const isLegalPerson = taker.ownerDiscriminator === 'LegalPerson';
    const isNaturalPerson = taker.ownerDiscriminator === 'NaturalPerson';
    useEffect(() => {
        if (isNewBankAccount) {
            if (isLegalPerson) {
                handleLegalPersonData(legalPersonData);
            } else if (isNaturalPerson) {
                handleNaturalPersonData(naturalPersonData);
            }
        }
    }, [taker.ownerDiscriminator, legalPersonData, naturalPersonData]);

    const totalIncomeWatch = watch('owner.totalIncome');
    useEffect(() => {
        if (!isNewBankAccount && isNaturalPerson) {
            setValue('owner.averageMonthlyRevenue', totalIncomeWatch);
        }
    }, []);

    const involveds: RelatedPerson[] = watch('relatedPerson') ?? [];
    const signaturiesToImport: RelatedPersonFull[] = watch('signaturiesToImport') ?? [];

    const hasBankAccountProductIdEqual = involveds?.every((person: RelatedPerson) => {
        return person?.productId === bankAccountProductId;
    });

    useEffect(() => {
        const initializeImportFields = () => {
            setValue('containsImport', false);
            setValue('signaturiesToImport', []);
        };

        const mergeUniquePersons = (existing: RelatedPerson[], newPersons: RelatedPerson[]): RelatedPerson[] => {
            const mergedPersons = [...existing];
            newPersons.forEach((person) => {
                if (
                    !mergedPersons.some(
                        (p) =>
                            p.personId === person.personId &&
                            p.typeOfRelationship === person.typeOfRelationship
                    )
                ) {
                    mergedPersons.push(person);
                }
            });
            return mergedPersons;
        };

        const setRelatedPersons = (persons: RelatedPerson[]) => {
            if (!(involveds?.length && hasBankAccountProductIdEqual)) {
                setValue('relatedPerson', assignToRelatedPersons(persons, bankAccountProductId));
            }
        };

        const processPersons = (persons: RelatedPerson[], fallbackPersons: RelatedPerson[]) => {
            if (persons.length > 0) {
                const updatedPersons = mergeUniquePersons(involveds, persons);
                if (!arraysAreEqual<RelatedPerson>(involveds, updatedPersons)) {
                    setRelatedPersons(updatedPersons);
                }
            } else {
                const allRelatedPersons = fallbackPersons.filter(
                    (person) => !signaturiesToImport.some((s) => s.personId === person.personId)
                );
                setValue('relatedPerson', allRelatedPersons);
            }
        };

        if (!taker.ownerId) {
            initializeImportFields();
        }

        const productRelatedPersons = productAccData?.relatedPerson ?? [];
        const bankAccountRelatedPersons = bankAccountRequestData?.relatedPerson ?? [];
        const productIdHasChanged = bankAccountProductId !== bankAccountRequestData?.bankAccountProductId;

        if (isNewBankAccount || productIdHasChanged) {
            processPersons(productRelatedPersons, bankAccountRelatedPersons);
        }
    }, [productAccData, bankAccountProductId, isNewBankAccount]);

    useEffect(() => {
        const legalPersonRelated: RelatedPersonFull[] = legalPersonData?.relatedPerson ?? [];

        if (bankAccountId === 'nova' && legalPersonRelated?.length > 0) {
            const list = legalPersonRelated.map((person) => {
                return {
                    ...person,
                    relatedToId: legalPersonData?.id,
                    relatedToIdDisplay: legalPersonData?.name,
                    isSigner: true,
                };
            });
            setValue('signaturiesToImport', list);
            !watch('containsImport') && setOpenModal(true);
        }
    }, [legalPersonData]);

    const personsToImportList = legalPersonData?.relatedPerson?.map((person: RelatedPerson) => {
        return { label: person.personIdDisplay, value: person.personId };
    });

    const handleConfirmSignatories = ({ personsToImport }: FieldValues) => {
        const currentRelatedPerson = productAccData?.relatedPerson ?? [];
        const personsSelected: RelatedPersonFull[] = signaturiesToImport.filter((item) =>
            personsToImport.includes(item?.personId)
        );

        setValue(
            'relatedPerson',
            assignToRelatedPersons([...currentRelatedPerson, ...personsSelected], bankAccountProductId)
        );
        setValue('containsImport', true);
        setOpenModal(false);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setIsAddPerson(false);
    };

    return (
        <>
            <DrawerAddPerson
                open={isAddPerson}
                onClose={handleCloseModal}
                isAddPerson={isAddPerson}
            />
            <Toast toast={toast} setToast={setToast} />
            <InformationBankAccountRequestTab
                bankAccountId={bankAccountId!}
                bankAccountProductList={bankAccountProductAutoCompleteProps}
                titularList={personAutoCompleteProps}
                UFList={UF}
                setIsAddPerson={setIsAddPerson}
                enableShortcut={isNewBankAccount}
                legalNatureList={legalNatureList.listOptions}
                cnaeList={economicActvCodeCompleteProps}
            />

            <Modal
                open={openModal}
                onClose={handleCloseModal}
                title="Importar signatários"
                description="Selecione quem você deseja importar como envolvido da conta"
                sizeModal="small"
                children={
                    <FormProvider
                        defaultValues={defaultValuesImportSignatoriesSchema}
                        validationSchema={validationApproveImportSignatoriesSchemaSchema()}
                        onSubmit={handleConfirmSignatories}
                    >
                        <ImportSignatories
                            onClose={handleCloseModal}
                            personsToImportList={personsToImportList}
                        />
                    </FormProvider>
                }
            />
        </>
    );
};
