import { FormProvider, useFormContext } from 'contexts/formContext';
import { validationSchemaClosureRevision } from './ClosureRevisionSchema';
import { useLiquidationScheduleClosureRevision } from 'contexts/liquidationSchedule';
import { ApiResponseError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { BankDetails } from 'pages/BankAccount/BankDetailsTab/BankDetails';

type TLiquidationScheduleClosure = {
    creditNoteId: string;
    onErrorCatch: (error: ApiResponseError) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    navigateWithFilters: (path: string) => void;
    handleOnClose: () => void;
};

export const LiquidationScheduleClosureRevision = ({
    creditNoteId,
    onErrorCatch,
    setToast,
    navigateWithFilters,
    handleOnClose,
}: TLiquidationScheduleClosure) => {
    const { watch} = useFormContext();
    const bankAccounts = watch('changeBankAccount') ?? [];

    const onSuccess = () => {
        showSuccessToast('Operação realizada com sucesso', undefined, setToast);
        navigateWithFilters(`/ccb/operacoes/${creditNoteId}`);
        handleOnClose();
    };

    const { mutateClosureRevision } = useLiquidationScheduleClosureRevision(
        creditNoteId,
        onSuccess,
        onErrorCatch
    );

    return (
        <FormProvider
            validationSchema={validationSchemaClosureRevision()}
            defaultValues={bankAccounts}
            onSubmit={mutateClosureRevision}
        >
            <BankDetails onClose={handleOnClose} />
        </FormProvider>
    );
};
