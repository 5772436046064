import { string, object } from 'yup';

export const validationSchemaRejectSignatureForm = () => {
    return object().shape({
        message: string().nullable()
    });
};

export const defaultValuesRejectSignatureForm = {
    message: null
};