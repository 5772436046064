import { isValidString } from 'helpers/formats/StringFormats';
import { boolean, number, object, string } from 'yup';


export const validationChatbotSchema = () => {
  return object().shape({
    productId: string().required("Produto: precisa ser preenchido").typeError("Produto: precisa ser preenchido"),
    serviceQueueId: string().required("Fila: precisa ser preenchida").typeError("Fila: precisa ser preenchida"),
    companyName: string().required("Nome da empresa: precisa ser preenchido").typeError("Nome da empresa: precisa ser preenchido"),
    originCampaign: string().required("Origem da campanha: precisa ser preenchido").typeError("Origem da campanha: precisa ser preenchido"),
    botPhone: string().required("Telefone: precisa ser preenchida").typeError("Telefone: precisa ser preenchida"),
    incomingMessage: string().required("Mensagem: precisa ser preencida").typeError("Mensagem: precisa ser preencida"),
    invoiceValue: number().nullable().notRequired(),
    isDefault: boolean().nullable(),
    personId: string().nullable().notRequired(),
    beneficiaryId: string().when(['productCategoryName'], {
      is: (productCategoryName: string) => {
        return isValidString(String(productCategoryName)) && (productCategoryName??"")?.toLowerCase() === 'cdc'
      },
      then: string()
        .required('Beneficiário: Precisa ser preenchido.')
        .typeError('Beneficiário: Precisa ser preenchido.'),
      otherwise: string().nullable(),
    }),
    bankAccountId: string().when(['beneficiaryId'], {
      is: (beneficiaryId: string) => {
        return beneficiaryId !== null && isValidString(String(beneficiaryId))
      },
      then: string()
        .required('Conta do beneficiário: Precisa ser preenchido.')
        .typeError('Conta do beneficiário: Precisa ser preenchido.'),
      otherwise: string().nullable(),
    })
  });
};

export const defaultValuesChatbot = {
  productId: null,
  serviceQueueId: null,
  companyName: null,
  originCampaign: null,
  botPhone: null,
  incomingMessage: null,
  isDefault: false,
  person: null,
  beneficiary: null,
  invoiceValue: 0,
  personId: null,
  personName: null,
  beneficiaryId: null,
  beneficiaryName: null,
  bankAccountId: null,
  bankAccountIdDisplay: null,
};