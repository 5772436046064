import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { FilterProvider } from 'contexts/filterContext';
import React, { FunctionComponent } from 'react';
import { CreditNoteListApprovals } from './CreditNoteListApprovals';
import { GridCallbackDetails, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';

interface CreditApprovListWithFiltersProps {
    personAutocompleteOptions: any;
    optionsTenantList: any;
    rowsPerPage: number;
    page: number;
    pendingApprovalsData: any;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    onChangeRowsPerPage: (page: number) => void;
    onChangePage: (page: number) => void;
    isLoading: boolean;
    rowsSelected: GridRowId[];
    setRowAction: any;
}

const CreditNoteApprovListWithFilters: FunctionComponent<CreditApprovListWithFiltersProps> = ({
    optionsTenantList,
    personAutocompleteOptions,
    isLoading,
    handleSelectionModelChange,
    rowsSelected,
    page,
    onChangePage,
    rowsPerPage,
    onChangeRowsPerPage,
    setRowAction,
    pendingApprovalsData,
}) => {
    return (
        <div>
            <FilterProvider
                availableFilters={{
                    tenant: {
                        label: 'Correspondente',
                        type: 'autocomplete',
                        multiple: false,
                        options: optionsTenantList,
                    },
                    personId: {
                        label: 'Tomador',
                        type: 'autocomplete',
                        multiple: false,
                        options: personAutocompleteOptions?.listOptions,
                    },
                    status: {
                        label: 'Status',
                        type: 'select',
                        multiple: true,
                        options: creditNoteSelectFilterOptions,
                    },
                    creditNoteNo: {
                        label: 'Número da operação',
                        type: 'text',
                        multiple: false,
                    },
                    initialDate: {
                        label: 'Data de início',
                        type: 'dateRange',
                        multiple: false,
                        dateRangeName: 'initialDate',
                    },
                    maxValue: { label: 'Valor máximo', type: 'text', multiple: false },
                    minValue: { label: 'Valor mínimo', type: 'text', multiple: false },
                }}
            >
                <ListSkeleton isLoading={isLoading}>
                    <CreditNoteListApprovals
                        handleSelectionModelChange={handleSelectionModelChange}
                        rowsSelected={rowsSelected}
                        page={page}
                        setPage={onChangePage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setRowAction={setRowAction}
                        queryData={pendingApprovalsData}
                    />
                </ListSkeleton>
            </FilterProvider>
        </div>
    );
};

export default CreditNoteApprovListWithFilters;
