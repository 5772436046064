import { BankAccountFull } from "contexts/apiRequestContext";
import { CreateBeneficiaryType, CreateTransferType } from "contexts/bankAccount/bankAccountType";
import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { CreditNoteReadModel } from "services/creditNote/types/creditNoteReadModel";

const url = "/Approvals";

type BankAccountPaymentType = {
    barCode: string;
    NSU: string;
    amount: string
    dateTimeOffset: Date;
    paymentPurpose: string;
    PaymentPurposeDisplay: string;
    status: string;
    statusDisplay: string;
    bankAccountId: string;
}

type BankAccountOperator = {
    userId: string;
    userIdDisplay: string;
    tenantDisplay: string;
}

type BankAccountUserType = {
    user: BankAccountOperator;
    level: string;
    levelDisplay: string;
    status: string;
    statusDisplay: string;
    isDeleted: boolean;
    bankAccountId: string;
    accountNumber: string;
    ownerNameAccount: string;
    bankAccountRequestId: string;
    registrationNumber: string;
}

type TransactionLimitValuesType = {
    valueInCentsOwnOwnership: number;
    valueInCentsNaturalPerson: number;
    valueInCentsLegalPerson: number;
}

export type TransactionLimitsType = {
    type: string;
    values: DailyTransactionLimitsType
}
export type DailyTransactionLimitsType = {
    businessHours: TransactionLimitValuesType
    nonBusinessHours: TransactionLimitValuesType
}

type BankAccountTransactionsLimits = {
    status: string;
    statusDisplay: string;
    pixLimit: DailyTransactionLimitsType;
    bankSlipLimit: DailyTransactionLimitsType;
    transferLimit: DailyTransactionLimitsType;
    tedLimit: DailyTransactionLimitsType;
    updateStatusUser: string;
    bankAccountId: string;
}

export type TransactionsLimitsType = {
    pixLimit: DailyTransactionLimitsType;
    bankSlipLimit: DailyTransactionLimitsType;
    transferLimit: DailyTransactionLimitsType;
    tedLimit: DailyTransactionLimitsType;
}

export type ApprovalsFull = {
    id: string;
    bankAccountPaymentId: string;
    bankAccountBeneficiaryId: string;
    bankAccountTransferId: string;
    bankAccountUserId: string;
    bankAccountTransactionLimitsId: string;
    creditNoteOpId: string;
    bankAccountRequestId: string;
    bankAccountPayment: BankAccountPaymentType;
    bankAccountBeneficiary: CreateBeneficiaryType;
    bankAccountTransfer: CreateTransferType;
    bankAccountUser: BankAccountUserType;
    bankAccountTransactionLimits: BankAccountTransactionsLimits;
    creditNoteOp: CreditNoteReadModel;
    BankAccountRequest: BankAccountFull;
    approvalResource: string;
    approvalResourceDisplay: string;
    approvalType: string;
    approvalTypeDisplay: string;
    approvers: [];
}

export type ApproveBankAccountApprovalsType = {
    action: string,
    sessionId: string,
    code: string,
    type: string,
    status: string
}

type AvailableValues =
    "BankAccountTransfer" |
    "BankAccountTransactionLimits" |
    "BankAccountUser" |
    "BankAccountBeneficiary" |
    "BankAccountPayment" |
    "BankAccountRequest" |
    "CreditNoteOp"

export type ApprovalsProps = {
    page: number,
    size: number,
    approvalResource?: AvailableValues,
    personId?: string;
    orderBy?: string;
    tenantDisplay?: string;
    status?: string;
    creditNoteNo?: number;
    initialDate?: string;
    finalDate?: string;
}

export const getEndpointToCreditNoteApprove = (type: string): string => {
    const options: { [type: string]: string } = {
        "CreditNoteOpComplianceApproval": "approveCompliance",
        "CreditNoteOpCreditApproval": "approveCredit",
        "CreditNoteOpInstrumentApproval": "approveInstrument",
        'CreditNoteOpSignature': 'signature',
        'CreditNoteOpSignatureValidationApproval': 'approveSignature',
        "CreditNoteOpLiquidationApproval": "approveLiquidation",
        "CreditNoteOpPaymentRevisionApproval": "donePaymentRevision"
    };
    return options[type];
}

export const getApprovals = async (filters: ApprovalsProps, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await creditAxiosApi.get(url, config);
}

export const postApproveCreditNoteOp = async (type: string, id: string, token: string) => {
    let url = `CreditNote/${id}/${type}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.post(url, {}, config);
}

export const approveBankAccountApprovals = async ({ type, ...payload }: ApproveBankAccountApprovalsType, token: string) => {

    const optionsRequest: { [type: string]: string } = {
        "BankAccountTransfer": `/Transfer/BatchApproval`,
        "BankAccountUser": `/Operator/BatchApproval`,
        "BankAccountBeneficiary": `/Beneficiary/BatchApproval`,
        "BankAccountPayment": `/Pay/BatchApproval`,
        "BankAccountTransactionLimits": `/Limits/ApproveOrRejectByBatch`
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    if (type === 'BankAccountTransactionLimits') {
        payload = {
            ...payload,
            status: payload.action === 'Approve' ? 'Approved' : 'Rejected'
        }
    }

    return await creditAxiosApi.put(`BankAccount${optionsRequest[type]}`, payload, config);
}