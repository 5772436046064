import { validationSchemaDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { isValidString } from 'helpers/formats/StringFormats';
import { handleNumericNaN } from 'helpers';
import { string, boolean, object, number, lazy, InferType, array } from 'yup';

const isNullOrEmpty = (value: string | null) => {
    return value == null || value === "";
};

export function validationSchemaFundForm() {
    return object().shape({
        codeWalletCerc: string().nullable().typeError('Código de Carteira CERC inválido.'),
        externalId: string().typeError('Código externo inválido.').required('Código externo: precisa ser preenchido.'),
        administratorPersonId: string().typeError('Administrador inválido.').nullable().notRequired(),
        creditApproverTenantName: string().typeError('Aprovador de Crédito inválido.').nullable().notRequired(),
        InstrumentApproverTenantName: string().typeError('Aprovador de Instrumento inválido.').nullable().notRequired(),
        name: string().typeError('Nome é obrigatório.').required("Nome: Precisa ser preenchido."),
        companyName: string().typeError('Razão social é obrigatório.').required("Razão social: Precisa ser preenchido."),
        registrationNumber: string().typeError('CNPJ inválido.').required("CNPJ: Precisa ser preenchido.").cpfCnpjValidation("CNPJ inválido."),
        email: string().typeError('E-mail inválido.').required('Campo "E-mail" é obrigatório').email("E-mail inválido"),
        allowIndividualAssignment: boolean().nullable(),
        templateDoc: object().shape({
            fileName: string().nullable(),
        }).transform((value, _) => {
            return value?.fileName == null ? null : value;
        }).nullable(),
        templateBatchPath: object().shape({
            fileName: string().nullable(),
        }).transform((value, _) => {
            return value?.fileName == null ? null : value;
        }).nullable(),
        assignmentType: string().typeError('Tipo de Contrato é obrigatório.').required("Tipo de Contrato: Precisa ser preenchido."),
        assignmentCalculation: object().shape({
            mode: string().typeError('Modelo de cálculo inválido.').required("Modelo de cálculo: Precisa ser preenchido."),
            amount: number().typeError('Valor precisa ser um número.')
                .required("Valor: Precisa ser preenchido.").transform(handleNumericNaN),
            type: number().typeError('Tipo de taxa é obrigatório.').required("Tipo de taxa: Precisa ser preenchido.")
        }),
        ftpHostName: string().when("assignmentType", {
            is: 1,
            then: string().typeError('Host-FTP inválido').required("Host-FTP: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        ftpUser: string().when("assignmentType", {
            is: 1,
            then: string().typeError('Usuário-FTP inválido').required("Usuário-FTP: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        ftpPassword: string().when("assignmentType", {
            is: 1,
            then: string().typeError('Senha-FTP inválido').required("Senha-FTP: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        pathLastro: string().nullable(),
        pathCnab: string().nullable(),
        signerMinimumQuorum: number().typeError('Valor precisa ser um número.').required("Valor: Precisa ser preenchido."),
        signaturePortalProvider: string().typeError('Selecione a Certificadora.').required("Selecione a Certificadora."),
        maximumValueWithoutDigitalSignature:
            number()
                .typeError('Valor precisa ser um número.')
                .test('length', 'Valor precisa ser no máximo R$ 2.000.000,00', (value) => {
                    return value! <= 200000000;
                }).nullable().nullable(),
        titleSpecies: number().typeError('Espécie de Título precisa ser um número.').nullable(),
        bankAccount: lazy(value => {
            var nothingIsFiled = isNullOrEmpty(value?.agency) && isNullOrEmpty(value?.account) && isNullOrEmpty(value?.bankCode)
                && isNullOrEmpty(value?.type) && isNullOrEmpty(value?.accountDigit) && isNullOrEmpty(value?.agencyDigit);
            return nothingIsFiled ? object().nullable().transform((value, _) => {
                return Object.values(value ?? {}).every(x => x === null) ? null : value;
            }) : object().shape({
                bankCode: string().typeError('Selecione o código bancário.').required("Código bancário: Precisa ser preenchido."),
                agency: number().typeError('Valor precisa ser um número.').required("Agência: Precisa ser preenchido.").max(9999, "Agência: Precisa ter no máximo 4 caracteres."),
                agencyDigit: string().nullable().max(1, "Dígito da agência: Precisa ter no máximo 1 caracter."),
                account: number().typeError('Valor precisa ser um número.').required("Conta: Precisa ser preenchido."),
                accountDigit: string().nullable().max(1, "Dígito da conta: Precisa ter no máximo 1 caracter."),
                type: string().typeError('Selecione o tipo de Conta.').required("Tipo de Conta: Precisa ser preenchido.")
            }).transform((value, _) => {
                return Object.values(value ?? {}).every(x => x === null) ? null : value;
            });
        }),
        cnabType: string().typeError('CNAB inválido.').required("CNAB: Precisa ser preenchido."),
        administrator: string().nullable(),
        manager: string().nullable(),
        address: object().shape({
            addressName: string().typeError('Endereço inválido.').required("Endereço: Precisa ser preenchido."),
            zipCode: string().typeError('CEP inválido.').required("CEP: Precisa ser preenchido."),
            city: string().typeError('Cidade inválida.').required("Cidade: Precisa ser preenchido."),
            uf: string().typeError('UF inválida.').required("UF: Precisa ser preenchido."),
            district: string().typeError('Bairro inválido.').required("Bairro: Precisa ser preenchido."),
            number: number().typeError('Valor precisa ser um número.').required("Número: Precisa ser preenchido."),
            complement: string().nullable()
        }),
        relatedDocs: array().of(validationSchemaDocForm()).nullable(),
        walletCode: string().nullable().notRequired(),
        billingSettingId: string().when("walletCode", {
            is: (value: string) => isValidString(value),
            then: string().typeError('Régua de cobrança: precisa ser preenchida').required("Régua de cobrança: precisa ser preenchida"),
            otherwise: string().nullable()
        }),
        communicationSettingId: string().when("walletCode", {
            is: (value: string) => isValidString(value),
            then: string().typeError('Régua de comunicação: precisa ser preenchida').required("Régua de comunicação: precisa ser preenchida"),
            otherwise: string().nullable()
        })
    });
};

let inferedSchema = validationSchemaFundForm();
export type FundFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesFundForm = {
    codeWalletCerc: null,
    externalId: null,
    administratorPersonId: "",
    administratorPersonIdDisplay: "",
    creditApproverTenantName: "",
    name: "",
    companyName: "",
    registrationNumber: "",
    email: "",
    templateDoc: null,
    templateBatchPath: null,
    assignment: false,
    collectAssignmentSignatures: false,
    allowIndividualAssignment: false,
    assignorCode: "",
    titleSpecies: null,
    assignmentType: 0,
    ftpHostName: "",
    ftpUser: "",
    ftpPassword: "",
    pathLastro: "",
    pathCnab: "",
    signerMinimumQuorum: 0,
    signaturePortalProvider: 0,
    maximumValueWithoutDigitalSignature: 0,
    bankAccount: null,
    cnabType: "444",
    administrator: "",
    manager: "",
    walletCode: null,
    billingSettingId: null,
    communicationSettingId: null,
};