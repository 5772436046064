import { Grid, Stack } from '@mui/material';
import { AvailableCashIcon, Button, CardData, CloseIcon, Typography } from '@uy3/web-components';
import { DatePickerFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import moment from 'moment';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';
import ViewTransactionalLimitsDrawer from '../../ViewTransactionalLimitsDrawer';
import { Radio } from 'components/Forms/Radio/Radio';
import { PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

type DetailsProps = {
    bankSlipData: PaymentBankSlip;
    onCloseDrawer: () => void;
    withPaymentAmount?: boolean;
};

const theme = activeTheme();
export const PaymentDetails = ({ bankSlipData, onCloseDrawer, withPaymentAmount = true }: DetailsProps) => {
    const { watch, setValue, submitting } = useFormContext();

    const transferDateRadioValue = () => {
        var check = watch('paymentDate') === moment().format('yyyy-MM-DD');
        return check ? 'today' : 'otherDay';
    };

    const otherDay = transferDateRadioValue() === 'otherDay'

    return (
        <>
            <Stack spacing={3} pt={2}>
                <Typography variant="lg" fontWeight="700" mb={2}>
                    Dados do beneficiário e pagador original
                </Typography>
                <Stack
                    direction="column"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid width="100%">
                        <CardData
                            listItem={[
                                { id: '1', title: 'Beneficiário' },
                                {
                                    id: '2',
                                    title: 'CPF/CNPJ',
                                    value: `${bankSlipData?.beneficiary.document === null
                                            ? 'N/D'
                                            : formatDocumentNumber( bankSlipData?.beneficiary.document)
                                        }`,
                                },
                                {
                                    id: '3',
                                    title: 'Nome/Razão Social',
                                    value: `${bankSlipData?.beneficiary?.name === null
                                            ? 'N/D'
                                            : bankSlipData?.beneficiary?.name
                                        }`,
                                },
                            ]}
                        />
                    </Grid>
                    <Grid width="100%">
                        <CardData
                            listItem={[
                                { id: '1', title: 'Pagador' },
                                {
                                    id: '2',
                                    title: 'Nome completo',
                                    value: `${bankSlipData?.payer.name === null
                                            ? 'N/D'
                                            : bankSlipData?.payer.name
                                        }`,
                                },
                                {
                                    id: '3',
                                    title: 'CPF/CNPJ',
                                    value: `${bankSlipData?.payer.document === null
                                            ? 'N/D'
                                            : formatDocumentNumber(bankSlipData?.payer.document)
                                        }`,
                                },
                            ]}
                        />
                    </Grid>

                    <Grid container justifyContent={'flex-start'}>
                        <Typography variant="lg" fontWeight="700">
                            Detalhes do boleto
                        </Typography>
                    </Grid>

                    <Grid width="100%">
                        <CardData
                            listItem={[
                                {
                                    id: '1',
                                    title: 'Data de vencimento',
                                    value: `${moment(bankSlipData?.dueDate).format('DD/MM/yyyy')}`,
                                },
                                {
                                    id: '2',
                                    title: 'Valor nominal',
                                    value: `${formatCurrencyInCents(bankSlipData?.nominalValue)}`,
                                },
                                {
                                    id: '3',
                                    title: 'Encargos/juros',
                                    value: `${formatCurrencyInCents(bankSlipData?.fee)}`,
                                },
                                {
                                    id: '4',
                                    title: 'Descontos',
                                    value: `${formatCurrencyInCents(bankSlipData?.discount)}`,
                                },
                                {
                                    id: '5',
                                    title: 'Multa',
                                    value: `${formatCurrencyInCents(bankSlipData?.trafficTicket)}`,
                                },
                                {
                                    id: '6',
                                    title: 'Valor total',
                                    value: `${bankSlipData?.totalValue !== null
                                            ? formatCurrencyInCents(bankSlipData?.totalValue)
                                            : "N/D"
                                        }`,
                                },
                            ]}
                        />
                    </Grid>

                    <Grid container justifyContent={'flex-start'}>
                        <Typography variant="lg" fontWeight="700">
                            Quando pagar?
                        </Typography>
                    </Grid>

                    <Stack spacing={2} width="100%">
                        <Grid
                            item
                            display="flex"
                            alignItems="center"
                            mb={3}
                            width={500}
                            height={30}
                        >
                            <Radio
                                options={[
                                    {
                                        label: `Hoje ${moment().format('DD/MM/YYYY')}`,
                                        value: 'today',
                                    },
                                    { label: `Agendar para`, value: 'otherDay' },
                                ]}
                                onChange={(event) => {
                                    let option = event.target.value;
                                    option === 'today'
                                        ? setValue('paymentDate', moment().format('yyyy-MM-DD'))
                                        : setValue('paymentDate', undefined);
                                }}
                                value={transferDateRadioValue()}
                                row
                            />
                        </Grid>
                        {otherDay && (
                            <Grid>
                                <DatePickerFormField
                                    label="Data do Pagamento"
                                    name="paymentDate"
                                    fullWidth
                                    shrink
                                    required
                                    disablePast
                                />
                            </Grid>
                        )}
                        {withPaymentAmount && <Grid item mt={3} xs={12}>
                            <CurrencyFormField
                                label="Valor do pagamento"
                                fullWidth
                                name="paymentValue"
                                variant="outlined"
                                required
                            />
                        </Grid>}
                    </Stack>
                </Stack>

                <Stack justifyContent={'flex-end'} alignItems={'flex-end'}>
                    <ViewTransactionalLimitsDrawer />
                </Stack>

                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={3}
                >
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        variant="outlined"
                        size="medium"
                        onClick={onCloseDrawer}
                    >
                        Fechar
                    </Button>
                    <Button
                        name="btn-pay"
                        type="submit"
                        variant="contained"
                        disabled={submitting}
                        startIcon={
                            <AvailableCashIcon
                                htmlColor={theme.palette.common.white}
                                sx={{ height: 19, width: 20 }}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        Pagar
                    </Button>
                </Stack>
            </Stack>
        </>
    );
};

export default PaymentDetails;
