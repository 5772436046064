import { IDesconto } from "services/creditNote/types/creditNoteReadModel";
import { InferType, object, string } from "yup";

export const openCreditNoteSchema = (isProductHasCommercialPapper?: boolean) => {

    return object().shape({
        productId: string().typeError('Selecione um produto').required('Selecione um produto'),
        personId: string().typeError('Tomador inválido').required('Tomador: Precisa ser preenchido.'),
        bankAccountId: string().typeError('Conta inválida').required('Valor obrigatório para liquidações via TED.'),
        fundId: string().typeError("Dundo: precisa ser preenchido").required("Dundo: precisa ser preenchido"),
        quantity: string().when('field', {
            is: () => !!isProductHasCommercialPapper,
            then: string()
                .typeError('Quantidade de notas precisa ser preechido.')
                .required('Quantidade de notas: precisa ser preechido.'),
            otherwise: string().nullable(),
        }),

        unitPrice: string().when('field', {
            is: () => !!isProductHasCommercialPapper,
            then: string()
                .typeError('Valor unitário precisa ser preechido.')
                .required('Valor unitário: precisa ser preechido.'),
            otherwise: string().nullable(),
        }),
    });
};

export const openCreditNoteDefaultValues = (descontoData: IDesconto | null) => {
    return {
        personId: descontoData?.personId ?? null,
        personDisplay: descontoData?.personId !== null ? descontoData?.personDisplay ?? null : null,
        personDiscriminator: descontoData?.personDiscriminator ?? null,
        productId: null,
        fundId: null,
        bankAccountId: null,
        quantity: null,
        unitPrice: null
    }
};

let inferedSchema = openCreditNoteSchema();

export type OpenCreditNoteType = InferType<typeof inferedSchema>;