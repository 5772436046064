import { Grid, IconButton, Stack } from '@mui/material';
import { CheckboxFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import CustomAccordionContainer from '../../../../../../../components/Custom/CustomAccordion/CustomAccordionContainer';
import { useFormContext } from 'contexts/formContext';
import { StyledTooltip } from 'components/Custom/CustomTooltip/Tooltip';
import { InfoIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
interface CommissionCostsFieldsProps {
    index: number;
}
export const CommissionFormCostsAccordionMetadataKey = 'meta.currentAccordion';

const InformationTooltip = () => {
    return (
        <StyledTooltip title={<InfoTooltipContent />} placement="right-start">
            <IconButton aria-label="info-tooltip">
                <InfoIcon sx={iconSx} />
            </IconButton>
        </StyledTooltip>
    );
};

const InfoTooltipContent = () => (
    <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
        <Grid item>
            <Typography variant="xs" textAlign="center">
                Tarifa de cadastro
            </Typography>
        </Grid>
        <Grid
            item
            sx={{
                textAlign: 'start',
                inlineSize: '200px',
                overflowWrap: 'break-word',
                lineHeight: '16.8px',
            }}
        >
            Se ativo, não aplicará cobrança desse custo caso o cliente já possua operações
            encerradas no período definido no campo 'Validade padrão de cadastro (dias)'. Se
            desativado, a cobrança do custo será realizada em todas as operações.
        </Grid>
    </Grid>
);

const CommissionFormCosts: React.FC<CommissionCostsFieldsProps> = ({ index }) => {
    const { watch } = useFormContext();

    const bankComissionType = watch(`details.${index}.type`);
    const bankComissionDescription = watch(`details.${index}.name`);

    return (
        <CustomAccordionContainer
            index={index}
            description={bankComissionDescription ?? `Custo #${index + 1}`}
        >
            <Stack direction="column" spacing={2}>
                <TextFormField
                    required
                    fullWidth
                    label="Descrição do custo"
                    name={`details.${index}.name`}
                    variant="outlined"
                />
                <SelectFormField
                    fullWidth
                    required
                    label="Tipo do custo de emissão"
                    name={`details.${index}.type`}
                    options={[
                        { label: 'Valor Fixo', value: 'Absolute' },
                        { label: 'Percentual', value: 'Percentage' },
                    ]}
                />
                {bankComissionType === 'Absolute' && (
                    <CurrencyFormField
                        required
                        variant="outlined"
                        fullWidth
                        name={`details.${index}.amount`}
                        label="Valor do custo de emissão"
                    />
                )}
                {bankComissionType === 'Percentage' && (
                    <>
                        <PercentageFormField
                            required
                            name={`details.${index}.amount`}
                            label="Valor do custo de emissão (%)"
                            fullWidth
                            variant="outlined"
                        />

                        <SelectFormField
                            required
                            fullWidth
                            label="Tipo base da comissão"
                            name={`details.${index}.baseValue`}
                            options={[
                                { label: 'Valor inicial da dívida', value: 'InitialValue' },
                                { label: 'Valor solicitado pelo tomador', value: 'RequestedValue' },
                            ]}
                        />
                    </>
                )}
            </Stack>
            <Stack mx={0.2} my={1} direction="row" alignItems="center">
                <CheckboxFormField
                    label="Isenção de TC"
                    color="primary"
                    name={`details.${index}.isCustomerRegistrationFee`}
                />
                <Grid item mt={1.4}>
                    <InformationTooltip />
                </Grid>
            </Stack>
        </CustomAccordionContainer>
    );
};

export default CommissionFormCosts;
