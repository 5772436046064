import { Stack } from '@mui/material'
import { Button, CancelIcon, SaveIcon } from '@uy3/web-components'
import { SelectFormField, SelectOption } from 'components/Forms/FormFields'
import React from 'react'
import { activeTheme } from 'services/theme'

type SendRemessaProps = {
    onClose: () => void;
    isFetching: boolean;
    optionsRelatedDocs: SelectOption[];
}

const theme = activeTheme();

export const SendRemessa: React.FC<SendRemessaProps> = ({ onClose, isFetching, optionsRelatedDocs }) => {
    return (
        <Stack spacing={2} mt={1}>
            <SelectFormField
                label='Caminho'
                name='path'
                fullWidth
                options={optionsRelatedDocs}
            />

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    onClick={onClose}
                    variant='outlined'
                    startIcon={<CancelIcon htmlColor={theme.palette.primary.main} />}
                >
                    Fechar
                </Button>
                <Button
                    type='submit'
                    variant='contained'
                    disabled={isFetching}
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} />}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
