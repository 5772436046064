import { Stack } from '@mui/material'
import { AddIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'
import { useNavigate } from 'react-router'

type ChatbotListHeaderProps = {
  refetch: () => void
}

export const ChatbotListHeader: React.FC<ChatbotListHeaderProps> = ({ refetch }) => {
  const navigate = useNavigate();
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between'>
      <Typography
        variant="h4"
        color="neutral.dark"
        fontStyle="normal"
        fontWeight="700"
        fontSize="32px"
        lineHeight="38.4px"
      >
        Chatbot
      </Typography>

      <Actions
        numberOfButtons={3}
        buttonsActionsList={[
          {
            enable: true,
            label: 'Atualizar',
            action: refetch,
            icon: <UpdateIcon />,
          }, 
          {
            enable: true,
            label: 'Novo registro de entrada',
            action: () => navigate('/chatbot/novo'),
            icon: <AddIcon sx={iconSx}/>,
          }, 
        ]}
      />
    </Stack>
  )
}
