import { Stack } from '@mui/material';
import React from 'react';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { FieldValues } from 'react-hook-form';
import SummaryChargesCardData from './SummaryChargesCardData';

interface SummaryChargesProps {
    formValues: FieldValues;
}

export const SummaryCharges: React.FC<SummaryChargesProps> = ({ formValues }) => {
    const amount = formValues?.amount;

    return (
        <Stack spacing={4}>
            <SummaryChargesCardData formValues={formValues} />
            <HorizontalInfo type="currency" value={amount} />
        </Stack>
    );
};
