import { creditAxiosApi } from "services/axiosApi/axiosApi";

let url = "/Cnab";

export interface IReadRemessa {
    dataRepasse: string;
    dataGarantia: Date | string;
    persist: boolean;
    queryCaixa: boolean;

}
export interface IRemessaLiquidacaoForDate {
    date: string;
    fundId: string
}

export interface IRemessaLiquidacaoForOp {
    date: string;
    opId: string;
    incluirVencidos: boolean;
    liquidateByvalue: string
}

export type ResponseErrorSendMessageType = {
    Message: string,
    Errors: string,
    id_unico: string,
    retorno: number,
    mensagem: string,
    conteudo: number,
    conteudoLista: string
}

export const readRemessa = async (file: File | null, token: string) => {

    const config = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': `multipart/form-data`
        }
    };

    return await creditAxiosApi.post(`${url}/Cnab600/read`, {
        file
    }, config);
};
