import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { ICommunicationSettingFilters, ICommunicationSettingFull, ICreateCommunicationSetting, IUpdateCommunicationSetting } from "./communicationSetting.types";

const url = `/CommunicationSetting`

export const getCommunicationSettingListAsync = async ({page = 0, size = 10, ...rest}: ICommunicationSettingFilters, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            page, 
            size, 
            ...rest
        }
    };

    return await billingAxiosApi.get(url, config);
};

export async function getCommunicationSettingByIdAsync(communicationSettingId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    return await billingAxiosApi.get<ICommunicationSettingFull>(`${url}/${communicationSettingId}`, config);
}

export async function updateCommunicationSettingAsync(communicationSettingId: string, payload: IUpdateCommunicationSetting, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    return await billingAxiosApi.patch(`${url}/${communicationSettingId}`,  payload, config);
}

export async function createCommunicationSettingAsync(payload: ICreateCommunicationSetting, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await billingAxiosApi.post(`${url}`,  payload, config);
}

export async function deleteCommunicationSettingAsync(communicationSettingId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    
    return await billingAxiosApi.delete(`${url}/${communicationSettingId}`, config);
}