/* eslint-disable react-hooks/exhaustive-deps */
import React, { ComponentProps, FC, useMemo } from 'react';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { font } from 'services/theme/typography';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import ViewTransactionalLimitsDrawer from '../../ViewTransactionalLimitsDrawer';
import CardsSelect from 'components/CardSelect/CardSelect';
import moment from 'moment';
import { Grid, IconButton, Stack } from '@mui/material';
import {
    Avatar,
    Button,
    CloseIcon,
    EditIcon,
    PersonIcon,
    TransferIcon,
    Typography,
    UY3Icon,
} from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { DatePickerFormField } from 'components/Forms/FormFields';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { CreateBeneficiaryType } from 'contexts/bankAccount/bankAccountType';
import ButtonCustom from '../../ButtonCustom';
import { isEmpty } from 'lodash';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import { concatNumberAgency } from 'helpers/formats/StringFormats';
import { iconSx } from 'contexts/apiRequestContext';

type CreatePixKeyProps = {
    bankAccountAutocomplete: AutocompleteOptionList;
    onCreateBeneficiary: () => void;
    onClose: () => void;
    onEdit: () => void;
    isCreatingBeneficiary: boolean;
    formValues: CreateBeneficiaryType | null;
    selectExistingPerson: () => void;
    setBeneficiaryId: React.Dispatch<React.SetStateAction<string | undefined>>;
};
interface ShowKeyPixCreatedProps extends ComponentProps<typeof Typography> {
    formValues: CreateBeneficiaryType | null;
}

const ShowKeyPixCreated: FC<ShowKeyPixCreatedProps> = ({ formValues, ...rest }) => {
    const beneficiaryCreated = formValues?.bankAccountBeneficiaryCreate;
    if (beneficiaryCreated && beneficiaryCreated.pixKeyTypeValue === "AgencyAndAccount") {
        return <></>
    }
    const hasCreated = Object.values(beneficiaryCreated ?? {})?.length > 0
    const pixKeyTypeValueDisplay = !hasCreated ? formValues?.pixKeyTypeValueDisplay.toString() : beneficiaryCreated?.keyTypeDisplay.toString();
    return !!pixKeyTypeValueDisplay ? (
        <Typography variant='sm' {...rest}>
            {formatBankAccountManagePixKey(
                pixKeyTypeValueDisplay?.toLowerCase()!,
                formValues?.keyPix! ?? beneficiaryCreated?.keyValue,
                hasCreated
            )}
        </Typography>
    ) : <></>;
}

const theme = activeTheme();
export const CreatePixKey: React.FC<CreatePixKeyProps> = ({
    bankAccountAutocomplete,
    onCreateBeneficiary,
    formValues,
    setBeneficiaryId,
    selectExistingPerson,
    isCreatingBeneficiary,
    onEdit,
    onClose
}) => {
    const { watch, setValue } = useFormContext();
    const bankAccountBeneficiaryId = watch('bankAccountBeneficiaryId');
    const beneficiaryCreated = formValues?.bankAccountBeneficiaryCreate;

    useMemo(() => {
        const isBeneficiaryValid = !isEmpty(bankAccountBeneficiaryId) && bankAccountBeneficiaryId !== 'create';
        if (isBeneficiaryValid) setBeneficiaryId(bankAccountBeneficiaryId);
        else setBeneficiaryId(undefined);
    }, [bankAccountBeneficiaryId]);

    useMemo(() => {
        if (isCreatingBeneficiary === true) {
            setValue('bankAccountBeneficiaryId', 'create');
        }
    }, [isCreatingBeneficiary]);

    const showInformation = () => {
        if (Object.keys(formValues ?? {}).length > 0 || !isEmpty(bankAccountBeneficiaryId))
            return true;
        return false;
    };

    const transferDateValue = () => {
        var check = watch('transferDate') === moment().format('yyyy-MM-DD');
        return check ? 'today' : 'otherDay';
    };

    const resetValues = () => {
        selectExistingPerson();
        setValue('bankAccountBeneficiaryId', null);
        setValue('bankAccountBeneficiaryIdDisplay', null);
    };

    return (
        <Stack spacing={2}>
            {!isCreatingBeneficiary && (
                <AutocompleteField
                    name="bankAccountBeneficiaryId"
                    label="Selecione um favorecido"
                    displayName="bankAccountBeneficiaryIdDisplay"
                    {...bankAccountAutocomplete}
                />
            )}

            <ButtonCustom
                name="btn-create-favorite"
                Icon={
                    <PersonIcon
                        htmlColor={theme.palette.primary.main}
                        sx={{ verticalAlign: 'text-bottom', fontSize: font.size.sm }}
                    />
                }
                title={
                    isCreatingBeneficiary ? 'Selecionar um favorecido existente' : 'Adicionar favorecido'
                }
                onClick={isCreatingBeneficiary ? resetValues : onCreateBeneficiary}
            />

            {showInformation() && (
                <>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            width: '100%',
                            position: 'relative',
                            border: '1px solid #BABFD0',
                            borderRadius: 4,
                            padding: 3
                        }}
                    >
                        {isCreatingBeneficiary && (formValues && !formValues.dataByKeyPixRequest) && (
                            <IconButton
                                onClick={onEdit}
                                sx={{
                                    position: 'absolute',
                                    top: 3,
                                    right: 3
                                }}
                            >
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        )}
                        <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar />
                            <Grid>
                                <Typography variant="h5" fontWeight={700}>
                                    {formValues?.name ?? beneficiaryCreated?.name}
                                </Typography>
                                <ShowKeyPixCreated formValues={formValues} />
                            </Grid>
                        </Grid>
                        {(!!formValues?.pixKeyTypeValue || !!beneficiaryCreated?.pixKeyTypeValue) &&
                            (beneficiaryCreated?.pixKeyTypeValueDisplay === "Agência e Conta" || formValues?.pixKeyTypeValueDisplay === "Agência e Conta") && (
                                <>
                                    {(beneficiaryCreated?.bank === "457" || beneficiaryCreated?.bank === 457) ||
                                        (formValues?.bank === "457" || formValues?.bank === 457) ? (
                                        <UY3Icon sx={{ width: '64px', height: '64px' }} />
                                    ) : <></>}
                                    <Grid mr={2}>
                                        <Typography variant="h5" fontWeight={700}>
                                            {(beneficiaryCreated?.bankDisplay ?? formValues?.bankDisplay) ?? beneficiaryCreated?.bankName}
                                        </Typography>
                                        <Grid>
                                            <Typography variant="sm">{`Agência: ${beneficiaryCreated?.agency ?? formValues?.agency}`}</Typography>
                                            <br />
                                            <Typography variant="sm">{`Conta: ${(beneficiaryCreated?.accountNumber ?? beneficiaryCreated?.account) ??
                                                formValues?.accountNumber ?? formValues?.account
                                                }`}</Typography>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        {(formValues && beneficiaryCreated?.bankName) && (
                            <>
                                {formValues?.bank === "457" || formValues?.bank === 457 ? (
                                    <UY3Icon sx={{ width: '64px', height: '64px' }} />
                                ) : <></>}
                                <Grid mr={2}>
                                    <Typography variant="h5" fontWeight={700}>
                                        {beneficiaryCreated.bankName}
                                    </Typography>
                                    <Grid>
                                        <Typography variant="sm">{`Agência: ${concatNumberAgency(beneficiaryCreated?.accountAgency)}`}</Typography>
                                        <br />
                                        <Typography variant="sm">{`Conta: ${(beneficiaryCreated?.accountNumber)}`}</Typography>
                                    </Grid>
                                </Grid>
                            </>
                        )}
                    </Stack>

                    <Typography variant="h5" fontWeight={700}>
                        Valor da transferência
                    </Typography>

                    <Grid item xs={4}>
                        <CurrencyFormField fullWidth name="amount" variant="outlined" />
                    </Grid>

                    <Stack direction="row" justifyContent="right">
                        <ViewTransactionalLimitsDrawer />
                    </Stack>

                    <Typography variant="h5" fontWeight={700}>
                        Data transferência
                    </Typography>

                    <CardsSelect
                        onChange={({value, displayName}) => {
                            setValue('transferDateSelected', value);
                            if (value === 'otherDay') {
                                const transferDate = moment().add(1, 'day').format('yyyy-MM-DD')
                                setValue('transferDate', transferDate);
                            }

                            if (value === 'today') {
                                setValue('transferDate', moment().format('yyyy-MM-DD'));
                            } 
                        }}
                        options={[
                            { label: `Hoje ${moment().format('L')}`, value: 'today' },
                            { label: "Agendar para", value: 'otherDay' },
                        ]}
                        value={transferDateValue()}
                    />

                    {transferDateValue() === 'otherDay' && (
                        <Grid item xs={4}>
                            <DatePickerFormField
                                label="Data da Transferência"
                                disablePast
                                name="transferDate"
                                fullWidth
                                shrink={true}
                                required
                            />
                        </Grid>
                    )}

                    <Grid pt={2} pb={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography variant='h5' fontWeight={700} >
                            Valor da transferência
                        </Typography>
                        <Typography
                            variant='h4'
                            fontWeight={700}
                            color={theme.palette.primary.main}
                        >
                            {formatCurrencyInCents(watch('amount'))}
                        </Typography>
                    </Grid>
                </>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 18, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<TransferIcon htmlColor={theme.palette.common.white} sx={{ height: 19, width: 20 }}/>}
                >
                    Transferir
                </Button>
            </Stack>
        </Stack>
    );
};
