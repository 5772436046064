import { EPaymentMonth } from 'utils/enums';
import { BasicAmortization, DefaultSchemaBasicAmortization } from './BasicAmortization';

abstract class FGTS extends BasicAmortization {
    public paymentMonth?: EPaymentMonth | null;
    public registrationNumber?: string | null;
}

export class DefaultSchemaAmmFGTS extends DefaultSchemaBasicAmortization implements FGTS {
    amortizationType = 'fgts';
    registrationNumber = null;
    paymentMonth = null;
}
