import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
} from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import React, { useState } from 'react';
import BankAccountForm from './BankAccountRequestForm';
import {
    BankAccountRequestFormSchema,
    defaultValuesBankAccountRequestForm,
    validationSchemaBankAccountRequestForm,
} from './BankAccountRequestSchema';
import {
    useBankAccountMutation,
    useBankAccountRequestData,
} from 'contexts/bankAccount/bankAccountRequest';
import Toast from 'components/Toast/Toast';
import { useUserPermissionData } from 'contexts/userContext';
import {
    OperatorReadModel,
    BankAccountRequestReadModel,
} from 'services/accounts/bankAccountRequest';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import AssignDrawer from 'components/Assign/AssignDrawer';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import { BankAccountActionsProvider } from 'contexts/bankAccount/bankAccountActions';

type OptionsActionsType =
    | 'approve'
    | 'disapprove'
    | 'assign'
    | 'delete'
    | 'update'
    | 'deleteCheckList'
    | 'approveSignature'
    | 'disapproveSignature'
    | 'draftpreview'
    | undefined;

export const BankAccountRequestFormContainer = () => {
    const { bankAccountId } = useParams();
    const { data: permissionsData, hasPermission } = useUserPermissionData();
    const formMode: FormMode = bankAccountId !== 'nova' ? 'update' : 'create';
    const [handleAction, setHandleAction] = useState<OptionsActionsType>(undefined);
    const { setFormFieldsErrors } = useFormFieldsError();
    const [error, setError] = useState<ApiResponseError | undefined>(undefined);
    const navigate = useNavigate();
    const [toast, setToast] = useState<ToastType>(toastState);
    const typePermission = bankAccountId === 'nova' ? 'Create' : 'Update';
    const hasPermissionCreateOrEditbyParams = hasPermission('BankAccountRequest', typePermission);

    const {
        bankAccountRequestData,
        bankAccountRequestErr,
        bankAccountRequestStatus,
        refetch,
        isLoading,
    } = useBankAccountRequestData(bankAccountId!);

    const onSuccess = (data: BankAccountRequestReadModel) => {
        navigate(
            `/contas-digitais/solicitacoes/${
                formMode === 'create' ? data : data.id ?? bankAccountId
            }`
        );
        setFormFieldsErrors([]);
        setError(undefined);
        const title =
            formMode === 'create'
                ? 'Solicitação de conta criada com sucesso!'
                : 'Solicitação de conta atualizada com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        const { fieldErrors, warningToastError, errorMessage } = mapErrorResponse(error);
        setError(error);
        setFormFieldsErrors(fieldErrors ?? []);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const onClose = () => setHandleAction(undefined);

    const { mutateAsync } = useBankAccountMutation(bankAccountId!, onSuccess, onError);
    const onSubmit = async (values: BankAccountRequestFormSchema) => {
        let operators: OperatorReadModel[] = values?.operators;
        if (operators) {
            //@ts-ignore
            values.operators = operators.map((operator) => {
                return {
                    ...operator,
                    tenantName: operator?.tenant,
                };
            });
        }

        let payload: any = {
            ...values,
            uploads: values?.uploads || [],
            relatedPerson: values?.relatedPerson || [],
        };

        await mutateAsync(payload);
    };

    const isReadOnly = (): boolean => {
        let status: string[] = ['Draft', 'Error', 'Revision', 'Disapproved', 'CorbanPreAnalysis'];
        if (
            (formMode === 'update' && !status.includes(bankAccountRequestData?.status)) ||
            !hasPermissionCreateOrEditbyParams
        ) {
            return true;
        }
        return false;
    };

    const schema = validationSchemaBankAccountRequestForm();
    const defaultValues = bankAccountRequestData ?? defaultValuesBankAccountRequestForm;

    return (
        <GenericErrorBoundary
            status={bankAccountRequestStatus}
            error={bankAccountRequestErr}
            fallback="solicitação de conta"
        >
            <GenericFormSkeleton isLoading={isLoading && formMode === 'update'}>
                <React.Fragment>
                    <ApiErrorAlert error={error} />
                    <Toast toast={toast} setToast={setToast} />
                    <FormProvider
                        validationSchema={schema}
                        defaultValues={defaultValues}
                        onSubmit={onSubmit}
                        readOnly={isReadOnly()}
                    >
                        <BankAccountActionsProvider bankAccountId={bankAccountId!} refetch={refetch} bankAccountRequestData={bankAccountRequestData}>
                            <BankAccountForm
                                mode={formMode}
                                bankAccountId={bankAccountId!}
                                permissionsData={permissionsData}
                                onAssignBankAccountRequest={() => setHandleAction('assign')}
                                timeline={bankAccountRequestData?.timeline ?? []}
                                statusDisplay={bankAccountRequestData?.statusDisplay ?? ''}
                            />
                        </BankAccountActionsProvider>
                    </FormProvider>

                    <AssignDrawer
                        recordId={bankAccountId!}
                        recordType="BankAccountRequest"
                        openDrawer={handleAction === 'assign'}
                        onClose={onClose}
                        navigate="/contas-digitais/solicitacoes"
                    />
                </React.Fragment>
            </GenericFormSkeleton>
        </GenericErrorBoundary>
    );
};
