import { creditAxiosApi } from "services/axiosApi/axiosApi";
export interface GroupListProps {
    size: number;
    page: number;
    paginationToken?: string;
    searchString?: string;
    tenant?: string | undefined;
}

export type GroupInfo = {
    groupName: string
    id: string
}

var url = `/UserGroup`;
export async function getGroupList(filters: GroupListProps, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return creditAxiosApi.get(url, config);
}

export function removeUserGroupById(groupName: string, userId: string, token: string, tenant?: string) {

    let urlFull = `${url}/${groupName}/RemoveUser/${userId}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant: tenant }
    };

    return  creditAxiosApi.delete(urlFull, config);
}

export function postGroupInsertUser(groupName: string, userId: string, token: string, tenant?: string) {
    let urlFull = `${url}/${groupName}/InsertUser/${userId}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant: tenant }
    };
    
    return  creditAxiosApi.post(urlFull, {}, config);
}