import { string, object, date, number, boolean, InferType, array } from 'yup';
import { validationSchemaDocForm } from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { generateAddressSchema, validateRequiredFields, isFieldRequired } from 'helpers';

// Atenção - Lembre-se de que cada nova propriedade inserida deve também ser incluída na classe LegalPersonBaseModel.
export function validationSchemaLegalPersonForm() {
    return object().shape({
        managerId: validateRequiredFields(
            string().typeError('Selecione o Gestor da empresa'),
            'managerId',
            `Gestor da empresa`
        ),
        registrationNumber: validateRequiredFields(
            string().typeError('Cnpj inválido').cpfCnpjValidation('Cnpj inválido'),
            'registrationNumber',
            `CNPJ da empresa`
        ),
        companyName: validateRequiredFields(
            string().typeError(`Razão social inválido.`),
            'companyName',
            `Razão social`
        ),
        name: validateRequiredFields(
            string().typeError(`Nome fantasia inválido.`),
            'name',
            `Nome fantasia`
        ),
        typeOfActivity: validateRequiredFields(
            string().typeError(`Ramo de atividade inválido.`),
            'typeOfActivity',
            `Ramo de atividade`
        ),
        municipalRegistration: validateRequiredFields(
            string()
                .typeError('Inscrição municipal inválida')
                .typeError('Inscrição municipal é obrigatório.'),
            'municipalRegistration',
            `Inscrição municipal`
        ),
        stateRegistration: validateRequiredFields(
            string().typeError('Inscrição estadual é obrigatório.'),
            'stateRegistration',
            `Inscrição estadual`
        ),
        B3Account: validateRequiredFields(
            string().typeError('Conta B3 é obrigatório.'),
            'B3Account',
            `Conta B3`
        ),
        openDate: validateRequiredFields(
            date().typeError('Data inválida'),
            'openDate',
            'Data de abertura da empresa'
        ),
        email: validateRequiredFields(string().email('E-mail inválido'), 'email', `Email`),
        site: validateRequiredFields(string().typeError('Site é obrigatório.'), 'site', `Site`),
        address: generateAddressSchema(),
        legalNature: validateRequiredFields(
            string().typeError('Natureza Jurídica é obrigatória.'),
            'legalNature',
            `Natureza Jurídica`
        ),
        phone: validateRequiredFields(
            string()
                .typeError('Número de telefone inválido')
                .phoneNumberValidation('Número de telefone deve conter 11 Caracteres'),
            'phone',
            `Telefone`
        ),
        phoneExtension: string().nullable(),
        phone2: string().nullable(),
        phone2Extension: string().nullable(),
        economicActivityCodeId: validateRequiredFields(
            string().typeError('CNAE é obrigatório.'),
            'economicActivityCodeId',
            `CNAE`
        ),
        averageMonthlyRevenue: validateRequiredFields(
            number().typeError('Valor precisa ser um número.'),
            'averageMonthlyRevenue',
            `Faturamento médio mensal`
        ),
        averageGrossProfitMargin: number().nullable(),
        numberOfEmployees: validateRequiredFields(
            number().typeError('Número de Funcionários é inválido.'),
            'numberOfEmployees',
            `Número de Funcionários`
        ),
        initialShareCapital: validateRequiredFields(
            number().typeError('Capital social inicial é obrigatório'),
            'initialShareCapital',
            `Capital social inicial`
        ),
        netWorth: number().nullable(),
        purchases: validateRequiredFields(
            number().typeError('Valor precisa ser um número.'),
            'purchases',
            `Faturamento médio mensal`
        ),
        amountRentedFinanced: number().nullable(),
        exportPercentage: number().nullable(),
        costWithSuppliers: number().nullable(),
        payrollValue: number().nullable(),
        spendingAmountWithTax: number().nullable(),
        typeOfResidence: string().nullable(),
        averageSalesPeriod: number().nullable(),
        fosterablePotential: number().nullable(),
        delayedSalePercentage: number().nullable(),
        description: string().nullable(),
        allowAccessToSchedule: boolean().nullable(),
        allowAccessToReceivables: boolean().nullable(),
        allowPartnerApiSubscription: boolean().nullable(),
        uploads: array().of(validationSchemaDocForm()).nullable(),
        externalEmployerId: validateRequiredFields(
            number().typeError('Código do Empregador inválido!'),
            'externalEmployerId',
            `Código do Empregador`
        )
    });
}

let inferedSchema = validationSchemaLegalPersonForm();
export type LegalPersonFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesLegalPersonForm = {
    registrationNumber: '',
    companyName: '',
    name: '',
    typeOfActivity: '',
    municipalRegistration: '',
    stateRegistration: '',
    B3Account: '',
    openDate: null,
    email: '',
    site: '',
    legalNature: '',
    address: null,
    phone: '',
    phoneExtension: '',
    phone2: '',
    phone2Extension: '',
    economicActivityCodeId: '',
    averageMonthlyRevenue: isFieldRequired('averageMonthlyRevenue') ? null : 0,
    averageGrossProfitMargin: 0,
    initialShareCapital: isFieldRequired('initialShareCapital') ? null : 0,
    netWorth: 0,
    purchases: 0,
    amountRentedFinanced: 0,
    exportPercentage: 0,
    costWithSuppliers: 0,
    numberOfEmployees: isFieldRequired('numberOfEmployees') ? null : 0,
    payrollValue: 0,
    spendingAmountWithTax: 0,
    typeOfResidence: '',
    averageSalesPeriod: 0,
    fosterablePotential: 0,
    delayedSalePercentage: 0,
    description: '',
    allowAccessToSchedule: false,
    allowAccessToReceivables: false,
    allowPartnerApiSubscription: false,
    uploads: null,
    externalEmployerId: 0,
};
