import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@uy3/web-components';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import {
    ToastType,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { DisapproveDataProps, disapproveCreditNoteById } from 'services/creditNote';
import {
    validationCreditNoteRejectSchema,
    defaultValuesCreditNoteReject,
} from './CreditNoteRejectSchema';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { mapErrorResponse } from 'contexts/responseErrorContext';

type RejectComplianceProps = {
    selectedPerson: CreditNoteReadModel[];
    onClose: () => void;
    creditNoteId: string;
    onlyOneCreditNote: boolean;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

const CreditNoteRejectContainer = ({ selectedPerson, onClose, setToast, creditNoteId, onlyOneCreditNote }: RejectComplianceProps) => {
    const { token } = useIdentity();
    const queryClient = useQueryClient();
    const { setSubmitError, submitting, startRequest, endRequest } = useApiRequest();

    const successfulCreditDisapprove = () => {
        queryClient.invalidateQueries({
            queryKey: ['credit-disapprove'],
            refetchType: 'active',
        });

        const title = 'Operação aprovada com sucesso';
        const description = 'Ótimo! A operação seguirá para a próxima etapa.';
        showSuccessToast(title, description, setToast);
        setSubmitError(undefined);
        onClose();
        endRequest(true);
    };

    const errorCreditDisapprove = (errorMessage: string) => {
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        endRequest(false);
    };

    const asyncDisapproveCreditNote = async (fieldValues: DisapproveDataProps, id: string) => {
        startRequest();
        await disapproveCreditNoteById(fieldValues, id, token!)
            .then(() => {
                successfulCreditDisapprove();
            })
            .catch((error) => {
                const { errorMessage } = mapErrorResponse(error);
                errorCreditDisapprove(errorMessage);
                setSubmitError(error);
            });
    };

    const onSubmit = (fieldValues: DisapproveDataProps) => {
        if (onlyOneCreditNote) return asyncDisapproveCreditNote(fieldValues, creditNoteId);

        selectedPerson.map(async (row: any) => {
            await asyncDisapproveCreditNote(fieldValues, row?.creditNoteOp?.id);
        });
    };

    return (
        <FormProvider
            defaultValues={defaultValuesCreditNoteReject}
            validationSchema={validationCreditNoteRejectSchema()}
            onSubmit={onSubmit}
        >
            <>
                <Grid item xs={12} mt={1}>
                    <TextFormField
                        name="message"
                        label="Digite o Motivo"
                        multiline
                        rows={3}
                        variant="outlined"
                        fullWidth
                        disabled={false}
                        required
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <SelectFormField
                        name="insertBlock"
                        label="Realizar Bloqueio?"
                        required
                        options={[
                            { label: 'Não', value: null },
                            { label: 'Tomador', value: 'Person' },
                            { label: 'Operação', value: 'Operation' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12} mt={2}>
                    <Grid item>
                        <DatePickerFormField
                            label="Data de Bloqueio"
                            name="blockUntil"
                            fullWidth
                            required
                            disabled={false}
                            disablePast
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClose}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={submitting}
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};

export default CreditNoteRejectContainer;
