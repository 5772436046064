import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IconButton, ListItemIcon } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ActionsIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';

export const OptionItem = styled(MenuItem)(() => ({
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  color: '#000000'
}));

const CustomMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    borderRadius: 4,
    marginTop: 4,
  },
}));

export interface RowButtonType {
  disabled?: boolean;
  label: string;
  action: () => void
  icon?: React.ReactNode;
  isEdit?: boolean;
  recordType?: string;
  hide?: boolean;
}

type RowActionsProps = {
  listButtons: RowButtonType[];
  icon?: React.ReactNode
}

export type RowDataAction<T> = {
  action: string;
  rowData: T;
} | undefined

export const RowActions: React.FC<RowActionsProps> = ({ listButtons, icon }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <React.Fragment>
      <IconButton onClick={handleClick} name="btn-basic" aria-label="more options">
        {icon ?? <ActionsIcon />}
      </IconButton>
      <CustomMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {listButtons.map(({ icon, action, disabled, label, isEdit, recordType, hide }) => (
          <>
            {hide ?
              <></>
              :
              <>
                {isEdit ? (
                  <Link to={recordType!} style={{ textDecoration: 'none' }} key={recordType}>
                    <OptionItem
                      id="btn-link-edit"
                      disabled={disabled}
                      onClick={() => handleClose}
                    >
                      {icon ?? <ListItemIcon sx={{ mr: -1 }}>{icon}</ListItemIcon>}
                      {label}
                    </OptionItem>
                  </Link>
                )
                  :
                  <OptionItem
                    id={`btn-${label.toLowerCase()}`}
                    disabled={disabled}
                    onClick={() => { action(); handleClose(); }}
                  >
                    {icon && <ListItemIcon sx={{ mr: -1 }}>{icon}</ListItemIcon>}
                    {label}
                  </OptionItem>}
              </>
            }
          </>
        ))}
      </CustomMenu>
    </React.Fragment>
  );
}