import { object, string } from "yup";

export function validationTransferBankSlipFormSchemaSchema() {
  return object().shape({
    walletCode: string().typeError('Carteira precisa ser preenchido.').required('Carteira precisa ser preenchido.'),
  });
}

export const defaultValuesTransferBankSlipFormSchemaSchema = {
  walletCode: null,
};