import { Grid, SxProps } from '@mui/material';
import {
    CancelIcon,
    CheckCircleIcon,
    MoneyIcomeIcon,
    ShieldWarningIcon,
    Typography,
} from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { EAction } from 'services/LiquidationSchedule/types/enums';

type ActionInfo = {
    text: string;
    color: string;
    icon: React.ReactNode;
};

const iconPropsSx: SxProps = { ...iconSx, marginRight: 1 };

const getActionInfo = (action: keyof typeof EAction): ActionInfo => {
    switch (action) {
        case 'ManualLiquidation':
            return {
                text: 'Pago',
                color: '#4CAF50',
                icon: <CheckCircleIcon sx={iconPropsSx} htmlColor="#4CAF50" />,
            };
        case 'Failed':
            return {
                text: 'Reprovar',
                color: '#FF0000',
                icon: <CancelIcon sx={iconPropsSx} htmlColor="#FF0000" />,
            };
        case 'AutomaticLiquidation':
            return {
                text: 'Liquidar',
                color: '#D04D27',
                icon: <MoneyIcomeIcon sx={iconPropsSx} htmlColor="#D04D27" />,
            };
        case 'NoAction':
        default:
            return {
                text: 'Pendente',
                color: '#C77700',
                icon: <ShieldWarningIcon sx={iconPropsSx} htmlColor="#C77700" />,
            };
    }
};

export const FooterText: React.FC<{ action: keyof typeof EAction }> = ({ action }) => {
    const { text, color, icon } = getActionInfo(action);

    return (
        <Grid container alignItems="center">
            {icon}
            <Typography variant="xs" style={{ color }}>
                {text}
            </Typography>
        </Grid>
    );
};
