import { Grid } from '@mui/material';
import { Typography } from '@uy3/web-components';
interface IHeaderOperationProps {

};

const HeaderOperation: React.FC<IHeaderOperationProps> = () => {
    return (
        <>
            <Grid container xs={12} alignItems="center" justifyContent="space-between" mb={2}>
                <Grid item xs={12} md={6} lg={4} xl={3} justifyContent="flex-start">
                    <Typography
                        variant="xxl"
                        color="neutral.dark"
                        sx={{ lineHeight: '24px', fontWeight: '700', fontStyle: 'normal' }}
                    >
                        Detalhes das parcelas
                    </Typography>
                    <Typography
                        variant="h6"
                        color="neutral.medium"
                        sx={{
                            lineHeight: '20px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            fontSize: '12px',
                            opacity: '87%',
                        }}
                    >
                        Confira a lista de vencimentos da operação
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default HeaderOperation;
