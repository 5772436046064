import { useState } from 'react';
import { usePaymentScheduleBatchAssignment } from 'contexts/batchAssignment/batchAssignmentContext';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { Grid, Stack } from '@mui/material';
import { Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import ParcelsBatchListWithFilters from './ParcelsBatchAssignmentListWithFilters';

export function ParcelsBatchAssignmentContainer() {
    const { id } = useParams();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const {
        data: paymentScheduleData,
        error,
        status,
        isFetching,
        refetch,
        isLoading,
    } = usePaymentScheduleBatchAssignment(id!, { page, size: rowsPerPage });

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    if (status === 'error') return <Error error={error} />;

    return (
        <>
            <Stack direction="row" justifyContent="space-between" alignItems='center'>
                <Grid>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="30px"
                        lineHeight="38.4px"
                    >
                        Parcelas
                    </Typography>
                </Grid>
                <Button
                    sx={{ alignSelf: 'flex-end', color: 'black', mb: 2 }}
                    variant="text"
                    disabled={isFetching}
                    onClick={() => refetch()}
                    size="large"
                    type="submit"
                    startIcon={<UpdateIcon htmlColor={isFetching ? 'grey' : 'black'} sx={iconSx} />}
                    color="primary"
                >
                    Atualizar
                </Button>
            </Stack>
            <ParcelsBatchListWithFilters
                rowsPerPage={rowsPerPage}
                page={page}
                onChangeRowsPerPage={onChangeRowsPerPage}
                onChangePage={onChangePage}
                paymentScheduleData={paymentScheduleData}
                isLoading={isLoading}
            />
        </>
    );
}
