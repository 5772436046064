import { useMutation, useQuery } from '@tanstack/react-query';
import { useIdentity } from "contexts/identityContext";
import { ApiResponseError, GetViewApiResponse, GetViewApiResponseSuccess, useApiRequest } from "contexts/apiRequestContext";
import { AxiosError, isAxiosError } from "axios";
import { deleteBankAccountPixKey, getBankAccountByKey, getBankAccountKey, getBankAccountPixKeyClaim, getInformationByPixKey, postBankAccountClaimKeyPix, postBankAccountKey, putBankAccountPixKeyClaim } from "services/accounts/bankAccount/bankAccount";
import { IPixKeyData } from './bankAccountType';
import { ManagementPixKeyType, PostBankAccountClaimPixKeyProps, PostBankAccountKeyProps, PutBankAccountClaimPixKeyType } from 'services/accounts/bankAccount';
import { useState } from 'react';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import { SelectOption } from 'components/Forms/FormFields';
import { isEmpty } from 'lodash';

export function useBankAccountPixKeyData(bankAccountId: string) {
    const [search, setSearch] = useState<string | undefined>(undefined);

    const { token } = useIdentity();
    const [pixKeyList, setPixKeyList] = useState<SelectOption[]>([]);

    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ["pix-key-data", bankAccountId, search],
        queryFn: async (): Promise<GetViewApiResponse<ManagementPixKeyType[]>> => {
            startRequest();
            const params = {
                searchString: search
            }
            const { data, status, statusText } = await getBankAccountKey(bankAccountId, params, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };

            const optionsData = data as ManagementPixKeyType[]; 
            const optionsToAutocompleteMapped = optionsData.map((item) => {
                const typeDisplayLowerCase = item?.typeDisplay?.toString()?.toLowerCase();
                const labelBankAccountFormated =  formatBankAccountManagePixKey(typeDisplayLowerCase, item?.pixKey)
                return {
                    label: labelBankAccountFormated!, 
                    value: item.pixKey, 
                    type: item?.type, 
                    typeDisplay: item?.typeDisplay
                }; 
            });  
            setPixKeyList(optionsToAutocompleteMapped);

            return data as GetViewApiResponseSuccess<ManagementPixKeyType[]>
        }
    });

    const keyPixListAutocomplete = {
        listOptions: pixKeyList,
        loading: isLoading || isFetching,
        onSearch: (searchString: string | undefined) => {
            setSearch(searchString);
        },
    };
    const dataIsEmpty: boolean = isEmpty(search) && data?.type !== 'error' && data?.length === 0; 
    return { status, data, error, isFetching, refetch, isError, keyPixListAutocomplete, dataIsEmpty };
}

export function useBankAccountPixKeyClaimData(bankAccountId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["pix-key-claim-data", bankAccountId],
        queryFn: async (): Promise<GetViewApiResponse<any>> => {
            startRequest();
            const { data, status, statusText } = await getBankAccountPixKeyClaim(bankAccountId, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponseSuccess<any>
        }
    });

    return { status, data, error, isFetching, refetch, isError };
}

export function useGetInformationByPixKey(bankAccountId: string, pixKey: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError } = useQuery({
        enabled: !!token && !!pixKey,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["pix-key-data-by-pix-key", bankAccountId, pixKey],
        queryFn: async (): Promise<IPixKeyData> => {
            startRequest();
            const { data, status, statusText } = await getInformationByPixKey(bankAccountId, pixKey, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as IPixKeyData
        }
    });

    return { status, data, error, isFetching, refetch, isError };
}

export function useBankAccountByKeyPix(bankAccountId: string, keyPix: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch, isError } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["pix-key-by-key", bankAccountId],
        queryFn: async (): Promise<GetViewApiResponse<any>> => {
            startRequest();
            const { data, status, statusText } = await getBankAccountByKey(bankAccountId, keyPix, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponseSuccess<any>
        }
    });

    return { status, data, error, isFetching, refetch, isError };
}

export function useBankAccountAddKeyPixMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: PostBankAccountKeyProps) => {
            startRequest();
            const { data, status, statusText } = await postBankAccountKey(bankAccountId, values, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};

export function useBankAccountClaimAddKeyPixMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: PostBankAccountClaimPixKeyProps) => {
            startRequest();
            const { data, status, statusText } = await postBankAccountClaimKeyPix(bankAccountId, values, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};
export function useConfirmOrCancelPixKeyClaimMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: PutBankAccountClaimPixKeyType) => {
            startRequest();
            const { data, status, statusText } = await putBankAccountPixKeyClaim(bankAccountId, values, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};

export function useBankAccountDeleteKeyPixMutation(
    bankAccountId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (keyPix: string) => {
            startRequest();
            const { data, status, statusText } = await deleteBankAccountPixKey(bankAccountId, keyPix, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};