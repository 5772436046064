import { useQuery, useMutation } from '@tanstack/react-query';
import { ApiResponseError, GetListApiResponseSuccess, useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { isAxiosError } from 'axios';
import {
    CreditStatusRequestCreateModel,
    getCreditStatusRequestList,
    ICreditStatusRequestListProps,
    postCreditApproval,
    postCreditStatusRequest,
    CreditStatusReadModel,
    AproverCreditStatusRequestPost,
} from 'services/creditStatusRequest';
import { handleErrorUseQuery } from 'helpers';

export function useCreditStatusRequestList(filters: ICreditStatusRequestListProps) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && !!filters.personId && filters.personId !== 'novo',
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['creditStatusRequest-list', filters],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getCreditStatusRequestList(filters!, token!);
                endRequest(true);
                return data as GetListApiResponseSuccess<CreditStatusReadModel>;
                
            } catch (err) {
                endRequest(true);
                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err?.response?.status + '' + err?.response?.statusText,
                        message: err?.response?.data?.message,
                        errors: err?.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        status,
        data: data ? data.data : [],
        error,
        isFetching,
        refetch,
    };
}

export function useCreditStatusRequestMutation(
    onSuccess?: (data: CreditStatusRequestCreateModel) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: CreditStatusRequestCreateModel) => {
            startRequest();
            const { data, status, statusText } = await postCreditStatusRequest(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as CreditStatusReadModel;
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateAsync, isLoading, error };
}

export function useCreditApprovalMutation(
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: AproverCreditStatusRequestPost) => {
            startRequest();
            const { data, status, statusText } = await postCreditApproval(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as CreditStatusReadModel;
        },
        onSuccess(data, _, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateAsync, isLoading, error };
}
