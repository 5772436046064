import React, { useEffect } from 'react';
import { Typography } from '@uy3/web-components';
import { Stack } from '@mui/material';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import { FieldValues } from 'react-hook-form';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

type FormFieldsProps = {
    limitOption: string;
    hoursLimitOption: string;
    advancedSettingsLimit: boolean;
    currentLimit: FieldValues
    isEscrow: boolean;
};

interface LimitFieldsProps {
    limitType: string;
    hoursLimitOption: string;
    limitOption: string;
    advancedSettingsLimit: boolean;
    title: string;
    currentLimitResource: FieldValues
}

const commonFieldProps = {
    placeholder: 'Limite padrão da instituição',
    fullWidth: true,
    autoFocus: true
};

const LimitFields: React.FC<LimitFieldsProps> = ({
    limitType,
    title,
    hoursLimitOption,
    limitOption,
    advancedSettingsLimit,
    currentLimitResource
}) => {
    const { setValue, watch } = useFormContext();

    useEffect(() => {
        if (!advancedSettingsLimit) {
            const valuebusinessHoursOwnership = watch(
                `${limitType}.businessHours.valueInCentsOwnOwnership`
            );
            setValue(
                `${limitType}.businessHours.valueInCentsNaturalPerson`,
                valuebusinessHoursOwnership
            );
            setValue(
                `${limitType}.businessHours.valueInCentsLegalPerson`,
                valuebusinessHoursOwnership
            );

            const valueNonBusinessOwnership = watch(
                `${limitType}.nonBusinessHours.valueInCentsOwnOwnership`
            );
            setValue(
                `${limitType}.nonBusinessHours.valueInCentsNaturalPerson`,
                valueNonBusinessOwnership
            );
            setValue(
                `${limitType}.nonBusinessHours.valueInCentsLegalPerson`,
                valueNonBusinessOwnership
            );
        }
    }, [advancedSettingsLimit, limitType, setValue, watch]);

    const limit = advancedSettingsLimit ? (
        <Fields limitType={limitType} hoursLimitOption={hoursLimitOption} currentLimitResource={currentLimitResource} />
    ) : (
        <>
            <CurrencyFormField
                name={`${limitType}.businessHours.${limitOption}`}
                label="Horário comercial"
                variant="outlined"
                {...commonFieldProps}
            />
            {limitType !== 'tedLimit' && (
                <CurrencyFormField
                    name={`${limitType}.nonBusinessHours.${limitOption}`}
                    label="Fora do horário comercial"
                    variant="outlined"
                    {...commonFieldProps}
                />
            )}
        </>
    );

    return (
        <Stack spacing={2}>
            <Typography variant="md" fontWeight={700}>
                {title}
            </Typography>
            {limit}
        </Stack>
    );
};

interface FieldFormProps {
    limitType: string;
    hoursLimitOption: string;
    currentLimitResource: FieldValues
}

const Fields: React.FC<FieldFormProps> = ({ limitType, hoursLimitOption, currentLimitResource }) => {
    const getValuesWithLabel = (label:string, field: string) => {
        const value = currentLimitResource?.[hoursLimitOption]?.[field];
        return `${label} - Limite atual: ${formatCurrencyInCents(value)}`
    }

    return (
        <>
            <CurrencyFormField
                name={`${limitType}.${hoursLimitOption}.valueInCentsOwnOwnership`}
                label={getValuesWithLabel("Mesma titularidade", 'valueInCentsOwnOwnership')}
                variant="outlined"
                {...commonFieldProps}
            />
            <CurrencyFormField
                name={`${limitType}.${hoursLimitOption}.valueInCentsNaturalPerson`}
                label={getValuesWithLabel("Pessoa Física", 'valueInCentsNaturalPerson')}
                variant="outlined"
                {...commonFieldProps}
            />
            <CurrencyFormField
                name={`${limitType}.${hoursLimitOption}.valueInCentsLegalPerson`}
                label={getValuesWithLabel("Pessoa Jurídica", 'valueInCentsLegalPerson')}
                variant="outlined"
                {...commonFieldProps}
            />
        </>
    );
};

const RequestLimitForm: React.FC<FormFieldsProps> = ({
    hoursLimitOption,
    limitOption,
    advancedSettingsLimit,
    currentLimit,
    isEscrow
}) => {
    return (
        <>
            <LimitFields
                title="Pix"
                limitType="pixLimit"
                hoursLimitOption={hoursLimitOption}
                limitOption={limitOption}
                advancedSettingsLimit={advancedSettingsLimit}
                currentLimitResource={currentLimit?.pixLimit}
            />
            {hoursLimitOption === 'businessHours' && (
                <LimitFields
                    title="TED"
                    limitType="tedLimit"
                    hoursLimitOption={hoursLimitOption}
                    limitOption={limitOption}
                    advancedSettingsLimit={advancedSettingsLimit}
                    currentLimitResource={currentLimit?.tedLimit}
                />
            )}
            <LimitFields
                title="TRANSFERÊNCIA INTERNA"
                limitType="transferLimit"
                hoursLimitOption={hoursLimitOption}
                limitOption={limitOption}
                advancedSettingsLimit={advancedSettingsLimit}
                currentLimitResource={currentLimit?.transferLimit}
            />
           {!isEscrow && <LimitFields
                title="BOLETO"
                limitType="bankSlipLimit"
                hoursLimitOption={hoursLimitOption}
                limitOption={limitOption}
                advancedSettingsLimit={advancedSettingsLimit}
                currentLimitResource={currentLimit?.bankSlipLimit}
            />}
        </>
    );
};

export default RequestLimitForm;
