import { Drawer } from '@uy3/web-components';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    AddInterestModelFormSchema,
    defaultValuesAddInterestModelForm,
    validationSchemaAddInterestModelForm,
} from './AddInterestModelSchema';
import CreateOrEditInterestModel from './CreateOrEditInterestModel';
import { useBillingParametersMutation } from 'contexts/wallet/billingParameters/billingParametersContext';
import { FieldValues } from 'react-hook-form';
import {
    ApiResponseError,
    ToastType,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { IBillingSettingFull } from 'services/walletManagement/billingSetting/billingSetting.types';

interface DrawerContainerProps {
    addInterestModel: boolean;
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetchParametrs: () => void
    billingSettingData: IBillingSettingFull[]
}

const DrawerManageInterestModelContainer: React.FC<DrawerContainerProps> = ({
    addInterestModel,
    onClose,
    setToast,
    refetchParametrs,
    billingSettingData
}) => {
    const { watch, setValue } = useFormContext();
    const { handleErrorException } = useApiRequest();

    const onSuccess = (_: IBillingSettingFull) => {
        const title = 'Parâmetro da cobrança adicionado com sucesso!';
        const description = 'Excelente! Agora você pode editar ou criar novos parâmetros.';
        showSuccessToast(title, description, setToast);
        onClose();
        refetchParametrs()
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)


    const { mutateAsync: addParametersMutateAsync } = useBillingParametersMutation(onSuccess, onError);

    const handleOnSubmit = async (values: FieldValues) => {
        const payload = values.interestModel.map((interestObj: AddInterestModelFormSchema) => ({
            ...interestObj
        }));
        setValue('interestModel', payload);
        
        return payload?.map(async (values: FieldValues) => {

            const dataPayload = {
                ...values,
                walletCode: watch('walletCode'),
                fineAmount: values?.fineAmount,
                interestAmount: values?.interestAmount
            } as IBillingSettingFull;

            setValue('parametroCobrancaId', null)
            setValue('parametroCobrancaIdDisplay', null)
            await addParametersMutateAsync(dataPayload);
        })
    };

    const defaultInterest = {
        interestModel: billingSettingData?.filter((item: any) => {
            return item?.id !== undefined;
        })
    }

    const hasValue = Object.values(defaultInterest ?? {})?.length > 0

    const validationSchema = validationSchemaAddInterestModelForm();
    const defaultValues = hasValue ? defaultInterest : defaultValuesAddInterestModelForm;

    return (
        <Drawer
            title="Gerenciar régua de cobrança"
            description="Confira as informações antes de editar ou criar um novo régua de cobrança"
            anchor="right"
            open={addInterestModel}
            onClose={onClose}
            toggleDrawer
        >
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
            >
                <CreateOrEditInterestModel onClose={onClose} />
            </FormProvider>
        </Drawer>
    );
};

export default DrawerManageInterestModelContainer;
