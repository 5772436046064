import { Stack } from '@mui/material'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useTenant } from 'contexts/tenantContext';

export const CloneWithTenant = () => {
    const { tenantAutoCompleteProps } = useTenant();
    return (
        <Stack p='20px 0'>
            <AutocompleteField
                label="Correspondente"
                name="tenant"
                displayName="tenantDisplay"
                endIconType="submit"
                {...tenantAutoCompleteProps}
            />
        </Stack>
    )
}
