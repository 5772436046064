import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const BellSchoolIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M16.7305 5.88999C16.9771 6.22275 17.4468 6.29255 17.7796 6.04589C18.1124 5.79922 18.1822 5.32951 17.9355 4.99675L16.7305 5.88999ZM17.9355 16.0033C18.1822 15.6705 18.1124 15.2008 17.7796 14.9541C17.4468 14.7075 16.9771 14.7772 16.7305 15.11L17.9355 16.0033ZM10.5 22H9.75C9.75 22.4142 10.0858 22.75 10.5 22.75V22ZM21.25 10.5C21.25 11.4665 20.4665 12.25 19.5 12.25V13.75C21.2949 13.75 22.75 12.2949 22.75 10.5H21.25ZM19.5 12.25C18.5335 12.25 17.75 11.4665 17.75 10.5H16.25C16.25 12.2949 17.7051 13.75 19.5 13.75V12.25ZM17.75 10.5C17.75 9.5335 18.5335 8.75 19.5 8.75V7.25C17.7051 7.25 16.25 8.70507 16.25 10.5H17.75ZM19.5 8.75C20.4665 8.75 21.25 9.5335 21.25 10.5H22.75C22.75 8.70507 21.2949 7.25 19.5 7.25V8.75ZM10.5 18.25C6.21979 18.25 2.75 14.7802 2.75 10.5H1.25C1.25 15.6086 5.39137 19.75 10.5 19.75V18.25ZM2.75 10.5C2.75 6.21979 6.21979 2.75 10.5 2.75V1.25C5.39137 1.25 1.25 5.39137 1.25 10.5H2.75ZM10.5 2.75C13.0527 2.75 15.3172 3.9834 16.7305 5.88999L17.9355 4.99675C16.2515 2.72492 13.5479 1.25 10.5 1.25V2.75ZM16.7305 15.11C15.3172 17.0166 13.0527 18.25 10.5 18.25V19.75C13.5479 19.75 16.2515 18.2751 17.9355 16.0033L16.7305 15.11ZM21.25 10.5C21.25 16.4371 16.4371 21.25 10.5 21.25V22.75C17.2655 22.75 22.75 17.2655 22.75 10.5H21.25ZM11.25 22V19H9.75V22H11.25ZM12.75 10.5C12.75 9.25736 11.7426 8.25 10.5 8.25V9.75C10.9142 9.75 11.25 10.0858 11.25 10.5H12.75ZM10.5 8.25C9.25736 8.25 8.25 9.25736 8.25 10.5H9.75C9.75 10.0858 10.0858 9.75 10.5 9.75V8.25ZM8.25 10.5C8.25 11.7426 9.25736 12.75 10.5 12.75V11.25C10.0858 11.25 9.75 10.9142 9.75 10.5H8.25ZM10.5 12.75C11.7426 12.75 12.75 11.7426 12.75 10.5H11.25C11.25 10.9142 10.9142 11.25 10.5 11.25V12.75Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

BellSchoolIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
