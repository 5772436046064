import { string, object } from 'yup';

export function validationCreditNoteListSchema() {
    const isCnabValid = ['Cnab444REAG', 'Cnab444', 'Cnab500', 'Cnab550'];

    return object().shape({
        cnab: string().typeError('Tipo do relatório inválido.').required('Tipo do relatório: precisa ser preenchido.'),
        fundId: string().when("cnab", {
            is: (value: string) =>  isCnabValid.includes(value),
            then: string().typeError('Fundo inválido.').required('Fundo: precisa ser preenchido.'),
            otherwise: string().notRequired().nullable()
        })
    });
}
export const defaultValuesCreditNoteList = {
    cnab: null, 
    fundId: null
};