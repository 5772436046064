import { GenericQueryParamsDash } from "../types/queryParams.types";
import { billingAxiosApi } from "services/axiosApi/axiosApi";

type RecordTypeProps = `BillingWalletTotal` | `BillingWalletOverdueBill` | `BillingWalletRegisteredXSettled` | 'BillingWalletRegistered'

export const getGenericDashboardAsync = async (filters: GenericQueryParamsDash, recordType: RecordTypeProps, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await billingAxiosApi.get(`/Dashboard/Metrics/${recordType}`, config);
};