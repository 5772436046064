import { ThemeContext } from '@uy3/web-components';
import { Outlet } from 'react-router';
import { IdentityProvider } from './contexts/identityContext';
import { ApiRequestProvider } from 'contexts/apiRequestContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ShowValuesProvider } from 'contexts/showValuesContext';
import { TenantProvider } from 'contexts/tenantContext';
import { AppAreaProvider } from 'contexts/appAreaContext';
import { AppConfigProvider } from 'contexts/appConfig';
import { activeTheme } from 'services/theme';
import HelmetContext from 'contexts/helmetContext';
import { EmailProvider } from 'contexts/emailContext';
import { FilterValuesProvider } from 'contexts/filterValuesContext';
import { SelectColumnsProvider } from 'contexts/SelectColumnContexts';
import { ProductIdProvider } from 'contexts/productIdContext';
import { FormFieldsErrorsProvider } from 'contexts/formFieldsErrors';
import { HandleSubmitContextProvider } from 'contexts/HandleSubmitCreditNote';
import { QueryCacheContextProvider } from 'contexts/queryCacheContext';
import { ShowNotificationsProvider } from 'contexts/showNotificationsContext';

const queryClient = new QueryClient();

function App() {
    return (
        <AppConfigProvider>
            <QueryClientProvider client={queryClient}>
                <AppAreaProvider>
                    <EmailProvider>
                        <IdentityProvider>
                            <FilterValuesProvider>
                                <FormFieldsErrorsProvider>
                                    <HandleSubmitContextProvider>
                                        <SelectColumnsProvider>
                                            <ApiRequestProvider>
                                                <TenantProvider>
                                                    <ProductIdProvider>
                                                        <QueryCacheContextProvider>
                                                            <ShowValuesProvider>
                                                                <ShowNotificationsProvider>
                                                                    <HelmetContext />
                                                                    <ThemeContext theme={activeTheme()}>
                                                                        <Outlet />
                                                                    </ThemeContext>
                                                                </ShowNotificationsProvider>
                                                            </ShowValuesProvider>
                                                        </QueryCacheContextProvider>
                                                    </ProductIdProvider>
                                                </TenantProvider>
                                            </ApiRequestProvider>
                                        </SelectColumnsProvider>
                                    </HandleSubmitContextProvider>
                                </FormFieldsErrorsProvider>
                            </FilterValuesProvider>
                        </IdentityProvider>
                    </EmailProvider>
                </AppAreaProvider>
            </QueryClientProvider>
        </AppConfigProvider>
    );
};
export default App;