import { creditAxiosApi } from "services/axiosApi/axiosApi";
import { IConciliacaoLiquidacaoCaixa, IRemessaLiquidacaoForDate, IRemessaLiquidacaoForOp } from "./remessasVortx.type";

let url = "/Vortx";

export const conciliacaoLiquidacaoCaixa = async (dataRequest: IConciliacaoLiquidacaoCaixa, token: string) => {
  const bodyFormData = new FormData();
  const payload = dataRequest.payload; 

  for (let key in payload) {
    //@ts-ignore
    let currentData = payload[key];
    if (currentData !== null) {
      bodyFormData.append(key, currentData);
    }
  }

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    params: {
      persist: dataRequest.params.persist,
      queryCaixa: dataRequest.params.queryCaixa
    }
  };

  return await creditAxiosApi.post(`${url}/ConciliacaoLiquidacaoCaixa`, bodyFormData, config);
};

export const remessaLiquidacaoForDate = async (params: IRemessaLiquidacaoForDate, token: string) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: params
  };

  return await creditAxiosApi.get(`${url}/GenerateRemessaLiquidacaoForDate`, config);
};

export const getRemessaLiquidacaoForOp = async (params: IRemessaLiquidacaoForOp, token: string) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: params
  };

  return await creditAxiosApi.get(`${url}/GenerateRemessaLiquidacaoForOp`, config);
};

export const postRemessaLiquidacaoForOp = async (params: IRemessaLiquidacaoForOp, token: string) => {

  const config = {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params: params
  };

  return await creditAxiosApi.post(`${url}/GenerateRemessaLiquidacaoForOp`, {}, config);
};

export async function sendRemessaAsync(filePath: string, token: string) {

  const config = {
    headers: { Authorization: `Bearer ${token}` },
    params: {
      filePath
    }
  };

  return await creditAxiosApi.post(`${url}/SendRemessa`, {}, config);
}