import { Typography } from "@uy3/web-components";

interface ITitleProps {
    title: string;
    value: any;
    key: string;
};

export function HandleTitle({ title, value, key }: ITitleProps) {
    return (
        <div key={key}>
            <Typography variant="h6" color="neutral.dark" mb={1} fontWeight={600} >
                {title}:&nbsp; <DatasetValues value={value} />
            </Typography>
        </div>
    );
}

function DatasetValues({ value }: { value: any }) {
    return (
        <Typography variant="sm" color="neutral.medium" fontWeight={500}>
            {value}
        </Typography>
    );
};
