import { Stack } from '@mui/material';
import { FormProvider } from 'contexts/formContext';
import { EditBilling } from './EditBilling';
import { validationSchemaEditBilling, defaultValuesEditBilling } from './EditBillingSchema';
import { BillingFull } from 'services/walletManagement/billing/billing.types';
import React, { useState } from 'react';
import { usePatchBillingAsyncMutation } from 'contexts/wallet/Billing/billingContext';
import { ApiResponseError, ToastType, showSuccessToast, useApiRequest } from 'contexts/apiRequestContext';
import { FieldValues } from 'react-hook-form';
import { Drawer } from '@uy3/web-components';
import { defaultValuesCategoriesForm, validationSchemaCategoriesForm } from 'pages/WalletManagement/Dashboard/DashboardContainer/Drawer/NewCharge/FormStep/CreateCharges/AddCategoriesDrawer/CategoriesSchema';
import CreateOrEditCategories from 'pages/WalletManagement/Dashboard/DashboardContainer/Drawer/NewCharge/FormStep/CreateCharges/AddCategoriesDrawer/CreateOrEditCategories';

type EditBillingContainerProps = {
    formValues: BillingFull;
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetch: () => void;
    allowEditGuarantor?: boolean
    allowEditTags?: boolean
}

export const EditBillingContainer: React.FC<EditBillingContainerProps> = ({
    formValues,
    onClose,
    setToast,
    refetch,
    allowEditGuarantor = false,
    allowEditTags = false
}) => {
    const [categories, setCategories] = useState<string[]>(formValues?.tags);
    const [allowUpdateTags, setAllowUpdateTags] = useState<boolean>(false);
    const onCloseAllowUpdate = () => setAllowUpdateTags(false); 
    const { handleErrorException } = useApiRequest();
 
    const onSuccess = () => {
        const title = `Sucesso ao editar cobrança`;
        const description = "";
        showSuccessToast(title, description, setToast);
        onClose();
        refetch();
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)

    const { mutateAsync } = usePatchBillingAsyncMutation(formValues?.id, onSuccess, onError);

    const payer = formValues.payer;
    const defaultValue = {
        ...formValues,
        payer: payer,
        walletCodeDisplay: formValues.walletCode,
        personId: formValues?.payer.name,
        personIdDisplay: payer.name,
        tags: categories
    };

    const mapperCategories = categories?.map(item => {
        return {
            category: item
        }
    });

    const defaultValuesCategories = {
        categories: mapperCategories
    };

    const onSubmit = (values: FieldValues) => {
        const payload = {
            documentNumber: values?.documentNumber,
            description: values?.description,
            amount: values?.amount,
            dueDate: values?.dueDate,
            discount: values?.discount,
            payer: values?.payer,
            guarantor: values?.guarantor,
            tags: categories
        };
        mutateAsync(payload);
    }

    const onSubmitCategories = (values: FieldValues) => {
        const list: string[] = values?.categories?.map((item: { category: string }) => item?.category)
        setCategories(list);
        onCloseAllowUpdate();
    }

    return (
        <Stack spacing={2}>
            <FormProvider
                defaultValues={defaultValue ?? defaultValuesEditBilling}
                validationSchema={validationSchemaEditBilling()}
                onSubmit={onSubmit}
            >
                <EditBilling {...{
                    onClose,
                    allowEditGuarantor,
                    allowEditTags,
                    handleUpdateTags: () => setAllowUpdateTags(true),
                    categories, 
                    setToast
                }} />
            </FormProvider>

            <Drawer
                title='Editar categorias da cobrança'
                anchor='right'
                toggleDrawer
                open={allowUpdateTags}
                onClose={onCloseAllowUpdate}
                children={(
                    <FormProvider
                        defaultValues={categories?.length > 0 ? defaultValuesCategories : defaultValuesCategoriesForm}
                        validationSchema={validationSchemaCategoriesForm()}
                        onSubmit={onSubmitCategories}
                    >
                        <CreateOrEditCategories onClose={onCloseAllowUpdate} isLoading={false} />
                    </FormProvider>
                )}
            />
        </Stack>
    )
}
