export const dateMask = [
	/\d/,
	/\d/,
	"/",
	/\d/,
	/\d/,
	"/",
	/\d/,
	/\d/,
	/\d/,
	/\d/
];