import { Stack } from "@mui/material";
import { Typography } from "@uy3/web-components";
import React, { ReactNode } from "react";
import { activeTheme } from "services/theme";

type HelpPermissionProps = {
    currentFormLabel: string;
    levelDisplay: string;
    isRestricted?: boolean
}

const theme = activeTheme();

export const SpanCustom = ({ children }: { children: ReactNode }) => {
    const sx = { color: theme.palette.primary.main };
    return <span style={sx}>{children}</span>
}

interface IChildren {
    children: ReactNode
}

export const HelpPermission = ({ currentFormLabel, levelDisplay, isRestricted }: HelpPermissionProps) => {
    const ListItem = ({ children }: IChildren) => {
        return <li style={{ margin: '10px 0 ', fontSize: '12px' }}>{children}</li>
    }
    return (
        <Stack>
            <Typography fontSize='14px'>
                Nível de permissão para <SpanCustom>{levelDisplay}</SpanCustom> dos registros de <SpanCustom>{currentFormLabel}</SpanCustom>.
            </Typography>
            <ul style={{ display: 'block', marginLeft: '-20px', fontSize: '13px' }}>
                <ListItem><SpanCustom>Nenhum: </SpanCustom> Sem acesso. O usuário não pode visualizar, modificar ou excluir os recursos.</ListItem>
                <ListItem><SpanCustom>Todos:</SpanCustom> Acesso total. O usuário pode fazer tudo com os recursos.</ListItem>
                {!isRestricted && (
                    <>
                        <ListItem><SpanCustom>Só os Meus:</SpanCustom> Acesso aos próprios recursos. O usuário pode apenas trabalhar com o que ele criou.</ListItem>
                        <ListItem><SpanCustom>Meus Grupos:</SpanCustom> Acesso aos recursos compartilhados nos grupos. O usuário pode trabalhar com recursos compartilhados.</ListItem>
                    </>)}
            </ul>
        </Stack>
    )
};