import type { ThemeOptions } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';

export const palette: ThemeOptions['palette'] = {
  mode: 'light',

  primary: {
    light: '#ECA973', // brand-primary-light
    main: '#D04D27', // brand-primary-medium
    dark: '#F46C00', // brand-primary-dark
    contrastText: '#FFFFFF',

    shade: {
      '10': alpha('#D04D27', 0.1),
    },
  },

  secondary: {
    light: '#ECA973', // brand-primary-light
    main: '#D04D27', // brand-primary-medium
    dark: '#F46C00', // brand-primary-dark
    contrastText: '#FFFFFF',
  },

  brand: {
    lightest: '#E9D4C4',
    light: '#ECA973',
    medium: '#F18B3A',
    dark: '#F46C00',
    darkest: '#D04D27',
  },

  neutral: {
    lightest: '#FFFFFF',
    light: '#BABFD0',
    medium: '#666666',
    dark: '#373737',
    darkest: '#000000',

    primaryShade: {
      '10': alpha('#373737', 0.1),
      '15': alpha('#BABFD0', 0.1),
      '20': alpha('#BABFD0', 0.2),
      '30': alpha('#BABFD0', 0.3),
      '45': alpha('#373737', 0.45),
      '87': alpha('#666666', 0.87),
    },
  },

  divider: alpha('#000000', 0.12),

  error: {
    light: '#F88078', // suport-danger-light
    main: '#F44336', // suport-danger-dark
    dark: '#F44336', // suport-danger-dark
    contrastText: '#FFFFFF',
  },

  warning: {
    light: '#FFB547', // suport-highlight-light
    main: '#FF9800', // suport-highlight-dark
    dark: '#C77700', // suport-highlight-darkest
    contrastText: '#FFFFFF',
  },

  success: {
    light: '#7BC67E', // suport-success-light
    main: '#4CAF50', // suport-success-dark
    dark: '#4CAF50', // suport-success-dark
    contrastText: '#FFFFFF',
  },

  info: {
    light: '#ACABF0', // suport-highlight-lightest
    main: '#7471FD', // suport-highlight-medium
    dark: '#7471FD', // suport-highlight-medium
    contrastText: '#FFFFFF',
  },

  common: {
    black: '#000000',
    white: '#ffffff',
  },
};
