import { LegalPersonBaseModel } from './generics';

export class LegalPersonUpdateModel extends LegalPersonBaseModel {
    relatedPerson?: IRelatedPersonUpdateModel[];

    constructor(init?: Partial<LegalPersonUpdateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

interface IRelatedPersonUpdateModel {
    id: string;
    legalPersonId: string;
    personId: string;
    typeOfRelationship: string;
    signatureType: string;
    signatureValidation: string;
}
