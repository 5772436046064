import { UseFormWatch } from "react-hook-form";
import { RelatedPerson } from "services/creditProduct";

type ImportRelatedPersonProps = {
  personId: string;
  setValue: (name: string, value: any) => void;
  watch: UseFormWatch<any>
  currentRelatedPerson: RelatedPerson[];
  relatedPersonToImport: RelatedPerson[];
}

export function importRelatedPersonByPersonId(props: ImportRelatedPersonProps): { allRelatedPersons: RelatedPerson[] } {
  const { currentRelatedPerson, personId, setValue, relatedPersonToImport, watch } = props;
  const allRelatedPersons: RelatedPerson[] = [];
  setValue(personId, relatedPersonToImport);
  currentRelatedPerson?.forEach((p) => {
    const relatedPerson = watch(p.personId) ?? [];
    const relatedPersonMapped = relatedPerson?.map((related: RelatedPerson) => {
      return {
        ...related,
        relatedToIdDisplay: p.personIdDisplay,
        relatedToId: p.personId
      }
    });
    allRelatedPersons.push(...relatedPersonMapped);
  });

  return { allRelatedPersons }
}