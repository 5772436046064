import { FormProvider } from "contexts/formContext";
import { RecoverPasswordFormDefaultValues, RecoverPasswordFormValidationSchema } from "./RecoverPasswordFormSchema";
import { RecoverPasswordForm } from "./RecoverPasswordForm";
import { useEffect, useState } from "react";
import { Alert } from "@uy3/web-components";
import { useIdentity } from "contexts/identityContext";
import { useNavigate } from "react-router-dom";

export function RecoverPasswordFormContainer() {

	const { sendCodeForPasswordRecover, confirmPasswordRecover, authFlow, navigateToFlow, submitError, clearSubmitError } = useIdentity();
	const navigate = useNavigate();
	const [codeSent, setCodeSent] = useState(false);
	const schema = RecoverPasswordFormValidationSchema(codeSent);
	const defaultValues = RecoverPasswordFormDefaultValues(codeSent);

	const submit = async (values: any) => {
		if (codeSent) {
			await confirmPasswordRecover(values.email, values.code, values.newPassword);
		} else {
			setCodeSent(true);
			await sendCodeForPasswordRecover(values.email);
		}
	};

	const error = (values: any) => console.log("RecoverPasswordFormContainer - onError", values);

	useEffect(() => {
		clearSubmitError();
	}, [clearSubmitError]);

	useEffect(() => {
		console.log("RecoverPasswordFormContainer - authFlow atualizado: " + authFlow?.type);
		navigateToFlow(navigate);
	}, [authFlow, navigate, navigateToFlow]);

	return <>
		{submitError && <Alert
			severity="error"
			text={submitError}
			variant="filled"
		/>}
		<FormProvider
			validationSchema={schema}
			defaultValues={defaultValues}
			onSubmit={submit}
			onError={error}>
			<RecoverPasswordForm
				goBack={() => codeSent ? setCodeSent(false) : navigate(-1)}
				codeSent={codeSent}
			/>
		</FormProvider>
	</>
}