import { Stack } from "@mui/system";
import { Button, Typography } from "@uy3/web-components";
import { useFormContext } from "contexts/formContext";
import { Link } from '@mui/material';
import { TextFormField } from "components/Forms/FormFields/TextFormField/TextFormField";

type ConfirmEmailFormProps = {
	sendCode: () => void
}

export function ConfirmEmailForm({ sendCode }: ConfirmEmailFormProps) {

	const { submitting } = useFormContext();

	return <>
		<Typography
			variant="h5"
			color="neutral.dark"
			lineHeight="34px"
			mb={2}
			fontWeight={400}
		>
			Confirmação de e-mail
		</Typography>
		<Stack direction="column" spacing={2} mb={2}>
			<Typography
				variant="caption">
				Um código será enviado por e-mail. Digito no campo abaixo e clica em Acessar para confirmar sua conta. <br />
				<Link style={{ cursor: "pointer" }} onClick={sendCode}>
					Pronto para receber o código? Clique aqui
				</Link>
			</Typography>
			<TextFormField name="code" type="text" label="Código de confirmação" variant="outlined" fullWidth required />
			<Button
				type="submit"
				variant="contained"
				size="medium"
				color="primary"
				disabled={submitting}
				fullWidth={true}
				sx={{ width: '100%'}}
			>
				Acessar
			</Button>
		</Stack>

	</>
}