import { RefreshProgress } from 'components/RefreshProgress';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import React, { useState } from 'react';
import FraudRecordForm from './FraudRecordForm';
import { FormProvider } from 'contexts/formContext';
import {
    EFraudRecordFormStep,
    defaultValuesFraudRecordForm,
    validationSchemaFraudRecordForm,
} from './schema/FraudRecord';
import { FieldErrors, FieldValues } from 'react-hook-form';
import { Stack } from '@mui/material';
import {
    Button,
    CloseIcon,
    LeftIcon,
    RightIcon,
    ShieldWarningIcon,
    Toast,
} from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import LocationForm from './LocationForm';
import DetailsForm from './DetailsForm';
import { onlyNumbers } from 'helpers/validations/formFieldValidator';


export interface FraudRecord {
    name?: string;
    registrationNumber?: string; 
    taxPayer: 'PF' | 'PJ';
    tradeName?: string;
}

interface FraudRecordContainerProps {
    onClose: () => void;
    onFraudRecordSubmit: (values: FieldValues) => void;
    fraudRecordDetails?: FraudRecord;
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
const FraudRecordContainer: React.FC<FraudRecordContainerProps> = ({
    onClose,
    onFraudRecordSubmit,
    fraudRecordDetails,
}) => {
    const { submitting } = useApiRequest();
    const [currentFormStep, setCurrentFormStep] = useState(EFraudRecordFormStep.FraudRecordForm);
    const [formValues, setFormValues] = useState<FieldValues>({});
    const [onError, setOnError] = useState<FieldErrors>();

    const handleProgress = (values: FieldValues) => {
        if (
            currentFormStep === EFraudRecordFormStep.FraudRecordForm ||
            currentFormStep === EFraudRecordFormStep.LocationForm
        ) {
            setFormValues((prev) => ({ ...prev, ...values }));
            setCurrentFormStep((prev) => prev + 1);
        } else {
            handleOnSubmit({ ...formValues, ...values });
        }
    };

    const formatDetails = (details: FieldValues) => {
        const filteredDetails: FieldValues = {};

        for (const key in details) {
            const value = details[key];

            const isObjectNotEmpty = typeof value === 'object' && Object.keys(value).length > 0;
            const hasNoUndefinedValues = !Object.values(value).every(
                (v) => v === undefined || v === 'Não informado'
            );

            if (isObjectNotEmpty && hasNoUndefinedValues) {
                filteredDetails[key] = formatDocumentNumber(value);
            }
        }

        return Object.keys(filteredDetails).length > 0 ? filteredDetails : null;
    };

    const formatDocumentNumber = (entity: FieldValues) => {
        return entity && entity.documentNumber !== undefined
            ? { ...entity, documentNumber: Number(onlyNumbers(entity.documentNumber)) }
            : entity;
    };

    const handleOnSubmit = (values: FieldValues) => {
        delete values['meta'];
        const details = values?.details;
        const formattedDetails = formatDetails(details);

        const payload = {
            ...values,
            details: formattedDetails,
        };

        onFraudRecordSubmit(payload);
    };

    const validationSchema = validationSchemaFraudRecordForm(currentFormStep);
    const defaultValues = defaultValuesFraudRecordForm(currentFormStep);

    const isLastFormStep = currentFormStep === EFraudRecordFormStep.DetailsForm;
    const startIcon = getStartIcon(isLastFormStep);
    const endIcon = getEndIcon(isLastFormStep);

    const children: { [key: number]: React.ReactNode } = {
        [EFraudRecordFormStep.FraudRecordForm]: <FraudRecordForm />,
        [EFraudRecordFormStep.LocationForm]: <LocationForm />,
        [EFraudRecordFormStep.DetailsForm]: <DetailsForm fraudRecordDetails={fraudRecordDetails} />,
    };

    const errorDetails = onError?.details ?? {};

    function getErrorMessage(errorDetails: any): string | undefined {
        if (Object.keys(errorDetails).length === 0) return undefined;
        const errorDetailsKeys = Object.keys(errorDetails);
        const details: { [key: string]: string } = {
            reporter: 'Reclamante',
            executor: 'Executor',
            toAccount: 'Conta de destino',
        };

        for (const key in errorDetailsKeys) {
            if (errorDetailsKeys[key] in details) {
                return `Ops! Parece que você esqueceu de preencher alguns campos obrigatórios em: ${
                    details[errorDetailsKeys[key]]
                }`;
            }
        }

        return undefined;
    }

    const errorMessage = getErrorMessage(errorDetails);

    return (
        <Stack spacing={3} mt={-5}>
            <RefreshProgress refreshing={submitting} />

            {isLastFormStep && errorMessage && (
                <Toast
                    open={!!errorMessage}
                    onClose={() => setOnError({})}
                    title={errorMessage}
                    severity="error"
                />
            )}

            <FormProvider
                validationSchema={validationSchema}
                defaultValues={defaultValues}
                onSubmit={handleProgress}
                onError={setOnError}
            >
                <Stack spacing={3}>
                    {children[currentFormStep]}
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={3}
                    >
                        <Button
                            startIcon={<CloseIcon htmlColor={colorMain} sx={iconSx} />}
                            variant="outlined"
                            size="medium"
                            name="btn-close"
                            onClick={onClose}
                        >
                            Fechar
                        </Button>
                        {currentFormStep !== EFraudRecordFormStep.FraudRecordForm && (
                            <Button
                                name="btn-back"
                                variant="outlined"
                                size="medium"
                                onClick={() => setCurrentFormStep((prev) => prev - 1)}
                                startIcon={<LeftIcon htmlColor={colorMain} sx={iconSx} />}
                            >
                                Voltar
                            </Button>
                        )}
                        <Button
                            name="btn-add"
                            type="submit"
                            startIcon={startIcon}
                            endIcon={endIcon}
                            variant="contained"
                            size="medium"
                        >
                            {isLastFormStep ? ' Registrar fraude' : 'Continuar'}
                        </Button>
                    </Stack>
                </Stack>
            </FormProvider>
        </Stack>
    );
};

export default FraudRecordContainer;

const getStartIcon = (isLastStep: boolean) => {
    return isLastStep ? (
        <ShieldWarningIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />
    ) : null;
};

const getEndIcon = (isLastStep: boolean) => {
    return isLastStep ? null : (
        <RightIcon htmlColor={theme.palette.primary.contrastText} sx={iconSx} />
    );
};
