import { Stack } from '@mui/material';
import { Button, CloseIcon, Drawer, TransactionalLimitsIcon } from '@uy3/web-components';
import { useState } from 'react';
import CurrentLimit from '../MyLimits/DrawerForm/DrawerDetails/CurrentLimit';
import DrawerFormContainer from '../MyLimits/DrawerForm/DrawerFormContainer';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';

const theme = activeTheme();
const ViewTransactionalLimitsDrawer: React.FC = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openDrawerFormContainer, setOpenDrawerFormContainer] = useState(false);
    const [getGroupedButton, setGroupedButton] = useState(0);

    return (
        <>
            <Stack direction="row" justifyContent="flex-start">
                <Button
                    variant="text"
                    size="small"
                    color="inherit"
                    onClick={() => setOpenDrawer(true)}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    Ver limites transacionais
                </Button>
            </Stack>

            <Drawer
                anchor="right"
                title="Limites Atuais"
                open={openDrawer}
                toggleDrawer
                onClose={() => setOpenDrawer(false)}
            >
                <CurrentLimit
                    setGroupedButton={setGroupedButton}
                    getGroupedButton={getGroupedButton}
                    toggleDrawer={true}
                />
                <Stack
                    direction={'row'}
                    alignItems="center"
                    justifyContent="flex-end"
                    spacing={3}
                    mt={3}
                >
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={iconSx}
                            />
                        }
                        variant="outlined"
                        size="medium"
                        onClick={() => setOpenDrawer(false)}
                    >
                        Fechar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        startIcon={
                            <TransactionalLimitsIcon
                                sx={{ height: 22, width: 22 }}
                                htmlColor="#fff"
                            />
                        }
                        onClick={() => {
                            setOpenDrawer(false);
                            setOpenDrawerFormContainer(true);
                        }}
                    >
                        Solicitar aumento de limite
                    </Button>
                </Stack>
            </Drawer>

            <DrawerFormContainer
                openDrawer={openDrawerFormContainer}
                setOpenDrawer={setOpenDrawerFormContainer}
            />
        </>
    );
};

export default ViewTransactionalLimitsDrawer;
