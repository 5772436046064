import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import { CheckboxFormField, TextFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { isEmpty } from 'lodash';
import { activeTheme } from 'services/theme';

const paletteCollor = activeTheme()?.palette;

type CommunicationSettingFormProps = {
    onClose: () => void;
    disableWalletCodeField?: boolean
};

export const CommunicationSettingForm: React.FC<CommunicationSettingFormProps> = ({ onClose, disableWalletCodeField }) => {
    const { watch, submitting } = useFormContext();
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });

    return (
        <Stack spacing={2}>
            <Grid xs={3}>
                <AutocompleteField
                    label="Código do convênio"
                    name="walletCode"
                    disabled={!isEmpty(watch('id')) || !!disableWalletCodeField}
                    displayName="walletCodeDisplay"
                    required
                    {...walletCodeAutoCompleteProps!}
                />
            </Grid>
            <Grid xs={3}>
                <TextFormField name="name" label="Nome" variant="outlined" fullWidth />
            </Grid>
            <Grid>
                <TextFormField
                    label="Dias para envio da cobranca antes do vencimento"
                    name="daysToSendBillingBeforeDueDate"
                    variant="outlined"
                    type="number"
                />
            </Grid>
            <Grid xs={3}>
                <TextFormField
                    name="walletNumber"
                    label="Número da carteira"
                    variant="outlined"
                    fullWidth
                    type='number'
                />
            </Grid>

            <Grid>
                <CheckboxFormField label="Notificar por e-mail" name="notifyByEmail" />
            </Grid>
            <Grid>
                <CheckboxFormField
                    label="Notificar liquidação"
                    name="notifyBillingAfterSettlement"
                />
            </Grid>
            <Grid>
                <CheckboxFormField label="Notificar atualização" name="notifyBillingUpdate" />
            </Grid>
            <Grid>
                <CheckboxFormField
                    label="Reenviar após vencimento"
                    name="resendBillingAfterOverDueDate"
                />
            </Grid>
            <Grid>
                <CheckboxFormField
                    label="Notificar depois do vencimento"
                    name="notifyBillingAfterOverDueDate"
                />
            </Grid>

            <Stack direction="row" alignItems="center" spacing={2} justifyContent="end">
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon sx={iconSx} htmlColor={paletteCollor.primary.main} />}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    disabled={submitting}
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={paletteCollor.common.white} />}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
