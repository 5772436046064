import { activeTheme } from "services/theme";
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { Typography } from "@uy3/web-components";

const theme = activeTheme();
export const TooltipContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 8px 12px;
    width: 288px;
    height: 186px;
    background: #000000;
    border-radius: 8px;
`;

export const TooltipContent = styled(Box)`
    padding-bottom: 5px;
    width: 100%;
    margin-bottom: 4px;
`;

export const TooltipTitle = styled(Typography)`
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: ${theme.palette.common.white};
    opacity: 0.8;
`;

export const TooltipSpan = styled.span`
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 8px;
    color: ${theme.palette.common.white};
`;

export const TooltipIcon = styled(Box)(({ color }: any) => ({
    display: 'inline-block',
    width: 12,
    height: 12,
    backgroundColor: color,
    marginRight: 8,
    borderRadius: '4px',
}));