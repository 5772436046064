import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';

type GareIpvaFormProps = {
    onClose: () => void;
}

const theme = activeTheme();
export const GareIpvaForm = ({ onClose }: GareIpvaFormProps) => {
    const { watch } = useFormContext();
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, "").length > 11;

    return (
        <Stack spacing={2}>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='name'
                    label='Nome do arrecadador'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    name='registrationNumber'
                    variant='outlined'
                    label='CPF/CNPJ'
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace }
                    }}
                    fullWidth
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='typeSpecie'
                    label='Tipo espécie do veículo'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type='number'
                    name='currentBoard'
                    label='Placa atual'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type='number'
                    name='quote'
                    label='Número da cota'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    type='number'
                    name='taxPayer'
                    label='Número de contribuente'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='receitaFederalCodeId'
                    label='Código da receita federal'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='ipvaRange'
                    label='Código da faixa'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='cityCode'
                    label='Código do município'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <TextFormField
                    fullWidth
                    name='exercise'
                    label='Exercício'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name='valueOfRevenue'
                    label='Valor da receita'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name='paymentValue'
                    label='Valor do pagamento'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name='principalValue'
                    label='Valor principal'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <CurrencyFormField
                    fullWidth
                    name='totalValue'
                    label='Valor total'
                    variant='outlined'
                    required
                />
            </Grid>
            <Grid item>
                <DatePickerFormField
                    label='Data de pagamento'
                    name='paymentDate'
                    fullWidth
                    disablePast
                    required
                />
            </Grid>
            <Grid item>
                <DatePickerFormField
                    label='Data de vencimento'
                    name='dueDate'
                    fullWidth
                    required
                />
            </Grid>
            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-submit-icms"
                    type="submit"
                    variant="contained"
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    )
}
