import {
    RelatedPeoplePhonesList,
    AddressesExtended,
    PesquisaProtesto,
    PGFNList,
    PhonesList,
    JudicialProcesses,
    KycAndCompliance,
    Sintegra,
    BasicData,
    AssociatedPeopleList,
    PartnersLawsuits,
    FraudRecords,
} from 'components/Datasets';
import DynamicAccordion from 'components/Datasets/Accordion/Accordion';
import InfoMessage from 'components/InfoMessage/InfoMessage';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';

type DatasetLegalPersonContainerProps = {
    personId?: string | undefined;
};

export const DatasetLegalPersonContainer: React.FC<DatasetLegalPersonContainerProps> = ({
    personId,
}) => {
    const resource = 'LegalPerson';

    const { data: permissionsData } = useUserPermissionData();
    const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, resource);
    const disableRequestDataSet = !checkActionsPerms('RequestDataSet', permissionsData, resource);

    const accordion = [
        {
            title: 'Consultar Fraude',
            content: hasPermissionToReadDataset ? (
                <FraudRecords
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Consulta de CNPJ',
            content: hasPermissionToReadDataset ? (
                <BasicData disableRequestDataSet={disableRequestDataSet} datasetId={personId} />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Endereços',
            content: hasPermissionToReadDataset ? (
                <AddressesExtended
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Protestos',
            content: hasPermissionToReadDataset ? (
                <PesquisaProtesto
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'KYC e Compliance',
            content: hasPermissionToReadDataset ? (
                <KycAndCompliance
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Processos Judiciais e Administrativos',
            content: hasPermissionToReadDataset ? (
                <JudicialProcesses
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Ações Judiciais relacionadas aos sócios',
            content: hasPermissionToReadDataset ? (
                <PartnersLawsuits
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Procuradoria Geral da Fazenda Nacional',
            content: hasPermissionToReadDataset ? (
                <PGFNList
                    recordType={resource}
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'SINTEGRA - Sistema Integrado de Operações Interestaduais com Mercadorias e Serviços',
            content: hasPermissionToReadDataset ? (
                <Sintegra disableRequestDataSet={disableRequestDataSet} datasetId={personId} />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Pessoas Relacionadas',
            content: hasPermissionToReadDataset ? (
                <AssociatedPeopleList
                    disableRequestDataSet={disableRequestDataSet}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Telefones',
            content: hasPermissionToReadDataset ? (
                <PhonesList
                    recordType={resource}
                    datasetId={personId}
                    disableRequestDataSet={disableRequestDataSet}
                />
            ) : (
                <InfoMessage />
            ),
        },
        {
            title: 'Telefones de relacionados',
            content: hasPermissionToReadDataset ? (
                <RelatedPeoplePhonesList
                    disableRequestDataSet={disableRequestDataSet}
                    recordType={resource}
                    datasetId={personId}
                />
            ) : (
                <InfoMessage />
            ),
        },
    ];

    return <DynamicAccordion accordion={accordion} />;
};
