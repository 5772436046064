import { Grid, Stack } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GridColDef } from '@mui/x-data-grid';
import { Button, DataTable, SearchIcon } from '@uy3/web-components';
import { useState } from 'react';
import { JudicialProcessesDatasetGet } from 'services/datasets/datasets.interface';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';

type JudicialProcessesProps = {
    data: JudicialProcessesDatasetGet;
    setRowData: (rowData: any) => void;
    setModal: (modal: boolean) => void;
    isLoading: boolean;
};

const formatDate = (date: any) => {
    switch (date) {
        case '0001-01-01T00:00:00':
        case null:
        case undefined:
        case '':
            return 'N/D';
        default:
            return new Date(date).toLocaleDateString('pt-BR');
    }
};

function JudicialProcessesList({ data, setRowData, setModal, isLoading }: JudicialProcessesProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'mainSubject',
            headerName: 'Assunto',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'type',
            headerName: 'Tipo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1.5,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'number',
            headerName: 'Número',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'courtName',
            headerName: 'Tribunal',
            hideSortIcons: true,
            minWidth: 80,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 80,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setRowData(cellValues.row);
                                setModal(true);
                            }}
                            startIcon={<SearchIcon sx={{ height: 20, width: 20, ml: 1 }} />}
                        />
                    </Stack>
                );
            },
        },
    ];

    const processes = data?.result?.processes;

    return (
        <>
            <Grid2 mt={2}>
                <HandleTitle
                    title={'Total de processos'}
                    value={data?.result?.totalLawsuits ?? 'N/D'}
                    key="totalLawsuits"
                />
                <HandleTitle
                    title={'Total de processos como autor'}
                    value={data?.result?.totalLawsuitsAsAuthor ?? 'N/D'}
                    key="totalLawsuitsAsAuthor"
                />
                <HandleTitle
                    title={'Total de processos como réu'}
                    value={data?.result?.totalLawsuitsAsDefendant ?? 'N/D'}
                    key="totalLawsuitsAsDefendant"
                />
                <HandleTitle
                    title={'Data da primeira ocorrência de processo em que a pessoa está envolvida'}
                    value={formatDate(data?.result?.firstLawsuitDate)}
                    key="firstLawsuitDate"
                />
                <HandleTitle
                    title={'Data da última ocorrência de processo em que a pessoa está envolvida'}
                    value={formatDate(data?.result?.lastLawsuitDate)}
                    key="lastLawsuitDate"
                />
                <HandleTitle
                    title={'Soma do valor total das causas'}
                    value={formatCurrencyInCents(data?.result?.sumTotalProcessesValue) ?? 'N/D'}
                    key="sumTotalProcessesValue"
                />
            </Grid2>

            <DataTable
                loading={isLoading}
                getRowId={() => Math.random().toString()}
                columns={columns}
                rows={processes ?? []}
                page={page}
                rowCount={data?.result?.totalLawsuits ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
        </>
    );
}

export default JudicialProcessesList;
