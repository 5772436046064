import React from 'react';
import { useTheme } from '@mui/material';
import { CustomGrid } from './CardRadio.styles';
import { CardRadioProps } from './CardRadio.interfaces';
import { Radio, Typography } from '../../atoms';

export const CardRadio: React.FC<CardRadioProps> = ({
  title,
  key,
  value,
  selectedValue,
  ...props
}) => {
  const theme = useTheme();
  const color = value === selectedValue ? theme.palette.primary.main : theme.palette.common.black;

  return (
    <CustomGrid
      container
      key={key}
      borderColor={color}
      sx={{
        '&:hover': {
          cursor: 'pointer',
          color,
        },
      }}
      color={color}
    >
      <Typography variant="md">{title}</Typography>
      {/* @ts-ignore */}
      <Radio key={key} value={value} {...props} />
    </CustomGrid>
  );
};
