/* eslint-disable no-mixed-operators */
import { Block, Done, PriorityHigh } from '@mui/icons-material';
import { Drawer, UpdateIcon } from '@uy3/web-components';
import { Error } from 'components/Errors/Error';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useState, useEffect } from 'react';
import { activeTheme } from 'services/theme';
import { DatasetDetailsCredit } from '../DatasetDetailsCredit/DatasetDetailsCredit';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import Spinner from './Spinner';
import { ComplianceOrCreditAnalysisGet } from 'services/datasets/datasets.interface';
import { Box } from '@mui/material';
import { highRisk, mediumRisk } from './conditionRule';
type DatasetsStatusColumnProps = {
    recordType: string;
    id: string;
    datasetName: string;
};

export default function DatasetsStatusColumnContainer(props: DatasetsStatusColumnProps) {
    const theme = activeTheme();
    const { recordType, id, datasetName } = props;
    const [dataColumn, setDataColumn] = useState<any>(null);
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [datasetMotorAnalysis, setDatasetMotorAnalysis] =
        useState<ComplianceOrCreditAnalysisGet>();
    const { setSubmitError } = useApiRequest();

    const { dataset, status, error, refetch, isFetching } = useDatasetData(recordType, id!, datasetName);

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'credit_analysis' || dataset?.dataSet === 'compliance_analysis'
    ) as ComplianceOrCreditAnalysisGet;
    const result = data?.result;
    const overview = result?.overview ?? [];
    const hasBlock = overview.bloqueio === false; 

    const checkIsHeightOrMediumRisk = result?.details?.find(detail => {
        let logsFiltered = detail?.logs?.filter((item) => {
            return item.alerta === true || item.bloqueio === true
        });
        const onlyRegraDescricao = logsFiltered.map(item => item?.regra_descricao);
        const rule = onlyRegraDescricao.includes(mediumRisk) || onlyRegraDescricao.includes(highRisk);
        return hasBlock && rule;
    });

    useEffect(() => {
        if (!id) return;
        setDataColumn(result ?? []);
    }, [result, id, refetch]);

    const onSuccess = (data: Array<ComplianceOrCreditAnalysisGet>) => {
        setDataColumn(data);
        refetch();
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        setDataColumn([]);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        setDataColumn(null);
        setSubmitError(undefined);
        mutate(values);
    };

    const handleAnalysis = () => {
        if (result) {
            setDatasetMotorAnalysis(result as unknown as ComplianceOrCreditAnalysisGet);
            setOpenDrawer(true);
            setDataColumn(overview);
        }
    };

    const handleCloseDrawer = () => setOpenDrawer(false);

    if (status === 'error') return <Error error={error} />;

    return (
        <>
            <Drawer
                anchor="right"
                title={'Relatório de analise'}
                open={!!openDrawer}
                onClose={handleCloseDrawer}
            >
                <DatasetDetailsCredit dataDetails={datasetMotorAnalysis} />
            </Drawer>
            <>
                {(isLoading || isFetching) ? (
                    <Spinner />
                ) : (
                    <Box sx={{
                        margin: '0 20px'
                    }}>
                        {dataColumn?.length === 0 ? (
                            <UpdateIcon
                                sx={{ height: 18, width: 18, cursor: 'pointer', color: theme.palette.primary.main, display: 'inline-block' }}
                                onClick={() => handleUpdateData(datasetName)}
                            />
                        ) : (
                            <></>
                        )}
                        {overview?.alerta === false && hasBlock && (
                            <Done
                                onClick={() => handleAnalysis()}
                                style={{
                                    color: 'green',
                                    cursor: 'pointer',
                                    minWidth: 20,
                                    display: 'inline-block',
                                    textAlign: 'center',
                                }}
                            />
                        )}
                        {!!checkIsHeightOrMediumRisk && (
                            <Done
                                onClick={() => handleAnalysis()}
                                style={{
                                    color: 'green',
                                    cursor: 'pointer',
                                    minWidth: 20,
                                    display: 'inline-block',
                                    textAlign: 'center',
                                }}
                            />
                        )}
                        {(overview?.alerta === true && hasBlock) && !checkIsHeightOrMediumRisk && (
                            <PriorityHigh
                                onClick={() => handleAnalysis()}
                                style={{
                                    cursor: 'pointer',
                                    minWidth: 20,
                                    display: 'inline-block',
                                    textAlign: 'center',
                                }}
                            />
                        )}
                        {overview?.bloqueio === true && (
                            <Block
                                onClick={() => handleAnalysis()}
                                style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    minWidth: 20,
                                    display: 'inline-block',
                                    textAlign: 'center',
                                }}
                            />
                        )}
                    </Box>
                )}
            </>
        </>
    );
}
