import { useFormContext } from 'contexts/formContext';
import { Autocomplete, AutocompleteProps } from 'components/Forms/Autocomplete';
import { Typography } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { isFieldDisable } from 'helpers';

const theme = activeTheme();

type AutocompleteFieldProps = {
    name: string;
    displayName?: string;
} & AutocompleteProps;

export default function AutocompleteField(props: AutocompleteFieldProps) {
    const { name, displayName = "" } = props;
    const { formFieldsErrors } = useFormFieldsError();
    const { validationErrors, watch, setValue, readOnly } = useFormContext();
    let error = validationErrors && resolve(name, validationErrors)?.message;
    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key);
    });

    const userOnChange = props.onChange;
    
    const onChange = (_: any, opt: any) => {        
        setValue(name, opt?.value, { shouldDirty: true });
        setValue(displayName, opt?.label, { shouldDirty: true });

        let nameWithoutId = name?.replace("Id", "");
        if (nameWithoutId) setValue(`${nameWithoutId}Discriminator`, opt?.discriminator, { shouldDirty: true });
        
        userOnChange && userOnChange(_, opt);                
    };

    const autoCompleteValue = { label: watch(displayName), value: watch(name) };
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);

    return (
        <div>
            <Autocomplete
                disabled={isFieldDisabledConfig || readOnly}
                value={autoCompleteValue}
                error={error?.length > 0 ? true : false}
                {...props}
                onChange={onChange}
            />
            {!!error &&
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    {error}
                </Typography>}
            {!!hasResponseError &&
                <Typography variant="body1" mt={0.5} color={theme.palette.error.dark}>
                    {responseError?.helpMessage}
                </Typography>}
        </div>);
};