import { isEmpty } from "lodash";
import { BillingFiltersProps } from "services/walletManagement/billing/billing.types";

export function paramsFilterBillingList(filters: BillingFiltersProps): BillingFiltersProps{
    let filterSearchString = filters?.searchString;
    let filterRegistrationNumber = filters.payerRegistrationNumber?.replace(/\D/g, '');

    if (!isEmpty(filterRegistrationNumber) && isRequiredStartZero(filterRegistrationNumber!)) {
        filterRegistrationNumber = filters.payerRegistrationNumber?.padStart(11, '0')
    }
    const filterOnlyNumbers = filters.searchString?.replaceAll('-', '').replaceAll('.', ''); 
    if (!isEmpty(filterSearchString) && /^\d+$/.test(filterOnlyNumbers!)) {
        if (filterOnlyNumbers?.startsWith('0')) {
            filterSearchString = filterOnlyNumbers?.slice(1)
        }  else {
            filterSearchString = filterOnlyNumbers
        }
    }


    return {
        ...filters, 
        payerRegistrationNumber: filterRegistrationNumber ?? filters.payerRegistrationNumber, 
        searchString: filterSearchString ?? filters.searchString
    }
}


const isRequiredStartZero = (value: string) => {
    return value?.startsWith('0') && value?.length < 11
}