import { Amortization, DefaultSchemaAmortization, ValidationSchemaAmortization } from './Amortization';

export abstract class CleanPrice extends Amortization {}

export class DefaultSchemaAmmCleanPrice extends DefaultSchemaAmortization implements CleanPrice
{
    public amortizationType = 'cleanPrice';
}


export class ValidationSchemaAmmCleanPrice extends ValidationSchemaAmortization {}
