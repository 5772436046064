import React, { useState } from 'react'
import { Drawer } from '@uy3/web-components';
import { useBankAccountPixKeyClaimData, useConfirmOrCancelPixKeyClaimMutation } from 'contexts/bankAccount/bankAccountPixKey';
import { useParams } from 'react-router';
import { FormProvider } from 'contexts/formContext';
import { ClaimsRequests } from './ClaimsRequests';
import { validationSchemaClaimRequest, defaultValuesClaimRequest } from './ClaimRequestSchema';
import { ClaimsRequestsOptions } from './ClaimsRequestsOptions/ClaimsRequestsOptions';
import { ConfirmOrCancelClaim } from './ConfirmOrCancelClaim/ConfirmOrCancelClaim';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import FinishPixKey from '../FinishPixKey/FinishPixKey';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

type stepsDrawer = "optionsClaim" | "confirmOrCancel" | "finish";

export const ClaimsRequestsContainer = () => {
  const { bankAccountId } = useParams();
  const [toast, setToast] = useState<ToastType>(toastState);
  const [claimId, setClaimId] = useState<string>("");
  const [openDrawer, setOpenDrawer] = useState<stepsDrawer | undefined>(undefined);

  const { data, refetch, isFetching } = useBankAccountPixKeyClaimData(bankAccountId!);
  const claimListData = data?.filter((claim: any) => claim.isDonorParticipant && claim.isDonorPerson);
  const claimRequestSelected = claimListData?.find((item: any) => item.claim.id === claimId);

  const onCloseDrawer = () => setOpenDrawer(undefined);

  const onSubmitClaimRequest = (values: any) => {
    setClaimId(values?.claimId);
    setOpenDrawer('confirmOrCancel');
  };

  const onSuccess = (values: any) => {
    setClaimId(values?.claimId);
    setOpenDrawer('finish');
    const title = 'Ação concluída com sucesso!';
    const description = undefined;
    showSuccessToast(title, description, setToast);
  };

  const onError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error);
    const title = 'Ops, ocorreu um erro!';
    const description = errorMessage;
    showErrorToast(title, description, setToast);
  };

  const { mutate, isLoading } = useConfirmOrCancelPixKeyClaimMutation(bankAccountId!, onSuccess, onError);

  const onConfirmClaim = (claimId: string) => {
    mutate({
      claimId,
      queueClaimStatus: 'Confirm'
    });
  }

  const onCancelClaim = (claimId: string) => {
    mutate({
      claimId,
      queueClaimStatus: 'Cancel'
    })
  };

  return (
    <>
      <Toast toast={toast} setToast={setToast} />
      {claimListData?.length > 0 && <ClaimsRequests
        openDrawer={() => setOpenDrawer('optionsClaim')}
      />}
      <Drawer
        anchor='right'
        open={openDrawer === 'optionsClaim'}
        onClose={onCloseDrawer}
        title='Pedido de portabilidade da chave Pix'
        description='Isso significa que outra instituição registrou pedido em seu nome para que essa chave seja vinculada a outra conta'
        children={
          <FormProvider
            defaultValues={defaultValuesClaimRequest}
            validationSchema={validationSchemaClaimRequest()}
            onSubmit={onSubmitClaimRequest}
          >
            <ClaimsRequestsOptions
              isFetching={isFetching}
              refetch={refetch}
              claimList={claimListData ?? []}
              onClose={onCloseDrawer}
            />
          </FormProvider>
        }
      />
      <Drawer
        anchor='right'
        open={openDrawer === 'confirmOrCancel'}
        onClose={() => setOpenDrawer('optionsClaim')}
        title='Pedido de portabilidade da chave Pix'
        description='Isso significa que outra instituição registrou pedido em seu nome para que essa chave seja vinculada a outra conta'
        children={
          <ConfirmOrCancelClaim
            claimData={claimRequestSelected ?? {}}
            isLoading={isLoading}
            onConfirmClaim={(claimId) => onConfirmClaim(claimId)}
            onCancelClaim={(claimId) => onCancelClaim(claimId)}
          />
        }
      />

      <Drawer
        anchor='right'
        open={openDrawer === 'finish'}
        onClose={onCloseDrawer}
        title="Ação concluída com sucesso"
        children={
          <FinishPixKey
            finishingFlowByExternal={false}
            values={{ protocolId: claimId }}
            onClose={() => setOpenDrawer(undefined)}
          />
        }
      />
    </>
  )
}