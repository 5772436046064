import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesApprovalsSignature, validationSchemaApprovalsSignature } from './ApprovalsSignatureSchema'
import { Grid, Stack } from '@mui/material'
import { TextFormField } from 'components/Forms/FormFields'
import { Button } from '@uy3/web-components'
import { FieldValues } from 'react-hook-form'
import { ApiResponseError, handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext'
import { useApproveOrRejectBankAccountRequestMutation } from 'contexts/bankAccount/bankAccountRequest'
import { useParams } from 'react-router'

type ApprovalsSignatureFormProps = {
  resource: string
  onClose: () => void;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>;

}

export const ApprovalsSignatureForm: React.FC<ApprovalsSignatureFormProps> = ({ onClose, resource, setToast }) => {
  const { bankAccountId } = useParams();
  const resourceOption = resource as 'approveSignature' | 'disapproveSignature';
  const isDisapprove = resourceOption === 'disapproveSignature';
  
  const onSuccess = () => {
    const optionsMessage: { [type: string]: string } = {
      "approveSignature": 'Sucesso ao aprovar assinatura',
      "disapproveSignature": 'Sucesso ao rejeitar assinatura'
    };
    showSuccessToast(optionsMessage[resourceOption!], "", setToast);
  };

  const onError = (response: ApiResponseError) => handleOnError(response, setToast);

  const { mutateAsync } = useApproveOrRejectBankAccountRequestMutation(bankAccountId!, isDisapprove, onSuccess, onError);

  async function onSubmit(values: FieldValues) {
    let payload: object = {};
    if (isDisapprove) {
      payload = {
        message: values?.message
      }
    } else {
      payload = {}
    }
    await mutateAsync(payload);
  };

  return (
    <FormProvider
      validationSchema={validationSchemaApprovalsSignature(resourceOption)}
      defaultValues={defaultValuesApprovalsSignature}
      onSubmit={onSubmit}
    >
      <>
        {isDisapprove && <Grid container direction="row" justifyContent="flex-start" alignItems="center">
          <Grid item xs={12} mt={2}>
            <TextFormField
              name="message"
              label="Mensagem"
              multiline
              rows={3}
              variant="outlined"
              fullWidth
              required={isDisapprove}
            />
          </Grid>
        </Grid>}

        <Stack direction="row" justifyContent={!isDisapprove ? 'flex-start' : 'flex-end'} alignItems="center" spacing={2} mt={3} >
          <Button
            variant="outlined"
            sx={{width: !isDisapprove ? '100%' : 'auto'}}
            onClick={onClose}
            size="medium"
          >
            Não, cancelar
          </Button>
          <Button
            sx={{width: !isDisapprove ? '100%' : 'auto'}}
            variant="contained"
            type="submit"
            size="medium"
          >
            Sim, confirmar
          </Button>
        </Stack>
      </>
    </FormProvider>
  )
}
