import { useState } from 'react';
import { StepsPayment } from 'services/accounts/bankAccount';
import DarfContainer from '../DarfForm/DarfContainer';
import { ToastType } from 'contexts/apiRequestContext';

type DarfBlackContainerProps = {
    refetch: () => void;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const DarfBlackContainer = ({ refetch, onCloseDrawer, setToast }: DarfBlackContainerProps) => {
    const [stepPayment, setStepPayment] = useState<StepsPayment>();

    const title = stepPayment === 'requestDarf' ? 'DARF sem código de barras' : 'Confirme a operação';

    return (
        <>
            <DarfContainer
                title={title}
                setStepPayment={setStepPayment}
                stepPayment={stepPayment}
                recordType="DARFPreto"
                refetch={refetch}
                onCloseDrawer={onCloseDrawer}
                setToast={setToast}
            />
        </>
    );
};
