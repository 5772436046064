import { FormProvider, useFormContext } from 'contexts/formContext'
import React, { useState } from 'react'
import { QueryBasicData } from './QueryBasicData'
import { Drawer } from '@uy3/web-components'
import { validationSchemaQueryData, defaultValuesQueryData } from './QueryBasicDataSchema'
import { FieldValues } from 'react-hook-form'
import { IQueryBasicData } from 'contexts/personContext'
import { isEmpty } from 'lodash'
import { useIdentity } from 'contexts/identityContext'
import Toast from 'components/Toast/Toast'
import { ApiResponseError, ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { handleSubmitQueryData, queryBasicDataPersonMethods } from 'helpers/methods/queryBasicDataPerson'
import { SelectOptionsDrawerContainer } from './SelectOptions/SelectOptionsContainer'

type QueryBasicDataContainerProps = {
    enableModal: boolean;
    title?: string;
    description?: string;
    onClose: () => void
    resource: "NaturalPerson" | "LegalPerson"
}

export const QueryBasicDataContainer: React.FC<QueryBasicDataContainerProps> = ({
    enableModal,
    title = "Carregar informações",
    description = "Informe um número de documento desejado para que realize a consulta.",
    onClose,
    resource
}) => {
    const { startRequest, endRequest } = useApiRequest();
    const [queryData, setQueryData] = useState<IQueryBasicData | undefined>(undefined)
    const [toast, setToast] = useState<ToastType>(toastState);
    const [enableStep, setEnableStep] = useState<boolean>(false);
    const { token } = useIdentity();
    const { watch, setValue } = useFormContext();
    const currentRegistrationNumber = watch('registrationNumber');

    const onCloseAllSteps = () => {
        onClose();
        setEnableStep(false)
    }

    const { handleSetValuesPerson, handleSaveValuesByDrawer } = queryBasicDataPersonMethods(setValue, resource, onCloseAllSteps);

    const onSuccess = (queryData: IQueryBasicData, registrationNumber: string) => {
        setQueryData(queryData);
        setValue('registrationNumber', registrationNumber);

        if (queryData.addresses?.length > 1 || queryData.emails?.length > 1 || queryData.phones.length > 1) {
            return setEnableStep(true);
        }

        handleSetValuesPerson(queryData);
    }

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error as ApiResponseError);
        endRequest(false);
        setToast({
            open: true,
            severity: 'error',
            title: 'Ops, ocorreu um erro!',
            description: errorMessage
        });
    }

    const onSubmit = async (values: FieldValues) => {
        startRequest();
        const registrationNumber = values?.registrationNumber.replace(/\D/g, '');

        await handleSubmitQueryData(
            registrationNumber,
            token!,
            (data) => onSuccess(data, registrationNumber),
            onError
        );
        endRequest(true)
    };

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />

            <Drawer anchor='right' open={enableModal} title={title} description={description} >
                <FormProvider
                    defaultValues={!isEmpty(currentRegistrationNumber) ? { registrationNumber: currentRegistrationNumber } : defaultValuesQueryData}
                    validationSchema={validationSchemaQueryData(resource)}
                    onSubmit={onSubmit}
                >
                    <QueryBasicData
                        isLegalPerson={resource === 'LegalPerson'}
                        onClose={onClose}
                    />
                </FormProvider>
            </Drawer>

            <SelectOptionsDrawerContainer
                enable={enableStep}
                onClose={() => setEnableStep(false)}
                onSubmit={(formValues) => handleSaveValuesByDrawer(formValues, queryData!)}
                queryData={queryData!}
                toggleDrawer
                title={resource === 'LegalPerson' ? queryData?.legalPerson.companyName : queryData?.naturalPerson.name}

            />
        </React.Fragment>
    )
}
