import { string, object, array, date } from 'yup';
import { isAfter, isToday } from 'date-fns';

export function validationSchemaSignatureForm() {
    return object().shape({
        deadLine: date()
            .typeError('Data limite para assinar o arquivo: precisa ser preenchido.')
            .required('Data limite para assinar o arquivo: precisa ser preenchido.')
            .test(
                'futureDate',
                'A data limite para assinar o arquivo deve ser hoje ou uma data futura.',
                (value) => {
                    if (!value) return false;
                    const today = new Date();
                    return isToday(value) || isAfter(value, today);
                }
            ),

        signaturePortal: string()
            .typeError('Certificadora: precisa ser preenchido.')
            .required('Certificadora: precisa ser preenchido.'),
        signers: array()
            .typeError('Pessoa relacionada precisa ser preenchido.')
            .min(1, 'Selecione ao menos 1 pessoa.')
            .required('Pessoa relacionada: precisa ser preenchido.'),
    });
}

export const defaultValuesSignatureForm = {
    deadLine: new Date(),
    signaturePortal: null,
    signers: [],
};
