import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SeverityErrorIcon: FunctionComponent<SvgIconProps> = (props) => {

  return (
    <SvgIcon {...props}>
      <rect width="56" height="56" rx="8" fill="#F44336" />
      <path d="M37.3334 37.3332L18.6667 18.6667" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M37.3333 18.6666L18.6667 37.3333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </SvgIcon>
  );
};

SeverityErrorIcon.defaultProps = {
  viewBox: '0 0 56 56',
  width: '26',
  height: '26'
};
