import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react'

interface IBankAccountLimitSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const BankAccountLimitSkeleton: React.FC<IBankAccountLimitSkeleton> = (props) => {
    const { children, isLoading } = props;
    if (!isLoading) return children;
    return (
        <Stack spacing={4}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='200px'
                    height='20px'
                />
                <Stack direction='row' alignItems='center' spacing={2}>
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='200px'
                        height='30px'
                    />
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='200px'
                        height='30px'
                    />
                </Stack>
            </Stack>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='400px'
                height='20px'
            />
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='500px'
                height='60px'
            />
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height='400px'
            />
        </Stack>
    )
}
