import { useFormContext } from "contexts/formContext";
import { Grid, Stack } from "@mui/material";
import AutocompleteField from "components/Forms/FormFields/AutocompleteField/AutocompleteField";
import CurrencyFormField from "components/Forms/FormFields/CurrencyFormField/CurrencyFormField";
import { Button } from "@uy3/web-components";
import { AutocompleteOptionList } from "components/Forms/Autocomplete";

type CreditRequestProps = {
    closeModal: () => void;
    productList: AutocompleteOptionList;
};

function CreditRequestForm({ productList, closeModal }: CreditRequestProps) {
    const { watch } = useFormContext();

    return (
        <Stack component={Grid} container spacing={2} mt={1}>
            <Grid item xs={12}>
                <AutocompleteField
                    name="productId"
                    label="Produto"
                    displayName="productDisplay"
                    endIconType="link"
                    link={`/cadastro/produtos-credito/${watch("productId")}`}
                    showEndIcon={watch("productId")}
                    required
                    {...productList}
                />
            </Grid>
            <Grid item xs={12}>
                <CurrencyFormField
                    label="Valor solicitado"
                    fullWidth
                    name="requestedLimit"
                    variant="outlined"
                    required
                />
            </Grid>

            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={closeModal}
                    size="medium"
                    sx={{ mt: 1, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    sx={{ mt: 1 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    );

};

export default CreditRequestForm;