import { translatePoint, rotatePoint } from './Pentagon';

type PentagonDetailsProps = {
    children?: React.ReactNode,
    details: React.ReactNode[],
    innerRadius: number,
    outerRadius: number,
}

const PentagonDetails: React.FC<PentagonDetailsProps> = ({ details, innerRadius, outerRadius, children }) => {
    const size = outerRadius * 2;
    const detailsSize = (outerRadius - innerRadius) * 5;

    const centerVector: [number, number] = [0, -innerRadius - 25];
    let points: any[] = [];
    const degreesPerPoint = 360 / details.length;

    for (let i = 0; i < details.length; i += 1) {
        points.push(rotatePoint(centerVector, degreesPerPoint * i));
    }

    points = points.map(point => translatePoint(point, outerRadius, outerRadius));

    return (
        <div style={{ position: 'relative', width: 100, height: size, top: 20 }}>
            {details.map((detail, index) => {
                let [x, y] = points[index];
                x -= detailsSize / 2;
                y -= detailsSize / 2;
                return (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            left: x,
                            top: y,
                            width: detailsSize,
                            height: detailsSize,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            
                        }}
                    >
                        {detail}
                    </div>
                );
            })}
            <div style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', height: size, width: size }}>
            {children}
            </div>
        </div>
    );
};

export default PentagonDetails;