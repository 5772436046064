import { Alert } from "@uy3/web-components";
import SideMenu from "components/AuthenticatedLayout/Menu/SideMenu";
import Breadcrumb from './Breadcrumb';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router';
import { useIdentity } from "contexts/identityContext";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import Hello from "components/AuthenticatedLayout/Hello/Hello";
import { useAppArea } from "contexts/appAreaContext";

function AuthenticatedLayout() {

	const { isAuthenticated, user } = useIdentity();
	const navigate = useNavigate();
	const { menuWidth } = useAppArea();

	const authenticated = isAuthenticated();
	useEffect(() => {
		if (!authenticated) {
			navigate("/login");
		}
	}, [authenticated, navigate]);

	return authenticated ? (
		<>
			<SideMenu />
			<AppArea pl={`${menuWidth - 80}px`} ml={1} mr={1}>
				<Box mt={1}>
					{user && user?.verified === false && <Link to="/login/confirmar-email"><Alert
						severity="info"
						text="Você não está verificado. Clique aqui para confirmar seu e-mail"
						variant="outlined"
					>Você não está verificado. Clique aqui para confirmar seu e-mail</Alert></Link>}
				</Box>
				<Hello />
				<Breadcrumb />
				<Outlet />
			</AppArea>
		</>
	) : <> 401 - Acesso não autorizado</>;
}

export const AppArea = styled(Box)(() => ({
	width: 'calc(100% - 120px)',
	justifyContent: 'center',
	float: 'right'
}));

export default AuthenticatedLayout;