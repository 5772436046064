import { object, string } from 'yup';

export function validationSchemaTerminateAccount() {
    return object().shape({
        reason: string()
            .typeError('Motivo do encerramento é inválido.')
            .required('Motivo do encerramento é obrigatório.')
            .max(200, 'Motivo do encerramento deve ter no máximo 200 caracteres')
            .min(3, 'Motivo do encerramento deve ter no mínimo 3 caracteres')
    });
}
