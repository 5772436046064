import { Stack } from '@mui/material';
import { AddIcon, ApproveIcon, RejectIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { useUserPermissionData } from 'contexts/userContext';
import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { checkActionsPerms } from 'services/permissions';
import { Actions } from 'components/Actions/Actions';
import { BankAccountRequestReadModel } from 'services/accounts/bankAccountRequest';

export type ActionsType = 'approve' | 'disapprove' | undefined;

interface BankAccountRequestHeaderProps {
    rowsSelected: any;
    refetch: () => void;
    setApproveOrDesapprove: React.Dispatch<React.SetStateAction<ActionsType>>;
    rejectInstrument: () => void;
    approveInstrument: () => void;
}

const BankAccountRequestHeader: FC<BankAccountRequestHeaderProps> = ({
    rowsSelected,
    refetch,
    setApproveOrDesapprove,
    rejectInstrument,
    approveInstrument,
}) => {
    const navigate = useNavigate();
    const { data: permissionsData, hasPermission } = useUserPermissionData();

    const isApprovalStts = rowsSelected?.every(
        (i: BankAccountRequestReadModel) => i?.status === 'Approval'
    );
    const isInstrumentApprovalStts = rowsSelected?.every(
        (i: BankAccountRequestReadModel) => i?.status === 'InstrumentApproval'
    );

    const hasRowSelected = rowsSelected?.length > 0;

    const showActionApproveAndDisapprove = (): boolean => {
        if (
            checkActionsPerms('ComplianceApproval', permissionsData, 'BankAccountRequest') &&
            hasRowSelected &&
            isApprovalStts
        )
            return true;
        return false;
    };

    const showApproveAndDisapproveInstrument = (): boolean => {
        if (hasRowSelected && isInstrumentApprovalStts) return true;
        return false;
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography
                variant="h4"
                color="neutral.dark"
                sx={{
                    fontSize: { xs: '24px', md: '32px' },
                    lineHeight: { xs: '28.8px', md: '38.4px' },
                    fontWeight: { xs: '700', md: '700' },
                    fontStyle: { xs: 'normal', md: 'normal' },
                }}
            >
                Solicitações
            </Typography>
            <Actions
                buttonsActionsList={[
                    {
                        enable: showApproveAndDisapproveInstrument(),
                        label: 'Aprovar instrumento',
                        icon: <ApproveIcon />,
                        action: approveInstrument,
                    },
                    {
                        enable: showApproveAndDisapproveInstrument(),
                        label: 'Reprovar instrumento',
                        icon: <RejectIcon />,
                        action: rejectInstrument,
                    },
                    {
                        enable: showActionApproveAndDisapprove(),
                        label: 'Aprovar',
                        icon: <ApproveIcon />,
                        action: () => setApproveOrDesapprove('approve'),
                    },
                    {
                        enable: showActionApproveAndDisapprove(),
                        label: 'Reprovar',
                        icon: <RejectIcon />,
                        action: () => setApproveOrDesapprove('disapprove'),
                    },
                    {
                        enable: true,
                        label: 'Atualizar',
                        icon: <UpdateIcon />,
                        action: refetch,
                    },
                    {
                        enable: hasPermission('BankAccountRequest', 'Create'),
                        label: 'Novo cadastro',
                        icon: <AddIcon />,
                        action: () => navigate('/contas-digitais/solicitacoes/nova'),
                    },
                ]}
                numberOfButtons={4}
            />
        </Stack>
    );
};
export default BankAccountRequestHeader;
