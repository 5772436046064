import { Grid, Stack } from '@mui/material'
import { Typography } from '@uy3/web-components'
import { activeTheme } from 'services/theme'

type CategoriesType = {
    categoryList: string[]
}
const theme = activeTheme();

export const Categories = ({ categoryList }: CategoriesType) => {
    return (
        <Stack spacing={2}>
            <Typography variant="md" fontWeight={600}>
                Categorias
            </Typography>
            <Stack direction='row' alignItems='center' gap={2} pb={2}>
                {categoryList.map(item => {
                    return (
                        <Grid
                            style={{
                                background: '#E9D4C4',
                                color: theme.palette.primary.main,
                                borderRadius: '16px',
                                padding: '8px 12px'
                            }}
                        >
                            <Typography fontWeight={600} fontSize='14px'>
                                {item}
                            </Typography>
                        </Grid>
                    )
                })}
            </Stack>
        </Stack>
    )
}
