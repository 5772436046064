import { object, string } from 'yup';

export function validationSchemaGuarantorFormSchema() {
    return object().shape({
        registrationNumber: string().typeError('CPF/CNPJ precisa ser um número.').required("CPF/CNPJ: Precisa ser preenchido.").cpfCnpjValidation("CPF ou CNPJ inválido."), 
        name: string().typeError('Nome Precisa ser preenchido.').required("Nome: Precisa ser preenchido."), 
    })
}

export const defaultValuesGuarantorForm = {
    registrationNumber: null,
    name: null
};
