import { LegalPersonFormSchema } from 'pages/LegalPerson/LegalPersonForm/LegalPersonSchema';
import { LegalPersonFiltersProps, LegalPersonReadModel } from './types/legalPersonReadModel';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { GetViewApiResponse } from 'contexts/apiRequestContext';
import { LegalPersonCreateModel } from './types/legalPersonCreateModel';
import { LegalPersonUpdateModel } from './types/legalPersonUpdateModel';

var url = `/LegalPerson`;

export async function getLegalPersonById(personId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<LegalPersonReadModel>(url + `/${personId}`, config);
}

type LegalPersonTypeModel = LegalPersonCreateModel | LegalPersonUpdateModel;
export async function editOrCreateLegalPerson(
    data: LegalPersonFormSchema,
    personId: string,
    token = '',
    tenant?: string
) {
    const isCreating = personId === 'novo';
    const urlFull = isCreating ? url : `${url}/${personId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const LegalPersonCreateOrUpdate = isCreating ? LegalPersonCreateModel : LegalPersonUpdateModel;
    const payload = toDataModel<LegalPersonTypeModel>(data, LegalPersonCreateOrUpdate);

    const axiosFunction = isCreating ? creditAxiosApi.post : creditAxiosApi.put;

    return await axiosFunction<GetViewApiResponse<LegalPersonReadModel>>(
        urlFull,
        payload,
        axiosConfig
    );
}

export const getLegalPersonsList = async (filters: LegalPersonFiltersProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters,
    };

    return await creditAxiosApi.get(url, config);
};

export const createLegalPerson = async (
    data: LegalPersonFormSchema,
    tenant: string,
    token: string
) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    const payload = toDataModel<LegalPersonCreateModel>(data, LegalPersonCreateModel);

    return await creditAxiosApi.post(url, payload, config);
};

export const deleteLegalPersonById = async (personId: string, token?: string, tenant?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant },
    };

    return await creditAxiosApi.delete(url + `/${personId}`, config);
};
