import { validateRequiredFields } from 'helpers';
import { date, object, string } from 'yup';

export enum StepNaturalPersonFormStep {
    InformationForm = 1,
    Address = 2,
}

const genericRequired = 'Precisa ser preenchido.';

export function validationSchemaDrawerAddPersonForm(step: StepNaturalPersonFormStep) {

    const schemas: Record<StepNaturalPersonFormStep, any> = {
        [StepNaturalPersonFormStep.InformationForm]: object().shape({
            name: string().required(`Nome: ${genericRequired}`).typeError('Nome inválido.'),
            registrationNumber: string()
                .typeError('CPF inválido.')
                .cpfCnpjValidation('CPF inválido.')
                .required(`CPF: ${genericRequired}`),
            email: string().email('Email inválido.').required(`Email: ${genericRequired}`),
            birthDate: date()
                .typeError('Data de nascimento inválida.')
                .max(new Date(), 'A data de nascimento não pode ser maior que a data atual.')
                .required(`Data de nascimento: ${genericRequired}`),
            nationality: string().nullable().notRequired(),
            civilStatus: string()
                .typeError('Estado civil inválido.')
                .required(`Estado civil: ${genericRequired}`),
            phone: string()
                .phoneNumberValidation('Número de telefone deve conter 11 Caracteres.')
                .required(`Telefone: ${genericRequired}`),
            occupation: validateRequiredFields(
                string().typeError('Ocupação inválido.'),
                'occupation',
                `A ocupação`
            ),
        }),
        [StepNaturalPersonFormStep.Address]: object().shape({
            address: object().shape({
                addressName: string()
                    .typeError(`Endereço: ${genericRequired}`)
                    .required(`Endereço: ${genericRequired}`),
                zipCode: string()
                    .typeError(`CEP: ${genericRequired}`)
                    .required(`CEP: ${genericRequired}`),
                city: string()
                    .typeError(`Cidade: ${genericRequired}`)
                    .required(`Cidade: ${genericRequired}`),
                uf: string().typeError(`UF: ${genericRequired}`).required(`UF: ${genericRequired}`),
                district: string()
                    .typeError(`Bairro: ${genericRequired}`)
                    .required(`Bairro: ${genericRequired}`),
                number: string()
                    .typeError('Valor precisa ser um número.')
                    .required(`Número: ${genericRequired}`),
                complement: string().nullable(),
            }),
        }),
    };
    
    return schemas[step] ?? object().shape({});
}
