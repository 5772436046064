import { FormProvider } from "contexts/formContext";
import { useIdentity } from "contexts/identityContext";
import { Alert } from "@uy3/web-components";
import { MfaFormDefaultValues, MfaFormValidationSchema } from "./MfaFormSchema";
import { MfaForm } from "./MfaForm";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";


export function MfaFormContainer() {

	const { authFlow, confirmMfa, navigateToFlow, submitError } = useIdentity();
	const navigate = useNavigate();

	const schema = MfaFormValidationSchema();
	const defaultValues = MfaFormDefaultValues;

	const submit = async (values: any) => await confirmMfa(values.code);
	const error = (values: any) => console.log("MfaFormContainer - onError", values);

	useEffect(() => {
		if (!authFlow) {
			console.log("AuthFlow não encontrado");
			navigate('/login');
		}
	}, [authFlow, navigate]);

	useEffect(() => {
		navigateToFlow(navigate);
	}, [authFlow, navigate, navigateToFlow]);

	return <>

		{submitError && <Box mt={2} mb={2}>
			<Alert
				severity="error"
				text={submitError}
				variant="filled"
			/>
		</Box>}
		<FormProvider
			validationSchema={schema}
			defaultValues={defaultValues}
			onSubmit={submit}
			onError={error}>
			<MfaForm />
		</FormProvider>
	</>
}