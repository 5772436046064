import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ProtractorIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M11 20V19.25C10.5858 19.25 10.25 19.5858 10.25 20H11ZM11 4H10.25C10.25 4.41421 10.5858 4.75 11 4.75V4ZM11 15H10.25V15.75H11V15ZM11 9V8.25H10.25V9H11ZM5 5.25C4.58579 5.25 4.25 5.58579 4.25 6C4.25 6.41421 4.58579 6.75 5 6.75V5.25ZM7 6.75C7.41421 6.75 7.75 6.41421 7.75 6C7.75 5.58579 7.41421 5.25 7 5.25V6.75ZM5 9.25C4.58579 9.25 4.25 9.58579 4.25 10C4.25 10.4142 4.58579 10.75 5 10.75V9.25ZM7 10.75C7.41421 10.75 7.75 10.4142 7.75 10C7.75 9.58579 7.41421 9.25 7 9.25V10.75ZM5 13.25C4.58579 13.25 4.25 13.5858 4.25 14C4.25 14.4142 4.58579 14.75 5 14.75V13.25ZM7 14.75C7.41421 14.75 7.75 14.4142 7.75 14C7.75 13.5858 7.41421 13.25 7 13.25V14.75ZM5 17.25C4.58579 17.25 4.25 17.5858 4.25 18C4.25 18.4142 4.58579 18.75 5 18.75V17.25ZM7 18.75C7.41421 18.75 7.75 18.4142 7.75 18C7.75 17.5858 7.41421 17.25 7 17.25V18.75ZM7 2.75H9V1.25H7V2.75ZM9 21.25H7V22.75H9V21.25ZM5.75 20V4H4.25V20H5.75ZM7 21.25C6.30964 21.25 5.75 20.6904 5.75 20H4.25C4.25 21.5188 5.48122 22.75 7 22.75V21.25ZM10.25 20C10.25 20.6904 9.69036 21.25 9 21.25V22.75C10.5188 22.75 11.75 21.5188 11.75 20H10.25ZM9 2.75C9.69036 2.75 10.25 3.30964 10.25 4H11.75C11.75 2.48122 10.5188 1.25 9 1.25V2.75ZM7 1.25C5.48122 1.25 4.25 2.48122 4.25 4H5.75C5.75 3.30964 6.30964 2.75 7 2.75V1.25ZM18.25 12C18.25 16.0041 15.0041 19.25 11 19.25V20.75C15.8325 20.75 19.75 16.8325 19.75 12H18.25ZM11 4.75C15.0041 4.75 18.25 7.99594 18.25 12H19.75C19.75 7.16751 15.8325 3.25 11 3.25V4.75ZM13.25 12C13.25 13.2426 12.2426 14.25 11 14.25V15.75C13.0711 15.75 14.75 14.0711 14.75 12H13.25ZM11 9.75C12.2426 9.75 13.25 10.7574 13.25 12H14.75C14.75 9.92893 13.0711 8.25 11 8.25V9.75ZM10.25 9V15H11.75V9H10.25ZM5 6.75H7V5.25H5V6.75ZM5 10.75H7V9.25H5V10.75ZM5 14.75H7V13.25H5V14.75ZM5 18.75H7V17.25H5V18.75Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

ProtractorIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
};
