import { object, string, number, boolean } from "yup";

export function createBeneficiaryFormValidationSchema() {
    return object().shape({
        pixKeyTypeValue: string().typeError("Tipo de chave: Precisa ser preenchido.").required("Tipo de chave: Precisa ser preenchido."),
        bank: string().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: string().required("Banco: Precisa ser preenchido.").typeError("Banco: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        name: string().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: string().typeError("Nome: Precisa ser preenchido.").required("Nome: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        accountType: string().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: string().required("Tipo de conta: Precisa ser preenchido.").typeError("Tipo de conta: Precisa ser preenchido."),
            otherwise: string().nullable()
        }),
        agency: string().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: string().typeError("Agência: precisa ser preenchido.")
                .required("Agência: Precisa ser preenchido.")
                .max(4, "Agência (sem digito): Precisa ter 4 digitos.")
                .min(4, "Agência (sem digito): Precisa ter 4 digitos."),
            otherwise: string().nullable()
        }),
        accountNumber: number().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: number().typeError("Conta: precisa ser preenchido.").required("Conta: Precisa ser preenchido."),
            otherwise: number().nullable()
        }),
        registrationNumber: string().when("pixKeyTypeValue", {
            is: "AgencyAndAccount",
            then: string().typeError("Chave Pix: Precisa ser preenchido.")
                .required("Chave Pix: Precisa ser preenchido.")
                .cpfCnpjValidation('Campo inválido'),
            otherwise: string().nullable()
        }),
        keyPix: string().when("pixKeyTypeValue", pixKeyTypeValue => {
            if (pixKeyTypeValue === "NaturalRegistrationNumber" || pixKeyTypeValue === "LegalRegistrationNumber") {
                return string().typeError("Chave Pix: Precisa ser preenchido.")
                    .required("Chave Pix: Precisa ser preenchido.")
                    .cpfCnpjValidation('Campo inválido')
            }
            if (pixKeyTypeValue === "Email") {
                return string().typeError("Chave Pix: Precisa ser preenchido.")
                    .required("Chave Pix: Precisa ser preenchido.")
                    .email('Campo inválido')
            }
            if (pixKeyTypeValue === "Phone") {
                return string()
                    .phoneNumberValidation('Número de telefone deve conter 11 Caracteres')
                    .required("Chave Pix: Precisa ser preenchido.")
            }
            return pixKeyTypeValue === "Automatic" ?
                string().typeError("Chave Pix: Precisa ser preenchido.")
                    .required("Chave Pix: Precisa ser preenchido.") :
                string().nullable()
        }),
        saveBeneficiary: boolean().nullable()
    });
}

export const createBeneficiaryFormDefaultValues = {
    name: null,
    agency: null,
    accountNumber: null,
    accountType: null,
    bank: null,
    operationTypeValue: 2,
    pixKeyTypeValue: null,
    keyPix: null,
    saveBeneficiary: false
};