import { Grid, Stack } from '@mui/material';
import { Button, LeftIcon, RightIcon, Typography, UpdateIcon } from '@uy3/web-components';
import CardsSelect from 'components/CardSelect/CardSelect';
import { RefreshProgress } from 'components/RefreshProgress';
import { useFormContext } from 'contexts/formContext';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import moment from 'moment';
import React from 'react';
import { activeTheme } from 'services/theme';

type ClaimListType = {
  claim: {
    id: string;
  },
  pixKey: {
    type: string,
    typeDisplay: string,
    pixKey: string;
  }
}

type ClaimsRequestsOptionsProps = {
  claimList: [];
  onClose: () => void;
  refetch: () => void;
  isFetching: boolean;
};

const theme = activeTheme();

export const ClaimsRequestsOptions: React.FC<ClaimsRequestsOptionsProps> = ({ claimList, onClose, refetch, isFetching }) => {
  const { watch, setValue } = useFormContext();

  const optionsSelect = claimList.map(({ claim, pixKey }: ClaimListType) => {
    return {
      label: `${formatBankAccountManagePixKey(pixKey.typeDisplay, pixKey.pixKey)}`,
      value: claim.id
    }
  });

  return (
    <Stack spacing={2} position='relative'>
      <Grid mt={-4} mb={-2}>
        <RefreshProgress refreshing={isFetching} />
      </Grid>
      <Stack direction='row' justifyContent='right' >
        <Button variant='text' size='medium' sx={{ color: 'black', mt: -7, mb: -2 }} onClick={refetch} startIcon={<UpdateIcon />}>
          Atualizar
        </Button>
      </Stack>

      <Typography fontSize={24}>Lista de solicitações</Typography>

      <CardsSelect
        onChange={({ value }) => {
          setValue('claimId', value)

          if (value === 'otherDay') {
            const transferDate = moment().add(1, 'day').format('yyyy-MM-DD')
            setValue('transferDate', transferDate);
          }

          if (value === 'today') {
            setValue('transferDate', moment().format('yyyy-MM-DD'));
          }

        }}
        options={optionsSelect}
        value={watch('claimId')}
      />

      <Grid sx={{ border: '1px solid grey', p: 3, borderRadius: 2 }}>
        <Typography fontSize={17} display="block" fontWeight='bold'>
          Informações importantes
        </Typography>
        <Typography fontSize={15} display="block">
          Para a conclusão desse processo, é necessária a confirmação em até 7 (sete)
          dias. Se você não confirmar ou recusar neste período, a portabilidade será
          cancelada.
        </Typography>
      </Grid>
      <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
        <Button
          startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Voltar
        </Button>
        <Button
          startIcon={<RightIcon htmlColor={theme.palette.common.white} />}
          variant="contained"
          size="medium"
          disabled={watch('claimId') === undefined || watch('claimId') === null}
          type='submit'
        >
          Avançar
        </Button>
      </Stack>
    </Stack>
  );
};
