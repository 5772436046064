/* eslint-disable sonarjs/no-duplicate-string */

import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    field: 'dados',
    headerName: 'Dados',
    width: 130,
    editable: false,
  },
  {
    field: 'chaves',
    headerName: 'Chaves',
    width: 430,
    editable: false,
  },
  {
    field: 'ag/conta',
    headerName: 'Ag/Conta',
    width: 180,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
    editable: false,
  },
  {
    field: 'acoes',
    headerName: 'Ações',
    width: 150,
    editable: false,
    hideSortIcons: true,
  },
];

export const rows = [];
