import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon, SearchIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { GridColDef } from '@mui/x-data-grid';
import { RelatedPersonCreditNote } from 'services/creditNote/types/creditNoteReadModel';
import { iconSx } from 'contexts/apiRequestContext';
import { handleRedirectByLine } from 'helpers';
import { Link } from 'react-router-dom';

type InvolvedFundProps = {
    name: string;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    openDrawer: (rowIndex?: number | undefined) => void;
    page: number;
    onDelete: (rowIndex: number) => void;
};

const InvolvedFundList = ({
    name,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    openDrawer,
    page,
    onDelete,
}: InvolvedFundProps) => {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];

    const columns: GridColDef[] = [
        {
            field: 'personIdDisplay',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'fundSignerTypeDisplay',
            headerName: 'Tipo de relação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'signatureTypeDisplay',
            headerName: 'Forma de Envio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues.value,
        },
        {
            field: 'signatureValidationDisplay',
            headerName: 'Forma de Validação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => cellValues.value,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: ({ row }) => {
                const rowIndex = queryData.findIndex(
                    (person: RelatedPersonCreditNote) => row?.personId === person?.personId
                );

                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        gap={1}
                    >
                        {
                            <>
                                <Link to={handleRedirectByLine(row)}>
                                    <IconButton >
                                        <SearchIcon sx={iconSx} />
                                    </IconButton>
                                </Link>

                                <IconButton onClick={() => openDrawer(rowIndex)}>
                                    <EditIcon sx={iconSx} />
                                </IconButton>

                                <IconButton onClick={() => onDelete(rowIndex)}>
                                    <DeleteIcon sx={iconSx} />
                                </IconButton>
                            </>
                        }
                    </Stack>
                );
            },
        },
    ];

    return (
        <>
            <DataTable
                NoRowsOverlayNew="Nenhum envolvido adicionado."
                NoResultsOverlayNew="Nenhum envolvido adicionado."
                getRowId={(row) => row?.personId}
                columns={columns}
                rows={queryData}
                page={page}
                rowCount={queryData?.totalItems}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};

export default InvolvedFundList;
