import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import { useTenant } from 'contexts/tenantContext';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { creditNoteSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { sendSubmitEvent } from 'contexts/apiRequestContext';

type CreditApprovFormProps = {
    currentValues: any;
    removeFilter: (index: number, duplicateRemove?: boolean) => void;
};

function CreditNoteApprovalsForm() {
    const { watch } = useFormContext();
    const { tenantAutoCompleteProps, currentTenantId } = useTenant();
    const { personAutoCompleteProps, data: personData } = usePersonsList(
        { page: 0, size: 10, tenant: currentTenantId },
        'always'
    );

    let dataPerson = personData as any;
    const personAutocompleteOptions: any = {
        ...personAutoCompleteProps,
        listOptions:
            dataPerson?.data?.map((person: PersonFull) => {
                return { label: person.name, value: person.id };
            }) || [],
    };

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Status', value: 'status' },
                    { label: 'Tomador', value: 'personId' },
                    { label: 'Data de início', value: 'initialDate' },
                    { label: 'Valor máximo', value: 'maxValue' },
                    { label: 'Valor mínimo', value: 'minValue' },
                    { label: 'Correspondente', value: 'tenant' },
                    { label: 'Número da operação', value: 'creditNoteNo' },
                ]}
            />
            {watch('optionFilter') === 'tenant' && (
                <AutocompleteField
                    label="Filtrar por correspondente"
                    name="tenant"
                    displayName="tenantDisplay"
                    showEndIcon
                    endIconType="submit"
                    {...tenantAutoCompleteProps}
                />
            )}
            {watch('optionFilter') === 'personId' && (
                <AutocompleteField
                    label="Filtrar por tomador"
                    name="personId"
                    displayName="personDisplay"
                    showEndIcon
                    endIconType="submit"
                    {...personAutocompleteOptions}
                />
            )}

            {watch('optionFilter') === 'creditNoteNo' && (
                <TextFormField
                    label="Filtrar por número da operação"
                    name="creditNoteNo"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ marginRight: -5 }}>
                                <span
                                    style={{
                                        display: 'block',
                                        fontSize: '20px',
                                    }}
                                >
                                    #
                                </span>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'status' && (
                <SelectFormField
                    label="Filtrar por status"
                    name="status"
                    fullWidth
                    showEndAdornment
                    options={creditNoteSelectFilterOptions}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'maxValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor máximo"
                    name="maxValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor mínimo"
                    name="minValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}

            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

export const CreditNoteApprovalsFilters = ({
    currentValues,
    removeFilter,
}: CreditApprovFormProps) => {
    return (
        <FilterStyleBase
            formFilters={<CreditNoteApprovalsForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilter}
                />
            }
        />
    );
};
