import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

interface IBillingSettingHeaderProps {
    isFetching: boolean;
    refetch: () => void;
    addParameters: () => void;
}

const theme = activeTheme();
const BillingSettingHeader: React.FC<IBillingSettingHeaderProps> = ({ isFetching, refetch, addParameters }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Régua de cobrança
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color }}
                    variant="text"
                    disabled={isFetching}
                    onClick={() => refetch()}
                    size="large"
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                >
                    Atualizar
                </Button>
                    <Button
                        onClick={addParameters}
                        sx={{ color }}
                        variant="text"
                        size="large"
                        startIcon={<AddIcon htmlColor={color} sx={iconSx} />}
                    >
                        Nova régua de cobrança
                    </Button>
            </Grid>
        </Stack>
    );
};
export default BillingSettingHeader;
