
interface IValidationRequestedAmount {
    currentValue: number;
    initialValue: string;
}

// this validation return the value to save; 
export function validationRequestedAmount({ currentValue, initialValue }: IValidationRequestedAmount) {
    if (currentValue === undefined || currentValue === null || currentValue === 0) {
        return initialValue
    };
    return currentValue;
}