/* eslint-disable react-hooks/exhaustive-deps */
import { FormMode, FormProvider } from 'contexts/formContext';
import { useNavigate, useParams } from 'react-router-dom';
import {
    ApiResponseError,
    TimelineType,
    ToastType,
    showErrorToast,
    showSuccessToast,
    showWarningToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { defaultValuesCreditNoteForm, validationSchemaCreditNoteForm } from './CreditNoteSchema';
import CreditNoteForm from './CreditNoteForm';
import { useCreditData, useCreditNoteMutation } from 'contexts/creditNote/creditContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { CreditNoteActionsProvider } from 'contexts/creditNote/creditNoteActions';
import React, { useEffect, useState } from 'react';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useCreditProductData } from 'contexts/creditProductContext';
import { CreditNoteInformationFormContextProvider } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import Toast from 'components/Toast/Toast';
import { useProductId } from 'contexts/productIdContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';
import {
    HandleOnSubmitType,
    useHandleSubmitCreditNoteFormContext,
} from 'contexts/HandleSubmitCreditNote';
import { getCreditNoteById } from 'services/creditNote';
import { useIdentity } from 'contexts/identityContext';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { useAppConfig } from 'contexts/appConfig';
import {
    calculateAndSetFirstPaymentDate,
    handlePaymentAndCutoffDays,
} from './Tabs/Operation/setFirstPaymentDate';
import { useForm } from 'react-hook-form';
import { isFieldHidden } from 'helpers';

export const CreditNoteFormContainer = () => {
    const { id } = useParams();
    const { token } = useIdentity();
    const { hasPermission } = useUserPermissionData();
    const formMode: FormMode = id !== 'nova' ? 'update' : 'create';
    const { creditData, creditError, creditStatus, refetch } = useCreditData(id!);
    const { submitError, setSubmitError } = useApiRequest();
    const [handleSetValue, setHandleSetValue] = useState<HandleOnSubmitType>(undefined);
    const { handleOnSubmit: handleSubmitWhenSuccess } = useHandleSubmitCreditNoteFormContext();
    const navigate = useNavigate();
    const { productId, setProductId } = useProductId();
    const { creditProductData } = useCreditProductData(productId);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { setFormFieldsErrors } = useFormFieldsError();
    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const hasPermissionCreateOrUpdate = hasPermission('CreditNote', formMode === 'create' ? 'Create' : 'Update')

    useEffect(() => {
        if (creditData && creditData.productId) {
            setProductId(creditData.productId);
        }
    }, [creditData]);

    async function updateFieldValueCommercialPapper() {
        await getCreditNoteById(id!, token).then(async (data) => {
            const creditnoteData = data.data as CreditNoteReadModel;
            await handleSubmitWhenSuccess(creditnoteData, handleSetValue?.handle!);
        });
    }

    const instrumentType = creditProductData?.instrumentType.toLowerCase();
    const onSuccess = async (data: CreditNoteReadModel) => {
        if (formMode === 'create') {
            const creditNoteId = formMode === 'create' ? data : data.id ?? id;
            navigate(`/ccb/operacoes/${creditNoteId}`);
        }
        if (id !== 'nova') {
            refetch();
        }
        setSubmitError(undefined);
        setFormFieldsErrors([]);
        const title =
            formMode === 'create'
                ? 'Operação criada com sucesso!'
                : 'Operação atualizada com sucesso!';
        const description =
            formMode === 'create'
                ? 'Ótimo! Agora você pode adicionar os envolvidos e os documentos da operação.'
                : 'Ótimo! Agora você pode seguir com a operação.';
        showSuccessToast(title, description, setToast);
        if (formMode === 'update' && instrumentType === 'commercialpapper') {
            await updateFieldValueCommercialPapper();
        }
    };

    const onError = (error: ApiResponseError) => {
        const { fieldErrors, errorMessage, warningToastError } = mapErrorResponse(error);
        setSubmitError(error);
        setFormFieldsErrors(fieldErrors ?? []);

        if (warningToastError && warningToastError?.length > 0) {
            const title = 'Atenção!';
            showWarningToast(title, warningToastError, setToast);
        } else {
            const title = 'Ops, ocorreu um erro!';
            const description = errorMessage;
            showErrorToast(title, description, setToast);
        }
    };

    const { mutate } = useCreditNoteMutation(id!, onSuccess, onError);
    const onSubmit = async (values: CreditNoteReadModel) => {
        //@ts-ignore
        delete values?.createSimulation;

        const isCustomPaymentPlan = values?.customPaymentPlan;
        if (!isCustomPaymentPlan) {
            values.paymentScheduleItems = [];
        }

        await mutate(values);
    };

    const schema = validationSchemaCreditNoteForm(creditProductData);
    const defaultValues = creditData ?? defaultValuesCreditNoteForm(creditProductData);

    const isRefreshing = creditStatus === 'loading' && formMode === 'update';

    function findLastNonNullStartedAt(timeline: TimelineType[]): string | undefined {
        let lastNonNullStartedAt = null;
        let lastEventName = undefined;

        for (const event of timeline) {
            if (event.startedAt !== null && event.startedAt !== undefined) {
                if (lastNonNullStartedAt === null || event.startedAt > lastNonNullStartedAt) {
                    lastNonNullStartedAt = event.startedAt;
                    lastEventName = event.name;
                }
            }
        }

        if (creditData?.statusDisplay === 'Reserva de Margem') return 'Reserva de Margem';
        return lastEventName;
    }

    const getStatusDisplay = (): string => {
        if (['Finished'].includes(creditData?.status)) {
            return creditData.statusDisplay as string;
        }
        return findLastNonNullStartedAt(creditData?.timeline ?? []) as string;
    };

    const isFirstPaymentAutoSet = appConfig.SET_FIRST_PAYMENT_ON_DAY;
    const isPaymentDay = appConfig.PAYMENTDAY_FIELD;
    const amortizationType = creditProductData?.amortizationType?.toLowerCase() ?? 'cleanprice';
    const executeAmortizationBasedOnType = ['price', 'sac', 'cleanprice'].includes(amortizationType);
    const warrantyRegistrationOffice = creditProductData?.warrantyRegistrationOffice?.toLowerCase();
    const isPublicPayrollLoan = instrumentType === 'publicpayrollloan';
    const isSiapeOffice = warrantyRegistrationOffice === 'siape' && isPublicPayrollLoan;

    return (
        <GenericErrorBoundary status={creditStatus} error={creditError} fallback="operação">
            <GenericFormSkeleton isLoading={isRefreshing}>
                <React.Fragment>
                    <Toast toast={toast} setToast={setToast} />
                    {submitError?.errors && <ApiErrorAlert error={submitError} />}
                    <FormProvider
                        readOnly={
                            (id !== 'nova' &&
                                !['Draft', 'Revision', 'Disapproved'].includes(creditData?.status)) ||
                            !hasPermissionCreateOrUpdate
                        }
                        validationSchema={schema}
                        defaultValues={defaultValues}
                        onSubmit={onSubmit}
                        onChangeField={[
                            {
                                fieldName: 'liquidationMode',
                                delegate: (
                                    value: string | number,
                                    setValue: (name: any, value: any) => void
                                ) => {
                                    if (value === 'LiquidationSchedule') {
                                        setValue('bankAccountId', null);
                                        setValue('beneficiaryId', null);
                                    }
                                },
                            },
                            {
                                fieldName: 'liquidationType',
                                delegate: (
                                    value: string | null,
                                    setValue: (name: any, value: any) => void
                                ) =>
                                    value === 'Invoice'
                                        ? setValue('bankAccountId', null)
                                        : setValue('invoiceBarCode', null),
                            },
                            {
                                fieldName: 'productId',
                                delegate: (
                                    value: string | undefined,
                                    setValue: ReturnType<typeof useForm>['setValue'],
                                    watch?: ReturnType<typeof useForm>['watch']
                                ) => {
                                    setProductId(value);
                                    const startDateString = watch!('amortization.startDate');
                                    const isHidden = isFieldHidden(
                                        'amortization.startDate',
                                        formFieldsConfig
                                    );

                                    // #region Lógica para setar a data de pagamento - KORV
                                    if (isHidden) {
                                        calculateAndSetFirstPaymentDate({
                                            startDateString,
                                            executeAmortizationBasedOnType,
                                            setValue,
                                            watch: watch!,
                                            isFirstPaymentAutoSet,
                                        });
                                    } else {
                                        // #region Lógica para setar a data de pagamento - SIAPE
                                        handlePaymentAndCutoffDays(
                                            startDateString,
                                            isPaymentDay,
                                            isFirstPaymentAutoSet,
                                            setValue
                                        );
                                    }
                                },
                            },
                            {
                                fieldName: 'amortization.startDate',
                                delegate: (
                                    startDateString: string,
                                    setValue: ReturnType<typeof useForm>['setValue'],
                                    watch?: ReturnType<typeof useForm>['watch']
                                ) => {

                                    if (startDateString && !isSiapeOffice) {
                                        calculateAndSetFirstPaymentDate({
                                            startDateString,
                                            executeAmortizationBasedOnType,
                                            setValue,
                                            watch: watch!,
                                            isFirstPaymentAutoSet,
                                        });
                                    } else {
                                        handlePaymentAndCutoffDays(
                                            startDateString,
                                            isPaymentDay,
                                            isFirstPaymentAutoSet,
                                            setValue
                                        );
                                    }
                                },
                            },
                        ]}
                    >
                        <CreditNoteActionsProvider
                            id={id}
                            isNewCreditNote={formMode === 'create'}
                            creditNoteRefetch={refetch}
                            product={creditProductData}
                        >
                            <CreditNoteInformationFormContextProvider product={creditProductData}>
                                <CreditNoteForm
                                    setHandleSetValue={setHandleSetValue}
                                    mode={formMode}
                                    id={id!}
                                    statusDisplay={getStatusDisplay() ?? ''}
                                    timeline={creditData?.timeline ?? []}
                                />
                            </CreditNoteInformationFormContextProvider>
                        </CreditNoteActionsProvider>
                    </FormProvider>
                </React.Fragment>
            </GenericFormSkeleton>
        </GenericErrorBoundary>
    );
};
