import { useBankAccountPaymentReceiptMutation } from 'contexts/bankAccount/bankAccountContext';
import { FilterProvider } from 'contexts/filterContext';
import { PaymentTab } from './PaymentList';
import { useState } from 'react';
import PaymentHeader from './PaymentHeaderList';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { RefreshProgress } from 'components/RefreshProgress';
import Toast from 'components/Toast/Toast';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';

interface PaymentListContainerProps {
    setPaymentId: (paymentId: string) => void;
    setStepAction: (action: string) => void;
    setTransferInfo: (cellValues: any) => void;
    bankAccountId: string | undefined;
    statusBankAccountOptions: any;
    isFetching: boolean;
    refetchList: () => void
    paymentListData: any
    rowsPerPage: number;
    setRowsPerPage: React.Dispatch<React.SetStateAction<number>>
    setPage: React.Dispatch<React.SetStateAction<number>>
    page: number
    enableBatchActionApprove: boolean
    handleSelectionModelChange: (selectionModel: GridSelectionModel) => void
    rowsSelected: GridRowId[]

}

export default function PaymentListContainer({
    setPaymentId,
    setStepAction,
    setTransferInfo,
    bankAccountId,
    statusBankAccountOptions,
    isFetching,
    refetchList,
    rowsPerPage,
    paymentListData,
    setPage,
    setRowsPerPage,
    page, 
    enableBatchActionApprove, 
    handleSelectionModelChange, 
    rowsSelected
}: PaymentListContainerProps) {

    const [toast, setToast] = useState<ToastType>(toastState);

    const onChangePage = (page: number) => setPage(page);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };
    const { submitting } = useApiRequest();
    const { isLoading: loadingPaymentreceipt, mutateTransferAndPayment } = useBankAccountPaymentReceiptMutation(bankAccountId!);

    const handlePaymentReceipt = (values: any) => mutateTransferAndPayment(values)

    const onAction = (paymentId: string, action: string) => {
        setStepAction(action);
        setPaymentId(paymentId);
    };

    return (
        <div>
            <Toast toast={toast} setToast={setToast} />
            <PaymentHeader
                isFetching={isFetching || submitting}
                refetch={refetchList}
                bankAccountId={bankAccountId}
                enableBatchActionApprove={enableBatchActionApprove}
                handleAction={(action) => setStepAction(action)}
            />
            <RefreshProgress refreshing={isFetching || loadingPaymentreceipt} />
            <FilterProvider
                availableFilters={{
                    searchString: { label: 'Todos', type: 'text' },
                    f_paymentDate: { label: 'Data do pagamento', type: 'date' },
                    f_amount: { label: 'Valor do pagamento', type: 'text' },
                    f_status: {
                        label: 'Status',
                        type: 'select',
                        multiple: false,
                        options: statusBankAccountOptions,
                    },
                }}
            >
                <ListSkeleton isLoading={isFetching}>
                    <PaymentTab
                        onApprove={(paymentId) => onAction(paymentId, 'approve')}
                        onReject={(paymentId) => onAction(paymentId, 'reject')}
                        onCancel={(paymentId) => onAction(paymentId, 'cancel')}
                        handlePaymentReceipt={handlePaymentReceipt}
                        setTransferInfo={setTransferInfo}
                        queryData={paymentListData}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                        setToast={setToast}
                        handleSelectionModelChange={handleSelectionModelChange}
                        rowsSelected={rowsSelected}
                    />
                </ListSkeleton>
            </FilterProvider>
        </div>
    );
}
