import { WarrantyType } from './generics';

export class VehicleCreateModel {
    warrantyType: WarrantyType = 'Vehicle';
    renavam?: string;
    typeOfVehicle?: string;
    chassis?: string;
    board?: string; // Placa do Veículo
    supplier?: string;
    taxNumberOfSupplier?: string;
    brand?: string; // Marca do Veículo
    color?: string;
    model?: string; // Modelo do Veículo
    yearOfManufacture?: number; // Ano de Fabricação do Veículo
    yearOfModel?: number; // Ano de Modelo do Veículo
    typeOfFuel?: string; // Tipo de Combustível do Veículo
    downPayment?: number; // Valor de entrada
    sellerCompanyName?: string; // Revenda
    sellerRegistrationNumber?: string;
    valueInCash?: number;
    subtotalAmount?: number;
    totalAmountFinancedTaxFree?: number; // Valor total a ser financiado sem impostos

    constructor(obj?: Partial<VehicleCreateModel>) {
        Object.assign(this, obj);
    }

    title?() {
        return 'Veículo';
    }
    description?() {
        return ` Modelo ${this.brand} ${this.color}, ano ${this.yearOfManufacture} (modelo ${this.yearOfModel}), placa ${this.board} RENAVAM ${this.renavam}`;
    }
}
