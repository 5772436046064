/* eslint-disable react-hooks/exhaustive-deps */
import { FormProvider, useFormContext } from 'contexts/formContext';
import { validationApproveRevisionSchema } from './ApproveRevisionFormSchema';
import { ApproveRevisionForm } from './ApproveRevisionForm';
import { useState } from 'react';
import { useBatchAssignmenApprovalMutation } from 'contexts/batchAssignment/batchAssignmentContext';
import { useParams } from 'react-router';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { PayloadBatchAssignmentApprovalProps } from 'services/creditNote/BatchAssignment/BatchAssignment.types';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useCosifData } from 'contexts/creditProductContext';

type ApproveRevisionContainerProps = {
    onCloseModal: () => void;
    refetch: () => void;
};

interface CosifItem {
    description: string | any;
    code: string | any;
    pix: boolean;
    transfer: boolean;
    bankSlip: boolean;
    tenantName: string;
}

const ApproveRevisionContainer = ({ onCloseModal, refetch }: ApproveRevisionContainerProps) => {
    const { getValues } = useFormContext();
    const [toast, setToast] = useState<ToastType>(toastState);

    const onSuccess = () => {
        onCloseModal();
        const title = 'Cessão aprovada com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
        refetch();
    };

    const onError = (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { id } = useParams();
    const { mutate: approveBatch, isLoading } = useBatchAssignmenApprovalMutation(
        id!,
        onSuccess,
        onError
    );
    const { cosifData } = useCosifData();

    const cosifDataOptions = cosifData?.map((item: CosifItem) => {
        return {
            label: item?.description,
            value: item?.code
        };
    });

    const onSubmit = async (values: PayloadBatchAssignmentApprovalProps) => await approveBatch(values);

    const schema = validationApproveRevisionSchema();
    const currentValues = getValues();
    const defaultValues = {
        cosifAccountCode: currentValues.cosifAccountCode,
        liquidationValue: currentValues.liquidationValue,
        observations: currentValues.observations,
        creditDate: currentValues.creditDate
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                <ApproveRevisionForm
                    isLoading={isLoading}
                    onCloseModal={onCloseModal}
                    cosifDataOptions={cosifDataOptions ?? []}
                />
            </FormProvider>
        </>
    );
};

export default ApproveRevisionContainer;
