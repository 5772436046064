import { FormProvider } from 'contexts/formContext';
import { BlackList } from './BlackList';
import { validationSchemaBlackList, defaultValuesBlackList } from './BlackListSchema';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel';
import { FieldValues } from 'react-hook-form';
import { useCreateComplianceChecklistMutation } from 'contexts/creditNote/complianceCheckListContext';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    ToastType,
} from 'contexts/apiRequestContext';
import { useQueryClient } from '@tanstack/react-query';
import { AttributeType, ComplianceCheckListType } from 'services/creditNote/complianceChecklist';
import { getAttributeValue } from 'contexts/creditNote/creditNoteOptions';

type BlackListContainerProps = {
    data: NaturalPersonReadModel | LegalPersonReadModel;
    resource: 'NaturalPerson' | 'LegalPerson';
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetchBatchAssignment: () => void;
};

export const BlackListContainer = ({
    resource,
    onClose,
    data,
    setToast,
    refetchBatchAssignment
}: BlackListContainerProps) => {
    const queryClient = useQueryClient();
    const isNaturalPerson = resource === 'NaturalPerson';

    const onSuccess = async () => {
        showSuccessToast('Sucesso ao incluir o registro na black list', '', setToast);
        onClose();
        isNaturalPerson
            ? await queryClient.refetchQueries(['get-naturalPerson-data', data?.id])
            : await queryClient.refetchQueries(['legalPerson-data', data?.id]);
            
            refetchBatchAssignment();
    };

    const { mutateAsync, isLoading } = useCreateComplianceChecklistMutation(
        onSuccess,
        (error: ApiResponseError) => handleOnError(error, setToast)
    );

    async function onSubmit(values: FieldValues) {
        const complianceChecklist = values?.complianceChecklist;
        const ispb = values?.ispb;
        
        const payload = complianceChecklist.map((attributeType: AttributeType) => {
            return {
                attributeType: attributeType,
                attributeValue: getAttributeValue(attributeType, data, ispb),
                recordId: data?.id,
                recordType: resource?.toLowerCase(),
            } as ComplianceCheckListType;
        });
        await mutateAsync(payload);
    }

    return (
        <FormProvider
            defaultValues={defaultValuesBlackList}
            validationSchema={validationSchemaBlackList()}
            onSubmit={onSubmit}
            onChangeField={[
                {
                    fieldName: 'complianceChecklist',
                    delegate: (values, setValue) => {
                        const ispbSelected = values?.includes('ispb');

                        if (!ispbSelected) {
                            setValue('ispb', '');
                        }
                        return values;
                    },
                },
            ]}
        >
            <BlackList {...{ isLoading, onClose, data }} />
        </FormProvider>
    );
};
