import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-expandIconWrapper:not(.Mui-expanded)': {
        transform: 'rotate(-90deg)',
    },

    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

type AccordionContentProps = {
    expandedIndex: number;
    title: string;
    content: React.ReactNode;
}

export default function AccordionContent({ content, expandedIndex, title }: AccordionContentProps) {
    const [expanded, setExpanded] = React.useState<number | false>(expandedIndex);

    const handleChange =
        (panel: number) => (event: React.SyntheticEvent, newExpanded: boolean) => {
            setExpanded(newExpanded ? panel : false);
        };

    return (
        <Accordion
            key={expandedIndex}
            expanded={expanded === expandedIndex}
            defaultExpanded={true}
            onChange={handleChange(expandedIndex)}
        >
            <AccordionSummary>
                <Typography variant='h6' fontWeight='700'>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ display: 'block', padding: 0, margin: 0, boxSizing: 'border-box' }}>
                <Typography>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
}
