import { ApiResponseError } from "contexts/apiRequestContext";
import { mapErrorResponse } from "contexts/responseErrorContext";

export const errorHandlingBankAccount = (response: ApiResponseError) => {
  const { errorMessage, code, message } = mapErrorResponse(response);
  const isErrorLimit = code === "INSUFICIENT_TRANSACTION_LIMIT";
  const title = 'Ops, ocorreu um erro!';  
  return {
      title: isErrorLimit ? message ?? title : title,
      description: errorMessage,
      open: true
  }
}