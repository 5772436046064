import { string } from "yup";
import { object } from "yup";

export function validationSchemaRejectInstrument() {
    return object().shape({
        message: string().required('Mensagem: Precisa ser preenchido').typeError('Mensagem inválida'),
    });
};

export const defaultValuesRejectInstrument = {
    message: null,
};