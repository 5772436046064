import { IInstallmentFull } from 'services/walletManagement/installment/installment.types';
import { object, string } from 'yup';

export function transferValidationSchema(row: IInstallmentFull) {
    return object().shape({
        toWalletCode: string()
        .typeError('Carteira precisa ser preenchida')
        .required('Carteira precisa ser preenchida') 
        .test('equal-wallet', 'Selecione uma carteira diferente da atual', (value: any) => {
            if (value === row?.walletCode.toString()) return false
            return true ;
        })
    });
}

export const defaultValuesTransferSchema = {
    toWalletCode: null
};
