import { BankSlipIcon, PaymentLinkIcon, PixIcon } from "@uy3/web-components";
import CardsSelect from "components/CardSelect/CardSelect";
import { CustomTitle } from "components/TitleCustom/TitleCustom";
import { formatCurrencyInCents } from "helpers/formats/Currency";

type ProcessBillingFormProps = {
    registrationCost: number
}

const ProcessBillingForm = ({ registrationCost }: ProcessBillingFormProps) => {
    return (
        <>
            <CustomTitle title="Como você quer cobrá-lo?" variant="lg" />
            <CardsSelect
                onChange={({ value }) => {}}
                options={[
                    {
                        label: 'Boleto bancário',
                        description: `${formatCurrencyInCents(registrationCost) ?? ""} por cobrança emitida`,
                        value: 'boletoDeCobrança',
                        disabled: false,
                        Icon: BankSlipIcon,
                    },
                    {
                        label: 'Pix',
                        description: 'Em breve',
                        value: 'exemplo2',
                        disabled: true,
                        Icon: PixIcon,
                    },
                    {
                        label: 'Link de pagamento',
                        description: 'Em breve',
                        value: 'exemplo3',
                        disabled: true,
                        Icon: PaymentLinkIcon,
                    }
                ]}
                isCheckbox
                value={'boletoDeCobrança'}
            />
        </>
    );
};

export default ProcessBillingForm