import { useQuery } from '@tanstack/react-query';
import { useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { getBankList } from 'services/bankList/bankList';
import { isAxiosError } from 'axios';
import { useState } from 'react';

type UseBankListProps = {
    compe: boolean;
};

type TBankList = { label: string; value: string | number; bankIspb: number };

const invalidCode = (code: number) => {
    return code === undefined || code === null || code === 0;
};

export function useBankList({ compe = false }: UseBankListProps) {
    const { token } = useIdentity();
    const [bankList, setBankList] = useState<TBankList[]>([]);
    const [search, setSearch] = useState<string | undefined>(undefined);
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { data, isFetching, isLoading } = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['use-bank-list', search],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getBankList(search!, compe, token!);
                const bankList = data?.data ?? [];

                let bankListData = bankList?.map((item) => {
                    const { code, ispb, codeDisplay } = item ?? {};
                    const value = invalidCode(code) ? ispb : code;

                    return {
                        label: `${value} - ${codeDisplay}`,
                        value,
                        bankIspb: ispb,
                    };
                });

                setBankList(bankListData);
                endRequest(true);
            } catch (err) {
                endRequest(true);
                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    const bankListAutocomplete = {
        listOptions: bankList,
        loading: isLoading || isFetching,
        onSearch: (searchString: string | undefined) => setSearch(searchString)
    };

    return { data, bankListAutocomplete };
}
