import { useQuery } from '@tanstack/react-query';
import { useIdentity } from './identityContext';
import { useState } from 'react';
import { GetListApiResponseSuccess, useApiRequest } from './apiRequestContext';
import { useFilterValues } from './filterValuesContext';
import { isAxiosError } from 'axios';
import {
    EmploymentStatusProps,
    EmploymentStatusReadModel,
    getEmploymentStatusAsync,
} from 'services/EmploymentStatus';
import { SelectOption } from 'components/Forms/FormFields';

export function useGetEmploymentStatus(filters: EmploymentStatusProps, isPublicPayroll = false) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues, setFilterValues } = useFilterValues();
    const [employmentStatusList, setEmploymentStatusList] = useState<SelectOption[]>([]);
    let filtersComplete = { ...filters, ...filterValues.filters };

    const queryContext = useQuery({
        enabled: !!token && isPublicPayroll,
        refetchIntervalInBackground: false,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        queryKey: ['employment-status-list', filtersComplete],
        queryFn: async () => {
            startRequest();
            const resp = await getEmploymentStatusAsync(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const querySucess = data as unknown as GetListApiResponseSuccess<EmploymentStatusReadModel>;
            const queryMappedList =
                querySucess?.data.map((option) => {
                    return {
                        label: `${option.name} - Código: ${option.code}`,
                        value: option.code,
                        description: option.name,
                    };
                }) ?? [];

            setEmploymentStatusList(queryMappedList);

            return querySucess;
        },
    });

    const employmentSttsAutoCompleteProps = {
        listOptions: employmentStatusList,
        loading: queryContext.isLoading || queryContext.isFetching,
        onSearch: (searchString: string | undefined) => searchString && setFilterValues({ searchString }, 'EmploymentStatus')
    };

    return {
        ...queryContext,
        employmentSttsAutoCompleteProps,
    };
}
