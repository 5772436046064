import { ToastType, showSuccessToast } from "contexts/apiRequestContext";

type RedirectCloneRegisterProps = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    redirect: () => void;
    onClose: () => void;
}

export function redirectCloneRegister({ redirect, setToast, onClose }: RedirectCloneRegisterProps) {
    const successTitle = "Sucesso clonar o registro";
    const description = "Em aluns instantes você será redirecionado para o registro clonado."
    showSuccessToast(successTitle, description, setToast);
    onClose();
    return setTimeout(() => {
        redirect();
        setToast({ open: false, severity: 'success', title: successTitle })
    }, 2000);
}