import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, GetListApiResponse, useApiRequest } from "contexts/apiRequestContext";
import { useFilterValues } from "contexts/filterValuesContext";
import { useIdentity } from "contexts/identityContext";
import { isEmpty } from "lodash";
import { handleErrorUseQuery } from "helpers";
import { FileInstructionParams, downloadFileInstructionById, generateCNAB400FileInstruction, getAllFileInstruction, getFileInstructionById, getFileinstructionLinesById, postFileInstructionAsync, postFileInstructionValidateAsync } from "services/walletManagement/files/fileInstruction/fileInstruction";
import { FileInstructionModel, FiltersFileInstruction, GenerateCNAB400FileInstructionType } from "services/walletManagement/files/fileInstruction/fileInstruction.type";
import { LinesFileTypeGeneric } from "services/walletManagement/bankSlip/bankSlip.types";
import { downloadReportFileConfirmationLine, downloadTransactionReceiptFileConfirmation, getConfirmationLinesById } from "services/walletManagement/files/fileConfirmation/fileConfirmation";
import { ConfirmationDownloadFilePDFRequestProps } from "services/walletManagement/files/fileConfirmation/fileConfirmation.type";
import { useTenant } from "contexts/tenantContext";
import { responseBlobFile } from "helpers/methods/ResponseBlobFile";



export function useGetAllFileInstruction(params: FiltersFileInstruction) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { filterValues } = useFilterValues();

    const filtersComplete = {
        ...filterValues.filters,
        ...params, 
        tenant: currentTenantId
    };

    const queryContext = useQuery({
        enabled: !!token,
        retry: false,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-instruction', filtersComplete],
        queryFn: async (): Promise<FileInstructionModel[]> => {
            startRequest();

            const { data, status, statusText } = await getAllFileInstruction(filtersComplete, token!);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as FileInstructionModel[];
            return dataSuccess;
        },
    });

    return queryContext;
}

export function useGetByIdFileInstruction(fileInstructionId: string) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();


    const queryContext = useQuery({
        enabled: !!token && !!fileInstructionId && !isEmpty(fileInstructionId),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-instruction-by-id', fileInstructionId],
        queryFn: async (): Promise<FileInstructionModel> => {
            startRequest();

            const { data, status, statusText } = await getFileInstructionById(fileInstructionId, token!);

            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const dataSuccess = data as FileInstructionModel;
            return dataSuccess;
        },
    });

    return queryContext;
}

export function useGetByIdFileLines(fileInstructionId: string, recordType: "FileInstruction" | "FileConfirmation", params?: object) {
    const { token } = useIdentity();

    const queryContext = useQuery({
        enabled: !!token && !!fileInstructionId && !isEmpty(fileInstructionId),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-file-instruction-lines-by-id', fileInstructionId],
        queryFn: async (): Promise<GetListApiResponse<LinesFileTypeGeneric>> => {
            if (recordType === 'FileConfirmation') {
                const { data } = await getConfirmationLinesById(fileInstructionId, token!, params);
                return data as GetListApiResponse<LinesFileTypeGeneric>;
            }
            const { data } = await getFileinstructionLinesById(fileInstructionId, {lineType: 'RemessaTransacao'}, token!);
            return data as GetListApiResponse<LinesFileTypeGeneric>;
        },
    });

    return queryContext;
}


export function useFileInstructionAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (values: FileInstructionParams) => {
            startRequest();

            const { data, status, statusText } = await postFileInstructionAsync(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;

        },
        onSuccess(data: any, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useFileInstructionValidateAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (values: FileInstructionParams) => {
            startRequest();

            const { data, status, statusText } = await postFileInstructionValidateAsync(values, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;

        },
        onSuccess(data: any, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useDownloadFileInstructionAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (FileInstructionId: string) => {
            startRequest();
            return await downloadFileInstructionById(FileInstructionId, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess && onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useGenerateCNAB400FileInstructionAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (payload: GenerateCNAB400FileInstructionType) => {
            startRequest();
            return await generateCNAB400FileInstruction(payload, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess && onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}

export function useDownloadTransactionReceiptFileConfirmationAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (confirmationTransactionId: string) => {
            startRequest();

            return await downloadTransactionReceiptFileConfirmation(confirmationTransactionId, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess&&onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}

// francesinha
export function useDownloadReportFileConfirmationAsyncMutation(
    onSuccess: (data: any) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, ...rest } = useMutation({
        mutationFn: async (payload: ConfirmationDownloadFilePDFRequestProps) => {
            startRequest();

            return await downloadReportFileConfirmationLine(payload, token!)
                .then(async (response: any) => {
                    await responseBlobFile(response);
                    onSuccess&&onSuccess(response);
                }).catch((error: ApiResponseError) => {
                    handleErrorUseQuery(error, setSubmitError, endRequest, onError);
                });
        }
    });

    return { mutateAsync, isLoading, error, ...rest };
}

