import { Grid, styled } from "@mui/material";
import { Box } from "@mui/system";
import {
	Breadcrumb as BreadcrumbBase,
} from "@uy3/web-components";
import { useMatches } from "react-router";

export const StyledNav = styled('nav')(() => ({
	position: 'absolute'
}))

export const StyledBox = styled(Box)(({ theme }) => ({
	minHeight: theme.spacing(5)
}));

export const StyledIndex = styled(Box)(({ theme }) => ({
	'@media print': {
		display: 'none',
	}, 
	marginTop: "24px"
}));

function Breadcrumb() {
	const matches = useMatches();
	let crumbs = [{
		title: "Home",
		url: "/",
	}, ...matches
		.filter((match: any) => Boolean(match.handle?.showBreadcrumb))
		.map((match: any) => {
			return {
				title: match.handle.displayName,
				url: match.pathname,
			}
		})];
	return (
		<StyledIndex>
			<Grid container>
				<Grid item xs={8}>
					<StyledBox mb={2}>
						<BreadcrumbBase
							links={crumbs}
							separator="/"
						/>
					</StyledBox>
				</Grid>
			</Grid >
		</StyledIndex>
	);
}

export default Breadcrumb;
