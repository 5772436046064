import { Stack } from '@mui/material';
import { GroupedButtons } from '@uy3/web-components';

interface IStatementFiltersProps {
    onGetFilterSelected: (index: number) => void
    openSelectPeriod: (index: number) => void
}

export const filterOptionsList = [
    'Últimos 7 dias',
    'Últimos 15 dias',
    'Últimos 30 dias',
    'Últimos 60 dias',
    'Últimos 90 dias', 
    'Definir período'
]

export const StatementFilters = ({ onGetFilterSelected, openSelectPeriod }: IStatementFiltersProps) => {
    return (
        <Stack direction="row" justifyContent="right">
            <GroupedButtons
                groupedButtons={filterOptionsList}
                onClick={(index) => onGetFilterSelected(index!)}
                size='large'
            />
        </Stack>
    );
};
