export const operationTypeList = [
    { label: 'Inclusão', value: 'Inclusion' },
    { label: 'Edição', value: 'Edition' },
    { label: 'Exclusão', value: 'Exclusion' },
];

export const evidenceType = [
    { label: 'Ocorrência de Fraude', value: 'Ocorrencia' },
    { label: 'Tentativa de Fraude', value: 'Tentativa' },
];

export const role = [
    { label: 'Reclamante', value: 'Reporter' },
    { label: 'Executor', value: 'Executor' },
    { label: 'Conta Destino', value: 'ToAccount' },
];

export const dataSource = [
    { label: 'Resolução 6', value: 'Resolucao6' },
    { label: 'Interoperabilidade', value: 'Interoperabilidade' },
    { label: 'Chargeback', value: 'Chargeback' },
];

export const fraudModalityList = [
    { label: 'Auto Fraude', value: 'Self_Fraud' },
    { label: 'Conta de Passagem/Conta Laranja', value: 'Mule_Account' },
    { label: 'Fraude Amigável', value: 'Friendly_Fraud' },
    { label: 'Roubo de Identidade', value: 'Identity_Theft' },
    { label: 'Golpe por Phishing (pescaria digital) / link falso', value: 'Phishing_Scam' },
    { label: 'Engenharia Social e outros (mídias sociais)', value: 'Social_Engineering' },
    { label: 'Software Malicioso / dispositivo não pertencente ao cliente', value: 'Malicious_Software' },
    { label: 'Roubo ou Furto', value: 'Fraud_Robbery_Theft' },
    { label: 'Fraude com utilização do dispositivo do cliente (Acesso Remoto)', value: 'Fraud_Remote_Access' },
    { label: 'SIM SWAP', value: 'SIM_SWAP' },
    { label: 'Telefone utilizado de forma fraudulenta', value: 'Phone_Used_Fraudulently' },
    { label: 'Email utilizado de forma fraudulenta', value: 'Email_Used_Fraudulently' },
    { label: 'Endereço Completo utilizado de forma fraudulenta', value: 'Complete_Address_Used_Fraudulently' },
    { label: 'Falsidade Ideológica-Onboarding-Sucesso', value: 'Ideological_Falsehood_Onboarding_Success' },
    { label: 'Falsidade Ideológica-Onboarding-Tentativa', value: 'Ideological_Falsehood_Onboarding_Attempt' },
    { label: 'Fraude na concessão de Crédito (ex.: consignado)', value: 'Fraud_Granting_Credit' },
    { label: 'Fraude do comprador', value: 'Buyer_Fraud' },
    { label: 'Fraude do vendedor', value: 'Seller_Fraud' },
    { label: 'Regulatório', value: 'Regulatory' },
    { label: 'Fraude na portabilidade de salário', value: 'Salary_Portability_Fraud' },
    { label: 'Fraude de Assinatura', value: 'Subscription_Fraud' },
    { label: 'Fraude na restituição do imposto de renda', value: 'Income_Tax_Refund_Fraud' },
    { label: 'Golpe da troca de cartão', value: 'Card_Exchange_Scam' },
    { label: 'Sequestro ou Extorsão', value: 'Kidnapping_Extortion' },
    { label: 'Fraude no Transacional do E Commerce', value: 'ECommerce_Transactional_Fraud' },
];

export const relatedActivityList = [
    { label: 'Abertura de conta de depósitos ou conta de pagamento', value: 'Opening_Deposit_Account_Payment_Account' },
    { label: 'Manutenção de conta de depósitos ou conta de pagamento', value: 'Maintenance_Deposit_Account_Payment_Account' },
    { label: 'Contratação de operação de crédito', value: 'Contracting_Credit_Operations' },
    { label: 'Transferências entre contas na própria instituição', value: 'Transfers_Between_Accounts_Institution_Itself' },
    { label: 'Transferência Eletrônica Disponível (TED)', value: 'Electronic_Transfer_Available_TED' },
    { label: 'Transações de pagamento com emprego de cheque', value: 'Payment_Transactions_Using_Checks' },
    { label: 'Transações de pagamento instantâneo (PIX)', value: 'Instant_Payment_Transactions_PIX' },
    { label: 'Transferências por meio de Documento de Crédito (DOC)', value: 'Transfers_Credit_Document_DOC' },
    { label: 'Boletos de pagamento', value: 'Payment_Slips' },
    { label: 'Saques de recursos em espécie', value: 'Withdrawals_Cash_Resources' },
];

export const channelOptionsList = [
    { label: 'Internet', value: 'Internet' },
    { label: 'Celular', value: 'Celular' },
    { label: 'Autoatendimento', value: 'Autoatendimento' },
    { label: 'Rede Parceiros', value: 'RedeParceiros' },
    { label: 'Mídias Sociais', value: 'MídiasSociais' },
    { label: 'Interbancário', value: 'Interbancário' },
    { label: 'Outros', value: 'Outros' },

];

export const taxPayerList = [
    { label: 'Pessoa Física', value: 'PF' },
    { label: 'Pessoa Jurídica', value: 'PJ' },
    { label: 'Não informado', value: undefined },
]


export const accountTypeList = [
    { label: 'Conta de Depósito à Vista', value: 'CV_Conta_Deposito_AVista' },
    { label: 'Conta de Depósito Poupança', value: 'CP_Conta_Deposito_Poupanca' },
    { label: 'Conta de Pagamento Pré-Paga', value: 'PP_Conta_Pagamento_Pre_Paga' },
];