import { IconButton } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { CancelIcon } from '@uy3/web-components';
import React from 'react';
import { activeTheme } from 'services/theme';

type ButtonRemoveSelectedRowProps = {
    handleClearRow: () => void;
    rowsSelected: string[] | GridRowId[] | undefined;
}
const theme = activeTheme();
const ButtonRemoveSelectedRow: React.FC<ButtonRemoveSelectedRowProps> = (props) => {
    const { handleClearRow, rowsSelected } = props;
    return (
        <IconButton
            sx={{
                position: 'absolute',
                bottom: 9,
                left: rowsSelected!?.length === 1 ? 182: rowsSelected!?.length >= 10 ? 197 : 189,
            }}
            onClick={handleClearRow}
        >
            <CancelIcon height={1} width={1} htmlColor={theme.palette.primary.main} />
        </IconButton>
    );
}

export default ButtonRemoveSelectedRow;