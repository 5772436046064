import { Box, Grid, IconButton } from '@mui/material';
import { DataTable, EditIcon, ClearCircleIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid';
import Stack from '@mui/material/Stack';
import { UsersAndGroupFiltersContainer } from 'components/DataTableFilters/UsersAndGroupFilters/UsersAndGroupContainer';
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { TypographyCustom } from './TypographyCustom';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';

type UserListProps = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    page: number;
    setOpenPopup?: (rowData: any) => void;
};

const UsersList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setOpenPopup,
}: UserListProps) => {
    const { data: permissionsData } = useUserPermissionData();
    if (queryData?.type === 'error') return <Error error={queryData} />

    const columnsUsersList: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Operador',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => formatDocumentNumber(cellValues.value),
        },
        {
            field: 'email',
            headerName: 'Email',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'phoneNumber',
            headerName: 'Telefone',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => formatPhoneNumber(cellValues.value),
        },
        {
            field: 'enabled',
            headerName: 'Habilitado',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: ({ value }) => {
                const isEnable = !!value;
                return (
                    <TypographyCustom
                        isTrue={isEnable}
                        title={isEnable ? "Habilitado" : "Desabilitado"}
                    />
                )
            },
        },
        {
            field: 'userStatus',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                const isConfirmed = cellValues.value === 'CONFIRMED';
                return (
                    <TypographyCustom
                        isTrue={isConfirmed}
                        title={isConfirmed ? "Confirmado" : "Alteração de senha"}
                    />
                )
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const isEnabled = cellValues.row?.enabled;
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'right',
                            marginLeft: isEnabled ? '25px' : '-8px',
                        }}
                    >
                        <Link to={`${cellValues.id}`}>
                            <IconButton>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        {isEnabled && checkActionsPerms("DisableUser", permissionsData, 'User')&&
                            <IconButton
                                onClick={() => setOpenPopup && setOpenPopup(cellValues?.row)}
                            >
                                <ClearCircleIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        },
    ];

    return (
        <Box>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <UsersAndGroupFiltersContainer typeFilter="users" />,
                }}
                columns={columnsUsersList}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData?.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                getRowId={(row) => row?.id}
                enableJumpAction={false}
            />
        </Box>
    );
};

export default UsersList;
