/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import { UF } from 'services/zipCode/zipcode.types';
import { cnpjMask, cpfMaskAndSpace, phoneNumberMask, zipCodeMask } from 'utils/constants';

type BasicInformationFormProps = {
    discriminator: 'LegalPerson' | 'NaturalPerson';
};

export const BasicInformationForm: React.FC<BasicInformationFormProps> = ({ discriminator }) => {
    const { watch, setValue } = useFormContext();
    const isLegalPerson = discriminator === 'LegalPerson';

    return (
        <Stack spacing={2}>
            <Typography variant='h5' fontWeight='bold'>Informações básicas</Typography>
            <TextFormField
                name="registrationNumber"
                label={isLegalPerson ? "CNPJ" : "CPF"}
                variant="outlined"
                fullWidth
                required
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                }}
            />

            {isLegalPerson && <TextFormField
                name="companyName"
                label="Razão Social"
                variant="outlined"
                fullWidth
                required
            />}

            <TextFormField
                name="name"
                label={isLegalPerson ? "Nome Fantasia" : "Nome"}
                variant="outlined"
                fullWidth
                required
            />

            <TextFormField
                name="email"
                label="E-mail"
                variant="outlined"
                fullWidth
                required
            />

            <TextFormField
                name="phone"
                variant="outlined"
                fullWidth
                label="Telefone Celular"
                placeholder="(00) 0000-0000"
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: phoneNumberMask },
                }}
                required
            />

            <Typography variant='h5' fontWeight='bold'>Endereço</Typography>

            <TextFormField
                name="address.zipCode"
                id="zipCode"
                label="CEP"
                variant="outlined"
                placeholder="00000-000"
                fullWidth
                InputProps={{
                    inputComponent: MaskedInput,
                    inputProps: { mask: zipCodeMask },
                    onChange: (event) => {
                        let keyBoard = event?.currentTarget?.value.replace(/\D/g, '');
                        queryZipCodeAndFillForm(keyBoard, (fieldName, value) => {
                            setValue(`address.${fieldName}`, value);
                        });
                    },
                }}
            />
            <TextFormField
                name="address.addressName"
                fullWidth
                label="Endereço"
                variant="outlined"
                value={watch('address.addressName') ?? ''}
                InputLabelProps={{
                    shrink: watch('address.addressName') ? true : false,
                }}
            />
            <TextFormField
                name="address.city"
                fullWidth
                label="Cidade"
                variant="outlined"
                value={watch('address.city') ? watch('address.city') : ''}
                InputLabelProps={{
                    shrink: watch('address.city') ? true : false,
                }}
            />
            <TextFormField
                name="address.complement"
                fullWidth
                label="Complemento"
                variant="outlined"
            />
            <SelectFormField
                name="address.uf"
                label="UF"
                options={UF}
                fullWidth
                variant="outlined"
            />
            <TextFormField
                name="address.district"
                fullWidth
                label="Bairro"
                variant="outlined"
                value={watch('address.district') ? watch('address.district') : ''}
                InputLabelProps={{
                    shrink: watch('address.district') ? true : false,
                }}
            />
            <TextFormField name="address.number" fullWidth label="Número" variant="outlined" />

        </Stack>
    );
};
