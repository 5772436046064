import { Grid } from '@mui/material'
import { SelectFormField, TextFormField, TextFormFieldProps } from 'components/Forms/FormFields'
import { MaskedInput } from 'components/Forms/MaskedInput'
import { useFormContext } from 'contexts/formContext'
import { isEmpty } from 'lodash'
import React, { Fragment } from 'react'
import { pixKeyTypeValueOpt } from 'services/accounts/bankAccount'
import { cnpjMask, cpfMask, phoneNumberMask } from 'utils/constants'

type PixFieldsFormProps = {}

export type PixKeyValueType =
    | 'Phone'
    | 'Email'
    | 'Automatic'
    | 'AgencyAndAccount'
    | 'NaturalRegistrationNumber'
    | 'LegalRegistrationNumber'


export const PixFieldsForm: React.FC<PixFieldsFormProps> = () => {
    const { setValue, watch } = useFormContext();

    const pixKeyTypeValue = watch('pixKeyTypeValue') as PixKeyValueType;

    const ComponentPixKey: { [type: string]: Partial<TextFormFieldProps> } = {
        "Phone": {
            placeholder: "(00) 00000-0000",
            InputProps: {
                inputComponent: MaskedInput,
                inputProps: { mask: phoneNumberMask },
            }
        },
        "Email": {
            placeholder: `email@exemplo.com`,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
                const value = event?.target?.value;
                setValue('keyPix', value);
            }
        },
        "NaturalRegistrationNumber": {
            placeholder: "000.000.000-00",
            InputProps: {
                inputComponent: MaskedInput,
                inputProps: { mask: cpfMask },
            }
        },
        "LegalRegistrationNumber": {
            placeholder: "00.000.000/0000-00",
            InputProps: {
                inputComponent: MaskedInput,
                inputProps: { mask: cnpjMask },
            }
        },
        "Automatic": {
            placeholder: "00000000-0000-0000-0000-000000000000"
        },
    }

    const showDynamicComponent: boolean = !isEmpty(pixKeyTypeValue) && pixKeyTypeValue !== 'AgencyAndAccount'


    return (
        <Fragment>
            <Grid item xs={6}>
                <SelectFormField
                    name="pixKeyTypeValue"
                    label="Tipo de chave Pix"
                    fullWidth
                    required
                    options={pixKeyTypeValueOpt}
                />
            </Grid>
            {showDynamicComponent && (
                <TextFormField
                    name="keyPix"
                    variant="outlined"
                    fullWidth
                    {...ComponentPixKey[pixKeyTypeValue]}
                />
            )}

        </Fragment>)
}
