import { Grid, Stack } from '@mui/material'
import { Button, CancelIcon, CompleteIcon } from '@uy3/web-components'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

interface IProps {
    onClose: () => void;
    isLoading: boolean
}

const theme = activeTheme();

export const UpdateBankSlipCostForm: React.FC<IProps> = ({ onClose, isLoading }) => {
    return (
        <Stack spacing={3}>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de registro"
                    name='register'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de baixa"
                    name='cancellation'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de abatimento"
                    name='discount'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de alteração do vencimento"
                    name='changeDueDate'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de liquidação"
                    name='settlement'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de protesto"
                    name='debtCollection'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo sustar protesto"
                    name='debtCollectionCancellation'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo liquidação cartorio"
                    name='debtCollectionSettlement'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de baixa protesto"
                    name='debtCollectionSuccess'
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de transferência registro"
                    name="transferRegister"
                    variant='outlined'
                    fullWidth
                />
            </Grid>
            <Grid item xs={2}>
                <CurrencyFormField
                    label="Custo de transferência baixa"
                    name="transferCancellation"
                    variant='outlined'
                    fullWidth
                />
            </Grid>

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant='contained'
                    disabled={isLoading}
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    type='submit'
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
