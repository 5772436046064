import { Drawer } from '@uy3/web-components'
import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { CommunicationSettingForm } from './CommunicationSettingForm'
import { ICreateCommunicationSetting } from 'services/walletManagement/communicationSetting/communicationSetting.types'

type CommunicationSettingFormContainerProps = {
    open: boolean; 
    title: string; 
    onClose: () => void; 
    schema: {
        validation: object; 
        defaultValues: object;
    }, 
    toggleDrawer?: boolean, 
    onSubmit: (values: ICreateCommunicationSetting) => void; 
    disableWalletCodeField?: boolean
}

export const CommunicationSettingFormContainer: React.FC<CommunicationSettingFormContainerProps> = ({
    onClose, 
    open, 
    schema,
    title, 
    onSubmit, 
    toggleDrawer = false, 
    disableWalletCodeField = false
}) => {
    return (
        <Drawer
            anchor="right"
            open={open}
            title={title}
            onClose={onClose}
            toggleDrawer={toggleDrawer}
        >
            <FormProvider
                defaultValues={schema.defaultValues}
                validationSchema={schema.validation}
                onSubmit={onSubmit}
            >
                <CommunicationSettingForm {...{ onClose, disableWalletCodeField }} />
            </FormProvider>
        </Drawer>)
}
