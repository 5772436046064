import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { SelectFormField } from 'components/Forms/FormFields'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField'
import { RefreshProgress } from 'components/RefreshProgress'
import { useFormContext } from 'contexts/formContext'
import React from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type CreateBatchAssignmentProps = {
    onClose: () => void;
    isLoading: boolean
}

export const CreateBatchAssignment = ({ onClose, isLoading }: CreateBatchAssignmentProps) => {
    const { watch } = useFormContext();
    return (
        <Stack spacing={2} direction='column'>
            <Grid item xs={6}>
                <SelectFormField
                    name="assignmentCalculation.type"
                    label="Tipo de taxa"
                    fullWidth
                    options={[
                        { label: 'Valor absoluto', value: 'Absolute' },
                        { label: 'Percentual', value: 'Percentage' },
                    ]}
                />
            </Grid>
            {watch('assignmentCalculation.type') === 'Absolute' ? (
                <Grid item xs={6}>
                    <CurrencyFormField
                        label="Custo de cessão"
                        name="assignmentCalculation.amount"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            ) : (
                <Grid item xs={6}>
                    <PercentageFormField
                        label="Custo de cessão (%)"
                        name="assignmentCalculation.amount"
                        variant="outlined"
                        fullWidth
                    />
                </Grid>
            )}

            <RefreshProgress refreshing={isLoading} />

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 17, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={
                        <CompleteIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    disabled={isLoading}
                    variant="contained"
                    size="medium"
                    type="submit"
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
