import { Stack } from '@mui/material'
import { SelectFormField } from 'components/Forms/FormFields'
import { useFormContext } from 'contexts/formContext'
import React from 'react'
import { RemessaLiquidacaoForDateForm } from './Forms/RemessaLiquidacaoForDateForm/RemessaLiquidacaoForDateForm'
import { GenerateRemessaLiquidacaoOpForm } from './Forms/RemessaLiquidacaoForOpForm/RemessaLiquidacaoForOpForm'

type GenerateFileLiquidationProps = {

}

export const GenerateFileLiquidation: React.FC<GenerateFileLiquidationProps> = () => {
    const { watch } = useFormContext();
    return (
        <Stack spacing={2} mt={3}>
            <SelectFormField
                name='typeLiquidation'
                label="Tipo de liquidação"
                sx={{width: '400px'}}
                showButtonClearValue
                options={[
                    { label: "Conciliação Caixa", value: "concilicaoCaixa" },
                    { label: "Liquidação Antecipada Cliente", value: 'liquidacaoAntecipadaCliente' }
                ]}
            />

            {watch('typeLiquidation') === 'concilicaoCaixa' ? (
                <Stack>
                    <RemessaLiquidacaoForDateForm />
                </Stack>
            ) : watch('typeLiquidation') === 'liquidacaoAntecipadaCliente' ? (
                <Stack>
                    <GenerateRemessaLiquidacaoOpForm />
                </Stack>
            ) : <React.Fragment></React.Fragment>}

        </Stack>
    )
}
