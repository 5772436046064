import type { MenuItemProps } from './MenuItem.interface';
import React, { FunctionComponent } from 'react';

import clsx from 'clsx';

import { StyledMenuItem } from './MenuItem.styled';

export const MenuItem: FunctionComponent<MenuItemProps> = React.forwardRef((props, ref) => {
  const { children, size, className, ...menuItemProps } = props;
  return (
    <StyledMenuItem ref={ref} {...menuItemProps} className={clsx(size, className)}>
      {children}
    </StyledMenuItem>
  );
});
