import { string, object } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

export function walletFiltersSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        walletsCode: string().when('optionFilter', {
            is: 'walletsCode',
            then: string().required(`Código da carteira: ${genericMessage}.`).typeError('Código da carteira inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        name: string().when('optionFilter', {
            is: 'name',
            then: string()
                .required(`Nome: ${genericMessage}`)
                .typeError('Nome é inválido.'),
            otherwise: string().nullable().notRequired(),
        })
    });
}

export const defaultValuesWalletFilterschema = {
    optionFilter: 'walletsCode',
    walletsCode: null,
    name: null
};
