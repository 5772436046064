import type { FunctionComponent } from 'react';
import type { PaymentDrawerProps } from './PaymentDrawer.interface';

import { Stack, useMediaQuery, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import {
  alertProps,
  cardDataBeneficiarioProps,
  cardDataPagadorProps,
  cardInfoWithValueProps,
} from './PaymentDrawer.mock';
import { Link, StyledButton, TotalStack } from './PaymentDrawer.styled';
import { Alert, CardData } from '../../molecules';
import { Drawer } from '../Drawer';
import { Button, Typography } from '../../atoms';
import { BankSlipIcon } from '../../icons/BankSlipIcon';
import { CardValue } from '../../molecules/CardValue';

export const PaymentDrawer: FunctionComponent<PaymentDrawerProps> = ({ open, onClose, anchor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Drawer
      title="Resumo da operação"
      description="Confira as informações antes de aprovar a operação"
      open={open}
      anchor={anchor}
      onClose={onClose}
    >
      <Stack direction="column">
        <CardValue {...cardInfoWithValueProps} />

        <Typography variant="xs" lineHeight="24px" color="neutral.dark" my={4}>
          Dados do beneficiário original
        </Typography>

        <CardData {...cardDataBeneficiarioProps} />

        <Typography variant="xs" lineHeight="24px" color="neutral.dark" mb={4}>
          Dados do pagador original
        </Typography>

        <CardData {...cardDataPagadorProps} />

        <Alert {...alertProps} />

        <Stack direction="row" justifyContent="flex-end">
          <Link href="/">Ver limites transacionais</Link>
        </Stack>

        <TotalStack direction="row" justifyContent="space-between" alignItems="center" my={6}>
          <Typography variant="sm" color="neutral.dark" lineHeight="29px">
            Total a pagar
          </Typography>
          <Typography variant="lg" color="primary" lineHeight="38px" fontWeight={700}>
            R$ 1.122,71
          </Typography>
        </TotalStack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'flex-end'}
          spacing={3}
          mb={6}
        >
          <Button
            startIcon={<CancelOutlinedIcon />}
            variant="outlined"
            size="medium"
            fullWidth={isMobile}
          >
            Cancelar
          </Button>
          <StyledButton
            startIcon={<BankSlipIcon />}
            variant="contained"
            size="medium"
            fullWidth={isMobile}
          >
            Confirmar transferência
          </StyledButton>
        </Stack>
      </Stack>
    </Drawer>
  );
};
