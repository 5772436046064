import { GetListApiResponseSuccess, PaginatedRequest, PaginationSelfType } from "contexts/apiRequestContext";
import { CreateBeneficiaryType, FiltersTableTransfer, StatementQueryFiltersType } from "contexts/bankAccount/bankAccountType";
import { createAxiosConfig, creditAxiosApi } from "services/axiosApi/axiosApi";
import {
    ActionApproveOrRejectType,
    ApproveOrRejectTransfer,
    BankAccountPixRefundCreateModel,
    CancelPaymentType,
    CancelTransferProps,
    ConsumptionBillPaymentType,
    DeleteOperatorByOperatorIdType,
    EditOperatorType,
    IBankAccountPixQRcode,
    OperatorListFilters,
    OperatorsPedingApprove,
    OperatorsPedingFilters,
    ParamsAndFiltersStatement,
    ParamsCancel,
    ParamsCreateOperator,
    ParamsDelete,
    PayloadApproveOrRejectType,
    PaymentListFilters,
    PixQRcodeImmediateDynamic,
    PixQRcodeStatic,
    PixRefundSuccess,
    StatementReadModel,
    OperatorReadModelList,
    PostBankAccountClaimPixKeyProps,
    PostBankAccountKeyProps,
    PutBankAccountClaimPixKeyType,
    postBankAccountRequestOperatorProps
} from './types';
import { FieldValues } from 'react-hook-form';
import { currentDateWithSetHours, toIsoStringWithTimezone, toDataModel } from "helpers";

var url = '/BankAccount';

export const getBankAccountList = async (filters: any, token?: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            ...filters
        }
    };

    return await creditAxiosApi.get(url, config);
};

export const getBankAccountStatement = async (bankAccountId: string, filters?: StatementQueryFiltersType, token = '') => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            ...filters
        }
    };
    return await creditAxiosApi.get<StatementReadModel>(`${url}/${bankAccountId}/Statement`, config);
};

export const getBankAccountOperatorsPeding = async (filters: OperatorsPedingFilters, token = '') => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            ...filters,
            f_level: filters?.f_level ?? 'AllOperators'
        }
    };
    return await creditAxiosApi.get(`${url}/PendingOperators`, config);
};

export const putBankAccountOperatorsPeding = async (payload: OperatorsPedingApprove, token = '') => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.put(`${url}/adminApproveOperatorsByBatch`, payload, config);
};

export const createBeneficiary = async (bankAccountId: string, payload: CreateBeneficiaryType, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    payload = {
        ...payload,
        registrationNumber: payload?.registrationNumber?.replace(/\D/g, '')
    };

    return await creditAxiosApi.post(`${url}/${bankAccountId}/Beneficiary`, payload, config);
};

export const getTransfersList = async (bankAccountId: string, filters: FiltersTableTransfer, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            ...filters
        }
    };
    return await creditAxiosApi.get(`${url}/${bankAccountId}/Transfer`, config);
};

export const createTransfer = async (bankAccountId: string, data: any, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(`${url}/${bankAccountId}/Transfer`, data, config);
};

export const getBankSlipData = async (bankAccountId: string, barCode: any, token: string) => {

    let digitableLine = barCode.length >= 47 ? barCode : null;

    let urlPay = `${url}/${bankAccountId}/Pay/Bankslip`;
    barCode.length === 44 ? urlPay = urlPay + `?barCode=${barCode}` : urlPay = urlPay + `?digitableLine=${digitableLine}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(urlPay, config);
};

export const getBankslipConsumptionBillOrTaxes = async (bankAccountId: string, barCode: any, token: string) => {

    let digitableLine = barCode.length >= 47 ? barCode : null;

    let urlPay = `${url}/${bankAccountId}/Pay/BankslipConsumptionBillOrTaxes`;
    barCode.length === 44 ? urlPay = urlPay + `?barCode=${barCode}` : urlPay = urlPay + `?digitableLine=${digitableLine}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(urlPay, config);
};

export const getBankSlipFGTS = async (bankAccountId: string, data: any, token: string) => {

    const { barCode } = data;

    let digitableLine = barCode.length >= 47 ? barCode : null;

    let urlPay = `${url}/${bankAccountId}/pay/BankSlipFGTS`;
    barCode.length === 44 ? urlPay = urlPay + `?barCode=${barCode}` : urlPay = urlPay + `?digitableLine=${digitableLine}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(urlPay, config);
};

export const getBankSlipDARF = async (bankAccountId: string, filters: any, token: string) => {
    let urlPay = `${url}/${bankAccountId}/pay/BankSlipDARF`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await creditAxiosApi.get(urlPay, config);
};

export const getPaymentsList = async (accountId: string, filters: PaymentListFilters, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await creditAxiosApi.get(`${url}/${accountId}/Pay`, config);
};

export const postPay = async (bankAccountId: string, payload: any, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay`, payload, config);
};

export const postPayTaxWithBarCode = async (bankAccountId: string, payload: any, token: string) => {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/TaxWithBarCode`, payload, config);
};

export const postPayFgtsWithBarCode = async (bankAccountId: string, payload: any | undefined, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/FGTSWithBarCode`, payload, config);
};

export const postPayDarf = async (bankAccountId: string, payload: any | undefined, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/DARF`, payload, config);
};

export const postPayGareIcms = async (bankAccountId: string, payload: any | undefined, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/GAREICMS`, payload, config);
};

export const postPayGareIpva = async (bankAccountId: string, payload: any | undefined, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/GAREIPVA`, payload, config);
};

export const postPayDarfWithBarCode = async (bankAccountId: string, payload: any | undefined, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/DARFWithBarCode`, payload, config);
};

export const postPayConsumerAccounts = async (bankAccountId: string, payload: ConsumptionBillPaymentType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/Pay/ConsumptionBill`, payload, config);
};

export const getBankAccountOperatorList = async (bankAccountId: string, filters: OperatorListFilters, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await creditAxiosApi.get<GetListApiResponseSuccess<OperatorReadModelList>>(`${url}/${bankAccountId}/Operator`, config);
};

export const getBankAccountBeneficiariesList = async (accountId: string, filters: any, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await creditAxiosApi.get<PaginatedRequest<any[]> & PaginationSelfType>(`${url}/${accountId}/Beneficiary`, config);
};

export const deleteByBankAccountId = async (props: ParamsDelete, token: string) => {
    const { bankAccountId, userId, path } = props;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.delete(`${url}/${bankAccountId}/${path}/${userId}`, config);
};

export const createOperator = async (accountId: string, payload: ParamsCreateOperator, tenant: string | undefined, token: string) => {
    const url = `/BankAccount/${accountId}/Operator`;

    const urlWithTenant = tenant ?
        url + "?tenant=" + tenant :
        url;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(urlWithTenant, payload, config);
};

export const putEditOperator = async (operatorId: string, payload: EditOperatorType, token: string) => {
    const url = `/BankAccount/${operatorId}/alterLevel`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(url, payload, config);
};

export const approveOrRejectBankAccountByResource = async (bankAccountId: string, resourceId: string, { payload, resource }: ActionApproveOrRejectType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`${url}/${bankAccountId}/${resource}/${resourceId}/Approve`, payload, config);
};

export const operatorBatchApproval = async (payload: PayloadApproveOrRejectType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`${url}/Operator/BatchApproval`, payload, config);
};

export const beneficiaryBatchApproval = async (payload: PayloadApproveOrRejectType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`${url}/Beneficiary/BatchApproval`, payload, config);
};

export const payBatchApproval = async (payload: PayloadApproveOrRejectType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`${url}/Pay/BatchApproval`, payload, config);
};

export const transferBatchApproval = async (payload: PayloadApproveOrRejectType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.put(`${url}/Transfer/BatchApproval`, payload, config);
};

export const cancelOperatorAndFavored = async (props: ParamsCancel, token: string) => {
    const { bankAccountId, beneficiaryId, path } = props;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.put(`${url}/${bankAccountId}/${path}/${beneficiaryId}/cancel`, {}, config);
};


export const postBankAccountStatementExportPfd = async (params: ParamsAndFiltersStatement) => {
    const { bankAccountId, finalDate, initialDate, token } = params;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' as 'blob',
        params: {
            initialDate,
            finalDate
        },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/statement`, {}, config);
};

export const postBankAccountStatementSpreadsheet = async (params: ParamsAndFiltersStatement) => {
    const { bankAccountId, finalDate, initialDate, token } = params;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' as 'blob',
        params: {
            initialDate,
            finalDate
        },
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/statementspreadsheet`, {}, config);
};

export const postTransferreceipt = async (bankAccountId: string, beneficiaryId: string, token: string) => {
    const config: any = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/${beneficiaryId}/transferreceipt`, {}, config);
};

export const postPaymentreceipt = async (bankAccountId: string, beneficiaryId: string, token: string) => {
    const config: any = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob',
    };
    return await creditAxiosApi.post(`${url}/${bankAccountId}/${beneficiaryId}/paymentreceipt`, {}, config);
};

export const putCancelPayment = async (bankAccountId: string, data: CancelPaymentType, token: string) => {
    const { paymentId, code, sessionId } = data;
    const url = `/BankAccount/${bankAccountId}/Pay/${paymentId}/cancel?sessionId=${sessionId}&code=${code}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.put(url, {}, config);
};


export async function getAccountOperators(bankAccountId: string, filters: { page: number, size: number }, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return await creditAxiosApi.get(`BankAccountRequest/${bankAccountId}/accountOperators`, config);
}

export async function postBankAccountOperator(accountNumber: string, data: postBankAccountRequestOperatorProps, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.post(`/BankAccount/${accountNumber}/BankAccountOperator`, data, config);
}

export async function deleteBankAccountOperator(accountNumber: string, operatorId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.delete(`/BankAccount/${accountNumber}/BankAccountOperator/${operatorId}`, config);
}

export async function deleteOperatorByBankAccountId(bankAccountId: string, operatorId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.delete(`/BankAccount/${bankAccountId}/Operator/${operatorId}`, config);
}

export async function postBankAccountCnab240(bankAccountId: string, initialDate: string, finalDate: string, token: string) {
    let url = `/Cnab/Cnab240?bankAccountId=${bankAccountId}&initialDate=${initialDate}&finalDate=${finalDate}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    }
    return await creditAxiosApi.post(url, {}, config);
}

export async function putCancelTransfer({ bankAccountId, transferId, sessionId, code, token }: CancelTransferProps) {
    let url = `BankAccount/${bankAccountId}/Transfer/${transferId}/cancel/?sessionId=${sessionId}&code=${code}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    return await creditAxiosApi.put(url, {}, config);

}

export async function putApproveOrRejectTransfer({ bankAccountId, beneficiaryId, sessionId, code, token, action }: ApproveOrRejectTransfer) {
    let url = `BankAccount/${bankAccountId}/Transfer/${beneficiaryId}/approve`;

    let payload = { code, sessionId, action };

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.put(url, payload, config);
}

export const getBankAccountLimits = async (bankAccountId: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Limits`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(url, config);
};

export const postRequestBankAccountLimits = async (bankAccountId: string, payload: any, token: string) => {
    let url = `BankAccount/${bankAccountId}/Limits`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
}

export const getBankAccountKey = async (bankAccountId: string, params: unknown, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };

    return await creditAxiosApi.get(url, config);
};

export const getBankAccountByKey = async (bankAccountId: string, keyPix: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/${keyPix}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(url, config);
};

export const postBankAccountKey = async (bankAccountId: string, data: PostBankAccountKeyProps, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key`;

    const payload: PostBankAccountKeyProps = {
        pixKey: data.pixKey,
        type: data.type
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
};

export const postBankAccountClaimKeyPix = async (bankAccountId: string, data: PostBankAccountClaimPixKeyProps, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/Claim`;

    const payload: PostBankAccountClaimPixKeyProps = {
        pixKey: data.pixKey,
        type: data.type,
        claimType: data.claimType
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
};

export const deleteBankAccountPixKey = async (bankAccountId: string, keyPix: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/${keyPix}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.delete(url, config);
};

export const getBankAccountPixKeyClaim = async (bankAccountId: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/Claim`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(url, config);
};

export const putBankAccountPixKeyClaim = async (bankAccountId: string, payload: PutBankAccountClaimPixKeyType, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/Claim`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.put(url, payload, config);
};

export async function deleteBankAccountOperatorByOperatorId({ accountNumber, operatorId, code, sessionId }: DeleteOperatorByOperatorIdType, token: string) {
    const url = `/BankAccount/${accountNumber}/BankAccountOperator/${operatorId}/?sessionId=${sessionId}&code=${code}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.delete(url, config);
}
export const getInformationByPixKey = async (bankAccountId: string, pixKey: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/Key/${pixKey}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.get(url, config);
};

export async function getBankAccountCreditorId(bankAccountId: string, personId: string, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { personId }
    };
    return await creditAxiosApi.get(url + `/${bankAccountId}/Creditor`, config);
}

export const getBankAccountPixQRCode = async (bankAccountId: string, token: string, qrCode?: string) => {
    let url = `BankAccount/${bankAccountId}/Pix/QRCode`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            qrCode,
            intendedPaymentDate: toIsoStringWithTimezone(new Date(currentDateWithSetHours))
        }
    };

    return await creditAxiosApi.get(url, config);
}

export const postBankAccountPixQRCodeStatic = async ({ bankAccountId, payload, token }: IBankAccountPixQRcode<PixQRcodeStatic>) => {
    let url = `BankAccount/${bankAccountId}/Pix/QRCode/Static`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
}

export const postBankAccountPixQRCodeImmediateDynamic = async ({ bankAccountId, payload, token }: IBankAccountPixQRcode<PixQRcodeImmediateDynamic>) => {
    let url = `BankAccount/${bankAccountId}/Pix/QRCode/ImmediateDynamic`;

    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await creditAxiosApi.post(url, payload, config);
}

export const downloadIncomeTaxBankAccountById = async (bankAccountId: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/IncomeTax`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        responseType: 'blob' as 'blob',
    };

    return await creditAxiosApi.get(url, config);
}

export const postCreateCreditor = async (bankAccountId: string, personId: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Creditor`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { personId }
    };

    return await creditAxiosApi.post(url, {}, config);
}

export const deleteCreditorByBankAccount = async (bankAccountId: string, personId: string, token: string) => {
    let url = `BankAccount/${bankAccountId}/Creditor`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { personId }
    };

    return await creditAxiosApi.delete(url, config);
};

export const terminateAsync = async (
    bankAccountId: string,
    payload: FieldValues,
    token?: string
) => {
    let url = `BankAccount/${bankAccountId}/Terminate`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        'Content-Type': 'application/json',
    };

    return await creditAxiosApi.post(url, payload, config);
};

export const addAccountEntryAsync = async (
    bankAccountId: string,
    payload: FieldValues,
    token?: string
) => {
    let url = `BankAccount/${bankAccountId}/Entry`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        'Content-Type': 'application/json',
    };

    return await creditAxiosApi.post(url, payload, config);
};

export const pixRefoundAsync = async (bankAccountId: string, data: FieldValues, token: string) => {
    const axiosConfig = createAxiosConfig(token);
    const urlFull = `${url}/${bankAccountId}/Pix/Refund`;

    const payload = toDataModel(
        data as Record<keyof BankAccountPixRefundCreateModel, unknown>,
        BankAccountPixRefundCreateModel
    );

    return await creditAxiosApi.post<PixRefundSuccess>(urlFull, payload, axiosConfig);
};
