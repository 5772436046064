import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesFGTSManagement, validationSchemaFGTSManagement } from './FGTSManagementSchema'
import { FGTSManagement } from './FGTSManagement'
import { useBatchAssignmentData, useFGTSBatchAssignmentMutation } from 'contexts/batchAssignment/batchAssignmentContext'
import { FGTSBatchAssignmentType } from 'services/creditNote/BatchAssignment/BatchAssignment.types'
import { useFundsList } from 'contexts/fundContext'
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel'
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { isEmpty } from 'lodash'

type FGTSManagementContainerProps = {
  onCloseModal: () => void;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
  refetchList: () => void;
  batchAssignmentData: CreditNoteReadModel[];
}

type SaveValuesType = {
  setValue: (name: string, value: string | null) => void;
  batch: { [field: string]: any };
  value: string;
}

export const FGTSManagementContainer: React.FC<FGTSManagementContainerProps> = ({
  onCloseModal,
  setToast,
  refetchList,
  batchAssignmentData
}) => {
  const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');
  const { batchAssignmentAutoComplete } = useBatchAssignmentData({ page: 0, size: 10 }, 'always', true);

  const onSuccess = () => {
    const title = "Sucesso ao incluir/atualizar cessão FGTS!";
    const description = "Aguarde a atualização da lista de cessões.";
    showSuccessToast(title, description, setToast);
    onCloseModal();
    refetchList();
  };

  const onError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error);
    showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
  };

  const { isLoading, mutateFGTSManagement } = useFGTSBatchAssignmentMutation(onSuccess, onError);

  const onSubmit = (payload: FGTSBatchAssignmentType) => {
    mutateFGTSManagement(payload);
  }

  const findSelectedBatch = (id: string): CreditNoteReadModel | null => {
    const find = batchAssignmentData?.find(item => item?.id === id);
    return find ?? null;
  }

  const saveValuesField = ({ batch, setValue, value }: SaveValuesType) => {
    const fieldValues = ['id', 'assignmentDate', 'fundId', 'fundIdDisplay'];
    if (!isEmpty(batch)) {
      return fieldValues.map(field => setValue(field, batch[field]));
    }
    if (isEmpty(value)) {
      return fieldValues.map(field => setValue(field, null));
    }
  }

  const validationSchema = validationSchemaFGTSManagement();
  const defaultValues = defaultValuesFGTSManagement;

  return (
    <>
      <FormProvider
        validationSchema={validationSchema}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        onChangeField={
          [{
            fieldName: 'id',
            delegate: (value: string, setValue: (name: string, value: string | null) => void) => {
              const batch = findSelectedBatch(value);
              return saveValuesField({ batch: batch!, setValue, value });
            }
          }]
        }
      >
        <FGTSManagement
          fundList={fundAutoCompleteProps}
          batchAssignmentAutoComplete={batchAssignmentAutoComplete}
          isLoading={isLoading}
          onCloseModal={onCloseModal} />
      </FormProvider>
    </>
  )
}
