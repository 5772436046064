import { createAxiosConfig, creditAxiosApi } from 'services/axiosApi';
import {
    CancelConsignmentCreateModel,
    CheckConsignmentsReadModel,
    ReserveMarginCreateModel,
    TCancelConsignmentParams,
    TCheckConsignmentsParams,
    TUpdateZetraParams,
    TZetraMarginQueryParams,
    IZetraMarginQueryReadModel,
    ZetraMarginQueryUpdateModel,
} from './types';

const url = '/Zetra';
export async function postUpdateZetraMarginQueryAsync(params: TUpdateZetraParams, token: string) {
    const endpoint = `${url}/UpdateZetraMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);

    return await creditAxiosApi.post<ZetraMarginQueryUpdateModel>(endpoint, {}, axiosConfig);
}

export async function getZetraMarginQueryAsync(params: TZetraMarginQueryParams, token: string) {
    const endpoint = `${url}/ZetraMarginQuery`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<IZetraMarginQueryReadModel[]>(endpoint, axiosConfig);
}

export async function getCheckConsignmentsAsync(params: TCheckConsignmentsParams, token: string) {
    const endpoint = `${url}/CheckConsignments`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.get<CheckConsignmentsReadModel>(endpoint, axiosConfig);
}

export async function postReserveMarginAsync(creditNoteOpId: string, token: string) {
    const endpoint = `${url}/${creditNoteOpId}/ReserveMargin`;
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post<ReserveMarginCreateModel>(endpoint, {}, axiosConfig);
}

export async function postCancelConsignmentAsync(params: TCancelConsignmentParams, token: string) {
    const endpoint = `${url}/CancelConsignment`;
    const axiosConfig = createAxiosConfig(token, params);
    return await creditAxiosApi.post<CancelConsignmentCreateModel>(endpoint, {}, axiosConfig);
}
