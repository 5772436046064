import { Stack } from '@mui/material'
import { useSignatureCreateAsync } from 'contexts/signature/signatureContext';
import { SignatureWorkFlowCreateType } from 'services/signature/signature.types';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesSignatureForm, validationSchemaSignatureForm } from './SignatureSchema';
import { SignatureForm } from './SignatureForm';
import React from 'react';
import { formatDeadLine } from 'helpers/formats/DateFormat';
import { SelectOption } from 'components/Forms/FormFields';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { isEmpty } from 'lodash';

type SignatureCreditNoteContainerType = {
  onCloseStep: () => void;
  uploadSelected: any;
  persons: any[];
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
  skipStepToStartSignature: () => void;
  saveSignatureWorkflowId: (id: string) => void;
  targetType?: "BankAccountRequest" | "CreditNoteOp" | "BatchAssignment"
  targetId: string;
}

export const SignaturesContainer: React.FC<SignatureCreditNoteContainerType> = (props) => {
  const { onCloseStep, uploadSelected, persons, setToast, skipStepToStartSignature, saveSignatureWorkflowId, targetType = 'CreditNoteOp', targetId } = props;

  const optionsFiltered = persons.filter((item) => {
    const personId = item?.personId;
    return !isEmpty(personId) && personId !== undefined && personId != null;
  })

  const listInvolvedOptions: SelectOption[] = optionsFiltered.map(item => {
    
    const personIdDisplay = item?.personIdDisplay ?? '';
    const typeOfRelationshipDisplay = item?.typeOfRelationshipDisplay ?? '';
    const fundSignerTypeDisplay = item?.fundSignerTypeDisplay ?? '';
    const relationshipOrSignerType = typeOfRelationshipDisplay || fundSignerTypeDisplay;

    const label = `${personIdDisplay}${relationshipOrSignerType ? ` - (${relationshipOrSignerType})` : ''}`;

    return {
      label: label,
      value: item?.personId
    }
  });

  const onSuccess = (data: any) => {
    skipStepToStartSignature();
    saveSignatureWorkflowId(data);
    const title = 'Sucesso ao enviar para assinatura';
    showSuccessToast(title, "", setToast)
  }

  const onError = (response: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(response);
    showErrorToast("Ops, houve um erro", errorMessage, setToast)
  }

  const getSignersSelectedByPersonId = (personsIds: string[]) => {
    const find = persons?.filter(item => personsIds.includes(item.personId));
    return find?.map(item => {
      return {
        displayName: item?.personIdDisplay,
        personId: item?.personId,
        typeOfRelationship: targetType === 'BatchAssignment' ? item?.fundSignerType : item?.typeOfRelationship,
        signatureType: item?.signatureType,
        validation: item?.signatureValidation
      }
    })
  }

  const { mutateCreateSignatureAsync } = useSignatureCreateAsync((data) => onSuccess(data), onError);

  const onSubmit = ({ deadLine, ...values }: SignatureWorkFlowCreateType) => {

    const payload = {
      ...values,
      deadLine: formatDeadLine(deadLine),
      uploadId: uploadSelected?.id,
      targetType,
      targetId,
      signers: getSignersSelectedByPersonId(values.signers)
    } as SignatureWorkFlowCreateType;

    mutateCreateSignatureAsync(payload);
  }

  return (
    <Stack>
      <FormProvider
        validationSchema={validationSchemaSignatureForm()}
        defaultValues={defaultValuesSignatureForm}
        onSubmit={onSubmit}
      >
        <SignatureForm
          onClose={onCloseStep}
          signersList={listInvolvedOptions ?? []}
        />
      </FormProvider>
    </Stack>
  )
}