import { string, object, date, array } from 'yup';

export function validationSchemaCancelForm() {
    return object().shape({
        rejectionReasonCode: array()
            .typeError('Selecione um motivo')
            .min(1, 'Selecione ao menos 1 motivo')
            .required('Selecione um motivo'),
        message: string()
            .typeError('A mensagem precisa ser um texto.')
            .required('Mensagem: precisa ser preenchida.'),
        insertBlock: string().nullable(),
        blockUntil: date().when('insertBlock', {
            is: (insertBlock: string | null) => insertBlock !== null,
            then: date()
                .typeError('Data de Bloqueio precisa ser preenchida')
                .required('Data de Bloqueio: Precisa ser preenchida.'),
            otherwise: date().nullable(),
        }),
        complianceChecklist: array().when('insertBlock', {
            is: (insertBlock: string | null) => insertBlock !== null,
            then: array()
                .typeError('Campos da blacklist precisa ser preenchida')
                .min(1, 'Selecione ao menos 1 campo na blacklist.')
                .required('Campos da blacklist: precisa ser preenchida'),
            otherwise: array().nullable(),
        }),

    });
}

export const defaultValuesCancelForm = {
    message: null,
    insertBlock: null,
    blockUntil: null,
    rejectionReasonCode: [], 
    complianceChecklist: []
};
