export const font = {
  size: {
    xg: '40px',
    xxxl: '36px',
    xxl: '24px',
    xl: '20px',
    lg: '18px',
    md: '17px',
    sm: '16px',
    xs: '14px',
  },
  weight: {
    regular: 400,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  lineHeight: {
    xg: '4.5rem',
    xxxl: '3.5rem',
    xxl: 1,
    xl: 1,
    lg: 1,
    md: '2.5rem',
    sm: '2.5rem',
    xs: '2rem',
  },
};
