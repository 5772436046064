import React, { useState } from 'react';
import { RefreshProgress } from 'components/RefreshProgress';
import Toast from 'components/Toast/Toast';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { useBillingParametersList } from 'contexts/wallet/billingParameters/billingParametersContext';
import ParametersWalletList from './BillingSettingList';
import DrawerBillingSettingFormContainer from './Drawers/DrawerBillingSettingFormContainer';
import { GridRowModel } from '@mui/x-data-grid';
import { FilterProvider } from 'contexts/filterContext';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import BillingSettingHeader from './BillingSettingHeader';

export type ActionOptions = "add" | "delete" | "edit";

export type ActionRowDataType = {
    row: GridRowModel | null;
    action: ActionOptions;
} | undefined

export const BillingSettingListContainer = () => {
    const [actionRowData, setActionRowData] = useState<ActionRowDataType>(undefined);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(
        parseInt(localStorage.getItem('rowsPerPage')!) || 5
    );

    const { data, isFetching, refetch, isLoading } = useBillingParametersList({
        page,
        size: rowsPerPage
    });

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        localStorage.setItem('rowsPerPage', JSON.stringify(page));
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onClose = () => setActionRowData(undefined);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <BillingSettingHeader
                addParameters={() => setActionRowData({ action: "add", row: null })}
                isFetching={isFetching}
                refetch={refetch}
            />
            <RefreshProgress refreshing={isFetching} />
            <FilterProvider
                availableFilters={{
                    name: { label: 'Nome', type: 'text' },
                    walletsCode: { label: 'Código convênio', type: 'text', multiple: true }
                }}
            >
                <ListSkeleton isLoading={isLoading}>
                    <ParametersWalletList
                        queryData={data}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={onChangeRowsPerPage}
                        setPage={onChangePage}
                        page={page}
                        setActionRowData={setActionRowData}
                    />
                </ListSkeleton>
            </FilterProvider>

            <DrawerBillingSettingFormContainer
                actionRowData={actionRowData}
                onClose={onClose}
                refetch={refetch}
                setToast={setToast}
            />
        </>
    );
};
