import { Stack } from '@mui/material';
import {
    CheckboxFormField,
    DatePickerFormField,
    SelectFormField,
    SelectOption,
} from 'components/Forms/FormFields';
import { TGetCosif } from 'contexts/creditProductContext';

type TClosureForm = {
    cosifData?: TGetCosif[];
};

const LiquidationScheduleClosureForm = ({ cosifData }: TClosureForm) => {
    const cosifAccount = cosifData?.filter((i) => !!i.externalAccountId);

    return (
        <Stack spacing={3} my={3}>
            <DatePickerFormField name="paymentDate" label="Data de pagamento" fullWidth required />
            <SelectFormField
                name="cosifAccountCode"
                label="Conta de crédito"
                required
                options={cosifAccount as SelectOption[]}
            />

            <CheckboxFormField
                name="closeTransitoryBankAccount"
                label="Encerrar conta transitória"
            />
        </Stack>
    );
};

export default LiquidationScheduleClosureForm;
