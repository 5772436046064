import { useQueryClient } from '@tanstack/react-query';
import { createContext, useContext } from 'react';

type QueryCacheContextProps = {
    getQueryCache: (queryKey: any[]) => any;
};

interface QueryCacheContextProviderProps {
    children: JSX.Element | JSX.Element[];
}

const QueryCacheContext = createContext<QueryCacheContextProps>({
    getQueryCache: (queryKey: any[]) => { },
});

export const QueryCacheContextProvider = ({ children }: QueryCacheContextProviderProps) => {
    const queryClient = useQueryClient();

    const findDataByQueryKey = (queryKey: any[]) => {
        const cachedQuery = queryClient.getQueryCache().find(queryKey);

        if (cachedQuery && cachedQuery.state.data) {
            return cachedQuery.state.data;
        }
    };

    return (
        <QueryCacheContext.Provider
            value={{
                getQueryCache: findDataByQueryKey,
            }}
        >
            {children}
        </QueryCacheContext.Provider>
    );
};

export const useQueryCacheContext = () => useContext(QueryCacheContext);
