import { Stack } from '@mui/material';
import { Button, ChartWaveIcon, CompleteIcon, LeftIcon } from '@uy3/web-components';
import CardsSelect, { HandleChangeCardsSelectedProps } from 'components/CardSelect/CardSelect';
import { useFormContext } from 'contexts/formContext';
import React from 'react';
import { ISiapeMarginQueryReadModel } from 'services/datasets';
import { activeTheme } from 'services/theme';
import { IZetraMarginQueryReadModel } from 'services/zetra';
import { summarySiape, summaryZetra } from '../Summary';
import { PublicPayrollForm } from 'pages/CreditNote/WarrantyTab';

type SelectWarrantyProps = {
    marginQueryData: IZetraMarginQueryReadModel | ISiapeMarginQueryReadModel | undefined;
    onClose: () => void;
    handleMarginQuery: () => void;
    isZetra: boolean;
};

const color = activeTheme().palette;

export const SelectWarranty: React.FC<SelectWarrantyProps> = ({
    marginQueryData,
    onClose,
    handleMarginQuery,
    isZetra,
}) => {
    const { submitting, setValue, watch } = useFormContext();

    const options = isZetra
        ? summaryZetra(marginQueryData as IZetraMarginQueryReadModel)
        : summarySiape(marginQueryData as ISiapeMarginQueryReadModel);

    const BtnSiapLabel = !!marginQueryData ? 'Atualizar margem' : 'Obter margem';
    const isConfirmButtonDisabled = options?.length === 1 && options?.[0]?.disabled;

    const watchedMargens: string[] = watch('margensOptionsZetra') ?? [];
    const watchedMarginSiape = watch('marginSiape') ?? '';

    const handleOnMargin = ({ value, checked }: HandleChangeCardsSelectedProps) => {
        if (isZetra) {
            if (checked) {
                setValue('margensOptionsZetra', [...watchedMargens, value]);
            } else {
                setValue(
                    'margensOptionsZetra',
                    watchedMargens.filter((item) => item !== value)
                );
            }
        } else {
            setValue('marginSiape', value);
        }
    };

    return (
        <Stack spacing={2}>
            {isZetra && <PublicPayrollForm />}

            <CardsSelect
                onChange={handleOnMargin}
                options={options}
                value={isZetra ? watchedMargens : watchedMarginSiape}
                isCheckbox={isZetra}
            />

            <Stack direction="row" spacing={2} justifyContent="right" alignItems="center">
                <Button
                    variant="outlined"
                    startIcon={<LeftIcon htmlColor={color.primary.main} />}
                    onClick={onClose}
                >
                    Voltar
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<ChartWaveIcon htmlColor={color.primary.main} />}
                    onClick={handleMarginQuery}
                >
                    {BtnSiapLabel}
                </Button>
                <Button
                    variant="contained"
                    disabled={submitting || isConfirmButtonDisabled}
                    startIcon={<CompleteIcon htmlColor={color.common.white} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
