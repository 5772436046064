import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';

export const QontoConnector = styled(StepConnector)(
  ({ theme }) => css`
    & .${stepConnectorClasses.line} {
      border: none;
      border-top-width: 3px;
      border-radius: 1px;
      border-color: ${theme.palette.neutral?.light};
    }

    & .${stepConnectorClasses.root} {
      left: calc(-50% + 40px);
      right: calc(50% + 40px);
    }

    &.${stepConnectorClasses.active} {
      & .${stepConnectorClasses.line} {
        border-color: ${theme.palette.brand?.darkest};
      }
    }

    &.${stepConnectorClasses.completed} {
      & .${stepConnectorClasses.line} {
        border-color: ${theme.palette.brand?.darkest};
      }
    }

    @media (max-width: ${theme.breakpoints.values.md}px) {
      margin-top: 45px;

      & .${stepConnectorClasses.line} {
        border-top-width: 2px;
        border-radius: 1px;
        border-color: ${theme.palette.neutral?.light};
      }

      & .${stepConnectorClasses.root} {
        left: calc(-50% + 30px);
        right: calc(50% + 30px);
      }
    }

    @media (max-width: ${theme.breakpoints.values.sm}px) {
      margin-top: 40px;

      & .${stepConnectorClasses.line} {
        border-top-width: 1px;
        border-radius: 1px;
        border-color: ${theme.palette.neutral?.light};
      }

      & .${stepConnectorClasses.root} {
        left: calc(-50% + 20px);
        right: calc(50% + 20px);
      }
    }
  `
);

export const StepIconRoot = styled('div')<{
  ownerState: {
    completed?: boolean;
    active?: boolean;
    isFinished?: boolean;
  };
  setColor: (color: string) => void;
}>(({ theme, ownerState, setColor }) => {
  const isFinished = ownerState.isFinished!;

  let backgroundColor;
  
  if (isFinished) {
    backgroundColor = "#014B04";
  } else {
    const isActiveOrCompleted = ownerState.active || ownerState.completed;
    backgroundColor = isActiveOrCompleted ? theme.palette.brand?.darkest : theme.palette.neutral?.light;
  }

  setColor(backgroundColor);
  return {
    width: 65,
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    marginLeft: 10,
    top: ownerState.active ? 29 : 0,

    '&::before, &::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor,
      opacity: 0.1,
      borderRadius: '10%',
      zIndex: -1
    },

    '&::after': {
      opacity: 0.1,
      zIndex: 0
    },

    [theme.breakpoints.down('md')]: {
      width: 44,
      height: 46
    },

    [theme.breakpoints.down('sm')]: {
      width: 30,
      height: 32,
      top: ownerState.active ? -2 : 0
    }
  };
});