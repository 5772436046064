import { Button as MuiButton } from '@mui/material';
import {
    DataTable,
    EditIcon,
    ShowIcon,
    DeleteIcon,
    MoreOptionsIcon,
    ReportIcon,
    CompleteIcon,
    Typography,
    PersonIcon,
    CloseIcon,
    UpdateIcon,
} from '@uy3/web-components';
import { iconSx, invalidateAndRefetch } from 'contexts/apiRequestContext';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { EnumItem } from 'contexts/enumContext';
import { useFormContext } from 'contexts/formContext';
import { RowActions } from 'components/RowActions/RowActions';
import { activeTheme } from 'services/theme';
import { isEmpty } from 'lodash';
import StatusSignature from '../DrawerWrapper/Signature/StatusSignature/StatusSignature';
import SignersLength from '../DrawerWrapper/Signature/SignersLength/SignersLength';
import { useLocation } from 'react-router';
import { isSupportedFileType } from 'helpers';
import { useQueryClient } from '@tanstack/react-query';
import { IUploadReadModel } from 'utils/interface';

type DocsProps = {
    uploads: IUploadReadModel[];
    name?: string;
    titleHeader?: string;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (page: number) => void;
    setOpenDrawer?: (type: string, rowData?: GridValidRowModel) => void;
    setSelectedUploadIndex: (rowIndex?: number | undefined) => void;
    page: number;
    onDelete: (rowIndex: number) => void;
    typeOfDocument: EnumItem[];
    statusSignature?: string;
    enableAction?: boolean;
};

const buttonSx = { height: 20, width: 20, ml: 0.5, mr: 2 };
const theme = activeTheme();
const UploadList = ({
    titleHeader = 'Documentos',
    rowsPerPage,
    setRowsPerPage,
    setPage,
    setSelectedUploadIndex,
    page,
    onDelete,
    typeOfDocument,
    uploads,
    name,
    setOpenDrawer,
    statusSignature,
    enableAction,
}: DocsProps) => {
    const { pathname } = useLocation();
    const { watch } = useFormContext();
    const queryClient = useQueryClient();
    const isBatchAssignment = pathname.includes('cessoes');

    // Esta variavel é usada para habilitar ações como envio e cancelamento de assinatura, adição de signatários...
    const isDocBankAccountRequest = titleHeader === 'Documentos da solicitação' || isBatchAssignment;

    const columnsDocumentList = [
        {
            field: 'displayName',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'fileType',
            headerName: 'Tipo de documento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value &&
                            typeOfDocument?.find((file) => file.value === cellValues.value)?.label}
                    </div>
                );
            },
        },
        isDocBankAccountRequest
            ? {
                field: 'signers',
                headerName: 'Signatários',
                hideSortIcons: true,
                minWidth: 150,
                align: 'left',
                headerAlign: 'left',
                flex: 1,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <SignersLength
                            signatureWorkFlowId={cellValues?.row?.signatureWorkFlowId}
                        />
                    );
                },
            }
            : undefined,
        {
            field: 'createdAt',
            headerName: 'Data de criação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1.5,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleString('pt-BR')}
                    </div>
                );
            },
        },
        isDocBankAccountRequest
            ? {
                field: 'signatureStatusDisplay',
                headerName: 'Status da assinatura',
                hideSortIcons: true,
                minWidth: 150,
                flex: 1.5,
                headerAlign: 'center',
                align: 'center',
                editable: false,
                renderCell: (cellValues: any) => {
                    const row = cellValues?.row;
                    const isSignedContract = row?.fileType === 'SignedContract';

                    return (
                        <>
                            {isSignedContract ? (
                                <Typography>-</Typography>
                            ) : (
                                <StatusSignature signatureWorkFlowId={row?.signatureWorkFlowId} />
                            )}
                        </>
                    );
                },
            }
            : undefined,
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 200,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues: any) => {
                const row = cellValues?.row;
                const rowIndex = uploads?.findIndex(
                    (row: IUploadReadModel) => cellValues?.row?.id === row?.id
                );

                const isNotWaitingForStart = statusSignature !== 'Aguardando Início';
                const isRequestSignature = isEmpty(statusSignature);
                const isWaitingForSignature = statusSignature === 'Aguardando Assinatura';
                const hidden = isEmpty(row?.createdAt) || !isDocBankAccountRequest;
                const invalidFileOrMissingCreatedAt = hidden || !isSupportedFileType(row?.fileType);
                const signatureWorkFlowId = cellValues?.row?.signatureWorkFlowId;

                async function signatureRefetch() {
                    await invalidateAndRefetch(queryClient, ['get-signature-workflow-signrs', signatureWorkFlowId]);
                }

                const listActions = [
                    {
                        label: 'Atualizar status',
                        action: signatureRefetch,
                        icon: <UpdateIcon sx={{ ...iconSx, mx: 0.5 }} />,
                        disabled: false,
                        hide: (isEmpty(signatureWorkFlowId) && !enableAction) || isRequestSignature,
                    },
                    {
                        label: 'Editar',
                        action: () => {
                            setSelectedUploadIndex(rowIndex);
                            setOpenDrawer && setOpenDrawer('edit', cellValues?.row);
                        },
                        icon: <EditIcon sx={buttonSx} />,
                        disabled:
                            titleHeader !== 'Documentos da operação' && pathname.includes('ccb'),
                    },
                    {
                        label: 'Ver detalhes',
                        action: () => setOpenDrawer && setOpenDrawer('viewDetails', row),
                        icon: <ReportIcon sx={buttonSx} />,
                        disabled: titleHeader.includes(name!),
                        hide: hidden,
                    },
                    {
                        label: 'Excluir',
                        action: () => onDelete(rowIndex),
                        icon: <DeleteIcon sx={buttonSx} />,
                        disabled: titleHeader.includes(name!),
                    },
                    {
                        label: 'Solicitar assinatura',
                        action: () => setOpenDrawer && setOpenDrawer('requestSignature', row),
                        icon: <CompleteIcon sx={buttonSx} />,
                        disabled: false,
                        hide: invalidFileOrMissingCreatedAt || !isRequestSignature,
                    },
                    {
                        label: 'Adicionar Signatarios',
                        action: () => setOpenDrawer && setOpenDrawer('signature', row),
                        icon: <PersonIcon sx={buttonSx} />,
                        disabled: false,
                        hide: hidden || isNotWaitingForStart,
                    },
                    {
                        label: 'Cancelar assinatura',
                        action: () => setOpenDrawer && setOpenDrawer('cancelSignature', row),
                        icon: <CloseIcon sx={buttonSx} />,
                        disabled: false,
                        hide: hidden || !isWaitingForSignature,
                    },
                ];

                return (
                    <>
                        <MuiButton
                            sx={{ ml: 0, mr: -2 }}
                            variant="text"
                            color="primary"
                            size="small"
                            startIcon={<ShowIcon sx={buttonSx} />}
                            href={cellValues.row.tempGetUrl}
                            target="_blank"
                        />
                        {watch('statusDisplay') !== 'Encerrado' && enableAction === true && (
                            <>
                                <RowActions
                                    icon={
                                        <MoreOptionsIcon
                                            sx={iconSx}
                                            htmlColor={theme.palette.common.black}
                                            onClick={(event) =>
                                                setOpenDrawer && setOpenDrawer('moreOptions', row)
                                            }
                                        />
                                    }
                                    listButtons={listActions}
                                />
                            </>
                        )}
                    </>
                );
            },
        },
    ].filter(Boolean);

    return (
        <>
            <DataTable
                headerBarWrapper={{
                    header: pathname.includes('solicitacoes') ? titleHeader : ""
                }}
                NoRowsOverlayNew="Nenhum documento encontrado"
                NoResultsOverlayNew="Nenhum documento encontrado"
                getRowId={(row) => row.id ?? row.fileName ?? row.displayName}
                columns={columnsDocumentList as GridColDef[]}
                rows={uploads}
                page={page}
                rowCount={5}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
};

export default UploadList;
