import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { ConciliacaoLiquidacaoFormContainer } from './ConciliacaoLiquidacaoForm/ConciliacaoLiquidacaoFormContainer';
import { useConciliacaoMutation } from 'contexts/Vortx/ConciliacaoLiquidacaoCaixaContext';
import { ApiResponseError, ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { FieldValues } from 'react-hook-form';
import { RefreshProgress } from 'components/RefreshProgress';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesConcilicaoLiquidacao, validationSchemaConcilicaoLiquidacao } from './ConciliacaoLiquidacaoForm/ConciliacaoLiquidacaoFormSchema';
import { IResponseQueryConcilicao } from 'services/remessasVortx/remessasVortx.type';
import { isEmpty } from 'lodash';

export const ConciliacaoLiquidacaoCaixa: React.FC = () => {
    const [queryResponse, setQueryResponse] = useState<IResponseQueryConcilicao | null>(null);
    const [toast, setToast] = useState<ToastType>(toastState);

    const onSuccess = (response: IResponseQueryConcilicao) => {
        setQueryResponse(response);
        return setToast({
            open: true,
            severity: 'success',
            title: "Conciliação ao realizar com sucesso"
        });
    }

    const onError = (responseError: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(responseError);
        return setToast({
            open: true,
            severity: 'error',
            title: "Ops, ocorreu um erro",
            description: errorMessage
        })
    }

    const validateDataGarantia = (value: string) => {
        if (!isEmpty(value)) {
            return new Date(value).toLocaleDateString()?.replaceAll('/', '-')
        }

        return null;
    }

    const handleSubmit = async (data: FieldValues) => {
        const file = data?.file as File;
        const dataRepasse = data?.dataRepasse as string;

        if (file === undefined || file === null) {
            return setToast({
                open: true,
                severity: 'info',
                title: 'Informe o caminho do arquivo'
            })
        };

        await conciliacaoMutateAsync({
            params: {
                persist: data?.persist,
                queryCaixa: data?.queryCaixa
            },
            payload: {
                dataGarantia: validateDataGarantia(data?.dataGarantia),
                dataRepasse: dataRepasse.slice(0, dataRepasse.indexOf('T')).replaceAll('-', ''),
                file
            }
        });
    }

    const { mutateAsync: conciliacaoMutateAsync, isLoading } = useConciliacaoMutation(onSuccess, onError);

    return (
        <Stack>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isLoading} />

            <FormProvider
                defaultValues={defaultValuesConcilicaoLiquidacao}
                validationSchema={validationSchemaConcilicaoLiquidacao()}
                onSubmit={handleSubmit}
                readOnly={isLoading}
            >
                <ConciliacaoLiquidacaoFormContainer
                    isLoading={isLoading}
                    queryResponse={queryResponse}
                />
            </FormProvider>
        </Stack>
    );
}