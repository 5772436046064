import styled from '@emotion/styled'


export const CustomInput = styled.input`
padding: 14px;
justify-content: center;
align-items: center;
text-align: center;
gap: 16px;
border: 1px solid #BABFD0;
background: #FFF;

&:focus {
    outline: none;
    border: 1px solid #BABFD0;
    box-shadow: 0px 0px 4px rgba(186, 191, 208, 1);
}

&:hover {
    border: 1px solid #BABFD0;
    box-shadow: 0px 0px 4px rgba(186, 191, 208, 1);
}

`;