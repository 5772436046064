import { Drawer } from '@uy3/web-components';
import { DiscriminatorType } from 'components/GenericForms/AddPayer/AddPayerFormContainer';
import { DescriminatorForm } from 'components/GenericForms/AddPayer/StepsForm/DescriminatorForm/DescriminatorForm';
import React, { useState } from 'react'
import { createBeneficiarySchema, defaultValuesBeneficiary } from './BankAccountFavoredFormSchema';
import { FormProvider } from 'contexts/formContext';
import { BankAccountFavoredForm } from './BankAccountFavoredForm';
import { IQueryBasicData } from 'contexts/personContext';
import { FieldValues } from 'react-hook-form';
import { Stack } from '@mui/material';

export type BankAccountFavoredFormContainerProps = {
    onClose: () => void;
    getQueryBasicDataPerson: (registrationNumber: string, onSuccess: (values: IQueryBasicData) => void) => Promise<void>;
    createFavoredMethod: (formValues: FieldValues) => Promise<void>
}

export const BankAccountFavoredFormContainer: React.FC<BankAccountFavoredFormContainerProps> = (props) => {
    const { onClose, createFavoredMethod } = props; 

    const [discriminator, setDiscriminator] = useState<DiscriminatorType>(undefined);

    return (
        <Stack spacing={2}>
            <DescriminatorForm {...{
                onNextStep: (value) => setDiscriminator(value),
                onClose,
                discriminator,
                resource: 'createFavored'
            }} />

            <Drawer
                anchor='right'
                title={`Adicionar favorecido | ${discriminator === 'LegalPerson' ? 'Pessoa Jurídica' : 'Pessoa Física'}`}
                open={discriminator !== undefined}
                onClose={() => setDiscriminator(undefined)}
                toggleDrawer
            >
                <FormProvider
                    validationSchema={createBeneficiarySchema()}
                    defaultValues={defaultValuesBeneficiary}
                    onSubmit={createFavoredMethod}
                >
                    <BankAccountFavoredForm {...{
                        ...props, 
                        discriminator, 
                        onClose: () => setDiscriminator(undefined)
                    }} />
                </FormProvider>
            </Drawer>
        </Stack>
    )
}
