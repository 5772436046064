import { useState } from 'react';
import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { AddresExtendedDatasetGet } from 'services/datasets/datasets.interface';
import DatasetWrapper from './DatasetWrapper';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { formatCEP } from 'helpers/formats/DocumentCepFormated';

const type = (type: string) => {
    switch (type) {
        case 'WORK':
            return 'Trabalho';
        case 'HOME':
            return 'Casa';
        case 'OFFICIAL REGISTRATION':
            return 'Registro oficial';
        default:
            return '-';
    }
};

interface IAddressesExtendedProps {
    recordType: string;
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function AddressesExtended({ recordType, datasetId, disableRequestDataSet }: IAddressesExtendedProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    let datasetname =
        recordType === 'NaturalPerson' ? 'AddressesPersonal' : 'AddressesLegalPersonal';
    const { dataset, status, refetch } = useDatasetData(recordType, id!, datasetname);
    const { setSubmitError } = useApiRequest();


    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Endereço${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) =>
            dataset?.dataSet ===
            (recordType === 'NaturalPerson' ? 'addresses_personal' : 'addresses_legalpersonal')
    ) as AddresExtendedDatasetGet;
    const address = data?.result?.addresses;

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'type',
            headerName: 'Tipo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && type(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'typology',
            headerName: 'Logradouro',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {`${cellValues.value} ${cellValues.row.addressMain}`}
                    </div>
                );
            },
        },
        {
            field: 'number',
            headerName: 'Número',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'neighborhood',
            headerName: 'Bairro',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'zipCode',
            headerName: 'CEP',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && formatCEP(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'city',
            headerName: 'Cidade',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'state',
            headerName: 'Estado',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
    ];


    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {address && (
                <DataTable
                    loading={isLoading || status === 'loading'}
                    getRowId={(row) => row.number}
                    columns={columns}
                    rows={address ?? []}
                    page={page}
                    rowCount={address?.length ?? 5}
                    rowsPerPage={rowsPerPage}
                    setPage={onChangePage}
                    setRowsPerPage={onChangeRowsPerPage}
                    paginationMode="client"
                />
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData(datasetname)}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};