import { styled } from '@mui/material/styles';
import { DialogContent, Dialog } from '@mui/material';
import { Typography } from '../../atoms';

export const StyledDialog = styled(Dialog)(() => ({
  '& .MuiDialog-paper': {
    borderRadius: '8px',
    boxShadow: '0px 8px 50px rgba(0, 0, 0, 0.27)',
  }
}))
export const StyledDialogContent = styled(DialogContent)(({theme}) => ({
  padding: '24px 48px',
  display: 'flex',
  flexDirection: 'column',
  [theme.breakpoints.down('sm')]: {
    padding: '24px 24px',
  }
}))
export const Title = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  'h1,h2,h3,h4,h5, p': {
    margin: 0
  },
  '& strong': {
    color: theme.palette.primary.main,
  }
}))
export const Description = styled(Typography)(({ theme }) => ({
  marginBottom: '16px',
  'h1,h2,h3,h4,h5, p': {
    margin: 0
  },
  '& strong': {
    color: theme.palette.primary.main,
  }
}))