import { ApiResponseError, showWarningToast, ToastType } from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { isEmpty } from 'lodash';
import { Dispatch, SetStateAction } from 'react';
import * as creditNoteAPI from 'services/creditNote';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { isFinishedAndUnlinked } from 'services/LiquidationSchedule';

const getCreditNoteById = async (id: string, token: string) => {
    try {
        const { data } = await creditNoteAPI.getCreditNoteById(id, token);
        return data;
    } catch (err) {
        throw new Error(mapErrorResponse(err as ApiResponseError)?.errorMessage);
    }
};

// Função para duplicar a nota de crédito
const duplicateCreditNote = async (creditNoteData: CreditNoteReadModel, token: string, tenantId: string | undefined) => {
    try {
        const liquidationSchedule = creditNoteData?.liquidationSchedule?.filter(
            (i) => (isFinishedAndUnlinked(i) || i?.linkStatus === 'Linked') && i?.type === "LiquidationSchedule"
        );

        const payload = {
            ...creditNoteData,
            liquidationSchedule
        };

        const { data } = await creditNoteAPI.duplicateCreditNote(payload, token, tenantId);
        return data;
    } catch (err) {
        throw err;
    }
};

// Função principal de clonagem
export const cloneCreditNoteAsync = async (
    id: string,
    token: string,
    currentTenantId: string | undefined,
    navigateWithFilters: Function,
    setToast: Dispatch<SetStateAction<ToastType>>,
    handleErrorResponse: Function
) => {
    try {
        const creditNoteData = await getCreditNoteById(id, token);
        const duplicatedCreditNoteId = await duplicateCreditNote(creditNoteData, token, currentTenantId);
        navigateWithFilters(`/ccb/operacoes/${duplicatedCreditNoteId}`);
    } catch (err) {
        const { errorMessage, warningToastError, fieldErrors } = mapErrorResponse(err as ApiResponseError);
        const hasErrorAprField = fieldErrors?.every((x) => x?.key === 'amortization.apr');
        const reasonMessage = hasErrorAprField && fieldErrors?.length === 1 ? fieldErrors?.[0]?.helpMessage : warningToastError || errorMessage;

        if (!isEmpty(reasonMessage)) return showWarningToast('Atenção!', reasonMessage, setToast);

        return handleErrorResponse(err);
    }
};
