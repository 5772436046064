import React, { Fragment } from 'react';
import { CheckboxWithTooltip } from '../HelpersTooltip/CheckboxWithTooltip';
import { ActionsPermissions } from 'components/ActionsPermissions/ActionsPermissions';

export const BankAccountRequestActions = () => {
    return (
        <ActionsPermissions
            PermissionRootTenancy={<PermissionResourceBankAccountRequestRootTenancy />}
        />
    );
};

const PermissionResourceBankAccountRequestRootTenancy = () => {
    return (
        <Fragment>
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar Compliance',
                    name: 'actions.ComplianceApproval',
                }}
                helpMessage="Permite aprovar o compliance de uma solicitação de conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Aprovar Instrumento',
                    name: 'actions.InstrumentApproval',
                }}
                helpMessage="Permite aprovar um instrumento de uma solicitação de conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Realizar abertura de conta bancária',
                    name: 'actions.OpenBankAccount',
                }}
                helpMessage="Permite o usuário realizar a abertura de uma conta bancária."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Salvar documentos',
                    name: 'actions.UploadDocs',
                }}
                helpMessage="Permite salvar os documentos da solicitação de conta."
            />
            <CheckboxWithTooltip
                checkboxFormField={{
                    label: 'Validação de assinaturas',
                    name: 'actions.SignatureValidation',
                }}
                helpMessage="Permite validar registros que estão em validação de assinaturas."
            />
        </Fragment>
    )
}
