import { Grid, IconButton } from '@mui/material';
import { Stack } from '@mui/system';
import { GridCallbackDetails, GridColDef, GridRenderCellParams, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import {
    DataTable,
    RejectIcon,
    ApproveIcon,
    DeleteIcon,
    SearchIcon,
    CancelIcon
} from '@uy3/web-components';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import { formatBankAccountForString } from 'helpers/formats/BankAccount';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

interface FavoredTabProps {
    setRowsPerPage: (page: number) => void;
    setStepFavored: React.Dispatch<React.SetStateAction<string | undefined>>;
    setUserId: (rowData: any) => void;
    setPage: (newPage: number) => void;
    setFavoredInfo: (rowData: any) => void;
    queryData: any;
    rowsPerPage: number;
    page: number;
    rowsSelected: GridRowId[];
    handleSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
}

export const FavoredTab = ({
    page,
    setPage,
    setUserId,
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setFavoredInfo,
    setStepFavored,
    handleSelectionModelChange, 
    rowsSelected
}: FavoredTabProps) => {

    const handleFavoredAction = (row: GridRenderCellParams, actionType?: string) => {
        if (!!actionType) setStepFavored(actionType);
        setUserId(row);
    };

    const columnsFavoredList: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF/CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return formatDocumentNumber(cellValues.value);
            },
        },
        {
            field: 'agency',
            headerName: 'Dados da conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 3,
            editable: false,
            renderCell: ({ row }) => formatBankAccountForString(row)
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                const isPedingApprove = row?.statusDisplay === "Aguardando aprovação";
                const hasItemsSelected = rowsSelected?.length > 0;
                return (
                    <Stack
                        component={Grid}
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton onClick={() => setFavoredInfo(row)}>
                            <SearchIcon sx={iconSx} />
                        </IconButton>
                        {!isPedingApprove &&
                            <IconButton onClick={() => handleFavoredAction(row.id, 'confirmMfaDelete')}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>
                        }
                        {
                            isPedingApprove ?
                                <RowActions listButtons={[
                                    {
                                        action: () => handleFavoredAction(row.id, 'confirmMfaApprove'),
                                        disabled: !isPedingApprove || hasItemsSelected,
                                        icon: <ApproveIcon />,
                                        label: "Aprovar"
                                    },
                                    {
                                        action: () => handleFavoredAction(row.id, 'confirmMfaReject'),
                                        disabled: !isPedingApprove || hasItemsSelected,
                                        icon: <RejectIcon />,
                                        label: "Rejeitar"
                                    },
                                    {
                                        action: () => handleFavoredAction(row.id, 'confirmMfaDelete'),
                                        disabled: !isPedingApprove || hasItemsSelected,
                                        icon: <DeleteIcon />,
                                        label: "Excluir"
                                    },
                                    {
                                        action: () => handleFavoredAction(row.id, 'confirmMfaCancel'),
                                        disabled: !isPedingApprove || hasItemsSelected,
                                        icon: <CancelIcon />,
                                        label: "Cancelar"
                                    }
                                ]} />
                                :
                                <></>
                        }
                    </Stack>
                )
            },
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <BankAccountFiltersContainer typeFilter="favored" />,
            }}
            columns={columnsFavoredList}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            checkboxSelection={true}
            keepNonExistentRowsSelected
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleSelectionModelChange}
        />
    );
};
