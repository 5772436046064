import { FormProvider } from 'contexts/formContext';
import { BankAccountRequestFilters } from './BankAccountRequestFilters';
import { defaultValuesFundtFilterSchema, fundtFiltersSchema } from './BankAccountRequestFiltersSchema';
import { useFilters } from 'contexts/filterContext';
import { Toast } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { useState } from 'react';
import moment from 'moment';


export const BankAccountRequestFiltersContainer = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams?.getAll('status');
    const [existsStatusFilter, setExistsStatusFilter] = useState(false);

    const onSubmitSearch = (values: any) => {
        if (values.optionFilter === "initialDate") {
            const initialDate = moment(values[values.optionFilter]).set({hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate().toISOString();
            const finalDate = moment(values["finalDate"]).set({hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate().toISOString(); 
            addFilter(values.optionFilter, initialDate, new Date(finalDate));
        } else if (values.optionFilter === 'status') {
            if (status && status.includes(values[values?.optionFilter])) {
                setExistsStatusFilter(true);
                return;
            }
            addFilter(values?.optionFilter, values[values?.optionFilter]);
            setExistsStatusFilter(false);
        } else {
            addFilter(values.optionFilter, values[values.optionFilter]);
        }
    };

    const schema = fundtFiltersSchema();
    const defaultValues = defaultValuesFundtFilterSchema;

    return (
        <>
            <Grid container item>
                <Toast
                    severity="info"
                    title="O filtro já está aplicado na listagem abaixo."
                    onClose={() => setExistsStatusFilter(false)}
                    open={existsStatusFilter}
                />
            </Grid>
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmitSearch}
            >
                <BankAccountRequestFilters
                    currentValues={filters}
                    removeFilter={removeFilterByIndex}
                />
            </FormProvider>
        </>
    )
}
