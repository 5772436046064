/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { font } from 'services/theme/typography';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import ViewTransactionalLimitsDrawer from '../../ViewTransactionalLimitsDrawer';
import CardsSelect from 'components/CardSelect/CardSelect';
import moment from 'moment';
import { Grid, IconButton, Stack } from '@mui/material';
import {
    Alert,
    Avatar,
    Button,
    CloseIcon,
    EditIcon,
    PersonIcon,
    TransferIcon,
    Typography,
    UY3Icon,
} from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { DatePickerFormField } from 'components/Forms/FormFields';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { CreateBeneficiaryType } from 'contexts/bankAccount/bankAccountType';
import ButtonCustom from '../../ButtonCustom';
import { isEmpty } from 'lodash';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import { iconSx } from 'contexts/apiRequestContext';

type CreateTransferProps = {
    bankAccountAutocomplete: AutocompleteOptionList;
    onCreateBeneficiary: () => void;
    paymentPurposeList: AutocompleteOptionList;
    isCreatingBeneficiary: boolean;
    formValues: CreateBeneficiaryType | null;
    selectExistingPerson: () => void;
    onEdit: () => void;
    onClose: () => void;
    setBeneficiaryId: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const theme = activeTheme();
export const CreateTransfer: React.FC<CreateTransferProps> = ({
    bankAccountAutocomplete,
    onCreateBeneficiary,
    formValues,
    setBeneficiaryId,
    selectExistingPerson,
    isCreatingBeneficiary,
    paymentPurposeList,
    onEdit,
    onClose
}) => {
    const { watch, setValue } = useFormContext();
    const bankAccountBeneficiaryId = watch('bankAccountBeneficiaryId');

    useMemo(() => {
        const isBeneficiaryValid = !isEmpty(bankAccountBeneficiaryId) && bankAccountBeneficiaryId !== 'create';

        if (isBeneficiaryValid) {
            setBeneficiaryId(bankAccountBeneficiaryId);
        }
    }, [bankAccountBeneficiaryId]);

    useMemo(() => {
        if (isCreatingBeneficiary === true) {
            setValue('bankAccountBeneficiaryId', 'create');
        }
    }, [isCreatingBeneficiary]);

    const showInformation = () => {
        if (Object.keys(formValues ?? {}).length > 0 || !isEmpty(bankAccountBeneficiaryId))
            return true;
        return false;
    };

    const transferDateValue = () => {
        var check = watch('transferDate') === moment().format('yyyy-MM-DD');
        return check ? 'today' : 'otherDay';
    };

    const resetValues = () => {
        selectExistingPerson();
        setValue('bankAccountBeneficiaryId', null);
        setValue('bankAccountBeneficiaryIdDisplay', null);
    };

    function getTEDInformationAtHour() {
        const timeZone = 'America/Sao_Paulo';
        const hourFormatter = new Intl.DateTimeFormat('pt-BR', {
            timeZone,
            hour: '2-digit',
            hour12: false
        });
        const currentDate = new Date();
        const formatedDate = hourFormatter.format(currentDate);
        const parseIntHour = parseInt(formatedDate);
        if (parseIntHour < 9) return true;
        return false;
    }

    return (
        <Stack spacing={2}>
            {!isCreatingBeneficiary && (
                <AutocompleteField
                    name="bankAccountBeneficiaryId"
                    label="Selecione um favorecido"
                    displayName="bankAccountBeneficiaryIdDisplay"
                    {...bankAccountAutocomplete}
                />
            )}

            <ButtonCustom
                name="btn-create-favorite"
                Icon={
                    <PersonIcon
                        htmlColor={theme.palette.primary.main}
                        sx={{ verticalAlign: 'text-bottom', fontSize: font.size.sm }}
                    />
                }
                title={
                    isCreatingBeneficiary ? 'Selecionar um favorecido existente' : 'Adicionar favorecido'
                }
                onClick={isCreatingBeneficiary ? resetValues : onCreateBeneficiary}
            />

            {showInformation() && (
                <>
                    <Stack
                        sx={{
                            width: '100%',
                            border: '1px solid #BABFD0',
                            position: 'relative',
                            display: 'grid',
                            gridTemplateColumns: 'auto auto',
                            flexWrap: 'wrap',
                            borderRadius: 4,
                            padding: 3,
                        }}
                    >
                        {isCreatingBeneficiary && (
                            <IconButton
                                onClick={onEdit}
                                sx={{ position: 'absolute', top: 0, right: 0 }}
                            >
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        )}
                        <Grid sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Avatar />
                            <Grid>
                                <Typography variant="h5" fontWeight={700}>
                                    {formValues?.name}
                                </Typography>
                                <Typography variant="sm">
                                    {formatBankAccountManagePixKey(
                                        formValues?.pixKeyTypeValueDisplay!,
                                        formValues?.keyPix!
                                    )}
                                </Typography>
                            </Grid>
                        </Grid>
                        {!!formValues?.bankDisplay && (
                            <Grid
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                    textAlign: 'left',
                                }}
                            >
                                {formValues?.bank === 457 && formValues?.bank === 457 && (
                                    <UY3Icon sx={{ width: '64px', height: '64px' }} />
                                )}
                                <Grid>
                                    <Typography variant="h5" fontWeight={700}>
                                        {formValues?.bankDisplay}
                                    </Typography>
                                    <Grid>
                                        <Typography variant="sm">{`Agência: ${formValues?.agency}`}</Typography>
                                        <br />
                                        <Typography variant="sm">{`Conta: ${formValues?.accountNumber ?? formValues?.account
                                            }`}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Stack>

                    <Typography variant="h5" fontWeight={700}>
                        Finalidade
                    </Typography>

                    <AutocompleteField
                        name="paymentPurpose"
                        label=""
                        displayName="paymentPurposeDisplay"
                        {...paymentPurposeList}
                    />

                    <Typography variant="h5" fontWeight={700}>
                        Valor e tipo de transferência
                    </Typography>

                    <Grid item xs={4}>
                        <CurrencyFormField fullWidth name="amount" variant="outlined" />
                    </Grid>

                    <Stack direction="row" justifyContent="right">
                        <ViewTransactionalLimitsDrawer />
                    </Stack>

                    <Typography variant="h5" fontWeight={700}>
                        Data transferência
                    </Typography>

                    <CardsSelect
                        onChange={({ value, displayName }) => {
                            setValue("transferDateSelected", value)

                            if (value === 'otherDay') {
                                const transferDate = moment().add(1, 'day').format('yyyy-MM-DD')
                                setValue('transferDate', transferDate);
                            }

                            if (value === 'today') {
                                setValue('transferDate', moment().format('yyyy-MM-DD'));
                            }
                        }}
                        options={[
                            { label: `Hoje ${moment().format('L')}`, value: 'today' },
                            { label: 'Agendar para', value: 'otherDay' },
                        ]}
                        value={transferDateValue()}
                    />

                    {transferDateValue() === 'otherDay' && (
                        <Grid item xs={4}>
                            <DatePickerFormField
                                label="Data da Transferência"
                                disablePast
                                name="transferDate"
                                fullWidth
                                shrink={true}
                                required
                            />
                        </Grid>
                    )}

                    {getTEDInformationAtHour() &&
                        <Alert
                            severity='info'
                            text='Transferência via TED serão processadas à partir das 09:00h'
                        />
                    }

                    <Grid
                        py={2}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography variant="h5" fontWeight={700}>
                            Valor da transferência
                        </Typography>
                        <Typography
                            variant="h4"
                            fontWeight={700}
                            color={theme.palette.primary.main}
                        >
                            {formatCurrencyInCents(watch('amount'))}
                        </Typography>
                    </Grid>
                </>
            )}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name='btn-transfer'
                    startIcon={
                        <TransferIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    type="submit"
                >
                    Transferir
                </Button>
            </Stack>
        </Stack>
    );
};
