import { Popup } from '@uy3/web-components';
import React from 'react';
import CreditNoteApprove from '../CreditNoteApprove/CreditNoteApprove';
import { ToastType } from 'contexts/apiRequestContext';
import CreditNoteRejectContainer from '../CreditNoteReject/CreditNoteRejectContainer';
import { GridRowId } from '@mui/x-data-grid';

interface PopupContainerProps {
    onClosePopup: () => void;
    refetch: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    getCreditNoteOpSelected: any;
    rowAction: rowActionType | undefined;
    rowsSelected: GridRowId[];
    openDrawer?: string;
}

type rowActionType = {
    action: string;
    rowData: any;
};

const PopupContainer: React.FC<PopupContainerProps> = ({
    onClosePopup,
    getCreditNoteOpSelected,
    refetch,
    rowAction,
    rowsSelected,
    setToast,
    openDrawer,
}) => {
    
    const approveCreditChildren = (
        <CreditNoteApprove
            setToast={setToast}
            rowsSelected={rowsSelected}
            rowAction={rowAction}
            refetch={refetch}
            getCreditNoteOpSelected={getCreditNoteOpSelected}
            onClose={onClosePopup}
        />
    );
    const rejectCreditChildren = (
        <CreditNoteRejectContainer
            creditNoteId={rowAction?.rowData?.creditNoteOp?.id}
            selectedPerson={getCreditNoteOpSelected}
            onClose={onClosePopup}
            setToast={setToast}
            onlyOneCreditNote={!!rowAction}
        />
    );

    const isApprove = rowAction?.action === 'approve' || openDrawer === 'approveCreditNoteOp';
    const isReject = rowAction?.action === 'reject' || openDrawer === 'rejectCreditNoteOp';

    const openPoup = !!isApprove || !!isReject;

    const title = isApprove ? 'Aprovar operação' : 'Reprovar operação';
    const text = isApprove
        ? 'Tem certeza que deseja aprovar essa operação?'
        : 'Tem certeza que deseja reprovar essa operação?';

    const children = isApprove ? approveCreditChildren : rejectCreditChildren;

    return (
        <>
            {openPoup && (
                <Popup
                    open={openPoup}
                    title={title}
                    text={text}
                    onClose={onClosePopup}
                    children={children}
                />
            )}
        </>
    );
};

export default PopupContainer;
