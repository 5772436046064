import { useState } from 'react';
import { Button, DataTable, SearchIcon } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { PartnersLawsuitsResult } from 'services/datasets/datasets.interface';
import { Grid, Stack } from '@mui/material';
import { HandleTitle } from 'components/Datasets/DatasetNaturalPerson/TypographyAndValues';

interface IPartnersLawsuitsListProps {
    result: PartnersLawsuitsResult;
    setRowData: (rowData: any) => void;
    setModal: (modal: boolean) => void;
    isLoading: boolean;
}

function PartnersLawsuitsList({
    result,
    setRowData,
    setModal,
    isLoading,
}: IPartnersLawsuitsListProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'mainSubject',
            headerName: 'Assunto',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? cellValues.value : 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'number',
            headerName: 'Número',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'courtName',
            headerName: 'Tribunal',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => {
                                setRowData(cellValues.row);
                                setModal(true);
                            }}
                            startIcon={<SearchIcon sx={{ height: 20, width: 20, ml: 1 }} />}
                        />
                    </Stack>
                );
            },
        },
    ];

    var asAuthor = result?.totalLawsuitsAsAuthor;
    var asDef = result?.totalLawsuitsAsDefendant;
    var asOther = result?.totalLawsuitsAsOther;

    return (
        <>
            <HandleTitle
                title={'Total de processos como autor'}
                value={asAuthor ?? 'N/D'}
                key="totalLawsuitsAsAuthor"
            />
            <HandleTitle
                title={'Total de processos como réu'}
                value={asOther ?? 'N/D'}
                key="totalLawsuitsAsDefendant"
            />
            <HandleTitle
                title={'Total de processos como outros'}
                value={asDef ?? 'N/D'}
                key="totalProtests"
            />
            <DataTable
                loading={isLoading}
                getRowId={() => Math.random().toString()}
                columns={columns}
                rows={result?.lawsuits ?? []}
                page={page}
                rowCount={result?.lawsuits?.length ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
        </>
    );
}

export default PartnersLawsuitsList;
