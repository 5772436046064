import React from 'react';
import { SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import { Stack, useMediaQuery } from '@mui/material';
import { activeTheme } from 'services/theme';
import { Button, CloseIcon, CompleteIcon, UploadIcon } from '@uy3/web-components';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';

interface MultipleUploadFormProps {
    onClose?: () => void;
    shouldDisplayButtons?: boolean;
    options: SelectOption[];
    isSendSignature: boolean;
    startSignature?: () => void;
}

const theme = activeTheme();
const UploadForm: React.FC<MultipleUploadFormProps> = ({ ...props }) => {
    const { watch } = useFormContext();
    const { onClose, shouldDisplayButtons = true, options, isSendSignature, startSignature } = props;
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const typeOfDocumentsOptions = sortToSelectOptionList(options);
    const isEditForm = !!watch('id');
    const displayName =  watch('displayName'); 
    return (
        <Stack spacing={4} pt={1}>
            <TextFormField
                name="displayName"
                label="Nome de exibição"
                variant="outlined"
                fullWidth
                InputLabelProps={{
                    shrink: !!displayName
                }}
                required
            />
            <SelectFormField
                name="fileType"
                label="Tipo de documento"
                variant="outlined"
                options={typeOfDocumentsOptions}
                fullWidth
                required
            />

            {shouldDisplayButtons && (
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    alignItems="center"
                    justifyContent="right"
                    spacing={2}
                >
                    <Button
                        startIcon={
                            <CloseIcon
                                htmlColor={theme.palette.primary.main}
                                sx={iconSx}
                            />
                        }
                        variant="outlined"
                        size="medium"
                        onClick={onClose}
                        fullWidth={isMobile}
                    >
                        Fechar
                    </Button>
                    {isSendSignature && (
                        <Button
                            variant='outlined'
                            onClick={() => startSignature && startSignature()}
                            startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                        >
                            Solicitar assinatura
                        </Button>
                    )}
                    <Button
                        name="btn-submit-upload"
                        type="submit"
                        variant="contained"
                        fullWidth={isMobile}
                        startIcon={
                            <UploadIcon
                                htmlColor={theme.palette.common.white}
                                sx={iconSx}
                            />
                        }
                        sx={{ justifyContent: 'center' }}
                    >
                        {isEditForm ? 'Salvar documento' : 'Adicionar documento'}
                    </Button>
                </Stack>
            )}
        </Stack>
    );
};

export default UploadForm;
