import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, SearchIcon } from '@uy3/web-components';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { Button, Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';
import { ParcelsBatchFilterContainer } from 'components/DataTableFilters/ParcelsBatchAssignment/ParcelsBatchFilterContainer';

type ParcelsBatchAssignmentProps = {
    queryData: any;
    rowsPerPage: number;
    page: number;
    setPage: (page: number) => void;
    setRowsPerPage: (page: number) => void;
};

const ParcelsBatchAssignmentList: React.FC<ParcelsBatchAssignmentProps> = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowsPerPage,
}) => {
    const columnsBatchAssignmentList = [
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => `#${cellValues.value ?? ''}`,
        },
        {
            field: 'dueDate',
            headerName: 'Data de vencimento',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'payment',
            headerName: 'Valor da Parcela',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'assignmentValue',
            headerName: 'Valor da Cessão',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'principalAmountInCents',
            headerName: 'Saldo devedor',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'accruedInterest',
            headerName: 'Juros apropriados',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'assignmentCalculationResult',
            headerName: 'Ágio',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={`/ccb/operacoes/${cellValues.row?.creditNoteOpId}`}>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={<SearchIcon sx={iconSx} />}
                            />
                        </Link>
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsBatchAssignmentList as GridColDef[];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: (
                    <Grid mb={-0.5}>
                        <ParcelsBatchFilterContainer />
                    </Grid>
                ),
            }}
            columns={columns}
            rows={queryData.data}
            page={page}
            rowCount={queryData?.totalItems}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default ParcelsBatchAssignmentList;
