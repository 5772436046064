import { Stack } from "@mui/system";
import { Button, Typography } from "@uy3/web-components";
import { Link } from "react-router-dom";
import { TextFormField } from "components/Forms/FormFields/TextFormField/TextFormField";
import { useFormContext } from "contexts/formContext";

type RecoverPasswordFormProps = {
	codeSent: boolean,
	goBack: () => void,
}

export function RecoverPasswordForm({ codeSent, goBack }: RecoverPasswordFormProps) {

	const { submitting } = useFormContext();

	return <>
		<Typography
			variant="xl"
			color="neutral.dark"
			lineHeight="34px"
			mb={2}
			fontWeight={400}
		>
			Recuperar senha
		</Typography>
		<Stack direction="column" spacing={2} mb={2}>
			{codeSent ?
				(<>
					<Typography variant="xs">
						Um código foi enviado por e-mail. Digite no campo abaixo e clica em Acessar para confirmar sua conta. <br />
					</Typography>
					<TextFormField key="code" name="code" type="text" label="Código de confirmação" variant="outlined" fullWidth required />
					<TextFormField key="newPassword" name="newPassword" type="password" label="Nova senha" variant="outlined" fullWidth required />
					<TextFormField key="passwordConfirmation" name="passwordConfirmation" type="password" label="Confirmar nova senha" variant="outlined" fullWidth required />
				</>) :
				<>
					<Typography variant="xs">
						Um código será enviado por e-mail. Digite no campo abaixo e clica em Acessar para confirmar sua conta. <br />
					</Typography>
					<TextFormField key="email" name="email" type="text" label="E-mail" variant="outlined" fullWidth required />
				</>

			}
			<Button
				type="submit"
				variant="contained"
				size="medium"
				disabled={submitting}
				fullWidth={true}
				sx={{ width: '100%' }}
			>
				{codeSent ? "Enviar código" : "Confirmar"}
			</Button>
		</Stack>
		<Link to="" onClick={() => goBack()}>Voltar</Link>

	</>
}