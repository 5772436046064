import moment from "moment";
import { object, date } from "yup";

export const validationPaymentInformationFgtsSchema = () => {
    return object().shape({
        paymentDate: date().typeError('Data inválida.').required('Data de pagamento: Precisa ser preenchido.'),
    });
};
export const defaultValuesPaymentInformationFgtsForm = {
    paymentDate: parseInt(moment().format('HH')) < 22 ? moment().format('yyyy-MM-DD') : moment().add(1, 'day').format('yyyy-MM-DD'),    
};