/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Stack } from '@mui/material';
import { DeleteIcon } from '@uy3/web-components';
import { Fragment } from 'react';
import { TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import CustomAccordionContainer from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { isEmpty } from 'lodash';
import { activeTheme } from 'services/theme';

type UpdateEmailsListFormProps = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
};

export const emailsOfWalletToDisableAccordions = [
    'lucas@uy3.com.br',
    'renan.santos@uy3.com.br',
    'thiago@uy3.com.br',
];

const theme = activeTheme();

export const UpdateEmailsListForm = ({ fields, remove }: UpdateEmailsListFormProps) => {
    const { watch } = useFormContext();
    return (
        <Fragment>
            <Stack direction="column" spacing={2}>
                {fields.map((field, index) => {
                    const prefix = `notificationRecepients.${index}`;
                    const currentEmail = watch(`${prefix}.email`);

                    const disableAccordion = emailsOfWalletToDisableAccordions.includes(currentEmail);
                    return (
                        <Stack key={field.id} direction="row" alignItems="center">
                            <EmailsForm
                                key={field.id}
                                index={index}
                                currentEmail={currentEmail}
                                disableAccordion={disableAccordion}
                                prefix={prefix}
                            />
                            <IconButton
                                onClick={() => remove(index)}
                                disabled={disableAccordion}
                            >
                                <DeleteIcon htmlColor={disableAccordion ? theme.palette.grey['500'] : theme.palette.common.black} />
                            </IconButton>
                        </Stack>
                    );
                })}
            </Stack>
        </Fragment>
    );
};

type EmailsFormProps = {
    index: number;
    prefix: string;
    disableAccordion: boolean;
    currentEmail: string;
};

const EmailsForm = ({ index, disableAccordion, currentEmail, prefix }: EmailsFormProps) => {
    const { watch } = useFormContext();
    const expandedLastItem = (watch('notificationRecepients') ?? [])?.length === index + 1 && !disableAccordion;
    return (
        <CustomAccordionContainer
            index={index}
            disabled={disableAccordion}
            expanded={expandedLastItem}
            description={`${!isEmpty(currentEmail) ? currentEmail : `Novo email`}`}
        >
            <Stack spacing={2}>
                <Grid xs={3}>
                    <TextFormField
                        name={`${prefix}.email`}
                        label="Email"
                        variant="outlined"
                        fullWidth
                        required
                    />
                </Grid>
            </Stack>
        </CustomAccordionContainer>
    );
};
