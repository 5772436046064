import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask } from 'utils/constants';
import { activeTheme } from 'services/theme';
import { sendSubmitEvent } from 'contexts/apiRequestContext';

function ChatbotFiltersForm() {
    const { watch } = useFormContext();

    const SearchComponent = () => {
        return (
            <InputAdornment position="end">
                <IconButton type="submit">
                    <SearchIcon
                        sx={{ width: 25, height: 25 }}
                        htmlColor={activeTheme().palette.grey[500]}
                    />
                </IconButton>
            </InputAdornment>
        )
    }
    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Valor', value: 'invoiceValue' },
                    { label: 'Número de telefone', value: 'botPhone' },
                    { label: 'Nome do produto', value: 'productName' },
                    { label: 'Categoria do produto', value: 'productCategoryName' },
                    { label: 'Nome da tomador', value: 'personName' },
                    { label: 'Nome do beneficiário', value: 'baneficiaryName' },
                    { label: 'Nome da fila', value: 'serviceQueueName' },
                    { label: 'Código de identificação', value: 'identificationCode' }
                ]}
            />

            {watch('optionFilter') === 'invoiceValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Valor"
                    name="invoiceValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'botPhone' && (
                <TextFormField
                    name="botPhone"
                    variant="outlined"
                    label="Número de telefone"
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: phoneNumberMask },
                        endAdornment: <SearchComponent />
                    }}
                />
            )}
            {watch('optionFilter') === 'productName' && (
                <TextFormField
                    label="Nome do produto"
                    name="productName"
                    fullWidth
                    variant="outlined"
                    InputProps={{
                        endAdornment: <SearchComponent />
                    }}
                />
            )}
            {watch('optionFilter') === 'personName' && (
                <TextFormField
                    label="Nome da tomador"
                    name="personName"
                    fullWidth
                    InputProps={{
                        endAdornment: <SearchComponent />
                    }}
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'baneficiaryName' && (
                <TextFormField
                    label="Nome do beneficiário"
                    name="baneficiaryName"
                    fullWidth
                    InputProps={{
                        endAdornment: <SearchComponent />
                    }}
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'serviceQueueName' && (
                <TextFormField
                    label="Nome da fila"
                    name="serviceQueueName"
                    fullWidth
                    InputProps={{
                        endAdornment: <SearchComponent />
                    }}
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'identificationCode' && (
                <TextFormField
                    label="Código de identificação"
                    name="identificationCode"
                    fullWidth
                    InputProps={{
                        endAdornment: <SearchComponent />
                    }}
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'productCategoryName' && (
                <SelectFormField
                    label="Categoria do produto"
                    name="productCategoryName"
                    fullWidth
                    showEndAdornment
                    options={[
                        {label: "FGTS", value: "FGTS"}, 
                        {label: "CDC", value: "CDC"}, 
                    ]}
                    onClick={sendSubmitEvent}
                />
            )}
        </>
    );
}

type ChatbotFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const ChatbotFilters = ({ currentValues, removeFilterSelected }: ChatbotFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<ChatbotFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                    recordType='billing-list-columns'
                    enableSelectFilter
                />
            }
        />
    );
};
