import { Stack } from '@mui/material'
import React, { useState } from 'react'
import { ClonePermission } from './ClonePermission'
import { FormProvider } from 'contexts/formContext'
import { defaultValuesClonePermission, validationClonePermissionSchema } from './ClonePermissionSchema'
import { useOperatorList } from 'contexts/OperatorContext'
import { useGroupListData } from 'contexts/groupContext'
import { FieldValues } from 'react-hook-form'
import { ApiResponseError, handleOnError, ToastType, useApiRequest } from 'contexts/apiRequestContext'
import { useTenant } from 'contexts/tenantContext'
import { fromApiDataModel, getPermissionList, PermissionListProps, PermissionModel } from 'services/permissions'
import { useIdentity } from 'contexts/identityContext'

export type ClonePermissionContainerProps = {
    onClose: () => void;
    recordType: "Group" | "User"
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    refetch: () => void;
    postPermissionAsync: (permissionList: PermissionModel[]) => Promise<void>
}

export const ClonePermissionContainer: React.FC<ClonePermissionContainerProps> = ({ recordType, onClose, setToast, refetch, postPermissionAsync }) => {
    const [tenantSelected, setTenantSelected] = useState<string | undefined>(undefined);
    const { endRequest, startRequest } = useApiRequest();
    const { token } = useIdentity();

    const { tenantAutoCompleteProps, currentTenantId } = useTenant()
    const { operatorsAutoCompleteProps } = useOperatorList({ page: 0, size: 10 }, 'always', tenantSelected);
    const { groupAutoCompleteProps } = useGroupListData({ page: 0, size: 10, tenant: tenantSelected }, 'always');

    const resourceDisplay = recordType === 'Group' ? 'Grupo' : 'Operador' as string;

    const onSubmit = async ({ resourceId, resourceIdDisplay, tenant }: FieldValues) => {
        startRequest();

        const paramsFromGet = {
            principalId: recordType === 'User' ? resourceId : resourceIdDisplay,
            principalType: recordType
        };

        try {
            const tenantSelected = tenant ?? currentTenantId;
            const { data } = await getPermissionList(paramsFromGet, tenantSelected, token!)
            const response = await data as PermissionListProps[];

            if (response?.length === 0) {
                setToast({
                    open: true,
                    severity: 'info',
                    title: `${resourceDisplay} selecionado não possui permissões`
                });
                endRequest(false);
                return;
            };

            const permissions = fromApiDataModel(response as PermissionListProps[]);
            await postPermissionAsync(permissions);
        } catch (error) {
            handleOnError(error as ApiResponseError, setToast);
        }
    }

    return (
        <Stack mt={-2}>
            <FormProvider {...{
                defaultValues: defaultValuesClonePermission,
                validationSchema: validationClonePermissionSchema(resourceDisplay),
                onSubmit,
                onChangeField: [
                    {
                        fieldName: 'tenant',
                        delegate(fieldValue, setValue, watch) {
                            setTenantSelected(fieldValue)
                        },
                    }
                ]
            }}>
                <ClonePermission
                    recordType={recordType}
                    listGroupsAutocomplete={groupAutoCompleteProps}
                    listUsersAutocomplete={operatorsAutoCompleteProps}
                    tenantAutoComplete={tenantAutoCompleteProps}
                    onClose={onClose}
                />
            </FormProvider>
        </Stack>
    )
}
