enum EAssignmentCalculationMode {
    InitialValue = 0, // Valor inicial sem ágio ou atualização para valor presente
    PresentValuePayment = 1, // Diferença do ágio para a taxa da operação é utilizado para trazer o valor da parcela à VP
    PresentValueCreditNote = 2, // Diferença do ágio para a taxa da operação é utilizado para trazer a operação à VP
    Default = 3, // Ágio aplicado ao valor inicial + atualização da operação para valor presente
}

enum EAmortizationType {
    CleanPrice = 1,
    HomeEquityPrice = 2,
    HomeEquitySac = 3,
    StudentLoan = 4,
    ReceivablesPrice = 5,
    CleanPriceIncentivized = 6,
    FGTS = 7,
    Consignado = 8,
    CommercialPapper = 9,
    CleanPriceNoIOF = 10,
    SAC = 11,
    Price = 12,
    PriceCoefficient = 13,
    Discount = 14,
}

enum ELiquidationMode {
    Simple = 1,
    LiquidationSchedule = 2,
}

enum ELiquidationClosureStatus {
    Pending = 'Pending',
    SettleOperation = 'SettleOperation', //Operação quitada
    TransferChangeAccount = 'TransferChangeAccount', //Transferência para conta troco
    TransitoryBankAccountClosed = 'TransitoryBankAccountClosed', //Conta transitória encerrada
    Revision = 'Revision', //Aguardando revisão
    Cancelled = 'Cancelled', //Cancelado
    Finished = 'Finished', //Finalizado
}

enum EwarrantyOption {
    None = "None",
    FreeMargin = "FreeMargin",
    Portability = "Portability",
    DealContract = "DealContract",
}

export {
    EAssignmentCalculationMode,
    EAmortizationType,
    ELiquidationMode,
    ELiquidationClosureStatus,
    EwarrantyOption
};
