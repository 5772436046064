import { Stack } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { ApproveIcon, RejectIcon, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

interface TransferHeaderListProps {
    isFetching: boolean;
    refetch: () => void;
    bankAccountId: string | undefined;
    enableActionsApprove: boolean
    setStepActionTransfer: React.Dispatch<React.SetStateAction<string | undefined>>
}

const theme = activeTheme();
const TransferHeader: React.FC<TransferHeaderListProps> = ({ isFetching, refetch, bankAccountId, enableActionsApprove, setStepActionTransfer }) => {
    const queryClient = useQueryClient();
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];

    async function transferRefetch() {
        refetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction={'row'} justifyContent="flex-end" spacing={2} mr={2} mb={3}>
            <Actions
                numberOfButtons={3}
                buttonsActionsList={[
                    {
                        enable: enableActionsApprove,
                        label: "Aprovar",
                        action: () => setStepActionTransfer('approveTransfer'),
                        icon: <ApproveIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: enableActionsApprove,
                        label: "Reprovar",
                        action: () => setStepActionTransfer('rejectTransfer'),
                        icon: <RejectIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: true,
                        label: "Atualizar",
                        action: transferRefetch,
                        icon: <UpdateIcon sx={iconSx} htmlColor={color}/>,
                        disabled: isFetching
                    },
                ]}
            />
        </Stack>
    );
};
export default TransferHeader;
