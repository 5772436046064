import { Grid, Stack } from '@mui/material'
import { CheckboxFormField, TextFormField } from 'components/Forms/FormFields'
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFormContext } from 'contexts/formContext';
import { useTenant } from 'contexts/tenantContext';

export const CloneProduct = () => {
    const { tenantAutoCompleteProps } = useTenant();
    const { watch } = useFormContext();
    return (
        <Stack spacing={2}>
            <Grid>
                <CheckboxFormField
                    name='keepSameName'
                    label='Manter o mesmo nome do produto'
                />
            </Grid>
            {!watch('keepSameName') &&
                <TextFormField
                    name='name'
                    label='Novo nome do produto'
                    variant='outlined'
                />
            }

            <AutocompleteField
                label="Correspondente"
                name="tenant"
                displayName="tenantDisplay"
                {...tenantAutoCompleteProps}
            />
        </Stack>
    )
}
