/* eslint-disable sonarjs/no-duplicate-string */

import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    field: 'dataDoPagamento',
    headerName: 'Data do pagamento',
    width: 230,
    editable: false,
  },
  {
    field: 'valorDoPagamento',
    headerName: 'Valor do pagamento',
    width: 230,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 520,
    editable: false,
    renderCell: (cellValues) => {
      return (
        <div
          style={{
            color: '#4CAF50',
            textAlign: 'left',
          }}
        >
          {cellValues.value}
        </div>
      );
    },
  },
  {
    field: 'acoes',
    disableReorder: true,
    headerName: 'Ações',
    width: 200,
    editable: false,
    hideSortIcons: true,
  },
];

export const rows = [
  {
    id: 1,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 2,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 3,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 4,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 5,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 6,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 7,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 8,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 9,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 10,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 11,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 12,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Aprovado',
    acoes: 'icon',
  },
  {
    id: 13,
    dataDoPagamento: '01/01/2021',
    valorDoPagamento: 'R$ 999.999.99,99',
    status: 'Rejeitado',
    acoes: 'icon',
  },
];
