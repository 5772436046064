import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridToolbarQuickFilter } from '@mui/x-data-grid';

export const MainBox = styled(Box)(({theme}) => ({
  width: '100%', 
  padding: '24px 24px 0 24px',
  background: theme.palette.common.white,
  boxShadow: '0px 8px 32px rgba(186, 191, 208, 0.16)',
  borderRadius: '8px',
}))

export const StyledToolbar = styled(GridToolbarQuickFilter)(({theme}) => ({
  maxWidth: '548px',
  width: '100%',
  '& .MuiInputBase-input': {
    ...theme.typography.xs,
    padding: '14px',
    height: '56px',
    boxSizing: 'border-box',
    color: theme.palette.neutral.dark,
    '&::placeholder': {
      ...theme.typography.xs,
      color: theme.palette.neutral.dark,
      opacity: 1,
    }
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px'
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px'
  }
}))