import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { ReactNode } from 'react';

interface CardContentProps {
    title: string;
    value: string | ReactNode;
}

export const CardContent: React.FC<CardContentProps> = ({ title, value }) => {
    return (
        <Stack spacing={1}>
            <Typography variant="xl" color="neutral.dark" fontWeight={600}>
                {title}
            </Typography>
            <Typography variant="md" color="neutral.medium">{value}</Typography>
        </Stack>
    );
};