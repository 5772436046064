import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import CardInfo from 'components/Custom/CustomCardInfo/CardInfo';
import { UploadFormWrapper } from 'components/Tabs/Uploads/UploadForm/UploadFormWrapper';
import { EnumItem } from 'contexts/enumContext';
import { useFormContext } from 'contexts/formContext';
import React from 'react';

interface SendDocFormProps {
    typeOfDocument: EnumItem[] | undefined;
}

const SendDocForm: React.FC<SendDocFormProps> = ({ typeOfDocument }) => {
    const { watch } = useFormContext();
    const name = watch('name');
    const registrationNumber = watch('registrationNumber');
    
    return (
        <React.Fragment>
            <CardInfo {...{ name, registrationNumber }} />
            <Typography variant="lg" color="common.black">
                Enviar documentos
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <UploadFormWrapper
                    options={typeOfDocument ?? []}
                    accept="image/*,.pdf,.doc,.docx,.xlsx,.xsl,.rar,.zip"
                    isRegistrationPerson
                />
            </Stack>
        </React.Fragment>
    );
};
export default SendDocForm;