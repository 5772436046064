import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { IFluxoConsolidado } from "services/datasets/datasets.interface";
import { maskCurrencyInCents } from "helpers/formats/Currency";

type ScrListProps = {
	fluxoConsolidado: IFluxoConsolidado
}

const ScrList = ({fluxoConsolidado}: ScrListProps) => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
					<TableBody>
						<TableRow key={1}>
							<TableCell component="th" scope="row">
								<b>Vencer - Total</b>
							</TableCell>
							<TableCell component="th" scope="row"></TableCell>
							<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.vencerTotal)}</b></TableCell>
						</TableRow>

						<TableRow key={2}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row" >
								Até 30 dias e Vencidos até 14 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencerAte30diasVencidoAte14dias)}</TableCell>
						</TableRow>

						<TableRow key={3}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								31 a 60 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencer31a60dias)}</TableCell>
						</TableRow>
						<TableRow key={4}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								61 a 90 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencer61a90dias)}</TableCell>
						</TableRow>
						<TableRow key={5}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								91 a 180 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencer91a180dias)}</TableCell>
						</TableRow>
						<TableRow key={6}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								181 a 360 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencer181a360dias)}</TableCell>
						</TableRow>
						<TableRow key={7}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								Acima 360 dias
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencerAcima360dias)}</TableCell>
						</TableRow>
						<TableRow key={8}>
							<TableCell scope="row" size={"small"} />
							<TableCell scope="row">
								Indeterminado
							</TableCell>
							<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencerPrazoIndeterminado)}</TableCell>
						</TableRow>
					</TableBody>

					<TableRow key={1}>
						<TableCell component="th" scope="row">
							<b>Vencido - Total</b>
						</TableCell>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.vencidoTotal)}</b></TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							15 a 30 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido15a30dias)}</TableCell>
					</TableRow>

					<TableRow key={3}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row">
							31 a 60 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido31a60dias)}</TableCell>
					</TableRow>
					<TableRow key={4}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row">
							61 a 90 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido61a90dias)}</TableCell>
					</TableRow>
					<TableRow key={5}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row">
							91 a 180 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido91a180dias)}</TableCell>
					</TableRow>
					<TableRow key={6}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row">
							181 a 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido181a360dias)}</TableCell>
					</TableRow>
					<TableRow key={7}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row">
							Acima 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencidoAcima360dias)}</TableCell>
					</TableRow>

					<TableRow key={1}>
						<TableCell component="th" scope="row">
							<b>Prejuizo - Total</b>
						</TableCell>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.vencidoTotal)}</b></TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Até 12 meses
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido15a30dias)}</TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Acima de 12 meses
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.vencido15a30dias)}</TableCell>
					</TableRow>


					<TableRow key={1}>
						<TableCell component="th" scope="row">
							<b>Coobrigação - Total</b>
						</TableCell>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.coobrigacaoTotal)}</b></TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Coobrigação Assumida
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.coobrigacaoAssumida)}</TableCell>
					</TableRow>

					<TableRow key={3}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Coobrigação Prestadas
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.coobrigacaoPrestadas)}</TableCell>
					</TableRow>



					<TableRow key={1}>
						<TableCell component="th" scope="row">
							<b>Créditos a Liberar - Total</b>
						</TableCell>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.creditosALiberarTotal)}</b></TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Créditos a Liberar até 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.creditosALiberarAte360dias)}</TableCell>
					</TableRow>

					<TableRow key={3}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Créditos a Liberar acima de 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.creditosALiberarAcima360dias)}</TableCell>
					</TableRow>



					<TableRow key={1}>
						<TableCell component="th" scope="row">
							<b>Limites de Crédito - Total</b>
						</TableCell>
						<TableCell component="th" scope="row"></TableCell>
						<TableCell align="right"><b>{maskCurrencyInCents(fluxoConsolidado?.limiteCreditoTotal)}</b></TableCell>
					</TableRow>

					<TableRow key={2}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Limite de Crédito com vencimento até 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.limiteCreditoAte360dias)}</TableCell>
					</TableRow>

					<TableRow key={3}>
						<TableCell scope="row" size={"small"} />
						<TableCell scope="row" >
							Limite de Crédito com vencimento acima 360 dias
						</TableCell>
						<TableCell align="right">{maskCurrencyInCents(fluxoConsolidado?.limiteCreditoAcima360dias)}</TableCell>
					</TableRow>
				</Table>
			</TableContainer>
		</>
	);
};

export default ScrList;
