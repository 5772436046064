import { object, string } from "yup";

export const validationSchemaAssignForm = () => {
    return object().shape({
        userId: string().when("groupName", {
            is: (value: string) => value === "",
            then: string().typeError("Operador inválido.").required("Operador: Precisa ser preenchido."),
            otherwise: string().nullable().notRequired()
        }),
        groupName: string().when("userId", {
            is: (value: string) => value === "",
            then: string().typeError("Grupo inválido.").required("Grupo: Precisa ser preenchido."),
            otherwise: string().nullable().notRequired()
        }),
        tenant: string().when(["userId", "groupName"], {
            is: (id: string, groupName: string) => id === "" && groupName === "",
            then: string().typeError("Tenant inválido.").required("Tenant: Precisa ser preenchido."),
            otherwise: string().nullable().notRequired(),
        })
    }, [['userId', 'groupName']])
};

export const defaultValuesAssignForm = {
    username: "",
    tenantDisplayName: "",
    userId: "",
    groupName: "",
    tenant: ""
};