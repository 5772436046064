import { Grid, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { ToastType } from 'contexts/apiRequestContext';
import { BatchAssignmentListActions } from 'contexts/batchAssignment/batchAssignmenListAction';
import React from 'react';

interface BatchAssignmentHeaderProps {
    refetch: () => void;
    setDrawerActionSteps: React.Dispatch<React.SetStateAction<string | undefined>>;
    selectedStatusOfTable: string[];
    setSelectedStatusOfTable: React.Dispatch<React.SetStateAction<string[]>>;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    toast: ToastType;
    batchData: any
}

const BatchAssignmentHeader: React.FC<BatchAssignmentHeaderProps> = ({
    setToast,
    toast,
    selectedStatusOfTable,
    setSelectedStatusOfTable,
    refetch,
    batchData
}) => {
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Cessões
            </Typography>
            <Grid mr={1}>
                <BatchAssignmentListActions
                    setSelectedStatusOfTable={setSelectedStatusOfTable}
                    selectedStatusOfTable={selectedStatusOfTable}
                    toast={toast}
                    setToast={setToast}
                    refetch={refetch}
                    batchData={batchData}
                />
            </Grid>
        </Stack>
    );
};

export default BatchAssignmentHeader;
