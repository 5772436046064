import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useFilterValues } from 'contexts/filterValuesContext';
import { useIdentity } from 'contexts/identityContext';
import { useTenant } from 'contexts/tenantContext';
import { useUserPermissionData } from 'contexts/userContext';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import {
    deleteBillingSettingAsync,
    createOrEditBillingSettingAsync,
    getBillingSettingListAsync,
} from 'services/walletManagement/billingSetting';
import { IBillingSettingFull, IBillingSettingFiltersProps } from 'services/walletManagement/billingSetting/billingSetting.types';

export function useBillingParametersList(
    filters: IBillingSettingFiltersProps,
    refetchOnMount?: IRefetchOnMountModeGetQuery, 
    queryKey?: any
) {
    const { token} = useIdentity();
    const { hasPermission } = useUserPermissionData();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [chargesList, setBillingParametersList] = useState<{ label: string; value: string }[]>([]);
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType)
        ? 'ParametroCobranca'
        : filterValues.recordType;
    const listDataFilters = recordType === 'ParametroCobranca' && { ...filterValues.filters };
    const filtersComplete = { ...listDataFilters, ...filters, tenant: currentTenantId };

    const queryContext = useQuery({
        enabled: !!token && hasPermission('BillingAccount', 'Read'),
        refetchIntervalInBackground: false,
        refetchOnMount,
        retry: false,
        refetchOnWindowFocus: false,
        queryKey: ['billing-parameters-list', filtersComplete, queryKey],
        queryFn: async (): Promise<GetListApiResponse<IBillingSettingFull>> => {
            startRequest();
            const resp = await getBillingSettingListAsync(filtersComplete, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            var list = data as GetListApiResponseSuccess<IBillingSettingFull>;
            setBillingParametersList(
                list.data.map((c: IBillingSettingFull) => {
                    return {
                        label: `(${c.walletCode}) - ${c.name}`,
                        value: c.id,
                    };
                })
            );

            return data as GetListApiResponse<IBillingSettingFull>;
        },
    });
    const billingParameterAutoCompleteProps = {
        listOptions: chargesList,
        loading: (queryContext.isLoading || queryContext.isFetching),
        onSearch: (searchString: string | undefined) =>
            searchString && setFilterValues({ searchString }, 'ParametroCobranca'),
    };

    return {
        ...queryContext,
        billingParameterAutoCompleteProps,
    };
}

const handleError = (
    error: any,
    setSubmitError: Function,
    endRequest: Function,
    onError: Function
) => {
    let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
    let apiError: ApiResponseError = {
        type: 'error',
        message,
        code: 'UNKNOWN',
        errors: [],
    };

    if (isAxiosError(error)) {
        apiError = { type: 'error', code: error.code!, errors: [] };
        const { response } = error;
        if (response) {
            const { data } = response;
            if (data) {
                apiError = data as ApiResponseError;
            }
        }
    }

    endRequest(false);
    setSubmitError(apiError);
    onError(apiError);
};

export function useBillingParametersMutation(
    onSuccess: (data: IBillingSettingFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: IBillingSettingFull) => {
            startRequest();

            const resp = await createOrEditBillingSettingAsync(values, values.id, token);
            const { data, status, statusText } = resp;
            
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as IBillingSettingFull;
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleError(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error };
}


export function useDeleteBillingSettingMutation(
    onSuccess: (data: IBillingSettingFull) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (billingId: string) => {
            startRequest();

            const resp = await deleteBillingSettingAsync(billingId, token);
            const { data, status, statusText } = resp;
            
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data as IBillingSettingFull;
        },
        onSuccess(data, _, context) {
            onSuccess(data);
        },
        onError(error, _, context) {
            handleError(error, setSubmitError, endRequest, onError);
        },
    });

    return { mutateAsync, isLoading, error };
}
