import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { CostsParamsQuery } from "./costs.type";
import { toIsoStringWithTimezone } from "helpers/formats/DateFormat";

const url = "/Costs"

const getParamsFromListAndExport = (params: CostsParamsQuery): CostsParamsQuery => {
    let filters: Partial<CostsParamsQuery> = {};
    const hasBillingDate = params.hasBillingDate ?? true;
    const initialBillingDate = params?.initialBillingDate ?? toIsoStringWithTimezone(new Date())
    const finalBillingDate = params?.finalBillingDate ?? toIsoStringWithTimezone(new Date())

    if (hasBillingDate === true) {
        filters = {
            initialBillingDate, 
            finalBillingDate
        }
    } else {
        delete filters?.finalBillingDate
        delete filters?.initialBillingDate
    }

    return {
        ...params,
        ...filters
    }
}

export async function getAllCosts(params: CostsParamsQuery, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: getParamsFromListAndExport(params)
    };
    return await billingAxiosApi.get(`${url}`, config);
}

export async function exportWalletsCosts(params: CostsParamsQuery, token: string) {
    const config = {
        responseType: 'blob' as 'blob',
        headers: { Authorization: `Bearer ${token}` },
        params: getParamsFromListAndExport(params)
    };

    return await billingAxiosApi.get(`${url}/Export`, config);
}

