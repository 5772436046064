import { Drawer } from '@uy3/web-components';
import CreateOrEditCategories from './CreateOrEditCategories';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    CategoriesFormSchema,
    defaultValuesCategoriesForm,
    validationSchemaCategoriesForm,
} from './CategoriesSchema';
import { ApiResponseError, ToastType, useApiRequest } from 'contexts/apiRequestContext';
import { useQueryClient } from '@tanstack/react-query';
import { usePatchBillingAsyncMutation } from 'contexts/wallet/Billing/billingContext';

interface DrawerContainerProps {
    addCategories: boolean;
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

const DrawerManageCategoryContainer: React.FC<DrawerContainerProps> = ({
    addCategories,
    onClose,
    setToast
}) => {
    const { getValues, watch } = useFormContext();
    const { handleErrorException } = useApiRequest();
    const values = getValues();
    const queryClient = useQueryClient();
    const tags = watch('tagsList') as string[]
    let categoriesData;

    const onSuccess = () => {
        setToast({ open: true, title: 'Categorias adicionadas com sucesso', severity: 'info' }); 
        onClose();
        queryClient.refetchQueries({queryKey: ['get-tags-by-wallet-code']});
    };

    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)


    const { mutateAsync: addTagsMutateAsync, isLoading } = usePatchBillingAsyncMutation(values?.id!, onSuccess, onError);

    if (tags) {
        // Adapting the category structure to meet the new Yup validation.
        const convertedCategories = tags.map((category) => ({ category }));

        // Preparing category data according to the new structure required by Yup validation.
        categoriesData = {
            categories: convertedCategories,
        };
    }

    const handleOnSubmit = (values: CategoriesFormSchema) => {
        const categories = values.categories;
        if (categories) {
            const categoryArray = categories.map((c) => c.category);
            addTagsMutateAsync({
                tags: categoryArray
            });
        }
    };

    const validationSchema = validationSchemaCategoriesForm();
    const defaultValues = categoriesData ?? defaultValuesCategoriesForm;

    return (
        <Drawer
            title="Gerenciar categorias"
            description="Confira as informações antes de editar ou criar uma nova categoria"
            anchor="right"
            open={addCategories}
            onClose={onClose}
            toggleDrawer
        >
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
            >
                <CreateOrEditCategories onClose={onClose} isLoading={isLoading}/>
            </FormProvider>
        </Drawer>
    );
};
export default DrawerManageCategoryContainer;
