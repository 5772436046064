/* eslint-disable no-useless-escape */
import { string, object, ref, InferType } from 'yup';

export function ConfirmPasswordFormValidationSchema() {
    return object().shape({
        password: string()
            .typeError('Senha inválida.')
            .required('Senha: Precisa ser preenchido.')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                'A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número'
            )
            .min(8, 'A senha é muito curta - deve ter no mínimo 8 caracteres.'),
        passwordConfirmation: string()
            .typeError('Confirmar senha inválida.')
            .required('Confirmar senha: Precisa ser preenchido.')
            .oneOf([ref('password'), null], 'A senha e a confirmação de senha não conferem'),
    });
}

let inferedSchema = ConfirmPasswordFormValidationSchema();
export type ConfirmPasswordFormSchema = InferType<typeof inferedSchema>;

export const ConfirmPasswordFormDefaultValues = {
    password: null,
    passwordConfirmation: null,
};
