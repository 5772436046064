import { array, object } from "yup";

export const validationApproveImportSignatoriesSchemaSchema = () => {
  return object().shape({
    personsToImport: array().typeError('Importar signatários').min(1, "Selecione ao menos 1 pessoa.").required('Importar siganatários precisa ser preenchido')
  })
};

export const defaultValuesImportSignatoriesSchema = {
  personsToImport: []
};