import { array, lazy, mixed, number, object, string } from 'yup';
import { accountTypeList } from '../Options';

const TAX_PAYER_OPTIONS = ['PF', 'PJ'] as const;
const INVOLVEMENT_OPTIONS = ['Sim', 'Nao'] as const;

type TaxPayer = (typeof TAX_PAYER_OPTIONS)[number];
type Involvement = (typeof INVOLVEMENT_OPTIONS)[number];

const ACCOUNT_TYPE_OPTIONS = accountTypeList.map((option) => option.value);
type AccountType = (typeof ACCOUNT_TYPE_OPTIONS)[number];

const validateCPF = (message: string) =>
    string().required(message).cpfCnpjValidation('CPF inválido').typeError('CPF inválido');

const validateCNPJ = (message: string) =>
    string().required(message).cpfCnpjValidation('CNPJ inválido');

const validatePhone = (msg: string, typeError: string) => {
    return number()
        .integer(msg)
        .notRequired()
        .nullable()
        .typeError(typeError)
        .transform((value, originalValue) => {
            if (!originalValue) {
                return undefined;
            }
            return value;
        })
        .test('phone-required', 'Telefone é obrigatório.', function (value) {
            const { phoneCountryCode, phoneAreaCode, phoneNumber, phoneFullValue } = this.parent;
            if (phoneCountryCode || phoneAreaCode || phoneNumber || phoneFullValue) {
                if (!phoneCountryCode || !phoneAreaCode || !phoneNumber || !phoneFullValue) {
                    return false;
                }
            }
            return true;
        });
};

const createCommonSchema = () =>
    object().shape({
        taxPayer: mixed<TaxPayer>()
            .oneOf([...TAX_PAYER_OPTIONS], 'Contribuinte inválido.')
            .typeError('Contribuinte inválido.')
            .required('Contribuinte é obrigatório.'),
        documentNumber: string().when('taxPayer', {
            is: 'PF',
            then: validateCPF('Número de documento é obrigatório para PF.'),
            otherwise: validateCNPJ('Número de documento é obrigatório para PJ.'),
        }),
        fullName: string().required('Nome completo é obrigatório.').typeError('Nome inválido.'),
        tradeName: string().when('taxPayer', {
            is: 'PJ',
            then: string()
                .required('Nome Fantasia é obrigatório')
                .min(1, 'Nome fantasia deve ter pelo menos 1 caractere.'),
            otherwise: string().nullable(),
        }),
        cpfLegalRepresentatives: array().of(number()).optional().typeError('CPF inválido.'),
        email: string().email('Email inválido.').nullable().typeError('Email inválido.'),
        phoneCountryCode: validatePhone(
            'O código do país deve ser um número inteiro.',
            'Código do país inválido.'
        ).max(999, 'Código do país deve ter no máximo 3 caracteres.'),
        phoneAreaCode: validatePhone(
            'O código de área deve ser um número inteiro.',
            'Código de área inválido.'
        ),
        phoneNumber: validatePhone(
            'O número de telefone deve ser um número inteiro.',
            'Número de telefone inválido.'
        ),
        phoneFullValue: validatePhone(
            'O número de telefone completo deve ser um número inteiro.',
            'Número de telefone completo inválido.'
        ),
    });

// Schema para validar o repórter
const reporterSchema = createCommonSchema().shape({
    involvement: mixed<Involvement>()
        .oneOf([...INVOLVEMENT_OPTIONS], 'Envolvimento inválido.')
        .typeError('Envolvimento inválido.')
        .required('Indicação sobre o envolvimento do reclamante no indício de fraude.'),
});

// Schema para validar o executor
const executorSchema = createCommonSchema();

// Schema para validar a conta de destino
const toAccountSchema = createCommonSchema().shape({
    institutionCode: number()
        .integer('Código da instituição deve ser um número inteiro.')
        .positive('Código da instituição deve ser um número positivo.')
        .typeError('Código da instituição inválido.')
        .required('Código da instituição é obrigatório.'),
    accountType: mixed<AccountType>()
        .oneOf([...ACCOUNT_TYPE_OPTIONS], 'Tipo de conta inválido.')
        .typeError('Tipo de conta inválido.')
        .required('Tipo de conta é obrigatório.'),
    agencyCode: string()
        .typeError('Código da agência inválido.')
        .required('Código da agência é obrigatório.')
        .min(1, 'Código da agência deve ter pelo menos 1 caractere.'),
    accountNumber: string()
        .required('Número da conta é obrigatório.')
        .typeError('Número da conta inválido.')
        .min(1, 'Número da conta deve ter pelo menos 1 caractere.'),
    involvement: mixed<Involvement>()
        .oneOf([...INVOLVEMENT_OPTIONS], 'Envolvimento inválido.')
        .typeError('Envolvimento inválido.')
        .required('Envolvimento é obrigatório.'),
});

export const detailsSchema = object().shape({
    reporter: reporterSchema.required('Repórter é obrigatório.'),
    executor: lazy((value) => (isEmptyObject(value) ? nullSchema() : executorSchema)),
    toAccount: lazy((value) => (isEmptyObject(value) ? nullSchema() : toAccountSchema)),
});

const isEmptyObject = (obj: any) => {
    return Object.keys(obj || {}).every((key) => {
        if (key?.toLowerCase().includes('display')) {
            return true; // Ignorar propriedades com "Display" no nome
        }
        return isNullOrEmpty(obj[key]);
    });
};

const isNullOrEmpty = (value: any) => {
    return value == null || value === '' || value === undefined;
};

const nullSchema = () =>
    object()
        .nullable()
        .transform((value) => (isEmptyObject(value) ? {} : value));
