import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import { array, boolean, number, object, string } from "yup";

export const includeBatchAssingmentSchema = () => {
    return object().shape({
        fundId: string().nullable().notRequired(),
        creditNoteOpInitialDate: string().nullable().notRequired(),
        creditNoteOpFinalDate: string().nullable().notRequired(),
        status: array().nullable().notRequired(),
        amortizationTypes: array().nullable().notRequired(),
        minCreditNoteOpValue: string().nullable().notRequired(),
        maxCreditNoteOpValue: string().nullable().notRequired(),
        minAssignmentCalculationResultValue: string().nullable().notRequired(),
        assignmentDate: string().nullable().notRequired(),
        maxBatchAssignmentValue: string().nullable().notRequired(),
        withoutFund: boolean().nullable().notRequired(),
        initialCreditNoteNoToExclude: number().nullable().notRequired().transform(handleNumericNaN),
        finalCreditNoteNoToExclude: number().nullable().notRequired().transform(handleNumericNaN),
    });
};

export const includeBatchAssingmentDefaultValues = {
    status: [],
    amortizationTypes: [],
    fundId: null,
    creditNoteOpInitialDate: null,
    creditNoteOpFinalDate: null,
    minCreditNoteOpValue: null,
    maxCreditNoteOpValue: null,
    minAssignmentCalculationResultValue: null,
    withoutFund: false,
    maxBatchAssignmentValue: null,
    assignmentDate: null,
    initialCreditNoteNoToExclude: null,
    finalCreditNoteNoToExclude: null,
}