import { string, object, InferType } from 'yup';

export function validationSchemaMemberForm() {
    return object().shape({
        name: string().typeError('Operador: Precisa ser preenchido.').required('Operador: Precisa ser preenchido.')
    });
};

export const defaultValuesMemberForm = {
    name: ""
};

let inferedSchema = validationSchemaMemberForm();

export type MemberFormSchema = InferType<typeof inferedSchema>;