import { hideString } from './StringFormats';

export function formatDocumentNumber(value?: string) {
    if (!value || !value.replace || value.includes('*')) return value;
    const cnpjCpf = value.replace(/\D/g, '');

    if (cnpjCpf.length <= 11)
        return cnpjCpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    if (cnpjCpf.length <= 14)
        return cnpjCpf.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    return undefined;
}

export function hideCredencesDocumentNumber(documentNumber: string): string {
    const first = documentNumber.slice(0, 3);
    const center = documentNumber.slice(3, 9);
    const last = documentNumber.slice(9, 11);
    return `${hideString(first)}.${center.slice(0, 3)}.${center.slice(3)}-${hideString(last)}`;
}
