/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Stack } from '@mui/material';
import {
    GridCallbackDetails,
    GridColDef,
    GridRowId,
    GridSelectionModel,
    GridSortModel,
} from '@mui/x-data-grid';
import { DataTable, EditIcon } from '@uy3/web-components';
import { BatchAssignmentFiltersContainer } from 'components/DataTableFilters/BatchAssignment/BatchAssignmentFilters/BatchAssignmentFiltersContainer';
import ButtonRemoveSelectedRow from 'components/DataTableFilters/MethodsForFilters/ButtonRemoveSelectedRow';
import { useSelectColumns } from 'contexts/SelectColumnContexts';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';

export const defaultFieldsNamesColumn = [
    'tenantDisplay',
    'assignmentNumber',
    'fundIdDisplay',
    'assignmentDate',
    'statusDisplay',
    'assignmentPrice',
    'presentValue',
    'assignmentCalculationResult',
];

type BatchAssignmentListProps = {
    queryData: any;
    rowsPerPage: number;
    page: number;
    setRowsSelected: (rows: any) => void;
    rowsSelected: string[] | GridRowId[] | undefined;
    setPage: (page: number) => void;
    setRowsPerPage: (page: number) => void;
    handleSelectionModelChange: (
        selectionModel: GridSelectionModel,
        details: GridCallbackDetails<any>
    ) => void;
    setOrderBy: React.Dispatch<React.SetStateAction<GridSortModel>>;
};

const theme = activeTheme();
export const BatchAssignmentList: React.FC<BatchAssignmentListProps> = ({
    page,
    setPage,
    queryData,
    rowsPerPage,
    setRowsPerPage,
    rowsSelected,
    setRowsSelected,
    setOrderBy,
    handleSelectionModelChange,
}) => {
    const { selectedColumns, setColumns, setDefaultValues, defaultValues } = useSelectColumns();

    useEffect(() => {
        setColumns(columns);
        if (defaultFieldsNamesColumn.length) {
            setDefaultValues(defaultFieldsNamesColumn);
        }
    }, []);

    const handleClearSelection = () => {
        setRowsSelected([]);
    };

    const columnsBatchAssignmentList = [
        {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            hideSortIcons: true,
            align: 'left',
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => cellValues.value,
        },
        {
            field: 'assignmentNumber',
            headerName: 'Número da cessão',
            hideSortIcons: true,
            align: 'left',
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => `${cellValues.value}ª`,
        },
        {
            field: 'liquidationValue',
            headerName: 'Valor creditado',
            hideSortIcons: true,
            align: 'left',
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'initialValue',
            headerName: 'Valor inicial',
            hideSortIcons: true,
            align: 'left',
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'fundIdDisplay',
            headerName: 'Cessionário',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 4,
        },
        {
            field: 'assignmentDate',
            headerName: 'Data da cessão',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'creditDate',
            headerName: 'Data do crédito',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => new Date(cellValues.value).toLocaleDateString('pt-BR'),
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
        },
        {
            field: 'assignmentPrice',
            headerName: 'Valor da cessão',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'presentValue',
            headerName: 'Valor presente',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'assignmentCalculationResult',
            headerName: 'Ágio',
            hideSortIcons: true,
            editable: false,
            align: 'left',
            flex: 3,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'accruedInterest',
            headerName: 'Juros apropriados',
            hideSortIcons: true,
            align: 'left',
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues.value),
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            flex: 0,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={`${cellValues.id}`}>
                            <IconButton>
                                <EditIcon
                                    htmlColor={theme.palette.common.black}
                                    height={20}
                                    width={20}
                                />
                            </IconButton>
                        </Link>
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsBatchAssignmentList as GridColDef[];
    const columnsActions = columns?.filter((x) => x.field === 'actions');

    const filteredColumns = columns?.filter((column) =>
        selectedColumns?.length === 0
            ? defaultValues?.includes(column?.field)
            : selectedColumns?.includes(column?.field)
    );

    const batchAssignmentColumns = [...filteredColumns, ...columnsActions];

    return (
        <Stack position="relative">
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <BatchAssignmentFiltersContainer />,
                }}
                columns={batchAssignmentColumns}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                onSortModelChange={(sorts) => setOrderBy(sorts)}
                checkboxSelection={true}
                keepNonExistentRowsSelected={true}
                rowSelectionModel={rowsSelected}
                onSelectionModelChange={handleSelectionModelChange}
            />
            {rowsSelected!?.length > 0 && queryData?.data !== undefined && (
                <ButtonRemoveSelectedRow
                    handleClearRow={handleClearSelection}
                    rowsSelected={rowsSelected}
                />
            )}
        </Stack>
    );
};
