import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon, Typography } from '@uy3/web-components';
import { SelectFormField } from 'components/Forms/FormFields';
import React from 'react';
import { activeTheme } from 'services/theme';

type EditOperatorFormProps = {
    onClose: () => void;
    currentLevel: string;
    isFetching: boolean;
};

const theme = activeTheme();
export const EditOperatorForm: React.FC<EditOperatorFormProps> = ({ onClose, isFetching, currentLevel }) => {
    return (
        <Stack spacing={2}>
            <Typography variant='md' color="neutral.medium">Nível atual: {currentLevel}</Typography>
            <SelectFormField
                name="level"
                label="Novo nível de permissão"
                options={[
                    { label: 'Visualizador', value: 'Viewer' },
                    { label: 'Aprovador em Conjunto', value: 'JointApprover' },
                    { label: 'Aprovador Master', value: 'MasterApprover' },
                    { label: 'Solicitante', value: "Requester" }
                ]}
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 18, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    disabled={isFetching}
                >
                    Fechar
                </Button>
                <Button
                    disabled={isFetching}
                    variant="contained"
                    name="btn-submit"
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    type="submit"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
