import { Stack } from '@mui/material';
import { Button, CardData, CloseIcon } from '@uy3/web-components';
import InfoMessage from 'components/InfoMessage/InfoMessage';
import { iconSx } from 'contexts/apiRequestContext';
import { IGetTokensQuery } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { activeTheme } from 'services/theme';

type ShowTokensRegisteredProps = {
  onClose: () => void
  tokensList: IGetTokensQuery[]
}
const theme = activeTheme();

export const ShowTokensRegistered = ({ onClose, tokensList }: ShowTokensRegisteredProps) => {
  return (
    <Stack spacing={2}>
      {tokensList?.length > 0 ?
        <>
          {tokensList?.map((item) => {
            return (
              <Stack direction='column'>
                <CardData listItem={listItemOptions(item)} />
              </Stack>
            )
          })}
        </> : 
        <InfoMessage message='Nenhum token cadastrado disponível' />}

      <Stack direction='row' alignItems='center' justifyContent='right'>
        <Button variant='outlined' onClick={onClose} startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}>
          Fechar
        </Button>
      </Stack>
    </Stack>
  )
}

const listItemOptions = (item: IGetTokensQuery) => [
  {
    id: "1",
    title: "Status",
    value: item.status
  },
  {
    id: "2",
    title: "Tipo",
    value: item.Token.TokenTypeDisplay
  },
  {
    id: "3",
    title: "Token",
    value: item.Token.TokenValue
  },
  {
    id: "4",
    title: "Data de expiração",
    value: new Date(item.ExpirationDate).toLocaleDateString("pt-BR")
  },
  {
    id: "5",
    title: "Criado por",
    value: item.createdBy.userIdDisplay
  },
  {
    id: "6",
    title: "Criado em",
    value: new Date(item.createdAt).toLocaleDateString("pt-BR")
  },
]