import { string, object } from 'yup';

export function communicationSettingSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        walletsCode: string().when("optionFilter", {
            is: "walletsCode",
            then: string()
                .typeError('Código do convênio precisa ser preenchido.').required('Código do convênio: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        name: string().when("optionFilter", {
            is: "name",
            then: string()
                .typeError('Nome precisa ser preenchido.').required('Nome: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        walletNumber: string().when("optionFilter", {
            is: "walletNumber",
            then: string()
                .typeError('Número da carteira precisa ser preenchido.').required('Número da carteira: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        daysToSendBillingBeforeDueDate: string().when("optionFilter", {
            is: "daysToSendBillingBeforeDueDate",
            then: string()
                .typeError('Dias para envio precisa ser preenchido.').required('Dias para envio: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesCommunicationSettingFilter = {
    optionFilter: 'walletsCode',
    walletsCode: null,
    name: null, 
    walletNumber: null, 
    daysToSendBillingBeforeDueDate: null
};
