import { Grid } from '@mui/material';
import { Button, Popup } from '@uy3/web-components';
import { ApiResponseError, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import React from 'react';
import { useNavigate } from 'react-router';
import { deleteFundAsync } from 'services/fund';
interface DeleteFundProps {
    openPopup: boolean;
    onClosePopup: () => void;
    refetch: () => void;
    setToast: (toast: any) => void;
    fundId: string;
    fundName: string;
    isFundDeletedByForm?: boolean;
}
const DeleteFund: React.FC<DeleteFundProps> = ({
    openPopup,
    onClosePopup,
    refetch,
    setToast,
    fundId,
    fundName,
    isFundDeletedByForm,
}) => {
    const { token } = useIdentity();
    const navigate = useNavigate();
    const onDelete = async (fundId: string) => {
        await deleteFundAsync(fundId, token!)
            .then(() => {
                const title = 'Fundo excluído com sucesso!';
                const description = 'Ótimo, Agora você pode cadastrar um novo fundo.';
                showSuccessToast(title, description, setToast);
                if (isFundDeletedByForm) navigate(`/cadastro/fundos`);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);
            })
            .finally(() => {
                onClosePopup();
               if (!isFundDeletedByForm) refetch();
            });
    };
    return (
        <Popup
            open={openPopup}
            //@ts-ignore
            title={`Excluir #${fundName}?`}
            text={`Tem certeza que deseja excluir este fundo?`}
            onClose={onClosePopup}
            children={
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onClosePopup}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => onDelete(fundId)}
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            }
        />
    );
};
export default DeleteFund;
