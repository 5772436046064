import React, { useState } from 'react'
import { Drawer } from '@uy3/web-components'
import { useBankAccountAddKeyPixMutation } from 'contexts/bankAccount/bankAccountPixKey'
import { useParams } from 'react-router'
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext'
import { FormProvider } from 'contexts/formContext'
import { defaultValuesAddPixKey, validationSchemaAddKeyPix } from './AddPixKeyForm/AddPixKeyFormSchema'
import { AddPixKeyForm } from './AddPixKeyForm/AddPixKeyForm'
import { ManagePixKeyForm } from './ManagePixKeyForm'
import { SelectKeyPixForm } from './AddPixKeyForm/SelectKeyPixForm'
import { defaultValuesSelectPixKey, validationSchemaSelectKeyPix } from './AddPixKeyForm/SelectPixKeyFormSchema'
import { useBankAccountBalance } from 'contexts/bankAccount/bankAccountContext'
import { ConfirmPixKey } from './ConfirmPixKey/ConfirmPixKey'
import { ClaimPixKey } from './ClaimPixKey/ClaimPixKey'
import { useBankAccountClaimAddKeyPixMutation } from '../../../../../../../../contexts/bankAccount/bankAccountPixKey'
import FinishPixKey from './FinishPixKey/FinishPixKey'
import { ClaimsRequestsContainer } from './ClaimsRequests/ClaimsRequestsContainer'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import Toast from 'components/Toast/Toast'
import { hasMessageError } from 'helpers/formats/StringFormats'
import ErrorBoundary from 'components/Errors/ErrorBoundary'
import { isEmpty } from 'lodash'

type ManagePixKeyFormContainerProps = {
  refetch: () => void;
  stepToStart?: stepsDrawer; 
  onCloseGenericMethod?: () => void;
};

type ResponseSuccessType = {
  pixKey: string;
  protocolId: string;
  type: string;
}

type stepsDrawer = "selectPixKey" | "addPixKey" | "claimPixKey" | "confirmPixKey" | "finish";

export const ManagePixKeyFormContainer: React.FC<ManagePixKeyFormContainerProps> = ({ refetch, stepToStart, onCloseGenericMethod }) => {
  const [toast, setToast] = useState<ToastType>(toastState);
  const [formValues, setFormValues] = useState<any>(null);
  const [stepDrawer, setStepDrawer] = useState<stepsDrawer | undefined>(stepToStart ?? undefined);
  const { bankAccountId } = useParams();

  const customOnCloseFirstStep = () => {
    if (!isEmpty(stepToStart)) {
      return onCloseGenericMethod && onCloseGenericMethod()
    }

    return onCloseDrawer();
  }

  const onSuccessCreatePixKey = (data: ResponseSuccessType) => {
    const title = "Chave Pix cadastrada com sucesso!";
    const description = 'Ótimo! Agora você pode receber transferências pelo Pix';
    showSuccessToast(title, description, setToast);

    refetch();
    setFormValues({ ...formValues, protocolId: data.protocolId });
    setStepDrawer('finish');
  }

  const onErrorCreatePixKey = (response: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(response);
    if (hasMessageError(errorMessage)) {
      setStepDrawer('claimPixKey');
      setFormValues({
        ...formValues,
        claimType: errorMessage?.includes('PSP') ? "portability" : "ownership"
      });
      const title = 'Ops, ocorreu um erro!';
      const description = errorMessage;
      return showErrorToast(title, description, setToast);
    }
    const title = 'Ops, ocorreu um erro!';
    const description = errorMessage;
    return showErrorToast(title, description, setToast);
  }

  const onSuccessClaimPixKey = () => {
    setStepDrawer('finish');
    const title = "Reivindicação de chave Pix realizada com sucesso!";
    const description = 'Ótimo! Recebemos a sua solicitação de portabilidade de chave Pix. Em breve você receberá um e-mail com a confirmação da portabilidade.';
    showSuccessToast(title, description, setToast);
    refetch();
  }

  const onErrorClaimPixKey = (response: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(response);
    const title = 'Ops, ocorreu um erro!';
    const description = errorMessage;
    showErrorToast(title, description, setToast);
  }

  const { data: bankAccountData } = useBankAccountBalance(bankAccountId!);
  const { mutate: mutateCreatePixKey, isLoading } = useBankAccountAddKeyPixMutation(bankAccountId!, onSuccessCreatePixKey, onErrorCreatePixKey);
  const { mutate: mutateClaimKeyPix, isLoading: isLoadingClaimKeyPix } = useBankAccountClaimAddKeyPixMutation(bankAccountId!, onSuccessClaimPixKey, onErrorClaimPixKey);

  const onCloseDrawer = () => setStepDrawer(undefined);

  const onSelectKeyPix = (values: { type: string, typeDisplay: string }) => {
    if (values?.type === 'Automatic') {
      return mutateCreatePixKey({ type: values.type, pixKey: null });
    }
    setFormValues(values);
    setStepDrawer('addPixKey');
  }

  const onSubmitPixKey = (values: { pixKey: string }) => {
    setFormValues({ ...formValues, ...values });
    setStepDrawer('confirmPixKey');
  };

  const onClaimSubmit = () => mutateClaimKeyPix(formValues);
  
  return (
    <ErrorBoundary fallback='gerenciamento de chave Pix'>
      <Toast toast={toast} setToast={setToast} />
      <ManagePixKeyForm
        onAddKeyPix={() => setStepDrawer('selectPixKey')}
      />

      <ClaimsRequestsContainer />

      <Drawer
        anchor='right'
        toggleDrawer={!isEmpty(stepToStart)}
        open={stepDrawer === 'selectPixKey'}
        onClose={customOnCloseFirstStep}
        title='Registrar ou trazer chaves'
        description='Registe uma chave ou faça portabilidade para a UY3'
        children={
          <FormProvider
            validationSchema={validationSchemaAddKeyPix()}
            defaultValues={defaultValuesAddPixKey}
            onSubmit={onSelectKeyPix}
          >
            <SelectKeyPixForm
              isFething={isLoading}
              onClose={customOnCloseFirstStep}
            />
          </FormProvider>
        }
      />
      <Drawer
        anchor='right'
        toggleDrawer={!isEmpty(stepToStart)}
        open={stepDrawer === 'addPixKey'}
        onClose={() => setStepDrawer('selectPixKey')}
        title={`Registrar ${formValues?.typeDisplay}`}
        description={`Poderão fazer transferência pelo Pix, usando apenas a sua chave do tipo  ${formValues?.typeDisplay}`}
        children={
          <FormProvider
            validationSchema={formValues !== null ? validationSchemaSelectKeyPix(formValues?.type) : {}}
            defaultValues={defaultValuesSelectPixKey}
            onSubmit={onSubmitPixKey}
          >
            <AddPixKeyForm
              formValues={formValues}
              onClose={() => setStepDrawer('selectPixKey')}
            />
          </FormProvider>
        }
      />
      <Drawer
        anchor='right'
        toggleDrawer={!isEmpty(stepToStart)}
        open={stepDrawer === 'claimPixKey'}
        onClose={() => setStepDrawer('addPixKey')}
        title="Traga a sua chave para a UY3"
        description="Você pode solicitar a sua portabilidade de uma chave Pix que está cadastrada em outra instituição ou em uma conta UY3"
        children={
          <ClaimPixKey
            onClaimSubmit={onClaimSubmit}
            formValues={formValues}
            onClose={() => setStepDrawer('addPixKey')}
            isLoading={isLoadingClaimKeyPix}
            bankAccountData={bankAccountData}
          />
        }
      />
      <Drawer
        anchor='right'
        toggleDrawer={!isEmpty(stepToStart)}
        open={stepDrawer === 'confirmPixKey'}
        onClose={() => setStepDrawer('selectPixKey')}
        title={`Registrar ${formValues?.typeDisplay}`}
        description={`Poderão fazer transferência pelo Pix, usando apenas a sua chave do tipo ${formValues?.typeDisplay}`}
        children={
          <ConfirmPixKey
            formValues={formValues}
            isFething={isLoading}
            onSubmitCreateKeyPix={() => mutateCreatePixKey(formValues)}
            onClose={() => setStepDrawer('selectPixKey')}
          />
        }
      />
      <Drawer
        anchor='right'
        toggleDrawer={!isEmpty(stepToStart)}
        open={stepDrawer === 'finish'}
        onClose={customOnCloseFirstStep}
        title="Solcitação de portabilidade de chave"
        description="A solicitação foi realizada"
        children={
          <FinishPixKey
            values={formValues}
            finishingFlowByExternal={!isEmpty(stepToStart)}
            onClose={customOnCloseFirstStep}
          />
        }
      />
    </ErrorBoundary>
  )
}