import { date, number, object, string } from 'yup';

const genericRequired = 'Precisa ser preenchido.';

export enum StepLegalPersonForm {
    LegalPerson = 1,
    FinancialInformation = 2,
    ContactInformation = 3,
    Address = 4,
}

export function validationSchemaDrawerForm(step: StepLegalPersonForm) {
    const schemas: Record<StepLegalPersonForm, any> = {
        [StepLegalPersonForm.LegalPerson]: object().shape({
            registrationNumber: string()
                .typeError('Cnpj inválido')
                .cpfCnpjValidation('Cnpj inválido')
                .required(`CNPJ da empresa: ${genericRequired}`),
            companyName: string()
                .required(`Razão social: ${genericRequired}`)
                .typeError('Razão social inválida.'),
            name: string()
                .required(`Nome fantasia: ${genericRequired}`)
                .typeError('Nome inválido.'),
            email: string()
                .typeError('E-mail inválido.')
                .required(`E-mail: ${genericRequired}`)
                .email('E-mail inválido'),
            openDate: date()
                .typeError('Data inválida')
                .required(`Data de abertura: ${genericRequired}`),
            phone: string()
                .typeError('Telefone: Precisa ser preenchido')
                .required('Telefone: Precisa ser preenchido')
                .phoneNumberValidation('Número de telefone deve conter 11 Caracteres'),
            economicActivityCodeId: string()
                .required(`CNAE: ${genericRequired}`)
                .typeError('CNAE inválido.'),
            legalNature: string()
                .required(`Natureza jurídica: ${genericRequired}`)
                .typeError('Natureza jurídica inválida.'),
        }),
        [StepLegalPersonForm.FinancialInformation]: object().shape({
            averageMonthlyRevenue: number()
                .required(`Faturamento mensal: ${genericRequired}`)
                .typeError('Valor precisa ser um número.')
                .min(1, 'Faturamento mensal deve ser maior que 0')
                .integer('Faturamento mensal deve ser um número inteiro.'),
            averageGrossProfitMargin: number().nullable(),
            initialShareCapital: number().nullable(),
            netWorth: number().nullable(),
            purchases: number().nullable(),
            exportPercentage: number().nullable(),
            costWithSuppliers: number().nullable(),
            numberOfEmployees: number()
                .min(1, 'Número de funcionários deve ser maior que 0')
                .required(`Número de funcionários: ${genericRequired}`)
                .typeError('Valor precisa ser um número.'),
            spendingAmountWithTax: number().nullable(),
        }),
        [StepLegalPersonForm.ContactInformation]: object().shape({
            phone: string().nullable(),
            phone2: string().nullable(),
        }),
        [StepLegalPersonForm.Address]: object().shape({
            address: object().shape({
                addressName: string()
                    .typeError('Endereço inválido.')
                    .required('Endereço: Precisa ser preenchido.'),
                zipCode: string()
                    .typeError('CEP inválido.')
                    .required('CEP: Precisa ser preenchido.'),
                city: string()
                    .typeError('Cidade inválida.')
                    .required('Cidade: Precisa ser preenchido.'),
                uf: string().typeError('UF inválida.').required('UF: Precisa ser preenchido.'),
                district: string()
                    .typeError('Bairro inválido.')
                    .required('Bairro: Precisa ser preenchido.'),
                number: string()
                    .typeError('Valor precisa ser um número.')
                    .required('Número: Precisa ser preenchido.'),
                complement: string().nullable(),
            }),
        }),
    };

    return schemas[step] ?? object().shape({});
}
