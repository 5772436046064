/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { Button, ClearCircleIcon, CloseIcon, RightIcon } from '@uy3/web-components';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useBankList } from 'contexts/bankList';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { BankTransferFieldsForm } from './FieldsForm/BankTransferFieldsForm';
import { PixFieldsForm, PixKeyValueType } from './FieldsForm/PixFieldsForm';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';
import { Fragment, useEffect, useState } from 'react';
import { IQueryBasicData } from 'contexts/personContext';
import { DiscriminatorType } from 'components/GenericForms/AddPayer/AddPayerFormContainer';
import { BankAccountFavoredFormContainerProps } from './BankAccountFavoredFormContainer';
import { isValidString } from 'helpers/formats/StringFormats';

type BankAccountFavoredFormProps = {
   discriminator: DiscriminatorType
} & BankAccountFavoredFormContainerProps;

type OperationeValueType = 'Transfer' | 'Pix';

const listFieldsToNotClean = [
   'registrationNumber', 
   'name', 
   'pixKeyTypeValue', 
   'operationTypeValue'
]

const theme = activeTheme();
export const BankAccountFavoredForm = ({
   onClose,
   getQueryBasicDataPerson,
   discriminator,
}: BankAccountFavoredFormProps) => {
   const [personData, setPersonData] = useState<IQueryBasicData | null>(null);
   const { submitting }= useApiRequest()
   const { watch, setValue, getValues } = useFormContext();

   const registrationNumber = String(watch('registrationNumber') ?? '');
   const operationTypeValue = watch('operationTypeValue') as OperationeValueType;
   const pixKeyTypeValue = watch('pixKeyTypeValue') as PixKeyValueType;
   const showComponentBankTransfer =
      operationTypeValue === 'Transfer' || pixKeyTypeValue === 'AgencyAndAccount';

   const { bankListAutocomplete } = useBankList({ compe: operationTypeValue === 'Transfer' });
  
   const isPixKeyTypeValueDocumentNumber = () =>  {
      if (discriminator === 'LegalPerson') {
         return pixKeyTypeValue === 'LegalRegistrationNumber'
      } else {
         return pixKeyTypeValue === 'NaturalRegistrationNumber'
      }
   }; 
   
   useEffect(() => {
      const formValues = getValues();

      if (!!operationTypeValue || !!pixKeyTypeValue) {
         Object.entries(formValues ?? {})?.forEach((field) => {
            const name: string = field[0];
            
            if (isPixKeyTypeValueDocumentNumber()) {
               setValue('keyPix', registrationNumber); 
            }

            if (!listFieldsToNotClean.includes(name)) {               
               if (isPixKeyTypeValueDocumentNumber() && name === 'keyPix') return; 
               setValue(name, null);
            }
         });
      }
   }, [operationTypeValue, pixKeyTypeValue])


   const onSuccess = (values: IQueryBasicData) => {
      setPersonData(values);
      switch (discriminator) {
         case 'LegalPerson':
            const legalPerson = values?.legalPerson;
            return setValue('name', legalPerson?.companyName	);
         case 'NaturalPerson':
            return setValue('name', values?.naturalPerson?.name);
      }
   };

   const onChangeRegistratioNumber = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = String(event.target.value)?.replace(/\D/g, '');
      setValue('registrationNumber', value);

      if ((value?.length === 11 && discriminator === 'NaturalPerson') || (value?.length === 14 && discriminator === 'LegalPerson')) {
         await getQueryBasicDataPerson(value, onSuccess);
      }

      if (!isValidString(value)) {
         return handleClearValues(); 
      }
   };

   const handleClearValues = () => {
      Object.entries(getValues() ?? {})?.forEach((value) => {
         setValue(value[0], null);
      });
      setPersonData(null);
   }

   const hasPersonData = Object.values(personData ?? {}).length > 0;

   return (
      <Stack spacing={3}>
         <TextFormField
            name="registrationNumber"
            label={discriminator === 'LegalPerson' ? 'CNPJ' : 'CPF'}
            variant="outlined"
            InputProps={{
               inputComponent: MaskedInput,
               inputProps: {
                  mask: registrationNumber.length > 11 ? cnpjMask : cpfMaskAndSpace,
               },
            }}
            onChange={onChangeRegistratioNumber}
            fullWidth
         />

         {hasPersonData && (
            <Fragment>
               <TextFormField
                  name="name"
                  variant="outlined"
                  label="Nome do favorecido"
                  fullWidth
               />

               <SelectFormField
                  name="operationTypeValue"
                  label="Tipo de Operação"
                  required
                  fullWidth
                  options={[
                     { label: 'Transferência', value: 'Transfer' },
                     { label: 'Pix', value: 'Pix' },
                  ]}
               />

               {operationTypeValue === 'Pix' && <PixFieldsForm />}

               {showComponentBankTransfer && (
                  <BankTransferFieldsForm
                     {...{
                        bankListAutocomplete,
                     }}
                  />
               )}
            </Fragment>
         )}

         <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
            <Button
               startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
               variant="outlined"
               size="medium"
               onClick={onClose}
            >
               Fechar
            </Button>
            {hasPersonData && <Button
               startIcon={
                  <ClearCircleIcon
                     htmlColor={theme.palette.primary.main}
                     sx={iconSx}
                  />
               }
               variant="outlined"
               size="medium"
               onClick={handleClearValues}
            >
               Limpar
            </Button>}
            <Button
               variant="contained"
               name="btn-submit"
               disabled={submitting || !hasPersonData}
               startIcon={
                  <RightIcon
                     htmlColor={!hasPersonData ? theme.palette.grey['500'] : theme.palette.common.white}
                     sx={iconSx}
                  />
               }
               type="submit"
            >
               Continuar
            </Button>
         </Stack>
      </Stack>
   );
};
