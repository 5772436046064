import { TextFormField } from 'components/Forms/FormFields';

interface CategorieFormFieldsProps {
    index: number;
}

const CategorieForm: React.FC<CategorieFormFieldsProps> = ({ index }) => {
    return (
        <TextFormField
            required
            fullWidth
            label="Descrição da categoria"
            name={`categories.${index}.category`}
            variant="outlined"
        />
    );
};

export default CategorieForm;
