import { AddIcon, Button, Typography, UpdateIcon } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { useUserPermissionData } from 'contexts/userContext';

interface IGroupListHeaderProps {
    isFetching: boolean;
    refetch: () => void;
}

const theme = activeTheme();
const GroupListHeader = ({ isFetching, refetch }: IGroupListHeaderProps) => {
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('UserGroup', 'Read');
    const color = !isFetching || !hasReadPermission ? theme.palette.common.black : theme.palette?.grey['400'];
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Grupos
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color: 'black' }}
                    variant="text"
                    disabled={isFetching || !hasReadPermission}
                    onClick={() => refetch()}
                    size="medium"
                    startIcon={<UpdateIcon sx={iconSx} htmlColor={color} />}
                    color="primary"
                >
                    Atualizar
                </Button>
                {hasPermission('UserGroup', 'Create') &&
                    <Link
                        to="/seguranca/grupos/novo"
                        style={{ textDecoration: 'none' }}
                        onClick={(e) => (!hasReadPermission ? e.preventDefault() : e)}
                    >
                        <Button
                            sx={{ color }}
                            variant="text"
                            size="medium"
                            disabled={isFetching || !hasReadPermission}
                            startIcon={<AddIcon sx={iconSx} htmlColor={color} />}
                        >
                            Novo grupo
                        </Button>
                    </Link>}
            </Grid>
        </Stack>
    );
};

export default GroupListHeader;
