/* eslint-disable sonarjs/prefer-single-boolean-return */
/* eslint-disable react/jsx-no-useless-fragment */
import type { FunctionComponent } from 'react';
import type { DataTableProps } from './DataTable.interface';
import { GridFilterModel } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { ArrowButtonLeft, ArrowButtonRight, CustomStack, MainBox, StyledDataGrid } from './DataTable.styled';
import HeaderBarWrapper from './HeaderBarWrapper';
import FilterComponent from './FilterComponent';
import { Typography } from '../../atoms';

const NoRowsOverlayNewMsg = (message: string) => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center" mb={3}>
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        {message}
      </Typography>
    </Stack>
  );
};

const NoResultsOverlayNewMsg = (message: string) => {
  return (
    <Stack height="100%" alignItems="center" justifyContent="center">
      <Typography variant="sm" color="neutral.medium" lineHeight="29px">
        {message}
      </Typography>
    </Stack>
  );
};

type TypeJump = "JumpFirst" | "JumpLast";

export const DataTable: FunctionComponent<DataTableProps> = ({
  NoRowsOverlayNew = 'Nenhum resultado encontrado',
  NoResultsOverlayNew = 'Nenhum resultado encontrado',
  filterComponent = {
    componentFilter: <></>,
    showFilters: false,
  },
  headerBarWrapper = {
    header: undefined,
    showSearch: false,
    onChangeSearch: () => { },
  },
  rows,
  setPage,
  setRowsPerPage,
  hideFooterSelectedRowCount = false,
  onSelectionModelChange,
  rowSelectionModel,
  keepNonExistentRowsSelected,
  page,
  rowsPerPage,
  columns,
  rowCount,
  pageCount,
  paginationMode = 'server',
  getRowId = (row) => row.id,
  loading = false,
  enableJumpAction = true, 
  ...props
}: DataTableProps) => {
  const [filterModel, setFilterModel] = useState<GridFilterModel | undefined>(undefined);

  const handleFilterModelChange = (model: GridFilterModel, _: any) => {
    setFilterModel(model);
  };

  const jumpToPage = (type: TypeJump) => {
    const totalPages = Math.ceil(rowCount ?? 0 / rowsPerPage);
    const jumpOption: { [type: string]: () => void } = {
      "JumpFirst": () => setPage(0),
      "JumpLast": () => setPage(totalPages)
    };
    return jumpOption[type]();
  };

  const disableActionJump = (type: TypeJump): boolean => {
    const isDisable = pageCount === 0 || pageCount === undefined || pageCount === null;
    const option: { [type: string]: boolean } =
    {
      "JumpFirst": page === 0,
      "JumpLast": pageCount === page! + 1 || isDisable
    };
    return option[type];
  };

  const style = (type: TypeJump) => {
    return {
      height: 25,
      width: 25,
      color: disableActionJump(type) ? '#BDBDBD' : '#252525'
    };
  };

  const rowsPerPageOptions = [5, 10, 15, 20];

  return (
    <CustomStack>
      {(isEmpty(headerBarWrapper.header) && !headerBarWrapper.showSearch) && (
        <FilterComponent {...filterComponent} />
      )}
      <MainBox height={Math.max((rowsPerPage * 35) + 190, 350)}>
        <StyledDataGrid
          rows={rows}
          rowHeight={40}
          columns={columns}
          disableColumnMenu
          onPageSizeChange={setRowsPerPage}
          onPageChange={setPage}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          pageSize={rowsPerPage}
          getRowId={getRowId}
          paginationMode={paginationMode}
          rowCount={rowCount}
          disableSelectionOnClick
          hideFooter={hideFooterSelectedRowCount}
          onSelectionModelChange={onSelectionModelChange}
          keepNonExistentRowsSelected={keepNonExistentRowsSelected}
          selectionModel={rowSelectionModel}
          hideFooterPagination={rows?.length === 0}
          hideFooterSelectedRowCount={rows?.length === 0}
          filterModel={filterModel}
          onFilterModelChange={handleFilterModelChange}
          components={{
            Toolbar: () => HeaderBarWrapper(headerBarWrapper),
            ColumnSortedAscendingIcon: KeyboardArrowUpIcon,
            ColumnSortedDescendingIcon: KeyboardArrowDownIcon,
            NoRowsOverlay: () => NoRowsOverlayNewMsg(NoRowsOverlayNew),
            NoResultsOverlay: () => NoResultsOverlayNewMsg(NoResultsOverlayNew),
          }}
          componentsProps={{
            pagination: {
              SelectProps: {
                MenuProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      fontSize: 14
                    }
                  }
                }
              }
            }
          }}
          {...props}
        />
      </MainBox>

      {(rows.length !== 0 && enableJumpAction )&& <>
          <ArrowButtonLeft
            disabled={disableActionJump('JumpFirst')}
            onClick={() => jumpToPage('JumpFirst')}
          >
            <FirstPageIcon 
              sx={style('JumpFirst')}
            />
          </ArrowButtonLeft>
          <ArrowButtonRight
            disabled={disableActionJump('JumpLast')}
            onClick={() => jumpToPage('JumpLast')}
          >
            <LastPageIcon
              sx={style('JumpLast')}
            />
          </ArrowButtonRight>
        </>}
    </CustomStack>
  );
};
