import { FundCreateModel } from 'services/fund/types/fundCreateModel';
import { CreditProductBaseModel } from './gererics';

export class CreditProductCreateModel extends CreditProductBaseModel {
    templatePath!: string;
    defaultFund!: FundCreateModel;

    constructor(init?: Partial<CreditProductCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}
