import { Drawer } from '@uy3/web-components'
import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesSelectOption, validationSchemaSelectOption } from './SelectOptionsSchema'
import { IQueryBasicData } from 'contexts/personContext'
import { SelectOptions } from './SelectOptions'
import { FieldValues } from 'react-hook-form'

type SelectOptionsContainerProps = {
    enable: boolean;
    queryData: IQueryBasicData;
    title?: string;
    onClose: () => void;
    toggleDrawer?: boolean, 
    onSubmit: (values: FieldValues) => void
}

export const SelectOptionsDrawerContainer: React.FC<SelectOptionsContainerProps> = ({
    enable,
    onClose,
    queryData,
    toggleDrawer = false, 
    title = "Informações",
    onSubmit
}) => {
    return (
        <Drawer
            anchor='right'
            toggleDrawer={toggleDrawer}
            open={enable}
            title={title}
            description='É necessário escolher uma das opções abaixo'
        >
            <FormProvider
                defaultValues={defaultValuesSelectOption}
                validationSchema={validationSchemaSelectOption(queryData)}
                onSubmit={onSubmit}
            >
                <SelectOptions
                    onClose={onClose}
                    queryData={queryData}
                />
            </FormProvider>
        </Drawer>
    )
}
