import { FieldValues } from 'react-hook-form';
import { StepNewChargesButton } from '../../StepsButtons/StepNewChargesButton';
import { SummaryCharges } from './SummaryCharges';
import { useFormContext } from 'contexts/formContext';

interface NewChargesStepProps {
    currentStep: number;
    onNextStep: () => void;
    continueCriatting: (values: FieldValues) => void
    onSaveAndDoNotChargeNow: (values: FieldValues) => void;
    handleClose: () => void;
}

export const SummaryChargesStep: React.FC<NewChargesStepProps> = ({
    currentStep,
    onNextStep,
    onSaveAndDoNotChargeNow,
    handleClose,
    continueCriatting
}) => {
    const { getValues, setValue } = useFormContext();
    const formValues = getValues();

    const saveAndDoNotChargeNow = () => onSaveAndDoNotChargeNow(formValues);
    const continueCriattingNow = async () => {
        const data = await continueCriatting(formValues) as any;

        Object.entries(data ?? {}).forEach((item, indice) => {
            const name = item[0]; 
            const value = item[1];
            return setValue(name, value); 
        }) 
    };

    return (
        <>
            <SummaryCharges formValues={formValues} />
            <StepNewChargesButton {...{
                currentStep,
                onNextStep,
                saveAndDoNotChargeNow,
                continueCriatting: continueCriattingNow,
                onClose: handleClose,
                isReverseList: true
            }} />
        </>
    );
};
