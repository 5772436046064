import { Stack } from "@mui/system";
import { Button, Typography } from "@uy3/web-components";
import { Link } from "react-router-dom";
import { useFormContext } from "contexts/formContext";
import { TextFormField } from "components/Forms/FormFields/TextFormField/TextFormField";

export function ChangePasswordForm() {

	const { submitting } = useFormContext();

	return <>
		<Typography
			variant="h5"
			color="neutral.dark"
			lineHeight="34px"
			mb={2}
			fontWeight={400}
		>
			Alterar senha
		</Typography>
		<Stack direction="column" spacing={2} mb={2}>
			<TextFormField name="oldPassword" type="password" label="Senha atual" variant="outlined" fullWidth required />			
			<TextFormField name="password" type="password" label="Nova senha" variant="outlined" fullWidth required />
			<TextFormField name="passwordConfirmation" type="password" label="Confirmar nova senha" variant="outlined" fullWidth required />
			<Button
				type="submit"
                variant="contained"
				size="medium"
				color="info"
				disabled={submitting}
                fullWidth={true}
                sx={{ width: '100%' }}
              >
                Acessar
              </Button>
		</Stack>
		<Link to='/ChangePassword/recuperar-senha'>Esqueceu sua senha?</Link>
	</>
}