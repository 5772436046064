import { object, boolean } from 'yup';

export function validationSchemaTransferReceiptPdfForm(hasOpenBankAccount: boolean) {
  if (hasOpenBankAccount) {
    return object().shape({
      generateStatement: boolean().nullable(),
      generateRecepit: boolean()
        .typeError('Comprovante de transferência precisa ser selecionado ')
        .required("Comprovante de transferência precisa ser selecionado ")
    });
  }
  return object().shape({
    generateRecepit: boolean()
      .typeError('Comprovante de transferência precisa ser selecionado ')
      .required("Comprovante de transferência precisa ser selecionado ")
  });
};

export const defaultValuesTransferReceiptPdfForm = (hasOpenBankAccount: boolean) => {
  if (hasOpenBankAccount) {
    return {
      generateStatement: false,
      generateRecepit: false
    }
  }
  return {
    generateRecepit: false
  }
};