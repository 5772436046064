import { Stack } from '@mui/material'
import { Button, LeftIcon, RightIcon } from '@uy3/web-components';
import CardsSelect from 'components/CardSelect/CardSelect';
import { RefreshProgress } from 'components/RefreshProgress';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';

type SelectKeyPixFormProps = {
    onClose: () => void;
    isFething: boolean;
}
const theme = activeTheme();
export const SelectKeyPixForm = ({ onClose, isFething }: SelectKeyPixFormProps) => {
    const { watch, setValue } = useFormContext();
    return (
        <Stack spacing={2}>

            <CardsSelect
                onChange={({value, displayName}) => {
                    setValue('type', value);
                    setValue('typeDisplay', displayName);
                }}
                options={[
                    { label: "CNPJ", value: "LegalRegistrationNumber" },
                    { label: "CPF", value: "NaturalRegistrationNumber" },
                    { label: "Telefone", value: "Phone" }, 
                    { label: "Email", value: "Email" },
                    { label: "Automática", value: "Automatic" }
                ]}
                value={watch('type')}
            />

            <RefreshProgress refreshing={isFething} />

            <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Voltar
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{
                                height: 23,
                                width: 23,
                            }}
                        />
                    }
                    disabled={watch('type') === null || isFething}
                >
                    Validar chave pix
                </Button>
            </Stack>
        </Stack>
    )
}