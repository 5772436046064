import { styled } from '@mui/material/styles';
import { Autocomplete as MuiAutocomplete } from '@mui/material';

export const Autocomplete = styled(MuiAutocomplete)(({ theme }) => ({
  '& .MuiFormLabel-root': {
    color: theme.palette.neutral.medium,
  },
  '& .MuiChip-label': {
    fontSize: theme.typography.xs!.fontSize ?? '1rem',
    color: theme.palette.primary.main,
  },
  '& .MuiChip-root': {
    backgroundColor: '#F7E2DC',
    color: theme.palette.common.white,
    '& .MuiChip-deleteIcon': {
      color: theme.palette.primary.main,

      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
  },
  '& .MuiAutocomplete-inputRoot': {
    paddingTop: '0px',
    paddingBottom: '0px',
  },
}));
