import { Grid, Stack } from '@mui/material';
import { ApproveIcon, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import React, { FC } from 'react';
import { Actions } from 'components/Actions/Actions';

interface OperationBatchHeaderProps {
    showLiquidationApprove: (status: string) => boolean;
    onLiquidationApprove: () => void;
    refetch: () => void;
    isFetching: boolean;
}

const OperationBatchHeader: FC<OperationBatchHeaderProps> = ({
    showLiquidationApprove,
    onLiquidationApprove,
    refetch,
    isFetching,
}) => {
    const { watch } = useFormContext();

    return (
        <Stack direction="row" justifyContent="space-between" alignItems='center' my={2}>
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Operações
                </Typography>
            </Grid>
            <Actions
                buttonsActionsList={[
                    {
                        enable:
                            showLiquidationApprove('ManualLiquidation') &&
                            watch('status') === 'Finished',
                        label: 'Aprovar liquidação',
                        action: onLiquidationApprove,
                        icon: <ApproveIcon htmlColor={isFetching ? 'grey' : 'black'} sx={iconSx} />,
                    },
                    {
                        enable: true,
                        label: 'Atualizar',
                        action: refetch,
                        icon: <UpdateIcon htmlColor={isFetching ? 'grey' : 'black'} sx={iconSx} />,
                    },
                ]}
                numberOfButtons={2}
            />
        </Stack>
    );
};
export default OperationBatchHeader;
