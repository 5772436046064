import { Grid, Stack } from '@mui/material';
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { DatePickerFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { barCodeAndLineDigitableMask } from 'utils/constants';

type DarfWithBarCodeProps = {
    disable?: boolean;
    onClose: () => void;
    hideBtnClose?: boolean;
}

const theme = activeTheme();

export const DarfWithBarCodeForm = ({ disable, onClose, hideBtnClose }: DarfWithBarCodeProps) => {
    const { watch } = useFormContext();
    
    const isLegalPerson = watch('registrationNumber')?.replace(/\D/g, '').length > 11;
    return (
        <Stack spacing={2}>
            <Grid>
                <TextFormField
                    required
                    label="Informe o código de barras ou linha digitável"
                    name="barCode"
                    variant="outlined"
                    fullWidth
                    placeholder="0000.0000 0000.00000 0000.00000 0 000000000000000"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: {
                            mask: barCodeAndLineDigitableMask
                        }
                    }}
                />
            </Grid>
            <Grid>
                <TextFormField
                    name="registrationNumber"
                    variant="outlined"
                    label="CPF/CNPJ"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                    }}
                    fullWidth
                />
            </Grid>
            <Grid >
                <DatePickerFormField
                    label="Data de vencimento"
                    name="dueDate"
                    fullWidth
                    required
                />
            </Grid>
            <Stack
                direction={{ xs: 'column', sm: 'row' }}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                {!!hideBtnClose && <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>}
                <Button
                    variant="contained"
                    disabled={disable}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{
                                height: 23,
                                width: 23,
                            }}
                        />
                    }
                    sx={{ marginRight: -2 }}
                    type="submit"
                >
                    Consultar
                </Button>
            </Stack>
        </Stack>
    );
};
