import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const BarChartIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M12 3C13.1046 3 14 3.89543 14 5L14 19C14 20.1046 13.1046 21 12 21C10.8954 21 10 20.1046 10 19L10 5C10 3.89543 10.8954 3 12 3Z"
          stroke={htmlColor}
          strokeWidth="2"
        />
        <path
          d="M4 12C5.10457 12 6 12.8954 6 14L6 19C6 20.1046 5.10457 21 4 21C2.89543 21 2 20.1046 2 19L2 14C2 12.8954 2.89543 12 4 12Z"
          stroke={htmlColor}
          strokeWidth="2"
        />
        <path
          d="M20 8C21.1046 8 22 8.89543 22 10V19C22 20.1046 21.1046 21 20 21C18.8954 21 18 20.1046 18 19V10C18 8.89543 18.8954 8 20 8Z"
          stroke={htmlColor}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

BarChartIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
