import { createContext, useContext, useState } from 'react';

type EmailContextType = {
    email: string;
    setEmail: (email: string) => void;
};

const EmailContext = createContext<EmailContextType>({
    email: '',
    setEmail: () => {},
});

interface IProps {
    children: JSX.Element | JSX.Element[];
}

export const EmailProvider = ({ children }: IProps) => {
    const [email, setEmail] = useState<string>('');

    return <EmailContext.Provider value={{ email, setEmail }}>{children}</EmailContext.Provider>;
};

export function useEmail() {
    const context = useContext(EmailContext);
    const { email, setEmail } = context;
    return { email, setEmail };
}
