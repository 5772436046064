import { useAppConfig } from 'contexts/appConfig';
import { FormFieldsConfig } from 'helpers/methods/formFieldConfiguration';
import _ from 'lodash';

const GetLabelForField = (fieldName: string, defaultValue: string) => {
    const { appConfig } = useAppConfig();

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const label = getLabelByFieldName(fieldName, formFieldsConfig);

    return label ?? defaultValue;
};

export default GetLabelForField;

const getLabelByFieldName = (fieldName: string, formFieldsConfig: FormFieldsConfig): string | undefined => {
    const fieldValue = _.get(formFieldsConfig, fieldName);
    return fieldValue?.Label ?? undefined;
};