import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { BankSlipIcon, CancelIcon, DataTable, EditIcon, RegisterIcon, TransferIcon } from '@uy3/web-components';
import { InstallmentFiltersContainer } from 'components/DataTableFilters/InstallmentFilters/InstallmentFiltersContainer';
import { RenderColumnStatusBilling } from 'components/RenderColumnStatusBilling/RenderColumnStatusBilling';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { useNavigate } from 'react-router';
import { IInstallmentFull } from 'services/walletManagement/installment/installment.types';

type InstallmentListProps = {
  setRowsPerPage: (page: number) => void;
  setPage: (newPage: number) => void;
  queryData: any;
  rowsPerPage: number;
  page: number;
  isLoading: boolean
  handleAction: React.Dispatch<React.SetStateAction<{
    action: string | undefined;
    row?: IInstallmentFull;
  } | undefined>>
}

export const InstallmentList = ({ page, queryData, rowsPerPage, setPage, setRowsPerPage, isLoading, handleAction }: InstallmentListProps) => {
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'walletCode',
      headerName: 'Código do Convênio',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      renderCell: ({ value }) => value ?? "N/D"
    },
    {
      field: 'payer',
      headerName: 'Devedor',
      hideSortIcons: true,
      flex: 2,
      editable: false,
      renderCell: ({ row }) => row?.payer?.name ?? "N/D"
    },
    {
      field: 'secondInstructionDebtCollection',
      headerName: 'Instrução',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      renderCell: ({ row }) => {
        const isAutomatic = row?.firstInstructionDebtCollection === "06";
        const firstInstructionDebtCollectionDisplay = isAutomatic ? "Automático" : "Não envia protesto";
        return `${firstInstructionDebtCollectionDisplay} ${isAutomatic ? `| ${row?.secondInstructionDebtCollection}` : ""}`
      }
    },
    {
      field: 'billingCount',
      headerName: 'Parcelas',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      renderCell: ({ value }) => value ?? "N/D"
    },
    {
      field: 'createdAt',
      headerName: 'Criado em',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      renderCell: ({ value }) => new Date(value).toLocaleDateString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      })
    },
    {
      field: 'statusDisplay',
      headerName: 'Status',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      align: "center",
      headerAlign: 'center',
      renderCell: ({ value, row: {statusValue}, id }) => {
        return <RenderColumnStatusBilling {...{
          onClick: () => navigate(`/gestao-carteira/parcelamentos/${id}`),
          statusDisplay: value!,
          statusValue,
          resource: 'InstallmentList'
      }} />
      }
    },
    {
      field: 'actions',
      headerName: 'Ações',
      headerAlign: 'center',
      align: 'center',
      hideSortIcons: true,
      flex: 0,
      editable: false,
      renderCell: ({ row }) => {

        const redirectToForm = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
          event.stopPropagation();
          navigate(`/gestao-carteira/parcelamentos/${row?.id}`);
        }

        return <Stack direction='row' alignItems='center'>
          <IconButton onClick={(e) => redirectToForm(e)}>
            <BankSlipIcon />
          </IconButton>
          <RowActions
            listButtons={[
              {
                action: () => handleAction({ action: 'cancel', row }),
                label: "Cancelar",
                disabled: false,
                hide: false,
                icon: <CancelIcon sx={iconSx} />
              },
              {
                action: () => handleAction({ action: 'transfer', row }),
                label: "Transferir",
                disabled: false,
                hide: false,
                icon: <TransferIcon sx={iconSx} />
              },
              {
                action: () => handleAction({ action: 'register', row }),
                label: 'Registrar',
                hide: row.registered === true,
                disabled: false,
                icon: <RegisterIcon />
              }, 
              {
                action: () => handleAction({ action: 'edit', row }),
                label: 'Edição rápida',
                hide: row.registered !== true,
                disabled: false,
                icon: <EditIcon />
              }, 
            ]}
          />
        </Stack>
      },
    },
  ];
  
  return (
    <DataTable
      filterComponent={{
        showFilters: true,
        componentFilter: <InstallmentFiltersContainer />,
      }}
      sx={{
        '& .MuiDataGrid-row': {
            cursor: 'pointer',
        },
    }}
      columns={columns}
      rows={queryData !== undefined ? queryData?.data ?? [] : []}
      page={queryData ? queryData.page ?? 0 : page}
      rowCount={queryData?.totalItems ?? 0}
      pageCount={queryData?.totalPages ?? 0}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      NoRowsOverlayNew={isLoading ? 'Carregando...' : 'Nenhum resultado encontrado'}
      onRowClick={(row) => navigate(`/gestao-carteira/parcelamentos/${row?.id}`)}
    />
  )
}
