import { styled } from '@mui/material/styles';
import { Stack, Typography } from '@mui/material';

export const BannerBox = styled(Stack)(() => ({
  width: '100%',
  height: '136px',
  borderRadius: '8px',
  position: 'relative',
  background: 'linear-gradient(139.14deg, #FF7133 23.19%, rgba(242, 108, 32, 0.24) 104.35%)',
  '& .swiper-container': {
    width: '100%',
    height: '100%',
  },
  '& .swiper': {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },  
  '& .swiper-pagination': {
    bottom: '24px',
    left: '24px',
    zIndex: 5000,
    position: 'absolute',
    display: 'flex',
    '.swiper-pagination-bullet': {
      background: ' rgba(255, 255, 255, 0.46);',
      borderRadius: '4px',
      width: '32px',
      height: '4px',
      transition:' 0.8s',
    },
    '.swiper-pagination-bullet-active': {
      width: '32px',
      height: '4px',
      background: '#E5E5E5',
      borderRadius: '30px',
      transition: '0.6s',
    }
  },
  '& .swiper-slide': {
    textAlign: 'center',
    fontSize: '18px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    '& a': {
      display: 'flex',
      width: '100%',
      height: '100%',
      '& .MuiBox-root': {
        width: '100%',
        height: '100%',
        position: 'relative'
      }
    }
  },
  
  '& .swiper-slide img': {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }
}))

BannerBox.defaultProps = {
  direction: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start'
}

export const Title = styled(Typography)(({ theme }) => ({
  fontStyle: 'normal',
  ...theme.typography.sm,
  lineHeight: '29px',
  color: theme.palette.common.white,
  position: 'absolute',
  top: '24px',
  left: '24px',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'left',
  '& svg': {
    fontSize: '14px',
    marginLeft: '18px'
  },
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '22px'
  }
}))