import { string, object } from 'yup';

const genericMessage = 'Precisa ser preenchido.';

export function chatbotFiltersSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        invoiceValue: string().when('optionFilter', {
            is: 'invoiceValue',
            then: string().required(`Valor: ${genericMessage}.`).typeError('Valor inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        botPhone: string().when('optionFilter', {
            is: 'botPhone',
            then: string()
                .required(`Número de telefone: ${genericMessage}`)
                .typeError('Número de telefone é inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        productName: string().when('optionFilter', {
            is: 'productName',
            then: string()
                .required(`Nome do produto: ${genericMessage}`)
                .typeError('Nome do produto inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        personName: string().when('optionFilter', {
            is: 'personName',
            then: string().required(`Nome da pessoa: ${genericMessage}`).typeError('Nome da pessoa inválido.'),
            otherwise: string().nullable().notRequired(),
        }),
        baneficiaryName: string().when('optionFilter', {
            is: 'baneficiaryName',
            then: string().required(`Nome do beneficiário: ${genericMessage}`).typeError(`Nome do beneficiário: ${genericMessage}`),
            otherwise: string().nullable().notRequired(),
        }),
        serviceQueueName: string().when('optionFilter', {
            is: 'serviceQueueName',
            then: string().required(`Nome da fila: ${genericMessage}`).typeError(`Nome da fila: ${genericMessage}`),
            otherwise: string().nullable().notRequired(),
        })
    });
}

export const defaultValuesChatbotSchema = {
    optionFilter: 'productName',
    invoiceValue: null,
    botPhone: null,
    productName: null,
    personName: null,
    baneficiaryName: null,
    serviceQueueName: null,
};
