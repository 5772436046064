import { object, string } from "yup";

export const validationSchemaAddKeyPix = () => {
    return object().shape({
        type: string().typeError('Tipo de chave: Precisa ser preenchido.').required('Tipo de chave: Precisa ser preenchido.')
    });
}

export const defaultValuesAddPixKey = {
    type: null
};