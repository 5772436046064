import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const CloseIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <path
        d="M16.3941 16.3941L9.60589 9.60589M9.60588 16.3941L16.3941 9.60589M13 25C19.6274 25 25 19.6274 25 13C25 6.37258 19.6274 1 13 1C6.37258 1 1 6.37258 1 13C1 19.6274 6.37258 25 13 25Z"
        stroke={htmlColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill='none'
      />
    </SvgIcon>
  );
};

CloseIcon.defaultProps = {
  viewBox: '0 0 26 26',
  width: '26',
  height: '26',
  fill: 'none'
};
