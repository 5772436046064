import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react'

interface IGenericFormSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const GenericFormSkeleton: React.FC<IGenericFormSkeleton> = ({
    children,
    isLoading
}) => {
    if (!isLoading) return children;

    return (
        <Stack spacing={6}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='400px'
                    height={40}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='600px'
                    height={40}
                    sx={{
                        borderRadius: '5px'
                    }}
                />
            </Stack>
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={200}
                sx={{
                    borderRadius: '5px'
                }}
            />
            <Skeleton
                variant='rectangular'
                animation='pulse'
                width='100%'
                height={65}
                sx={{
                    borderRadius: '5px'
                }}
            />
            <Stack sx={{
                display: 'grid',
                pt: 10, 
                gridTemplateColumns: 'auto auto auto auto ',
                flexWrap: 'wrap',
                gap: 5
            }}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(item => {
                    return (
                        <Skeleton
                            variant='rectangular'
                            animation='pulse'
                            width='100%'
                            height={65}
                            sx={{
                                margin: '20px 0', 
                                borderRadius: '5px'
                            }}
                        />
                    )
                })}
            </Stack>
        </Stack>
    )
}

export const GenericFormSkeletonSuspense = () => <GenericFormSkeleton isLoading={true} children={<></>}/>