import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const LowGraphIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2H3ZM22 23C22.5523 23 23 22.5523 23 22C23 21.4477 22.5523 21 22 21V23ZM15.5 14C14.9477 14 14.5 14.4477 14.5 15C14.5 15.5523 14.9477 16 15.5 16V14ZM18.2764 14.4472L17.382 14L18.2764 14.4472ZM19.8944 13.4472C20.1414 12.9532 19.9412 12.3526 19.4472 12.1056C18.9532 11.8586 18.3526 12.0588 18.1056 12.5528L19.8944 13.4472ZM16.6637 15.0483L17.2119 15.8846L18.8846 14.7881L18.3363 13.9517L16.6637 15.0483ZM14.7644 10.3271L13.9281 10.8754L14.7644 10.3271ZM13.0726 10.3576L12.2171 9.83973L13.0726 10.3576ZM11.1644 13.5099L10.309 12.9921L11.1644 13.5099ZM9.43044 13.4698L8.55192 13.9475L9.43044 13.4698ZM7.87852 8.5223C7.6147 8.03711 7.0075 7.85765 6.5223 8.12148C6.03711 8.3853 5.85765 8.9925 6.12148 9.4777L7.87852 8.5223ZM1 2V18H3V2H1ZM6 23H22V21H6V23ZM1 18C1 20.7614 3.23858 23 6 23V21C4.34315 21 3 19.6569 3 18H1ZM15.5 16H17.382V14H15.5V16ZM19.1708 14.8944L19.8944 13.4472L18.1056 12.5528L17.382 14L19.1708 14.8944ZM17.382 16C18.1395 16 18.832 15.572 19.1708 14.8944L17.382 14V16ZM18.3363 13.9517L15.6007 9.77889L13.9281 10.8754L16.6637 15.0483L18.3363 13.9517ZM12.2171 9.83973L10.309 12.9921L12.0199 14.0278L13.9281 10.8754L12.2171 9.83973ZM10.309 12.9921L7.87852 8.5223L6.12148 9.4777L8.55192 13.9475L10.309 12.9921ZM10.309 12.9921L10.309 12.9921L8.55192 13.9475C9.28944 15.3039 11.2204 15.3486 12.0199 14.0278L10.309 12.9921ZM15.6007 9.77889C14.7939 8.54822 12.9792 8.58085 12.2171 9.83973L13.9281 10.8754L13.9281 10.8754L15.6007 9.77889Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

LowGraphIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24'
};
