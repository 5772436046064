import { boolean, object, string } from "yup";

const genericRequired = 'Esse campo é obrigatório.';

export const validationApproveLiquidationSchema = () => {
    return object().shape({
        cosifAccountCode: string().required(genericRequired).typeError(genericRequired),
        creditAccountId: string().nullable(),
        isAutomaticBankTransfer: boolean().required(genericRequired).nullable()
    });
};

export const defaultValuesApproveLiquidation = {
    cosifAccountCode: "",
    creditAccountId: null,
    isAutomaticBankTransfer: false
};