import { Stack } from '@mui/material'
import { Button, CardData, CloseIcon, DownloadIcon, Item, Typography } from '@uy3/web-components'
import InfoMessage from 'components/InfoMessage/InfoMessage'
import { ToastType, iconSx } from 'contexts/apiRequestContext'
import { isEmpty } from 'lodash'
import { formatCurrencyInCents } from 'helpers/formats/Currency'
import moment from 'moment'
import { RenderBarCode } from 'pages/WalletManagement/Billing/BillingForm/Drawers/ViewCharges/RenderBarCode'
import { activeTheme } from 'services/theme'
import { LinesFileTypeGeneric } from 'services/walletManagement/bankSlip/bankSlip.types'

type ShowDetailsFilesProps = {
    dataQuery: LinesFileTypeGeneric;
    recordType: "FileInstruction" | "FileConfirmation"
    onClose: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    handleActionDownload: () => void;
}

const theme = activeTheme();
export function ShowDetailsFiles({ onClose, dataQuery, recordType, setToast, handleActionDownload }: ShowDetailsFilesProps) {
    const detail = dataQuery?.detail;
    const isFileInstruction = recordType === 'FileInstruction';

    const listItems: Item[] = [
        {
            id: "1",
            title: "Status",
            value: detail?.statusOperationValueDisplay ?? "N/D"
        }
    ];

    if (isFileInstruction && detail?.error !== null) {
        listItems.push({ id: "17", title: "Erro", value: detail?.error ?? "N/D" })
    }
    return (
        <Stack spacing={2}>
            <Typography variant='md' fontWeight='bold'>Detalhes</Typography>
            {recordType === 'FileInstruction' && <Stack>
                <CardData
                    listItem={listItems}
                />
            </Stack>}
            <Stack>
                <CardData
                    listItem={[
                        { id: "1", title: "Número sequencial", value: dataQuery?.sequentialNumber ?? "N/D" },
                        { id: "2", title: "Instrução", value: detail?.statusOperationValueDisplay ?? "N/D" },
                        { id: "3", title: "Código de barras", value: <RenderBarCode barCode={detail?.barCode} setToast={setToast} /> },
                        { id: "4", title: "Número carteira", value: detail?.walletNumber ?? "N/D" },
                        { id: "6", title: "Pagador", value: detail?.payerName ?? "N/D" },
                        { id: "7", title: "Criado em", value: moment(detail?.createdAt).format('L') },
                        { id: "7", title: "Data de vencimento", value: moment(detail?.dueDate + "T00:00:00").format('L') },
                        { id: "8", title: "Número do documento", value: detail?.documentNumber ?? "N/D" },
                        { id: "9", title: "Nosso número", value: detail?.ourNumberDigit ?? "N/D" },
                        { id: "10", title: "Controle do participante", value: detail?.participantControlNumber ?? "N/D" },
                        { id: "11", title: "Valor", value: formatCurrencyInCents(detail?.amount) },
                        { id: "12", title: "Valor desconto", value: formatCurrencyInCents(detail?.discount) },
                        { id: "13", title: "Valor abatimento", value: formatCurrencyInCents(detail?.deduction) },
                        { id: "14", title: "Multa", value: detail?.fineTypeValueDisplay ?? "N/D" },
                        { id: "15", title: "Valor da multa", value: detail?.fineTypeValue === 'Percent' ? `${detail?.fineDecimal / 100}%` : formatCurrencyInCents(detail?.fineDecimal) },
                    ]}
                />
            </Stack>
            <Stack direction='row' alignItems='center' spacing={2} justifyContent='end'>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                >
                    Fechar
                </Button>
                <Button
                    variant='contained'
                    disabled={!isEmpty(detail?.error) || isEmpty(detail?.bankSlipId) || isEmpty(detail?.barCode)}
                    onClick={handleActionDownload}
                    startIcon={<DownloadIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                >
                    {recordType === 'FileInstruction' ? "Baixar boleto" : "Baixar recibo"}
                </Button>
            </Stack>

            <InfoMessage
                title='Informação sobre o boleto baixado'
                message='Este documento representa a versão atual do boleto e não deve ser confundido com a instrução de pagamento original.'
            />
        </Stack>
    )
}
