import { Error } from "components/Errors/Error";
import { Button, DataTable } from "@uy3/web-components";
import { Link } from "react-router-dom";
import Stack from "@mui/material/Stack";
import { GridColDef } from "@mui/x-data-grid";
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { GetListApiResponse } from "contexts/apiRequestContext";
import { CreditNoteReadModel } from "services/creditNote/types/creditNoteReadModel";
import { formatCurrencyInCents, formatPercentage } from "helpers/formats/Currency";
import { Grid } from "@mui/material";

type OperationListProps = {
    queryData: GetListApiResponse<CreditNoteReadModel>;
    rowsPerPage: number;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    columns?: GridColDef[]
};

const OperationList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    columns
}: OperationListProps) => {
    if (queryData.type === 'error') return <Error error={queryData} />;

    const creditNotesColumnList: GridColDef[] = columns ?? [
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '4px',
                        }}
                    >
                        {`#${cellValues.value}`}
                    </div>
                );
            },
        },
        {
            field: 'personDisplay',
            headerName: 'Tomador',
            hideSortIcons: true,
            minWidth: 210,
            flex: 1,
            editable: false
        },
        {
            field: 'amortization.principalAmountInCents',
            headerName: 'Valor',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => formatCurrencyInCents(cellValues?.row?.amortization?.principalAmountInCents)
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
        },
        {
            field: 'amortization.apr',
            headerName: 'Taxa',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => formatPercentage(cellValues?.row?.amortization?.apr)
        },
        {
            field: 'createdAt',
            headerName: 'Criado em',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => cellValues && new Date(cellValues.value).toLocaleDateString('pt-BR')
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            editable: false,
            renderCell: (cellValues: any) => {

                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent='center'
                        alignItems='center'
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={`/ccb/operacoes/${cellValues.id}`}>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={
                                    <SearchOutlinedIcon
                                        sx={{ height: 20, width: 20, ml: 1 }}
                                    />}
                            />
                        </Link>
                    </Stack>
                );
            }
        },
    ].filter(Boolean);

    return (
        <DataTable
            columns={creditNotesColumnList}
            rows={queryData?.data ?? queryData}
            page={queryData ? queryData?.page : page}
            rowCount={queryData?.totalItems}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );

};

export default OperationList;