import { toIsoStringWithTimezone } from 'helpers/formats/DateFormat';
import { object, string, array, date } from 'yup';

export const tokenObjectToSchema = object().shape({
    warrantyRegistrationOffice: string()
        .typeError('Orgão de averbação: Precisa ser preenchido')
        .required('Orgão de averbação: Precisa ser preenchido'),
    tokenType: string()
        .typeError('Tipo do token: Precisa ser preenchido')
        .required('Tipo do token: Precisa ser preenchido'),
    tokenValue: string()
        .typeError('Token: Precisa ser preenchido')
        .required('Token: Precisa ser preenchido'),
    expirationDate: date()
        .typeError('Data inválida')
        .required('Data de expiração: Precisa ser preenchido')
        .min(new Date(), 'Data de expiração: Não pode ser menor ou igual a data atual'),
});

export function validationSchemaReserveTokenForm() {
    return object().shape({
        details: array().of(tokenObjectToSchema).nullable(),
    });
}

const currentDateWithSetHours = new Date().setHours(0, 0, 0, 0);
export const defaultValuesReserveTokenForm = {
    details: [
        {
            token: null,
            warrantyRegistrationOffice: null,
            expirationDate: toIsoStringWithTimezone(new Date(currentDateWithSetHours)),
            warrantyCode: null,
        },
    ],
};
