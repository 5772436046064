import { styled } from '@mui/material/styles';
import { Button as MuiButton, Stack } from '@mui/material';

export const Button = styled(MuiButton)(({ theme }) => ({
  border: `0.5px solid rgba(208, 77, 39, 0.5)`,
  borderRadius: '8px',
  padding: 0
}))

export const BoxContent = styled(Stack)(() => ({
  padding: '12px 8px 8px 8px',
}))

BoxContent.defaultProps = {
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  direction: 'column',
  width: 'fit-content',
  maxWidth: '200px'
}