/* eslint-disable @typescript-eslint/no-use-before-define */
import { AnyObject } from 'yup/lib/types';
import { BasicAmortization, DefaultSchemaBasicAmortization, ValidationSchemaBasicAmortization } from './BasicAmortization';
import { RequiredStringSchema } from 'yup/lib/string';
import { boolean, number, string } from 'yup';
import { EPeriodicity, ECalculateByValueType } from 'utils/enums';


export abstract class Amortization extends BasicAmortization {
    public calculateByValueType?:
        | ECalculateByValueType
        | string
        | RequiredStringSchema<string | undefined, AnyObject>;
    public paymentDay?: number;
    public absAmortizationInMonths?: number;
    public absInterestInMonths?: number;
    public periodicity?: EPeriodicity | string | any;
    public indexer?: string;
    public indexerValue?: number;
    public daysInYear?: number;
    public firstPaymentInterest: boolean = true;
}

export class DefaultSchemaAmortization extends DefaultSchemaBasicAmortization implements Amortization {
    public calculateByValueType: ECalculateByValueType | string = ECalculateByValueType[ECalculateByValueType.Gross];
    public paymentDay = new Date().getDate();
    public absAmortizationInMonths = 0;
    public absInterestInMonths = 0;
    public periodicity: EPeriodicity | string = EPeriodicity[EPeriodicity.Monthly];
    public indexer = '';
    public indexerValue = 0;
    public daysInYear = 360;
    public firstPaymentInterest = true;
}


const genericRequired = 'Precisa ser preenchido.';
export class ValidationSchemaAmortization extends ValidationSchemaBasicAmortization {
    public calculateByValueType = string()
        .oneOf(['Gross', 'Liquid', 'Payment'], 'Selecione um tipo de cálculo.')
        .required('Selecione um tipo de cálculo.');
    public paymentDay = number()
        .required(`Dia de vencimento: ${genericRequired} `)
        .typeError('Valor precisa ser um número.')
        .min(1, 'Informe o dia de vencimento.')
        .max(31, 'Informe o dia de vencimento.');
    public absAmortizationInMonths = number()
        .typeError('Valor precisa ser um número.')
        .required(`Carência de amortização: ${genericRequired}`);
    public absInterestInMonths = number()
        .typeError('Valor precisa ser um número.')
        .required(`Carência de juros: ${genericRequired}`);
    public periodicity = string()
        .oneOf(['Daily', 'Monthly', 'Yearly'], 'Selecione uma periodicidade.')
        .required('Selecione uma periodicidade.');
    public daysInYear = number()
        .typeError('Valor precisa ser um número.')
        .required('Selecione a base de cálculo.');
    public firstPaymentInterest = boolean();
}