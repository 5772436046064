import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useLocation, useParams } from 'react-router';
import DatasetWrapper from '../DatasetWrapper';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';
import { Alert } from '@uy3/web-components';
import ScrList from './ScrList';
import { useMemo, useState } from 'react';
import Toast from 'components/Toast/Toast';

function formatYearMonthToMonthYear(date: string | null) {
    return date != null ? date.substring(5) + '/' + date.substring(0, 4) : 'N/D';
}

type SCRDataProps = {
    id?: string | undefined;
    discriminator?: string | undefined;
    disableRequestDataSet: boolean;
};

export function SCRData({ id, discriminator, disableRequestDataSet }: SCRDataProps) {
    const [toast, setToast] = useState<ToastType>(toastState);
    const { pathname } = useLocation();
    const isNaturalPerson = pathname.includes('pessoas-fisicas');

    const recordType =
        isNaturalPerson || discriminator === 'NaturalPerson' ? 'NaturalPerson' : 'LegalPerson';
    const { personId } = useParams();
    const dataId = id ?? personId;

    const { dataset, status, refetch } = useDatasetData(recordType, dataId!, 'ScrBcb');
    const { setSubmitError } = useApiRequest();

    const onSuccess = () => refetch();
    const onError = (error: ApiResponseError) => setSubmitError(error);

    const { mutate, isLoading } = useDatasetMutation(recordType, dataId!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'scr_bcb') as any;
    const result = data?.result;

    let fluxoConsolidado = result?.fluxoConsolidado;
    let msgs = result?.listaDeMensagensDeValidacao;
    let dataSCR = data?.result?.dataBaseConsultada;
    let error = result?.Status?.scr_bcb && result?.Status.scr_bcb[0]?.Message;

    useMemo(() => {
        if (status === 'success') {
            const title = 'SRC consultado com sucesso!';
            const description = 'Ótimo! agora você pode visualizar o registro consultado.';
            showSuccessToast(title, description, setToast);
        } else if (status === 'error') {
            const title = 'Ops, ocorreu um erro ao consultar o SCR!';
            const description = error;
            showErrorToast(title, description, setToast);
        }
    }, [status, error]);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />

            {msgs &&
                msgs.map((m: any) => (
                    <Alert severity="info" text={`${m.codigo} - ${m.mensagem}`} />
                ))}
            {error && <Alert severity="error" text={error} />}

            {dataSCR && (
                <HandleTitle
                    title="Data-Base"
                    value={formatYearMonthToMonthYear(dataSCR)}
                    key="dataBaseConsultada"
                />
            )}

            {fluxoConsolidado && <ScrList fluxoConsolidado={fluxoConsolidado} />}

            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('ScrBcb')}
                isLoading={isLoading || status === 'loading'}
            />
        </>
    );
}
