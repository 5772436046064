import { string, object, boolean, date } from 'yup';

export function validationBatchAssignmentFormSchema() {
    return object().shape({
        fundId: string().typeError("Fundo precisa ser preencido").required("Fundo: precisa ser preencido"),
        assignmentDate: date().typeError("Data de atribuição precisa ser preencida").required("Data de atribuição precisa ser preencida."),
        creditDate: date().typeError("Data de atribuição precisa ser preencida").required("Data de atribuição precisa ser preencida."),
        sendEmail: boolean().nullable(),
        observations: string().required("Observações precisa ser preenchda").required("Observações: precisa ser preenchda"),
        initialValue: string().required("Valor inicial precisa ser preenchda").required("Valor inicial: precisa ser preenchda"),        
    });
};

export const defaultValuesBatchAssignmentForm = {
    fundId: null,
    observations: "", 
    assignmentDate: null, 
    initialValue: null, 
    creditDate: null, 
    accruedInterest: null, 
    sendEmail: false 
};
