import { IconButton } from '@mui/material';
import { Typography, UpdateIcon } from '@uy3/web-components';
import Spinner from 'components/RiskScore/DatasetsStatusColumn/Spinner';
import { iconSx } from 'contexts/apiRequestContext';
import { useBankAccountBalance } from 'contexts/bankAccount/bankAccountContext';
import { useShowValues } from 'contexts/showValuesContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';

type BankAccountGetBalanceType = {
    accountId: string;
    onlyRefresh?: boolean
}

export const BankAccountGetBalance = ({ accountId, onlyRefresh = false }: BankAccountGetBalanceType) => {
    const { showValues } = useShowValues();

    const { data: bankAccountData, isLoading, isFetching, refetch } = useBankAccountBalance(accountId!);

    if (isFetching && isLoading && !onlyRefresh) {
        return <Spinner />
    }

    const balance = bankAccountData?.available;

    return (
        <>
            {onlyRefresh ? (
                <IconButton onClick={() => refetch()} title='Atualizar saldo'>
                    <UpdateIcon sx={iconSx} />
                </IconButton>
            ) : (
                <Typography variant="xs" fontWeight="400" fontFamily='"Lato", sans-serif' color="#00CD2D">
                    {showValues ? formatCurrencyInCents(balance) : (
                        <div style={{ fontWeight: 'bold', fontSize: '17px', }}>
                            - - - - - - - - - -
                        </div>
                    )}
                </Typography>
            )
            }
        </>
    );
};
