import { EAmortizationType } from 'services/creditNote/enum';
import {
    EInstrumentType,
    ESignaturePortalProvider,
    EWarrantyRegistrationOffice,
    EWarrantyType,
} from '../enums';
import { IUploadModel } from 'contexts/apiRequestContext';
import { ESignatureType, ESignatureValidation, EViewType, ETypeOfRelationship } from 'utils/enums';
import { ICommissionModel } from 'utils/interface';
import { IComplianceRules } from './creditProductReadModel';

export interface ICreditProductRequiredDocModel {
    Id?: string;
    productId?: string;
    personType: string;
    fileType: string;
    group: number;
    typeOfRelationship: string;
}

export interface ICreditProductRelatedPersonModel {
    Id?: string;
    productId?: string;
    personId: string;
    typeOfRelationship: ETypeOfRelationship;
    signatureType: ESignatureType;
    signatureValidation: ESignatureValidation;
}

export interface IDebitBankAccountModel {
    productId?: string;
    fundId: string;
    debitAccountExternalId: string;
    cosifBankAccount?: string;
}

export interface ICommissionRangeModel {
    Id?: string;
    minimumPrincipalAmount: number;
    maximumPrincipalAmount: number;
    details: ICommissionRangeDatailsCreateModel[];
}

export interface ICommissionRangeDatailsCreateModel extends ICommissionModel {
    name: string;
    isCustomerRegistrationFee: boolean;
}

export class CreditProductBaseModel {
    instrumentType?: EInstrumentType;
    name!: string;
    categoryName!: string;
    code?: number;
    templateDoc!: IUploadModel;
    amortizationType!: EAmortizationType;
    warrantyType!: EWarrantyType;
    numberDaysValidityCustomerRegistration?: number;
    maximumAbsAmortizationPeriods?: number;
    maximumAbsInterestPeriods?: number;
    maximumAmortizationPeriods?: number;
    minimumAmortizationPeriods?: number;
    minimumEmissionCostInCents?: number;
    maximumEmissionCostInCents?: number;
    defaultFundId?: string;
    minimumInterestRate!: number;
    maximumInterestRate!: number;
    minimumPrincipalAmount!: number;
    maximumPrincipalAmount!: number;
    cosifBankAccount!: string;
    requireGuarantor: boolean = false;
    bankComission!: ICommissionModel;
    commissionRange: ICommissionRangeModel[] = [];
    automaticComplianceAnalysisExecution: boolean = false;
    defaultComplianceAnalysisGroup?: number;
    relatedPerson: ICreditProductRelatedPersonModel[] = [];
    requiredDoc: ICreditProductRequiredDocModel[] = [];
    allowOwnFund: boolean = false;
    maximumValueWithoutDigitalSignature!: number;
    instrumentApproval: boolean = false;
    draftGeneration: boolean = false;
    useSignaturePortal: boolean = false;
    signatureType!: ESignatureType;
    selfieSignature: boolean = false;
    selfieDocumentSignature: boolean = false;
    facialBiometrics: boolean = false;
    signatureValidation!: ESignatureValidation;
    signatureValidationStep: boolean = false;
    defaultCreditAnalysisGroup?: number;
    automaticCreditApproval: boolean = false;
    automaticCreditAnalysisExecution: boolean = false;
    automaticComplianceApproval: boolean = false;
    requireWitness: boolean = false;
    viewType!: EViewType;
    limitOpToPerson?: boolean;
    allowFinanceTaxExemption: boolean = false;
    financeFinTax: boolean = false;
    financeEmissioncost: boolean = false;
    debitBankAccount!: IDebitBankAccountModel;
    signaturePortalProvider!: ESignaturePortalProvider;
    useCollateral: boolean = false;
    defaultCollateralDescription!: string;
    openBankAccount: boolean = false;
    isConsumerCreditNote: boolean = false;
    timeToSendToSignatures?: number;
    timeLimitToSign?: number;
    timeLimitToSubmitApproval?: number;
    timeLimitToApprove?: number;
    maximumAutomaticLiquidationValueInCents!: number;
    cvmRegistrationNumber!: bigint;
    cvmRegistrationNumberIndex!: number;
    cvmRegistrationDate?: Date;
    maximumValueWithoutSignatureValidation?: number;
    notifySigner: boolean = true;
    unicoTemplateName!: string;
    unicoMinimumScoreForSignatureValidation?: number;
    manualWarranty?: boolean;
    liquidationApprovalTenantName!: string;
    complianceApprovalTenantName!: string;
    creditProductModalityId!: string;
    creditProductSubModalityId!: string;
    signatureApprovalTenantName!: string;
    warrantyRegistrationOffice!: EWarrantyRegistrationOffice;
    paymentFixedCosts?: number;
    insuranceCoverAmount?: number;
    payrollAgreementIds: string[] = [];
    creditNotePostingDeadline?: string | Date;
    termStartDate?: string | Date;
    termEndDate?: string | Date;
    complianceRules?: IComplianceRules[];
    useReleaseToPaymentTrigger?: boolean;
    releaseToPaymentTriggerLimitInDays?: number;
    termStatus?: string;

    constructor(init?: Partial<CreditProductBaseModel>) {
        Object.assign(this, init);
    }
}
