import { CancelIcon, Typography } from '@uy3/web-components';
import { SelectedValues } from './CurrentValuesSelected';
import { renderLabel, renderRangeValue, renderValue } from 'helpers/formats/StringFormats';
import { IconButton } from '@mui/material';
import { activeTheme } from 'services/theme';

type ShowSelectedValuesType = {
    removeFilterSelected: () => void;
    item: any;
    label: string;
};

const theme = activeTheme();

export const ShowSelectedValues = ({
    label,
    item,
    removeFilterSelected,
}: ShowSelectedValuesType) => {
    return (
        <SelectedValues>
            {renderTypography(item, label)}
            <IconButton onClick={removeFilterSelected}>
                <CancelIcon
                    style={{ height: 15, width: 15 }}
                    htmlColor={theme.palette.primary.main}
                />
            </IconButton>
        </SelectedValues>
    );
};

interface Item {
    label?: string;
    value?: Date | string;
    rangeValue?: string;
}

export const renderTypography = (item: Item, label: string | undefined): JSX.Element => {
    return (
        <Typography sx={{ fontSize: '14px' }}>
            {renderLabel(label ?? item.label)} &nbsp;
            {renderValue(item)}
            {renderRangeValue(item.rangeValue)}
        </Typography>
    );
};
