import { Stack, styled, Switch } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import React from 'react'
import { activeTheme } from 'services/theme';

export type SwitchFormFieldProps = {
    name: string;
    label?: string;
    useReverse?: boolean
}

const theme = activeTheme();

export const SwitchFormField = ({ label, name, useReverse = false }: SwitchFormFieldProps) => {
    const { setValue, watch } = useFormContext();
    return (
        <Stack direction={useReverse ? 'row-reverse' : 'row'} justifyContent={useReverse ? 'space-between' : 'start'}  alignItems='center' spacing={1}>
            <SwitchCustom
                id="simple-switch-label"
                onChange={(_: any, checked: boolean) => setValue(name, checked)}
                checked={watch(name)}
                name={name}
            />
            {label && <Typography variant='sm'>{label}</Typography>}
        </Stack>
    )
}

const SwitchCustom = styled(Switch)(() => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 1,
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.primary.main,
                opacity: 1,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        width: 24,
        height: 24,
    },
    '& .MuiSwitch-track': {
        borderRadius: 13,
        backgroundColor: '#bdbdbd',
        opacity: 1,
    },
}));