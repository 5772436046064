import { object, string } from 'yup';

type ValidationSchemaType = {
    entity: any, 
    uniqueTenant: boolean
}

export function validationSchemaCloneWithTenant({entity, uniqueTenant}: ValidationSchemaType) {
    if (uniqueTenant === true) {
        return object().shape({
            tenant: string()
                .typeError('Correspondente: Precisa ser preenchido')
                .required('Correspondente: Precisa ser preenchido')
                .test('same-info', 'Correspondente não pode ser o mesmo do registro atual', (value: any) => {
                    return entity?.tenant !== value;
                }),
        });
    }
    return object().shape({
        tenant: string()
            .typeError('Correspondente: Precisa ser preenchido')
            .required('Correspondente: Precisa ser preenchido')
    });


};

type DefaultType = {
    entity: any, 
    hasDefaultValue: boolean
}

export const defaultValuesCloneWithTenant = ({ entity, hasDefaultValue }: DefaultType) => {
    if (hasDefaultValue) {
        return {
            tenant: entity.tenant, 
            tenantDisplay: entity.tenantDisplay, 

        }
    }
    return {
        tenant: null
    }
};