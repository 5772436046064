import { Button, Typography, AddIcon, UpdateIcon } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { useUserPermissionData } from 'contexts/userContext';

interface IUserHeaderProps {
    isFetching: boolean;
    refetch: () => void;
}

const theme = activeTheme();
const UserHeader = ({ isFetching, refetch }: IUserHeaderProps) => {
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('User', 'Read');
    const color = !isFetching || !hasReadPermission ? theme.palette.common.black : theme.palette?.grey['400'];
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Operadores
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color }}
                    variant="text"
                    disabled={isFetching || !hasReadPermission}
                    onClick={refetch}
                    size="medium"
                    type="submit"
                    color="primary"
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                >
                    Atualizar
                </Button>
                {hasPermission('User', 'Create') && <Link
                    to="/seguranca/usuarios/novo"
                    style={{ textDecoration: 'none' }}
                    onClick={(e) => (!hasReadPermission ? e.preventDefault() : e)}
                >
                    <Button
                        sx={{ color }}
                        variant="text"
                        size="medium"
                        startIcon={<AddIcon htmlColor={color} sx={iconSx} />}
                        disabled={isFetching || !hasReadPermission}
                    >
                        Novo operador
                    </Button>
                </Link>}
            </Grid>
        </Stack>
    );
};
export default UserHeader;
