import { useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { GetViewApiResponse, GetViewApiResponseSuccess, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { getSignaturesCreditNote } from "services/creditNote";
import { SignaturesType } from "services/creditNote/types/creditNoteReadModel";

export function useSignaturesCreditNote(creditNoteId: string) {
  const { token } = useIdentity();
  const { startRequest, endRequest, setSubmitError } = useApiRequest();

  const { status, data, error, isFetching, refetch } = useQuery({
    enabled: !!token && !!creditNoteId,
    refetchIntervalInBackground: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: ['signatures-credit-note', creditNoteId],
    queryFn: async (): Promise<GetViewApiResponse<SignaturesType[]>> => {
      startRequest();
      const { data, status, statusText } = await getSignaturesCreditNote(creditNoteId, token!);
      endRequest(true);

      if (isAxiosError(data)) {
        setSubmitError({
          type: 'error',
          code: status + '' + statusText,
          message: data.message,
          errors: data.response?.data?.errors,
        });
        throw data;
      }

      if (status >= 400 && status <= 599) {
        throw data;
      }
      return data as GetViewApiResponseSuccess<SignaturesType[]>;
    },
  });

  return { 
    signaturesData: data, 
    signaturesStatus: status, 
    signaturesError: error, 
    isFetching, 
    refetch 
  };
}