import type { FunctionComponent } from 'react';
import type { CardButtonProps } from './CardButton.interface';

import { Stack, Typography } from '@mui/material';
import { BoxContent, Button } from './CardButton.styled';
import { CardIcon } from '../../atoms/CardIcon';

export const CardButton: FunctionComponent<CardButtonProps> = ({
  title,
  description,
  icon,
  onClick,
  contentSx,
  name
}) => {
  return (
    <Button onClick={onClick} name={name}>
      <Stack direction="row">
        <CardIcon size="medium" color="primary" icon={icon} />
        <BoxContent sx={contentSx}>
          <Typography variant="xs" color="neutral.dark" lineHeight="19px" mb="4px" fontWeight={600} >
            {title}
          </Typography>
          <Typography variant="xs" color="neutral.medium" align="left" lineHeight="14px">
            {description}
          </Typography>
        </BoxContent>
      </Stack>
    </Button>
  );
};
