import { Box, IconButton, Stack } from '@mui/material';
import { DataTable, EditIcon, Typography } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid/';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { BankAccountGetBalance } from './BankAccountGetBalance';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { RenderAvatarsName } from '../../../../../components/RenderAvatarsName/RenderAvatarsName';
import { useTenant } from 'contexts/tenantContext';

interface BankAccountListType {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
}

const BankAccountList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
}: BankAccountListType) => {
    const { isRootTenancy } = useTenant();

    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columns: GridColDef[] | any[] = [
        isRootTenancy ? {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        } : undefined,
        {
            field: 'person',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.row?.person?.name}
                    </div>
                );
            },
        },
        {
            field: 'person.registrationNumber',
            headerName: 'CPF/CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {formatDocumentNumber(cellValues?.row?.person?.registrationNumber)}
                    </div>
                );
            },
        },
        {
            field: 'creditors',
            headerName: 'Credores',
            hideSortIcons: true,
            align: 'left',
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                const list = cellValues.row?.creditors ?? [];
                return (
                <>
                    {list?.length > 0 ? <RenderAvatarsName list={list} /> : <Typography sx={{ml: 2}}>-</Typography>}
                </>)
            }
        },
        {
            field: 'account',
            headerName: 'Número da conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'typeDisplay',
            headerName: 'Tipo de conta',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'balance',
            headerName: 'Saldo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            headerAlign: 'left',
            align: 'left',
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                const accountId = cellValues?.row?.id;
                return <BankAccountGetBalance accountId={accountId} />;
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            renderCell: (cellValues: GridRenderCellParams) => {
                const accountId = cellValues.row.id;
                return (
                    <Stack direction='row' alignItems='center'>
                        <Link to={accountId}>
                            <IconButton name="btn-edit" title='Entrar na conta'>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>
                        <BankAccountGetBalance accountId={accountId} onlyRefresh={true} />
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    return (
        <Box p={0} mt={2}>
            <DataTable
                filterComponent={{
                    showFilters: true,
                    componentFilter: <BankAccountFiltersContainer typeFilter="bankAccountList" />,
                }}
                columns={columns}
                rows={queryData !== undefined ? queryData?.data ?? [] : []}
                page={queryData ? queryData?.page ?? 0 : page}
                rowCount={queryData?.totalItems ?? 0}
                pageCount={queryData?.totalPages ?? 0}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </Box>
    );
};

export default BankAccountList;
