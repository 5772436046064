import { chatbotAxiosApi } from "services/axiosApi/axiosApi";
import { IInputSettingsCreateModel } from "./types/CreateModel/InputSettingsCreateModel";
import { IInputSettingsUpdateModel } from "./types/UpdateModel/InputSettingsUpdateModel";
import { FilterInputSettingType, IInputSettingsReadModel } from "./types";

const url = `/InputSettings`;

export const getInputSettingsAsync = async (filters: FilterInputSettingType, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };

    return await chatbotAxiosApi.get(`${url}`, config);
}

export const getInputSettingsByIdAsync = async (id: string, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await chatbotAxiosApi.get<IInputSettingsReadModel>(`${url}/${id}`, config);
}

export const createInputSettingAsync = async (payload: IInputSettingsCreateModel, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await chatbotAxiosApi.post(`${url}`, payload, config);
}

export const updateInputSettingAsync = async (id: string, payload: IInputSettingsUpdateModel, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await chatbotAxiosApi.put(`${url}/${id}`, payload, config);
}

export const deleteInputSettingAsync = async (id: string, token: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await chatbotAxiosApi.delete(`${url}/${id}`, config);
}


