import { IconButton, Stack } from '@mui/material';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import CategorieForm from './CategorieForm';
import { DeleteIcon } from '@uy3/web-components';

type CategoryListProps  = {
    fields: Record<'id', string>[];
    remove: (index: number) => void;
};

const CategoryList = ({ fields, remove }: CategoryListProps ) => (
    <>
        <CustomTitle
            title="Crie uma nova categoria ou faça alterações em uma já existente"
            variant="sm"
        />

        <Stack direction="column" spacing={2}>
            {fields.map((field, index) => (
                <Stack key={field.id} direction="row" alignItems="center">
                    <CategorieForm key={field.id} index={index} />
                    <IconButton onClick={() => remove(index)}>
                        <DeleteIcon />
                    </IconButton>
                </Stack>
            ))}
        </Stack>
    </>
);

export default CategoryList;
