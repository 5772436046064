import { Grid, IconButton, Stack, useMediaQuery } from '@mui/material'
import { Button, KeyIcon, Typography } from '@uy3/web-components'
import WestIcon from '@mui/icons-material/West';
import React from 'react';
import { activeTheme } from 'services/theme';
import { useNavigate } from 'react-router';

type ManagePixKeyFormProps = {
  onAddKeyPix: () => void;
}

const theme = activeTheme();
export const ManagePixKeyForm: React.FC<ManagePixKeyFormProps> = ({ onAddKeyPix }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Stack direction='row' position='relative' alignItems='start' mt={2}>
          <IconButton onClick={() => navigate(-1)} sx={{ marginRight: 2 }}>
            <WestIcon sx={{ color: 'common.black', width: 25, height: 25 }} />
          </IconButton>
          <Grid>
            <Typography variant="xxl" color="neutral.dark">
              Gerenciar chaves
            </Typography>
            <Typography
              variant="h4"
              color="neutral.dark"
              sx={{
                fontSize: { xs: '15px', md: '15px' },
                lineHeight: { xs: '28.8px', md: '38.4px' },
                fontWeight: { xs: '500', md: '500' },
                fontStyle: { xs: 'normal', md: 'normal' }
              }}
            >
              Cadastre ou exclua as suas chaves Pix sempre que quiser.
            </Typography>
          </Grid>
        </Stack>

        <Stack
          component={Grid}
          direction={isMobile ? 'column' : 'row'}
          spacing={2}
          mr={2}
        >
          <Button
            variant='text'
            sx={{ color: theme.palette.common.black }}
            startIcon={<KeyIcon sx={{ width: 20, height: 20 }} htmlColor={theme.palette.common.black} />}
            onClick={onAddKeyPix}
          >
            Cadastrar chave Pix
          </Button>

        </Stack>
      </Stack>
    </>
  )
}