import { Button, Drawer, Popup } from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { useState } from 'react';
import { useGroupInsertUserMutation, useUserListData } from 'contexts/userContext';
import { useParams } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { removeUserGroupById } from 'services/user/userGroups';
import { useIdentity } from 'contexts/identityContext';
import { FormProvider } from 'contexts/formContext';
import AddMemberForm from './MemberDrawer/MemberForm';
import {
    MemberFormSchema,
    defaultValuesMemberForm,
    validationSchemaMemberForm,
} from './MemberDrawer/MemberSchema';
import MemberListTab from './MemberListTab';
import { useUserInGroupListData } from 'contexts/groupContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useTenant } from 'contexts/tenantContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { Grid } from '@mui/material';
import MemberHeader from './MemberHeader';

export type ActionType = { action: string, value: string | undefined }

const MemberTabContainer = () => {
    const [actionType, setActionType] = useState<ActionType | undefined>(undefined);
    const { groupId } = useParams();
    const { currentTenantId } = useTenant();
    const { token } = useIdentity();
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<number>(0);
    const [nextToken, setNextToken] = useState<any | undefined>({ 0: undefined });
    const {
        status: userInGroupStts,
        data: userInGroupData,
        error: UserInGroupErr,
        refetch,
        isFetching,
    } = useUserInGroupListData({ page, size: rowsPerPage, nextToken: nextToken[page] }, groupId!);
    const { userStts, userErr, usersAutoCompleteProps } = useUserListData({ page, size: 10 }, 'always');
    const memberSchema = validationSchemaMemberForm();
    const { setSubmitError } = useApiRequest();
    const [id, setId] = useState<string>('');
    const [toast, setToast] = useState<ToastType>(toastState);

    const onCloseActionType = () => setActionType(undefined);

    const handleMessageError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    }

    const onRemoveUserGroup = async (userId: string) => {
        var groupName: string = groupId!;
        await removeUserGroupById(groupName, userId, token!, currentTenantId)
            .then(() => {
                refetch();
                onCloseActionType();
                const title = 'Sucesso ao excluir o membro do grupo';
                showSuccessToast(title, undefined, setToast);
            }).catch((response: ApiResponseError) => {
                return handleMessageError(response);
            });
    };

    const onSuccess = () => {
        refetch();
        onCloseActionType();
        setSubmitError(undefined);
        const title = 'Membro adicionado com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (response: ApiResponseError) => {
        setSubmitError(response);
        handleMessageError(response)
        onCloseActionType();
    };

    const { mutateAsync } = useGroupInsertUserMutation(id!, onSuccess, onError);
    const onSubmitMember = (values: MemberFormSchema) => {
        setId(values.name);
        mutateAsync(groupId);
    };

    if (userInGroupStts === 'loading') return <RefreshProgress refreshing={true} />;

    if (userStts === 'error' || UserInGroupErr === 'error')
        <Error error={userErr ?? UserInGroupErr} />;

    const onChangeRowsPerPage = (page: number) => {
        setNextToken({ 0: null });
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (newPage: number) => {
        setNextToken({ ...nextToken, [newPage]: userInGroupData!.paginationToken });
        setPage(newPage);
    };

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <MemberHeader refetch={refetch} setActionType={setActionType} />
            <RefreshProgress refreshing={isFetching} />
            {userInGroupData !== undefined && (
                <MemberListTab
                    queryData={userInGroupData}
                    page={page}
                    setPage={onChangePage}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={onChangeRowsPerPage}
                    onRemoveUserGroup={(id) => setActionType({ action: 'deleteMember', value: id! })}
                />
            )}
            <Drawer
                anchor="right"
                title="Adicionar novo membro"
                open={actionType?.action === 'addNewMember'}
                onClose={onCloseActionType}
            >
                <FormProvider
                    validationSchema={memberSchema}
                    defaultValues={defaultValuesMemberForm}
                    onSubmit={onSubmitMember}
                >
                    <AddMemberForm
                        memberList={usersAutoCompleteProps}
                        onClose={onCloseActionType}
                    />
                </FormProvider>
            </Drawer>
            <Popup
                open={actionType?.action === 'deleteMember'}
                title='Excluir'
                text={`Tem certeza que deseja excluir este membro?`}
                onClose={onCloseActionType}
                children={
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onCloseActionType}
                            size="medium"
                            sx={{ mt: 3, mr: 2 }}
                        >
                            Não, cancelar
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => onRemoveUserGroup(actionType?.value!)}
                            size="medium"
                            sx={{ mt: 3 }}
                        >
                            Sim, confirmar
                        </Button>
                    </Grid>
                }
            />
        </>
    );
};

export default MemberTabContainer;
