import { FormProvider } from 'contexts/formContext';
import {
    ChangePasswordFormDefaultValues,
    ChangePasswordFormValidationSchema,
} from './ChangePasswordFormSchema';
import { ChangePasswordForm } from './ChangePasswordForm';
import { useEffect } from 'react';
import { Alert } from '@uy3/web-components';
import { useIdentity } from 'contexts/identityContext';
import { useNavigate } from 'react-router-dom';
import { FieldValues } from 'react-hook-form';

export function ChangePasswordFormContainer() {
    const { changePassword, authFlow, navigateToFlow, isAuthenticated, submitError } =
        useIdentity();
    const navigate = useNavigate();

    const authenticated = isAuthenticated();
    const schema = ChangePasswordFormValidationSchema();
    const defaultValues = ChangePasswordFormDefaultValues;

    const submit = async (values: FieldValues) => {
        await changePassword(values.password, values.oldPassword);
    };
    const error = (values: any) => console.log('ChangePasswordFormContainer - onError', values);

    useEffect(() => {
        console.log('ChangePasswordFormContainer - authFlow atualizado: ' + authFlow?.type);
        navigateToFlow(navigate);
    }, [authFlow, navigate, navigateToFlow]);

    useEffect(() => {
        if (!authFlow && !authenticated) {
            console.log('AuthFlow não encontrado');
            navigate('/login');
        }
    }, [authFlow, navigate, authenticated]);

    window.onpopstate = () => navigate('/');

    return (
        <>
            {submitError && <Alert severity="error" text={submitError} variant="filled" />}
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={submit}
                onError={error}
            >
                <ChangePasswordForm />
            </FormProvider>
        </>
    );
}
