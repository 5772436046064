import type { DateFiltersProps } from './GroupedButtons.interface';
import type { FunctionComponent } from 'react';
import { useState } from 'react';
import { DateFiltersBox } from './GroupedButtons.styles';
import { Button, Typography } from '../../atoms';

export const GroupedButtons: FunctionComponent<DateFiltersProps> = ({
  onClick,
  groupedButtons = [],
  size = 'large',
  width = 'auto',
}) => {
  const [activeFilter, setActiveFilter] = useState<number>(0);

  return (
    <DateFiltersBox>
      {groupedButtons?.map((dateFilter, index) => {
        return (
          <Button
            variant={activeFilter === index ? 'contained' : 'text'}
            type="button"
            key={index}
            onClick={(event) => {
              onClick(index, event);
              setActiveFilter(index);
            }}
            size={size}
          >
            <Typography
              fontSize="12px"
              fontWeight={500}
              variant="xs"
              color={activeFilter === index ? 'common.white' : '#979797'}
              lineHeight="14.52px"
              width={width}
            >
              {dateFilter}
            </Typography>
          </Button>
        );
      })}
    </DateFiltersBox>
  );
};
