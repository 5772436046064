import { boolean, object, string } from 'yup';


export function validationSchemaGenerateFileLiquidationSchema() {
    return object().shape({
        typeLiquidation: string().typeError('Tipo de liquidação precisa ser preenchida').required('Tipo de liquidação: precisa ser preenchida'),
        date: string().typeError('Data de liquidação precisa ser preenchida').required('Data de liquidação: precisa ser preenchida'),
        fundId: string().when("typeLiquidation", {
            is: "concilicaoCaixa",
            then: string().typeError('Fundo precisa ser preenchida').required('Fundo: precisa ser preenchida'),
            otherwise: string().nullable()
        }),
        opId: string().when("typeLiquidation", {
            is: "liquidacaoAntecipadaCliente",
            then: string().typeError('Operação precisa ser prenchido').required('Oeração: precisa ser prenchido'),
            otherwise: string().nullable()
        }),
        liquidateByValue: string().when("typeLiquidation", {
            is: "liquidacaoAntecipadaCliente",
            then: string().nullable(),
            otherwise: string().nullable()
        }), 
        incluirVencidos: boolean().when("typeLiquidation", {
            is: "liquidacaoAntecipadaCliente",
            then: boolean().nullable(),
            otherwise: boolean().nullable()
        }),
        baixarParcelas: boolean().when("typeLiquidation", {
            is: "liquidacaoAntecipadaCliente",
            then: boolean().nullable(),
            otherwise: boolean().nullable()
        }),
    });
};

export const defaultValuesGenerateFileLiquidationSchema = {
    typeLiquidation: null, 
    date: null, 
    fundId: null, 
    opId: null, 
    liquidateByValue: null, 
    baixarParcelas: true, 
    incluirVencidos: false
};