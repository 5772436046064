import { Typography } from '@uy3/web-components';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import DatasetWrapper from '../DatasetWrapper';
import { OnlineNegotiationGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useFormContext } from 'contexts/formContext';
import { useState } from 'react';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IOnlineNegotiation {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean
}

export function OnlineNegotiation({ datasetId, disableRequestDataSet }: IOnlineNegotiation) {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'OnlineNegotiation');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Presença online${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'online_negotiation'
    ) as OnlineNegotiationGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <Typography variant="h4" fontWeight={600} color="neutral.medium" mb={2}>
                        *Score vai de "A" (maior relevância ou importância) até "H" (menor
                        relevância ou importância).
                    </Typography>
                    <HandleTitle
                        title={'Utilização'}
                        value={result?.internetUsageLevelV3 ?? 'N/D'}
                        key="internetUsageLevelV3"
                    />
                    <HandleTitle
                        title={'Vendas'}
                        value={result?.esellerV3 ?? 'N/D'}
                        key="esellerV3"
                    />
                    <HandleTitle
                        title={'Compras'}
                        value={result?.eshopperV3 ?? 'N/D'}
                        key="eshopperV3"
                    />
                    <HandleTitle
                        title={'Última captura'}
                        value={
                            result?.lastWebPassageDate &&
                            new Date(result?.lastWebPassageDate).toLocaleDateString('pt-BR')
                        }
                        key="lastWebPassageDate"
                    />
                    <HandleTitle
                        title={'Total de passagens'}
                        value={result?.totalWebPassages ?? 'N/D'}
                        key="totalWebPassages"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('OnlineNegotiation')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};
