import { Grid, Stack } from '@mui/material';
import {
    Alert,
    ChatLineIcon,
    DeleteIcon,
    SaveIcon,
    Typography,
    UpdateIcon
} from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';

type ChatbotHeaderFormProps = {
    isNew: boolean;
    refetch: () => void;
    handleAction: (action: string) => void
};

const theme = activeTheme();

export default function ChatbotHeaderForm({ refetch, isNew, handleAction }: ChatbotHeaderFormProps) {
    const { watch, isDirty } = useFormContext();
    const used = !!Boolean(watch('used'));
    return (
        <>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Stack>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="28px"
                        lineHeight="38.4px"
                    >
                        {isNew ? 'Novo registro de entrada' : `Dados de entrada`}
                    </Typography>
                    {!isNew && <Typography
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="600"
                        fontSize="16px"
                        lineHeight="38.4px"
                    >
                        {`${watch('identificationCode') ?? ''}`}
                    </Typography>}
                </Stack>
                <Grid direction="row" alignItems="center">
                    <Actions
                        numberOfButtons={4}
                        buttonsActionsList={[
                            {
                                enable: !used,
                                label: "Salvar",
                                type: 'submit',
                                icon: <SaveIcon htmlColor={theme.palette.common.black} sx={iconSx} />
                            },
                            {
                                enable: !isNew,
                                label: "Atualizar",
                                action: refetch,
                                icon: <UpdateIcon htmlColor={theme.palette.common.black} sx={iconSx} />
                            },
                            {
                                enable: !isNew && !used,
                                label: "Entrar em contato",
                                action: () => handleAction('waQRCode'),
                                icon: <ChatLineIcon htmlColor={theme.palette.common.black} sx={iconSx} />
                            },
                            {
                                enable: !isNew && !used,
                                label: "Excluir",
                                action: () => handleAction('delete'),
                                icon: <DeleteIcon htmlColor={theme.palette.common.black} sx={iconSx} />
                            },
                        ]}
                    />
                </Grid>
            </Stack>
            <Grid container item xs={4} mb={3}>
                <NotSavedChangesAlert isDirty={isDirty} />
            </Grid>
            {used &&
                <Grid mr={2} mb={3}>
                    <Alert severity='warning' text='Este registro já está sendo utilizado, portanto, não é possível modificá-lo agora.'/>
                </Grid>}
        </>
    );
};