import { Stack, styled } from "@mui/material";
import { MenuItem } from "@uy3/web-components";

export const ActionsMyProfile = styled(Stack)(() => ({
    background: 'white',
    minWidth: '300px',
    display: 'inline-flex',
    padding: '0',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderRadius: '4px',
    boxShadow: '0px 2px 8px 2px rgba(31, 31, 31, 0.10)',
    position: 'absolute',
    top: '8rem',
    right: 20,
    zIndex: 500
}));

export const ActionButtonProfile = styled(MenuItem)(() => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center'
}));