import DynamicAccordion from 'components/Datasets/Accordion/Accordion';
import CreditLimit from './CreditLimit/CreditLimit';
import { useAppConfig } from 'contexts/appConfig';
import { useLocation } from 'react-router';
import { SCRData, PDFReport, Fgts } from 'components/Datasets';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import InfoMessage from 'components/InfoMessage/InfoMessage';
import TokensListContainer from '../TokensList/TokensListContainer';
import { SiapeMarginQuery } from './SiapeMarginQuery/SiapeMarginQuery';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { useFormContext } from 'contexts/formContext';
import { ZetraMarginQuery } from './ZetraMarginQuery';

type CreditTabContainerProps = {
    personId?: string | undefined;
    discriminator?: string;
};

export const CreditTabContainer = ({ personId, discriminator }: CreditTabContainerProps) => {
    const { appConfig } = useAppConfig();
    const { pathname } = useLocation();
    const { data: permissionsData } = useUserPermissionData();
    const isNaturalPerson =
        pathname.includes('pessoas-fisicas') || discriminator === 'NaturalPerson';
    const resource = isNaturalPerson ? 'NaturalPerson' : 'LegalPerson';
    const { product } = useCreditNoteFormContext();
    const { watch } = useFormContext();
    const [registrationNumber, personRegistrationNumber] = watch([
        'registrationNumber',
        'personRegistrationNumber',
    ]);

    const isPublicPayrollLoan = product?.instrumentType === 'PublicPayrollLoan';
    const warrantyRegistrationOffice = product?.warrantyRegistrationOffice === 'Siape';
    const enableMarginQuery = isPublicPayrollLoan && warrantyRegistrationOffice;

    const useRequestCredit = !!appConfig.REQUEST_CREDIT;
    const useSCR = !!appConfig.USE_SCR_DATASET;

    const hasActionPermission = (action: string, resource: string) =>
        checkActionsPerms(action, permissionsData, resource);

    const hasPermissionToReadDataset = () => {
        if (hasActionPermission('ReadDataSet', resource)) return true;
        return false;
    };

    const disableRequestDataSet = !hasActionPermission('RequestDataSet', resource);
    const marginQuery = (isNaturalPerson && enableMarginQuery && !!personRegistrationNumber) || (isNaturalPerson && !!registrationNumber);

    const accordion = [
        useRequestCredit
            ? {
                title: 'Limite de Crédito',
                content: hasPermissionToReadDataset() ? (
                    <CreditLimit id={personId} disableRequestDataSet={disableRequestDataSet} />
                ) : (
                    <InfoMessage />
                ),
            }
            : {},

        isNaturalPerson
            ? {
                title: 'Saque aniversário',
                content: hasPermissionToReadDataset() ? (
                    <Fgts id={personId} disableRequestDataSet={disableRequestDataSet} />
                ) : (
                    <InfoMessage />
                ),
            }
            : {},
        isNaturalPerson
            ? {
                title: 'Consignado - Tokens de Averbação',
                content: hasPermissionToReadDataset() ? (
                    <TokensListContainer
                        personIdByCreditNote={personId!}
                        pathIsNaturalPerson={pathname.includes('pessoas-fisicas')}
                    />
                ) : (
                    <InfoMessage />
                ),
            }
            : {},

        marginQuery
            ? {
                title: 'Consultar margem - SIAPE',
                content: hasPermissionToReadDataset() ? <SiapeMarginQuery /> : <InfoMessage />,
            }
            : {},

        marginQuery
            ? {
                title: 'Consultar margem - ZETRA',
                content: hasPermissionToReadDataset() ? <ZetraMarginQuery /> : <InfoMessage />,
            }
            : {},

        !isNaturalPerson
            ? {
                title: 'Relatório de crédito',
                content: hasPermissionToReadDataset() ? (
                    <PDFReport id={personId} disableRequestDataSet={disableRequestDataSet} />
                ) : (
                    <InfoMessage />
                ),
            }
            : {},

        useSCR
            ? {
                title: 'Banco central - SCR',
                content: hasPermissionToReadDataset() ? (
                    <SCRData
                        id={personId}
                        discriminator={discriminator}
                        disableRequestDataSet={disableRequestDataSet}
                    />
                ) : (
                    <InfoMessage />
                ),
            }
            : {},
    ].filter((x) => x.title !== undefined) as { title: string; content: JSX.Element }[];

    return <DynamicAccordion accordion={accordion} />;
};
