import { IconButton, Stack } from '@mui/material';
import { ArrowLeftIcon, Button, SaveIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useNavigate } from 'react-router';
import { activeTheme } from 'services/theme';

const theme = activeTheme();

export const SettingsNotificationsHeader = () => {
    const navigate = useNavigate();

    return (
        <Stack direction="row" spacing={2} mb={4} alignItems="center" justifyContent="space-between">
            <Stack direction='row' alignItems='center'>
                <IconButton onClick={() => navigate('/meu-perfil')}>
                    <ArrowLeftIcon sx={{ height: 24, width: 24 }} />
                </IconButton>

                <Typography fontSize='24px' fontWeight='bold'>
                    Configurações de notificações
                </Typography>
            </Stack>

            <Button
                startIcon={<SaveIcon sx={iconSx} />}
                variant='text'
                type='submit'
                sx={{
                    color: theme.palette.common.black
                }}
            >
                Salvar configurações
            </Button>
        </Stack>
    );
};
