/* eslint-disable react-hooks/exhaustive-deps */
import Grid from '@mui/material/Grid';
import { Typography } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields/TextFormField/TextFormField';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useParams } from 'react-router-dom';
import { DatePickerFormField, SelectFormField } from 'components/Forms/FormFields';
import { Box } from '@mui/material';
import { queryZipCodeAndFillForm } from 'services/zipCode';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useFormContext } from 'contexts/formContext';
import { useEffect } from 'react';
import { UF } from 'services/zipCode/zipcode.types';
import { cnpjMask, cpfMask, phoneNumberMask, zipCodeMask } from 'utils/constants';
import ConfigurableHiddenField from 'components/FormFieldHidden';
import { useAppConfig } from 'contexts/appConfig';
import { hasValidRegistrationNumber } from 'helpers/formats/StringFormats';
import { ToastType } from 'contexts/apiRequestContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { documentTypeOption } from '../../NaturalPersonSchema';

type NaturalPersonInformationProps = {
    UFList: typeof UF;
    legalPersonAutoCompleteProps: AutocompleteOptionList;
    handleOnSubmitByChangeField: (registrationNumber: string) => void
    cnaeList: AutocompleteOptionList;
    legalPersonData: any;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
};

const NaturalPersonInformationTab = ({
    UFList,
    legalPersonAutoCompleteProps,
    cnaeList,
    legalPersonData,
    handleOnSubmitByChangeField,
    setToast
}: NaturalPersonInformationProps) => {
    const { watch, setValue } = useFormContext();
    const { personId } = useParams();
    const workplace = watch('workplace');
    const { appConfig } = useAppConfig();
    const isAutoQueryBasicData = appConfig?.AUTO_QUERY_BASIC_DATA === 1;
    const isNew = personId === "nova";

    const disableRegistrationNumberField = watch('loadValuesByRegistrationNumber') === true && isNew;

    useEffect(() => {
        const personSelected = legalPersonData?.data?.find(
            (person: any) => person?.id === workplace
        );
        if (workplace === undefined) {
            setValue('workplaceCompanyRegistrationNumber', null);
            setValue('economicActivityCode', null);
        }
        if (workplace?.length) {
            setValue('workplaceCompanyRegistrationNumber', personSelected?.registrationNumber);
            setValue('economicActivityCode', personSelected?.economicActivityCode);
            setValue('economicActivityCodeId', personSelected?.economicActivityCodeId);
        }
    }, [workplace]);


    const handleLegalPersonLink = (personId: string) => {
        return `/pessoas-juridicas/todas/${personId}`;
    };

    const handleChangeByBasicData = async (event: any) => {
        const registrationNumber = String(event?.currentTarget?.value)?.replace(/\D/g, '');
        setValue('registrationNumber', registrationNumber);

        if (registrationNumber?.length !== 11) return;

        if (!hasValidRegistrationNumber(registrationNumber)) {
            return setToast({
                open: true,
                severity: 'info',
                title: `Ops!`,
                description: `CPF informado não é válido (${formatDocumentNumber(registrationNumber)})`
            })
        };

        handleOnSubmitByChangeField(registrationNumber);
    }

    return (
        <>
            <Box p={0} mt={3}>
                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Informações básicas
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={3}>
                        <TextFormField
                            name="registrationNumber"
                            label="CPF"
                            fullWidth
                            placeholder="000.000.000-00"
                            autoComplete="registrationNumber"
                            variant="outlined"
                            required
                            InputProps={isAutoQueryBasicData && isNew ?
                                {
                                    inputComponent: MaskedInput,
                                    inputProps: { mask: cpfMask },
                                    onChange: handleChangeByBasicData
                                } : {
                                    inputComponent: MaskedInput,
                                    inputProps: { mask: cpfMask }
                                }}
                            disabled={disableRegistrationNumberField || !isNew}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="name"
                            label="Nome completo"
                            placeholder="Nome"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="socialName"
                            label="Nome social"
                            placeholder="Nome"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            name="civilStatus"
                            label="Estado Civil"
                            required
                            options={[
                                { label: 'Não informado', value: '' },
                                { label: 'Casado(a)', value: 'Married' },
                                { label: 'Viúvo(a)', value: 'Widowed' },
                                { label: 'Separado(a)', value: 'Separated' },
                                { label: 'Divorciado(a)', value: 'Divorced' },
                                { label: 'Solteiro(a)', value: 'Single' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <DatePickerFormField
                            label="Data de nascimento"
                            name="birthDate"
                            fullWidth
                            required
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            label="Sexo"
                            name="gender"
                            id="gender"
                            options={[
                                { label: 'Não informado', value: 'NotInformed' },
                                { label: 'Masculino', value: 'Male' },
                                { label: 'Feminino', value: 'Female' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            id="mothersName"
                            label="Nome da mãe"
                            name="mothersName"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            id="fathersName"
                            label="Nome do pai"
                            name="fathersName"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            id="nationality"
                            name="nationality"
                            label="Nacionalidade"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="placeOfBirthCountry"
                            label="Pais de nascimento"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Telefone
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={2}>
                        <TextFormField
                            name="phone"
                            id="phone"
                            label="Telefone Celular"
                            variant="outlined"
                            placeholder="(00) 00000-0000"
                            fullWidth
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                            }}
                            required
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextFormField
                            type="number"
                            id="phoneExtension"
                            name="phoneExtension"
                            label="Ramal"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="phone2"
                            label="Telefone opcional"
                            variant="outlined"
                            fullWidth
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextFormField
                            type="number"
                            name="phone2Extension"
                            label="Ramal"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Documento de identificação
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={3}>
                        <SelectFormField
                            name="documentType"
                            label="Tipo de documento"
                            id="documentType"
                            options={documentTypeOption}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            id="documentNumber"
                            label="Documento de identidade"
                            name="documentNumber"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerFormField
                            name="documentDate"
                            fullWidth
                            label="Data de Emissão"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerFormField
                            label="Data de expiração"
                            fullWidth
                            name="documentExpiration"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <SelectFormField
                            name="pep"
                            label="PEP"
                            id="pep"
                            placeholder="Name"
                            options={[
                                { label: 'Sim', value: true },
                                { label: 'Não', value: false },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            name="documentIssuer"
                            label="Emissor/UF"
                            variant="outlined"
                            fullWidth
                            options={UFList}
                        />
                    </Grid>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Endereço
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={3}>
                        <TextFormField
                            name="address.zipCode"
                            id="zipCode"
                            label="CEP"
                            variant="outlined"
                            placeholder="00000-000"
                            fullWidth
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: zipCodeMask },
                                onChange: (event) => {
                                    let keyBoard = event?.currentTarget?.value.replace(/\D/g, '');
                                    queryZipCodeAndFillForm(keyBoard, (fieldName, value) => {
                                        setValue(`address.${fieldName}`, value);
                                    });
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="address.addressName"
                            fullWidth
                            label="Endereço"
                            variant="outlined"
                            value={watch('address.addressName') ? watch('address.addressName') : ''}
                            InputLabelProps={{
                                shrink: watch('address.addressName') ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="address.city"
                            fullWidth
                            label="Cidade"
                            variant="outlined"
                            value={watch('address.city') ? watch('address.city') : ''}
                            InputLabelProps={{
                                shrink: watch('address.city') ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <TextFormField
                            name="address.complement"
                            fullWidth
                            label="Complemento"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={1.5}>
                        <SelectFormField
                            name="address.uf"
                            label="UF"
                            options={UFList}
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            name="address.district"
                            fullWidth
                            label="Bairro"
                            variant="outlined"
                            value={watch('address.district') ? watch('address.district') : ''}
                            InputLabelProps={{
                                shrink: watch('address.district') ? true : false,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            name="address.number"
                            fullWidth
                            type='number'
                            label="Número"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            label="Tipo de residêcia"
                            name="typeOfResidence"
                            options={[
                                { label: 'Própria', value: 'Owner' },
                                { label: 'Alugada', value: 'Rent' },
                            ]}
                        />
                    </Grid>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Dados Profissionais
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={3}>
                        <AutocompleteField
                            label="Nome da empresa de onde trabalha"
                            name="workplace"
                            displayName="workplaceDisplay"
                            showEndIcon={!!workplace}
                            endIconType="link"
                            link={handleLegalPersonLink(workplace)}
                            {...legalPersonAutoCompleteProps}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            id="workplaceCompanyRegistrationNumber"
                            name="workplaceCompanyRegistrationNumber"
                            variant="outlined"
                            fullWidth
                            label="CNPJ da empresa"
                            value={watch('workplaceCompanyRegistrationNumber')}
                            placeholder="00.000.000/0000-00"
                            disabled
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: cnpjMask },
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <AutocompleteField
                            name="economicActivityCodeId"
                            label="CNAE"
                            displayName="economicActivityCode.description"
                            {...cnaeList}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <SelectFormField
                            name="natureOfOccupation"
                            label="Natureza da ocupação"
                            id="natureOfOccupation"
                            fullWidth
                            placeholder="Name"
                            options={[
                                { label: 'Autônomo', value: 'Autonomous' },
                                { label: 'Empregado', value: 'PrivateEmployee' },
                                { label: 'Funcionário Público', value: 'PublicEmployee' },
                                { label: 'Empresário', value: 'Employer' },
                                { label: 'Rentista', value: 'Capitalist' },
                                { label: 'Militar', value: 'Military' },
                                { label: 'Outros', value: 'Other' },
                            ]}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextFormField
                            type="text"
                            name="occupation"
                            id="ocupation"
                            label="Ocupação"
                            fullWidth
                            variant="outlined"
                            required
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <CurrencyFormField
                            id="netSalary"
                            name="netSalary"
                            fullWidth
                            label="Salário Líquido"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CurrencyFormField
                            name="otherIncome"
                            fullWidth
                            label="Outras Rendas"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerFormField
                            label="Data de admissão"
                            name="admissionDate"
                            fullWidth
                        />
                    </Grid>
                    <ConfigurableHiddenField fieldName="employeeNumber">
                        <Grid item xs={2}>
                            <TextFormField
                                name="employeeNumber"
                                label="Matricula do funcionario"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </ConfigurableHiddenField>
                    <ConfigurableHiddenField fieldName="department">
                        <Grid item xs={2}>
                            <TextFormField
                                name="department"
                                label="Departamento"
                                fullWidth
                                variant="outlined"
                            />
                        </Grid>
                    </ConfigurableHiddenField>
                </Grid>

                <Typography
                    variant="h5"
                    color="neutral.dark"
                    sx={{
                        lineHeight: { xs: '24px', md: '24px' },
                        fontWeight: { xs: '700', md: '700' },
                        fontStyle: { xs: 'normal', md: 'normal' },
                        m: '20px 0 ',
                    }}
                >
                    Informações sobre o Cônjuge
                </Typography>

                <Grid container spacing={5} columns={10}>
                    <Grid item xs={3}>
                        <TextFormField
                            id="spouseName"
                            fullWidth
                            name="spouseName"
                            label="Nome"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextFormField
                            id="spouseDocumentNumber"
                            name="spouseDocumentNumber"
                            variant="outlined"
                            fullWidth
                            label="CPF"
                            placeholder="00.000.000-00"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: cpfMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            id="partner-information-workplace"
                            name="spouseWorkplace"
                            variant="outlined"
                            fullWidth
                            label="CNPJ da empresa onde trabalha"
                            placeholder="00.000.000/0000-00"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: cnpjMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            id="partner-information-workplace"
                            type="text"
                            fullWidth
                            name="spouseOccupation"
                            label="Ocupação"
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextFormField
                            id="spouseCommercialPhone"
                            name="spouseCommercialPhone"
                            variant="outlined"
                            fullWidth
                            label="Telefone"
                            placeholder="(00) 00000-0000"
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <TextFormField
                            id="spouse-commercial-PhoneExtension"
                            type="number"
                            name="spouseCommercialPhoneExtension"
                            label="Ramal"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <DatePickerFormField
                            name="spouseBirthDate"
                            label="Data de nascimento"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CurrencyFormField
                            name="spouseNetSalary"
                            id="spouseNetSalary"
                            label="Salário"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <CurrencyFormField
                            id="spouseOtherIncome"
                            name="spouseOtherIncome"
                            label="Outras Rendas"
                            fullWidth
                            variant="outlined"
                        />
                    </Grid>
                </Grid>

                <ConfigurableHiddenField fieldName="healthDeclaration">
                    <Typography
                        variant="h5"
                        color="neutral.dark"
                        sx={{
                            lineHeight: { xs: '24px', md: '24px' },
                            fontWeight: { xs: '700', md: '700' },
                            fontStyle: { xs: 'normal', md: 'normal' },
                            m: '20px 0 0 0',
                        }}
                    >
                        Declaração de Saúde
                    </Typography>
                    <Typography variant="md" color="neutral.medium">
                        Declaro que possuo boas condições de saúde e que não apresento nenhuma das
                        doenças listadas.
                    </Typography>

                    <Grid container spacing={2} mt={0.5}>

                        <Grid item xs={6}>
                            <TextFormField
                                name="healthDeclaration"
                                label="Motivo"
                                multiline
                                rows={3}
                                fullWidth
                                variant={'outlined'}
                            />
                        </Grid>
                    </Grid>
                </ConfigurableHiddenField>
            </Box>
        </>
    );
};

export default NaturalPersonInformationTab;
