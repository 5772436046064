export class ReserveTokenCreateModel {
    warrantyRegistrationOffice: string;
    token: Token;
    expirationDate: string;
    warrantyCode: string;

    constructor() {
        this.warrantyRegistrationOffice = '';
        this.token = { tokenType: '', tokenValue: '' };
        this.expirationDate = '';
        this.warrantyCode = '';
    }
}

export interface Token {
    tokenType: string;
    tokenValue: string;
}