import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DownloadIcon, SearchIcon } from '@uy3/web-components';
import { FileConfirmationFiltersContainer } from 'components/DataTableFilters/FileConfirmationFilters/FileConfirmationFiltersContainer';
import { RowActions } from 'components/RowActions/RowActions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { useNavigate } from 'react-router';

type FileConfirmationListProps = {
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    queryData: any;
    rowsPerPage: number;
    handleDownloadFileConfirmation: (fileInstructionId: string) => void
    downloadReport: (rowData: any) => void
    page: number;
}

export const FileConfirmationList: React.FC<FileConfirmationListProps> = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    handleDownloadFileConfirmation,
    downloadReport
}) => {
    const navigate = useNavigate()
    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'walletCode',
            headerName: 'Código do Convênio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'date',
            headerName: 'Data do upload',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ value }) => new Date(value).toLocaleDateString('pt-BR', {
                hour: '2-digit',
                minute: '2-digit',
            })
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 0,
            editable: false,
            renderCell: ({ row }) => {
                return <RowActions
                    listButtons={[
                        {
                            action: () => navigate(`/gestao-carteira/carteiras/arquivos/${row?.id}/retorno`),
                            label: "Ver transações",
                            icon: <SearchIcon sx={iconSx} />,
                            disabled: false,
                            hide: false
                        },
                        {
                            action: () => handleDownloadFileConfirmation(row?.id),
                            label: " Download Retorno",
                            icon: <DownloadIcon sx={iconSx} />,
                            disabled: false,
                            hide: false
                        },
                        {
                            action: () => downloadReport(row), 
                            label: "Download Francesinha", 
                            disabled: false, 
                            hide: false, 
                            icon: <DownloadIcon sx={iconSx}/>
                        }
                    ]}
                />
            },
        },
    ];
    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <FileConfirmationFiltersContainer />,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    )
}
