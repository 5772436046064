import type { FunctionComponent } from 'react';
import type { BannerProps } from './Banner.interface';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// import required modules
import { Pagination } from 'swiper';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';

import { BannerBox, Title } from './Banner.styles';

export const Banner: FunctionComponent<BannerProps> = ({ sliders }) => {
  return (
    <BannerBox>
      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
      >
        {sliders?.map((slide) => (
          <SwiperSlide key={slide.title}>
            <Link to={slide.href} style={{ textDecoration: 'none' }}>
              <Box sx={{ background: `${slide.color}` }}>
                {slide.background && <img src={slide.background} alt="backgroundSlide" />}
                <Title>
                  {slide.title}
                  <ArrowForwardIosIcon />
                </Title>
              </Box>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </BannerBox>
  );
};
