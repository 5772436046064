import { Grid, IconButton } from '@mui/material';
import { DataTable, DeleteIcon, SearchIcon } from '@uy3/web-components';
import { GetListApiResponseSuccess, iconSx } from 'contexts/apiRequestContext';
import { GroupInfo } from 'services/user/userGroups';
import { GridColDef } from '@mui/x-data-grid';
import { Stack } from '@mui/system';
import { Link } from 'react-router-dom';
import { useUserPermissionData } from 'contexts/userContext';

type GroupTabProps = {
    page: number;
    setPage: (page: number) => void;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    groupData: GetListApiResponseSuccess<GroupInfo>;
    onRemoveGroup: (groupName: string) => void;
    setOpenPopup?: (rowData: any) => void;
};

export function UserGroupListTab({
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    groupData,
    setOpenPopup,
}: GroupTabProps) {
    const { hasPermission } = useUserPermissionData();
    const columnsGroupTab: GridColDef[] = [
        {
            field: 'groupName',
            headerName: 'Nome do grupo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            align: 'center',
            headerAlign: 'center',
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {hasPermission('UserGroup', 'Read') &&
                            <Link to={`/seguranca/grupos/${cellValues?.id}`}>
                                <IconButton>
                                    <SearchIcon sx={iconSx} />
                                </IconButton>
                            </Link>}
                        {hasPermission('UserGroup', 'Delete') && <IconButton onClick={() => setOpenPopup && setOpenPopup(cellValues)}>
                            <DeleteIcon sx={iconSx} />
                        </IconButton>}
                    </Stack>
                );
            },
        },
    ];

    return (
        <>
            <DataTable
                NoResultsOverlayNew="Nenhum grupo encontrado"
                NoRowsOverlayNew="Nenhum grupo encontrado"
                getRowId={(row) => row?.groupName ?? row?.id}
                columns={columnsGroupTab}
                rows={groupData?.data ?? []}
                page={page}
                rowCount={5}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    );
}

export default UserGroupListTab;
