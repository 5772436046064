import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { Link } from 'react-router-dom';
import { activeTheme } from 'services/theme';

interface IFundListHeaderProps {
    isFetching: boolean;
    refetch: () => void;
    hasPermission: (resource: string | string[], type: any) => boolean
}

const theme = activeTheme();
const FundListHeader: React.FC<IFundListHeaderProps> = ({ isFetching, refetch, hasPermission }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Fundos
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color }}
                    variant="text"
                    disabled={isFetching}
                    onClick={() => refetch()}
                    size="large"
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                >
                    Atualizar
                </Button>
                {hasPermission('Fund', 'Create') &&
                    <Link to="/cadastro/fundos/novo" style={{ textDecoration: 'none' }}>
                        <Button
                            sx={{ color }}
                            variant="text"
                            size="large"
                            startIcon={<AddIcon htmlColor={color} sx={iconSx} />}
                        >
                            Novo cadastro
                        </Button>
                    </Link>}
            </Grid>
        </Stack>
    );
};
export default FundListHeader;
