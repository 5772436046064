import { Stack } from '@mui/material';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { evidenceType, fraudModalityList, relatedActivityList } from './Options';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';

export default function FraudRecordForm() {
    return (
        <Stack spacing={3}>
            <DatePickerFormField label="Data do evento" name="eventDateTime" fullWidth required />
            <SelectFormField
                label="Tipo de evidência"
                name="evidenceType"
                options={evidenceType}
                fullWidth
                required
            />
            <SelectFormField
                label="Tipo de Fraude"
                name="fraudModality"
                options={sortToSelectOptionList(fraudModalityList)}
                fullWidth
                required
            />
            <SelectFormField
                label="Atividade relacionada"
                name="relatedActivity"
                options={sortToSelectOptionList(relatedActivityList)}
                fullWidth
                required
            />
            <CurrencyFormField
                name="transactionValue"
                label="Valor da transação"
                fullWidth
                variant="outlined"
            />
            <TextFormField
                fullWidth
                name="description"
                variant="outlined"
                label="Motivo da fraude"
                multiline
                rows={3}
            />
        </Stack>
    );
}
