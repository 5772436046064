import { Grid, Stack } from '@mui/material';
import { CardButton, Typography, PixIcon, KeyIcon, TransferIcon, CopyIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { useNavigate } from 'react-router-dom';
import { QrCode } from '@mui/icons-material';

type HeaderTransferProps = {
  type: "Pix" | "Transfer";
  title?: string;
  description?: string;
  handlePix?: () => void;
  handlePixQRcode?: () => void;
  handlePixCopyAndPaste?: () => void;
}

const theme = activeTheme();
export const HeaderTransfer = ({
  title = "Efetuar uma transferência",
  description = "Selecione um favorecido, valor e data para efetuar sua transferência",
  type,
  handlePix,
  handlePixCopyAndPaste, 
  handlePixQRcode
}: HeaderTransferProps) => {
  const navigate = useNavigate();
  return (
    <>
      <Stack spacing={2}>
        <Grid>
          <Typography variant='xxl'>{title}</Typography>
          <Typography variant='h5' mt={0.4} color="neutral.medium">{description}</Typography>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2} mt={2} mb={3}>
            {type === 'Pix' ? <>
              <CardButton
                fullWidth
                contentSx={{height: '90px'}}
                name="btn-card-pix"
                description="Transferências com chave Pix ou agência e conta 24hrs por dia, todos os dias."
                title="Pagar Pix com chave"
                icon={<PixIcon htmlColor={theme.palette.primary.main} />}
                onClick={handlePix}
              />

              <CardButton
                fullWidth
                contentSx={{height: '90px'}}
                name="btn-card-pix-copy-and-paste"
                description="Transferências copia e cola 24hrs por dia, todos os dias."
                title="Pagar Pix copia e cola"
                icon={<CopyIcon htmlColor={theme.palette.primary.main} />}
                onClick={handlePixCopyAndPaste}
              />

              <CardButton
                fullWidth
                contentSx={{height: '90px'}}
                name="btn-card-pix-qrcode"
                description="Receba transferências Pix gerando QR Codes a qualquer momento."
                title="Receber Pix QR Code"
                icon={<QrCode htmlColor={theme.palette.primary.main} />}
                onClick={handlePixQRcode}
              />

              <CardButton
                fullWidth
                contentSx={{height: '90px'}}
                name="btn-card-manage-keys"
                description="Cadastre ou exclua as suas chaves Pix sempre que quiser."
                title="Gerenciar chaves"
                icon={<KeyIcon htmlColor={theme.palette.primary.main} />}
                onClick={() => navigate('gerenciar-chaves')}
              />
            </> :
              <CardButton
                name="btn-card-transfer"
                description="Selecione a modalidade de transferência que melhor atender a sua necessidade"
                title="Transferir"
                icon={<TransferIcon htmlColor={theme.palette.primary.main} />}
                onClick={handlePix}
              />}
          </Stack>
        </Grid>
      </Stack>
    </>
  )
}
