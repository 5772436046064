import { boolean, object, string } from "yup";


export const validationGenerateCnabSchema = () => {
    return object().shape({
        assignmentCalculation: boolean().nullable(),
        amount: string().when("assignmentCalculation", {
            is: true,
            then: string().required('Valor precisa ser preenchido').typeError('Valor precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        type: string().when("assignmentCalculation", {
            is: true,
            then: string().required('Tipo do cálculo precisa ser selecionado').typeError('Tipo do cálculo precisa ser selecionado.'),
            otherwise: string().nullable()
        }),
        mode: string().when("assignmentCalculation", {
            is: true,
            then: string().required('Modelo de cálculo precisa ser selecionado').typeError('Modelo de cálculo precisa ser selecionado.'),
            otherwise: string().nullable()
        })
    });
};

export const defaultValuesGenerateCnab = {
    assignmentCalculation: false,
    mode: null,
    type: null, 
    amount: null
};