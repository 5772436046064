import React, { createContext, useContext, useState } from 'react';

interface AppAreaProviderProps {
    children: JSX.Element,
}

interface AppAreaContextProps {
    menuWidth: number,
    setMenuWidth: (width: number) => void
}

const AppAreaContext = createContext<AppAreaContextProps>({
    menuWidth: 0,
    setMenuWidth: () => { },
});

export const AppAreaProvider = ({ children }: AppAreaProviderProps) => {

    const [menuWidth, setMenuWidth] = useState<number>(0);
    return <AppAreaContext.Provider
        value={{
            menuWidth,
            setMenuWidth
        }}>
        {children}
    </AppAreaContext.Provider>
};

export function useAppArea(): AppAreaContextProps {
    const context = useContext(AppAreaContext);
    const {
        menuWidth,
        setMenuWidth,
    } = context;

    return {
        menuWidth,
        setMenuWidth,
    };
}