import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Button, SaveIcon, CloseIcon } from '@uy3/web-components';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { EnumItem } from 'contexts/enumContext';
import { sortToSelectOptionList } from 'helpers/formats/ArrayFormats';
import { activeTheme } from 'services/theme';

type RequiredDocumentsFormProps = {
    onClose: () => void;
    typeOfDocument: EnumItem[];
};

const theme = activeTheme();
export const RequiredDocumentsForm = ({ onClose, typeOfDocument }: RequiredDocumentsFormProps) => {
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const typeOfDocumentsOptions = sortToSelectOptionList(typeOfDocument);
    return (
        <Stack spacing={2}>
            <Grid item>
                <TextFormField
                    label="Agrupamento"
                    name="group"
                    variant="outlined"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid item>
                <SelectFormField
                    label="Tipo de pessoa"
                    name="personType"
                    options={[
                        { label: 'Pessoa Física', value: 'Natural' },
                        { label: 'Pessoa Jurídica', value: 'Legal' },
                    ]}
                />
            </Grid>

            <Grid item>
                <SelectFormField
                    label="Tipo de documento"
                    name="fileType"
                    options={typeOfDocumentsOptions}
                />
            </Grid>
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-add-document-form"
                    type="submit"
                    startIcon={<SaveIcon htmlColor={theme.palette.primary.contrastText} />}
                    variant="contained"
                    size="medium"
                >
                    Adicionar documento
                </Button>
            </Stack>
        </Stack>
    );
};
