import { object, string, number } from 'yup';

export function validationSchemaFirstInstructionDebtCollection() {
    return object().shape({
        firstInstructionDebtCollection: string().typeError("Instrução: Precisa ser preenchido").required("Instrução: Precisa ser preenchido"),
        secondInstructionDebtCollection: number().when("firstInstructionDebtCollection", {
            is: "06",
            then: number()
                .required('Nº de dias após vencimento para envio automático precisa ser preenchido.')
                .typeError('Nº de dias após vencimento para envio automático: precisa ser preenchido.')
                .min(3, 'Dias após vencimento: deve ser no mínimo 3 dias')
                .max(30, 'Dias após vencimento: não pode no máximo 30 dias'),
            otherwise: number().nullable()
        }),
    });
}

export const defaultValuesFirstInstructionDebtCollection = {
    firstInstructionDebtCollection: null, 
    secondInstructionDebtCollection: null
};
