import { useMutation, useQuery } from "@tanstack/react-query";
import { ApiResponseError, useApiRequest } from "./apiRequestContext";
import { useIdentity } from "./identityContext";
import { getDataset, getQueryMarginDataset, postDataset, postQueryMarginDataset } from "services/datasets/datasets";
import { AxiosError, isAxiosError } from "axios";
import { useUserPermissionData } from "./userContext";
import { checkActionsPerms } from "services/permissions";
import { ISiapeMarginQueryReadModel, ISiapeMarginQueryParams } from "services/datasets/datasets.interface";
import { handleErrorUseQuery } from "helpers";

export function useDatasetData(recordType: string, id: string, dataset: string) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission, data: permissionsData } = useUserPermissionData();
    const hasPermissionToReadDataset = checkActionsPerms('ReadDataSet', permissionsData, 'CreditNote');
    const hasPermNP = hasPermission('NaturalPerson', 'Read');
    const hasPermPJ = hasPermission('LegalPerson', 'Read');

    const { data, error, status, refetch, isFetching, isSuccess } = useQuery({
        enabled: !!token && id !== "novo" && Boolean(id) && (hasPermNP || hasPermPJ) && hasPermissionToReadDataset,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        staleTime: 120000,
        queryKey: [recordType, dataset ?? 'all', id],
        queryFn: async () => {
            startRequest();
            const resp = await getDataset(recordType, id, dataset, token!);
            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data;
        }
    });

    return { dataset: data as any[], isSuccess, error, status, refetch, isFetching };
};

export function useDatasetMutation(recordType: string, id: string, onSuccess?: (data: any) => void, onError?: (error: any) => void) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutate, isLoading } = useMutation({
        mutationFn: async (values: string) => {
            startRequest();
            const { data, status, statusText } = await postDataset(recordType, id, values, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.'
            let apiError: ApiResponseError = { type: "error", message, code: 'UNKNOWN', errors: [] }
            if (isAxiosError(error)) {
                const axErr = error as AxiosError
                apiError = { type: "error", code: axErr.code!, errors: [] }
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError
                    if (data) {
                        apiError = respData
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });

    return { mutate, isLoading };
};


export const useSiapeMarginQuery = (params: ISiapeMarginQueryParams) => {
    const { personId, registrationNumber } = params ?? {};

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useQuery({
        enabled: Boolean(token) && (Boolean(personId) || Boolean(registrationNumber)),
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ["siape-margin-query", params],
        queryFn: async () => {
            startRequest();
            const resp = await getQueryMarginDataset(params, token!);

            const { data, status, statusText } = resp;
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: "error",
                    code: status + "" + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            }

            return data as ISiapeMarginQueryReadModel[]
        }
    });
};


export function useSiapeMarginMutation(onSuccess?: (data: any) => void, onError?: (error: any) => void) {

    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (params: ISiapeMarginQueryParams) => {
            startRequest();
            const { data } = await postQueryMarginDataset(params, token!);
            endRequest(true)
            return data;
        },
        onSuccess(data) {
            onSuccess && onSuccess(data as ISiapeMarginQueryReadModel);
        },
        onError(error) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError!);
        }
    });
};
