import { boolean } from 'yup';
import { string, object, array } from 'yup';

export const validationSchemaApproveMarginReverse = () => {
    return object().shape({
        message: string()
            .typeError('Mensagem: Precisa ser preenchido')
            .required('Mensagem: Precisa ser preenchido'),
        valueMarginReserve: string()
            .typeError('Valor de margem: Precisa ser preenchido')
            .required('Valor de margem: Precisa ser preenchido'),
        includePaymentFixedCosts: boolean(),
        selectedTokens: array()
            .of(string())
            .typeError('Selecione ao menos 1 token')
            .required('Selecione ao menos 1 token')
            .min(1, 'Selecione ao menos 1 token')
    });
};

export const defaultvaluesApproveMarginReverse = (
    includePaymentFixedCostsValue?: boolean,
    valueMarginReserveValue?: number
) => {
    return {
        message: null,
        valueMarginReserve: valueMarginReserveValue ?? null,
        includePaymentFixedCosts: includePaymentFixedCostsValue ?? false,
    };
};
