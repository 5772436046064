import React from 'react';
import { Stack } from '@mui/material';
import { Avatar, Typography } from '@uy3/web-components';
import { CustomInfoContainer } from '../CustomMUI';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { activeTheme } from 'services/theme';

interface CardInfoProps {
    name: string;
    registrationNumber: string;
    companyName?: string
}

const theme = activeTheme();
const avatarSx = {
    color: theme.palette.common.black,
    bgcolor: '#666666',
};

const CardInfo: React.FC<CardInfoProps> = ({ name, registrationNumber, companyName }) => {
    const onlyNumbers = /\D/g;
    const onlyRegistrationNumber: string = registrationNumber?.replace(onlyNumbers, '');
    const registrationNumberFormated = formatDocumentNumber(onlyRegistrationNumber) ?? "N/D";

    const isLegalPerson = onlyRegistrationNumber?.length > 11;

    return (
        <CustomInfoContainer>
            <Stack direction="row" alignItems="center" spacing={2} p={2}>
                <Avatar sx={avatarSx} />
                <Stack spacing={0.5} direction="column">
                    <Typography variant="sm" color="common.black">
                        {`${name} ${isLegalPerson && companyName?.length? `| ${companyName}` : ''}`}
                    </Typography>
                    <Typography variant="xs" color="neutral.medium">
                        {registrationNumberFormated}
                    </Typography>
                </Stack>
            </Stack>
        </CustomInfoContainer>
    );
};

export default CardInfo;
