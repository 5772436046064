import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const NotFoundIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props}>
      <path
        d="M171.957 0H44.2978C38.4225 0 33.6595 4.76497 33.6595 10.6429V138.357C33.6595 144.235 38.4225 149 44.2978 149H171.957C177.833 149 182.596 144.235 182.596 138.357V10.6429C182.596 4.76497 177.833 0 171.957 0Z"
        fill="#E9D4C4"
      />
      <g filter="url(#filter0_d_707_1351)">
        <path
          d="M154.936 10.6429H11.3191C8.38146 10.6429 6 13.0253 6 15.9643V42.5714C6 45.5104 8.38146 47.8929 11.3191 47.8929H154.936C157.874 47.8929 160.255 45.5104 160.255 42.5714V15.9643C160.255 13.0253 157.874 10.6429 154.936 10.6429Z"
          fill="white"
        />
      </g>
      <path
        d="M79.4043 19.1571H51.7447C49.9821 19.1571 48.5532 20.5866 48.5532 22.35C48.5532 24.1134 49.9821 25.5428 51.7447 25.5428H79.4043C81.1669 25.5428 82.5958 24.1134 82.5958 22.35C82.5958 20.5866 81.1669 19.1571 79.4043 19.1571Z"
        fill="#ECA973"
      />
      <path
        d="M98.5532 32.9928H51.7447C49.9821 32.9928 48.5532 34.4223 48.5532 36.1857C48.5532 37.9491 49.9821 39.3786 51.7447 39.3786H98.5532C100.316 39.3786 101.745 37.9491 101.745 36.1857C101.745 34.4223 100.316 32.9928 98.5532 32.9928Z"
        fill="#F18B3A"
      />
      <path
        d="M34.7234 13.8357H14.5107C11.573 13.8357 9.19153 16.2182 9.19153 19.1571V39.3786C9.19153 42.3175 11.573 44.7 14.5107 44.7H34.7234C37.6611 44.7 40.0426 42.3175 40.0426 39.3786V19.1571C40.0426 16.2182 37.6611 13.8357 34.7234 13.8357Z"
        fill="#D04D27"
      />
      <path
        d="M22.1936 32.7587H24.3213L24.3447 31.9392C24.3447 30.8622 25.0234 30.5812 25.983 30.2768C26.9455 30.0029 27.7928 29.4229 28.3966 28.6244C29.0004 27.826 29.328 26.8527 29.3298 25.8515C29.3509 25.2402 29.2449 24.6312 29.0185 24.0631C28.7922 23.4949 28.4504 22.9799 28.0148 22.5508C27.5792 22.1216 27.0593 21.7875 26.488 21.5698C25.9166 21.352 25.3064 21.2553 24.6958 21.2857C24.0504 21.2716 23.409 21.3888 22.8103 21.6302C22.2117 21.8716 21.6683 22.2321 21.213 22.6899C20.7578 23.1477 20.4003 23.6932 20.1622 24.2934C19.924 24.8936 19.8102 25.5359 19.8277 26.1814H22.0277C22.0193 25.825 22.0822 25.4705 22.2125 25.1387C22.3429 24.8069 22.5381 24.5045 22.7868 24.2492C23.0355 23.9938 23.3326 23.7907 23.6608 23.6517C23.9889 23.5128 24.3415 23.4407 24.6979 23.4398C25.0192 23.4212 25.3408 23.472 25.6408 23.5887C25.9408 23.7055 26.2122 23.8855 26.4364 24.1166C26.6607 24.3476 26.8326 24.6242 26.9405 24.9276C27.0484 25.231 27.0897 25.5541 27.0617 25.8749C27.0475 26.3942 26.8671 26.8951 26.5468 27.304C26.2266 27.7129 25.7836 28.0081 25.283 28.1461L24.5106 28.4526C23.7545 28.6349 23.0943 29.0946 22.6609 29.7407C22.2274 30.3868 22.0523 31.1722 22.1702 31.9413L22.1936 32.7587ZM23.2468 37.8183C23.4552 37.8257 23.6629 37.7913 23.8577 37.717C24.0525 37.6427 24.2304 37.5301 24.381 37.3858C24.5315 37.2416 24.6516 37.0685 24.7342 36.877C24.8168 36.6855 24.8601 36.4794 24.8617 36.2709C24.8599 36.0625 24.8163 35.8566 24.7336 35.6653C24.6509 35.4741 24.5307 35.3013 24.3802 35.1573C24.2297 35.0132 24.0518 34.9008 23.8572 34.8267C23.6625 34.7525 23.455 34.7181 23.2468 34.7255C23.0387 34.7181 22.8311 34.7525 22.6365 34.8267C22.4418 34.9008 22.2639 35.0132 22.1134 35.1573C21.9629 35.3013 21.8427 35.4741 21.76 35.6653C21.6773 35.8566 21.6338 36.0625 21.6319 36.2709C21.6335 36.4794 21.6769 36.6855 21.7594 36.877C21.842 37.0685 21.9621 37.2416 22.1127 37.3858C22.2632 37.5301 22.4412 37.6427 22.636 37.717C22.8308 37.7913 23.0385 37.8257 23.2468 37.8183Z"
        fill="white"
      />
      <g filter="url(#filter1_d_707_1351)">
        <path
          d="M57.0638 56.4071H200.681C202.092 56.4071 203.444 56.9678 204.442 57.9657C205.44 58.9637 206 60.3172 206 61.7286V88.3357C206 89.747 205.44 91.1006 204.442 92.0985C203.444 93.0965 202.092 93.6571 200.681 93.6571H57.0638C55.6531 93.6571 54.3001 93.0965 53.3026 92.0985C52.305 91.1006 51.7446 89.747 51.7446 88.3357V61.7286C51.7446 60.3172 52.305 58.9637 53.3026 57.9657C54.3001 56.9678 55.6531 56.4071 57.0638 56.4071V56.4071Z"
          fill="white"
        />
      </g>
      <path
        d="M125.149 64.9214H97.4893C95.7267 64.9214 94.2979 66.3509 94.2979 68.1143C94.2979 69.8776 95.7267 71.3071 97.4893 71.3071H125.149C126.912 71.3071 128.34 69.8776 128.34 68.1143C128.34 66.3509 126.912 64.9214 125.149 64.9214Z"
        fill="#ECA973"
      />
      <path
        d="M144.298 78.7571H97.4893C95.7267 78.7571 94.2979 80.1866 94.2979 81.95C94.2979 83.7133 95.7267 85.1428 97.4893 85.1428H144.298C146.06 85.1428 147.489 83.7133 147.489 81.95C147.489 80.1866 146.06 78.7571 144.298 78.7571Z"
        fill="#F18B3A"
      />
      <path
        d="M80.4681 59.6H60.2553C57.3176 59.6 54.9362 61.9825 54.9362 64.9214V85.1428C54.9362 88.0818 57.3176 90.4643 60.2553 90.4643H80.4681C83.4058 90.4643 85.7872 88.0818 85.7872 85.1428V64.9214C85.7872 61.9825 83.4058 59.6 80.4681 59.6Z"
        fill="#D04D27"
      />
      <path
        d="M67.9383 78.523H70.0659L70.0893 77.7035C70.0893 76.6264 70.768 76.3455 71.7276 76.0411C72.6902 75.7671 73.5374 75.1871 74.1412 74.3887C74.7451 73.5903 75.0726 72.617 75.0744 71.6158C75.0955 71.0045 74.9895 70.3955 74.7631 69.8273C74.5368 69.2592 74.195 68.7442 73.7594 68.315C73.3238 67.8859 72.8039 67.5518 72.2326 67.3341C71.6613 67.1163 71.051 67.0196 70.4404 67.05C69.7951 67.0359 69.1536 67.1531 68.555 67.3945C67.9563 67.6359 67.4129 67.9964 66.9577 68.4542C66.5025 68.912 66.1449 69.4575 65.9068 70.0577C65.6687 70.6579 65.5549 71.3002 65.5723 71.9457H67.7723C67.7639 71.5893 67.8268 71.2348 67.9571 70.903C68.0875 70.5712 68.2827 70.2688 68.5314 70.0134C68.7801 69.7581 69.0772 69.555 69.4054 69.416C69.7336 69.277 70.0862 69.205 70.4425 69.2041C70.7639 69.1855 71.0855 69.2363 71.3855 69.353C71.6855 69.4698 71.9568 69.6498 72.1811 69.8808C72.4053 70.1119 72.5772 70.3885 72.6851 70.6919C72.793 70.9953 72.8344 71.3184 72.8063 71.6392C72.7922 72.1584 72.6117 72.6594 72.2915 73.0683C71.9712 73.4772 71.5282 73.7724 71.0276 73.9104L70.2553 74.2169C69.4991 74.3992 68.8389 74.8589 68.4055 75.505C67.9721 76.1511 67.797 76.9365 67.9149 77.7056L67.9383 78.523ZM68.9914 83.5826C69.1998 83.59 69.4075 83.5555 69.6023 83.4813C69.7971 83.407 69.975 83.2944 70.1256 83.1501C70.2762 83.0058 70.3963 82.8328 70.4788 82.6413C70.5614 82.4498 70.6048 82.2437 70.6063 82.0351C70.6045 81.8268 70.5609 81.6209 70.4782 81.4296C70.3955 81.2383 70.2754 81.0656 70.1248 80.9216C69.9743 80.7775 69.7965 80.6651 69.6018 80.5909C69.4072 80.5168 69.1996 80.4824 68.9914 80.4898C68.7833 80.4824 68.5757 80.5168 68.3811 80.5909C68.1864 80.6651 68.0086 80.7775 67.8581 80.9216C67.7075 81.0656 67.5874 81.2383 67.5047 81.4296C67.422 81.6209 67.3784 81.8268 67.3766 82.0351C67.3781 82.2437 67.4215 82.4498 67.5041 82.6413C67.5866 82.8328 67.7067 83.0058 67.8573 83.1501C68.0078 83.2944 68.1858 83.407 68.3806 83.4813C68.5754 83.5555 68.7831 83.59 68.9914 83.5826Z"
        fill="white"
      />
      <g filter="url(#filter2_d_707_1351)">
        <path
          d="M11.3191 102.171H154.936C156.347 102.171 157.7 102.732 158.697 103.73C159.695 104.728 160.255 106.082 160.255 107.493V134.1C160.255 135.511 159.695 136.865 158.697 137.863C157.7 138.861 156.347 139.421 154.936 139.421H11.3191C9.90842 139.421 8.55548 138.861 7.55794 137.863C6.56041 136.865 6 135.511 6 134.1V107.493C6 106.082 6.56041 104.728 7.55794 103.73C8.55548 102.732 9.90842 102.171 11.3191 102.171V102.171Z"
          fill="white"
        />
      </g>
      <path
        d="M79.4043 110.686H51.7447C49.9821 110.686 48.5532 112.115 48.5532 113.879C48.5532 115.642 49.9821 117.071 51.7447 117.071H79.4043C81.1669 117.071 82.5958 115.642 82.5958 113.879C82.5958 112.115 81.1669 110.686 79.4043 110.686Z"
        fill="#ECA973"
      />
      <path
        d="M98.5532 124.521H51.7447C49.9821 124.521 48.5532 125.951 48.5532 127.714C48.5532 129.478 49.9821 130.907 51.7447 130.907H98.5532C100.316 130.907 101.745 129.478 101.745 127.714C101.745 125.951 100.316 124.521 98.5532 124.521Z"
        fill="#F18B3A"
      />
      <path
        d="M34.7234 105.364H14.5107C11.573 105.364 9.19153 107.747 9.19153 110.686V130.907C9.19153 133.846 11.573 136.229 14.5107 136.229H34.7234C37.6611 136.229 40.0426 133.846 40.0426 130.907V110.686C40.0426 107.747 37.6611 105.364 34.7234 105.364Z"
        fill="#D04D27"
      />
      <path
        d="M22.1936 124.287H24.3213L24.3447 123.468C24.3447 122.391 25.0234 122.11 25.983 121.805C26.9455 121.531 27.7928 120.951 28.3966 120.153C29.0004 119.355 29.328 118.381 29.3298 117.38C29.3509 116.769 29.2449 116.16 29.0185 115.592C28.7922 115.023 28.4504 114.509 28.0148 114.079C27.5792 113.65 27.0593 113.316 26.488 113.098C25.9166 112.881 25.3064 112.784 24.6958 112.814C24.0504 112.8 23.409 112.917 22.8103 113.159C22.2117 113.4 21.6683 113.761 21.213 114.218C20.7578 114.676 20.4003 115.222 20.1622 115.822C19.924 116.422 19.8102 117.064 19.8277 117.71H22.0277C22.0193 117.354 22.0822 116.999 22.2125 116.667C22.3429 116.335 22.5381 116.033 22.7868 115.778C23.0355 115.522 23.3326 115.319 23.6608 115.18C23.9889 115.041 24.3415 114.969 24.6979 114.968C25.0192 114.95 25.3408 115.001 25.6408 115.117C25.9408 115.234 26.2122 115.414 26.4364 115.645C26.6607 115.876 26.8326 116.153 26.9405 116.456C27.0484 116.76 27.0897 117.083 27.0617 117.403C27.0475 117.923 26.8671 118.424 26.5468 118.833C26.2266 119.241 25.7836 119.537 25.283 119.675L24.5106 119.981C23.7545 120.163 23.0943 120.623 22.6609 121.269C22.2274 121.915 22.0523 122.701 22.1702 123.47L22.1936 124.287ZM23.2468 129.347C23.4552 129.354 23.6629 129.32 23.8577 129.246C24.0525 129.171 24.2304 129.059 24.381 128.914C24.5315 128.77 24.6516 128.597 24.7342 128.406C24.8168 128.214 24.8601 128.008 24.8617 127.799C24.8599 127.591 24.8163 127.385 24.7336 127.194C24.6509 127.003 24.5307 126.83 24.3802 126.686C24.2297 126.542 24.0518 126.429 23.8572 126.355C23.6625 126.281 23.455 126.247 23.2468 126.254C23.0387 126.247 22.8311 126.281 22.6365 126.355C22.4418 126.429 22.2639 126.542 22.1134 126.686C21.9629 126.83 21.8427 127.003 21.76 127.194C21.6773 127.385 21.6338 127.591 21.6319 127.799C21.6335 128.008 21.6769 128.214 21.7594 128.406C21.842 128.597 21.9621 128.77 22.1127 128.914C22.2632 129.059 22.4412 129.171 22.636 129.246C22.8308 129.32 23.0385 129.354 23.2468 129.347Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_707_1351"
          x="0"
          y="7.64285"
          width="166.255"
          height="49.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_707_1351" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_707_1351"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_707_1351"
          x="45.7446"
          y="53.4071"
          width="166.255"
          height="49.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_707_1351" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_707_1351"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_707_1351"
          x="0"
          y="99.1714"
          width="166.255"
          height="49.25"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_707_1351" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_707_1351"
            result="shape"
          />
        </filter>
      </defs>
    </SvgIcon>
  );
};

NotFoundIcon.defaultProps = {
  viewBox: '0 0 212 149',
  fill: 'none',
  width: '212',
  height: '149'
};
