/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import BillingDetailsForm from './BillingDetailsForm';
import AddInterestModelForm from './AddInterestModelForm';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import InterestModelDetails from './InterestModelDetails';
import { useFormContext } from 'contexts/formContext';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { IBillingSettingFull } from 'services/walletManagement/billingSetting/billingSetting.types';

interface BillingParametersFormWrapperProps {
    // setAddInterestModel: React.Dispatch<React.SetStateAction<boolean>>;
    refetchParametrs: () => void;
    billingSettingData: IBillingSettingFull[];
    communicationSettingAutocomplete: AutocompleteOptionList;
    setAction: (step: string) => void
}

const BillingParametersFormWrapper: React.FC<BillingParametersFormWrapperProps> = ({
    billingSettingData,
    refetchParametrs, 
    communicationSettingAutocomplete, 
    setAction
}) => {
    const { watch } = useFormContext();
    const amount = watch('amount');

    useEffect(() => { refetchParametrs(); }, [])

    return (
        <Stack spacing={3.5}>
            <BillingDetailsForm valorTitulo={amount} />
            <AddInterestModelForm
                billingSettingData={billingSettingData}
                refetchParametrs={refetchParametrs}
                communicationSettingAutocomplete={communicationSettingAutocomplete}
                setAction={setAction}
            />
            <InterestModelDetails />
            <HorizontalInfo type="currency" value={amount} />
        </Stack>
    );
};

export default BillingParametersFormWrapper;
