import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import moment from "moment";
import { number, object, string } from "yup";

export const validationvGareIcmsFormSchema = () => {
    return object().shape({
        paymentValue: number()
            .typeError('Valor do pagamento: Precisa ser preenchido.')
            .required("Valor do pagamento: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
        paymentDate: string().typeError('Data de apuração Precisa ser preenchido.').required("Data de apuração: Precisa ser preenchido."),
        name: string().typeError('Nome Precisa ser preenchido.').required("Nome: Precisa ser preenchido."),
        dueDate: string().typeError('Data de vencimento Precisa ser preenchido.').required("Data de vencimento: Precisa ser preenchido."),
        registrationNumber: string().typeError('CPF/CNPJ precisa ser um número.').required("CPF/CNPJ: Precisa ser preenchido.").cpfCnpjValidation("CPF ou CNPJ inválido."),
        stateRegistration: string().typeError('Estádo de registro Precisa ser preenchido.').required("Estádo de registro: Precisa ser preenchido."),
        diNumber: number().typeError('Número do DI precisa ser um número.').required("Número do DI: Precisa ser preenchido."),
        principalValue: number()
            .typeError('Valor principal: Precisa ser preenchido.')
            .required("Valor principal: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor principal: Precisa ter um valor maior que 0.'),
        totalValue: number()
            .typeError('Valor total: Precisa ser preenchido.')
            .required("Valor total: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor total: Precisa ter um valor maior que 0.'),
        receitaFederalCodeId: string().typeError('Código da receita federal inválido.').required("Código da receita federal: Precisa ser preenchido."),
        referenceNumber: string().typeError('Ano e mês de referência inválido.').required("Ano e mês de referência: Precisa ser preenchido."),
        installmentNumber: number().typeError('Número de parcelamento precisa ser um número.').required("Número de parcelamento: Precisa ser preenchido."),
        valueOfRevenue: number().typeError('Valor da receita Precisa ser preenchido.').required("Valor da receita: Precisa ser preenchido."),
        financialAddition: number().typeError('Valor do acréscimo precisa ser um número.').required("Valor do acréscimo: Precisa ser preenchido."),
        taxPayer: number().typeError('Número de contribuinte precisa ser um número.').required("Número de contribuinte: Precisa ser preenchido."),
    });
};

export const defaultValuesGareIcmsForm = {
    dueDate: moment(new Date()).format('YYYY-MM-DD'), 
    paymentDate: moment(new Date()).format('YYYY-MM-DD'), 
    referenceNumber: moment(new Date()).format('YYYY-MM'), 
    name: "",
    stateRegistration: "",
    registrationNumber: "", 
    paymentValue: null, 
    financialAddition: null, 
    diNumber: null,
    taxPayer: null, 
    valueOfRevenue: null, 
    installmentNumber: null, 
    principalValue: null, 
    totalValue: null, 
    receitaFederalCodeId: null, 
};