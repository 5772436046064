import { useState } from 'react';
import { GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Grid, Stack } from '@mui/material';
import {
    ApproveIcon,
    Button,
    CloseIcon,
    Drawer,
    RejectIcon,
    UpdateIcon,
} from '@uy3/web-components';
import { Error } from 'components/Errors/Error';
import { Actions } from 'components/Actions/Actions';
import { RefreshProgress } from 'components/RefreshProgress';
import { useApprovals } from 'contexts/approvalsContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { ToastType, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { ApprovalsDetails } from '../Details/ApprovalsDetails';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import Toast from 'components/Toast/Toast';
import { ApprovalsHeader } from 'pages/Approvals/ApprovalsForm/ApprovalsHeader';
import PopupContainer from './PopupContainer/PopupContainer';
import { PersonFull, usePersonsList } from 'contexts/personContext';
import { useTenant } from 'contexts/tenantContext';
import CreditNoteApprovListWithFilters from './CreditNoteApprovListWithFilters';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
export const CreditNoteListApprovalsContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const [openDrawer, setOpenDrawer] = useState<string | undefined>(undefined);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { submitError } = useApiRequest();
    const [page, setPage] = useState(0);
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const [rowAction, setRowAction] = useState<{ action: string; rowData: any } | undefined>();
    const { personAutoCompleteProps, data: personsData } = usePersonsList(
        { page: 0, size: 10 },
        'always'
    );
    const { tenantAutoCompleteProps } = useTenant();

    const {
        pendingApprovalsData,
        isFetching,
        pendingApprovalsError,
        pendingApprovalsStatus,
        refetch,
        isLoading,
    } = useApprovals({ page, size: rowsPerPage, approvalResource: 'CreditNoteOp' });

    const onClose = () => {
        setOpenDrawer(undefined);
        setRowAction(undefined);
    };

    const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
        setRowsSelected(selectionModel);
    };

    const getCreditNoteOpSelected = pendingApprovalsData?.data?.filter((op: CreditNoteReadModel) =>
        rowsSelected.includes(op.id)
    );

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    if (pendingApprovalsStatus === 'error') return <Error error={pendingApprovalsError} />;

    let dataPerson = personsData as any;
    const personAutocompleteOptions: any = {
        ...personAutoCompleteProps,
        listOptions:
            dataPerson?.data?.map((person: PersonFull) => {
                return { label: person?.name, value: person?.id };
            }) || [],
    };

    let optionsTenantList =
        tenantAutoCompleteProps?.listOptions?.map((item: any) => {
            return { label: item?.label, value: item?.value };
        }) || [];

    return (
        <>
            <ApiErrorAlert error={submitError} />
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={4}>
                <ApprovalsHeader
                    Actions={
                        <Actions
                            numberOfButtons={3}
                            buttonsActionsList={[
                                {
                                    enable: rowsSelected.length > 0,
                                    label: 'Aprovar',
                                    action: () => setOpenDrawer('approveCreditNoteOp'),
                                    icon: <ApproveIcon />,
                                },
                                {
                                    enable: rowsSelected.length > 0,
                                    label: 'Reprovar',
                                    action: () => setOpenDrawer('rejectCreditNoteOp'),
                                    icon: <RejectIcon />,
                                },
                                {
                                    enable: true,
                                    label: 'Atualizar',
                                    action: refetch,
                                    icon: <UpdateIcon />,
                                },
                            ]}
                        />
                    }
                />
            </Grid>

            {!isLoading && <RefreshProgress refreshing={isFetching} />}
            <CreditNoteApprovListWithFilters
                {...{
                    handleSelectionModelChange,
                    isLoading,
                    onChangePage,
                    onChangeRowsPerPage,
                    optionsTenantList,
                    page,
                    pendingApprovalsData,
                    personAutocompleteOptions,
                    rowsPerPage,
                    rowsSelected,
                    setRowAction,
                }}
            />

            <PopupContainer
                openDrawer={openDrawer}
                onClosePopup={onClose}
                getCreditNoteOpSelected={getCreditNoteOpSelected}
                refetch={refetch}
                rowAction={rowAction}
                rowsSelected={rowsSelected}
                setToast={setToast}
            />

            <Drawer
                title="Detalhes"
                anchor="right"
                open={rowAction?.action === 'details'}
                onClose={onClose}
            >
                <ApprovalsDetails detailsData={rowAction?.rowData!} showPersonalData={false} />

                <Stack spacing={2} justifyContent="right" direction="row">
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    >
                        Fechar
                    </Button>
                </Stack>
            </Drawer>
        </>
    );
};
