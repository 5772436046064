import {
    Box,
    SelectChangeEvent,
    SelectProps as MuiSelectProps,
    InputAdornment,
    IconButton
} from '@mui/material';
import { CancelIcon, SearchIcon, Typography } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { SelectFieldComponent } from 'components/Forms/SelectField/SelectField';
import { isFieldDisable, isFieldRequired } from 'helpers';

const theme = activeTheme();

export interface SelectOption {
    label: string;
    value: string | number | undefined | boolean | null;
}

export type SelectFormFieldProps = {
    name: string;
    /**
     * Determina os items do select
     */
    options?: SelectOption[];

    /**
     * Determina o Label do campo
     */
    label: string;
    /**
     * Determina se o campo é obrigatorio
     */
    required?: boolean;

    showEndAdornment?: boolean;

    showButtonClearValue?: boolean;
} & MuiSelectProps; 

export const SelectFormField: FunctionComponent<SelectFormFieldProps> = ({
    options,
    required,
    label,
    disabled,
    ...props
}) => {
    const { formFieldsErrors } = useFormFieldsError();
    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const name = props.name;
    const labels: string[] | undefined = options?.length
        ? options.map((i) => i?.label)
        : ['Nenhuma opção encontrada'];

    let value = undefined;
    var { validationErrors, setValue, watch, readOnly } = useFormContext();

    var error = validationErrors && resolve(name, validationErrors)?.message;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key);
    });

    if (watch) {
        const currentValue = watch(name);
        if (props.multiple) {
            const selectedItems = options?.filter((item) => currentValue?.includes(item?.value));
            const selectedLabels = selectedItems?.map((item) => item.label);
            value = selectedLabels ?? undefined;
        } else {
            const foundItem = options?.find((item) => item.value === currentValue);
            value = foundItem?.label ?? undefined;
        }
    }

    const onChange = (event: SelectChangeEvent<unknown>) => {
        if (props.multiple && options?.length) {
            const selectedOptions = event.target?.value as string[];
            const selectedValues: any[] = [];
            const selectedLabels: any[] = [];

            selectedOptions.forEach((val) => {
                const index = options.findIndex((i) => i?.label === val);
                if (index !== -1) {
                    selectedValues.push(options[index]?.value);
                    selectedLabels.push(options[index]?.label);
                }
            });
            setValue(name, selectedValues, { shouldDirty: true });
            setValue(`${name}Display`, selectedLabels, { shouldDirty: true });
        } else if (options?.length) {
            const value = event.target?.value as string;
            const index = options.findIndex((i) => i?.label === value);
            setValue(name, options[index]?.value, { shouldDirty: true });
            setValue(`${name}Display`, options[index]?.label, { shouldDirty: true });
        }
    };

    const cleanCurrentValueField = () => {
        setValue(name, null); 
        setValue(`${name}Display`, null);
    };

    const isRequired = !!required || isFieldRequired(name);
    const labelWithRequired: string = isRequired ? `${label} *` : label;
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;

    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);
    
    return (
        <Box>
            <SelectFieldComponent
                id={name}
                label={labelWithRequired}
                inputProps={{
                    'aria-label': 'secondary select',
                }}
                endAdornment={
                    <>
                        {props.showEndAdornment && (
                            <InputAdornment position="end" sx={{ marginRight: 1.5 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        )}
                        {props.showButtonClearValue && <>
                            {!!value && !readOnly &&
                                <InputAdornment position="end" sx={{ marginRight: 1.5 }}>
                                    <IconButton onClick={cleanCurrentValueField}>
                                        <CancelIcon
                                            sx={{ width: 15, height: 15 }}
                                            htmlColor="#666666"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            }</>
                        }
                    </>
                }
                key={`${name}${value}key`}
                error={!!error || hasResponseError}
                onChange={onChange}
                items={labels}
                value={value === undefined ? '' : value}
                MenuProps={{
                    PaperProps: {
                        onKeyDown: (event: any) => {
                            if (event.key === 'Enter') {
                                sendSubmitEvent(event)
                            }
                        },
                    }
                }}
                {...props}
                disabled={isFieldDisabledConfig || !!readOnly || disabled}
            />
            {!!error && (
                <Typography mt={0.5} variant="body1" color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography mt={0.5} variant="body1" color={theme.palette.error.dark}>
                    {responseError?.helpMessage}
                </Typography>
            )}
        </Box>
    );
};
