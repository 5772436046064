import { Stack } from '@mui/material';
import DetailsBaseForm from './DetailsBaseForm';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { accountTypeList } from '../Options';

export default function ToAccountForm() {
    return (
        <Stack spacing={3}>
            <DetailsBaseForm prefix="details.toAccount" />
            <TextFormField
                name="details.toAccount.institutionCode"
                variant="outlined"
                label="Código da Instituição"
                fullWidth
                required
            />
            <SelectFormField
                name="details.toAccount.accountType"
                variant="outlined"
                label="Tipo de Conta"
                fullWidth
                required
                options={accountTypeList}
            />

            <TextFormField
                name="details.toAccount.agencyCode"
                variant="outlined"
                label="Código da Agência"
                fullWidth
                required
            />
            <TextFormField
                name="details.toAccount.accountNumber"
                variant="outlined"
                label="Número da Conta"
                fullWidth
                required
            />
            <SelectFormField
                name="details.toAccount.involvement"
                variant="outlined"
                label="Envolvimento"
                fullWidth
                required
                options={[
                    { label: 'Sim', value: 'Sim' },
                    { label: 'Não', value: 'Nao' },
                ]}
            />
        </Stack>
    );
}
