import styled from '@emotion/styled';
import {
  FormControl,
  FormControlLabelProps,
  Radio as MuiRadio,
  RadioGroup,
  useRadioGroup,
} from '@mui/material';
import { StyledControlLabelRadio } from './Radio.styled';
import { RadioProps } from './Radio.interface';
import { Typography } from '../Typography';

export const ControlOptionRadio = (props: FormControlLabelProps): JSX.Element => {
  const radioGroupe = useRadioGroup();
  let checked = false;
  if (radioGroupe) {
    checked = radioGroupe.value === props.value;
  }
  return <StyledControlLabelRadio checked={checked} {...props} />;
};

export const Radio: React.FC<RadioProps> = ({ onChange, options, size, title, ...rest }) => {
  return (
    <FormControl>
      <Typography variant="h5">{title}</Typography>
      <RadioGroup
        id="radio-buttons-group"
        aria-labelledby="radio-buttons-group"
        name="radio-buttons-group"
        {...rest}
      >
        {options?.map((option) => (
          <ControlOptionRadio
            id="radio-button"
            control={
              <MuiRadio
                size={size}
                sx={{
                  '&.Mui-checked': { color: '#e86500' },
                  '& svg': { width: '2.3rem', height: '2.3rem' },
                }}
              />
            }
            {...option}
            label={option.label}
            onChange={onChange}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};
