import { handleNumericNaN } from "helpers/validations/formFieldValidator";
import moment from "moment";
import { number, object, string, date } from "yup";

export const validationvDarfWithBarCodeFormSchema = () => {
    return object().shape({
        name: string().typeError('Nome Precisa ser preenchido.').required("Nome: Precisa ser preenchido."),
        trafficTicketValue: number().typeError('Valor da multa de trânsito: Precisa ser preenchido.').required("Valor da multa de trânsito: Precisa ser preenchido."),
        interestValue: number()
            .typeError('Valor do juros Precisa ser preenchido.')
            .required("Valor do juros: Precisa ser preenchido.")
            .test('validate', 'Valor do juros: não pode ser negativo', (value) => {
                return Number(value) >= 0
            }),
        interestAndChargesValue: number().typeError('Valor de Juros e Encargos Precisa ser preenchido.').required("Valor de Juros e Encargos: Precisa ser preenchido."),
        calculationPeriod: date().typeError('Data de apuração inválida.').required("Data de apuração: Precisa ser preenchido."),
        paymentValue: number()
            .typeError('Valor do pagamento inválido.')
            .required('Valor do pagamento: Precisa ser preenchido.')
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
        receitaFederalCodeId: string().typeError('Código da receita federal inválido.').required("Código da receita federal: Precisa ser preenchido."),
        referenceNumber: string().typeError('Número do documento: Precisa ser preenchido.').required("Número do documento: Precisa ser preenchido."),
        totalValue: number()
            .typeError('Valor total: Precisa ser preenchido.')
            .required("Valor total: Precisa ser preenchido.")
            .transform(handleNumericNaN)
            .min(0.01, 'Valor do pagamento: Precisa ter um valor maior que 0.'),
    });
};

export const defaultValuesDarfWithBarCodeForm = {
    name: "",
    trafficTicketValue: 0,
    interestValue: 0,
    interestAndChargesValue: 0,
    calculationPeriod: moment(new Date()).format('YYYY/MM/DD'),
    paymentValue: 0,
    receitaFederalCodeId: null,
    referenceNumber: "0",
    totalValue: 0,
};