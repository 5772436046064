import { Grid, IconButton, Stack } from '@mui/material';
import { GridColDef, GridRowModel } from '@mui/x-data-grid';
import { DataTable, DeleteIcon, EditIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import { ActionOptions } from './BillingSettingListContainer';
import { WalletFiltersContainer } from 'components/DataTableFilters/WalletFilters/WalletFiltersContainer';

interface BillingSettingListProps {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setActionRowData: React.Dispatch<React.SetStateAction<{
        row: GridRowModel | null;
        action: ActionOptions;
    } | undefined>>
}

const BillingSettingList: React.FC<BillingSettingListProps> = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setActionRowData
}) => {

    const formatShowValueByType = (type: string, value: string) => {
        return type === 'Percent' ? formatPercentage(Number(value)) : formatCurrencyInCents(Number(value));
    }
    const columns: GridColDef[] = [
        {
            field: 'walletCode',
            headerName: 'Código do Convênio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'interestTypeValueDisplay',
            headerName: 'Tipo de juros',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'interestAmount',
            headerName: 'Juros',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                return formatShowValueByType(row?.interestTypeValue, row?.interestAmount)
            }
        },
        {
            field: 'fineTypeValueDisplay',
            headerName: 'Tipo de multa',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'fineAmount',
            headerName: 'Multa',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: ({ row }) => {
                return formatShowValueByType(row?.fineTypeValue, row?.fineAmount)
            }
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <IconButton name="btn-edit" onClick={() => setActionRowData({ action: "edit", row })}>
                            <EditIcon sx={iconSx} />
                        </IconButton>
                        <IconButton name="btn-delete" onClick={() => setActionRowData({ action: "delete", row })}>
                            <DeleteIcon sx={iconSx} />
                        </IconButton>
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <WalletFiltersContainer />
            }}
            columns={columns}
            rowsPerPage={rowsPerPage}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default BillingSettingList;
