import { FormProvider } from 'contexts/formContext'
import { PixQRCode } from './PixQRCode'
import { Stack } from '@mui/material'
import { useState } from 'react'
import { defaultValuesPixQRcode, validationPixQRcodeSchema } from './PixQRCodeSchema'
import { FieldValues } from 'react-hook-form'
import { useParams } from 'react-router'
import { usePostBankAccountPixQRCodeImmediateDynamic, usePostBankAccountPixQRCodeStaticMutation } from 'contexts/bankAccount/bankAccountContext'
import Toast from 'components/Toast/Toast'
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { ShowPixQRCode } from './ShowPixQRCode/ShowPixQRCode'
import { isEmpty } from 'lodash'
import { PixQRcodeImmediateDynamic, PixQRcodeStatic } from 'services/accounts/bankAccount'
import { onlyNumbers } from 'helpers/validations/formFieldValidator'
import { AutocompleteOptionList } from 'components/Forms/Autocomplete'

type PixQRCodeContainerProps = {
  onClose: () => void; 
  addPixKey: () => void;
  keyPixListAutocomplete: AutocompleteOptionList; 
  refetchListKeyPix: () => void
  showActionToAddPixKey: boolean
}

export const PixQRCodeContainer = ({ onClose, addPixKey, keyPixListAutocomplete, refetchListKeyPix, showActionToAddPixKey }: PixQRCodeContainerProps) => {
  const { bankAccountId } = useParams();
  const [QRCodeType, setQRCodeType] = useState<string | undefined>('QRCodeStatic');
  const [formValues, setFormValues] = useState<FieldValues | null>(null);
  const [codeGenerated, setCodeGenerated] = useState<string | undefined>(undefined);
  const [toast, setToast] = useState<ToastType>(toastState);

  const onCloseFull = () => {
    setCodeGenerated(undefined);
    setFormValues(null);
    onClose();
  }

  const onSuccess = (data: FieldValues) => {
    const messages: { [type: string]: string } = {
      "QRCodeStatic": "QR Code estático gerado com sucesso",
      "QRCodeImmediateDynamic": "QR Code dinâmico gerado com sucesso"
    }
    setCodeGenerated(data?.qrCode as string)
    const title = messages[QRCodeType!];
    const description = "Agora você pode escanear ou copiar o QRCode";
    showSuccessToast(title, description, setToast);
    refetchListKeyPix();
  };

  const onError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error);
    showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
  };

  const { mutateAsync: mutatePixQRCodeImmediateDynamicAsync } = usePostBankAccountPixQRCodeImmediateDynamic(bankAccountId!, onSuccess, onError);
  const { mutateAsync: mutatePixQRCodeStaticAsync } = usePostBankAccountPixQRCodeStaticMutation(bankAccountId!, onSuccess, onError);

  const handlePixQRcode = (values: FieldValues) => {
    setFormValues(values);
    type Action = { [type: string]: () => void }

    const handleActions: Action = {
      "QRCodeStatic": async () => {
        const payload = {
          description: values?.description,
          pixKey: values?.pixKey,
          type: values?.type,
          valueInCents: values?.valueInCents
        } as PixQRcodeStatic;
        await mutatePixQRCodeStaticAsync(payload);
      },
      "QRCodeImmediateDynamic": async () => {
        const payload = {
          payerName: values?.payerName,
          payerRegistrationNumber: onlyNumbers(values?.payerRegistrationNumber),
          pixKey: values?.pixKey,
          type: values?.type,
          valueInCents: values?.valueInCents
        } as PixQRcodeImmediateDynamic;
        await mutatePixQRCodeImmediateDynamicAsync(payload);
      }
    }

    return handleActions[QRCodeType!]();
  }

  return (
    <Stack>
      <Toast toast={toast} setToast={setToast} />

      {isEmpty(codeGenerated) ? (
        <FormProvider
          defaultValues={defaultValuesPixQRcode(QRCodeType!)}
          validationSchema={validationPixQRcodeSchema(QRCodeType!)}
          onSubmit={handlePixQRcode}
        >
          <PixQRCode {...{
            QRCodeType,
            setQRCodeType,
            onClose: onCloseFull,
            keyPixListAutocomplete, 
            addPixKey, 
            showActionToAddPixKey
          }} />
        </FormProvider>) : (
        <ShowPixQRCode
          onClose={onCloseFull}
          code={codeGenerated!}
          formValues={formValues!}
          setToast={setToast}
          typeQrCode={QRCodeType!}
        />
      )}
    </Stack>
  )
}
