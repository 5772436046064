import Timeline from "components/Timeline";
import { useBankAccountRequestData } from "contexts/bankAccount/bankAccountRequest";
import { useParams } from "react-router";

export function TimelineBankAccountTabContainer() {
    const { bankAccountId } = useParams();
    const { bankAccountRequestData } = useBankAccountRequestData(bankAccountId!);

    const timeline = bankAccountRequestData?.timeline ?? [];
    const status = bankAccountRequestData?.statusDisplay ?? '';

    return <Timeline timeline={timeline} title="conta" key={1} status={status} />
}
