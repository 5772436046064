/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material';
import { AvailableCashIcon, CardValue, Drawer, Typography } from '@uy3/web-components';
import { useBankAccontBeneficiaries } from 'contexts/bankAccount/bankAccountBeneficiaryContext';
import { FormProvider } from 'contexts/formContext';
import { useParams } from 'react-router';
import { CreatePixKey } from './CreatePixKey';
import { defaultValuesCreatePixKey, validationCreatePixKeySchema } from './CreatePixKeySchema';
import { activeTheme } from 'services/theme';
import { FieldValues } from 'react-hook-form';
import { useMemo, useState } from 'react';
import { CreateBeneficiaryType, IMFAState, IPixKeyData } from 'contexts/bankAccount/bankAccountType';
import { createBeneficiaryFormDefaultValues, createBeneficiaryFormValidationSchema } from './CreateBeneficiaryForm/CreateKeyPixFormSchema';
import CreateBeneficiaryForm from './CreateBeneficiaryForm/CreateBeneficiaryForm';
import { defaultValuesConfirmMfaForm, validationConfirmMfaFormSchema } from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { ApiResponseError, ToastType, useApiRequest } from 'contexts/apiRequestContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { AxiosResponse } from 'axios';
import { createTransfer, getInformationByPixKey } from 'services/accounts/bankAccount/bankAccount';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { InformationPixKeyContainer } from './InformationPixKey/InformationPixKeyContainer';
import { concatNumberAgency } from 'helpers/formats/StringFormats';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';
import ErrorBoundary from 'components/Errors/ErrorBoundary';

const theme = activeTheme();

type CreatePixKeyContainerProps = {
  setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>
  onClose: () => void;
  dataBalance: any;
}

export const CreatePixKeyContainer = ({ setIsRefreshing, setToast, dataBalance, onClose }: CreatePixKeyContainerProps) => {
  const { bankAccountId } = useParams();
  const { token } = useIdentity();
  const [pixKeyData, setPixKeyData] = useState<IPixKeyData | null>(null)
  const [isCreatingBeneficiary, setIsCreatingBeneficiary] = useState<boolean>(false);
  const [stepAction, setStepAction] = useState<string | undefined>(undefined);
  const [beneficiaryId, setBeneficiaryId] = useState<string | undefined>(undefined);
  const [formValues, setFormValues] = useState<any | null>(null);
  const { startRequest, endRequest } = useApiRequest();

  const { bankAccountAutocomplete, data: beneficiariesData } = useBankAccontBeneficiaries({id: bankAccountId!, operationTypeValue: 'Pix', onlyApproveds: true});
  const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

  useMemo(() => {
    if (beneficiaryId !== undefined && beneficiariesData.data !== undefined) {
      const find = beneficiariesData.data?.find((item: { id: string }) => item.id === beneficiaryId);
      setFormValues(find as CreateBeneficiaryType);
      setIsCreatingBeneficiary(false);
    } else {
      if (isCreatingBeneficiary) return;
      setFormValues(null);
    }
  }, [beneficiaryId]);

  const onCreateBeneficiary = async (values: FieldValues) => {
    if (values?.pixKeyTypeValue === 'AgencyAndAccount') {
      setIsCreatingBeneficiary(true);
      setFormValues({
        bankAccountBeneficiaryCreate: values,
        saveBeneficiary: values?.saveBeneficiary,
      });
      return setStepAction(undefined);
    } else {
      let keyPix: string = values?.keyPix;
      const keyTypeDisplay = values?.pixKeyTypeValueDisplay;

      if (keyTypeDisplay === "Telefone" || keyTypeDisplay === "CPF" || keyTypeDisplay === "CNPJ") {
        keyPix = keyPix.replace(/\D/g, "");
      }

      if (keyTypeDisplay === "Telefone") {
        keyPix = `+55${keyPix}`;
      }

      startRequest();
      await getInformationByPixKey(bankAccountId!, keyPix, token!)
        .then(({ data }) => {
          let pixKeyData = data as IPixKeyData;
          delete pixKeyData.accountType;
          setPixKeyData(pixKeyData);
          setStepAction('information_keypix');
          endRequest(true);
        }).catch((error: ApiResponseError) => {
          endRequest(false);
          const { errorMessage } = mapErrorResponse(error);
          setToast({
            title: 'Ops, ocorreu um erro!',
            description: errorMessage,
            open: true,
            severity: 'error'
          });
        })
    }
  };

  const onCreatePix = (values: FieldValues) => {
    setStepAction('mfacreatePix');
    endRequest(false);
    setFormValues({
      ...formValues,
      amount: values?.amount,
      bankAccountBeneficiaryId: values?.bankAccountBeneficiaryId,
      operationTypeValue: values?.operationTypeValue,
      transferDate: values?.transferDate, 
      transferDateSelected: values?.transferDateSelected
    });
  };

  const onConfirmationKeyPix = (values: FieldValues) => {
    setFormValues({
      bankAccountBeneficiaryCreate: {
        ...pixKeyData,
        operationTypeValue: formValues?.operationTypeValue ?? "Pix",
        pixKeyTypeValue: pixKeyData?.keyType,
        keyPix: pixKeyData?.keyValue
      },
      saveBeneficiary: values?.saveBeneficiary,
      dataByKeyPixRequest: true
    });
    setStepAction(undefined);
    setIsCreatingBeneficiary(true);
  }

  const selectExistingPerson = () => {
    setFormValues(null);
    setIsCreatingBeneficiary(false);
  };

  const validBank = (bank: string) => {
    return bank !== undefined &&
      bank !== null &&
      (bank !== "0" && parseInt(bank) !== 0);
  }

  const handlePixSubmit = async (values: IMFAState) => {
    startRequest();
    mutateGenerateSessionId({
      userPassword: values.password,
      then: async function ({ data: sessionId }: AxiosResponse<string>) {
        if (formValues) {
          let payload: any = {};
          let payloadBeneficiaryCreate: any = {};
          const beneficiaryCreated = formValues?.bankAccountBeneficiaryCreate;
          const transferDateType = formValues?.transferDateSelected; 
          const transferDate = transferDateType === 'today' ? new Date().toISOString() : formValues?.transferDate;

          let payloadBankAccountBeneficiaryCreate = {
            name: beneficiaryCreated?.name,
            registrationNumber: beneficiaryCreated?.registrationNumber,
            agency: beneficiaryCreated?.agency ?? concatNumberAgency(beneficiaryCreated?.accountAgency),
            accountNumber: beneficiaryCreated?.accountNumber,
            accountType: beneficiaryCreated?.accountType,
            operationTypeValue: beneficiaryCreated?.operationTypeValue ?? "Pix",
            pixKeyTypeValue: beneficiaryCreated?.pixKeyTypeValue,
            keyPix: beneficiaryCreated?.keyValue
          }

          if (formValues.bankAccountBeneficiaryId === 'create') {
            if (validBank(beneficiaryCreated?.bank)) {
              payloadBeneficiaryCreate = {
                ...payloadBankAccountBeneficiaryCreate,
                bank: beneficiaryCreated?.bank
              }
            } else {
              payloadBeneficiaryCreate = {
                ...payloadBankAccountBeneficiaryCreate,
                bankIspb: beneficiaryCreated?.ispb
              }
            }
            payload = {
              bankAccountBeneficiaryCreate: payloadBeneficiaryCreate,
              amount: formValues?.amount,
              transferDate,
              saveBeneficiary: formValues?.saveBeneficiary,
              endToEndId: beneficiaryCreated?.endToEndId,
              code: values.code,
              sessionId
            }
          } else {
            payload = {
              bankAccountBeneficiaryId: formValues.bankAccountBeneficiaryId,
              amount: formValues?.amount,
              transferDate,
              saveBeneficiary: formValues?.saveBeneficiary,
              code: values.code,
              sessionId
            }
          }

          await createTransfer(bankAccountId!, payload, token!)
            .then(() => {
              setStepAction(undefined);
              endRequest(true);
              setIsRefreshing(true);
              onClose();
              setToast({
                open: true,
                title: 'Transferência Pix realizado com sucesso!',
                severity: 'success'
              })
            }).catch((response: ApiResponseError) => {
              const { description, open, title } = errorHandlingBankAccount(response);
              endRequest(false);
              setToast({ title, description, open, severity: 'error' });
            });
        }
      }
    })

  };

  return (
    <Stack spacing={2}>
      <ErrorBoundary fallback='Pix'>
        <CardValue
          sx={{ width: '100%' }}
          color="primary"
          title="Saldo disponível"
          description="Baseado em transações e aprovações."
          icon={<AvailableCashIcon htmlColor={theme.palette.primary.main} />}
          value={formatCurrencyInCents(dataBalance?.available).toString()}
        />
        <Typography variant='h5' fontWeight={700}>Selecione um favorecido</Typography>
        <FormProvider
          validationSchema={validationCreatePixKeySchema()}
          defaultValues={defaultValuesCreatePixKey}
          onSubmit={onCreatePix}
        >
          <CreatePixKey
            bankAccountAutocomplete={bankAccountAutocomplete}
            formValues={formValues}
            onEdit={() => setStepAction('editBeneficiary')}
            isCreatingBeneficiary={isCreatingBeneficiary}
            setBeneficiaryId={setBeneficiaryId}
            onClose={onClose}
            selectExistingPerson={selectExistingPerson}
            onCreateBeneficiary={() => setStepAction('createBeneficiary')}
          />
        </FormProvider>
        <Drawer
          anchor='right'
          title={stepAction === 'editBeneficiary' ? 'Editar favorecido' : 'Adicionar favorecido'}
          toggleDrawer
          onClose={() => setStepAction(undefined)}
          open={stepAction === 'createBeneficiary' || stepAction === 'editBeneficiary'}
          children={
            <FormProvider
              validationSchema={createBeneficiaryFormValidationSchema()}
              defaultValues={stepAction === 'editBeneficiary' ? formValues?.bankAccountBeneficiaryCreate : createBeneficiaryFormDefaultValues}
              onSubmit={onCreateBeneficiary}
            >
              <CreateBeneficiaryForm onClose={() => setStepAction(undefined)} />
            </FormProvider>}
        />
        <Drawer
          anchor='right'
          title="Confirmar chave PIX"
          description='Confira se suas informações estão corretas'
          toggleDrawer
          onClose={() => setStepAction('createBeneficiary')}
          open={stepAction === 'information_keypix'}
          children={<InformationPixKeyContainer
            pixKeyData={pixKeyData!}
            onClose={() => setStepAction('createBeneficiary')}
            onConfirmationKeyPix={onConfirmationKeyPix}
          />}
        />
        <Drawer
          anchor='right'
          title='Confirmar MFA'
          open={stepAction === 'mfacreatePix'}
          onClose={() => setStepAction(undefined)}
          toggleDrawer
        >
          <FormProvider
            validationSchema={validationConfirmMfaFormSchema()}
            defaultValues={defaultValuesConfirmMfaForm}
            onSubmit={handlePixSubmit}
          >
            <ConfirmMfaForm
              onClose={() => setStepAction(undefined)}
              toggleDrawer={true}
            />
          </FormProvider>
        </Drawer>
      </ErrorBoundary>
    </Stack>
  )
}