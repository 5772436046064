import { useState } from 'react';
import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import DatasetWrapper from '../DatasetWrapper';
import { AssociatedPeopleGet } from 'services/datasets/datasets.interface';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IAssociatedPeopleList {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function AssociatedPeopleList({ datasetId, disableRequestDataSet }: IAssociatedPeopleList) {
    const recordType = 'LegalPerson';
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'AssociatedPeople');
    const { setSubmitError } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Pessoas relacionadas ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'relatedEntityTaxIdNumber',
            headerName: 'Documento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'relatedEntityName',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'relationshipType',
            headerName: 'Tipo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'relationshipName',
            headerName: 'Relação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'relationshipLevel',
            headerName: 'Nível',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'relationshipStartDate',
            headerName: 'Início',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
        {
            field: 'relationshipEndDate',
            headerName: 'Fim',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
    ];

    const data = dataset?.find(
        (dataset) => dataset?.dataSet === 'associated_people'
    ) as AssociatedPeopleGet;
    const result = data?.result?.items;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />

            <DataTable
                loading={isLoading || status === 'loading'}
                getRowId={() => Math.random().toString()}
                columns={columns}
                rows={result ?? []}
                page={page}
                rowCount={result?.length ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
            <DatasetWrapper
                dataSet={data}
                disableRequestDataSet={disableRequestDataSet}
                handleUpdateData={() => handleUpdateData('AssociatedPeople')}
            />
        </>
    );
}
