import moment from 'moment';
import { string, object, array } from 'yup';

export function validationCreditNoteRejectComplianceSchema() {
    return object().shape({
        rejectionReasonCode: array().typeError('Selecione um motivo').notRequired(),
        message: string()
            .typeError('Motivo é obrigatório.')
            .required('Motivo: precisa ser preenchido'),
        insertBlock: string()
            .typeError('Realizar bloqueio é obrigatório.')
            .required('Realizar bloqueio: precisa ser preenchido'),
        blockUntil: string()
            .typeError('Data de bloqueio preisa ser preenchida.')
            .required('Data de Bloqueio: precisa ser preenchida'),
    });
}
export const defaultValuesCreditNoteRejectCompliance = {
    rejectionReasonCode: [],
    message: null,
    insertBlock: 'not',
    blockUntil: moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00',
};
