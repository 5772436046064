import { Grid, IconButton, Stack } from '@mui/material';
import {
  DataTable,
  EditIcon,
  DeleteIcon,
  SearchIcon,
} from '@uy3/web-components';
import { RefreshProgress } from 'components/RefreshProgress';
import { GridColDef } from '@mui/x-data-grid';
import { EnumItem } from 'contexts/enumContext';
import { iconSx } from 'contexts/apiRequestContext';
import { Link } from 'react-router-dom';
import { handleRedirectByLine } from 'helpers';

type InvolvedProps = {
  title?: string;
  queryData?: any;
  enableAction: boolean
  rowsPerPage: number;
  setRowsPerPage: (page: number) => void;
  setPage: (page: number) => void;
  setSelectedInvolvedIndex: (rowIndex: number | undefined) => void;
  setOpenDrawer: (open: boolean) => void;
  page: number;
  onDelete: (rowIndex: number) => void;
  typeOfRelationship: EnumItem[];
};

const InvolvedBankAccountRequestList = ({
  queryData,
  title = 'Envolvidos',
  rowsPerPage,
  enableAction,
  setRowsPerPage,
  setPage,
  page,
  onDelete,
  typeOfRelationship,
  setSelectedInvolvedIndex,
}: InvolvedProps) => {

  const columns: GridColDef[] = [
    {
      field: 'personIdDisplay',
      headerName: 'Pessoa',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: ({ row }) => row.personIdDisplay
    },
    {
      field: 'typeOfRelationship',
      headerName: 'Tipo de relação',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value &&
              typeOfRelationship?.find((file) => file.value === cellValues.value)?.label}
          </div>
        );
      },
    },
    {
      field: 'relatedToIdDisplay',
      headerName: 'Relacionada a',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
    },
    {
      field: 'isSigner',
      headerName: 'Signatário',
      hideSortIcons: true,
      minWidth: 90,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        let isSigner = cellValues.row.isSigner;
        return isSigner ? "Sim" : "Não"
      }
    },
    {
      field: 'participationPercentage',
      headerName: 'Participação na Empresa',
      hideSortIcons: true,
      minWidth: 80,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => (cellValues.value ? `${cellValues.value}%` : 'N/A'),
    },
    {
      field: 'levelDisplay',
      headerName: 'Nível de permissão',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => cellValues.value ?? "-",
    },
    {
      field: 'actions',
      headerName: 'Ações',
      headerAlign: 'center',
      align: 'center', 
      hideSortIcons: true,
      minWidth: 150,
      editable: false,
      renderCell: (cellValues) => {
        const rowIndex = queryData.findIndex((row: any) => cellValues.id === row?.id);
        return (
          <Stack
            component={Grid}
            direction={'row'}
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Link to={handleRedirectByLine(cellValues?.row)}>
              <IconButton>
                <SearchIcon sx={iconSx} />
              </IconButton>
            </Link>
            {enableAction &&
              <>
                <IconButton onClick={() => setSelectedInvolvedIndex(rowIndex)}>
                  <EditIcon sx={iconSx} />
                </IconButton>
                <IconButton onClick={() => onDelete(rowIndex)}>
                  <DeleteIcon sx={iconSx} />
                </IconButton>
              </>}
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <RefreshProgress refreshing={false} />

      <DataTable
        headerBarWrapper={{
          header: title
        }}
        columns={columns}
        rows={queryData}
        page={page}
        rowCount={queryData?.totalItems}
        rowsPerPage={rowsPerPage}
        setPage={setPage}
        setRowsPerPage={setRowsPerPage}
      />
    </>
  );
};

export default InvolvedBankAccountRequestList;
