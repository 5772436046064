import { CardData } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { isEmpty } from 'lodash';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import { isValidString } from 'helpers/formats/StringFormats';

interface InterestModelCardDataProps { }

const InterestModelCardData: React.FC<InterestModelCardDataProps> = () => {
    const { watch} = useFormContext();
    const interestModelSelected = watch('interestModelSelected') || [];

    const interestTypeValueDisplay = interestModelSelected.interestTypeValueDisplay;
    const fineTypeValueDisplay = interestModelSelected.fineTypeValueDisplay;

    const formatInterest = (interestValue: number, type: "multa" | "juros") => {
        const options: {[type:string] : string} = {
            "juros": interestTypeValueDisplay === 'Porcentagem' ? formatPercentage(interestValue) as string : formatCurrencyInCents(interestValue), 
            "multa": fineTypeValueDisplay === 'Porcentagem' ? formatPercentage(interestValue) as string : formatCurrencyInCents(interestValue), 
        }
        return options[type]; 
    };

    const daysToAutoBankSlipCancel = interestModelSelected ? interestModelSelected.daysToAutoBankSlipCancel : 'N/A';
    const daysToAutoBankSlipRegister = interestModelSelected ? interestModelSelected.daysToAutoBankSlipRegister : 'N/A';

    const dataList = [
        {
            id: '1',
            title: 'Tipo de juros',
            value: interestTypeValueDisplay === 'Isento' ? "Valor fixo" : "Percentual",
        },
        {
            id: '2',
            title: 'Juros por Mês',
            value: formatInterest(interestModelSelected.interestAmount, "juros") ?? 'N/A',
        },
        {
            id: '3',
            title: 'Tipo de multa',
            value: fineTypeValueDisplay === 'Isento' ? "Valor fixo" : "Percentual",
        },
        {
            id: '4',
            title: 'Multa por atraso',
            value: formatInterest(interestModelSelected.fineAmount, "multa") ?? 'N/A',
        }
    ]

    if (isValidString(String(daysToAutoBankSlipCancel))) {
        dataList.push({
            id: String(dataList.length+1),
            title: 'Quantidade de dias para baixa automática',
            value: `${daysToAutoBankSlipCancel} dias`,
        })
    }
    if (daysToAutoBankSlipRegister !== null && isValidString(String(daysToAutoBankSlipRegister))) {
        dataList.push({
            id: String(dataList.length+1),
            title: 'Quantidade de dias para registro automático do boleto',
            value: `${daysToAutoBankSlipRegister} dias`,
        })
    }
    if (!isEmpty(interestModelSelected.bankSlipMessage)) {
        dataList.push( {
            id: String(dataList.length+1),
            title: 'Mensagem',
            value: interestModelSelected.bankSlipMessage  ?? "N/D",
        });
    }

    return (
        <>
            <CardData
                listItem={dataList}
            />
        </>
    );
};

export default InterestModelCardData;
