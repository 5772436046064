import { mapDataToModelInstance, MappingConfig } from 'helpers/validations/validateAndPreparePayload';

export class UploadUpdateModel {
    id?: string;
    fileType!: string;
    fileName!: string;
    displayName!: string;

    constructor(init?: Partial<UploadUpdateModel>) {
        Object.assign(this, init);
    }
};

const modelMapping: MappingConfig<UploadUpdateModel> = {
    UploadUpdateModel
};

export function mapUploadModelData<T>(dataModel: T) {
    return mapDataToModelInstance(dataModel, 'UploadUpdateModel', modelMapping) as T;
};