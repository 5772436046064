import { onlyNumbers } from "helpers/validations/formFieldValidator";

type FormatMultiplyType = "percentage" | "currency"

export var getNumberWithPrecision = (number: number, precision: number = 10) => {
	var factor = Math.pow(10, precision);
	return Math.round(number * factor) / factor;
};

export function formatDivide(num: number | undefined): number | undefined {
	if (typeof num !== 'number' || isNaN(num) || !isFinite(num)) {
		return undefined;
	}

	return getNumberWithPrecision(num / 100);
}

export function formatMultiply(num: number | undefined, type: FormatMultiplyType = 'percentage'): number {
	if (num === undefined || isNaN(num) || !num) return 0;
	let multipliedValue = num * 100;
	const value = type === 'currency' ? Math.round(multipliedValue) : multipliedValue;
	return value;
};


type CurrencyValueType = number | undefined | string
export const isNegativeValue = (value: CurrencyValueType) => String(value)?.startsWith('-');

export function formatCurrencyInCents(value: CurrencyValueType) {
	if (typeof value === 'string' && value?.includes('R$')) return value;

	const isNegative = isNegativeValue(value);
	if (isNegative) return "Saldo insuficiente";

	const currency = Number(onlyNumbers(String(value)));
	if (isNaN(currency) || value === undefined) return "R$ 0,00";
	const dividedValue = formatDivide(currency);

	return maskCurrencyInCents(dividedValue);
};

export function maskCurrencyInCents(num: number | undefined) {
	if (num === undefined || isNaN(num) || !num) return "R$ 0,00";
	return num!?.toLocaleString("pt-BR", {
		style: "currency",
		currency: "BRL",
		minimumFractionDigits: 2,
		maximumFractionDigits: 2,
	});
};

export function formatPercentage(value: number) {
	if (!value) return 0;

	const percentage = `${(value * 100).toFixed(4)}%`;
	return percentage.toString().replace(".", ",");
};

export function formatPercentageWithPlaces(value: number) {
	if (!value) return 0;
	const percentage = (Math.floor(value * 100 * 10000) / 10000)?.toFixed(4);
	return `${percentage?.toString()?.replace(".", ",")}%`;
};
