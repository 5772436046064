/* eslint-disable no-useless-escape */
import { string, object, ref } from 'yup';

export function RecoverPasswordFormValidationSchema(codeSent: boolean) {
	return codeSent ? object().shape({
		email: string().typeError('Email inválido.')
			.required('Email: Precisa ser preenchido.').email('Email inválido.'),
		code: string().typeError('Código inválido.')
			.required('Código: Precisa ser preenchido.'),
		newPassword: string().typeError('Senha inválida.')
			.required('Senha: Precisa ser preenchido.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
				"A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número")
			.min(8, 'A senha é muito curta - deve ter no mínimo 8 caracteres.'),
		passwordConfirmation: string().typeError('Confirmar senha: Precisa ser preenchido.').required('Confirmar senha: Precisa ser preenchido.').oneOf([ref("newPassword"), null], "A senha e a confirmação de senha não conferem"),
	}) : object().shape({
		email: string().typeError('Email inválido.')
			.required('Email: Precisa ser preenchido.').email('Email inválido.'),
	});
}

export const RecoverPasswordFormDefaultValues = (codeSent: boolean) => {
	return codeSent ? {
		email: "",
		code: "",
		newPassword: "",
		passwordConfirmation: ""
	} : {
		email: ""
	}
};