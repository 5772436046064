import { Grid, Stack } from '@mui/material';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { formatCurrencyInCents, formatPercentage } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';

type ValueType = 'currency' | 'percentage' | 'text';

type HorizontalInfoProps = {
    value: string;
    type: ValueType;
    title?: string;
    hasInstallmentValue?: boolean
    numberInstallment?: number
};

const theme = activeTheme();

export default function HorizontalInfo({
    value,
    title = 'Valor total da cobrança',
    type,
    hasInstallmentValue = false,
    numberInstallment = 0
}: HorizontalInfoProps) {
    const parsedValue = parseInt(value);
    const parseValueInstallment = hasInstallmentValue ? parseInt((Number(value) / numberInstallment)?.toString()) : 0;
    
    const showValue = () => {

        const options: { [type: string]: string } = {
            text: value,
            percentage: formatPercentage(parsedValue) as string,
            currency: formatCurrencyInCents(parsedValue) as string,
        };

        return options[type];
    };


    return (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Grid>
                <CustomTitle variant="lg" title={title} />
            </Grid>
            <Stack direction='column' justifyContent='end'>
                <CustomTitle
                    variant="xl"
                    sx={{ textAlign: "right" }}
                    color={theme.palette.primary.main}
                    title={showValue()}
                />

                {hasInstallmentValue &&
                    <CustomTitle
                        variant="sm"
                        sx={{ fontSize: "13px", textAlign: "right" }}
                        color={theme.palette.primary.main}
                        title={`em ${numberInstallment}x de ${formatCurrencyInCents(parseValueInstallment)}`}
                    />
                }
            </Stack>
        </Stack>
    );
}
