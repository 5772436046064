import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';
import { ApiResponseError, invalidateAndRefetch, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { FormProvider } from 'contexts/formContext';
import { FieldValues } from 'react-hook-form';
import {
    defaultValuesApproveInstrument,
    validationSchemaApproveInstrument,
} from './ApproveInstrumentSchema';
import { approveInstrumentById } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { useNavigate } from 'react-router';

type ApproveInstrumentProps = {
    setPopupProps: (props: any) => void;
    onErrorCatch: (response: ApiResponseError) => void;
    bankaccountId: string;
};

export const ApproveInstrument = ({
    bankaccountId,
    setPopupProps,
    onErrorCatch,
}: ApproveInstrumentProps) => {
    const { startRequest, endRequest } = useApiRequest();
    const { token } = useIdentity();
    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const onSubmit = async (values: FieldValues) => {
        const { message } = values;
        const payload = { message };
        startRequest();
        await approveInstrumentById(bankaccountId, payload, token!)
            .then(async () => {
                await invalidateAndRefetch(queryClient, ['bankAccountRequest-list']);
                endRequest(true);
                setPopupProps(undefined);
                navigate('/contas-digitais/solicitacoes');
            })
            .catch((error: ApiResponseError) => onErrorCatch(error));
    };

    const schema = validationSchemaApproveInstrument();
    const defaultValues = defaultValuesApproveInstrument;

    return (
        <FormProvider validationSchema={schema} defaultValues={defaultValues} onSubmit={onSubmit}>
            <>
                <Grid container direction="row" spacing={3} mt={2}>
                    <Grid item xs={12}>
                        <TextFormField
                            name="message"
                            label="Mensagem"
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        name="btn-cancel"
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopupProps(undefined)}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        name="btn-confirm"
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};
