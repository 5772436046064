import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react'

interface IHeaderSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const HeaderSkeleton: React.FC<IHeaderSkeleton> = (props) => {
    const { children, isLoading } = props;
    if (!isLoading) return children;
    return (
        <React.Fragment>
            <Stack position='absolute' top='14px' right='30px'>
                <Stack sx={{ display: "grid", gridTemplateColumns: "auto auto auto", alignItems: 'center', gap: 3, mb: 2 }}>
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='200px'
                        height='25px'
                    />
                    <Skeleton
                        variant='circular'
                        animation='pulse'
                        width={40}
                        height={40}
                    />
                    <Skeleton
                        variant='circular'
                        animation='pulse'
                        width={40}
                        height={40}
                    />
                </Stack>

            </Stack>
        </React.Fragment>
    )
}
