import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';

function FileConfirmationFiltersForm() {
    const { watch } = useFormContext();

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Data inicial', value: 'initialDate' },
                ]}
            />
            {watch('optionFilter') === 'walletsCode' && (
                <TextFormField
                    name="walletsCode"
                    variant="outlined"
                    label="Filtrar por código da carteira"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}

            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}

        </>
    );
}

type FileConfirmationFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const FileConfirmationFilters = ({ currentValues, removeFilterSelected }: FileConfirmationFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<FileConfirmationFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
