import { boolean, object } from "yup";

export function validationSchemaDraftPreview() {
    return object().shape({
        useWatermark: boolean().nullable().notRequired(),
        createPdf: boolean().nullable().notRequired(),
    });
};

export const defaultValuesDraftPreview = {
    useWatermark: false, 
    createPdf: false
};