import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Box } from "@mui/system";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useIdentity } from "contexts/identityContext";
import { useQueryClient } from "@tanstack/react-query";

export const MainBox = styled(Grid2)();

export const ButtonBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'right',
	alignItems: 'right',
}))

export function Logout() {

	const queryClient = useQueryClient();

	const { logout } = useIdentity();
	const navigate = useNavigate();

	useEffect(() => {
		queryClient.invalidateQueries(['user-permission-list']);
		logout();
		navigate('/');
	}, [logout, navigate, queryClient]);

	return (
		<MainBox m={8}>
			<Typography variant="h2">Saindo!</Typography>
			<Typography variant="subtitle1">Limpando suas credenciais e redirecionando para o login. Aguarde</Typography>
		</MainBox>
	);
}