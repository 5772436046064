import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { WarrantyType } from './generics';

export class JudiciaryProcessCreateModel {
    warrantyType: WarrantyType = 'JudiciaryProcess';
    number?: string; // Número do Processo
    court?: string; // Vara do Processo
    valueOfCause?: number; // Valor da Causa do Processo
    claimant?: string; // Reclamante do Processo
    claimed?: string; // Reclamado do Processo
    typeOfProcess?: string; // Tipo do Processo

    constructor(init?: Partial<JudiciaryProcessCreateModel>) {
        Object.assign(this, init);
    }

    description?() {
        //@ts-ignore
        return `${this.typeOfProcess} nº ${this.number} - ${this.court} - ${this.claimant} vs ${
            this.claimed
        } - Valor da causa: ${formatCurrencyInCents((this.valueOfCause || 0) * 100)}`;
    }

    title?() {
        return `Processo Judicial`;
    }
}
