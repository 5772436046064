import { string, object } from 'yup';

export function productFiltersSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        name: string().when("optionFilter", {
            is: "name",
            then: string().required('Nome precisa ser preenchida.').typeError('Nome: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string().required('Data de início da ocorrência precisa ser preenchida.').typeError('Data de início da ocorrência: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().required('Data final da ocorrência precisa ser preenchida.').typeError('Data final da ocorrência: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        tenant: string().when("optionFilter", {
            is: "tenant",
            then: string().typeError('Correspondente precisa ser preenchido.').required('Correspondente: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        amortizationType: string().when("optionFilter", {
            is: "amortizationType",
            then: string().typeError('Tipo de amortização precisa ser preenchido.').required('Tipo de amortização: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }), 
        categoryName: string().when("optionFilter", {
            is: "categoryName",
            then: string().typeError('Nome simplificado precisa ser preenchido.').required('Nome simplificado: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }), 
        termStatus: string().when("optionFilter", {
            is: "termStatus",
            then: string().typeError('Status de vigência precisa ser preenchido.').required('Status de vigência: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }), 
    });
}

export const defaultValuesProductFilterschema = {
    optionFilter: 'searchString',
    name: null,
    amortizationType: null,
    categoryName: null,
    tenant: null,
    termStatus: null,
    initialDate: new Date(),
    finalDate: new Date()
};