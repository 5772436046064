import { Grid, Box, Stack } from '@mui/material';
import {
    Typography,
    SaveIcon,
    Alert,
    OperatorIcon,
    UpdateIcon,
    TabsProps,
    Toast,
    DeleteIcon,
    ShareIcon,
    ShieldWarningIcon,
    UserRectangleIcon,
    AddCircleIcon,
} from '@uy3/web-components';
import { FormMode, useFormContext } from 'contexts/formContext';
import OperatorViewInfo from 'components/OperatorViewInfo/OperatorViewInfo';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType, iconSx } from 'contexts/apiRequestContext';
import { useEffect, useState } from 'react';
import { useAppConfig } from 'contexts/appConfig';
import { ComplianceChecklistMessage } from 'components/ComplianceChecklistMessage/ComplianceChecklistMessage';
import { useUserPermissionData } from 'contexts/userContext';
import { checkActionsPerms } from 'services/permissions';
import { Actions } from 'components/Actions/Actions';
import { useTenant } from 'contexts/tenantContext';
import { AttributeTypeChecklist } from 'services/creditNote/complianceChecklist';

type LegalPersonType = {
    personId: string;
    hasPermissionCreateOrUpdate: boolean
    mode: FormMode;
    setOpenDrawer: React.Dispatch<React.SetStateAction<{
        open: boolean;
        type: string;
    } | undefined>>
    complianceChecklist: AttributeTypeChecklist[]
    refetch: () => void;
    setToast: (toast: ToastType) => void;
};

const LegalPersonForm = ({
    personId,
    mode,
    setOpenDrawer,
    hasPermissionCreateOrUpdate,
    refetch,
    setToast,
    complianceChecklist
}: LegalPersonType) => {
    const { validationErrors, watch, isDirty } = useFormContext();
    const { data: permissionsData } = useUserPermissionData();
    const { isRootTenancy } = useTenant();
    const { appConfig } = useAppConfig();
    const isNew = mode === 'create';
    const [open, setOpen] = useState(false);

    const enableLoadValuesByRegistrationNumber = isNew &&
        appConfig?.AUTO_QUERY_BASIC_DATA !== 1 && watch('loadValuesByRegistrationNumber') !== true;

    const hasPermissionToReadDataset = (): boolean =>
        checkActionsPerms('ReadDataSet', permissionsData, 'LegalPerson');

    const tabs = [
        { label: 'Informações', href: personId },
        { label: 'Dados bancários', href: 'dados-bancarios' },
        { label: 'Documentos', href: 'documentos' },
        ...(!isNew && hasPermissionToReadDataset() ? [{ label: 'Compliance', href: 'compliance' }] : []),
        ...(!isNew ? [{ label: 'Crédito', href: 'credito' }] : []),
        ...(!isNew ? [{ label: 'Operações', href: 'operacoes' }] : []),
        ...(!isNew ? [{ label: 'Signatários', href: 'signatarios' }] : []),
    ] as TabsProps[];

    const showDeletecomplianceChecklist = () => {
        if (complianceChecklist?.length > 0 && !isNew && isRootTenancy) return true;
        return false;
    }

    const refresWithToast = () => {
        refetch();
        setToast({
            title: 'Os dados da pessoa jurídica foram atualizados!',
            severity: 'success',
            open: true,
        });
    }

    useEffect(() => {
        const personCredit = watch('personCreditStatus');
        const personCreditStatus = watch('personCreditStatus.status');

        if (!!personCredit && personCreditStatus !== "Approved") setOpen(true);
    }, [watch]);

    const close = () => setOpen(false);


    return (
        <>
            {validationErrors && (
                <Box mt={2} mb={2}>
                    <Alert
                        severity="error"
                        text={
                            'Não foi possível salvar: Corrija os erros do formulário e envie novamente.'
                        }
                        variant="filled"
                    />
                </Box>
            )}
            <Box>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
                    <Typography
                        variant="h4"
                        color="neutral.dark"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="32px"
                        lineHeight="38.4px"
                    >
                        {isNew ? 'Novo cadastro' : `${watch('companyName')}`}
                    </Typography>
                    <Grid direction="row" alignItems="center">

                        <Actions
                            numberOfButtons={4}
                            buttonsActionsList={[
                                {
                                    enable: hasPermissionCreateOrUpdate,
                                    label: "Salvar",
                                    type: 'submit',
                                    icon: <SaveIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: !isNew,
                                    label: "Atualizar",
                                    action: refresWithToast,
                                    icon: <UpdateIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: hasPermissionCreateOrUpdate && enableLoadValuesByRegistrationNumber,
                                    label: "Carregar informações",
                                    action: () => setOpenDrawer({ open: true, type: 'getPersonByRegistrationNumber' }),
                                    icon: <UserRectangleIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: hasPermissionCreateOrUpdate && !isNew && isRootTenancy,
                                    label: "Clonar registro",
                                    action: () => setOpenDrawer({ open: true, type: 'clone' }),
                                    icon: <ShareIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: !isNew && hasPermissionCreateOrUpdate,
                                    label: "Atribuir",
                                    action: () => setOpenDrawer({ open: true, type: 'assign' }),
                                    icon: <OperatorIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    enable: showDeletecomplianceChecklist(),
                                    label: "Remover restrições",
                                    action: () => setOpenDrawer({ open: true, type: 'deletecomplianceChecklist' }),
                                    icon: <DeleteIcon htmlColor="black" sx={iconSx} />
                                },
                                {
                                    label: 'Registrar fraude',
                                    action: () => setOpenDrawer({ open: true, type: 'fraudRecord' }),
                                    enable: isRootTenancy && hasPermissionCreateOrUpdate && !isNew,
                                    icon: <ShieldWarningIcon sx={iconSx} />,
                                },
                                {
                                    enable: !isNew && isRootTenancy,
                                    label: "Incluir na Blacklist",
                                    action: () => setOpenDrawer({open: true, type: 'addBlackList'}),
                                    icon: <AddCircleIcon htmlColor="black" sx={iconSx} />
                                },
                            ]}
                        />
                    </Grid>
                </Stack>
                <Toast
                    onClose={close}
                    open={open}
                    title="Atenção! O tomador possui restrições de crédito."
                    severity="info"
                />
                <Grid container item xs={4}>
                    <NotSavedChangesAlert isDirty={isDirty} />
                </Grid>
                <OperatorViewInfo />
                {showDeletecomplianceChecklist() &&
                    <Grid spacing={2} mt={2} mr={3} mb={2}>
                        <ComplianceChecklistMessage
                            complianceChecklistData={complianceChecklist}
                            name={watch('name')}
                        />
                    </Grid>}
                <Grid sx={{ mr: 3, mt: 3 }}>
                    <TabBar tabs={tabs} />
                </Grid>
            </Box>
        </>
    );
};

export default LegalPersonForm;