import { ComplianceRulesActionType, ComplianceRulesTypeEnum } from "../enums";

export let complianceRulesTypesList = [
    { label: 'Aprovação automática', value: 0 },
    // { label: 'Bloqueio de CPF', value: 1 },
    { label: 'Blacklist UY3', value: 2 },
    { label: 'Regularidade de CPF', value: 3 },
    { label: 'Validação de nome', value: 4 },
    { label: 'Score de risco', value: 5 },
    { label: 'Motor de compliance', value: 6 },
    { label: 'KYC', value: 7 },
    { label: 'R6', value: 8 },
    { label: 'Aprovações recentes', value: 9 },
];

export const complianceRulesActionType = [
    { label: "Rejeitar", value: 0 },
    { label: "Aprovação manual", value: 1 }
]

const ComplianceRulesTypeDescriptions: { [key in ComplianceRulesTypeEnum]: string } = {
    [ComplianceRulesTypeEnum.AutomaticApproval]: 'Aprovação automática',
    [ComplianceRulesTypeEnum.RegistrationNumberBlock]: 'Bloqueio de CPF',
    [ComplianceRulesTypeEnum.Uy3Blacklist]: 'Blacklist UY3',
    [ComplianceRulesTypeEnum.RegistrationNumberRegularity]: 'Regularidade de CPF',
    [ComplianceRulesTypeEnum.NameValidation]: 'Validação de nome',
    [ComplianceRulesTypeEnum.RiskScore]: 'Score de risco',
    [ComplianceRulesTypeEnum.ComplianceEngine]: 'Motor de compliance',
    [ComplianceRulesTypeEnum.Kyc]: 'KYC',
    [ComplianceRulesTypeEnum.R6]: 'R6',
    [ComplianceRulesTypeEnum.RecentApprovals]: 'Aprovações recentes',
};

export function getComplianceRuleDescription(rule: ComplianceRulesTypeEnum): string {
    return ComplianceRulesTypeDescriptions[rule];
}

export function getComplianceRuleByDescription(description: string): ComplianceRulesTypeEnum | undefined {
    //@ts-ignore
    const entries = Object.entries(ComplianceRulesTypeDescriptions) as [ComplianceRulesTypeEnum, string][];
    const found = entries.find(([, value]) => value === description);
    return found ? found[0] : undefined; // Retorna a chave (valor do enum) correspondente
  }

const ComplianceRulesActionTypeDescriptions: { [key in ComplianceRulesActionType]: string } = {
    [ComplianceRulesActionType.Reject]: 'Rejeitar',
    [ComplianceRulesActionType.ManualApproval]: 'Aprovação manual',
};

export function getComplianceActionDescription(action: ComplianceRulesActionType): string {
    return ComplianceRulesActionTypeDescriptions[action];
}
