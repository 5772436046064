import type { ToastProps } from './Toast.interface';
import { useState, type FunctionComponent, useEffect } from 'react';
import { IconButton, Grid, Stack } from '@mui/material';
import { ToastWrapper } from './Toast.styled';
import { CancelIcon, SeverityErrorIcon, SeverityInfoIcon, SeveritySucessIcon, SeverityWarningIcon } from '../../icons';
import { LoadingBar, Typography } from '../../atoms';

export const Toast: FunctionComponent<ToastProps> = ({
  startIcon,
  vertical = 'top',
  horizontal = 'right',
  autoHideDuration = 6000,
  open,
  onClose,
  severity = 'success',
  title,
  description
}) => {
  const handleClose = () => onClose && onClose();
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let timer: string | number | NodeJS.Timer | undefined;
    let progress = 0;

    if (open) {
      setShowProgressBar(true);
      const increment = 100 / (autoHideDuration! / 100);
      timer = setInterval(() => {
        progress += increment;
        setProgress(progress);
        if (progress >= 100) {
          clearInterval(timer);
          setShowProgressBar(false);
        }
      }, 100);
    }

    return () => {
      clearInterval(timer);
    };
  }, [open, autoHideDuration]);

  const seveirySx = { height: 60, width: 60 }
  const severityIcon: { [key: string]: JSX.Element } = {
    "success": <SeveritySucessIcon sx={seveirySx} />,
    "info": <SeverityInfoIcon sx={seveirySx} />,
    "warning": <SeverityWarningIcon sx={seveirySx} />,
    "error": <SeverityErrorIcon sx={seveirySx} />
  }

  return (
    <div style={{ position: 'relative', marginTop: 'auto' }}>
      <ToastWrapper
        severity={severity}
        key={vertical + horizontal}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
        open={open}
        message={
          <>
            <Stack direction="row" justifyContent="center" alignItems="center" spacing={3}>
              <Grid item>
                {severityIcon[severity]}
              </Grid>
              <Stack
                direction="column"
                spacing={1}>
                <Typography color="neutral.darkest" variant='xl' fontWeight={700}>
                  {title}
                </Typography>
                <Typography color="neutral.medium" variant='sm' lineHeight="19.2px">
                  {description}
                </Typography>
              </Stack>
            </Stack>

            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                position: 'absolute',
                top: '8px',
                right: '8px',
              }}
            >
              <IconButton
                aria-label="fechar"
                color="inherit"
                size="small"
                onClick={handleClose}
              >
                <CancelIcon sx={{ height: '18px', width: '18px' }} htmlColor="#4F4F4F" />
              </IconButton>
            </div>

            {showProgressBar && (
              <LoadingBar
                value={progress}
                valueBuffer={progress}
                variant="determinate"
                style={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
              />
            )}
          </>
        }
      />
    </div>
  );
};
