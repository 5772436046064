import { Stack } from '@mui/material';
import { CardData, Typography } from '@uy3/web-components'
import { PaymentBankSlip } from 'contexts/bankAccount/bankAccountType';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import moment from 'moment';

type InfoConfirmPayProps = {
    bankSlipData: PaymentBankSlip;
};

export const InfoConfirmPay = ({ bankSlipData }: InfoConfirmPayProps) => {
    return (
        <Stack spacing={3}>
            <Typography variant='lg' fontWeight="700">Dados do beneficiário original</Typography>
            <CardData
                listItem={[
                    { id: "1", title: 'Data de vencimento', value: `${moment(bankSlipData?.dueDate).format('DD/MM/yyyy')}` },
                    { id: "2", title: 'CPF/CNPJ', value: `${formatDocumentNumber(bankSlipData?.beneficiary.document) ?? "N/D"}` },
                    { id: "3", title: 'Nome/Razão Social', value: `${bankSlipData?.beneficiary?.name ?? "N/D"}` },
                    { id: "4", title: 'Nome fantasia', value: `${bankSlipData?.beneficiary?.name ?? "N/D"}` },
                ]}
            />
            <Typography variant='lg' fontWeight="700">Dados do pagador original</Typography>
            <CardData
                listItem={[
                    { id: "1", title: 'Nome completo', value: `${bankSlipData?.payer.name ?? "N/D"}` },
                    { id: "2", title: 'CPF/CNPJ', value: `${formatDocumentNumber(bankSlipData?.payer.document) ?? "N/D"}` }
                ]}
            />
        </Stack>
    )
}
