import { TextField as MuiTextField } from '@mui/material';
import { styled } from '@mui/material/styles';

export const TextField = styled(MuiTextField)(({ theme, error }) => ({
  '& .MuiInputBase-input': {
    ...theme.typography.sm,
    padding: '14px',
    height: '56px',
    boxSizing: 'border-box',
    color: theme.palette.neutral.dark,
    '&::placeholder': {
      ...theme.typography.sm,
      color: theme.palette.neutral.dark,
      opacity: 1,
    },
  },
  '& .Mui-disabled': {
    color: 'rgba(0, 0, 0, 0.38) !important',
    '& .MuiSvgIcon-root': {
      color: 'rgba(0, 0, 0, 0.38) !important',
    },
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    fontSize: '25px',
  },
  '& .MuiFormLabel-root': {
    ...theme.typography.sm,
  },
  '& MuiInputBase-root-MuiOutlinedInput-root.Mui-error ': {
    border: `2px solid ${theme.palette.error.dark}`,
  },
  '& .MuiFormHelperText-root': {
    ...theme.typography.xs,
    color: theme.palette.primary.main,
  },
  '& legend': {
    ...theme.typography.xs,
  },
  '& .MuiFormLabel-asterisk': {
    color: theme.palette.primary.main,
  }
}));
