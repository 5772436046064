import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { GetListApiResponse, iconSx } from 'contexts/apiRequestContext';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid';
import { NaturalPersonAndLegalPersonFiltersContainer } from 'components/DataTableFilters/NaturaPersonAndLegalPersonFilter/NaturalPersonAndLegalPersonFilterContainer';
import { useAppConfig } from 'contexts/appConfig';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { NaturalPersonReadModel } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { useUserPermissionData } from 'contexts/userContext';
import { useTenant } from 'contexts/tenantContext';

type NaturalPersonListProps = {
    queryData: GetListApiResponse<NaturalPersonReadModel> | any;
    rowsPerPage: any;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (page: number) => void;
    setRowData: (data: any) => void;
    page: number;
};

const NaturalPersonList = ({
    queryData,
    setPage,
    setRowsPerPage,
    rowsPerPage,
    page,
    setRowData,
}: NaturalPersonListProps) => {
    const { hasPermission } = useUserPermissionData();
    const { appConfig } = useAppConfig();
    const isScan3 = appConfig.USE_SCAN3;
    const { isParentTenant } = useTenant();

    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columnListNaturalPerson = [
        isParentTenant && !isScan3
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                minWidth: 120,
                flex: 2,
                editable: false,
                renderCell: (cellValues: any) => {
                    return (
                        <div
                            style={{
                                textAlign: 'left',
                                marginLeft: '8px',
                            }}
                        >
                            {cellValues.value}
                        </div>
                    );
                },
            }
            : undefined,
        {
            field: 'name',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {formatDocumentNumber(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'birthDate',
            headerName: 'Data de Nascimento',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Data de Criação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div>
                        {cellValues.value &&
                            new Date(cellValues.value).toLocaleDateString('pt-BR', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            headerAlign: 'center',
            align: 'center',
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        {hasPermission('NaturalPerson', 'Read') &&
                            <Link to={`/pessoas-fisicas/todas/${cellValues.id}`}>
                                <IconButton>
                                    <EditIcon sx={iconSx} />
                                </IconButton>
                            </Link>}
                        {hasPermission('NaturalPerson', 'Delete') &&
                            <IconButton onClick={() => setRowData(cellValues)}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnListNaturalPerson as GridColDef[];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: (
                    <NaturalPersonAndLegalPersonFiltersContainer isNaturalPerson={true} />
                ),
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default NaturalPersonList;
