import { Grid, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import React from 'react'

export const GuarantorDetails = () => {
    const { getValues } = useFormContext();
    const formValues = getValues();
    const guarantor = formValues?.guarantor;
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between' border='1px solid grey' p={2} borderRadius='8px'>
            <Grid>
                <Typography fontSize='19px' fontWeight='bold'>{guarantor?.name}</Typography>
            </Grid>
            <Grid>
                <Typography fontSize='16px'>{formatDocumentNumber(guarantor?.registrationNumber)}</Typography>
            </Grid>
        </Stack>
    )
}
