import { Stack } from '@mui/material';
import { Button, Typography } from '@uy3/web-components';
import React, { useState } from 'react'
import { activeTheme } from 'services/theme';

type VerticalSelectProps = {
  listOptions: string[] | undefined; 
  size?: 'small' | 'medium' | 'large';
  width?: 'full' | number;
  handleValueSelected?: (index: number, value: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const theme = activeTheme();
const VerticalSelect: React.FC<VerticalSelectProps> = ({ size = 'large', width, listOptions, handleValueSelected }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  return (
    <Stack width={width === 'full' ? '100%' : width}>
      {listOptions && listOptions?.length > 0 && listOptions.map((item, index) => {
        const isActive = currentIndex === index;
        return (
          <>
            <Button
              variant={'text'}
              type="button"
              key={index}
              sx={{
                textAlign: 'left',
                display: 'block',
                borderRadius: 0,
                borderRight: `2px solid ${isActive ? theme.palette.primary.main : 'transparent'}`
              }}
              onClick={(event) => {
                handleValueSelected && handleValueSelected(index, event);
                setCurrentIndex(index);
              }}
              size={size}
            >
              <Typography
                fontSize="15px"
                fontWeight={500}
                variant="xs"
                textAlign='left'
                color={isActive ? 'primary.main' : '#979797'}
                lineHeight="14.52px"
              >
                {item}
              </Typography>
            </Button>
          </>
        )
      }
      )}
    </Stack>
  )
}

export default VerticalSelect
