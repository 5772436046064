import { useEconomicActivityCodeListData } from 'contexts/economicActivityCode';
import InformationLegalPersonForm from './InformationForm';
import { useEnumContext } from 'contexts/enumContext';

interface InfoLegalPersonContainerProps {}
const InformationLegalPersonContainer: React.FC<InfoLegalPersonContainerProps> = () => {
    const { autoCompleteProps: economicActvCodeCompleteProps } = useEconomicActivityCodeListData();
    const { autoCompleteProps: legalNatureList} = useEnumContext({enumName: "LegalNature"});

    return <InformationLegalPersonForm cnaeList={economicActvCodeCompleteProps}  legalNatureList={legalNatureList.listOptions}/>;
};
export default InformationLegalPersonContainer;
