import { Grid, IconButton, Stack } from '@mui/material';
import { DataTable, EditIcon, DeleteIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid/';
import { NaturalPersonAndLegalPersonFiltersContainer } from 'components/DataTableFilters/NaturaPersonAndLegalPersonFilter/NaturalPersonAndLegalPersonFilterContainer';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';
import { useTenant } from 'contexts/tenantContext';

type LegalPersonListType = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setRowData: (rowData: any) => void;
};

const LegalPersonList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setRowData,
}: LegalPersonListType) => {
    const { hasPermission } = useUserPermissionData();
    const { isParentTenant } = useTenant();

    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columnsLegalPersonList = [
        isParentTenant
            ? {
                field: 'tenantDisplay',
                headerName: 'Correspondente',
                hideSortIcons: true,
                minWidth: 120,
                flex: 2,
                editable: false,
            }
            : undefined,
        {
            field: 'companyName',
            headerName: 'Razão social',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'registrationNumber',
            headerName: 'CNPJ',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => formatDocumentNumber(cellValues.value),
        },
        {
            field: 'email',
            headerName: 'Email',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'createdAt',
            headerName: 'Data de Criação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {hasPermission('LegalPerson', 'Read') &&
                            <Link to={`/pessoas-juridicas/todas/${cellValues.id}`}>
                                <IconButton>
                                    <EditIcon sx={iconSx} />
                                </IconButton>
                            </Link>}
                        {hasPermission('LegalPerson', 'Delete') &&
                            <IconButton onClick={() => setRowData(cellValues)}>
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        },
    ].filter(Boolean);

    const columns = columnsLegalPersonList as GridColDef[];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <NaturalPersonAndLegalPersonFiltersContainer />,
            }}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            pageCount={queryData?.totalPages ?? 0}
            page={queryData ? queryData.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default LegalPersonList;
