import React from 'react'
import { ShowSelectedValues } from './ShowSelectedValues';
type SelectedValueRelatedPersonsType = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
}

export const SelectedValueRelatedPersons = ({ currentValues, removeFilterSelected}: SelectedValueRelatedPersonsType) => {
    const filteredOnlyRelatedPersons = currentValues?.map((item: any, index: number) => {
        return { ...item, idToRemoved: index }
    }).filter((item: any) => item?.name === "relatedPersons");
    const filteredOnlyTypeOfRelationship = currentValues?.filter((item: any) => item?.name === "typeOfRelationship");

    const listMappedToRender = filteredOnlyRelatedPersons.map((item: any, index: number) => {
        return {
            label: `${filteredOnlyTypeOfRelationship[index].value}: ${item?.value}`,
            indexInitial: item?.idToRemoved
        }
    });

    return (
        <>
            {listMappedToRender.map((item: any) => {
                return (
                    <ShowSelectedValues
                        item={item}
                        label={item?.label}
                        removeFilterSelected={() => removeFilterSelected(item?.indexInitial)}
                    />
                )
            })}
        </>
    )
}
