import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, Typography } from '@uy3/web-components';
import { useState } from 'react';
import { HandleTitle } from '../DatasetNaturalPerson/TypographyAndValues';

type KycPepHistoryListProps = {
  result: any;
};

function KycPepHistoryList({ result }: KycPepHistoryListProps) {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const onChangePage = (page: number) => {
    setPage(page);
  };

  const onChangeRowsPerPage = (page: number) => {
    setRowsPerPage(page);
    setPage(0);
  };

  const columnsPepHistory: GridColDef[] = [
    {
      field: 'details.originalName',
      headerName: 'Nome original',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'details.pepName',
      headerName: 'Nome de sanção',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'level',
      headerName: 'Nível',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'jobTitle',
      headerName: 'Cargo',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'department',
      headerName: 'Departamento',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'motive',
      headerName: 'Motivo',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'source',
      headerName: 'Fonte',
      hideSortIcons: true,
      minWidth: 150,
      flex: 2,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value}
          </div>
        );
      },
    },
    {
      field: 'startDate',
      headerName: 'Início',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
          </div>
        );
      },
    },
    {
      field: 'endDate',
      headerName: 'Fim',
      hideSortIcons: true,
      minWidth: 150,
      flex: 1,
      editable: false,
      renderCell: (cellValues) => {
        return (
          <div
            style={{
              textAlign: 'left',
              marginLeft: '-8px',
            }}
          >
            {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
          </div>
        );
      },
    }
  ];

  const firstPEPOccurenceDate =
    result?.firstPEPOccurenceDate &&
    new Date(result?.firstPEPOccurenceDate).toLocaleDateString('pt-BR');
  const lastPEPOccurenceDate =
    result?.lastPEPOccurenceDate &&
    new Date(result?.lastPEPOccurenceDate).toLocaleDateString('pt-BR');

  return (
    <>
      <Grid2 mt={2}>
        <Typography variant="h5" color="neutral.dark" fontWeight={600}>
          PEP
        </Typography>
        <HandleTitle
          title={'PEP'}
          value={result?.isCurrentlyPEP ? 'Sim' : 'Não'}
          key="isCurrentlyPEP"
        />
        <HandleTitle
          title={'Primeiro registro'}
          value={firstPEPOccurenceDate ?? 'N/D'}
          key="firstPEPOccurenceDate"
        />
        <HandleTitle
          title={'Último registro'}
          value={lastPEPOccurenceDate ?? 'N/D'}
          key="lastPEPOccurenceDate"
        />
      </Grid2>

      <DataTable
        getRowId={() => Math.random().toString()}
        columns={columnsPepHistory}
        rows={result?.pepHistory ?? []}
        page={page}
        rowCount={result?.pepHistory?.length ?? 5}
        rowsPerPage={rowsPerPage}
        setPage={onChangePage}
        setRowsPerPage={onChangeRowsPerPage}
        paginationMode="client"
      />
    </>
  );
}

export default KycPepHistoryList;
