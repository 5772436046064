import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { EAction, ETransitoryResourceLiquidation } from './enums';

export class ApprovalLiquidationScheduleCreateModel {
    cosifAccountCode: string | undefined;
    transitoryBankAccountAction: ETransitoryResourceLiquidation | undefined;
    liquidationSchedule: LiquidationSchedule[] | undefined;
}

export interface LiquidationSchedule {
    liquidationScheduleId: string;
    action: EAction;
    failureMessage: string;
    cosifAccountCode: string;
}

export function mapApprovalScheduleData<T>(data: T): T {
    return toDataModel(data as unknown as Record<string, unknown>, ApprovalLiquidationScheduleCreateModel) as T;
}
