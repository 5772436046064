import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useFundsList } from 'contexts/fundContext';
import { batchAssignmentSelectFilterOptions } from 'contexts/creditNote/creditNoteOptions';
import { useLocation } from 'react-router';

type BatchAssignmentFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

function BatchAssignmentFiltersForm() {
    const { watch } = useFormContext();
    const { fundAutoCompleteProps } = useFundsList({ page: 0, size: 10 }, 'always');
    return (
        <>
            <Grid>
                <SelectFormField
                    name='optionFilter'
                    label='Filtrar '
                    variant='outlined'
                    fullWidth
                    options={[
                        { label: "Status", value: 'status' },
                        { label: "Cessionário", value: 'fundId' },
                        { label: "Data da cessão", value: 'initialAssignmentDate' },
                        { label: "Valor máximo da cessão", value: 'maxAssigmentValue' },
                        { label: "Valor mínimo da cessão", value: 'minAssigmentValue' },
                        { label: "Valor máximo presente", value: 'maxPresentValue' },
                        { label: "Valor mínimo presente", value: 'minPresentValue' },
                        { label: "Valor de cálculo máximo", value: 'maxAssignmentCalculationValue' },
                        { label: "Valor de cálculo mínimo", value: 'minAssignmentCalculationValue' },
                        { label: "Número da operação", value: 'creditNoteNos' },
                        { label: "Número da cessão", value: 'assignmentNumber' },
                    ]}
                />
            </Grid>
            {watch('optionFilter') === 'creditNoteNos' && (
                <TextFormField
                    label="Filtrar por número da operação"
                    name="creditNoteNos"
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'assignmentNumber' && (
                <TextFormField
                    label="Filtrar por número da cessão"
                    name="assignmentNumber"
                    type='number'
                    fullWidth
                    required
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'assignmentPrice' && (
                <CurrencyFormField
                    label="Filtrar por valor da cessão"
                    name="assignmentPrice"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'maxPresentValue' && (
                <CurrencyFormField
                    label="Filtrar por valor máximo presente"
                    name="maxPresentValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minPresentValue' && (
                <CurrencyFormField
                    label="Filtrar por valor mínimo presente"
                    name="minPresentValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'maxAssigmentValue' && (
                <CurrencyFormField
                    label="Filtrar por valor máximo da cessão"
                    name="maxAssigmentValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minAssigmentValue' && (
                <CurrencyFormField
                    label="Filtrar por valor mínimo da cessão"
                    name="minAssigmentValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'maxAssignmentCalculationValue' && (
                <CurrencyFormField
                    label="Filtrar por valor máximo do cálculo"
                    name="maxAssignmentCalculationValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minAssignmentCalculationValue' && (
                <CurrencyFormField
                    label="Filtrar por valor mínimo do cálculo"
                    name="minAssignmentCalculationValue"
                    fullWidth
                    required
                    variant="outlined"
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'status' && (
                <SelectFormField
                    label="Filtrar por status"
                    name="status"
                    fullWidth
                    showEndAdornment={true}
                    options={batchAssignmentSelectFilterOptions}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'fundId' && (
                <AutocompleteField
                    label="Filtrar por cessionário"
                    name="fundId"
                    displayName="fundIdDisplay"
                    showEndIcon={true}
                    endIconType="submit"
                    {...fundAutoCompleteProps}
                />
            )}
            {watch('optionFilter') === 'initialAssignmentDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Filtrar por data inicial"
                        name="initialAssignmentDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalAssignmentDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

export const BatchAssignmentFilters = ({
    currentValues,
    removeFilterSelected,
}: BatchAssignmentFiltersProps) => {
    const { pathname } = useLocation();
    let keyLocalStorageToColumns: string = pathname === "/ccb/cessoes" ?
        'batch-assignment-columns' : 'assignment-columns-creditNote'
    return (
        <FilterStyleBase
            formFilters={<BatchAssignmentFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    enableSelectFilter
                    recordType={keyLocalStorageToColumns}
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
