import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, CompleteIcon } from '@uy3/web-components'
import { SelectFormField, TextFormField } from 'components/Forms/FormFields'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import React from 'react'
import { activeTheme } from 'services/theme'
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types'

const theme = activeTheme();
type UpdateInstructionDebtCollectionProps = {
    onClose: () => void
}
export const UpdateInstructionDebtCollection: React.FC<UpdateInstructionDebtCollectionProps> = ({onClose}) => {
    const { watch } = useFormContext();
    return (
        <Stack spacing={2}>
            <Grid xs={3}>
                <SelectFormField
                    label="Instrução de protesto"
                    name="firstInstructionDebtCollection"
                    options={firstInstructionDebtCollectionOptions}
                />
            </Grid>
            <Grid xs={3}>
                {watch('firstInstructionDebtCollection') === "06" &&
                    <TextFormField
                        label="Nº de dias após vencimento para envio automático"
                        variant="outlined"
                        required
                        name="secondInstructionDebtCollection"
                        fullWidth
                        type='number'
                    />
                }
            </Grid>

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    variant='outlined'
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant='contained'
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    type='submit'
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
