import type { FunctionComponent } from 'react';
import type { SummaryDrawerProps } from './SummaryDrawer.interface';

import { Stack, useMediaQuery, useTheme } from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { alertProps, cardDataProps, cardInfoWithValueProps } from './SummaryDrawer.mock';
import { Link, TotalStack } from './SummaryDrawer.styled';
import { Alert, CardData } from '../../molecules';
import { Drawer } from '../Drawer';
import { Button, Typography } from '../../atoms';
import { TransferIcon } from '../../icons/TransferIcon';
import { CardValue } from '../../molecules/CardValue';

export const SummaryDrawer: FunctionComponent<SummaryDrawerProps> = ({ open, onClose, anchor }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Drawer
      title="Resumo da operação"
      description="Confira as informações antes de aprovar a operação"
      open={open}
      anchor={anchor}
      onClose={onClose}
    >
      <Stack direction="column">
        <CardValue {...cardInfoWithValueProps} />

        <Typography variant="xs" lineHeight="24px" color="neutral.dark" mt={4} mb={7}>
          Dados da operação
        </Typography>

        <CardData {...cardDataProps} />

        <Alert {...alertProps} />

        <Stack direction="row" justifyContent="flex-end">
          <Link href="/">Ver limites transacionais</Link>
        </Stack>

        <TotalStack direction="row" justifyContent="space-between" alignItems="center" my={6}>
          <Typography variant="sm" color="neutral.dark" lineHeight="29px">
            Total da transferência
          </Typography>
          <Typography variant="lg" color="primary" lineHeight="38px" fontWeight={700}>
            R$ 1.000,00
          </Typography>
        </TotalStack>

        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems="center"
          justifyContent={isMobile ? 'center' : 'flex-end'}
          spacing={3}
          mb={6}
        >
          <Button
            startIcon={<CancelOutlinedIcon />}
            variant="outlined"
            size="medium"
            fullWidth={isMobile}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<TransferIcon />}
            variant="contained"
            size="medium"
            fullWidth={isMobile}
          >
            Confirmar transferência
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};
