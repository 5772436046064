import { string, object, number } from 'yup';

export function validationSchemaGroupForm() {
    return object().shape({
        groupName: string().typeError("Nome do grupo é obrigatório.").required("Nome do grupo é obrigatório.")
            .min(1, 'Informe ao menos 1 caractere').max(128, 'Limite de caracteres excedido')
            .matches(/^\S+$/g, "O nome do grupo deve conter entre 1 e 128 caracteres que não sejam espaços."),
        description: string().nullable(),
        precedence: number().nullable(),
    });
};

export const defaultValuesGroupForm = {
    groupName: "",
    description: "",
    precedence: 0
};