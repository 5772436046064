import { Dispatch, SetStateAction } from 'react';
import { SelectOption } from 'components/Forms/FormFields';
import { invalidBankCode } from 'helpers/formats/BankAccount';
import { LiquidationScheduleReadModel } from '../LiquidationScheduleReadModel';
import { RelatedBankAccountType } from 'contexts/relatedBankAccountContext';
import { TransferRequestModel, TransferToRequestModel } from '../genericsType';
import { EPaymentType } from 'utils/enums';
import { ETransferCreditNoteStatus } from '../enums';
import { CheckCircleIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';

// #region Função para mapear os dados de uma conta para um CardValue
export function mapDataToCardValue(
    data: LiquidationScheduleReadModel | RelatedBankAccountType,
    dataList: any[],
    selectedIndex: Dispatch<SetStateAction<number | undefined>>,
    onDelete: (index: number) => void,
    readOnly: boolean
) {
    const commonProps = {
        balanceTitle: 'Valor liberado',
        balanceAmountInCents: 'amountInCents' in data ? String(data.amountInCents) : undefined,
        isReadOnly: readOnly,
    };

    const index = dataList.findIndex((item) => item === data);
    const handleClick = () => {
        selectedIndex(index);
    };

    if ('pixQrCode' in data && data.pixQrCode) {
        const cardHeader = data.typeDisplay ? `${data.typeDisplay} (QR Code)` : 'QR Code';
        return {
            ...commonProps,
            cardHeader,
            accountType: 'pixQrCode' as const,
            paymentTypeTitle: 'Pix QR Code',
            paymentValue: data.pixQrCode.qrCode,
            onEditClick: handleClick,
            onDeleteClick: () => onDelete(index),
            erroMessage: data?.erroMessage,
            isLiquidationSchedule: true,
            statusInfo: getTransferStatusInfo(data.status),
        };
    }

    if ('bankSlip' in data && data.bankSlip) {
        const cardHeader = data.typeDisplay ? `${data.typeDisplay} (Boleto)` : 'Boleto';
        return {
            ...commonProps,
            cardHeader,
            accountType: 'bankSlip' as const,
            paymentTypeTitle: 'Boleto',
            paymentValue: data.bankSlip.barcodeOrDigitableLine,
            onEditClick: handleClick,
            onDeleteClick: () => onDelete(index),
            erroMessage: data?.erroMessage,
            isLiquidationSchedule: true,
            statusInfo: getTransferStatusInfo(data.status),
        };
    }

    if ('pixKey' in data && data.pixKey) {
        const cardHeader = data.typeDisplay ? `${data.typeDisplay} (Pix)` : 'Pix';
        return {
            ...commonProps,
            cardHeader,
            accountType: 'pix' as const,
            paymentTypeTitle: 'Chave Pix',
            paymentValue: data.pixKey.key,
            onEditClick: handleClick,
            onDeleteClick: () => onDelete(index),
            erroMessage: data?.erroMessage,
            isLiquidationSchedule: true,
            statusInfo: getTransferStatusInfo(data.status),
        };
    }

    if ('transfer' in data && data.transfer) {
        const cardHeader = data.typeDisplay ? `${data.typeDisplay} (TED)` : 'TED';

        const transfer = data.transfer as TransferRequestModel;
        return {
            ...commonProps,
            cardHeader,
            accountType: 'transfer' as const,
            bankNameLabel: transfer.to.bankCodeDisplay,
            bankAgencyNumber: transfer.to.agency,
            accountDetails: transfer.to.accountNumber,
            onEditClick: handleClick,
            onDeleteClick: () => onDelete(index),
            erroMessage: data?.erroMessage,
            isLiquidationSchedule: true,
            statusInfo: getTransferStatusInfo(data.status),
        };
    }

    if ('operationTypeValue' in data) {
        switch (data.operationTypeValue) {
            case 'Transfer':
                return {
                    cardHeader: data.bankCode === '457' ? 'Conta transitória' : 'Conta troco',
                    accountType: 'transfer' as const,
                    bankNameLabel: data.bankCodeDisplay,
                    bankAgencyNumber: data.agency,
                    accountDetails: data?.account,
                    onEditClick: handleClick,
                    onDeleteClick: () => onDelete(index),
                    isReadOnly: readOnly,
                };

            case 'Pix':
                return {
                    cardHeader: 'Conta troco',
                    accountType: 'pix' as const,
                    paymentTypeTitle: 'Chave Pix',
                    paymentValue: data.label,
                    onEditClick: handleClick,
                    onDeleteClick: () => onDelete(index),
                    isReadOnly: readOnly,
                };

            default:
                return null;
        }
    }

    return null;
}

export const getTransactionType = (paymentType: EPaymentType | undefined): string => {
    switch (paymentType) {
        case EPaymentType.QrCode:
            return 'pixQrCode';
        case EPaymentType.Bankslip:
            return 'bankSlip';
        case EPaymentType.Pix:
            return 'pixKey';
        case EPaymentType.BankTransfer:
            return 'transfer';
        default:
            return '';
    }
};

export type TliquidationScheduleOpt = {
    description: string;
    type: string;
    status: string;
    linkStatus: string;
    cosifAccountCode: string | null
} & SelectOption;

export function createLiquidationScheduleOptions(data: LiquidationScheduleReadModel[]): TliquidationScheduleOpt[] {
    return data.map((item) => {
        let label = '';
        let description = '';

        if (item?.pixQrCode) {
            label = `QR Code: ${item.pixQrCode.qrCode}`;
            description = `Pagamento por QR Code`;
        } else if (item?.bankSlip) {
            label = `Boleto: ${item.bankSlip.barcodeOrDigitableLine}`;
            description = `Pagamento por Boleto`;
        } else if (item?.pixKey) {
            label = `Chave Pix: ${item.pixKey.key}`;
            description = `Pagamento por Pix`;
        } else if (item?.transfer) {
            label = `Transferência: ${formatTransfer(item.transfer.to)}`;
            description = `Pagamento por TED`;
        } else {
            label = 'N/D';
        }

        const { id, type, status, linkStatus, cosifAccountCode } = item ?? {};
        return {
            label,
            value: id,
            description,
            type,
            status,
            linkStatus,
            cosifAccountCode
        };
    });
}

function formatTransfer(transfer: TransferToRequestModel): string {
    return `
    ${invalidBankCode(transfer?.bankCode) ? '' : `${transfer?.bankCode}`} 
    - ${transfer.bankCodeDisplay ?? 'N/D'} 
    - Ag: ${transfer?.agency} 
    - Conta: ${transfer?.accountNumber ?? ''}`;
}

export interface StatusInfo {
    text: string;
    color: string;
    icon?: JSX.Element;
}

const getTransferStatusInfo = (status?: keyof typeof ETransferCreditNoteStatus): StatusInfo => {
    switch (status) {
        case 'Finished':
            return {
                text: 'Pago',
                color: '#4CAF50',
                icon: <CheckCircleIcon sx={iconSx} htmlColor="#4CAF50" />,
            };
        case 'Failed':
            return {
                text: ETransferCreditNoteStatus.Failed,
                color: '#FF0000',
            };
        case 'Pendding':
            return {
                text: ETransferCreditNoteStatus.Pendding,
                color: '#C77700',
            };
        default:
            return { text: 'Novo', color: '#808080' };
    }
};


interface ScheduleItem {
    status: string;
    linkStatus: string;
}

export const isFinishedAndUnlinked = (item: ScheduleItem): boolean => {
    return item?.status === 'Finished' && item?.linkStatus === 'Unlinked';
};
