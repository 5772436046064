/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError, AxiosError } from 'axios';
import { useIdentity } from 'contexts/identityContext';
import {
    getPermissionList,
    fromApiDataModel,
    GetPermissionProps,
    PermissionModel,
    PermissionListProps
} from 'services/permissions';
import {
    editOrCreateUser,
    getUserList,
    getUserById,
    getUserGroupList,
    UserListProps,
    UserGroupListProps,
    enableOrDisableUser,
} from 'services/user';
import { GroupInfo, postGroupInsertUser } from 'services/user/userGroups';
import {
    ApiResponseError,
    GetListApiResponse,
    GetListApiResponseSuccess,
    GetViewApiResponse,
    GetViewApiResponseSuccess,
    IRefetchOnMountModeGetQuery,
    useApiRequest,
} from './apiRequestContext';
import { useTenant } from './tenantContext';
import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useFilterValues } from './filterValuesContext';
import { isEmpty } from 'lodash';
import { useLocation, useNavigate } from 'react-router';
export interface Attribute {
    userAttributes: UserAttribute[];
}

export interface UserAttribute {
    name: string;
    value: string;
}

export type UserGetDetailResponseModel = {
    address: string;
    email: string;
    emailVerified: boolean;
    enabled: boolean;
    id: string;
    name: string;
    phoneNumber: string;
    registrationNumber: string;
    sendNotifications: boolean;
    userAttributes: Attribute[];
    userCreateDate: string;
    userLastModifiedDate: string;
    userStatus: string;
};

export type UserCreateResponse = UserCreateSuccessResponse | ApiResponseError;

export type UserCreateSuccessResponse = {
    type: 'success';
    username: string;
    enabled: boolean;
    userStatus: string;
};

export function useUserData(userId: string) {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('User', 'Read');

    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && userId !== 'novo' && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['get-user-data', userId, currentTenantId],
        queryFn: async (): Promise<GetViewApiResponseSuccess<UserGetDetailResponseModel>> => {
            startRequest();
            const { data, status, statusText } = await getUserById(userId, token!, currentTenantId);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors
                });
                throw data;
            };

            if (status >= 400 && status <= 599) {
                throw data;
            };
            return data as GetViewApiResponseSuccess<UserGetDetailResponseModel>;
        },
        onError: (error: ApiResponseError) => {
            if (pathname.includes('/seguranca/usuarios/') && pathname.length > 20) {
                return navigate('/seguranca/usuarios');
            }
        }
    });

    return { status, data, error, isFetching, refetch, isLoading };
};

export function useUserListData(filters: UserListProps, refetchOnMount?: IRefetchOnMountModeGetQuery) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { currentTenantId } = useTenant();
    const { filterValues, setFilterValues } = useFilterValues();
    const recordType = isEmpty(filterValues.recordType) ? 'User' : filterValues.recordType;
    const listDataFilters = recordType === 'User' && { ...filterValues.filters };
    let filtersComplete = { tenant: currentTenantId, ...filters, ...listDataFilters };
    const [userList, setUserList] = useState<{ label: string; value: string }[]>([]);
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('User', 'Read');
    const { status, data, error, isFetching, refetch, isLoading } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount,
        refetchOnWindowFocus: false,
        queryKey: ['user-list-operators', filtersComplete, filterValues, currentTenantId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getUserList(filtersComplete, token!);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);

            const options = data ?? [];

            setUserList(
                options.data.map((n: any) => {
                    return { label: n?.name, value: n?.id };
                })
            );

            return data
        },
    });

    const usersAutoCompleteProps = {
        listOptions: userList,
        loading: !hasReadPermission ? false : isLoading || isFetching,
        onSearch: (name: string | undefined) => name && setFilterValues({ name }, 'User')
    };

    return {
        usersAutoCompleteProps,
        userStts: status,
        userData: data as GetListApiResponseSuccess<UserGetDetailResponseModel>,
        userErr: error,
        isFetching,
        refetch,
        isLoading
    };
}

export function useUserMutation(userId: string, onSuccess?: (data: any) => void, onError?: (error: any) => void) {
    const { currentTenantId } = useTenant();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    return useMutation({
        mutationFn: async (values: FieldValues): Promise<GetViewApiResponse<UserCreateResponse>> => {
            startRequest();
            const { data, status, statusText } = await editOrCreateUser(values, userId, currentTenantId, token);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<UserCreateResponse>;
        },
        onSuccess(data, variables, context) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, context) {
            let message = 'Erro desconhecido';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        }
    });
}

export function useUserGroupListData(filters: UserGroupListProps, userId: string) {
    const { currentTenantId } = useTenant();
    const { pathname } = useLocation();
    const isPatchCreditNote = pathname === '/ccb/operacoes';
    const { token } = useIdentity();
    let filterFull = { ...filters, tenant: currentTenantId };
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { hasPermission } = useUserPermissionData();
    const hasReadPermission = hasPermission('User', 'Read');

    if (isPatchCreditNote) {
        filterFull = { ...filters, tenant: undefined }
    }

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && hasReadPermission,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['user-list', filterFull, userId, currentTenantId],
        queryFn: async (): Promise<GetListApiResponse<GroupInfo>> => {
            startRequest();
            const { data, status, statusText } = await getUserGroupList(filterFull, userId, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            return data;
        },
    });

    return { status, data, error: error, isFetching, refetch };
};

export function useUserPermissionData(params?: GetPermissionProps) {
    const { currentTenantId } = useTenant();
    let tenantToQuery: string | undefined = undefined;
    if (params !== undefined) {
        // Só usar informações de tenant caso haja algum principal especifico sendo consultado
        tenantToQuery = currentTenantId;
    }
    const { token } = useIdentity();
    const { endRequest, setSubmitError, startRequest } = useApiRequest();

    const { status, data: permissions, error, isFetching, refetch, isLoading} = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['user-permission-list-all', params, token, currentTenantId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getPermissionList(params, tenantToQuery, token!);
            endRequest(true);
            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            return {
                data: fromApiDataModel(data as PermissionListProps[]), 
                dataModelApi: data
            }
        }
    });

    const hasPermission = (resource: string | string[], type: any) => {
        if (permissions === undefined) return false;

        const hasPerm = permissions?.data?.filter(
            (perm: PermissionModel) =>
                ((perm.resource === resource ||
                    (typeof resource != 'string' && resource.includes(perm.resource))) &&
                    ((type === 'Read' && perm.readPermission !== 'None') ||
                        (type === 'Create' && perm.createPermission !== 'None') ||
                        (type === 'Update' && perm.updatePermission !== 'None') ||
                        (type === 'Delete' && perm.deletePermission !== 'None'))) ||
                (perm.actions && perm.actions[type])
        ).length > 0;

        return hasPerm;
    };

    return { status, data: permissions?.data, permissionInModelApi: (permissions?.dataModelApi as any) ?? [], error: error, isFetching, isLoading, refetch, hasPermission };
}

export function useGroupInsertUserMutation(
    userId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { currentTenantId } = useTenant();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: any) => {
            startRequest();
            if (userId === '') return;
            const { data, status, statusText } = await postGroupInsertUser(
                values,
                userId!,
                token!,
                currentTenantId
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<UserCreateResponse>;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: []
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
}

export function useEnableOrDisableMutation(
    userId: string,
    onSuccess?: (data: any) => void,
    onError?: (error: any) => void
) {
    const { token } = useIdentity();
    const { currentTenantId } = useTenant();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (type: "EnableUser" | "DisableUser") => {
            startRequest();
            if (userId === undefined || userId === null || isEmpty(userId)) {
                return
            }
            const { data, status, statusText } = await enableOrDisableUser({
                token: token!,
                userId,
                type,
                tenant: currentTenantId!
            });
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }
            return data as GetViewApiResponseSuccess<UserCreateResponse>;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: []
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });
}
