import { Grid, Stack } from '@mui/material'
import { Button, CancelIcon, CompleteIcon } from '@uy3/web-components'
import { CheckboxFormField, DatePickerFormField } from 'components/Forms/FormFields'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import React from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type GenerateCnabProps = {
    onClose: () => void;
}

export const GenerateCnab = ({ onClose }: GenerateCnabProps) => {
    const { watch } = useFormContext();
    return (
        <Stack spacing={2}>
            <CheckboxFormField name='selectManualDate' label='Definir período' />
            {watch('selectManualDate') === true && (
                <React.Fragment>
                    <Grid>
                        <DatePickerFormField
                            label='Data de início'
                            name='initDate'
                            required
                            fullWidth
                        />
                    </Grid>
                    <Grid>
                        <DatePickerFormField
                            label='Data final'
                            name='endDate'
                            required
                            fullWidth
                        />
                    </Grid>
                </React.Fragment>
            )}

            <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CancelIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                >
                    Fechar
                </Button>

                <Button
                    variant='contained'
                    type='submit'
                    startIcon={<CompleteIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
