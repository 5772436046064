import { Grid, Stack } from '@mui/material';
import { Button } from '@uy3/web-components';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { reasonOptions } from './PixRefundSchema';

type PixRefundProps = {
    onClosePopup: () => void;
};

const PixRefundForm = ({ onClosePopup }: PixRefundProps) => {
    return (
        <Stack spacing={2} mt={2}>
              <CurrencyFormField
                fullWidth
                name="valueInCents"
                label="Valor do estorno"
                variant="outlined"
                disabled
            />

            <SelectFormField
                name="reason"
                label="Motivo do estorno"
                fullWidth
                required
                options={reasonOptions}
            />

            <TextFormField
                variant="outlined"
                fullWidth
                type="text"
                name="observation"
                label="Motivo do estorno"
                margin="dense"
                multiline
                rows={2}
            />

            <Grid container direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                <Button variant="outlined" color="primary" onClick={onClosePopup} size="medium">
                    Não, cancelar
                </Button>
                <Button variant="contained" color="primary" type="submit" size="medium">
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    );
};

export default PixRefundForm;
