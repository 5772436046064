import { FormControlLabel, styled } from "@mui/material";
import { StyledFormControlLabelProps } from "./Radio.interface";

export const StyledControlLabelRadio = styled((props: StyledFormControlLabelProps) => (
    <FormControlLabel
        sx={{
            '.MuiFormControlLabel-label': { fontSize: '1.5rem' },
        }}
        {...props}
    />
))(({ checked }) => ({ '.MuiFormControlLabel-label': checked && { color: '#222' } }));