import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { AlertProps, CardDataProps, CardInfoProps } from '../../molecules';

export const cardInfoWithValueProps: CardInfoProps = {
  title: 'Saldo disponível',
  description: 'Baseado em transações em aprovação.',
  value: '4.451,02',
  icon: <PaidOutlinedIcon />,
  color: 'primary',
};

export const cardDataBeneficiarioProps: CardDataProps = {
  listItem: [
    {
      id: '1',
      title: 'Data de vencimento',
      value: '30/10/2022',
    },
    {
      id: '2',
      title: 'CPF/CNPJ',
      value: '36.686.787/0001-34',
    },
    {
      id: '3',
      title: 'Nome/Razão Social',
      value: 'SPE PARQUE AMAZONIA 14 LTDA',
    },
    {
      id: '4',
      title: 'Nome Fantasia',
      value: 'SPE PARQUE AMAZONIA 14 LTDA',
    },
  ],
};

export const cardDataPagadorProps: CardDataProps = {
  listItem: [
    {
      id: '1',
      title: 'Nome completo',
      value: 'JULIANA  MARTINS',
    },
    {
      id: '2',
      title: 'CPF/CNPJ',
      value: '751.386.893-31',
    },
  ],
};

export const alertProps: AlertProps = {
  text: 'O pagamento será feito mediante saldo em conta de acordo com os seus limites diário e mensal.',
  variant: 'filled',
  severity: 'info',
};
