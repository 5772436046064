import type { FunctionComponent } from 'react';
import type { SelectedWalletProps } from './SelectedWallet.interface';

import { BoxItems, Card, ListItem } from './SelectedWallet.styled';
import { CardValue } from '../../molecules';
import { Typography } from '../../atoms';

export const SelectedWallet: FunctionComponent<SelectedWalletProps> = ({
  walletName,
  completedOperations,
  icon,
  value,
  color,
  listItem,
  description,
}) => {
  return (
    <Card>
      <CardValue
        title={walletName}
        icon={icon}
        value={value}
        color={color}
        isCard={false}
        completedOperations={completedOperations}
      />
      <BoxItems>
        {listItem?.map((item) => (
          <ListItem>
            <Typography variant="sm" color="neutral.medium" lineHeight="19px">
              {item.title}
            </Typography>
            <Typography variant="xs" color="neutral.dark" lineHeight="19px">
              {item.value}
            </Typography>
          </ListItem>
        ))}
      </BoxItems>
      <Typography
        variant="xs"
        color="neutral.primaryShade.87"
        lineHeight="19px"
        align="left"
        width="100%"
      >
        {description}
      </Typography>
    </Card>
  );
};
