import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { BankReadModel } from './Type/BankReadModel';
import { GetViewApiResponseSuccess } from 'contexts/apiRequestContext';
export async function getBankList(search: string, compe: boolean, token: string) {
    const url = '/Bank';

    function containsOnlyNumbers(str: string) {
        return /^[0-9]+$/.test(str);
    }

    let isName = false;
    let isCode = false;

    if (search && containsOnlyNumbers(search)) {
        isCode = true;
    } else if (typeof search === 'string' && search.replace(/\s/g, '')) {
        isName = true;
    }

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: {
            name: isName ? search : undefined,
            code: isCode ? search : undefined,
            compe
        },
    };

    return await creditAxiosApi.get<GetViewApiResponseSuccess<BankReadModel>>(url, config);
}
