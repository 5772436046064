import { string, object } from 'yup';

export function validationSchemaReprocessByStatusForm() {
    return object().shape({
        creditNoteSequence: string().typeError('Etapa da operação: Precisa ser preenchida').required('Etapa da operação: Precisa ser preenchida')
    });
};

export const defaultValuesReprocessByStatus = {
    creditNoteSequence: null
};