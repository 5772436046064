import { Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { phoneNumberMask } from 'utils/constants';
import { cpfMask } from 'utils/constants';
import React from 'react';

export default function InformationNaturalPersonForm() {

    return (
        <React.Fragment>
            <Typography
                variant="lg"
                color="common.black"

            >
                Informações básicas
            </Typography>
            <Stack spacing={3} direction="column" mt={3}>
                <TextFormField
                    name="name"
                    label="Nome"
                    placeholder="Nome"
                    variant="outlined"
                    fullWidth
                    required
                />
                <TextFormField
                    name="registrationNumber"
                    label="CPF"
                    fullWidth
                    placeholder="000.000.000-00"
                    autoComplete="registrationNumber"
                    variant="outlined"
                    required
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: cpfMask },
                    }}
                />
                <TextFormField
                    name="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    required
                />
                <DatePickerFormField
                    label="Data de nascimento"
                    name="birthDate"
                    fullWidth
                    required
                />
                <TextFormField
                    id="nationality"
                    name="nationality"
                    label="Nacionalidade"
                    variant="outlined"
                    fullWidth
                />
                <SelectFormField
                    name="civilStatus"
                    label="Estado Civil"
                    required
                    options={[
                        { label: 'Não informado', value: '' },
                        { label: 'Casado(a)', value: 'Married' },
                        { label: 'Viúvo(a)', value: 'Widowed' },
                        { label: 'Separado(a)', value: 'Separated' },
                        { label: 'Divorciado(a)', value: 'Divorced' },
                        { label: 'Solteiro(a)', value: 'Single' },
                    ]}
                />
                <TextFormField
                    name="phone"
                    id="phone"
                    label="Telefone Celular"
                    variant="outlined"
                    placeholder="(00) 00000-0000"
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: phoneNumberMask },
                    }}
                    required
                />
                <TextFormField
                    type="text"
                    name="occupation"
                    id="ocupation"
                    label="Ocupação"
                    fullWidth
                    variant="outlined"
                    required
                />
            </Stack>
        </React.Fragment>

    );
}