import { string, object, date, array } from 'yup';

const validationSchemaDefault = () => {
    return object().shape({
        rejectionReasonCode: array()
            .typeError('Selecione um motivo')
            .min(1, 'Selecione ao menos 1 motivo')
            .required('Selecione um motivo'),
        message: string().when('rejectionReasonCode', {
            is: (reasonDisapproval: string[]) => {
                return reasonDisapproval.includes('OUTROS_MOTIVOS');
            },
            then: string()
                .typeError('A mensagem precisa ser um texto.')
                .required('Mensagem: precisa ser preenchida.'),
            otherwise: string().nullable(),
        }),
        insertBlock: string().nullable(),
        blockUntil: date().when('insertBlock', {
            is: (insertBlock: string | null) => insertBlock !== null,
            then: date()
                .typeError('Data de Bloqueio inválida.')
                .required('Data de Bloqueio: Precisa ser preenchida.'),
            otherwise: date().typeError('Data inválida'),
        }),
        complianceChecklist: array().when('insertBlock', {
            is: 'Person',
            then: array()
                .typeError('Motivo da aprovação precisa ser preenchida')
                .min(1, 'Selecione ao menos 1 reprovação.')
                .required('Motivo da aprovação: precisa ser preenchida'),
            otherwise: array().typeError('Data inválida'),
        }),
    })
}

export function validationSchemaRejectForm(creditNoteStatus?: string) {
    switch (creditNoteStatus) {
        case 'ComplianceApproval':
            return validationSchemaDefault();
        case 'CreditApproval':
            return validationSchemaDefault();
        case 'Liquidation':
            return validationSchemaDefault();
        case 'ManualLiquidation':
            return validationSchemaDefault();
        case 'SignaturesValidation':
            return object().shape({
                reasonDisapproval: array()
                    .typeError('Motivo da aprovação precisa ser preenchida')
                    .min(1, 'Selecione ao menos 1 reprovação.')
                    .required('Motivo da aprovação: precisa ser preenchida'),
                message: string().when('reasonDisapproval', {
                    is: (option: string[]) => option.includes('OUTROS_MOTIVOS'),
                    then: string()
                        .typeError('Motivo inválido.')
                        .required('Motivo: Precisa ser preenchido.'),
                    otherwise: string().nullable(),
                }),
            });
        default:
            return object().shape({
                message: string()
                    .typeError('Motivo inválido.')
                    .required('Motivo: Precisa ser preenchido.'),
                insertBlock: string().nullable(),
                blockUntil: date().when('insertBlock', {
                    is: (insertBlock: string | null) => insertBlock !== null,
                    then: date()
                        .typeError('Data de Bloqueio inválida.')
                        .required('Data de Bloqueio: Precisa ser preenchida.'),
                    otherwise: date().typeError('Data inválida'),
                }),
            });
    }
}

export const defaultValuesRejectForm = {
    message: null,
    insertBlock: null,
    reasonDisapproval: [],
    complianceChecklist: [],
    rejectionReasonCode: [],
    blockUntil: new Date().toDateString(),
};
