import { Stack } from '@mui/material'
import { Button, CloseIcon, RightIcon } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields'
import React from 'react'
import { activeTheme } from 'services/theme';

const theme = activeTheme();

type PixCopyAndPasteType = {
  onClose: () => void; 
  disabled: boolean
}

export const PixCopyAndPaste = ({ onClose, disabled }: PixCopyAndPasteType) => {
  return (
    <Stack spacing={2}>
      <TextFormField name='qrCode' variant='outlined' label="Inserir código copia e cola" />
      <Stack direction='row' alignItems='center' spacing={2} mt={3} justifyContent='right'>
        <Button
          variant='outlined'
          startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
          onClick={onClose}
        >
          Fechar
        </Button>
        <Button
          variant='contained'
          type='submit'
          disabled={disabled}
          startIcon={<RightIcon htmlColor={theme.palette.common.white} />}
        >
          Consultar
        </Button>
      </Stack>
    </Stack>
  )
}
