import { styled } from '@mui/material/styles';
import { Stack, Link as MuiLink } from '@mui/material';

export const TotalStack = styled(Stack)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    alignItems: 'flex-start'
  }
}));

export const Link = styled(MuiLink)(({ theme }) => ({
  ...theme.typography.xs,
  lineHeight: '19px',
  color: theme.palette.neutral.dark,
  marginTop: '24px',
  textDecorationColor: theme.palette.neutral.dark,
  '&:hover': {
    color: theme.palette.primary.main
  }
}));