import { Stack } from '@mui/material';
import { Button, DeleteIcon, EditIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

interface CardActionProps {
    onEditAction: () => void;
    onDeleteAction: () => void;
}

const theme = activeTheme();
export const CardAction: React.FC<CardActionProps> = ({ onEditAction, onDeleteAction }) => {
    
    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                position: 'absolute',
                bottom: 0,
                py: 4,
                width: '80%',
                left: '50%',
                transform: 'translateX(-50%)',
                justifyContent: 'center',
                alignItems: 'center',
            }}
            >
            <Button
                startIcon={<EditIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                name="btn-edit-action"
                variant="outlined"
                size="small"
                onClick={onEditAction}
                fullWidth
            >
                Editar
            </Button>
            <Button
                fullWidth
                name="btn-delete-action"
                onClick={onDeleteAction}
                variant="outlined"
                size="small"
                startIcon={<DeleteIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
            >
                Excluir
            </Button>
        </Stack>
    );
};