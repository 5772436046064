import { IconButton, Stack } from "@mui/material";
import { CopyIcon } from "@uy3/web-components";
import { ToastType, showErrorToast, showSuccessToast } from "contexts/apiRequestContext";
import { isEmpty } from "lodash";
import { ReactElement } from "react";

type RenderBarCodeProps = {
    barCode: string;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;

}

export const RenderBarCode = ({ barCode, setToast }: RenderBarCodeProps): ReactElement<any, any> => {
    if (isEmpty(barCode)) return <>N/D</>
    const onCopy = async () => {
        await navigator.clipboard.writeText(barCode)
            .then(() => {
                const description = "Código de barras copiado com sucesso para a sua área de transferência.";
                showSuccessToast("Sucesso", description, setToast)
            })
            .catch(() => {
                showErrorToast("Ops, houve um erro", "Não foi possível copiar o código de barras", setToast)
            })

    };
    return (
        <Stack direction='row' alignItems='center'>
            {barCode?.toString()?.substring(0, 32) + '...'}
            <IconButton onClick={onCopy}>
                <CopyIcon />
            </IconButton>
        </Stack>
    )
}