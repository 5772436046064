import styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { ReactNode } from 'react';

type FilterStyleBaseProps = {
    currentValues: ReactNode;
    formFilters: ReactNode;
}
export const FilterStyled = styled(Grid)(() => ({
    display: 'grid',
    gridTemplateColumns: 'auto 70%',
    gridGap: 20,
    marginTop: 20,
}));

export const FilterStyleBase = ({ currentValues, formFilters }: FilterStyleBaseProps) => {    
    return <>
        <FilterStyled>
            {formFilters}
        </FilterStyled>
        <Grid position='relative'>
            {currentValues}
        </Grid>
    </>
}