import { Grid, Stack } from '@mui/material';
import { Button, SaveIcon, Typography, CloseIcon } from '@uy3/web-components';
import { MfaFormField } from 'components/Forms/FormFields';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { activeTheme } from 'services/theme';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { TextFormFieldPassword } from 'components/Forms/FormFields/TextFormFieldPassword/TextFormFieldPassword';

type ConfirmationTransferDrawerProps = {
    onClose: () => void;
    toggleDrawer?: boolean
};

const theme = activeTheme();
const ConfirmMfaForm = ({ onClose, toggleDrawer = false }: ConfirmationTransferDrawerProps) => {
    const { submitError, submitting } = useApiRequest();

    return (
        <Stack spacing={2} direction="column" margin='auto' maxWidth={550}>
            <ApiErrorAlert error={submitError} />
            <Typography variant="xs" fontSize='16px' color="neutral.medium">
                Digite a sua senha
            </Typography>
            <Grid item>
                <TextFormFieldPassword
                    name="password"
                    variant="outlined"
                    label="Senha"
                    fullWidth
                />
            </Grid>

            <Stack direction="column">
                <Typography variant="lg" fontSize='18px' fontWeight={700}>
                    Confirmação de código MFA
                </Typography>
                <Typography variant="xs" fontSize='16px' mt={1.5} color="neutral.medium">
                    Informe o código que está presente no seu aplicativo MFA
                </Typography>
            </Stack>

            <Grid item>
                <MfaFormField name='code' toggleDrawer={toggleDrawer} />
            </Grid>

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={iconSx}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    name='btn-close-mfa'
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm-mfa"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={
                        <SaveIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                    sx={{ justifyContent: 'center' }}
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    );
};

export default ConfirmMfaForm;
