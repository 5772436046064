import { Snackbar as MuiSnackbar } from '@mui/material';
import { styled } from '@mui/system';

interface SnackbarProps {
  severity?: 'success' | 'error' | 'warning' | 'info';
}

export const ToastWrapper = styled(MuiSnackbar)<SnackbarProps>(({ theme, severity }) => {
  let color;
  let bgMuiLinear;
  const { palette } = theme;

  const severityColor = {
    success: {
      color: '#7BC67E',
      bgMuiLinear: palette.success?.main
    },
    error: {
      color: '#F88078',
      bgMuiLinear: palette.error?.main
    },
    warning: {
      color: '#FFB547',
      bgMuiLinear: palette.warning?.main
    },
    info: {
      color:  '#DEDCFF',
      bgMuiLinear: palette.info?.light
    }
  }

  if (severity) {
    color = severityColor[severity].color;
    bgMuiLinear = severityColor[severity].bgMuiLinear;
  } else {
    color = '#BABFD0';
    bgMuiLinear = palette.neutral?.lightest;
  }

  return {
    '& .MuiSnackbarContent-root': {
      display: 'inline-flex',
      backgroundColor: theme.palette.neutral?.lightest,
      padding: '28px 36px',
      borderRadius: '8px',
      width: '544px',
      height: 'auto',
      border: '1px solid #BABFD0'
    },
    '& .MuiLinearProgress-root': {
      height: '4px',
      borderRadius: '8px',
      backgroundColor: bgMuiLinear,
      margin: '5px'
    },
    '& .MuiLinearProgress-bar': {
      backgroundColor: color
    }
  };
});