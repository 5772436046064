import { Skeleton, Stack } from '@mui/material';
import React, { ReactElement } from 'react'

interface IListSkeleton {
    children: ReactElement<any, any>;
    isLoading: boolean;
}

export const ListSkeleton: React.FC<IListSkeleton> = (props) => {
    const { children, isLoading } = props;
    const rowsPerPage = parseInt(localStorage.getItem('rowsPerPage') || '5') ?? 5
    if (!isLoading) return children;

    const heightOptions: {[type:number]: number} = {
        5: 400, 
        10: 600, 
        15: 700, 
        20: 700
    }

    const genericSx = {
        borderRadius: '8px'
    }
    return (
        <React.Fragment>
            <Stack mr={5}>
                <Stack sx={{
                    display: "grid",
                    gridTemplateColumns: `400px auto`,
                    gap: 3,
                    mb: 2
                }}>
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height='50px'
                        sx={{...genericSx}}
                    />
                    <Skeleton
                        variant='rectangular'
                        animation='pulse'
                        width='100%'
                        height='50px'
                        sx={{...genericSx}}
                    />
                </Stack>
                <Skeleton
                    variant='rectangular'
                    animation='pulse'
                    width='100%'
                    height={heightOptions[rowsPerPage]}
                    sx={{...genericSx}}
                />
            </Stack>
        </React.Fragment>
    )
}
