import React from 'react';
import { TabBar } from 'components/TabBar/TabBar';

export const ApprovalsFormContainer: React.FC = () => {
  const tabs = [
    { label: `Solicitações de conta`, href: 'gestao-aprovacoes' },
    { label: `Internet Banking`, href: 'internet-banking' },
    { label: `Operações`, href: 'operacoes' },
    { label: 'Operadores', href: 'operadores' },
  ];
  return <TabBar tabs={tabs} />
};
