import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import DatasetWrapper from '../DatasetWrapper';
import { WebMentionsGet } from 'services/datasets/datasets.interface';
import { HandleTitle } from './TypographyAndValues';
import { useState } from 'react';
import { useFormContext } from 'contexts/formContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';

interface IWebMentions {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export const WebMentions = ({ datasetId, disableRequestDataSet }: IWebMentions) => {
    const recordType = 'NaturalPerson';
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'WebMentions');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta Menções na web${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'web_mentions') as WebMentionsGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <div>
                    <HandleTitle
                        title={'Total'}
                        value={result?.totalPassages ?? 'N/D'}
                        key="totalPassages"
                    />
                    <HandleTitle
                        title={'Ruins'}
                        value={result?.badPassages ?? 'N/D'}
                        key="badPassages"
                    />
                    <HandleTitle
                        title={'Boas'}
                        value={result?.goodPassages ?? 'N/D'}
                        key="goodPassages"
                    />
                </div>
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('WebMentions')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};