
import { Box } from "@mui/system";
import { Alert } from "@uy3/web-components";
import { AxiosError } from "axios";
import { ApiResponseError } from "contexts/apiRequestContext";
import { mapErrorResponse } from "contexts/responseErrorContext";

type ErrorProps = {
	error?: ApiResponseError | AxiosError
};

export function ApiErrorAlert(response: ErrorProps) {
	const { errorMessage } = mapErrorResponse(response.error as ApiResponseError);

	if (!response.error) return <></>;

	return <Box mt={2} mb={2}>
		<Alert
			severity="error"
			text={errorMessage}
			variant="filled"
		/>
	</Box>
};