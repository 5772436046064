import moment from "moment";
import { InferType, date, object, string } from "yup";

export function validationCreatePixKeySchema() {
  return object().shape({
    bankAccountBeneficiaryId: string().typeError("Selecione um favorecido existente ou adicione manualmente").required("Selecione um favorecido existente ou adicione manualmente"),
    transferDate: date().typeError("Data inválida.").required("Data da transferência: Precisa ser preenchido."),
    amount: string().typeError("Valor da transferência precisa ser preenchido").required("Valor da transferência: precisa ser preenchido"),
    sessionId: string().nullable(),
    code: string().nullable()
  });
}

const inferedSchema = validationCreatePixKeySchema()
export type CreatePixType = InferType<typeof inferedSchema>;

export const defaultValuesCreatePixKey = {
  bankAccountBeneficiaryId: null, 
  bankAccountBeneficiaryIdDisplay: null, 
  operationTypeValue: "Pix",
  amount: null,
  transferDateSelected: "today", 
  transferDate: moment().format('yyyy-MM-DD'),
  sessionId: "",
  code: ""
};