import { Drawer } from '@uy3/web-components';
import { useState } from 'react';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { ToastType, IUploadModel, toastState, showSuccessToast } from 'contexts/apiRequestContext';
import { useEnumContext } from 'contexts/enumContext';
import {
    defaultValuesInvolvedFundForm,
    validationSchemaInvolvedFundForm,
} from './InvolvedFundSchema';
import { usePersonsList } from 'contexts/personContext';
import InvolvedFundList from './InvolvedFundList';
import { InvolvedFundForm } from './InvolvedFundForm';
import Toast from 'components/Toast/Toast';
import { useLocation } from 'react-router';
import { useUserPermissionData } from 'contexts/userContext';
import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import { hasCustomPermission, isActionAllowed } from 'helpers';
import { useTenant } from 'contexts/tenantContext';
import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary';

export const InvolvedFundTabContainer = () => {
    const {
        status: signatureStts,
        data: signatureType,
        error: signatureErr,
    } = useEnumContext({ enumName: 'SignatureType' });
    const {
        status: SignatureValidationStts,
        data: signatureValidation,
        error: SignatureValidationErr,
    } = useEnumContext({ enumName: 'SignatureValidation', size: 50 });
    const { pathname } = useLocation();
    const typePermission = pathname.includes('novo') ? 'Create' : 'Update';
    const { hasPermission } = useUserPermissionData();
    const { isRootTenancy } = useTenant();

    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 }, 'always');
    const [toast, setToast] = useState<ToastType>(toastState);
    const [selectedInvolvedFundIndex, setSelectedInvolvedFundIndex] = useState<number | undefined>();
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { setValue, watch, readOnly } = useFormContext();

    const involveds = watch('relatedPersonFund') ?? [];
    const involvedFundSchema = validationSchemaInvolvedFundForm();

    const setNewRelatedPerson = (values: IUploadModel) => {
        let newRelatedPerson = [...involveds];
        newRelatedPerson[selectedInvolvedFundIndex!] = values;
        setValue('relatedPersonFund', newRelatedPerson);
        showSuccessToast('Lista de envolvidos atualizada com sucesso', '', setToast);
        closeDrawer();
    };

    const closeDrawer = () => setSelectedInvolvedFundIndex(undefined);
    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    function onDelete(index: number) {
        let rowData = [...involveds];
        rowData?.splice(index, 1);
        showSuccessToast('Sucesso ao excluir o envolvido!', '', setToast);
        setValue('relatedPersonFund', rowData);
    }

    const defaultFormValue =
        selectedInvolvedFundIndex !== undefined
            ? involveds[selectedInvolvedFundIndex!]
            : defaultValuesInvolvedFundForm;

    const resource = 'Fund';
    const permissionCustom = hasCustomPermission(resource, typePermission, hasPermission);
    const enableAction = isActionAllowed(Boolean(readOnly), permissionCustom, isRootTenancy);

    const status = signatureStts || SignatureValidationStts;
    const error = signatureErr || SignatureValidationErr;

    return (
        <GenericErrorBoundary status={status} error={error} fallback='envolvidos'>
            <Toast toast={toast} setToast={setToast} />
            <GenericListHeader
                title="Envolvidos"
                titleButton="Adicionar envolvido"
                enableAction={enableAction}
                onClick={() => setSelectedInvolvedFundIndex(involveds?.length)}
            />
            <InvolvedFundList
                name="relatedPersonFund"
                rowsPerPage={rowsPerPage}
                setRowsPerPage={onChangeRowsPerPage}
                setPage={onChangePage}
                page={page}
                openDrawer={(rowNumber: number | undefined) =>
                    setSelectedInvolvedFundIndex(rowNumber ?? involveds?.length)
                }
                onDelete={onDelete}
            />
            <Drawer
                anchor="right"
                title="Envolvido"
                description="Confira as informações antes de adicionar o envolvido"
                open={selectedInvolvedFundIndex !== undefined}
                onClose={closeDrawer}
            >
                <FormProvider
                    validationSchema={involvedFundSchema}
                    defaultValues={defaultFormValue}
                    onSubmit={setNewRelatedPerson}
                >
                    <InvolvedFundForm
                        name="relatedPersonFund"
                        signatureType={signatureType ?? []}
                        signatureValidation={signatureValidation ?? []}
                        onClose={closeDrawer}
                        personList={personAutoCompleteProps}
                        isLoading={false}
                    />
                </FormProvider>
            </Drawer>
        </GenericErrorBoundary>
    );
};
