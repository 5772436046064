import { Stack } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid'
import { CardData, DataTable, Typography } from '@uy3/web-components'
import { maskCurrencyInCents } from 'helpers/formats/Currency'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { IResponseForOp } from 'services/remessasVortx/remessasVortx.type'

type ResponseForOpProps = {
    response: IResponseForOp
}

export const ResponseForOp = ({ response }: ResponseForOpProps) => {
    const columns: GridColDef[] = [
        {
            field: 'valorFuturo',
            headerName: 'Valor futuro',
            hideSortIcons: true,
            editable: false,
            flex: 2,
            maxWidth: 500,
            renderCell: ({ value }) => maskCurrencyInCents(value)
        },
        {
            field: 'valorLiquidacaoCalculado',
            headerName: 'Valor liquidação calculado',
            hideSortIcons: true,
            editable: false,
            flex: 2,
            maxWidth: 500,
            renderCell: ({ value }) => maskCurrencyInCents(value)
        },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            hideSortIcons: true,
            editable: false,
            flex: 2,
            maxWidth: 500,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'dataCessao',
            headerName: 'Data cessão',
            hideSortIcons: true,
            editable: false,
            flex: 2,
            maxWidth: 500,
            renderCell: ({ value }) => value ?? "N/D"
        },
        {
            field: 'dataLiquidacao',
            headerName: 'Data liquidação',
            hideSortIcons: true,
            editable: false,
            flex: 2,
            maxWidth: 500,
            renderCell: ({ value }) => value ?? "N/D"
        },
    ];

    return (
        <Stack spacing={4} >
            <Typography variant='h5' fontWeight='bold'>Resposta liquidação antecipada</Typography>
            <CardData
                listItem={[
                    { id: '1', title: 'CPF/CNPJ', value: formatDocumentNumber(response.cpfcnpj) },
                    { id: '2', title: 'Tomador', value: response.tomador ?? "N/D" },
                    { id: '2', title: 'Número da operação', value: response.numeroOperacao ?? "N/D" },
                    { id: '3', title: 'Valor futuro', value: maskCurrencyInCents(response?.valorFuturo) },
                    { id: '4', title: 'Valor liquidação', value: maskCurrencyInCents(response.valorLiquidacao) },
                    { id: '5', title: 'Valor liquidação calculado', value: maskCurrencyInCents(response.valorLiquidacaoCalculado) },
                    { id: '6', title: 'Taxa de juros', value: `${String(response.taxaJuros.toFixed(2)).replace('.', ',')}%` },
                ]}
            />


            <DataTable
                headerBarWrapper={{
                    header: 'Parcelas'
                }}
                columns={columns}
                rows={response.parcelas ?? []}
                page={0}
                rowCount={response.parcelas?.length ?? 0}
                rowsPerPage={5}
                setPage={() => { }}
                setRowsPerPage={() => { }}
                hideFooter
                enableJumpAction={false}
            />
        </Stack>
    )
}
