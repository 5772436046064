import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { FiltersFileInstruction, GenerateCNAB400FileInstructionType, ParamsLinesTypeFromGetById } from "./fileInstruction.type";

const url: string = '/FileInstruction';

export type FileInstructionParams = {
    file: File;
    name: string;
}

export const getAllFileInstruction = async (filters: FiltersFileInstruction, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: filters
    };
    return await billingAxiosApi.get(`${url}`, config);
}

export const getFileInstructionById = async (fileInstructionId: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return await billingAxiosApi.get(`${url}/${fileInstructionId}`, config);
}

export const postFileInstructionAsync = async (params: FileInstructionParams, token?: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': `multipart/form-data`
        }
    };
    return await billingAxiosApi.post(`${url}`, params, config);
};

export const postFileInstructionValidateAsync = async (params: FileInstructionParams, token?: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': `multipart/form-data`
        }
    };
    return await billingAxiosApi.post(`${url}/Validate`, params, config);
};

export const getFileinstructionLinesById = async (fileConfirmationId: string, params: ParamsLinesTypeFromGetById, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params
    };
    return await billingAxiosApi.get(`${url}/${fileConfirmationId}/Lines`, config);
}

export const downloadFileInstructionById = async (FileInstructionId: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.get(`${url}/${FileInstructionId}/Download`, config);
}

export const generateCNAB400FileInstruction = async (payload: GenerateCNAB400FileInstructionType, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    
    return await billingAxiosApi.post(`${url}/Generate`, payload, config);
}
