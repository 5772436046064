import { FormProvider } from 'contexts/formContext'
import React, { useState } from 'react'
import GenerateCnabForm from './GenerateCnabForm'
import { validationGenerateCnabSchema, defaultValuesGenerateCnab } from './GenerateCnabFormSchema';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { generateCnab444BatchAssignmentById } from 'services/creditNote/BatchAssignment/BatchAssignment';
import { useParams } from 'react-router';
import { useIdentity } from 'contexts/identityContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';

type GenerateCnabFormContainerProps = {
    onCloseModal: () => void;
}

const GenerateCnabFormContainer = ({ onCloseModal }: GenerateCnabFormContainerProps) => {
    const { id } = useParams();
    const { token } = useIdentity();
    const { endRequest, startRequest } = useApiRequest();
    const [toast, setToast] = useState<ToastType>(toastState);

    const onSubmit = (formValues: any) => {
        startRequest();

        const payload = {
            amount: formValues.amount,
            type: formValues.type,
            mode: formValues.mode,
            isAssignmentCalculation: formValues?.assignmentCalculation
        }

        generateCnab444BatchAssignmentById(id!, payload, token!)
            .then((response: any) => {
                endRequest(true)
                const title = "CNAB gerado com com sucesso!";
                const description = undefined;
                showSuccessToast(title, description, setToast);

                window.open(response?.data?.tempUrl, "_blank");
            }).catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                endRequest(false);
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);
            }).finally(() => setTimeout(() => onCloseModal(), 2000))
    }

    const schema = validationGenerateCnabSchema();
    const defaultValues = defaultValuesGenerateCnab;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmit}
            >
                <GenerateCnabForm
                    onCloseModal={onCloseModal}
                />
            </FormProvider>
        </>
    )
}

export default GenerateCnabFormContainer
