import { FieldValues } from 'react-hook-form';
import { number, object, string } from 'yup';

export const reasonOptions = [
    {
        label: 'Erro',
        value: 'ERRO',
    },
    {
        label: 'Fraude',
        value: 'FRAUDE',
    },
    {
        label: 'Usuário de origem',
        value: 'USUARIO_ORIGEM',
    },
    {
        label: 'Erro de saque/troco',
        value: 'ERRO_SAQUE_TROCO',
    },
];

const reason = reasonOptions.map((item) => item.value);

export function validationSchemaPixRefund() {
    return object().shape({
        idPixEntry: string().nullable(),
        reason: string()
            .oneOf(reason, 'Motivo inválido.')
            .typeError('Selecione um motivo.')
            .required('Selecione um motivo.'),
        observation: string().nullable(),
        valueInCents: number()
            .required('Informe o valor a ser estornado.')
            .positive('O valor deve ser maior que zero.')
            .typeError('Informe um valor válido.'),
    });
}

export function defaultValuesPixRefund(value: FieldValues | undefined) {
    return {
        idPixEntry: value?.id,
        reason: '',
        observation: '',
        valueInCents: value?.amount,
    };
}
