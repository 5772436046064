import { IconButton, Stack } from '@mui/material';
import { Button, DeleteIcon, EditIcon, PersonIcon } from '@uy3/web-components';
import React from 'react';
import { activeTheme } from 'services/theme';
import HorizontalInfo from 'components/HorizontalInfo/HorizontalInfo';
import { iconSx } from 'contexts/apiRequestContext';
import SummaryChargesCardData from '../SummaryCharges/SummaryChargesCardData';
import { useFormContext } from 'contexts/formContext';
import InterestModelCardData from '../BillingParameters/BillingParametersForm/InterestModelCardData';
import { CustomTitle } from 'components/TitleCustom/TitleCustom';
import { GuarantorDetails } from './GuarantorForm/GuarantorDetails';
import { isEmpty } from 'lodash';

interface SummaryChargesProps {
    setAddGuarantor: React.Dispatch<React.SetStateAction<boolean>>;
    deleteGuarantorAsync: () => void;
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
export const BillingSummary: React.FC<SummaryChargesProps> = ({ setAddGuarantor, deleteGuarantorAsync }) => {
    const { getValues } = useFormContext();
    const formValues = getValues();
    const amount = formValues?.amount;
    const guarantor = formValues?.guarantor;

    const hasGuarantor = !isEmpty(guarantor?.registrationNumber) && !isEmpty(guarantor?.name);

    return (
        <Stack spacing={4}>
            <Stack justifyContent="flex-end" direction="row" alignItems='center' pt={3}>
                <Button
                    onClick={() => setAddGuarantor(true)}
                    startIcon={hasGuarantor ? <EditIcon htmlColor={colorMain} sx={iconSx} /> : <PersonIcon htmlColor={colorMain} sx={iconSx} />}
                >
                    {`${hasGuarantor ? 'Editar' : 'Adicionar'} sacador avalista`}
                </Button>
            </Stack>

            {hasGuarantor &&
                <Stack sx={{
                    display: 'grid', 
                    gridTemplateColumns: 'auto 30px', 
                    gap: 1
                }}>
                    <GuarantorDetails />

                    <IconButton onClick={deleteGuarantorAsync}>
                        <DeleteIcon htmlColor={colorMain} sx={{height: 25, width: 25}} />
                    </IconButton>
                </Stack>
            }

            <SummaryChargesCardData formValues={formValues} />

            <CustomTitle title="Informações da régua de cobrança" variant="lg" />
            <InterestModelCardData />

            <HorizontalInfo type="currency" value={amount} />
        </Stack>
    );
};
