import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { defaultValuesProductFilterschema, productFiltersSchema } from './ProductFiltersSchema'
import { ProdutFilters } from './ProdutFilters'
import { useFilters } from 'contexts/filterContext'

type ProductFiltersContainerProps = {
  typeFilter: "bankAccountProduct" | "creditProduct";
}

export const ProductFiltersContainer = ({ typeFilter }: ProductFiltersContainerProps) => {
  const { filters, addFilter, removeFilterByIndex } = useFilters();

  const onSubmitSearch = (values: any) => {
    if (values.optionFilter === "initialDate") {
      addFilter(values.optionFilter, values[values.optionFilter], new Date(values["finalDate"]));
    } else {
      addFilter(values.optionFilter, values[values.optionFilter]);
    }
  };

  const schema = productFiltersSchema();
  const defaultValues = defaultValuesProductFilterschema;

  return (
    <FormProvider
      validationSchema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmitSearch}
    >
      <ProdutFilters
        currentValues={filters}
        removeFilterSelected={removeFilterByIndex}
        typeFilter={typeFilter}
      />
    </FormProvider>
  )
}