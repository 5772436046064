import { onlyNumbers } from "helpers/validations/formFieldValidator";

export function formatBarcode(barcode: string, groupSize: number = 4, separator: string = ' '): string {
    if (!barcode) return '-';

    const cleanedBarcode =  onlyNumbers(barcode);

    // Divide o código de barras em grupos de dígitos
    const regex = new RegExp(`.{1,${groupSize}}`, 'g');
    const groups = cleanedBarcode.match(regex);

    if (!groups) {
        return barcode;
    }

    // Junta os grupos com o separador especificado
    return groups.join(separator);
}