import { Grid, Stack } from '@mui/material'
import { Typography } from '@uy3/web-components'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'

export const InformationsWallet = () => {
  return (
    <Stack>
      <Typography
        color="neutral.dark"
        sx={{
          fontSize: '23px',
          lineHeight: { xs: '24px', md: '24px' },
          fontWeight: { xs: '700', md: '700' },
          fontStyle: { xs: 'normal', md: 'normal' },
          m: "20px 0 "
        }}
      >
        Informações
      </Typography>

      <Grid container spacing={2} columns={6}>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de registro"
            name='register'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de baixa"
            name='cancellation'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de abatimento"
            name='discount'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de alteração do vencimento"
            name='changeDueDate'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de liquidação"
            name='settlement'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de protesto"
            name='debtCollection'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo sustar protesto"
            name='debtCollectionCancellation'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo liquidação cartorio"
            name='debtCollectionSettlement'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de baixa protesto"
            name='debtCollectionSuccess'
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de transferência registro"
            name="transferRegister"
            variant='outlined'
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <CurrencyFormField
            label="Custo de transferência baixa"
            name="transferCancellation"
            variant='outlined'
            fullWidth
          />
        </Grid>
      </Grid>
    </Stack>
  )
}
