import { useMutation, useQuery } from "@tanstack/react-query";
import { isAxiosError } from "axios";
import { ApiResponseError, GetViewApiResponse, useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { useTenant } from "contexts/tenantContext";
import { handleErrorUseQuery } from "helpers";
import { createComplianceCheckList, getCheckListVerifyPersonBy } from "services/creditNote/complianceChecklist/complianceChecklist";
import { ComplianceCheckListType } from "services/creditNote/complianceChecklist";

export function useComplianceChecklistVerifyPersonId(personId: string) {
    const { isRootTenancy } = useTenant();
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { status, data, error, isFetching, refetch } = useQuery({
        enabled: !!token && !!personId && personId !== 'novo' && personId !== 'nova' && isRootTenancy,
        refetchIntervalInBackground: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        queryKey: ['batchAssignment-data', personId],
        queryFn: async (): Promise<GetViewApiResponse<any>> => {
            startRequest();
            const { data, status, statusText } = await getCheckListVerifyPersonBy(personId, token!);
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }
            return data;
        },
    });

  return { status, data, error, refetchBatchAssignment: refetch, isFetching };
};


export function useCreateComplianceChecklistMutation(
    onSuccess: (data: ComplianceCheckListType) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    return useMutation({
        mutationFn: async (values: ComplianceCheckListType[]) => {
            startRequest();
            const { data } = await createComplianceCheckList(values, token!);
            endRequest(true);
            endRequest(true);
            return data as ComplianceCheckListType;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(error, setSubmitError, endRequest, onError);
        },
    });
}
