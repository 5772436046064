import { FormProvider } from 'contexts/formContext'
import { defaultValuesUsersAndGroupFilterschema, usersAndGroupSchema } from './UsersAndGroupFiltersSchema'
import { UsersAndGroupFilters } from './UsersAndGroup'
import { useFilters } from 'contexts/filterContext'

type UsersAndGroupFiltersContainerProps = {
  typeFilter: "users" | "groups"
}

export const UsersAndGroupFiltersContainer = ({ typeFilter }: UsersAndGroupFiltersContainerProps) => {
  const { filters, addFilter, removeFilterByIndex } = useFilters();

  const onSubmitSearch = (values: any) => {
    addFilter(values.optionFilter, values[values.optionFilter]);
  };

  let defaultTypeFilter = typeFilter === 'users' ? "name" : "searchString"; 
  const schema = usersAndGroupSchema();
  const defaultValues = defaultValuesUsersAndGroupFilterschema(defaultTypeFilter);

  return (
    <FormProvider
      validationSchema={schema}
      defaultValues={defaultValues}
      onSubmit={onSubmitSearch}
    >
      <UsersAndGroupFilters
        typeFilter={typeFilter}
        currentValues={filters}
        removeFilterSelected={removeFilterByIndex}
      />
    </FormProvider>
  )
}