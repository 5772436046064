import { useState } from 'react';
import { DataTable } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import DatasetWrapper from './DatasetWrapper';
import { PhonesListGet } from 'services/datasets/datasets.interface';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';

interface IPhonesListProps {
    recordType: string;
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function PhonesList({ recordType, datasetId, disableRequestDataSet }: IPhonesListProps) {
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'PhonesList');
    const { setSubmitError } = useApiRequest();


    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de Telefones ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'number',
            headerName: 'Número',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'areaCode',
            headerName: 'Cód. Área',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'countryCode',
            headerName: 'Cód. País',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'type',
            headerName: 'Tipo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'isActive',
            headerName: 'Ativo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ? 'Sim' : 'Não'}
                    </div>
                );
            },
        },
        {
            field: 'currentCarrier',
            headerName: 'Operadora',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'globalLastPassageDate',
            headerName: 'Última passagem',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && new Date(cellValues.value).toLocaleDateString('pt-BR')}
                    </div>
                );
            },
        },
    ];

    const data = dataset?.find((dataset) => dataset?.dataSet === 'phones_list') as PhonesListGet;
    const result = data?.result?.phones;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <DataTable
                loading={isLoading || status === 'loading'}
                getRowId={() => Math.random().toString()}
                columns={columns}
                rows={result ?? []}
                page={page}
                rowCount={result?.length ?? 5}
                rowsPerPage={rowsPerPage}
                setPage={onChangePage}
                setRowsPerPage={onChangeRowsPerPage}
                paginationMode="client"
            />
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('PhonesList')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};