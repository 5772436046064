import { AvatarGroup, Grid } from '@mui/material';
import { AvatarProps, Avatar as AvatarUI, Typography } from '@uy3/web-components';
import { StyledTooltip } from 'components/Custom/CustomTooltip/Tooltip';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';

interface AvatarListProps extends AvatarProps {
    abbreviatedNames: string[];
    listNames: string[];
    title?: string;
    maxAvatars?: number 
}

const theme = activeTheme();
const AvatarComponent: React.FC<AvatarListProps> = ({ abbreviatedNames, listNames, title = "Credores", maxAvatars = 3 }) => {
    const avatarSx = {
        ...iconSx,
        p: 0.7,
        color: theme.palette.common.black,
        bgcolor: '#BABFD0',
    };

    return (
        <StyledTooltip
            title={<TooltipContent listNames={listNames} title={title}/>}
            placement="bottom"
        >
            <AvatarGroup
                max={maxAvatars}
                componentsProps={{
                    additionalAvatar: { sx: avatarSx },
                }}
            >
                {abbreviatedNames.map((item, index) => (
                    <AvatarUI key={index} sx={avatarSx}>
                        {item}
                    </AvatarUI>
                ))}
            </AvatarGroup>
        </StyledTooltip>
    );
};

export default AvatarComponent;

interface TooltipContentProps {
    listNames: string[];
    title:string
}

const TooltipContent: React.FC<TooltipContentProps> = ({ listNames, title }) => {
    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" spacing={1}>
            <Grid item>
                <Typography variant="xs" textAlign="center">
                    {title}
                </Typography>
            </Grid>
            <Grid item sx={{ textAlign: 'start'}}>
                {listNames.map((creditor, index) => (
                    <Typography key={index}>{creditor}</Typography>
                ))}
            </Grid>
        </Grid>
    );
};
