import { Components } from '@mui/material';
import { Palette } from '@mui/material/styles';
import { palette as paletteOptions } from '../palette/light';

const palette = paletteOptions! as Palette;

export const MuiAvatar: Components['MuiAvatar'] = {
    styleOverrides: {
        root: {
            backgroundColor: palette.primary.light,
            color: palette.primary.main
        }
    }
}