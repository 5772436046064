import { Grid, Stack } from '@mui/material';
import { Typography, UpdateIcon } from '@uy3/web-components';
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';

interface WalletsListHeaderProps {
    refresh: () => void
}

export const WalletsListHeader: React.FC<WalletsListHeaderProps> = ({ refresh }) => {

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Grid>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="30px"
                    lineHeight="38.4px"
                >
                    Carteiras
                </Typography>
            </Grid>

            <Actions
                buttonsActionsList={[
                    {
                        enable: true,
                        label: "Atualizar",
                        action: refresh,
                        disabled: false,
                        icon: <UpdateIcon sx={iconSx} />
                    }
                ]}
                numberOfButtons={4}
            />
        </Stack>
    );
};