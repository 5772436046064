import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import { AlertProps, CardDataProps, CardInfoProps } from '../../molecules';

export const cardInfoWithValueProps: CardInfoProps = {
  title: 'Saldo disponível',
  description: 'Baseado em transações em aprovação.',
  value: '4.451,02',
  icon: <PaidOutlinedIcon />,
  color: 'primary',
};

export const cardDataProps: CardDataProps = {
  listItem: [
    {
      id: '1',
      title: 'Nome/Favorecido',
      value: 'PABLO FERREIRA',
    },
    {
      id: '2',
      title: 'Tipo de transferência',
      value: 'TED',
    },
    {
      id: '3',
      title: 'Data de transferência',
      value: '01/08/2022',
    },
    {
      id: '4',
      title: 'Banco',
      value: '260 - NU PAGAMENTOS S.A.',
    },
    {
      id: '5',
      title: 'Agência',
      value: '0001',
    },
    {
      id: '6',
      title: 'Conta',
      value: '9061194-4',
    },
  ],
};

export const alertProps: AlertProps = {
  text: 'O pagamento será feito mediante saldo em conta de acordo com os seus limites diário e mensal.',
  variant: 'filled',
  severity: 'info',
};
