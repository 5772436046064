import { Box, Typography } from '@mui/material';
import { Checkbox, CheckboxProps } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { resolve } from 'services/arrayGetters';
import { useFormFieldsError } from 'contexts/formFieldsErrors';
import { useAppConfig } from 'contexts/appConfig';
import { isFieldDisable } from 'helpers';

const theme = activeTheme();

export interface CheckboxFormFieldProps extends CheckboxProps {
    name: string;
}

export const CheckboxFormField: FunctionComponent<CheckboxFormFieldProps> = ({ ...props }) => {
    const name = props.name;
    let value = undefined;
    var { validationErrors, watch, setValue, readOnly } = useFormContext();
    const { formFieldsErrors } = useFormFieldsError();
    const { appConfig } = useAppConfig();
    const formFieldsConfig = appConfig?.FORM_FIELDS;

    const responseError = formFieldsErrors?.find(({ key }) => {
        let fieldName = name.toLowerCase();
        return fieldName.includes(key);
    });

    if (watch) {
        value = watch(name);
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.checked;
        setValue(name, newValue, { shouldDirty: true });
    };

    var error = validationErrors && resolve(name, validationErrors)?.message;
    const hasResponseError = Object.keys(responseError ?? {}).length > 0;
    const isFieldDisabledConfig = isFieldDisable(name, formFieldsConfig);

    return (
        <Box mt={'8px'}>
            <Checkbox
                onChange={onChange}
                checked={value === true}
                disabled={isFieldDisabledConfig || readOnly}
                inputProps={{
                    'aria-label': 'secondary checkbox',
                }}
                {...props}
            />
            {!!error && (
                <Typography mt={1} mb={2} variant="body1" color={theme.palette.error.dark}>
                    <>{error}</>
                </Typography>
            )}
            {!!hasResponseError && (
                <Typography mt={1} mb={2} variant="body1" color={theme.palette.error.dark}>
                    <>{responseError?.helpMessage}</>
                </Typography>
            )}
        </Box>
    );
};
