import React from 'react';
import { Button, DataTable, SearchIcon } from '@uy3/web-components';
import { Link } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { Grid } from '@mui/material';
import { GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { iconSx } from 'contexts/apiRequestContext';
import { BatchCreditNoteFiltersContainer } from 'components/DataTableFilters/BatchAssignment/BatchCreditNoteFilters/BatchCreditNoteFiltersContainer';

type OperationBatchAssigmentProps = {
    queryData: any;
    page: number;
    rowsPerPage: number;
    setPage: (page: number) => void;
    rowsSelected: string[] | GridRowId[] | undefined;
    handleSelectionModelChange: (selectionModel: GridSelectionModel) => void;
    onChangeRowsPerPage: (page: number) => void;
    personAutocompleteOptions: any;
};

export const OperationBatchAssigmentList: React.FC<OperationBatchAssigmentProps> = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    rowsSelected,
    onChangeRowsPerPage,
    handleSelectionModelChange,
}) => {
    const columns: GridColDef[] = [
        {
            field: 'creditNoteNo',
            headerName: 'Operação',
            hideSortIcons: true,
            minWidth: 120,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '4px',
                        }}
                    >
                        {`#${cellValues.value}`}
                    </div>
                );
            },
        },
        {
            field: `createdAt`,
            headerName: 'Data de início',
            hideSortIcons: true,
            flex: 1,
            maxWidth: 190,
            editable: false,
            renderCell: ({ row }) => {
                let value = row?.createdAt;
                return new Date(value).toLocaleDateString('pt-BR');
            },
        },
        {
            field: 'personDisplay',
            headerName: 'Tomador',
            hideSortIcons: true,
            minWidth: 210,
            flex: 1,
            editable: false,
        },
        {
            field: 'amortization.principalAmountInCents',
            headerName: 'Valor',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) =>
                formatCurrencyInCents(cellValues?.row?.amortization?.principalAmountInCents),
        },
        {
            field: 'amortization.liquidValue',
            headerName: 'Valor líquido',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues: any) =>
                formatCurrencyInCents(cellValues?.row?.amortization?.liquidValue),
        },
        {
            field: 'amortization.assignmentPriceValue',
            headerName: 'Valor de cessão',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => {
                let value = cellValues?.row?.assignmentValue;
                return formatCurrencyInCents(value);
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: (cellValues) => cellValues?.row?.statusDisplay,
        },
        {
            field: 'amortization.assignmentCalculation',
            headerName: 'Ágio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                let value = row?.assignmentCalculationResult;
                return formatCurrencyInCents(value);
            },
        },

        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            editable: false,
            renderCell: (cellValues: any) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Link to={`/ccb/operacoes/${cellValues.id}`}>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={<SearchIcon sx={iconSx} />}
                            />
                        </Link>
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: (
                    <Grid mb={-0.5}>
                        <BatchCreditNoteFiltersContainer />
                    </Grid>
                ),
            }}
            columns={columns}
            rows={queryData?.data ?? []}
            page={page}
            rowCount={queryData?.totalItems}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={onChangeRowsPerPage}
            checkboxSelection={true}
            keepNonExistentRowsSelected={true}
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={handleSelectionModelChange}
        />
    );
};
