import { Error } from 'components/Errors/Error';
import UsersList from './UsersList';
import { useEnableOrDisableMutation, useUserListData } from 'contexts/userContext';
import { useState } from 'react';
import { Button, Popup } from '@uy3/web-components';
import { Grid, Stack } from '@mui/material';
import { RefreshProgress } from 'components/RefreshProgress';
import { FilterProvider } from 'contexts/filterContext';
import UserHeader from './UserHeader';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useTenant } from 'contexts/tenantContext';

export function UserListContainer() {
  const { currentTenantId } = useTenant();
  const [rowsPerPage, setRowsPerPage] = useState(parseInt(localStorage.getItem('rowsPerPage')!) || 5);
  const [page, setPage] = useState(0);
  const [paginationToken, setPaginationToken] = useState<any | undefined>({ "undefined": { 0: undefined } });

  const tenantPaginationTokens = paginationToken[currentTenantId === undefined ? "undefined" : currentTenantId] ?? {};
  const { userStts, userData, userErr, isFetching, refetch } = useUserListData({
    page,
    size: rowsPerPage,
    paginationToken: tenantPaginationTokens[page],
  });
  const [rowData, setRowData] = useState<any | undefined>(undefined);
  const [toast, setToast] = useState<ToastType>(toastState);

  const onSuccess = () => {
    showSuccessToast("Usuário excluído com sucesso", "", setToast);
    refetch();
    onClosePopup();
  };

  const onError = (response: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(response);
    showErrorToast("Ops, ocorreu um erro", errorMessage, setToast);
  };

  const { mutate } = useEnableOrDisableMutation(rowData?.id, onSuccess, onError);

  if (userStts === 'error') return <Error error={userErr} />;

  const onChangeRowsPerPage = (page: number) => {
    setPaginationToken({ "undefined": { 0: undefined } });
    localStorage.setItem('rowsPerPage', JSON.stringify(page));
    setRowsPerPage(page);
  };

  const onChangePage = (newPage: number) => {
    setPaginationToken({ ...paginationToken, [currentTenantId === undefined ? "undefined" : currentTenantId]: { [newPage]: userData!.paginationToken } });
    setPage(newPage);
  };

  const onClosePopup = () => setRowData(undefined);

  return (
    <>
      <Toast toast={toast} setToast={setToast} />
      <Stack pb={3}>
        <UserHeader isFetching={isFetching} refetch={refetch} />
      </Stack>
      <RefreshProgress refreshing={isFetching} />
      <FilterProvider availableFilters={{
        name: { label: "Nome", type: "text" },
        email: { label: "Email", type: "text" },
      }}>

        <UsersList
          page={page}
          queryData={userData}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={onChangeRowsPerPage}
          setPage={onChangePage}
          setOpenPopup={setRowData}
        />
      </FilterProvider>

      <Popup
        open={rowData !== undefined}
        title={`Desabilitar ${rowData?.name ?? ""}`}
        text={`Tem certeza que deseja desabilitar esse operador?`}
        onClose={onClosePopup}
        children={
          <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
              variant="outlined"
              color="primary"
              onClick={onClosePopup}
              size="medium"
              sx={{ mt: 3, mr: 2 }}
            >
              Não, cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => mutate('DisableUser')}
              size="medium"
              sx={{ mt: 3 }}
            >
              Sim, confirmar
            </Button>
          </Grid>
        }
      />
    </>
  );
}
