export type CostsType = {
    id: string
    walletCode: number
    confirmationTransactionTypeValue: string
    confirmationTransactionTypeValueDisplay: string
    documentNumber: string
    ourNumber: string
    ourNumberDigit: string
    costs: number
    createdAt: string
}

export type CostsParamsQuery = {
    page?: number;
    size?: number;
    tenant?: string;
    intialDate?: string;
    finalDate?: string
    walletsCode?: string
    initialBillingDate?: string
    finalBillingDate?: string
    finalCreationDate?: string
    initialCreationDate?: string
    hasBillingDate?: boolean
}

export const confirmationTransactionOptions =  [
    { value: "EntradaConfirmada", label: "Entrada Confirmada" },
    { value: "EntradaRejeitada", label: "Entrada rejeitada" },
    { value: "LiquidacaoNormal", label: "Liquidação Normal" },
    { value: "BaixadoAutomaticoViaArquivo", label: "Baixado por arquivo" },
    { value: "BaixadoConformeInstrucoesAgencia", label: "Baixado" },
    { value: "AbatimentoConcedido", label: "Abatimento Concedido" },
    { value: "AbatimentoCancelado", label: "Abatimento Cancelado" },
    { value: "VencimentoAlterado", label: "Vencimento Alterado" },
    { value: "LiquidacaoCartorio", label: "Liquidação Cartorio" },
    { value: "LiquidacaoAposBaixaOuTituloNaoRegistrado", label: "Liquidação Após Baixa Ou Titulo Não Registrado" },
    { value: "ConfirmacaoRecebimentoInstrucaoProtesto", label: "Confirmacão Recebimento Instrucão Protesto" },
    { value: "ConfirmacaoRecebimentoInstrucaoSustacaoProtesto", label: "Confirmação Recebimento Instrução Sustacao Protesto" },
    { value: "AcertoControleParticipante", label: "Acerto Controle Participante" },
    { value: "EntradaTituloCartorio", label: "Entrada Titulo Cartorio" },
    { value: "EntradaRejeitadaCepIrregular", label: "Entrada Rejeitada Cep Irregular" },
    { value: "ConfirmacaoRecebimentoInstrucaoProtestoFalimentar", label: "Confirmação Recebimento Instrução Protesto Falimentar" },
    { value: "BaixaRejeitada", label: "Baixa Rejeitada" },
    { value: "DebitoTarifasCustas", label: "Debito Tarifas Custas" },
    { value: "InstrucaoRejeitada", label: "Instrucao Rejeitada" },
    { value: "ConfirmacaoPedidoAlteracaoOutrosDados", label: "Confirmação Pedido Alteração Outros Dados" },
    { value: "RetiradoCartorioManutencaoCarteira", label: "Retirado Cartorio Manutenção Carteira" }
];