import { FormProvider } from "contexts/formContext";
import { MfaSetupFormDefaultValues, MfaSetupFormValidationSchema } from "./MfaSetupFormSchema";
import { MfaSetupForm } from "./MfaSetupForm";
import { FailureResponse } from "services/identity";
import { useEffect } from "react";
import { Alert } from "@uy3/web-components";
import { useIdentity } from "contexts/identityContext";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/system";


export function MfaSetupFormContainer() {

	const { authFlow, confirmMfaSetup, mfaSecret, navigateToFlow, submitError } = useIdentity();

	const navigate = useNavigate();

	const schema = MfaSetupFormValidationSchema();
	const defaultValues = MfaSetupFormDefaultValues;

	const submit = async (values: any) => await confirmMfaSetup(values.code);
	const error = (values: FailureResponse) => console.log("MfaSetupFormContainer - onError", values);

	const code = mfaSecret();

	useEffect(() => {
		if (!authFlow || authFlow?.type === "mfaSetup") {
			console.log("AuthFlow não encontrado ou etapa incorreta");
			navigate('/login');
		}
	}, [authFlow, navigate]);

	useEffect(() => {
		navigateToFlow(navigate);
	}, [authFlow, navigate, navigateToFlow]);

	return <>

		{submitError && <Box mt={2} mb={2}>
			<Alert
				severity="error"
				text={submitError}
				variant="filled"
				color="error"
			/>
		</Box>}
		<FormProvider
			validationSchema={schema}
			defaultValues={defaultValues}
			onSubmit={submit}
			onError={error}>
			<MfaSetupForm code={code} />
		</FormProvider>
	</>
}