import { IconButton, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, DeleteIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { PayrollAgreementReadModel } from 'services/payrollAgreement/payrollAgreement.type';

type PayrollAgreementsListProps = {
    queryData: PayrollAgreementReadModel[]; 
    handleDeleteItem: (id: number) => void; 
    
}

export const PayrollAgreementsList: React.FC<PayrollAgreementsListProps> = ({queryData, handleDeleteItem}) => {

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Convênio',
            hideSortIcons: true,
            minWidth: 120,
            flex: 2,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: (cellValues) => {
                const rowIndex = queryData?.findIndex(
                    (row: PayrollAgreementReadModel) => cellValues?.row?.id === row?.id
                );
                return (
                    <Stack
                        direction='row'
                        justifyContent="center"
                        alignItems="center"
                    >
                        <IconButton name='btn-delete' onClick={() => handleDeleteItem(rowIndex)}>
                            <DeleteIcon sx={iconSx} />
                        </IconButton>
                    </Stack>
                );
            }
        },
    ];
    return (
        <DataTable
            columns={columns}
            rowsPerPage={5}
            rows={queryData ?? []}
            pageCount={0}
            page={1}
            setPage={() => {}}
            setRowsPerPage={() => {}}
            rowCount={queryData?.length || 0}
            enableJumpAction={false}
        />
    )
}
