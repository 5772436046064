import LiquidationScheduleClosureForm from './LiquidationScheduleClosureForm';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesClosure, validationSchemaClosure } from './LiquidationScheduleClosureSchema';
import { useLiquidationScheduleClosure } from 'contexts/liquidationSchedule';
import { ApiResponseError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { Button } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { useCosifData } from 'contexts/creditProductContext';

type TLiquidationScheduleClosure = {
    creditNoteId: string;
    onErrorCatch: (error: ApiResponseError) => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    navigateWithFilters: (path: string) => void;
    handleOnClose: () => void;
};

export const LiquidationScheduleClosure = ({
    creditNoteId,
    onErrorCatch,
    setToast,
    navigateWithFilters,
    handleOnClose,
}: TLiquidationScheduleClosure) => {
    const { getCosif } = useCosifData();

    const onSuccess = () => {
        showSuccessToast('Conta transitória encerrada com sucesso', undefined, setToast);
        navigateWithFilters(`/ccb/operacoes/${creditNoteId}`);
        handleOnClose();
    };

    const { mutateClosure } = useLiquidationScheduleClosure(creditNoteId, onSuccess, onErrorCatch);

    return (
        <FormProvider
            validationSchema={validationSchemaClosure()}
            defaultValues={defaultValuesClosure}
            onSubmit={mutateClosure}
        >
            <>
                <LiquidationScheduleClosureForm cosifData={getCosif} />
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOnClose}
                        size="medium"
                        sx={{ mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button type="submit" variant="contained" color="primary" size="medium">
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};
