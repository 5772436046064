import { Grid } from "@mui/material";
import { Typography } from "@uy3/web-components";
import { TextFormField } from "components/Forms/FormFields";
import { useParams } from "react-router";


const InformationGroupTab = () => {
    const { groupId } = useParams();
    const isNew = groupId === 'novo';

    return (
        <>
            <Typography
                variant="h5"
                color="common.black"
            >
                Dados do Grupo
            </Typography>
            <br />
            <br />
            <Grid container spacing={5} columns={10}>
                <Grid item xs={5}>
                    <TextFormField
                        name="groupName"
                        label="Nome"
                        placeholder='Nome do grupo'
                        variant="outlined"
                        fullWidth
                        disabled={!isNew}
                        required />
                </Grid>
                <Grid item xs={5}>
                    <TextFormField
                        name="precedence"
                        label="Precedência"
                        placeholder='Precedência'
                        variant="outlined"
                        fullWidth
                        type="number"
                        inputProps={{ min: 0, max: 100 }}
                    />
                </Grid>
                <Grid item xs={10}>
                    <TextFormField
                        name="description"
                        label="Descrição"
                        variant="outlined"
                        fullWidth
                        type="text"
                        inputProps={{ min: 0, max: 100 }}
                        multiline
                        rows={4}
                    />
                </Grid>
            </Grid>
        </>
    )
};

export default InformationGroupTab;