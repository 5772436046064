import { FormProvider } from "contexts/formContext";
import { loginFormDefaultValues, loginFormValidationSchema } from "./LoginFormSchema";
import { LoginForm } from "./LoginForm";
import { useEffect } from "react";
import { Alert } from "@uy3/web-components";
import { useIdentity } from "contexts/identityContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEmail } from "contexts/emailContext";
import { FieldValues } from "react-hook-form";

export function LoginFormContainer() {

	let [searchParams] = useSearchParams();
	const { isAuthenticated, signIn, authFlow, navigateToFlow, submitError } = useIdentity();
	const navigate = useNavigate();
	const { setEmail } = useEmail();

	const authenticated = isAuthenticated();
	const schema = loginFormValidationSchema();
	const defaultValues = loginFormDefaultValues;

	const submit = async (values: FieldValues) => {
		setEmail(values.email);
		await signIn(values.email, values.password);
	};

	useEffect(() => {
		console.log("LoginFormContainer - authFlow atualizado: " + authFlow?.type);
		navigateToFlow(navigate);
	}, [authFlow, navigate, navigateToFlow]);

	useEffect(() => {
		if (authenticated && !searchParams.has('force')) {
			console.log("LoginFormContainer - usuário logado, redirecionando para home");
			navigate('/');
		}
	}, [authenticated, navigate, searchParams]);

	const error = (values: any) => console.log("LoginFormContainer - onError", values);

	return <>
		{submitError && <Alert
			severity="error"
			text={submitError}
			variant="filled"
		/>}
		<FormProvider
			validationSchema={schema}
			defaultValues={defaultValues}
			onSubmit={submit}
			onError={error}>
			<LoginForm />
		</FormProvider>
	</>
}