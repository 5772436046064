import { Grid } from "@mui/material";
import { formatCurrencyInCents } from "helpers/formats/Currency";
import { isEmpty } from 'lodash';
import { HandleTitle } from "../DatasetNaturalPerson/TypographyAndValues";

export function ProcessDetails({ rowData }: { rowData: any }) {
    const process = rowData;

    return (
        <Grid spacing={4} mt={1}>
            <HandleTitle title={'Número'} value={process.number ?? 'N/D'} key='number' />
            <HandleTitle title={'Tipo'} value={process.type ?? 'N/D'} key='type' />
            <HandleTitle title={'Valor'} value={formatCurrencyInCents(process.value * 100) ?? 'N/D'} key='value' />
            <HandleTitle title={'Assunto'} value={process.mainSubject ?? 'N/D'} key='mainSubject' />
            <HandleTitle title={'Tribunal'} value={process.courtName ?? 'N/D'} key='courtName' />
            <HandleTitle title={'Instância'} value={process.courtLevel ?? 'N/D'} key='courtLevel' />
            <HandleTitle title={'Distrito do tribunal'} value={isEmpty(process.courtDistrict) ? 'N/D' : process.courtDistrict} key='courtDistrict' />
            <HandleTitle title={'Vara do tribunal'} value={process.judgingBody ?? 'N/D'} key='judgingBody' />
            <HandleTitle title={'Status'} value={process.status ?? 'N/D'} key='status' />
            <HandleTitle title={'Estado'} value={process.state ?? 'N/D'} key='state' />
            <HandleTitle title={'Volumes'} value={process.numberOfVolumes ?? 'N/D'} key='numberOfVolumes' />
            <HandleTitle title={'Páginas'} value={process.numberOfPages ?? 'N/D'} key='numberOfPages' />
            <HandleTitle title={'Partes'} value={process.numberOfParties ?? 'N/D'} key='numberOfParties' />
            <HandleTitle title={'Movimentações'} value={process.numberOfUpdates ?? 'N/D'} key='numberOfUpdates' />
            <HandleTitle title={'Idade do processo em dias'} value={process.lawSuitAge ?? 'N/D'} key='lawSuitAge' />
            <HandleTitle title={'Valor total da causa extraído do processo'} value={formatCurrencyInCents(process.value * 100) ?? 'N/D'} key='value' />
            <HandleTitle title={'Data de publicação'} value={process.publicationDate ? new Date(process.publicationDate).toLocaleDateString('pt-BR') : 'N/D'} key='publicationDate' />
            <HandleTitle title={'Data de notificação das partes do processo'} value={process.noticeDate ? new Date(process.noticeDate).toLocaleDateString('pt-BR') : 'N/D'} key='noticeDate' />
            <HandleTitle title={'Data da úlitma movimentação'} value={process.lastMovementDate ? new Date(process.lastMovementDate).toLocaleDateString('pt-BR') : 'N/D'} key='lastMovementDate' />
            <HandleTitle title={'Data da captura inicial do processo'} value={process.captureDate ? new Date(process.captureDate).toLocaleDateString('pt-BR') : 'N/D'} key='captureDate' />
            <HandleTitle title={'Data da última atualização dos dados'} value={process.lastUpdate ? new Date(process.lastUpdate).toLocaleDateString('pt-BR') : 'N/D'} key='lastUpdate' />
        </Grid>
    );
};
