import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { BillingFiltersProps, IFiltersTags, IBillingUpdatePatch, IBillingCrate } from './billing.types';
import { paramsFilterBillingList } from 'helpers/methods/ParamsFilterBilling';

var url = `/Billing`;
export const getBillingList = async (filters: BillingFiltersProps, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: paramsFilterBillingList(filters)
    };

    return await billingAxiosApi.get(url, config);
};

export async function createBillingAsync(data: IBillingCrate,token: string, idempotence?: string) {
    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
        params: { idempotence }
    };
    return await billingAxiosApi.post(url, data, axiosConfig);
}

export async function getBillingByIdAsync(chargesId: string, token = '') {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await billingAxiosApi.get(`${url}/${chargesId}`, config);
}

export async function getTagsByWalletCode(params: IFiltersTags, token: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params
    };

    return await billingAxiosApi.get(`${url}/Tags`, config);
}

export async function registerBillingAsync(chargesId: string, token: string) {
    const recordType = `${url}/${chargesId}/Register`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await billingAxiosApi.post(recordType, {}, axiosConfig);
}

export async function updateBillingByPatch(chargesId: string, payload: IBillingUpdatePatch, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await billingAxiosApi.patch(`${url}/${chargesId}`, payload, config)
}

export const updateCategoryBillingByBillingId = async (billingId: string, tags: string[], token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.patch(`Billing/${billingId}`, { tags }, config);
}

export const cancelBillingBId = async (billingId: string, isPaid: boolean = false,  token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`Billing/${billingId}/Cancel`, {isPaid}, config);
}

export const sendEmailBillingById = async (billingId: string, token?: string) => {
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`Billing/${billingId}/SendEmail`, {}, config);
}

export const downloadBatchByBillingList = async (billingIds: string[], token?: string) => {
    const config = {
        responseType: 'blob' as 'blob',
        headers: { Authorization: `Bearer ${token}` }
    };

    return await billingAxiosApi.post(`/BankSlip/DownloadBatch`, {billingIds}, config);
}

export const exportBillingsAsCsvAsync = async (token: string, params?: BillingFiltersProps) => {
    const config = {
        responseType: 'blob' as 'blob',
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };

    return await billingAxiosApi.get(`${url}/Export`, config);
}

export const exportBillingsAsCnabAsync = async (token: string, params?: BillingFiltersProps) => {
    const config = {
        responseType: 'blob' as 'blob',
        headers: { Authorization: `Bearer ${token}` }, 
        params
    };

    return await billingAxiosApi.get(`${url}/ExportAsCNAB`, config);
}
