import { Stack, useMediaQuery } from "@mui/material";
import { Button, CloseIcon, OperatorIcon } from "@uy3/web-components";
import { useFormContext } from "contexts/formContext";
import { activeTheme } from "services/theme";
import AutocompleteField from "components/Forms/FormFields/AutocompleteField/AutocompleteField";
import { AutocompleteOptionList } from "components/Forms/Autocomplete";
import { iconSx } from "contexts/apiRequestContext";
export interface IAddGroupFormProps {
    onClose: () => void;
    groupAutoCompleteProps: AutocompleteOptionList;
};

const theme = activeTheme();
const AddGroupForm = ({ onClose, groupAutoCompleteProps }: IAddGroupFormProps) => {
    const { submitting } = useFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Stack spacing={2}>
            <AutocompleteField
                name="groupName"
                label="Grupo"
                displayName="groupNameDisplay"
                {...groupAutoCompleteProps}
            />
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CloseIcon htmlColor={theme?.palette?.primary?.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<OperatorIcon htmlColor={theme?.palette?.primary?.contrastText} sx={iconSx}/>}
                    variant="contained"
                    size="medium"
                    disabled={submitting}
                    fullWidth={isMobile}
                >
                    Adicionar
                </Button>
            </Stack>
        </Stack>
    );
}

export default AddGroupForm;