import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

interface CustomGridProps {
  borderColor?: string;
}

export const CustomGrid = styled(Grid)<CustomGridProps>(({ theme, borderColor }) => ({
  borderRadius: '8px',
  padding: '16px',
  justifyContent: 'space-between',
  alignItems: 'center',
  border: '1px solid',
  borderColor: borderColor || theme.palette.primary.main,
  cursor: 'pointer',
  '&:hover': {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
  },
}));
