import { IconButton, Stack } from '@mui/material';
import { CloseIcon, SettingIcon, Typography } from '@uy3/web-components';
import { ReadNotificationsHeaderBox } from '../Notifications.styled';
import { TooltipComponent } from 'components/Tooltip/Tooltop';

export type ReadNotificationsHeaderProps = {
    onClose: () => void;
};

export const ReadNotificationsHeader = ({ onClose }: ReadNotificationsHeaderProps) => {

    return (
        <ReadNotificationsHeaderBox spacing={2}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontWeight="700" fontStyle="normal" letterSpacing="-0.2px" fontSize="24px">
                    Notificações
                </Typography>

                <Stack direction="row" alignItems="center" justifyContent="start">
                    
                    <TooltipComponent title="Em breve" placement='top'>
                        <IconButton onClick={() => {}}>
                            <SettingIcon sx={{ height: 26, width: 26 }} />
                        </IconButton>
                    </TooltipComponent>

                    <TooltipComponent title="Fechar" placement='top'>
                        <IconButton onClick={onClose}>
                            <CloseIcon sx={{ height: 24, width: 24 }} />
                        </IconButton>
                    </TooltipComponent>

                </Stack>
            </Stack>
        </ReadNotificationsHeaderBox>
    );
};
