import { BankIcon, CardValue } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { activeTheme } from 'services/theme';
import { BillingFull } from 'services/walletManagement/billing/billing.types';

interface CardValueBaseProps {
    rowData?: BillingFull
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
const CardValueBase: React.FC<CardValueBaseProps> = ({ rowData }) => {
    const { getValues } = useFormContext();
    const formValues = getValues();

    const name = formValues?.payer?.name ?? rowData?.payer?.name ?? 'N/D';
    const walletCode = formValues?.walletCode ?? rowData?.walletCode ?? 'N/D';

    return (
        <CardValue
            color="primary"
            icon={<BankIcon htmlColor={colorMain} />}
            title="Dados do devedor"
            description={`Titular: ${name} | Convênio: ${walletCode}`}
        />
    );
};
export default CardValueBase;
