import React from 'react';
import { Grid, Stack } from '@mui/material'
import { DatePickerFormField, SelectFormField, SelectOption, TextFormField } from 'components/Forms/FormFields';
import { Button } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';

type ApproveRevisionFormFormProps = {
    onCloseModal: () => void;
    cosifDataOptions?: SelectOption[];
    isLoading: boolean;
}

export const ApproveRevisionForm = ({ onCloseModal, cosifDataOptions, isLoading }: ApproveRevisionFormFormProps) => {    
    return (
        <Stack spacing={2} mt={2}>
            <Grid >
                <SelectFormField
                    name='cosifAccountCode'
                    label='Conta de crédito'
                    required
                    options={cosifDataOptions}
                />
            </Grid>
            <Grid>
                <DatePickerFormField
                    label='Data do crédito'
                    shrink
                    name='creditDate'
                    required={true}
                    fullWidth
                />
            </Grid>
            <Grid mt={2}>
                <CurrencyFormField
                    fullWidth
                    label='Valor da liquidação'
                    name='liquidationValue'
                    variant='outlined'
                />
            </Grid>
            <Grid item>
                <TextFormField
                    variant='outlined'
                    fullWidth
                    type="text"
                    name="observations"
                    label="Observações"
                    margin="dense"
                    multiline={true}
                    rows={4}
                />
            </Grid>
            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCloseModal}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={isLoading}
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    )
}
