import { string, object } from 'yup';

export function MfaSetupFormValidationSchema() {
	return object().shape({
		code: string().typeError('Campo obrigatório')
			.required('Campo obrigatório.')
			.min(6, "Deve possuir 6 dígitos")
			.max(6, "Deve possuir 6 dígitos"),
	});
}

export const MfaSetupFormDefaultValues = {
	code: "",
};