import { Stack } from '@mui/material';
import { Suspense, lazy, useState } from 'react';
import { ApiResponseError, ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { GenericQueryParamsDash } from 'services/walletManagement/dashboard/types/queryParams.types';
import { useTotalCharges } from 'contexts/wallet/dashboard/totalChargesContext';
import { useOverdueCharges } from 'contexts/wallet/dashboard/overdueChargesContext';
import { ErrorBox } from 'components/Errors/ErrorBox/ErrorBox';
import { useBillingWalletRegisteredDash } from 'contexts/wallet/dashboard/billingWalletRegisteredContext';
import { applyFilterByPeriod } from 'helpers/formats/DateFormat';
import moment from 'moment';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';

// Using dynamic import (lazy) to load components asynchronously.
// This is beneficial for improving the initial performance of the application,
// loading only the necessary components at the appropriate time.

const DashboardHeader = lazy(() => import('./DashboardHeader/DashboardHeader'));
const DashboardOverview = lazy(() => import('../DashboardOverview/DashboardOverview'));
const WalletInfoSection = lazy(() => import('../WalletInfoSection/WalletInfoSection'));

export const DashboardContainer = () => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const getDateToday = applyFilterByPeriod(0);

    const [params, setParams] = useState<GenericQueryParamsDash>({
        walletsCode: undefined,
        initialDate: getDateToday.initialDate,
        finalDate: getDateToday.finalDate
    });

    const {
        data: chargesData,
        error: chargesError,
        status: chargesStatus, 
        refetch: chargesRefetch
    } = useTotalCharges(params, 'always');

    const {
        data: dueChargesData,
        status: dueChargesStatus,
        error: dueChargeError,
        isLoading: isLoadingDashCharges,
        refetch: dueDateRefetch
    } = useOverdueCharges({
        walletsCode: params.walletsCode
    });

    const {
        data: billingWalletRegisteredData,
        status: billingWalletRegisteredStatus,
        error: billingWalletRegisteredError,
        refetch: billingWalletRefetch
    } = useBillingWalletRegisteredDash({
        walletsCode: params.walletsCode
    });

    const formatedLastRefetch = () => {
        const date = moment(chargesData?.lastRefetch).calendar();
        return date[0].toLowerCase() + date.slice(1)
    }

    const countWallet = params?.walletsCode?.length ?? 0;
    const viewingWalletsMessage: string = countWallet === 0 ? "todas" : countWallet?.toString();

    const Loading = () => <GenericFormSkeleton isLoading={true}><></></GenericFormSkeleton>

    return (
        <Stack spacing={2} mb={6}>
            <Toast toast={toast} setToast={setToast} />
            <ErrorBox error={chargesError as ApiResponseError} resource='Painel de Carteiras' height={230} status={'success'}>
                <Suspense fallback={<Loading />}>
                    <DashboardHeader {...{ 
                        setToast, 
                        setParams, 
                        lastRefetch: formatedLastRefetch()
                    }} />
                </Suspense>

            </ErrorBox>

            <ErrorBox
                error={chargesError as ApiResponseError}
                resource='Painel de Carteiras'
                status={chargesStatus}
                refreshByResource={chargesRefetch}
            >
                <Suspense fallback={<></>}>
                    <Stack mb={3}>
                        <DashboardOverview
                            {...{
                                params, 
                                setParams,
                                setToast,
                                chargesData: chargesData!,
                                viewingWalletsMessage,
                                isLoadingDashCharges
                            }}
                        />
                    </Stack>
                </Suspense>
            </ErrorBox>

            <Suspense fallback={<></>}>
                <WalletInfoSection
                    {...{
                        dueChargesData,
                        dueChargeError,
                        dueChargesStatus,
                        billingWalletRegisteredStatus,
                        billingWalletRegisteredError,
                        billingWalletRegisteredData,
                        refreshBillingWallet: billingWalletRefetch,
                        refreshDueDate: dueDateRefetch, 
                        params
                    }}
                />
            </Suspense>
        </Stack>
    );
};
