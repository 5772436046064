import React, { Fragment, useState } from 'react';
import BillingParametersFormWrapper from './BillingParametersForm/BillingParametersFormWrapper';
import DrawerManageInterestModelContainer from './AddInterestModel/DrawerManageInterestModelContainer';
import { ApiResponseError, handleOnError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useBillingParametersList } from 'contexts/wallet/billingParameters/billingParametersContext';
import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import { useCommunicationSettingList, useCreateCommunicationSettingMudataion } from 'contexts/wallet/communicationSetting/communicationSettingContext';
import { CommunicationSettingFormContainer } from 'pages/WalletManagement/CommunicationSetting/Drawers/CommunicationSettingForm/CommunicationSettingFormContainer';
import { defaultValuesCommunicationSetting, validationSchemaCommunicationSetting } from 'pages/WalletManagement/CommunicationSetting/Drawers/CommunicationSettingForm/CommunicationSettingFormSchema';
import { ICreateCommunicationSetting } from 'services/walletManagement/communicationSetting/communicationSetting.types';
import { isEmpty } from 'lodash';
import { FieldValues } from 'react-hook-form';
import { isValidString } from 'helpers/formats/StringFormats';
import { DrawerSkeleton } from 'components/Skeleton/DrawerSkeleton';

interface BillingParametersContainerProps {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

const BillingParametersContainer: React.FC<BillingParametersContainerProps> = ({ setToast }) => {
    const { watch, setValue } = useFormContext();
    const [actionStep, setActionStep] = useState<string | undefined>(undefined);
    const walletsCode = watch('walletCode');
    const { data: billingSettingData, error, refetch, isLoading } = useBillingParametersList({ page: 0, size: 10, walletsCode }, false);
    const { communicationSettingAutocomplete } = useCommunicationSettingList({ walletsCode });

    const onClose = () => setActionStep(undefined);

    const onSuccess = (response: FieldValues, type: string) => {
        let message = "";

        if (type === 'communicationSetting') {
            message = 'Sucesso ao criar régua de comunicação';
            setValue('communicationSettingId', response?.id!);
            setValue('communicationSettingDisplay', `(${response?.walletCode}) - ${response?.name}`);
        }

        if (!isEmpty(message)) showSuccessToast(message, '', setToast);

        return onClose();
    };

    const onError = (error: ApiResponseError) => handleOnError(error, setToast)

    const { mutateAsync: createCommunicationSettingMutateAsync } =
        useCreateCommunicationSettingMudataion(
            (data) => onSuccess(data, 'communicationSetting'),
            onError
        );

    const createCommunicationSetting = async (values: ICreateCommunicationSetting) => {
        await createCommunicationSettingMutateAsync(values);
    };

    if (billingSettingData?.type === 'error') return <Error erro={error} />


    return (
        <DrawerSkeleton isLoading={isLoading}>
            <Fragment>
                <RefreshProgress refreshing={isLoading} />
                <BillingParametersFormWrapper
                    {...{
                        setAction: setActionStep,
                        billingSettingData: billingSettingData?.data ?? [],
                        refetchParametrs: refetch,
                        communicationSettingAutocomplete
                    }}
                />

                <DrawerManageInterestModelContainer {...{
                    addInterestModel: actionStep === 'add-billing-setting',
                    onClose,
                    setToast,
                    refetchParametrs: refetch,
                    billingSettingData: billingSettingData?.data ?? [],
                }} />

                <CommunicationSettingFormContainer {...{
                    title: "Nova régua de comunicação",
                    onClose,
                    onSubmit: createCommunicationSetting,
                    open: actionStep === 'add-communication-setting',
                    toggleDrawer: true,
                    schema: {
                        defaultValues: defaultValuesCommunicationSetting({ walletCode: walletsCode }),
                        validation: validationSchemaCommunicationSetting()
                    },
                    disableWalletCodeField: isValidString(String(walletsCode))
                }} />

            </Fragment>
        </DrawerSkeleton>
    );
};

export default BillingParametersContainer;
