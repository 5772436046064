import { Stack } from '@mui/material'
import { Button, LeftIcon, SaveIcon } from '@uy3/web-components';
import { DatePickerFormField, SelectFormField, SelectOption } from 'components/Forms/FormFields';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

type SignatureCreditNoteProps = {
  signersList: SelectOption[];
  onClose: () => void;
}

const theme = activeTheme();

export const SignatureForm: React.FC<SignatureCreditNoteProps> = ({ signersList, onClose }) => {
  const { submitting} = useApiRequest();

  return (
    <Stack spacing={2}>
      <SelectFormField
        name='signers'
        label='Quem deve receber o documento para assinatura?'
        multiple
        options={signersList}
      />
      <SelectFormField
        label="Certificadora"
        name="signaturePortal"
        fullWidth
        options={[
          { label: "RBM", value: "RBM" },
          { label: "ClickSign", value: "ClickSign" },
          { label: "QuickSoft", value: "QuickSoft"  },
          { label: "UnicoCheck", value: "UnicoCheck" },
        ]}
      />
      <DatePickerFormField
        label="Data limite para assinar o arquivo"
        name="deadLine"
        fullWidth
        required
      />

      <Stack direction='row' alignItems='center' justifyContent='right' gap={2}>
        <Button
          startIcon={
            <LeftIcon
              htmlColor={theme.palette.primary.main}
              sx={iconSx}
            />
          }
          variant="outlined"
          size="medium"
          onClick={onClose}
        >
          Voltar
        </Button>
        <Button
          name="btn-submit-upload"
          type="submit"
          variant="contained"
          disabled={submitting}
          startIcon={
            <SaveIcon
              htmlColor={theme.palette.common.white}
              sx={iconSx}
            />
          }
          sx={{ justifyContent: 'center' }}
        >
          Salvar
        </Button>
      </Stack>
    </Stack>
  )
}
