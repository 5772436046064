/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ApproveLiquidationScheduleWrapperForm from './Form/ApproveLiquidationScheduleWrapperForm';
import { FormProvider, useFormContext } from 'contexts/formContext';
import {
    defaultValuesApproveLiquidationScheduleSchema,
    validationApproveLiquidationScheduleSchema,
} from './ApproveLiquidationScheduleSchema';
import { ApiResponseError, showSuccessToast, ToastType } from 'contexts/apiRequestContext';
import { useCosifData } from 'contexts/creditProductContext';
import { Box, Grid } from '@mui/material';
import { Button } from '@uy3/web-components';
import {
    useApproveLiquidationSchedule,
    useLiquidationScheduleList,
} from 'contexts/liquidationSchedule';
import { ApprovalLiquidationScheduleCreateModel } from 'services/LiquidationSchedule/types/ApprovalLiquidationScheduleRequest';
import { isFinishedAndUnlinked } from 'services/LiquidationSchedule';

type ApproveLiquidationProps = {
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    onModalClose: () => void;
    creditNoteId: string;
    onErrorResponse: (error: ApiResponseError) => void;
    navigateWithFilters: (path: string) => void;
};

export function ApproveLiquidationScheduleContainer({
    setToast,
    onModalClose,
    creditNoteId,
    onErrorResponse,
    navigateWithFilters,
}: ApproveLiquidationProps) {
    const { getCosif } = useCosifData();
    const { liquidationScheduleList } = useLiquidationScheduleList(creditNoteId, {
        page: 0,
        size: 100,
    });
    const { getValues } = useFormContext();
    const hasTransitoryAccount: boolean = !!getValues('transitoryBankAccountId');
    const [hasExternalId, setHasExternalId] = useState(false);
    const [hasPrincipalExternalId, setHasPrincipalExternalId] = useState(false);

    const handleMutationSuccess = async () => {
        const successTitle = 'Roteiro de liquidação aprovado com sucesso';
        const successDescription = 'Ótimo! Agora você seguirá para a próxima etapa.';
        showSuccessToast(successTitle, successDescription, setToast);
        onModalClose();
        navigateWithFilters('/ccb/operacoes');
    };

    const { mutateAsync } = useApproveLiquidationSchedule(
        creditNoteId,
        handleMutationSuccess,
        onErrorResponse
    );

    const handleOnSubmit = (values: ApprovalLiquidationScheduleCreateModel) => {
        const hasCosifAccountCode = !!getCosif?.find((i) => i.value === values?.cosifAccountCode)?.externalAccountId;
        if (hasCosifAccountCode) {
            values = {
                ...values, 
                liquidationSchedule: values?.liquidationSchedule?.map((object: any) => {
                    delete object?.cosifAccountCode;
                    delete object?.cosifAccountCodeDisplay;
                    return object;
                })
            }
        }
        mutateAsync(values); 
    };

    const hasUnlinkedTransfer: boolean = liquidationScheduleList.some(
        (i) => i.type === 'TransferTransitoryBankAccount' && isFinishedAndUnlinked(i)
    );

    const hasCosifAccountOfTransitory = !!liquidationScheduleList.find(
        (i) => i.type === 'TransferTransitoryBankAccount' && isFinishedAndUnlinked(i)
    )?.cosifAccountCode;

    useEffect(() => {
        if (hasCosifAccountOfTransitory && !hasExternalId) {
            setHasExternalId(hasCosifAccountOfTransitory);
        }
    }, [hasCosifAccountOfTransitory]);

    const validationSchema = validationApproveLiquidationScheduleSchema(
        hasTransitoryAccount,
        hasUnlinkedTransfer,
        hasExternalId,
        hasPrincipalExternalId
    );

    const liquidationScheduleLiked = liquidationScheduleList
        .filter((l) => l.status === 'New' && l.linkStatus === 'Linked')
        .map((i) => i);

    return (
        <Box mt={-5}>
            <FormProvider
                onSubmit={handleOnSubmit}
                validationSchema={validationSchema}
                defaultValues={defaultValuesApproveLiquidationScheduleSchema}
                onChangeField={[
                    {
                        fieldName: 'cosifAccountCode',
                        delegate: (value, setValue, watch) => {
                            const externalAccountId = getCosif?.find(
                                (i) => i.value === value
                            )?.externalAccountId;
                            setHasExternalId(!!externalAccountId);

                            const selectedAutomatic =
                                watch!('transitoryBankAccountAction') === 'AutomaticLiquidation';

                            if (externalAccountId && selectedAutomatic) {
                                setValue('transitoryBankAccountAction', null);
                            }
                        },
                    },
                ]}
            >
                <>
                    <ApproveLiquidationScheduleWrapperForm
                        {...{
                            cosifList: getCosif ?? [],
                            liquidationScheduleList: liquidationScheduleLiked,
                            hasTransitoryAccount,
                            hasUnlinkedTransfer,
                            hasCosifAccountOfTransitory,
                            setHasPrincipalExternalId
                        }}
                    />
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onModalClose}
                            size="medium"
                            sx={{ mr: 2 }}
                        >
                            Não, cancelar
                        </Button>
                        <Button variant="contained" color="primary" type="submit" size="medium">
                            Sim, confirmar
                        </Button>
                    </Grid>
                </>
            </FormProvider>
        </Box>
    );
}
