import { Stack } from '@mui/material'
import { Button, CompleteIcon, Typography } from '@uy3/web-components'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'
import { activeTheme } from 'services/theme'

type GenerateFileLiquidationHeaderProps = {
    isLoading: boolean
}

const theme = activeTheme(); 

export const GenerateFileLiquidationHeader = ({ isLoading }: GenerateFileLiquidationHeaderProps) => {
    return (

        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Gerar arquivo liqudação vortx
            </Typography>

            <Button
                variant='text'
                size='large'
                type='submit'
                disabled={isLoading}
                sx={{ color: theme.palette.common.black }}
                startIcon={<CompleteIcon sx={iconSx} htmlColor={isLoading ? 'grey' : theme.palette.common.black} />}
            >
                Executar
            </Button>
        </Stack>
    )
}
