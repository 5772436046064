import { UF } from 'services/zipCode/zipcode.types';
import NaturalPersonInformationTab from './NaturalPersonInformationTab';
import { useLegalPersonListData } from 'contexts/legalPersonContext';
import { useEconomicActivityCodeListData } from 'contexts/economicActivityCode';
import React, { useState } from 'react';
import { ApiResponseError, ToastType, toastState } from 'contexts/apiRequestContext';
import { IQueryBasicData } from 'contexts/personContext';
import { handleSubmitQueryData, queryBasicDataPersonMethods } from 'helpers/methods/queryBasicDataPerson';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import Toast from 'components/Toast/Toast';
import { SelectOptionsDrawerContainer } from 'components/QueryBasicData/SelectOptions/SelectOptionsContainer';

export const NaturalPersonInformationTabContainer = () => {
  const [toast, setToast] = useState<ToastType>(toastState);
  const [enableStep, setEnableStep] = useState<boolean>(false);
  const { token } = useIdentity();
  const [queryData, setQueryData] = useState<IQueryBasicData | undefined>(undefined);
  const { setValue } = useFormContext();
  const onClose = () => setEnableStep(false)

  const { legalPersonAutoCompleteProps, data: legalPersonData } = useLegalPersonListData({ page: 0, size: 15 }, 'always');
  const { autoCompleteProps: economicActvCodeCompleteProps } = useEconomicActivityCodeListData();


  const { handleSetValuesPerson, handleSaveValuesByDrawer } = queryBasicDataPersonMethods(setValue, 'NaturalPerson', onClose);

  const onSuccess = (queryData: IQueryBasicData, registrationNumber: string) => {
    setQueryData(queryData);
    setValue('registrationNumber', registrationNumber);

    if (queryData.addresses?.length > 1 || queryData.emails?.length > 1 || queryData.phones.length > 1) {
      return setEnableStep(true);
    }

    handleSetValuesPerson(queryData);
  }

  const onError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error as ApiResponseError);
    setToast({
      open: true,
      severity: 'error',
      title: 'Ops, ocorreu um erro!',
      description: errorMessage
    });
  };

  const handleOnSubmitByChangeField = async (registrationNumber: string) => {
    await handleSubmitQueryData(
      registrationNumber,
      token!,
      (data) => onSuccess(data, registrationNumber),
      onError
    );
  };


  return (
    <React.Fragment>
      <Toast toast={toast} setToast={setToast} />

      <NaturalPersonInformationTab
        UFList={UF}
        setToast={setToast}
        legalPersonAutoCompleteProps={legalPersonAutoCompleteProps}
        legalPersonData={legalPersonData}
        cnaeList={economicActvCodeCompleteProps}
        handleOnSubmitByChangeField={handleOnSubmitByChangeField}
      />

      <SelectOptionsDrawerContainer
        enable={enableStep}
        onClose={() => setEnableStep(false)}
        onSubmit={(formValues) => handleSaveValuesByDrawer(formValues, queryData!)}
        queryData={queryData!}
        title={queryData?.naturalPerson?.name ?? "Pessoa física"}

      />
    </React.Fragment>
  );
};
