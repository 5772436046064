import { Box, IconButton, Stack } from '@mui/material';
import { Theme, styled } from '@mui/material/styles';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';

export const MainBox = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: '24px 24px 0 24px',
  background: theme.palette.common.white,
  borderRadius: '8px',
}));

export const StyledSearchField = styled(GridToolbarQuickFilter)(({ theme }) => ({
  width: '50%',
  '& .MuiInputBase-input': {
    ...theme.typography.xs,
    padding: '14px',
    height: '56px',
    boxSizing: 'border-box',
    color: theme.palette.neutral.dark,
    '&::placeholder': {
      ...theme.typography.xs,
      color: theme.palette.neutral.medium,
      opacity: 1,
    },
  },
  '& .MuiSvgIcon-root': {
    fontSize: '20px',
    color: theme.palette.neutral.medium,
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '20px',
  },
}));

export const StyledDataGrid = styled(DataGrid)(({ theme }) => {
  const color = theme.palette.neutral.dark;
  const { fontSize } = theme.typography.xs!;
  return {
    '& .MuiDataGrid-row': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiDataGrid-row .Mui-selected, & .MuiDataGrid-row:nth-child(2n).Mui-selected': {
      backgroundColor: 'rgba(208, 77, 39, 0.08)',
    },
    '& .MuiDataGrid-cell': {
      fontSize,
      lineHeight: '16.48px',
      fontWeight: 400,
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontSize,
      fontWeight: 700,
      whiteSpace: 'normal',
      lineHeight: 'normal',
      wordWrap: 'break-word',
    },
    '& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-sortIcon': {
      color,
    },
    '& .MuiDataGrid-columnHeaderCheckbox .MuiSvgIcon-root, & .MuiDataGrid-cellCheckbox, & .MuiSvgIcon-root':
    {
      fontSize: '22px',
    },
    '& .MuiTablePagination-selectLabel,&  .css-aglid1-MuiTablePagination-displayedRows': {
      fontSize,
      color: theme.palette.neutral.medium,
    },
    '& .css-aglid1-MuiTablePagination-displayedRows': {
      marginRight: 23
    },
    '& .MuiTablePagination-select': {
      fontSize,
      marginTop: '10px',
    },
    '& .MuiSelect-icon': {
      color: theme.palette.neutral.medium,
      height: '24px',
      width: '24px',
      paddingBottom: '2px',
    },
    '& .MuiDataGrid-virtualScrollerRenderZone': {
      marginTop: '0',
    },
  };
});

export const ArrowButtonLeft = styled(IconButton)(() => ({
  position: 'absolute', 
  bottom: 5.8, 
  right: 4*27
}));

export const ArrowButtonRight = styled(IconButton)(
  () => ({
  position: 'absolute', 
  bottom: 5.8, 
  left: "calc(100% - 70px)"
}));

export const CustomStack = styled(Stack)`
    border-radius: 8px;
    border: 1px solid var(--neutral-light, #babfd0);
    padding: 24px;
    padding-bottom: 0;
    margin-right: 24px;
    position: relative;
`;