import type { FunctionComponent } from 'react';
import type { AlertProps } from './Alert.interface';
import { Alert as MuiAlert, useTheme } from '@mui/material';

export const Alert: FunctionComponent<AlertProps> = ({ text, ...props }) => {
  const theme = useTheme();
  return (
    <MuiAlert {...props} sx={{ fontSize: theme.typography.sm }}>
      {text}
    </MuiAlert>
  );
};
