import GenericErrorBoundary from 'components/Errors/ErrorBoundary/GenericErrorBoundary'
import { GenericFormSkeletonSuspense } from 'components/Skeleton/GenericFormSkeleton'
import Toast from 'components/Toast/Toast'
import { ApiResponseError, handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext'
import { useCreateInputSettingsMutation, useDeleteInputSettingsMutation, useGetInputSettingsById, useUpdateInputSettingsMutation } from 'contexts/chatbot/inputSettingsContext'
import { FormProvider } from 'contexts/formContext'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { validationChatbotSchema, defaultValuesChatbot } from './ChatbotFormSchema'
import ChatbotHeaderForm from './ChatbotHeaderForm'
import { ChatForm } from './ChatForm'
import { useGetServiceQueueList } from 'contexts/chatbot/serviceQueueContext'
import { useTenant } from 'contexts/tenantContext'
import { useCreditProdutList } from 'contexts/creditProductContext'
import { IInputSettingsReadModel, IInputSettingsUpdateModel } from 'services/chatbot/types'
import { RefreshProgress } from 'components/RefreshProgress'
import { usePersonsList } from 'contexts/personContext'
import { FieldValues } from 'react-hook-form'
import { isEmpty } from 'lodash'
import { Drawer, Modal } from '@uy3/web-components'
import { GenerateLink } from '../GenerateLink/GenerateLink'
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler'
import { Stack } from '@mui/material'
import { useIdentity } from 'contexts/identityContext'
import { getNaturalPersonById, NaturalPersonReadModel } from 'services/accounts/naturalPerson'
import { getLegalPersonById } from 'services/accounts/legalPerson'
import { LegalPersonReadModel } from 'services/accounts/legalPerson/types/legalPersonReadModel'
import { isValidString } from 'helpers'
import { CreditProductReadModel } from 'services/creditProduct'

export const ChatbotFormContainer = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [action, setAction] = useState<string | undefined>(undefined);
    const isUpdateMode = id !== 'novo'
    const { token } = useIdentity();
    const { selectedTenant } = useTenant();
    const { tenantAutoCompleteProps } = useTenant();
    const { serviceQueueAutocomplete } = useGetServiceQueueList();
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10, }, 'always');
    const { status, data: queryData, error, isLoading: isLoadinfInputSetting, refetch } = useGetInputSettingsById(id!);
    const { creditProductAutoCompleteProps } = useCreditProdutList({ page: 0, size: 10 }, 'always');

    const onClose = () => setAction(undefined);

    const onSuccess = (response: IInputSettingsReadModel, action: string) => {
        const options: { [type: string]: string } = {
            "delete": "Sucesso ao excluir o registro",
            "create": "Dados de entrada adicionado com sucesso",
            "update": "Sucesso ao atualizar os dados de entrada"
        }
        showSuccessToast(options[action]!, undefined, setToast);
        if (action === 'create') {
            return navigate(`/chatbot/${response?.id}`);
        };

        if (action === 'delete') {
            return navigate(`/chatbot`);
        }

        refetch();
    };

    const getPersonByDiscriminator = async (personId: string, discriminator: string) => {
        if (!isValidString(discriminator)) return;
        if (discriminator === 'NaturalPerson') {
            try {
                const { data } = await getNaturalPersonById(personId, token!);
                return data as NaturalPersonReadModel;
            } catch (error) {
                if (!!personId) {
                    handleOnError(error as ApiResponseError, setToast)
                }
            }
        }
        else {
            try {
                const { data } = await getLegalPersonById(personId, token!);
                return data as LegalPersonReadModel;
            } catch (error) {
                if (!!personId) {
                    handleOnError(error as ApiResponseError, setToast)
                }
            }
        }
    }

    const onError = (response: ApiResponseError) => handleOnError(response, setToast);

    const { mutateAsync, isLoading } = useCreateInputSettingsMutation((data) => onSuccess(data, 'create'), onError);
    const { mutateAsync: updateAsync, isLoading: isLoadingUpdate } = useUpdateInputSettingsMutation(id!, (data) => onSuccess(data, 'update'), onError);
    const { mutateAsync: deleteMutateAsync, isLoading: isLoadingDelete } = useDeleteInputSettingsMutation((data) => onSuccess(data, 'delete'), onError);

    function validateObjectProperties(obj: Record<string, any>): boolean {
        if (Object.keys(obj).length === 0) {
            return false;
        }
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
                return false;
            }
        }
        return true;
    }

    const onSubmit = async (values: FieldValues) => {
        const product = values?.product as CreditProductReadModel; 

        let payload: IInputSettingsUpdateModel = {
            botPhone: values?.botPhone,
            companyName: values?.companyName,
            incomingMessage: values?.incomingMessage,
            invoiceValue: values?.invoiceValue,
            isDefault: values?.isDefault,
            originCampaign: values?.originCampaign,
            productCategoryName: product?.categoryName ?? product?.amortizationType,
            productId: values?.productId,
            productName: product?.name,
            serviceQueueId: values?.serviceQueueId,
            tenant: selectedTenant?.value as string | undefined ?? queryData?.tenant,
            tenantName: selectedTenant?.label ?? queryData?.tenantName,
            beneficiary: !isEmpty(values?.beneficiary?.personId) ? {
                ...values?.beneficiary,
                bankAccountId: values?.bankAccountId,
                bankAccountName: values?.bankAccountIdName ?? values?.bankAccountIdDisplay
            } : null,
            person: values?.person
        }

        if (!validateObjectProperties(payload.beneficiary ?? {})) {
            payload = {
                ...payload,
                beneficiary: null
            };
        }

        if (!validateObjectProperties(payload.person ?? {})) {
            payload = {
                ...payload,
                person: null
            };
        }

        if (!!isUpdateMode) {
            return await updateAsync(payload);
        }

        return await mutateAsync(payload)
    };

    if (isLoadinfInputSetting && !!isUpdateMode)
        return <GenericFormSkeletonSuspense />;


    const getDefaultValues = () => {
        if (!isUpdateMode) return defaultValuesChatbot;
        return {
            ...queryData,
            personId: queryData?.person?.personId,
            personName: queryData?.person?.name,
            beneficiaryId: queryData?.beneficiary?.personId,
            beneficiaryName: queryData?.beneficiary?.name,
            bankAccountId: queryData?.beneficiary?.bankAccountId,
            bankAccountIdDisplay: queryData?.beneficiary?.bankAccountName
        }
    };
    return (
        <GenericErrorBoundary
            fallback="menu pessoa fisica"
            status={status}
            error={error}
        >
            <React.Fragment>
                <Toast toast={toast} setToast={setToast} />
                <RefreshProgress refreshing={isLoading || isLoadingUpdate || isLoadingDelete} />
                <FormProvider
                    defaultValues={getDefaultValues()}
                    validationSchema={validationChatbotSchema()}
                    onSubmit={onSubmit}
                    readOnly={!!queryData?.used}
                >
                    <>
                        <ChatbotHeaderForm
                            isNew={!isUpdateMode}
                            refetch={refetch}
                            handleAction={(action) => setAction(action)}
                        />
                        <ChatForm
                            productList={creditProductAutoCompleteProps}
                            tenatnList={tenantAutoCompleteProps}
                            serviceQueueList={serviceQueueAutocomplete}
                            personList={personAutoCompleteProps}
                            getPersonByDiscriminator={getPersonByDiscriminator}
                        />
                    </>
                </FormProvider>
            </React.Fragment>

            <Drawer {...{
                anchor: 'right',
                open: action === 'waQRCode',
                title: "Entre em contato via WhatsApp",
                onClose: onClose,
                children: <GenerateLink onClose={onClose} inputSetting={queryData as IInputSettingsReadModel} setToast={setToast} />
            }} />

            <Modal {...{
                open: action === 'delete',
                align: 'left',
                title: 'Excluir',
                description: 'Tem certeza que deseja excluir esse registro?',
                children: (
                    <Stack mt={2}>
                        <GenericActionHandler
                            handleSubmit={() => deleteMutateAsync(queryData?.id!)}
                            onClose={onClose}
                            isModal
                        />
                    </Stack>
                )
            }} />

        </GenericErrorBoundary>
    )
}
