import React from 'react';
import { PaidOutlined } from '@mui/icons-material';
import { Stack } from '@mui/system';
import { Alert, Button, CardValue, CloseIcon, RightIcon, Typography } from '@uy3/web-components';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useApiRequest } from 'contexts/apiRequestContext';
import { useShowValues } from 'contexts/showValuesContext';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { activeTheme } from 'services/theme';
import ViewTransactionalLimitsDrawer from '../Tabs/ViewTransactionalLimitsDrawer';

type ConfirmInformationDrawerProps = {
    setStep: (step: 'showInfoOfPayment' | 'confirmationPayment' | 'confirmationMfa') => void;
    open: boolean;
    CardInfoComponent: React.ReactNode;
    onClose: () => void;
    dataBalance?: any;
    valueOfOperation: number;
};

const ConfirmInformationDrawer = ({
    setStep,
    onClose,
    dataBalance,
    CardInfoComponent,
    valueOfOperation,
}: ConfirmInformationDrawerProps) => {
    const { showValues } = useShowValues();
    const { submitError } = useApiRequest();
    const theme = activeTheme();

    return (
        <Stack spacing={2}>
            <CardValue
                sx={{ width: '100%' }}
                color="primary"
                description="Baseado em trasações em aprovação."
                icon={<PaidOutlined />}
                title="Saldo disponível"
                value={
                    showValues
                        ? formatCurrencyInCents(dataBalance?.available).toString()
                        : '------------'
                }
            />

            {CardInfoComponent}

            <Stack direction="row" spacing={2}>
                <Alert
                    severity="info"
                    text="O pagamento será feito mediante saldo em conta de acordo com os seus limites diário e mensal."
                    variant="filled"
                />
            </Stack>

            <Stack justifyContent="flex-end" alignItems="flex-end" spacing={2}>
                <ViewTransactionalLimitsDrawer />
            </Stack>

            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                <Typography variant="lg" fontWeight="700">
                    Total da transferência
                </Typography>
                <Typography variant="lg" color={theme.palette.primary.main} fontWeight="700">
                    {formatCurrencyInCents(valueOfOperation)}
                </Typography>
            </Stack>

            {submitError && <ApiErrorAlert error={submitError} />}

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 17, width: 20 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-confirm"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 19, width: 20 }}
                        />
                    }
                    variant="contained"
                    size="medium"
                    onClick={() => {
                        setStep('confirmationMfa');
                    }}
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    );
};

export default ConfirmInformationDrawer;
