import { Box } from "@mui/material";
import { TextField, TextFieldProps, Typography } from "@uy3/web-components";
import { FunctionComponent } from "react";
import { useFormContext } from "contexts/formContext";
import { activeTheme } from "services/theme";
import { resolve } from "services/arrayGetters";

const theme = activeTheme();

export interface TextFormFieldProps extends TextFieldProps {
    name: string;
};

export const TimeTextField: FunctionComponent<TextFormFieldProps> = ({ ...props }) => {

    const name = props.name;
    let value = undefined;
    var { validationErrors, watch, setValue, readOnly } = useFormContext();
    if (watch) {
        const time = watch(name);
        //Time pode ser uma string ou number, sendo string quando o campo é preenchido e number quando valor vem do banco de dados.
        if (String(time).includes(':')) {
            const [hours, minutes] = time.split(':');
            value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        } else if (time) {
            const hours = Math.floor(time / 100);
            const minutes = time % 100;
            value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
        };
    };

    var error = validationErrors && resolve(name, validationErrors)?.message;

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue(name, newValue, { shouldDirty: true })
    };

    return <Box width='100%'>
        <TextField
            error={!!error}
            disabled={readOnly}
            onChange={onChange}
            value={value}
            {...props} />
        {!!error && <Typography
            variant="body1"
            mt={0.5}
            color={theme.palette.error.dark}>
            <>{error}</>
        </Typography>}
    </Box>;
};