import { Components } from '@mui/material';

export const MuiCard: Components['MuiCard'] = {
    styleOverrides: {
        root: {
            padding: '24px',
            '& .MuiPaper-root': {
                backgroundColor: '#f6f6f6',
                padding: '22px',
                '& svg': {
                    color: '#a1a1a1'
                }
            },
            '& .MuiCardContent-root': {
                margin: 0,
                padding: '0 0 0 16px',
                '&:last-child': {
                    paddingBottom: 0,
                }
            }
        }
    }
}