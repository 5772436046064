import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const CallLoveIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M14.4258 6.1573L15.9383 8.04783C16.7389 9.04864 18.2611 9.04864 19.0617 8.04783L20.5742 6.1573C20.8498 5.81273 21 5.38459 21 4.94332V4.85616C21 3.83103 20.169 3 19.1438 3C18.6516 3 18.1794 3.19556 17.8313 3.54366L17.5 3.875L17.1687 3.54366C16.8206 3.19556 16.3484 3 15.8562 3C14.831 3 14 3.83103 14 4.85616V4.94332C14 5.38459 14.1502 5.81273 14.4258 6.1573Z"
          stroke={htmlColor}
           strokeWidth="1.7"
          strokeLinejoin="round"
        />
        <path
          d="M21 19V17.3541C21 16.5363 20.5021 15.8008 19.7428 15.4971L17.7086 14.6835C16.7429 14.2971 15.6422 14.7156 15.177 15.646L15 16C15 16 12.5 15.5 10.5 13.5C8.5 11.5 8 9 8 9L8.35402 8.82299C9.28438 8.35781 9.70285 7.25714 9.31654 6.29136L8.50289 4.25722C8.19916 3.4979 7.46374 3 6.64593 3H5C3.89543 3 3 3.89543 3 5C3 13.8366 10.1634 21 19 21C20.1046 21 21 20.1046 21 19Z"
          stroke={htmlColor}
           strokeWidth="1.7"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

CallLoveIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
