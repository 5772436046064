import { Grid, Stack } from '@mui/material';
import { Button } from '@uy3/web-components';
import { TextFormField } from 'components/Forms/FormFields';

type TerminateProps = {
    onClosePopup: () => void;
};

const TerminateForm = ({ onClosePopup }: TerminateProps) => {
    return (
        <Stack spacing={2}>
            <TextFormField
                variant="outlined"
                fullWidth
                type="text"
                name="reason"
                label="Motivo do encerramento"
                margin="dense"
                multiline
                rows={2}
                required
            />

            <Grid container direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClosePopup}
                    size="medium"
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    );
};

export default TerminateForm;
