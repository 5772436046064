import { Stack } from '@mui/material'
import { DownloadIcon, InvoiceReceivableIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import { iconSx } from 'contexts/apiRequestContext'

type FileInstructionHeaderProps = {
    refresh: () => void;
    handleGenerateCNAB400: () => void;
    setHandleAction: React.Dispatch<React.SetStateAction<{
        action: string;
        data: any;
    } | undefined>>
}

export default function FileInstructionHeader({ refresh, setHandleAction, handleGenerateCNAB400 }: FileInstructionHeaderProps) {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Arquivos remessa
            </Typography>

            <Stack>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Importar arquivo CNAB",
                            action: () => {
                                setHandleAction({
                                    action: "generate-cnab", 
                                    data: null
                                })
                            },
                            disabled: false,
                            icon: <DownloadIcon sx={iconSx} />
                        }, 
                        {
                            enable: true,
                            label: "Gerar CNAB 400",
                            action: handleGenerateCNAB400,
                            disabled: false,
                            icon: <InvoiceReceivableIcon sx={iconSx} />
                        }, 
                        {
                            enable: true,
                            label: "Atualizar",
                            action: refresh,
                            disabled: false,
                            icon: <UpdateIcon sx={iconSx} />
                        }, 
                    ]}
                />
            </Stack>
        </Stack>
    )
}
