import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const VoiceMailIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.4697 19.4697C10.1768 19.7626 10.1768 20.2374 10.4697 20.5303C10.7626 20.8232 11.2374 20.8232 11.5303 20.5303L10.4697 19.4697ZM20.5303 11.5303C20.8232 11.2374 20.8232 10.7626 20.5303 10.4697C20.2374 10.1768 19.7626 10.1768 19.4697 10.4697L20.5303 11.5303ZM11.25 17C11.25 19.3472 9.34721 21.25 7 21.25V22.75C10.1756 22.75 12.75 20.1756 12.75 17H11.25ZM7 21.25C4.65279 21.25 2.75 19.3472 2.75 17H1.25C1.25 20.1756 3.82436 22.75 7 22.75V21.25ZM2.75 17C2.75 14.6528 4.65279 12.75 7 12.75V11.25C3.82436 11.25 1.25 13.8244 1.25 17H2.75ZM7 12.75C9.34721 12.75 11.25 14.6528 11.25 17H12.75C12.75 13.8244 10.1756 11.25 7 11.25V12.75ZM21.25 7C21.25 9.34721 19.3472 11.25 17 11.25V12.75C20.1756 12.75 22.75 10.1756 22.75 7H21.25ZM17 11.25C14.6528 11.25 12.75 9.34721 12.75 7H11.25C11.25 10.1756 13.8244 12.75 17 12.75V11.25ZM12.75 7C12.75 4.65279 14.6528 2.75 17 2.75V1.25C13.8244 1.25 11.25 3.82436 11.25 7H12.75ZM17 2.75C19.3472 2.75 21.25 4.65279 21.25 7H22.75C22.75 3.82436 20.1756 1.25 17 1.25V2.75ZM11.5303 20.5303L20.5303 11.5303L19.4697 10.4697L10.4697 19.4697L11.5303 20.5303Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

VoiceMailIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
