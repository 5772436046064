import { Stack } from '@mui/material';
import { Button, CloseIcon, Popup, SaveIcon } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    iconSx,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import React from 'react';
import {
    cancelOperatorAndFavored,
    deleteOperatorByBankAccountId,
} from 'services/accounts/bankAccount/bankAccount';
import { ParamsCancel } from 'services/accounts/bankAccount';
import { activeTheme } from 'services/theme';

interface PopupOperatorProps {
    stepOperator: string;
    handleResponseError: (error: ApiResponseError) => void;
    bankAccountId: string | undefined;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    userId: any;
    operatorListRefetch: () => void;
    onClose: () => void;
}

const theme = activeTheme();
const PopupOperator: React.FC<PopupOperatorProps> = ({
    stepOperator,
    handleResponseError,
    bankAccountId,
    setToast,
    userId,
    operatorListRefetch,
    onClose,
}) => {
    const { startRequest, endRequest } = useApiRequest();
    const { token } = useIdentity();

    const onDeleteOperator = async () => {
        startRequest();
        const operadorId = userId?.id;
        deleteOperatorByBankAccountId(bankAccountId!, operadorId, token!)
            .then(() => {
                operatorListRefetch();
                endRequest(true);
                onClose();
                const title = 'Operador excluído com sucesso!';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    const onCancelOperatorAndFavored = async () => {
        startRequest();
        const data = {
            bankAccountId,
            beneficiaryId: userId!,
            path: 'Operator',
        } as ParamsCancel;
        cancelOperatorAndFavored(data, token!)
            .then(() => {
                operatorListRefetch();
                endRequest(true);
                onClose();
                const title = 'Ótimo! Sucesso ao cancelar o operador.';
                const description = undefined;
                showSuccessToast(title, description, setToast);
            })
            .catch((response: ApiResponseError) => handleResponseError(response));
    };

    const generateButtons = (onClickAction = () => { }) => (
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3}>
            <Button
                startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                name="btn-close-popup"
                variant="outlined"
                size="medium"
                onClick={onClose}
            >
                Fechar
            </Button>
            <Button
                name="btn-confirm-popup"
                onClick={onClickAction}
                variant="contained"
                startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                sx={{ justifyContent: 'center' }}
            >
                Confirmar
            </Button>
        </Stack>
    );

    const buttonsDelete = () => generateButtons(onDeleteOperator);
    const buttonsCancel = () => generateButtons(onCancelOperatorAndFavored);

    const popupForm: { [key: string]: JSX.Element } = {
        confirmMfaCancel: buttonsCancel(),
        confirmMfaDelete: buttonsDelete(),
    };

    const popupTitle: { [key: string]: string } = {
        confirmMfaCancel: 'Cancelar operador',
        confirmMfaDelete: 'Remover operador',
    };
    const title = popupTitle[stepOperator];

    const popupText: { [key: string]: string } = {
        confirmMfaCancel: 'Tem certeza que deseja cancelar esse registro?',
        confirmMfaDelete: 'Tem certeza que deseja remover esse registro?',
    };
    const text = popupText[stepOperator];

    const poppupOpen: { [key: string]: boolean } = {
        confirmMfaCancel: true,
        confirmMfaDelete: true,
    };
    const open = poppupOpen[stepOperator];

    const form = popupForm[stepOperator];

    return (
        <>
            {!!stepOperator ? (
                <Popup title={title} open={open} onClose={onClose} text={text}>
                    {form}
                </Popup>
            ): <></>}
        </>
    );
};

export default PopupOperator;
