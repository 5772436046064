import { Helmet } from "react-helmet";
import { useAppConfig } from "./appConfig";

export interface IHelmetContextContextProps {
    children: JSX.Element | JSX.Element[];
};

const HelmetContext = () => {
    const { appConfig } = useAppConfig();

    return (
        <Helmet>
            <title>{`${appConfig.MAIN_TITLE} - Backoffice`}</title>
            <link rel="icon" href={appConfig.MAIN_FAVICON} />
        </Helmet>
    );
};

export default HelmetContext;