import { lazy, Suspense } from 'react';
import { Stack } from '@mui/material';
const LiquidationScheduleContainer = lazy(() => import('./LiquidationSchedule/LiquidationScheduleContainer'));
const SelectAccountContainer = lazy(() => import('./SelectAccount/SelectAccountContainer'));

export function BankAccountContainer() {
    return (
        <Stack spacing={5}>
            <Suspense fallback={null}>
                <SelectAccountContainer />
                <LiquidationScheduleContainer />
            </Suspense>
        </Stack>
    );
}
