import React, { FC, useState } from 'react'
import { TransferReceiptPdfForm } from './TransferReceiptPdfForm'
import { FormProvider, useFormContext } from 'contexts/formContext'
import { defaultValuesTransferReceiptPdfForm, validationSchemaTransferReceiptPdfForm } from './TransferReceiptPdfFormSchema'
import { useCreditProductData } from 'contexts/creditProductContext'
import { FieldValues } from 'react-hook-form'
import { useTransferReceiptPdfMutation } from 'contexts/creditNote/creditContext'
import { useParams } from 'react-router'
import { ApiResponseError, ToastType, showErrorToast, useApiRequest } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'

type TransferReceiptPdfFormContainerProps = {
  setToast: React.Dispatch<React.SetStateAction<ToastType>>;
  onClose: () => void;
  creditNoteRefetch: () => void;
}

export const TransferReceiptPdfFormContainer: FC<TransferReceiptPdfFormContainerProps> = ({
  setToast,
  onClose,
  creditNoteRefetch
}) => {
  const { id } = useParams();
  const { watch } = useFormContext();
  const productId = watch('productId');
  const { endRequest } = useApiRequest();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const { creditProductData } = useCreditProductData(productId!);
  const hasOpenBankAccount = !!creditProductData?.openBankAccount!;

  const onSuccess = () => {
    setToast({
      title: "Sucesso ao gerar comprovantes",
      open: true,
      severity: 'success'
    });
    onClose();
    creditNoteRefetch();
  }
  const onError = (response: ApiResponseError) => {
    endRequest(false);
    const { errorMessage } = mapErrorResponse(response);
    const title = 'Ops, ocorreu um erro!';
    const description = errorMessage;
    showErrorToast(title, description, setToast);
  }

  const { mutate } = useTransferReceiptPdfMutation(id!, onSuccess, onError);

  function onSubmit({ generateStatement, generateRecepit }: FieldValues) {
    if (generateStatement === false && generateRecepit === false) {
      setErrorMessage("Selecione ao menos uma das opções");
      return
    };
    if (!hasOpenBankAccount && !generateRecepit) {
      setErrorMessage("Comprovante de transferência precisa ser selecionado");
      return
    }
    setErrorMessage(undefined);
    mutate({ generateRecepit, generateStatement })
  }

  return (
    <>
      <FormProvider
        defaultValues={defaultValuesTransferReceiptPdfForm(hasOpenBankAccount)}
        validationSchema={validationSchemaTransferReceiptPdfForm(hasOpenBankAccount)}
        onSubmit={onSubmit}
      >
        <TransferReceiptPdfForm
          onClose={onClose}
          hasOpenBankAccount={hasOpenBankAccount}
          errorMessage={errorMessage}
        />
      </FormProvider>
    </>
  )
}

