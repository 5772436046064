import { Grid, Stack } from '@mui/material'
import { Button, CloseIcon, SaveIcon, SettingIcon } from '@uy3/web-components'
import { TextFormField } from 'components/Forms/FormFields'
import { UploadDropForm } from 'components/UploadDrop/UploadDrop'
import { iconSx } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import { activeTheme } from 'services/theme'
import { FileInstructionParams } from 'services/walletManagement/files/fileInstruction/fileInstruction'

const theme = activeTheme();

type UploadFileInstructionProps = {
  onClose: () => void;
  handleValidateFile: (values: FileInstructionParams) => void
}

export const UploadFileInstruction = ({ onClose, handleValidateFile }: UploadFileInstructionProps) => {
  const { setValue, watch } = useFormContext();
  const enableActionToValidate = !!watch('file'); 

  const handleChangeMenu = (data: File) => {
    setValue('file', data);
    setValue('name', data?.name);
  }

  return (
    <Stack spacing={2}>
      <Grid>
        <UploadDropForm
          handleFormValues={handleChangeMenu}
          accept='image/*,.pdf,.doc,.docx,.xlsx,.xsl,.rar,.zip,.rem'
        />
      </Grid>
      <Grid>
        <TextFormField
          label='Nome'
          name='name'
          variant='outlined'
          InputLabelProps={{
            shrink: !!watch('name')
          }}
        />
      </Grid>

      <Stack direction='row' alignItems='center' justifyContent='right' spacing={2}>
        <Button
          onClick={onClose}
          variant='outlined'
          startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
        >
          Fechar
        </Button>
        <Button
          variant='contained'
          disabled={!enableActionToValidate}
          onClick={() => handleValidateFile({file: watch('file'), name: watch('name')})}
          startIcon={<SettingIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
        >
          Validar arquivo
        </Button>
        <Button
          variant='contained'
          disabled={!enableActionToValidate}
          type='submit'
          startIcon={<SaveIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
        >
          Confirmar
        </Button>
      </Stack>
    </Stack>
  )
}
