import { Button, HideIcon, ShowIcon } from '@uy3/web-components';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import { useShowValues } from 'contexts/showValuesContext';
import { activeTheme } from 'services/theme';

const theme = activeTheme();
const ShowValuesButton = () => {
    const { showValues, setShowValues } = useShowValues();
    const { submitting } = useApiRequest();
    const color = !submitting ? theme.palette.common.black : theme.palette?.grey['400'];

    return (
        <>
            <Button
                onClick={() => {
                    setShowValues(!showValues);
                }}
                disabled={submitting}
                color="inherit"
                startIcon={
                    showValues ? (
                        <ShowIcon htmlColor={color} sx={iconSx} />
                    ) : (
                        <HideIcon htmlColor={color} sx={iconSx} />
                    )
                }
                size="large"
            >
                {showValues ? 'Ocultar valores' : 'Mostrar valores'}
            </Button>
        </>
    );
};

export default ShowValuesButton;
