
import { array, object } from 'yup';

export const sendFileHemeraSchema = () => {
  return object().shape({
    list: array()
      .min(1, "Selecione ao menos 1 documento.")
      .typeError('Lista de documentos: ser preenchido')
      .required('Lista de documentos: ser preenchido')
  });
};

export const sendFileHemeraDefaultValues = {
  list: null
}