import Timeline from "components/Timeline";
import { useBatchAssignmentByIdData } from "contexts/batchAssignment/batchAssignmentContext";
import { useParams } from "react-router";

export function TimelineCessaoTabContainer() {
    const { id } = useParams();
    const { batchAssignmentData } = useBatchAssignmentByIdData(id!);

    const timeline = batchAssignmentData?.timeline ?? [];
    const status = batchAssignmentData?.statusDisplay ?? '';

    return (
        <>
            <Timeline timeline={timeline} title="cessão" key={1} status={status} />
        </>
    )
}
