import { array, number, object, string } from 'yup';

export function complianceRulesValidationSchema() {
    return object().shape({
        complianceRuleTypes: array()
            .typeError("Regras de compliance: precisa ser preenchidas")
            .required("Regras de compliance: precisa ser preenchidas"),
        complianceRules: array()
            .of(
                object().shape({
                    complianceRulesActionType: number()
                        .nullable()
                        .when('complianceRulesType', {
                            is: (complianceRulesType: number) => ![0, 9].includes(complianceRulesType),
                            then: number()
                                .required('Ação: Precisa ser preenchido')
                                .typeError('Ação: Precisa ser preenchido'),
                            otherwise: number().nullable()
                        }),
                    complianceRulesType: number().required(),
                })
            )
            .nullable(),
        defaultComplianceAnalysisGroup: string().when("complianceRuleTypes", {
            is: (complianceRuleTypes: number[]) => !!complianceRuleTypes?.includes(6),
            then: string()
                .typeError('Grupo de análise compliance precisa ser preenchido.')
                .required('Grupo de análise compliance: Precisa ser preenchido.'),
            otherwise: string().nullable(),
        })
    });
}



export const complianceRulesDefaultValues = {
    complianceRules: [],
    complianceRuleTypes: []
};