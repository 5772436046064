/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, Stack } from '@mui/material';
import { Button, Checkbox, PersonIcon, Typography, RegistrationIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import {
    CheckboxFormField,
    SelectFormField,
    SelectOption,
    TextFormField,
} from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField';
import { useAppConfig } from 'contexts/appConfig';
import { useFormContext } from 'contexts/formContext';
import { useCreditNoteFormContext } from 'pages/Product/CreditProduct/CreditProductForm/CreditProductForm';
import { useEffect, useState } from 'react';
import { UseFormWatch } from 'react-hook-form';
import { ToastType, toastState } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import Toast from 'components/Toast/Toast';
import ConfigurableHiddenField from 'components/FormFieldHidden';
import { getFieldOptions, formatBankAccountForOption, isValidString } from 'helpers';
import { CreditProductReadModel, updateAmortizationForProduct } from 'services/creditProduct';
import { liquidationModeOptions } from 'services/creditNote/options';

interface InformationCreditNoteTabProps {
    productList: AutocompleteOptionList;
    fundList?: AutocompleteOptionList;
    tomadorList: AutocompleteOptionList;
    personBankList?: SelectOption[];
    beneficiaryBankList?: SelectOption[];
    watch: UseFormWatch<any>;
    setIsAddPerson: React.Dispatch<React.SetStateAction<boolean>>;
    setIsAddBankAccount: React.Dispatch<React.SetStateAction<boolean>>;
    setChangeAccount: React.Dispatch<React.SetStateAction<boolean>>;
    isNewOperation?: boolean;
}

type HandlePersonLinkParams = {
    personId?: string;
    beneficiaryId?: string;
    personDiscriminator?: string;
    beneficiaryDiscriminator?: string;
};

type SelectedPersonType = {
    discriminator?: string;
    registrationNumber?: string;
} & SelectOption;

export const handlePersonLink = ({
    personId,
    beneficiaryId,
    personDiscriminator,
    beneficiaryDiscriminator,
}: HandlePersonLinkParams): string => {
    const discriminator = personDiscriminator || beneficiaryDiscriminator;
    const path =
        discriminator === 'LegalPerson' ? `/pessoas-juridicas/todas` : `/pessoas-fisicas/todas`;
    const id = personId || beneficiaryId;
    return `${path}/${id}`;
};

const theme = activeTheme();
const InformationCreditNoteTab: React.FC<InformationCreditNoteTabProps> = ({
    productList,
    fundList,
    tomadorList,
    personBankList,
    beneficiaryBankList,
    setIsAddPerson,
    watch,
    setIsAddBankAccount,
    setChangeAccount,
    isNewOperation,
}) => {
    const [toast, setToast] = useState<ToastType>(toastState);
    const { product } = useCreditNoteFormContext();
    const { appConfig } = useAppConfig();
    const { getValues, setValue, readOnly } = useFormContext();
    const ammType = product?.amortizationType?.toLowerCase();
    const instrumentType = product?.instrumentType?.toLowerCase();
    const useCDC = product?.isConsumerCreditNote;
    const isFGTS = ammType === 'fgts';
    const isFund = !!appConfig.USE_FUND;
    const [agioDesagio, setAgioDesagio] = useState<boolean>(getValues('assignmentCalculation') !== null);
    const hasFund = getValues('fundId');
    const codeIPOC = watch('codeIPOC');

    const [beneficiaryDiscriminator, beneficiaryId, personId, liquidationMode] = watch([
        'beneficiaryDiscriminator',
        'beneficiaryId',
        'personId',
        'liquidationMode'
    ]);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAgioDesagio(checked);
        if (!checked) {
            setValue('assignmentCalculation', null);
        }
    };

    const formFieldsConfig = appConfig?.FORM_FIELDS;
    const fieldConfigOptions = (fieldName: string) => getFieldOptions(fieldName, formFieldsConfig);

    const liquidationTypeOption =
        fieldConfigOptions('liquidationType') ||
        ([
            { label: 'Boleto', value: 'Invoice' },
            { label: 'Transferência (TED ou PIX)', value: 'EletronicTransfer' },
        ].filter((i) => i !== undefined) as SelectOption[]);

    const fieldBankAccountIdOptions = fieldConfigOptions('bankAccountId');
    const fieldBeneficiaryIdOptions = fieldConfigOptions('beneficiaryId');

    useEffect(() => {
        if (fieldBankAccountIdOptions?.length === 1) {
            setValue('bankAccountIdDisplay', fieldBankAccountIdOptions[0].label);
            setValue('bankAccountId', fieldBankAccountIdOptions[0].value);
        }

        if (fieldBeneficiaryIdOptions?.length === 1) {
            setValue('beneficiaryDisplay', fieldBeneficiaryIdOptions[0].label);
            setValue('beneficiaryId', fieldBeneficiaryIdOptions[0].value);
        }
    }, []);

    const bankAccountList = getValues('bankAccount') ?? {};

    const bankAccountIdOptions =
        fieldBankAccountIdOptions ||
        (useCDC
            ? readOnly
                ? [formatBankAccountForOption(bankAccountList)]
                : beneficiaryBankList
            : personBankList);

    const executeSetValueInvoiceValue = !!appConfig?.EXECUTE_SET_VALUE_INVOICE_VALUE;
    function setValueInvoiceValue(value: string) {
        setValue('invoiceValue', value);
        setValue('amortization.requestedAmount', value);
    }

    const isLiquidationSchedule = liquidationMode === 'LiquidationSchedule';
    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Stack direction='row' justifyContent='space-between' alignItems='center'>
                <Typography variant="h5" fontWeight="bold" color="neutral.dark">
                    Informações do produto
                </Typography>
            </Stack>
            <Box py={3}>
                <Grid container spacing={4}>
                    <Grid item xs={6}>
                        <AutocompleteField
                            name="productId"
                            label="Produto"
                            displayName="productIdDisplay"
                            endIconType="link"
                            link={`/cadastro/produtos-credito/${watch('productId')}`}
                            showEndIcon={watch('productId')}
                            onChange={(a, b) => {
                                let unk = b as unknown;
                                let product = unk as CreditProductReadModel;
                                if (product != null) {
                                    const updated = updateAmortizationForProduct(
                                        product,
                                        setValue,
                                        appConfig
                                    );
                                    if (updated) {
                                        const messsageToast =
                                            'Ajustamos sua simulação de acordo com os parâmetros máximos de taxa e prazo do produto selecionado';
                                        setToast({
                                            open: true,
                                            title: 'Atenção!',
                                            description: messsageToast,
                                            severity: 'info',
                                        });
                                    }
                                }
                            }}
                            required
                            {...productList}
                        />
                    </Grid>

                    <ConfigurableHiddenField fieldName="liquidationMode">
                        <Grid item xs={6}>
                            <SelectFormField
                                name="liquidationMode"
                                label="Modo de liquidação"
                                variant="outlined"
                                fullWidth
                                required
                                options={liquidationModeOptions}
                            />
                        </Grid>
                    </ConfigurableHiddenField>

                    <ConfigurableHiddenField fieldName="fundId">
                        {isFund && (
                            <Grid item xs={6}>
                                <AutocompleteField
                                    name="fundId"
                                    label="Fundo Cessionário"
                                    displayName="fundIdDisplay"
                                    link={`/cadastro/fundos/${watch('fundId')}`}
                                    showEndIcon={watch('fundId')}
                                    endIconType="link"
                                    {...fundList!}
                                />
                            </Grid>
                        )}
                    </ConfigurableHiddenField>

                    <Grid item xs={6}>
                        <AutocompleteField
                            name="personId"
                            label="Tomador"
                            displayName="personDisplay"
                            required
                            endIconType="link"
                            link={handlePersonLink({
                                personId: personId,
                                personDiscriminator: watch('personDiscriminator'),
                            })}
                            showEndIcon={personId}
                            {...tomadorList!}
                            onChange={(_, options) => {
                                const selected = options as SelectedPersonType

                                setValue("personId", selected?.value, { shouldDirty: true });
                                setValue("personDisplay", selected?.label, { shouldDirty: true });
                                if (selected?.discriminator) setValue("personDiscriminator", selected.discriminator, { shouldDirty: true });
                                if (selected?.registrationNumber) setValue("registrationNumber", selected.registrationNumber, { shouldDirty: true });

                            }}
                        />
                        {isNewOperation && (
                            <Grid container sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                                <Button
                                    name="btn-add-person"
                                    startIcon={
                                        <PersonIcon
                                            htmlColor={theme.palette.primary.main}
                                            width={22}
                                            height={22}
                                        />
                                    }
                                    variant="text"
                                    onClick={() => {
                                        setIsAddPerson(true);
                                        setIsAddBankAccount(false);
                                    }}
                                >
                                    Adicionar tomador
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <ConfigurableHiddenField fieldName="beneficiaryId" hidden={isLiquidationSchedule}>
                        {useCDC && (
                            <Grid item xs={6}>
                                <AutocompleteField
                                    name="beneficiaryId"
                                    label="Beneficiário"
                                    displayName="beneficiaryDisplay"
                                    endIconType="link"
                                    link={handlePersonLink({
                                        beneficiaryId: beneficiaryId,
                                        beneficiaryDiscriminator: beneficiaryDiscriminator,
                                    })}
                                    showEndIcon={beneficiaryId}
                                    required
                                    {...tomadorList}
                                />
                            </Grid>
                        )}
                    </ConfigurableHiddenField>
                    <ConfigurableHiddenField fieldName="liquidationType" hidden={isLiquidationSchedule}>
                        <Grid item xs={6}>
                            <SelectFormField
                                name="liquidationType"
                                label="Tipo de Liquidação"
                                variant="outlined"
                                fullWidth
                                required
                                options={liquidationTypeOption}
                            />
                        </Grid>
                    </ConfigurableHiddenField>
                    {watch('liquidationType') === 'Invoice' && (
                        <Grid item xs={6}>
                            <TextFormField
                                name="invoiceBarCode"
                                fullWidth
                                label="Código de Barras"
                                variant="outlined"
                                required
                                placeholder="00000.00000 00000.000000 00000.000000 0 00000000000000"
                            />
                        </Grid>
                    )}
                    <ConfigurableHiddenField fieldName="bankAccountId" hidden={isLiquidationSchedule}>
                        {watch('liquidationType') !== 'Invoice' && (
                            <Grid item xs={6}>
                                {readOnly ? <SelectFormField
                                    name="bankAccountId"
                                    label="Conta de liquidação"
                                    variant="outlined"
                                    options={getValues()?.bankAccount ? [formatBankAccountForOption(getValues()?.bankAccount)] : []}
                                    fullWidth
                                    required={isFGTS ? false : true}
                                /> : <SelectFormField
                                    name="bankAccountId"
                                    label="Conta de liquidação"
                                    variant="outlined"
                                    options={bankAccountIdOptions ?? []}
                                    fullWidth
                                    required={isFGTS ? false : true}
                                />}

                                {(!!(useCDC && beneficiaryId) || (!useCDC && personId)) &&
                                    !readOnly && (
                                        <Grid
                                            container
                                            sx={{ justifyContent: 'flex-end', display: 'flex' }}
                                        >
                                            <Button
                                                name="btn-add-account"
                                                startIcon={
                                                    <RegistrationIcon
                                                        htmlColor={theme.palette.primary.main}
                                                        width={22}
                                                        height={22}
                                                    />
                                                }
                                                variant="text"
                                                onClick={() => {
                                                    setIsAddBankAccount(true);
                                                    setIsAddPerson(false);
                                                    setChangeAccount(false);
                                                }}
                                            >
                                                Adicionar dados bancários
                                            </Button>
                                        </Grid>
                                    )}
                            </Grid>
                        )}
                    </ConfigurableHiddenField>

                    {useCDC && (
                        <>
                            <Grid item xs={6}>
                                <TextFormField
                                    name="invoiceNumber"
                                    fullWidth
                                    label="Nº da NF/Contrato"
                                    variant="outlined"
                                    required={useCDC || isFGTS}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CurrencyFormField
                                    name="invoiceValue"
                                    fullWidth
                                    label="Valor da NF/Contrato"
                                    variant="outlined"
                                    required={useCDC || isFGTS}
                                    onChangeField={(value) => {
                                        if (executeSetValueInvoiceValue) {
                                            setValueInvoiceValue(value);
                                        } else {
                                            setValue('invoiceValue', value);
                                        }
                                    }}
                                />
                            </Grid>
                            <ConfigurableHiddenField fieldName="changeBankAccountId" hidden={isLiquidationSchedule}>
                                <Grid item xs={6}>
                                    <SelectFormField
                                        name="changeBankAccountId"
                                        label="Conta para Troco"
                                        variant="outlined"
                                        options={personBankList}
                                        fullWidth
                                    />

                                    {personId && !readOnly && (
                                        <Grid
                                            container
                                            sx={{ justifyContent: 'flex-end', display: 'flex' }}
                                        >
                                            <Button
                                                name="btn-add-account"
                                                startIcon={
                                                    <RegistrationIcon
                                                        htmlColor={theme.palette.primary.main}
                                                        width={22}
                                                        height={22}
                                                    />
                                                }
                                                variant="text"
                                                onClick={() => {
                                                    setIsAddBankAccount(true);
                                                    setChangeAccount(true);
                                                    setIsAddPerson(false);
                                                }}
                                            >
                                                Adicionar conta para troco
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </ConfigurableHiddenField>
                        </>
                    )}
                </Grid>
            </Box>
            {instrumentType === 'commercialpapper' && (
                <>
                    <Typography variant="h5" fontWeight="bold" color="neutral.dark">
                        Informações da nota comercial
                    </Typography>
                    <Box py={3}>
                        <Grid container spacing={4}>
                            <Grid container item spacing={4}>
                                <Grid item xs={3}>
                                    <TextFormField
                                        label="Quantidade de Notas"
                                        name="quantity"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        inputProps={{ min: 0, max: 1000000000 }}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <CurrencyFormField
                                        label="Valor unitário"
                                        name="unitPrice"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextFormField
                                        label="Número da emissão"
                                        name="emissionNumber"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        inputProps={{ min: 0, max: 1000 }}
                                        required
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextFormField
                                        label="Número da série"
                                        name="issueNumber"
                                        variant="outlined"
                                        type="number"
                                        fullWidth
                                        inputProps={{ min: 0, max: 100 }}
                                        required
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </>
            )}
            <Box py={3}>
                <Grid container spacing={4}>
                    <Grid container item>
                        <ConfigurableHiddenField fieldName="isRegistered">
                            <CheckboxFormField
                                color="primary"
                                label="Registradora de Recebíveis"
                                name="isRegistered"
                            />
                        </ConfigurableHiddenField>
                        <ConfigurableHiddenField fieldName="bulkAssignment">
                            {(instrumentType || ammType) !== 'commercialpapper' && (
                                <>
                                    <CheckboxFormField
                                        label="Cessão em lote"
                                        color="primary"
                                        name="bulkAssignment"
                                    />
                                    <ConfigurableHiddenField fieldName="showAssignmentCalculation">
                                        {hasFund && (
                                            <Grid mt={1}>
                                                <Checkbox
                                                    disabled={readOnly}
                                                    onChange={handleCheckboxChange}
                                                    checked={agioDesagio ? true : false}
                                                    label="Incluir Ágio/Deságio na Cessão"
                                                    color="primary"
                                                />
                                            </Grid>
                                        )}
                                    </ConfigurableHiddenField>
                                </>
                            )}
                        </ConfigurableHiddenField>
                    </Grid>

                    {agioDesagio && hasFund && (
                        <>
                            <Grid item xs={6}>
                                <SelectFormField
                                    name="assignmentCalculation.mode"
                                    label="Modelo de cálculo"
                                    fullWidth
                                    required
                                    options={[
                                        { label: 'Padrão', value: 3 },
                                        { label: 'Valor Inicial', value: 0 },
                                        { label: 'Valor Presente', value: 2 },
                                        { label: 'Valor Presente Parcela', value: 1 },
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <SelectFormField
                                    name="assignmentCalculation.type"
                                    label="Tipo do Taxa"
                                    fullWidth
                                    defaultValue=""
                                    options={[
                                        { label: 'Valor absoluto', value: 0 },
                                        { label: 'Percentual', value: 1 },
                                    ]}
                                />
                            </Grid>
                            {watch('assignmentCalculation.type') === 0 ? (
                                <Grid item xs={6}>
                                    <CurrencyFormField
                                        label="Custo de cessão"
                                        name="assignmentCalculation.amount"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    <PercentageFormField
                                        label="Custo de cessão (%)"
                                        name="assignmentCalculation.amount"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            )}
                        </>
                    )}
                    {isValidString(String(codeIPOC)) &&
                        <Grid item xs={12}>
                            <Stack spacing={2} mb={2}>
                                <Typography variant="h5" fontWeight="bold" color="neutral.dark">
                                    Código IPOC
                                </Typography>
                            </Stack>
                            <Grid item xs={6}>
                                <TextFormField
                                    fullWidth
                                    disabled={true}
                                    type="text"
                                    name="codeIPOC"
                                    label=""
                                    variant="outlined"
                                />

                            </Grid>
                        </Grid>}
                    <ConfigurableHiddenField fieldName="observations">
                        <Grid item xs={12}>
                            <Stack spacing={2} mb={2}>
                                <Typography variant="h5" fontWeight="bold" color="neutral.dark">
                                    Observações
                                </Typography>
                            </Stack>
                            <TextFormField
                                fullWidth
                                type="text"
                                name="observations"
                                label="Observações adicionais"
                                margin="dense"
                                multiline
                                rows={4}
                                variant="outlined"
                            />
                        </Grid>
                    </ConfigurableHiddenField>
                </Grid>
            </Box>
        </>
    );
};

export default InformationCreditNoteTab;

