import { ReactNode, useState } from 'react';
import { Popup, Button, Modal } from '@uy3/web-components';
import { Grid } from '@mui/material';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { CreditNoteReadModel } from 'services/creditNote/types/creditNoteReadModel';
import { mapErrorResponse } from 'contexts/responseErrorContext';

import BatchAssignmentButtonList from 'pages/CreditNote/BatchAssinment/BatchAssignmentList/BatchAssignmentButtonsList/BatchAssignmentButtonList';
import { FGTSManagementContainer } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Modals/FGTSManagement/FGTSManagementContainer';
import {
    cancelBatchAssignmentById,
    deleteBatchAssignmentById,
} from 'services/creditNote/BatchAssignment/BatchAssignment';
import { BatchAssignmentTotalValues } from 'pages/CreditNote/BatchAssinment/BatchAssignmentForm/Modals/BatchAssignmentTotalValues/BatchAssignmentTotalValues';
import { useNavigate } from 'react-router';

interface BatchAssignmentListActionsProps {
    setSelectedStatusOfTable: React.Dispatch<React.SetStateAction<string[]>>;
    selectedStatusOfTable: string[];
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    toast: ToastType;
    refetch: () => void;
    batchData: any;
}

type PopupState = {
    title: string;
    message: string;
    children: ReactNode;
};

type ModalState = {
    title: string;
    description: string;
    children: JSX.Element;
};

export const BatchAssignmentListActions = ({
    selectedStatusOfTable,
    setSelectedStatusOfTable,
    setToast,
    toast,
    refetch,
    batchData,
}: BatchAssignmentListActionsProps) => {
    const { token } = useIdentity();
    const navigate = useNavigate();
    const { setSubmitError, endRequest, startRequest } = useApiRequest();
    const [popupProps, setPopupProps] = useState<PopupState | undefined>(undefined);
    const [modalProps, setModalProps] = useState<ModalState | undefined>(undefined);
    const batchAssignment = batchData?.data;

    let getSelectedRow = batchAssignment?.filter((item: any) =>
        selectedStatusOfTable?.includes(item?.id)
    );

    const clearSelectedItems = () => setSelectedStatusOfTable([]);

    const handleErrorResponse = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
        clearSelectedItems();
        endRequest(false);
    };

    function Fgts() {
        return (
            <FGTSManagementContainer
                onCloseModal={() => setModalProps(undefined)}
                setToast={setToast}
                batchAssignmentData={batchData?.data ?? []}
                refetchList={refetch}
            />
        );
    }

    const GenericChildrenAction = ({ onClick }: { onClick: () => void }) => {
        return (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        setPopupProps(undefined);
                        setModalProps(undefined);
                    }}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onClick}
                    size="medium"
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        );
    };

    const onDelete = () => {
        getSelectedRow?.map(async (item: CreditNoteReadModel) => {
            startRequest();
            await deleteBatchAssignmentById(item.id!, token!)
                .then(() => {
                    const title = 'Cessão excluída com sucesso!!';
                    const description = 'Ótimo! A lista de cessão foi atualizada.';
                    showSuccessToast(title, description, setToast);
                    refetch();
                    clearSelectedItems();
                    endRequest(true);
                    setPopupProps(undefined);
                })
                .catch((response: ApiResponseError) => handleErrorResponse(response));
        });
    };
    const DeleteBatch = () => <GenericChildrenAction onClick={onDelete} />;

    const onCancel = () => {
        getSelectedRow?.map(async (item: CreditNoteReadModel) => {
            startRequest();
            await cancelBatchAssignmentById(item.id, token!)
                .then(() => {
                    const title = 'Cessão cancelada com sucesso!';
                    const description = undefined;
                    showSuccessToast(title, description, setToast);
                    endRequest(true);
                    clearSelectedItems();
                    refetch();
                    setPopupProps(undefined);
                })
                .catch((response: ApiResponseError) => handleErrorResponse(response));
        });
    };
    const CancelBatch = () => <GenericChildrenAction onClick={onCancel} />;

    function TotalValues() {
        return (
            <BatchAssignmentTotalValues
                batchAssignmentData={batchData}
                selectedStatusOfTable={selectedStatusOfTable!}
                onClose={() => setModalProps(undefined)}
            />
        );
    }

    return (
        <>
            <Modal
                title={modalProps?.title}
                description={modalProps?.description}
                open={!!modalProps}
                onClose={() => setModalProps(undefined)}
                children={modalProps?.children}
                sizeModal="large"
            />
            
            <Popup
                open={!!popupProps}
                title={popupProps?.title}
                text={popupProps?.message}
                onClose={() => {
                    setPopupProps(undefined);
                    setToast({ ...toast, open: false });
                }}
                children={popupProps?.children}
            />

            <BatchAssignmentButtonList
                selectedStatusOfTable={selectedStatusOfTable}
                onTotalValues={() => setModalProps({
                    title: 'Total',
                    description: 'Total dos valores selecionados',
                    children: <TotalValues />,
                })}
                includeBatchAssignment={() => navigate('/ccb/cessoes/nova') }
                addOrUpdateFGTSAssignment={() =>
                    setModalProps({
                        title: 'Incluir/Atualizar Cessão FGTS',
                        description: '',
                        children: <Fgts />,
                    })
                }
                refetch={refetch}
                onCancel={() =>
                    setPopupProps({
                        title: 'Cancelar cessão',
                        message: 'Tem certeza que deseja cancelar esse registro?',
                        children: <CancelBatch />,
                    })
                }
                onDelete={() =>
                    setPopupProps({
                        title: 'Excluir cessão',
                        message: 'Você tem certeza que deseja excluir este registro?',
                        children: <DeleteBatch />,
                    })
                }
            />
        </>
    );
};
