import { array, object, string } from 'yup';

const ObjectSchema = object().shape({
    displayName: string(),
    personId: string()
        .typeError('Signatário inválido.')
        .required('Signatário: Precisa ser preenchido.'),
    typeOfRelationship: string()
        .typeError('Tipo de relação inválido.')
        .required('Tipo de relação: Precisa ser preenchido.'),
    signatureType: string().typeError('Forma de Envio inválido.').required('Forma de Envio: Precisa ser preenchido.'),
    validation: string().nullable().notRequired(),
});

export const validationSchemaAddSignersForm = () => {
    return object().shape({
        signatories: array().of(ObjectSchema).nullable(),
    });
};

export const defaultValuesAddSigners = {
    signatories: [],
};
