import { Stack } from '@mui/material';
import { TextFormField } from 'components/Forms/FormFields';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';

type UpdateZetraFormProps = {
    onCloseDrawer: () => void;
};

const theme = activeTheme().palette;
export default function UpdateZetraForm({ onCloseDrawer }: UpdateZetraFormProps) {
    const { submitting } = useApiRequest();

    return (
        <Stack spacing={4}>
            <TextFormField
                variant="outlined"
                name="registrationNumber"
                label="CPF"
                required
                disabled
            />

            <TextFormField
                variant="outlined"
                name="employeePassword"
                label="Senha do servidor"
                required
            />

            <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={3} mt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onCloseDrawer}
                    disabled={submitting}
                >
                    Fechar
                </Button>
                <Button
                    name="btn-submit-bank-details"
                    type="submit"
                    variant="contained"
                    disabled={submitting}
                    startIcon={<SaveIcon htmlColor={theme.common.white} sx={iconSx} />}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
}
