import { FieldValues } from 'react-hook-form';
import { creditAxiosApi } from 'services/axiosApi/axiosApi';
import { FraudRecordCreateModel } from './types/FraudRecordCreateModel';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';

export async function postFraudRecordAsync(data: FieldValues, personId?: string, token = '') {
    const url = `/ClearSaleR6/RegisterFraud?personId=${personId}`;

    const axiosConfig = {
        headers: { Authorization: `Bearer ${token}` },
    };

    const payload = toDataModel(data, FraudRecordCreateModel);
    return await creditAxiosApi.post<FraudRecordCreateModel>(url, payload, axiosConfig);
}
