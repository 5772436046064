import type { FunctionComponent } from 'react';
import type { CheckboxProps } from './Checkbox.interface';
import { FormGroup } from '@mui/material';
import { StyledCheckbox, StyledFormControlLabel } from './Checkbox.styled';

export const Checkbox: FunctionComponent<CheckboxProps> = (props) => {
  const { label, ...checkboxProps } = props;
  const { checked } = checkboxProps;

  return (
    <FormGroup>
      <StyledFormControlLabel
        control={
          <StyledCheckbox
            defaultChecked={typeof checked === 'undefined' ? true : undefined}
            {...checkboxProps}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};
