import { Drawer } from '@uy3/web-components';
import { AxiosResponse } from 'axios';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useBankAccountBalance, useDarfWithBarCode } from 'contexts/bankAccount/bankAccountContext';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { FormProvider } from 'contexts/formContext';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import moment from 'moment';
import ConfirmInformationDrawer from 'pages/BankAccount/BankAccountActive/BankAccountView/InformationTransferDataDrawer/ConfirmInformationDrawer';
import { useState } from 'react';
import { useParams } from 'react-router';
import { postPayDarfWithBarCode } from 'services/accounts/bankAccount/bankAccount';
import { InfoConfirmPay } from '../InfoConfirmPay/InfoConfirmPay';
import { DarfWithBarCodeForm } from './DarfWithBarCode';
import { DrawerDarfWithBarCodeForm } from './DarfWithBarCodeForm/DarfWithBarCodeForm';
import {
    validationvDarfWithBarCodeFormSchema,
    defaultValuesDarfWithBarCodeForm,
} from './DarfWithBarCodeForm/DarfWithBarCodeFormSchema';
import {
    defaultValuesDarfWithBarCode,
    validationvDarfWithBarCodeSchema,
} from './DarfWithBarCodeSchema';
import { DarfWithBarCodeInformation } from './DarfWithCodeInformation/DarfWithBarCodeInformation';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { StepsPayment } from 'services/accounts/bankAccount';
import { FieldValues } from 'react-hook-form';

type DarfWithBarCodeContainerFormProps = {
    refetch: () => void;
    onCloseDrawer: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const DarfWithBarCodeContainerForm = ({
    refetch,
    onCloseDrawer,
    setToast,
}: DarfWithBarCodeContainerFormProps) => {
    const { bankAccountId } = useParams();
    const { token } = useIdentity();
    const [stepPayment, setStepPayment] = useState<StepsPayment>();
    const [formFieldValues, setFormFieldValues] = useState<any>();
    const [infoFormWithBarcode, setInfoFormWithBarcode] = useState<any>('');
    const { setSubmitError, startRequest, endRequest } = useApiRequest();
    const [hideBtnClose, setHideBtnClose] = useState(true);

    const onlyNumbers = /\D/g;
    const barCodeOrLineDigitable = infoFormWithBarcode?.barCode?.replace(onlyNumbers, '');
    const registrationNumber = infoFormWithBarcode?.registrationNumber?.replace(onlyNumbers, '');
    const dueDate = moment(infoFormWithBarcode?.dueDate).format('YYYY-MM-DD') + 'T00:00:00';

    let filter =
        barCodeOrLineDigitable?.length === 44
            ? {
                  registrationNumber,
                  barCode: barCodeOrLineDigitable,
                  dueDate,
              }
            : {
                  registrationNumber,
                  digitableLine: barCodeOrLineDigitable,
                  dueDate,
              };

    const { darfWithBarCodeData, isFetching } = useDarfWithBarCode(bankAccountId!, filter);
    const { data: dataBalance } = useBankAccountBalance(bankAccountId!);

    const schema = validationvDarfWithBarCodeSchema();
    const defaultValues = defaultValuesDarfWithBarCode;
const schemaDarfForm = validationvDarfWithBarCodeFormSchema();
    const defaultValuesForm = defaultValuesDarfWithBarCodeForm;
    const validationConfirmMfa = validationConfirmMfaFormSchema();
    const defaultValuesConfirmMfa = defaultValuesConfirmMfaForm;

    const onClose = () => {
        setStepPayment(undefined);
        setSubmitError(undefined);
    };

    const onSubmitDarfWithBarCode = (value: FieldValues) => {
        setHideBtnClose(false);
        return darfWithBarCodeData ? undefined : setInfoFormWithBarcode(value);
    };

    const onSubmitFormDrawer = (values: FieldValues) => {
        setStepPayment('confirmationMfa');
        setFormFieldValues(values);
    };

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
    const onSubmitConfirmationMfa = async (values: IMFAState) => {
        startRequest();
        mutateGenerateSessionId({
            userPassword: values.password,
            then: async (response: AxiosResponse) => {
                const payload = {
                    name: formFieldValues?.name,
                    trafficTicketValue: formFieldValues?.trafficTicketValue,
                    interestValue: formFieldValues?.interestValue,
                    interestAndChargesValue: formFieldValues?.interestAndChargesValue,
                    receitaFederalCodeId: formFieldValues?.receitaFederalCodeId,
                    principalValue: darfWithBarCodeData?.principalValue,
                    referenceNumber: formFieldValues?.referenceNumber,
                    dueDate: darfWithBarCodeData?.dueDate,
                    barCodeOrDigitableLine: filter.barCode || filter.digitableLine,
                    paymentDate: moment(formFieldValues?.paymentDate).format('YYYY/MM/DD') + 'T00:00:00',
                    calculationPeriod: moment(formFieldValues?.calculationPeriod).format('YYYY/MM/DD'),
                    paymentValue: formFieldValues?.paymentValue,
                    totalValue: formFieldValues?.totalValue,
                    registrationNumber: filter.registrationNumber?.replace(/\D/g, ''),
                    sessionId: response.data,
                    code: values?.code,
                };

                postPayDarfWithBarCode(bankAccountId!, payload, token!)
                    .then(() => {
                        endRequest(true);
                        onClose();
                        onCloseDrawer();
                        const title = 'Pagamento realizado com sucesso!';
                        const description =
                            'Ótimo! Agora é só aguardar a confirmação do pagamento.';
                        showSuccessToast(title, description, setToast);

                        refetch();
                    })
                    .catch((error: ApiResponseError) => {
                        const { errorMessage } = mapErrorResponse(error);
                        const title = 'Ops, ocorreu um erro!';
                        const description = errorMessage;
                        showErrorToast(title, description, setToast);

                        setSubmitError(error);
                        endRequest(false);
                    });
            },
        });
    };

        function darfWithBarCodeForm() {
        return (
            <FormProvider
                validationSchema={schemaDarfForm}
                defaultValues={defaultValuesForm}
                onSubmit={onSubmitFormDrawer}
            >
                <DrawerDarfWithBarCodeForm onClose={onClose} />
            </FormProvider>
        );
    }

    const paymentValue = formFieldValues?.paymentValue;
    function confirmationPaymentForm() {
        return (
            <ConfirmInformationDrawer
                open={true}
                onClose={onClose}
                setStep={setStepPayment}
                dataBalance={dataBalance}
                valueOfOperation={paymentValue ?? darfWithBarCodeData?.value}
                CardInfoComponent={<InfoConfirmPay bankSlipData={darfWithBarCodeData} />}
            />
        );
    }

    function mfaForm() {
        return (
            <FormProvider
                validationSchema={validationConfirmMfa}
                defaultValues={defaultValuesConfirmMfa}
                onSubmit={onSubmitConfirmationMfa}
            >
                <ConfirmMfaForm onClose={onClose} />
            </FormProvider>
        );
    }

    const formDrawerStep: { [key: string]: React.ReactNode } = {
        formDrawer: darfWithBarCodeForm(),
        confirmationPayment: confirmationPaymentForm(),
        confirmationMfa: mfaForm(),
    };
    const form = formDrawerStep[stepPayment!];
    const openDrawer =
        stepPayment === 'formDrawer' ||
        stepPayment === 'confirmationPayment' ||
        stepPayment === 'confirmationMfa';

    const titleDrawer: { [key: string]: string } = {
        formDrawer: 'Preencha os campos abaixo',
        confirmationPayment: 'Resumo da operação',
        confirmationMfa: 'Confirme a operação',
    };

    const title = titleDrawer[stepPayment!];

    return (
        <>
            <FormProvider
                validationSchema={schema}
                defaultValues={defaultValues}
                onSubmit={onSubmitDarfWithBarCode}
            >
                <DarfWithBarCodeForm
                    disable={isFetching}
                    onClose={onCloseDrawer}
                    hideBtnClose={hideBtnClose}
                />
            </FormProvider>

            {darfWithBarCodeData !== undefined && (
                <DarfWithBarCodeInformation
                    onPayment={() => setStepPayment('formDrawer')}
                    bankSlipData={darfWithBarCodeData}
                />
            )}

            <Drawer anchor="right" title={title} open={openDrawer} onClose={onClose}>
                {form}
            </Drawer>
        </>
    );
};
