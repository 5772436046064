import { Grid, Stack } from '@mui/material'
import { Button, CardData, LeftIcon, RightIcon, Typography } from '@uy3/web-components'
import { isEmpty } from 'lodash'
import { formatBankAccountPixKeyOnyMask } from 'helpers/formats/BankAccount'
import { formatCurrencyInCents } from 'helpers/formats/Currency'
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber'
import { GetPixQRcodeType } from 'services/accounts/bankAccount'
import { useFormContext } from 'contexts/formContext'
import { activeTheme } from 'services/theme'
import CardsSelect from 'components/CardSelect/CardSelect'
import moment from 'moment'
import { DatePickerFormField } from 'components/Forms/FormFields'

type PixCopyAndPasteDetailsProps = {
    queryData: GetPixQRcodeType;
    goBack: () => void;
}

const theme = activeTheme();

export const PixCopyAndPasteDetails = ({ queryData, goBack }: PixCopyAndPasteDetailsProps) => {
    const { submitting, setValue, watch } = useFormContext()
    const beneficiary = queryData.beneficiary;
    const payer = queryData.payer;
    const accountFormated = formatBankAccountPixKeyOnyMask(queryData.pixKeyTypeDisplay, queryData.pixKey, true);
    const hasPayer = !isEmpty(payer?.registrationNumber);

    const listItem = [
        { id: "1", title: "Valor a pagar", value: formatCurrencyInCents(queryData.valueInCents) },
        { id: "2", title: "Nome", value: beneficiary.name },
        { id: "3", title: "CPF/CNPJ", value: formatDocumentNumber(beneficiary.registrationNumber) },
        { id: "4", title: "Banco", value: beneficiary.bankName ?? "N/D" },
        { id: "5", title: "Chave Pix", value: accountFormated ?? "N/D" }
    ]

    if (hasPayer) {
        listItem.push(
            { id: "6", title: "Nome do pagador", value: payer?.name },
            { id: "7", title: "CPF/CNPJ do pagador", value: formatDocumentNumber(payer.registrationNumber) }
        )
    }

    const transferDateValue = () => {
        var check = watch('transferDate') === moment().format('yyyy-MM-DD');
        return check ? 'today' : 'otherDay';
    };

    return (
        <Stack spacing={2}>
            <Typography variant='md' fontWeight='bold'>
                Detalhes
            </Typography>
            <CardData listItem={listItem} />

            <Typography variant='md' fontWeight='bold'>
                Data da transferência
            </Typography>

            <CardsSelect
                onChange={({ value, displayName }) => {
                    setValue('transferDateSelected', value);

                    if (value === 'otherDay') {
                        const transferDate = moment().add(1, 'day').format('yyyy-MM-DD')
                        setValue('transferDate', transferDate);
                    }

                    if (value === 'today') {
                        setValue('transferDate', moment().format('yyyy-MM-DD'));
                    }
                }}
                options={[
                    { label: `Hoje ${moment().format('L')}`, value: 'today' },
                    { label: "Agendar para", value: 'otherDay' },
                ]}
                value={transferDateValue()}
            />

            {transferDateValue() === 'otherDay' && (
                <Grid item xs={4}>
                    <DatePickerFormField
                        label="Data da Transferência"
                        disablePast
                        name="transferDate"
                        fullWidth
                        shrink={true}
                        required
                    />
                </Grid>
            )}

            <Stack direction='row' alignItems='center' spacing={2} mt={3} justifyContent='right'>
                <Button
                    variant='outlined'
                    startIcon={<LeftIcon htmlColor={theme.palette.primary.main} />}
                    onClick={goBack}
                >
                    Voltar
                </Button>
                <Button
                    variant='contained'
                    type='submit'
                    disabled={submitting}
                    startIcon={<RightIcon htmlColor={theme.palette.common.white} />}
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
