import { Grid } from '@mui/material';
import { GridRowId } from '@mui/x-data-grid';
import { Button } from '@uy3/web-components';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import React from 'react';
import {
    getEndpointToCreditNoteApprove,
    postApproveCreditNoteOp,
} from 'services/approvals/approvals';

interface CreditNoteApproveProps {
    onClose: () => void;
    refetch: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    rowAction: rowActionType | undefined;
    rowsSelected: GridRowId[];
    getCreditNoteOpSelected: any;
}

type rowActionType = {
    action: string;
    rowData: any;
};

type ApproveType = {
    approvalType: string;
    creditNoteOpId: string;
};

const CreditNoteApprove: React.FC<CreditNoteApproveProps> = ({
    onClose,
    rowAction,
    refetch,
    rowsSelected,
    getCreditNoteOpSelected,
    setToast,
}) => {
    const { token } = useIdentity();
    const { endRequest, startRequest, submitting, setSubmitError } = useApiRequest();

    const approveCreditNoteOpAsync = async (type: string, creditNoteId: string) => {
        startRequest();
        await postApproveCreditNoteOp(type, creditNoteId, token!)
            .then(() => {
                const title = 'Operação aprovada com sucesso';
                const description = 'Ótimo! A operação seguirá para a próxima etapa.';
                showSuccessToast(title, description, setToast);

                onClose();
                refetch();
                setSubmitError(undefined);
                endRequest(true);
            })
            .catch((error: ApiResponseError) => {
                const { errorMessage } = mapErrorResponse(error);
                const title = 'Ops, ocorreu um erro!';
                const description = errorMessage;
                showErrorToast(title, description, setToast);

                setSubmitError(error);
                endRequest(false);
            });
    };

    const approveCreditNoteOp = (isOnlyOneCretitNote: boolean = false) => {
        if (isOnlyOneCretitNote) {

            const recordtype = rowAction?.rowData?.approvalType;

            approveCreditNoteOpAsync(
                getEndpointToCreditNoteApprove(recordtype),
                rowAction?.rowData?.creditNoteOp?.id
            );
        }
        getCreditNoteOpSelected.map(async (op: ApproveType) => {
            return approveCreditNoteOpAsync(
                getEndpointToCreditNoteApprove(op.approvalType),
                op.creditNoteOpId
            );
        });
    };

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            <Button
                variant="outlined"
                color="primary"
                onClick={onClose}
                size="medium"
                sx={{ mt: 3, mr: 2 }}
            >
                Não, cancelar
            </Button>
            <Button
                variant="contained"
                color="primary"
                disabled={submitting}
                onClick={() => {
                    rowsSelected.length > 0
                        ? approveCreditNoteOp(false)
                        : approveCreditNoteOp(true);
                }}
                size="medium"
                sx={{ mt: 3 }}
            >
                Sim, aprovar
            </Button>
        </Grid>
    );
};

export default CreditNoteApprove;
