import { Grid, IconButton, InputAdornment } from '@mui/material'
import { SearchIcon } from '@uy3/web-components'
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { useTenant } from 'contexts/tenantContext';
import { GridColDef } from '@mui/x-data-grid';
import { phoneNumberMask } from 'utils/constants';
import { cnpjMask, cpfMask } from 'utils/constants';

type NaturaPersonAndLegalPersonFilterProps = {
    currentValues: any,
    columns?: GridColDef[]
    removeFilterSelected: (index: number) => void;
    isNaturalPerson?: boolean;
}

const NaturalPersonFormFilter = ({ isNaturalPerson }: { isNaturalPerson: boolean }) => {
    const { tenantAutoCompleteProps } = useTenant();
    const { watch } = useFormContext();

    const listOptions = [
        { label: "Todos", value: 'searchString' },
        { label: isNaturalPerson ? "CPF" : "CNPJ", value: 'registrationNumber' },
        { label: "Email", value: 'email' },
        { label: "Data de criação", value: 'initialDate' },
        { label: "Número de telefone", value: 'phone' },
    ];

    if (isNaturalPerson) {
        listOptions.push(
            { label: "Nome", value: 'personDisplay' },
            { label: "Correspondente", value: 'tenant' }
        )
    } else {
        listOptions.push(
            // { label: "Nome Fantasia", value: 'name' }, ToDo: Falta implementar a busca por nome fantasia na api
            { label: "Razão social ", value: 'companyName' }
        )
    }

    const filters = listOptions.sort((a, b) => a.label.localeCompare(b.label));

    return (
        <>
            <Grid>
                <SelectFormField
                    name='optionFilter'
                    label='Filtrar por'
                    variant='outlined'
                    fullWidth
                    options={filters}
                />
            </Grid>
            <Grid direction='row-reverse' >
                {watch('optionFilter') === 'name' &&
                    <TextFormField
                        name='name'
                        variant='outlined'
                        label='Nome Fantasia'
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }

                {watch('optionFilter') === 'searchString' &&
                    <TextFormField
                        name='searchString'
                        variant='outlined'
                        label='Procurar'
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                {watch('optionFilter') === 'companyName' &&
                    <TextFormField
                        name='companyName'
                        variant='outlined'
                        label='Filtrar por razão social'
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton type="submit">
                                        <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                }
                {
                    watch('optionFilter') === 'personDisplay' && (
                        <TextFormField
                            label='Filtrar por nome'
                            name='personDisplay'
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit">
                                            <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )
                }
                {
                    watch('optionFilter') === 'phone' && (
                        <TextFormField
                            label='Filtrar por número de telefone'
                            name='phone'
                            fullWidth
                            variant='outlined'
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: phoneNumberMask },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton type="submit">
                                            <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )
                }
                {watch('optionFilter') === 'tenant' && (
                    <AutocompleteField
                        label="Filtrar por correspondente"
                        name="tenant"
                        displayName="tenantDisplay"
                        showEndIcon={true}
                        endIconType="submit"
                        {...tenantAutoCompleteProps}
                    />
                )}
                {
                    watch('optionFilter') === 'registrationNumber' && (
                        <TextFormField
                            label={`Filtrar por ${isNaturalPerson ? "CPF" : "CNPJ"}`}
                            name='registrationNumber'
                            variant='outlined'
                            InputProps={{
                                inputComponent: MaskedInput,
                                inputProps: { mask: isNaturalPerson ? cpfMask : cnpjMask },
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ marginRight: 0 }}>
                                        <IconButton type="submit">
                                            <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            fullWidth
                        />
                    )
                }
                {
                    watch('optionFilter') === 'email' && (
                        <TextFormField
                            label='Filtrar por email'
                            name='email'
                            variant='outlined'
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end" sx={{ marginRight: 0 }}>
                                        <IconButton type="submit">
                                            <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    )
                }
                {watch('optionFilter') === 'initialDate' && (
                    <Grid
                        sx={{
                            display: 'grid',
                            alignItems: 'center',
                            gridTemplateColumns: 'auto auto',
                            gridGap: 10,
                        }}
                    >
                        <DatePickerFormField
                            label="Filtrar por data inicial"
                            name="initialDate"
                            fullWidth
                            required
                        />
                        <DatePickerFormField
                            label="Filtrar por data final"
                            name="finalDate"
                            fullWidth
                            required
                            componentFilter={
                                <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                    <IconButton type="submit">
                                        <SearchIcon
                                            sx={{ width: 25, height: 25 }}
                                            htmlColor="#666666"
                                        />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </Grid>
                )}
            </Grid >
        </>
    )
}

export const NaturaPersonAndLegalPersonFilter = ({ currentValues, removeFilterSelected, isNaturalPerson }: NaturaPersonAndLegalPersonFilterProps) => {
    return (
        <>
            <FilterStyleBase
                formFilters={<NaturalPersonFormFilter isNaturalPerson={isNaturalPerson!} />}
                currentValues={
                    <CurrentValuesSelected
                        currentValues={currentValues}
                        removeFilterSelected={removeFilterSelected}
                    />
                }
            />
        </>
    )
}