import { object, string } from "yup";


export const validationAddCreditorSchema = () => {
  return object().shape({
    personId: string().typeError('Pessoa precisa ser preenchido').required('Pessoa: precisa ser preenchido')
  });
};

export const defaultValuesAddCreditor = {
  personId: null
};