/* eslint-disable react-hooks/exhaustive-deps */
import { Stack } from '@mui/material'
import { Alert, Button, CloseIcon, RightIcon, Typography } from '@uy3/web-components'
import { SelectFormField } from 'components/Forms/FormFields'
import { useFormContext } from 'contexts/formContext'
import { IQueryBasicData } from 'contexts/personContext'
import { formatPhoneNumber } from 'helpers/formats/PhoneNumber'
import { isEmpty } from 'lodash'
import { useEffect } from 'react'
import { activeTheme } from 'services/theme'

type SelectOptionsProps = {
    onClose: () => void
    queryData?: IQueryBasicData
}

const theme = activeTheme();

export const formatPhoneNumberWithNone = (phone: string) => {
    const onlyNumbers = phone?.replace(/\D/g, '')
    const phoneNumber = onlyNumbers?.slice(0, 2) + "9" + onlyNumbers?.slice(2);
    return phoneNumber;
}

const methodToOptions = (array: any[], field?: string) => array.map(item => {
    let valueCustom = item;

    switch (field) {
        case 'phone': {
            const phoneNumber = formatPhoneNumberWithNone(valueCustom);
            const customLabel = formatPhoneNumber(phoneNumber);
            return { label: customLabel, value: phoneNumber }
        }
        case 'address': {
            const zipCodeFormated = `${item?.addressName}, ${item?.zipCode?.slice(0, 5) + '-' + item?.zipCode?.slice(5)}`
            const labalAddress = `${zipCodeFormated}, ${item?.number}, ${item?.city} - ${item?.ufDisplay ?? item?.uf}`;
            return { label: labalAddress, value: `${item?.zipCode + item?.number + item?.addressName}` }
        }
    }
    return { label: valueCustom, value: valueCustom }
});

export const SelectOptions = ({ onClose, queryData }: SelectOptionsProps) => {
    const { setValue } = useFormContext();
    const emailsList = queryData?.emails ?? [];
    const addressList = queryData?.addresses ?? [];
    const phonesList = queryData?.phones ?? [];

    useEffect(() => {
        if (emailsList.length === 1) {
            setValue('emailSelected', emailsList[0])
        }
        if (phonesList.length === 1) {
            setValue('phoneNumberSelected', formatPhoneNumberWithNone(phonesList[0]))
        }
        if (addressList.length === 1) {
            setValue('addressSelected', addressList[0].zipCode)
        }
    }, [emailsList, addressList, phonesList]);

    return (
        <Stack spacing={2}>
            {!isEmpty(addressList) && addressList.length > 1 && (
                <Stack spacing={1}>
                    <Typography variant='md' fontWeight='bold'>Selecione um endereço </Typography>
                    <SelectFormField label='Endereço' name='addressSelected' options={methodToOptions(addressList, 'address')} />

                </Stack>
            )}

            {!isEmpty(emailsList) && emailsList.length > 1 && (
                <Stack spacing={1}>
                    <Typography variant='md' fontWeight='bold'>Selecione um e-mail </Typography>
                    <SelectFormField label='Email' name='emailSelected' options={methodToOptions(emailsList)} />

                </Stack>
            )}

            {!isEmpty(phonesList) && phonesList.length > 1 && (
                <Stack spacing={1}>
                    <Typography variant='md' fontWeight='bold'>Selecione um número de telefone</Typography>
                    <SelectFormField label='Telefone' name='phoneNumberSelected' options={methodToOptions(phonesList, 'phone')} />
                </Stack>
            )}

            <Alert severity='info' text='Após selecionar, você poderá alterá-lo no formulário de origem, se necessário.'/>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    startIcon={
                        <CloseIcon
                            htmlColor={theme.palette.primary.main}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    type="submit"
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={{ height: 23, width: 23 }}
                        />
                    }
                >
                    Continuar
                </Button>
            </Stack>
        </Stack>
    )
}
