import { useMutation } from '@tanstack/react-query';
import { FieldValues } from 'react-hook-form';
import { ApiResponseError, useApiRequest } from './apiRequestContext';
import { useIdentity } from './identityContext';
import { AxiosError, isAxiosError } from 'axios';
import { postFraudRecordAsync } from 'services/fraudRecord';

export function useFraudRecordMutation(
    id: string,
    onSuccess?: (data: any) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error, status } = useMutation({
        mutationFn: async (payload: FieldValues) => {
            startRequest();
            const { data, status, statusText } = await postFraudRecordAsync(payload, id, token);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess(data, variables, _) {
            onSuccess && onSuccess(data);
        },
        onError(error, variables, _) {
            let message = 'Erro desconhecido. Por favor, entre em contato com o suporte técnico.';
            let apiError: ApiResponseError = {
                type: 'error',
                message,
                code: 'UNKNOWN',
                errors: [],
            };
            if (isAxiosError(error)) {
                const axErr = error as AxiosError;
                apiError = { type: 'error', code: axErr.code!, errors: [] };
                const { response } = axErr;
                if (response) {
                    const { data } = response;
                    let respData = data as ApiResponseError;
                    if (data) {
                        apiError = respData;
                    }
                }
            }
            endRequest(false);
            setSubmitError(apiError);
            onError && onError(apiError);
        },
    });

    return { mutateAsync, isLoading, error, status };
}
