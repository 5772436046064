import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React, { useState } from 'react';
import { defaultValuesfileConfirmationchema, fileConfirmationSchema } from './FileConfirmationFiltersSchema';
import { FieldValues } from 'react-hook-form';
import { Toast } from '@uy3/web-components';
import { Grid } from '@mui/material';
import { FileConfirmationFilters } from './FileConfirmationFilters';

export const FileConfirmationFiltersContainer: React.FC = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();
    const [existsStatusFilter, setExistsStatusFilter] = useState(false);

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        switch (optionFilter) {
            case 'initialDate':
                const finalDate = new Date(values.finalDate);
                addFilter(optionFilter, values[optionFilter], finalDate);
                break;
            default:
                addFilter(optionFilter, values[optionFilter]);
        }
    };

    const validationSchema = fileConfirmationSchema();
    const defaultValues = defaultValuesfileConfirmationchema;

    return (
        <>
            <Grid container item>
                <Toast
                    severity="info"
                    title="O filtro já está aplicado na listagem abaixo."
                    onClose={() => setExistsStatusFilter(false)}
                    open={existsStatusFilter}
                />
            </Grid>

            <FormProvider
                defaultValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={handleOnSubmit}
            >
                <FileConfirmationFilters
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    );
};
