import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { PixCopyAndPaste } from './PixCopyAndPaste';
import { FormProvider } from 'contexts/formContext';
import {
  validationCodeCopyAndPasteSchema,
  defaultValuesCodeCopyAndPaste,
} from './PixCopyAndPasteSchema';
import { FieldValues } from 'react-hook-form';
import { useGetBankAccountPixQRCode } from 'contexts/bankAccount/bankAccountContext';
import { useParams } from 'react-router';
import { isEmpty } from 'lodash';
import {
  ApiResponseError,
  ToastType,
  showErrorToast,
  showSuccessToast,
  useApiRequest,
} from 'contexts/apiRequestContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { PixCopyAndPasteDetails } from './PixCopyAndPasteDetails/PixCopyAndPasteDetails';
import {
  defaultValuesCodeCopyAndPasteDetails,
  validationCodeCopyAndPasteDetailsSchema,
} from './PixCopyAndPasteDetails/PixCopyAndPasteDetailsSchema';
import { Drawer } from '@uy3/web-components';
import {
  defaultValuesConfirmMfaForm,
  validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { AxiosResponse } from 'axios';
import { createTransfer } from 'services/accounts/bankAccount/bankAccount';
import { errorHandlingBankAccount } from 'helpers/formats/ErrorMessageFormat';

type PixCopyAndPasteContainerProps = {
  onClose: () => void;
  setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>;
  setToast: React.Dispatch<React.SetStateAction<ToastType>>;
};

export const PixCopyAndPasteContainer = ({
  onClose,
  setToast,
  setIsRefreshing
}: PixCopyAndPasteContainerProps) => {
  const { bankAccountId } = useParams();
  const { token } = useIdentity();
  const { endRequest, startRequest } = useApiRequest();
  const [qrCode, setQrCode] = useState<string | undefined>();
  const [formValues, setFormValues] = useState<FieldValues | null>(null);
  const [isStepMfa, setIsStepMfa] = useState<boolean>(false);

  const onSuccess = () => {
    showSuccessToast('Consulta concluída com sucesso!', 'Ótimo! Agora você pode continuar com as próximas etapas.', setToast);
  };
  
  const onError = (error: ApiResponseError) => {
    const { errorMessage } = mapErrorResponse(error);
    showErrorToast('Ops, ocorreu um erro', errorMessage, setToast);
    setQrCode(undefined);
  };

  const { mutateGenerateSessionId } = useGenerateSessionIdMutation();
  const { data: dataPixQrCode, isLoading } = useGetBankAccountPixQRCode({
    bankAccountId: bankAccountId!,
    onError,
    onSuccess,
    qrCode
  });

  const isLoadingDataWithCode = !isEmpty(qrCode) && isLoading;

  const handleSubmitQuery = (values: FieldValues) => {
    setQrCode(values?.qrCode);
  };

  // temporarily
  const concactNumberInAgency = (agency: string) => {
    if (agency.length < 4) {
      return agency.padStart(4, '0');
    };
    return agency;
  };

  const handleSubmitToMfa = (values: FieldValues) => {
    const accountType = Number(dataPixQrCode?.beneficiary?.accountType);
    setFormValues({
      amount: dataPixQrCode?.valueInCents,
      transferDate: values?.transferDate,
      bankAccountBeneficiaryCreate: {
        ...dataPixQrCode?.beneficiary,
        agency: concactNumberInAgency(String(dataPixQrCode?.beneficiary?.agency)),
        operationTypeValue: 'Pix',
        pixKeyTypeValue: dataPixQrCode?.pixKeyType,
        keyPix: dataPixQrCode?.pixKey,
        accountType: accountType === 4 ? 1 : accountType
      },
      endToEndId: dataPixQrCode?.endToEndId,
      qrCode,
      saveBeneficiary: false
    });
    setIsStepMfa(true);
  };

  const handleSubmitTransfer = (valuesMfa: IMFAState) => {
    startRequest();
    mutateGenerateSessionId({
      userPassword: valuesMfa.password,
      then: async function ({ data: sessionId }: AxiosResponse<string>) {
        const payload = {
          ...formValues,
          code: valuesMfa.code,
          sessionId
        };

        await createTransfer(bankAccountId!, payload, token!)
          .then(() => {
            endRequest(true);
            setIsRefreshing(true);
            onClose();
            showSuccessToast(
              'Transferência Pix com código de barras realizada com sucesso!',
              undefined,
              setToast
            );
          })
          .catch((response: ApiResponseError) => {
            const { description, title } = errorHandlingBankAccount(response);
            endRequest(false);
            showErrorToast(title, description, setToast);
          });
      },
    });
  };

  const hasData = !isEmpty(dataPixQrCode) && Object.values(dataPixQrCode ?? {}).length > 0 && !!qrCode;

  return (
    <Stack>
      {!hasData && (
        <FormProvider
          validationSchema={validationCodeCopyAndPasteSchema()}
          defaultValues={defaultValuesCodeCopyAndPaste}
          onSubmit={handleSubmitQuery}
        >
          <PixCopyAndPaste disabled={isLoadingDataWithCode} onClose={onClose} />
        </FormProvider>
      )}

      {hasData && (
        <FormProvider
          defaultValues={defaultValuesCodeCopyAndPasteDetails}
          validationSchema={validationCodeCopyAndPasteDetailsSchema()}
          onSubmit={handleSubmitToMfa}
        >
          <PixCopyAndPasteDetails
            {...{ queryData: dataPixQrCode, goBack: () => setQrCode(undefined) }}
          />
        </FormProvider>
      )}

      <Drawer
        anchor="right"
        title="Confirmar MFA"
        open={isStepMfa}
        toggleDrawer
        onClose={() => setIsStepMfa(false)}
      >
        <FormProvider
          validationSchema={validationConfirmMfaFormSchema()}
          defaultValues={defaultValuesConfirmMfaForm}
          onSubmit={handleSubmitTransfer}
        >
          <ConfirmMfaForm onClose={() => setIsStepMfa(false)} toggleDrawer />
        </FormProvider>
      </Drawer>
    </Stack>
  );
};
