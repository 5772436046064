import { Stack } from '@mui/material'
import { SettingsNotificationsHeader } from './SettingsNotificationsHeader'
import { FormProvider } from 'contexts/formContext'
import { SettingNotificationForm } from './SettingNotificationForm/SettingNotificationForm'
import { defaultValuesSettingNotification, validationSchemaSettingNotificationForm } from './SettingNotificationForm/SettingNotificationFormSchema'
import { Fragment } from 'react/jsx-runtime'
import { FieldValues } from 'react-hook-form'
import { useGetNotificationsSettings, useNotificationSettingMutations } from 'contexts/notifications/notificationsContext'
import { ApiResponseError, handleOnError, showSuccessToast, toastState, ToastType } from 'contexts/apiRequestContext'
import { useState } from 'react'
import Toast from 'components/Toast/Toast'
import { INotificationCustomFromSchemaType, INotificationType } from 'services/notifications/types/notificationsCreateModel'
import { ReadNotificationsSkeleton } from 'components/Skeleton/ReadNotificationsSkeleton'
import Spinner from 'components/RiskScore/DatasetsStatusColumn/Spinner'

export const SettingsNotificationsContainer = () => {
  const [toast, setToast] = useState<ToastType>(toastState);

  const onSuccess = () => {
    const title = "Configurações de notificações atualizada com sucesso!";
    showSuccessToast(title, "", setToast);
    refetch();
  };

  const onError = (error: ApiResponseError) => handleOnError(error, setToast)

  const { mutateAsync: notificationSettingMutate } = useNotificationSettingMutations(onSuccess, onError);
  const { data: notificationData, isLoading, isFetching, refetch } = useGetNotificationsSettings();

  const onSubmit = async (values: FieldValues) => {
    const listNotificationType = (values?.notificationTypes ?? []) as INotificationCustomFromSchemaType[];
    const notificationTypeList: INotificationType[] = [];

    listNotificationType.forEach((x) => {
      if (!!["Bankslip", "Communication"].includes(x.resource)) {
        for (let i: number = 0; i < x.fields.length; i++) {
          const current = x.fields[i];
          let notification: INotificationType = {
            level: x.isEnableConfiguration ===  false ? 0 : Number(current.level),
            type: Number(current.type)
          };
          notificationTypeList.push(notification);
        };
      };
    });

    await notificationSettingMutate(notificationTypeList);
  };

  return (
    <Stack spacing={2}>
      <Toast {...{ setToast, toast }} />
      <ReadNotificationsSkeleton isLoading={isLoading || isFetching}>
        <FormProvider {...{
          defaultValues: defaultValuesSettingNotification(notificationData!),
          validationSchema: validationSchemaSettingNotificationForm(),
          onSubmit
        }}>
          <Fragment>
            <SettingsNotificationsHeader />
            {isFetching && <Stack mb={3}> <Spinner /></Stack>}
            <SettingNotificationForm />
          </Fragment>
        </FormProvider>
      </ReadNotificationsSkeleton>
    </Stack>
  )
}
