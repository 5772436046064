import NewChargesForm from './NewChargesForm';
import { StepNewChargesButton } from '../../StepsButtons/StepNewChargesButton';
import { useState } from 'react';
import { usePersonsList } from 'contexts/personContext';
import { FieldValues } from 'react-hook-form';
import { useFormContext } from 'contexts/formContext';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { ToastType } from 'contexts/apiRequestContext';
import { useWalletDataList } from 'contexts/wallet/walletContext/walletContext';
import { WalletsFull } from 'services/walletManagement/wallet/wallets.type';
import { AddPayerFormContainer } from 'components/GenericForms/AddPayer/AddPayerFormContainer';

interface NewChargesStepProps {
    currentStep: number;
    onClose: () => void;
    onPrevStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
}

export const NewChargesStep: React.FC<NewChargesStepProps> = ({
    currentStep,
    onClose,
    onPrevStep,
    setToast
}) => {
    const { setValue } = useFormContext();
    const [isAddPayer, setIsAddPayer] = useState(false);
    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 });
    const { walletCodeAutoCompleteProps, data: assignorData } = useWalletDataList({ page: 0, size: 10 });

    const onSuccessWhenAddPerson = (values: FieldValues) => {
        setValue('personIdDisplay', `${values?.name} - ${formatDocumentNumber(values?.registrationNumber)}`)
        setValue('personId', values?.id)
        setValue('personDiscriminator', values?.discriminator)
    }; 
    
    return (
        <>
            <AddPayerFormContainer {...{
                title: "Adicionar pagador", 
                onClose: () => setIsAddPayer(false), 
                open: isAddPayer, 
                toggleDrawer: true, 
                setToast, 
                onSuccessGeneric: onSuccessWhenAddPerson
            }} />

            <NewChargesForm
                assignorList={walletCodeAutoCompleteProps}
                personList={personAutoCompleteProps}
                setIsAddPayer={setIsAddPayer}
                assignorData={assignorData?.data as unknown as WalletsFull[]}
                setToast={setToast}
            />

            <StepNewChargesButton
                currentStep={currentStep}
                onClose={onClose}
                onPrevStep={onPrevStep}
            />
        </>
    );
};
