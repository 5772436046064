import { lazy, Suspense, useState } from 'react';
import { DashboardV3MetricsFilters, useDashboardData } from 'contexts/dashboardContext';
import { Stack } from '@mui/material';
import { Error } from 'components/Errors/Error';
import ProductDetails from './ProductTable/ProductDetails/ProductDetails';
import moment from 'moment';
import { DetailsContainer } from './ProductTable/ProductDetails/ProductDetails.styled';
import ErrorBoundary from 'components/Errors/ErrorBoundary';
import { CardDetails } from './CardDetails/CardDetails';
import { formatCurrencyInCents } from 'helpers/formats/Currency';
import { DashboardSkeleton } from 'components/Skeleton/DashboardSkeleton';

const OperationsPerPeriod = lazy(() => import('./OperationsPerPeriod/OperationsPerPeriod'));
const HeaderInformationBox = lazy(() => import('./HeaderInformationBox/HeaderInformationBox'));

export const Dashboard = () => {
    const [filters, setFilters] = useState<DashboardV3MetricsFilters | undefined>({
        initialDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
        finalDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
        statusList: ["ComplianceApproval", "CreditApproval", "Signatures", "SignaturesValidation"],
        productList: undefined
    });
    const { status, data, error, isFetching, refetch, isLoading } = useDashboardData(filters);
    const oneMonthData = data?.creditNotesByMonth[0];

    return (
        <ErrorBoundary
            fallback='Dashboard'
            textButton='Tentar novamente'
        >
            <Stack spacing={4} mr={2} mb={10} mt={-3}>
                <Suspense
                    fallback={
                        status === 'loading' || !isFetching ? (
                            <></>
                        ) : status === 'error' ? (
                            <Error error={error} />
                        ) : null
                    }
                >
                    <HeaderInformationBox
                        queryData={data}
                        filters={filters}
                        refetch={refetch}
                        setFilters={setFilters}
                        isLoading={isLoading}
                        isFetching={isFetching}
                    />
                </Suspense>

                <DashboardSkeleton isLoading={isLoading || isFetching}>
                    <>
                        <Suspense
                            fallback={
                                status === 'loading' || !isFetching ? (
                                    <></>
                                ) : status === 'error' ? (
                                    <Error error={error} />
                                ) : null
                            }
                        >
                            <ProductDetails data={data} filters={filters} setFilters={setFilters} />
                        </Suspense>
                        {data?.creditNotesByMonth?.length === 1 ?
                            <CardDetails
                                updatedAt={data?.updatedAt}
                                options={[
                                    { label: "Total de operações", value: oneMonthData?.numberOfCreditNotes },
                                    { label: "Valor líquido", value: formatCurrencyInCents(oneMonthData?.liquidAmountInCents) },
                                    // { label: "Valor bruto", value: formatCurrencyInCents(data?.creditNotesSummary?.creditNotesFinishedGrossValueSum) }
                                ]} />
                            :
                            <Suspense
                                fallback={
                                    status === 'loading' || !isFetching ? (
                                        <></>
                                    ) : status === 'error' ? (
                                        <Error error={error} />
                                    ) : null
                                }
                            >
                                <DetailsContainer>
                                    <OperationsPerPeriod data={data?.creditNotesByMonth ?? []} />
                                </DetailsContainer>
                            </Suspense>
                        }
                    </>
                </DashboardSkeleton>
            </Stack>
        </ErrorBoundary>
    );
};
