/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import {
    CheckboxFormField,
    SelectFormField,
    SelectOption,
    TextFormField,
} from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { activeTheme } from 'services/theme';
import { iconSx } from 'contexts/apiRequestContext';
import { IGetTokensQuery } from 'services/accounts/naturalPerson/types/naturalPersonReadModel';
import { useEffect } from 'react';

interface ApproveMarginReverseFormProps {
    onClose: () => void;
    handleAddToken: () => void;
    tokenData: IGetTokensQuery[] | undefined;
}

const theme = activeTheme();
const colorMain = theme.palette.primary.main;
export const ApproveMarginReverseForm = ({
    onClose,
    handleAddToken,
    tokenData,
}: ApproveMarginReverseFormProps) => {
    const { setValue, watch } = useFormContext();
    const watchSelectedTokens = watch('selectedTokens') ?? [];
    const filteredByTokensSelected = tokenData?.filter(x => watchSelectedTokens?.includes(x?.Token?.TokenValue)) ?? [];
    
    useEffect(() => {
        if (filteredByTokensSelected.length > 0) {
            filteredByTokensSelected?.forEach((item, index) => {
                if (watchSelectedTokens?.includes(item.Token.TokenValue)) {
                    setValue(`tokens.${index}.WarrantyRegistrationOffice`,
                        item.WarrantyRegistrationOffice
                    );
                    setValue(`tokens.${index}.token.tokenType`,
                        item.Token.TokenType
                    );
                    setValue(`tokens.${index}.token.tokenValue`,
                        item.Token.TokenValue
                    );
                    setValue(`tokens.${index}.ExpirationDate`,
                        item.ExpirationDate
                    );
                } else {
                    const list = watch('tokens')?.filter((_:unknown, i: number) => i !== index);
                    setValue(`tokens`, list);
                }
            })
        }
        setValue('filteredByTokensSelected', filteredByTokensSelected)
    }, [watchSelectedTokens])

    const optionTokens: SelectOption[] = !!tokenData?.length
        ? tokenData?.map((item) => item.option)
        : [];

    return (
        <Stack spacing={4}>
            <Grid item xs={12}>
                <SelectFormField
                    label="Token"
                    multiple
                    name="selectedTokens"
                    required
                    fullWidth
                    options={optionTokens}
                />
                <Grid container sx={{ justifyContent: 'flex-end', display: 'flex' }}>
                    <Button
                        variant="text"
                        onClick={handleAddToken}
                        startIcon={<AddIcon htmlColor={colorMain} />}
                        sx={{ color: colorMain }}
                    >
                        Adicionar token
                    </Button>
                </Grid>
            </Grid>

            {watchSelectedTokens && watchSelectedTokens.map((token: any, tokenIndex: number) => (
                <TextFormField
                    key={tokenIndex}
                    name={`tokens.${tokenIndex}.warrantyCode`}
                    variant="outlined"
                    label={`Código ADE - Token ${token}`}
                    fullWidth
                />
            ))}

            <TextFormField
                label="Motivo da aprovação"
                name="message"
                fullWidth
                required
                variant="outlined"
            />

            <Grid>
                <CurrencyFormField
                    label="Valor da margem"
                    name="valueMarginReserve"
                    required
                    fullWidth
                    variant="outlined"
                />
            </Grid>

            <CheckboxFormField
                name="includePaymentFixedCosts"
                label="Incluir cliente como Participante de Regime de Previdência"
            />
            <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon sx={iconSx} htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    startIcon={<SaveIcon sx={iconSx} htmlColor={theme.palette.common.white} />}
                    type="submit"
                >
                    Sim, confirmar
                </Button>
            </Stack>
        </Stack>
    );
};
