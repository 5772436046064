import React from 'react';
import { ApproveIcon, BankSlipIcon, DataTable, RejectIcon, SearchIcon } from '@uy3/web-components';
import { GridCallbackDetails, GridColDef, GridRowId, GridSelectionModel } from '@mui/x-data-grid';
import { Stack } from '@mui/material';
import { RowActions } from 'components/RowActions/RowActions';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { iconSx } from 'contexts/apiRequestContext';
import { Link } from 'react-router-dom';

type BankAccuntRequestListProps = {
  rowsSelected: GridRowId[];
  handleSelectionModelChange: (selectionModel: GridSelectionModel, details: GridCallbackDetails<any>) => void;
  page: number;
  setPage: (newPage: number) => void;
  rowsPerPage: number;
  setRowAction: React.Dispatch<React.SetStateAction<{
    action: string;
    rowData: any;
  } | undefined>>
  setRowsPerPage: (page: number) => void
  queryData: any
}

export const BankAccuntRequestList: React.FC<BankAccuntRequestListProps> = ({
  handleSelectionModelChange,
  rowsSelected,
  page,
  queryData,
  rowsPerPage,
  setRowAction,
  setPage,
  setRowsPerPage,
}) => {
  const columns: GridColDef[] | any[] = [
    {
      field: 'tenantDisplay',
      headerName: 'Correspondente',
      minWidth: 200,
      editable: false,
      hideSortIcons: true
    },
    {
      field: 'row.bankAccountRequest',
      headerName: 'Parceiro',
      flex: 1,
      editable: false,
      hideSortIcons: true,
      renderCell: ({ row }) => row?.bankAccountRequest?.partnerName
    },
    {
      field: 'ownerDisplay',
      headerName: 'Titular',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      align: 'left',
      renderCell: ({ row }) => row?.bankAccountRequest?.ownerDisplay
    },
    {
      field: 'bankAccountRequest.statusDisplay',
      headerName: 'Status',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      align: 'left',
      renderCell: ({ row }) => row?.bankAccountRequest?.statusDisplay
    },
    {
      field: 'ownerRegistrationNumber',
      headerName: 'CNPJ/CPF',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      align: 'left',
      renderCell: ({ row }) => formatDocumentNumber(row?.bankAccountRequest?.ownerRegistrationNumber)
    },
    {
      field: 'typeDisplay',
      headerName: 'Tipo de conta',
      hideSortIcons: true,
      flex: 1,
      editable: false,
      align: 'left',
      renderCell: ({ row }) => row?.bankAccountRequest?.typeDisplay
    },
    {
      field: 'approvalTypeDisplay',
      headerName: 'Tipo de solicitação',
      hideSortIcons: true,
      flex: 1,
      editable: false
    },
    {
      field: `bankAccountRequest.createdAt`,
      headerName: 'Criado em',
      hideSortIcons: true,
      editable: false,
      minWidth: 150,
      renderCell: ({ row }) => {
        let value = row?.bankAccountRequest?.createdAt;
        return new Date(value).toLocaleDateString('pt-BR')
      }
    },
    {
      field: "actions",
      headerName: 'Ações',
      hideSortIcons: true,
      headerAlign: 'center',
      align: 'center',
      editable: false,
      renderCell: ({ row }) => {
        const linkToPerson = () => {
          const discriminator = row?.bankAccountRequest?.ownerDiscriminator;
          const ownerId = row?.bankAccountRequest?.ownerId;
          if (discriminator === 'NaturalPerson') {
            return `/pessoas-fisicas/todas/${ownerId}`;
          } else {
            return `/pessoas-juridicas/todas/${ownerId}`;
          }

        }
        return (
          <Stack direction='row' alignItems='center'>
            <Link to={linkToPerson()}>
              <SearchIcon sx={iconSx} />
            </Link>
            <RowActions
              listButtons={[
                {
                  action: () => setRowAction({
                    action: 'details',
                    rowData: row
                  }),
                  disabled: false,
                  icon: <BankSlipIcon />,
                  label: "Detalhes"
                },
                {
                  action: () => setRowAction({
                    action: 'approve',
                    rowData: row
                  }),
                  disabled: false,
                  icon: <ApproveIcon />,
                  label: "Aprovar"
                },
                {
                  action: () => setRowAction({
                    action: 'reject',
                    rowData: row
                  }),
                  disabled: false,
                  icon: <RejectIcon />,
                  label: "Rejeitar"
                }
              ]}
            />
          </Stack>
        )
      }
    }
  ];

  return (
    <DataTable
      columns={columns}
      filterComponent={{
        componentFilter: <></>,
        showFilters: true
      }}
      page={queryData ? queryData.page ?? 0 : page}
      rows={queryData !== undefined ? queryData?.data ?? [] : []}
      rowCount={queryData?.totalItems ?? 0}
      pageCount={queryData?.totalPages ?? 0}
      rowsPerPage={rowsPerPage}
      setPage={setPage}
      setRowsPerPage={setRowsPerPage}
      checkboxSelection={true}
      keepNonExistentRowsSelected
      rowSelectionModel={rowsSelected}
      onSelectionModelChange={handleSelectionModelChange}
    />
  )
}
