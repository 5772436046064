/* eslint-disable no-mixed-operators */
import { FormProvider } from 'contexts/formContext';
import { BankAccountFilters } from './BankAccountFilters';
import { bankAccountFiltersSchema, defaultValuesBankAccountFilterSchema } from './BankAccountFiltersSchema';
import { useFilters } from 'contexts/filterContext';
type BankAccountFiltersContainerProps = {
    typeFilter: "transferAndPix" | "payment" | "operator" | "favored" | "bankAccountList" | "pendingOperators" | "pendingApprovals",
    enableSelectFilter?: boolean;
    recordType?: string;
}

export const BankAccountFiltersContainer = ({ typeFilter, enableSelectFilter, recordType }: BankAccountFiltersContainerProps) => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const onSubmitSearch = (values: any) => {
        addFilter(values.optionFilter, values[values.optionFilter]);
    };

    let possibleDefaultValues = () => {
        if (typeFilter === 'pendingOperators') {
            return "accountNumber" 
        } else if (typeFilter === 'pendingApprovals') {
            return "typeOfPendingApprovals"
        } else {
            return "searchString"
        }
    }

    const schema = bankAccountFiltersSchema();
    const defaultValues = defaultValuesBankAccountFilterSchema(possibleDefaultValues());

    return (
        <>
            <FormProvider
                defaultValues={defaultValues}
                validationSchema={schema}
                onSubmit={onSubmitSearch}
            >
                <BankAccountFilters
                    enableSelectFilter={enableSelectFilter}
                    recordType={recordType}
                    typeFilter={typeFilter}
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    )
}
