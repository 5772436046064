import { SelectOption } from "components/Forms/FormFields";

type validateCheckItemIncludelistType<T> = {
  list: T[],
  contains: string[],
  attribute: string,
  rule: boolean
}

export function sortToSelectOptionList(options: SelectOption[]) {
  const typeOfDocumentsOptions = options.map((option) => {
    return { label: option?.label, value: option?.value };
  }).sort((a, b) => {
    const label = a.label.toUpperCase();
    const value = b.label.toUpperCase();
    if (label < value) {
      return -1;
    }
    if (label > value) {
      return 1;
    }
    return 0;
  });

  return typeOfDocumentsOptions;
};
export function checkOnlyStatusSome(array: string[], valutToCompare: string): boolean {
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== valutToCompare) {
      return false;
    }
  }
  return true;
}
// check if the arrays are equals
export function arraysAreEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
      return false;
  }
  
  for (let i = 0; i < array1.length; i++) {
      if (array1[i] !== array2[i]) {
          return false;
      }
  }

  return true;
}

export function removeDuplicateData(dataArray: any[], key: string | string[]): any[] {
  const uniqueData: any[] = [];
  const seenIds = new Set<string | number>();

  for (const data of dataArray) {
    const keyValue = Array.isArray(key) ? key.map(k => data[k]).join(',') : data[key];
    if (!seenIds.has(keyValue)) {
      uniqueData.push(data);
      seenIds.add(keyValue);
    }
  }

  return uniqueData;
}

export function formatMessageReasonsDisapprove(array: string[], compareValue: string, complementValue: string): string {
  const firstValue = array[0];
  const arrayWithJoin = array.filter(item => item !== compareValue).join(', ');
  if (firstValue === compareValue) {
    return `${compareValue}: ${complementValue}`
  };
  if (array.includes(compareValue)) {
    return `${arrayWithJoin}. ${compareValue}: ${complementValue}`
  }
  return arrayWithJoin;
}

export function validateCheckItemInclude<T>(props: validateCheckItemIncludelistType<T>): T[] {
  const { attribute, contains, list, rule } = props;
  
  const listFiltered = list?.filter((item: any) => {
    const target = item?.[attribute];
    if (rule) {
      return !contains.includes(target);
    }
    return true;
  });

  return listFiltered;
}