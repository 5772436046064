import { activeConfig } from 'services/config';
import { CognitoUserPool, CognitoUser, AuthenticationDetails, ChallengeName, CognitoUserSession } from 'amazon-cognito-identity-js';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { isEmpty } from 'lodash';

export function currentUser(): CognitoUser | null {

	var appConfig = activeConfig();
	const cognitoUserPool = new CognitoUserPool({ ClientId: appConfig.USER_CLIENT_ID, UserPoolId: appConfig.USER_POOL_ID });
	return cognitoUserPool.getCurrentUser();
}


export type AuthenticationResponse =
	| SuccessResponse
	| FailureResponse
	| MfaRequiredResponse
	| MfaSetupRequiredResponse
	| NewPasswordRequiredResponse

export type SuccessResponse = {
	type: "success"
	user: CognitoUser
}

export type FailureResponse = {
	type: "failure"
	user?: CognitoUser
	failureDetails: FailureDetails
}

export type FailureDetails = {
	message: string | undefined
	error?: any
	code?: number
}

export type MfaRequiredResponse = {
	type: "mfa"
	mfaDetails: any
	user: CognitoUser
}

export type MfaSetupRequiredResponse = {
	type: "mfaSetup"
	user: CognitoUser
	mfaChallengeName?: ChallengeName
	mfaChallengeParameters?: any
	secretCode?: string
}

export type NewPasswordRequiredResponse = {
	type: "newPassword";
	user: CognitoUser;
}

export const generateSessionId = async (username: string | unknown, password: string, poolId: string, cognitoClientId: string, token = '') => {

	const url = `/Auth/StartAuth`;

	const config = {
		headers: { Authorization: `Bearer ${token}` },
	};

	const payload = {
		username: username,
		password: password,
		poolId: poolId,
		cognitoClientId: cognitoClientId
	};

	return await creditAxiosApi.post<string>(url, payload, config);
};

export async function signIn(email: string, password: string): Promise<AuthenticationResponse> {

	var appConfig = activeConfig();
	const cognitoUserPool = new CognitoUserPool({ ClientId: appConfig.USER_CLIENT_ID, UserPoolId: appConfig.USER_POOL_ID });
	return new Promise((resolve) => {
		const finalEmail = email.toLocaleLowerCase().trim();
		const authenticationDetails = new AuthenticationDetails({
			Username: finalEmail,
			Password: password,
		});
		const userData = {
			Username: finalEmail,
			Pool: cognitoUserPool
		};
		let cognitoUser = new CognitoUser(userData);

		const callbacks = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.authenticateUser(authenticationDetails, callbacks);
	});
}

export async function confirmMfa(maybeCognitoUser: CognitoUser | undefined, code: string): Promise<AuthenticationResponse> {
	return new Promise((resolve, reject) => {
		if (!maybeCognitoUser) {
			return reject({ failure: true, failureDetails: "Sessão não foi iniciada" });
		}
		const cognitoUser = maybeCognitoUser!;
		const callbacks = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.sendMFACode(code, callbacks, 'SOFTWARE_TOKEN_MFA');
	});
}

export async function confirmMfaSetup(code: string, cognitoUser: CognitoUser): Promise<AuthenticationResponse> {
	return new Promise((resolve, reject) => {
		cognitoUser.verifySoftwareToken(code, 'SoftwareToken', {
			onSuccess: function (result) {				
				var totpMfaSettings = {
					PreferredMfa: true,
					Enabled: true
				};
				cognitoUser.setUserMfaPreference(null, totpMfaSettings, function (err, result) {
					if (err) {
						console.log("UserService - confirmMfaSetup failed in setUserMfaPreference", err)
						reject(failureFromError(err, cognitoUser));
					}
					resolve({ type: "success", user: cognitoUser });
				});
			},
			onFailure: function (err: any) {
				console.log("UserService - confirmMfaSetup failed in verifySoftwareToken", err)
				reject(failureFromError(err, cognitoUser));
			}
		});

	});
}

export async function completeNewPasswordChallenge(email: string, newPassword: string, cognitoUser: CognitoUser): Promise<AuthenticationResponse> {
	return new Promise((resolve) => {
		const callbacks = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.completeNewPasswordChallenge(newPassword, { email }, {
			...callbacks,
			onSuccess: (session: CognitoUserSession) => {
				cognitoUser.setSignInUserSession(session);
				const authenticationDetails = new AuthenticationDetails({
					Username: email,
					Password: newPassword,
				});
				cognitoUser.authenticateUser(authenticationDetails, callbacks);
			}
		});
	});
}

export async function sendEmailConfirmationCode(cognitoUser: CognitoUser): Promise<AuthenticationResponse> {
	return new Promise((resolve) => {
		const { onSuccessPreVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.getSession((err: any, session: any) => {
			if (err) {
				resolve(failureFromError(err, cognitoUser));
			} else {
				cognitoUser.setSignInUserSession(session);
				cognitoUser.getAttributeVerificationCode("email", {
					onSuccess: onSuccessPreVerification,
					onFailure,
					inputVerificationCode: onSuccessPreVerification,
				});
			}
		});
	});
}

export async function changePassword(cognitoUser: CognitoUser, oldPassword: string, newPassword: string): Promise<AuthenticationResponse> {
	return new Promise((resolve) => {
		const { onSuccess, onFailure } = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.setAuthenticationFlowType('REFRESH_TOKEN');
		cognitoUser.getSession((err: any, session: any) => {
			if (err) {
				resolve(failureFromError(err, cognitoUser));
			} else {
				cognitoUser.changePassword(oldPassword, newPassword, (changePasswordError) => {
					if (changePasswordError) {
						onFailure(changePassword)
					} else {
						onSuccess(session)
					}
				});
			}
		});
	});
}


export async function confirmEmailCode(cognitoUser: CognitoUser, emailCode: string): Promise<AuthenticationResponse> {
	return new Promise((resolve) => {
		const { onSuccessVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.getSession((err: any, session: any) => {
			if (err) {
				resolve(failureFromError(err, cognitoUser));
			} else {
				cognitoUser.setSignInUserSession(session);
				cognitoUser.verifyAttribute("email", emailCode.trim(), {
					onSuccess: onSuccessVerification,
					onFailure
				});
			}
		});
	});
}

export async function sendCodeForPasswordRecover(email: string): Promise<AuthenticationResponse> {

	var appConfig = activeConfig();
	const cognitoUserPool = new CognitoUserPool({ ClientId: appConfig.USER_CLIENT_ID, UserPoolId: appConfig.USER_POOL_ID });
	return new Promise((resolve) => {
		const userData = {
			Username: email.toLocaleLowerCase().trim(),
			Pool: cognitoUserPool
		};
		const cognitoUser = new CognitoUser(userData);
		const { onSuccessPreVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.forgotPassword({
			onSuccess: onSuccessPreVerification,
			onFailure: onFailure
		});
	});
}

export async function confirmPasswordRecover(email: string, verificationCode: string, password: string): Promise<AuthenticationResponse> {

	var appConfig = activeConfig();
	const cognitoUserPool = new CognitoUserPool({ ClientId: appConfig.USER_CLIENT_ID, UserPoolId: appConfig.USER_POOL_ID });
	return new Promise((resolve) => {
		var userData = {
			Username: email.toLocaleLowerCase().trim(),
			Pool: cognitoUserPool
		};
		var cognitoUser = new CognitoUser(userData);
		const { onSuccessVerification, onFailure } = cognitoCallbacks(cognitoUser, resolve);
		cognitoUser.confirmPassword(verificationCode, password, {
			onSuccess: onSuccessVerification,
			onFailure: onFailure
		});
	});
}

const cognitoCallbacks = (cognitoUser: CognitoUser, resolve: any) => {
	return {
		mfaSetup: function (challengeName: string, challengeParameters: string) {
			resolve({ type: "mfaSetup", user: cognitoUser, mfaChallengeParameters: challengeParameters, mfaChallengeName: challengeName });
		},
		mfaRequired: function (codeDeliveryDetails: any) {
			resolve({ type: "mfa", user: cognitoUser, mfaDetails: codeDeliveryDetails });
		},
		totpRequired: (codeDeliveryDetails: any) => {
			resolve({ type: "mfa", user: cognitoUser, mfaDetails: codeDeliveryDetails });
		},
		onSuccessPreVerification: (success: string) => {
			resolve({ type: "verificationEmail", user: cognitoUser, success });
		},
		onSuccessVerification: (success: string) => {
			resolve({ type: "success", user: cognitoUser, success });
		},
		onSuccess: (session: CognitoUserSession) => {
			var appConfig = activeConfig();
			console.log("UserService - authenticate User Success", { session });
			const useMfa = appConfig.USE_MFA;
			if (useMfa) {
				cognitoUser.getUserData((error: any, data) => {
					if (error) {
						if (error.code) {
							error.message = codeToMessage(error.code);
						}
						return resolve({ type: "failure", failureDetails: error });
					}

					if (!data?.UserMFASettingList) {
						cognitoUser.associateSoftwareToken({
							associateSecretCode: function (secretCode) {
								return resolve({ type: "mfaSetup", user: cognitoUser, secretCode: secretCode });
							},
							onFailure: function (err) {
								console.log("UserService - associateSoftwareToken failure", err);
								return resolve(failureFromError(err, cognitoUser));
							}
						});
					} else {
						resolve({ type: "success", user: cognitoUser });
					}
				});
			}
			else {
				resolve({ type: "success", user: cognitoUser });
			}
		},
		onFailure: (error: any) => {
			const message = String(error?.message);
			if (!isEmpty(message) && !!error?.message && error?.message === 'User is disabled.') {
				return resolve({
					type: "failure",
					user: cognitoUser,
					failureDetails: {
						message: "Usuário desativado. Entre em contato com o administrador do sistema.",
						code: error.code,
						error
					}
				})
			}
			console.log("error on cognito callback", error);
			resolve(failureFromError(error, cognitoUser));
		},
		newPasswordRequired: () => {
			resolve({ type: "newPassword", user: cognitoUser });
		}
	}
}

function failureFromError(error: any, user: CognitoUser): FailureResponse {

	return error.code ?
		{
			type: "failure",
			user: user,
			failureDetails: {
				message: codeToMessage(error.code),
				code: error.code,
				error
			}
		}
		: {
			type: "failure",
			user: user,
			failureDetails: {
				message: "Erro inesperado. Tente novamente.",
				error
			}
		};

}

function codeToMessage(code: string) {
	switch (code) {
		case "LimitExceededException":
			return "Limite de tentativas excedido. Tente novamente mais tarde.";
		case "UserNotConfirmedException":
			return "Este usuário não esta confirmado ainda. Confirme antes de prosseguir.";
		case "PasswordResetRequiredException":
			return "Você precisa alterar sua senha. Clique em 'Esqueci minha senha'";
		case "NotAuthorizedException":
			return "Usuário e senha incorretos.";
		case "ResourceNotFoundException":
			return "Usuário e/ou senha incorretos.";
		case "UsernameExistsException":
			return "Este e-mail/nome de usuário já esta sendo utilizado.";
		case "UserNotFoundException":
			return "Este usuário não existe.";
		case "CodeMismatchException":
			return "Código inválido. Tente novamente.";
		case "EnableSoftwareTokenMFAException":
			return "Não foi possível validar o código. Tente novamente.";
		case "ExpiredCodeException":
			return "O código informado expirou. Tente novamente com um novo código";
		default:
			return "Ocorreu um erro ao realizar a requisição. Tente novamente.";
	}
}
