import moment from 'moment';
import { object, date, number } from 'yup';

export const validationPaymentDetailsSchema = () => {
    return object().shape({
        paymentDate: date()
            .typeError('Data inválida.')
            .required('Data de pagamento: Precisa ser preenchido.'),
        paymentValue: number()
            .typeError('Valor do pagamento precisa ser preechido.')
            .required('Valor do pagamento: precisa ser preechido.'),
    });
};

export const defaultValuesPaymentDetailsForm = ({ paymentValue }: { paymentValue?: number | null }) => {
    return {
        paymentDate:
            parseInt(moment().format('HH')) < 22
                ? moment().format('yyyy-MM-DD')
                : moment().add(1, 'day').format('yyyy-MM-DD'),
        paymentValue: paymentValue ?? null,
    };
};
