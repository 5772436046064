import { AxiosResponse } from 'axios';
import { ApiResponseError, UploadNew } from 'contexts/apiRequestContext';
import { creditAxiosApi } from "services/axiosApi/axiosApi";
export async function getSignedUrl(
    fileName: string,
    token: string
): Promise<AxiosResponse<UploadNew, ApiResponseError>> {
    var url = `/Upload/${encodeURI(fileName)}`;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    return await creditAxiosApi.get(url, config);
}

export async function uploadFile(file: any, url: string) {
    var fileName = new URL(url).pathname.split('/').reverse()[0];

    var reader = new FileReader();
    reader.readAsArrayBuffer(file);

    const config = {
        headers: {
            'Content-Type': file.type || 'application/octet-stream',
            'Content-Disposition': `inline; filename="${fileName}"`,
        },
    };

    reader.onload = function (event: any) {
        return creditAxiosApi.put(url, event.target.result, config);
    };
}
