import { createAxiosConfig, creditAxiosApi } from 'services/axiosApi/axiosApi';
import {
    AproverCreditStatusRequestPost,
    CreditStatusReadModel,
} from './types/CreditStatusReadModel';
import { CreditStatusRequestCreateModel } from './types';
import { GetListApiResponse } from 'contexts/apiRequestContext';
import { toDataModel } from 'helpers/validations/validateAndPreparePayload';
import { FieldValues } from 'react-hook-form';

export interface ICreditStatusRequestListProps {
    page: number;
    size: number;
    searchString?: string;
    personId: string;
}

var url = '/CreditStatusRequest';
export const getCreditStatusRequestList = async (
    filters: ICreditStatusRequestListProps,
    token?: string
) => {
    const { personId, ...params } = filters ?? {};
    const axiosConfig = createAxiosConfig(token!, params);

    return await creditAxiosApi.get<GetListApiResponse<CreditStatusReadModel>>(
        url + `?id=${personId}`,
        axiosConfig
    );
};

export async function postCreditStatusRequest(data: FieldValues, token: string) {
    const axiosConfig = createAxiosConfig(token);
    const payload = toDataModel(data, CreditStatusRequestCreateModel);

    return await creditAxiosApi.post(url, payload, axiosConfig);
}

export async function postCreditApproval(data: AproverCreditStatusRequestPost, token: string) {
    const axiosConfig = createAxiosConfig(token);
    return await creditAxiosApi.post(url + '/approval', data, axiosConfig);
}
