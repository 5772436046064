import { Grid, Stack } from '@mui/material'
import { Button, Checkbox } from '@uy3/web-components'
import { SelectFormField } from 'components/Forms/FormFields'
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField'
import PercentageFormField from 'components/Forms/FormFields/PercentageFormField/PercentageFormField'
import { useApiRequest } from 'contexts/apiRequestContext'
import { useFormContext } from 'contexts/formContext'
import React, { useState } from 'react'

type GenerateCnabFormProps = {
    onCloseModal: () => void;
}

const GenerateCnabForm = ({ onCloseModal }: GenerateCnabFormProps) => {
    const { watch, readOnly, setValue, getValues } = useFormContext();
    const { submitting } = useApiRequest();

    const [agioDesagio, setAgioDesagio] = useState<boolean>(
        getValues('assignmentCalculation') !== false
    );
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        setAgioDesagio(checked);
        if (checked) {
            setValue('assignmentCalculation', true);
        } else {
            setValue('assignmentCalculation', false);
        }
    };

    return (
        <Stack spacing={2}>
            <Grid mt={1}>
                <Checkbox
                    disabled={readOnly}
                    onChange={handleCheckboxChange}
                    checked={agioDesagio ? true : false}
                    label="Incluir Ágio/Deságio no CNAB"
                    color="primary"
                />
            </Grid>
            {agioDesagio && (
                <>
                    <Grid item xs={6}>
                        <SelectFormField
                            name="type"
                            label="Tipo de taxa"
                            fullWidth
                            options={[
                                { label: 'Valor absoluto', value: 'Absolute' },
                                { label: 'Percentual', value: 'Percentage' },
                            ]}
                        />
                    </Grid>
                    {watch('type') === 'Absolute' ? (
                        <Grid item xs={6}>
                            <CurrencyFormField
                                label="Custo de cessão"
                                name="amount"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <PercentageFormField
                                label="Custo de cessão (%)"
                                name="amount"
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                    )}
                    <Grid item xs={6}>
                        <SelectFormField
                            name="mode"
                            label="Modelo de cálculo"
                            fullWidth
                            required
                            options={[
                                { label: 'Padrão', value: 'Default' },
                                { label: 'Valor Inicial ', value: 'InitialValue' },
                                { label: 'Valor Presente', value: 'PresentValuePayment' },
                                { label: 'Valor Presente Parcela', value: 'PresentValueCreditNote' },
                            ]}
                        />
                    </Grid>
                </>
            )}

            <Grid container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
            >
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCloseModal}
                    size="medium"
                    sx={{ mt: 3, mr: 2 }}
                >
                    Não, cancelar
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    size="medium"
                    disabled={submitting}
                    sx={{ mt: 3 }}
                >
                    Sim, confirmar
                </Button>
            </Grid>
        </Stack>
    )
}

export default GenerateCnabForm
