import { Stack } from "@mui/material";
import { FormProvider, useFormContext } from "contexts/formContext";
import { usePersonsList } from "contexts/personContext";
import { useBillingParametersList, useBillingParametersMutation } from "contexts/wallet/billingParameters/billingParametersContext";
import { useCommunicationSettingList, useCreateCommunicationSettingMudataion } from "contexts/wallet/communicationSetting/communicationSettingContext";
import { useWalletDataList } from "contexts/wallet/walletContext/walletContext";
import { formatDocumentNumber } from "helpers/formats/DocumentNumber";
import { useState } from "react";
import InstallmentCreateFormFields from "./InstallmentCreateFormFields";
import { FieldValues } from "react-hook-form";
import { CommunicationSettingFormContainer } from "pages/WalletManagement/CommunicationSetting/Drawers/CommunicationSettingForm/CommunicationSettingFormContainer";
import { defaultValuesCommunicationSetting, validationSchemaCommunicationSetting } from "pages/WalletManagement/CommunicationSetting/Drawers/CommunicationSettingForm/CommunicationSettingFormSchema";
import { ApiResponseError, showSuccessToast, toastState, ToastType, useApiRequest } from "contexts/apiRequestContext";
import { ICreateCommunicationSetting } from "services/walletManagement/communicationSetting/communicationSetting.types";
import Toast from "components/Toast/Toast";
import { Drawer } from "@uy3/web-components";
import { defaultValuesAddInterestModelForm, interestModelSchemaValidation } from "pages/WalletManagement/BillingSetting/Drawers/BillingSettingForm/AddBillingSettingFormSchema";
import AddBillingSettingForm from "pages/WalletManagement/BillingSetting/Drawers/BillingSettingForm/AddBillingSettingForm";
import { IBillingSettingFull } from "services/walletManagement/billingSetting/billingSetting.types";
import { isEmpty } from "lodash";
import { isValidString } from "helpers/formats/StringFormats";
import { AddPayerFormContainer } from "components/GenericForms/AddPayer/AddPayerFormContainer";

export const InstallmentCreateForm = () => {
    const { setValue, watch } = useFormContext();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [handleAction, setHandleAction] = useState<string | undefined>(undefined);
    const walletCode = watch('walletCode');
    const { handleErrorException } = useApiRequest();
    
    const onSuccess = (response: FieldValues, type: string) => {
        let message = "";
        
        if (type === 'communicationSetting') {
            message = 'Sucesso ao criar régua de comunicação';
            setValue('communicationSettingId', response?.id!);
            setValue('communicationSettingDisplay', `(${response?.walletCode}) - ${response?.name}`);
        }
        
        if (type === 'billingSetting') {
            message = "Sucesso ao criar régua de cobrança";
            setValue('billingSettingId', response?.id!);
            setValue('billingSettingDisplay', `(${response?.walletCode}) - ${response?.name}`);
        }
        
        if (!isEmpty(message)) showSuccessToast(message, '', setToast);

        return onClose();
    };
    
    const onError = (error: ApiResponseError) => handleErrorException(error, setToast)

    const { mutateAsync } = useBillingParametersMutation((data) => onSuccess(data, 'billingSetting'), onError);

    const { mutateAsync: createCommunicationSettingMutateAsync } =
        useCreateCommunicationSettingMudataion(
            (data) => onSuccess(data, 'communicationSetting'),
            onError
        );

    const createCommunicationSetting = async (values: ICreateCommunicationSetting) => {
        await createCommunicationSettingMutateAsync(values);
    };


    const handleBillingSetting = async (values: IBillingSettingFull) => {
        await mutateAsync(values)
    };

    const { personAutoCompleteProps } = usePersonsList({ page: 0, size: 10 });
    const { walletCodeAutoCompleteProps } = useWalletDataList({ page: 0, size: 10 });
    const { billingParameterAutoCompleteProps } = useBillingParametersList({ page: 0, size: 10, walletsCode: walletCode });
    const { communicationSettingAutocomplete } = useCommunicationSettingList({ walletsCode: walletCode });

    const onSuccessWhenAddPerson = (values: FieldValues) => {
        setValue('personIdDisplay', `${values?.name} - ${formatDocumentNumber(values?.registrationNumber)}`);
        setValue('personId', values?.id); 
        setValue('personDiscriminator', values?.discriminator);
    };

    const onClose = () => setHandleAction(undefined);

    const hasWalletCode = isValidString(String(walletCode));

    return (
        <Stack spacing={2} mt={-2} mb={2}>
            <Toast toast={toast} setToast={setToast} />

            <InstallmentCreateFormFields
                {...{
                    setHandleAction: setHandleAction,
                    personAutocomplete: personAutoCompleteProps,
                    walletAutocomplete: walletCodeAutoCompleteProps,
                    billingSettingAutocomplete: billingParameterAutoCompleteProps,
                    communicationSettingAutocomplete: communicationSettingAutocomplete,
                }}
            />

            <AddPayerFormContainer {...{
                title: "Adicionar pagador",
                onClose: onClose,
                open: handleAction === 'add-person',
                toggleDrawer: true,
                setToast,
                onSuccessGeneric: onSuccessWhenAddPerson
            }} />

            <CommunicationSettingFormContainer {...{
                title: "Nova régua de comunicação",
                open: handleAction === 'add-communication-setting',
                onSubmit: createCommunicationSetting,
                schema: {
                    defaultValues: defaultValuesCommunicationSetting({ walletCode }),
                    validation: validationSchemaCommunicationSetting()
                },
                onClose,
                toggleDrawer: true,
                disableWalletCodeField: hasWalletCode
            }} />

            <Drawer
                open={handleAction === 'add-billing-setting'}
                title='Adicionar régua de cobrança'
                description='Possibilidade de inclusão de juros, multas e descontos.'
                onClose={onClose}
                toggleDrawer
                anchor="right"
                children={
                    <FormProvider
                        defaultValues={defaultValuesAddInterestModelForm({ walletCode })}
                        validationSchema={interestModelSchemaValidation()}
                        onSubmit={handleBillingSetting}
                    >
                        <AddBillingSettingForm
                            onCloseDrawer={onClose}
                            walletCodeAutoCompleteProps={walletCodeAutoCompleteProps}
                            isEdit={false}
                            disableWalletCodeField={hasWalletCode}
                        />
                    </FormProvider>
                }
            />

        </Stack>
    );
};