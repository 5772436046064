import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import { firstInstructionDebtCollectionOptions } from 'services/walletManagement/billing/billing.types';
import { sendSubmitEvent } from 'contexts/apiRequestContext';
import { MaskedInput } from 'components/Forms/MaskedInput';
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants';

export const installmentStatusOptions = [
    { label: "Em aberto", value: "Created" },
    { label: "Registrado", value: "Registered" },
    { label: "Pago", value: "Paid" },
    { label: "Baixado", value: "Cancelled" }
]

function InstallmentFiltersForm() {
    const { watch } = useFormContext();
    const isLegalPerson = watch('payerRegistrationNumber')?.replace(/\D/g, '')?.length > 11;


    const ActionSubmitSearch = () => {
        return (
            <InputAdornment position="end">
                <IconButton type="submit">
                    <SearchIcon
                        sx={{ width: 25, height: 25 }}
                        htmlColor="#666666"
                    />
                </IconButton>
            </InputAdornment>
        )
    }

    return (
        <>
            <SelectFormField
                name="optionFilter"
                label="Filtrar por"
                variant="outlined"
                fullWidth
                options={[
                    { label: 'Código convênio', value: 'walletsCode' },
                    { label: 'Status', value: 'statusesValue' },
                    { label: 'Data de criação', value: 'initialCreatedDate' },
                    { label: 'Nome do devedor', value: 'payerName' },
                    { label: 'CPF/CNPJ do devedor', value: 'payerRegistrationNumber' },
                    { label: 'Instrução de protesto', value: 'firstInstructionDebtCollection' },
                    { label: 'Quantidade de parcelas', value: 'billingCount' },
                ]}
            />

            {watch('optionFilter') === 'walletsCode' && (
                <TextFormField
                    name="walletsCode"
                    variant="outlined"
                    label="Filtrar por código convênio"
                    fullWidth
                    InputProps={{
                        endAdornment: <ActionSubmitSearch />,
                    }}
                />
            )}

            {watch('optionFilter') === 'billingCount' && (
                <TextFormField
                    name="billingCount"
                    variant="outlined"
                    label="Filtrar por quantidade de parcelas"
                    fullWidth
                    InputProps={{
                        endAdornment: <ActionSubmitSearch />,
                    }}
                />
            )}

            {watch('optionFilter') === 'payerName' && (
                <TextFormField
                    name="payerName"
                    variant="outlined"
                    label="Filtrar por nome do devedor"
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'firstInstructionDebtCollection' && (
                <SelectFormField
                    label="Instrução de protesto"
                    name="firstInstructionDebtCollection"
                    options={firstInstructionDebtCollectionOptions}
                    showEndAdornment
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'payerRegistrationNumber' && (
                <TextFormField
                    label="Filtrar por CPF/CNPJ"
                    name="payerRegistrationNumber"
                    fullWidth
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                        endAdornment: (
                            <InputAdornment position="end" sx={{ marginRight: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    required
                    variant="outlined"
                />
            )}
            {watch('optionFilter') === 'statusesValue' && (
                <SelectFormField
                    label="Filtrar por status"
                    name="statusesValue"
                    fullWidth
                    showEndAdornment
                    options={installmentStatusOptions}
                    onClick={sendSubmitEvent}
                />
            )}
            {watch('optionFilter') === 'initialCreatedDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data de criação inicial"
                        name="initialCreatedDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data de criação final"
                        name="finalCreatedDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    );
}

type InstallmentFiltersProps = {
    currentValues: any;
    removeFilterSelected: (index: number) => void;
};

export const InstallmentFilters = ({ currentValues, removeFilterSelected }: InstallmentFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<InstallmentFiltersForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    );
};
