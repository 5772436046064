import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@uy3/web-components';
import { Grid } from '@mui/material';

interface SpeedometerProps {
    value: number;
};

export const scoreText = (score: number) => {
    if (score) {
        var text = "Muito alto";
        var color = "#b13030";
        if (score < 10) {
            text = "Muito baixo";
            color = "#68c22e";
        } else if (score < 46) {
            text = "Baixo";
            color = "#4ea3ff";
        } else if (score < 80) {
            text = "Médio";
            color = "#f0cd36";
        } else if (score < 90) {
            text = "Alto";
            color = "#F83";
        }
        return (
            <Typography
                variant="h6"
                style={{
                    background: color,
                    color: 'white',
                    padding: 2,
                    borderRadius: 18,
                    minWidth: 100,
                    textAlign: 'center'
                }}>
                {text}
            </Typography>
        );
    }
};

const SpeedometerRiskScore: React.FC<SpeedometerProps> = ({ value }) => {
    return (
        <Box p={3}>
            <Grid container justifyContent="left">
                    <Grid item>
                        {scoreText(value)}
                    </Grid>
            </Grid>
        </Box>
    );
};

export default SpeedometerRiskScore;
