import moment from "moment";

export function dateDiff(
  startingDate: string | number | Date | undefined,
  endingDate: string | number | Date | undefined
) {
  if (!startingDate) {
    return 'N/D';
  }

  var startDate = new Date(new Date(startingDate).toISOString().substring(0, 10));

  if (!endingDate) {
    endingDate = new Date().toISOString().substring(0, 10);
  }
  var endDate = new Date(endingDate);
  if (startDate > endDate) {
    var swap = startDate;
    startDate = endDate;
    endDate = swap;
  }
  var startYear = startDate?.getFullYear();
  var february =
    (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0 ? 29 : 28;
  var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  var yearDiff = endDate?.getFullYear() - startYear;
  var monthDiff = endDate?.getMonth() - startDate?.getMonth();
  if (monthDiff < 0) {
    yearDiff--;
    monthDiff += 12;
  }
  var dayDiff = endDate?.getDate() - startDate?.getDate();
  if (dayDiff < 0) {
    if (monthDiff > 0) {
      monthDiff--;
    } else {
      yearDiff--;
      monthDiff = 11;
    }
    dayDiff += daysInMonth[startDate?.getMonth()];
  }

  let yearLabel = 'anos';
  if (yearDiff === 1) {
    yearLabel = 'ano';
  }

  let monthLabel = 'meses';
  if (monthDiff === 1) {
    monthLabel = 'mês';
  }

  let dayLabel = 'dias';
  if (dayDiff === 1) {
    dayLabel = 'dia';
  }

  if (yearDiff > 0 && monthDiff > 0) {
    return `${yearDiff} ${yearLabel} e ${monthDiff} ${monthLabel}`;
  } else if (yearDiff > 0) {
    return `${yearDiff} ${yearLabel}`;
  } else if (monthDiff > 0) {
    return `${monthDiff} ${monthLabel}`;
  } else {
    return `${dayDiff} ${dayLabel}`;
  }
}

export const formatDeadLine = (deadLine: string) => {
  const hour = "23:59:59";
  const data = new Date(deadLine);
  const [hours, minutes, seconds] = hour.split(':');
  //@ts-ignore
  data.setUTCHours(hours, minutes, seconds);
  return data.toISOString();
}


const hoursStart = { hour: 0, minute: 0, second: 0, millisecond: 0 };

export const dateFormatedNonHours = (dateIso: string) => {
  const lastLetter = dateIso.indexOf('T');
  return dateIso.slice(0, lastLetter);
}

export const calculatePeriodRangeTwoDate = (startDate: string, endDate: string): {
  days: number,
  month: number
} => {
  const dateubtract: number = new Date(endDate).getTime() - new Date(startDate).getTime();
    const days =  dateubtract / (1000 * 60 * 60 * 24) as number;
  const month = days / 30;
  return {
    days,
    month: String(month).startsWith('0') ? 1 : Number.parseInt(month.toString())
  };
}

export const applyFilterByPeriod = (index: number) => {
  let initialDate: Date = new Date();
  let finalDate: Date = moment().add(30, 'days').toDate();

  const setDates = (startDate: Date) => {
    initialDate = startDate;
    finalDate = new Date();
  };

  const option: { [index: number]: () => void } = {
    0: () => setDates(moment().set(hoursStart).toDate()),
    1: () => setDates(moment().subtract(1, 'days').toDate()),
    2: () => setDates(moment().subtract(1, 'weeks').toDate()),
    3: () => setDates(moment().subtract(1, 'months').toDate()),
    4: () => setDates(moment().subtract(3, 'months').toDate()),
    5: () => setDates(moment().subtract(6, 'months').toDate())
  };

  if (option[index]) {
    option[index]();
  }

  const initialDateFormated = dateFormatedNonHours(initialDate.toISOString());
  const finalDateFormated = dateFormatedNonHours(finalDate.toISOString());

  return {
    initialDate: initialDateFormated,
    finalDate: finalDateFormated
  };
};

export const currentDateWithSetHours = new Date().setHours(0, 0, 0, 0);
export function toIsoStringWithTimezone(date: Date) {
  const pad = (num: number) => String(num).padStart(2, '0');

  // Ajustando manualmente a data para o fuso horário de São Paulo (UTC-3)
  const year = date?.getFullYear();
  const month = pad(date?.getMonth() + 1);
  const day = pad(date?.getDate());
  const hours = pad(date?.getHours());
  const minutes = pad(date?.getMinutes());
  const seconds = pad(date?.getSeconds());
  const milliseconds = String(date?.getMilliseconds()).padStart(3, '0');

  // Construindo a string no formato ISO com o fuso horário de São Paulo
  const dateFormated = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}-03:00`;
  return dateFormated;
}

export function getRelativeDate(date: string): string {
  const now = moment();
  const inputDate = moment(date);

  if (inputDate.isSame(now, 'day')) {
    return 'Hoje';
  } else if (inputDate.isSame(moment().subtract(1, 'days'), 'day')) {
    return 'Ontem';
  } else if (inputDate.isSame(now, 'week')) {
    return 'Essa Semana';
  } else if (inputDate.isSame(now, 'month')) {
    return 'Este Mês';
  } else if (inputDate.isSame(moment().subtract(1, 'months'), 'month')) {
    return 'Mês Passado';
  } else {
    return inputDate.format('MMMM YYYY');
  }
}


export function formatCustomDate(date: string) {
  const inputDate = moment(date);
   
  const day = inputDate.format('DD');
  const month = inputDate.format('MM');
  const year = inputDate.format('YYYY');
  const time = inputDate.format('HH:mm');

  if (Number(year) === new Date().getFullYear()) {
    return `${day}/${month} às ${time}`;
  };
   
  return `${day}/${month}/${year} às ${time}`;
}

export function formatDate(date: string) {
  return moment(date).format('DD/MM/YYYY');
}