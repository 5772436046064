import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import { Grid, Stack } from '@mui/material';
import { Typography } from '@uy3/web-components';
import { RenderAvatarsName } from 'components/RenderAvatarsName/RenderAvatarsName';
import { SelectOption } from 'components/Forms/FormFields';
import { ICreditor } from 'contexts/bankAccount/bankAccountContext';
import { isEmpty } from 'lodash';
import { activeTheme } from 'services/theme';

interface ITypographyValue {
  value: string;
  title: string;
  hideIsEmpty: boolean
  textBold: boolean | undefined
}

type DetailsType = {
  textBold?: boolean | undefined;
} & SelectOption;

interface InformationCardHeaderProps {
  title: string;
  details: DetailsType[];
  creditors?: ICreditor[]
  Actions: ReactNode
  width?: string
  gridTemplateColumns?: string
  hideIsEmpty?: boolean
}

const theme = activeTheme();

const SpanBold = styled('span')(({ theme }) => ({
  fontWeight: 700
}));

const TypographyValue = ({ title, value, hideIsEmpty, textBold }: ITypographyValue) => {
  if (hideIsEmpty && isEmpty(value)) return <></>
  return (
    <Grid item xs={6}>
      <Typography variant='md' lineHeight={hideIsEmpty ? '30px' : '30px'}>
        <SpanBold>{title}: </SpanBold>
        <span
          style={{
            color: textBold ? theme.palette.primary.main : theme.palette.common.black,
            fontWeight: textBold ? 'bold' : 'normal'
          }}>
          {value}
        </span>
      </Typography>
    </Grid>
  )
}

const InformationCardHeader: React.FC<InformationCardHeaderProps> = ({
  title,
  details,
  Actions,
  creditors = [],
  width = '50%',
  gridTemplateColumns = "auto auto auto",
  hideIsEmpty = false
}) => {
  return (
    <Stack borderRadius='8px' padding='15px 23px' border='0.5px solid #BABFD0' position='relative'>
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Typography variant="xxxl" color="neutral.dark" mb={-1} fontWeight={700}>
          {title}
        </Typography>
        {Actions}
      </Stack>
      <Stack
        direction='row'
        display='grid'
        gridTemplateColumns={gridTemplateColumns}
        width={width}
        mt={3}
        gap={1}
      >
        {details.map(({ label, value, textBold }) => (
          <TypographyValue
            title={label}
            value={value as string}
            hideIsEmpty={hideIsEmpty}
            textBold={textBold}
          />
        ))}
      </Stack>

      {creditors.length > 0 &&
        <Stack sx={{ position: 'absolute', right: '20px', bottom: '10px' }}>
          <RenderAvatarsName list={creditors ?? []} />
        </Stack>}
    </Stack>
  );
}

export default InformationCardHeader;