import { useQueryClient } from '@tanstack/react-query';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { useParams } from 'react-router';
import { DisapproveDataProps, SignatureCreditNoteProps, disapproveCreditNoteById, signatureCreditNoteById } from 'services/creditNote';
import { defaultValuesRejectForm, validationSchemaRejectForm } from './RejectSchema';
import { FieldValues } from 'react-hook-form';
import RejectForm from './RejectForm';
import { formatMessageReasonsDisapprove } from 'helpers/formats/ArrayFormats';

type RejectCreditNoteProps = {
    setPopupProps: (props: any) => void;
    statusCreditNote: string;
    onErrorCatch: (response: ApiResponseError) => void;
    navigateWithFilters: (path: string) => void
};

type SignatureApproveType = {
    message: string;
    reasonDisapprovalDisplay: string[]
};

export const RejectCreditNote = ({ setPopupProps, statusCreditNote, onErrorCatch, navigateWithFilters }: RejectCreditNoteProps) => {
    const { startRequest, endRequest } = useApiRequest();
    const { watch } = useFormContext();
    const { token } = useIdentity();
    const { id } = useParams();
    const queryClient = useQueryClient();

    const handleSignature = async (values: SignatureApproveType, type: "signature" | "approveSignature") => {
        const { message, reasonDisapprovalDisplay } = values;

        const payloadMessage = {
            id: id!,
            token: token!,
            reject: true,
            message: formatMessageReasonsDisapprove(reasonDisapprovalDisplay, 'Outros motivos', message)!,
            type
        } as SignatureCreditNoteProps;

        startRequest();
        signatureCreditNoteById(payloadMessage)
            .then(() => {
                queryClient.invalidateQueries({
                    queryKey: ['credit-note-list'],
                    refetchType: 'active',
                });
                navigateWithFilters('/ccb/operacoes');
            }).catch((response) => onErrorCatch(response));
    };

    const handleDisapprove = async (values: FieldValues) => {
        const { message, insertBlock, blockUntil, complianceChecklist } = values;
        const payload = {
            message,
            insertBlock,
            blockUntil,
            attributeTypes: complianceChecklist,
            rejectionReasonCode: values?.rejectionReasonCode
        } as DisapproveDataProps;
  
        startRequest();
        await disapproveCreditNoteById(payload, id!, token!)
            .then(() => {
                endRequest(true);
                queryClient.invalidateQueries({
                    queryKey: ['credit-note-list'],
                    refetchType: 'active'
                });
                navigateWithFilters('/ccb/operacoes');
            }).catch((response) => onErrorCatch(response));
    };

    const schema = validationSchemaRejectForm(statusCreditNote);
    const defaultValues = defaultValuesRejectForm;

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onChangeField={[
                {
                    fieldName: 'insertBlock',
                    delegate: (value: string | null, setValue: (name: any, value: any) => void) =>
                        setValue('insertBlock', value),
                }
            ]}
            onSubmit={(values) =>
                statusCreditNote === 'SignaturesValidation'
                    ? handleSignature(values, 'approveSignature')
                    : handleDisapprove(values)
            }
        >
            <RejectForm
                onClose={() => setPopupProps(undefined)}
                statusCreditNote={statusCreditNote}
                personInformation={{
                    discriminator: watch('personDiscriminator'),
                    personId: watch('personId')
                }}
            />
        </FormProvider>
    );
};
