import { string, object, boolean, number, InferType } from 'yup';

const isNullOrEmpty = (value: string | null) => {
    return value == null || value === "";
};

export function validationSchemaBankAccountProductForm() {
    return object().shape({
        rateExternalId: string().typeError('ID do pacote: Precisa ser preenchido.').nullable().transform((value, _) => {
            return value === "" ? null : value;
        }),
        name: string().typeError('Nome do Produto: Precisa ser preenchido.').required("Nome do Produto: Precisa ser preenchido."),
        templateDoc: object().when("useSignaturePortal", useSignaturePortal => {
            return useSignaturePortal === true ?
                object().shape({
                    fileName: string().typeError('Modelo de contrato é obrigatório').required("Modelo de contrato: Precisa ser preenchido.")
                }) :
                object().nullable().transform((value, _) => {
                    return value?.fileName === null ? null : value;
                });
        }),
        useSignaturePortal: boolean(),
        bankingAccountType: string().typeError('Selecione o tipo de conta.').required("Selecione o tipo de conta."),
        signaturePortalProvider: string().when("useSignaturePortal", {
            is: true,
            then: string().typeError('Selecione a Certificadora.').required("Selecione a Certificadora."),
            otherwise: string().nullable()
        }),
        signatureType: string().when("useSignaturePortal", {
            is: true,
            then: string().typeError('Selecione a forma de envio para assinatura.').required("Selecione a forma de envio para assinatura."),
            otherwise: string().nullable()
        }),
        selfieSignature: boolean(),
        viewType: string().typeError('Selecione a visibilidade do produto.').required("Selecione a visibilidade do produto."),
        packageValue: number()
            .typeError('Valor precisa ser um número.')
            .required("Valor é obrigatório.")
            .transform((_, value) => isNullOrEmpty(value) ? 0 : value),
        sendEmailNotification: boolean(),
        unicoMinimumScoreForSignatureValidation: number().when('signaturePortalProvider', {
            is: "UnicoCheck",
            then: number()
                .typeError('Score mínimo inválido.')
                .required('Score mínimo: Precisa ser preenchido.'),
            otherwise: number().nullable()
        }),
        unicoTemplateName: string().when('signaturePortalProvider', {
            is: "UnicoCheck",
            then: string()
                .typeError('Nome do template inválido.')
                .required('Nome do template: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        signatureValidationStep: boolean(),
        instrumentApproval: boolean()
    });
};

let inferedSchema = validationSchemaBankAccountProductForm();
export type BankAccountProductFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesBankAccountProductForm = {
    rateExternalId: null,
    name: null,
    templateDoc: null,
    useSignaturePortal: false,
    bankingAccountType: "Payment",
    signaturePortalProvider: "RBM",
    signatureType: "email",
    selfieSignature: false,
    viewType: "BottomToTop",
    packageValue: 0, 
    sendEmailNotification: false,
    unicoMinimumScoreForSignatureValidation: 0,
    unicoTemplateName: null,
    signatureValidationStep: false,
    instrumentApproval: false
};