import { FormProvider } from 'contexts/formContext'
import React, { FC } from 'react'
import { defaultValuesInformationKeyPix, validationInformationKeyPixSchema } from './InformationPixKeySchema'
import { InformationPixKey } from './InformationPixKey'
import { IPixKeyData } from 'contexts/bankAccount/bankAccountType'
import { FieldValues } from 'react-hook-form'

type InformationPixKeyContainerProps = {
  onClose: () => void;
  pixKeyData: IPixKeyData;
  onConfirmationKeyPix: (values: FieldValues) => void
}

export const InformationPixKeyContainer: FC<InformationPixKeyContainerProps> = ({ onClose, pixKeyData, onConfirmationKeyPix }) => {
  return (
    <FormProvider
      validationSchema={validationInformationKeyPixSchema()}
      defaultValues={defaultValuesInformationKeyPix}
      onSubmit={onConfirmationKeyPix}
    >
      <InformationPixKey onClose={onClose} pixKeyData={pixKeyData} />
    </FormProvider>
  )
}