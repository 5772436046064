import { Stack } from '@mui/material';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { channelOptionsList } from '../Options';

export default function LocationForm() {
    return (
        <Stack spacing={3}>
            <SelectFormField
                label="Canal utilizado para execução"
                name="location.channel"
                options={channelOptionsList}
                fullWidth
                required
            />
            <TextFormField
                name="location.local"
                variant="outlined"
                label="Local da Fraude"
                fullWidth
            />
            <TextFormField
                name="location.ipAddress"
                variant="outlined"
                label="Endereço de IP"
                fullWidth
                type='number'
            />
            <TextFormField
                name="location.device"
                variant="outlined"
                label="Dispositivo"
                fullWidth
            />
        </Stack>
    );
}
