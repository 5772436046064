import { Grid, Stack } from '@mui/material';
import { AddIcon, Button, Typography, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react';
import { Link } from 'react-router-dom';
import { activeTheme } from 'services/theme';

interface ICreditProductHeaderProps {
    isFetching: boolean;
    refetch: () => void;
    disableCreateProduct: boolean;
}

const theme = activeTheme();
const CreditProductHeader: React.FC<ICreditProductHeaderProps> = ({ isFetching, refetch, disableCreateProduct }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    return (
        <Stack justifyContent="space-between" alignItems="center" direction="row">
            <Typography
                variant="h4"
                color="neutral.dark"
                fontStyle="normal"
                fontWeight="700"
                fontSize="32px"
                lineHeight="38.4px"
            >
                Produtos de crédito
            </Typography>
            <Grid mr={2}>
                <Button
                    sx={{ color }}
                    variant="text"
                    disabled={isFetching}
                    onClick={() => refetch()}
                    size="large"
                    startIcon={<UpdateIcon htmlColor={color} sx={iconSx} />}
                >
                    Atualizar
                </Button>
                {!disableCreateProduct && <Link to="/cadastro/produtos-credito/novo" style={{ textDecoration: 'none' }}>
                    <Button
                        sx={{ color }}
                        variant="text"
                        size="large"
                        startIcon={<AddIcon htmlColor={color} sx={iconSx} />}
                    >
                        Novo cadastro
                    </Button>
                </Link>}
            </Grid>
        </Stack>
    );
};

export default CreditProductHeader;
