import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const GlassesIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M18 2.25C17.5858 2.25 17.25 2.58579 17.25 3C17.25 3.41421 17.5858 3.75 18 3.75V2.25ZM6 3.75C6.41421 3.75 6.75 3.41421 6.75 3C6.75 2.58579 6.41421 2.25 6 2.25V3.75ZM9.25 17.5C9.25 19.0188 8.01878 20.25 6.5 20.25V21.75C8.84721 21.75 10.75 19.8472 10.75 17.5H9.25ZM6.5 20.25C4.98122 20.25 3.75 19.0188 3.75 17.5H2.25C2.25 19.8472 4.15279 21.75 6.5 21.75V20.25ZM3.75 17.5C3.75 15.9812 4.98122 14.75 6.5 14.75V13.25C4.15279 13.25 2.25 15.1528 2.25 17.5H3.75ZM6.5 14.75C8.01878 14.75 9.25 15.9812 9.25 17.5H10.75C10.75 15.1528 8.84721 13.25 6.5 13.25V14.75ZM20.25 17.5C20.25 19.0188 19.0188 20.25 17.5 20.25V21.75C19.8472 21.75 21.75 19.8472 21.75 17.5H20.25ZM17.5 20.25C15.9812 20.25 14.75 19.0188 14.75 17.5H13.25C13.25 19.8472 15.1528 21.75 17.5 21.75V20.25ZM14.75 17.5C14.75 15.9812 15.9812 14.75 17.5 14.75V13.25C15.1528 13.25 13.25 15.1528 13.25 17.5H14.75ZM17.5 14.75C19.0188 14.75 20.25 15.9812 20.25 17.5H21.75C21.75 15.1528 19.8472 13.25 17.5 13.25V14.75ZM10.75 18C10.75 17.3096 11.3096 16.75 12 16.75V15.25C10.4812 15.25 9.25 16.4812 9.25 18H10.75ZM12 16.75C12.6904 16.75 13.25 17.3096 13.25 18H14.75C14.75 16.4812 13.5188 15.25 12 15.25V16.75ZM18 3.75C19.2426 3.75 20.25 4.75736 20.25 6H21.75C21.75 3.92893 20.0711 2.25 18 2.25V3.75ZM20.25 6V17.5H21.75V6H20.25ZM6 2.25C3.92893 2.25 2.25 3.92893 2.25 6H3.75C3.75 4.75736 4.75736 3.75 6 3.75V2.25ZM2.25 6V17.5H3.75V6H2.25Z"
          fill={htmlColor}
        />
      </svg>
    </SvgIcon>
  );
};

GlassesIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
