import { useQuery } from "@tanstack/react-query";
import { AutocompleteOptionList } from "components/Forms/Autocomplete";
import { useApiRequest } from "contexts/apiRequestContext";
import { useIdentity } from "contexts/identityContext";
import { getServiceQueueAsync } from "services/chatbot/ServiceQueue";
import { IServiceQueueReadModel } from "services/chatbot/types";

export function useGetServiceQueueList() {
    const { token } = useIdentity();
    const { startRequest, endRequest } = useApiRequest();

    const queryData = useQuery({
        enabled: !!token,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['input-settings-list'],
        queryFn: async () => {
            startRequest();
            const { data } = await getServiceQueueAsync(token!);
            endRequest(true);
            return data;
        },
    });

    const mapperFromAutocomplete = (x: IServiceQueueReadModel) => {
        return {
            label: `(${x.name}) - ${x.display}`,
            value: x.id
        }
    }

    const serviceQueueAutocomplete = {
        listOptions: queryData !== undefined && (queryData?.data??[])?.length > 0 ? queryData?.data?.map(mapperFromAutocomplete) : [],
        loading: queryData.isLoading || queryData.isFetching,
        onSearch: () => { }
    } as AutocompleteOptionList

    return {
        ...queryData, 
        serviceQueueAutocomplete
    }
};