import { GridColDef } from "@mui/x-data-grid";
import { DataTable, DeleteIcon, EditIcon } from "@uy3/web-components";
import { RowActions } from "components/RowActions/RowActions";
import { useTenant } from "contexts/tenantContext";

type AccountOperatorsProps = {
    page: number;
    queryData: any;
    rowsPerPage: number;
    setPage: (newPage: number) => void;
    setRowsPerPage: (page: number) => void;
    onDeleteOperator: (row: any) => void
    onEditOperator: (row: any) => void
}

export const AccountOperatorsList = ({
    page,
    queryData,
    rowsPerPage,
    setPage,
    setRowsPerPage,
    onDeleteOperator,
    onEditOperator
}: AccountOperatorsProps) => {
    const { isRootTenancy } = useTenant();
    
    const columnsPaymentList: GridColDef[] = [
        {
            field: 'user.userIdDisplay',
            headerName: 'Nome',
            hideSortIcons: true,
            flex: 1,
            editable: false,
            renderCell: (cellValue: any) => {
                const userName = cellValue.row.user.userIdDisplay;
                return isRootTenancy ? `${userName} - (${cellValue?.row?.user?.tenantDisplay})` : userName;
            }
        },
        {
            field: 'levelDisplay',
            headerName: 'Nível',
            hideSortIcons: true,
            flex: 1,
            editable: false,
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 1,
            editable: false,
        },
        {
            field: 'actions',
            headerName: "Ações",
            hideSortIcons: true,
            editable: false,
            renderCell: ({ row }) => {
                const index = queryData?.data?.findIndex((find: any) => find.id === row.id);
                const pending = row?.statusDisplay === 'Aguardando aprovação'; 
                return (
                    <RowActions
                        listButtons={[
                            {
                                action: () => onDeleteOperator(row),
                                disabled: false,
                                icon: <DeleteIcon />,
                                label: "Excluir"
                            },
                            {
                                action: () => onEditOperator(index),
                                disabled: pending,
                                icon: <EditIcon />,
                                label: "Editar"
                            }
                        ]}
                    />
                )
            }
        }
    ];
    return (
        <>
            <DataTable
                getRowId={(row: any) => row?.id}
                columns={columnsPaymentList}
                rows={queryData?.data ?? []}
                page={page}
                rowCount={queryData?.totalItems}
                rowsPerPage={rowsPerPage}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
            />
        </>
    )
}
