import React, { Suspense, lazy, useState } from 'react';
import { useBankSlipByBarCodeList } from 'contexts/wallet/bankSlip/bankSlip';
import { useParams } from 'react-router';
import { ErrorBox } from 'components/Errors/ErrorBox/ErrorBox';
import { ApiResponseError, ToastType, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { Stack } from '@mui/material';
import { GenericFormSkeleton } from 'components/Skeleton/GenericFormSkeleton';
import { isEmpty } from 'lodash';
import { RefreshProgress } from 'components/RefreshProgress';
import { useGetWalletByWalletCode } from 'contexts/wallet/walletContext/walletContext';
import { useBillingById } from 'contexts/wallet/Billing/billingContext';

const ChargesFormContainer = lazy(() => import('../BillingForm/ChargesFormContainer'));
const BankSlipFormContainer = lazy(() => import('../../BankSlip/BankSlipForm/BankSlipFormContainer'));

export default function BillingAndBankSlipContainer() {
    const { billingId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);

    const {
        data: billingData,
        status: billingStatus,
        error: billingError,
        refetch: billingRefetch,
        isLoading: isLoadingBilling,
        isFetching: isFethingBilling
    } = useBillingById(billingId!);

    const bankSlipId = billingData?.bankSlip.id ?? undefined;

    const {
        data: bankSlipData,
        status: bankSlipStatus,
        error: bankSlipError,
        refetch: bankSlipRefetch,
        isLoading: isLoadingBankSlip,
        isFetching: isFethingBankSlip
    } = useBankSlipByBarCodeList(bankSlipId!);

    const { data: walletData } = useGetWalletByWalletCode(billingData?.walletCode!);
    const refetchFull = async () => {
        await billingRefetch();
        if (!isEmpty(bankSlipId)) {
            await bankSlipRefetch();
        }
    }

    const Loading = () => <GenericFormSkeleton isLoading={true}><></></GenericFormSkeleton>

    return (
        <Stack spacing={2} mt={-3}>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isFethingBilling || isFethingBankSlip} />
            <Stack>
                <ErrorBox error={billingError as ApiResponseError} resource='Informações da cobrança' status={billingStatus} refreshByResource={billingRefetch}>
                    <Suspense fallback={<Loading />}>
                        <ChargesFormContainer
                            chargesData={billingData!}
                            refetch={refetchFull}
                            isFething={isFethingBilling || isFethingBankSlip}
                            isLoading={isLoadingBilling}
                        />
                    </Suspense>
                </ErrorBox>
                {!isEmpty(bankSlipId) &&
                    <ErrorBox error={bankSlipError as ApiResponseError} resource='Informações do boleto' status={bankSlipStatus} refreshByResource={bankSlipRefetch}>
                        <Suspense fallback={<></>}>
                            <BankSlipFormContainer {...{
                                bankSlipData: bankSlipData!,
                                walletData: walletData!, 
                                refetch: bankSlipRefetch,
                                setToast,
                                chargesId: billingData?.id!,
                                isLoading: isLoadingBankSlip, 
                                billingStatus: billingData?.statusValue?.toLowerCase() ?? ""
                            }} />

                        </Suspense>
                    </ErrorBox>}
            </Stack>
        </Stack>
    )
}
