import moment from 'moment';
import { string, object } from 'yup';

export function walletFiltersSchema() {
    return object().shape({
        optionFilter: string()
            .typeError('Tipo do filtro precisa ser preenchido.')
            .required('Tipo do filtro: precisa ser preenchido.'),
        initialBillingDate: string().when("optionFilter", {
            is: "initialBillingDate",
            then: string()
                .typeError('Data de início de cobrança precisa ser preenchido.').required('Data de início de cobrança: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalBillingDate: string().when("optionFilter", {
            is: "finalBillingDate",
            then: string().typeError('Data final de cobrança precisa ser preenchido.').required('Data final de cobrança: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),

        initialCreationDate: string().when("optionFilter", {
            is: "initialCreationDate",
            then: string()
                .typeError('Data de início de lançamento precisa ser preenchido.').required('Data de início de lançamento: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        finalCreationDate: string().when("optionFilter", {
            is: "finalCreationDate",
            then: string()
                .typeError('Data de início de lançamento precisa ser preenchido.').required('Data de início de lançamento: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        confirmationTransactionType: string().when("optionFilter", {
            is: "confirmationTransactionType",
            then: string()
                .typeError('Tipo de instrução precisa ser preenchido.').required('Tipo de instrução: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        hasBillingDate: string().when("optionFilter", {
            is: "hasBillingDate",
            then: string()
                .typeError('Escolha uma opção precisa ser preenchido.').required('Escolha uma opção: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesWalletFilterschema = {
    optionFilter: 'initialBillingDate',
    confirmationTransactionType: null,
    initialBillingDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalBillingDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
    initialCreationDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalCreationDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(), 
    hasBillingDate: true
};
