import { ESignatureType, ESignatureValidation } from 'utils/enums';
import { EFundSignerType } from '../enums';
import { FundBaseModel } from './generics';

export class FundUpdateModel extends FundBaseModel {
    relatedPersonFund: RelatedPersonFundUpdateModel[] = [];

    constructor(init?: Partial<FundUpdateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

interface RelatedPersonFundUpdateModel {
    id: string;
    fundId: string;
    personId: string;
    FundSignerType: EFundSignerType;
    signatureType?: ESignatureType;
    signatureValidation?: ESignatureValidation;
}
