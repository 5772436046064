import { object, boolean } from 'yup';

export function validationSchemaGeneratePreviewForm() {
    return object().shape({
        useWatermark: boolean(),
        createPdf: boolean()
    });
}

export const defaultValuesGeneratePreviewForm = {
    useWatermark: false,
    createPdf: false
};
