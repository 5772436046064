import { GenericListHeader } from 'components/GenericListHeader/GenericListHeader';
import React from 'react';

interface IPayrollAgreementProps {
    handleAddNew: () => void;
}

export const PayrollAgreementsListHeader: React.FC<IPayrollAgreementProps> = ({ handleAddNew }) => {
    return (
        <GenericListHeader
            title="Orgão"
            titleButton="Novo orgão"
            enableAction
            onClick={handleAddNew}
        />
    );
};
