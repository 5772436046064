import { Grid, Stack } from '@mui/material';
import { Button, CancelIcon, DoneIcon, Typography, UY3Icon } from '@uy3/web-components';
import { formatBankAccountManagePixKey } from 'helpers/formats/BankAccount';
import React from 'react';
import { activeTheme } from 'services/theme';

const cardStyle = {
  border: '1.5px solid #BABFD0',
  borderRadius: 3,
  padding: '20px',
  mt: -2,
};

const theme = activeTheme();

type ConfirmOrCancelClaimProps = {
  isLoading: boolean,
  onConfirmClaim: (calimId: string) => void,
  onCancelClaim: (calimId: string) => void,
  claimData: any;
}

export const ConfirmOrCancelClaim: React.FC<ConfirmOrCancelClaimProps> = ({
  isLoading,
  onCancelClaim,
  onConfirmClaim,
  claimData
}) => {
  return (
    <Stack spacing={4}>
      <Typography display="block" fontSize={18} fontWeight="bold">
        Chave que deseja efetuar portabilidade
      </Typography>

      <Stack
        direction='row'
        sx={{
          ...cardStyle,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <UY3Icon sx={{ height: 30, width: 30, display: 'block' }} />
        <Typography fontSize={16} ml={-18} mt={0.2}> UY3 S.A</Typography>
        <Typography fontSize={15} display='block' mt={0.2}>
          {formatBankAccountManagePixKey(claimData?.pixKey?.typeDisplay, claimData?.pixKey?.pixKey)}
        </Typography>
      </Stack>

      <Typography display="block" fontSize={18} fontWeight="bold">
        Transferindo para
      </Typography>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ ...cardStyle, padding: '15px 20px' }}
      >
        <Grid direction="row" alignItems="center">
          <Typography fontSize={15}>{claimData?.claimantDataAccount?.institutionName}</Typography>
        </Grid>
      </Stack>

      <Grid sx={{ ...cardStyle }}>
        <Typography display="block" fontSize={17} fontWeight="bold">
          Informações importantes
        </Typography>

        <Typography display="block" fontSize={15} m="10px 0 ">
          Para a conclusão desse processo, é necessária a confirmação em até 7 (sete)
          dias. Se você não confirmar ou recusar neste período, a portabilidade será
          cancelada.
        </Typography>
      </Grid>

      <Stack direction={'row'} alignItems="center" justifyContent="flex-end" spacing={3}>
        <Button
          startIcon={<CancelIcon htmlColor={isLoading ? 'grey' : theme.palette.primary.main} />}
          variant="outlined"
          size="medium"
          disabled={isLoading}
          onClick={() => onCancelClaim(claimData?.claim?.id)}
        >
          Cancelar portabilidade
        </Button>
        <Button
          onClick={() => onConfirmClaim(claimData?.claim?.id)}
          variant="contained"
          size="medium"
          disabled={isLoading}
          startIcon={
            <DoneIcon
              htmlColor={theme.palette.common.white}
              sx={{
                height: 23,
                width: 23,
              }}
            />
          }
        >
          Confirmar portabilidade
        </Button>
      </Stack>
    </Stack>
  );
};
