import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const DonateIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M2 8H4L10.2622 10.7397C11.2241 11.1605 11.6664 12.2784 11.2528 13.2434V13.2434C10.837 14.2136 9.71355 14.663 8.74342 14.2472L7 13.5"
          stroke={htmlColor}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.33541 16.3292C1.96493 16.1439 1.51442 16.2941 1.32918 16.6646C1.14394 17.0351 1.29411 17.4856 1.66459 17.6708L2.33541 16.3292ZM11.3354 13.3292L10.6646 12.9938L9.99377 14.3354L10.6646 14.6708L11.3354 13.3292ZM13.7331 15.3666L13.3977 16.0374L13.7331 15.3666ZM16.4164 16.75H18V15.25H16.4164V16.75ZM18 19.25H9.88854V20.75H18V19.25ZM6.64625 18.4846L2.33541 16.3292L1.66459 17.6708L5.97542 19.8262L6.64625 18.4846ZM14.0685 14.6957L11.3354 13.3292L10.6646 14.6708L13.3977 16.0374L14.0685 14.6957ZM9.88854 19.25C8.76302 19.25 7.65295 18.9879 6.64625 18.4846L5.97542 19.8262C7.19041 20.4337 8.53015 20.75 9.88854 20.75V19.25ZM19.25 18C19.25 18.6904 18.6904 19.25 18 19.25V20.75C19.5188 20.75 20.75 19.5188 20.75 18H19.25ZM18 16.75C18.6904 16.75 19.25 17.3096 19.25 18H20.75C20.75 16.4812 19.5188 15.25 18 15.25V16.75ZM16.4164 15.25C15.6014 15.25 14.7975 15.0602 14.0685 14.6957L13.3977 16.0374C14.335 16.506 15.3685 16.75 16.4164 16.75V15.25Z"
          fill={htmlColor}
        />
        <path
          d="M12.6083 8.51043L15.4383 12.0478C16.2389 13.0486 17.7611 13.0486 18.5617 12.0478L21.3917 8.51043C21.7855 8.01818 22 7.40656 22 6.77617V6.65165C22 5.18718 20.8128 4 19.3484 4H19.0509C18.5381 4 18.0463 4.20372 17.6836 4.56635C17.3061 4.94392 16.6939 4.94392 16.3164 4.56635C15.9537 4.20372 15.4619 4 14.9491 4H14.6517C13.1872 4 12 5.18718 12 6.65165V6.77617C12 7.40656 12.2145 8.01818 12.6083 8.51043Z"
          stroke={htmlColor}
          strokeWidth="1.7"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

DonateIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
