import { string, object, number } from 'yup';

export function bankAccountFiltersSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        typeOfPendingApprovals: string().when("optionFilter", {
            is: "typeOfPendingApprovals",
            then: string().required('Tipo filtro de pendentes ser preenchido.').typeError('Tipo filtro de pendentes ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_transferDate: string().when("optionFilter", {
            is: "f_transferDate",
            then: string().required('Data da ocorrência precisa ser preenchida.').typeError('Data da ocorrência: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        f_personName: string().when("optionFilter", {
            is: "f_personName",
            then: string().required('Nome precisa ser preenchida.').typeError('Nome: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        f_personRegistrationNumber: string().when("optionFilter", {
            is: "f_personRegistrationNumber",
            then: string().required('CPF/CNPJ precisa ser preenchida.').typeError('CPF/CNPJ: precisa ser preenchida.').cpfCnpjValidation("CPF ou CNPJ inválido."),
            otherwise: string().nullable()
        }),
        f_paymentDate: string().when("optionFilter", {
            is: "f_paymentDate",
            then: string().required('Data do pagamento precisa ser preenchida.').typeError('Data do pagamento: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        f_beneficiaryRegistrationNumber: string().when("optionFilter", {
            is: "f_beneficiaryRegistrationNumber",
            then: string().typeError('CPF/CNPJ precisa ser preenchido.').required('CPF/CNPJ: Precisa ser preenchido.').cpfCnpjValidation("CPF ou CNPJ inválido."),
            otherwise: string().nullable()
        }),
        f_beneficiaryName: string().when("optionFilter", {
            is: "f_beneficiaryName",
            then: string().typeError('Favorecio precisa ser preenchido.').required('Favorecio: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_status: string().when("optionFilter", {
            is: "f_status",
            then: string().typeError('Status precisa ser preenchido.').required('Status: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_amount: number().when("optionFilter", {
            is: "f_amount",
            then: number().typeError('Valor do pagamento precisa ser preenchido.').required('Valor do pagamento: Precisa ser preenchido.'),
            otherwise: number().nullable()
        }),
        f_userUserId: string().when("optionFilter", {
            is: "f_userUserId",
            then: string().typeError('Nome precisa ser preenchido.').required('Nome: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_level: string().when("optionFilter", {
            is: "f_level",
            then: string().typeError('Nível precisa ser preenchido.').required('Nível: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_name: string().when("optionFilter", {
            is: "f_name",
            then: string().typeError('Nível precisa ser preenchido.').required('Nível: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_registrationNumber: string().when("optionFilter", {
            is: "f_registrationNumber",
            then: string().typeError('Nível precisa ser preenchido.').required('Nível: Precisa ser preenchido.').cpfCnpjValidation("CPF ou CNPJ inválido."),
            otherwise: string().nullable()
        }),
        accountNumber: string().when("optionFilter", {
            is: "accountNumber",
            then: string().typeError('Número da conta precisa ser preenchido.').required('Número da conta: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        typeAccount: string().when("optionFilter", {
            is: "typeAccount",
            then: string().typeError('Tipo de conta precisa ser preenchido.').required('Tipo de conta: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_account: string().when("optionFilter", {
            is: "f_account",
            then: string().typeError('Conta precisa ser preenchido.').required('Conta: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_agency: string().when("optionFilter", {
            is: "f_agency",
            then: string().typeError('Agência precisa ser preenchido.').required('Agência: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        f_modality: string().when("optionFilter", {
            is: "f_modality",
            then: string().typeError('Modalidade precisa ser preenchido.').required('Modalidade: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }) 
    });
}

export const defaultValuesBankAccountFilterSchema = (defaultSearch: string) => {
    return {
        optionFilter: defaultSearch,
        f_account: null,
        typeOfPendingApprovals: null,
        f_agency: null,
        f_modality: null,
        typeAccount: null,
        accountNumber: null,
        f_userUserId: null,
        f_personName: null,
        f_personRegistrationNumber: null,
        f_level: null,
        f_name: null,
        searchString: null,
        f_beneficiaryName: null,
        f_status: null,
        f_amount: null,
        f_registrationNumber: null,
        f_beneficiaryRegistrationNumber: null,
        f_paymentDate: new Date(),
        f_transferDate: new Date()
    }
};