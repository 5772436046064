import { ESignatureType, ESignatureValidation } from 'utils/enums';
import { EFundSignerType } from '../enums';
import { FundBaseModel } from './generics';

export class FundCreateModel extends FundBaseModel {
    relatedPersonFund: IRelatedPersonFundCreateModel[] = [];

    constructor(init?: Partial<FundCreateModel>) {
        super(init);
        Object.assign(this, init);
    }
}

interface IRelatedPersonFundCreateModel {
    personId: string;
    fundSignerType: EFundSignerType;
    signatureType?: ESignatureType;
    signatureValidation?: ESignatureValidation;
}
