import { Stack } from '@mui/material'
import { Button, LeftIcon, PenIcon } from '@uy3/web-components'
import React from 'react'
import { activeTheme } from 'services/theme'

const theme = activeTheme();

type StartSignatureProps = {
    onStartSignature: () => void; 
    onClose: () => void;
}

export const StartSignature: React.FC <StartSignatureProps> = ({onClose, onStartSignature}) => {
  return (
    <Stack direction='row' alignItems='center' justifyContent='end' spacing={2}>
        <Button 
           onClick={onClose}
           variant='outlined'
           startIcon={<LeftIcon htmlColor={theme.palette.primary.main}/>}
        >
            Agora não, fechar
        </Button>
        <Button 
           onClick={onStartSignature}
           variant='contained'
           startIcon={<PenIcon htmlColor={theme.palette.common.white}/>}
        >
            Sim, inicar agora
        </Button>
    </Stack>
  )
}