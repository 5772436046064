import { string, object, array } from 'yup';

export const validationApproveDisapproveFormSchemaSchema = (bankAccountId: string | undefined) => {
    return object().shape({
        blockType: string().typeError('Tipo de bloqueio precisa ser preenchido.').required('Tipo de bloqueio: precisa ser preenchido.'),
        reasonDisapproval: array().typeError('Motivo da aprovação precisa ser preenchida').min(1, "Selecione ao menos 1 reprovação.").required('Motivo da aprovação: precisa ser preenchida'),
        message: string().when("reasonDisapproval", {
            is: (reasonDisapproval: string[]) => reasonDisapproval.includes("OUTROS_MOTIVOS"),
            then: string().typeError('A mensagem precisa ser um texto.').required('Mensagem: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        blockUntil: string().when("blockType", {
            is: "Final",
            then: string().typeError('Data precisa ser preenchida.').required('Data: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        complianceChecklist: array().when("blockType", {
            is: "Final",
            then: bankAccountId !== undefined ?
                array()
                    .typeError('Compliance checklist ser preenchida')
                    .min(1, "Selecione ao menos 1 reprovação.")
                    .required('Compliance checklist: precisa ser preenchida') :
                array().nullable(),
            otherwise: array().nullable()
        }),
    })
};

export const defaultValuesDisapproveFormSchema = {
    reasonDisapproval: [],
    complianceChecklist: [],
    message: null,
    blockType: null,
    blockUntil: null
};

export const validationApproveSchema = () => {
    return object().shape({
        message: string().nullable()
    })
};

export const defaultValuesApproveFormSchema = {
    message: null
};