import { Grid, Stack } from '@mui/material'
import { AddIcon, Alert, Button, CloseIcon, RightIcon, Typography } from '@uy3/web-components';
import { Radio } from 'components/Forms/Radio/Radio';
import { activeTheme } from 'services/theme';
import { PixQRCodeImmediateDynamic } from './Forms/PixQRCodeImmediateDynamic/PixQRCodeImmediateDynamic';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { useFormContext } from 'contexts/formContext';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';
import { SelectOption } from 'components/Forms/FormFields';

type PixQRCodeProps = {
    setQRCodeType: (type: string | undefined) => void;
    QRCodeType: string | undefined;
    onClose: () => void
    addPixKey: () => void
    keyPixListAutocomplete: AutocompleteOptionList;
    showActionToAddPixKey: boolean
}

const theme = activeTheme();

export const PixQRCode = ({ setQRCodeType, QRCodeType, onClose, keyPixListAutocomplete, addPixKey, showActionToAddPixKey }: PixQRCodeProps) => {
    const { reset, setValue, submitting } = useFormContext();
    const optionsQrCode = [
        { label: `QRCode estático`, value: 'QRCodeStatic' },
        { label: `QRCode dinâmico`, value: 'QRCodeImmediateDynamic' }
    ]

    const handleSelectTypeQrCode = (event: any) => {
        let value = event?.target?.value;
        setQRCodeType(value);
        reset();
    }

    const handleSetValueInType = (option: SelectOption) => {
        return ["type", "typeDisplay"].map(item => {
            //@ts-ignore
            return setValue(item, option?.[item])
        });
    };

    const colorMain = theme.palette.primary.main;

    return (
        <Stack mt={-2} spacing={3}>
            <Typography variant='md' fontWeight='bold'>
                Selecione o tipo do QRCode
            </Typography>

            <Grid m='10px 0 '>
                <Radio
                    options={optionsQrCode}
                    onChange={handleSelectTypeQrCode}
                    value={QRCodeType!}
                    row={true}
                />
            </Grid>

            {QRCodeType === 'QRCodeStatic' ? (
                <Alert
                    severity='info'
                    text='Esse tipo de QR Code é ideal para pagamentos fixos em estabelecimentos. Não inclui dados do pagador, agilizando o processo de pagamento.'
                />
            ) : (
                <Alert
                    severity='info'
                    text='Esse tipo de QR Code é ideal para transações individuais, onde o pagador precisa inserir seus dados antes de concluir o pagamento.'
                />
            )}

            <Grid position='relative'>
                <Grid mb={showActionToAddPixKey ? 3.5 : 0}>
                    <AutocompleteField
                        name="pixKey"
                        label="Chave Pix"
                        onChange={(_, option) => handleSetValueInType(option!)}
                        displayName="pixKeyDisplay"
                        {...keyPixListAutocomplete!}
                    />
                </Grid>

                {showActionToAddPixKey && <Stack sx={{
                    position: "absolute",
                    bottom: -17,
                    right: 0
                }}>
                    <Button variant='text' onClick={addPixKey} startIcon={<AddIcon htmlColor={colorMain} />} sx={{ color: colorMain }} >
                        Adicionar chave Pix
                    </Button>
                </Stack>}
            </Grid>

            {QRCodeType === "QRCodeImmediateDynamic" && (
                <PixQRCodeImmediateDynamic />
            )}

            <Grid>
                <CurrencyFormField
                    name='valueInCents'
                    variant='outlined'
                    label="Valor"
                    fullWidth
                />
            </Grid>

            <Stack direction='row' alignItems='center' spacing={2} mt={3} justifyContent='right'>
                <Button
                    variant='outlined'
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} />}
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant='contained'
                    type='submit'
                    disabled={submitting}
                    startIcon={<RightIcon htmlColor={theme.palette.common.white} />}
                >
                    Continuar
                </Button>
            </Stack>

        </Stack>
    )
}
