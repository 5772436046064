import { Grid, Stack } from '@mui/material'
import { DocExcelIcon, Typography, UpdateIcon } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';

type CostsListHeaderProps = {
  refetch: () => void;
  handleExportCsv: () => void;
  hasData: boolean
}

const theme = activeTheme();

export const CostsListHeader: React.FC<CostsListHeaderProps> = ({ refetch, handleExportCsv, hasData }) => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems='center'>
      <Grid>
        <Typography
          variant="h4"
          color="neutral.dark"
          fontStyle="normal"
          fontWeight="700"
          fontSize="25px"
        >
          Tarifas do convênio
        </Typography>
      </Grid>
      <Actions
        numberOfButtons={4}
        buttonsActionsList={[
          {
            enable: true,
            label: "Atualizar",
            action: refetch,
            disabled: false,
            icon: <UpdateIcon
              htmlColor={theme.palette.common.black}
              sx={iconSx}
            />
          },
          {
            enable: hasData,
            disabled: false,
            label: "Exportar tarifas",
            action: handleExportCsv,
            icon: <DocExcelIcon
              htmlColor={theme.palette.common.black}
              sx={iconSx}
            />
          }
        ]}
      />
    </Stack>
  )
}
