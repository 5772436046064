(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-dom"), require("react-router-dom"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-dom", "react-router-dom"], factory);
	else if(typeof exports === 'object')
		exports["web-components"] = factory(require("react"), require("react-dom"), require("react-router-dom"));
	else
		root["web-components"] = factory(root["react"], root["react-dom"], root["react-router-dom"]);
})(global, (__WEBPACK_EXTERNAL_MODULE__8156__, __WEBPACK_EXTERNAL_MODULE__7111__, __WEBPACK_EXTERNAL_MODULE__6128__) => {
return 