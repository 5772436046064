import { Grid, Stack } from '@mui/material'
import { TextFormField } from 'components/Forms/FormFields'
import { MaskedInput } from 'components/Forms/MaskedInput'
import { cnpjMask, cpfMaskAndSpace } from 'utils/constants'
import { useFormContext } from 'contexts/formContext'

export const PixQRCodeImmediateDynamic = () => {
    const { watch } = useFormContext();
    const isLegalPerson = watch('payerRegistrationNumber')?.replace(/\D/g, '').length > 11;
    return (
        <Stack spacing={3}>
            <Grid>
                <TextFormField
                    name="payerRegistrationNumber"
                    label="CPF/CNPJ do pagador"
                    variant="outlined"
                    InputProps={{
                        inputComponent: MaskedInput,
                        inputProps: { mask: isLegalPerson ? cnpjMask : cpfMaskAndSpace },
                    }}
                    fullWidth
                />
            </Grid>
            <Grid>
                <TextFormField
                    name="payerName"
                    label="Nome do pagador"
                    variant="outlined"
                    fullWidth
                />
            </Grid>
        </Stack>
    )
}

