import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const SpeedTestIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M16.8028 10.4075C17.0278 10.7552 17.4922 10.8547 17.8399 10.6297C18.1877 10.4046 18.2871 9.94029 18.0621 9.59254L16.8028 10.4075ZM5.93784 9.59254C5.71281 9.94029 5.81229 10.4046 6.16004 10.6297C6.5078 10.8547 6.97213 10.7552 7.19717 10.4075L5.93784 9.59254ZM15.6431 12.3859C15.8562 12.0307 15.7411 11.57 15.3859 11.3569C15.0307 11.1438 14.57 11.2589 14.3569 11.6141L15.6431 12.3859ZM6 20.75H18V19.25H6V20.75ZM22.75 16V14H21.25V16H22.75ZM1.25 14V16H2.75V14H1.25ZM12 3.25C6.06294 3.25 1.25 8.06294 1.25 14H2.75C2.75 8.89137 6.89137 4.75 12 4.75V3.25ZM22.75 14C22.75 8.06294 17.9371 3.25 12 3.25V4.75C17.1086 4.75 21.25 8.89137 21.25 14H22.75ZM18 20.75C20.6234 20.75 22.75 18.6234 22.75 16H21.25C21.25 17.7949 19.7949 19.25 18 19.25V20.75ZM6 19.25C4.20507 19.25 2.75 17.7949 2.75 16H1.25C1.25 18.6234 3.37665 20.75 6 20.75V19.25ZM9.75 20C9.75 18.7574 10.7574 17.75 12 17.75V16.25C9.92893 16.25 8.25 17.9289 8.25 20H9.75ZM12 17.75C13.2426 17.75 14.25 18.7574 14.25 20H15.75C15.75 17.9289 14.0711 16.25 12 16.25V17.75ZM12 7.75C14.44 7.75 15.8577 8.94708 16.8028 10.4075L18.0621 9.59254C16.9152 7.82012 15.0722 6.25 12 6.25V7.75ZM7.19717 10.4075C8.1422 8.94708 9.55991 7.75 12 7.75V6.25C8.92771 6.25 7.0848 7.82012 5.93784 9.59254L7.19717 10.4075ZM12.6431 17.3859L15.6431 12.3859L14.3569 11.6141L11.3569 16.6141L12.6431 17.3859Z"
          fill={htmlColor}
          strokeWidth="2"
        />
      </svg>
    </SvgIcon>
  );
};

SpeedTestIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none'
};
