import { IconButton, Stack } from '@mui/material';
import { Button, CloseIcon, DeleteIcon, SaveIcon, Typography } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { SelectFormField, TextFormField } from 'components/Forms/FormFields';
import {
    complianceRulesTypesList,
    getComplianceRuleByDescription,
    getComplianceRuleDescription,
    IComplianceRules,
} from 'services/creditProduct';

type ComplianceRulesFormContainerProps = {
    onClose: () => void;
};

const theme = activeTheme();
export const ComplianceRulesFormContainer = ({ onClose }: ComplianceRulesFormContainerProps) => {
    const { setValue, watch } = useFormContext();
    const complianceRules: IComplianceRules[] = watch('complianceRules') ?? [];
    const complianceRuleTypes: number[] = watch('complianceRuleTypes') ?? [];

    const complianceListOrder = () => {
        let list = complianceRulesTypesList;
        const targetValue = 0;
        if (complianceRuleTypes.length !== 0)
            if (complianceRuleTypes.includes(targetValue)) {
                list = complianceRulesTypesList.filter((x) => x.value === targetValue);
            } else {
                list = complianceRulesTypesList.filter((x) => x.value !== targetValue);
            }
        return list;
    };

    const onRemoveItem = (option: IComplianceRules) => {
        const listcomplianceRulesRemoved = complianceRules.filter(
            (x) => x.complianceRulesType !== option.complianceRulesType
        );
        const listComplianceRuleTypesRemoved = complianceRuleTypes.filter(
            (x) => x !== option.complianceRulesType
        );
        setValue('complianceRules', listcomplianceRulesRemoved);
        setValue('complianceRuleTypes', listComplianceRuleTypesRemoved);
    };

    return (
        <Stack spacing={2}>
            <SelectFormField
                name="complianceRuleTypes"
                label="Regras de compliance"
                variant="outlined"
                multiple
                onChange={({ target: { value } }) => {
                    const list = ((value ?? []) as string[])?.map((x) =>
                        Number(getComplianceRuleByDescription(x))
                    );
                    setValue('complianceRuleTypes', list);
                    const compliaceList = list
                        ?.map((option) => {
                            const getAction = complianceRules.find(
                                (x) => x.complianceRulesType === option
                            )?.complianceRulesActionType;
                            return {
                                complianceRulesActionType: getAction ?? null,
                                complianceRulesType: option,
                            };
                        })
                        .sort((a, b) => {
                            if (a.complianceRulesType !== 9 && b.complianceRulesType === 9)
                                return -1;
                            if (a.complianceRulesType !== 9 && b.complianceRulesType === 9)
                                return 1;
                            return 0;
                        });
                    setValue('complianceRules', compliaceList);
                }}
                options={complianceListOrder()}
            />

            {complianceRules?.length > 0 && (
                <Typography fontSize="15px" fontWeight="bold">
                    Defina as ações para cada regra adicionada
                </Typography>
            )}

            {complianceRules?.map((option, index) => {
                const shoActionField = ![0, 9].includes(option.complianceRulesType);
                return (
                    <Stack
                        spacing={2}
                        sx={{
                            border: '1px solid #BABFD0',
                            borderRadius: '8px',
                            padding: '7px',
                        }}
                    >
                        <Stack
                            direction="row"
                            alignItems="center"
                            pb={0.5}
                            justifyContent="space-between"
                            borderBottom={
                                shoActionField ? `1px solid ${theme.palette.grey[200]}` : 'none'
                            }
                        >
                            <Typography fontSize="17px" fontWeight="bold">
                                {getComplianceRuleDescription(option.complianceRulesType)}
                            </Typography>
                            <IconButton onClick={() => onRemoveItem(option)}>
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                        {shoActionField && (
                            <Stack mt={2}>
                                <SelectFormField
                                    name={`complianceRules.${index}.complianceRulesActionType`}
                                    label="Ação"
                                    fullWidth
                                    options={[
                                        { label: 'Aprovação manual', value: 1 },
                                        { label: 'Rejeitar', value: 0 },
                                    ]}
                                />
                            </Stack>
                        )}

                        {option.complianceRulesType === 6 && (
                            <TextFormField
                                name="defaultComplianceAnalysisGroup"
                                label="Grupo de análise compliance"
                                type="number"
                                variant="outlined"
                                fullWidth
                                required
                            />
                        )}
                    </Stack>
                );
            })}
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    variant="outlined"
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    type="submit"
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    );
};
