import { Grid, Stack, styled } from "@mui/material";
import { activeTheme } from "services/theme";

const theme = activeTheme();

type BoxInformationStackProps = {
    isOnClick?: boolean
}

type BackgroundCustomProps = {
    isDisable?: boolean
}

export const BoxInformationStack = styled(Stack)<BoxInformationStackProps>(({ isOnClick }) => ({
    border: '1px solid var(--Neutral-Light, #BABFD0)',
    padding: '24px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'transform 300ms ease-out',
    flexDirection: 'row',
    borderRadius: '8px',
    cursor: isOnClick ? 'pointer' : 'default',
    '@media (max-width: 50em)': {
        padding: '20px 10px',
    },
    '&:hover': isOnClick ? {
        transform: 'translate(0px, -4px)',
        background: theme.palette.grey['100']

    } : {}
}));

export const DetailsStack = styled(Stack)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 20
}));

export const BackgroundCustom = styled(Grid)<BackgroundCustomProps>(({ isDisable }) => ({
    height: 64,
    width: 64,
    borderRadius: '8px',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    background: isDisable ? theme.palette.grey[400] : theme.palette.primary.main,
    opacity: isDisable ? 0.2 : 0.1,
    position: 'relative',
    zIndex: 100,
    '@media (max-width: 50em)': {
        display: 'none'
    }
}));

export const IconGrid = styled(Grid)(() => ({
    position: 'absolute',
    zIndex: 100,
    top: 20,
    transition: 'transform 300ms ease-out',
    left: 20,
    '@media (max-width: 50em)': {
        display: 'none'
    }
}));

export const ContentGrid = styled(Grid)(() => ({

})); 