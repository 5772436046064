import { AddIcon, Button, CloseIcon, CompleteIcon } from '@uy3/web-components';
import { CommissionFormCostsAccordionMetadataKey } from 'components/Custom/CustomAccordion/CustomAccordionContainer';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useFieldArray } from 'react-hook-form';
import { activeTheme } from 'services/theme';
import { UpdateEmailsListForm } from './UpdateEmailsListForm';
import { Stack } from '@mui/material';

const theme = activeTheme()

type UpdateEmailsFormProps = {
    onClose: () => void;
    isLoading: boolean
}

export const UpdateEmailsForm = ({ onClose, isLoading }: UpdateEmailsFormProps) => {
    const { control, setValue, watch } = useFormContext();
    const { fields, append, remove } = useFieldArray({ control, name: 'notificationRecepients' });
    const currentAccordion = watch(CommissionFormCostsAccordionMetadataKey);

    const removeComm = (index: number) => {
        if (index < currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, currentAccordion - 1);
        }
        if (index === currentAccordion) {
            setValue(CommissionFormCostsAccordionMetadataKey, null);
        }
        remove(index);
    };

    const addNewComm = () => {
        append({});
        setValue(CommissionFormCostsAccordionMetadataKey, fields.length);
    };

    return (
        <Stack spacing={2}>
            <UpdateEmailsListForm fields={fields} remove={removeComm} />
            <Button
                sx={{
                    justifyContent: 'flex-start',
                    fontWeight: 600,
                    fontSize: '15px'
                }}
                variant="text"
                onClick={addNewComm}
                startIcon={<AddIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
            >
                Adicionar novo email
            </Button>

            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={3} mt={3}>
                <Button
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                >
                    Fechar
                </Button>
                <Button
                    variant="contained"
                    name="btn-continuar"
                    disabled={isLoading}
                    startIcon={<CompleteIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    type="submit"
                >
                    Confirmar
                </Button>
            </Stack>
        </Stack>
    )
}
