import moment from 'moment';
import { string, object } from 'yup';

export function fundtFiltersSchema() {
    return object().shape({
        optionFilter: string().typeError('Tipo do filtro precisa ser preenchido.').required('Tipo do filtro: precisa ser preenchido.'),
        searchString: string().when("optionFilter", {
            is: "searchString",
            then: string().required('Filtro precisa ser preenchido.').typeError('Filtro precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        partnerName: string().when("optionFilter", {
            is: "partnerName",
            then: string().required('Nome do parceiro precisa ser preenchida.').typeError('Nome do parceiro: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        ownerDisplay: string().when("optionFilter", {
            is: "ownerDisplay",
            then: string().required('Nome do solicitante precisa ser preenchida.').typeError('Nome do solicitante: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        accountNumber: string().when("optionFilter", {
            is: "accountNumber",
            then: string().required('Número da conta precisa ser preenchida.').typeError('Número da conta: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        initialDate: string().when("optionFilter", {
            is: "initialDate",
            then: string().required('Data da ocorrência precisa ser preenchida.').typeError('Data da ocorrência: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        finalDate: string().when("optionFilter", {
            is: "finalDate",
            then: string().required('Data da ocorrência precisa ser preenchida.').typeError('Data da ocorrência: precisa ser preenchida.'),
            otherwise: string().nullable()
        }),
        status: string().when("optionFilter", {
            is: "status",
            then: string().typeError('Status precisa ser preenchido.').required('Status: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        typeAccount: string().when("optionFilter", {
            is: "typeAccount",
            then: string().typeError('Tipo de conta precisa ser preenchido.').required('Tipo de conta: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
        email: string().when("optionFilter", {
            is: "email",
            then: string().typeError('Email precisa ser preenchido.').required('Email: Precisa ser preenchido.').email("Email inválido"),
            otherwise: string().nullable()
        }),
        phone: string().when("optionFilter", {
            is: "phone",
            then: string().typeError('Número de telefone precisa ser preenchido.').required('Número de telefone: Precisa ser preenchido.'),
            otherwise: string().nullable()
        }),
    });
}

export const defaultValuesFundtFilterSchema = {
    optionFilter: 'searchString',
    email: null,
    phone: null,
    partnerName: null,
    accountNumber: null,
    ownerDisplay: null,
    status: null,
    typeAccount: null,
    initialDate: moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toDate(),
    finalDate: moment().set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toDate(),
};