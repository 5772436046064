import { BankAccountProductReadModel } from 'services/bankAccountProduct/types/productReadModel';
import { CreditProductReadModel } from 'services/creditProduct';
import { object, string, boolean } from 'yup';

type ProductType = CreditProductReadModel | BankAccountProductReadModel

export function validationSchemaCloneProduct<T>(creditProductData: T | ProductType) {
    const product = creditProductData as ProductType
    return object().shape({
        keepSameName: boolean().nullable(),
        name: string().when("keepSameName", {
            is: false,
            then: string()
                .typeError('Novo nome do produto precisa ser preenchido')
                .required('Novo nome do produto: Precisa ser preenchido')
                .test('is-equal-name', 'Novo nome não deve ser igual ao nome do produto existente.', value => {
                    return String(product.name).toLowerCase().trim() !== String(value).toLowerCase().trim()
                }),
            otherwise: string().nullable()
        }),
        tenant: string()
            .typeError('Correspondente: Precisa ser preenchido')
            .required('Correspondente: Precisa ser preenchido')
    });
};

export function defaultValuesCloneProduct<T>(creditProductData: T | ProductType) {
    const entity = creditProductData as ProductType; 
    return {
        name: entity?.name,
        keepSameName: false,
        tenant: entity.tenant,
        tenantDisplay: entity.tenantDisplay
    }
};