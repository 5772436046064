export function replacePhoneNumber(phone: string): string {
  let defaultDDI = '+55';
  const containsDDI = phone.includes(defaultDDI);

  return containsDDI ? phone.replace(defaultDDI, '') : phone;
}

export function formatPhoneNumber(value: string) {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2').slice(0, 14);
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
}