import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { useFormContext } from 'contexts/formContext';
import { CurrentValuesSelected } from '../CurrentValuesSelected/CurrentValuesSelected';
import { FilterStyleBase } from '../MethodsForFilters/FilterStyleBase';
import { Grid, IconButton, InputAdornment } from '@mui/material';
import { SearchIcon } from '@uy3/web-components';
import CurrencyFormField from 'components/Forms/FormFields/CurrencyFormField/CurrencyFormField';

type ParcelsBatchFiltersProps = {
    currentValues: any,
    removeFilterSelected: (index: number) => void;
}

function ParcelsBatchAssignmentFilterForm() {
    const { watch } = useFormContext();

    return (
        <>
            <SelectFormField
                name='optionFilter'
                label='Filtrar por'
                variant='outlined'
                fullWidth
                options={[
                    { label: "Todos", value: 'searchString' },
                    { label: "Data de vencimento", value: 'initialDate' },
                    { label: "Valor da Parcela (Máximo)", value: 'maxPaymentValue' },
                    { label: "Valor da Parcela (Mínimo)", value: 'minPaymentValue' },
                    { label: "Valor da Cessão (Máximo)", value: 'maxAssignmentValue' },
                    { label: "Valor da Cessão (Mínimo)", value: 'minAssignmentValue' },
                    // { label: "Saldo devedor", value: 'principalAmountInCents' },
                    { label: "Número da operação", value: 'creditNoteNo' },
                ]}
            />
            {watch('optionFilter') === 'searchString' && (
                <TextFormField
                    name='searchString'
                    variant='outlined'
                    label='Filtrar por todos'
                    fullWidth
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon sx={{ width: 25, height: 25 }} htmlColor="#666666" />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
             {watch('optionFilter') === 'creditNoteNo' && (
                <TextFormField
                    label="Filtrar por número da operação"
                    name="creditNoteNo"
                    variant="outlined"
                    fullWidth
                    type="number"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{ marginRight: -5 }}>
                                <span
                                    style={{
                                        display: 'block',
                                        fontSize: '20px',
                                    }}
                                >
                                    #
                                </span>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            {watch('optionFilter') === 'maxPaymentValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Filtrar por Valor da Parcela (Máximo)"
                    name="maxPaymentValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minPaymentValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Filtrar por Valor da Parcela (Mínimo)"
                    name="minPaymentValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'maxAssignmentValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Filtrar por Valor da Cessão (Máximo)"
                    name="maxAssignmentValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {watch('optionFilter') === 'minAssignmentValue' && (
                <CurrencyFormField
                    fullWidth
                    label="Filtrar por Valor da Cessão (Mínimo)"
                    name="minAssignmentValue"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )}
            {/* {watch('optionFilter') === 'principalAmountInCents' && (
                <CurrencyFormField
                    fullWidth
                    label="Filtrar por Saldo devedor"
                    name="principalAmountInCents"
                    variant="outlined"
                    required
                    showEndAdornment
                />
            )} */}
            {watch('optionFilter') === 'initialDate' && (
                <Grid
                    sx={{
                        display: 'grid',
                        alignItems: 'center',
                        gridTemplateColumns: 'auto auto',
                        gridGap: 10,
                    }}
                >
                    <DatePickerFormField
                        label="Data de vencimento"
                        name="initialDate"
                        fullWidth
                        required
                    />
                    <DatePickerFormField
                        label="Filtrar por data final"
                        name="finalDate"
                        fullWidth
                        required
                        componentFilter={
                            <InputAdornment position="end" sx={{ marginRight: -1.3, ml: -1 }}>
                                <IconButton type="submit">
                                    <SearchIcon
                                        sx={{ width: 25, height: 25 }}
                                        htmlColor="#666666"
                                    />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Grid>
            )}
        </>
    )
}

export const ParcelsBatchAssignmentFilter = ({ currentValues, removeFilterSelected }: ParcelsBatchFiltersProps) => {
    return (
        <FilterStyleBase
            formFilters={<ParcelsBatchAssignmentFilterForm />}
            currentValues={
                <CurrentValuesSelected
                    currentValues={currentValues}
                    removeFilterSelected={removeFilterSelected}
                />
            }
        />
    )
}
