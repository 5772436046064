/* eslint-disable no-useless-escape */
import { string, object, boolean, ref } from 'yup';

export function validationSchemaUserForm(isEditing: boolean) {
    return isEditing === false ? object().shape({
        email: string().typeError('E-mail inválido.').required('E-mail: Precisa ser preenchido.').email("E-mail inválido."),
        name: string().typeError('Nome: Precisa ser preenchido.').required('Nome completo: Precisa ser preenchido.'),
        registrationNumber: string().typeError('CPF inválido.').required("CPF: Precisa ser preenchido.").cpfCnpjValidation("CPF inválido."),
        phoneNumber: string().typeError('Telefone inválido.').phoneNumberValidation('Número de telefone deve conter 11 Caracteres').required("Telefone: Precisa ser preenchido."),
        address: string().typeError('Endereço inválido.').required("Endereço: Precisa ser preenchido."),
        sendNotifications: boolean(),
        password: string().typeError('Senha: Precisa ser preenchido.')
            .required('Senha: Precisa ser preenchido.').matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                "A senha deve conter pelo menos 8 caracteres, incluindo maiúsculas, minúsculas, caracteres especiais e número")
            .min(8, 'A senha é muito curta - deve ter no mínimo 8 caracteres.'),
        passwordConfirmation: string().typeError('Confirmar senha: Precisa ser preenchido.').required('Confirmar senha: Precisa ser preenchido.').oneOf([ref("password"), null], "A senha e a confirmação de senha não conferem"),
        emailVerified: boolean()
    }) :
        object().shape({
            email: string().typeError('E-mail inválido.').required('E-mail: Precisa ser preenchido.').email("E-mail inválido."),
            name: string().typeError('Nome: Precisa ser preenchido.').required('Nome: Precisa ser preenchido.'),
            registrationNumber: string().typeError('CPF inválido.').required("CPF: Precisa ser preenchido.").cpfCnpjValidation("CPF inválido."),
            phoneNumber: string().typeError('Telefone inválido.').required("Telefone: Precisa ser preenchido."),
            address: string().typeError('Endereço inválido.').required("Endereço: Precisa ser preenchido."),
            sendNotifications: boolean(),
            emailVerified: boolean(),
        });
}

export const defaultValuesUserForm = {
    email: "",
    name: "",
    registrationNumber: "",
    phoneNumber: "",
    address: "",
    sendNotifications: false,
    emailVerified: false,
    password: null, 
    passwordConfirmation: null
};