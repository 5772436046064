import React, { useState } from 'react'
import { CreditorsList } from './CreditorsList'
import { CreditorsHeader } from './CreditorsHeader'
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState } from 'contexts/apiRequestContext';
import { ICreditor, useBankAccountBalance, useCreateCreditorMutation, useDeleteCreditorMutation } from 'contexts/bankAccount/bankAccountContext';
import { useParams } from 'react-router';
import { Error } from 'components/Errors/Error';
import { RefreshProgress } from 'components/RefreshProgress';
import { Drawer, Modal } from '@uy3/web-components';
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler';
import { Stack } from '@mui/material';
import Toast from 'components/Toast/Toast';
import { AddCreditorForm } from './Drawers/AddCreditorForm/AddCreditorForm';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesAddCreditor, validationAddCreditorSchema } from './Drawers/AddCreditorForm/AddCreditorFormShema';

export const CreditorsListContainer = () => {
    const { bankAccountId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [actionStep, setActionStep] = useState<{ step: string, rowData?: ICreditor } | undefined>();
    const { status, data: balanceData, error, refetch, isLoading, isRefetching } = useBankAccountBalance(bankAccountId!);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);

    const onClose = () => setActionStep(undefined);

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const currentStep: string = actionStep?.step!;
    const onSuccess = () => {
        const optionsMessage: { [type: string]: string } = {
            "create": "Credor adicionado com sucesso",
            "delete": "Sucesso ao exluir o credor",
        }
        showSuccessToast(optionsMessage[currentStep], "", setToast);
        onClose();
        refetch();
    }

    const onError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
    }

    const { mudateCreateCreditorAsync } = useCreateCreditorMutation(bankAccountId!, onSuccess, onError)
    const { mutateDeleteCreditorAsync } = useDeleteCreditorMutation(bankAccountId!, onSuccess, onError)

    if (status === 'error') {
        const { errorMessage } = mapErrorResponse(error as ApiResponseError);
        return <Error errorMessage={errorMessage} />;
    }

    const creditorsList = balanceData?.bankAccount?.creditors;


    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />

            <Stack margin='10px 0'>
                <CreditorsHeader {...{
                    handleAddCreditor: () => setActionStep({ step: 'create' }),
                    refetch,
                }} />
            </Stack>
            <RefreshProgress refreshing={isLoading || isRefetching} />
            <CreditorsList {...{
                queryData: creditorsList ?? [],
                page,
                rowsPerPage,
                setPage: onChangePage,
                setRowsPerPage: onChangeRowsPerPage,
                setActions: setActionStep
            }} />

            <Modal
                open={currentStep === 'delete'}
                align='left'
                onClose={onClose}
                title={`Excluir ${actionStep?.rowData?.name ?? ""}`}
                description='Tem certeza que deseja excluir esse registro?'
                children={(
                    <GenericActionHandler
                        handleSubmit={() => mutateDeleteCreditorAsync(actionStep?.rowData?.id!)}
                        onClose={onClose}
                        isModal
                        titleCancel='Não, cancelar'
                        titleConfirm='Sim, excluir'
                    />
                )}
            />

            <Drawer
                anchor='right'
                open={currentStep === 'create'}
                title='Adicionar credor'
                onClose={onClose}
                children={(
                    <FormProvider
                        defaultValues={defaultValuesAddCreditor}
                        validationSchema={validationAddCreditorSchema()}
                        onSubmit={(values) => mudateCreateCreditorAsync(values?.personId)}
                    >
                        <AddCreditorForm onClose={onClose} />
                    </FormProvider>
                )}
            />
        </React.Fragment>
    )
}
