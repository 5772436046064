import { useGetTokenRegisteredByPersonId } from 'contexts/naturalPersonContext';
import React from 'react'
import TokensList from './TokensList';
import { useParams } from 'react-router';

type TokensListContainerProps = {
  personIdByCreditNote: string; 
  pathIsNaturalPerson: boolean;
}

export default function TokensListContainer({ personIdByCreditNote, pathIsNaturalPerson }: TokensListContainerProps) {
  const { personId } = useParams();
  const { data: tokensList } = useGetTokenRegisteredByPersonId(pathIsNaturalPerson ? personId! : personIdByCreditNote!);

  return (
    <React.Fragment>
      <TokensList {...{
        queryData: tokensList ?? []
      }} />
    </React.Fragment>
  )
}
