import { ChangeStartDate } from './ChangeStartDate'

export type RecordTypeChangeStartDate = "simulate" | "submitApproval";

export type ChangeStartDateContainerProps = {
    handleSubmit: () => void;
    handleKeepCurrentState: () => void; 
    textCurrentStateButton?: string;
    textHandleSubmitButton?: string; 
    recordType: string
}

export const ChangeStartDateContainer: React.FC<ChangeStartDateContainerProps> = (props) => {
    return <ChangeStartDate {...props} />
}

