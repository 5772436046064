import { Stack, useMediaQuery } from "@mui/material";
import { Button, OperatorIcon } from "@uy3/web-components";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { useFormContext } from "contexts/formContext";
import { activeTheme } from "services/theme";
import AutocompleteField from "components/Forms/FormFields/AutocompleteField/AutocompleteField";

export interface AddMemberFormProps {
    onClose: () => void;
    memberList: any;    
}
const theme = activeTheme();

const AddMemberForm = ({ onClose, memberList }: AddMemberFormProps) => {
    const { submitting } = useFormContext();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));    

    return (
        <Stack spacing={2}>
            <AutocompleteField
                name="name"
                label="Membro"
                displayName="nameDisplay"
                {...memberList!}
            />
            <Stack
                direction={isMobile ? 'column' : 'row'}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
            >
                <Button
                    startIcon={<CancelOutlinedIcon />}
                    variant="outlined"
                    size="medium"
                    onClick={onClose}
                    fullWidth={isMobile}
                >
                    Fechar
                </Button>
                <Button
                    type="submit"
                    startIcon={<OperatorIcon htmlColor={theme.palette.primary.contrastText}/>}
                    variant="contained"
                    size="medium"
                    disabled={submitting}
                    fullWidth={isMobile}                    
                >
                    Adicionar
                </Button>
            </Stack>
        </Stack>
    );
}

export default AddMemberForm;