import { DateFilterType } from 'pages/BankAccount/BankAccountActive/BankAccountView/Tabs/AccountStatementTab/AccountStatementTabContainer';
import { StatementFilters, filterOptionsList } from './StatementFilters'
import { useFilters } from 'contexts/filterContext';
import { Popup } from '@uy3/web-components';
import { Calendar, IHandleCalendarSubmit, ITypeDateToIso } from 'components/Calendar/Calendar';
import { useState } from 'react';
import { ToastType } from 'contexts/apiRequestContext';
import { calculatePeriodRangeTwoDate } from 'helpers/formats/DateFormat';

interface IStatementFiltersContainerProps {
    setPeriodSelected: React.Dispatch<React.SetStateAction<string>>;
    setDateFilter: React.Dispatch<React.SetStateAction<DateFilterType>>
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
}

export const StatementFiltersContainer = ({ setPeriodSelected, setDateFilter, setToast }: IStatementFiltersContainerProps) => {
    const [openRange, setOpenRange] = useState<boolean>(false);
    const { addFilter } = useFilters();

    function subtractDaysFromDate(day: number): Date {
        const date = new Date();
        const replaceWithDay = date.setDate(date.getDate() - day);
        return new Date(replaceWithDay);
    };

    const skipDaysFromDate = (day: number) => {
        const initialDate = subtractDaysFromDate(day);
        return { initialDate, finalDate: new Date() };
    }

    const onGetFilterSelected = (index: number) => {
        const optionValue = filterOptionsList.find((_, i) => i === index);
        setPeriodSelected(optionValue!);

        if (optionValue === "Definir período") {
            return setOpenRange(true);
        }

        const filterOptions: { [type: string]: any } = {
            "Últimos 7 dias": skipDaysFromDate(7),
            "Últimos 15 dias": skipDaysFromDate(15),
            "Últimos 30 dias": skipDaysFromDate(30),
            "Últimos 60 dias": skipDaysFromDate(60),
            "Últimos 90 dias": skipDaysFromDate(90)
        };

        const { initialDate, finalDate } = filterOptions[optionValue!];
        setDateFilter({ finalDate, initialDate })
        return addFilter('initialDate', initialDate, finalDate);
    }

    const handlePeriodSelected = ({ rangeValueDate }: IHandleCalendarSubmit) => {
        const { endDate, startDate } = rangeValueDate as ITypeDateToIso;
        const getPeriodInNumber = calculatePeriodRangeTwoDate(startDate, endDate).days;

        if (getPeriodInNumber > 90) {
            return setToast({
                open: true,
                severity: "info",
                title: "Limite de período excedido",
                description: `O período selecionado de ${getPeriodInNumber} dias ultrapassou o limite máximo permitido de 90 dias. Por favor, escolha um intervalo dentro dos limites permitidos.`
            })
            
        }
        setDateFilter({ initialDate: startDate, finalDate: endDate }); 
        addFilter('initialDate', startDate, new Date(endDate));
        return setOpenRange(false); 
    }

    return <>
        <StatementFilters onGetFilterSelected={onGetFilterSelected} openSelectPeriod={() => setOpenRange(true)} />
        <Popup
            open={openRange}
            title='Selecione um período'
            onClose={() => setOpenRange(false)}
            children={
                <Calendar
                    typeOfUse='onlyCalendar'
                    onClose={() => setOpenRange(false)}
                    handleSubmit={handlePeriodSelected}
                />
            }
        />
    </>
}