import { NotificationsReadModel } from "services/notifications/types/notificationsReadModel";
import { ContentNotificationStack, DetailContentNotificationStask } from "./notification.styled";
import { MaximizeIcon, Typography } from "@uy3/web-components";
import { formatCustomDate } from "helpers/formats/DateFormat";
import moment from "moment";
import { IconButton, Stack } from "@mui/material";

type NotificationsProps = {
    notification: NotificationsReadModel;
    handleLastReadAndRedirect: () => void;
}; 

export const Notification = ({ notification, handleLastReadAndRedirect }: NotificationsProps) => {
    const { title, message, createdAt, groupDisplay, isNewNotification: newNotification } = notification;
    return (
        <ContentNotificationStack isNew={newNotification}>
            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                <Typography fontSize='14px' fontWeight='700' m='6px 0'>
                    {title}
                </Typography>
                <Typography fontSize='14px' fontWeight='400' m='6px 0'>
                    {['Ontem', 'Hoje'].includes(groupDisplay!) ? `às ${moment(createdAt).format('LT')}` : formatCustomDate(createdAt)}
                </Typography>
            </Stack>

            <DetailContentNotificationStask spacing={2} isNew={newNotification}>
                <Typography fontSize='14px'>
                    {message}
                </Typography>

                <IconButton onClick={handleLastReadAndRedirect}>
                    <MaximizeIcon sx={{height: 23, width: 23}} />
                </IconButton>
            </DetailContentNotificationStask>



        </ContentNotificationStack>
    )
}
