import { boolean, object, string } from "yup";

export function validationGenerateCnabSchema() {
    return object().shape({
        selectManualDate: boolean(), 
        initDate: string().when("selectManualDate", {
            is: true,
            then: string().typeError('Data inicial: precisa ser preenchido').required('Data inicial: precisa ser preenchido'),
            otherwise: string().nullable()
        }),
        endDate: string().when("selectManualDate", {
            is: true,
            then: string().typeError('Data final: precisa ser preenchido').required('Data final: precisa ser preenchido'),
            otherwise: string().nullable()
        })
    });
}

export const defaultValuesGenerateCnab = {
    initDate: new Date(),
    endDate: new Date(), 
    selectManualDate: false
};