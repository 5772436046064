import { InferType, string, object } from 'yup';


export function validationSchemaGroupForm() {
    return object().shape({
        groupName: string().typeError('Grupo: Precisa ser preenchido.').required('Grupo: Precisa ser preenchido.')      
    });
}

let inferedSchema = validationSchemaGroupForm();

export type GroupFormSchema = InferType<typeof inferedSchema>;

export const defaultValuesGroupForm = {
    groupName: "",
};