import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';


export const BoxIcon = styled(Box)(({ theme }) => ({
  background: theme.palette.primary.shade?.['10'],
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&.small': {
    height: 'auto',
    width: '80px',
    padding: '20px',
    '& svg': {
      fontSize: '20px!important',
    }
  },
  '&.medium': {
    height: 'auto',
    width: '84px',
    padding: '22px',
    '& svg': {
      fontSize: '24px!important',
    }
  },
  '&.large': {
    height: '84px',
    width: '84px',
    padding: '24px',
    '& svg': {
      fontSize: '26px!important',
      color: theme.palette.primary.main,
    }
  },
  '&.grey': {
    background: theme.palette.neutral.primaryShade?.['10'],
    '& svg': {
      color: theme.palette.neutral.primaryShade?.['45'],
    }
  },
  '&.dark': {
    background: theme.palette.neutral.primaryShade?.['10'],
    '& svg': {
      color: theme.palette.neutral.dark,
    }
  },
  '&.primary': {
    background: theme.palette.primary.shade?.['10'],
    '& svg': {
      color: theme.palette.primary.main,
    }
  },
}))