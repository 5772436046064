import { activeConfig } from 'services/config';
import { GetListApiResponse, GetListApiResponseSuccess } from 'contexts/apiRequestContext';
import { GroupInfo } from './userGroups';
import { creditAxiosApi } from "services/axiosApi/axiosApi";import { EnableOrDisableUserType, ResetUserPasswordProps, UserGetResponseModel } from './types/userTypes';
import { UserGetDetailResponseModel } from 'contexts/userContext';

var appConfig = activeConfig();

export interface UserListProps {
    page: number;
    size: number;
    name?: string;
    email?: string;
    paginationToken?: string;
    tenant?: string | undefined;
};

var url = `/User`;
export async function getUserList(filters: UserListProps, token: string) {
    const { email, name, paginationToken, page, size, tenant } = filters;
    let url = `/User?size=${size}&page=${page}`;

    url = tenant ? url + `&tenant=${tenant}` : url;
    url = name ? url + `&filter=name^="${name}"` : url;
    url = email ? url + `&filter=email^="${email}"` : url;
    url = paginationToken ? url + `&paginationToken=${paginationToken}` : url;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };

    return await creditAxiosApi.get<GetListApiResponseSuccess<UserGetResponseModel | UserGetDetailResponseModel>>(url, config);

};

export function getUserById(id: string, token: string, tenant?: string) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant: tenant }
    };

    return creditAxiosApi.get(`${url}/${id}`, config);
};

export function editOrCreateUser(data: any, userId?: string, tenant?: string, token = "") {
    let { phoneNumber, sendNotifications } = data;

    var sendNotificationsValue = sendNotifications === true ? 1 : 0;
    const onlyNumbers = /\D/g;
    phoneNumber = phoneNumber?.toString().replace(onlyNumbers, '');

    const payload = {
        ...data,
        phoneNumber: phoneNumber,
        registrationNumber: data?.registrationNumber?.toString().replace(onlyNumbers, ''),
        sendNotifications: sendNotificationsValue
    };

    const type = userId === 'novo' ? 'create' : 'edit';

    var url = type === 'create' ? appConfig.CREDIT_API_URL + `/User` :
        appConfig.CREDIT_API_URL + `/User/${userId}`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };

    const request = {
        create: {
            function: () => creditAxiosApi.post(url, payload, config),
            successMessage: 'Operador criado com sucesso',
            errorMessage: 'Não foi possível criar o operador.'
        },
        edit: {
            function: () => creditAxiosApi.put(url, payload, config),
            successMessage: 'Operador atualizado com sucesso.',
            errorMessage: 'Não foi possível atualizar o operador.'
        }
    };

    return request[type].function();
}

export function disableMFA(id: string, token: string, tenant?: string | undefined) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant: tenant }
    };

    return creditAxiosApi.put(`${url}/${id}/DisableMFA`, {}, config);
}

export function resetUserPassword({ id, password, token, tenant }: ResetUserPasswordProps) {
    let urlResetPassword = url + `/${id}/ResetPassword`;

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant, password }
    };

    return creditAxiosApi.put(urlResetPassword, {}, config);
}

export interface UserGroupListProps {
    tenant?: string | undefined;
    page: number;
    size: number;
    filters?: object;
    paginationToken?: string | undefined;
}

export function getUserGroupList(filters: UserGroupListProps, userId: string, token: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: filters
    };
    return creditAxiosApi.get<GetListApiResponse<GroupInfo>>(`${url}/${userId}/Groups`, config);
}

export function deleteOperatorById(id: string, token: string, tenant?: string) {

    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant: tenant }
    };

    return creditAxiosApi.delete(url + `/${id}`, config);
}

export function enableOrDisableUser({ token, type, userId, tenant }: EnableOrDisableUserType) {
    const config = {
        headers: { Authorization: `Bearer ${token}` },
        params: { tenant }
    };
    return creditAxiosApi.put(`${url}/${userId}/${type}`, {}, config);
}