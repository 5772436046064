import React from 'react'
import { CancelForm } from './CancelForm'
import { Stack } from '@mui/material'
import { useDownBankSlipMutation } from 'contexts/wallet/bankSlip/bankSlip';
import { ApiResponseError } from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { validationCancelFormSchema, defaultValuesCancelFormSchema } from './CancelFormSchema';
import { FieldValues } from 'react-hook-form';

type CancelFormContainerProps = {
    barCode: string | string[];
    handleSuccess: (type: string) => void;
    handleError: (error: ApiResponseError) => void;
    onClose: () => void
}

export const CancelFormContainer: React.FC<CancelFormContainerProps> = ({ barCode, handleError, handleSuccess, onClose }) => {
    const { downBankSlipMutateAsync, isLoading } = useDownBankSlipMutation(() => handleSuccess("down"), handleError)

    const downloadMethod = async (barCode: string, keepBillingOpen: boolean) => await downBankSlipMutateAsync({barCode, keepBillingOpen}); 

    const handleSubmit = async (values: FieldValues) => {
        if (typeof barCode === 'object') {
            return ((barCode??[]) as string[])?.forEach(async (x) => await downloadMethod(x, values?.keepBillingOpen)); 
        }
       return await downloadMethod(barCode as string, values?.keepBillingOpen)   
    }

    return (
        <Stack>
            <FormProvider
                defaultValues={defaultValuesCancelFormSchema}
                validationSchema={validationCancelFormSchema()}
                onSubmit={handleSubmit}
                children={(
                    <CancelForm {...{
                        isLoading,
                        onClose
                    }} />
                )}
            />
        </Stack>
    )
}
