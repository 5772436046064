import React, { useEffect, useState } from 'react';
import { BankAccuntList } from './BankAccountList';
import { Grid } from '@mui/material';
import { Actions } from 'components/Actions/Actions';
import { ApproveIcon, Drawer, RejectIcon, UpdateIcon } from '@uy3/web-components';
import { useApprovalsBankAccount } from 'contexts/approvalsContext';
import { FilterProvider } from 'contexts/filterContext';
import { optionsFilterBankAccountPending } from 'components/DataTableFilters/BankAccountFilters/BankAccountFilters';
import { RefreshProgress } from 'components/RefreshProgress';
import { Error } from 'components/Errors/Error';
import { useFilterValues } from 'contexts/filterValuesContext';
import {
    ApprovalsFull,
    ApproveBankAccountApprovalsType,
    approveBankAccountApprovals,
} from 'services/approvals/approvals';
import { useGenerateSessionIdMutation, useIdentity } from 'contexts/identityContext';
import { AxiosResponse } from 'axios';
import { IMFAState } from 'contexts/bankAccount/bankAccountType';
import {
    defaultValuesConfirmMfaForm,
    validationConfirmMfaFormSchema,
} from 'components/ConfirmMfaForm/ConfirmMfaFormSchema';
import { FormProvider } from 'contexts/formContext';
import ConfirmMfaForm from 'components/ConfirmMfaForm/ConfirmMfaForm';
import {
    ApiResponseError,
    ToastType,
    showErrorToast,
    showSuccessToast,
    toastState,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { BankAccountDetails } from '../Details/BankAccountDetails';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { ApprovalsHeader } from 'pages/Approvals/ApprovalsForm/ApprovalsHeader';
import { getRecordIdByResource, mapperOpitonsPayloadApprovals, payloadActionBankAccountApprovals } from 'helpers/formats/StringFormats';
import { ListSkeleton } from 'components/Skeleton/ListSkeleton';
import { ApprovalLimitFormContainer } from './ApprovalLimitForm/ApprovalLimitFormContainer';
import { FieldValues } from 'react-hook-form';
import { GridRowId } from '@mui/x-data-grid';
import { MapperApprovalsBankAccount } from 'helpers/methods/MapperApprovalsBankAccount';


export const ApprovalsBankAccountListContainer = () => {
    const [rowsSelected, setRowsSelected] = useState<GridRowId[]>([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const { token } = useIdentity();
    const [page, setPage] = useState(0);
    const [transactionLimitValues, setTransactionLimitValues] = useState<FieldValues | null>(null);
    const [toast, setToast] = useState<ToastType>(toastState);
    const [dataSelected, setDataSelected] = useState<any>(null);
    const [rowAction, setRowAction] = useState<{ action: string; rowData?: ApprovalsFull } | undefined>(undefined);
    const { filterValues } = useFilterValues();
    const { startRequest, endRequest } = useApiRequest();
    const filterSelected = filterValues?.filters?.typeOfPendingApprovals ?? 'BankAccountUser';

    const isFormTransactionLimit = filterSelected === 'BankAccountTransactionLimits'

    useEffect(() => setRowsSelected([]), [filterSelected])

    const onClose = () => {
        setRowAction(undefined);
    };

    const {
        pendingApprovalsData,
        isFetching,
        pendingApprovalsError,
        pendingApprovalsStatus,
        refetch,
        isLoading
    } = useApprovalsBankAccount({ page, size: rowsPerPage });

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const resource = filterSelected.toString();

    const onSelectionModelChange = (itemsSelecteds: GridRowId[]) => {
        const dataList = pendingApprovalsData?.data.filter((item: any) => itemsSelecteds.includes(item?.id));
        setRowsSelected(itemsSelecteds);
        setDataSelected(dataList);
    }

    const { mutateGenerateSessionId } = useGenerateSessionIdMutation();

    const handleSubmitApprova = (values: FieldValues) => {
        setTransactionLimitValues(values);
        setRowAction({ action: 'approve', rowData: rowAction?.rowData })
    }

    const approveOrRejectSubmit = (values: any, action: string) => {
        approveOrRejectBankAccount({ values, action });
    }

    type FromApproveType = {
        values: IMFAState,
        action: string
    }

    const approveSingleGenericBankAccount = async (values: FromApproveType, payload: unknown) => {
        startRequest();

        generateSessionId(values, payload, async (data) => {
            await approveBankAccountApprovals(data, token!)
                .then(() => {
                    const successMessage = `Sucesso ao ${values.action === 'Approve' ? 'aprovar' : 'rejeitar'} esse registro`;
                    const title = successMessage;
                    const description = undefined;
                    showSuccessToast(title, description, setToast);
                    onClose();
                    refetch();
                    endRequest(true);
                })
                .catch((response: ApiResponseError) => {
                    const { errorMessage } = mapErrorResponse(response);
                    const title = 'Ops, ocorreu um erro!';
                    const description = errorMessage;
                    showErrorToast(title, description, setToast);

                    endRequest(false);
                });
        });
    };

    const generateSessionId = (
        props: FromApproveType,
        payload: any,
        actionBankAccount: (data: ApproveBankAccountApprovalsType) => void
    ) => {
        const { values, action } = props;
        mutateGenerateSessionId({
            userPassword: values.password,
            then: (response: AxiosResponse<string, unknown>) => {
                let data = {
                    ...payload,
                    code: values.code,
                    action, 
                    sessionId: response.data,
                    type: filterSelected.toString()
                } as ApproveBankAccountApprovalsType;

                if (isFormTransactionLimit) {
                    return actionBankAccount({ ...data, ...transactionLimitValues });
                }
                return actionBankAccount(data);
            },
        });
    };


    const approveOrRejectBankAccount = (values: FromApproveType) => {
        if (rowsSelected.length === 0 && !!rowAction) {
            const object = payloadActionBankAccountApprovals(rowAction.rowData)[resource!];
            return approveSingleGenericBankAccount(values, MapperApprovalsBankAccount(object, resource));

        } else if (rowsSelected.length > 0) {
            const isNotLimitOrOperators = ["BankAccountTransfer", "BankAccountBeneficiary", "BankAccountPayment"].includes(resource); 

            const dataSelectedFormated = dataSelected.map((item: any) => payloadActionBankAccountApprovals(item)[resource!]);
            const listCustom = dataSelectedFormated?.map((item: any) => {
                if (isNotLimitOrOperators) {
                    return {
                        bankAccountId: item?.bankAccountId,
                        [getRecordIdByResource(resource!)]: item?.id
                    };
                };
                return item.id;
            }); 

            return approveSingleGenericBankAccount(values, {
                [mapperOpitonsPayloadApprovals(resource)]: listCustom
            });
        }
    };

    if (pendingApprovalsStatus === 'error') return <Error error={pendingApprovalsError} />;
    const mfaSchema = validationConfirmMfaFormSchema();
    const mfaDefaultValues = defaultValuesConfirmMfaForm;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Grid mb={4}>
                <ApprovalsHeader
                    Actions={<Actions
                        numberOfButtons={3}
                        buttonsActionsList={[
                            {
                                enable: rowsSelected.length > 0,
                                label: 'Aprovar',
                                action: () => setRowAction({ action: 'approve' }),
                                icon: <ApproveIcon />,
                            },
                            {
                                enable: rowsSelected.length > 0,
                                label: 'Reprovar',
                                action: () => setRowAction({ action: 'reject' }),
                                icon: <RejectIcon />,
                            },
                            {
                                enable: true,
                                label: 'Atualizar',
                                action: refetch,
                                icon: <UpdateIcon />,
                            },
                        ]}
                    />}
                />
            </Grid>
            {!isLoading && <Grid mt={3} pb={2}>
                <RefreshProgress refreshing={isFetching} />
            </Grid>}
            <Grid>
                <FilterProvider
                    availableFilters={{
                        typeOfPendingApprovals: {
                            label: 'Aprovações',
                            type: 'select',
                            multiple: false,
                            options: optionsFilterBankAccountPending,
                        },
                    }}
                >
                    <ListSkeleton isLoading={isLoading}>
                        <BankAccuntList
                            setRowAction={setRowAction}
                            page={page}
                            filterSelected={filterSelected}
                            setPage={onChangePage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={onChangeRowsPerPage}
                            queryData={pendingApprovalsData}
                            onSelectionModelChange={onSelectionModelChange}
                            rowsSelected={rowsSelected}
                        />
                    </ListSkeleton>
                </FilterProvider>
            </Grid>

            <Drawer
                anchor="right"
                title="Aprovar"
                toggleDrawer
                open={rowAction?.action === 'approve'}
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={mfaSchema}
                    defaultValues={mfaDefaultValues}
                    onSubmit={(values) => approveOrRejectSubmit(values, 'Approve')}
                >
                    <ConfirmMfaForm onClose={onClose} toggleDrawer />
                </FormProvider>
            </Drawer>
            <Drawer
                anchor="right"
                title="Reprovar"
                open={rowAction?.action === 'reject'}
                onClose={onClose}
            >
                <FormProvider
                    validationSchema={mfaSchema}
                    defaultValues={mfaDefaultValues}
                    onSubmit={(values) => approveOrRejectSubmit(values, 'Reject')}
                >
                    <ConfirmMfaForm onClose={onClose} />
                </FormProvider>
            </Drawer>
            <Drawer
                title="Detalhes"
                anchor="right"
                open={rowAction?.action === 'details'}
                onClose={onClose}
            >
                <BankAccountDetails
                    rowData={rowAction?.rowData!}
                    showLimit={() => setRowAction({ action: 'formApprovalLimits', rowData: rowAction?.rowData! })}
                    onClose={onClose}
                />
            </Drawer>

            <Drawer
                title="Aprovação de solicitação de aumento de limite"
                description='Confirme as informações do novo limite solicitado para prosseguir'
                anchor="right"
                open={rowAction?.action === 'formApprovalLimits'}
                onClose={onClose}
            >
                <ApprovalLimitFormContainer
                    handleSubmit={handleSubmitApprova}
                    rowData={rowAction?.rowData!}
                    onClose={onClose}
                />
            </Drawer>
        </>
    );
};
