import { object, string } from "yup";

type TypeBarcodeOrLine = "lengthTributes" | "consumerAccounts" | "default";

export const validationBarCodeSchema = (type?: TypeBarcodeOrLine) => {
    return object().shape({
        barCode: string()
            .typeError('Valor precisa ser uma string.')
            .required('Código de barras ou linha digitável precisa ser preenchido.')
            .test('length', 'O código de barras ou linha digitável inválida.', (value: any) => {
                let onlyNumbers = /\D/g;
                let barCodeOrLineDigitable = value?.replace(onlyNumbers, '');
                if (type === "lengthTributes") {
                    return barCodeOrLineDigitable.length === 44 || barCodeOrLineDigitable.length === 48
                } else if (type === "consumerAccounts") {
                    return barCodeOrLineDigitable.length === 44 || barCodeOrLineDigitable.length === 47 || barCodeOrLineDigitable.length === 48
                } else {
                    return barCodeOrLineDigitable.length === 44 || barCodeOrLineDigitable.length === 47;
                };
            })
    });
};

export const defaultValuesBarCode = {
    barCode: ""
};