import { lazy, Suspense } from 'react'
import { Stack } from '@mui/material'
import { GenericFormSkeletonSuspense } from 'components/Skeleton/GenericFormSkeleton';
import { useUserPermissionData } from 'contexts/userContext';
import { PermissionModel } from 'services/permissions';
import { RefreshProgress } from 'components/RefreshProgress';

const Header = lazy(() => import('./EffectivePermissionsHeader'));
const EffectivePermissionsUser = lazy(() => import('./EffectivePermissionsUser'));

export const EffectivePermissionsContainer = () => {
    const { data, permissionInModelApi, refetch, isFetching, isLoading } = useUserPermissionData();

    const permissions = (data ?? []) as PermissionModel[];

    if (isLoading) 
        return <GenericFormSkeletonSuspense/>

    return (
        <Stack m='0 20px 30px 0' >
            <Suspense fallback={<GenericFormSkeletonSuspense />}>
                <Header refetch={refetch} />
                <Stack mt={-3} mb={3}>
                    <RefreshProgress refreshing={isFetching} />
                </Stack>
                <EffectivePermissionsUser {...{ permissions, permissionInModelApi }} />
            </Suspense>
        </Stack>
    )
}