import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ActionsType } from './Actions';
import { ButtonAction } from './ButtonAction';
import { Stack } from '@mui/material';

type ButtonMenuProps = {
    action: ActionsType;
};

export default function ButtonMenu({ action }: ButtonMenuProps) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <ButtonAction
                key={action?.label}
                label={action?.label}
                startIcon={action?.icon}
                visible={Boolean(action?.enable)}
                onClick={handleClick}
                type={action?.type}
                disabled={action?.disabled}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                // anchorOrigin={{
                //     vertical: 'top',
                //     horizontal: 'left',
                // }}
                // transformOrigin={{
                //     vertical: 'top',
                //     horizontal: 'left',
                // }}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                slotProps={{
                    paper: {
                        sx: {
                            padding: '5px',
                            minWidth: '150px'
                        }
                    }
                }}
            >
                {action?.groupButtons?.map(({ action, label, icon }) => {
                    return (
                        <MenuItem
                            sx={{ padding: '4px', fontSize: '14px' }}
                            onClick={() => {
                                action && action();
                                handleClose();
                            }}
                        >
                            <Stack direction='row' alignItems='center' gap={1}>
                                {icon}
                                {label}
                            </Stack>
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
