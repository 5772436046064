import { Stack } from '@mui/material'
import { CompleteIcon, Typography } from '@uy3/web-components'
import { Actions } from 'components/Actions/Actions'
import { iconSx } from 'contexts/apiRequestContext'
import React from 'react'

type IncludeBatchAssignmentHeaderProps = {
    // createBatchAssignment: () => void;
}

export const IncludeBatchAssignmentHeader: React.FC<IncludeBatchAssignmentHeaderProps> = (props) => {
    return (
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' alignItems='center'>
                <Typography
                    variant="h4"
                    color="neutral.dark"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="32px"
                    lineHeight="38.4px"
                >
                    Nova cessão
                </Typography>
            </Stack>

            <Stack>
                <Actions
                    numberOfButtons={3}
                    buttonsActionsList={[
                        {
                            enable: true,
                            label: "Consultar",
                            type: 'submit', 
                            disabled: false,
                            icon: <CompleteIcon sx={iconSx} />
                        }
                    ]}
                />
            </Stack>
        </Stack>
    )
}
