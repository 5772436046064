import { useQueryClient } from '@tanstack/react-query';
import { ApiResponseError, invalidateAndRefetch, useApiRequest } from 'contexts/apiRequestContext';
import { FormProvider } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { FieldValues } from 'react-hook-form';
import { Grid } from '@mui/material';
import { TextFormField } from 'components/Forms/FormFields';
import {
    defaultValuesRejectInstrument,
    validationSchemaRejectInstrument,
} from './RejectInstrumentSchema';
import { Button } from '@uy3/web-components';
import { disapproveInstrumentById } from 'services/accounts/bankAccountRequest/bankAccountRequest';
import { useNavigate } from 'react-router';

type RejectProps = {
    setPopupProps: (props: any) => void;
    onErrorCatch: (response: ApiResponseError) => void;
    bankaccountId: string;
};

export const RejectInstrument = ({ bankaccountId, setPopupProps, onErrorCatch }: RejectProps) => {
    const { startRequest, endRequest } = useApiRequest();
    const { token } = useIdentity();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const handleDisapprove = async (values: FieldValues) => {
        startRequest();

        const payload = { message: values?.message };

        await disapproveInstrumentById(bankaccountId, payload, token!)
            .then(async () => {
                await invalidateAndRefetch(queryClient, ['bankAccountRequest-list']);
                endRequest(true);
                setPopupProps(undefined);
                navigate('/contas-digitais/solicitacoes');
            })
            .catch((response) => onErrorCatch(response));
    };

    const schema = validationSchemaRejectInstrument();
    const defaultValues = defaultValuesRejectInstrument;

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={handleDisapprove}
        >
            <>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <Grid item xs={12} mt={2}>
                        <TextFormField
                            name="message"
                            label="Mensagem"
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                            required
                        />
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        name="btn-cancel"
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopupProps(undefined)}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        name="btn-confirm"
                        variant="contained"
                        color="primary"
                        type="submit"
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};
