import { number } from "yup";
import { object, string } from "yup";

export function validationPixQRcodeSchema(type: string) {
    const schemaByTypeQrCode: { [type: string]: object } = {
        "QRCodeStatic": object().shape({
            pixKey: string().typeError("Chave Pix: precisa ser preenchida").required("Chave Pix: precisa ser preenchida"),
            valueInCents: number()
                .typeError("Valor precisa ser preenchido")
                .required("Valor: Precisa ser preenchido")
                .min(0.01, "Valor: precisa ser maior que 0"),
            description: string().nullable(),
        }),
        "QRCodeImmediateDynamic": object().shape({
            pixKey: string().typeError("Chave Pix: precisa ser preenchida").required("Chave Pix: precisa ser preenchida"),
            valueInCents: number()
                .typeError("Valor precisa ser preenchido")
                .required("Valor: Precisa ser preenchido")
                .min(0.01, "Valor: precisa ser maior que 0"),
            payerRegistrationNumber: string()
                .typeError("CPF/CNPJ do tomador: precisa ser preenchida")
                .required("CPF/CNPJ do tomador: precisa ser preenchida")
                .cpfCnpjValidation("CPF/CNPJ inválido"),
            payerName: string().typeError("Nome do pagador: precisa ser preenchida").required("Nome do pagador: precisa ser preenchida"),
        })
    };
    return schemaByTypeQrCode[type] ?? object().shape({});
}

export const defaultValuesPixQRcode = (type: string) => {
    const defaultObjectValue = {
        pixKey: null,
        valueInCents: null,
    };

    const defaultValue: { [type: string]: Object } = {
        "QRCodeStatic": {
            ...defaultObjectValue,
            description: null
        },
        "QRCodeImmediateDynamic": {
            ...defaultObjectValue,
            payerRegistrationNumber: null,
            payerName: null
        }
    };

    return defaultValue[type] ?? {};
};