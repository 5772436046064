import { object, string } from "yup"

export const validationSchemaSiapeQueryForm = () => {
    return object().shape({
        registrationNumber: string()
            .typeError('CPF: precisa ser preenchido')
            .required('CPF: precisa ser preenchido')
            .cpfCnpjValidation('CPF: inválido')
    })
}

export const defaultvaluesSiapeQueryForm = {
    registrationNumber: null
}