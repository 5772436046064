import { Stack } from '@mui/material';
import HighlightBox from 'components/HighlightBox';
import Spinner from 'components/RiskScore/DatasetsStatusColumn/Spinner';
import { useGetSignatureAsync } from 'contexts/signature/signatureContext';
import { activeTheme } from 'services/theme';

type StatusSignatureType = {
    signatureWorkFlowId: string;
};

const theme = activeTheme();
const palette = theme.palette;

const statusColors: { [statusDisplay: string]: string } = {
    'Aguardando Início': palette.info.dark,
    'Aguardando Assinatura': palette.warning.main,
    Concluído: palette.success.main,
    Cancelado: palette.error.light,
    Expirado: palette.error.main,
    'Não iniciado': palette.warning.light,
};

const statusBackgroundSize: { [statusDisplay: string]: string } = {
    'Aguardando Início': '170px',
    'Aguardando Assinatura': '170px',
    Concluído: '170px',
    Cancelado: '170px',
    Expirado: '170px',
    'Não iniciado': '170px',
};

export default function StatusSignature({ signatureWorkFlowId }: StatusSignatureType) {
    const { signatureData, isLoading, signatureFetch } = useGetSignatureAsync(signatureWorkFlowId);

    if ((isLoading && !!signatureWorkFlowId) || signatureFetch) {
        return <Spinner />;
    }

    const statusDisplay = signatureData?.statusDisplay || 'Não iniciado';

    return (
        <Stack position="relative">
            <HighlightBox
                background={statusColors[statusDisplay]}
                textDisplay={statusDisplay}
                width={statusBackgroundSize[statusDisplay]}
            />
        </Stack>
    );
}
