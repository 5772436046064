import { FormProvider, useFormContext } from 'contexts/formContext';
import { recalculateSchema } from './RecalculateFormSchema';
import { RecalculateForm } from './RecalculateForm';

type ApproveRevisionContainerProps = {
    onCloseModal: () => void;
    onCalculate: (values : any) => void;
    isLoadingRecalculate: boolean;
};

const RecalculateFormContainer = ({ onCloseModal, onCalculate, isLoadingRecalculate }: ApproveRevisionContainerProps) => {
    const { getValues } = useFormContext();
    const schema = recalculateSchema();
    const currentValues = getValues();
    const defaultValues = {
        assignmentDate: currentValues.assignmentDate
    };
    return (
        <FormProvider
            defaultValues={defaultValues}
            validationSchema={schema}
            onSubmit={(values) => onCalculate(values)}
        >
            <RecalculateForm
                onCloseModal={onCloseModal}
                isLoadingRecalculate={isLoadingRecalculate}
            />
        </FormProvider>
    )
}

export default RecalculateFormContainer