import { isEmpty } from 'lodash';
import { string, object, number, boolean } from 'yup';

export function validationPercentage(percentage: number, currentPercentage = 0) {
    const maxAllowedPercentage = 100;

    return number()
        .typeError('Porcentagem de participação inválida.')
        .min(0, 'Porcentagem de participação: Deve ser maior ou igual a 0.')
        .max(100, 'Porcentagem de participação: Deve ser menor ou igual a 100%.')
        .required('Participação na empresa: Precisa ser preenchida.')
        .test(
            'is-valid-sum',
            `A percentagem total atribuída não pode exceder 100%. Percentagem disponível restante: ${
                maxAllowedPercentage - percentage + currentPercentage
            }%.`,
            function (value = 0) {
                return value + percentage - currentPercentage <= maxAllowedPercentage;
            }
        );
}

const validateRelatedToId = (relatedToIdPercentage: number, currentPercentage = 0) =>
    validationPercentage(relatedToIdPercentage, currentPercentage);

export function validationSchemaBankAccInvolvedForm(
    percentage: number,
    currentPercentage = 0,
    relatedToIdPercentage: number
) {
    return object().shape({
        personId: string()
            .typeError('O Envolvido é inválido.')
            .required('Envolvido: Precisa ser preenchido.'),
        relatedToId: string().typeError('Relacionada a: é inválido.').nullable().notRequired(),
        typeOfRelationship: string()
            .typeError('Tipo de relação inválido.')
            .required('Tipo de relação: Precisa ser preenchido.'),
        isSigner: boolean().when('personDiscriminator', {
            is: (personDiscriminator: string) => personDiscriminator === 'NaturalPerson',
            then: boolean()
                .typeError('Signatário inválido.')
                .required('Signatário: Precisa ser preenchido.'),
            otherwise: boolean().nullable().notRequired(),
        }),
        participationPercentage: number().when(
            ['personDiscriminator', 'relatedToId'],
            (personDiscriminator, relatedToId) => {
                if (personDiscriminator === 'NaturalPerson' && !isEmpty(relatedToId)) {
                    return validateRelatedToId(relatedToIdPercentage, currentPercentage);
                }

                if (personDiscriminator === 'NaturalPerson') {
                    return validationPercentage(percentage, currentPercentage);
                }

                return number().nullable().notRequired();
            }
        ),
        tenantName: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string()
                .required('Correspondente é obrigatório.')
                .typeError('Correspondente inválido.'),
            otherwise: string().nullable().optional()
        }),
        userId: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string()
                .required('Operador é obrigatório.')
                .typeError('Operador inválido.')
                .test(
                    'is-valid-user',
                    'O CPF do operador deve ser o mesmo da pessoa selecionada',
                    function () {
                        const { personRegistrationNumber, userIdRegistrationNumber } = this.parent;
                        return personRegistrationNumber === userIdRegistrationNumber;
                    }
                ),
            otherwise: string().nullable().optional(),
        }),
        level: string().when('typeOfRelationship', {
            is: 'BillingAgentApproverUser',
            then: string()
                .required('Nível de permissão é obrigatório.')
                .typeError('Nível de permissão inválido.'),
            otherwise: string().nullable().optional()
        }),
    });
}

export const defaultValuesBankAccInvolvedForm = {
    personId: null,
    relatedToId: null,
    typeOfRelationship: null,
    isSigner: false,
    participationPercentage: 0,
    tenantName: null,
    userId: null,
    level: null,
};
