import { Stack } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';
import { SelectFormField } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { ApiResponseError, iconSx } from 'contexts/apiRequestContext';
import { ApiErrorAlert } from 'components/Errors/ApiErrorAlert';
import { useTenant } from 'contexts/tenantContext';
import { activeTheme } from 'services/theme';

type AccountOperatorsFormProps = {
    onClose: () => void;
    submitError: ApiResponseError | any;
    listOperator: AutocompleteOptionList;
    submitting: boolean
}

const theme = activeTheme();
export const AccountOperatorsForm = ({ listOperator, onClose, submitError, submitting }: AccountOperatorsFormProps) => {
    const { tenantAutoCompleteProps } = useTenant();

    return (
        <Stack spacing={2}>
            <AutocompleteField
                name='tenant'
                displayName='tenantDisplay'
                label='Correspondente'
                required
                {...tenantAutoCompleteProps}
            />
            <AutocompleteField
                label='Operadores'
                name='userId'
                displayName='userIdDisplay'
                required
                {...listOperator}
            />
            <SelectFormField
                name="level"
                label="Nível de permissão"
                options={[
                    { label: 'Visualizador', value: "Viewer" },
                    { label: 'Aprovador em Conjunto', value: "JointApprover" },
                    { label: 'Aprovador Master', value: "MasterApprover" }, 
                    { label: 'Solicitante', value: "Requester" }
                ]}
                required
            />
            <ApiErrorAlert error={submitError} />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
                <Button
                    variant='outlined'
                    onClick={onClose}
                    startIcon={<CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />}
                >
                    Fechar
                </Button>
                <Button
                    startIcon={<SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />}
                    variant="contained"
                    size="medium"
                    type='submit'
                    disabled={submitting}
                >
                    Salvar
                </Button>
            </Stack>
        </Stack>
    )
}
