import { useQueryClient } from '@tanstack/react-query';
import { ApproveIcon, RejectIcon, UpdateIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';
import { Stack } from '@mui/material';
import { Actions } from 'components/Actions/Actions';

interface PaymentHeaderListProps {
    isFetching: boolean;
    refetch: () => void;
    bankAccountId: string | undefined;
    enableBatchActionApprove: boolean
    handleAction: (action: "approve" | "reject") => void
}

const theme = activeTheme();
const PaymentHeader: React.FC<PaymentHeaderListProps> = ({ isFetching, refetch, bankAccountId, enableBatchActionApprove, handleAction }) => {
    const color = !isFetching ? theme.palette.common.black : theme.palette?.grey['400'];
    const queryClient = useQueryClient();

    async function paymentRefetch() {
        refetch();
        await queryClient.invalidateQueries(['bank-account-balance', bankAccountId]);
        await queryClient.refetchQueries(['bank-account-balance', bankAccountId]);
    }

    return (
        <Stack direction={'row'} justifyContent="flex-end" spacing={2} mr={2} mb={2}>
            <Actions
                numberOfButtons={3}
                buttonsActionsList={[
                    {
                        enable: enableBatchActionApprove,
                        label: "Aprovar",
                        action: () => handleAction('approve'),
                        icon: <ApproveIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: enableBatchActionApprove,
                        label: "Reprovar",
                        action: () => handleAction('reject'), 
                        icon: <RejectIcon sx={iconSx} htmlColor={color}/>,
                        disabled: false
                    },
                    {
                        enable: true,
                        label: "Atualizar",
                        action: paymentRefetch,
                        icon: <UpdateIcon sx={iconSx} htmlColor={color}/>,
                        disabled: isFetching
                    },
                ]}
            />
        </Stack>
    );
};
export default PaymentHeader;