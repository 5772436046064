import { billingAxiosApi } from "services/axiosApi/axiosApi";
import { ConfirmationDownloadFilePDFRequestProps, FiltersFileConfirmation } from "./fileConfirmation.type";


export const getAllFileConfirmation = async (filters: FiltersFileConfirmation, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }, 
        params: filters
    };
    return await billingAxiosApi.get(`/FileConfirmation`, config);
}

export const getAllFileConfirmationById = async (fileConfirmationId: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.get(`/FileConfirmation/${fileConfirmationId}`, config);
}

export const downloadFileConfirmationById = async (fileConfirmationId: string, token: string) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }
    };
    return await billingAxiosApi.get(`/FileConfirmation/${fileConfirmationId}/download`, config);
}

export const getConfirmationLinesById = async (fileConfirmationId: string, token: string, params?: object) => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        }, 
        params
    };
    return await billingAxiosApi.get(`/FileConfirmation/${fileConfirmationId}/Lines`, config);
}

export const downloadTransactionReceiptFileConfirmation = async (confirmationTransactionId: string, token: string) => {
    const config = {
        responseType: 'blob' as 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    return await billingAxiosApi.post(`/FileConfirmation/Transaction/${confirmationTransactionId}/DownloadReceipt`, {}, config);
}

export const downloadReportFileConfirmationLine = async (payload: ConfirmationDownloadFilePDFRequestProps, token: string) => {
    const config = {
        responseType: 'blob' as 'blob',
        headers: {
            Authorization: `Bearer ${token}`
        }
    };
    return await billingAxiosApi.post(`/FileConfirmation/DownloadReport`, payload, config);
}
