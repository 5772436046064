
export const optionsBankAccNewTimeLine = [
	{
		label: 'Aprovação Abertura de Conta Bancaria',
		value: 'BankAccountApprove'
	},
	{
		label: 'Assinatura',
		value: 'Signature'
	},
	{
		label: 'Abertura de conta',
		value: 'BankAccountOpen'
	},
	{
		label: 'Cancelada',
		value: 'Cancel'
	},
    {
		label: 'Encerrado',
		value: 'Finished'
	},
    {
		label: 'Rascunho',
		value: 'Draft'
	},
	{
		label: 'Reprovado',
		value: 'Disapproved',
	},
	{
		label: 'Revisão',
		value: 'Revision'
	},
	{
		label: 'Revisão de Conta Bancária',
		value: 'BankAccountRequestRevision'
	},
	{
		label: 'Revisão de Conta Bancária Aprovada',
		value: 'ApprovedBankAccountRequestRevision'
	},
	{
		label: 'Revisão de abertura',
		value: 'RevisionOpenBankAccount'
	}

];

export const optionsBankAccNewStatus = [
    {
        label: 'Ativa',
        value: 'Active'
    },
    {
        label: 'Aprovação',
        value: 'Approval'
    },
    {
        label: 'Abertura',
        value: 'Processing'
    },
    {
        label: 'Assinatura',
        value: 'Signatures'
    },
    {
        label: 'Validação de Assinatura',
        value: 'SignaturesValidation'
    },
    {
        label: 'Aprovação de Instrumento',
        value: 'InstrumentApproval'
    },
    {
        label: 'Cancelado',
        value: 'Cancel'
    },
    {
        label: 'Expirado',
        value: 'Timeout'
    },
    {
        label: 'Encerrado',
        value: 'Closed'
    },
    {
        label: 'Rascunho',
        value: 'Draft'
    },
    {
        label: 'Revisão',
        value: 'Revision'
    },
    {
        label: 'Revisão de Abertura de Conta',
        value: 'OpeningAccountRevision'
    },
    {
        label: 'Pré-Análise Corban',
        value: 'CorbanPreAnalysis'
    }
];