export const MapperApprovalsBankAccount = (reference: any, resource: string) => {

    const defaultValue = { bankAccountId: reference?.bankAccountId }; 
    const resourceId = reference?.id; 

    const optionsByResource: { [type: string]: any } = {
        "BankAccountTransfer": {
            transferBankAccounts: [{
                ...defaultValue,
                transferId: resourceId
            }]
        },
        "BankAccountTransactionLimits": {
            bankAccountTransactionLimitsIds: [resourceId]
        },
        "BankAccountUser": {
            operatorIds: [resourceId]
        },
        "BankAccountBeneficiary": {
            beneficiaryBankAccounts: [{
                ...defaultValue,
                beneficiaryId: resourceId
            }]
        },
        "BankAccountPayment": {
            payBankAccounts: [{
                ...defaultValue,
                paymentId: resourceId
            }]
        }
    }
    return optionsByResource[resource!];
}