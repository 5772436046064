import { Button, LeftIcon, RightIcon, UploadIcon } from '@uy3/web-components';
import { Stack } from '@mui/material';
import { activeTheme } from 'services/theme';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';

interface ButtonDrawerProps {
    steps: { step: number };
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
    setSteps: React.Dispatch<React.SetStateAction<{ step: number }>>;
    resetOptionsFilter?: () => void;
}

const theme = activeTheme();
const ButtonDrawer = ({ steps, setSteps, setOpenDrawer, resetOptionsFilter }: ButtonDrawerProps) => {
    const { submitting } = useApiRequest();
    return (
        <Stack
            spacing={2}
            mt={3}
            direction={{ xs: 'column', sm: 'row' }}
            display="flex"
            justifyContent="flex-end"
        >
            <Button
                variant="outlined"
                onClick={() => {
                    if (steps.step === 1) {
                        setOpenDrawer(false);
                        resetOptionsFilter && resetOptionsFilter();
                    } else {
                        setSteps({ step: steps.step - 1 });
                    }
                }}
                startIcon={
                    <LeftIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                }
            >
                {steps.step === 2 ? 'Voltar e editar os valores' : 'Voltar'}
            </Button>

            {steps.step === 1 ? (
                <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={submitting}
                    startIcon={
                        <RightIcon
                            htmlColor={theme.palette.common.white}
                            sx={iconSx}
                        />
                    }
                >
                    Avançar
                </Button>
            ) : (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        if (steps.step === 3) {
                            setSteps({ step: 1 });
                        }

                        if (steps.step < 3) {
                            setSteps({ step: steps.step + 1 });
                        }
                    }}
                    startIcon={
                        steps.step === 3 ? (
                            <UploadIcon
                                htmlColor={theme.palette.common.white}
                                sx={iconSx}
                            />
                        ) : (
                            <RightIcon
                                htmlColor={theme.palette.common.white}
                                sx={iconSx}
                            />
                        )
                    }
                >
                    {steps.step === 3 ? 'Adicionar documento' : 'Avançar'}
                </Button>
            )}
        </Stack>
    )
};

export default ButtonDrawer;
