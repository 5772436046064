import { FormProvider } from 'contexts/formContext'
import React from 'react'
import { CompleteManualWarranty } from './CompleteManualWarranty'
import { validationSchemaCompleteManualWarrantySchema, defaultvaluesCompleteManualWarrantySchema } from './CompleteManualWarrantySchema'
import { FieldValues } from 'react-hook-form'
import { useFinishManualWarrantyMutation } from 'contexts/creditNote/creditContext'
import { useParams } from 'react-router'
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast } from 'contexts/apiRequestContext'
import { mapErrorResponse } from 'contexts/responseErrorContext'
import { useGetAvailableReserveTokenByPersonId } from 'contexts/naturalPersonContext'
import { GenericActionHandler } from 'components/GenericActionHandler/GenericActionHandler'
import { Stack } from '@mui/material'

type CompleteManualWarrantyContainerProps = {
    onClose: () => void;
    personId: string;
    handleAddToken: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>
    isNaturalPerson: boolean
    isNotRequiredFields: boolean
}

export const CompleteManualWarrantyContainer = ({ onClose, setToast, personId, handleAddToken, isNaturalPerson, isNotRequiredFields }: CompleteManualWarrantyContainerProps) => {
    const { id } = useParams();
    const { data: tokensList } = useGetAvailableReserveTokenByPersonId(personId!, isNaturalPerson);

    const handleSuccess = () => {
        showSuccessToast("Sucesso ao concluir garantia", "", setToast);
        onClose();
    }

    const handleError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        showErrorToast("Ops, ocorreu um erro", errorMessage, setToast)
    }

    const { mutate } = useFinishManualWarrantyMutation(id!, handleSuccess, handleError);

    const onSubmit = (values?: FieldValues) => {
        
        if (!!isNotRequiredFields) {
            return mutate(null)
        }

        const tokens = values?.tokens;
        const token = tokens.map((item: any) => {
            return {
                token: {
                    tokenType: item.Token.TokenType,
                    tokenValue: item.Token.TokenValue
                },
                warrantyRegistrationOffice: item.WarrantyRegistrationOffice,
                expirationDate: item.ExpirationDate,
                warrantyCode: item.warrantyCode
            }
        });
        mutate(token);
    }

    if (isNotRequiredFields) {
        return (
            <Stack mt={-3}>
                <GenericActionHandler {...{
                    handleSubmit: onSubmit,
                    onClose: onClose,
                    isModal: true
                }} />
            </Stack>
        )
    }

    return (
        <div>
            <FormProvider
                defaultValues={defaultvaluesCompleteManualWarrantySchema}
                validationSchema={validationSchemaCompleteManualWarrantySchema()}
                onSubmit={onSubmit}
            >
                <CompleteManualWarranty {...{
                    onClose,
                    handleAddToken,
                    tokensList: tokensList ?? []
                }} />
            </FormProvider>
        </div>
    )
}