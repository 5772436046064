import React from 'react';
import { Button, ButtonProps, CloseIcon, LeftIcon, RightIcon, SaveIcon } from '@uy3/web-components';
import { activeTheme } from 'services/theme';
import { Stack } from '@mui/material';
import { iconSx } from 'contexts/apiRequestContext';

interface ButtonStepProps {
    currentStep: number;
    onNextStep: () => void;
    onPrevStep: () => void;
    onClose: () => void;
    isNaturalPerson?: boolean;
    formError: any;
    isBankAccountRequest?: boolean;
}

interface LabeledButtonProps extends ButtonProps {
    label: string;
}

const theme = activeTheme();

const ButtonStep: React.FC<ButtonStepProps> = ({
    currentStep = 0,
    onClose,
    onPrevStep,
    onNextStep,
    isNaturalPerson,
    formError,
    isBankAccountRequest = false
}) => {
    const hasAddressError = formError?.address;

    const buttonConfigs: LabeledButtonProps[] = [
        {
            label: 'Fechar',
            onClick: onClose,
            name: 'btn-onClose',
            variant: 'outlined',
            size: 'medium',
            startIcon: (
                <CloseIcon htmlColor={theme.palette.primary.main} sx={{ height: 17, width: 20 }} />
            ),
            color: 'primary',
        },
        {
            label: 'Voltar',
            disabled: !!hasAddressError,
            onClick: onPrevStep,
            name: 'btn-onBack',
            variant: 'outlined',
            size: 'medium',
            startIcon: (
                <LeftIcon htmlColor={!!hasAddressError ? theme.palette?.grey["400"] : theme.palette.primary.main} sx={iconSx} />
            ),
        },
        currentStep >= 1
            ? {
                  label: 'Continuar',
                  type: 'submit',
                  name: 'btn-onNext',
                  variant: 'contained',
                  size: 'medium',
                  startIcon: (
                      <RightIcon
                          htmlColor={theme.palette.common.white}
                          sx={{ height: 23, width: 23 }}
                      />
                  ),
              }
            : {
                  label: 'Continuar',
                  onClick: onNextStep,
                  name: 'btn-onNext',
                  variant: 'contained',
                  size: 'medium',
                  startIcon: (
                      <RightIcon
                          htmlColor={theme.palette.common.white}
                          sx={{ height: 23, width: 23 }}
                      />
                  ),
              },
        {
            label: 'Salvar cadastro',
            type: 'submit',
            name: 'btn-submit',
            variant: 'contained',
            size: 'medium',
            startIcon: (
                <SaveIcon htmlColor={theme.palette.common.white} sx={iconSx} />
            ),
            color: 'primary',
        },
    ];

    const renderTheButtons = [
        [0, 2],
        [0, 1, 2],
        [0, 1, 3],
    ];

    const getConfigurations = (isNaturalPerson = false) => {
        if (!isNaturalPerson) {
            if (currentStep === 0) return renderTheButtons[0];
            if (currentStep < 4) return renderTheButtons[1];
            if (currentStep === 4) return renderTheButtons[2];
        } else {
            if (currentStep === 0) return renderTheButtons[0];
            if (currentStep < 2 && !isBankAccountRequest) return renderTheButtons[1];
            if (currentStep === 2 && !isBankAccountRequest) return renderTheButtons[2];

            if (currentStep < 3 && isBankAccountRequest) return renderTheButtons[1];
            if (currentStep === 3 && isBankAccountRequest) return renderTheButtons[2];
           
        }
        return [];
    };

    const renderButtons = buttonConfigs
        .filter((_, index) => {
            const configurations = getConfigurations(isNaturalPerson);
            return configurations.includes(index);
        })
        .map((button, index) => (
            <Button key={index} {...button}>
                {button.label}
            </Button>
        ));

    return (
        <Stack direction="row" spacing={2} mt={2} justifyContent="flex-end">
            {renderButtons}
        </Stack>
    );
};

export default ButtonStep;
