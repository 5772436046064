import { useMutation, useQuery } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import { ApiResponseError, useApiRequest } from 'contexts/apiRequestContext';
import { useIdentity } from 'contexts/identityContext';
import { handleErrorUseQuery } from 'helpers';
import {
    getZetraMarginQueryAsync,
    TZetraMarginQueryParams,
    postUpdateZetraMarginQueryAsync,
    TUpdateZetraParams,
    getCheckConsignmentsAsync,
    TCheckConsignmentsParams,
    postReserveMarginAsync,
    postCancelConsignmentAsync,
    TCancelConsignmentParams,
    ZetraMarginQueryUpdateModel,
} from 'services/zetra';

export function useUpdateZetraMarginQuery(
    onSuccess?: (data: ZetraMarginQueryUpdateModel) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (value: TUpdateZetraParams) => {
            startRequest();
            const { data, status, statusText } = await postUpdateZetraMarginQueryAsync(
                value,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateUpdateZetra: mutateAsync, isLoading, error };
}

export function useZetraMarginQuery(params: TZetraMarginQueryParams, enabledRequest = false) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const { personId, registrationNumber } = params;
    const filter = !!personId || !!registrationNumber;

    const queryContext = useQuery({
        enabled: !!token && enabledRequest && filter,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['zetra-margin-query', filter],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getZetraMarginQueryAsync(params, token!);
                endRequest(true);
                return data;
            } catch (err) {
                endRequest(true);

                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        ...queryContext,
        zetraMarginQueryData: queryContext?.data,
        zetraMarginQueryError: queryContext?.error as ApiResponseError,
        zetraMarginQueryisFecth: queryContext.isFetching,
        zetraMarginQueryStts: queryContext.status,
        zetraMarginQueryRefecth: queryContext.refetch,
    };
}

export function useCheckConsignments(params: TCheckConsignmentsParams) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const filter = Object.values(params).filter((v) => !!v);

    const queryContext = useQuery({
        enabled: !!token && !!filter,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['check-consignment', !!filter],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getCheckConsignmentsAsync(params, token!);
                endRequest(true);
                return data;
            } catch (err) {
                endRequest(true);

                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        ...queryContext,
        checkConsigData: queryContext?.data,
        checkConsigError: queryContext?.error as ApiResponseError,
        checkConsigRefecth: queryContext.refetch,
        checkConsigStts: queryContext.status,
    };
}

export function useReserveMargin(
    creditNoteId: string,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async () => {
            startRequest();
            const { data, status, statusText } = await postReserveMarginAsync(creditNoteId, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateReserveMargin: mutateAsync, isLoading, error };
}

export function useCancelConsignment(
    params: TCancelConsignmentParams,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async () => {
            startRequest();
            const { data, status, statusText } = await postCancelConsignmentAsync(params, token!);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateCancel: mutateAsync, isLoading, error };
}
