export const zipCodeMask = [
	/\d/, 
	/\d/, 
	/\d/, 
	/\d/, 
	/\d/, 
	"-", 
	/\d/,
	/\d/, 
	/\d/
];