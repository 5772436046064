import { object, array } from 'yup';

export function selectColumnsSchema() {
    return object().shape({
        optionsSelected: array().nullable(),
    });
}

export const defaultValuesSelectColumnsSchema = {
    optionsSelected: []
};