import { string } from "yup";
import { object } from "yup";

export function validationSchemaApproveInstrument() {
    return object().shape({
        message: string().nullable(),
    });
};

export const defaultValuesApproveInstrument = {
    message: null
};