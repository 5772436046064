import { Grid, Stack } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { Button, DataTable, DeleteIcon, EditIcon, SearchIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { Link } from 'react-router-dom';
import { RelatedPersonCreditNote } from 'services/creditNote/types/creditNoteReadModel';

type SignatoriesTabProps = {
    name: string;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setSelectedInvolvedIndex: (rowIndex: number | undefined) => void;
    onDelete: (rowIndex: number) => void;

}

export const SignatoriesTab = ({ page, setPage, setRowsPerPage, onDelete, setSelectedInvolvedIndex, name }: SignatoriesTabProps) => {
    const { watch } = useFormContext();
    const queryData = watch(name) ?? [];

    const columnsSignatoriies: GridColDef[] = [
        {
            field: 'personIdDisplay',
            headerName: 'Nome',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'typeOfRelationshipDisplay',
            headerName: 'Tipo de relação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false
        },
        {
            field: 'signatureTypeDisplay',
            headerName: 'Forma de Envio',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'signatureValidationDisplay',
            headerName: 'Forma de Validação',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
        },
        {
            field: 'actions',
            headerName: 'Ações',
            headerAlign: 'center',
            align: 'center',
            hideSortIcons: true,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                const rowIndex = queryData.findIndex(
                    (row: RelatedPersonCreditNote) => cellValues.row?.personId === row?.personId
                );

                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent='center'
                        alignItems='center'
                    >
                        <>
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={<EditIcon sx={iconSx} />}
                                onClick={() => setSelectedInvolvedIndex(rowIndex)}
                            />
                            <Button
                                variant="text"
                                color="primary"
                                size="small"
                                startIcon={<DeleteIcon sx={iconSx} />}
                                onClick={() => onDelete(rowIndex)}
                            />
                            <Link to={cellValues.row?.personDiscriminator === 'LegalPerson' ?
                                `/pessoas-juridicas/todas/${cellValues?.row?.personId}` : `/pessoas-fisicas/todas/${cellValues?.row?.personId}`
                            }>
                                <Button
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    startIcon={
                                        <SearchIcon
                                            sx={iconSx}
                                        />}
                                />
                            </Link>
                        </>
                    </Stack>
                );
            },
        },
    ];

    return (
        <Grid>
            <DataTable
                columns={columnsSignatoriies}
                rows={queryData}
                getRowId={(row) => Math.random().toString()}
                page={page}
                rowCount={queryData?.length}
                rowsPerPage={queryData?.length}
                setPage={setPage}
                setRowsPerPage={setRowsPerPage}
                enableJumpAction={false}
            />
        </Grid>
    )
}
