import { Stack, useMediaQuery } from '@mui/material';
import { Button, CloseIcon, SaveIcon } from '@uy3/web-components';
import { FunctionComponent } from 'react';
import { activeTheme } from 'services/theme';
import { useFormContext } from 'contexts/formContext';
import { RefreshProgress } from 'components/RefreshProgress';
import { SelectFormField, SelectOption } from 'components/Forms/FormFields';
import AutocompleteField from 'components/Forms/FormFields/AutocompleteField/AutocompleteField';
import { AutocompleteOptionList } from 'components/Forms/Autocomplete';

type InvolvedFundProps = {
  signatureType: SelectOption[];
  signatureValidation: SelectOption[];
  onClose: () => void;
  personList: AutocompleteOptionList;
  isLoading: boolean;
  name: string;
};

const fundSignerType = [
  { label: 'Cedente', value: 'Assignor' },
  { label: 'Cessionário', value: 'Assignee' },
  { label: 'Convidado', value: 'Guest' },
  { label: 'Agente de cobrança', value: 'BillingAgent' },
  { label: 'Testemunha', value: 'Witness' },
];

const theme = activeTheme();
export const InvolvedFundForm: FunctionComponent<InvolvedFundProps> = ({
  signatureType,
  signatureValidation,
  onClose,
  personList,
  isLoading,
}) => {
  const { submitting } = useFormContext();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack spacing={4}>
      {isLoading && <RefreshProgress refreshing={isLoading} />}
      <AutocompleteField
        name="personId"
        label="Envolvido"
        displayName="personIdDisplay"
        required
        {...personList!}
      />

      <SelectFormField
        name="fundSignerType"
        label="Tipo de relação"
        variant="outlined"
        options={fundSignerType}
        fullWidth
        required
        disabled={isLoading}
      />

      <SelectFormField
        name="signatureType"
        label="Forma de Envio"
        variant="outlined"
        options={signatureType}
        fullWidth
        disabled={isLoading}
      />

      <SelectFormField
        name="signatureValidation"
        label="Forma de Validação"
        variant="outlined"
        options={signatureValidation}
        fullWidth
        disabled={isLoading}
      />

      <Stack
        direction={isMobile ? 'column' : 'row'}
        alignItems="center"
        justifyContent="flex-end"
        spacing={3}
      >
        <Button
          startIcon={<CloseIcon htmlColor="#D04D27" />}
          variant="outlined"
          size="medium"
          onClick={onClose}
          fullWidth={isMobile}
        >
          Fechar
        </Button>
        <Button
          name='btn-add-involved-form'
          type="submit"
          startIcon={<SaveIcon htmlColor="#fff" />}
          variant="contained"
          size="medium"
          disabled={submitting}
          fullWidth={isMobile}
        >
          Adicionar envolvido
        </Button>
      </Stack>
    </Stack>
  );
};
