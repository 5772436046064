import { Drawer } from '@uy3/web-components';
import { FormProvider } from 'contexts/formContext';
import { defaultValuesMarginZetra, validationSchemaUpdateMarginZetra } from './UpdateZetraSchema';
import UpdateZetraForm from './UpdateZetraForm';
import { UseMutateAsyncFunction } from '@tanstack/react-query';
import { TUpdateZetraParams, ZetraMarginQueryUpdateModel } from 'services/zetra';

type UpdateZetraProps = {
    isUpdateZetra: boolean;
    onCloseDrawer: () => void;
    mutateUpdateZetra: UseMutateAsyncFunction<ZetraMarginQueryUpdateModel, unknown, TUpdateZetraParams, unknown>
    registrationNumber: string;
};

export function UpdateZetra({
    isUpdateZetra,
    onCloseDrawer,
    mutateUpdateZetra,
    registrationNumber,
}: UpdateZetraProps) {
    return (
        <Drawer
            anchor="right"
            open={isUpdateZetra}
            title="Atualizar margem"
            toggleDrawer
            onClose={onCloseDrawer}
        >
            <FormProvider
                validationSchema={validationSchemaUpdateMarginZetra}
                defaultValues={defaultValuesMarginZetra(registrationNumber)}
                onSubmit={mutateUpdateZetra}
            >
                <UpdateZetraForm onCloseDrawer={onCloseDrawer} />
            </FormProvider>
        </Drawer>
    );
}
