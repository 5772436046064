import { number, object, string } from 'yup';

export const validationApproveRevisionSchema = () => {
    return object().shape({
        cosifAccountCode: string().typeError('Conta de crédito precisa ser preenchido').required('Conta de crédito: Precisa ser preenchido'),
        liquidationValue: number().typeError('Valor da liquidação: Precisa ser preenchido').required('Valor da liquidação: Precisa ser preenchido'), 
        observations: string().nullable(), 
        creditDate: string().typeError('Data de crédito: precisa ser preenchido').required('Data de crédito: precisa ser preenchido')
    });
};

export const defaultValuesApproveRevision = {
    cosifAccountCode: null,
    liquidationValue: null, 
    observations: null, 
    creditDate: null
};