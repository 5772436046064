import { useState } from 'react';
import { Button, DataTable, Modal, SearchIcon, Typography } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { useParams } from 'react-router';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import DatasetWrapper from '../DatasetWrapper';
import { Grid, Stack } from '@mui/material';
import { useFormContext } from 'contexts/formContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import Toast from 'components/Toast/Toast';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';

interface IPfAntecedenteList {
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function PfAntecedenteList({ datasetId, disableRequestDataSet }: IPfAntecedenteList) {
    const recordType = 'NaturalPerson';
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'PfAntecedente');
    const { setSubmitError } = useApiRequest();
    const [modal, setModal] = useState(false);
    const [rowData, setRowData] = useState<any>();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta da Certidão Antecedentes Criminais ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };
    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };
    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const onChangePage = (page: number) => {
        setPage(page);
    };

    const onChangeRowsPerPage = (page: number) => {
        setRowsPerPage(page);
        setPage(0);
    };

    const columns: GridColDef[] = [
        {
            field: 'protocolNumber',
            headerName: 'Protocolo',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value}
                    </div>
                );
            },
        },
        {
            field: 'idNumber',
            headerName: 'CPF',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value && formatDocumentNumber(cellValues.value)}
                    </div>
                );
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'certificateNumber',
            headerName: 'Número',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'emissionDate',
            headerName: 'Emissão',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'validUntil',
            headerName: 'Valido até',
            hideSortIcons: true,
            minWidth: 150,
            flex: 2,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '-8px',
                        }}
                    >
                        {cellValues.value ?? 'N/D'}
                    </div>
                );
            },
        },
        {
            field: 'certificateText',
            headerName: 'Cetificado',
            hideSortIcons: true,
            minWidth: 150,
            editable: false,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        component={Grid}
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                        style={{
                            textAlign: 'left',
                            marginLeft: '-4px',
                        }}
                    >
                        <Button
                            variant="text"
                            color="primary"
                            size="small"
                            onClick={() => {
                                var rowData = certificateText(cellValues.value);
                                setRowData(rowData);
                                setModal(true);
                            }}
                            startIcon={<SearchIcon sx={{ height: 20, width: 20, ml: 1 }} />}
                        />
                    </Stack>
                );
            },
        },
    ];

    const certificateText = (value: string) => (
        <Typography variant="h5" color="neutral.medium" mt={1} lineHeight={1.5} letterSpacing={0.5}>
            {value}
        </Typography>
    );

    const data = dataset?.find((dataset) => dataset?.dataSet === 'pf_antecedente') as any;
    const result = data?.result.certificates;
    const closeModal = () => setModal(false);

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            <Modal
                title="Informações detalhada"
                open={modal}
                onClose={closeModal}
                sizeModal="large"
                children={<>{rowData}</>}
            />
            {result && (
                <DataTable
                    loading={isLoading || status === 'loading'}
                    getRowId={(row) => row.idNumber}
                    columns={columns}
                    rows={result ?? []}
                    page={page}
                    rowCount={result?.length ?? 5}
                    rowsPerPage={rowsPerPage}
                    setPage={onChangePage}
                    setRowsPerPage={onChangeRowsPerPage}
                    paginationMode="client"
                />
            )}
            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('PfAntecedente')}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};
