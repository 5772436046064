import DatasetWrapper from '../DatasetWrapper';
import KycSanctionsHistoryList from './SanctionsHistoryList';
import KycPepHistoryList from './PepHistoryList';
import { KycDatasetGet } from 'services/datasets/datasets.interface';
import { useDatasetData, useDatasetMutation } from 'contexts/datasetContext';
import { useParams } from 'react-router';
import { ApiResponseError, ToastType, showErrorToast, showSuccessToast, toastState, useApiRequest } from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useState } from 'react';
import Toast from 'components/Toast/Toast';
import { mapErrorResponse } from 'contexts/responseErrorContext';

interface IKycProps {
    recordType: string;
    datasetId?: string | undefined;
    disableRequestDataSet?: boolean;
}

export function KycAndCompliance({ recordType, datasetId, disableRequestDataSet }: IKycProps) {
    const { personId } = useParams();
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();

    const id = datasetId ? datasetId : personId;
    const { dataset, status, refetch } = useDatasetData(recordType, id!, 'KycData');
    const { setSubmitError } = useApiRequest();

    const name = watch('name');
    const successMessage = `Ótimo! Conseguimos realizar a consulta de KYC e Compliance ${name ? ` de ${name}` : ''
        } com sucesso!`;

    const onSuccess = () => {
        refetch();
        const title = successMessage;
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const onError = (error: ApiResponseError) => {
        setSubmitError(error);
        const { errorMessage } = mapErrorResponse(error);
        const title = 'Ops, ocorreu um erro!';
        const description = errorMessage;
        showErrorToast(title, description, setToast);
    };

    const { mutate, isLoading } = useDatasetMutation(recordType, id!, onSuccess, onError);
    const handleUpdateData = async (values: string) => {
        mutate(values);
    };

    const data = dataset?.find((dataset) => dataset?.dataSet === 'kyc_data') as KycDatasetGet;
    const result = data?.result;

    return (
        <>
            <Toast toast={toast} setToast={setToast} />
            {result && (
                <>
                    <KycPepHistoryList result={result} />
                    <KycSanctionsHistoryList result={result} />
                </>
            )}

            <DatasetWrapper
                dataSet={data}
                handleUpdateData={() => handleUpdateData('KycData')}
                isLoading={isLoading || status === 'loading'}
                disableRequestDataSet={disableRequestDataSet}
            />
        </>
    );
};