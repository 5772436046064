import { isEmpty } from 'lodash'
import { activeTheme } from 'services/theme'

type ShowOperationStatusProps = {
    status: string
    statusDisplay: string
}

const theme = activeTheme();
export const ShowOperationStatus = ({ status, statusDisplay }: ShowOperationStatusProps) => {
    const successColor = theme.palette.success.main;
    const warningColor = theme.palette.warning.main;
    const errorColor = theme.palette.error.main;
    const infoColor = theme.palette.info.main;

    const backgroundColorByStatus: { [type: string]: string } = {
        "Registrada": successColor,
        "ProcessadaJd": successColor,
        "ProcessadoUY3": successColor,
        "InformacaoCip": warningColor,
        "AguardandoCip": warningColor,
        "Desconhecida": infoColor,
        "ErroJd": errorColor,
        "ErroCip": errorColor,
        "ErroUy3": errorColor,
    }

    const backgroundColor = backgroundColorByStatus[status!];
    return (
        <div
            style={{
                backgroundColor,
                color: isEmpty(statusDisplay) ? theme.palette.common.black : theme.palette.common.white,
                padding: '8px',
                borderRadius: '4px',
                width: '100%',
                textAlign: 'center'
            }}
        >
            {statusDisplay ?? "N/D"}
        </div>
    )
}
