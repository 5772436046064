import AvatarComponent from "components/Avatar/Avatar";

type RenderAvatarsNamesType = {
    list: ListNamesType[];
    title?: string;
    maxAvatars?: number
}

export type ListNamesType = {
    id: string;
    name: string;
    tenant: string;
}

export const RenderAvatarsName = ({ list, title = "Credores", maxAvatars = 3}: RenderAvatarsNamesType) => {
    const optionsList = list ?? [];

    function extractFirstTwoLetters(listOfNames: string[]): string[] {
        return listOfNames?.map((name) => name?.toUpperCase().slice(0, 2));
    }

    const listNames = optionsList.map((item: ListNamesType) => item.name);
    const abbreviatedNames = extractFirstTwoLetters(listNames);

    return (
        <AvatarComponent
            abbreviatedNames={abbreviatedNames}
            listNames={listNames}
            title={title}
            maxAvatars={maxAvatars}
        />
    );
};