import { array, object, string } from 'yup';

const Email = object().shape({
    email: string().required('Email: precisa ser preenchido').typeError('Email: precisa ser preenchido').email('Email inválido')
});


export function validationSchemaUpdateEmails() {
    return object().shape({
        notificationRecepients: array().of(Email).nullable()
    })
}

export const defaultValuesUpdateEmails = {
    notificationRecepients: []
};