import { Grid, Stack } from '@mui/material';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import { HandleTitle } from 'components/Datasets/DatasetNaturalPerson/TypographyAndValues';
import { RefreshProgress } from 'components/RefreshProgress';
import { Button, Drawer } from '@uy3/web-components';
import {
    ApiResponseError,
    handleOnError,
    showSuccessToast,
    toastState,
    ToastType,
} from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { useFormContext } from 'contexts/formContext';
import { IServidore } from 'services/zetra';
import { useUpdateZetraMarginQuery, useZetraMarginQuery } from 'contexts/zetra';
import { ZetraMarginQueryList } from './ZetraMarginQueryList';
import { ZetraMarginQueryDetails } from './ZetraMarginQueryDetails';
import { useParams } from 'react-router';

export const ZetraMarginQuery = () => {
    let { personId } = useParams();
    const [rowData, setRowData] = useState<IServidore | null>(null);
    const [toast, setToast] = useState<ToastType>(toastState);
    const { watch } = useFormContext();
    const [registrationNumber, personRegistrationNumber] = watch([
        'registrationNumber',
        'personRegistrationNumber',
    ]);

    const cpf = personRegistrationNumber ?? registrationNumber;

    const { zetraMarginQueryData, zetraMarginQueryRefecth, isFetching } = useZetraMarginQuery(
        { registrationNumber: cpf },
        !!cpf
    );

    const onSuccess = () => {
        zetraMarginQueryRefecth();
        const title = 'Consulta de margem realizada com sucesso!';
        const description = undefined;
        showSuccessToast(title, description, setToast);
    };

    const { mutateUpdateZetra, isLoading } = useUpdateZetraMarginQuery(
        onSuccess,
        (err: ApiResponseError) => handleOnError(err, setToast)
    );

    const hasData =
        zetraMarginQueryData?.length !== 0 &&
        !isEmpty(zetraMarginQueryData) &&
        zetraMarginQueryData !== undefined;

    const zetraMarginData = hasData ? zetraMarginQueryData?.[0] : null;
    const result = zetraMarginData?.result;
    const queryDataToList = result?.servidores ?? [];

    return (
        <Stack spacing={2} mt={-4}>
            <Toast toast={toast} setToast={setToast} />
            <RefreshProgress refreshing={isFetching || isLoading} />

            {hasData && (
                <Stack>
                    {result !== null && (
                        <Grid mb={3}>
                            {!isEmpty(result?.codRetorno) && (
                                <HandleTitle
                                    title="Código de retorno"
                                    value={result?.codRetorno}
                                    key="cdRetCode"
                                />
                            )}
                            {!isEmpty(result?.mensagem) && (
                                <HandleTitle
                                    title="Mensagem de retorno"
                                    value={result?.mensagem}
                                    key="dsRetCode"
                                />
                            )}
                        </Grid>
                    )}

                    {queryDataToList?.length > 0 && (
                        <ZetraMarginQueryList
                            queryData={queryDataToList}
                            handleShowDetail={setRowData}
                        />
                    )}
                </Stack>
            )}

            <Grid item xs={6}>
                <Button
                    onClick={() => mutateUpdateZetra({ personId })}
                    variant="contained"
                    disabled={isLoading}
                    size="medium"
                >
                    Consultar margem
                </Button>
            </Grid>

            <Drawer
                anchor="right"
                open={rowData !== null}
                title={rowData?.orgao ?? 'Detalhes'}
                description="Confira mais informações sobre a margem"
                onClose={() => setRowData(null)}
            >
                <ZetraMarginQueryDetails data={rowData!} onClose={() => setRowData(null)} />
            </Drawer>
        </Stack>
    );
};
