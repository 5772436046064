import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const PixIcon: FunctionComponent<SvgIconProps> = (props) => {
  const { htmlColor = '#373737' } = props;

  return (
    <SvgIcon {...props}>
      <path
        d="M7.03532 19.1321C7.68279 18.9113 8.27643 18.5435 8.77245 18.0475L12.1513 14.6686L15.5168 18.0353L15.5169 18.0355C15.9612 18.4797 16.4829 18.82 17.0513 19.0442L13.8702 22.2253C12.8384 23.2571 11.1622 23.2577 10.1285 22.2253C10.1283 22.2251 10.1282 22.225 10.1281 22.2249L7.03532 19.1321ZM14.0797 12.2712C13.9799 12.1716 13.8734 12.0815 13.7616 12.0009C13.8731 11.9193 13.9798 11.8282 14.0805 11.7275L17.6791 8.12878C17.9512 7.8567 18.3327 7.69901 18.7174 7.69901H19.7951L22.2249 10.1288C23.2586 11.1624 23.2581 12.8384 22.2253 13.8702L22.2249 13.8706L19.7938 16.3017H18.7174C18.3342 16.3017 17.9521 16.1437 17.6787 15.8715C17.6785 15.8713 17.6783 15.8711 17.6781 15.8709L14.0806 12.272L14.0797 12.2712ZM10.2214 11.7266C10.3222 11.8278 10.4291 11.9192 10.5409 12.0011C10.4291 12.0816 10.3227 12.1716 10.223 12.2712L10.2222 12.2719L6.6089 15.8852C6.33746 16.1567 5.95569 16.315 5.57201 16.315H4.21955L1.77511 13.8706L1.77478 13.8702C0.741924 12.8384 0.741448 11.1624 1.77511 10.1288L4.21822 7.68568H5.57201C5.9557 7.68568 6.33788 7.84404 6.60963 8.11485C6.60973 8.11495 6.60983 8.11504 6.60993 8.11514L10.2208 11.7261C10.221 11.7262 10.2212 11.7264 10.2214 11.7266ZM8.77245 5.9519C8.27614 5.45559 7.6828 5.08833 7.03551 4.86795L10.1281 1.77444C10.1283 1.77429 10.1284 1.77415 10.1286 1.774C11.1623 0.741671 12.8384 0.742291 13.8702 1.77411L17.0513 4.95513C16.4829 5.17936 15.9612 5.51965 15.5169 5.9639L15.5168 5.96403L12.1507 9.33138L8.77258 5.95203L8.77245 5.9519Z"
        stroke={htmlColor}
        strokeWidth="2"
        fill="none"
      />
    </SvgIcon>
  );
};

PixIcon.defaultProps = {
  viewBox: '0 0 24 24',
  width: '24',
  height: '24',
  fill: 'none',
};
