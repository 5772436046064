/* eslint-disable no-eval */
/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import {
    ApiResponseError,
    invalidateAndRefetch,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import { handleErrorUseQuery } from 'helpers';
import { TLiquidationScheduleType } from 'pages/CreditNote/schema';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
    createLiquidationScheduleOptions,
    LiquidationScheduleRequestModel,
    TliquidationScheduleOpt,
} from 'services/LiquidationSchedule';
import {
    approveLiquidationSchedule,
    deleteLiquidationScheduleAsync,
    editOrCreateLiquidationSchedule,
    getBankslipAsync,
    getLiquidationScheduleCreditNote,
    getPixkeyAsync,
    getPixQrCodeAsync,
    postApproveRevisionAsync,
    postClosureAsync,
    postClosureRevisionAsync,
} from 'services/LiquidationSchedule/LiquidationSchedule';
import { ApprovalLiquidationScheduleCreateModel } from 'services/LiquidationSchedule/types/ApprovalLiquidationScheduleRequest';
import {
    LiquidationScheduleClosureModel,
    LiquidationScheduleFilters,
    TLiquidationValidation,
} from 'services/LiquidationSchedule/types/genericsType';
import { LiquidationScheduleReadModel } from 'services/LiquidationSchedule/types/LiquidationScheduleReadModel';
import { BankAccountCreateOrUpdateModel } from 'utils';

export function useLiquidationScheduleMutation(
    id: string,
    liquidationId: string | undefined,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: LiquidationScheduleRequestModel) => {
            startRequest();
            const { data, status, statusText } = await editOrCreateLiquidationSchedule(
                values,
                id,
                token,
                liquidationId
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateAsync, isLoading, error };
}

export function useLiquidationScheduleList(
    creditNoteId: string,
    filters: LiquidationScheduleFilters
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();
    const [liquidationScheduleList, setLiquidationScheduleList] = useState<TliquidationScheduleOpt[]>([]);

    const isNewCreditNote = creditNoteId === 'nova';
    const queryContext = useQuery({
        enabled: !!token && !isNewCreditNote,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        queryKey: ['liquidation-Schedule-creditNote', creditNoteId],
        queryFn: async () => {
            startRequest();
            const { data, status, statusText } = await getLiquidationScheduleCreditNote(
                filters,
                creditNoteId,
                token!
            );
            endRequest(true);

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            const options = createLiquidationScheduleOptions(data?.data ?? []);
            setLiquidationScheduleList(options);

            return data;
        },
    });

    return {
        ...queryContext,
        liquidationScheduleList,
        data: queryContext.data?.data ?? [],
    };
}

export function useApproveLiquidationSchedule(
    creditNoteId: string,
    onSuccess: (data: unknown) => void,
    onError: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: ApprovalLiquidationScheduleCreateModel) => {
            startRequest();
            const { data, status, statusText } = await approveLiquidationSchedule(
                values,
                creditNoteId,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateAsync, isLoading, error };
}

export function useKeyPix(pixKey: string | undefined) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token && !!pixKey,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['schedule-key-pix', !!pixKey],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getPixkeyAsync(pixKey!, token!);
                endRequest(true);
                return data;
            } catch (err) {
                endRequest(true);
                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        ...queryContext,
        keyPixData: queryContext.data,
        keyPixError: queryContext.error as ApiResponseError,
        keyPixFecth: queryContext.isFetching,
        keyPixStts: queryContext.status,
        keyPixIsError: queryContext?.isError
    };
}

export function useBankslip(barCode: string | undefined) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token && !!barCode,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['schedule-bank-slip', !!barCode],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getBankslipAsync(barCode!, token!);
                endRequest(true);
                return data;
            } catch (err) {
                endRequest(true);

                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        ...queryContext,
        bankslipData: queryContext.data,
        bankslipError: queryContext.error as ApiResponseError,
        bankslipFecth: queryContext.isFetching,
        bankslipStts: queryContext.status,
        bankslipIsError: queryContext?.isError,
    };
}

export function usePixQrCode(pixQrCode: string | undefined) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const queryContext = useQuery({
        enabled: !!token && !!pixQrCode,
        refetchIntervalInBackground: false,
        refetchOnMount: 'always',
        refetchOnWindowFocus: false,
        retry: false,
        queryKey: ['schedule-pix-qrcode', !!pixQrCode],
        queryFn: async () => {
            startRequest();
            try {
                const { data } = await getPixQrCodeAsync(pixQrCode!, token!);
                endRequest(true);
                return data;
            } catch (err) {
                endRequest(true);

                if (isAxiosError(err)) {
                    setSubmitError({
                        type: 'error',
                        code: err.response?.status + '' + err.response?.statusText,
                        message: err.response?.data?.message,
                        errors: err.response?.data?.message,
                    });
                    throw err;
                }

                throw err;
            }
        },
    });

    return {
        ...queryContext,
        pixQrCodeData: queryContext?.data,
        pixQrCodeError: queryContext?.error as ApiResponseError,
        pixQrCodeFecth: queryContext.isFetching,
        pixQrCodeStts: queryContext.status,
        pixQrCodeIsError: queryContext?.isError,
    };
}

export function useApproveRevisionLiquidation(
    creditNoteId: string,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: LiquidationScheduleReadModel[]) => {
            startRequest();
            const { data, status, statusText } = await postApproveRevisionAsync(
                values,
                creditNoteId,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateApproveRevision: mutateAsync, isLoading, error };
}

export function useLiquidationScheduleClosure(
    creditNoteId: string,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: LiquidationScheduleClosureModel) => {
            startRequest();
            const { data, status, statusText } = await postClosureAsync(
                creditNoteId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateClosure: mutateAsync, isLoading, error };
}

export function useLiquidationScheduleClosureRevision(
    creditNoteId: string,
    onSuccess?: (data: unknown) => void,
    onError?: (error: ApiResponseError) => void
) {
    const { token } = useIdentity();
    const { startRequest, endRequest, setSubmitError } = useApiRequest();

    const { mutateAsync, isLoading, error } = useMutation({
        mutationFn: async (values: BankAccountCreateOrUpdateModel) => {
            startRequest();
            const { data, status, statusText } = await postClosureRevisionAsync(
                creditNoteId,
                values,
                token!
            );

            if (isAxiosError(data)) {
                setSubmitError({
                    type: 'error',
                    code: status + '' + statusText,
                    message: data.message,
                    errors: data.response?.data?.errors,
                });
                throw data;
            }

            if (status >= 400 && status <= 599) {
                throw data;
            }

            endRequest(true);
            return data;
        },
        onSuccess,
        onError(error, _) {
            handleErrorUseQuery(
                error,
                setSubmitError,
                endRequest,
                () => onError && onError(error as ApiResponseError)
            );
        },
    });

    return { mutateClosureRevision: mutateAsync, isLoading, error };
}

// #region Regra de negócio - Roteiro
export const useHandleLiquidationForm = ({
    queryKey,
    setQueryKey,
    setToast,
    onDrawerClose,
    liquidationSchedule,
    selectedLiquidationIndex,
    creditNoteId,
    liquidationId,
}: TLiquidationValidation) => {
    const { setValue, watch } = useFormContext();
    const { keyPixData, keyPixError, keyPixIsError } = useKeyPix(queryKey?.pixKey);
    const { bankslipData, bankslipError, bankslipIsError } = useBankslip(queryKey?.barCode);
    const { pixQrCodeData, pixQrCodeError, pixQrCodeIsError } = usePixQrCode(queryKey?.pixQrCode);
    const queryClient = useQueryClient();
    const [currentFormValues, setCurrentFormValues] = useState<TLiquidationScheduleType | undefined>();
    const updatedSchedule = [...liquidationSchedule];
    const incomingFormValues = queryKey?.formValue as TLiquidationScheduleType;
    const watchStatus = watch('status');
    const { token } = useIdentity();

    const onDeleteLiquidation = async (id: string) => {
        try {
            await deleteLiquidationScheduleAsync(creditNoteId!, id, token!);
            await invalidateAndRefetch(queryClient, ['liquidation-Schedule-creditNote', creditNoteId!]);
            showSuccessToast('Roteiro de liquidação deletado com sucesso', '', setToast);
        } catch (error) {
            const { errorMessage } = mapErrorResponse(error as ApiResponseError);
            const errorTitle = 'Não foi possível deletar o roteiro de liquidação.';
            showErrorToast(errorTitle, errorMessage, setToast);
        }
    };

    const handleMutationSuccess = async () => {
        const successTitle = 'Roteiro de liquidação registrado com sucesso';
        const successDescription = 'Ótimo! Confira o roteiro de liquidação abaixo.';
        showSuccessToast(successTitle, successDescription, setToast);
        onDrawerClose();

        await invalidateAndRefetch(queryClient, ['liquidation-Schedule-creditNote', creditNoteId!]);
    };

    const handleMutationError = (response: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(response);
        const errorTitle = 'Não foi possível processar a solicitação.';
        showErrorToast(errorTitle, errorMessage, setToast);
    };

    const { mutateAsync } = useLiquidationScheduleMutation(
        creditNoteId!,
        liquidationId,
        handleMutationSuccess,
        handleMutationError
    );

    const hasErrors = useMemo(
        () => keyPixIsError || bankslipIsError || pixQrCodeIsError,
        [bankslipIsError, pixQrCodeIsError, keyPixIsError]
    );

    const isValidData = useMemo(
        () =>
            (!!keyPixData && !hasErrors) ||
            (!!bankslipData && !hasErrors) ||
            (!!pixQrCodeData && !hasErrors),
        [keyPixData, bankslipData, pixQrCodeData, hasErrors]
    );

    const isDraft = watchStatus === 'Draft';
    const addOrUpdate = useCallback(
        async (formValues: TLiquidationScheduleType) => {
            setCurrentFormValues(formValues);
            if (!isDraft) {
                selectedLiquidationIndex === undefined
                    ? updatedSchedule.push(formValues)
                    : (updatedSchedule[selectedLiquidationIndex] = formValues);

                setValue('liquidationSchedule', updatedSchedule);
                handleMutationSuccess();
            } else if (isDraft) {
                await mutateAsync(formValues);
            }
        },
        [incomingFormValues, currentFormValues, selectedLiquidationIndex]
    );

    const rollback = () => {
        handleMutationError(keyPixError || bankslipError || pixQrCodeError);
        setQueryKey(undefined);
    };

    useEffect(() => {
        if (isValidData) {
            addOrUpdate(incomingFormValues);
            setQueryKey(undefined);
        } else if (hasErrors) {
            rollback();
        }
    }, [isValidData, hasErrors]);

    return {
        keyPixData,
        bankslipData,
        pixQrCodeData,
        handleFormSubmit: addOrUpdate,
        onDeleteLiquidation
    };
};
