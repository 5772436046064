import { Stack } from '@mui/material';
import { Button, CloseIcon, Drawer, Typography, UploadIcon } from '@uy3/web-components';
import UploadDropzone from 'components/Tabs/Uploads/UploadForm/UploadDrop';
import { iconSx, useApiRequest } from 'contexts/apiRequestContext';
import React from 'react';
import { activeTheme } from 'services/theme';

interface ImportSpreadsheetDrawerProps {
    openDrawer: boolean;
    onClose: () => void;
}

const theme = activeTheme();
const ImportSpreadsheetDrawer: React.FC<ImportSpreadsheetDrawerProps> = ({
    openDrawer,
    onClose,
}) => {
    const { submitting } = useApiRequest();

    return (
        <Drawer
            open={openDrawer}
            onClose={onClose}
            anchor="right"
            title="Importar planilha"
            description="Confira as informações antes de importar uma nova planilha"
        >
            <>
                <Typography variant="lg" sx={{ fontWeight: 'bold' }}>
                    Enviar planilha de de plano de pagamentos
                </Typography>

                <Stack spacing={4}>
                    <UploadDropzone accept=".xlsx" />

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        spacing={3}
                    >
                        <Button
                            startIcon={
                                <CloseIcon htmlColor={theme.palette.primary.main} sx={iconSx} />
                            }
                            variant="outlined"
                            size="medium"
                            onClick={onClose}
                        >
                            Fechar
                        </Button>
                        <Button
                            name="btn-pay-darf-simples"
                            type="submit"
                            variant="contained"
                            disabled={submitting}
                            startIcon={
                                <UploadIcon htmlColor={theme.palette.common.white} sx={iconSx} />
                            }
                        >
                            Importar
                        </Button>
                    </Stack>
                </Stack>
            </>
        </Drawer>
    );
};

export default ImportSpreadsheetDrawer;
