import React, { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { FormProvider } from 'contexts/formContext';
import {
    ApiResponseError,
    ToastType,
    invalidateAndRefetch,
    showErrorToast,
    showSuccessToast,
    useApiRequest,
} from 'contexts/apiRequestContext';
import { useNaturalPersonMutation } from 'contexts/naturalPersonContext';
import { mapErrorResponse } from 'contexts/responseErrorContext';
import InformationNaturalPersonContainer from './InformationNaturalPerson/InformationContainer';
import AddressForm from '../../../components/DrawerAddPerson/Address/Address';
import SendDocContainer from 'components/DrawerAddPerson/SendDoc/SendDocContainer';
import BankInformationForm from '../../../components/DrawerAddPerson/BankInformation/BankInformationForm';
import ButtonStep from 'components/DrawerAddPerson/ButtonSteps';
import {
    NaturalPersonSchema,
    defaultValuesNaturalPerson,
} from '../NaturalPersonForm/NaturalPersonSchema';
import {
    bankDetailsListValidationSchema,
    defaultValuesBankDetails,
} from 'pages/BankAccount/BankDetailsTab/BankDetailsSchema';
import {
    defaultValuesDocForm,
    validationSchemaDocForm,
} from 'components/Tabs/Uploads/UploadForm/UploadSchema';
import { validationSchemaDrawerAddPersonForm } from './DrawerAddNaturalPersonSchema';
import { usePersonsBankAccountMutation } from 'contexts/Persons/bankAccountContext';

interface DrawerFormProps {
    currentStep: number;
    setNextStep: (nextStep: boolean) => void;
    isAddBankAccount: boolean;
    handleClose: () => void;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    setToast: React.Dispatch<React.SetStateAction<ToastType>>;
    naturalPersonId: string;
    displayName: string;
    setValue: UseFormSetValue<any>;
    onSuccessGeneric?: (values: FieldValues) => void;
    isBankAccountRequest: boolean;
}

const DrawerAddNaturalPersonForm: React.FC<DrawerFormProps> = ({
    currentStep,
    setNextStep,
    isAddBankAccount,
    handleClose,
    handlePrevStep,
    handleNextStep,
    setToast,
    naturalPersonId,
    displayName,
    setValue,
    onSuccessGeneric,
    isBankAccountRequest
}) => {
    const [formValues, setFormValues] = useState<FieldValues | null>(null);
    const { submitError, setSubmitError } = useApiRequest();
    const queryClient = useQueryClient();

    const refetchQueries = async () => {
        await invalidateAndRefetch(queryClient, ['related-bank-account']);
        await invalidateAndRefetch(queryClient, ['persons-list']);
    }

    const onSuccess = async (id: string) => {
        if (!isAddBankAccount) {
            setValue('personId', id);
            setValue('personDisplay', formValues?.name);
            setValue('personDiscriminator', 'NaturalPerson');
        }

        showSuccessToast('Ação realizada com sucesso!', 'Ótimo! Agora você pode seguir com o cadastro da operação.', setToast);
        onSuccessGeneric && onSuccessGeneric({ ...formValues!, id });
        refetchQueries();
        handleClose();
    };

    const onError = async (error: ApiResponseError) => {
        const { errorMessage } = mapErrorResponse(error);
        setSubmitError(error);
        showErrorToast('Ops, ocorreu um erro!', errorMessage, setToast);
    };

    const formSteps: { [key: number]: JSX.Element } = {
        1: <InformationNaturalPersonContainer />,
        2: <AddressForm />,
        3: <SendDocContainer />,
    };

    // validation schema and default values
    const getSchemaAndDefaults = () => {
        if (isAddBankAccount) {
            return {
                schema: bankDetailsListValidationSchema(),
                defaultValues: defaultValuesBankDetails,
            };
        }
        if (currentStep === 3 && isBankAccountRequest) {
            return { schema: validationSchemaDocForm(), defaultValues: defaultValuesDocForm };
        }
        return {
            schema: validationSchemaDrawerAddPersonForm(currentStep),
            defaultValues: defaultValuesNaturalPerson,
        };
    };

    const { schema, defaultValues } = getSchemaAndDefaults();

    const handleFormSave = (data: NaturalPersonSchema) => {
        if (currentStep === 3 && isBankAccountRequest) {
            handleUploadSubmission(data);
        } else if (shouldProgressStep(currentStep, isBankAccountRequest)) {
            handleStepProgress(data);
        } else if (data?.operationTypeValue) {
            handleBankAccountSubmission(data);
        } else {
            //@ts-ignore
            const values: NaturalPersonSchema = { ...formValues, ...data };
            onSubmit(values);
        }
    };

    const shouldProgressStep = (step: number, isBankRequest: boolean) => {
        return (step > 0 && step < 3 && isBankRequest) || (step > 0 && step < 2 && !isBankRequest);
    };

    const handleStepProgress = (data: NaturalPersonSchema) => {
        setFormValues((prev) => ({ ...prev, ...data }));
        setNextStep(true);
        handleNextStep();
    };

    const { mutateAsync } = usePersonsBankAccountMutation({
        recordType: 'NaturalPerson',
        onSuccess,
        onError,
        personId: naturalPersonId,
    });

    const handleBankAccountSubmission = async (data: FieldValues) => mutateAsync(data);

    const handleUploadSubmission = (data: NaturalPersonSchema) => {
        const { id, fileType, fileName, displayName, tempGetUrl, fileTypeDisplay } = data;
        const uploads = [{ id, fileType, fileName, displayName, tempGetUrl, fileTypeDisplay }];
        const uploadsValues: any = { ...formValues, uploads };
        onSubmit(uploadsValues);
    };

    const { mutate } = useNaturalPersonMutation(naturalPersonId, onSuccess, onError);

    const onSubmit = async (values: NaturalPersonSchema) => {
        await mutate(values);
        refetchQueries();
        handleClose();
    };

    return (
        <FormProvider
            validationSchema={schema}
            defaultValues={defaultValues}
            onSubmit={handleFormSave}
            onError={setSubmitError}
        >
            <>
                {isAddBankAccount ? (
                    <BankInformationForm onClose={handleClose} personDisplay={displayName} />
                ) : (
                    formSteps[currentStep]
                )}
                {!isAddBankAccount && (
                    <ButtonStep
                        currentStep={currentStep}
                        onNextStep={handleNextStep}
                        onPrevStep={handlePrevStep}
                        onClose={handleClose}
                        isNaturalPerson
                        formError={submitError}
                        isBankAccountRequest={isBankAccountRequest}
                    />
                )}
            </>
        </FormProvider>
    );
};

export default DrawerAddNaturalPersonForm;
