import { Grid, Stack } from '@mui/material'
import { Button, Typography, UploadIcon } from '@uy3/web-components';
import { iconSx } from 'contexts/apiRequestContext';
import React from 'react'
import { activeTheme } from 'services/theme';

type UploadWithRelatedHeaderType = {
  addNewDocuemnt: () => void;
  disabled: boolean;
};

const theme = activeTheme();

export const UploadHeader: React.FC<UploadWithRelatedHeaderType> = ({ addNewDocuemnt, disabled }) => {
  const color = !disabled ? theme.palette.common.black : theme.palette?.grey['400'];
  return (
    <Stack direction='row' alignItems='center' justifyContent='space-between' mb={2}>
      <Grid>
        <Typography
          variant="h4"
          color="neutral.dark"
          fontStyle="normal"
          fontWeight="700"
          fontSize="28px"
          lineHeight="38.4px"
        >
          Documentos
        </Typography>
        <Typography variant="md" fontStyle="normal" fontSize="16px">
          Todos os documentos relacionados a solicitação de conta
        </Typography>
      </Grid>

      <Button
        onClick={addNewDocuemnt}
        sx={{ color }}
        disabled={disabled}
        startIcon={<UploadIcon sx={iconSx} htmlColor={color} />}
      >
        Novo documento adicional
      </Button>
    </Stack>
  )
};