import { Error } from 'components/Errors/Error';
import { ApproveIcon, DataTable, RejectIcon, ReportIcon, SearchIcon } from '@uy3/web-components';
import { GridColDef } from '@mui/x-data-grid';
import { BankAccountFiltersContainer } from 'components/DataTableFilters/BankAccountFilters/BankAccountFiltersContainer';
import { formatDocumentNumber } from 'helpers/formats/DocumentNumber';
import { RowActions } from 'components/RowActions/RowActions';
import { isEmpty } from 'lodash';
import HighlightBox from 'components/HighlightBox';
import { activeTheme } from 'services/theme';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { iconSx } from 'contexts/apiRequestContext';

type BankAccountOperatorProps = {
    queryData: any;
    rowsPerPage: number;
    setRejectOrApprove: (method: 'approve' | 'reject') => void;
    setRowData: React.Dispatch<
        React.SetStateAction<{
            type: string;
            data: any;
        } | null>
    >;
    setRowsPerPage: (rowsPerPage: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    rowsSelected: string[];
    onRowModesModelChange: (selectionModel: string[]) => void;
    isIncludeSubIdInApprovals: (approvals: any[]) => boolean
};

const theme = activeTheme();
const palette = theme.palette;

const BankAccountOperator = ({
    queryData,
    setRejectOrApprove,
    rowsPerPage,
    setRowData,
    setRowsPerPage,
    setPage,
    page,
    rowsSelected,
    onRowModesModelChange,
    isIncludeSubIdInApprovals
}: BankAccountOperatorProps) => {
    if (queryData?.type === 'error') return <Error error={queryData} />;

    const columnsBankAccountOperator: GridColDef[] = [
        {
            field: 'tenantDisplay',
            headerName: 'Correspondente',
            hideSortIcons: true,
            minWidth: 50,
            flex: 1,
            align: 'left',
            editable: false,
            renderCell: ({ row }) => row?.tenantDisplay ?? 'N/D',
        },
        {
            field: 'owner_NameAccount',
            headerName: 'Nome (Titular)',
            hideSortIcons: true,
            minWidth: 50,
            flex: 1,
            align: 'left',
            editable: false,
            renderCell: ({ row }) => row?.ownerNameAccount ?? 'N/D',
        },
        {
            field: 'registrationNumber',
            headerName: 'CPF/CNPJ (Titular)',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            align: 'left',
            editable: false,
            renderCell: ({ value }) => (!isEmpty(value) ? formatDocumentNumber(value) : 'N/D'),
        },
        {
            field: 'accountNumber',
            headerName: 'Número da conta (Titular)',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            align: 'left',
            editable: false,
            renderCell: ({ value }) => (!isEmpty(value) ? value : 'N/D'),
        },
        {
            field: 'ownerAccountType',
            headerName: 'Tipo de conta (Titular)',
            hideSortIcons: true,
            minWidth: 150,
            flex: 1,
            align: 'left',
            editable: false,
            renderCell: ({ value }) => (!isEmpty(value) ? value : 'N/D'),
        },
        {
            field: 'user.userIdDisplay',
            headerName: 'Nome (Operador)',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: (cellValue: any) => cellValue?.row?.user?.userIdDisplay ?? 'N/D',
        },
        {
            field: 'user.registrationNumber',
            headerName: 'CPF (Operador)',
            hideSortIcons: true,
            minWidth: 200,
            flex: 1,
            editable: false,
            align: 'left',
            renderCell: ({row}) => {
                const registrationNumberUser = row?.user?.registrationNumber; 
                
                return !isEmpty(registrationNumberUser) ? formatDocumentNumber(registrationNumberUser) : "N/D";
            },
        },
        {
            field: 'statusDisplay',
            headerName: 'Status',
            hideSortIcons: true,
            flex: 1,
            minWidth: 200,
            align: 'left',
            editable: false,
        },
        {
            field: 'levelDisplay',
            headerName: 'Nível',
            hideSortIcons: true,
            align: 'left',
            minWidth: 200,
            flex: 1,
            editable: false,
            renderCell: ({ row }) => {
                const levelDisplay = row?.levelDisplay;
                const backgroundColor: { [key: string]: string } = {
                    'Aprovador em Conjunto': palette.warning.light,
                    'Aprovador Master': palette.error.main,
                };
                const background = backgroundColor[levelDisplay];
                return (
                    <HighlightBox
                        background={background}
                        textDisplay={levelDisplay}
                        width={'170px'}
                    />
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            minWidth: 80,
            align: 'left',
            editable: false,
            renderCell: (cellValues: any) => {
                const action = (type: 'approve' | 'reject') => {
                    setRowData({ type, data: cellValues });
                    setRejectOrApprove(type!);
                };
                const isDisableActions = isIncludeSubIdInApprovals(cellValues?.row?.approvals);
                return (
                    <Stack direction='row' alignItems='center'>

                        <Link to={`/contas-digitais/solicitacoes/${cellValues?.row?.bankAccountRequestId}`}>
                            <SearchIcon sx={iconSx} />
                        </Link>
                        <RowActions
                            listButtons={[
                                {
                                    action: () => {
                                        setRowData({ data: cellValues, type: 'details' });
                                    },
                                    disabled: false,
                                    icon: <ReportIcon />,
                                    label: 'Detalhes',
                                },
                                {
                                    action: () => action('approve'),
                                    disabled: rowsSelected.length > 0 || isDisableActions,
                                    icon: <ApproveIcon />,
                                    label: 'Aprovar',
                                },
                                {
                                    action: () => action('reject'),
                                    disabled: rowsSelected.length > 0 || isDisableActions,
                                    icon: <RejectIcon />,
                                    label: 'Reprovar',
                                },
                            ]}
                        />
                    </Stack>
                );
            },
        },
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <BankAccountFiltersContainer typeFilter="pendingOperators" />,
            }}
            getRowId={(row) => row?.id}
            columns={columnsBankAccountOperator}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData.page ?? 0 : page}
            pageCount={queryData?.totalPages ?? 0}
            rowCount={queryData?.totalItems ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
            checkboxSelection
            rowSelectionModel={rowsSelected}
            onSelectionModelChange={(item) => onRowModesModelChange(item as string[])}
        />
    );
};

export default BankAccountOperator;
