/* eslint-disable react-hooks/exhaustive-deps */
import { GridColDef } from '@mui/x-data-grid';
import { createContext, useContext, useEffect, useState } from 'react';

type SelectColumnsContextProps = {
    selectedColumns: string[],
    columns: GridColDef[];
    defaultValues: string[];
    setColumns: (columns: any[]) => void;
    setSelectedColumns: (columns: string[]) => void;
    setDefaultValues: (columns: any[]) => void;
    setLocalConfigKey: (key: string | undefined) => void;
    localConfigKey: string | undefined;
};

const SelectColumnsContext = createContext<SelectColumnsContextProps>({
    selectedColumns: [],
    columns: [],
    defaultValues: [],
    setDefaultValues: () => { },
    setColumns: () => { },
    setSelectedColumns: () => { },
    setLocalConfigKey: () => { },
    localConfigKey: undefined
});

interface SelectColumnsProviderProps {
    children: JSX.Element | JSX.Element[];
};

export const SelectColumnsProvider = ({ children }: SelectColumnsProviderProps) => {
    const [localConfigKey, setLocalConfigKey] = useState<string | undefined>(undefined);
    const [selectedColumns, setSelectedColumns] = useState<any[]>([]);
    const [defaultValues, setDefaultValues] = useState<any[]>([]);
    const [columns, setColumns] = useState<any[]>([]);

    useEffect(() => {
        const storedColumns = localStorage.getItem(localConfigKey!);

        if (storedColumns) {
            setSelectedColumns(JSON.parse(storedColumns));
        } else if (defaultValues.length) {
            setSelectedColumns(defaultValues);
        } else {
            setSelectedColumns([]);
        }
    }, [localConfigKey, defaultValues]);

    return (
        <SelectColumnsContext.Provider
            value={{
                selectedColumns,
                setSelectedColumns,
                setLocalConfigKey,
                localConfigKey,
                defaultValues,
                setDefaultValues,
                columns,
                setColumns
            }}
        >
            {children}
        </SelectColumnsContext.Provider>
    );
};

export function useSelectColumns(): SelectColumnsContextProps {
    const context = useContext(SelectColumnsContext);
    const {
        selectedColumns,
        setSelectedColumns,
        setLocalConfigKey,
        localConfigKey,
        columns,
        setColumns,
        setDefaultValues,
        defaultValues
    } = context;

    return {
        selectedColumns,
        setSelectedColumns,
        setLocalConfigKey,
        localConfigKey,
        columns,
        setColumns,
        defaultValues,
        setDefaultValues
    };
}
