import { Paper, Button } from '@mui/material';
import { Alert } from '@uy3/web-components';
import { baseStyle } from 'components/Tabs/Uploads/UploadForm/UploadDrop';

//Note: This component is not used in the project, the correct option would be to use UploadDrop.tsx
const SendBillingStatement = () => (
    <>
        <Alert
            text="Pode ser um documento de faturamento, imposto de renda, declaração do contador, ou outro documento comprobatório."
            severity="info"
            variant="filled"
            sx={{ mb: 2 }}
        />
        <Paper sx={baseStyle}>
            <Button variant="text" fullWidth component="label" sx={{ color: '#000000' }} size="large">
                Clique aqui para fazer o carregamento dos arquivos. Max 5 MB
                <input
                    hidden
                    type="file"
                    accept="image/*,.pdf"
                    onChange={(e) => {
                        console.log(e.target.files);
                    }}
                />
            </Button>
        </Paper>
    </>
);

export default SendBillingStatement;