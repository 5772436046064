import { array, object, string } from 'yup';

export function validationSchemaBlackList() {
    return object().shape({
        complianceChecklist: array()
            .typeError('Selecione um motivo')
            .min(1, 'Selecione ao menos 1 motivo')
            .required('Selecione um motivo'),
        ispb: string().when('complianceChecklist', {
            is: (val: string) => val.includes('ispb'),
            then: string().required('Selecione um ISPB'),
            otherwise: string().notRequired(),
        }),
    });
}

export const defaultValuesBlackList = {
    complianceChecklist: [],
};
