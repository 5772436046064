import { FunctionComponent } from 'react';
import { Stack, useMediaQuery, useTheme, InputAdornment } from '@mui/material';
import { StyledSearchField } from './DataTable.styled';
import { Typography } from '../../atoms';
import { SearchIcon } from '../../icons';

export interface HeaderBarWrapperProps {
  header?: string | undefined;
  showSearch?: boolean;
  onChangeSearch?: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

const HeaderBarWrapper: FunctionComponent<HeaderBarWrapperProps> = (props) => {
  const { header = '', onChangeSearch, showSearch } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Stack
        direction={isMobile ? 'column' : 'row'}
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={3}
      >
        {header?.length > 0 && (
          <Typography variant="xxl" color="neutral.dark" lineHeight="29px" fontWeight={600}>
            {header}
          </Typography>
        )}
        {showSearch && (
          <StyledSearchField
            variant="outlined"
            placeholder="Procurar"
            onChangeCapture={onChangeSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" sx={{ pr: 1 }}>
                  <SearchIcon htmlColor="#666666" />
                </InputAdornment>
              ),
            }}
          />
        )}
      </Stack>
    </>
  );
};
export default HeaderBarWrapper;