import { useParams } from 'react-router-dom';
import { UF } from 'services/zipCode/zipcode.types';
import InformationLegalPersonTab from './InformationTab';
import { useEconomicActivityCodeListData } from 'contexts/economicActivityCode';
import { useEnumContext } from 'contexts/enumContext';
import React, { useState } from 'react';
import { ApiResponseError, ToastType, handleOnError, toastState } from 'contexts/apiRequestContext';
import Toast from 'components/Toast/Toast';
import { IQueryBasicData } from 'contexts/personContext';
import { useFormContext } from 'contexts/formContext';
import {
    handleSubmitQueryData,
    queryBasicDataPersonMethods,
} from 'helpers/methods/queryBasicDataPerson';
import { useIdentity } from 'contexts/identityContext';
import { SelectOptionsDrawerContainer } from 'components/QueryBasicData/SelectOptions/SelectOptionsContainer';
import { useNaturalPersonList } from 'contexts/naturalPersonContext';

export const InformationLegalPersonTabContainer = () => {
    const { token } = useIdentity();
    const [toast, setToast] = useState<ToastType>(toastState);
    const [enableStep, setEnableStep] = useState<boolean>(false);
    const [queryData, setQueryData] = useState<IQueryBasicData | undefined>(undefined);

    const { setValue } = useFormContext();

    const { personId } = useParams();
    const { autoCompleteProps: economicActvCodeCompleteProps } = useEconomicActivityCodeListData();
    const { autoCompleteProps: legalNatureList } = useEnumContext({ enumName: 'LegalNature' });
    const { autocompleteNaturalPerson } = useNaturalPersonList({
        page: 0,
        size: 10,
    });

    const onClose = () => setEnableStep(false);

    const { handleSetValuesPerson, handleSaveValuesByDrawer } = queryBasicDataPersonMethods(
        setValue,
        'LegalPerson',
        onClose
    );

    const onSuccess = (queryData: IQueryBasicData, registrationNumber: string) => {
        setQueryData(queryData);
        setValue('registrationNumber', registrationNumber);

        if (
            queryData.addresses?.length > 1 ||
            queryData.emails?.length > 1 ||
            queryData.phones.length > 1
        ) {
            return setEnableStep(true);
        }

        handleSetValuesPerson(queryData);
    };

    const handleOnSubmitByChangeField = async (registrationNumber: string) => {
        await handleSubmitQueryData(
            registrationNumber,
            token!,
            (data) => onSuccess(data, registrationNumber),
            (error: ApiResponseError) => handleOnError(error, setToast)
        );
    };

    return (
        <React.Fragment>
            <Toast toast={toast} setToast={setToast} />

            <InformationLegalPersonTab
                personId={personId!}
                setToast={setToast}
                UFList={UF}
                cnaeList={economicActvCodeCompleteProps}
                legalNatureList={legalNatureList.listOptions}
                handleOnSubmitByChangeField={handleOnSubmitByChangeField}
                naturalPersonList={autocompleteNaturalPerson}
            />

            <SelectOptionsDrawerContainer
                enable={enableStep}
                onClose={() => setEnableStep(false)}
                onSubmit={(formValues) => handleSaveValuesByDrawer(formValues, queryData!)}
                queryData={queryData!}
                title={queryData?.legalPerson.companyName ?? 'Pessoa jurídica'}
            />
        </React.Fragment>
    );
};
