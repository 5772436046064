import type { FunctionComponent } from 'react';
import type { NotFoundProps } from './NotFound.interface';
import { Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { Button, Typography } from '../../atoms';
import { LeftIcon, NotFoundIcon } from '../../icons';

export const NotFound: FunctionComponent<NotFoundProps> = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const BackToHomeButton = () => {
    return (
      <Button
        size="medium"
        variant="contained"
        color="primary"
        href="/"
        startIcon={<LeftIcon htmlColor="#ffff" sx={{ height: 25, width: 25 }} />}
      >
        Voltar para o início
      </Button>
    );
  };

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
        direction={{ xs: 'column', md: 'row' }}
        spacing={2}
      >
        <Grid item mr={3}>
          <NotFoundIcon
            sx={{
              height: 270,
              width: 270
            }}
          />
        </Grid>
        <Grid direction="column">
          <Grid item mb={2}>
            <Typography color="neutral.medium" variant={isMobile ? 'xxl' : 'xxxl'}>
              Página não encontrada
            </Typography>
          </Grid>
          <Grid item mb={4}>
            <Typography color="neutral.medium" variant={isMobile ? 'xs' : 'sm'}>
              A&nbsp;
              <Typography color="primary" variant={isMobile ? 'xs' : 'sm'}>
                URL
              </Typography>
              &nbsp;digitada não foi encontrada tente corrigir ou voltar.
            </Typography>
          </Grid>
          <Grid item>
            <BackToHomeButton />
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  );
};
