import { IconButton, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { Error } from 'components/Errors/Error';
import { GridColDef } from '@mui/x-data-grid';
import { DataTable, EditIcon, DeleteIcon } from '@uy3/web-components';
import { UsersAndGroupFiltersContainer } from 'components/DataTableFilters/UsersAndGroupFilters/UsersAndGroupContainer';
import { iconSx } from 'contexts/apiRequestContext';
import { useUserPermissionData } from 'contexts/userContext';

type GroupListProps = {
    queryData: any;
    rowsPerPage: number;
    setRowsPerPage: (page: number) => void;
    setPage: (newPage: number) => void;
    page: number;
    setOpenPopup: (groupName: string) => void | undefined;
};

const GroupList = ({
    queryData,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    page,
    setOpenPopup,
}: GroupListProps) => {
    const { hasPermission } = useUserPermissionData();
    if (queryData?.type === 'error') return <Error error={queryData.message} />;

    const columns: GridColDef[] = [
        {
            field: 'groupName',
            headerName: 'Grupo',
            editable: false,
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'precedence',
            headerName: 'Precedência',
            editable: false,
            minWidth: 150,
            flex: 2,
        },
        {
            field: 'creationDate',
            headerName: 'Data de Criação',
            editable: false,
            minWidth: 150,
            flex: 2,
            renderCell: (cellValues) => {
                return (
                    <div
                        style={{
                            textAlign: 'left',
                            marginLeft: '4px',
                        }}
                    >
                        {cellValues.value &&
                            new Date(cellValues.value).toLocaleDateString('pt-BR', {
                                hour: '2-digit',
                                minute: '2-digit',
                            })}
                    </div>
                );
            },
        },
        {
            field: 'actions',
            headerName: 'Ações',
            hideSortIcons: true,
            editable: false,
            align: 'center',
            headerAlign: 'center',
            minWidth: 150,
            renderCell: (cellValues) => {
                return (
                    <Stack
                        direction={'row'}
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Link to={`${cellValues.id}`}>
                            <IconButton>
                                <EditIcon sx={iconSx} />
                            </IconButton>
                        </Link>

                        {hasPermission('UserGroup', 'Delete') &&
                            <IconButton
                                onClick={() => setOpenPopup && setOpenPopup(cellValues.id as string)}
                            >
                                <DeleteIcon sx={iconSx} />
                            </IconButton>}
                    </Stack>
                );
            },
        }
    ];

    return (
        <DataTable
            filterComponent={{
                showFilters: true,
                componentFilter: <UsersAndGroupFiltersContainer typeFilter="groups" />,
            }}
            getRowId={(row) => row.groupName}
            columns={columns}
            rows={queryData !== undefined ? queryData?.data ?? [] : []}
            page={queryData ? queryData?.page ?? 0 : page}
            rowCount={queryData?.totalItems ?? 0}
            pageCount={queryData?.totalPages ?? 0}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
        />
    );
};

export default GroupList;
