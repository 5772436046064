import { Grid, Box, Stack } from '@mui/material';
import { Typography, SaveIcon, Alert, UpdateIcon } from '@uy3/web-components';
import { useFormContext } from 'contexts/formContext';
import { TabBar } from 'components/TabBar/TabBar';
import NotSavedChangesAlert from 'components/NotSavedChangesAlert';
import { ToastType } from 'contexts/apiRequestContext';
import { Actions } from 'components/Actions/Actions';

type GroupFormProps = {
  groupId: string | undefined;
  refetch: () => void;
  isFetching: boolean;
  setToast: (toast: ToastType) => void;
  hasPermissionCreateOrUpdate: boolean
};

const GroupForm = ({ groupId, isFetching, refetch, setToast, hasPermissionCreateOrUpdate }: GroupFormProps) => {
  const { validationErrors, isDirty, watch } = useFormContext();
  const isNew = groupId === 'novo';

  const tabs = [
    {
      label: 'Informações',
      href: groupId
    },
  ];

  if (!isNew) {
    tabs.push(
      {
        label: 'Membros',
        href: "membros"
      },
      {
        label: 'Permissões',
        href: "permissoes"
      }
    );
  }

  return (
    <>
      {validationErrors && (
        <Box mt={2} mb={2}>
          <Alert
            severity="error"
            text={
              'Não foi possível salvar o grupo: Corrija os erros do formulário e envie novamente.'
            }
            variant="filled"
          />
        </Box>
      )}
      <Box>
        <NotSavedChangesAlert isDirty={isDirty} />
        <Stack direction='row' mb={3} justifyContent='space-between' alignItems='center'>
          <Typography
            variant="h4"
            color="neutral.dark"
            fontStyle="normal"
            fontWeight="700"
            fontSize="32px"
            lineHeight="38.4px"
          >
            {isNew ? 'Novo grupo' : `Grupo ${watch('groupName')}`}
          </Typography>

          <Actions
            numberOfButtons={3}
            buttonsActionsList={[
              {
                enable: !isNew,
                disabled: false,
                label: "Atualizar",
                action: () => {
                  refetch();
                  setToast({
                    title: 'Os dados do grupo foram atualizados!',
                    severity: 'success',
                    open: true
                  });
                },
                icon: <UpdateIcon />
              },
              {
                enable: hasPermissionCreateOrUpdate,
                disabled: false,
                label: "Salvar",
                type: 'submit',
                icon: <SaveIcon />
              }
            ]}
          />
        </Stack>
        <Grid sx={{ mr: 3 }}>
          <TabBar tabs={tabs} />
        </Grid>
      </Box>
    </>
  );
};

export default GroupForm;
