export enum NotificationTypeEnum {
    "NotInformed" = 0,
    "Notice" = 1,
    "Billing_Cobranca_Pago" = 11,
    "Billing_Cobranca_Registrado" = 12,
    "Billing_Cobranca_Cancelado" = 13,
    "Billing_Cobranca_Protestado" = 14,
    "Billing_Cobranca_EnviadoCartorio" = 15,
    "Billing_Carteira_TarifasAtualizadas" = 21,
    "Billing_ArquivoRemessa_ErroProcessamento" = 31
}

export const NotificationTypeDescriptions: { [key in NotificationTypeEnum]: string } = {
    [NotificationTypeEnum.NotInformed]: "Não informado",
    [NotificationTypeEnum.Notice]: "Noticia",
    [NotificationTypeEnum.Billing_Cobranca_Pago]: "Boletos pagos",
    [NotificationTypeEnum.Billing_Cobranca_Registrado]: "Boletos regisrados",
    [NotificationTypeEnum.Billing_Cobranca_Cancelado]: "Boletos cancelados",
    [NotificationTypeEnum.Billing_Cobranca_Protestado]: "Boletos protestados",
    [NotificationTypeEnum.Billing_Cobranca_EnviadoCartorio]: "Boletos enviados para cartório",
    [NotificationTypeEnum.Billing_Carteira_TarifasAtualizadas]: "Tarifas da carteira atualizadas ",
    [NotificationTypeEnum.Billing_ArquivoRemessa_ErroProcessamento]: "Erro de arquivo remessa"
};

export function getNotificationTypeDescription(type: NotificationTypeEnum): string {
    return NotificationTypeDescriptions[type];
}