import { useFilters } from 'contexts/filterContext';
import { FormProvider } from 'contexts/formContext';
import React from 'react';
import { WalletFilters } from './WalletFilters';
import { walletFiltersSchema, defaultValuesWalletFilterschema } from './WalletFiltersSchema';
import { FieldValues } from 'react-hook-form';

export const WalletFiltersContainer: React.FC = () => {
    const { filters, addFilter, removeFilterByIndex } = useFilters();

    const handleOnSubmit = (values: FieldValues) => {
        const { optionFilter } = values;
        addFilter(optionFilter, values[optionFilter]);
    };

    return (
        <>
            <FormProvider
                defaultValues={defaultValuesWalletFilterschema}
                validationSchema={walletFiltersSchema()}
                onSubmit={handleOnSubmit}
            >
                <WalletFilters
                    currentValues={filters}
                    removeFilterSelected={removeFilterByIndex}
                />
            </FormProvider>
        </>
    );
};
