import { IVinculoFuncional } from 'services/datasets';
import { PayrollAgreementReadModel } from '../payrollAgreement.type';
import { IServidore } from 'services/zetra';
import { FieldValues } from 'react-hook-form';
import { PublicPayrollCreateModel } from 'services/creditNote';
import { showErrorToast, ToastType } from 'contexts/apiRequestContext';
import { AxiosError } from 'axios';
import { getByInternalId } from '../payrollAgreement';
import { Dispatch, SetStateAction } from 'react';
import { IReserveTokensRegistered } from 'services/accounts/naturalPerson';

export const mapSiapeWarrantyPublicPayroll = (
    list: IVinculoFuncional[],
    data: PayrollAgreementReadModel
) => {
    return list.map((item) => ({
        warrantyType: 'PublicPayroll',
        payrollAgreementId: data?.id,
        payrollAgreementName: data?.name,
        paymentTransferDay: data?.paymentTransferDay,
        employeeCode: item.CdMatricula,
        agreementCode: item.Produtos[0]?.CdConvenio,
        employeerInstCode: item?.OrgMatInst,
        paymentAmountInCents: item.Produtos[0]?.VlMargemDisp,
        employmentStatusCode: item.CodSituacaoFuncional,
        employmentStatusDescription: item.DescSituacaoFuncional,
        codOrgao: item?.CodOrgao,
    }));
};

export const mapZetraWarrantyPublicPayroll = (
    data: PayrollAgreementReadModel,
    servidore: IServidore[],
    newValues: FieldValues,
    registrationNumber: string | undefined
): PublicPayrollCreateModel[] => {
    return servidore.map((item) => ({
        warrantyType: 'PublicPayroll',
        payrollAgreementId: data?.id,
        payrollAgreementName: data?.name,
        paymentTransferDay: data?.paymentTransferDay,
        employeeCode: item.matricula,
        agreementCode: item.convenio,
        paymentAmountInCents: item?.margens?.[0]?.valorDisponivel ?? 0,
        codOrgao: item?.orgaoCodigo,
        warrantyOption: newValues?.warrantyOption,
        warrantyDeadline: newValues?.warrantyDeadline,
        previousAdeNumber: newValues?.previousAdeNumber,
        simulationRegistrationNumber: registrationNumber
    }));
};

/**
 *  Função para buscar e mapear dados do convênio com base no internalId
 * @param internalId
 * @param selectedItems
 * @param mapWarrantyModels
 * @param token
 * @param watchedWarranty
 * @param setValue
 * @param setToast
 * @param onCloseAll
 */
export const fetchAndMapWarrantyData = async (
    internalId: string,
    selectedItems: any[],
    mapWarrantyModels: (selectedItems: any[], data: PayrollAgreementReadModel) => any[],
    token: string,
    watchedWarranty: any[],
    setValue: Function,
    setToast: Dispatch<SetStateAction<ToastType>>,
    onCloseAll: Function
) => {
    try {
        const { data } = await getByInternalId(internalId, token);
        const mappedWarrantyModels = mapWarrantyModels(selectedItems, data);

        setValue('warranty', [...watchedWarranty, ...mappedWarrantyModels]);
        setValue('amortization.calculateByValueType', 'Payment');
        setValue(
            'amortization.requestedAmount',
            mappedWarrantyModels[0]?.paymentAmountInCents ||
                selectedItems[0].Produtos[0]?.VlMargemDisp
        );
        onCloseAll();
    } catch (error) {
        const errorMessage =
            error instanceof AxiosError
                ? error.response?.data?.message || 'Erro desconhecido'
                : error;

        showErrorToast(
            'Ops! Ocorreu um erro ao buscar os dados do convênio',
            errorMessage,
            setToast
        );
    }
};

/**
 * Extrai o valor do token de uma lista de objetos de dados se o tipo de token for "Reserva".
 *
 * @param {Array<IReserveTokensRegistered>} dataList - Lista de objetos de dados para procurar token.
 * @returns {string|null} - O valor do token, se encontrado, caso contrário, nulo.
 */
export function getReserveTokenValue(dataList: IReserveTokensRegistered[]) {
    if (!Array.isArray(dataList)) return null;

    const reserveToken = dataList.find((item) => item.result?.Token?.TokenType === 'Reserve');

    return reserveToken ? reserveToken.result?.Token?.TokenValue : null;
}
