/* eslint-disable sonarjs/no-duplicate-string */

import { GridColDef } from '@mui/x-data-grid';

export const columns: GridColDef[] = [
  {
    field: 'data',
    headerName: 'Data ',
    width: 150,
    editable: false,
  },
  {
    field: 'CPF/CNPJ',
    headerName: 'CPF/CNPJ ',
    width: 170,
    editable: false,
  },
  {
    field: 'tipo-de-pagamento',
    headerName: 'Tipo de pagamento ',
    width: 220,
    editable: false,
  },
  {
    field: 'dados-da-conta',
    headerName: 'Dados da Conta ',
    width: 220,
    editable: false,
  },
  {
    field: 'lancamento',
    headerName: 'Lançamento ',
    width: 280,
    editable: false,
  },
  {
    field: 'valor',
    headerName: 'Valor ',
    width: 120,
    editable: false,
  },
  {
    field: 'status',
    headerName: 'Status ',
    width: 220,
    editable: false,
  },
  {
    field: 'acoes',
    headerName: 'Ações ',
    width: 170,
    editable: false,
    hideSortIcons: true,
  },
];

export const rows = [];
