import { Grid } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@uy3/web-components';
import { DatePickerFormField, SelectFormField, TextFormField } from 'components/Forms/FormFields';
import { ApiResponseError } from 'contexts/apiRequestContext';
import { FormProvider, useFormContext } from 'contexts/formContext';
import { useIdentity } from 'contexts/identityContext';
import { disapproveCreditNoteById } from 'services/creditNote';
import {
    defaultValuesCreditNoteRejectCompliance,
    validationCreditNoteRejectComplianceSchema,
} from './CreditNoteRejectComplianceSchema';
import { PopupState } from 'contexts/creditNote/creditNoteActions.interface';
import { useGetRejectionReason } from 'contexts/creditNote/creditContext';

type RejectComplianceProps = {
    selectedPerson: any;
    clearSelectedItems: () => void;
    handleErrorResponse: (response: ApiResponseError) => void;
    setPopupProps: (prevState: PopupState | undefined) => void;
    refetch: () => void;
};

const CreditNoteRejectComplianceContainer = ({
    selectedPerson,
    setPopupProps,
    refetch,
    handleErrorResponse,
    clearSelectedItems
}: RejectComplianceProps) => {
    const { token } = useIdentity();
    const { watch } = useFormContext();
    const queryClient = useQueryClient();
    const { listReasons } = useGetRejectionReason();

    const onSubmit = async (values: any) => {
        selectedPerson?.map(async (row: any) => {
            const insertBlock = values?.insertBlock;
            const payment = {
                ...values,
                insertBlock: insertBlock === 'not' ? null : insertBlock,
            };
            await disapproveCreditNoteById(payment, row?.id, token!)
                .then(() => {
                    queryClient.invalidateQueries({
                        queryKey: ['credit-signature'],
                        refetchType: 'active',
                    });
                    clearSelectedItems();
                    refetch();
                    setPopupProps(undefined);
                })
                .catch((response) => handleErrorResponse(response));
        });
    };

    const schema = validationCreditNoteRejectComplianceSchema();
    const defaultValus = defaultValuesCreditNoteRejectCompliance;

    return (
        <FormProvider defaultValues={defaultValus} validationSchema={schema} onSubmit={onSubmit}>
            <>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} mt={2}>
                        <SelectFormField
                            label="Motivo da Reprovação"
                            name="rejectionReasonCode"
                            multiple
                            options={listReasons}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextFormField
                            name="message"
                            label="Digite o Motivo"
                            multiline
                            rows={3}
                            variant="outlined"
                            fullWidth
                            disabled={false}
                            required
                        />
                    </Grid>
                    {watch('status') !== 'SignaturesValidation' && (
                        <>
                            <Grid item xs={12}>
                                <SelectFormField
                                    name="insertBlock"
                                    label="Realizar Bloqueio?"
                                    required
                                    options={[
                                        { label: 'Não', value: 'not' },
                                        { label: 'Tomador', value: 'Person' },
                                        { label: 'Operação', value: 'Operation' }
                                    ]}
                                />
                            </Grid>
                            {watch('insertBlock') !== 'not' && (
                                <Grid item xs={12}>
                                    <Grid item>
                                        <DatePickerFormField
                                            label="Data de Bloqueio"
                                            name="blockUntil"
                                            fullWidth
                                            required
                                            disabled={false}
                                            disablePast
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => setPopupProps(undefined)}
                        size="medium"
                        sx={{ mt: 3, mr: 2 }}
                    >
                        Não, cancelar
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="medium"
                        sx={{ mt: 3 }}
                    >
                        Sim, confirmar
                    </Button>
                </Grid>
            </>
        </FormProvider>
    );
};

export default CreditNoteRejectComplianceContainer;
