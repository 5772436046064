import type { FunctionComponent } from 'react';
import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export const ActionsIcon: FunctionComponent<SvgIconProps> = (props) => {
    const { htmlColor = '#373737' } = props;

    return (
        <SvgIcon {...props}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
            >
                <path
                    d="M14.5 12.5C14.5 13.8807 13.3807 15 12 15C10.6193 15 9.5 13.8807 9.5 12.5C9.5 11.1193 10.6193 10 12 10C13.3807 10 14.5 11.1193 14.5 12.5Z"
                    stroke={htmlColor}
                    strokeWidth="2"
                />
                <path
                    d="M14.5 4C14.5 5.38071 13.3807 6.5 12 6.5C10.6193 6.5 9.5 5.38071 9.5 4C9.5 2.61929 10.6193 1.5 12 1.5C13.3807 1.5 14.5 2.61929 14.5 4Z"
                    stroke={htmlColor}
                    strokeWidth="2"
                />
                <path
                    d="M14.5 21C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21C9.5 19.6193 10.6193 18.5 12 18.5C13.3807 18.5 14.5 19.6193 14.5 21Z"
                    stroke={htmlColor}
                    strokeWidth="2"
                />
            </svg>
        </SvgIcon>
    );
};

ActionsIcon.defaultProps = {
    viewBox: '0 0 24 25',
    width: '24',
    height: '25',
    fill: 'none',
};
